import {
    onEnter,
    push,
    getReplacingPath,
    actionWithLoader
} from "../Utils/utils"
import {
    updateAllProductsCounter
} from "../Products/Products"
import {
    getProductsTags,
    createProductTag
} from "../../parseManager/products/resources/productTag/parseProductTagManager"

export function loadResourcesProductsTags() {
    return actionWithLoader(async (dispatch) => {
        const productsTags = await getProductsTags()

        dispatch({
            type: "RESOURCES_PRODUCTS_TAGS_LOADED",
            productsTags
        })
    })
}

export function loadProductTagsOptions() {
    return actionWithLoader(async (dispatch) => {
        const productTags = await getProductsTags({ select: ["name"] })
        const productTagsOptions = productTags.map(productTag => ({
            key: productTag.objectId,
            label: productTag.name
        }))
        dispatch({
            type: "PRODUCT_TAGS_OPTIONS_LOADED",
            productTagsOptions
        })
    })
}

export function createResourcesProductTag(values, dispatch, { productTag, location }) {
    return actionWithLoader(async (dispatch) => {
        const newProductTag = await createProductTag(productTag, values)

        dispatch({
            type: "RESOURCES_PRODUCT_TAG_SAVED"
        })

        await dispatch(loadResourcesProductsTags())
        await dispatch(updateAllProductsCounter())

        if (location.state && location.state.returnPath === "edit"){
            await dispatch(showProductTagRead(newProductTag.objectId))
        }
        else {
            await dispatch(showProductsTagsList())
        }
    })
}

export function onEnterProductsTags(store) {
    return onEnter({
        store,
        actionThunk: loadResourcesProductsTags,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function showProductsTagsList() {
    return push("/settings/resources/tags")
}

export function showProductTagEdit(id, location) {
    if (id) {
        return push(`/settings/resources/tag/${id}/edit`, location)
    }
    else {
        return push("/settings/resources/tag")
    }
}

export function showProductTagRead(id) {
    if (id) {
        return push(`/settings/resources/tag/${id}`)
    }
    else {
        return push("/settings/resources/tag")
    }
}
