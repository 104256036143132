import React from "react"
import { Formik, Form } from "formik"
import { TextField, MenuItem } from "@mui/material"
import { FormControl, FormControlLabel, Select, InputLabel, Switch } from "@mui/material"
import { DistributionCenterTypes } from "../../../../../utils/distributionCenter"
import { packagingNature } from "../../../../../utils/packaging"
import { ACTIVE_KFC_BRANDS } from "../../../../../utils"
import { sectors } from "../../../../../utils/planning"

import { makeStyles } from "@mui/styles"
import { red, green } from "@mui/material/colors"

const useStyles = makeStyles(() => ({
	formContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		flexWrap: "wrap"
	},
	field: {
		width: "100%",
		marginBottom: 10
	},
	switchBase: {
		color: red[400],
	},
	switchChecked: {
		color: green[400]
	},
	switchTrack: {
		backgroundColor: red[400],
	},
	switchTrackChecked: {
		backgroundColor: green[400],
	},
	switchFieldInput: {
		marginLeft: 0
	}
}))

export const distributionCenterResumeFormId = "distributionCenterResumeFormId"

const DistributionCenterResumeForm = ({ distributionCenter, handleSubmit }) => {

	const classes = useStyles()

	const renderBase = (arr) => {
		return arr.map(elem =>
			<MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>
		)
	}

	const renderDistributionCenterTypes = () => {
		return renderBase(DistributionCenterTypes)
	}

	const renderDistributionCenterPackagingType = () => {
		return renderBase(packagingNature)
	}

	const renderBrands = () => {
		return renderBase(ACTIVE_KFC_BRANDS.map(brand => ({ value: brand.name, label: brand.label })))
	}

	const renderSectors = () => {
		const enabledSectors = sectors.filter(elem => elem.enableValue === true)

		return renderBase(enabledSectors)
	}

	const handleChangeBrand = (e, setFieldValue) => {
		const value = e.target.value
		setFieldValue("brand", value)
	}

	// format initialValues so as just to keep what exists
	// pour chaque formulaire passer au momeent d'enregistrer la fonction les fields considérées  
	// de sorte à n'avoir qu'une fonction pour tous les formulaires avec le tableau des fields considérés qui change

	return (
		<Formik
			initialValues={{
				name: distributionCenter.name,
				externalId: distributionCenter.externalId,
				brand: distributionCenter.brand,
				sector: distributionCenter.sector || sectors.filter(elem => elem.enableValue === true)[0].value,
				type: distributionCenter.type || DistributionCenterTypes[0].value,
				packagingTypes: distributionCenter.packagingTypes,
				isActive: distributionCenter.isActive,
				national: distributionCenter.national,
				onlyPreorders: distributionCenter.onlyPreorders
			}}
			onSubmit={handleSubmit}
		>
			{({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => (
				<Form
					id={distributionCenterResumeFormId}>
					<div className={classes.formContainer}>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<TextField
									variant="standard"
									name="name"
									label="Nom"
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								{
									touched.name && errors.name
										?
										(
											<div className={classes.errorField}>{errors.name}</div>
										)
										: null
								}
							</FormControl>
						</div>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<TextField
									variant="standard"
									name="externalId"
									label="Id externe (nu)"
									value={values.externalId}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<InputLabel>Marque</InputLabel>
								<Select
									variant="standard"
									name="brand"
									value={values.brand}
									onChange={(e) => handleChangeBrand(e, setFieldValue)}
									onBlur={handleBlur}
								>
									{renderBrands()}
								</Select>
							</FormControl>
						</div>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<InputLabel>Secteur</InputLabel>
								<Select
									variant="standard"
									name="sector"
									value={values.sector}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									{renderSectors()}
								</Select>
							</FormControl>
						</div>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<InputLabel>Typologie</InputLabel>
								<Select
									variant="standard"
									name="type"
									value={values.type}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									{renderDistributionCenterTypes()}
								</Select>
							</FormControl>
						</div>
						<div>
							<FormControl variant="standard" className={classes.field}>
								<InputLabel>Type de packaging</InputLabel>
								<Select
									variant="standard"
									name="packagingTypes"
									multiple
									value={values.packagingTypes}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									{renderDistributionCenterPackagingType()}
								</Select>
							</FormControl>
						</div>
						<div>
							<FormControlLabel
								control={
									<Switch
										classes={{
											switchBase: classes.switchBase,
											checked: classes.switchChecked,
											track: values.isActive ? classes.switchTrackChecked : classes.switchTrack,
										}}
										color="default"
										name="isActive"
										checked={values.isActive}
										onChange={handleChange}
									/>
								}
								labelPlacement="start"
								label="Actif"
								className={classes.switchFieldInput}
							/>
						</div>
						<div>
							<FormControlLabel control={
								<Switch
									classes={{
										switchBase: classes.switchBase,
										checked: classes.switchChecked,
										track: values.national ? classes.switchTrackChecked : classes.switchTrack,
									}}
									color="default"
									name="national"
									checked={values.national}
									onChange={handleChange}
								/>
							}
								labelPlacement="start"
								label="Province"
								className={classes.switchFieldInput}
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Switch
										classes={{
											switchBase: classes.switchBase,
											checked: classes.switchChecked,
											track: values.onlyPreorders ? classes.switchTrackChecked : classes.switchTrack,
										}}
										color="default"
										name="onlyPreorders"
										checked={values.onlyPreorders}
										onChange={handleChange}
									/>
								}
								labelPlacement="start"
								label="Seulement précommande"
								className={classes.switchFieldInput}
							/>
						</div>
					</div>
				</Form>)}
		</Formik>
	)

}

export default DistributionCenterResumeForm