import {
    actionWithLoader,
    getReplacingPath,
    onEnter,
    push
} from "../../../Utils/utils"
import {
    createAllergenIngredient,
    getAllergensIngredients
} from "../../../../parseManager/ingredients/classifications/allergen/parseAllergenManager"
import {
    getSubcontractorsCommercialNamesForAllergens
} from "../../../../parseManager/ingredients/external/parseSubcontractorCommercialNameManager"
import {
    getCommercialNamesForAllergens
} from "../../../../parseManager/ingredients/internal/parseCommercialNameManager"

export function loadAllergensClassificationsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const includes = ["allergens"]

        const allergensIngredients = await getAllergensIngredients()
        const commercialsNames = await getCommercialNamesForAllergens(includes)
        const subcontractorsCommercialsNames = await getSubcontractorsCommercialNamesForAllergens(includes)

        dispatch({
            type: "CLASSIFICATIONS_ALLERGENS_INGREDIENTS_LOADED",
            allergensIngredients,
            commercialsNames,
            subcontractorsCommercialsNames
        })
    })
}

export function createClassificationAllergenIngredient(values, dispatch, { allergenIngredient, location }) {
    return actionWithLoader(async (dispatch) => {

        const newAllergenIngredient = await createAllergenIngredient(allergenIngredient, values)

        dispatch({
            type: "CLASSIFICATIONS_ALLERGEN_INGREDIENT_SAVED"
        })

        dispatch(loadAllergensClassificationsIngredients())
        if (location.state && location.state.returnPath === "edit"){
            dispatch(showSingleAllergenClassificationsIngredientsRead(newAllergenIngredient.objectId))
        }
        else {
            dispatch(showAllergensClassificationsIngredients())
        }
    })
}

export function onEnterAllergensClassificationsIngredients(store) {
    return onEnter({
        store,
        actionThunk: loadAllergensClassificationsIngredients,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function showSingleAllergenClassificationsIngredientsRead(id) {
    if (id) {
        return push(`/settings/ingredients/classifications/allergen/${id}`)
    }
    else {
        return push("/settings/ingredients/classifications/allergen")
    }
}

export function showSingleAllergenClassificationsIngredientsEdit(id, location) {
    if (id) {
        return push(`/settings/ingredients/classifications/allergen/${id}/edit`, location)
    }
    else {
        return push("/settings/ingredients/classifications/allergen")
    }
}
export function showAllergensClassificationsIngredients() {
    return push("/settings/ingredients/classifications/allergens")
}
