import React from "react"
import PlanningsPackaging from "./PlanningsPackaging"
import PlanningsTabs from "./PlanningsTabs"

const PlanningPackagingTab = () => {
    return (
        <PlanningsTabs tabValue="packaging">
            <PlanningsPackaging />
        </PlanningsTabs>
    )
}

export default PlanningPackagingTab
