import { getBrandBy, forEachBrand, KFC_BRANDS } from "../utils"

export const subcontractorProductValidator = values => {
    const errors = {}
    const status = (values && parseFloat(values.status)) || 0

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }
    if (!values.commercialName){
        errors.commercialName = "Le nom commercial est obligatoire"
    }
    else if (values.commercialName.length > 60) {
        errors.commercialName = "Le nom commercial ne doit pas dépasser 60 signes"
    }
    if (!values.status) {
        errors.status = "Le statut est obligatoire"
    }
    if (!values.type) {
        errors.type = "Le type est obligatoire"
    }
    if (!values.brand) {
        errors.brand = "La marque est obligatoire"
    }
    if (!values.subcontractor){
        errors.subcontractor = "Le sous-traitant est obligatoire"
    }
    if (!values.preparation){
        errors.preparation = "La préparation est obligatoire"
    }
    if (!values.specialInstruction){
        errors.specialInstruction = "Les instructions spécifiques sont obligatoires"
    }
    if (!values.season || values.season.length === 0){
        errors.season = "Au moins une saison doit être seléctionnée"
    }

    if (values.brand !== null){
        if (!values.packaging){
            errors.packaging = "Le packaging est obligatoire"
        }
    }
    if (status === 4) {
        if (!values.tva) {
            errors.tva = "La tva est obligatoire"
        }
        if (values.brand && !values.price) {
            const completeBrand = getBrandBy("name", values.brand)

            if (completeBrand.priceEnabled) {
                errors.price = "Le prix de vente est obligatoire"
            }
        }
        if (!values.dlc) {
            errors.dlc = "La DLC est obligatoire"
        }
        if (!values.description){
            errors.description = "La description est obligatoire"
        }
    }
    return errors
}

export const subcontractorCommercialNameValidator = values => {
    const errors = {}

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }
    if (!values.subcontractor){
        errors.subcontractor = "Le sous-traitant est obligatoire"
    }
    if (!values.group){
        errors.group = "Le groupe est obligatoire"
    }
    if (!values.firstOriginAverage){
        errors.firstOriginAverage = "La valeur est obligatoire"
    }
    if (values.firstOriginAverage !== 100){
        if (!values.secondOrigin && values.secondOrigin !== 0){
            errors.secondOrigin = "La provenance est obligatoire"
        }
    }

    return errors
}

export const commercialNameValidator = values => {
    const errors = {}

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }
    if (!values.group){
        errors.group = "Le groupe est obligatoire"
    }
    if (!values.firstOriginAverage){
        errors.firstOriginAverage = "La valeur est obligatoire"
    }
    if (values.firstOriginAverage !== 100){
        if (!values.secondOrigin && values.secondOrigin !== 0){
            errors.secondOrigin = "La provenance est obligatoire"
        }
    }
    if (!values.complexity) {
        errors.complexity = "La complexité est obligatoire"
    }

    return errors
}

export const familyIngredientValidator = values => {
    const errors = {}

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }

    return errors
}

export const allergenIngredientValidator = values => {
    const errors = {}

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }

    return errors
}

export const groupIngredientValidator = values => {
    const errors = {}

    if (!values.name){
        errors.name = "Le nom est obligatoire"
    }
    if (!values.family){
        errors.family = "La famille d'ingrédient est obligatoire"
    }

    return errors
}

export const checkBrandsRelatedValues = (brand, errors, values) => {
    const recipeStatus = parseFloat(values.status)
    const completeBrand = getBrandBy("name", brand)

    if (recipeStatus >= 1) {
        // PACKAGINGS
        let invalidPackaging = false
        if (values.packaging && values.packaging.length) {
            const packaging = values.packaging.find(p => p.brand === brand)
            invalidPackaging = ! (packaging && packaging.value)
        } else {
            invalidPackaging = true
        }

        if (invalidPackaging) {
            errors["packaging"].find(v => v.brand === brand).value = `Le packaging ${completeBrand.shortname} est obligatoire`
        }

        // INSTRUCTIONS
        let invalidInstructions = false
        if (values.instructions && values.instructions.length) {
            const instructions = values.instructions.find(p => p.brand === brand)
            invalidInstructions = ! (instructions && instructions.value)
        } else {
            invalidInstructions = true
        }

        if (invalidInstructions) {
            errors["instructions"].find(v => v.brand === brand).value = values.sameInstructions
                ? "Les instructions de dressage sont obligatoires"
                : `Les instructions de dressage ${completeBrand.shortname} sont obligatoires`
        }
    }

    if (recipeStatus === 4) {
        // DLCs
        let invalidDlc = false
        if (values.dlc && values.dlc.length) {
            const dlc = values.dlc.find(dlc => dlc.brand === brand)
            invalidDlc = ! (dlc && dlc.value)
        } else {
            invalidDlc = true
        }

        if (invalidDlc) {
            errors["dlc"].find(v => v.brand === brand).value = `La dlc ${completeBrand.shortname} est obligatoire`
        }

        // PRICES
        if (completeBrand.priceEnabled) {
            let invalidPrice = false
            if (values.price && values.price.length) {
                const price = values.price.find(p => p.brand === brand)
                invalidPrice = ! (price && price.value)
            } else {
                invalidPrice = true
            }

            if (invalidPrice) {
                errors["price"].find(v => v.brand === brand).value = `Le prix de vente ${completeBrand.shortname} est obligatoire`
            }
        }

        // DESCRIPTIONS
        let invalidDescription = false
        if (values.description && values.description.length) {
            const description = values.description.find(d => d.brand === brand)
            invalidDescription = ! (description && description.value)
        } else {
            invalidDescription = true
        }

        if (invalidDescription) {
            errors["description"].find(v => v.brand === brand).value = values.sameDescriptions
                ? "La description du produit est obligatoire"
                : `La description ${completeBrand.shortname} est obligatoire`
        }
    }
}

export const labelTemplatesValidator = values => {
    const errors = {}
    if (!values.template) {
        errors.template = "Le template est obligatoire"
    }
    if (!values.color) {
        errors.color = "La couleur est obligatoire"
    }
    return errors
}

export const recipeValidator = values => {
    const recipeStatus = (values && parseFloat(values.status)) || null
    const errors = {}

    if (recipeStatus >= 1) {
        if (!values.name){
            errors.name = "Le nom est obligatoire"
        }
        if (!values.commercialName){
            errors.commercialName = "Le nom commercial est obligatoire"
        } else if (values.commercialName.length > 60) {
            errors.commercialName = "Le nom commercial ne doit pas dépasser 60 signes"
        }
        if (!values.type){
            errors.type = "Le type est obligatoire"
        }
        if (!values.brands || values.brands === null || !values.brands.length) {
            errors.brands = "Les marques sont obligatoires"
        }
        if (!values.preparation){
            errors.preparation = "La préparation est obligatoire"
        }
        if (!values.season || values.season.length === 0) {
            errors.season = "Au moins une saison doit être seléctionnée"
        }
        if (values.brands && values.brands.length) {
            errors["packaging"] = values.packaging.map(p => ({ brand: p.brand, value: "" }))
            errors["dlc"] = values.dlc.map(dlc => ({ brand: dlc.brand, value: "" }))
            errors["price"] = values.price.map(p => ({ brand: p.brand, value: "" }))
            errors["description"] = values.description.map(d => ({ brand: d.brand, value: "" }))
            errors["instructions"] = values.instructions.map(i => ({ brand: i.brand, value: "" }))
            values.brands.forEach(brand => {
                checkBrandsRelatedValues(brand, errors, values)
            })
        }
    }

    if (recipeStatus === 4){
        if (!values.tva){
            errors.tva = "La TVA est obligatoire"
        }
    }

    return errors
}

export const recipeCommentValidator = () => {
    return {}
}

export const packagingValidator = values => {
    const errors = {}
    if (!values.reference)
        errors.reference = "La référence est obligatoire"

    if (!values.name)
        errors.name = "Le nom est obligatoire"

    if (!values.price)
        errors.price = "Le prix est obligatoire"
    else if (values.price < 0)
        errors.price = "Le prix doit être un nombre positif"

    if (!values.type)
        errors.type = "Le type est obligatoire"

    if (values.weight && values.weight < 0)
        errors.weight = "Le poid doit être un nombre positif"

    if (values.quantity && values.quantity < 0) {
        errors.quantity = "La quantité doit être un nombre positif"
    }

    if (!values.brand)
        errors.brand = "La marque est obligatoire"

    if (!values.categories)
        errors.categories = "La catégorie est obligatoire"
    
    if (!values.nature)
        errors.nature = "La nature du packaging est obligatoire"
    return errors
}

export const nutritionInformationValidator = values => {
    const errors = {}
    const negArr = [
        {key: "calories", label: "calories"},
        {key: "sugars", label: "sucres"},
        {key: "carbohydrates", label: "glucides"},
        {key: "fat", label: "lipides"},
        {key: "carbonFootPrint", label: "Empreinte CO2"},
        {key: "sugar", label: "sucre"},
        {key: "salt", label: "sel"},
        {key: "saturatedFattyAcids", label: "acide gras saturé"},
        {key: "fibers", label: "fibre"},
        {key: "proteins", label: "protéines"},
        {key: "frenchIngredientsRate", label: "Ingrédients Français"},
        {key: "localIngredientsRate", label: "Circuits courts"},
        {key: "organicIngredientsRate", label: "Ingrédients bio"},
        {key: "seasonalIngredientsRate", label: "Légumes/fruits de saison"},
        {key: "certifiedIngredientsRate", label: "Ingrédients labellisés"},
    ]
    const pctArr = [
        {key: "frenchIngredientsRate", label: "Ingrédients Français"},
        {key: "localIngredientsRate", label: "Circuits courts"},
        {key: "organicIngredientsRate", label: "Ingrédients bio"},
        {key: "seasonalIngredientsRate", label: "Légumes/fruits de saison"},
        {key: "certifiedIngredientsRate", label: "Ingrédients labellisés"},
    ]

    for (const idx in negArr) {
        const elem = negArr[idx]
        if (values[elem.key] < 0) {
            errors[elem.key] = "Le champ " + elem.label + " ne peut pas être inférieur à 0."
        }
    }
    for (const idx in pctArr) {
        const elem = pctArr[idx]
        if (values[elem.key] > 100) {
            errors[elem.key] = "Le champ " + elem.label + " ne peut pas être supérieur à 100"
        }
    }

    return errors
}

export const productTagValidator = values => {
    const errors = {}

    if (!values.name) {
        errors.name = "Le nom est obligatoire"
    }
    if (!values.type) {
        errors.type = "Le type est obligatoire"
    }
    return errors
}

export const reportSettingValidator = values => {
    const errors = {}

    forEachBrand(brand => {
        if (values && values[brand.name]) {
            if (values[brand.name].productionDate === "") {
                errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                errors[brand.name].productionDate = `La date de production pour ${brand.label} à J- est obligatoire`
            }
            else if (Number(values[brand.name].productionDate) < 0) {
                errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                errors[brand.name].productionDate = `La date de production pour ${brand.label} à J- doit être un nombre positif`
            }

            if (values[brand.name].packagingDate === "") {
                errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                errors[brand.name].packagingDate = `La date de barquettage pour ${brand.label} à J- est obligatoire`
            }
            else if (Number(values[brand.name].packagingDate) < 0) {
                errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                errors[brand.name].packagingDate = `La date de barquettage pour ${brand.label} à J- doit être un nombre positif`
            }

            if (values[brand.name].productionDate !== "" && values[brand.name].packagingDate !== "" &&
                Number(values[brand.name].packagingDate) >= Number(values[brand.name].productionDate)) {
                errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                if (Number(values[brand.name].productionDate) === Number(values[brand.name].packagingDate)) {
                    errors[brand.name].packagingDate = "La date de packaging ne peut pas se faire le jour de la vente"
                } else {
                    errors[brand.name].packagingDate = "La date de packaging ne peut pas se faire après le jour de la vente"
                }
            }

            KFC_BRANDS.filter(b => b.name !== brand.name).forEach(otherBrand => {
                if (values[brand.name][`isReportingTo${otherBrand.shortname}`] === true) {
                    if (Number(values[brand.name][`reportSales${otherBrand.shortname}`]) < 0) {
                        errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
                        errors[brand.name][`reportSales${otherBrand.shortname}`] = `La date de report de vente de ${brand.label} vers ${otherBrand.label} ne peut pas être négative`
                    }
                }
            })
        }

        else {
            errors[brand.name] = errors[brand.name] ? errors[brand.name] : {}
            errors[brand.name].productionDate = `Date de production ${brand.label} invalide`
            errors[brand.name].packagingDate = `Date de barquettage ${brand.label} invalide`
        }
    })

    return errors
}

export const distributionCenterFormValidator = (values, productTypes = [], productTypeCapacities = []) => {
    const errors = {}
    const PRODUCT_TYPES = productTypes
    
    if (!values.name) {
        errors.name = "Le nom est obligatoire."
    }
    if (!values.brand) {
        errors.brand = "La marque est obligatoire"
    }
    if (!values.city) {
        errors.city = "La ville de ratachement est obligatoire"
    }
    if (!values.type) {
        errors.type = "Le type est obligatoire"
    }
    if (!values.openDate) {
        errors.openDate = "La date d'ouverture est obligatoire"
    }
    if (!values.address) {
        errors.address = {}
        errors.address.address = "L'adresse est obligatoire"
        errors.address.zipCode = "Le code postale est obligatoire"
        errors.address.city = "La ville est obligatoire"
        errors.address.latitude = "La latitude est obligatoire"
        errors.address.longitude = "La longitude est obligatoire"
    }
    else {
        if (!values.address.address) {
            errors.address = errors.address ? errors.address : {}
            errors.address.address = "L'adresse est obligatoire"
        }
        if (!values.address.zipCode) {
            errors.address = errors.address ? errors.address : {}
            errors.address.zipCode = "Le code postale est obligatoire"
        }
        if (!values.address.city) {
            errors.address = errors.address ? errors.address : {}
            errors.address.city = "La ville est obligatoire"
        }
        if (!values.address.latitude) {
            errors.address = errors.address ? errors.address : {}
            errors.address.latitude = "La latitude est obligatoire"
        }
        if (!values.address.longitude) {
            errors.address = errors.address ? errors.address : {}
            errors.address.longitude = "La longitude est obligatoire"
        }
    }

    if (values.capacities) {
        productTypeCapacities.forEach(productType => {
            if (values.capacities[productType.field] < 0) {
                if (!errors.capacities) {
                    errors.capacities = {}
                }
                errors.capacities[productType.field] = `Le champ ${productType.label} ne peut pas être négatif`
            }
        })
    }
    
    if (values.attachRates) {
        PRODUCT_TYPES.forEach(productType => {
            if (values.attachRates[productType.value] < 0) {
                if (!errors.attachRates) {
                    errors.attachRates = {}
                }
                errors.attachRates[productType.value] = `Le champ ${productType.label} ne peut pas être négatif`
            }
        })
    }

    if (values.internalMail) {
        values.internalMail.split(";").map(mail => {
            if (!mail.trim().match(EMAIL_REGEX)) errors.internalMail = "Entrer des emails valides séparés par des ;"
            return mail
        })
    }

    if (values.externalMail) {
        values.externalMail.split(";").map(mail => {
            if (!mail.trim().match(EMAIL_REGEX)) errors.externalMail = "Entrer des emails valides séparés par des ;"
            return mail
        })
    }

    return errors
}

const distributionCenterPriorityFieldNegative = [
    "waste", "minDessert", "maxDessert", "minStarter",
    "maxStarter", "minMainCourse", "maxMainCourse"
]

const distributionCenterPriorityFieldGreaterThan = [
    {first: "minDessert", last: "maxDessert"},
    {first: "minStarter", last: "maxStarter"},
    {first: "minMainCourse", last: "maxMainCourse"},
]

export const distributionCenterPriorityFormValidator = values => {
    let ret = {}

    if (values.distributionCenters) {
         ret = {
            distributionCenters: values.distributionCenters.map(value => {
                const dispatch = {}

                if (value.dispatch.waste !== undefined && Number(value.dispatch.waste) > 100) {
                    dispatch.waste = "Supérieur à 100"
                }

                for (const idx in distributionCenterPriorityFieldNegative) {
                    const field = distributionCenterPriorityFieldNegative[idx]
                    if (value.dispatch[field] !== undefined &&
                        Number(value.dispatch[field]) < 0) {
                        dispatch[field] = "Négatif"
                    }
                }

                for (const idx in distributionCenterPriorityFieldGreaterThan) {
                    const fields = distributionCenterPriorityFieldGreaterThan[idx]
                    if (value.dispatch[fields.first] !== undefined &&
                        value.dispatch[fields.last] !== undefined &&
                        Number(value.dispatch[fields.first]) > Number(value.dispatch[fields.last])) {
                        dispatch[fields.first] = "Supérieur au max"
                        dispatch[fields.last] = "Inférieur au min"
                    }
                }
                return {dispatch: dispatch}
            })
        }
    }

    return ret
}

export const roleValidator = values => {
    const errors = {}

    if (!values.name) {
        errors.name = "Un nom est nécessaire"
    }

    if (!values.label) {
        errors.label = "Un label est nécessaire"
    }
    return errors
}

const EMAIL_REGEX = "^[a-z0-9][\\-_\\.\\+\\!\\#\\$\\%\\&\\'\\*\\/\\=\\?\\^\\`\\{\\|]{0,1}([a-z0-9][\\-_\\.\\+\\!\\#\\$\\%\\&\\'\\*\\/\\=\\?\\^\\`\\{\\|]{0,1})*[a-z0-9]@[a-z0-9][-\\.]{0,1}([a-z][-\\.]{0,1})*[a-z0-9]\\.[a-z0-9]{1,}([\\.\\-]{0,1}[a-z]){0,}[a-z0-9]{0,}$"
export const userValidator = values => {
    const errors = {}

    if (!values.email) {
        errors.email = "Un email est nécessaire"
    }
    else if (!values.email.match(EMAIL_REGEX)) {
        errors.email = "Adresse email invalide"
    }
    if (!values.password) {
        errors.password = "Un mot de passe est nécessaire"
    }
    else if (values.password.length < 8) {
        errors.password = "Le mot de passe doit contenir au moins 8 caractères"
    }
    return errors
}
