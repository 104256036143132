import React, { useState, Fragment, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import OrderFilterBloc from "./OrderFilterBloc"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import OrderSupplierBloc from "./OrderSupplierBloc"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "8px 15px 8px 43px"
    },
    filterBloc: {
        flex: "0 0 67px"
    },
    cardsBloc: {
        flex: "1 1 auto",
        maxHeight: "calc(100vh - 200px)",
        overflow: "scroll",
        marginTop: 31
    }
})

const OrderLeftPanel = (props) => {
    const {
        mode,
        suppliers,
        data,
        cart,
        actions,
        selectedCard,
        selectedOrder,
        isValidOrder
    } = props

    const classes = useStyles()
    
    const [filter, setFilter] = useState("")
    const [regex, setRegex] = useState(new RegExp("", "ig"))
    const [selectedSuppliers, setSelectedSuppliers] = useState(selectedOrder ? [{value: selectedOrder.supplier.objectId, label: selectedOrder.supplier.name}] : [])
    const [selectedProductTypes, setSelectedProductTypes] = useState([supplierItemTypes.RAW_MATERIAL.value, supplierItemTypes.SALABLE_PRODUCT.value, supplierItemTypes.PACKAGING_CONSUMABLE.value])
    
    useLayoutEffect(() => {
        setRegex(new RegExp(filter, "ig"))
    }, [filter])

    return (
        <div className={classes.container}>
            <div className={classes.filterBloc}>
                <OrderFilterBloc
                    mode={mode}
                    filter={filter}
                    handleFilterChange={(value) => setFilter(value)}
                    suppliers={suppliers}
                    selectedOrder={selectedOrder}
                    selectedSuppliers={selectedSuppliers}
                    handleSelectSuppliers={(values) => setSelectedSuppliers(values)}
                    selectedProductTypes={selectedProductTypes}
                    handleSelectProductTypes={setSelectedProductTypes}
                />
            </div>
            <div className={classes.cardsBloc}>
                {
                    data && Array.from(data).map(([key, values], i) => {
                        const currentSupplier = suppliers.find(el => el.objectId === key)
                        const showSupplier = selectedSuppliers.length === 0 || selectedSuppliers.find(el => el.value === key)
                        let finalValues = values.filter(el => selectedProductTypes.includes(el.type))
                        finalValues = finalValues.filter(el => el.name.match(regex) !== null && el.isActive)
                        
                        if (showSupplier && finalValues.length > 0){
                            if (currentSupplier){
                                return (
                                    <OrderSupplierBloc
                                        selectedOrder={selectedOrder}
                                        key={i}
                                        isValidOrder={isValidOrder}
                                        supplier={currentSupplier}
                                        supplierItems={finalValues}
                                        cart={cart}
                                        actions={actions}
                                        selectedCard={selectedCard}
                                    />
                                )
                            }
                        }
                        
                        return <Fragment key={i}></Fragment>
                    })
                }
            </div>
        </div>
    )
}

export default OrderLeftPanel