import React from "react"
import CloudinaryImage from "../Image/CloudinaryImage"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton } from "@mui/material"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
	root: {
		position: "relative",
	},
	deleteIconContainer: {
		backgroundColor: "#FFF",
		padding: 4,
		position: "absolute",
		right: "-12px",
		top: "-12px",
		cursor: "pointer",
		boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.1)"
	},
	clearIcon: {
		width: 12,
		height: 12,
		color: "#555555"
	}
})


const CommentsImageThumbnail = ({ imageId, onDelete }) => {

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<CloudinaryImage
				imageId={imageId}
				width={85}
				height={60}
			/>
			<IconButton
				className={classes.deleteIconContainer}
			>
				<ClearIcon onClick={onDelete} className={classes.clearIcon} />
			</IconButton>
		</div>
	)
}

export default CommentsImageThumbnail