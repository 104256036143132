import React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const Actions = {
	BL: "BL",
	FRIDGE: "FRIDGE"
}

const DispatchActions = (props) => {
	const {
		onClickBL,
		onClickFridge,
		brand
	} = props
	
	const [anchorEl, setAnchorEl] = React.useState(null)
	
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	
	const handleClose = () => {
		setAnchorEl(null)
	}
	
	const handleMenuItemClick = (event, action) => {
		switch (action) {
			case Actions.BL:
				onClickBL()
				break
			case Actions.FRIDGE:
				onClickFridge()
				break
			default:
			// do nothing
		}
		
		setAnchorEl(null)
	}
	
	const open = Boolean(anchorEl)
	const id = open ? "menu-popover" : undefined
	
	return <>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large">
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={id}
            open={Boolean(open)}
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <MenuItem onClick={event => handleMenuItemClick(event, Actions.BL)}>Télécharger les Bons de livraison</MenuItem>
            {
                brand === "FRIGONU" &&
                    <MenuItem onClick={event => handleMenuItemClick(event, Actions.FRIDGE)}>Télécharger les planogrammes des frigos</MenuItem>
            }
        </Menu>
    </>
}

export default DispatchActions