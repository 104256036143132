export const nutrionalValues = [
	{
		nutritionLabel: "Calories",
		nutritionValueKey: "calories",
		unity: " kcal",
		indent: false
	},
	{
		nutritionLabel: "Lipides",
		nutritionValueKey: "fat",
		unity: "g",
		indent: false
	},
	{
		nutritionLabel: "Acides gras saturés",
		nutritionValueKey: "saturatedFattyAcids",
		unity: "g",
		indent: true
	},
	{
		nutritionLabel: "Glucide",
		nutritionValueKey: "carbohydrates",
		unity: "g",
		indent: false
	},
	{
		nutritionLabel: "Sucres",
		nutritionValueKey: "sugar",
		unity: "g",
		indent: true
	},
	{
		nutritionLabel: "Protéines",
		nutritionValueKey: "proteins",
		unity: "g",
		indent: false
	},
	{
		nutritionLabel: "Fibres",
		nutritionValueKey: "fibers",
		unity: "g",
		indent: false
	},
	{
		nutritionLabel: "Sel",
		nutritionValueKey: "salt",
		unity: "g",
		indent: false,
		space: true
	},
	{
		nutritionLabel: "Charge glycémique",
		nutritionValueKey: "glycemicLoad",
		unity: "g",
		indent: false
	},
	{
		nutritionLabel: "Indice glycémique",
		nutritionValueKey: "IG",
		unity: "",
		indent: false,
		space: true
	},
	{
		nutritionLabel: "Note santé",
		nutritionValueKey: "healthyRate",
		unity: "",
		indent: false
	},
	{
		nutritionLabel: "Note social",
		nutritionValueKey: "socialRate",
		unity: "",
		indent: false,
		space: true
	},
	{
		nutritionLabel: "Pour 100g",
		nutritionValueKey: null,
		unity: "",
		indent: false
	},
	{
		nutritionLabel: "Calcium",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "calcium",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Cuivre",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "copper",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Fer",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "iron",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Magnesium",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "magnesium",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Manganese",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "manganese",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Phosphore",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "phosphorus",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Potassium",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "potassium",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Selenium",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "selenium",
		unity: "µg",
		indent: true
	},
	{
		nutritionLabel: "Zinc",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "zinc",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Vitamine D",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "vitaminD",
		unity: "µg",
		indent: true
	},
	{
		nutritionLabel: "Vitamine C",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "vitaminC",
		unity: "mg",
		indent: true
	},
	{
		nutritionLabel: "Vitamine A",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "vitaminA",
		unity: "µg ER",
		indent: true
	},
	{
		nutritionLabel: "Omega 6",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "omega6",
		unity: "g",
		indent: true
	},
	{
		nutritionLabel: "Omega 3",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "omega3",
		unity: "g",
		indent: true
	},
	{
		nutritionLabel: "Sucres ajoutés",
		nutritionValueKey: "per100",
		nutritionSecondValueKey: "sugarAdd",
		unity: "g",
		indent: true
	}
]