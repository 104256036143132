import React from "react"
import { useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"

import { showSupplierItem } from "../../actions/Supplier/supplierItems"

import { capitalizeFirstLetter } from "../../utils"

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    paddingBottom: 40,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: "20px 32px 16px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
  },
  singleValueContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
  },
  valueLabel: {
    color: COLORS.BLUEGREY_700,
    width: 200,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
  },
  supplierItemLink: {
    fontWeight: "bold",
    cursor: "pointer",
    color: COLORS.LIGHT_BLUE_SLIDER
  }
}))

const IngredientsSupplierItems = ({ supplierItems = [] }) => {

  const classes = useStyles()

  const dispatch = useDispatch()

  const _onSupplierItemClick = (id) => dispatch(showSupplierItem(id))

  if (!supplierItems || !Array.isArray(supplierItems) || !supplierItems.length) {
    return null
  }

  return <div className={classes.container}>
    <div className={classes.titleContainer}>
      <div className={classes.title}>Articles fournisseurs rattachés</div>
    </div>
    {supplierItems.map((supplierItem, index) => (
      <div key={index} className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Rattaché à l'article fournisseur</div>
        <div className={classes.supplierItemLink} onClick={() => _onSupplierItemClick(supplierItem.objectId)}>
          {capitalizeFirstLetter(supplierItem.name)}
        </div>
      </div>
    ))}
  </div>

}


export default IngredientsSupplierItems