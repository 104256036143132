import { Box, styled } from "@mui/material"

import { COLORS } from "../../utils"

export const StyledPlanningPageHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open"
})((props) => ({
  position: "fixed",
  backgroundColor: COLORS.WHITE,
  zIndex: 300,
  width: props.open ? "calc(100% - 239px)" : "calc(100% - 54.4px)"
}))
