import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"

import {
    editNews,
    saveSupplier,
    showSupplierTab,
    closeSupplierSnackBar,
    saveSupplierImage
} from "../../../actions/Supplier/suppliers"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import SupplierGeneral from "../../../components/Supplier/SupplierGeneral"
import CircularProgress from "@mui/material/CircularProgress"
import Header from "../../../components/Supplier/Header"
import { withCookies } from "react-cookie"
import { COLORS } from "../../../utils"
import _isEqual from "lodash/isEqual"
import ArticlesList from "../../../components/Supplier/ArticlesList"
import CommandResume from "../../../components/Supplier/CommandResume"
import InvoiceResume from "../../../components/Supplier/InvoiceResume"
import {
    CAN_UPDATE_SUPPLIER
} from "../../../utils/accessRights"
import SupplierPageHeader from "../../../components/Supplier/SupplierPageHeader"
import { getSelectedSupplierSelector } from "../../../reducers/Suppliers/Suppliers/suppliers"

const useStyles = makeStyles({
    container: {
        display: "block",
        overflow: "auto",
        minHeight: "100vh"
    },
    tabs: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
    title: {
        flexGrow: 1,
        color: COLORS.RED_RECEPTION
    },
    cancel: {
        marginRight: 15
    },
    recipeImage: {
        height: 64,
        marginRight: 15
    },
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    },
    width100: {
        "& .MuiButtonBase-root": {
            width: 162,
        },
        width: "100%",
    }
})

const Supplier = (props) => {
    const { params: { tab, id: supplierId } } = props
    const sites = useSelector(state => state.suppliers.sites, _isEqual)
    const jsonSupplier = useSelector(getSelectedSupplierSelector)
    const supplierItems = useSelector(state => state.suppliers.supplierItems, _isEqual)
    const supplierSnackBar = useSelector(state => state.suppliers.supplierSnackBar, _isEqual)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_SUPPLIER])

    const [tabValue, setTabValue] = useState(("articles" === tab) ? 3 : (("invoice" === tab) ? 2 : (("command" === tab) ? 1 : 0)))
    const [selectedSupplier, setSelectedSupplier] = useState(jsonSupplier)

    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        if (jsonSupplier !== selectedSupplier) {
            setSelectedSupplier(jsonSupplier)
        }
    }, [jsonSupplier])

    const _onTabChange = (_event, tabValue) => {
        const tabName = tabValue === 3 ?
            "articles" :
            tabValue === 2 ?
                "invoice" :
                tabValue === 1 ? "command" : "general"

        dispatch(showSupplierTab(tabName, supplierId))
        setTabValue(tabValue)
    }

    const _closeSnackbar = () => {
        dispatch(closeSupplierSnackBar(supplierSnackBar.type))
    }

    const _renderTabs = () => {
        return jsonSupplier
            ?
            <>
                {
                    0 === tabValue &&
                    <SupplierGeneral
                        jsonSupplier={jsonSupplier}
                        onEditNews={value => dispatch(editNews(jsonSupplier.objectId, value))}
                        onSaveSupplier={values => dispatch(saveSupplier(jsonSupplier.objectId, values))}
                        canEdit={canEdit}
                        sites={sites}
                    />
                }
                {
                    1 === tabValue &&
                    <CommandResume
                        jsonSupplier={jsonSupplier}
                        onEditNews={value => dispatch(editNews(jsonSupplier.objectId, value))}
                        onSaveSupplier={values => dispatch(saveSupplier(jsonSupplier.objectId, values))}
                        canEdit={canEdit}
                    />
                }
                {
                    2 === tabValue &&
                    <InvoiceResume
                        jsonSupplier={jsonSupplier}
                        onEditNews={value => dispatch(editNews(jsonSupplier.objectId, value))}
                        onSaveSupplier={values => dispatch(saveSupplier(jsonSupplier.objectId, values))}
                        canEdit={canEdit}
                    />
                }
                {
                    3 === tabValue &&
                    <ArticlesList
                        articles={supplierItems}
                    />
                }
            </>
            :
            <CircularProgress
                size={80}
                className={classes.circularProgress}
            />
    }

    const image = selectedSupplier ? selectedSupplier.image : null

    return (
        <div className={classes.container}>
            <SupplierPageHeader
                withBackButton
                title="Fournisseurs"
                subtitle={jsonSupplier?.name}
                fixed={false}
                icon={<PeopleOutlineIcon />}
            />
            <Header
                image={image}
                jsonSupplier={selectedSupplier}
                saveImage={img => dispatch(saveSupplierImage(img, jsonSupplier.objectId))}
                canEdit={canEdit}
            />
            <Paper className={classes.tabs}>
                <Tabs
                    className={classes.width100}
                    value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Général" />
                    <Tab label="Commande" />
                    <Tab label="Facturation" />
                    <Tab label={`Articles (${supplierItems.length})`} />
                </Tabs>
            </Paper>
            {_renderTabs()}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={supplierSnackBar.open}
                autoHideDuration={supplierSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={supplierSnackBar.type}
                    message={supplierSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default withCookies(Supplier)
