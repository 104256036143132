import { createMachineModel, updateMachineModel } from "../../parseManager/machines/machineModelsManager"
import { createMachineType, getMachineTypes, getMachineTypesById } from "../../parseManager/machines/machineTypesManager"
import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"

export function loadMachineTypes(filters, include) {
    return actionWithLoader(async (dispatch) => {
        const machineTypes = await getMachineTypes(filters, include)

        dispatch({
            type: "MACHINE_TYPES_LOADED",
            machineTypes
        })
    })
}

export function loadMachineModelsByType(params) {
    return actionWithLoader(async (dispatch) => {
        const id = params ? params.id : ""
        const machineType = await getMachineTypesById(id)

        dispatch({
            type: "MACHINE_TYPE_LOADED",
            machineType
        })
        dispatch({
            type: "MACHINE_MODELS_LOADED",
            machineModels: machineType.machineModels || []
        })
    })
}

export function addMachineTypes(values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machineTypes = state.machines.machineTypes

        const newMachineType = await createMachineType(values)
        const newMachineTypes = [newMachineType, ...machineTypes]

        dispatch({
            type: "MACHINE_TYPES_LOADED",
            machineTypes: newMachineTypes
        })
    })
}

export function addMachineModels(values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machineModels = state.machines.machineModels
        const machineType = state.machines.machineType
        const newMachineModel = await createMachineModel(values, machineType.objectId)
        const newMachineModels = [newMachineModel, ...machineModels]

        dispatch({
            type: "MACHINE_MODELS_LOADED",
            machineModels: newMachineModels
        })
    })
}

export function editMachineModel(id, values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machineModels = state.machines.machineModels

        const updatedMachineModel = await updateMachineModel(id, values)
        const oldMachineModels = machineModels.filter(machineModeItem => machineModeItem.objectId !== updatedMachineModel.objectId)
        const newMachineModels = [updatedMachineModel, ...oldMachineModels]

        dispatch({
            type: "MACHINE_MODELS_LOADED",
            machineModels: newMachineModels
        })
    })
}

export function onEnterMachineTypes(store) {
    return onEnter({
        store,
        actionThunk: loadMachineTypes
    })
}

export function onEnterSingleMachineType(store) {
    return onEnter({
        store,
        actionThunk: loadMachineModelsByType,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function showMachineTypes() {
    return push("/settings/production/machines")
}

export function showMachineType(id) {
    return push("/settings/production/machines/" + id + "/models")
}

export function showKitchenAreaMachines(id) {
    return push(`/settings/sites/site/kitchenArea/${id}/machines`)
}

export function showKitchenAreaMachinesEdit() {
    return push("/settings/sites/site/ateliers/UPK3lbT78C/kitchenArea/id01/edit")
    //replace ids by siteId & areaId
}