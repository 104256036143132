import React from "react"
import { makeStyles } from "@mui/styles"
import { orderMode } from "../../../utils/ordersUtils"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    cartTitle: {
        padding: "16px 23px",
        fontSize: 16,
        fontWeight: 700
    },
    updateTitleBloc: {
        backgroundColor: COLORS.ORDER_BLUE,
        padding: "20px 6px"
    },
    updateTitle: {
        display: "flex" 
    },
    titleName: {
        padding: "0px 8px",
        fontSize: 16,
        fontWeight: 700
    },
    titleNumber: {
        fontSize: 16,
        marginLeft: 8
    },
})

const OrderHeader = (props) => {
    const {
        mode,
        order
    } = props
    const classes = useStyles()

    return (
        <div className={mode === orderMode.UPDATE ? classes.updateTitleBloc : ""}>
            {
                mode === orderMode.CREATE &&
                    <Typography className={classes.cartTitle}>
                        Panier
                    </Typography>
            }
            {
                mode === orderMode.UPDATE &&
                    <div className={classes.updateTitle}>
                        <Typography className={classes.titleName}>
                            {`COMMANDE ${order ? order.supplier.name : ""}`}
                        </Typography>
                        <Typography className={classes.titleNumber}>
                            {`n° ${order ? order.orderNumber : ""}`}
                        </Typography>
                    </div>
            }
        </div>
    )
}

export default OrderHeader