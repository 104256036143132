import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import { emphasize } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import NoSsr from "@mui/material/NoSsr"
import TextField from "@mui/material/TextField"
import { grey, blueGrey } from "@mui/material/colors"
import { Autocomplete, CircularProgress } from "@mui/material"

import { COLORS } from "../../utils"
import { searchSupplierItemsByName } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "100%",
    paddingLeft: 10,
    outline: "none",
    borderRadius: 5,
    border: `1px solid ${grey[400]}`,
    backgroundColor: COLORS.WHITE,
    color: blueGrey[800],
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  divider: {
    height: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
      fontSize: 16
    },
    "& .MuiOutlinedInput-input": {
      border: "none",
    },
    "& .MuiOutlinedInput-input:focus": {
      border: "none",
    }
  }
})

const IngredientAutocompleteNew = (props) => {
  const {
    onEnterValidation,
    value,
    classes, name, onFocus, onBlur,
    onChange: onInputChange, ingredientIndex,
    showNoCommercialName
  } = props

  const [selectedValue, setSelectedValue] = useState(null)
  const [enterPress, setEnterPress] = useState(false)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState("")

  const loading = open && inputValue.length > 1 && options.length === 0

  useEffect(() => {
    if (enterPress === true) {
      onEnterValidation(selectedValue)
      setEnterPress(false)
    }
  }, [enterPress])

  useEffect(() => {
    if (!value) return
    if (selectedValue) return
    setSelectedValue({
      id: value.id,
      label: value.name
    })
  }, [value, selectedValue]) 

  useEffect(() => {
    let active = true

    if (inputValue === "") {
      setOptions([])
      return
    }

    (async () => {
      let result = []

      if (active) {
        const supplierItems = await searchSupplierItemsByName(
          inputValue,
          showNoCommercialName
        )

        result = supplierItems.map((supplierItem) => ({
          id: supplierItem.id,
          label: supplierItem.get("name").toLowerCase(),
          supplierItem: supplierItem,
        }))
      }

      setOptions(result)
    })()

    return () => {
      active = false
    }
  }, [showNoCommercialName, selectedValue, inputValue])

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleChange = (_, value) => {
    setSelectedValue(value)
    onInputChange && onInputChange(ingredientIndex, value)
  }

  const onKeyDown = (event) => {
    if (event && event.keyCode === 13) {
      event.preventDefault()
    }
  }

  const _stopPropagation = (event) => event && event.stopPropagation()

  return (
    <div className={classes.root}>
      <NoSsr>
        <Autocomplete
          name={name}
          value={selectedValue}
          onChange={handleChange}
          getOptionLabel={(option) => option.label}
          id="ingredient-autocomplete"
          options={options}
          open={open}
          onOpen={() =>  setOpen(true)}
          onClose={() => setOpen(false)}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          className={classes.textField}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue)
          }}
          autoComplete
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={_stopPropagation}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={12} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              
            />
          )}
        />
      </NoSsr>
    </div>
  )
}

IngredientAutocompleteNew.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(
  IngredientAutocompleteNew
)
