import moment from "moment"
import { TableDateFormat } from "../components/table-utils/utils"

export const createChefDataTable = (chef) => {
    return {
        objectId: chef.objectId,
        firstName: chef.firstName,
        lastName: chef.lastName,
        createdAt: moment(chef.createdAt).format(TableDateFormat),
        updatedAt: moment(chef.updatedAt).format(TableDateFormat)
    }
}
