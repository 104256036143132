import React from "react"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import { TextField } from "@mui/material"
import UnhencedSelect from "../Filter/filterInputs/UnhencedSelect"
import UnhencedTextField from "../Filter/filterInputs/UnhencedTextField"
import { Stack, InputAdornment } from "@mui/material"
import { COLORS } from "../../utils"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

export const additionalFiltersFormId = "additionalFiltersForm"

const useStyles = makeStyles(() => ({
  menuPaper: {
    borderRadius: "0 0 4px 4px"
  },
  input: {
    "& ::placeholder": {
      color: COLORS.LABEL_GREY,
      opacity: 1
    },
    "& .MuiInputBase-root": {
      "&:hover": {
        "&::before": {
          borderBottom: "2px solid " + COLORS.LABEL_GREY
        }
      },
      "&::after": {
        borderBottom: "2px solid " + COLORS.LABEL_GREY
      }
    }
  },
  select: {
    border: "none",
    outline: "none",
    "&::after": {
      borderBottom: "2px solid " + COLORS.LABEL_GREY
    }
  }
}))

const AdditionalFiltersForm = ({ initialFilters, handleConfirm, initialValues = {} }) => {

  const classes = useStyles()

  const _renderField = ({ filter, filterConfig, values, errors, handleChange, setFieldValue, index }) => {
    switch (filterConfig.type) {
      case "select":
        return (
          <UnhencedSelect
            key={index}
            variant="standard"
            fullWidth
            error={!!errors[filter.key]}
            name={filter.key}
            label={filter.label}
            value={filterConfig?.multiple ? (values[filter.key] ? [...values[filter.key]] : []) : (values[filter.key] || "")}
            onChange={handleChange}
            classes={{ root: classes.select }}
            multiple={!!filterConfig?.multiple}
            type={filterConfig?.multiple ? "select-multiple" : "select"}
            options={filterConfig.options}
            withSearch={!!filterConfig?.withSearch}
            MenuProps={{
              classes: {
                paper: classes.menuPaper
              }
            }}
            render={value => filterConfig.render ? filterConfig.render(value) : value}
          />
        )
      case "date":
        return (
          <DatePicker
            key={index}
            label={filter.label}
            inputFormat="DD/MM/YYYY"
            variant="inline"
            inputVariant="outlined"
            value={values[filter.key] || dayjs().valueOf()}
            onChange={(e) => {
              setFieldValue(filter.key, e)
            }}
            componentsProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: "99.5% " }}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  placeholder: filterConfig.placeholder || "Rechercher"
                }}
                classes={{ root: classes.input }}
              />
            )}
            fullWidth
          />
        )
      case "dateRange":
        return (
          <Stack spacing={2} key={index}>
            <DatePicker
              label={filter.label}
              inputFormat="DD/MM/YYYY"
              variant="inline"
              inputVariant="outlined"
              value={values[filter.key]?.start || null} 
              name={filter.key + ".start"}
              onChange={(e) => {
                setFieldValue(filter.key + ".start", e)
                setFieldValue(filter.key +".isRange", true)
              }}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "99.5% " }}
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: filterConfig.placeholder || "Rechercher"
                  }}
                  classes={{ root: classes.input }}
                />
              )}
              fullWidth
            />
            <DatePicker
              label={filter.label}
              inputFormat="DD/MM/YYYY"
              variant="inline"
              inputVariant="outlined"
              value={values[filter.key]?.end || null}
              name={filter.key + ".end"}
              onChange={(e) => {
                setFieldValue(filter.key + ".end", e)
                setFieldValue(filter.key +".isRange", true)
              }}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "99.5% " }}
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: filterConfig.placeholder || "Rechercher"
                  }}
                  classes={{ root: classes.input }}
                />
              )}
              fullWidth
            />
          </Stack>
        )
      default:
        return (
          <UnhencedTextField
            key={index}
            label={filter.label}
            placeholder={filterConfig.placeholder || "Rechercher"}
            variant="standard"
            value={values[filter.key] || ""}
            onChange={handleChange}
            fullWidth
            classes={{ root: classes.input }}
            InputProps={{
              startAdornment: filterConfig.inputIcon
                ? (
                  <InputAdornment position="start">
                    {filterConfig.inputIcon}
                  </InputAdornment>
                ) : null
            }}
          />
        )
    }
  }

  if (!initialFilters || !initialFilters.length) return null

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleConfirm}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue
      }) => {
        return (
          <Form id={additionalFiltersFormId} sx={{ marginTop: "24px" }}>
            <Stack spacing={3}>
              {initialFilters.map((filter, index) => _renderField({ filter, filterConfig: filter.filter, values, errors, handleChange, setFieldValue, index }))}
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AdditionalFiltersForm