import React, {useRef, useState} from "react"
import {Formik, Form, ErrorMessage} from "formik"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  TextField,
  FormHelperText,
  styled
} from "@mui/material"
import {DateSchema} from "../../utils/yupValidators"
import {DatePicker} from "@mui/x-date-pickers"
import {COLORS, getTimezoneDate} from "../../utils"
import FormikAutocompleteField from "../../components/form/FormikAutocompleteField"
import {useDispatch} from "react-redux"
import {clearSearchRecipesAutocomplete} from "../../actions/Recipe/recipes"
import {searchRecipes} from "../../parseManager/recipe/parseRecipeManager"

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

const sx = {
  title: {
    pb: 0,
    fontSize: 16,
    color: COLORS.BLACK_500,
    fontWeight: 400,
    lineHeight: 1.375
  },
}

const PSEGenerationForOneRecipeDialog = ({ onClose, open, onConfirm, buttonText, title, inputLabel }) => {
  const [disabledButton, setDisabledButton] = useState(true)
  const [productionDate, setProductionDate] = useState(null)
  const [searchRecipe, setSearchRecipe] = useState("")
  const [searchKeyValue, setSearchKeyValue] = useState("")
  const [recipesAutocompleteResult, setRecipesAutocompleteResult] = useState([])
  const formikRef = useRef(null)
  const dispatch = useDispatch()

  const handleConfirm = () => {
    if (!formikRef.current) return
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
  }

  const handleCancel = () => {
    onClose()
  }

  const getOptionLabel = (recipe) => {
    const name = recipe.name ? recipe.name.toLowerCase() : ""
    return recipe.uniqueCode ? recipe.uniqueCode + " - " + name : name
  }

  const formatOptions = (recipes) => {
    return recipes.map((recipe) => ({
      value: recipe.objectId,
      data: recipe
    }))
  }

  const handleSearchRecipes = async (search, currentProductionDate = null) => {
    setSearchRecipe(search)
    const result = await searchRecipes(search, true, (currentProductionDate || productionDate))
    setRecipesAutocompleteResult(result)
  }

  const handleClearProductSearch = () => {
    dispatch(clearSearchRecipesAutocomplete())
  }

  const handleOnChangeValue = () => {
    if (productionDate) {
      setDisabledButton(false)
    }
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={sx.title}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Formik
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={DateSchema}
          initialValues={{ date: null }}
          validateOnChange
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Stack spacing={1} >
                  <DatePicker
                    showToolbar={false}
                    value={values.date}
                    name="date"
                    inputFormat="DD/MM/YY"
                    label={inputLabel}
                    disablePast
                    renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.date} InputLabelProps={{ shrink: true }} />}
                    onChange={(date) => {
                      const productionDate = getTimezoneDate(date)
                      setFieldValue("date", productionDate)
                      setProductionDate(productionDate)
                      handleSearchRecipes(searchRecipe, productionDate)
                      /** Start Reset form as we have changed the production Date **/
                      setDisabledButton(true)
                      setSearchRecipe("")
                      setSearchKeyValue(Math.random)
                      handleClearProductSearch()
                      setFieldValue("recipe", "")
                      /** End of reset form as we have changed the production Date **/
                    }}
                  />
                  {errors.date && (
                    <FormHelperText error sx={{ my: 1 }}>
                      {errors.date}
                    </FormHelperText>
                  )}
                </Stack>
                <Stack spacing={1} sx={{ mt: 3 }}>
                  <FormikAutocompleteField
                    name="recipe"
                    label="Recette"
                    setFieldValue={setFieldValue}
                    options={formatOptions(recipesAutocompleteResult)}
                    getOptionLabel={getOptionLabel}
                    onSearch={handleSearchRecipes}
                    onBlur={handleClearProductSearch}
                    key={searchKeyValue}
                    onChange={handleOnChangeValue}
                  />
                  <ErrorMessage
                    name="recipe"
                    render={(message) => (
                      <FormHelperText error>
                        {message}
                      </FormHelperText>
                    )}
                  />
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={disabledButton}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default PSEGenerationForOneRecipeDialog
