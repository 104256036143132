import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useDispatch } from "react-redux"
import SettingsTabs from "../SettingsTabs"
import { showFamiliesClassificationsIngredients } from "../../../actions/Ingredients/Classifications/Families/Families"
import { showGroupsClassificationsIngredients } from "../../../actions/Ingredients/Classifications/Groups/Groups"
import { showAllergensClassificationsIngredients } from "../../../actions/Ingredients/Classifications/Allergens/Allergens"
import TabsContainer from "../Tabs/TabsContainer"
import TabPanel from "../Tabs/TabPanel"

const IngredientsTabs = (props) => {
    const {
        tabValue,
        children
    } = props
    
    const dispatch = useDispatch()

    const _onTabChange = (event, tabValue) => {
        if (tabValue === "families") {
            dispatch(showFamiliesClassificationsIngredients())
            return
        }
        if (tabValue === "groups") {
            dispatch(showGroupsClassificationsIngredients())
            return
        }
        if (tabValue === "allergens") {
            dispatch(showAllergensClassificationsIngredients())
        }
    }
    
    return (
        <SettingsTabs tabValue="ingredients">
            <TabsContainer>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                    sx={{
                        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
                        borderBottom: "1px solid #D8D8D8",
                    }}
                >
                    <Tab value="families" label="Familles d'ingrédients" />
                    <Tab value="groups" label="Groupe d'ingrédients" />
                    <Tab value="allergens" label="Allergènes" />
                </Tabs>
            </TabsContainer>
            <TabPanel top={20}>
                {children}
            </TabPanel>
        </SettingsTabs>
    )
}

export default IngredientsTabs