import React, { useMemo } from "react"

import { makeStyles } from "@mui/styles"
import { Box, Button, Stack, Grid } from "@mui/material"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"

import GenericTopBarHeader from "../GenericTopBarHeader"
import PageHeaderTitle from "../PageHeaderTitle"
import MealPlannerMenu from "../MealPlanner/MealPlannerMenu"
import WeeklyMealPlannerCart from "./WeeklyMealPlannerCart"

import moment from "moment"
import { capitalizeFirstLetter, COLORS } from "../../utils"

const styles = makeStyles(() => ({
    weeklyMpRoot: {
        backgroundColor: COLORS.BACKGROUND_GREY,
        height: "100%",
    },
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    }
}))


const WeeklyMealPlanner = ({
    date,
    dateRange,
    brand = null,
    actions = {},
    filters = {},
    modalContent,
    selection,
    productionSchemas,
    currentProductionSchema,
    mealsSelectionIds
}) => {

    const classes = styles()

    const { selectProductionSchema, clickProduct, onSave, onCancel } = actions
    const { productTypeOptions, productionSchemasOptions } = filters

    const subtitle = useMemo(() => {
        if (dateRange.start && dateRange.end) {
            return "Semaine du " + moment(dateRange.start).format("DD/MM/YYYY") + " au " + moment(dateRange.end).format("DD/MM/YYYY")
        }
        return ""
    }, [dateRange])

    return (
        <>
            <Grid container spacing={0} className={classes.weeklyMpRoot}>
                {/** -----------------------------------------  HEADER ---------------------------------------- */}
                <Grid item xs={12}>
                    <Box sx={{ height: 64 }}>
                        <GenericTopBarHeader
                            title={
                                <PageHeaderTitle
                                    title={"Carte"}
                                    subtitle={capitalizeFirstLetter(subtitle)}
                                    color="#7900D9"
                                    icon={<ShoppingBasketIcon />}
                                />
                            }
                            rightAction={
                                <Stack direction="row" spacing={4}>
                                    <Button
                                        onClick={onCancel}>
                                        Annuler
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={onSave}>
                                        Enregistrer
                                    </Button>
                                </Stack>
                            }
                        />
                    </Box>
                </Grid>
                {/** ----------------------------------------  MEAL PLANNER ---------------------------------------- */}
                <Grid item xs={9} sx={{ height: "calc(100% - 64px)" }}>
                    <MealPlannerMenu
                        onClick={clickProduct}
                        targetBrand={brand}
                        date={date}
                        productionSchemasOptions={productionSchemasOptions}
                        productTypeOptions={productTypeOptions}
                        selectedProductIds={mealsSelectionIds}
                        selectProductionSchema={selectProductionSchema}
                        currentProductionSchema={currentProductionSchema}
                        isWeekly
                    />
                </Grid>
                {/** ---------------------------------------------- CART ------------------------------------------- */}
                <Grid item xs={3} sx={{ height: "calc(100% - 64px)" }}>
                    <WeeklyMealPlannerCart
                        productionSchemas={productionSchemas}
                        selection={selection}
                        removeProduct={clickProduct} />
                </Grid>
            </Grid>
            {/** -----------------------------------------  ERROR MODALS ----------------------------------------- */}
            {modalContent && modalContent}
        </>
    )
}


export default WeeklyMealPlanner