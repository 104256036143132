import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
	card: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 24,
		alignSelf: "stretch",
		borderRadius: 8,
		border: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
	},
})

const OrderCard = ({ children }) => {
	const classes = useStyles()
	return (
		<div className={classes.card}>
			{children}
		</div>
	)
}

export default OrderCard