import React from "react"
import { tva } from "../../utils/recipes"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const TvaSelect = (props) => {
	const {classes, ...otherProps} = props
	
	return (
		<FormControl className={classes.typeFormControl} variant="standard">
			<InputLabel>Tva</InputLabel>
			<Select
				variant="standard"
				name="tva"
				label="tva"
				id="tva"
				fullWidth
				{...otherProps}
			>
				{
					tva.map((tva, index) =>
						<MenuItem key={index} value={tva}>{tva}</MenuItem>
					)
				}
			</Select>
		</FormControl>
	)
}

export default TvaSelect
