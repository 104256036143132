import LotCardFooterContent from "./LotCardFooterContent"

import React from "react"

const SelectableAlphabetCardFooterContent = ({
  document,
  mode,
  collection,
  selected,
  footerClassName = null
}) => {

  const isLotOrSecondaryDLC = collection === "Lot" || collection === "SecondaryDLC"

  if (isLotOrSecondaryDLC) {
    return (
    <LotCardFooterContent
      document={document}
      collection={collection}
      mode={mode}
      selected={selected}
      footerClassName={footerClassName}
    />)
  }

  return null

}

export default SelectableAlphabetCardFooterContent