import React from "react"
import { Formik, Form } from "formik"
import { StyledContainer, StyledSubStatusTitle, StyledErrorMessage } from "../styledComponents"
import { TextField } from "@mui/material"
import { Stack, Box, Typography, styled } from "@mui/material"
import { ProductionStepExecutionWeightValidationSchema } from "../../../utils/yupValidators"
import { makeStyles } from "@mui/styles"
import { roundNumber } from "../../../utils"

const useStyles = makeStyles({
	input: {
		fontSize: 40,
		textAlign: "center",
	},
	inputWrapper: {
		width: 68
	},
})

const StyledLabel = styled(Typography)({
	color: "#262626",
	textAlign: "center",
	fontSize: 20,
	fontWeight: 400,
	lineHeight: "20px",
})

const StyledWeightLabel = styled(Typography)({
	color: "#7C7C7C",
	textAlign: "center",
	fontSize: "16px",
	fontWeight: 400,
	lineHeight: "16px"
})

const StyledWeightContainer = styled(Box)({
	height: 66.5,
	width: 68,
	padding: "4px 0px 5px 0px",
	fontSize: 40,
	textAlign: "center"
})

export const productionStepExecutionWeightValidationFormId = "productionStepExecutionWeightValidationForm"

const ProductionStepExecutionWeightValidation = ({ productionStepExecution, handleSubmit }) => {

	const classes = useStyles()

	return (
		<Formik
			onSubmit={handleSubmit}
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={{
				netWeight: null
			}}
			validationSchema={ProductionStepExecutionWeightValidationSchema}>
			{({ values, errors, handleChange }) => {
				return (
					<Form id={productionStepExecutionWeightValidationFormId}>
						<Stack gap={2}>
							<StyledSubStatusTitle>Pesez la préparation et saisissez son poids.</StyledSubStatusTitle>
							<StyledContainer>
								<Stack
									sx={{ p: 3 }}
									gap={3}>
									<Stack
										direction="row"
										gap={1}>
										<img src={"/img/recipes/weight.svg"} />
										<Typography sx={{
											color: "#262626",
											fontSize: 14,
											fontWeight: 500,
											lineHeight: "22px"
										}}>
											Poids
										</Typography>
									</Stack>
									<Stack direction="row" gap={"48px"} paddingTop={4} paddingBottom={4} justifyContent="center">
										<Stack alignItems="center" gap={2}>
											<StyledLabel>Poids final prévu</StyledLabel>
											<StyledWeightContainer>
												<Typography className={classes.input}>
													{roundNumber((productionStepExecution.theoreticalNetWeight || 0), 2)}
												</Typography>
											</StyledWeightContainer>
											<StyledWeightLabel>Kg</StyledWeightLabel>
										</Stack>
										<Box sx={{
											width: "1px",
											backgroundColor: "#F0F0F0"
										}}></Box>
										<Stack alignItems="center" gap={2}>
											<StyledLabel>Poids final pesé</StyledLabel>
											<TextField
												variant="standard"
												name="netWeight"
												inputProps={{
													className: classes.input,
													type: "number",
													pattern: "[0-9]*",
												}}
												className={classes.inputWrapper}
												value={values.netWeight}
												onChange={handleChange}
												type="number"
												placeholder={0}
												onWheel={(e) => e.target?.blur()}
												error={errors.netWeight}
											/>
											<StyledWeightLabel>Kg</StyledWeightLabel>
										</Stack>
									</Stack>
								</Stack>
							</StyledContainer>
							{errors.netWeight && (
								<StyledErrorMessage>{errors.netWeight}</StyledErrorMessage>
							)}
						</Stack>
					</Form>
				)
			}}
		</Formik>
	)

}

export default ProductionStepExecutionWeightValidation