import React, { useState, useLayoutEffect } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useDispatch, useSelector} from "react-redux"
import { Box } from "@mui/material"

import ReceptionSnackBar from "../../../components/Reception/ReceptionSnackBar"
import { closeReceptionGapSnackBar } from "../../../actions/Reception/receptionGap"
import HelpButton from "../../../components/HelpButton"
import helpLinks from "../../../utils/HelpLinks"
import SupervisionsTabs from "../SupervisionsTabs"
import ReceptionGapTable from "./ReceptionGapTable"

const sx = {
	tab: {
		outline: "none"
	},
	divider: {
		border: "1px solid #e8e8e8",
		width: "100%"
	},
}

const ReceptionGap = () => {
	const dispatch = useDispatch()

	const receptionGapUncheckedData = useSelector(state => state.receptionGap.receptionGapUncheckedData)
	const receptionGapCheckedData = useSelector(state => state.receptionGap.receptionGapCheckedData)
	const receptionGapSnackBar = useSelector(state => state.receptionGap.receptionGapSnackBar)

	const [tabValue, setTabValue] = useState(0)

	useLayoutEffect(() => {
		document.title = "Ecarts & Casse"

		return () => {
			document.title = "KFC"
		}
	}, [])

	const onTabChange = (event, tabValue) => {
		setTabValue(tabValue)
	}

	return (
		<SupervisionsTabs tabValue="receptionGap">
			<Box className="flexCenter flex1" sx={sx.tab}>
				<Tabs
					value={tabValue}
					onChange={onTabChange}
					indicatorColor="primary"
					textColor="primary"
					centered
				>
					<Tab label="À TRAITER"/>
					<Tab label="traité"/>
				</Tabs>
				<Box sx={sx.divider} />
				{tabValue === 0 && (
					<ReceptionGapTable
						data={receptionGapUncheckedData}
						tableType="TO_CHECK"
					/>
				)}
				{tabValue === 1 && (
					<ReceptionGapTable
						data={receptionGapCheckedData}
					/>
				)}
			</Box>

			{/* Error snackbar */}
			<ReceptionSnackBar data={receptionGapSnackBar} onClose={() => dispatch(closeReceptionGapSnackBar())} />
			<HelpButton link={helpLinks.hub}/>
		</SupervisionsTabs>
	)
}


export default ReceptionGap