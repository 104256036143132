import React from "react"
import { makeStyles } from "@mui/styles"
import AddLotBloc from "../../../components/Order/Lot/AddLotBloc"
import { Box } from "@mui/material"

const useStyles = makeStyles({
	error: {
		color: "#F44259",
		marginTop: 16
	}
})

const SupplierItemLotsBottomBlock = (props) => {
	const {
		quantityLeft,
		actions,
		orderSupplierItem,
		stockZones,
		receptionDate,
		errorMessageShown
	} = props

	const classes = useStyles()

	if (quantityLeft === 0) {
		return null
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "24px 24px 18px 24px",
					border: errorMessageShown ? "1px solid #F44259" : "1px solid #E6E6E6"
				}}>
				<AddLotBloc
					quantityLeft={quantityLeft}
					orderSupplierItem={orderSupplierItem}
					stockZones={stockZones}
					onCreateLot={actions}
					receptionDate={receptionDate}
				/>
			</Box>
			{errorMessageShown && <p className={classes.error}>Veuillez créer un ou plusieurs lots comprenant la quantité restante</p>}
		</>
	)
}

export default SupplierItemLotsBottomBlock