import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  subContentBloc: {
    composes: "flexRow",
    justifyContent: "center",
    marginTop: 43,
    marginBottom: 43,
  },
  subContentBlocTitle: {
    color: COLORS.DARK_GREY,
    fontSize: 20,
    lineHeight: "100%",
    textTransform: "uppercase",
  },
  contentLeftBlocValue: {
    fontSize: 70,
    color: COLORS.BLACK,
    lineHeight: "35px",
    marginTop: 60,
  },
  verticalDivider: {
    border: "1px solid #e8e8e8",
    height: 100,
    width: 1,
    marginLeft: 40,
    marginRight: 40,
  },
  contentRightBlocInput: {
    marginTop: 15,
  },
  formControl: {
    width: 200,
  },
  contentRightBlocTextField: {
    fontSize: 70,
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  contentRightBlocTextFieldError: {
    composes: "$contentRightBlocTextField",
    color: COLORS.RED,
  },
  contentRightBlocTextFieldSuccess: {
    composes: "$contentRightBlocTextField",
    color: COLORS.PRIMARY_COLOR,
  },
  empty: {
    paddingRight: 30,
  },
}))

const InputBlock = (props) => {
  const {
    inputRef,
    onKeyDown,
    onInputValueChange,
    inputValue,
    wantedInput,
    firstPage,
  } = props

  const classes = useStyles()

  return (
    <div className={classes.subContentBloc}>
      <div>
        <div>
          <span className={classes.subContentBlocTitle}>QUANTITÉ ATTENDUE</span>
        </div>
        <div className={classes.contentLeftBlocValue}>
          <span>{wantedInput}</span>
        </div>
      </div>
      <div className={classes.verticalDivider} />
      <div>
        <div>
          <span className={classes.subContentBlocTitle}>
            {firstPage ? "Quantité réceptionnée" : "quantité allotie"}
          </span>
        </div>
        <div className={classes.contentRightBlocInput}>
          <FormControl
            margin="normal"
            classes={{ root: classes.formControl }}
            required
          >
            <TextField
              variant="standard"
              name="realQuantity"
              type="number"
              InputProps={{
                placeholder: "  0",
                type: "number",
                pattern: "[0-9]*",
                className: clsx(
                  parseFloat(inputValue) === wantedInput
                    ? classes.contentRightBlocTextFieldSuccess
                    : inputValue
                    ? classes.contentRightBlocTextFieldError
                    : classes.contentRightBlocTextField,
                  "" === inputValue ? classes.empty : ""
                ),
              }}
              autoFocus
              value={inputValue}
              onChange={onInputValueChange}
              onKeyDown={onKeyDown}
              inputRef={inputRef}
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default InputBlock
