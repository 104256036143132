import { escapeRegExp } from "lodash"
import Parse from "parse"
import { SUCY_ID, Site } from "../site/parseSiteManager"

export const KitchenArea = Parse.Object.extend("KitchenArea")

export const getKitchenAreaById = async (id, toJson = true) => {
  try {
      const kitchenArea = await new Parse.Query(KitchenArea)
          .equalTo("objectId", id)
          .first()

      return toJson ? kitchenArea.toJSON() : kitchenArea
  }
  catch (e) {
      console.error("parseManager.kitchenAreasManager.getKitchenAreaById error : ", e)
      return Promise.reject(e) 
  }
}

export const createMachineType = async (values, toJSON = true) => {
    try {
        const kitchenArea = new KitchenArea()

        kitchenArea.set("name", values.name)

        const newKitchenArea = await kitchenArea.save()
        return toJSON ? newKitchenArea.toJSON() : newKitchenArea
    }
    catch (e) {
      console.error("parseManager.kitchenAreasManager.createMachineType error : ", e)
      return Promise.reject(e)
    }
}

export const getKitchenArea = async (filters, toJson = true) => {
    try {
        const query = await new Parse.Query(KitchenArea)

        if (filters) {
            if (filters.search) {
                const regex = new RegExp(escapeRegExp(filters.search), "ig")
                query.matches("name", regex)
            }
        }
        const kitchenAreas = await query.find()

        return toJson ? kitchenAreas.map(kitchenArea => kitchenArea.toJSON())  : kitchenAreas
    }
    catch (e) {
        console.error("parseManager.machine.kitchenAreasManager.getKitchenArea error : ", e)
        return Promise.reject(e) 
    }
}

export const getKitchenAreaBySiteId = async (siteId = SUCY_ID, toJson = true) => {
    try {
        const site = await new Parse.Query(Site)
            .equalTo("objectId", siteId)
            .include("kitchenAreas.kitchenArea")
            .select("kitchenAreas")
            .first()

        const kitchenAreas = site.has("kitchenAreas")
            ? site.get("kitchenAreas").map(kitchenArea => toJson ? kitchenArea.kitchenArea.toJSON() : kitchenArea.kitchenArea)
            : []
  
        return kitchenAreas
    }
    catch (e) {
        console.error("parseManager.kitchenAreasManager.getKitchenAreaBySiteId error : ", e)
        return Promise.reject(e) 
    }
  }