import { createData, getFilterValues } from "../../utils/searchAdvancedUtils"

const defaultState = {
    data: [],
    leftFilterValues: {},
    selectedRecipe: null,
    productsTags: [],
    suppliers: [],
    packagings: [],
    searchCount: 0
}

export default function (state = defaultState, action) {
    let data, leftFilterValues
    
    switch (action.type) {
        case "ADVANCED_SEARCH_DATA_LOADED":
            data = []
            leftFilterValues = getFilterValues(
                data,
                action.productsTags,
                action.suppliers,
                getPackagings(action.packagings),
                getSubPackagings(action.packagings),
                getReusablePackagings(action.packagings),
                getReusableSubPackagings(action.packagings),
                action.priceRange,
                action.gestersRange,
                action.portionPerPlateRange,
                action.foodcostRange,
                action.foodcostPCTRange,
                action.grossWeightRange,
                action.netWeightRange,
                action.dlcRange,
                action.ingredientsComplexityRange,
                action.fibersRange,
                action.saltRange,
                action.proteinsRange,
                action.sugarRange,
                action.saturatedFattyAcidsRange,
                action.caloriesRange,
                action.carbohydratesRange,
                action.fatRange,
                action.carbonFootPrintRange,
                action.organicIngredientsRateRange,
                action.frenchIngredientsRateRange,
                action.certifiedIngredientsRateRange,
                action.seasonalIngredientsRateRange
            )
            
            return {
                ...state,
                data,
                leftFilterValues,
                productsTags: action.productsTags,
                suppliers: action.suppliers,
                packagings: action.packagings,
                priceRange: action.priceRange,
                gestersRange: action.gestersRange,
                portionPerPlateRange: action.portionPerPlateRange,
                foodcostRange: action.foodcostRange,
                foodcostPCTRange: action.foodcostPCTRange,
                grossWeightRange: action.grossWeightRange,
                netWeightRange: action.netWeightRange,
                dlcRange: action.dlcRange,
                ingredientsComplexityRange: action.ingredientsComplexityRange,
                fibersRange: action.fibersRange,
                seasonalIngredientsRateRange: action.seasonalIngredientsRateRange,
                certifiedIngredientsRateRange: action.certifiedIngredientsRateRange,
                frenchIngredientsRateRange: action.frenchIngredientsRateRange,
                organicIngredientsRateRange: action.organicIngredientsRateRange,
                carbonFootPrintRange: action.carbonFootPrintRange,
                fatRange: action.fatRange,
                carbohydratesRange: action.carbohydratesRange,
                caloriesRange: action.caloriesRange,
                saturatedFattyAcidsRange: action.saturatedFattyAcidsRange,
                sugarRange: action.sugarRange,
                proteinsRange: action.proteinsRange,
                saltRange: action.saltRange
            }
        case "FILTERED_ADVANCED_SEARCH_DATA_LOADED":
            data = [...action.recipes, ...action.subcontractorsProducts].map(createData)
            leftFilterValues = getFilterValues(
                data,
                state.productsTags,
                state.suppliers,
                getPackagings(state.packagings),
                getSubPackagings(state.packagings),
                getReusablePackagings(state.packagings),
                getReusableSubPackagings(state.packagings),
                state.priceRange,
                state.gestersRange,
                state.portionPerPlateRange,
                state.foodcostRange,
                state.foodcostPCTRange,
                state.grossWeightRange,
                state.netWeightRange,
                state.dlcRange,
                state.ingredientsComplexityRange,
                state.fibersRange,
                state.saltRange,
                state.proteinsRange,
                state.sugarRange,
                state.saturatedFattyAcidsRange,
                state.caloriesRange,
                state.carbohydratesRange,
                state.fatRange,
                state.carbonFootPrintRange,
                state.organicIngredientsRateRange,
                state.frenchIngredientsRateRange,
                state.certifiedIngredientsRateRange,
                state.seasonalIngredientsRateRange
            )
            
            return {
                ...state,
                data,
                leftFilterValues,
                searchCount: state.searchCount + 1
            }
        case "SEARCH_CSV_CREATED":
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}


export function getPackagings(packagings) {
    return (packagings || []).filter(packaging => packaging.type === "1" && !packaging.reusable)
}

export function getSubPackagings(packagings) {
    return (packagings || []).filter(packaging =>  packaging.type === "2" && !packaging.reusable)
}

export function getReusablePackagings(packagings) {
    return (packagings || []).filter(packaging =>  packaging.type === "1" && packaging.reusable)
}

export function getReusableSubPackagings(packagings) {
    return (packagings || []).filter(packaging =>  packaging.type === "2" && packaging.reusable)
}