import React from "react"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import Snackbar from "@mui/material/Snackbar"
import { useDispatch } from "react-redux"
import { closeProductionSubstitutionSnackbar } from "../../actions/ProductionSubstitutions/ProductionSubstitutions"

export const ProductionSubstitutionSnackbar = ({ snackbar }) => {
  const dispatch = useDispatch()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={snackbar.open}
      autoHideDuration={snackbar.duration}
      onClose={() => dispatch(closeProductionSubstitutionSnackbar(snackbar.type))}
    >
      <MySnackbarContentWrapper
        variant={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch(closeProductionSubstitutionSnackbar(snackbar.type))}
      />
    </Snackbar>
  )
}

export default ProductionSubstitutionSnackbar