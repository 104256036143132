import React from "react"
import TechnicalSheetTabs from "../TechnicalSheetTabs"
import Packagings from "../../../Packaging/Packagings"

const PackagingTab = () => {
	return (
		<TechnicalSheetTabs tabValue="packaging">
			<Packagings />
		</TechnicalSheetTabs>
	)
}

export default PackagingTab