import React from "react"
import { Form, Formik } from "formik"
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"

import { packagingLifeCycleMaterialSchema } from "../../../../../utils/yupValidators"
import ModalForm from "../../../../../components/form/ModalForm"
import ModalActions from "../../../../../components/ModalActions"
import CountrySelect from "../../../../../components/form/CountrySelect"
import Delete from "@mui/icons-material/Delete"

const getInitialValues = (material) => {
  if (material) {
    return {
      packagingMaterial: material.packagingMaterial?.objectId,
      countryOfOrigin: material.countryOfOrigin,
      proportion: material.proportion
    }
  }

  return {
    packagingMaterial: "",
    countryOfOrigin: "",
  }
}

const PackagingLifeCycleMaterialFormDialog = ({
  material,
  packagingMaterials,
  onClose,
  open,
  onSave,
  onDelete
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title={material ? "Modifier une matière" : "Ajouter une matière"}
      sx={{ pt: 2 }}
      icon={material ? (
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      ): null}
    >
      <Formik
        initialValues={getInitialValues(material)}
        onSubmit={onSave}
        validationSchema={packagingLifeCycleMaterialSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, setFieldValue }) => {
          return (
            <Form>
              <Stack spacing={2}>
                <FormControl fullWidth error={!!errors.packagingMaterial}>
                  <InputLabel id="packagingMaterial">Nom de la matière</InputLabel>
                  <Select
                    labelId="packagingMaterial"
                    variant="standard"
                    name="packagingMaterial"
                    label="Type"
                    value={values.packagingMaterial}
                    onChange={handleChange}
                  >
                    {packagingMaterials.map(packagingMaterial => (
                      <MenuItem
                        key={packagingMaterial.objectId}
                        value={packagingMaterial.objectId}
                      >
                        {packagingMaterial.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.packagingMaterial && <FormHelperText error>{errors.packagingMaterial}</FormHelperText>}
                </FormControl>
                <CountrySelect
                  variant="standard"
                  name="countryOfOrigin"
                  label="Pays de provenance de la matière"
                  value={values.countryOfOrigin}
                  error={!!errors.countryOfOrigin}
                  helperText={errors.countryOfOrigin}
                  onChange={setFieldValue}
                />
                <TextField
                    variant="standard"
                    name="proportion"
                    label="Proportion dans le packaging (%)"
                    value={values.proportion}
                    type="number"
                    error={!!errors.proportion}
                    helperText={errors.proportion}
                    onChange={handleChange}
                />
              </Stack>
              <ModalActions onCancel={onClose} sx={{ mt: "40px" }} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingLifeCycleMaterialFormDialog
