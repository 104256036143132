import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"
import WidgetsIcon from "@mui/icons-material/Widgets"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"
import { ArrowBack } from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import Icon from "@mui/material/Icon"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        zIndex: theme.zIndex.drawer + 1
    },
    container: {
        display: "flex",
        alignItems: "center",
        height: 63
    },
    title: {
        color: COLORS.GREEN_INVENTORY
    },
    icon: {
        marginRight: 10,
        color: COLORS.GREEN_INVENTORY,
        height: 24
    },
    headerInfos: {
        color: COLORS.DARK_GREY,
    }
}))

const InventoryHeader = (props) => {
	const { hub, onClose } = props
    const classes = useStyles()

    return (
        <AppBar className={classes.appBar}>
        <Toolbar>
            <IconButton onClick={onClose} size="large">
                <ArrowBack />
            </IconButton>
            <Icon className={classes.icon}>
                <WidgetsIcon />
            </Icon>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                Inventaire Hubs <span className={classes.headerInfos}>- {hub}</span>
            </Typography>
            
        </Toolbar>
    </AppBar>
    )
}

export default InventoryHeader