import { makeStyles } from "@mui/styles"
import React from "react"
import Fab from "@mui/material/Fab"
import HelpIcon from "@mui/icons-material/HelpRounded"

const useStyles = makeStyles(() => ({
    container: {
        zIndex: 9999
    },
    helpButton : {
        position: "fixed",
        bottom: 15,
        right: 20
    },
    helpIcon: {
        marginRight: 5
    }
}))

const HelpButton = (props) => {
    const {
        link
    } = props

    const classes = useStyles()

    return(
        <a href={link} target="blank" className={classes.container}>
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.helpButton}
            >
                <HelpIcon className={classes.helpIcon}/>
                Aide

            </Fab>
        </a>
    )
}

export default HelpButton