import moment from "moment"

export const labelTemplates = [
	{value: "SEAZONBE140ECOSCORE", label: "Seazon 140", brand: "SEAZON_BE"},
	{value: "FRIGOCO140ECOSCORE", label: "FrigoCo 140", brand: "FRIGONU"},
	{value: "SEAZON140ECOSCORE", label: "Seazon 140", brand: "SEAZON"},
	{value: "SEAZON80", label: "Seazon 80", brand: "SEAZON"},
	// {value: "FOODCHERI80_ECOSCORE", label: "FoodChéri Rond 80", brand: "FOODCHERI"},
	// {value: "FOODCHERI70_ECOSCORE", label: "FoodChéri Rond 70", brand: "FOODCHERI"},
	{value: "FOODCHERI90", label: "FoodChéri Rond 90mm NEW", brand: "FOODCHERI"},
	{value: "FOODCHERI70", label: "FoodChéri Rond 70mm NEW", brand: "FOODCHERI"},
	{value: "OLYMPIC_GAMES90", label: "Jeux Olympiques Rond 90mm", brand: "OLYMPIC_GAMES"},
	{value: "OLYMPIC_GAMES70", label: "Jeux Olympiques Rond 70mm", brand: "OLYMPIC_GAMES"},
	{value: "MONOPRIX140ECOSCORE", label: "Monop 140", brand: "MONOPRIX"},
]

export const colors = [
    {value: "#FFFFFF", label: "Blanc", templates: ["SEAZON80", "FRIGOCO140ECOSCORE", "SEAZONBE140ECOSCORE", "SEAZON140ECOSCORE", "SEAZON140", "MONOPRIX140ECOSCORE", "MONOPRIX140", "MONOPRIX140V2", "SEAZON140V2", "SEAZONBE140V2"]},
    {value: "#C6DEEB", label: "Bleu poisson", templates: ["SEAZON80", "FRIGOCO140ECOSCORE", "SEAZONBE140ECOSCORE", "SEAZON140ECOSCORE", "SEAZON140", "MONOPRIX140ECOSCORE", "MONOPRIX140", "MONOPRIX140V2", "SEAZON140V2", "SEAZONBE140V2"], internalTags: ["Poisson","Crevette"]},
    {value: "#FEE39E", label: "Jaune vegan", templates: ["SEAZON80", "FRIGOCO140ECOSCORE", "SEAZONBE140ECOSCORE", "SEAZON140ECOSCORE", "SEAZON140", "MONOPRIX140ECOSCORE", "MONOPRIX140", "MONOPRIX140V2", "SEAZON140V2", "SEAZONBE140V2"], internalTags: ["Vegan"]},
    {value: "#F6C4C4", label: "Rouge viande", templates: ["SEAZON80", "FRIGOCO140ECOSCORE", "SEAZONBE140ECOSCORE", "SEAZON140ECOSCORE", "SEAZON140", "MONOPRIX140ECOSCORE", "MONOPRIX140", "MONOPRIX140V2", "SEAZON140V2", "SEAZONBE140V2"], internalTags: ["Boeuf / Veau / Agneau", "Porc", "Volaille (Poulet, dinde, canard)"]},
    {value: "#CEECD6", label: "Vert veggie", templates: ["SEAZON80", "FRIGOCO140ECOSCORE", "SEAZONBE140ECOSCORE", "SEAZON140ECOSCORE", "SEAZON140", "MONOPRIX140ECOSCORE", "MONOPRIX140", "MONOPRIX140V2", "SEAZON140V2", "SEAZONBE140V2"], internalTags: ["Végétarien"]},

    {value: "#151858", label: "Bleu foncé", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#66B5E5", label: "Bleu ciel", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#EBEEE3", label: "Mardi Veggie", templates: ["MARDIVEGGIE80", "MARDIVEGGIE70"]},
    {value: "#B75837", label: "Marron", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#FD9938", label: "Orange", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#860A61", label: "Pourpre", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#FF0000", label: "Rouge", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#C6BD4F", label: "Vert pomme", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#34B590", label: "Vert feuille", templates: ["FOODCHERI80", "FOODCHERI80V2", "FOODCHERI70"]},
    {value: "#165E45", label: "Vert bouteille", templates: ["FOODCHERI80", "FOODCHERI80V2","FOODCHERI70"]},
    {value: "#C16EC3", label: "Violet", templates: ["FOODCHERI80", "FOODCHERI80V2","FOODCHERI70"]},
    
    {value: "#000000", label: "Noir", templates: [
        "FOODCHERI80_NEW_DA", "FOODCHERI70_NEW_DA", "FOODCHERI70_ECOSCORE", "FOODCHERI80_ECOSCORE", "FOODCHERI70", "FOODCHERI90", "OLYMPIC_GAMES90", "OLYMPIC_GAMES70"
    ]},

    {value: "#FFFFFF", label: "Blanc Retail", templates: ["MONOPRIX"]},
    {value: "#C6DEEB", label: "Bleu poisson Retail", templates: ["MONOPRIX"]},
    {value: "#FEE39E", label: "Jaune vegan Retail ", templates: ["MONOPRIX"]},
    {value: "#F6C4C4", label: "Rouge viande Retail", templates: ["MONOPRIX"]},
    {value: "#CEECD6", label: "Vert veggie Retail", templates: ["MONOPRIX"]},
 //   {value: "#07393E", label: "Vert logo Retail", templates: ["MONOPRIX"]}
]

export function getLotNumberByPackagingDate (date){
    const day = moment.utc(date).isoWeekday()

    const lotNumbers = ["A", "B", "C", "D", "E", "F", "G"]

    return lotNumbers[day - 1]
}
