import moment from "moment"
import Parse from "parse"

export function formatReceptionData(receptionData) {
	const newReceptionData = {}

	receptionData.forEach(elm => {
		const group = elm.uniqueCode.replace(/\d/g,"")

		if (!newReceptionData[elm.productType]) newReceptionData[elm.productType] = {}
		if (!newReceptionData[elm.productType][group])  newReceptionData[elm.productType][group] = []
		newReceptionData[elm.productType][group].push(elm)
	})

	return newReceptionData
}

export function handleReceptionEvent(productStock) {
	let tooltipText = ""

	const reception = productStock.events.filter(elm => elm.type === "RECEPTION")

	if (reception.length > 0) {
		tooltipText = moment(reception[0].date).format("DD/MM HH:mm")
		tooltipText = `${reception[0].data.volume} - ${tooltipText} ${reception[0].user.username}`
	}

	if (undefined === productStock.commercialTitle) productStock.commercialTitle = ""
	if (undefined === productStock.uniqueCode) productStock.uniqueCode = "NC"

	productStock.display = (productStock.initialVolume > 0)
	productStock.toolTip = tooltipText
	
	return productStock
}

export function findProductStock(receptionData, uniqueCode, dlc= false) {
	let result = receptionData.filter(elm => elm.uniqueCode === uniqueCode)
	if (dlc.length === 10) {
		result = result.filter(elm => moment.utc(elm.dlc).format("DD/MM/YYYY") === dlc)
	} else if (dlc.length === 5) {
		result = result.filter(elm => moment.utc(elm.dlc).format("DD/MM") === dlc)
	}

	return result
}

export function getProductStock(receptionData, uniqueCode, dlc) {
	let result = receptionData.find(el => el.uniqueCode === uniqueCode && el.dlc === dlc)

	return result
}

export function getLostData(lost, date) {
	return {
		type: "FLAW",
		user: Parse.User.current(),
		date: date,
		data: {
			volume: parseInt(lost.volume),
			reason: lost.reason,
			commentary: lost.explanation
		}
	}
}
