import React from "react"
import { Box, Stack, Typography, styled } from "@mui/material"

import { COLORS } from "../../../utils"
import { getPackagingCategoryLabel, getPackagingLabelByType, getPackagingTypeLabel } from "../../../utils/packaging"

// --------- styled --------- //
const StyledContainer = styled("div")({
    width: "calc(100% - 245px)",
    paddingLeft: 48,
    paddingTop: 16,
    paddingRight: 16,
})

const StyledLabel = styled("span")({
    fontSize: 12
})

const StyledValue = styled("span")({
    fontSize: 16,
    marginTop: 8
})

const StyledImage = styled("img")({
    width: 245,
})

// --------- sx --------- //
const sx = {
    header: {
        width: "100%",
        backgroundColor: COLORS.BLUEGREY_700,
        height: 160,
        display: "flex",
        color: COLORS.WHITE,
        marginTop: "64px",
    },
    packagingName: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 30,
    },
}

const PackagingSummaryHeader = ({ packaging }) => {
    return (
        <Box sx={sx.header}>
            <StyledImage
                src="/img/noImageAvailabled.png"
                alt="Indisponible"
            />
            <StyledContainer>
                <Box sx={{ mb: 2 }}>
                    <Typography sx={sx.packagingName}>
                        {packaging.name}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={5}>
                    <div className="flexColumn">
                        <StyledLabel>
                            Référence
                        </StyledLabel>
                        <StyledValue>
                            {packaging.reference}
                        </StyledValue>
                    </div>
                    <div className="flexColumn">
                        <StyledLabel>
                            Type
                        </StyledLabel>
                        <StyledValue>
                            {getPackagingTypeLabel(packaging.type)}
                        </StyledValue>
                    </div>
                    <div className="flexColumn">
                        <StyledLabel>
                            Nature
                        </StyledLabel>
                        <StyledValue>
                            {getPackagingLabelByType(packaging.nature)}
                        </StyledValue>
                    </div>
                    <div className="flexColumn">
                        <StyledLabel>
                            Catégorie
                        </StyledLabel>
                        <StyledValue>
                            {getPackagingCategoryLabel(packaging.categories)}
                        </StyledValue>
                    </div>

                </Stack>
            </StyledContainer>
        </Box>
    )
}



export default PackagingSummaryHeader