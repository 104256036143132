import React, { memo, useEffect, useState } from "react"
import _cloneDeep from "lodash/cloneDeep"
import { makeStyles } from "@mui/styles"
import { isEqual } from "lodash"

import {
    COLORS
} from "../../../utils"
import {
    updateHubsPriorities
} from "../../../utils/planning"
import SellPlanningBody from "./SellPlanningBody"
import ProductionPlanningBody from "./ProductionPlanningBody"
import PackagingPlanningBody from "./PackagingPlanningBody"
import PrevisionsPlanningBody from "./PrevisionsPlanningBody"
import DragDropContextWrapper from "../../../components/Planning/DragDropContextWrapper"
import { Box } from "@mui/material"

const sx = {
    bodyPrevsContainer: (rowsLength) => ({
        position: "relative",
        // dynamic position depending of the product type's length
        top: (rowsLength * 22) + 20 // margin = 10
    })
}
const useStyles = makeStyles({
    planningBody: {
        display: "flex",
        backgroundColor: COLORS.LIGHT_GREY
    },
})

let PlanningBody = (props) => {
    const {
        data,
        isCardDragging,
        brand,
        section,
        isIntern,
        planningType,
        updateProductionCard,
        updatePackagingCard,
        updatePrevDay,
        saveExpectedProduction,
        saveSumExpectedProduction,
        removeProductionItemIdFromSaved,
        saveProd,
        openSplitModal,
        openAddModal,
        openDeleteModal,
        printSingleKitchenCard,
        printPackagingSingleCard,
        openRecipeLabelModal,
        rules,
        openPrevEditModal,
        foldedCards,
        canEdit,
        canPrint,
        canDeleteProductionItem,
        openProductInfo,
        setAnyFocused,
        selectedProductTypes,
        selectedHubs,
        filteredBrands,
        filteredSaleDate,
        isDragAndDroppable,
        canAdd,
        canSplit,
        canInputVolume,
        canPrintLabel,
        deleteProductionItem,
        showDays,
        productTypeOptions
    } = props
    const classes = useStyles()
    const [stateData, setStateData] = useState([])
    const [currentCard, setCurrentCard] = useState(null)
    const [currentColumn, setCurrentColumn] = useState(null)

    useEffect(() => {
        setStateData(_cloneDeep(data) || null)
    }, [data])

    const _onDragStart = (event) => {
        const currentColumn = stateData.find(item => item.id === event.source.droppableId)
        let currentCard = null
        // key is from old static product type, type for dynamic product type (db)
        const productType = section.key || section.type

        if (planningType === "SELL") {
            currentCard = currentColumn.cards[brand.name][productType].filter(el => el.itemType === isIntern).find(it => it.objectId === event.draggableId)
        }
        else if (planningType === "PREVISIONS") {
            currentCard = currentColumn.cards[brand.name].find(it => it.objectId === event.draggableId)
        }
        else if (planningType === "PRODUCTION") {
            currentCard = currentColumn.cards[productType]?.filter(el => el.itemType === isIntern).find(it => it.id === event.draggableId)
        }
        else {
            currentCard = currentColumn.cards[productType]?.filter(el => el.itemType === isIntern).find(it => it.id === event.draggableId)
        }
        setCurrentCard(currentCard)
        setCurrentColumn(currentColumn)

        isCardDragging(true)
    }

    const _onDragEnd = (event) => {
        const { destination, source } = event
        /* draggableId */

        isCardDragging(false)
        //No destination for the current card
        if (!destination || planningType === "SELL") {
            setCurrentCard(null)
            setCurrentColumn(null)
            return
        }

        if (planningType === "PREVISIONS") {
            const copyData = _cloneDeep(stateData)

            if (source.droppableId !== destination.droppableId) {
                setCurrentCard(null)
                setCurrentColumn(null)
                return
            }

            const column = copyData.find(item => item.id === destination.droppableId)
            const columnPosition = copyData.indexOf(column)
            const oldPosition = source.index
            const newPosition = destination.index
            const copyCard = _cloneDeep(column.cards[brand.name][oldPosition])

            column.cards[brand.name].splice(oldPosition, 1)
            column.cards[brand.name].splice(newPosition, 0, copyCard)

            updateHubsPriorities(column.cards[brand.name])
            updatePrevDay(column, columnPosition)

            setStateData(copyData)
            setCurrentCard(null)
            setCurrentColumn(null)
            return
        }

        //No changing position on current card
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            setCurrentCard(null)
            setCurrentColumn(null)
            return
        }

        //Apply change on card
        const copyData = _cloneDeep(stateData)

        const oldColumn = copyData.find(item => item.id === source.droppableId)
        const newColumn = copyData.find(item => item.id === destination.droppableId)
        // key is from old static product type, type for dynamic product type (db)
        const productType = section.key || section.type

        if (planningType === "PRODUCTION") {
            const cardDayInfo = showDays && showDays.find(elem => elem.timestamp === newColumn.date)
            if (currentCard && oldColumn.date !== newColumn.date && !cardDayInfo.isLocked) {
                const timeslots = oldColumn.cards[productType].find(el => el.id === currentCard.id).currentAvailableProductionDates || []
                if (timeslots.includes(newColumn.date)) {
                    oldColumn.cards[productType].splice(source.index, 1)
                    newColumn.cards[productType].splice(destination.index, 0, currentCard)
                    updateProductionCard(newColumn.date, currentCard, oldColumn.date)
                }
            }
        }
        else {
            const timeslots = oldColumn.cards[productType].find(el => el.id === currentCard.id).currentAvailablePackagingDates || []
            if (timeslots.includes(newColumn.date)) {
                oldColumn.cards[productType].splice(source.index, 1)
                newColumn.cards[productType].splice(destination.index, 0, currentCard)
                updatePackagingCard(newColumn.date, currentCard)
            }
        }

        setStateData(copyData)
        setCurrentCard(null)
        setCurrentColumn(null)
    }



    const isDragDroppable = !["PRODUCTION", "PACKAGING"].includes(planningType) ? true : isDragAndDroppable

    return (
        <Box className="flexRow" sx={planningType === "PREVISIONS" && sx.bodyPrevsContainer(productTypeOptions.length)}>
            <DragDropContextWrapper
                className={classes.planningBody}
                onDragStart={_onDragStart}
                onDragEnd={_onDragEnd}
                isDragDroppable={isDragDroppable}
            >
                {
                    planningType === "SELL" &&
                    <SellPlanningBody
                        data={stateData}
                        isIntern={isIntern}
                        section={section}
                        brand={brand}
                        currentCard={currentCard}
                        currentColumn={currentColumn}
                        canEdit={canEdit}
                        openProductInfo={openProductInfo}
                    />
                }
                {
                    planningType === "PREVISIONS" &&
                    <PrevisionsPlanningBody
                        data={stateData}
                        brand={brand}
                        currentCard={currentCard}
                        currentColumn={currentColumn}
                        openPrevEditModal={openPrevEditModal}
                        foldedCards={foldedCards}
                        canEdit={canEdit}
                        selectedProductTypes={selectedProductTypes}
                        selectedHubs={selectedHubs}
                        productTypeOptions={productTypeOptions}
                    />
                }
                {
                    planningType === "PRODUCTION" &&
                    <ProductionPlanningBody
                        deleteProductionItem={deleteProductionItem}
                        data={stateData}
                        isIntern={isIntern}
                        section={section}
                        currentCard={currentCard}
                        currentColumn={currentColumn}
                        saveExpectedProduction={saveExpectedProduction}
                        saveSumExpectedProduction={saveSumExpectedProduction}
                        openSplitModal={openSplitModal}
                        openAddModal={openAddModal}
                        openDeleteModal={openDeleteModal}
                        printSingleKitchenCard={printSingleKitchenCard}
                        rules={rules}
                        canPrint={canPrint}
                        canDeleteProductionItem={canDeleteProductionItem}
                        openProductInfo={openProductInfo}
                        setAnyFocused={setAnyFocused}
                        filteredBrands={filteredBrands}
                        filteredSaleDate={filteredSaleDate}
                        removeProductionItemIdFromSaved={removeProductionItemIdFromSaved}
                        isDragAndDroppable={isDragAndDroppable}
                        canAdd={canAdd}
                        canSplit={canSplit}
                        canInputVolume={canInputVolume}
                        showDays={showDays}
                    />
                }
                {
                    planningType === "PACKAGING" &&
                    <PackagingPlanningBody
                        data={stateData}
                        isIntern={isIntern}
                        section={section}
                        brand={brand}
                        currentCard={currentCard}
                        currentColumn={currentColumn}
                        saveProd={saveProd}
                        printPackagingSingleCard={printPackagingSingleCard}
                        openSplitModal={openSplitModal}
                        openRecipeLabelModal={openRecipeLabelModal}
                        canPrint={canPrint}
                        openProductInfo={openProductInfo}
                        setAnyFocused={setAnyFocused}
                        isDragAndDroppable={isDragAndDroppable}
                        canInputVolume={canInputVolume}
                        canPrintLabel={canPrintLabel}
                    />
                }
            </DragDropContextWrapper>
        </Box>
    )
}

PlanningBody = memo(PlanningBody, isEqual)

export default PlanningBody
