import React from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import { COLORS, roundNumber } from "../../../utils"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import {
    getOrderShippingFees,
    getOrderTotalAmount
} from "../../../utils/orderReception"
import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import { deliveryNoteInformationSchema } from "../../../utils/yupValidators"

const useStyles = makeStyles({
    flex: {
        display: "flex"
    },
    flexColumn: {
        composes: "flex",
        flexDirection: "column"
    },
    modal: {
        position: "absolute",
        width: 744,
        minHeight: 230,
        background: COLORS.WHITE,
        textAlign: "justify",
        fontSize: 16,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "32px 23px 26px 24px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 15,
        marginBottom: 10
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "24px",
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 32
    },
    backButton: {
        marginRight: 10,
        border: "none"
    },
    informationRow: {
        composes: "$flex"
    },
    informationColumn: {
        composes: "$flexColumn",
        minWidth: 200,
        paddingRight: 32
    },
    informationTitle: {
        fontSize: 12,
        opacity: 0.87,
        color: COLORS.INFORMATION_TITLE,
        marginBottom: 16
    },
    informationTitleInput: {
        composes: "$informationTitle",
        fontSize: 16,
        marginBottom: 0
    },
    informationValue: {
        fontSize: 14
    },
    divider: {
        borderBottom: `1px solid ${COLORS.SITE_INFORMATION_VALUE}`,
        marginRight: 48,
        marginLeft: 16
    },
    textField: {
        width: "100%",
        "& input": {
            fontSize: 14
        },
        "& label + .MuiInput-formControl": {
            marginTop: 30
        }
    },
    unit: {
        "& > p": {
            color: COLORS.BLACK_CARD,
            fontSize: 14
        }
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
})

const EditInformationModal = (props) => {
    const {
        orderReception,
        onBack,
        validate,
        open
    } = props

    const classes = useStyles()

    const totalAmount = getOrderTotalAmount(orderReception)

    const submitForm =  (values) => {
        validate(values)
        onBack()
    }

    return (
        <Modal
            open={open}
            onClose={onBack}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Formik
                enableReinitialize
                initialValues={{shippingFees: getOrderShippingFees(orderReception)}}
                onSubmit={submitForm}
                validationSchema={deliveryNoteInformationSchema}
            >
                {({
                    values,
                    errors,
                    handleChange,
                }) => {
                    const shippingFees = Number(values.shippingFees)

                    return (
                        <Form>
                            <div className={classes.modal}>
                                <div className={classes.title}>
                                    Récapitulatif du bon de livraison
                                </div>
                                <div className={classes.informationRow}>
                                    <div className={classes.informationColumn}>
                                        <FormControl className={classes.textField} variant="standard">
                                            <InputLabel
                                                className={classes.informationTitleInput}
                                                shrink={true}
                                            >
                                                Frais de port
                                            </InputLabel>
                                            <Input
                                                id="standard-adornment-shippingFees"
                                                name="shippingFees"
                                                value={values.shippingFees}
                                                onChange={handleChange}
                                                endAdornment={<InputAdornment className={classes.unit} position="end">€</InputAdornment>}
                                                aria-describedby="standard-shippingFees-helper-text"
                                                inputProps={{
                                                    "aria-label": "shippingFees",
                                                }}
                                                type="number"
                                            />
                                        </FormControl>
                                        {
                                            errors.shippingFees ?
                                                (
                                                    <div className={classes.errorField}>{errors.shippingFees}</div>
                                                )
                                                : null
                                        }
                                    </div>
                                    <div className={classes.informationColumn}>
                                        <Typography className={classes.informationTitle}>
                                            Montant global des produits
                                        </Typography>
                                        <Typography className={classes.informationValue}>
                                            {roundNumber(totalAmount, 2)} €
                                        </Typography>
                                    </div>
                                    <Divider
                                        className={classes.divider}
                                        orientation="vertical"
                                        flexItem
                                    />
                                    <div className={classes.informationColumn}>
                                        <Typography className={classes.informationTitle}>
                                            Total (HT)
                                        </Typography>
                                        <Typography className={classes.informationValue}>
                                            {roundNumber(shippingFees + totalAmount, 2)} €
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        color="primary"
                                        onClick={onBack}
                                        variant="outlined"
                                        className={classes.backButton}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Enregister
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default EditInformationModal