import React, { useState, useMemo, useEffect } from "react"
import flexModule from "flexsearch"
import LazyLoad, { forceCheck } from "react-lazyload"
import _sortBy from "lodash/sortBy"
import _capitalize from "lodash/capitalize"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import moment from "moment"

import Typography from "@mui/material/Typography"
import Search from "@mui/icons-material/Search"
import { CircularProgress, Stack } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import ListItemText from "@mui/material/ListItemText"

import Filter from "../Filter/Filter"
import MealPlannerCard from "./MealPlannerCard"
import WeeklyMealPlannerProductionSchemaFilter from "../WeeklyMealPlanner/WeeklyMealPlannerProductionSchemaFilter"

import { SORT_CRITERION } from "../../utils/mealPlanner"
import { getProductsTagsFilters, getSelectableItems, getSuppliersFilters } from "../../api/mealPlanner"
import { getBrandBy } from "../../utils"
import { months } from "../../utils/recipes"
import { STOPWORDS } from "../../utils/constant"

const itemsStyle = {
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "row wrap",
    "& > *": {
        flex: "0 0 auto",
        margin: 5
    },
    height: "calc(100vh - 232px)",
    overflowY: "scroll"
}

const useStyles = makeStyles(() => ({
    root: {
        boxSizing: "border-box",
    },
    filtersContainer: {
        padding: "24px 40px 0px 40px",
        gap: "24px"
    },
    weeklyFiltersContainer: {
        padding: "20px 32px 0px 32px",
        gap: "24px"
    },
    menu: {
        boxSizing: "border-box",
        padding: "15px 40px 0px 40px"
    },
    weeklyMenu: {
        boxSizing: "border-box",
        padding: "24px 32px 0px 32px"
    },
    items: {
        boxSizing: "border-box",
        display: "flex",
        flexFlow: "row wrap",
        "& > *": {
            flex: "0 0 auto",
            margin: 5
        },
        "@supports (display:grid)": {
            display: "grid",
            gridGap: "10px 10px",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridTemplateRows: "repeat(auto-fill, 262px)",
            "& > *": {
                margin: 0
            },
        },
        height: "calc(100vh - 270px)",
        overflowY: "scroll"
    },
    weeklyMpItems: {
        ...itemsStyle,
        "@supports (display:grid)": {
            display: "grid",
            gridGap: "10px 10px",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            "& > *": {
                margin: 0
            },
        },
        height: "calc(100vh - 270px)",
        overflowY: "scroll"
    },
    sortWidget: {
        backgroundColor: "transparent",
        width: "100%",
        paddingBottom: 40,
    },
    sortWidgetSelect: {
        width: "max-content",
        marginLeft: "auto",
        marginRight: 5
    },
    warning: {
        width: "100%",
        padding: "5%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    productsList: {
        width: "100%",
        height: "calc(100vh - 270px)",
        position: "relative"
    },
    progressContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(248, 248, 248, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 1
    }
}))

export const sortProducts = (products, criterion, isWeekly) => {
    if (isWeekly) {
        // no sorting on weekly planner
        return products
    }

    if (criterion && criterion.key) {
        products = criterion.desc
            ? _sortBy([...products], criterion.key).reverse()
            : _sortBy([...products], criterion.key)
    }

    return products
}

const getMealPlannerFilter = (brand, internalTags, suppliers, productTypeOptions = []) => {
    return [
        {
            key: "nameOrId",
            label: "Name",
            dataType: "text",
            filter: {
                filterable: true,
                type: "text",
                fullText: true,
                placeholder: "Rechercher un code, un produit",
                inputIcon: <Search />
            }
        },
        {
            key: "internalTags",
            label: "Tags internes",
            dataType: "text",
            filter: {
                filterable: true,
                label: "Tags internes",
                type: "select",
                multiple: true,
                withSearch: true,
                options: internalTags.map((internalTag, index) => ({
                    key: internalTag.value + index,
                    value: internalTag.value,
                    label: internalTag.label
                })),
                render: internalTag => internalTags.find(tag => tag.value === internalTag)?.label
            },
            width: 135
        },
        {
            key: "seasons",
            label: "Saisons",
            dataType: "text",
            filter: {
                filterable: true,
                label: "Saisons",
                type: "select",
                multiple: true,
                options: months.map((month, index) => ({
                    key: moment(month).format("M") + index,
                    value: moment(month).format("M"),
                    label: _capitalize(moment(month).format("MMMM"))
                })),
                render: monthNumber => _capitalize(moment(monthNumber, "M").format("MMMM"))
            },
            width: 135
        },
        {
            key: "brand",
            label: "Marque",
            dataType: "text",
            filter: {
                filterable: true,
                label: "Marque",
                type: "select",
                multiple: false,
                options: [
                    { key: brand, value: brand, label: `Uniquement ${getBrandBy("name", brand)?.label || ""}` },
                    { key: "FcAndSzn", value: "FcAndSzn", label: "Au moins FoodChéri & Seazon" }
                ],
                render: brand => brand === "FcAndSzn" ? "Au moins FoodCheri & Seazon" : `Uniquement ${getBrandBy("name", brand)?.label || ""}`
            },
            width: 135
        },
        {
            key: "type",
            label: "Types",
            dataType: "text",
            filter: {
                filterable: true,
                label: "Type",
                type: "select",
                options: productTypeOptions.map(producType => ({
                    key: producType.type,
                    label: producType.label,
                    value: producType.value
                })),
                render: productType => productTypeOptions.find(option => option.value === productType)?.label
            },
            width: 135
        },
        {
            key: "suppliers",
            label: "Fournisseurs",
            dataType: "text",
            filter: {
                filterable: true,
                label: "Fournisseurs",
                type: "select",
                multiple: true,
                withSearch: true,
                options: [
                    {
                        key: "INTERN",
                        label: "Interne",
                        value: "INTERN"
                    },
                    ...suppliers.map((supplier, index) => ({
                        key: supplier.value + index,
                        label: supplier.label,
                        value: supplier.value
                    }))],
                render: supplier => supplier === "INTERN" ? "Interne" : suppliers.find(s => s.value === supplier)?.label
            },
            width: 135
        }
    ]
}

const initializeSeason = date => {
    const month = date.substr(5, 2)
    return month.charAt(0) === "0" ? [month.charAt(1)] : [month]
}

const filterProducts = (products, filters, index) => {
    if (
        !products ||
        !Array.isArray(products) ||
        !filters ||
        typeof filters !== "object"
    ) {
        return products // Return original products if input is invalid
    }

    return products.filter(product => {
        // check nameOrId filter: full-text search
        if (filters.nameOrId) {
            const results = index.search(filters.nameOrId, { index: ["name", "commercialName", "uniqueCode"] })
            if (!Array.isArray(results) || !results.length) return false

            // results format
            // cf. documetation here https://www.npmjs.com/package/flexsearch#the-result-set
            if (!results.some(resultItem => {
                if (!Array.isArray(resultItem?.result)) return false
                return resultItem?.result.some(result => result === product.itemId)
            })) {
                return false
            }
        }
        // check internalTags
        if (Array.isArray(filters.internalTags) && filters.internalTags.length) {
            // to be valid product, all filters internalTags must be present in the product tags
            if (!filters.internalTags.every(tagId => product.internalTag?.some(productTag => productTag.objectId === tagId))) {
                return false
            }
        }
        // check seasons
        if (Array.isArray(filters.seasons) && filters.seasons.length) {
            if (!product.season?.some(season => filters.seasons.includes(season))) {
                return false
            }
        }
        // check brand
        if (filters.brand) {
            if (filters.brand === "FcAndSzn") {
                if (!product.itemBrand?.includes("FOODCHERI") || !product.itemBrand?.includes("SEAZON")) {
                    return false
                }
            } else if (product.itemBrand?.toString() !== filters.brand) {
                // only products with one specific brand can be selected
                return false
            }
        }
        // check suppliers
        if (Array.isArray(filters.suppliers) && filters.suppliers.length) {
            const formattedSuppliers = filters.suppliers.map(supplier => supplier === "INTERN" ? null : supplier)
            if (!formattedSuppliers.includes(product.supplier || null)) {
                return false
            }
        }

        return true
    })
}

export const MealPlannerMenu = props => {
    const {
        selectedProductIds = [],
        onClick,
        targetBrand,
        date,
        productTypeOptions,
        productionSchemasOptions,
        selectProductionSchema,
        currentProductionSchema,
        isWeekly = false
    } = props

    const [isLoading, setIsLoading] = useState(false)
    const [sortCriterionId, updateSortCriterion] = useState(Object.keys(SORT_CRITERION)[0])
    const [productType, setProductType] = useState("MAIN_COURSE")
    const [products, setProducts] = useState([])
    const [displayableProducts, setDisplayableProducts] = useState([...products])
    const [filters, updateFilters] = useState({ seasons: initializeSeason(date) })
    const [internalTags, updateInternalTags] = useState([])
    const [suppliers, updateSuppliers] = useState([])

    const classes = useStyles()

    const sortCriterion = SORT_CRITERION[sortCriterionId]

    suppliers.sort((a, b) => a.label.localeCompare(b.label))
    internalTags.sort((a, b) => a.label.localeCompare(b.label))

    const filterFields = useMemo(() =>
        getMealPlannerFilter(targetBrand, internalTags, suppliers, productTypeOptions)
        , [targetBrand, internalTags, suppliers, productTypeOptions]
    )

    const searchIndex = useMemo(() => {
        const newIndex = new flexModule.Document({
            language: "fr",
            tokenize: "forward",
            rtl: true,
            optimize: true,
            encoder: "simple",
            document: {
                field: ["name", "commercialName", "uniqueCode"],
                id: "itemId"
            },
            filter: STOPWORDS
        })

        products.forEach(document => {
            newIndex.add(document)
        })

        return newIndex
    }, [JSON.stringify(products)])

    useEffect(() => {
        const fetchFiltersData = async () => {
            const [internalTags, suppliers] = await Promise.all([
                getProductsTagsFilters(),
                getSuppliersFilters()
            ])

            const deduplicatedSuppliers = Array.from(new Set(suppliers.map(obj => obj.value))).map(value => {
                return suppliers.find(obj => obj.value === value)
            })

            updateInternalTags(internalTags)
            updateSuppliers(deduplicatedSuppliers)
        }

        fetchFiltersData()
    }, [])

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true)
            const products = await getSelectableItems({ brand: !isWeekly ? targetBrand : null, date, productTypes: [productType], weekly: isWeekly })
            setProducts(products)
            setIsLoading(false)
        }
        fetchProducts()
    }, [productType])

    useEffect(() => {
        const filteredProducts = filterProducts(products, filters, searchIndex)
        const sortedProducts = sortProducts(filteredProducts, sortCriterion, isWeekly)
        setDisplayableProducts([...sortedProducts])
    }, [JSON.stringify(products), JSON.stringify(filters), JSON.stringify(sortCriterion), isWeekly])

    useEffect(forceCheck, [displayableProducts, selectedProductIds, filters])

    const _updateFilters = filters => {
        const updatedFilters = { ...filters }

        if (updatedFilters.hasOwnProperty("type")) {
            setProductType(updatedFilters.type)
            delete updatedFilters["type"]
        }

        updateFilters(updatedFilters)
    }

    const applySortCriterion = ({ target = {} }) => {
        const criterionId = target.value
        updateSortCriterion(criterionId)
    }

    return (
        <div className={classes.root}>
            <Stack className={isWeekly ? classes.weeklyFiltersContainer : classes.filtersContainer}>
                {isWeekly && <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>Ajouter les produits à la carte</Typography>}
                <Stack>
                    {isWeekly && <WeeklyMealPlannerProductionSchemaFilter
                        selectProductionSchema={selectProductionSchema}
                        selectedProductionSchema={currentProductionSchema}
                        productionSchemasOptions={productionSchemasOptions}
                    />}
                    <Filter
                        initialFilters={{ seasons: initializeSeason(date), type: "MAIN_COURSE" }}
                        type="standard"
                        filters={filterFields}
                        onFilterChange={_updateFilters}
                    />
                </Stack>
            </Stack>
            <div className={isWeekly ? classes.weeklyMenu : classes.menu}>
                {!isWeekly && <FormControl className={classes.sortWidget} variant="standard">
                    <Select
                        variant="standard"
                        className={classes.sortWidgetSelect}
                        value={sortCriterionId}
                        onChange={applySortCriterion}
                        aria-label={"Tri par " + sortCriterion.label}
                        inputProps={{ name: "sort-criterion", id: "sort-criterion", }}
                    >
                        {
                            Object.keys(SORT_CRITERION)
                                .map(criterionId => (
                                    <MenuItem key={criterionId} value={criterionId}>
                                        <ListItemText primary={"Tri par " + SORT_CRITERION[criterionId].label} />
                                    </MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>}
                <div className={classes.productsList}>
                    {
                        isLoading ? (
                            <div className={classes.progressContainer}>
                                <CircularProgress
                                    size={80}
                                    className={classes.circularProgress}
                                />
                            </div>
                        ) : null
                    }
                    {
                        Array.isArray(displayableProducts) && displayableProducts.length > 0 ? (
                            <div className={isWeekly ? classes.weeklyMpItems : classes.items}>
                                {
                                    displayableProducts.map(item =>
                                        <LazyLoad key={item.itemId} height={isWeekly ? 185 : 228} scrollContainer="main" overflow={true} offset={isWeekly ? 185 : 228}>
                                            <MealPlannerCard
                                                item={item}
                                                selected={selectedProductIds.includes(item.itemId)}
                                                onClick={onClick}
                                                isWeekly={isWeekly}
                                            />
                                        </LazyLoad>
                                    )
                                }
                            </div>
                        ) : !isLoading ?
                            <Typography className={classes.warning} variant="h6" align="left">
                                Oups ! Aucun produit ne correspond à vos critères ;&#40;
                            </Typography>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

MealPlannerMenu.propTypes = {
    selection: PropTypes.arrayOf(
        PropTypes.string
    ),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            itemId: PropTypes.string
        })
    ),
    onClick: PropTypes.func,
    classes: PropTypes.object,
    applyFilters: PropTypes.func,
    applySearch: PropTypes.func,
    internalTags: PropTypes.array,
    productionSchemasOptions: PropTypes.array,
    isWeekly: PropTypes.bool
}

export default MealPlannerMenu
