import React from "react"
import { Formik, Form } from "formik"
import { TextField, Stack, InputLabel } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { getTimezoneDate } from "../../utils"
import dayjs from "dayjs"
import * as Yup from "yup"

const ScheduledSubstitutionPeriodForm = ({
	formId,
	formerValues,
	handleSubmit
}) => {

	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={{
				...formerValues,
				startDate: getTimezoneDate(dayjs.utc().startOf("day")),
				endDate: getTimezoneDate(dayjs.utc().endOf("day"))
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={Yup.object().shape({
				originalSupplierItem: Yup.string().required(),
				replacementSupplierItem: Yup.string().required(),
				startDate: Yup.string().required("Veuillez sélectionner une date de début"),
				endDate: Yup.string().required("Veuillez sélectionner une date de fin")
			})}>
			{({ values, setFieldValue, errors }) => {
				return (
					<Form
						id={formId}>
						<Stack gap={5}>
							<Stack>
								<InputLabel>Date de début de la période</InputLabel>
								<DatePicker
									showToolbar={false}
									value={values.startDate}
									name="startDate"
									inputFormat="DD/MM/YY"
									disablePast
									renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.productionDate} InputLabelProps={{ shrink: true }} />}
									onChange={(date) => {
										setFieldValue("startDate", getTimezoneDate(date))
									}}
								/>
							</Stack>
							<Stack>
								<InputLabel>Date de fin de la période</InputLabel>
								<DatePicker
									showToolbar={false}
									value={values.endDate}
									name="endDate"
									inputFormat="DD/MM/YY"
									disablePast
									renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.productionDate} InputLabelProps={{ shrink: true }} />}
									onChange={(date) => {
										setFieldValue("endDate", getTimezoneDate(date))
									}}
								/>
							</Stack>
						</Stack>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ScheduledSubstitutionPeriodForm