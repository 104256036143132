import React, { useRef } from "react"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
  Box
} from "@mui/material"
import { useDispatch } from "react-redux"

import ReportOrderRDForm from "./ReportOrderRDForm"
import { clearSearchRecipesAutocomplete, downloadRecipesReport } from "../../../actions/Recipe/recipes"

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: 8,
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
    padding: 8,
    maxWidth: 664
  }
})

export const ReportOrderRDDialog = ({ onClose, open }) => {
  const dispatch = useDispatch()
  const formikRef = useRef(null)

  const handleConfirm = () => {
    formikRef.current.submitForm()

    // not close the dialog if empty product or any arror
    const products = formikRef.current.values.products
    if (formikRef.current.errors.products?.length || !products.length) return
    onClose()
  }

  const handleCancel = () => {
    dispatch(clearSearchRecipesAutocomplete())
    onClose()
  }

  const handleSubmitOrderReport = (values) => {
    dispatch(downloadRecipesReport(values))
    dispatch(clearSearchRecipesAutocomplete())
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Générer un rapport de commande R&D
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ajouter les produits pour lesquels vous souhaitez générer un rapport
          de commande R&D, ainsi que la quantité associée à chaque produit.
        </DialogContentText>
        <Box sx={{ mt: 4 }}>
          <ReportOrderRDForm
            ref={formikRef}
            onSubmit={handleSubmitOrderReport}
          />
        </Box>
      </DialogContent>
      <DialogActions className="flexRow spaceBetween">
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Générer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ReportOrderRDDialog
