import React, { useEffect, useCallback, useState } from "react"
import { TextField, debounce } from "@mui/material"

const UnhencedTextField = ({ onChange, value, debounceDelay = 500, ...rest }) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const _debounceOnChange = useCallback(
    debounce((event) => {
      onChange && onChange(event)
    }, debounceDelay),
    []
  )
  const _onChange = (event) => {
    setInputValue(event.target.value)
    _debounceOnChange(event)
  }

  return (
    <TextField onChange={_onChange} value={inputValue} {...rest} />
  )
}

export default UnhencedTextField