import React from "react"
import ProductionTab from "../ProductionTabs"
import TransformationModes from "./TransformationModes"

export const TransformationModesTab = () => {
    return (
        <ProductionTab tabValue="transformationModes">
            <TransformationModes />
        </ProductionTab>
    )
}

export default TransformationModesTab