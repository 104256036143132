import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"
import { COLORS } from "../../../utils"

const useStyles = makeStyles(() => ({
    container: {
        color: COLORS.BLACK,
        boxShadow: "0 0 3px rgba(0,0,0,.2)",
        backgroundColor: "white",
        padding: "15px 30px 25px 30px",
        display: "flex",
        flexDirection:"column",
        justifyContent: "space-around",
        alignItems: "center",
        height: 237,
        width: 474
    },
    title: {
        fontSize: 20,
        fontFamily: "Roboto",
        fontWeight: 500
    },
    filterContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    siteSelector: {
		marginTop: 5,
		width: 130
	},
    buttonBloc: {
        marginTop: 21,
    },
    submitButton: {
		
		width: 328,
		height: 38,
		borderRadius: 0
	}
}))

const OrdersSiteSelector = (props) => {
    const {
        sites = [],
        onSubmit
    } = props

    const classes = useStyles()

    const [selectedSite, setSelectedSite] = useState(sites[0])


    const renderSites = () => {
        return (
            sites.map((item, i) => (
                <MenuItem
                    value={item}
                    key={i}
                >
                    {item.name}
                </MenuItem>
            ))
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                Sélectionnez le site
            </div>
            <div className={classes.filterContainer}>
                <InputLabel>Site de réception</InputLabel>
                <Select
                    value={selectedSite}
                    name="site"
                    className={classes.siteSelector}
                    onChange={(e) => setSelectedSite(e.target.value)}
                    variant="standard"
                >
                    {renderSites()}
                </Select>
            </div>
            <div className={classes.buttonBloc}>
                <Button
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={() => onSubmit(selectedSite)}
                >
                    voir les commandes
                </Button>
            </div>
        </div>
    )
}

export default OrdersSiteSelector