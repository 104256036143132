const defaultState = {
    familiesIngredients: []
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "CLASSIFICATIONS_FAMILIES_LOADED":
            return {
                ...state,
                familiesIngredients: action.familiesIngredients,
            }
        case "CLASSIFICATIONS_FAMILY_SAVED":
            return {
                ...state,
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getFamilyIngredient(state, id) {
    return state.familiesIngredients.familiesIngredients.find(familyIngredient => familyIngredient.objectId === id)
}
