import React from "react"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"

import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import OrderReceptionHeaderTitle from "./OrderReceptionHeaderTitle"
import OrderReceptionHeaderInfo from "./OrderReceptionHeaderInfo"

const useStyles = makeStyles({
    root: {
        zIndex: 1000,
        maxHeight: "fit-content",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw"
    },
})

const OrderReceptionHeader = ({ onBack }) => {
    const classes = useStyles()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)

    return (
        <div className={classes.root}>
            <GenericTopBarHeader
                leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                title={<OrderReceptionHeaderTitle sx={{ fontSize: 16 }}/>}
                info={orderReception && <OrderReceptionHeaderInfo info={`/ ... / Commande n°${orderReception.orderNumber}`}/>}
            />
        </div>
    )
}

export default OrderReceptionHeader