import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import Chip from "@mui/material/Chip"
import { makeStyles } from "@mui/styles"
import { KFC_BRANDS } from "../../utils"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const useStyles = makeStyles({
    logo: {
        width: 16,
        marginBottom: -4
    },
    chip: {
        height: "auto",
    },
})

const BrandsSelect = (props) => {
    const { classes, productBrands, value, noLabel,fullWidth = false, label = "Marques", error, ...otherProps } = props

    const currentClasses = useStyles()

    return (
        <FormControl className={classes?.formControl} fullWidth={fullWidth} variant="standard" error={!!error}>
            <InputLabel>{label}</InputLabel>
            <Select
                variant="standard"
                multiple={true}
                name="brand"
                label="Marque"
                id="brand"
                {...otherProps}
                fullWidth
                renderValue={selected => (
                    <div className={classes?.chips}>
                        {
                            selected.includes("ALL") &&
                            productBrands.find(elem => elem.name === "ALL").label
                        }
                        {
                            !selected.includes("ALL") &&
                            selected.map(value => {
                                const brand = KFC_BRANDS.find(elem => elem.name === value)
    
                                return <Chip
                                    key={value}
                                    label={(
                                        <>
                                            {
                                                brand.image &&
                                                <img className={classes ? classes.logo : currentClasses.logo} src={brand.image} alt={`Logo ${brand.label}`} />
                                            }
                                            {
                                                !noLabel &&
                                                brand.label
                                            }
                                        </>
                                        )}
                                    className={classes?.chip ? classes.chip : currentClasses.chip}
                                />
                            })
                        }
                    </div>
                )}
                value={value}
                MenuProps={MenuProps}
            >
                {
                    productBrands.map(elem =>
                        <MenuItem key={elem.name} value={elem.name}>
                            <Checkbox checked={value.indexOf(elem.name) > -1 || value.includes("ALL")}  color="secondary" />
                            <ListItemText primary={(
                                <>
                                    {
                                        elem.image &&
                                        <img className={classes ? classes.logo : currentClasses.logo} src={elem.image} alt={`Logo ${elem.label}`} />
                                    }
                                    {elem.label}
                                </>
                                )}
                            />
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default BrandsSelect
