import React from "react"
import { withStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll",
        zIndex: 1300,
        height: "100%"
    },
    title: {
        margin: 16
    },
    buttonsContainer: {
    },
    cancelButton: {
        position: "relative",
        float: "left",
        margin: 16,
        backgroundColor: "#009900",
        "&:hover": {
            color: "#009900"
        }

    },
    confirmButton: {
        position: "relative",
        float: "right",
        margin: 16,
        backgroundColor: "#990000",
        border: 1,
        "&:hover": {
            color: "#990000"
        }
    }
}

export const ConfirmDelete = ({role, onConfirm, onCancel, classes}) => {
    return (
        <div className={classes.container}>
            <h2 className={classes.title}>
                Êtes-vous sûr(e) de vouloir supprimer le profil { role.name} ?
            </h2>
            <div className={classes.buttonsContainer}>
                <Button
                    className={classes.cancelButton}
                    onClick={onCancel}
                >
                    Annuler
                </Button>
                <Button
                    className={classes.confirmButton}
                    onClick={onConfirm}>
                    Oui, je supprime
                </Button>
            </div>
        </div>
    )
}

const StyledConfirmDelete = withStyles(styles)(ConfirmDelete)
export default StyledConfirmDelete
