import React from "react"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

const RoundFormControlLabel =  props => {
    const {
        label,
        labelPlacement,
        checked,
        onChange,
        classes
    } =  props

    return (
        <FormControlLabel
            className={classes.formControl}
            control={
                <Switch
                    classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        track: checked ? classes.switchTrackChecked : classes.switchTrack,
                    }}
                    color="default"
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    )
}

export default RoundFormControlLabel