import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import IngredientsSupplierItems from "../../components/Ingredients/IngredientsSupplierItems"
import { getAssociatedSupplierItems } from "../../reducers/Ingredients/IngredientsPage"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        padding: "40px 0",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        justifyContent: "center",
        height: "fit-content"
    },
    firstColumnContainer: {
        width: "75%",
        margin: "0 16px",
        minWidth: 500,
        maxWidth: 1000,
    },
    secondColumnContainer: {
        width: "25%",
        margin: "0 16px",
        minWidth: 275,
        maxWidth: 300
    },
    blocContainer: {
        marginTop: 20
    }
}))

const IngredientConnections = () => {
    const classes = useStyles()

    const associatedSupplierItems = useSelector(getAssociatedSupplierItems)

    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <div className={classes.blocContainer}>
                    <IngredientsSupplierItems supplierItems={associatedSupplierItems} />
                </div>
            </div>
        </div>
    )
}

export default IngredientConnections