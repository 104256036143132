import React, { useState } from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../../utils"
import TextField from "@mui/material/TextField"

const useStyles = makeStyles({
    modal: {
        position: "absolute",
        width: 600,
        minHeight: 183,
        background: COLORS.WHITE,
        textAlign: "justify",
        color: COLORS.BLACK,
        padding: "25px 28px",
        fontSize: 16,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 25
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "24px",
        paddingBottom: 15
    },
    text: {
        fontSize: 16,
        color: COLORS.DARK_GREY
    },
    fieldContainer: {
        width: "100%",
        marginTop: 23,
        "& > div": {
            width: "100%"
        }
    },
    firstButton: {
        marginRight: 10,
    }
})

const OrderCommentModal = (props) => {
    const {
        actions,
        onClose,
        open,
        isOrderCancel
    } = props

    const classes = useStyles()

    const [comment, setComment] = useState(null)

    const onConfirm = () => {
        actions({
            method: "VALIDATE",
            data: {
                comment
            }
        })
        onClose()
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modal}>
                <div className={classes.title}>
                    {isOrderCancel ? "Mettre à jour la commande" : "Commander"}
                </div>
                {isOrderCancel
                    ? (
                        <div className={classes.text}>
                            Voulez vous valider le mise à jour de cette commande ? Une demande de mise à jour sera envoyée par mail au fournisseur. Vous pouvez ajouter un commentaire qui apparaîtra sur le bon de commande.
                        </div>
                    ) : (
                        <div className={classes.text}>
                            Voulez-vous valider le passage de cette commande ? <br />
                            Si oui, vous pouvez ajouter un commentaire qui apparaîtra sur le bon de commande.
                        </div>
                    )
                }
                <div className={classes.fieldContainer}>
                    <TextField
                        variant="standard"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder={"Commentaire (facultatif)"}
                        inputProps={{
                            maxLength: 200 
                        }}
                    />
                </div>
                <div className={classes.actions}>
                    <Button
                        color="primary"
                        onClick={onClose}
                        variant={isOrderCancel ? "text" : "outlined"}
                        className={classes.firstButton}
                    >
                        Annuler
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={onConfirm}
                    >
                        {isOrderCancel ? "Mettre à jour la commande" :  "Commander"}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default OrderCommentModal
