import Parse from "parse"
import moment from "moment"
import dayjs from "dayjs"
import copy from "copy-to-clipboard"

import {
    simpleAction,
    actionWithLoader,
    getReplacingPath,
    haveAccessRight,
    push,
    onEnter
} from "../Utils/utils"
import { updateMenu } from "../Utils/app"
import {
    rightReadPlanningSell,
    rightReadPlanningPrev,
    rightReadPlanningPackaging,
    rightReadPlanningProd
} from "../../utils/accessRights"
import {
    createDayInfosFormat,
    isAlreadyAdded,
    createNewProductionCardObject,
    createNewPackagingCardObject,
    updateProductionCardObject,
    updatePackagingCardObject,
    updateProdDataColumns,
    formatProductionItemDataToSend,
    countExpectedProduction,
    getPlanningSections
} from "../../utils/planning"
import {
    getSellPlanningItemData,
    getProductionPlanningItemData,
    getProductionPlanningDayData,
    getPackagingPlanningItemData,
    getProductsDifficultiesGesters,
    getProductsGestersPots,
    getDayInfoByDate,
    createProductionDay,
    updateProductionDay,
    updateExpectedProduction,
    updatePackagingProduction,
    getPlanningReportRules,
    getPrevPlanningItemData,
    updatePrevisionsPlanningValidationState,
    deleteProductionItems,
    getProductionItemByDatesAndItem,
    getProductionItemByPackagingDates
} from "../../parseManager/planning/parsePlanningManager"
import {
    getServerUrl,
    forEachBrand
} from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import isEqual from "lodash/isEqual"
import cloneDeep from "lodash/cloneDeep"
import {
    downloadFile
} from "../Utils/utils"
import { getRecipeDlc, getRecipeImage, getRecipePrice } from "../../utils/recipes"
import {
    getSubcontractorProductById,
    getSubcontractorProductForPlanning
} from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { getRecipeById, getRecipeForPlanning } from "../../parseManager/recipe/parseRecipeManager"
import { getDistributionCentersByBrand } from "../../parseManager/planning/parsePlanningDashboardManager"
import { SUCY_ID } from "../../parseManager/site/parseSiteManager"
import { computePrevisionsReusableProdApi, getComputeReusableProdSum, getReportQuantities } from "../../api/data"
import { expectedProductionModifOrigin } from "../../utils/production"
import { downloadSupplierItemsByReportCsv } from "../Supplier/supplierItems"
import { getProductTypeOptions, loadProductTypeOptions } from "../ProductTypes/productTypes"
import { WITH_MANAGEMENT_MODE_FILTER } from "../../utils/productTypesUtils"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import { loadProductionSchemas } from "../ProductionSchemas/ProductionSchemas"
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter")


export function loadPlanningSetting() {

}

async function getPrevInfo(start, end) {
    try {
        const url = `${getServerUrl()}/planningPrev/targetSums?startDate=${start}&endDate=${end}`
        const res = await axiosCall("get", url, null, { "Content-Type": "application/json" })
        return res && Array.isArray(res.data) ? res.data : []
    } catch (err) {
        return Promise.reject(err)
    }
}

/*
***    subtract and add one day because server request is exclusive
*/
async function addPrevToSpecificDate(obj, start) {
    try {
        const startDate = moment.utc(start).subtract(1, "day").startOf("day").valueOf()
        const endDate = moment.utc(start).add(1, "day").startOf("day").valueOf()
        await addPrevInfoToDataPlanning(startDate, endDate, { dataPlanning: [obj] })
    } catch (err) {
        return Promise.reject(err)
    }
}

async function addPrevInfoToDataPlanning(startDate, endDate, formatedData) {
    try {
        startDate = moment.utc(startDate).subtract(1, "day").startOf("day").valueOf()
        endDate = moment.utc(endDate).add(1, "day").startOf("day").valueOf()
        const prevs = await getPrevInfo(startDate, endDate)
        for (const prev of prevs) {
            const index = formatedData.dataPlanning.findIndex(planningDay => planningDay.date === prev.date)
            if (index >= 0) {
                formatedData.dataPlanning[index].prev = prev.targets
            }
        }
    } catch (err) {
        return Promise.reject(err)
    }
}

function loadDaysFromLocalStorage() {
    const item = localStorage.getItem("Planning/Sell/FirstDay")

    if (null !== item) {
        const firstDay = JSON.parse(item).timestamp
        localStorage.removeItem("Planning/Sell/FirstDay")

        return {
            startDate: moment(firstDay).utc({}).startOf("day").valueOf(),
            endDate: moment(firstDay).utc({}).add(6, "days").startOf("day").valueOf()
        }
    }

    return null
}

export function loadSellPlanning(saveStartDate) {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            let startDate
            let endDate
            let daysSize

            if (isEqual(saveStartDate, {})) {
                const localStorageDays = loadDaysFromLocalStorage()

                if (null === localStorageDays) {
                    startDate = moment.utc(saveStartDate).startOf("day").valueOf()
                    endDate = moment.utc(saveStartDate).add(14, "days").startOf("day").valueOf()
                    daysSize = 15
                } else {
                    startDate = localStorageDays.startDate
                    endDate = localStorageDays.endDate
                    daysSize = 7
                }
            } else {
                startDate = moment.utc(saveStartDate).startOf("day").valueOf()
                endDate = moment.utc(saveStartDate).add(6, "days").startOf("day").valueOf()
                daysSize = 7
            }
            // load product types
            await dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER))
            const productTypeOptions = getProductTypeOptionsSelector(getState())

            const data = await getSellPlanningItemData(startDate, endDate)
            const formatedData = await createPlanningData(daysSize, data, null, startDate, "SELL", productTypeOptions)
            await addPrevInfoToDataPlanning(startDate, endDate, formatedData)
            dispatch(updateMenu(false))
            dispatch({
                type: "LOAD_SELL_PLANNING_DATA",
                sellDays: formatedData.days,
                sellDataPlanning: formatedData.dataPlanning
            })
        }
        catch (e) {
            console.log("error on loading sell planning data : ", e)
        }
    })
}

export function findProduct(card) {
    return actionWithLoader(async (dispatch) => {
        let rightProduct

        if (card.itemType === "Recipe") rightProduct = await getRecipeForPlanning(card.itemId)
        if (card.itemType === "SubcontractorProduct") rightProduct = await getSubcontractorProductForPlanning(card.itemId)

        dispatch({
            type: "PLANNING_RIGHT_PRODUCT_LOADED",
            rightProduct
        })

    })
}

export function removeRightProduct() {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "PLANNING_RIGHT_PRODUCT_REMOVED"
        })
    })
}

export function setAnyFocused(focused) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "PLANNING_INPUT_FOCUSED",
            focused
        })
    })
}

export function loadPrevsPlanning(saveStartDate) {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            let startDate
            let endDate
            const daysSize = 5

            if (isEqual(saveStartDate, {})) {
                startDate = moment.utc().startOf("day").valueOf()
                endDate = moment.utc().add(4, "days").startOf("day").valueOf()
            }
            else {
                startDate = moment.utc(saveStartDate).startOf("day").valueOf()
                endDate = moment.utc(saveStartDate).add(4, "days").startOf("day").valueOf()
            }

            const state = getState()
            const hubs = await getDistributionCentersByBrand(state.planning.brand.name)
            const data = await getPrevPlanningItemData(startDate, endDate)

            const formatedData = createPrevPlanningData(daysSize, data, startDate, endDate)

            dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER))
            dispatch(updateMenu(false))
            dispatch({
                type: "LOAD_PREVISIONS_PLANNING_DATA",
                prevsDays: formatedData.days,
                prevsDataPlanning: formatedData.dataPlanning,
                hubs
            })
        }
        catch (e) {
            console.log("error on loading sell planning data : ", e)
        }
    })
}

export function loadProductionPlanning(saveStartDate) {
    return actionWithLoader(async (dispatch) => {
        try {
            let startDate
            let endDate
            const daysSize = 7

            let usedDate = dayjs().utc()

            if (!isEqual(saveStartDate, {})) {
                usedDate = dayjs(saveStartDate).utc()
            }

            startDate = usedDate.startOf("day").valueOf()
            endDate = usedDate.add(daysSize - 1, "days").startOf("day").valueOf()

            // load product types
            await dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER))

            const daysData = await getProductionPlanningItemData(startDate, endDate)
            const recipeIds = [...new Set(daysData.filter(data => data.itemType === "Recipe").map(data => data.itemId))]

            const [difficultiesGesters, daysInfosData, rules] = await Promise.all([
                getProductsDifficultiesGesters(recipeIds),
                getProductionPlanningDayData(startDate, endDate),
                getPlanningReportRules(),
            ])

            const formatedData = await createPlanningData(daysSize, daysData, daysInfosData, startDate, "PRODUCTION")
            dispatch(updateMenu(false))
            dispatch({
                type: "LOAD_PRODUCTION_PLANNING_DATA",
                prodDays: formatedData.days,
                prodDataPlanning: formatedData.dataPlanning,
                rules: rules,
                difficultiesGesters
            })
        }
        catch (e) {
            console.log("error on load production planning data : ", e)
        }
    })
}

export function loadPackagingPlanning(saveStartDate) {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            let startDate
            let endDate
            const daysSize = 7

            let usedDate = dayjs().utc()

            if (!isEqual(saveStartDate, {})) {
                usedDate = dayjs(saveStartDate).utc()
            }

            startDate = usedDate.startOf("day").valueOf()
            endDate = usedDate.add(daysSize - 1, "days").startOf("day").valueOf()

            await dispatch(loadProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER))
            const productTypeOptions = getProductTypeOptionsSelector(getState())

            const data = await getPackagingPlanningItemData(startDate, endDate)
            const recipeIds = []
            data.forEach(el => el.productionItems.forEach(productionItem => {
                if (productionItem.itemType) {
                    recipeIds.push(productionItem.itemId)
                }
            }))

            const gestersPots = await getProductsGestersPots([...new Set(recipeIds)])
            const formatedData = await createPlanningData(daysSize, data, null, startDate, "PACKAGING", productTypeOptions)

            dispatch(updateMenu(false))
            dispatch({
                type: "LOAD_PACKAGING_PLANNING_DATA",
                packagingDays: formatedData.days,
                packagingDataPlanning: formatedData.dataPlanning,
                gestersPots
            })
        }
        catch (e) {
            console.log("error on load packaging planning data : ", e)
        }
    })
}

export function loadPlanningSpecificDay(days, dataPlanning, date, oldDay, planningType) {
    return actionWithLoader(async (dispatch) => {
        try {
            let daysData
            let daysInfos
            let rules

            if (planningType === "SELL") {
                daysData = await getSellPlanningItemData(date, date)
                daysInfos = null
            }
            else if (planningType === "PRODUCTION") {
                daysData = await getProductionPlanningItemData(date, date)
                daysInfos = await getProductionPlanningDayData(date, date)
                rules = await getPlanningReportRules()
            }
            else {
                daysData = await getPackagingPlanningItemData(date, date)
                daysInfos = null
            }

            const newDay = {
                day: moment.utc(date).format("YYYY-MM-DD"),
                timestamp: moment.utc(date).valueOf()
            }

            let dayData
            let cards

            if (planningType === "SELL") {
                dayData = daysData.filter(el => el.saleDate === date)
                cards = orderSellPlanningCards(dayData)
            }
            else if (planningType === "PRODUCTION") {
                dayData = daysData.filter(el => el.productionDate === date)
                cards = await orderProductionPlanningCards(dayData)
                const arrayProduction = dayData.map(elem => elem.printKitchen)
                const arrayReport = dayData.map(elem => elem.printReport)

                newDay.reportHasAlreadyBeenPrinted = arrayReport.includes(true) || arrayReport.includes(false)
                newDay.printReport = arrayReport.length > 0 ? arrayReport.reduce((old, elem) => old && elem) : false
                newDay.hasAlreadyBeenPrinted = arrayProduction.includes(true) || arrayProduction.includes(false)
                newDay.print = arrayProduction.length > 0 ? arrayProduction.reduce((old, elem) => old && elem) : false
            }
            else {
                dayData = daysData.filter(el => el.packagingDate === date)
                cards = orderPackagingPlanningCards(dayData)
                const printArr = dayData.filter(elem => elem.productionItems && elem.productionItems[0]).map(elem => elem.productionItems[0].printPackaging)
                newDay.hasAlreadyBeenPrinted = printArr.includes(true) || printArr.includes(false)
                newDay.print = printArr.length > 0 ? printArr.reduce((old, elem) => old && elem) : false
            }

            /*
            * Generate new column id
            * if oldDay and first column id equal col0 then new column id will equal col-1
            * if !oldDay and last column id equal col10 then new column id will equal col11
            */
            const newId = oldDay ? `col${parseInt(dataPlanning[0].id.split("col")[1]) - 1}` : `col${parseInt(dataPlanning[dataPlanning.length - 1].id.split("col")[1]) + 1}`

            const obj = {
                id: newId,
                date: date,
                capacities: createDayInfosFormat(date, daysInfos),
                cards: cards
            }
            await addPrevToSpecificDate(obj, date)
            let newDays
            let newDataPlanning

            if (oldDay) {
                newDays = [
                    newDay,
                    ...days
                ]

                newDataPlanning = [
                    obj,
                    ...dataPlanning
                ]
            }
            else {
                newDays = [
                    ...days,
                    newDay
                ]

                newDataPlanning = [
                    ...dataPlanning,
                    obj
                ]
            }
            dispatch(updateMenu(false))
            if (planningType === "SELL") {
                dispatch({
                    type: "LOAD_SELL_PLANNING_DATA",
                    sellDays: newDays,
                    sellDataPlanning: newDataPlanning
                })
            }
            else if (planningType === "PRODUCTION") {
                dispatch({
                    type: "LOAD_PRODUCTION_PLANNING_DATA",
                    prodDays: newDays,
                    prodDataPlanning: newDataPlanning,
                    rules: rules
                })
            }
            else {
                dispatch({
                    type: "LOAD_PACKAGING_PLANNING_DATA",
                    packagingDays: newDays,
                    packagingDataPlanning: newDataPlanning
                })
            }
        }
        catch (e) {
            console.log("error on loading specific planning data : ", e)
        }
    })
}

function orderSellPlanningCards(data, productTypeOptions) {
    const finalData = {}
    forEachBrand(brand => finalData[brand.name] = {})
    const allOptions = getPlanningSections(productTypeOptions, false)
    const sections = allOptions.filter(option => option.key !== "ALL")

    for (const i in sections) {
        const currentSection = sections[i]
        forEachBrand(brand => finalData[brand.name][currentSection.key] = [])

        for (const j in data) {
            const currentData = data[j]
            if (currentData.productType === currentSection.key) {
                finalData[currentData.brand][currentSection.key].push(currentData)
            }
        }

        forEachBrand(brand => {
            finalData[brand.name][currentSection.key].sort(function (a, b) {
                if (a.commercialName < b.commercialName) return -1
                else if (a.commercialName > b.commercialName) return 1
                return 0
            })
        })
    }

    return finalData
}

async function orderProductionPlanningCards(data) {
    const finalData = {}
    const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
    const sections = productTypeOptions.filter(el => el.type !== "ALL")

    for (const i in sections) {
        const currentSection = sections[i]
        finalData[currentSection.type] = []

        for (const j in data) {
            const currentData = data[j]
            if (currentData.productType === currentSection.type) {
                if (isAlreadyAdded(finalData[currentSection.type], currentData.itemId)) {
                    const productionCardObject = finalData[currentSection.type].find(el => el.itemId === currentData.itemId)
                    updateProductionCardObject(productionCardObject, currentData)
                }
                else {
                    const newProductionCardObject = createNewProductionCardObject(currentData)
                    finalData[currentSection.type].push(newProductionCardObject)
                }
            }
        }

        finalData[currentSection.type].sort(function (a, b) {
            if (a.name < b.name) return -1
            else if (a.name > b.name) return 1
            return 0
        })
    }

    return finalData
}

function orderPrevsPlanningCards(data) {
    const finalData = {}
    forEachBrand(brand => finalData[brand.name] = [])

    for (const i in data) {
        const currentData = data[i]
        if (currentData.brand === "FOODCHERI" && currentData.prev) {
            currentData.prev = currentData.prev.filter(prev => prev.productType !== "YAOURT")
        }

        finalData[currentData.brand].push(currentData)
    }

    for (const i in finalData) {
        finalData[i].sort((a, b) => a.priority - b.priority)
    }

    return finalData
}

const orderPackagingPlanningCards = (data, productTypeOptions) => {
    const finalData = {}
    const sections = productTypeOptions.filter(option => option.type !== "ALL")

    for (const i in sections) {
        const currentSection = sections[i]
        finalData[currentSection.type] = []

        for (const j in data) {
            const currentData = data[j]

            if (currentData.productionItems && currentData.productionItems[0]) {
                currentData.productType = currentData.productionItems[0].productType

                if (currentData.productType === currentSection.type) {
                    if (isAlreadyAdded(finalData[currentSection.type], currentData.itemId)) {
                        const packagingCardObject = finalData[currentSection.type].find(el => el.itemId === currentData.itemId)
                        updatePackagingCardObject(packagingCardObject, currentData)
                    }
                    else {
                        const newPackagingCardObject = createNewPackagingCardObject(currentData)
                        finalData[currentSection.type].push(newPackagingCardObject)
                    }
                }
            }
        }

        finalData[currentSection.type].sort(function (a, b) {
            if (a.name < b.name) return -1
            else if (a.name > b.name) return 1
            return 0
        })
    }

    return finalData
}

async function createPlanningData(daysSize, daysData, daysInfos, startDate, planningType, productTypeOptions) {
    const days = []
    const dataPlanning = []
    let i = 0

    while (i < daysSize) {
        const currentDay = moment.utc(startDate).add(i, "day").valueOf()
        const day = {
            day: moment.utc(startDate).add(i, "days").format("YYYY-MM-DD"),
            timestamp: currentDay,
        }
        let cards
        let dayData

        if (planningType === "SELL") {
            dayData = daysData.filter(el => el.saleDate === currentDay)
            cards = orderSellPlanningCards(dayData, productTypeOptions)
        }
        else if (planningType === "PRODUCTION") {
            dayData = daysData.filter(el => el.productionDate === currentDay)
            cards = await orderProductionPlanningCards(dayData)
            const dayDataFiltered = dayData.filter(elem => elem.itemType === "Recipe")
            const arrayProduction = dayDataFiltered.map(elem => elem.printKitchen)
            const arrayReport = dayDataFiltered.map(elem => elem.printReport)

            const currentDayInfo = daysInfos.filter(elem => elem.date === currentDay)
            day.isLocked = !!(currentDayInfo && currentDayInfo[0] && currentDayInfo[0].isLocked)
            day.reportHasAlreadyBeenPrinted = arrayReport.includes(true) || arrayReport.includes(false)
            day.printReport = arrayReport.length > 0 ? arrayReport.reduce((old, elem) => old && elem) : false
            day.hasAlreadyBeenPrinted = arrayProduction.includes(true) || arrayProduction.includes(false)
            day.print = arrayProduction.length > 0 ? arrayProduction.reduce((old, elem) => old && elem) : false
        }
        else if (planningType === "PACKAGING") {
            dayData = daysData.filter(el => el.packagingDate === currentDay)
            cards = orderPackagingPlanningCards(dayData, productTypeOptions)
            const tmp = dayData.filter(elem => elem.productionItems && elem.productionItems[0]).map(elem => elem.productionItems[0].printPackaging)

            day.hasAlreadyBeenPrinted = tmp.includes(true) || tmp.includes(false)
            day.print = Array.isArray(tmp) && tmp.length > 0 ? tmp.reduce((old, elem) => old && elem) : false
        }

        const obj = {
            id: `col${i}`,
            date: moment.utc(startDate).add(i, "day").valueOf(),
            capacities: createDayInfosFormat(currentDay, daysInfos),
            cards: cards
        }

        dataPlanning.push(obj)
        days.push(day)
        i += 1
    }
    return {
        days,
        dataPlanning
    }
}

function createPrevPlanningData(daysSize, daysData, startDate) {
    const days = []
    const dataPlanning = []
    let i = 0

    while (i < daysSize) {
        const currentDay = moment.utc(startDate).add(i, "day").valueOf()
        const day = {
            day: moment.utc(startDate).add(i, "days").format("YYYY-MM-DD"),
            timestamp: currentDay
        }
        let cards
        let dayData

        dayData = daysData.filter(el => el.date === currentDay)
        cards = orderPrevsPlanningCards(dayData)

        const obj = {
            id: `col${i}`,
            date: moment.utc(startDate).add(i, "day").valueOf(),
            cards: cards,
            totalCards: {}
        }

        forEachBrand(brand => obj.totalCards[brand.name] = cards[brand.name].length)

        dataPlanning.push(obj)
        days.push(day)
        i++
    }
    return {
        days,
        dataPlanning
    }
}

export function updateInternCapacity(saveStartDay, value, item, productTypeData) {
    return actionWithLoader(async (dispatch) => {
        const dayInfo = await getDayInfoByDate(item.date)
        if (!dayInfo) {
            await createProductionDay(value, item, productTypeData)
        }
        else {
            await updateProductionDay(dayInfo, value, productTypeData)
        }

        dispatch(loadProductionPlanning(saveStartDay))
    })
}

export function updateProductionCard(saveStarDay, newProductionDate, item) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/mealPlanner/_updateProductionItems`

            const data = formatProductionItemDataToSend(item, newProductionDate)

            const result = await axiosCall(
                "PUT",
                url,
                {
                    type: "productionDate",
                    data: data
                },
                { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }

            dispatch(loadProductionPlanning(saveStarDay))
        }
        catch (e) {
            return Promise.reject(e)
        }
    })
}

export function updateProductionCardOnDragAndDrop(sourceDate, destinationDate, cardItem) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const prodDataPlanning = state.planning.prodDataPlanning

        const newProdDataPlanning = updateProdDataColumns(sourceDate, destinationDate, prodDataPlanning, cardItem)

        const url = `${getServerUrl()}/mealPlanner/_updateProductionItemsProductionDate`

        const data = formatProductionItemDataToSend(cardItem, destinationDate)

        const result = await axiosCall("PUT", url, { type: "productionDate", data, deleteProductionItem: false }, { "Content-Type": "application/json" })

        if (result.status !== 200) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
            })
            return
        }

        dispatch({
            type: "LOAD_PROD_DATA_PLANNInG",
            prodDataPlanning: newProdDataPlanning,
        })
    })
}

export function updateProductPackagingPackagingDate(packagingDate, productPackaging) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/mealPlanner/_updateProductPackaging`

            const result = await axiosCall(
                "PUT",
                url,
                {
                    productPackaging,
                    packagingDate
                },
                { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }
        }
        catch (e) {
            return Promise.reject(e)
        }
    })
}

export function updatePackagingCard(saveStartDay, newPackagingDate, item) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/mealPlanner/_updateProductionItems`
            let data = []

            for (const i in item.originalCards) {
                const toAddData = item.originalCards[i].productionItems.map(el => ({
                    id: el.objectId,
                    newPackagingDate: newPackagingDate
                }))

                data = [...data, ...toAddData]

                // upadate productPackaging
                dispatch(updateProductPackagingPackagingDate(newPackagingDate, item.originalCards[i]))
            }

            // update productionItems
            const result = await axiosCall(
                "PUT",
                url,
                {
                    type: "packagingDate",
                    data: data
                },
                { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }

            dispatch(loadPackagingPlanning(saveStartDay))
        }
        catch (e) {
            return Promise.reject(e)
        }
    })
}

export function savePrevisionsCards(cards, date, brand, prevChanged) {
    return actionWithLoader(async (dispatch) => {
        if (Array.isArray(cards) && cards.length > 0) {
            const url = `${getServerUrl()}/planningPrev/save`
            const data = {
                planningPrevCards: cards,
                brand: brand.name
            }

            const result = await axiosCall("POST", url, data, { "Content-Type": "application/json" })
            if (result.status === 200) {
                if (prevChanged.length > 0) {
                    dispatch({
                        type: "RESET_PREV_CHANGED"
                    })

                    await Promise.all(prevChanged.map(async changed => {
                        await computePrevisionsReusableProdApi(changed)

                        return changed
                    }))
                }
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "success", message: "Enregistrement validé !" }
                })
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue lors de la sauvegarde des prévisions" }
                })
            }
        }

        dispatch(loadPrevsPlanning(date))
    })
}

export function updatePrevisionsCardsValidationState(cards, status, date) {
    return actionWithLoader(async (dispatch) => {
        if (Array.isArray(cards) && cards.length > 0) {
            await updatePrevisionsPlanningValidationState(cards, status)
        }

        dispatch(loadPrevsPlanning(date))
        dispatch(downloadCsvPrevsPlanning(date))
    })
}

export function downloadCsvPrevsPlanning(date) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/planningPrev/extract?date=${date}`
            const response = await axiosCall("GET", url, null, { "Content-Type": "application/json" }, "Blob")
            if (response.status === 200 && response.data) {
                const fileName = `Extrait_prévisions_du_${moment(date).format("DD-MM-YYYY")}_au_${moment.utc(date).add(5, "day").format("DD-MM-YYYY")}.csv`
                const csvURL = window.URL.createObjectURL(new Blob([response.data]))
                downloadFile(csvURL, fileName)
            } else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: {
                        open: true,
                        duration: 5000,
                        type: "error",
                        message: "Erreur lors du téléchargement de l'extract."
                    }
                })
            }
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: {
                    open: true,
                    duration: 5000,
                    type: "error",
                    message: "Erreur lors du téléchargement de l'extract."
                }
            })
        }
    })
}

export function createPlanningPrevCard(saveStartDate, startDate, endDate, brand) {
    return actionWithLoader(async (dispatch) => {
        if (!startDate || !endDate) {
            return
        }

        try {
            const url = `${getServerUrl()}/planningPrev/`
            const data = {
                startDate: startDate,
                endDate: endDate,
                brand: brand.name
            }

            const response = await axiosCall("PUT", url, data, { "Content-Type": "application/json" })

            if (response.status === 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "success", message: "Les cartes de prévisions ont bien été générées" }
                })
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la génération des cartes de prévisions" }
                })
            }

            dispatch(loadPrevsPlanning(saveStartDate))
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la génération des cartes de prévisions" }
            })
        }

        dispatch(loadPrevsPlanning(saveStartDate))
    })
}

let lastActionId = 0

function nextActionId() {
    return ++lastActionId
}

/**
 * @param saveStartDate
 * @param value the value manually entered by the user
 * @param productionItemId
 * @returns {function(*=, *=): Promise<void>}
 */
export function saveExpectedProduction(saveStartDate, value, productionItemId, card) {
    return simpleAction(async dispatch => {
        // we save the actionId but we don't keep it on the closure because
        // we only check for it when we call saveSumExpectedProduction
        lastActionId = nextActionId()
        const origin = expectedProductionModifOrigin.PROD_PLANNING
        dispatch({
            type: "LOADING_EXPECTED_PRODUCTION",
            productionItemIds: [productionItemId]
        })

        await updateExpectedProduction(value, productionItemId, origin)

        dispatch({
            type: "SAVING_EXPECTED_PRODUCTION_DONE",
            productionItemIdAndValues: [{ id: productionItemId, value }],
            card
        })

    })
}

export function saveAllIfCurrentActionId(data, loadingProductionItemIds, actionId, card) {
    return simpleAction(async dispatch => {

        if (actionId === lastActionId) {
            const origin = expectedProductionModifOrigin.REPORT
            dispatch({
                type: "UPDATED_CARD_PRODUCTION_ITEM",
                changed: data
            })

            dispatch({
                type: "LOADING_EXPECTED_PRODUCTION",
                productionItemIds: Object.keys(data)
            })
            await Promise.all(Object.keys(data).map(async key => {
                await updateExpectedProduction(data[key], key, origin)
                return key
            }))

            dispatch({
                type: "SAVING_EXPECTED_PRODUCTION_DONE",
                productionItemIdAndValues: Object.keys(data).map(id => ({ id, value: data[id] })),
                card
            })
        } else {
            dispatch({
                type: "REMOVE_LOADING_EXPECTED_PRODUCTION",
                productionItemIds: loadingProductionItemIds
            })
        }
    })
}

/**
 * 
 * @param {object} data Comes from the data server
 * @param {*} productionItemIds 
 * @param {*} card 
 * @returns 
 */
export function saveSumExpectedProduction(data, productionItemIds, card) {
    return simpleAction(async (dispatch) => {
        try {
            const actionId = nextActionId()
            lastActionId = actionId

            let loadingIds = productionItemIds
            let result
            if (data.newProd) {
                //------------------------------------------------------------------------------//
                //--------------- Not FC / newProd = value for any other brand -----------------//
                //------------------------------------------------------------------------------//
                const productionItemIds = [data.productionItemId]
                loadingIds = productionItemIds

                dispatch({
                    type: "LOADING_EXPECTED_PRODUCTION",
                    productionItemIds
                })
                result = await getReportQuantities(data)
            } else {
                if (data.locked) {
                    //-----------------------------------------------------------------------------//
                    //--------------- locked = value for on line of FC production -----------------//
                    //-----------------------------------------------------------------------------//          
                    const productionItemIds = [data.prodReuId]
                    loadingIds = productionItemIds
                    dispatch({
                        type: "LOADING_EXPECTED_PRODUCTION",
                        productionItemIds
                    })
                }

                result = await getComputeReusableProdSum(data)
            }
            if (result) {
                if (Object.keys(result).length > 0) {
                    dispatch(saveAllIfCurrentActionId(result, loadingIds, actionId, card))
                } else {
                    if (!data.newProd && !data.locked) {
                        dispatch({
                            type: "REMOVE_LOADING_EXPECTED_PRODUCTION",
                            productionItemIds: loadingIds
                        })
                    }
                }
            } else {
                dispatch({
                    type: "REMOVE_LOADING_EXPECTED_PRODUCTION",
                    productionItemIds: loadingIds
                })
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }
        }
        catch (e) {
            dispatch({
                type: "REMOVE_LOADING_EXPECTED_PRODUCTION",
                productionItemIds: productionItemIds
            })
            return Promise.reject(e)
        }
    })
}


export function removeProductionItemIdFromSaved(productionItemId) {
    return simpleAction(async (dispatch) => {
        dispatch({
            type: "REMOVE_SAVED_EXPECTED_PRODUCTION",
            productionItemId
        })
    })
}

export function saveProd(value, id, card) {
    return actionWithLoader(async (dispatch) => {
        await updatePackagingProduction(value, id)

        dispatch({
            type: "SAVING_PACKAGING_PRODUCTION_DONE",
            cardId: card.id,
            cardProductType: card.productType,
            productPackagingIdAndValues: [{ id, value }]
        })
    })
}

export function saveSplitProduction(saveStartDay, data) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/mealPlanner/_splitProductionItems`
            const newData = { data, siteId: SUCY_ID }

            const result = await axiosCall("POST", url, newData, { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }

            dispatch(loadProductionPlanning(saveStartDay))
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
            })
            return Promise.reject(e)
        }
    })
}

export function saveSplitPackaging(saveStartDay, data) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/mealPlanner/_splitProductPackaging`

            const result = await axiosCall("POST", url, { data: data }, { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }

            dispatch(loadPackagingPlanning(saveStartDay))
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
            })
            return Promise.reject(e)
        }
    })

}

export function downloadExtract(field, minDate, maxDate, filePart) {
    return actionWithLoader(async (dispatch) => {
        const url = `${getServerUrl()}/productionItems/csv?min-${field}=${minDate}&max-${field}=${maxDate}`
        const result = await axiosCall("POST", url, null, { "Content-type": "application/csv" })
        if (result.status === 200) {
            const fileName = `Extrait_de_${filePart}_du_${moment.utc(minDate).format("DD-MM-YYYY")}_au_${moment.utc(maxDate).format("DD-MM-YYYY")}.csv`
            const csvURL = window.URL.createObjectURL(new Blob([result.data]))
            downloadFile(csvURL, fileName)
        } else {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de l'extract." }
            })
        }
    })
}

export function downloadBread(start, end) {
    return actionWithLoader(async (dispatch) => {
        const url = `${getServerUrl()}/planningPrev/exportType?startDate=${start}&endDate=${end}&type=BREAD`
        const result = await axiosCall("GET", url, null, { "Content-type": "application/csv" })

        if (result.status === 200) {
            const fileName = `Commande_pain_du_${moment.utc(start).format("DD-MM-YYYY")}_au_${moment.utc(end).format("DD-MM-YYYY")}.csv`
            const csvURL = window.URL.createObjectURL(new Blob([result.data]))
            downloadFile(csvURL, fileName)
        } else {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la commande." }
            })
        }
    })
}

export const getProductionStartAndEndDates = (startDate, endDate) => {

    let productionStartDate = dayjs(startDate).clone()
    let productionEndDate = dayjs(endDate).clone()
    while (dayjs(productionStartDate).day() !== 4) { // 4 === thursday
        productionStartDate = dayjs(productionStartDate).subtract(1, "day")
    }
    while (dayjs(productionEndDate).day() !== 3) { // 3 === wednesday
        productionEndDate = dayjs(productionEndDate).add(1, "day")
    }
    return {
        productionStartDate: productionStartDate.valueOf(),
        productionEndDate: productionEndDate.valueOf()
    }
}

export const getProductionWeeksFromPeriod = (productionStartDate, productionEndDate) => {
    const productionWeeks = []

    let current = dayjs(productionStartDate)// Start from adjusted production start date

    // Loop until current date exceeds production end date
    while (current.isBefore(productionEndDate)) {
        const clonedStart = current.clone()
        productionWeeks.push({
            start: current.valueOf(),
            end: clonedStart.add(6, "day").endOf("day").valueOf(), // End of the production week (Wednesday)
            readableStart: current.format("DD/MM/YYYY"),
            readableEnd: current.add(6, "day").format("DD/MM/YYYY")
        })

        // Move to the next Thursday
        current = current.add(1, "week").startOf("day")
    }

    const prevStart = dayjs(productionStartDate).subtract(7, "days").startOf("day").valueOf()
    const prevEnd = dayjs(productionStartDate).subtract(1, "days").endOf("day").valueOf()

    // to account for prev day data and next day data, we add previous week and next week
    productionWeeks.unshift({
        start: prevStart,
        end: prevEnd,
        readableStart: dayjs(prevStart).format("DD/MM/YYYY"),
        readableEnd: dayjs(prevEnd).format("DD/MM/YYYY"),
        isPrev: true
    })

    const nextStart = dayjs(productionEndDate).add(1, "days").startOf("day").valueOf()
    const nextEnd = dayjs(productionEndDate).add(7, "days").endOf("day").valueOf()

    productionWeeks.push({
        start: nextStart,
        end: nextEnd,
        readableStart: dayjs(nextStart).format("DD/MM/YYYY"),
        readableEnd: dayjs(nextEnd).format("DD/MM/YYYY"),
        isNext: true
    })

    return productionWeeks
}

export const splitProductionItemsByProductionWeek = (productionItems, weeks, dateField = "productionDate") => {

    dayjs.extend(isSameOrBefore)
    dayjs.extend(isSameOrAfter)

    const productionItemsChunks = []
    for (const week of weeks) {
        const productionItemsForThisWeek = productionItems.filter(productionItem => {
            const productionOrPackagingDate = productionItem[dateField]
            return (dayjs(productionOrPackagingDate).isAfter(week.start)) && (dayjs(productionOrPackagingDate).isSameOrBefore(week.end))
        })
        const productionItemsWithWeek = productionItemsForThisWeek.map((productionItem) => ({ ...productionItem, productionWeek: week }))
        if (productionItemsForThisWeek.length > 0) {
            productionItemsChunks.push(productionItemsWithWeek)
        }
    }
    return productionItemsChunks
}

const orderReportInclude = [
    // reusable production steps
    "recipe.sections.productionSteps.step.productionSteps",
    "recipe.sections.productionSteps.step.productionSteps.stepComponents.supplierItem",

    // standard production steps
    "recipe.sections.productionSteps.step.stepComponents.supplierItem",
]

export const orderReport = (startDate, endDate, isPreorderReport = false) => {
    return actionWithLoader(async (dispatch) => {
        // ------------------------------------------------ //
        // --------------- csv download  ------------------ //
        // ------------------------------------------------ //
        const { productionStartDate, productionEndDate } = getProductionStartAndEndDates(startDate, endDate)

        const startDateRef = isPreorderReport ? productionStartDate : startDate
        const endDateRef = isPreorderReport ? productionEndDate : endDate

        const data = []
        const prevDayData = []
        const nextDayData = []
        const packagingData = []

        const newStartDate = dayjs(startDateRef).startOf("day").valueOf()
        const newEndDate = dayjs(endDateRef).endOf("day").valueOf()
        const prevDayStartDateBegin = dayjs(newStartDate).subtract(1, "days").startOf("day").valueOf()
        const prevDayStartDateEnd = dayjs(prevDayStartDateBegin).endOf("day").valueOf()

        const nextDayEndDateBegin = dayjs(newEndDate).add(1, "days").startOf("day").valueOf()
        const nextDayEndDateEnd = dayjs(nextDayEndDateBegin).endOf("day").valueOf()

        // SEE KFC-2037
        // for the report, we need to split the production items by production weeks
        // production week start on a wenesday and end on a tuesday
        // we include prev and next day
        const productionWeeks = getProductionWeeksFromPeriod(newStartDate, newEndDate)

        const [recipeProductionItems, prevRecipeProductionItems, nextRecipeProductionItems, subContractorProductionItems, recipeProductionItemsWithPackagings] = await Promise.all([
            // recipe productionItems between production date start and end
            getProductionItemByDatesAndItem({
                startDate: newStartDate,
                endDate: newEndDate,
                itemType: "Recipe",
                include: orderReportInclude
            }),
            // recipe productionItems J-1 to productionDate start
            getProductionItemByDatesAndItem({
                startDate: prevDayStartDateBegin,
                endDate: prevDayStartDateEnd,
                itemType: "Recipe",
                include: orderReportInclude
            }),
            // recipe productionItems J+1 to productionDate end
            getProductionItemByDatesAndItem({
                startDate: nextDayEndDateBegin,
                endDate: nextDayEndDateEnd,
                itemType: "Recipe",
                include: orderReportInclude
            }),
            // subcontractorProducts productionItems
            getProductionItemByDatesAndItem({
                startDate: newStartDate,
                endDate: newEndDate,
                itemType: "SubcontractorProduct"
            }),
            // recipes for the packagings
            getProductionItemByPackagingDates({
                startDate: newStartDate,
                endDate: newEndDate
            })
        ])

        const products = [
            { className: "Recipe", type: "recipe", productionItems: recipeProductionItems },
            { className: "Recipe", type: "recipe", productionItems: prevRecipeProductionItems, day: "prev" },
            { className: "Recipe", type: "recipe", productionItems: nextRecipeProductionItems, day: "next" },
            { className: "SubcontractorsProducts", type: "subcontractorProduct", productionItems: subContractorProductionItems },
            { className: "Recipe", type: "recipe", productionItems: recipeProductionItemsWithPackagings, day: "packaging" }
        ]

        for (const product of products) {
            const productIds = product.productionItems.map(productionItem => productionItem.itemId)

            await new Parse.Query(product.className)
                .containedIn("objectId", productIds)
                .each(async parseProduct => {
                    const currentProductionItems = product.productionItems.filter(productionItem => productionItem.itemId === parseProduct.id)
                    const expectedProductions = currentProductionItems.map(productionItem => ({ value: productionItem.expectedProduction }))

                    const dateField = product.day === "packaging" ? "packagingDate" : "productionDate"
                    const productionItemsSplittedByWeeks = splitProductionItemsByProductionWeek(currentProductionItems, productionWeeks, dateField)

                    switch (product.day) {
                        case "prev": {
                            if (isPreorderReport) {
                                for (const chunkProductionItem of productionItemsSplittedByWeeks) {
                                    // since we will look for production items for ingredients with a preparation at day + 1
                                    // we need to display the next production week from the one of the production date of the production item
                                    // so as to display the first week of the restricted date range (newStartDate, newEndDate)
                                    const indexOfProductionWeek = productionWeeks.findIndex(week => week.isPrev)
                                    const productionWeekToDisplay = productionWeeks[indexOfProductionWeek + 1]
                                    prevDayData.push({
                                        commercialName: parseProduct.get("commercialName"),
                                        uniqueCode: parseProduct.get("uniqueCode"),
                                        day: product.day,
                                        data: {
                                            id: parseProduct.id,
                                            productionItems: [] // no need for productionItems
                                        },
                                        type: product.type,
                                        totalVolume: countExpectedProduction(chunkProductionItem.map(productionItem => ({ value: productionItem.expectedProduction }))),
                                        productionWeek: productionWeekToDisplay
                                    })
                                }
                            }
                            else {
                                prevDayData.push({
                                    commercialName: parseProduct.get("commercialName"),
                                    uniqueCode: parseProduct.get("uniqueCode"),
                                    day: product.day,
                                    data: {
                                        id: parseProduct.id,
                                        productionItems: [] // no need for productionItems
                                    },
                                    type: product.type,
                                    totalVolume: countExpectedProduction(expectedProductions),
                                })
                            }
                        }
                            break
                        case "next": {
                            if (isPreorderReport) {
                                for (const chunkProductionItem of productionItemsSplittedByWeeks) {
                                    // since we will look for production items for ingredients with a preparation at day - 1 
                                    // we need to display the previous production week from the one of the production date of the production item
                                    // so as to display the last week of the restricted date range (newStartDate, newEndDate)
                                    const indexOfProductionWeek = productionWeeks.findIndex(week => week.isNext)
                                    const productionWeekToDisplay = productionWeeks[indexOfProductionWeek - 1]
                                    prevDayData.push({
                                        commercialName: parseProduct.get("commercialName"),
                                        uniqueCode: parseProduct.get("uniqueCode"),
                                        day: product.day,
                                        data: {
                                            id: parseProduct.id,
                                            productionItems: [] // no need for productionItems
                                        },
                                        type: product.type,
                                        totalVolume: countExpectedProduction(chunkProductionItem.map(productionItem => ({ value: productionItem.expectedProduction }))),
                                        productionWeek: productionWeekToDisplay
                                    })
                                }
                            }
                            else {
                                nextDayData.push({
                                    commercialName: parseProduct.get("commercialName"),
                                    uniqueCode: parseProduct.get("uniqueCode"),
                                    day: product.day,
                                    data: {
                                        id: parseProduct.id,
                                        productionItems: [] // no need for productionItems
                                    },
                                    type: product.type,
                                    totalVolume: countExpectedProduction(expectedProductions),
                                })
                            }
                        }
                            break
                        case "packaging": {
                            if (isPreorderReport) {
                                for (const chunkProductionItem of productionItemsSplittedByWeeks) {
                                    packagingData.push({
                                        commercialName: parseProduct.get("commercialName"),
                                        uniqueCode: parseProduct.get("uniqueCode"),
                                        data: {
                                            id: parseProduct.id,
                                            productionItems: chunkProductionItem.map(productionItem => ({
                                                productionItemId: productionItem.objectId,
                                                expectedProduction: productionItem.expectedProduction,
                                                brand: productionItem.brand,
                                                isReusable: productionItem.isReusable,
                                                recipeId: productionItem.itemId
                                            }))
                                        },
                                        type: product.type,
                                        productionWeek: chunkProductionItem[0].productionWeek
                                    })
                                }
                            }
                            else {
                                packagingData.push({
                                    commercialName: parseProduct.get("commercialName"),
                                    uniqueCode: parseProduct.get("uniqueCode"),
                                    data: {
                                        id: parseProduct.id,
                                        productionItems: currentProductionItems.map(productionItem => ({
                                            productionItemId: productionItem.objectId,
                                            expectedProduction: productionItem.expectedProduction,
                                            brand: productionItem.brand,
                                            isReusable: productionItem.isReusable,
                                            productionDate: productionItem.productionDate,
                                            recipeId: productionItem.itemId,
                                        }))
                                    },
                                    type: product.type
                                })
                            }
                        }
                            break
                        // between 2 dates
                        default: {
                            if (isPreorderReport) {
                                for (const chunkProductionItem of productionItemsSplittedByWeeks) {
                                    data.push({
                                        commercialName: parseProduct.get("commercialName"),
                                        uniqueCode: parseProduct.get("uniqueCode"),
                                        data: {
                                            id: parseProduct.id,
                                            productionItems: chunkProductionItem.map(productionItem => ({
                                                productionItemId: productionItem.objectId,
                                                expectedProduction: productionItem.expectedProduction,
                                                brand: productionItem.brand,
                                                isReusable: productionItem.isReusable,
                                                productionDate: productionItem.productionDate,
                                                recipeId: productionItem.itemId
                                            }))
                                        },
                                        type: product.type,
                                        totalVolume: countExpectedProduction(chunkProductionItem.map(productionItem => ({ value: productionItem.expectedProduction }))),
                                        productionWeek: chunkProductionItem[0].productionWeek
                                    })
                                }
                            }
                            else {
                                data.push({
                                    commercialName: parseProduct.get("commercialName"),
                                    uniqueCode: parseProduct.get("uniqueCode"),
                                    data: {
                                        id: parseProduct.id,
                                        productionItems: currentProductionItems.map(productionItem => ({
                                            productionItemId: productionItem.objectId,
                                            expectedProduction: productionItem.expectedProduction,
                                            brand: productionItem.brand,
                                            isReusable: productionItem.isReusable,
                                            productionDate: productionItem.productionDate,
                                            recipeId: productionItem.itemId,
                                        }))
                                    },
                                    type: product.type,
                                    totalVolume: countExpectedProduction(expectedProductions),
                                })
                            }
                        }
                    }
                })
        }

        const formattedStartDate = dayjs(startDateRef).format("DD-MM-YY"), formattedEndDate = dayjs(endDateRef).format("DD-MM-YY"), formattedExtractionDate = dayjs().format("DD-MM-YY HH[h]mm")
        const csvFileName = `Rapport de commande du ${formattedStartDate} au ${formattedEndDate} - Extrait le ${formattedExtractionDate}`
        const reportMetadata = [`Date de début : ${formattedStartDate}`, `Date de fin : ${formattedEndDate}`, `Extrait le ${formattedExtractionDate}`]

        dispatch(downloadSupplierItemsByReportCsv({
            data,
            prevDayData,
            nextDayData,
            packagingData,
            csvFileName,
            reportMetadata,
            startDate: startDateRef,
            endDate: endDateRef,
            isPreorderReport,
            productionWeeks
        }))
    })
}



export function updateShowInfo(value) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "UPDATE_SHOW_INFO",
            showInfo: value
        })

    })
}

export function updateBrand(brand) {
    return actionWithLoader(async (dispatch) => {
        const hubs = await getDistributionCentersByBrand(brand.name)

        dispatch({
            type: "UPDATE_BRAND",
            brand: brand,
            hubs
        })
    })
}

export function closePlanningSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "PLANNING_CLOSE_SNACKBAR",
            planningSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function calculDispatch(days) {
    return actionWithLoader(async (dispatch) => {
        try {
            dispatch({
                type: "CALCUL_DISPATCH_START"
            })
            const url = `${getServerUrl()}/dispatch/init`

            const result = await axiosCall("POST", url, { days: days }, { "Content-Type": "application/json" })

            if (result.status !== 200) {
                dispatch({
                    type: "CALCUL_DISPATCH_END"
                })
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
                })
            }

            dispatch({
                type: "CALCUL_DISPATCH_END"
            })
        }
        catch (e) {
            dispatch({
                type: "CALCUL_DISPATCH_END"
            })
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
            })
        }
    })
}

export function updatePrevisionsCards(showDays, daysToUpdate, brand, diffPercentage, values, currentCard, currentProductType) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/planningPrev/updateCalcul`
            const data = {
                days: daysToUpdate,
                brand: brand.name,
                diffPercentage: parseFloat(diffPercentage),
                values: values,
                idHub: currentCard.idHub,
                productType: currentProductType,
                user: Parse.User.current()
            }

            const response = await axiosCall("POST", url, data, { "Content-Type": "application/json" })

            if (response.status === 200) {
                const copy = cloneDeep(showDays)
                const changed = []

                daysToUpdate.map(day => {
                    if (day.cards[brand.name] && day.cards[brand.name].length > 0) {
                        changed.push(JSON.stringify({ date: day.date, brand: brand.name, productType: currentProductType }))
                    }

                    return day
                })

                for (const i in response.data) {
                    const current = response.data[i]

                    const selectedDay = copy.find(el => el.id === current.id)

                    if (selectedDay) {
                        const index = copy.indexOf(selectedDay)
                        copy.splice(index, 1, current)
                    }
                }

                dispatch({
                    type: "PLANNING_PREVISIONS_UPDATE_CARDS",
                    prevsDataPlanning: copy,
                    prevChanged: changed
                })
            }
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Une erreur est survenue" }
            })
        }
    })
}

export const copyProductionItemToClipboard = (id) => {
    return actionWithLoader(async (dispatch) => {
        try {
            copy(id)
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 3000, type: "info", message: "Le ProductionItem a été copié" }
            })
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Le ProductionItem n'a pas été copié" }
            })
        }
    })
}

export function updateMultipleStocks(firstDay, lastDay, context) {
    return actionWithLoader(async (dispatch) => {
        try {
            let snackbarData = {}

            const result = await axiosCall(
                "PUT",
                `${getServerUrl()}/stocks/updateMultipleFCStocks`,
                { firstDay: firstDay, lastDay: lastDay, context: context },
                { "Content-Type": "application/json" }
            )

            if (200 !== result.status || !result.data) {
                snackbarData = { type: "error", message: "Une erreur est survenue." }
            } else if (result.data.errors && result.data.errors.length) {
                if (result.data.successes && 0 === result.data.successes.length) {
                    snackbarData = { type: "error", message: "La mise à jour des stocks a échoué pour tous les produits." }
                } else {
                    snackbarData = { type: "warning", message: `La mise à jour des stocks a échoué pour ${result.data.errors.length} produits.` }
                }
            } else {
                snackbarData = { type: "success", message: "La mise à jour des stocks a été effectuée avec succès." }
            }

            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, ...snackbarData }
            })
        } catch (e) {
            return Promise.reject(e)
        }
    })
}

export const loadMealPlannerData = (params) => {
    return actionWithLoader(async (dispatch) => {
        const brand = params.brand
        const filters = { ...WITH_MANAGEMENT_MODE_FILTER, brands: [brand] }
        dispatch(loadProductTypeOptions(filters, false))
    })
}


export const loadWeeklyMealPlannerData = () => {
    return actionWithLoader(async dispatch => {
        const filters = { ...WITH_MANAGEMENT_MODE_FILTER }
        await Promise.all([
            dispatch(loadProductTypeOptions(filters, false)),
            dispatch(loadProductionSchemas())
        ])
    })
}
export function createSingleProductionItem(saveStartDay, saleDate, itemId, brand, type) {
    return actionWithLoader(async (dispatch) => {
        try {
            let product = null

            if (type === "Recipe") {
                product = await getRecipeById(itemId)
            }
            else {
                product = await getSubcontractorProductById(itemId, [
                    "chef.image",
                    "name",
                    "subcontractor",
                    "subcontractorCommercialNames.commercialName",
                    "subcontractorCommercialNames.commercialName.allergens",
                    "internalTag"
                ])
            }

            let productImages
            if (type === "SubcontractorProduct") {
                productImages = product.appImage
            }
            else {
                productImages = getRecipeImage(product, brand)
            }
            const price = type === "SubcontractorProduct" ? product.price : getRecipePrice(product, brand)
            const dlcBrand = type === "SubcontractorProduct" ? product.dlc : getRecipeDlc(product, brand)
            const name = type === "SubcontractorProduct" ? product.name && product.name.name ? product.name.name : "" : typeof product.name === "string" ? product.name : ""

            const formatProduct = {
                itemId: product.objectId,
                itemType: type,
                createdAt: product.createdAt,
                updatedAt: product.updatedAt,
                name: name,
                commercialName: product.commercialName,
                brand: brand,
                uniqueCode: product.uniqueCode,
                itemBrand: product.brands || [product.brand],
                productType: product.type,
                internalTag: product.internalTag,
                image: Array.isArray(productImages) && productImages.length > 0 ? productImages[0] : productImages,
                rating: product.rating,
                nutriscore: (product.nutritionInformation && product.nutritionInformation.nutriscore) || null,
                subcontractor: type === "SubcontractorProduct" && product.subcontractor ? product.subcontractor.name : null,
                price: price,
                foodcost: product.foodcost || product.totalCost,
                dlcBrand: dlcBrand,
                season: product.season,
                nationalSend: true,
                sendCapital: true,
                smallRetail: true,
                lunchbag: false
            }

            const url = `${getServerUrl()}/mealPlanner/_createSingleProductionItem`
            const data = {
                saleDate,
                product: formatProduct,
                brand,
                user: Parse.User.current() ? Parse.User.current().get("email") : null,
                siteId: SUCY_ID
            }

            const response = await axiosCall("POST", url, data, { "Content-Type": "application/json" })

            if (response.status === 200) {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "success", message: "Le plat a été mis à la carte" }
                })
                dispatch(loadProductionPlanning(saveStartDay))
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "La mise à la carte du produit a échoué" }
                })
            }
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "La mise à la carte du produit a échoué" }
            })
        }
    })
}

export function deleteProductionItemsPlanning(saveStartDay, productionItemIds) {
    return actionWithLoader(async (dispatch) => {
        try {
            await deleteProductionItems(productionItemIds)
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "success", message: "Les éléments ont bien été supprimés" }
            })
            dispatch(loadProductionPlanning(saveStartDay))
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "La suppression a échouée" }
            })
        }
    })
}

export function onEnterMealPlanner(store) {
    return onEnter({
        store,
        actionThunk: loadMealPlannerData,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterWeeklyMealPlanner(store) {
    return onEnter({
        store,
        actionThunk: loadWeeklyMealPlannerData,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}


export function onEnterPlanningSell(store) {
    return onEnter({
        store,
        actionThunk: loadSellPlanning,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight(rightReadPlanningSell)

    })
}

export function onEnterPlanningPrev(store) {
    return onEnter({
        store,
        actionThunk: loadPrevsPlanning,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight(rightReadPlanningPrev)

    })
}

export function onEnterPlanningProduction(store) {
    return onEnter({
        store,
        actionThunk: loadProductionPlanning,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight(rightReadPlanningProd)

    })
}

export function onEnterPlanningPackaging(store) {
    return onEnter({
        store,
        actionThunk: loadPackagingPlanning,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight(rightReadPlanningPackaging)
    })
}

export function onEnterPlanningSetting(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningSetting,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function showPlanningSell() {
    return push("/planning/sell")
}

export function showPlanningPrev() {
    return push("/planning/prev")
}

export function showPlanningProduction() {
    return push("/planning/production")
}

export function showPlanningPackaging() {
    return push("/planning/packaging")
}

export function showPlanningDispatch() {
    return push("/planning/dispatch")
}
export function showPlanningSetting() {
    return push("/planning/settings")
}

export function showMealPlanner(day, brand) {
    return push(`/mealplanner/${day}/${brand}`)
}

export function showWeeklyMealPlanner(year, week) {
    return push(`/weeklyMealplanner/${year}/${week}`)

}

export const lineCountByBrand = (stateCard, brand) => {
    const brandLines = stateCard.saleDate.find(brandWithTabs => brandWithTabs.brand === brand.name)
    return brandLines ? brandLines.tab.length : 0
}