import { formatGapData, sortOnGap } from "../../utils/receptionGapUtils"
import moment from "moment"

const storage = JSON.parse(localStorage.getItem("searchValue"))

const defaultState = {
	hubs: [],
	receptionGapUncheckedData: [],
	receptionGapCheckedData: [],
	originalReceptionGapData: [],
	nameOrCode: (storage && storage.receptionGap)?storage.receptionGap:"",
	receptionGapSnackBar: { open: false, type: "", message: "", duration: 0 },
	brand: ""
}

export default function (state = defaultState, action) {
	let receptionGapData

	switch (action && action.type) {
		case "RECEPTION_GAP_LOADED":
			receptionGapData = formatGapData(action.originalReceptionGapData, action.startDate, action.endDate)

			return {
				...state,
				hubs: action.hubs,
				startDate: action.startDate,
				endDate: action.endDate,
				nameOrCode: state.nameOrCode,
				brand: action.brand,
				receptionGapUncheckedData: sortOnGap(filter(receptionGapData[0], state.nameOrCode)),
				receptionGapCheckedData: sortOnGap(filter(receptionGapData[1], state.nameOrCode)),
				originalReceptionGapData: receptionGapData
			}
		case "RECEPTION_GAP_FILTER_NAME_OR_CODE_SET":
			receptionGapData = state.originalReceptionGapData

			return {
				...state,
				receptionGapUncheckedData: sortOnGap(filter(receptionGapData[0], action.searchFilter)),
				receptionGapCheckedData: sortOnGap(filter(receptionGapData[1], action.searchFilter)),
				nameOrCode: action.searchFilter
			}
		case "RECEPTION_GAP_EVENT_CHECKED":
			receptionGapData = state.originalReceptionGapData

			// remove to be checked
			const event = action.selectedEvent
			receptionGapData[0].splice(receptionGapData[0].findIndex(elm => JSON.stringify(elm) === JSON.stringify(event)), 1)

			// add to checked
			event.checked = action.checked

			const checkedDate = moment.utc(event.checked.date).format("DD/MM HH:mm")
			event.checker = `${event.checked.user.get("username")} - ${checkedDate}`
			event.comment = (event.checked.comment)?event.checked.comment:""

			receptionGapData[1].push(event)

			return {
				...state,
				receptionGapUncheckedData: sortOnGap(filter(receptionGapData[0], state.searchFilter)),
				receptionGapCheckedData: sortOnGap(filter(receptionGapData[1], state.searchFilter))
			}
		case "FILTER_BRAND_UPDATED":
			receptionGapData = formatGapData(action.originalReceptionGapData, state.startDate, state.endDate)

			return {
				...state,
				brand: action.brand,
				hubs: action.hubs,
				receptionGapUncheckedData: sortOnGap(filter(receptionGapData[0], state.nameOrCode)),
				receptionGapCheckedData: sortOnGap(filter(receptionGapData[1], state.nameOrCode)),
				originalReceptionGapData: receptionGapData
			}
		case "RECEPTION_GAP_CLOSE_SNACKBAR":
		case "RECEPTION_GAP_OPEN_SNACKBAR":
			return {
				...state,
				receptionGapSnackBar: action.receptionGapSnackBar
			}
		default:
			return {
				...state
			}
	}
}


function filter(result, nameOrCode) {
	let newResult

	// filter on name or code
	const regex = new RegExp(nameOrCode, "ig")
	newResult = result.filter(elm =>  (elm.commercialTitle.match(regex) !== null || elm.uniqueCode.match(regex) !== null))

	return newResult
}
