import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Form } from "formik"
import { Stack, MenuItem } from "@mui/material"
import { TextField, FormHelperText, FormControl, InputLabel, Select, Typography, InputAdornment } from "@mui/material"
import FormikIntervalCalendarField from "../../components/form/FormikIntervalCalendarField"
import { getSecondaryDLCLotsSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { loadSecondaryDLCLotsAndSupplierItems } from "../../parseManager/secondaryDLC/parseSecondaryDLCManager"
import { capitalizeFirstLetter } from "../../utils"
import FormikReactDayPickerField from "../../components/form/FormikReactDayPickerField"
import dayjs from "dayjs"
import { dateToTimestamp } from "../../utils"

const secondaryDLCOptions = [
	{ interval: 1, intervalUnit: "day", intervalDirection: "plus", label: "J + 1" },
	{ interval: 3, intervalUnit: "days", intervalDirection: "plus", label: "J + 3" }
]

const SecondaryDLCCreationForm = ({ values, errors, handleChange, handleBlur, setFieldValue }) => {

	const lots = useSelector(getSecondaryDLCLotsSelector)

	const [allLots, setAllLots] = useState(lots)

	const [lotsOptions, setLotsOptions] = useState(lots)
	const [supplierItemsOptions, setSupplierItemsOptions] = useState([])
	const [productionSuppliesOptions, setProductionSuppliesOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState(null)

	const _reloadLots = async (openingDate) => {
		const { lots, supplierItems, productionSupplies } = await loadSecondaryDLCLotsAndSupplierItems({ openingDate })
		setAllLots(lots)
		setLotsOptions(lots)
		setSupplierItemsOptions(supplierItems)
		setProductionSuppliesOptions(productionSupplies)
	}

	// reset other fields on change opening date
	useEffect(() => {
		if (selectedOption) {
			const isAdd = selectedOption.intervalDirection === "plus"
			const newDate = isAdd ? dayjs(values.openingDate).add(selectedOption.interval, selectedOption.intervalUnit) : dayjs().subtract(selectedOption.interval, selectedOption.intervalUnit)
			setFieldValue("secondaryDLC", newDate.valueOf())
		}
		else {
			setFieldValue("secondaryDLC", dateToTimestamp(dayjs(values.openingDate).add(1, "day")))
		}
		setFieldValue("orderSupplierItem", null)
		setFieldValue("supplierItem", null)
		setFieldValue("lot", null)
		setFieldValue("dlc", null)
		setFieldValue("lotNumber", null)
		setFieldValue("name", null)
		setFieldValue("quantity", null)
		// fetch lots and orderSupplierItems options
		_reloadLots(values.openingDate)

	}, [values.openingDate])


	useEffect(() => {
		// save matching productionSupply
		const matchingProductionSupply = productionSuppliesOptions.find(productionSupply => productionSupply.lots.find(lot => lot.objectId === values.lot))
		setFieldValue("productionSupply", matchingProductionSupply?.objectId)
	}, [values.lot])

	useEffect(() => {
		const matchingLotsOptions = allLots.filter(lot => lot.supplierItem.objectId === values.supplierItem)
		setLotsOptions(matchingLotsOptions)
		setFieldValue("lot", null)
	}, [values.supplierItem])

	return (
		<Form>
			<Stack useFlexGap gap="24px">
				<Stack>
					<FormControl variant="standard" margin="none">
						<InputLabel>Nom</InputLabel>
						<Select
							error={!!errors.supplierItem}
							name="supplierItem"
							variant="standard"
							value={values.supplierItem}
							label="supplierItem"
							placeholder="supplierItem"
							onChange={handleChange}
							required
						>
							{supplierItemsOptions.map((supplierItem, index) => (
								<MenuItem key={index} value={supplierItem.objectId}>
									{capitalizeFirstLetter(supplierItem.name)}
								</MenuItem>
							))}
						</Select>
						{errors.supplierItem && (
							<FormHelperText error sx={{ my: 1 }}>
								{errors.supplierItem}
							</FormHelperText>
						)}
					</FormControl>
				</Stack>
				<Stack>
					<FormControl variant="standard" margin="none">
						<InputLabel>Lot (DLC)</InputLabel>
						<Select
							error={!!errors.lot || !!errors.lotNumber || !!errors.dlc || !!errors.orderSupplierItem || !!errors.name}
							name="lot"
							variant="standard"
							value={values.lot}
							label="Lot"
							placeholder="Lot"
							disabled={!values.supplierItem}
							onChange={(e) => {
								handleChange(e)
								const newValue = e.target.value
								const matchingLot = lotsOptions.find(lot => lot.objectId === newValue)
								setFieldValue("dlc", matchingLot?.dlc)
								setFieldValue("lotNumber", matchingLot?.lotNumber)
								setFieldValue("orderSupplierItem", matchingLot?.orderSupplierItem?.objectId)
								setFieldValue("name", matchingLot?.orderSupplierItem?.name)
							}}
							required
						>
							{lotsOptions.map((lot, index) => (
								<MenuItem key={index} value={lot.objectId}>
									{capitalizeFirstLetter(lot.lotNumber)}  ({dayjs(lot.dlc).format("DD/MM/YYYY")})
								</MenuItem>
							))}
						</Select>
						{(errors.lot || errors.lotNumber || errors.dlc || errors.orderSupplierItem || errors.name) && (
							<FormHelperText error sx={{ my: 1 }}>
								{errors.lot || errors.lotNumber || errors.dlc || errors.orderSupplierItem || errors.name}
							</FormHelperText>
						)}
					</FormControl>
				</Stack>
				<Stack direction="row" gap={2} justifyContent="center">
					<FormControl variant="standard" margin="none">
						<FormikReactDayPickerField
							maxWidth={"176px"}
							handleChange={(newValue) => {
								setFieldValue("openingDate", newValue)
							}}
							date={values.openingDate}
							label="Date d'ouverture"
							hasError={!!errors.openingDate}
							errorText={errors.openingDate}
						/>
					</FormControl>
					<Stack alignSelf="end">
						<Typography variant="span" sx={{ marginBottom: "5px" }}>À utiliser avant le </Typography>
					</Stack>
					<FormControl variant="standard" margin="none">
						<FormikIntervalCalendarField
							maxWidth={"176px"}
							options={secondaryDLCOptions}
							handleChange={(newValue) => {
								setFieldValue("secondaryDLC", newValue)
							}}
							afterChangeOption={(option) => {
								const sanitizedOption = {...option}
								delete sanitizedOption.date
								setSelectedOption(sanitizedOption)
							}}
							date={values.secondaryDLC}
							startDate={values.openingDate}
							label="DLC secondaire"
							hasError={!!errors.secondaryDLC}
							errorText={errors.secondaryDLC}
						/>
					</FormControl>
				</Stack>
				<Stack>
					<FormControl variant="standard" margin="none">
						<TextField
							name="quantity"
							label="Quantité"
							value={values.quantity}
							onChange={handleChange}
							onBlur={handleBlur}
							error={!!errors.quantity}
							helperText={errors.quantity}
							variant="standard"
							type="number"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">kg</InputAdornment>
								)
							}}
						/>
					</FormControl>
				</Stack>
			</Stack>
		</Form>
	)

}

export default SecondaryDLCCreationForm