import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import ButtonFlawAction from "./ButtonFlawAction"
import { COLORS } from "../../utils"
import clsx from "clsx"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

const useStyles = makeStyles(() => ({
  withSelection: {
    fontSize: 27,
    fontWeight: "normal",
    lineHeight: "32px",
    marginTop: 81,
    "&.is-tablet": {
      marginTop: 46,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  picker: {
    "& input": {
      fontWeight: "normal",
      fontSize: 60,
      color: COLORS.BLACK_CARD,
      textAlign: "center",
      lineHeight: "70px",
      width: 320,
      marginTop: 26,
      padding: "0 12px",
    },
    "& button": {
      display: "none",
    },
  },
  TextField: {
    "& input": {
      fontWeight: "normal",
      fontSize: 60,
      color: COLORS.BLACK_CARD,
      textAlign: "center",
      lineHeight: "70px",
      width: 380,
      marginTop: 26,
      padding: "0 12px",
    }
  },
}))

const InputFlawDLC = (props) => {
  const { handleValidate, setValue, flawProduct, flawDlc } = props
  const classes = useStyles()

  const [disabled, setDisabled] = useState(true)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const enableInput = (date) => {
    setSelectedDate(date)
    setDisabled(date === "")
    setValue(date.format("DD/MM/YYYY"))
  }

  const openDatePicker = () => {
    setIsDatePickerOpen(true)
    setSelectedDate(null)
  }

  const closeDatePicker = () => {
    setIsDatePickerOpen(false)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection)}>
        Quel est la DLC de <span>{flawProduct}</span> ?
      </div>

      {/* date */}
      <div onClick={openDatePicker}>
        {!isDatePickerOpen && (
          <TextField
            variant="standard"
            className={classes.TextField}
            placeholder="JJ/MM/AAAA"
            value={flawDlc}
          />
        )}
      </div>

      {isDatePickerOpen && (
        <DatePicker
          disableCloseOnSelect={false}
          showToolbar={false}
          renderInput={(params) => <TextField {...params} className={classes.picker} variant="standard"/>}
          variant="inline"
          value={selectedDate}
          open={isDatePickerOpen}
          onClose={closeDatePicker}
          onChange={enableInput}
          inputFormat="DD/MM/YYYY"
        />
      )}

      {/* button submit */}
      <ButtonFlawAction disabled={disabled} isFlaw={true}>
        Confirmer
      </ButtonFlawAction>
    </form>
  )
}

export default InputFlawDLC