import React, { PureComponent } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import moment from "moment"
import { lighten } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import PrintIcon from "@mui/icons-material/Print"
import CloseIcon from "@mui/icons-material/Close"
import { confirm } from "../../react-await-dialog/react-await-dialog"
import { COLORS } from "../../../utils"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  printKitchen: {
    color: COLORS.PRIMARY_COLOR,
  },
  printPackaging: {
    color: COLORS.SECONDARY_COLOR,
  },
})

class ProductionDetailEnhancedTableToolbar extends PureComponent {
  _printProductionDetail = () => {
    const { selected, printProductionDetail, date, productionData } =
      this.props
    const productionDetailsToPrint = productionData.filter((data) => {
      const dataId = data.recipe
        ? data.recipe.id
        : data.subcontractorProduct.id
      if (selected.includes(dataId)) {
        return dataId
      }
      return null
    })
    for (const productionDetail of productionDetailsToPrint) {
      printProductionDetail(date, productionDetail)
    }
  }

  _deleteProductionDetail = async () => {
    const { selected } = this.props
    const message =
      selected.length === 1
        ? "Voulez-vous supprimer cette fiche de production pour cette journée de production ?"
        : "Voulez-vous supprimer ces fiches de productions pour cette journée de production ?"
    if (
      await confirm({
        title: "Confirm",
        message: message,
        confirmText: "SUPPRIMER",
      })
    ) {
      const {
        selected,
        deleteProductionDetail,
        productionId,
        onDeleteItem,
        productionData,
      } = this.props
      const productionDetailsToDelete = productionData.filter((data) => {
        const dataId = data.recipe
          ? data.recipe.id
          : data.subcontractorProduct.id
        if (selected.includes(dataId)) {
          return dataId
        }
        return null
      })
      for (const i in productionDetailsToDelete) {
        onDeleteItem()
        await deleteProductionDetail(
          productionId,
          productionDetailsToDelete[i]
        )
      }
    }
  }

  render() {
    const { numSelected, classes, date, closeProductionDetail } = this.props
    const text =
      numSelected > 1 ? "éléments selectionnés" : "élement sélectioné"

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {text}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Détail fiche de production du :{" "}
              {moment(date).startOf("day").format("DD/MM/YYYY")}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className="flexRow">
          {numSelected > 0 && (
            <div className="flexRow">
              <div className={classes.actions}>
                <Tooltip title="Imprimer fiches" disableInteractive>
                  <IconButton
                    aria-label="Imprimer"
                    onClick={this._printProductionDetail}
                    size="large"
                  >
                    <PrintIcon className={classes.printKitchen} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.actions}>
                <Tooltip title="Supprimer" disableInteractive>
                  <IconButton
                    aria-label="Supprimer"
                    onClick={this._deleteProductionDetail}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          <div className={classes.actions}>
            <Tooltip title="Fermer" disableInteractive>
              <IconButton
                aria-label="Fermer"
                onClick={closeProductionDetail}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    )
  }
}

ProductionDetailEnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

ProductionDetailEnhancedTableToolbar = withStyles(toolbarStyles)(
  ProductionDetailEnhancedTableToolbar
)

export default ProductionDetailEnhancedTableToolbar
