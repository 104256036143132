import React, {
    useState,
    useEffect,
    useCallback
} from "react"
import { useSelector, useDispatch } from "react-redux"
import _isEqual from "lodash/isEqual"
import dayjs from "dayjs"
import {
    closeSupplierSnackBar,
    createNewSupplier,
    showSupplierTab,
} from "../../../actions/Supplier/suppliers"
import { getSuppliersSelector, getSitesSelector, getSuppliersSnackbar } from "../../../reducers/Suppliers/Suppliers/suppliers"
import {
    CAN_UPDATE_SUPPLIER
} from "../../../utils/accessRights"
import DataGrid from "../../../components/DataGrid/DataGrid"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import RoundedAddButton from "../../../components/RoundedAddButton"
import SupplierCreate from "../../../components/Supplier/SupplierCreate"

const SuppliersList = () => {
    const suppliers = useSelector(getSuppliersSelector, _isEqual)
    const sites = useSelector(getSitesSelector, _isEqual)
    const supplierSnackBar = useSelector(getSuppliersSnackbar, _isEqual)
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    const canAdd = useSelector(state => state.app.rights[CAN_UPDATE_SUPPLIER])

    const [data, setData] = useState([])
    const [isCreating, setIsCreating] = useState(false)

    const tableHeaderColumns = [
        {
            key: "id",
            label: "ID",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
        },
        {
            key: "name",
            label: "Nom",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            },
            width: 400,
        },
        {
            key: "state",
            label: "Etat",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: [
                    { key: "active", value: "active", label: "Actif" },
                    { key: "inactive", value: "inactive", label: "Inactif" },
                ]
            },
            render: (state) => {
                if (state === "active") {
                    return <CheckCircleIcon sx={{ color: "#4bb200" }} />
                } else {
                    return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
                }
            },
        },
        {
            key: "updatedAt",
            label: "Date de modification",
            dataType: "date",
            type: "date",
            sortable: true,
            filter: {
                filterable: false,
                type: "date"
            },
        }
    ]

    const dispatch = useDispatch()

    useEffect(() => {
        createDataObject()
    }, [])

    useEffect(() => {
        createDataObject()
    }, [suppliers])

    const createDataObject = useCallback(() => {
        setData(suppliers.map(createData))
    }, [suppliers])

    const createData = (supplier) => {
        return {
            id: supplier.objectId,
            name: supplier.name,
            state: supplier.isActive ? "active" : "Inactive",
            updatedAt: dayjs(supplier.updatedAt).format("DD/MM/YYYY")
        }
    }

    const _showSupplier = (supplierItem) => {
        dispatch(showSupplierTab("general", supplierItem.id))
    }

    const _toggleCreate = () => {
        setIsCreating(!isCreating)
    }

    const _createNewSupplier = useCallback(values => dispatch(createNewSupplier(values)), [])

    const _closeSupplierSnackBar = useCallback(() => dispatch(closeSupplierSnackBar(supplierSnackBar.type)), [supplierSnackBar])

    return (<>
        <DataGrid
            title="Fournisseurs"
            columns={tableHeaderColumns}
            data={data}
            withFilters
            rowLabel="fournisseurs"
            onRowClick={_showSupplier} />
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={supplierSnackBar.open}
            autoHideDuration={supplierSnackBar.duration}
            onClose={_closeSupplierSnackBar}
        >
            <MySnackbarContentWrapper
                onClose={_closeSupplierSnackBar}
                variant={supplierSnackBar.type}
                message={supplierSnackBar.message}
            />
        </Snackbar>
        {
            canAdd &&
            <>
                <RoundedAddButton
                    hoverText="Ajouter un fournisseur"
                    onClick={() => setIsCreating(true)}
                    styles={menuIsOpen ?
                        { left: 262 } :
                        { left: 75 }}
                />
                <SupplierCreate
                    onClose={_toggleCreate}
                    isCreating={isCreating}
                    sites={sites}
                    onSaveSupplier={_createNewSupplier}
                />
            </>
        }
    </>)
}

export default SuppliersList
