import { productType } from "./dispatchUtils"
import { months, recipeNutriscores, recipePreparations, RecipeStatus } from "./recipes"
import { filterOnSomeFields } from "../utils"

export const transformToObjectArray = (arr) => {
	return arr.filter(a => (a)).map(a => {return {value: a, label: a}})
}

export const transformMomentToObjectArray = (m) => {
	return m.map(a => {return {value: a.format("M"), label: a.format("MMMM")}})
}

export const filtersName = [
	"search", "provider", "brands", "types", "status", "isActive", "internalTag", "season", "nutriscore", "price",
	"dlc", "tva", "foodCostPCT", "packaging", "subPackaging", "reusablePackaging", "reusableSubPackaging", "season", "ean", "ingredientsComplexity",
	"grossWeight", "netWeight", "portionPerPlate", "gesters", "difficulty", "preparation", "foodcost","carboneScore", "fibers", "salt", "proteins",
	"sugar", "saturatedFattyAcids", "calories", "carbohydrates", "fat", "carbonFootPrint", "organicIngredientsRate", "frenchIngredientsRate", "certifiedIngredientsRate", "seasonalIngredientsRate"
]

export const topFields = [
    {field: "search", type: "search", placeholder:"Par mot-clé ou code :"},
    {field: "recipe", type: "switch", label: "Produits internes"},
    {field: "subcontractorProduct", type: "switch", label: "Produits sous-traitants"}
]

export const searchFields = [
	{field: "provider", type: "multiselect", label: "FOURNISSEUR :"},
	{field: "brands", type:"multiselect", label: "MARQUE :"},
	{field: "types", type:"multiselect", label: "TYPE :"},
	{field: "status", type:"multiselect", label: "STATUT :"},
	{field: "isActive", type:"multiselect", label: "ETAT :"},
	{field: "internalTag", type:"multiselect", label: "TAGS :"},
	{field: "season", type:"multiselect", label: "SAISONNALITE :"},
	{field: "nutriscore", type:"multiselect", label: "NUTRISCORE :"},
	{field: "price", type:"numeric", label: "PRIX DE VENTE", unit:"€"}
]

export const subSearchFields = [
    {field: "tva", type:"multiselect", label: "TVA :"},
    {field: "ean", type:"search", label: "ean :", placeholder:"EAN"},
    {field: "grossWeight", type:"numeric", label: "Poids brut", unit: "Kg"},
    {field: "netWeight", type:"numeric", label: "Poids net", unit: "Kg"},
    {field: "dlc", type:"numeric", label: "DLC", unit: "J"},
    {field: "foodcost", type:"numeric", label: "foodcost", unit:"€"},
    {field: "foodCostPCT", type:"numeric", label: "ratio foodcost", unit: "%"},
    {field: "ingredientsComplexity", type:"numeric", label: "compléxité d'ingrédient :"},
	{field: "difficulty", type:"multiselect", label: "Difficulté :"},
	{field: "preparation", type:"multiselect", label: "préparation :"},
	{field: "createdAt", type:"date", label: "Date de création :"},
	{field: "modifiedAt", type:"date", label: "Date de modification :"},
	{field: "gesters", type:"numeric", label: "Gestes :"},
	{field: "portionPerPlate", type:"numeric", label: "portion par plaque : "},
	{field: "packaging", type:"multiselect", label: "packaging :"},
	{field: "subPackaging", type:"multiselect", label: "sous packaging :"},
	{field: "reusablePackaging", type:"multiselect", label: "packaging réutilisable :"},
	{field: "reusableSubPackaging", type:"multiselect", label: "sous packaging réutilisable :"},
	{field: "carboneScore", type:"multiselect", label: "score carbone :"},
	{field: "seasonalIngredientsRate", type:"numeric", label: "Légumes et fruits de saison", unit: "%"},
	{field: "certifiedIngredientsRate", type:"numeric", label: "Ingrédients labellisés", unit: "%"},
	{field: "frenchIngredientsRate", type:"numeric", label: "Ingrédients français", unit: "%"},
	{field: "organicIngredientsRate", type:"numeric", label: "Ingrédients bio", unit: "%"},
	{field: "carbonFootPrint", type:"numeric", label: "Empreinte CO2 :"},
	{field: "calories", type:"numeric", label: "Calories :"},
	{field: "saturatedFattyAcids", type:"numeric", label: "Acides gras saturés", unit: "g"},
	{field: "fat", type:"numeric", label: "Lipides", unit: "g"},
	{field: "carbohydrates", type:"numeric", label: "Glucides", unit: "g"},
	{field: "sugar", type:"numeric", label: "Sucres", unit: "g"},
	{field: "proteins", type:"numeric", label: "Protéines", unit: "g"},
	{field: "fibers", type:"numeric", label: "Fibres", unit: "g"},
	{field: "salt", type:"numeric", label: "Sel", unit: "g"}
]

export const csvHeader = {
    id: "id",
    uniqueCode: "uniqueCode",
    name: "nom",
    commercialName: "nom commercial",
    type: "type",
    status: "statut",
    isActive: "etat",
    provider: "fournisseur",
    brands: "marque",
    internalTag: "Tag",
    nutriscore: "nutriscore",
    price: "prix",
    dlc: "dlc",
    tva: "tva",
    foodCostPCT: "ratio foodcost",
    packaging: "packaging",
    subPackaging: "sous packaging",
    reusablePackaging: "packaging réutilisable",
    reusableSubPackaging: "sous packaging réutilisable",
    season: "saison",
    ean: "ean",
    ingredientsComplexity: "compléxité d'ingrédient",
    grossWeight: "poids brut",
    netWeight: "poids net",
    portionPerPlate: "portion par plaque",
    gesters: "Gestes",
    difficulty: "Difficulté",
    preparation: "préparation",
    foodcost: "foodcost",
    carboneScore: "score carbone",
    fibers: "Fibres",
    salt: "Sel",
    proteins: "Protéines",
    sugar: "Sucres",
    saturatedFattyAcids: "Acides gras saturés",
    calories: "Calories",
    carbohydrates: "Glucides",
    fat: "Lipides",
    carbonFootPrint: "Empreinte CO2",
    organicIngredientsRate: "Ingrédients bio",
    frenchIngredientsRate: "Ingrédients français",
    certifiedIngredientsRate: "Ingrédients labellisés",
    seasonalIngredientsRate: "Légumes et fruits de saison"
}

export const getInitialValues = () => {
	const filters = {}
	const fields = [...topFields, ...searchFields, ...subSearchFields]

	fields.map(field => {
		filters[field.field] = (!["search", "ean"].includes(field.field))?
			((field.type === "multiselect")?["ALL"]:[]):
			""

		return field
	})

	return filters
}

export const createData = (data) => {
	const type = productType.find(t => t.value === data.type)
	const status = RecipeStatus.find(t => t.value === data.status)
	const isRecipe = (typeof data.name !== "object")
 
	const ret = {
		id: data.objectId,
		uniqueCode: data.uniqueCode,
		name: !isRecipe?data.name.name:data.name,
		commercialName: data.commercialName,
		type: type.label,
		typeObject: type,
		status: status.label,
		statusValue: status.value,
		isActive: data.isActive ? "Actif" : "Inactif",
		isRecipe: isRecipe,
		provider: isRecipe ? "interne" : data.name && data.name.supplier && data.name.supplier.name,
		brands: isRecipe ? data.brands : [data.brand],
		internalTag: data.internalTag.map(tag => tag.name),
		nutriscore: data.nutritionInformation && data.nutritionInformation.nutriscore,
		price: isRecipe ?
			[...(data.price ? data.price.map(p => p.value) : []), data.defaultValues && data.defaultValues.price] :
			[(data.price) ? data.price : null]
		,
		dlc: isRecipe ?
			[...(data.dlc ? data.dlc.map(p => p.value) : []), data.defaultValues && data.defaultValues.dlc] :
			[(data.dlc) ? data.dlc : null]
		,
		tva: isRecipe && (data.tva || data.defaultValues.tva) ?
			[...(data.tva ? data.tva.map(p => p.value) : []), data.defaultValues && data.defaultValues.tva] :
			[(data.tva) ? data.tva : null]
		,
		foodCostPCT: isRecipe && data.foodCostPCT ?
			[...(data.foodCostPCT ? data.foodCostPCT.map(p => p.value) : [])] :
			[null]
		,
		packaging: isRecipe && data.packaging ?
			[...(data.packaging ? data.packaging.map(p => p.value && p.value.name) : [])] :
			[(data.packaging) ? data.packaging.name : null]
		,
		subPackaging: isRecipe && data.subPackaging ?
			[...(data.subPackaging ? data.subPackaging.map(p => p.value.map(o => o.name)).reduce((a,b) => [...a, ...b], []) : [])] :
			(data.subPackaging) ? data.subPackaging.map(p => p.name) : [null]
		,
		reusablePackaging: isRecipe && data.reusablePackaging ?
			[...(data.reusablePackaging ? data.reusablePackaging.map(p => p.value && p.value.name) : [])] :
			[null]
		,
		reusableSubPackaging: isRecipe && data.reusablePackaging ?
			[...(data.reusableSubPackaging ? data.reusableSubPackaging.map(p => p.value.map(o => o.name)).reduce((a,b) => [...a, ...b], []) : [])] :
			[null]
		,
		season: data.season,
		ean: data.ean,
		ingredientsComplexity: data.ingredientsComplexity,
		grossWeight: data.grossWeight,
		netWeight: data.netWeight,
		portionPerPlate: data.portionPerPlate,
		gesters: data.gesters,
		difficulty: data.difficulty,
		preparation: data.preparation,
		foodcost: isRecipe?data.foodcost:data.name.pricePerPortion,
		carboneScore: data.nutritionInformation && data.nutritionInformation.carboneScore,
		fibers: data.nutritionInformation && data.nutritionInformation.fibers,
		salt: data.nutritionInformation && data.nutritionInformation.salt,
		proteins: data.nutritionInformation && data.nutritionInformation.proteins,
		sugar: data.nutritionInformation && data.nutritionInformation.sugar,
		saturatedFattyAcids: data.nutritionInformation && data.nutritionInformation.saturatedFattyAcids,
		calories: data.nutritionInformation && data.nutritionInformation.calories,
		carbohydrates: data.nutritionInformation && data.nutritionInformation.carbohydrates,
		fat: data.nutritionInformation && data.nutritionInformation.fat,
		carbonFootPrint: data.nutritionInformation && data.nutritionInformation.carbonFootPrint,
		organicIngredientsRate: data.nutritionInformation && data.nutritionInformation.organicIngredientsRate,
		frenchIngredientsRate: data.nutritionInformation && data.nutritionInformation.frenchIngredientsRate,
		certifiedIngredientsRate: data.nutritionInformation && data.nutritionInformation.certifiedIngredientsRate,
		seasonalIngredientsRate: data.nutritionInformation && data.nutritionInformation.seasonalIngredientsRate,
	}

	return ret
}

export const getFilterValues = (
    datas, tags, suppliers, packagings, subPackagings,
    reusablePackagings, reusableSubPackagings, price, gesters,
    portionPerPlate, foodcost, foodCostPCT, grossWeight, netWeight,
    dlc, ingredientsComplexity, fibers, salt, proteins, sugar, saturatedFattyAcids,
    calories, carbohydrates, fat, carbonFootPrint, organicIngredientsRate,
    frenchIngredientsRate, certifiedIngredientsRate, seasonalIngredientsRate
) => {
	const internalTag = transformToObjectArray(tags.map(tag => tag.name).sort())
	const provider = transformToObjectArray(suppliers.map(supplier => supplier.name))
	const packaging = transformToObjectArray(packagings.map(pack => pack.name))
	const subPackaging = transformToObjectArray(subPackagings.map(pack => pack.name))
	const reusablePackaging = transformToObjectArray(reusablePackagings.map(pack => pack.name))
	const reusableSubPackaging = transformToObjectArray(reusableSubPackagings.map(pack => pack.name))

	return {
		internalTag,
		provider,
		packaging,
		subPackaging,
		reusablePackaging,
		reusableSubPackaging,
		isActive: transformToObjectArray(["Actif", "Inactif"]),
		price,
		season: transformMomentToObjectArray(months),
		status: RecipeStatus,
		nutriscore: recipeNutriscores,
		dlc,
		tva: transformToObjectArray([5.5, 10, 20]),
		foodCostPCT,
		ingredientsComplexity,
		grossWeight,
		netWeight,
		portionPerPlate,
		gesters,
		difficulty: transformToObjectArray([1, 2, 3]),
		preparation: recipePreparations,
		foodcost,
		carboneScore: recipeNutriscores,
		fibers,
		salt,
		proteins,
		sugar,
		saturatedFattyAcids,
		calories,
		carbohydrates,
		fat,
		carbonFootPrint,
		organicIngredientsRate,
		frenchIngredientsRate,
		certifiedIngredientsRate,
		seasonalIngredientsRate
	}
}

export const filterLeft = (values, datas) => {
	let filtered = false
	let newDatas = datas

	// type
	if (values.types.length && !values.types.includes("ALL")) {
		newDatas = newDatas.filter(data => values.types.includes(data.typeObject.type))
		filtered = true
	}

	// provider
	if (values.provider.length && !values.provider.includes("ALL")) {
		newDatas = newDatas.filter(data => values.provider.includes(data.provider))
		filtered = true
	}

	// brands
	if (values.brands.length && !values.brands.includes("ALL")) {
		newDatas = newDatas.filter(data => data.brands.some(brand => values.brands.includes(brand)))
		filtered = true
	}

	// status
	if (values.status.length && !values.status.includes("ALL")) {
		newDatas = newDatas.filter(data => values.status.includes(data.statusValue))
		filtered = true
	}

	// isActive
	if (values.isActive.length && !values.isActive.includes("ALL")) {
		newDatas = newDatas.filter(data => values.isActive.includes(data.isActive))
		filtered = true
	}

	// nutriscore
	if (values.nutriscore.length && !values.nutriscore.includes("ALL")) {
		newDatas = newDatas.filter(data => values.nutriscore.includes(data.nutriscore))
		filtered = true
	}

	// season
	if (values.season.length && !values.season.includes("ALL")) {
		newDatas = newDatas.filter(data => data.season.some(season => values.season.includes(season)))
		filtered = true
	}

	// internalTag
	if (values.internalTag.length && !values.internalTag.includes("ALL")) {
		newDatas = newDatas.filter(data => data.internalTag.some(internalTag => values.internalTag.includes(internalTag)))
		filtered = true
	}

	// price
	if (values.price.length) {
		newDatas = newDatas.filter(data => data.price.some(p => p >= values.price[0] && p <= values.price[1]))
		filtered = true
	}

	// difficulty
	if (values.difficulty.length && !values.difficulty.includes("ALL")) {
		newDatas = newDatas.filter(data => values.difficulty.includes(data.difficulty))
		filtered = true
	}

	// preparation
	if (values.preparation.length && !values.preparation.includes("ALL")) {
		newDatas = newDatas.filter(data => values.preparation.includes(data.preparation))
		filtered = true
	}

	// gesters
	if (values.gesters.length) {
		newDatas = newDatas.filter(data => data.gesters >= values.gesters[0] && data.gesters <= values.gesters[1])
		filtered = true
	}

	//portionPerPlate
	if (values.portionPerPlate.length) {
		newDatas = newDatas.filter(data => data.portionPerPlate >= values.portionPerPlate[0] && data.gesters <= values.portionPerPlate[1])
		filtered = true
	}

	// tva
	if (values.tva.length && !values.tva.includes("ALL")) {
		newDatas = newDatas.filter(data => data.tva.some(tva => values.tva.includes(tva)))
		filtered = true
	}

	//foodcost
	if (values.foodcost.length) {
		newDatas = newDatas.filter(data => data.foodcost >= values.foodcost[0] && data.foodcost <= values.foodcost[1])
		filtered = true
	}

	//foodCostPCT
	if (values.foodCostPCT.length) {
		newDatas = newDatas.filter(data => data.foodCostPCT.some(p => p >= values.foodCostPCT[0] && p <= values.foodCostPCT[1]))
		filtered = true
	}

	//ingredientsComplexity
	if (values.ingredientsComplexity.length) {
		newDatas = newDatas.filter(data => data.ingredientsComplexity >= values.ingredientsComplexity[0] && data.ingredientsComplexity <= values.ingredientsComplexity[1])
		filtered = true
	}

	//grossWeight
	if (values.grossWeight.length) {
		newDatas = newDatas.filter(data => data.grossWeight >= values.grossWeight[0] && data.grossWeight <= values.grossWeight[1])
		filtered = true
	}

	//netWeight
	if (values.netWeight.length) {
		newDatas = newDatas.filter(data => data.netWeight >= values.netWeight[0] && data.netWeight <= values.netWeight[1])
		filtered = true
	}

	//dlc
	if (values.dlc.length) {
		newDatas = newDatas.filter(data => data.dlc.some(p => p >= values.dlc[0] && p <= values.dlc[1]))
		filtered = true
	}

	//packaging
	if (values.packaging.length && !values.packaging.includes("ALL")) {
		newDatas = newDatas.filter(data => data.packaging.some(packaging => values.packaging.includes(packaging)))
		filtered = true
	}

	//subPackaging
	if (values.subPackaging.length && !values.subPackaging.includes("ALL")) {
		newDatas = newDatas.filter(data => data.subPackaging.some(subPackaging => values.subPackaging.includes(subPackaging)))
		filtered = true
	}

	//reusablePackaging
	if (values.reusablePackaging.length && !values.reusablePackaging.includes("ALL")) {
		newDatas = newDatas.filter(data => data.reusablePackaging.some(reusablePackaging => values.reusablePackaging.includes(reusablePackaging)))
		filtered = true
	}

	//reusableSubPackaging
	if (values.reusableSubPackaging.length && !values.reusableSubPackaging.includes("ALL")) {
		newDatas = newDatas.filter(data => data.reusableSubPackaging.some(reusableSubPackaging => values.reusableSubPackaging.includes(reusableSubPackaging)))
		filtered = true
	}

	//carboneScore
	if (values.carboneScore.length && !values.carboneScore.includes("ALL")) {
		newDatas = newDatas.filter(data => values.carboneScore.includes(data.carboneScore))
		filtered = true
	}

	//seasonalIngredientsRate
	if (values.seasonalIngredientsRate.length && !values.seasonalIngredientsRate.includes("ALL")) {
		newDatas = newDatas.filter(data => values.seasonalIngredientsRate.includes(data.seasonalIngredientsRate))
		filtered = true
	}

	//certifiedIngredientsRate
	if (values.certifiedIngredientsRate.length && !values.certifiedIngredientsRate.includes("ALL")) {
		newDatas = newDatas.filter(data => values.certifiedIngredientsRate.includes(data.certifiedIngredientsRate))
		filtered = true
	}

	// frenchIngredientsRate
	if (values.frenchIngredientsRate.length && !values.frenchIngredientsRate.includes("ALL")) {
		newDatas = newDatas.filter(data => values.frenchIngredientsRate.includes(data.frenchIngredientsRate))
		filtered = true
	}

	// organicIngredientsRate
	if (values.organicIngredientsRate.length && !values.organicIngredientsRate.includes("ALL")) {
		newDatas = newDatas.filter(data => values.organicIngredientsRate.includes(data.organicIngredientsRate))
		filtered = true
	}

	// carbonFootPrint
	if (values.carbonFootPrint.length && !values.carbonFootPrint.includes("ALL")) {
		newDatas = newDatas.filter(data => values.carbonFootPrint.includes(data.carbonFootPrint))
		filtered = true
	}

	// calories
	if (values.calories.length && !values.calories.includes("ALL")) {
		newDatas = newDatas.filter(data => values.calories.includes(data.calories))
		filtered = true
	}

	// saturatedFattyAcids
	if (values.saturatedFattyAcids.length && !values.saturatedFattyAcids.includes("ALL")) {
		newDatas = newDatas.filter(data => values.saturatedFattyAcids.includes(data.saturatedFattyAcids))
		filtered = true
	}

	// fat
	if (values.fat.length && !values.fat.includes("ALL")) {
		newDatas = newDatas.filter(data => values.fat.includes(data.fat))
		filtered = true
	}

	// carbohydrates
	if (values.carbohydrates.length && !values.carbohydrates.includes("ALL")) {
		newDatas = newDatas.filter(data => values.carbohydrates.includes(data.carbohydrates))
		filtered = true
	}

	// sugar
	if (values.sugar.length && !values.sugar.includes("ALL")) {
		newDatas = newDatas.filter(data => values.sugar.includes(data.sugar))
		filtered = true
	}

	// proteins
	if (values.proteins.length && !values.proteins.includes("ALL")) {
		newDatas = newDatas.filter(data => values.proteins.includes(data.proteins))
		filtered = true
	}

	// fibers
	if (values.fibers.length && !values.fibers.includes("ALL")) {
		newDatas = newDatas.filter(data => values.fibers.includes(data.fibers))
		filtered = true
	}

	// salt
	if (values.salt.length && !values.salt.includes("ALL")) {
		newDatas = newDatas.filter(data => values.salt.includes(data.salt))
		filtered = true
	}

	//ean
	if (values.ean && values.ean.trim() !== "") {
		newDatas = filterOnSomeFields(values.ean.trim(), newDatas, ["ean"])
		filtered = true
	}

	//search
	if (values.search && values.search.trim() !== "") {
		newDatas = filterOnSomeFields(values.search.trim(), newDatas, ["name", "commercialName", "uniqueCode", "id"])
		filtered = true
	}

	return [filtered, newDatas]
}

export const convertToCSV = function(objArray, separator = ";") {
	const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray
	const keys = Object.keys(array[0])
	let str = ""

	for (let i = 0; i < array.length; i++) {
		if (undefined === array[i]) continue
		// we treat each cell to
		// . replace falsy values by an empty string
		// . replace the separator by a space
		const line = keys.map(key => {
			let value = array[i][key]
			if (typeof value === "string" && value.includes(",")) {
				return value.replaceAll(",", ".")
			} else {
				return value || ""
			}
		})
		str += line.join(separator) + "\r\n"
	}

	return str
}
