import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../../utils"
import LeftSection from "./LeftSection"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import SearchAdvancedEnhancedTableHead from "../../../components/table-utils/Products/SearchAdvancedEnhancedTableHead"
import TableBody from "@mui/material/TableBody"
import { getSorting, stableSort } from "../../../components/table-utils/utils"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TablePagination from "../../../components/TablePagination"
import {
  filterLeft,
  getInitialValues,
  csvHeader,
} from "../../../utils/searchAdvancedUtils"
import { exportCSVFile } from "../../../actions/Products/SearchAdvanced"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import ReceiptIcon from "@mui/icons-material/Receipt"
import clsx from "clsx"
import { updateMenu } from "../../../actions/Utils/app"

const useStyle = makeStyles((theme) => ({
  toolbarRoot: {
    paddingRight: theme.spacing(1),
  },
  bar: {
    height: 100,
    backgroudColor: COLORS.PRIMARY_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  joined: {
    display: "flex",
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  column: {
    cursor: "pointer",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  selectActions: {
    composes: "$actions",
    marginTop: 8,
    marginLeft: 10,
    marginRight: 10,
  },
}))

const SearchAdvanced = (props) => {
  const classes = useStyle()
  const { rootRef } = props

  const dispatch = useDispatch()
  const menuIsOpen = useSelector((state) => state.app.menuIsOpen)
  const data = useSelector((state) => state.searchAdvanced.data)
  const searchCount = useSelector((state) => state.searchAdvanced.searchCount)

  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const [page, setPage] = useState(0)
  const [searchOn, setSearchOn] = useState(["recipe", "subcontractorProduct"])
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [filterData, setFilterData] = useState(data)
  const [filterValues, setFilterValues] = useState(getInitialValues)
  const [loading, setLoading] = useState(false)

  //filter states
  const [hasFilter, setHasFilter] = useState(false)

  useEffect(() => {
    if (menuIsOpen) dispatch(updateMenu(false))
  }, [])

  useEffect(() => {
    if (!hasFilter) updateSearchFilter(filterValues)
  }, [hasFilter])

  useEffect(() => {
    updateSearchFilter(filterValues)
  }, [searchOn, searchCount])

  const switchSearchOn = (field) => {
    if (searchOn.includes(field))
      setSearchOn(searchOn.filter((elm) => elm !== field))
    if (!searchOn.includes(field)) setSearchOn([...searchOn, field])
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc"
    setOrder(isDesc ? "asc" : "desc")
    setOrderBy(property)
  }

  const resetSearch = () => {
    setFilterValues(getInitialValues())
    setHasFilter(false)
  }

  const updateSearchFilter = (values) => {
    if (rootRef.current)
      rootRef.current.querySelector("main").scrollTo({ top: 0 })
    let newData = data

    // search on recipe, subcontorProducts or both
    if (!searchOn.includes("subcontractorProduct"))
      newData = newData.filter((data) => data.isRecipe)
    if (!searchOn.includes("recipe"))
      newData = newData.filter((data) => !data.isRecipe)

    const filterResult = filterLeft(values, newData)

    if (filterResult[0]) {
      setHasFilter(filterResult[0])
      newData = filterResult[1]
    }

    setFilterData(newData)
    setPage(0)
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    setPage(0)
  }

  const decrementPage = () => {
    setPage(page - 1)
  }

  const incrementPage = () => {
    setPage(page + 1)
  }

  const showProduct = (event, objectId, isRecipe) => {
    if (isRecipe)
      window.open(`/products/recipe/general/${objectId ? objectId : ""}`)
    if (!isRecipe)
      window.open(
        `/products/subcontractor-product/general/${objectId ? objectId : ""}`
      )
  }

  const exportToCSV = () => {
    dispatch(exportCSVFile(csvHeader, filterData, "export"))
  }

  return (
    <Paper elevation={0}>
      <Grid container className={classes.root} spacing={0}>
        <LeftSection
          hasFilter={hasFilter}
          updateSearch={updateSearchFilter}
          resetSearch={resetSearch}
          setLoading={setLoading}
          setFilterValues={setFilterValues}
          searchOn={searchOn}
          switchSearchOn={switchSearchOn}
        />
        <Grid item lg={9}>
          <Paper elevation={0} className={classes.root}>
            <div className={classes.tableWrapper}>
              <Toolbar className={clsx(classes.toolbarRoot)}>
                {filterData.length > 0 && (
                  <div className={classes.selectActions}>
                    <Tooltip title="Export CSV" disableInteractive>
                      <IconButton
                        aria-label="Export CSV"
                        onClick={exportToCSV}
                        size="large"
                      >
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {filterData.length === 0 && "Aucun résultat"}
              </Toolbar>
              {filterData.length > 0 && (
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <SearchAdvancedEnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={filterData.length}
                  />
                  {!loading && (
                    <TableBody>
                      {stableSort(filterData, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((n) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={false}
                              tabIndex={-1}
                              key={n.id}
                            >
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.uniqueCode}
                              </TableCell>
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.name}
                              </TableCell>
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.commercialName}
                              </TableCell>
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.type}
                              </TableCell>
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.status}
                              </TableCell>
                              <TableCell
                                className={classes.column}
                                onClick={(event) =>
                                  showProduct(event, n.id, n.isRecipe)
                                }
                              >
                                {n.isActive}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  )}
                </Table>
              )}
            </div>
            {filterData.length > 0 && (
              <TablePagination
                rowsPerPage={rowsPerPage}
                dataPage={page}
                data={filterData}
                handleChangeRowPerPage={handleChangeRowsPerPage}
                decrementPage={decrementPage}
                incrementPage={incrementPage}
                choices={[5, 10, 25, 50]}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SearchAdvanced
