import React, { useEffect, useRef, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import moment from "moment"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import DeleteIcon from "@mui/icons-material/Delete"
import clsx from "clsx"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import Parse from "parse"
import { useDispatch } from "react-redux"

import { COLORS, KFC_BRANDS } from "../../utils"
import { copyProductionItemToClipboard, lineCountByBrand } from "../../actions/Planning/Planning"

const useStyles = makeStyles({
  expectedProduction: {
    composes: "$planningBrand",
    marginRight: 5,
    marginTop: 3,
  },
  planningBrand: {
    height: 7,
    width: 7,
    borderRadius: "50%",
    marginLeft: 2,
  },
  reusableExpectedProduction: {
    mask: "url(/img/recycle.svg) no-repeat center",
    width: 10,
    height: 10,
    marginRight: 3,
    marginLeft: 1,
    marginTop: 2,
  },
  saleDateRowInfos: {
    display: "flex",
    "&.saved": {
      background: "none",
      animation: "$pulse 2s ease",
    },
  },
  sellDateLabelButton: {
    fontSize: 12,
    color: COLORS.LIGHT_BLACK,
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    lineHeight: 1.33
  },
  circular: {
    marginLeft: 18,
    animationDuration: "550ms",
  },
  dlcLabel: {
    fontSize: 10,
    color: COLORS.DARK_GREY,
    margin: "auto",
  },
  expectedProductionInputContainer: {
    marginLeft: 5,
  },
  expectedProductionInfoInput: {
    width: 30,
  },
  expectedProductionInputProps: {
    height: 5,
    fontSize: 12,
    textAlign: "right",
  },
  expectedProductionLabel: {
    color: COLORS.BLACK,
  },
  ProductionPlanningInfos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
    flexWrap: "nowrap"
  },
  deleteIcons: {
    color: COLORS.DARK_GREY,
    cursor: "pointer",
    fontSize: 15,
    opacity: 0,
    animation: "$fadeIn 0.5s",
    animationFillMode: "forwards"
  },
  modalCommercialName: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold"
  },
  modalPackagingIcon: {
    marginTop: 6,
  },
  modalSellDate: {
    display: "flex",
    flexDirection: "row"
  },
  "@keyframes pulse": {
    "0%, 100%": { background: "none" },
    "50%": { background: COLORS.GREEN_100 },
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 100 }
  }
})

/**
 * represents a Production Item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ProductionPlanningLine = (props) => {
  const {
    stateCard,
    isReusable, // packaging type
    productionItemData,
    loading,
    saved,
    dlcExist,
    dlc,
    elem,
    number,
    length,
    brandSubTotal,
    canEdit,
    findItemExpectedProduction,
    updateExpectedProduction,
    saveTotal,
    saveExpectedProduction,
    removeFromSaved,
    canDeleteProductionItem,
    deleteProductionItem,
    rules
  } = props

  const classes = useStyles()
  const lineRef = useRef()
  const dispatch = useDispatch()
  const brand = KFC_BRANDS.find((el) => el.name === productionItemData.brand)
  const [pulseVisible, setPulseVisible] = useState(false)
  const [deleteIconDisplayed, setDeleteIconDisplayed] = useState(false)
  const [canBeDeleted, setCanBeDeleted] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteLineModal, setDeleteLineModal] = useState(false)
  const [cantDeleteLine, setCantDeleteLine] = useState(false)
  const expectedProduction = findItemExpectedProduction(productionItemData.id)
  const [multiLinesItemCanBeDeleted, setMultiLinesItemCanBeDeleted] = useState(false)
  const [currentIdsAndQuantity, setCurrentIdsAndQuantity] = useState()
  const ProductionItem = Parse.Object.extend("ProductionItem")

  useEffect(() => {
    const numberOfLineForBrand = lineCountByBrand(stateCard, brand)
    setCantDeleteLine(numberOfLineForBrand === 1 && expectedProduction !== 0)
    setCanBeDeleted((numberOfLineForBrand === 1 && expectedProduction === 0) || (numberOfLineForBrand > 1 && expectedProduction === 0))
    setMultiLinesItemCanBeDeleted(numberOfLineForBrand > 1 && expectedProduction !== 0)
  }, [stateCard, deleteLineModal])

  useEffect(() => {
    if (stateCard.expectedProduction.value !== 0) {
      setCurrentIdsAndQuantity(stateCard.expectedProduction.filter(({ brand }) => brand === "FOODCHERI"))
    }
  }, [stateCard])

  useEffect(() => {
    if (saved) {
      setPulseVisible(true)

      setTimeout(() => {
        setPulseVisible(false)
        removeFromSaved(productionItemData.id)
      }, 2000)
    }
  }, [saved])

  const handleClose = () => {
    setDeleteLineModal(false)
    setAnchorEl(null)
    setDeleteIconDisplayed(false)
  }

  const openDeleteModal = () => {
    setDeleteLineModal(true)
    setAnchorEl(null)
  }

  const deleteLine = async () => {
    handleClose()
    deleteProductionItem([productionItemData.id])
  }

  const getIdsAndQuantity = async () => {
    if (currentIdsAndQuantity) {
      let productionItemToRemove = currentIdsAndQuantity.find(({ id }) => id === productionItemData.id)
      let productionItemToKeep = currentIdsAndQuantity.find(({ id }) => id !== productionItemData.id)
      let quantityToRemove = productionItemToRemove.value
      let quantityToKeep = productionItemToKeep.value
      let newQuantity = quantityToRemove + quantityToKeep
      if (newQuantity) {
        productionItemToKeep.value = newQuantity
        productionItemToRemove.value = 0
        handleClose()
        await updateMultiLineQuantities(productionItemToRemove.value, productionItemToRemove.id)
        await updateMultiLineQuantities(productionItemToKeep.value, productionItemToKeep.id)
        await deleteProductionItem([productionItemData.id])
      }
    }
  }

  const updateMultiLineQuantities = async (value, id) => {
    const data = await new Parse.Query(ProductionItem)
      .equalTo("objectId", id)
      .first()
    data.set("expectedProduction", parseFloat(value))
    await data.save()
  }

  const handleCopyToClipBoard = () => {
    dispatch(copyProductionItemToClipboard(productionItemData.id))
  }
  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  return (
    <div
      ref={lineRef}
      className={clsx(classes.saleDateRowInfos, pulseVisible ? "saved" : "")}
      onMouseEnter={() => setDeleteIconDisplayed(true)}
      onMouseLeave={() => setDeleteIconDisplayed(false)}
    >
      <div className={classes.ProductionPlanningInfos}
        onMouseEnter={() => setDeleteIconDisplayed(true)}
        onMouseLeave={() => setDeleteIconDisplayed(false)}
      >
        {deleteIconDisplayed && canDeleteProductionItem &&
          <DeleteIcon
            id={id}
            open={Boolean(open)}
            anchorEl={anchorEl}
            onClose={handleClose}
            className={classes.deleteIcons}
            onClick={() => openDeleteModal()}
          />}
        {<Dialog
          open={deleteLineModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          className={classes.dialog}
        >
          {canBeDeleted && (
            <>
              <DialogTitle id="alert-dialog-title">
                Confirmer la suppression de cette ligne de production
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Typography>
                  Êtes-vous certains de vouloir supprimer la ligne de production suivante :
                </Typography>
                <br></br>
                <Typography>
                  <div className={classes.modalCommercialName}>
                    <div>
                      {stateCard.commercialName} - {stateCard.uniqueCode}
                    </div>
                  </div>
                </Typography>
                <Typography>
                  Production : {moment.utc(stateCard.productionDate).format("ddd DD/MM")}
                </Typography>
                <Typography>
                  <div className={classes.modalSellDate}>
                    Vente :&nbsp;
                    {!isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.expectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    {isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.reusableExpectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    <div>
                      {moment.utc(productionItemData.value).format("ddd DD/MM")}
                    </div>
                  </div>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="text" onClick={handleClose}>
                  Annuler
                </Button>
                <Button
                  onClick={deleteLine}
                  color="primary"
                  variant="contained"
                >
                  Supprimer
                </Button>
              </DialogActions>
            </>
          )}
          {multiLinesItemCanBeDeleted && (
            <>
              <DialogTitle id="alert-dialog-title">
                Confirmer la suppression de cette ligne de production
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Typography>
                  Êtes-vous certains de vouloir supprimer la ligne de production suivante :
                </Typography>
                <br></br>
                <Typography>
                  <div className={classes.modalCommercialName}>
                    <div>
                      {stateCard.commercialName} - {stateCard.uniqueCode}
                    </div>
                  </div>
                </Typography>
                <Typography>
                  Production : {moment.utc(stateCard.productionDate).format("ddd DD/MM")}
                </Typography>
                <Typography>
                  <div className={classes.modalSellDate}>
                    Vente :&nbsp;
                    {!isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.expectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    {isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.reusableExpectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    <div>
                      {moment.utc(productionItemData.value).format("ddd DD/MM")}
                    </div>
                  </div>
                </Typography>
                <br></br>
                <Typography>
                  Le volume présent sera automatiquement transféré sur l’autre packaging :
                </Typography>
                <Typography>
                  <div className={classes.modalSellDate}>
                    Vente :&nbsp;
                    {!isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.reusableExpectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    {isReusable && (
                      <div className={classes.modalPackagingIcon}>
                        <div
                          className={classes.expectedProduction}
                          style={{ backgroundColor: brand.color }}
                        />
                      </div>
                    )}
                    <div>
                      {moment.utc(productionItemData.value).format("ddd DD/MM")}
                    </div>
                  </div>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="text" onClick={handleClose}>
                  Annuler
                </Button>
                <Button
                  // onClick={setQuantityToZero}
                  onClick={getIdsAndQuantity}
                  color="primary"
                  variant="contained"
                >
                  Supprimer
                </Button>
              </DialogActions>
            </>
          )}
          {cantDeleteLine && (
            <>
              <DialogTitle id="alert-dialog-title">
                Impossible de supprimer car un volume est prévu en production
              </DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Typography>
                  Vous ne pouvez pas supprimer cette ligne de production car le volume prévu en production n'est pas nul.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="text" onClick={handleClose}>
                  Annuler
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        }
        {!isReusable && (
          <div
            className={classes.expectedProduction}
            style={{ backgroundColor: brand.color }}
          />
        )}
        {isReusable && (
          <div
            className={classes.reusableExpectedProduction}
            style={{ backgroundColor: brand.color }}
          />
        )}
        <button className={classes.sellDateLabelButton} type="button" onClick={handleCopyToClipBoard}>
          {moment.utc(productionItemData.value).format("ddd DD/MM")}
        </button>
        {loading && (
          <CircularProgress
            color="secondary"
            size={10}
            thickness={4}
            className={classes.circular}
          />
        )}
        {dlcExist &&
          <div className={classes.dlcLabel}>DLC {dlc}</div>
        }
      </div>
      <div className={classes.expectedProductionInputContainer}>

        {canEdit && (
          <TextField
            variant="standard"
            type="number"
            value={findItemExpectedProduction(productionItemData.id)}
            className={classes.expectedProductionInfoInput}
            // prevent scroll value change
            onWheel={(e) => e.target.blur()}
            inputProps={{
              className: classes.expectedProductionInputProps,
              min: 0,
              step: 1,
            }}
            onChange={(event) => {
              const value = event.target.value
              const single =
                length === 1 && productionItemData.brand !== "FOODCHERI"
                  ? { elem, number, id: productionItemData.id } // one line and it's not FOODCHERI
                  : length > 1
                    ? { // multiple lines
                      elem,
                      number,
                      id: productionItemData.id,
                      brandSubTotal,
                      isReusable,
                    } // one FOODCHERI line
                    : null
              updateExpectedProduction(value, productionItemData.id, true, single)
            }}
            onBlur={(event) => {
              const value = event.target.value
              const currentRule = rules[productionItemData.brand]
              if (length === 1) {
                if (productionItemData.brand !== "FOODCHERI" && currentRule && currentRule.volumeReporting !== null) {
                  saveTotal(value, number, elem, { id: productionItemData.id })
                } else if (productionItemData.id) {
                  saveExpectedProduction(value, productionItemData.id)
                }
              } else {
                saveTotal(value, number, elem, {
                  id: productionItemData.id,
                  brandSubTotal,
                  isReusable,
                })
              }
            }}
          />
        )}
        {!canEdit && (
          <div className={classes.expectedProductionLabel}>
            {findItemExpectedProduction(productionItemData.id)}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductionPlanningLine
