import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import React from "react"
import { createRoot } from "react-dom/client"
import { createStore, applyMiddleware, combineReducers } from "redux"
import { Provider } from "react-redux"
import moment from "moment-timezone"
import "moment/locale/fr"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"

import Parse from "parse"
import { browserHistory } from "react-router"
import { reducer as formReducer } from "redux-form"
import { syncHistoryWithStore, routerReducer, routerMiddleware } from "react-router-redux"
import createThunk from "./reduxThunkError"
import reduxDevToolsComposeEnhancer from "./reduxDevTools"
import appReducers from "./reducers"
import { showError, loginSuccess, connectLoggedUser, showLogin, getDatabaseEnvironment } from "./actions/Utils/app"
import Routes from "./Routes"
import timezone from "dayjs/plugin/timezone"
import fr from "dayjs/locale/fr"

moment.tz.setDefault("Europe/Paris")
moment.locale("fr")
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Paris")
dayjs.locale({
    ...fr,
    weekStart: 1,
})

const init = async () => {

    const location = window.location
    // LOCAL can also mean "accessed by a remote machine (like a Mac) on the local dev network"
    const hostName = location.hostname
    const isLocal = hostName.indexOf("localhost") !== -1
    window.LOCAL = isLocal

    // redirect to https if using http
    if (!isLocal && window.location.protocol === "http:") {
        window.location.href = window.location.href.replace("http:", "https:")
    }

    //---- local init ----//
    moment.locale("fr")

    //---- reducer ----//
    const reducer = combineReducers({
        ...appReducers,
        routing: routerReducer,
        form: formReducer,
    })

    //---- catch error ----//
    const reportError = (error, state, action, dispatch) => {
        console.log("================ error caught =====================")
        showError(error.message)(dispatch)
    }

    const store = createStore(reducer, reduxDevToolsComposeEnhancer(
        applyMiddleware(createThunk(reportError), routerMiddleware(browserHistory))
    ))


    //---------------------------------------------------//
    //------------------- Parse init --------------------//
    //---------------------------------------------------//
    Parse.initialize("kfc")
    const databaseEnv = await getDatabaseEnvironment()
    global.LOCAL_PLUGGED_TO_PROD = false // this flag is used when working on local with prod data
    if (process.env.REACT_APP_ENV === "DEV") {
        Parse.serverURL = "http://localhost:8080/parse"
        global.LOCAL_PLUGGED_TO_PROD = databaseEnv === "PROD"
    } else if (process.env.REACT_APP_ENV === "PREPROD") {
        Parse.serverURL = "https://server-dot-kfc-preprod.appspot.com/parse"
        global.LOCAL_PLUGGED_TO_PROD = databaseEnv === "PROD"
    } else if (process.env.REACT_APP_ENV === "PROD_KITCHEN") {
        Parse.serverURL = "https://kfc-api-kitchen.foodcheri.com/parse"
    } else if (process.env.REACT_APP_ENV === "PROD_PRODUCT") {
        Parse.serverURL = "https://kfc-api-product.foodcheri.com/parse"
    } else if (process.env.REACT_APP_ENV === "PROD_LOGISTIC") {
        Parse.serverURL = "https://kfc-api-logistic.foodcheri.com/parse"
    }

    //---- external Encrypted Session Token ----//
    try {
        const params = (new URL(document.location)).searchParams
        const encryptedSessionToken = params.get("_est")
        if (encryptedSessionToken) {
            const { sessionToken } = await Parse.Cloud.run("decryptSessionToken", { encryptedSessionToken })
            await Parse.User.become(sessionToken)
        }
    } catch (error) {
        console.error("UrlSearchParams not supported in this browser. Use Chrome.")
    }

    //---- loading user and checking token validity ----//
    let currentUser
    try {
        currentUser = Parse.User.current() // it can throw (rarely)
        if (currentUser) {
            // is the session valid ? (some issues might happen)
            try {
                await Parse.Session.current()
            } catch (err) {
                console.log("Error retrieving the session object => 400 but, in fact, invalid token => logout")
                currentUser = null
                throw new Error("Bad session")
            }
        }
    } catch (err) {
        try {
            await Parse.User.logOut()
        } catch (err2) {
            // might happen, but swallowed
        }
    }

    if (currentUser) {
        //we need to refresh the current user in the localStorage (in case the BO changed it)
        await currentUser.fetch()
        await loginSuccess(store.dispatch, store.getState)
        await connectLoggedUser(true)(store.dispatch, store.getState)
    } else if (!["/account/change-password"].includes(location.pathname)) {
        store.dispatch(showLogin()) // event if the history isn't built
    }

    // Create an enhanced history that syncs navigation events with the store
    const history = syncHistoryWithStore(browserHistory, store)

    //---- showBackButton ----//
    let firstDone = false // the first action is a POP (why?)
    const internalHistory = []
    browserHistory.listen(nextLocation => {
        const action = nextLocation.action
        if (!firstDone || action === "PUSH") {
            firstDone = true
            internalHistory.push(nextLocation.pathname)
        } else if (action === "POP") {
            internalHistory.pop()
        } else if (action === "REPLACE") {
            internalHistory.pop()
            internalHistory.push(nextLocation.pathname)
        } else {
            throw new Error("Unknown location action: " + action)
        }
    })

    const renderApp = () => {
        const container = document.getElementById("root")
        const root = createRoot(container)
        root.render(
            <Provider store={store}>
                <Routes history={history} store={store} />
            </Provider>
        )
    }

    renderApp()
}

init()
