import { Box, Typography, styled } from "@mui/material"
import { COLORS } from "../../../utils"

export const PEPSEResumeContainer = styled(Box)({
  background: COLORS.BACKGROUND_GREY,
  borderRadius: "8px",
  padding: "24px",
  height: "100%",
  flex: 1
})

export const PEPSEResumeLabel = styled(Typography)({
  fontSize: 16,
  lineHeight: 1,
  color: COLORS.LABEL_GREY
})

export const PEPSEResumeInfo = styled(Typography)({
  fontSize: 20,
  lineHeight: 1,
  color: "#000"
})

export const StyledContainer = styled(Box)({
  padding: 24,
  borderRadius: "8px",
  border: "1px solid #E6E6E6"
})

export const StyledErrorMessage = styled(Typography)({
  color: "#F44259",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  marginTop: 6
})