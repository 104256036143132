import React from "react"
import AllergenIngredientList from "./Allergens/AllergenIngredientList"
import IngredientsTabs from "../IngredientsTabs"

const AllergensIngredientsTab = () =>  {
    return (
        <IngredientsTabs tabValue="allergens">
            <AllergenIngredientList/>
        </IngredientsTabs>
    )
}

export default AllergensIngredientsTab