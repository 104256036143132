import React from "react"

import TechnicalSheetTabs from "../TechnicalSheetTabs"
import ReusableSteps from "../../../Steps/ReusableSteps"

const ReusableStepsTab = () => {
    return (
        <TechnicalSheetTabs tabValue="steps">
            <ReusableSteps />
        </TechnicalSheetTabs>
    )
}

export default ReusableStepsTab