import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import SearchInput from "./SearchInput"
import { COLORS } from "../../utils"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Checkbox from "@mui/material/Checkbox"
import ReactSelect from "react-select"

const useStyles = makeStyles(() => ({
  searchFieldDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "14px 18px 14px 10px",
    width: "100%",
    backgroundColor: COLORS.LIGHT_GREY,
  },
  selectType: {
    color: COLORS.DARK_GREY,
    marginRight: 25,
  },
  selectDlcType: {
    composes: "$selectType",
    width: 86,
  },
  selectLots: {
    composes: "$selectType",
    width: 150,
  },
  selectsDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    height: 45,
    minWidth: "50%",
  },
  selectsDivSecondaryDlc: {
    display: "flex",
    alignItems: "flex-start",
    height: 45,
  }
}))

const selectStyles = {
  container: (styles) => ({
    ...styles,
    minWidth: 350,
    maxWidth: 350,
    marginRight: 17,
    marginTop: 10,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: COLORS.LIGHT_GREY,
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid transparent",
    borderBottom: `1px solid ${COLORS.GREY_SEARCH}`,
    borderRadius: "none",
    maxHeight: 38,
    overflowY: "hidden",
    maxWidth: 350,
    position: "relative",
    "&:hover": {
      maxHeight: "100%",
      border: `1px solid ${COLORS.GREY_SEARCH}`,
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    maxHeight: 38,
  }),
}

const Filter = (props) => {
  const {
    isTablet,
    onDlcTypeChange,
    onSearchChange,
    searchValue,
    isInventory,
    isSecondaryDlc = false,
    groupIngredients,
    dlcFilterTypes,
    onGroupIngredientChange,
    selectedGroupIngredients,
    selectedDlcTypes,
    onOutputLotsSelection,
    selectedOutputLots,
    focusSearch,
    className = null
  } = props
  const classes = useStyles()

  const options = groupIngredients
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((el) => ({
      value: el.objectId,
      label: el.name,
    }))

  return (
    <div className={className || classes.searchFieldDiv}>
      <SearchInput
        isTablet={isTablet}
        onNameChange={onSearchChange}
        isInventory={isInventory}
        defaultValue={searchValue}
        focused={focusSearch}
      />
      {(isInventory || isSecondaryDlc) &&
        <div className={isSecondaryDlc ? classes.selectsDivSecondaryDlc : classes.selectsDiv}>
          {!isSecondaryDlc && <FormControl className={classes.formControl} variant="standard">
            <ReactSelect
              options={options}
              value={selectedGroupIngredients}
              onChange={(values) => onGroupIngredientChange(values)}
              isMulti
              closeMenuOnSelect={false}
              styles={selectStyles}
              placeholder="Tous"
            />
          </FormControl>}
          <FormControl className={classes.formControl} variant="standard">
            <InputLabel>DLC</InputLabel>
            <Select
              variant="standard"
              multiple={true}
              className={clsx(classes.selectDlcType, {
                "is-tablet": isTablet,
              })}
              labelId="select-status"
              id="select-status"
              value={selectedDlcTypes}
              onChange={(e) => onDlcTypeChange(e.target.value)}
              autoWidth={true}
              renderValue={(selected) =>
                selected.length > 0
                  ? selected.find((elem) => elem === "ALL")
                    ? "Tous"
                    : selected
                      .map(
                        (elem) =>
                          dlcFilterTypes.find((el) => elem === el.key).label
                      )
                      .join(", ")
                  : "Aucune dlc sélectionnée"
              }
            >
              <MenuItem value={"ALL"}>
                <Checkbox
                  checked={selectedDlcTypes.includes("ALL")}
                  color="secondary"
                />
                Tous
              </MenuItem>
              {dlcFilterTypes.map((dlc, index) => (
                <MenuItem key={index} value={dlc.key}>
                  <Checkbox
                    checked={
                      selectedDlcTypes.indexOf(dlc.key) > -1 ||
                      selectedDlcTypes.includes("ALL")
                    }
                    color="secondary"
                  />
                  {dlc.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
       </div>
      }
      {(!isInventory && !isSecondaryDlc) &&
        <FormControl className={classes.formControl} variant="standard">
          <InputLabel>Lots</InputLabel>
          <Select
            variant="standard"
            className={classes.selectLots}
            labelId="select-status"
            id="select-status"
            value={selectedOutputLots}
            onChange={(e) => onOutputLotsSelection(e.target.value)}
            autoWidth={true}
            renderValue={(selected) =>
                selected === "ALL"
                ? "Tous"
                : "Lots à sortir uniquement"
            }
          >
            <MenuItem value={"ALL"}>
              <Checkbox
                checked={selectedOutputLots === "ALL"}
                color="secondary"
              />
              Tous les lots
            </MenuItem>
            <MenuItem value={"SELECTED_LOTS"}>
              <Checkbox
                checked={selectedOutputLots === "SELECTED_LOTS"}
                color="secondary"
              />
              Lots à sortir uniquement

            </MenuItem>

          </Select>
        </FormControl>

      }
    </div>
  )
}

export default Filter
