import React, { useState, useEffect } from "react"
import { ErrorMessage, Field } from "formik"
import withStyles from "@mui/styles/withStyles"

import { Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import IconButton from "@mui/material/IconButton"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import AddIcon from "@mui/icons-material/Add"
import StarIcon from "@mui/icons-material/Star"
import TextField from "@mui/material/TextField"

import {
  getDefaultSection,
  parseSectionToObject,
  getSectionGrossWeight,
  getStepGrossWeight,
} from "../../utils/recipes"
import Steps from "./Steps"
import { RecipeStylesNew } from "../../styles"
import { COLORS, roundNumber } from "../../utils"
import Autocomplete from "@mui/material/Autocomplete"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment"
import SectionReusableModal from "./SectionReusableModal"
import ConnectedChangedModal from "./ConnectedChangedModal"
import GrossWeightChangedModal from "./GrossWeightChangedModal"
import clsx from "clsx"
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd"

export const COMPONENT_NAME = "SECTIONS"

const Sections = props => {
  const {
      sections,
      genericSections,
      editing,
      onFieldFocus,
      onFieldBlur,
      rowHover,
      onRowHover,
      onRowBlur,
      isFocus,
      deleteHover,
      onDeleteHover,
      onDeleteBlur,
      onClearFocus,
      setFieldValue,
      setFieldError,
      computationHandler,
      createReusableSection,
      onKeyUp,
      errors,
      setFieldTouched,
      classes,
      status,
      fromGenericSections
  } = props

  const [openModal, setOpenModal] = useState(false)
  const [openChangedModal, setOpenChangedModal] = useState(false)
  const [openGrossWeightChangeModal, setOpenGrossWeightChangeModal] = useState(false)
  const [currentSection, setCurrentSection] = useState()
  const [currentPart, setCurrentPart] = useState()
  const [currentSectionIndex, setCurrentSectionIndex] = useState()
  const [currentGenericSection, setCurrentGenericSection] = useState()
  const [changed, setChanged] = useState(0)
  const [currentDraggedCard, setCurrentDraggedCard] = useState(null)

  const _stopPropagation = (event) => event && event.stopPropagation()

  const _isHover = (index) => rowHover && COMPONENT_NAME === rowHover.component && rowHover.index === index

  const _isDeleteHover = (index) => deleteHover && COMPONENT_NAME === deleteHover.component && deleteHover.index === index

  const _isError = (index) => errors.sections && errors.sections[index] && (errors.sections[index].name || errors.sections[index].parentPercent)

  const _onKeyDown = (e, section, isGrossWeight = false) => {
      if (section.parentId) {
          setCurrentGenericSection(parseSectionToObject([genericSections.find(elm => elm.id === section.parentId)])[0])
          setCurrentSection(section)
          setOpenChangedModal(true)
      } else if (isGrossWeight && section.preventGrossWeightChange) {
          setCurrentPart(section)
          setOpenGrossWeightChangeModal(true)
      }
  }

  const _onDragStart = (event) => {
      let currentSection = null
      currentSection = sections.find(item => item.index === event.draggableId)

      setCurrentDraggedCard(currentSection)
  }

  const _onDragEnd = (event) => {
      const { destination, source } = event

      if (!destination) {
          setCurrentDraggedCard(null)

          return
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
          setCurrentDraggedCard(null)

          return
      }

      const newSections = [...sections]
      newSections.splice(source.index, 1)
      newSections.splice(destination.index, 0, currentDraggedCard)

      setFieldValue("sections", newSections)
  }

  useEffect(() => {
      if (currentSection) {
          _onGenericSectionChange({target:null}, currentSection.name, currentSectionIndex, "selectOption")
      }
  }, [genericSections])

  useEffect(() => {
      if (undefined !== currentSectionIndex) {
          handlePercentChange({target:{value:100}}, currentSectionIndex)
      }
  }, [changed])

  const _onGenericSectionChange = (event, value, sectionIndex, reason) => {
      if (event) {
          if (reason === "selectOption" && value.get) value = value.get("name")

          const section = genericSections.find(elm => elm.get("name") === value)
          const newSections = [].concat(sections)
          newSections[sectionIndex].name = value

          if (newSections[sectionIndex].parentId) {
              setCurrentGenericSection(parseSectionToObject([genericSections.find(elm => elm.id === newSections[sectionIndex].parentId)])[0])
              setCurrentSection(newSections[sectionIndex])
              setOpenChangedModal(true)
          }

          if (reason === "selectOption" && section) {
              newSections[sectionIndex] = parseSectionToObject([section])[0] || getDefaultSection()
              newSections[sectionIndex].error = false
              newSections[sectionIndex].id = null
              newSections[sectionIndex].parentId = section.id
              newSections[sectionIndex].parentPercent = 100
          }

          if (section && !newSections[sectionIndex].parentId) {
              newSections[sectionIndex].parentId = null
              newSections[sectionIndex].parentPercent = 0
          }

          setFieldValue("sections", newSections)

          if (reason === "selectOption" && section) {
              setCurrentSectionIndex(sectionIndex)
              setChanged(changed + 1)
              onClearFocus()
          }
          if (event.target) _stopPropagation(event)
      }
  }

  const handlePercentChange = (event, sectionIndex) => {
      if (event) {
          if (event.target.value === "") {
              setFieldError(`sections[${sectionIndex}].parentPercent`, "Obligatoire")
          } else {
              setFieldError(`sections[${sectionIndex}].parentPercent`, undefined)
          }

          const newSections = [].concat(sections)
          const section = genericSections.find(elm => elm.id === newSections[sectionIndex].parentId)

          if (section) {
              const value = (event.target.value !== "") ? event.target.value : 0

              const newSection = parseSectionToObject([section], parseFloat(value))[0] || getDefaultSection()
              newSections[sectionIndex].error = (event.target.value === "")
              newSections[sectionIndex].parentId = section.id
              newSections[sectionIndex].parentPercent = (event.target.value !== "")?parseFloat(event.target.value):""

              //update ingredient one by one
              newSection.steps.forEach((step, stepIndex) => {
                  step.ingredients.forEach((ingredient, ingredientIndex) => {
                      newSections[sectionIndex].steps[stepIndex].ingredients[ingredientIndex] = ingredient
                      setFieldValue("sections", newSections)
                      computationHandler(sectionIndex, stepIndex, ingredientIndex)
                  })
              })
          }

          if (event.stopPropagation) _stopPropagation(event)
      }
  }

  const _setSectionReusable = (event) => {
      createReusableSection(currentSection)
      setOpenModal(false)
      _stopPropagation(event)
  }

  const _disconnectFromReusable = (event) => {
      currentSection.parentId = null
      currentSection.parentPercent = 0

      setOpenChangedModal(false)
      _stopPropagation(event)
  }

  const _removePreventGrossWeightChange = (event, part) => {
      part.preventGrossWeightChange = false

      setOpenGrossWeightChangeModal(false)
      _stopPropagation(event)
  }

  const _registerReusable = (event, section, sectionIndex) => {
      if (section.parentId) {
          _onKeyDown({target: {name: `sections[${sectionIndex}]`}}, section)
          _stopPropagation(event)
          return
      }

      setCurrentSection(section)
      setCurrentSectionIndex(sectionIndex)
      setOpenModal(true)

      _stopPropagation(event)
  }

  const _addSection = (index, event = null) => {
      sections.splice(index + 1, 0, getDefaultSection())
      onDeleteBlur()
      setFieldValue("sections", sections)
      _stopPropagation(event)
  }

  const _removeSection = (index, event = null) => {
      if (sections[index].parentId) {
          _onKeyDown({target: {name: `sections[${index}]`}}, sections[index])
          _stopPropagation(event)
          return
      }

      const _sections = [...sections]
      _sections.splice(index, 1)
      if (!_sections.length) {
          _sections.splice(0, 0, getDefaultSection())
      }

      setFieldValue("sections", _sections)

      _stopPropagation(event)
  }

  const _onGrossWeightChange = (index, e) => {
      if (!sections[index].parentId) {
          const originalGrossWeight = (sections[index].grossWeight ? sections[index].grossWeight : getSectionGrossWeight(sections[index])) || 1
          const value = Number(e.target.value)
          const proportion = value !== 0 ? (value / originalGrossWeight) : 1

          sections[index].grossWeight = value
          setFieldTouched(`sections[${index}].grossWeight`)

          sections[index].steps.forEach((step, stepIndex) => {
              const stepGrossWeight = step.grossWeight ? step.grossWeight : getStepGrossWeight(step)
              step.grossWeight =  stepGrossWeight * proportion
              setFieldTouched(`sections[${index}].steps[${stepIndex}].grossWeight`)

              step.ingredients.forEach((ingredient, ingredientIndex) => {
                  ingredient.grossWeight *= proportion
                  setFieldTouched(`sections[${index}].steps[${stepIndex}].ingredients[${ingredientIndex}].grossWeight`)
                  computationHandler(index, stepIndex, ingredientIndex)
              })
          })
      }
  }

  const _updateSectionGrossWeight = (index) => {
      if (!sections[index].parentId) {
          sections[index].grossWeight = getSectionGrossWeight(sections[index])
          setFieldTouched(`sections[${index}].grossWeight`)
      }
  }

  const _renderEditableSection = (section, index, isHover, isDeleteHover, error = false, parentSectionGrossWeight) => (
      <Grid
          container
          className={`${isHover ? classes.editHover : ""} ${error || isDeleteHover ? classes.sectionLineError : ""} ${(section.parentId)?classes.sectionInherited:""}`}
          onClick={_stopPropagation}
      >
          <Grid container item xs={5} className={classes.natBorder} alignContent="center">
              <Grid container item xs={12} className={classes.sectionNameEdit} alignItems="center">
                  {
                      (isHover && !section.parentId) ?
                          (
                              <>
                                  {
                                      !fromGenericSections &&
                                      <AddIcon
                                          className={classes.addButtonSection}
                                          onClick={(e) => _addSection(index, e)}
                                      />
                                  }
                                  <Grid item xs={10}>
                                      <Autocomplete
                                          freeSolo
                                          disableClearable
                                          className={classes.autocompleteContainer}
                                          inputValue={typeof section.name === "string" ? section.name : section.name.get("name")}
                                          getOptionLabel={(option) => {
                                            return typeof option === "string" ? option : option.get("name")
                                          }}
                                          options={genericSections}
                                          onChange={(event, newInputValue, reason) => {
                                              _onGenericSectionChange(event, newInputValue, index, reason)
                                          }}
                                          onInputChange={(event, newInputValue) => {
                                              _onGenericSectionChange(event, newInputValue, index, "input-change")
                                          }}
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  name={`sections[${index}].name`}
                                                  onClick={_stopPropagation}
                                                  className={classes.sectionNameWhiteInput}
                                                  onFocus={onFieldFocus}
                                                  onBlur={onFieldBlur}
                                                  onKeyUp={onKeyUp}
                                                  onKeyDown={(e) => _onKeyDown(e, section)}
                                                  variant="standard"
                                              />
                                          )}
                                      />
                                      <ErrorMessage
                                          name={`sections[${index}].name`}
                                          render={msg => <div className={classes.errorSection}>{msg}</div>}
                                      />
                                  </Grid>
                                  <Grid item xs={1} className={classes.alignCenter}>
                                      {
                                          fromGenericSections &&
                                          <IconButton className={`${classes.starIcon} show`} size="large">
                                              <StarIcon
                                                  htmlColor={COLORS.BUTTON_INHERITED}
                                                  className="filled"
                                                  fontSize="small"
                                              />
                                          </IconButton>
                                      }
                                      {
                                          !fromGenericSections &&
                                          <IconButton
                                              className={classes.starIcon}
                                              onClick={(event) => {
                                                  _registerReusable(event, section, index)
                                              }}
                                              size="large">
                                              <StarIcon className="filled"/>
                                              <StarBorderIcon className="border"/>
                                          </IconButton>
                                      }
                                  </Grid>
                              </>
                          ) :
                          (
                              <>
                                  {
                                      isHover &&
                                      <AddIcon
                                          className={classes.addButtonSection}
                                          onClick={(e) => _addSection(index, e)}
                                      />
                                  }
                                  <Grid item xs={section.parentId?7:11} className={clsx(classes.sectionText, "center")}>
                                      {
                                          error // Only one field in sections so we can say if line is in error this field is
                                                  ?
                                              <ErrorMessage
                                                  name={`sections[${index}].name`}
                                                  render={msg => <div className={classes.errorSection}>{msg}</div>}
                                              />
                                              :
                                              section.name
                                      }
                                      {
                                          section.parentId &&
                                          <IconButton
                                              className={classes.starIconFilled}
                                              onClick={(event) => {_registerReusable(event, section, index)}}
                                              size="large">
                                              <StarIcon
                                                  htmlColor={COLORS.BUTTON_INHERITED}
                                                  className="filled"
                                                  fontSize="small"
                                              />
                                          </IconButton>
                                      }
                                  </Grid>
                              </>
                          )
                  }
                  {
                      section.parentId &&
                      <Grid item xs={4} className={classes.sectionTextBlock}>
                          <Input
                              id="standard-adornment-weight"
                              name={`sections[${index}].parentPercent`}
                              value={section.parentPercent}
                              onChange={(e) => handlePercentChange(e, index)}
                              className={(isHover)?classes.buttonPercentageInheritedHovered:classes.buttonPercentageInherited}
                              endAdornment={<InputAdornment position="end">%</InputAdornment>}
                              aria-describedby="standard-weight-helper-text"
                              type="number"
                              inputProps={{
                                  "aria-label": "weight",
                              }}
                              autoFocus
                          />
                          <span> de {roundNumber(parentSectionGrossWeight * 1000, 2)}g</span>

                          <ErrorMessage
                              name={`sections[${index}].parentPercent`}
                              render={msg => <div className={classes.errorSection}>{msg}</div>}
                          />
                      </Grid>
                  }
              </Grid>
          </Grid>
          <Grid container item xs={3} className={classes.natBorder} alignContent="center">
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                  { fromGenericSections && "--" }
                  {
                      !fromGenericSections && (isHover
                          ? (
                              <>
                                  <Field
                                      type="number"
                                      name={`sections[${index}].grossWeight`}
                                      value={ section.grossWeight ? section.grossWeight : getSectionGrossWeight(section) }
                                      onChange={e => _onGrossWeightChange(index, e)}
                                      onClick={_stopPropagation}
                                      className={classes.alignRight}
                                      onFocus={onFieldFocus}
                                      onBlur={onFieldBlur}
                                      onKeyUp={onKeyUp}
                                      onKeyDown={(e) => _onKeyDown(e, section, true)}
                                      onWheel= {(e) => e.target.blur()}
                                  />
                                  <ErrorMessage
                                      name={`sections[${index}].grossWeight`}
                                      render={msg => <div className={classes.error}>{msg}</div>}
                                  />
                              </>
                          ) : (
                              error
                                  ? (
                                      <ErrorMessage
                                          name={`sections[${index}].grossWeight`}
                                          render={msg => <div className={classes.error}>{msg}</div>}
                                      />
                                  )
                                  : (
                                      <div>
                                          { section.grossWeight ? section.grossWeight : getSectionGrossWeight(section) }
                                      </div>
                                  )
                          )
                      )
                  }
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>--</Grid>
              <Grid item xs={4} className={
                  `${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.bold}`
              }>
                  {section.cost && `${roundNumber(section.cost, 3)}€`}
              </Grid>
          </Grid>
          <Grid container item xs={4} className={classes.natBorder} alignContent="center">
              <Grid item xs={4} className={
                  `${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding}`
              }>
                  --
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>--</Grid>
              <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.bold}`}>
                  {section.netWeight}
              </Grid>
              <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                  {
                      isHover &&
                      <DeleteIcon
                          className={error || isDeleteHover ? classes.deleteIconRed : classes.deleteIcon}
                          onClick={(e) => _removeSection(index, e) }
                          onMouseEnter={() => onDeleteHover(COMPONENT_NAME, index)}
                          onMouseLeave={onDeleteBlur}
                      />
                  }
              </Grid>
          </Grid>
      </Grid>
  )


  const _renderViewSection = (section, parentSectionGrossWeight) => (
      <Grid container onClick={_stopPropagation}>
          <Grid container item xs={5} className={`${classes.natBorder} show`} alignContent="center">
              <Grid item xs={12} className={classes.sectionNameEdit}>
                  <Grid
                      item
                      xs={(section.parentId || fromGenericSections) ? 7 : 12}
                      className={clsx(classes.sectionText, "center")}
                  >
                      {section.name}
                      {
                          (section.parentId || fromGenericSections) &&
                          <IconButton className={`${classes.starIconFilled} show`} size="large">
                              <StarIcon
                                  htmlColor={COLORS.BUTTON_INHERITED}
                                  className="filled"
                                  fontSize="small"
                              />
                          </IconButton>
                      }
                  </Grid>
                  {
                      section.parentId &&
                      <>
                          <Grid item xs={1} ></Grid>
                          <Grid item xs={4} className={classes.sectionTextBlock}>
                                <Input
                                    id="standard-adornment-weight"
                                    value={section.parentPercent}
                                    className={classes.buttonPercentageInherited}
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    inputProps={{
                                        disabled: true,
                                        "aria-describedby" : "standard-weight-helper-text"
                                    }}
                                />
                                <span> de {roundNumber(parentSectionGrossWeight * 1000, 2)}g</span>
                          </Grid>
                      </>
                  }
              </Grid>
          </Grid>
          <Grid container item xs={3} className={`${classes.natBorder} show`} alignContent="center">
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                  { fromGenericSections && "--" }
                  { !fromGenericSections && (section.grossWeight ? section.grossWeight : getSectionGrossWeight(section)) }
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>--</Grid>
              <Grid item xs={4} className={
                  `${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.bold}`
              }>
                  {section.cost && `${roundNumber(section.cost, 3)}€`}
              </Grid>
          </Grid>
          <Grid container item xs={4} className={`${classes.natBorder} show`} alignContent="center">
              <Grid item xs={4} className={`${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding}`}>
                  --
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>--</Grid>
              <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.bold}`}>
                  {section.netWeight}
              </Grid>
              <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                  {/* Space for delete action */}
              </Grid>
          </Grid>
      </Grid>
  )

  return (
      <DragDropContext
          isDragDroppable={true}
          onDragStart={_onDragStart}
          onDragEnd={_onDragEnd}
      >
          <Droppable
              isDroppable={true}
              droppableId="sectionDroppable"
          >
              {
                  (provided) => (
                      <div ref={provided.innerRef}>
                          {
                              sections.map((section, index) => {
                                  const parentSectionGrossWeight = (section.parentId) ? getSectionGrossWeight(genericSections.find(el => el.id === section.parentId)) : ""
              
                                  return (
                                      <Draggable
                                          key={section.index}
                                          index={index}
                                          isDragDisabled={!editing || isFocus}
                                          draggableId={section.index}
                                      >
                                          {
                                              (provided, snapshot) => {
              
                                                  const style = {
                                                      outline: snapshot.isDragging ? `2px solid ${COLORS.PRIMARY_COLOR}` : "none",
                                                      boxShadow: snapshot.isDragging ? `inset 0 0 10px ${COLORS.PRIMARY_COLOR}, 0 0 10px ${COLORS.PRIMARY_COLOR}` : "none",
                                                      ...provided.draggableProps.style
                                                  }
              
                                                  return <Grid
                                                      item
                                                      xs={12}
                                                      key={index}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      style={style}
                                                  >
                                                      <Accordion
                                                          square={true}
                                                          elevation={0}
                                                          className={clsx(classes.sectionHeader, (section.parentId)?classes.sectionInherited:"")}
                                                          defaultExpanded={true}
                                                      >
                                                          <AccordionSummary
                                                              className={classes.expansionPannelSummary} classes={{
                                                              root: classes.summaryRootAndContentSection,
                                                              content: classes.summaryRootAndContentSection,
                                                              expanded: classes.expanded,
                                                              focused: classes.focused,
                                                              expandIcon: classes.expandIconSection
                                                          }}
                                                              expandIcon={<ExpandMoreIcon />}
                                                              onMouseEnter={() => onRowHover(COMPONENT_NAME, index)}
                                                              onMouseLeave={onRowBlur}
                                                              {...provided.dragHandleProps}
                                                          >
                                                              {
                                                                  editing
                                                                      ? _renderEditableSection(section, index, _isHover(index), _isDeleteHover(index), _isError(index), parentSectionGrossWeight)
                                                                      : _renderViewSection(section, parentSectionGrossWeight)
                                                              }
                                                          </AccordionSummary>
                                                          <AccordionDetails classes={{ root: classes.detailsRootSection }}>
                                                              <Steps
                                                                  steps={section.steps}
                                                                  sectionIndex={index}
                                                                  editing={editing}
                                                                  onFieldFocus={onFieldFocus}
                                                                  onFieldBlur={onFieldBlur}
                                                                  isFocus={isFocus}
                                                                  rowHover={rowHover}
                                                                  onRowHover={onRowHover}
                                                                  onRowBlur={onRowBlur}
                                                                  deleteHover={deleteHover}
                                                                  onDeleteHover={onDeleteHover}
                                                                  onDeleteBlur={onDeleteBlur}
                                                                  setFieldValue={setFieldValue}
                                                                  computationHandler={computationHandler}
                                                                  onKeyUp={onKeyUp}
                                                                  onKeyDown={(e) => _onKeyDown(e, section)}
                                                                  setFieldTouched={setFieldTouched}
                                                                  errors={errors}
                                                                  isInherited={(section.parentId) ? true : false}
                                                                  status={status}
                                                                  updateSectionGrossWeight={_updateSectionGrossWeight}
                                                                  setOpenGrossWeightChangeModal={setOpenGrossWeightChangeModal}
                                                                  setCurrentPart={setCurrentPart}
                                                                  fromGenericSections={fromGenericSections}
                                                              />
                                                          </AccordionDetails>
                                                      </Accordion>
                                                      <SectionReusableModal
                                                          open={openModal}
                                                          onClose={() => {setOpenModal(false)}}
                                                          section={currentSection}
                                                          register={_setSectionReusable}
                                                      />
                                                      <ConnectedChangedModal
                                                          open={openChangedModal}
                                                          onClose={() => {setOpenChangedModal(false)}}
                                                          section={currentGenericSection}
                                                          disconnect={_disconnectFromReusable}
                                                      />
                                                      <GrossWeightChangedModal
                                                          open={openGrossWeightChangeModal}
                                                          onClose={() => {setOpenGrossWeightChangeModal(false)}}
                                                          part={currentPart}
                                                          accept={_removePreventGrossWeightChange}
                                                      />
                                                  </Grid>
                                              }
                                          }
                                          
                                      </Draggable>
                                  )
                              })
                          }
                          {provided.placeholder}
                      </div>
                  )}
          </Droppable>
      </DragDropContext>
  )
}

export default withStyles(RecipeStylesNew)(Sections)
