import React, { useCallback, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"
import { COLORS } from "../../../utils"
import { roundNumber } from "../../../utils"
import moment from "moment"
import DataGrid from "../../../components/DataGrid/DataGrid"
import RoundedAddButton from "../../../components/RoundedAddButton"
import { computeReceptionDeliveryDelay } from "../../../utils/orderReception"

const useStyles = makeStyles({
    container: {
        flex: 1
    },
    row: {
        height: 72,
        backgroundColor: COLORS.WHITE
    },
    cell: {
        textAlign: "center"
    },
    buttonContainer: {
        width: 400
    },
    denyButton: {
        marginRight: 20,
        color: COLORS.RED_RASPBERRY,
        backgroundColor: COLORS.WHITE,
        border: `1px solid ${COLORS.RED_RASPBERRY}`,
        "&:hover": {
            color: COLORS.WHITE,
            backgroundColor: COLORS.RED_RASPBERRY
        }
    },
    allowButton: {
        color: COLORS.WHITE,
        backgroundColor: COLORS.PRIMARY_COLOR,
        border: `1px solid ${COLORS.PRIMARY_COLOR}`,
        "&:hover": {
            color: COLORS.PRIMARY_COLOR,
            backgroundColor: COLORS.WHITE
        }
    }
})

export const OrderReceptionToDo = (props) => {
    const {
        orders,
        supplierOptions,
        typeOptions,
        deliveryDelayOptions,
        onOrderClick,
        onAdd
    } = props

    const classes = useStyles()
    const [data, setData] = useState([])

    const columns = [
        {
            key: "orderNumber",
            label: "N° de commande",
            dataType: "text",
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
            sortable: false
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            filter: {
                filterable: true,
                withSearch: true,
                type: "select",
                options: supplierOptions
            },
            sortable: false
        },
        {
            key: "expectedDeliveryDate",
            label: "Date de livraison attendue",
            sortable: false,
            dataType: "date",
            filter: {
                filterable: true,
                type: "date"
            }
        },
        {
            key: "refAmount",
            label: "Nombre de produits",
            sortable: false,
            dataType: "number",
            filter: {
                filterable: false,
                type: "number"
            }
        },
        {
            key: "orderWeight",
            label: "Poids global commandé",
            sortable: false,
            dataType: "number",
            filter: {
                filterable: false,
                type: "number"
            },
            render: (value) => {
                const weight = value
                const kiloLabel = " kg"
                return (
                    <div><span style={{ color: "#55555" }}>{weight}</span><span style={{ color: "#7C7C7C" }}>{" " + kiloLabel}</span></div>
                )
            }
        }
    ]

    const additionalFilters = [
        {
            key: "type",
            label: "Type de produit",
            dataType: "text",
            filter: {
                filterable: true,
                type: "select",
                options: typeOptions
            },
        },
        {
            key: "deliveryDelay",
            label: "Respect de la date de livraison",
            dataType: "text",
            filter: {
                filterable: true,
                type: "select",
                options: deliveryDelayOptions
            },
        }
    ]

    useEffect(() => {
        createDataObject()
    }, [orders])

    const createDataObject = useCallback(() => {
        setData(orders.map(createData))
    })

    const createData = (order) => {
        return {
            id: order.objectId,
            orderNumber: order.orderNumber,
            supplier: order.supplier && order.supplier.name ? order.supplier.name : "",
            expectedDeliveryDate: order.expectedDeliveryDate ? moment.utc(parseFloat(order.expectedDeliveryDate)).format("DD/MM/YY") : "",
            refAmount: order.supplierItems.length,
            orderWeight: order.totalWeight || 0,
            type: order.supplierItems.map(item => typeOptions.find(type => type.key === item.supplierItem.type)?.label).join(", "),
            deliveryDelay: computeReceptionDeliveryDelay(order)
        }
    }

    const renderRecapRow = (data) => {
        const totalAmount = data.reduce((acc, row) => {
            return acc + Number(row.orderWeight)
        }, 0)
        return (
            <Box sx={{
                display: "flex",
                padding: "16px",
                backgroundColor: "#EBF7EE",
                color: "#262626",
                alignItems: "center",
                gap: "8px",
                alignSelf: "stretch",
                fontSize: "16px",
                lineHeight: "22px"
            }}>
                Poids total à réceptionner :
                <strong>
                    {roundNumber(totalAmount, 2)} kg
                </strong>
            </Box>)
    }

    const handleOrderClick = (order) => {
        onOrderClick(order.id)
    }

    return (
        <div className={classes.container}>
            <DataGrid
                title="Commandes à réceptionner"
                columns={columns}
                data={data}
                renderRecapRow={renderRecapRow}
                withFilters
                additionalFilters={additionalFilters}
                rowLabel="commandes"
                onRowClick={handleOrderClick}
            />
            <RoundedAddButton
                onClick={onAdd}
            />
        </div>
    )
}

export default OrderReceptionToDo