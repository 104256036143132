import Parse from "parse"
import { parseLimitRequest } from "../../utils"
const User = Parse.User

export async function getUsers(includes = [], toJson = true) {
    try {
        const roles = ((await new Parse.Query(Parse.Role)
            .limit(parseLimitRequest)
            .find()) || [])
        for (const role of roles) {
            const users = await role.getUsers().query().find()
            role.set("currentUsers", users)
        }

        const users = (await new Parse.Query(User)
            .include(includes)
            .notEqualTo("deleted", true)
            .limit(parseLimitRequest)
            .find()) || []

        for (const user of users) {
            const userRoles = await roles.filter(role =>
                (role.get("currentUsers") || []).find(roleUser =>
                    user.id === roleUser.id
                )
            ).map(elem => elem.toJSON())
            user.set("roles", userRoles)
        }
        for (const role of roles) {
            role.unset("currentUsers")
        }
        return toJson
            ? users.map(elem => elem.toJSON())
            : users
    } catch(err) {
        console.error("parseManager.parseRight.parseRights.getUsers : ", err)
        return Promise.reject(err)
    }
}

export async function getUserById(id, includes = [], toJson = true) {
    try {
        const user = await new Parse.Query(User)
            .equalTo("objectId", id)
            .include(includes)
            .first()

        return toJson
            ? user
                ? user.toJSON()
                : null
            : user

    } catch(err) {
        console.error("parseManager.parseRight.parseRights.getUserById : ", err)
        return Promise.reject(err)
    }
}

export async function getUserNotDeletedByUsername(username) {
    const user = await new Parse.Query(User)
        .equalTo("username", username)
        .notEqualTo("deleted", true)
        .first()

    return user
}

export async function createUser(userJson) {
    const user = new User()
    user.set(userJson)
    await user.save()

    return user
}
