import React, { useState } from "react"
import { COLORS } from "../../utils"
import { packagingNature } from "../../utils/packaging"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import makeStyles from "@mui/styles/makeStyles"
import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup
} from "@mui/material"
import moment from "moment"

const useStyles = makeStyles(() => ({
	splitProductionModalContainer: {
		backgroundColor: COLORS.WHITE,
		width: 700,
		outline: "none",
		margin: "0 auto",
		top: "25%",
		position: "relative",
		borderRadius: 3,
		color: COLORS.BLACK,
		padding: 10
	},
	closeContainer: {
		textAlign: "right"
	},
	closeIcon: {
		cursor: "pointer"
	},
	title: {
		fontSize: 20,
		textAlign: "center"
	},
	commercialName: {
		textAlign: "center",
		marginTop: 10
	},
	splitPackagingModalInfos: {
		margin: "40px 130px 40px 130px"
	},
	packagingDateRow: {
		display: "flex",
		marginTop: 25,
		marginBottom: 10
	},
	dot: {
		height: 7,
		width: 7,
		borderRadius: "50%",
		marginTop: 19,
		marginLeft: 2,
		marginRight: 5,
		backgroundColor: "black"
	},
	packaging: {
		width: 90,
		marginTop: 13,
		fontWeight: "bold"
	},
	radio: {
		marginTop: 10
	},
	buttonContainer : {
		textAlign: "center"
	},
	confirmBtn: {
		marginBottom: 10,
		color: COLORS.WHITE
	},
	productionRow: {
		textAlign: "center",
	},
	productionText: {
		marginTop: 0
	}
}))

export const PlanningSplitPackagingModal = (props) => {
	const {
		currentCard,
		closeModal,
		saveSplitPackaging
	} = props

	const [packagingDates, setPackagingDates] = useState(currentCard.packagingType.map(type => ({ item: type, day: ""})))

	const classes = useStyles()

	const _onChange = (value, index) => {
		const newDates = packagingDates
		newDates[index].day = value

		setPackagingDates([...newDates])
	}

	const _onConfirm = () => {
		
		saveSplitPackaging(packagingDates.filter(date => date.day !== ""))
	}


	return (
		<Modal open={true}>
			<div className={classes.splitProductionModalContainer}>
				<div className={classes.closeContainer}>
					<CloseIcon
						className={classes.closeIcon}
						onClick={closeModal}
					/>
				</div>
				<div className={classes.title}>
					Comment souhaitez-vous repartir ce barquettage ?
				</div>
				<div className={classes.commercialName}>
					{currentCard.commercialName}
				</div>
				<div className={classes.splitPackagingModalInfos}>
					{
						packagingDates.map((date, i) => {
							return (
								<div key={i}>
									<div className={classes.packagingDateRow}>
										<div className={classes.dot}/>
										<div className={classes.packaging}>
											{ packagingNature.find(el => el.value === date.item.value).label }
										</div>
										<RadioGroup row value={date.day} onChange={e => _onChange(e.target.value, i)}>
											<FormControlLabel
												value={"yesterday"}
												label={moment.utc(currentCard.packagingDate).add(-1, "days").startOf("day").format("DD/MM")}
												disabled={!date.item.availableDates.includes(moment.utc(currentCard.packagingDate).add(-1, "days").startOf("day").valueOf())}
												control={<Radio color="secondary" />}
												color="blue"
											/>
											<FormControlLabel
												value={""}
												label={moment.utc(currentCard.packagingDate).startOf("day").format("DD/MM")}
												disabled={!date.item.availableDates.includes(moment.utc(currentCard.packagingDate).startOf("day").valueOf())}
												control={<Radio color="secondary" />}
												color="blue"
											/>
											<FormControlLabel
												value={"tomorrow"}
												label={moment.utc(currentCard.packagingDate).add(1, "days").startOf("day").format("DD/MM")}
												disabled={!date.item.availableDates.includes(moment.utc(currentCard.packagingDate).add(1, "days").startOf("day").valueOf())}
												control={<Radio color="secondary" />}
												color="blue"
											/>
										</RadioGroup>
										
									</div>
									<div className={classes.productionRow}>
										<p className={classes.productionText}>Volume prévu : <span>{date.item.packagingProduction ? date.item.packagingProduction : "pas de volume indiqué"}</span></p>
									</div>
								</div>
							)
						})
					}
				</div>
				<div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classes.btn}
						onClick={_onConfirm}
					>
						Valider
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default PlanningSplitPackagingModal