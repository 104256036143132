import React from "react"
import TechnicalSheetTabs from "../TechnicalSheetTabs"
import SectionsGenericList from "../../../Sections/SectionsGenericList"

const SectionsGenericsTab = () => {
    return (
        <TechnicalSheetTabs tabValue="sections">
            <SectionsGenericList />
        </TechnicalSheetTabs>
    )
}

export default SectionsGenericsTab