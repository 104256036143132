import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import withStyles from "@mui/styles/withStyles"
import { COLORS } from "../../../../utils"
import { TextField } from "@mui/material"

const styles = {
    monitoringToolbarContainer: {
        height: 33,
        position: "relative",
        width: "100%",
        backgroundColor: COLORS.WHITE

    }
}
export const MonitoringToolbar = (props) => {
    const { classes, date, onDateChange } = props

    return (
        <div className={classes.monitoringToolbarContainer}>
            <DatePicker
                disableCloseOnSelect={false}
                showToolbar={false}
                variant="inline"
                value={date}
                inputFormat="DD/MM/YYYY"
                onChange={onDateChange}
                renderInput={(params) => <TextField {...params} variant="standard" />}
            />
        </div>
    )
}

export default withStyles(styles)(MonitoringToolbar)