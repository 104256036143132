import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import {
    showSingleGroupClassificationsIngredientsEdit,
    showSingleGroupClassificationsIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Groups/Groups"
import GroupIngredientEnhancedTableToolbar from "../../../../../components/table-utils/Ingredient/GroupIngredientEnhancedTableToolbar"
import GroupIngredientEnhancedTableHead from "../../../../../components/table-utils/Ingredient/GroupIngredientEnhancedTableHead"
import TablePagination from "../../../../../components/TablePagination"
import { stableSort, getSorting } from "../../../../../components/table-utils/utils"
import moment from "moment"
import {setSearchValue} from "../../../../../utils"


const styles = {
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    searchFieldInput: {
        width: 300
    },
    column: {
        cursor: "pointer"
    }
}

class GroupIngredientList extends PureComponent {
    constructor(props) {
        super(props)

        const storage = JSON.parse(localStorage.getItem("searchValue"))
        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            dataPage: 0,
            rowsPerPage: 10,
            searchFilter: (storage && storage.groupIngredientList)?storage.groupIngredientList:null,
            filterGroupsIngredients: [],
            data: []
        }
    }

    componentDidMount() {
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const { groupsIngredients } = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.groupsIngredients.length !== groupsIngredients.length){
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { groupsIngredients } = this.props
        let groupsIngredientsFilter = []

        if (this.state.searchFilter !== null && this.state.searchFilter !== ""){
            const regex = new RegExp(this.state.searchFilter, "ig")
            groupsIngredientsFilter = groupsIngredients.filter(groupIngredient =>
                groupIngredient.name.match(regex) !== null ||
                (groupIngredient.family && groupIngredient.family.name.match(regex) !== null)
            )
        }
        else {
            groupsIngredientsFilter = groupsIngredients
        }
        this.setState({filterGroupsIngredients: groupsIngredientsFilter, data: groupsIngredientsFilter.map(this.createData)})
    }

    createData = (groupIngredient) => {
        const family = groupIngredient.family ? groupIngredient.family.name : null

        return {
            id: groupIngredient.objectId,
            name: groupIngredient.name.toLowerCase(),
            family: family,
            createdAt: moment(groupIngredient.createdAt).format("DD/MM/YY"),
            updatedAt: moment(groupIngredient.updatedAt).format("DD/MM/YY")
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterGroupsIngredients } = this.state
            this.setState({selected: filterGroupsIngredients.map(groupIngredient => groupIngredient.objectId)})
            return
        }
        this.setState({selected: []})
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected })
    }

    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    updateSearchValue = (event) => {
        if (event) {
            this.setState({searchFilter: event.target.value, dataPage: 0})
            setSearchValue(event.target.value, "groupIngredientList")
        }
        else {
            this.setState({searchFilter: null})
        }
    }

    _showSingleGroupClassificationsIngredients = (event, groupIngredientId) => {
        const { showSingleGroupClassificationsIngredientsRead } = this.props
        showSingleGroupClassificationsIngredientsRead(groupIngredientId)
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    render() {
        const {
            classes,
            groupsIngredients,
            showSingleGroupClassificationsIngredientsEdit
        } = this.props
        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            data,
            dataPage,
            filterGroupsIngredients
        } = this.state

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper elevation={0} className={classes.root}>
                <GroupIngredientEnhancedTableToolbar
                    numSelected={selected.length}
                    groupsIngredients={groupsIngredients}
                    createGroupIngredient={showSingleGroupClassificationsIngredientsEdit}
                    selected={selected}
                />
                <div className={classes.searchFieldDiv}>
                    <TextField
                        variant="standard"
                        defaultValue={this.state.searchFilter}
                        className={classes.searchFieldInput}
                        onChange={this.updateSearchValue}
                        label="Rechercher un groupe d'ingrédient"
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <GroupIngredientEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterGroupsIngredients.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell padding="none" className={classes.column} onClick={event => this._showSingleGroupClassificationsIngredients(event, n.id)}>{n.name}</TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showSingleGroupClassificationsIngredients(event, n.id)}>{n.family}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleGroupClassificationsIngredients(event, n.id)}>{n.createdAt}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleGroupClassificationsIngredients(event, n.id)}>{n.updatedAt}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterGroupsIngredients}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />
            </Paper>
        )
    }
}

GroupIngredientList.propTypes = {
    classes: PropTypes.object.isRequired,
}

GroupIngredientList = withStyles(styles)(GroupIngredientList)

export default connect(state => {
    return {
        groupsIngredients: state.groupsIngredients.groupsIngredients
    }
}, {
    showSingleGroupClassificationsIngredientsEdit,
    showSingleGroupClassificationsIngredientsRead
})(GroupIngredientList)
