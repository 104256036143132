import React, { useState, useRef, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import { COLORS } from "../../utils"

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            height: 45,
            marginLeft: 30,
            borderColor: COLORS.GREY_BORDER,
            backgroundColor: COLORS.LIGHT_GREY
        },
        input: {
            color: COLORS.DARK_GREY,
            marginLeft: theme.spacing(0),
            flex: 1,
            width: 373
        },
        iconButton: {
            padding: 10
        },
        inputLabel: {
            paddingLeft: 50,
            color: COLORS.GREY_BORDER
        }
    })
)

export default function SearchInput(props) {
    const { defaultValue, onNameChange, focused } = props
    const classes = useStyles()
    const inputRef = useRef()

    const [shrink, setShrink] = useState(focused)

    useEffect(() =>  {
        if (focused) inputRef.current.focus()
    }, [])

    useEffect(() => {
        setShrink(defaultValue ? true : false)
    }, [defaultValue])

    return (
        <FormControl className={classes.root} variant="standard">
            <InputLabel
                htmlFor="standard-adornment-search"
                className={!shrink ? classes.inputLabel: ""}
                shrink={shrink}
            >
                Rechercher un nom de produit
            </InputLabel>
            <Input
                id="standard-adornment-search"
                className={classes.input}
                inputRef={inputRef}
                defaultValue={defaultValue}
                onChange={(e) => onNameChange(e.target.value)}
                startAdornment={
                    <InputAdornment position="end">
                        <IconButton className={classes.iconButton} aria-label="search" size="large">
                            <SearchIcon htmlColor={COLORS.SITE_BORDER}/>
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}