import React, { useMemo, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Stack } from "@mui/material"
import { StyledErrorMessage, StyledSubStatusTitle } from "../styledComponents"
import ProductionStepExecutionCoolingFormContainer from "./ProductionStepExecutionCoolingFormContainer"
import { getPSEMachinesOptionsSelector, getProductionStepsKitchenAreaSelector } from "../../../reducers/Production/ProductionSteps"
import { loadPSEMachinesOptions } from "../../../actions/ProductionSteps/ProductionStepExecutions"
import ProductionStepExecutionCookingFormContainer from "./ProductionStepExecutionCookingFormContainer"

const ProductionStepExecutionTemperatureValidation = ({ productionStepExecution, handleSubmit }) => {

  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState(null)

  const kitchenArea = useSelector(getProductionStepsKitchenAreaSelector)
  const machinesOptions = useSelector(getPSEMachinesOptionsSelector)

  const transformationType = useMemo(() => productionStepExecution.productionStepSnapshot.transformation, [productionStepExecution])

  const title = useMemo(() => {
    return `Saisissez l'heure et la température de fin de ${transformationType === "COOLING" ? "refroidissement" : "cuisson"}.`
  })

  const _loadMachinesOptions = (productionStepExecution, kitchenAreaId) => {
    dispatch(loadPSEMachinesOptions({ productionStepExecution, kitchenAreaId }))
  }

  const handleErrorMessageUpdate = (message) => setErrorMessage(message)

  useEffect(() => {
    if (kitchenArea && productionStepExecution) {
      _loadMachinesOptions(productionStepExecution, kitchenArea.objectId)
    }

  }, [productionStepExecution, transformationType, kitchenArea])

  return (
    <Stack gap={2}>
      <StyledSubStatusTitle>{title}</StyledSubStatusTitle>
      <ProductionStepExecutionCoolingFormContainer
        productionStepExecution={productionStepExecution}
        visible={transformationType === "COOLING"}
        machinesOptions={machinesOptions}
        handleSubmit={handleSubmit}
        updateErrorMessage={handleErrorMessageUpdate}
        isEnd
      />
      <ProductionStepExecutionCookingFormContainer
        productionStepExecution={productionStepExecution}
        visible={transformationType === "COOKING"}
        machinesOptions={machinesOptions}
        handleSubmit={handleSubmit}
        updateErrorMessage={handleErrorMessageUpdate}
      />
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </Stack>
  )
}

export default ProductionStepExecutionTemperatureValidation