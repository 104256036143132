import React from "react"
import { Formik, Form } from "formik"
import { TextField, Stack } from "@mui/material"
import { Autocomplete } from "@mui/material"
import * as Yup from "yup"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
	error: {
		color: "red",
		fontSize: 12,
		marginTop: 8
	}
})

const PSESubstitutionSupplierItemsForm = ({
	handleSubmit,
	formId,
	supplierItems
}) => {

	const classes = useStyles()

	const formatAutocompleteOptions = (supplierItems) =>
		supplierItems.map((supplierItem) => ({
			label: supplierItem.objectId + " - " + supplierItem.name,
			value: supplierItem.objectId,
			data: supplierItem,
		}))


	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={{
				originalSupplierItem: {
					value: "",
					label: ""
				},
				replacementSupplierItem: {
					objectId: "",
					label: ""
				}
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={Yup.object().shape({
				originalSupplierItem: Yup.object().shape({
					value: Yup.string().required("Veuillez sélectionner un article à remplacer")
				}),
				replacementSupplierItem: Yup.object().shape({
					value: Yup.string().required("Veuillez sélectionner un article de remplacement")
				})
			})}
		>
			{({ handleChange, errors, setFieldValue }) => {
				return (
					<Form id={formId}>
						<Stack spacing={5}>
							<Stack>
								<Autocomplete
									id="originalSupplierItem"
									name="originalSupplierItem"
									options={formatAutocompleteOptions(supplierItems)}
									getOptionLabel={(option) => option.label}
									onChange={(_, value) => setFieldValue("originalSupplierItem", value)}
									isOptionEqualToValue={(option, value) =>
										option?.objectId === value?.objectId
									}
									error={errors.originalSupplierItem}
									renderInput={(params) => (
										<TextField
											variant="standard"
											{...params}
											onChange={handleChange}
											label="Article à remplacer"
										/>
									)}
								/>
								{errors.originalSupplierItem?.value && (
									<div className={classes.error}>{errors.originalSupplierItem?.value}</div>
								)}
							</Stack>
							<Stack>
								<Autocomplete
									id="replacementSupplierItem"
									name="replacementSupplierItem"
									options={formatAutocompleteOptions(supplierItems)}
									getOptionLabel={(option) => option.label}
									onChange={(_, value) => setFieldValue("replacementSupplierItem", value)}
									isOptionEqualToValue={(option, value) =>
										option?.objectId === value?.objectId
									}
									error={errors.replacementSupplierItem}
									renderInput={(params) => (
										<TextField
											variant="standard"
											{...params}
											onChange={handleChange}
											label="Article de remplacement"
										/>
									)}
								/>
								{errors.replacementSupplierItem?.value && (
									<div className={classes.error}>{errors.replacementSupplierItem?.value}</div>
								)}
							</Stack>
						</Stack>
					</Form>
				)
			}}
		</Formik>
	)

}

export default PSESubstitutionSupplierItemsForm