import React from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import { getRecipeCostDataColumn } from "../../utils/recipes"
import { ACTIVE_KFC_BRANDS, COLORS, ACTIVE_KFC_BRANDS_NAMES } from "../../utils"

import { grey, green } from "@mui/material/colors"

const headCells = [
    { id: "labels", numeric: false, disablePadding: false, label: "Coûts" },
]

const EnhancedTableHead = ({classes, columns}) => {
    return (
        <TableHead>
            <TableRow>
                {
                    columns.map((column, index) =>
                        <TableCell
                            classes={{root: classes.headCell}}
                            key={index}
                            align={column.numeric ? "right" : "left"}
                            padding={column.disablePadding ? "none" : "default"}
                        >
                            {
                                <div className={column.numeric ? classes.cellHeadContent : classes.labelCellHeadContent}>
                                    {
                                        column.image
                                            ?
                                            <img
                                                className={classes.cellHeadImage}
                                                src={column.image}
                                                alt="logo"
                                            />
                                            : null
                                    }
                                    <div style={{color: column.color ? column.color : grey[700]}}>
                                        {column.label}
                                    </div>
                                </div>
                            }
                        </TableCell>
                    )
                }
            </TableRow>
        </TableHead>
    )
}

export const Cost = (props) => {
    const { classes, jsonRecipe } = props

    const brandsColumns = jsonRecipe && Array.isArray(jsonRecipe.brands)
        ? ACTIVE_KFC_BRANDS.filter(elem => jsonRecipe.brands.includes(elem.name))
            .map(elem => ({
                id: elem.name,
                numeric: true,
                disablePadding: false,
                label: elem.label,
                image:  elem.image,
                color: elem.color
            }))
        : []
    const columns = headCells.concat(brandsColumns)
    const brandSorting = ACTIVE_KFC_BRANDS.map( brand => brand.name )
    const brands = jsonRecipe && Array.isArray(jsonRecipe.brands)
        ? jsonRecipe.brands
        .filter(brand => ACTIVE_KFC_BRANDS_NAMES.includes(brand))
        .sort((a, b) => brandSorting.indexOf(a) - brandSorting.indexOf(b))
        : []

    const data = getRecipeCostDataColumn(jsonRecipe)

    return (
        <Paper className={classes.container}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        columns={columns}
                    />
                    <TableBody>
                        {
                            data.map((elem, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        classes={{
                                            root: index === 1 || index === 2
                                                ? classes.rowWithBorder
                                                : classes.rowWithoutBorder
                                        }}
                                    >
                                        <TableCell
                                            align="left"
                                            classes={{
                                                root: index === 1 || index === 2
                                                    ? classes.cellSmallPadding
                                                    : classes.cellLargePadding
                                            }}
                                        >
                                            {elem.label}
                                        </TableCell>
                                        {
                                            brands.map((brand, brandIndex) =>
                                                <TableCell
                                                    key={brandIndex}
                                                    align="right"
                                                    classes={{
                                                        root: index === 1 || index === 2
                                                            ? classes.cellSmallPadding
                                                            : classes.cellLargePadding
                                                    }}
                                                >
                                                    {
                                                        typeof elem[brand] === "object"
                                                            ?
                                                            <>
                                                                {
                                                                    elem[brand].hasOwnProperty("pct")
                                                                        ?
                                                                        <span
                                                                            className={elem[brand].hasOwnProperty("redPCT")
                                                                                ? parseFloat(elem[brand].pct) > elem[brand].redPCT
                                                                                    ? classes.redPctLabel
                                                                                    : classes.greenPctLabel
                                                                                : classes.pctLabel
                                                                            }
                                                                        >
                                                                            {elem[brand].pct}
                                                                        </span>
                                                                        : null
                                                                }
                                                                <span className={classes.priceLabel}>
                                                                    {elem[brand].value}€
                                                                </span>
                                                            </>
                                                            :
                                                            <>
                                                                <span className={classes.priceLabel}>{elem[brand]}</span>
                                                            </>
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: "16px 32px",
        marginTop: 16,
        paddingBottom: 16,
        borderRadius: 5,
    },
    headCell: {
        padding: "16px 0px",
        fontSize: 18,
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.87)"
    },
    cellHeadContent: {
        padding: "16px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    cellHeadImage: {
        marginRight: 8,
        width: 20,
        height: 20
    },
    labelCellHeadContent: {
    },
    cell: {
      borderBottom: "none"
    },
    cellWithoutBorder: {
        composes: "$cell",
        border: "none",
    },
    cellLargePadding: {
        composes: "$cell",
        padding: "10px 0px"
    },
    cellSmallPadding: {
        composes: "$cell",
        padding: "5px 0px"
    },
    rowWithBorder: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    rowWithoutBorder: {
    },
    priceLabel: {
        fontSize: 14,
        color: grey[600],

    },
    pctLabel: {
        fontWeight: "bold",
        fontSize: 16,
        marginRight: 8
    },
    greenPctLabel: {
        composes: "$pctLabel",
        color: green[700]
    },
    redPctLabel: {
        composes: "$pctLabel",
        color: COLORS.RED_ERROR
    }
}

export default withStyles(styles)(Cost)
