import { COLORS, forEachBrand} from "./utils"

import { cyan, blueGrey, grey, red, green, blue } from "@mui/material/colors"

export default {
    paper: {
        padding: 8
    },
    rootMenu: {
        backgroundColor: COLORS.BACKGROUND_LIGHT_GREY,
        width: 200
    },
    height100: {
        height: "100%"
    },
    width100: {
        width: "100%"
    },
    cardContainer: {
        position: "relative",
        height: "100%",
        padding: "8px"
    },
    disabled: {
        color: COLORS.DISABLED
    },
    smallIconButton: {
        width: 24,
        height: 24,
        padding: 0
    },
    wideDialog: {
        //width: "95%",
        height: "500px", // the real height is set by max-height, by Chrome still needs height for the children layout
        position: "relative"
    },
    //------------------------- styles used by object spread -------------------//
    flexRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
    },
    ellipsis: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    flex1: {
        flex: 1
    },
    shrink0: {
        flexShrink: 0
    },
    shrink1: {
        flexShrink: 1
    },
    overflowAuto: {
        overflow: "auto"
    },
}

export const cyanA400 = cyan.A400
export const disabledInputColor = "#f9f9f9" // DISABLED_INPUT_COLOR
export const backgroundGray = "#F8F8F8" // BACKGROUND_GREY
export const lightGreen = "#CEECD6" // LIGHT_GREEN

export const recipeFormstyles = {
    container: {
        padding: 16,
        width: "100%"
    },
    formControl: {
        margin: 10
    },
    formControlFlex1: {
        composes: "$formControl flex1"
    },
    formControlMax250: {
        composes: "$formControl flex1",
        maxWidth: 250
    },
    formControlMax400: {
        composes: "$formControl flex1",
        maxWidth: 400
    },
    switchLabel: {
        marginTop: 10
    },
    reusablePackagingInput: {
        width: 200
    },
    formControl100: {
        composes: "$formControl width100",
        marginLeft: 20
    },
    formControl50: {
        composes: "$formControl",
        width: "50%"
    },
    formControlFull: {
        width: "100%",
        composes: "$formControl"
    },
    select: {
        composes: "$formControl",
        minWidth: 120
    },
    statusSelect: {
        composes: "$formControl",
        minWidth: 170
    },
    statusSelectLarge: {
        composes: "$formControl",
        minWidth: 250
    },
    disabledSelect: {
        backgroundColor: disabledInputColor
    },
    internalTag: {
        composes: "$formControl",
        minWidth: 500
    },
    provider: {
        composes: "$formControl",
        minWidth: 200,
        marginLeft: 20
    },
    textArea: {
        composes: "$formControl",
        width: 300
    },
    textArea100: {
        composes: "$formControl width100",
    },
    textAreaFlex1: {
        composes: "$formControl flex1"
    },
    seasonContainer: {
        composes: "flexRow",
        marginTop: 20
    },
    stepBloc: {
        marginLeft: 20
    },
    ingredientBloc: {
        marginLeft: 10
    },
    seasonLabel: {
        marginRight: 20,
        marginLeft: 10,
        marginTop: 15
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
        textDecoration: "underline",
        margin: 20
    },
    gestersControl: {
        composes: "$formControl",
        minWidth: 100
    },
    gestersContainer: {
        composes: "flexRow",
        justifyContent: "strech",
        flex: 1
    },
    packagingContainer: {
        flex: 1,
        margin: 10
    },
    packagingLabelContainer: {
        margin: 30,
        marginTop: 40,
        marginLeft: 20
    },
    packagingLabel: {
        margin: 10,
        marginBottom: 30,
        marginTop: 35,
        textAlign: "right",
        minWidth: 200
    },
    subPackagingLabel: {
        composes: "$packagingLabel",
        marginTop: 45
    },
    labelBlue: {
        color: "blue"
    },
    labelGreen: {
        color: COLORS.GREEN_400
    },
    difficultyContainer: {
        composes: "flexRow",
        marginTop: 28,
        marginLeft: 10,
        marginBottom: 20
    },
    difficultyLabel: {
        marginRight: 20,
        paddingTop: 8
    },
    buttonContainer: {
        composes: "flexRow",
        justifyContent: "flex-end"
    },
    addAndRemoveButtonsContainer: {
        display: "flex"
    },
    actionButton: {
        marginRight: 8
    },
    sectionList: {
        marginTop: 15
    },
    textFieldDisabled: {
        backgroundColor: disabledInputColor,
        paddingLeft: 5
    },
    redTextFieldDisabled: {
        backgroundColor: disabledInputColor,
        paddingLeft: 5,
        color: COLORS.RED
    },
    selectAutoContainer: {
        composes: "flex1",
        minWidth: 250,
        marginRight: 10,
        marginLeft: 10
    },
    ingredientData: {
        display: "contents"
    },
    selectAuto: {
        composes: "$formControl width100",
        minWidth: 200,
        marginTop: 22
    },
    sectionContainer: {
        marginLeft: 50,
        marginTop: 20,
        width: "100%",
        overflow: "inherit"
    },
    sectionContainer2: {
        width: "100%",
        overflow: "inherit"
    },
    sectionRow: {
        composes: "flexRow center"
    },
    sectionCheckBox: {
        composes: "$sectionRow",
        marginTop: 20
    },
    sectionCheckBoxLabel: {
        marginLeft: 10,
        marginRight: 10
    },
    sectionInput: {
        flexGrow: 1,
        width: 400
    },
    addButton: {
        marginTop: 20
    },
    stepContainer: {
        marginTop: 20,
        marginLeft: 30
    },
    sectionSeparator: {
        border: "1px solid rgba(0,0,0, 0.3)",
        borderRadius: 5,
        padding: 10,
        marginBottom: 10
    },
    ingredientContainer: {
        composes: "$stepContainer",
        marginTop: 5
    },
    stepRow: {
        composes: "flexRow center"
    },
    numberControl: {
        composes: "$formControl",
        width: 120
    },
    instructionsContainer: {
        marginLeft: 20,
        marginTop: 20,
        overflow: "inherit"
    },
    instructionRoot: {
        marginRight: 50
    },
    instructionRow: {
        composes: "flexRow",
        paddingBottom: 20,
        marginTop: 10,
        marginBottom: 20,
        borderBottom: "1px solid rgba(0,0,0, 0.1)"
    },
    instructionRowLabel: {
        width: 100,
    },
    instructionRowData: {
        float: "right"
    },
    instructionDataTextArea: {
        composes: "$textArea",
        marginTop: 0
    },
    sameInstructions: {
        composes: "$formControl",
        marginLeft: -5
    },
    closeWindow: {
        textAlign: "end"
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 20,
        margin: 4,
    },
    subcontractorCommercialNameBloc: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginLeft: 10
    },
    subcontractorCommercialNamePourcentageBloc: {
        composes: "$subcontractorCommercialNameBloc",
        marginTop: 10
    },
    subcontractorPourcentageInput: {
        marginLeft: 10,
        width: 50
    },
    subcontractorCommercialNameBlocTitle: {
        fontSize: 18,
        marginTop: 20,
        marginLeft: 10,
        marginBottom: 20
    },
    sortSubcontractorCommercialNames: {
        marginTop: 10,
        marginLeft: 20
    },
    subcontractorCommercialNameInput: {
        marginLeft: 8,
        flex: 1,
        color: "black !important"
    },
    addIcon: {
        cursor: "pointer",
        marginTop: 10
    },
    errorEmpty: {
        margin: 0,
        fontSize: "0.75em",
        textAlign: "left",
        position: "absolute",
        marginTop: 55,
        minHeight: "1em",
        lineHeight: "1em",
        color: COLORS.SECONDARY_COLOR
    },
    errorEmpty2: {
        composes: "$errorEmpty",
        marginTop: 40
    },
    secondOriginAverage: {
        marginTop: 35,
        marginLeft: 10
    },
    commentField: {
        width: "100%"
    },
    labelIcon: {
        margin: "auto"
    },
    seasons: {
        marginTop: "12px"
    }
}

forEachBrand(brand => {
    recipeFormstyles[`${brand.name}Label`] = {
        color: brand.color
    }
})

export const recipeCommentStyle = {
    flexRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap"
    },
    container: {
        padding: 20,
        width: "100%"
    },
    commentContainer: {
        width: "100%",
        height: "100%"
    },
    buttonContainer: {
        composes: "flexRow",
        justifyContent: "flex-end"
    },
    formControl: {
        margin: 10,
        width: "100%"
    },
    commentField: {
        width: "100%"
    }
}

export const RecipeStylesNew = {
    /**
     * RECIPE BASE
     */
    header: {
        textAlign: "right",
        padding: 20,
        "& > p": {
            display: "inline-block",
            marginRight: 20,
            color: blueGrey[500]
        }
    },
    editButton: {
        height: 42
    },
    container: {
        "& input, & textarea, & select": {
            outline: "none",
            borderRadius: 5,
            border: `1px solid ${grey[400]}`,
            paddingLeft: 10,
            paddingRight: 10,
            width: "100%"
        },
        "& select": {
            height: 38,
            width: "100%",
            backgroundColor: COLORS.WHITE,
            color: blueGrey[800]
        },
        "& input, & select": {
            lineHeight: 2.5,
            fontSize: "0.875rem"
        },
        "& textarea": {
            lineHeight: 1.3,
            textAlign: "left",
            resize: "none",
            fontSize: 15,
            color: blueGrey[800]
        }
    },
    marginTop: {
        marginTop: 64
    },
    natHeader: {
        height: 80,
        backgroundColor: COLORS.WHITE,
    },
    natHeaderInfos: {
        fontSize: 16
    },
    natBorder: {
        border: `1px solid ${COLORS.GREY_NAT_BORDER}`,
        "&.show": {
            // cursor: "auto"
        }
    },
    alignLeft: {
        textAlign: "left"
    },
    alignCenter: {
        textAlign: "center"
    },
    alignRight: {
        textAlign: "right"
    },
    natCell: {
        paddingLeft: 10,
        paddingRight: 10
    },
    natColumnTitle: {
        color: "#4e6a78",
        fontWeight: "bold",
        fontSize: "0.8rem"
    },
    bold: {
        fontWeight: "bold"
    },
    greenPCT: {
        color: green[500],
        fontWeight: "bold"
    },
    redPCT: {
        color: red[500],
        fontWeight: "bold"
    },
    editHover: {
        border: `2px solid ${blue[500]}`
    },
    expansionPannelSummary: {
        padding: 0
    },
    focused: {}, // We need to keep this class empty, do not remove
    expanded: {}, // Same here
    deleteIcon: {
        verticalAlign: "middle"
    },
    deleteIconRed: {
        composes: "$deleteIcon",
        color: red[900]
    },
    addButtonStepAndIngredient: {
        cursor: "pointer",
        color: grey[500],
        marginLeft: 10
    },
    error: {
        color: red[500],
        fontSize: 14
    },
    rowsContainer: {
        width: "100%"
    },
    scrollContainerEditing: {
        composes: "$rowsContainer",
        maxHeight: "calc(100vh - 144px)",
        minHeight: "calc(100vh - 144px)",
    },
    scrollContainer: {
        composes: "$rowsContainer",
        maxHeight: "calc(100vh - 80px)"
    },
    pricePadding: {
        paddingRight: 25
    },
    cookingModePadding: {
        paddingLeft: 25
    },
    blueGrey500: {
        color: blueGrey[500]
    },
    blueGrey800: {
        color: blueGrey[800]
    },

    /**
     * RECIPE SECTIONS
     */
    sectionHeader: {
        backgroundColor: COLORS.BLUEGREY_CUSTOM,
        cursor: "pointer",
        padding: 0
    },
    sectionName: {
        fontWeight: "bold",
        color: "#37474F",
        fontSize: 16
    },
    sectionNameView: {
        composes: "$sectionName",
        marginLeft: 70
    },
    sectionNameEdit: {
        composes: "$sectionName",
        marginLeft: 60,
        display: "flex"
    },
    sectionNameInput: {
        composes: "$sectionName",
        marginLeft: 20,
    },
    sectionNameWhiteInput: {
        composes: "$sectionNameInput",
        background: COLORS.WHITE_INPUT,
        "& .MuiAutocomplete-inputRoot.MuiInputBase-root": {
            "&:before": {
                borderBottom: "none",
                "&:hover": {
                    borderBottom: "none",
                }
            },
            "& .MuiAutocomplete-input": {
                paddingLeft: 5
            }
        }
    },
    starIconFilled: {
        paddingTop: 8,
        "&.show": {
            cursor: "auto"
        }
    },
    starIcon: {
        composes: "$sectionNameInput",
        "& .filled": {
            display: "none"
        },
        "&:hover": {
            "& .filled": {
                display: "block"
            },
            "& .border": {
                display: "none"
            },
        }
    },
    summaryRootAndContentSection: {
        margin: 0,
        minHeight: 48,
        flexDirection: "row-reverse",
        " & .MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            left: 40
        },
        "&$expanded": {
            minHeight: 48,
            marginTop: 0,
            marginBottom: 0
        },
        "&$focused": {
            backgroundColor: COLORS.BLUEGREY_CUSTOM
        }
    },
    expandIconSection: {
        position: "absolute",
        left: 30,
    },
    detailsRootSection: {
        display: "block",
        padding: 0
    },
    sectionText: {
        paddingLeft: 23,
        display: "flex",
        "&.center": {
            alignItems: "center"
        }
    },
    sectionTextBlock: {
        composes: "$sectionText",
        alignItems: "center"
    },
    addButtonSection: {
        position: "absolute",
        left: 15,
        cursor: "pointer",
        color: grey[500]
    },
    errorSection: {
        marginLeft: 15,
        color: red[500]
    },
    sectionLineError: {
        backgroundColor: red[200]
    },
    sectionInherited: {
        "&.MuiPaper-root.MuiExpansionPanel-root": {
            backgroundColor: COLORS.SECTION_INHERITED,
            "& div": {
                color: COLORS.BUTTON_INHERITED,
            },
            "&:hover": {
                backgroundColor: COLORS.SECTION_INHERITED,
                "& div": {
                    color: COLORS.BUTTON_INHERITED,
                }
            }
        }
    },
    stepInherited: {
        "&.MuiButtonBase-root": {
            backgroundColor: COLORS.STEP_INHERITED,
            "& div": {
                color: COLORS.BUTTON_INHERITED,
            },
            "&:hover": {
                backgroundColor: COLORS.STEP_INHERITED,
                "& div": {
                    color: COLORS.BUTTON_INHERITED,
                }
            }
        }
    },
    ingredientInherited: {
        "&.MuiGrid-root": {
            backgroundColor: COLORS.INGREDIENT_INHERITED,
            "& div": {
                color: COLORS.BUTTON_INHERITED,
            },
            "&:hover": {
                backgroundColor: COLORS.INGREDIENT_INHERITED,
                "& div": {
                    color: COLORS.BUTTON_INHERITED,
                }
            }
        }
    },
    buttonPercentageInherited: {
        backgroundColor: COLORS.BUTTON_INHERITED,
        width: 57,
        borderRadius: 3,
        marginRight: 4,
        "&.MuiInputBase-root > input": {
            paddingRight: 0,
            border:0,
            color: COLORS.WHITE,
            borderRadius: 0
        },
        "&.MuiInputBase-root > .MuiInputAdornment-root > p" : {
            color: COLORS.WHITE,
            paddingRight: 3
        }
    },
    buttonPercentageInheritedHovered: {
        composes: "$buttonPercentageInherited",
        backgroundColor: COLORS.WHITE_INPUT,
        "&.MuiInputBase-root > input": {
            color: COLORS.BLACK
        },
        "&.MuiInputBase-root > .MuiInputAdornment-root > p" : {
            color: COLORS.BLACK
        }
    },
    /**
     * RECIPE STEPS
     */
    stepHeader: {
        backgroundColor: grey[100],
        cursor: "pointer",
        padding: 0
    },
    stepIndex: {
        fontWeight: "bold",
        marginRight: 5
    },
    expansionRoot: {
        "&$expanded": {
            margin: 0
        }
    },
    summaryRootAndContentStep: {
        margin: 0,
        minHeight: 48,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            position: "absolute",
            left: 50
        },
        "&$expanded": {
            minHeight: 48,
            marginTop: 0,
            marginBottom: 0
        },
        "&$focused": {
            backgroundColor: grey[100]
        }
    },
    expandIconStep: {
        position: "absolute",
        left: 40
    },
    detailsRoot: {
        padding: 0
    },
    stepDescription: {
        padding: "10px 10px 10px 30px",
        color: blueGrey[800],
        fontSize: 15,
        "& > div": {
            whiteSpace: "pre-wrap",
            textAlign: "left"
        }
    },
    stepLineError: {
        backgroundColor: red[100]
    },

    /**
     * RECIPE INGREDIENTS
     */
    content: {
        minHeight: 48,
        backgroundColor: COLORS.WHITE
    },
    ingredientLineContainer: {
        "& > div": {
            width: "100%"
        }
    },
    ingredientName: {
        paddingLeft: 50,
        color: blueGrey[800]
    },
    ingredientField: {
        paddingLeft: 40,
        paddingRight: 10
    },
    ingredientLineError: {
        backgroundColor: red[50]
    },
    selectFormControl: {
        width: "100%",
        marginLeft: -13,
        "& .MuiInputBase-root": {
            fontSize: 14,
            height: 40
        }
    }
}

export const RecipeDetailsStyles = {
    rootContainer: {
        background: COLORS.WHITE
    },
    header: {
        textAlign: "right",
        padding: 20,
        "& > p": {
            display: "inline-block",
            marginRight: 20,
            color: blueGrey[500]
        }
    },
    editButton: {
        height: 42
    },
    marginTop: {
        marginTop: 64
    },
    form: {
      display: "flex",
      justifyContent: "center"
    },
    container: {
        overflowX: "scroll",
        backgroundColor: "white",
        "& td": {
            padding: 8
        },
        "& tr td:not(:first-child)": {
            minWidth: 291
        },
        "& input, & textarea, & select": {
            outline: "none",
            borderRadius: 5,
            border: "1px solid white",
            paddingLeft: 10,
            paddingRight: 10,
            width: "100%",
            marginBottom: 5,
            backgroundColor: "white",
            fontFamily: "Roboto"
        },
        "& input::placeholder, & textarea::placeholder": {
            color: blueGrey[300],
            fontStyle: "italic"
        },
        "& select": {
            height: 38,
            width: "100%",
            backgroundColor: COLORS.WHITE,
            color: blueGrey[800]
        },
        "& input, & select": {
            lineHeight: 2.5,
            fontSize: "0.875rem"
        },
        "& textarea": {
            lineHeight: 1.3,
            textAlign: "left",
            resize: "none",
            fontSize: 15,
            color: blueGrey[800],
            minHeight: 40
        },
        [`& input:not([disabled]):hover, & textarea:not([disabled]):hover, & select:not([disabled]):hover,
            & input:not([disabled]):focus, & textarea:not([disabled]):focus, & select:not([disabled]):focus`
        ]: {
            border: `1px solid ${grey[400]}`
        },
        "& select:disabled": {
            "-webkit-appearance": "none",
            "appearance": "none"
        }
    },
    leftHeader: {
        borderRight: `1px solid ${COLORS.GREY_NAT_BORDER}`,
        width: 207,
        fontSize: 13,
        verticalAlign: "middle",
        padding: "18px 10px 10px 10px"
    },
    contentCell: {
        minWidth: 291
    },
    logo: {
        width: 21,
        marginRight: 5
    },
    sectionTitle: {
        composes: "$leftHeader",
        fontWeight: "bold",
        color: blueGrey[900],
        fontSize: 16
    },
    sectionSeparator: {
        backgroundColor: COLORS.BLUEGREY_CUSTOM
    },
    brandHeader: {
        "& div": {
            display: "flex",
            alignItems: "center"
        },
        textTransform: "uppercase"
    },
    brandHeaderDefault: {
        composes: "$brandHeader",
        borderBottom: `3px solid ${blue[500]}`,
        color: blue[500],
        fontSize: 16
    },
    default: {
        color: blueGrey[300],
        fontStyle: "italic"
    },
    imageContainer: {
        position: "relative",
        width: "50%",
        "&:hover $imageOverlay": {
            opacity: 1
        },
        marginBottom: 10
    },
    image: {
        display: "block",
        width: "100%",
        height: "auto",
        "&.subcontractor": {
            width: "auto"
        }
    },
    defaultImage: {
        composes: "$image",
        filter: "opacity(0.4)"
    },
    imageOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: ".5s ease"
    },
    deleteImageIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 5,
        backgroundColor: COLORS.BLACK,
        color: "white",
        "& svg": {
            cursor: "pointer",
            fontSize: 15
        }
    },
    addPhoto: {
        color: blue[500],
        fontSize: 14,
        cursor: "pointer",
        textAlign: "left",
        marginLeft: 10
    },
    iconFieldWrapper: {
        marginBottom: 20,
    },
    iconField: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            position: "relative",
            left: 5,
            fontSize: ".8em",
            marginTop: -5,
            color: grey[500]
        },
        "& input": {
            marginLeft: -10,
            paddingLeft: 25
        }
    },
    error: {
        color: "red"
    },
    fieldError: {
        border: "1px solid red !important"
    }
}

forEachBrand(brand => {
    RecipeDetailsStyles[`brandHeader${brand.name}`] = {
        composes: "$brandHeader",
        borderBottom: `3px solid ${brand.color}`,
        color: brand.color,
        fontSize: 16
    }
})