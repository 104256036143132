import { getData } from ".."

const storage = JSON.parse(localStorage.getItem("searchValue"))

const defaultState = {
    sites: [],
    selectedSite: null,
    siteSnackBar: { open: false, type: "", message: "", duration: 0 },
    siteFilters: {
        rowsPerPage: 10,
        page: 0,
        search: (storage && storage.siteList) ? storage.siteList : ""
    },
    total: 0,
    kitchenAreas: [],
    kitchenArea: null,
    supplierItems: [],
    packagingLines: [],
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "SITES_LOADED":
            return {
                ...state,
                sites: action.sites.sites,
                total:  action.sites.total,
                selectedSite: action.selectedSite
            }
        case "SITE_SAVED":
            return {
                ...state,
                selectedSite: action.selectedSite
            }
        case "SITE_SELECTED":
            return {
                ...state,
                selectedSite: action.selectedSite
            }
        case "KITCHEN_AREAS_LOADED":
            return {
                ...state,
                kitchenAreas: action.kitchenAreas,
            }
        case "KITCHEN_AREAS_CLEARED":
            return {
                ...state,
                kitchenAreas: [],
            }
        case "KITCHEN_AREA_LOADED":
            return {
                ...state,
                kitchenArea: action.kitchenArea,
            }
        case "SITE_PACKAGING_LINES_LOADED":
            return {
                ...state,
                packagingLines: [],
            }
        case "SITE_PACKAGING_LINE_CREATED":
            return {
                ...state,
                packagingLines: [action.packagingLine, ...state.packagingLines],
            }
        case "PRODUCTION_STEPS_SUPPLIER_ITEMS_LOADED":
            return {
                ...state,
                supplierItems: action.supplierItems,
            }
        case "SELECTED_SITE_UPDATED":
            return {
                ...state,
                selectedSite: action.selectedSite
            }
        case "SITE_UPDATE_LIST_FILTERS":
            return {
                ...state,
                siteFilters: {
                    ...state.siteFilters,
                    ...action.change
                }
            }
        case "CLOSE_SITE_SNACKBAR":
            return {
                ...state,
                siteSnackBar: action.siteSnackBar
            }
        case "SITES_ERROR_LOADED":
            return {
                ...state,
                sitesErrors: action.sitesErrors,
                selectedSite: action.selectedSite
            }
        case "SITE_IMAGE_SAVED":
            return {
                ...state,
                siteSnackBar: action.siteSnackBar
            }
        case "SITE_IMAGE_REMOVE":
            return {
                ...state,
                siteSnackBar: action.siteSnackBar
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getReduxSite(state, id) {
    if (!id) {
        return null
    }
    return (state.sites.sites || []).find(site => site.id === id)
}

export function getSelectedSite(state, errorIfNotFound = false) {
    return getData(state, "sites.selectedSite", errorIfNotFound && "No Site selected")
}
export function getKitchenAreasSelector(state, errorIfNotFound = false) {
    return getData(state, "sites.kitchenAreas", errorIfNotFound && "No Site selected")
}
export function getKitchenAreaSelector(state, errorIfNotFound = false) {
    return getData(state, "sites.kitchenArea", errorIfNotFound && "No Site selected")
}
export function getSitesSelector(state, errorIfNotFound = false) {
    return getData(state, "sites.sites", errorIfNotFound && "No Site selected")
}
export function getSupplierItemsSelector(state, errorIfNotFound = false) {
    return getData(state, "sites.supplierItems", errorIfNotFound && "No Site selected")
}
export const getSitePackagingLineSelector = (state, errorIfNotFound = false) => {
    return getData(state, "sites.packagingLines", errorIfNotFound && "No Site selected")
}
