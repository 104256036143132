import React from "react"

import { FormControlLabel, Stack, Switch, Typography } from "@mui/material"

const SwitchOutputProduction = ({ onCheck, checked }) => {
  const handleCheck = (event) => {
    onCheck(event.target.checked)
  }

  const label = (
    <Typography variant="body2" sx={{ color: "grey.600" }}>
      {checked ? "ProductionNeeds" : "ProductionItems"}
    </Typography>
  )

  return (
    <Stack direction="row" alignItems="center" spacing={5}>
      <Typography variant="body2" sx={{ color: "grey.600" }}>
        Données utilisées pour les besoins de sortie :
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleCheck}
            color="secondary"
          />}
        label={label}
      />
  </Stack>
  )
}

export default SwitchOutputProduction