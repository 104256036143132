import React from "react"
import { Table, TableHead, TableBody, TableRow } from "@mui/material"
import { isEqual } from "lodash"

import { PETableCell, PETableHeaderCell } from "../StyledPackagingExecutionPreviewComponents"
import { packagingNature } from "../../../utils/packaging"
import { COLORS } from "../../../utils"
import { timestampToDate } from "../../../utils"
import { getPackagingsRealNumber, getPackagingsTheoreticalNumber } from "../../../utils/packagingExecutions"

const selectedCell = {
	color: COLORS.PRIMARY_COLOR,
	backgroundColor: COLORS.BLUE_50
}

const PackagingExecutionPackagingsOverview = ({ packagings, brands, selectedPackaging }) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<PETableHeaderCell>Type de barquette</PETableHeaderCell>
					{brands.map((brand, index) => {
						return (
							<PETableHeaderCell key={index}>
								<img src={brand.image} alt={`${brand.shortName}-logo`} style={{
									width: 40,
									height: 40
								}} />
							</PETableHeaderCell>
						)
					})}
					<PETableHeaderCell>Total théorique ({getPackagingsTheoreticalNumber(packagings)})</PETableHeaderCell>
					<PETableHeaderCell>Total réel ({getPackagingsRealNumber(packagings) || "-"})</PETableHeaderCell>
					<PETableHeaderCell>Heures réelles</PETableHeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{packagings.map((packaging, index) => {
					const isSelected = isEqual(packaging, selectedPackaging)
					return (
						<TableRow
							key={index}
						>
							<PETableCell
								fontWeight={700}
								color={isSelected && selectedCell.color}
								backgroundColor={isSelected && selectedCell.backgroundColor}
							>{packagingNature.find(nature => nature.value === packaging.type)?.label}</PETableCell>
							{brands.map((brand, index) => {
								const packagingBrand = (packaging.brands || []).find(b => b.brand === brand.name)
								return (
									<PETableCell
										key={index}
										disabled
										backgroundColor={isSelected && selectedCell.backgroundColor}
									>
										{packagingBrand?.theoreticalNumber || 0}
									</PETableCell>
								)
							})}
							<PETableCell
								fontWeight={700}
								color={isSelected && selectedCell.color}
								backgroundColor={isSelected && selectedCell.backgroundColor}>
								{packaging.theoreticalNumber}
							</PETableCell>
							<PETableCell
								color={isSelected && selectedCell.color}
								backgroundColor={isSelected && selectedCell.backgroundColor}>
								{packaging.realNumber || "-"}
							</PETableCell>
							<PETableCell
								color={isSelected && selectedCell.color}
								backgroundColor={isSelected && selectedCell.backgroundColor}>
								{(timestampToDate(packaging.startTime, "HH:mm") || "").concat(" - ", (timestampToDate(packaging.endTime, "HH:mm") || ""))}
							</PETableCell>
						</TableRow>
					)
				}
				)}
			</TableBody>
		</Table>
	)
}

export default PackagingExecutionPackagingsOverview