import axios from "axios"

export async function axiosCall(method, url, data, headers, responseType) {
    try {
        const result = await axios({
            method: method,
            url: url,
            data: data,
            headers: headers,
            responseType: responseType || "json"
        })

        return Promise.resolve(result)
    }
    catch (e) {
        return Promise.reject(e)
    }
}
