import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"
import {
    getParseSupplier,
    deleteParseSuppliers
} from "../../parseManager/suppliers/suppliers/parseSupplierManager"
import { getSupplierItemsBySupplier } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import {
    getSitesNames,
    getParseSite
} from "../../parseManager/site/parseSiteManager"
import Parse from "parse"
import moment from "moment"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"

const Suppliers = Parse.Object.extend("Suppliers")

export function loadSuppliers() {
    return actionWithLoader(async (dispatch) => {
        const [suppliers, sites] = await Promise.all([
            getSuppliers({
                includes: ["informations.defaultDeliverySite"],
                sortBy: "updatedAt",
                sortDirection: "desc",
                toJSON: true
            }),
            getSitesNames()
        ])

        dispatch({
            type: "SUPPLIERS_LOADED",
            suppliers: { 
                suppliers
            },
            sites
        })
    })
}

export function loadSuppliersList() {
    return actionWithLoader(async (dispatch) => {
        const suppliers = await getSuppliers({ toJSON: true })

        dispatch({
            type: "SUPPLIERS_LIST",
            suppliers
        })
    })
}

export function onEnterSuppliers(store) {
    return onEnter({
        store,
        actionThunk: loadSuppliers,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSingleSupplier(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState

        if (params.id) {
            store.dispatch(loadSingleSupplier(params.id))
        }

        callback()
    }
}

export const onLeaveSingleSupplier = (store) => {
    return async () => {
        store.dispatch({ type: "CLEAR_SELECTED_SUPPLIER" })
    }
}

export function closeSupplierSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "CLOSE_SUPPLIER_SNACKBAR",
            supplierSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
        dispatch(loadSuppliers())
    })
}

export function deleteSuppliers(suppliers) {
    return actionWithLoader(async (dispatch) => {
        await deleteParseSuppliers(suppliers)
        await dispatch(loadSuppliers())
    })
}

export function createNewSupplier(values) {
    return actionWithLoader(async (dispatch) => {
        const supplier = new Suppliers()
        const informations = {
            clientReference: "FoodChéri"
        }
        const contact = { emails: {} }

        if (values.commercialEmails) values.commercialEmails = values.commercialEmails.split(",").map(el => el.trim())
        if (values.defaultDeliverySite) values.defaultDeliverySite = await getParseSite(values.defaultDeliverySite)

        const informationsKeys = ["defaultDeliverySite", "siret", "intraTVA", "corporateName"]
        const contactKeys = ["phone", "contactName", "commercialEmails"]

        informationsKeys.forEach(key => {
            informations[key] = values[key]
        })

        contactKeys.forEach(key => {
            if ("commercialEmails" === key) {
                contact.emails[key] = values[key]
            } else {
                contact[key] = values[key]
            }
        })

        supplier.set("name", values.name)
        supplier.set("informations", informations)
        supplier.set("contact", contact)

        await supplier.save()

        await dispatch(loadSuppliers())
        await dispatch(showSupplierNewDetails(supplier.id))
    })
}

export function loadSingleSupplier(supplierId) {
    return actionWithLoader(async (dispatch) => {
        const supplier = await getParseSupplier(supplierId, ["informations.defaultDeliverySite"])
        const supplierItems = await getSupplierItemsBySupplier(supplier)

        dispatch({
            type: "SELECTED_SUPPLIER_UPDATED",
            selectedSupplier: supplier.toJSON(),
            supplierItems
        })
    })
}

export function editNews(supplierId, news) {
    return async (dispatch) => {
        const supplier = await getParseSupplier(supplierId)

        if (!supplier) {
            return
        }

        supplier.set("news", news)
        await supplier.save()

        dispatch(loadSingleSupplier(supplierId))
    }
}

export function saveSupplier(id, values) {
    return async (dispatch) => {
        const supplier = await getParseSupplier(id)

        if (!supplier) {
            return
        }

        if (values.defaultDeliverySite) values.defaultDeliverySite = await getParseSite(values.defaultDeliverySite)
        if (values.commercialEmails && !Array.isArray(values.commercialEmails)) values.commercialEmails = values.commercialEmails.split(",").map(el => el.trim())
        if (values.orderEmails && !Array.isArray(values.orderEmails)) values.orderEmails = values.orderEmails.split(",").map(el => el.trim())
        if (values.referenceStartDate) values.referenceStartDate = moment.utc(values.referenceStartDate).startOf("day").valueOf()
        if (values.referenceEndDate) values.referenceEndDate = moment.utc(values.referenceEndDate).startOf("day").valueOf()

        const informationKeys = [
            "continentCode", "countryCode", "sanitaryApprovalNumber", "siret", "intraTVA", "referenceStartDate",
            "referenceEndDate", "clientReference", "defaultDeliverySite", "corporateName"
        ]
        const contactKeys = ["country", "zipCode", "city", "fax", "contactName", "address", "phone", "commercialEmails", "orderEmails"]
        const orderKeys = [
            "minimumCommand", "minimumCommandUnit", "forbidMinimumCommand", "deliveryDays", "shippingFees", "deliveryLeadTime",
            "showLinkInEmail", "openDays", "orderHourLimit", "sendOrderMode", "orderPrecision", "allowEarlyDelivery", "allowShippingFeesCancel"
        ]
        const billingKeys = ["invoiceEqualToBL", "autoComplain", "autoSendEmailGood", "autoSendEmailCredit", "autoSendEmailComplain"]

        const informations = {}
        const contact = { emails: {} }
        const order = {}
        const billing = {}

        let informationsSet = false
        let contactSet = false
        let orderSet = false
        let billingSet = false

        Object.keys(values).forEach(key => {
            if (informationKeys.includes(key)) {
                informations[key] = values[key]

                informationsSet = true
            } else if (contactKeys.includes(key)) {
                if (["commercialEmails", "orderEmails"].includes(key)) {
                    contact.emails[key] = values[key]
                } else {
                    contact[key] = values[key]
                }

                contactSet = true
            } else if (orderKeys.includes(key)) {
                order[key] = values[key]

                orderSet = true
            } else if (billingKeys.includes(key)) {
                billing[key] = values[key]

                billingSet = true
            } else {
                supplier.set(key, values[key])
            }
        })

        if (informationsSet) supplier.set("informations", informations)
        if (contactSet) supplier.set("contact", contact)
        if (orderSet) supplier.set("order", order)
        if (billingSet) supplier.set("billing", billing)

        await supplier.save()
        await dispatch(loadSingleSupplier(id))
    }
}

export function saveSupplierImage(image, supplierId) {
    return async (dispatch) => {
        const supplier = await getParseSupplier(supplierId)

        if (!supplier) {
            return
        }

        supplier.set("image", image)

        await supplier.save()
        await dispatch(loadSingleSupplier(supplierId))
    }
}

export function showSuppliersList() {
    return push("/suppliers/list")
}

export function showSupplierNewDetails(id) {
    return push(`/suppliers/supplier/general/${id}`)
}

export function showSupplierTab(tab, supplierId) {
    return push(`/suppliers/supplier/${tab}/${supplierId ? supplierId : ""}`)
}
