import React, { useEffect, useState } from "react"

import { useDispatch } from "react-redux"

import DataGrid from "../../../../components/DataGrid/DataGrid"
import FixedAddButton from "../../../../components/FixedAddButton"
import SupplierItemTransformationModeFormModal from "./SupplierItemTransformationModeFormModal"
import { addTransformationModesToSupplierItem, editTransformationModesToSupplierItem } from "../../../../actions/Supplier/supplierItems"
import { TRANSFORMATION_TYPES, getTransformationTypeLabel } from "../../../../utils/supplierItemUtils"

const columns = [
  {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
          filterable: true,
          type: "text",
          strict: true
      },
      width: 120
  },
  {
      key: "name",
      label: "Nom",
      dataType: "text",
      sortable: false,
      filter: {
          filterable: true,
          type: "text",
          fullText: true
      },
  },
  {
      key: "transformationType",
      label: "Type de transformation",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: TRANSFORMATION_TYPES.map((type) => ({
          key: type.value,
          value: type.label,
          label: type.label
        }))
      },
      width: 500
  },
  {
    key: "transformationRate",
    label: "Rendement (%)",
    dataType: "text",
    sortable: false,
    filter: {
        filterable: false,
    },
    width: 500
  },
]

const SupplierItemTransformationModes = ({ id, transformationModes, supplierItemTransformationModes = [] }) => {
	const [selectedRow, setSelectedRow] = useState(null)
  const [openCreationDialog, setOpenCreationDialog] = useState(false)
  // const [openCDialog, setOpenCreationDialog] = useState(false)
  const [data, setData] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    const newData = supplierItemTransformationModes.map((transformationMode) => {
      return {
        id: transformationMode.transformationMode.objectId,
        name: transformationMode.transformationMode.name,
        transformationType: getTransformationTypeLabel(transformationMode.transformationMode.transformationType),
        transformationRate: transformationMode.transformationRate
      }
    })
    const sortedData = newData.sort((a, b) => a.name.localeCompare(b.name))
    setData(sortedData)
  }, [supplierItemTransformationModes])

  const toggleCreationDialog = () => setOpenCreationDialog(!openCreationDialog)
  const closeEditionDialog = () => setSelectedRow(null)

	const handleSelectRow = (row) => {
    const transformationMode = supplierItemTransformationModes.find((item) => item.transformationMode.objectId === row.id)
    setSelectedRow(transformationMode)
  }

  const handleCreation = (values) => {
    dispatch(addTransformationModesToSupplierItem(id, values))
  }

	const handleEdition = (values) => {
    dispatch(editTransformationModesToSupplierItem(id, values))
	}
  
  return (
    <div>
      <div className="flexColumn">
        <DataGrid
          title="Modes de transformation associés à l'article"
          columns={columns}
          data={data}
          withFilters
          rowLabel="modes de transformation"
          onRowClick={handleSelectRow}
        />
        <FixedAddButton onClick={toggleCreationDialog} />
      </div> 

      {/* -------- creation modal --------  */}
      <SupplierItemTransformationModeFormModal
        open={openCreationDialog}
        onClose={toggleCreationDialog}
        onConfirm={handleCreation}
        transformationModes={transformationModes}
        supplierItemTransformationModes={supplierItemTransformationModes}
      />

      {/* -------- edition modal -------- */}
      <SupplierItemTransformationModeFormModal
        open={!!selectedRow}
        onClose={closeEditionDialog}
        onConfirm={handleEdition}
        transformationMode={selectedRow}
        transformationModes={transformationModes}
        supplierItemTransformationModes={supplierItemTransformationModes}
        isEdition
      />
  </div>
  )
}

export default SupplierItemTransformationModes