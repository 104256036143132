const defaultState = {
    groupsIngredients: [],
    familiesIngredients: []
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "CLASSIFICATIONS_GROUPS_LOADED":
            return {
                ...state,
                groupsIngredients: action.groupsIngredients,
                selectedGroupIngredient: action.selectedGroupIngredient
            }
        case "CLASSIFICATIONS_GROUP_SAVED":
            return {
                ...state
            }
        case "CLASSIFICATIONS_GROUP_FAMILIES_LOADED":
            return {
                ...state,
                familiesIngredients: action.familiesIngredients
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getGroupIngredient(state, id) {
    return state.groupsIngredients.groupsIngredients.find(groupIngredient => groupIngredient.objectId === id)
}
