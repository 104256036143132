import React, { PureComponent } from "react"
import { createRoot } from "react-dom/client"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import clsx from "clsx"

import CustomThemeProvider from "../CustomThemeProvider"

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

class PromisifiedDialog extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  cleanup() {
    this.setState({ open: false })

    //---- cleanup after Dialog closing animation ----//
    setTimeout(() => {
      this.props.root.unmount()
    }, 2000)
  }

  resolve(value) {
    this.props.deferred.resolve(value)
    this.cleanup()
  }

  _ref = (contentComponent) => {
    this._contentComponent = contentComponent
    const oldRef = this.props.content.ref
    oldRef && oldRef(contentComponent)
  }

  render() {
    let { actions, store, contentClasses = {}, ...otherProps } = this.props
    let index = 0
    actions = actions.map((action) =>
      React.cloneElement(action, {
        key: "action" + index++,
        action: undefined,
        onClick: () =>
          action.props.action(
            (value) => this.resolve(value),
            this._contentComponent
          ),
      })
    )

    // flexColumn is a good default (flex doesn't mix well and the content is usually flex)
    const classes = {
      ...contentClasses,
      root: clsx("flexColumn stretch", contentClasses.root),
    }

    const insideProvider = (
      <CustomThemeProvider>
        <Dialog open={this.state.open} {...otherProps}>
          <DialogContent classes={classes}>
            {React.cloneElement(this.props.content, {
              ref: this._ref,
            })}
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </CustomThemeProvider>
    )

    if (store) {
      const Provider = require("react-redux").Provider
      return <Provider store={store}>{insideProvider}</Provider>
    } else {
      return insideProvider
    }
  }
}

// not withTheme because we need 'innerRef'
PromisifiedDialog = withStyles({}, { withTheme: true })(PromisifiedDialog)

/**
 * @param {object} options :
 * 		{Array<ReactElement>} actions each action contains an 'action' event callback with the signature (resolve, contentComponent):void
 * 		{string} title,
 * 		{ReactElement} content
 * @return {Promise}
 */
export function showDialog(options) {
  const deferred = new Deferred()
  const dialogContainer = document.createElement("div")
  const dialogRoot = createRoot(dialogContainer)

  const { resolveRef, ...passedOptions } = options

  //---- resolveRef ----//
  const ref = (promisifiedDialog) => {
    if (resolveRef) {
      resolveRef((value) => {
        promisifiedDialog.resolve(value)
      })
    }
  }

  dialogRoot.render(
    <PromisifiedDialog
      innerRef={ref}
      deferred={deferred}
      root={dialogRoot}
      {...passedOptions}
    />,
  )

  return deferred.promise
}

export const DONT_RESOLVE = "DON'T RESOLVE"

/**
 * @param title
 * @param okText
 * @param cancelText
 * @param valueSupplier
 * @param otherOptions 'content', ...
 * @returns {Promise}
 */
export function showOkCancelDialog({
  title = "Confirmation",
  okText = "Ok",
  cancelText = "Annuler",
  valueSupplier = () => { },
  ...otherOptions
}) {
  const actions = [
    <Button
      key="cancelButton"
      action={(resolve) => resolve(null)}
    >
      {cancelText}
    </Button>,
    <Button
      key="okButton"
      color="primary"
      action={async (resolve, contentComponent) => {
        const value = await valueSupplier(contentComponent)
        if (value !== DONT_RESOLVE) {
          resolve(value)
        }
      }}
    >
      {okText}
    </Button>,
  ]
  return showDialog({
    title,
    actions,
    ...otherOptions,
  })
}

//---------------------------------------------------------------------------//
//---------------------------- classic functions ----------------------------//
//---------------------------------------------------------------------------//

//------------------------ alert -------------------------//
export function alert({
  title = "Alert",
  content,
  okText = "Ok",
  store = null,
}) {
  return showDialog({
    title,
    store,
    actions: [
      <Button
        key="alertOkButton"
        color="primary"
        action={(resolve) => resolve()}
      >
        {okText}
      </Button>,
    ],
    content: <div>{content}</div>,
  })
}

//------------------------ confirm -------------------------//
export function confirm({
  title = "Confirmation",
  message,
  confirmText = "Confirm",
  ...otherOptions
}) {
  return showOkCancelDialog({
    title,
    content: <div>{message}</div>,
    valueSupplier: () => true,
    okText: confirmText,
    ...otherOptions,
  })
}

//------------------------ prompt -------------------------//
class PromptComponent extends PureComponent {
  _setValue = (event) => {
    this._value = event.target.value
  }

  getValue = () => {
    return this._value || this.props.defaultValue
  }

  render() {
    return (
      <div>
        <div>{this.props.message}</div>
        <div>
          <TextField
            variant="standard"
            name="promptValue"
            fullWidth
            defaultValue={this.props.defaultValue}
            onChange={this._setValue}
            autoFocus
          />
        </div>
      </div>
    )
  }
}

export function prompt({
  title = "Question",
  message,
  defaultValue = "",
  ...otherOptions
}) {
  return showOkCancelDialog({
    title,
    content: <PromptComponent message={message} defaultValue={defaultValue} />,
    valueSupplier: (contentComponent) => contentComponent.getValue(),
    ...otherOptions,
  })
}

//---------------------------------------------------------------------------//
//---------------------------- Form and Redux-Form --------------------------//
//---------------------------------------------------------------------------//
/**
 * a Form must have
 * . a submit function
 * . a onSubmit callback prop with the signature (values:Array):void
 * (works with redux-form)
 * @param content "form" is an alias
 * @param title default value : "Form"
 * @param store
 * @param formProps optional
 * @param otherOptions
 * @returns {Promise}
 */
export function showFormDialog({
  title = "Form",
  store,
  content,
  formProps,
  ...otherOptions
}) {
  content = content || otherOptions.form

  if (!store && isReduxForm(content)) {
    throw new Error(
      "Missing store when calling showFormDialog with a content of type redux-form"
    )
  }

  let dialogResolve
  let formComponent
  let foundWithInnerRef = false

  //----------------- valueSupplier of the ok Action ---------------------//
  const valueSupplier = async () => {
    // contentComponent can be react-redux's Provider
    //---- submit call on the form component ----//
    if (formComponent.submit) {
      await formComponent.submit()
    } else {
      await formComponent.props.submit()
    }
    return DONT_RESOLVE // we use the resolve we got from "resolveRef"
  }

  //----------------- formElement cloning ------------------//
  // we don't use the contentComponent provided by the action callback, because it's only the <Provider>
  let formElement = React.cloneElement(content, {
    onSubmit: (values) => {
      // can be called on keyboard (like the 'enter' key)
      dialogResolve(values)
    },
    innerRef: (formComponentArg) => {
      foundWithInnerRef = true
      formComponent = toForm(formComponentArg)
    },
    ref: (formComponentArg) => {
      if (!foundWithInnerRef) {
        formComponent = toForm(formComponentArg)
      }
    },
    ...formProps,
  })

  return showOkCancelDialog({
    resolveRef: (resolve) => {
      dialogResolve = resolve
    },
    title,
    valueSupplier,
    store,
    content: formElement,
    ...otherOptions,
  })
}

export function isReduxForm(element) {
  return (
    element.type.name === "ReduxForm" ||
    (element.type.WrappedComponent &&
      element.type.WrappedComponent.name === "ReduxForm")
  )
}

/**
 * find the form into wrapped components (works with redux-form)
 * @param component
 * @returns {*}
 */
export function toForm(component) {
  if (!component) {
    return null
  } else if (component.getWrappedInstance) {
    //-- 'connect' was used (hopefully with the {withRef: true} option )--//
    return component.getWrappedInstance()
  } else {
    //-- it's the form --//
    return component
  }
}
