import { KFC_BRANDS } from "../../utils"
import {
    formatProduct,
    updateProdDataPlanning,
    updatePackagingDataPlanning
} from "../../utils/planning"

const defaultState = {
    sellDays: [],
    prevsDays: [],
    prodDays: [],
    packagingDays: [],
    showInfo: false,
    brand: KFC_BRANDS[0],
    dayStartIndex: 0,
    sellDataPlanning: [],
    prevsDataPlanning: [],
    prodDataPlanning: [],
    packagingDataPlanning: [],
    planningSnackBar: { open: false, type: "", message: "", duration: 0 },
    calculDispatchLoading: false,
    rightProduct: null,
    anyFocused: false,
    loading: new Set(),
    saved: new Set(),
    changed: {},
    prevChanged: [],
    hubs: [],
    difficultiesGesters: [],
    gestersPots: []
}

function cloneAndRemove(set, ids) {
    const newSet = new Set(set)
    ids.forEach(id => newSet.delete(id))
    
    return newSet
}

function cloneAndAdd(set, ids) {
    const newSet = new Set(set)
    ids.forEach(id => newSet.add(id))
    
    return newSet
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "UPDATE_SHOW_INFO":
            return {
                ...state,
                showInfo: action.showInfo
            }
        case "UPDATE_DAYS":
            return {
                ...state,
                days: action.days
            }
        case "UPDATE_BRAND":
            return {
                ...state,
                brand: action.brand,
                hubs: action.hubs
            }
        case "UPDATE_DAY_START_INDEX":
            return {
                ...state,
                dayStartIndex: action.dayStartIndex
            }
        case "LOAD_SELL_PLANNING_DATA":
            return {
                ...state,
                sellDays: action.sellDays,
                sellDataPlanning: action.sellDataPlanning
            }
        case "LOAD_PREVISIONS_PLANNING_DATA":
            return {
                ...state,
                prevsDays: action.prevsDays,
                prevsDataPlanning: action.prevsDataPlanning,
                hubs: action.hubs
            }
        case "LOAD_PRODUCTION_PLANNING_DATA":
            return {
                ...state,
                prodDays: action.prodDays,
                prodDataPlanning: action.prodDataPlanning,
                rules: action.rules,
                difficultiesGesters: action.difficultiesGesters || state.difficultiesGesters
            }
        case "LOAD_PROD_DATA_PLANNInG":
            return {
                ...state,
                prodDataPlanning: action.prodDataPlanning,
            }
        case "LOAD_PACKAGING_PLANNING_DATA":
            return {
                ...state,
                packagingDays: action.packagingDays,
                packagingDataPlanning: action.packagingDataPlanning,
                gestersPots:  action.gestersPots || state.gestersPots
            }
        case "PLANNING_PREVISIONS_UPDATE_CARDS":
            const prevChanged = [...new Set([...state.prevChanged, ...action.prevChanged])]
            
            return {
                ...state,
                prevsDataPlanning: action.prevsDataPlanning,
                prevChanged
            }
        case "PLANNING_OPEN_SNACKBAR":
        case "PLANNING_CLOSE_SNACKBAR":
            return {
                ...state,
                planningSnackBar: action.planningSnackBar
            }
        case "CALCUL_DISPATCH_START":
            return {
                ...state,
                calculDispatchLoading: true
            }
        case "CALCUL_DISPATCH_END":
            return {
                ...state,
                calculDispatchLoading: false
            }
        case "PLANNING_RIGHT_PRODUCT_LOADED":
            return {
                ...state,
                rightProduct: formatProduct(action.rightProduct)
            }
        case "PLANNING_RIGHT_PRODUCT_REMOVED":
            return {
                ...state,
                rightProduct: null
            }
        case "PLANNING_INPUT_FOCUSED":
            return {
                ...state,
                anyFocused: action.focused
            }
        case "LOADING_EXPECTED_PRODUCTION":
            return {
                ...state,
                loading: cloneAndAdd(state.loading, action.productionItemIds),
                saved: cloneAndRemove(state.saved, action.productionItemIds)
            }
        case "SAVING_EXPECTED_PRODUCTION_DONE":
            const productionItemIds = action.productionItemIdAndValues.map(el => el.id)
            return {
                ...state,
                loading: cloneAndRemove(state.loading, productionItemIds),
                saved: cloneAndAdd(state.saved, productionItemIds),
                prodDataPlanning: updateProdDataPlanning(state.prodDataPlanning, action)
            }
        case "SAVING_PACKAGING_PRODUCTION_DONE":
            return {
                ...state,
                packagingDataPlanning: updatePackagingDataPlanning(state.packagingDataPlanning, action)
            }
        case "REMOVE_LOADING_EXPECTED_PRODUCTION":
            return {
                ...state,
                loading: cloneAndRemove(state.loading, action.productionItemIds)
            }
        case "REMOVE_SAVED_EXPECTED_PRODUCTION":
            return {
                ...state,
                saved: cloneAndRemove(state.saved, [action.productionItemId])
            }
        case "UPDATED_CARD_PRODUCTION_ITEM":
            return {
                ...state,
                changed: action.changed
            }
        case "RESET_PREV_CHANGED":
            return {
                ...state,
                prevChanged: []
            }
        default: {
            return {
                ...state
            }
        }
    }
}
