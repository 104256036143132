import React, { useMemo, useRef, useState } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
  Chip,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"
import { COLORS } from "../../../utils"
import clsx from "clsx"
import Select from "react-select"
import Autocomplete from "@mui/material/Autocomplete"
import { cloneDeep } from "lodash"
import {
  filterSubcontractorProductsBySupplier,
  filterSubcontractorProductsByType,
} from "../../../utils/subcontractorProduct"
import { withStyles } from "@mui/styles"

const initialValues = {
  types: [],
  product: "",
}

const formatAutocompleteOptions = (products) =>
  products.map((product) => ({
    label: product.uniqueCode + " - " + product.commercialName,
    value: product.objectId,
    data: product,
  }))

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 641,
    height: 422,
  },
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      fontSize: 16,
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      height: 20,
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogDescription: {
    color: COLORS.DARK_BLACK,
  },
  plusIconContainer: {
    composes: "flexCenter",
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    borderRadius: 3,
  },
  plusIcon: {
    color: COLORS.WHITE,
  },
  addButton: {
    textTransform: "capitalize",
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 500,
    marginTop: 18,
    padding: 0,
  },
  chips: {
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `1px solid ${COLORS.GREY_SEARCH}`,
    borderRadius: "none",
    overflowY: "scroll",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const LoadingIndicator = () => <CircularProgress color="inherit" size={20} />

const StyledChip = withStyles({
  root: {
    maxWidth: 152,
    height: 32,
    background: COLORS.GREY_IMAGE_LIST,
    borderRadius: 4,
    padding: "0px 6px",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.15,
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})(Chip)

export const AddToDispatchManualModal = (props) => {
  const { onClose, open, suppliers, subcontractorsProducts, loading, onSelectProducts, productTypeOptions } = props

  const formikRef = useRef(null)
  const [showForm, setShowForm] = useState(false)
  const [selectedSuppliers, setSelectedSuppliers] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedProducts, setSelectedTProducts] = useState([])

  const classes = useStyles()

  const filteredProducts = useMemo(() => {
    let products = cloneDeep(subcontractorsProducts)

    if (products.length === 0) {
      return products
    }

    // filter by suppliers
    if (selectedSuppliers.length > 0) {
      products = filterSubcontractorProductsBySupplier(
        products,
        selectedSuppliers
      )
    }

    // // filter by product types
    if (selectedTypes.length > 0) {
      products = filterSubcontractorProductsByType(products, selectedTypes)
    }

    return products
  }, [subcontractorsProducts, selectedSuppliers, selectedTypes])

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  const handleConfirm = () => {
    if (showForm) {
      formikRef.current.submitForm()
      return
    }
    const products = selectedProducts.map((product) => product.data)
    onSelectProducts(products)
    onClose()
  }

  const handleSubmit = (values) => {
    if (!showForm) return
    setShowForm(false)
    setSelectedTProducts([...selectedProducts, values.product])
  }

  const handleCancel = () => {
    if (showForm) {
      setShowForm(false)
      return
    }
    onClose()
  }

  const handleSuppliersSelect = (options) => {
    const supplierIds = options.map((o) => o.value)
    setSelectedSuppliers(supplierIds)
  }

  const handleTypesSelect = (options) => {
    const types = options.map((o) => o.value)
    setSelectedTypes(types)
  }

  const supplierOptions = suppliers
    .sort((a, b) => a.name?.localeCompare(b.name))
    .map((supplier) => ({
      value: supplier.objectId,
      label: supplier.name,
    }))

  const handleDeleteSelectedProduct = (data) => () => {
    const newSelectedProducts = (products) =>
      products.filter((product) => product.value !== data.value)
    setSelectedTProducts(newSelectedProducts)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Ajout manuel au dispatch
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogDescription}>
          {showForm
            ? "Indiquez le produit sous-traitant que vous souhaitez ajouter au dispatch."
            : "Ajoutez des produits sous-traitants au dispatch."}
        </DialogContentText>
        {showForm ? (
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    className={clsx(
                      classes.formControl,
                      classes.multiSelectFormControl
                    )}
                    variant="standard"
                  >
                    <InputLabel>Fournisseurs</InputLabel>
                    <Select
                      name="suppliers"
                      options={supplierOptions}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={selectStyles}
                      value={values.suppliers}
                      isLoading={loading}
                      onChange={(option) => {
                        setFieldValue("suppliers", option)
                        handleSuppliersSelect(option)
                      }}
                      components={{ LoadingIndicator }}
                    />
                  </FormControl>
                  <FormControl
                    className={clsx(
                      classes.formControl,
                      classes.selectFormControl
                    )}
                    variant="standard"
                  >
                    <InputLabel>Type de produit</InputLabel>
                    <Select
                      name="types"
                      options={productTypeOptions}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={selectStyles}
                      value={values.types}
                      menuPortalTarget={document.body}
                      onChange={(option) => {
                        setFieldValue("types", option)
                        handleTypesSelect(option)
                      }}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.selectFormControl}
                    variant="standard"
                  >
                    <Autocomplete
                      id="product"
                      name="product"
                      options={formatAutocompleteOptions(filteredProducts)}
                      getOptionLabel={(option) => option.label}
                      onChange={(_, value) => setFieldValue("product", value)}
                      isOptionEqualToValue={(option, value) =>
                        option.objectId === value.objectId
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Code - Nom commercial"
                          value={values.product ? values.product.value : ""}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Form>
              )
            }}
          </Formik>
        ) : (
          // selected products list
          <div>
            {selectedProducts.length > 0 && (
              <div className={classes.chips}>
                {selectedProducts.map((data, index) => (
                  <Tooltip title={data.label} key={index} disableInteractive>
                    <StyledChip
                      key={index}
                      label={data.label}
                      onDelete={handleDeleteSelectedProduct(data)}
                      className={classes.chip}
                    />
                  </Tooltip>
                ))}
              </div>
            )}

            <div>
              <Button
                onClick={toggleForm}
                className={classes.addButton}
                startIcon={
                  <div className={classes.plusIconContainer}>
                    <AddIcon className={classes.plusIcon} />
                  </div>
                }
                color="primary"
              >
                Ajouter un produit
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddToDispatchManualModal
