import React from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import { getSubContractorProductCostDataColumn } from "../../utils/subcontractorProduct"
import { KFC_BRANDS, COLORS } from "../../utils"

import { grey, green } from "@mui/material/colors"

const headCells = [
    { id: "labels", numeric: false, disablePadding: false, label: "Coûts" },
]

const EnhancedTableHead = ({classes, columns}) => {
    return (
        <TableHead>
            <TableRow>
                {
                    columns.map((column, index) =>
                        <TableCell
                            classes={{root: classes.headCell}}
                            key={index}
                            align={column.numeric ? "right" : "left"}
                            padding={column.disablePadding ? "none" : "default"}
                        >
                            {
                                <div className={column.numeric ? classes.cellHeadContent : classes.labelCellHeadContent}>
                                    {
                                        column.image
                                            ?
                                            <img
                                                className={classes.cellHeadImage}
                                                src={column.image}
                                                alt="logo"
                                            />
                                            : null
                                    }
                                    <div style={{color: column.color ? column.color : grey[700]}}>
                                        {column.label}
                                    </div>
                                </div>
                            }
                        </TableCell>
                    )
                }
            </TableRow>
        </TableHead>
    )
}

export const Cost = (props) => {
    const {
        classes,
        jsonSubcontractorProduct,
        subcontractorIngredientCost,
        packagingCost
    } = props

    const brand = KFC_BRANDS.find(elem => elem.name === jsonSubcontractorProduct.brand)
    const brandColumn = {
                id: brand.name,
                numeric: true,
                disablePadding: false,
                label: brand.label,
                image:  brand.image,
                color: brand.color
            }
    const columns = headCells.concat(brandColumn)

    const data = getSubContractorProductCostDataColumn(jsonSubcontractorProduct, subcontractorIngredientCost, packagingCost)
    return (
        <Paper className={classes.container}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        columns={columns}
                    />
                    <TableBody>
                        {
                            data.map((elem, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        classes={{
                                            root: index === 1 || index === 2
                                                ? classes.rowWithBorder
                                                : classes.rowWithoutBorder
                                        }}
                                    >
                                        <TableCell
                                            align="left"
                                            classes={{
                                                root: index === 1 || index === 2
                                                    ? classes.cellSmallPadding
                                                    : classes.cellLargePadding
                                            }}
                                        >
                                            {elem.label}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            classes={{
                                                root: index === 1 || index === 2
                                                    ? classes.cellSmallPadding
                                                    : classes.cellLargePadding
                                            }}
                                        >
                                            {
                                                typeof elem.value === "object"
                                                    ?
                                                    <>
                                                        {
                                                            elem.value.hasOwnProperty("pct")
                                                                ?
                                                                <span
                                                                    className={elem.value.hasOwnProperty("redPCT")
                                                                        ? parseFloat(elem.value.pct) > elem.value.redPCT
                                                                            ? classes.redPctLabel
                                                                            : classes.greenPctLabel
                                                                        : classes.pctLabel
                                                                    }
                                                                >
                                                                    {elem.value.pct}
                                                                </span>
                                                                : null
                                                        }
                                                        <span className={classes.priceLabel}>
                                                            {elem.value.value}€
                                                        </span>
                                                    </>
                                                    :
                                                    <>
                                                        <span className={classes.priceLabel}>{elem.value}</span>
                                                    </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: "16px 32px",
        marginTop: 16,
        paddingBottom: 16,
        borderRadius: 5,
    },
    headCell: {
        padding: "16px 0px",
        fontSize: 18,
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.87)"
    },
    cellHeadContent: {
        padding: "16px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    cellHeadImage: {
        marginRight: 8,
        width: 20,
        height: 20
    },
    labelCellHeadContent: {
    },
    cell: {
      borderBottom: "none"
    },
    cellWithoutBorder: {
        composes: "$cell",
        border: "none",
    },
    cellLargePadding: {
        composes: "$cell",
        padding: "10px 0px"
    },
    cellSmallPadding: {
        composes: "$cell",
        padding: "5px 0px"
    },
    rowWithBorder: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    },
    rowWithoutBorder: {
    },
    priceLabel: {
        fontSize: 14,
        color: grey[600],

    },
    pctLabel: {
        fontWeight: "bold",
        fontSize: 16,
        marginRight: 8
    },
    greenPctLabel: {
        composes: "$pctLabel",
        color: green[700]
    },
    redPctLabel: {
        composes: "$pctLabel",
        color: COLORS.RED_ERROR
    }
}

export default withStyles(styles)(Cost)
