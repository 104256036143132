import React from "react"
import withStyles from "@mui/styles/withStyles"
import { showChefsList } from "../../../../../actions/Products/Products"
import { showChefEdit } from "../../../../../actions/Chefs/Chefs"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { connect } from "react-redux"
import { getChef } from "../../../../../reducers/Chefs/Chefs"
import CloudinaryImage from "../../../../../components/Image/CloudinaryImage"

export const ChefsRead = (props) => {
    const { chef, showChefsList, showChefEdit, classes } = props

    return (
        <div className={classes.container}>
            <div className={classes.closeWindow}>
                <IconButton aria-label="Fermer" onClick={() => showChefsList()} size="large">
                    <CloseIcon/>
                </IconButton>
            </div>
            {
                chef && chef.image && chef.image.publicId
                    ?
                    <div className={classes.imageContainer}>
                        <div className={classes.labelContainer}>
                            Photo
                        </div>
                        {
                            chef.image
                                ? <CloudinaryImage
                                    imageId={chef.image.publicId}
                                    containerClassName={classes.image}
                                    width={200}
                                    height={150}
                                    customQuality="auto"
                                />
                                : <p>Aucune photo</p>
                        }
                    </div>
                    : null
            }

            <div className={classes.subContainer}>
                <div className={classes.infoContainer}>
                    <div className={classes.labelContainer}>
                        Prénom
                    </div>
                    <div className={classes.valueContainer}>
                        {chef ? chef.firstName : ""}
                    </div>
                </div>
                <div className={classes.infoContainer}>
                    <div className={classes.labelContainer}>
                        Nom
                    </div>
                    <div className={classes.valueContainer}>
                        {chef ? chef.lastName : ""}
                    </div>
                </div>
            </div>
            <div className={classes.editButton}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showChefEdit(chef.objectId, "edit")}
                >
                    Editer
                </Button>
            </div>
        </div>
    )
}

const styles = {
    container: {
        padding: 20
    },
    closeWindow: {
        textAlign: "right"
    },
    infoContainer: {
        marginLeft: 15,
        marginBottom: 15,
        marginTop: 15,
        width: 200
    },
    editButton: {
        textAlign: "right",
        marginRight: 15
    },
    subContainer: {
        display: "flex"
    },
    labelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    imageContainer: {
        width: 160,
        height: 160,
        display: "flex"
    },
    image: {
        display: "block",
        height: "100%",
        width: "auto"
    },
}


const StyledChefRead = withStyles(styles)(ChefsRead)

export default connect((state, props) => {
    const { params: {id}} = props
    const chef = id ? getChef(state, id) : null

    return {
        chef: chef
    }
}, {
    showChefEdit,
    showChefsList
})(StyledChefRead)
