import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import News from "./News"
import QuickActions from "./QuickActions"
import NutritionalValues from "./NutritionalValues"
import NutritionalSourcing from "./NutritionalSourcing"
import NutritionalScore from "./NutritionalScore"
import ProductResume from "./ProductResume"
import RecipeResume from "./RecipeResume"
import Cost from "./Cost"

const useStyles = makeStyles({
    container: {
        display: "flex",
        background: "#E5E5E5",
        justifyContent: "center"
    },
    firstColumnContainer: {
        width: "75%",
        margin: 16,
        minWidth: 500,
        maxWidth: 1000,
    },
    secondColumnContainer: {
        width: "25%",
        margin: 16,
        minWidth: 275,
        maxWidth: 300
    }
})

const General = props => {
    const {
        jsonRecipe,
        parseRecipe,
        asyncPrintLabel,
        onEditNews,
        synchronizeARecipeToAndFromEtiquettable,
        productsTags,
        downloadProd,
        onSaveProductResume,
        chefs,
        canEdit = true,
        checkProductInCard,
        showModalProductInCard
    } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <ProductResume
                    jsonRecipe={jsonRecipe}
                    parseRecipe={parseRecipe}
                    productsTags={productsTags}
                    onSaveProductResume={onSaveProductResume}
                    chefs={chefs}
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <RecipeResume jsonRecipe={jsonRecipe} />
                <Cost jsonRecipe={jsonRecipe} />
            </div>
            <div className={classes.secondColumnContainer}>
                <News
                    news={jsonRecipe ? jsonRecipe.news : ""}
                    onEdit={onEditNews}
                    canEdit={canEdit}
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <QuickActions
                    onDownloadProd={downloadProd}
                    onClickSynchronizeEtiquettable={synchronizeARecipeToAndFromEtiquettable}
                    asyncPrintLabel={asyncPrintLabel}
                    jsonRecipe={jsonRecipe}
                />
                <NutritionalScore
                    nutritionInformation={
                        jsonRecipe && jsonRecipe.nutritionInformation
                            ? jsonRecipe.nutritionInformation
                            : {}
                    }
                />
                <NutritionalSourcing
                    nutritionInformation={
                        jsonRecipe && jsonRecipe.nutritionInformation
                            ? jsonRecipe.nutritionInformation
                            : {}
                    }
                />
                <NutritionalValues
                    nutritionInformation={
                        jsonRecipe && jsonRecipe.nutritionInformation
                        ? jsonRecipe.nutritionInformation
                        : {}
                    }
                />
            </div>
        </div>
    )
}

export default General
