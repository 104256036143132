const defaultState = {
    chefs: []
}

export default function (state = defaultState, action) {
    switch(action && action.type) {
        case "RESOURCES_CHEFS_LOADED":
            return {
                ...state,
                chefs: action.chefs
            }
        case "RESOURCES_CHEFS_SAVED":
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}

export function getChef(state, id) {
    return state.chefs.chefs.find(chef => chef.objectId === id)
}
