import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import { Stack } from "@mui/material"
import IntervalCalendarOptions from "./IntervalCalendarOptions"
import IntervalCalendar from "./IntervalCalendar"

const formatDateToTimeStamp = (date) => {
  return dayjs(date).valueOf()
}

const createDateFromOption = (option, startDate) => {
  const { interval, intervalUnit, intervalDirection } = option
  return intervalDirection === "plus" ? dayjs(startDate).add(interval, intervalUnit) : dayjs().subtract(interval, intervalUnit)
}

const CustomIntervalCalendar = ({
  date,
  startDate,
  options = [],
  onClose,
  onChange,
  afterChangeOption,
  onFormatDate = formatDateToTimeStamp
}) => {

  const [formattedOptions, setFormattedOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedDate, setSelectedDate] = useState(dayjs(date))

  useEffect(() => {
    const formattedOptions = options.map(option => ({
      date: createDateFromOption(option, startDate),
      ...option
    }))
    setFormattedOptions(formattedOptions)
  }, [options, startDate])

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = onFormatDate(selectedDate)
      onChange && onChange(formattedDate)
    }
  }, [selectedDate])

  // preselect option after reopening popover
  useEffect(() => {
    if (!selectedOption && date && formattedOptions.length > 0) {
      const option = formattedOptions.find(option => dayjs(option.date).isSame(date, "day"))
      setSelectedOption(option)
    }
  }, [selectedOption, date, formattedOptions])


  // from options panel
  const handleOptionSelect = (newOption) => {
    setSelectedOption(newOption)
    setSelectedDate(newOption.date)
    afterChangeOption && afterChangeOption(newOption)
    onClose && onClose()
  }

  // from calendar panel
  const handleDateSelect = (newDate) => {
    const dateToDayjs = dayjs(newDate) 
    setSelectedDate(dateToDayjs)
    onClose && onClose()
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      margin={3}
    >
      <IntervalCalendarOptions
        options={formattedOptions}
        selectedOption={selectedOption}
        selectedDate={selectedDate}
        onSelect={handleOptionSelect}
      />
      <IntervalCalendar
        selectedDate={new Date(selectedDate)}
        onSelectDate={handleDateSelect}
      />
    </Stack>
  )
}

export default CustomIntervalCalendar