import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import StockageForm from "./StockageForm"
import Dialog from "@mui/material/Dialog"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"

const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		borderRadius: 5,
		paddingBottom: 40,
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
	},
	titleContainer: {
		display: "flex",
		flex: "1 1",
		justifyContent: "space-between",
		alignItems: "center"
	},
	title: {
		padding: "20px 32px 16px 32px",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: 16
	},
	singleValueContainer: {
		display: "flex",
		paddingLeft: 32,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 16,
	},
	valueLabel: {
		color: COLORS.BLUEGREY_700,
		width: 200,
		minWidth: 200,
		display: "flex",
		alignItems: "center"
	}
}))

const SupplierItemStockage = (props) => {
	const {
		supplierItem,
		onUpdateSupplierItem,
		sites,
		canEdit
	} = props
	
	const classes = useStyles()
	
	const [openEditModal, setOpenEditModal] = useState(false)
	
	const handleCloseModal = () => {
		setOpenEditModal(false)
	}
	
	return (
		<Paper className={classes.container}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>
					Stockage
				</div>
				{
					canEdit &&
						<NewEditIcon
							onClick={setOpenEditModal}
						/>
				}
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>
					Site par défaut
				</div>
				<span>
                    { supplierItem && supplierItem.site && supplierItem.site.name }
                </span>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>
					Zone de stockage par défaut
				</div>
				<span>
                    { supplierItem && supplierItem.stockZone && supplierItem.stockZone.name }
                </span>
			</div>
			{
				openEditModal
					? <Dialog
						open={openEditModal}
						onClose={handleCloseModal}
						aria-labelledby="form-dialog-title"
					>
						<StockageForm
							supplierItem={supplierItem}
							onClose={handleCloseModal}
							onUpdateSupplierItem={onUpdateSupplierItem}
							sites={sites}
						/>
					</Dialog>
					: null
			}
		</Paper>
	)
}

export default SupplierItemStockage