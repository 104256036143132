import React , { useState } from "react"
import { Stack } from "@mui/material"

import { StyledCard, StyledCardLabel, StyledCardTitle, StyledCardTitleContainer } from "../../../../components/styled/StyledPreview"
import { getPackagingCategoryLabel, getPackagingLabelByType, getPackagingTypeLabel } from "../../../../utils/packaging"
import { getBrandsLabels } from "../../../../utils"
import PackagingResumeFormDialog from "./forms/PackagingResumeFormDialog"
import NewEditIcon from "../../../../components/NewEditIcon"

const PackagingResume = ({ packaging, onSubmit }) => {
  const [openModalForm, setOpenModalForm] = useState(false)

  const toggleEditTransformationMode = () => setOpenModalForm(!openModalForm)

  const handleSubmitResume = async (values) => {
    await onSubmit(values)
    toggleEditTransformationMode()
  }

  return (
    <StyledCard>
      <Stack spacing={3}>
        <StyledCardTitleContainer>
          <StyledCardTitle>Résumé</StyledCardTitle>
          <NewEditIcon onClick={toggleEditTransformationMode} />
        </StyledCardTitleContainer>
        <Stack spacing={2}>
          <div className="flexRow">
            <StyledCardLabel>Nom</StyledCardLabel>
            <span>{packaging.name}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Référence</StyledCardLabel>
            <span>{packaging.reference}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Type</StyledCardLabel>
            <span>{getPackagingTypeLabel(packaging.type)}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Nature</StyledCardLabel>
            <span>{getPackagingLabelByType(packaging.nature)}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Catégorie</StyledCardLabel>
            <span>{getPackagingCategoryLabel(packaging.categories)}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Marques</StyledCardLabel>
            <span>{getBrandsLabels(packaging.brands)}</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Prix</StyledCardLabel>
            <span>{packaging.price} €</span>
          </div>
        </Stack>
      </Stack>

      <PackagingResumeFormDialog
        open={openModalForm}
        onClose={toggleEditTransformationMode}
        packaging={packaging}
        onSave={handleSubmitResume}
      />
    </StyledCard>
  )
}

export default PackagingResume
