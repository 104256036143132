import React from "react"
import { Stack, styled, Typography } from "@mui/material"
import dayjs from "dayjs"

const StyledLabel = styled("span")({
	color: "#555",
	fontSize: 14,
	fontWeight: 400,
	lineHeight: "22px"
})

const StyledValue = styled("span")({
	color: "#262626",
	fontSize: 16,
	fontWeight: 400,
	lineHeight: "24px"
})

const StyledTitle = styled(Typography)({
	color: "#262626",
	fontSize: 20,
	fontWeight: 500,
	lineHeight: "140%"
})


const LotBanner = ({ lot }) => {

	return (
		<Stack
			justifyContent="center"
			alignItems="start"
			gap={5}
			padding={3}
			bgcolor={"#F8F8F8"}
		>
			<StyledTitle>
				{lot.supplierItem?.name}
			</StyledTitle>
			<Stack direction="row" gap={3}>
				<Stack gap={1}>
					<StyledLabel>N° de lot</StyledLabel>
					<StyledValue>{lot.lotNumber}</StyledValue>
				</Stack>
				<Stack gap={1}>
					<StyledLabel>DLC</StyledLabel>
					<StyledValue>{dayjs(lot.dlc).format("DD/MM/YYYY")}</StyledValue>
				</Stack>
				<Stack gap={1}>
					<StyledLabel>Fournisseur</StyledLabel>
					<StyledValue>{lot.supplierItem?.supplier?.name || ""}</StyledValue>
				</Stack>
				<Stack gap={1}>
					<StyledLabel>Zone de stockage</StyledLabel>
					<StyledValue>{lot.stockZone.name || ""}</StyledValue>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default LotBanner