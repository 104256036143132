import Parse from "parse"
import { MachineModels } from "./machineModelsManager"
import { KitchenArea } from "../kitchenAreas/kitchenAreasManager"
import { escapeRegExp } from "lodash"

const Machines = Parse.Object.extend("Machines")

export const getMachinesByKitchenArea = async (
  kitchenArea,
  filters,
  includes = ["machineModel", "kitchenArea"],
  toJson = true
) => {
  try {
    const query = await new Parse.Query(Machines)
      .equalTo("kitchenArea", kitchenArea)
      .include(includes)

    if (filters) {
      if (filters.name) {
        const regex = new RegExp(escapeRegExp(filters.name), "ig")
        query.matches("name", regex)
      }
    }
    const machines = (await query.find()) || []

    return toJson ? machines.map((machine) => machine.toJSON()) : machines
  } catch (e) {
    console.error(
      "parseManager.machine.machinesManager.getMachinesByKitchenArea error : ",
      e
    )
    return Promise.reject(e)
  }
}

export const countMachinesByKitchenArea = async (kitchenArea) => {
    try {
      const count = await new Parse.Query(Machines)
        .equalTo("kitchenArea", kitchenArea)
        .count()

    return count
    } catch (e) {
      console.error(
        "parseManager.machine.machinesManager.countMachinesByKitchenArea error : ",
        e
      )

      return Promise.reject(e)
    }
  }

export const getMachinesById = async (
  id,
  includes = ["machineModel", "kitchenArea"],
  toJson = true
) => {
  try {
    const machine = await new Parse.Query(Machines)
      .equalTo("objectId", id)
      .include(includes)
      .first()

    return toJson ? machine.toJSON() : machine
  } catch (e) {
    console.error(
      "parseManager.machine.machinesManager.getMachinesById error : ",
      e
    )
    return Promise.reject(e)
  }
}

export const createMachine = async (values, toJSON = true) => {
  try {
    // ------------ Machines ------------ //
    const machine = new Machines()
    const machineModel = new MachineModels()
    const kitchenArea = new KitchenArea()

    machine.set("name", values.name)

    machineModel.id = values.machineModelId
    machine.set("machineModel", machineModel)

    kitchenArea.id = values.kitchenAreaId
    machine.set("kitchenArea", kitchenArea)

    const newMachine = await machine.save()

    return toJSON ? newMachine.toJSON() : newMachine
  } catch (e) {
    console.error(
      "parseManager.machine.machinesManager.createMachine error : ",
      e
    )
    return Promise.reject(e)
  }
}

export const updateMachine = async (id, values, toJSON = true) => {
  try {
    // ------------ Machines ------------ //
    const machine = await getMachinesById(id, [], false)

    const machineModel = new MachineModels()
    const kitchenArea = new KitchenArea()

    machine.set("name", values.name)

    machineModel.id = values.machineModelId
    machine.set("machineModel", machineModel)

    kitchenArea.id = values.kitchenAreaId
    machine.set("kitchenArea", kitchenArea)

    const newMachine = await machine.save()

    return toJSON ? newMachine.toJSON() : newMachine
  } catch (e) {
    console.error(
      "parseManager.machine.machinesManager.createMachine error : ",
      e
    )
    return Promise.reject(e)
  }
}

export const deleteMachinesByIds = async (ids) => {
  try {
    await new Parse.Query(Machines)
      .containedIn("objectId", ids)
      .each(async (machine) => {
        await machine.destroy()
      })
  } catch (e) {
    console.error(
      "parseManager.machine.machinesManager.deleteMachinesByIds error : ",
      e
    )
    return Promise.reject(e)
  }
}
