import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../utils"

const useStyles = makeStyles({
    info: {
        color: COLORS.DISABLED,
        fontSize: "1.25rem",
        paddingLeft: 5
    }
})

const GenericHeaderInfo = (props) => {
    const {
        info
    } = props

    const classes = useStyles()
    
    return (
        <Typography className={classes.info}>
            {info}
        </Typography>
    )
}

export default GenericHeaderInfo