import React, { useRef, useMemo } from "react"
import { Formik, Form } from "formik"
import { TextField } from "@mui/material"
import * as Yup from "yup"
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Typography, FormLabel, FormControl } from "@mui/material"
import { packagingNature } from "../../../utils/packaging"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root": {
      width: "325px",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  dialogContent: {
    padding: "32px"
  },
  dialogTitle: {
    padding: 0,
    marginBottom: 14
  },
  formControl: {
    marginBottom: 30
  },
  formLabel: {
    fontSize: "12px"
  },
  buttons: {
    justifyContent: "space-around"
  }
}))

const PackagingExecutionPackagingsNumberForm = ({ packaging, onClose, open, handleSubmit }) => {

  const classes = useStyles()

  const formRef = useRef(null)

  const packagingTypeLabel = useMemo(() => {
    return packagingNature.find(nature => nature.value === packaging.type)?.label
  })

  const onSubmitForm = (values) => {
    handleSubmit && handleSubmit(values)
    onClose && onClose()
  }

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <DialogContent
        className={classes.dialogContent}>
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <Typography>
            Saisissez le nombre de produits barquettés <strong>({packagingTypeLabel})</strong>
          </Typography>
        </DialogTitle>
        <Formik
          innerRef={formRef}
          onSubmit={onSubmitForm}
          initialValues={{
            theoreticalNumber: packaging.theoreticalNumber || 0,
            realNumber: packaging.realNumber || undefined

          }}
          validationSchema={Yup.object().shape({
            realNumber: Yup.number().required("Ce champ est requis")
          })}
        >
          {({ values, handleChange, errors }) => {

            return (
              <Form>
                <FormControl className={classes.formControl}>
                  <FormLabel className={classes.formLabel}>Nb théorique de barquettes</FormLabel>
                  <Typography>{values.theoreticalNumber}</Typography>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <FormLabel className={classes.formLabel}>Nb réel de barquettes</FormLabel>
                  <TextField
                    variant="standard"
                    name="realNumber"
                    value={values.realNumber}
                    onChange={handleChange}
                    type="number"
                    error={errors.realNumber}
                  />
                </FormControl>
              </Form>
            )
          }}
        </Formik>
        <DialogActions className={classes.buttons}>
          <Button
            color="primary"
            onClick={onClose}>
            Annuler
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => formRef?.current?.submitForm()}>Valider</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )

}
export default PackagingExecutionPackagingsNumberForm