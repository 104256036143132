import { cloneDeep } from "lodash"
import { ACTIVE_KFC_BRANDS } from "../utils"

export const PRODUCTION_SCHEMA_BORDER_COLOR = "#E6E6E6"

export const daysOptions = [
  {
    label: "Lundi",
    value: "1"
  },
  {
    label: "Mardi",
    value: "2"
  },
  {
    label: "Mercredi",
    value: "3"
  },
  {
    label: "Jeudi",
    value: "4"
  },
  {
    label: "Vendredi",
    value: "5"
  },
  {
    label: "Samedi",
    value: "6"
  },
  {
    label: "Dimanche",
    value: "7"
  }
]

const daysWithoutSunday = cloneDeep(daysOptions).slice(0, daysOptions.length - 1)

export const sellDaysOptions = [
  {
    label: "Vendredi S-1",
    value: "5-1"
  },
  {
    label: "Samedi S-1",
    value: "6-1"
  },
  {
    label: "Dimanche S-1",
    value: "7-1"
  },
  ...daysOptions
]

export const weekDaysOptions = [
  {
    label: "Mercredi S-1",
    value: "3-1"
  },
  {
    label: "Jeudi S-1",
    value: "4-1"
  },
  {
    label: "Vendredi S-1",
    value: "5-1"
  },
  {
    label: "Samedi S-1",
    value: "6-1"
  },
  {
    label: "Dimanche S-1",
    value: "7-1"
  },
  ...daysWithoutSunday
]


export const productionSchemaBatchInitialValues = {
  productionDay: "",
  packagingDay: "",
  sellDays: ACTIVE_KFC_BRANDS.map((activeBrand) => ({
    brand: activeBrand.name,
    days: []
  }))
}

export const renderWeekDaysLabels = (dayNumbers) => {
  const days = weekDaysOptions.filter((option) =>
    dayNumbers.includes(option.value)
  )
  const label = days.map((day) => day.label).join(", ")
  return label
}

export const renderSellDaysLabels = (daysNumber) => {
  const days = sellDaysOptions.filter((option) =>
    daysNumber.includes(option.value)
  )
  const label = days.map((day) => day.label).join(", ")
  return label
}

export const productionSchemaInitialValues = {
  name: "Nom du schéma de production",
  batches: [productionSchemaBatchInitialValues],
  isNew: true // if it's a creation
}

export const productionSchemasEmptyInitialValues = {
  productionSchemas: [productionSchemaInitialValues]
}

export const reformatExistingProductionSchemas = (productionSchemas) => {
  const formattedProductionSchemas = cloneDeep(productionSchemas)
  formattedProductionSchemas.forEach(productionSchema => {
    productionSchema.batches.forEach(batch => {
      const currentSellDays = batch.sellDays
      const newSellDays = ACTIVE_KFC_BRANDS.map((activeBrand) => ({
        brand: activeBrand.name,
        days: currentSellDays.find((sellDay) => sellDay.brand === activeBrand.name)?.days || []
      }))
      batch.sellDays = newSellDays
    })
  })
  return formattedProductionSchemas
}
/**
 * empty initial values if for the first time
 * or initial values from db if there is ones
 * @param {*} productionSchemas 
 * @returns 
 */
export const getProductionSchemasInitialValues = (productionSchemas) => {
  if (productionSchemas?.length) {
    const formattedProductionSchemas = reformatExistingProductionSchemas(productionSchemas)
    return {
      productionSchemas: formattedProductionSchemas
    }
  }

  return productionSchemasEmptyInitialValues
}
