import React from "react"
import dayjs from "dayjs"
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"
import "./customReactDayPicker.css"
import { fr } from "date-fns/locale"

const CustomReactDayPicker = ({ selectedDate, onSelectDate, className, showSelectionInBetween }) => {

  const handleChange = (newValue) => {
    if (!newValue) return
    // by defaut day picker use local timezone
    // Create a new Date object with the same date parts but set to 00:00:00 in GMT
    const utcDate = new Date(Date.UTC(newValue.getFullYear(), newValue.getMonth(), newValue.getDate()))
    onSelectDate && onSelectDate(utcDate)
  }

  const today = new Date()

  const isSameDay = selectedDate && today.getDate() === selectedDate.getDate() && today.getMonth() === selectedDate.getMonth() && today.getFullYear() === selectedDate.getFullYear()

  const selectedYear = selectedDate ? selectedDate.getFullYear() : today.getFullYear()
  const selectedMonth = selectedDate ? selectedDate.getMonth() : today.getMonth()

  const modifiers = {
    inBetween: isSameDay ? false : { after: today, before: selectedDate },
    isSelected: (day) => selectedDate && dayjs(day).isSame(selectedDate, "day")
  }

  const yearMin = dayjs().subtract(5, "year").year()
  const yearMax = dayjs().add(5, "year").year()

  return (
    <DayPicker
      locale={fr}
      className={className}
      mode="single"
      selected={ new Date(selectedDate)}
      onSelect={handleChange}
      captionLayout="dropdown-buttons"
      hideWeekdayRow={true}
      fromYear={yearMin}
      toYear={yearMax}
      defaultMonth={new Date(selectedYear, selectedMonth)}
      disabled={{ before: new Date() }}
      formatters={{
        formatWeekdayName: (day) => dayjs(day).format("dd").substring(0, 1).toUpperCase()
      }}
      modifiers={modifiers}
      modifiersStyles={{
        inBetween: {
          backgroundColor: showSelectionInBetween ? "#F0F0F0" : "unset"
        },
        isSelected: {
          backgroundColor: "#1C76D2"
        }
      }}
    />
  )
}

export default CustomReactDayPicker