import React from "react"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { COLORS } from "../../utils"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import LotGroupHeaderTitle from "./LotGroupHeaderTitle"
import { getSitesSelector } from "../../reducers/Site/sites"
import LotGroupFilterForm from "./LotGroupFilterForm"
import { filterLotGroup } from "../../actions/LotGroup/lotGroup"

const LotGroupFilter = () => {
    const dispatch = useDispatch()
    const sites = useSelector(getSitesSelector)

    const onConfirm = (values) => {
        dispatch(filterLotGroup(values))
    }

    return (
        <div className="flexColumn stretchSelf flex1">
            <GenericTopBarHeader
                title={<LotGroupHeaderTitle />}
                className="stretchSelf"
            />
            
            <Box className="flexCenter stretchSelf flex1" sx={{ color: COLORS.GREY_SUPPLIER_BACKGROUND, flexGrow: 1  }}>
                <LotGroupFilterForm
                    sites={sites}
                    onSubmit={onConfirm}
                />
            </Box>
        </div>
    )
}

export default LotGroupFilter