import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS, roundNumber } from "../../../utils"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import {
    getOrderShippingFees,
    getBillTotalAmount
} from "../../../utils/orderReception"
import BillingCreditNoteControlHeaderTitle from "../../../components/BillingCreditNotes/BillingCreditNoteControlHeaderTitle"
import {
    updateShippingFees,
    updateArticleThunk,
} from "../../../actions/OrderReception/OrderReception"
import RowInformation from "../../../components/BillingCreditNotes/RowInformation"
import SearchInput from "../../../components/BillingCreditNotes/SearchInput"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import BillingCard from "../../../components/BillingCreditNotes/BillingCard"
import EditInformationModal from "../../../components/BillingCreditNotes/EditInformationModal"
import EditArticleModal from "../../../components/BillingCreditNotes/EditArticleModal"

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        overflow: "hidden",
        position: "relative"
    },
    headerBloc: {
        flex: "0 0 87px"
    },
    mainBloc: {
        backgroundColor: COLORS.WHITE,
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        paddingBottom: 50
    },
    infoBloc: {
        flex: "0 0 68px",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        borderBottom: `1px solid ${COLORS.GREY_FLAW_ICON}`,
        marginTop: 3
    },
    editContainer: {
        position: "absolute",
        right: 12,
        top: 12
    },
    searchBloc: {
        flex: "0 0 75px",
        display: "flex",
        justifyContent: "center",
        marginTop: 24
    },
    productsBloc: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margingTop: 8
    },
    fab: {
        position: "absolute",
        left: 63,
        bottom: 40
    },
    icon: {
        color: COLORS.PRIMARY_COLOR
    }
})


const BillingCreditNotesControl = ({ router }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const shippingFees = roundNumber(getOrderShippingFees(orderReception), 2)
    const totalAmount = roundNumber(getBillTotalAmount(orderReception), 2)
    const totalHT = roundNumber(shippingFees + totalAmount, 2)
    const [isExpanded, setIsExpanded] = useState(true)
    const [searchInput, setSearchInput] = useState("")
    const [showEditInformationModal, setShowEditInformationModal] = useState(false)
    const [showEditArticleModal, setShowEditArticleModal] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [articles, setArticles] = useState(null)

    useEffect(() => {
        setArticles(getFilteredArticles())
    }, [searchInput, orderReception])

    const onBack = () => {
        router && router.goBack()
    }

    const getFilteredArticles = () => {
        const regex = new RegExp(searchInput, "ig")
        return orderReception && orderReception.supplierItems && orderReception.supplierItems.filter(article =>
            article.supplierItem.objectId.match(regex) !== null ||
            article.supplierItem.name.match(regex) !== null
        )
    }

    const onSearchChange = (e) => {
        const value = e.target.value
        setSearchInput(value)
    }

    const editDeliveryNoteInformation = () => setShowEditInformationModal(true)

    const validateShippingFees = (values) => {
        dispatch(updateShippingFees(orderReception, values.shippingFees))
    }

    const updateArticle = async (values) => {
        dispatch(updateArticleThunk(orderReception, selectedArticle, values))
    }
    
    return (
        <div className={classes.wrapper}>
            <div className={classes.headerBloc}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                    title={
                        <BillingCreditNoteControlHeaderTitle
                            supplierName={(
                                orderReception &&
                                orderReception.supplier &&
                                orderReception.supplier.name
                            ) || ""}
                            orderNumber={(orderReception && orderReception.orderNumber) || ""}
                        />
                    }
                />
            </div>
            <div className={classes.infoBloc}>
                <RowInformation
                    shippingFees={shippingFees}
                    totalAmount={totalAmount}
                    totalHT={totalHT}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                />
                {
                    isExpanded &&
                    <IconButton
                        className={classes.editContainer}
                        disableRipple={true}
                        onClick={editDeliveryNoteInformation}
                        size="large">
                        <EditIcon className={classes.icon} />
                    </IconButton>
                }
            </div>
            <div className={classes.searchBloc}>
                <SearchInput
                    onSearchChange={onSearchChange}
                    searchInput={searchInput}
                />
            </div>
            <div className={classes.mainBloc}>
                <div className={classes.productsBloc}>
                    {
                        articles && articles.map((article, index) => (
                            <BillingCard
                                key={index}
                                article={article}
                                editSelectedArticle={() => {
                                    setSelectedArticle(article)
                                    setShowEditArticleModal(true)
                                }}
                            />
                        ))
                    }
                </div>
            </div>
            {
                showEditInformationModal &&
                <EditInformationModal
                    open={showEditInformationModal}
                    validate={validateShippingFees}
                    onBack={() => setShowEditInformationModal(false)}
                    orderReception={orderReception}
                />
            }
            {
                showEditArticleModal &&
                <EditArticleModal
                    open={showEditArticleModal}
                    onChange={updateArticle}
                    onBack={() => {
                        setShowEditArticleModal(false)
                        setSelectedArticle(null)
                    }}
                    article={selectedArticle}
                />
            }
            <div className={classes.fab}>
                <Fab color="primary">
                    <Add />
                </Fab>
            </div>
        </div>
    )
}

export default BillingCreditNotesControl