import React, { useState }  from "react"
import Paper from "@mui/material/Paper"
import LibraryAdd from "@mui/icons-material/LibraryAdd"
import IconButton from "@mui/material/IconButton"
import { makeStyles } from "@mui/styles"
import Parse from "parse"
import { Visibility } from "@mui/icons-material"

import { generateCloudinaryDownloadUrl, openUploadWidget } from "../../utils/cloudinary"
import { COLORS } from "../../utils"
import { downloadFile } from "../../actions/Utils/utils"
import FileListDialog from "./FileListDialog"

const Documentation = ({ supplierItem }) => {
    const [documentListOpen, setDocumentListOpen] = useState(false)
    const classes = useStyles()

    const handleUploadImage = () => {
        openUploadWidget({ tags: ["app"] }, async (error, result) => {
            if (error) {
                console.error(error)
                return
            }
            if (result.event !== "success") {
                // we don't use this event
                return
            } else {
                const publicId = result.info.public_id
                const originalName = result.info.original_filename + "." + result.info.format

                const imageToAdd = {label: originalName, publicId}
                const supplierItemObject = await new Parse.Query("SupplierItems")
                  .equalTo("objectId", supplierItem.objectId)
                  .first()

                const currentImagesStored = supplierItemObject.get("images")
                supplierItemObject.set("images", [...(currentImagesStored ? currentImagesStored : []), imageToAdd])
                supplierItem.images = supplierItem.images ? [...supplierItem.images, imageToAdd] : [imageToAdd]

                await supplierItemObject.save()
            }
        })
    }

    const handleDownloadDocument = (publicId, label) => {
        const fileUrl = generateCloudinaryDownloadUrl(publicId)
        fetch(fileUrl, {
            method: "GET",
        })
          .then((response) => response.blob())
          .then((blob) => {
              // Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob]))
              downloadFile(url, label)
          })
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.title}>
                Documents
            </div>
            <IconButton
                className={classes.actionContainer}
                classes={{
                    root: classes.rootButton
                }}
                onClick={() => handleUploadImage()}
                size="large">
                <LibraryAdd
                    classes={{
                    root: classes.rootIcon
                    }}
                />
                <span className={classes.spanButton}>
                    Ajouter un nouveau document
                </span>
            </IconButton>
            <IconButton
              className={classes.actionContainer}
              classes={{
                  root: classes.rootButton
              }}
              onClick={() => setDocumentListOpen(true)}
              size="large">
                <Visibility
                  classes={{
                      root: classes.rootIcon
                  }}
                />
                <span className={classes.spanButton}>
                    Consulter un document
                </span>
            </IconButton>
            {/* file download dialog */}
            <FileListDialog
                open={documentListOpen}
                onClose={() => setDocumentListOpen(false)}
                images={supplierItem.images}
                supplierItemId={supplierItem.objectId}
                onDownload={(image) => handleDownloadDocument(image.publicId, image.label)}
            />
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        display: "block",
        padding: 16,
        marginTop: 16,
        borderRadius: 4
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500
    },
    actionContainer: {
        display: "flex",
        width: "100%",
        color: COLORS.PRIMARY_COLOR,
        fontSize: 16,
        justifyContent: "flex-start"
    },
    spanButton: {
        marginLeft: 4,
    },
    rootButton: {
        borderRadius: 0,
        textAlign: "start",
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12,
    },
    rootIcon: {
        marginRight: 12
    },
    labelModal: {
        tabIndex: 1,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        outline: "none",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 4
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center"
    },
    modalTitle: {
        textAlign: "center",
        margin: "auto",
        marginTop: 10
    },
    modalSubTitle: {
        textAlign: "center"
    },
    closeIcon: {
        float: "right"
    },
}))

export default Documentation
