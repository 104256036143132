import { Paper, Typography, styled } from "@mui/material"

import { COLORS } from "../../utils"

// --------- styled --------- //
export const StyledCard = styled(Paper)({
  backgroundColor: COLORS.WHITE,
  borderRadius: 5,
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  padding: 32,
  paddingBottom: 40,
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
})

export const StyledCardTitleContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

export const StyledCardTitle = styled(Typography)({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 20,
})

export const StyledCardLabel = styled("span")({
  color: COLORS.BLUEGREY_700,
  width: 200,
  minWidth: 200,
})
