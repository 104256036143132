import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS,
    ACTIVE_KFC_BRANDS
} from "../../utils"
import { getPlanningTabsColor } from "../../utils/planning"

const styles = {
    brandTab: {
        margin: "auto",
    },
    brandContainer: {
        position: "fixed",
        width: "100%",
        margin: "auto",
        zIndex: 1000,
        backgroundColor: COLORS.WHITE
    },
    brandSubContainer: {
        composes: "$brandContainer",
    },
    brandUnderSubContainer: {
        composes: "$brandContainer",
        top: 160
    }
}

for (const i in ACTIVE_KFC_BRANDS) {
    const currentBrand = ACTIVE_KFC_BRANDS[i]
    styles[`${currentBrand.name}Color`] = `color: ${currentBrand.color}`
}

export const PlanningBrandTabs = (props) => {
    const {
        tabValue = ACTIVE_KFC_BRANDS[0].name,
        onTabChange,
        classes,
        isSub = true
    } = props

    return (
        <div className={isSub ? classes.brandSubContainer : classes.brandUnderSubContainer}>
            <Tabs
                value={tabValue ? tabValue : false}
                onChange={onTabChange}
                centered
                TabIndicatorProps={{style: {backgroundColor: getPlanningTabsColor(tabValue)}}}
                className={classes.brandTab}
                indicatorColor="primary"
                textColor="inherit"
            >
                {
                    ACTIVE_KFC_BRANDS.map((brand, i) =>
                        <Tab key={i} value={brand.name} label={brand.label} className={tabValue === brand.name ? classes[`${brand.name}Color`] : ""} />
                    )
                }
            </Tabs>
        </div>
    )
}

export default withStyles(styles)(PlanningBrandTabs)
