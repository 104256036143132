import React from "react"
import { makeStyles } from "@mui/styles"
import Fab from "@mui/material/Fab"
import AddIcon from "@mui/icons-material/Add"
import { motion, useCycle } from "framer-motion"
import { COLORS } from "../../../utils"
 
const useStyles = makeStyles(() => ({
    wrapper: {
        position: "fixed",
        bottom: 120,
        right: 20,
        zIndex: 2000
    },
    container: {
        position: "relative"
    },
    buttonContainer: {
        position: "absolute",
        bottom: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: -1
    },
    button: {
        height: 48,
        width: 48,
        marginBottom: 10,
        backgroundColor: COLORS.WHITE,
        boxShadow: "0px 3px 5px rgba(0,0,0, 0.3)",
        borderRadius: "50%",
    }
}))

const variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: -1 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
}

const buttonVariants = {
    open: {
        y: 0,
        opacity: 1,
        scale: "80%"
    },
    closed: {
        y: 50,
        opacity: 0,
        scale: "80%"
    }
}

const crossVariant = {
    open: {
        rotate: "135deg" 
    },
    closed: {
        rotate: "0deg"
    }
}

const MotionAddIcon = motion(AddIcon)

export const CollapsibleButton = (props) => {
    const { children } = props

    const buttons = React.Children.toArray(children)

    const classes = useStyles()

    const [isOpen, toggleIsOpen] = useCycle(false, true)

    return (
        <div className={classes.wrapper}>
            <motion.div
                initial={false}
                animate={isOpen ? "open" : "closed"}
                className={classes.container}
            >
                <Fab color="primary" onClick={toggleIsOpen}>
                    <MotionAddIcon variants={crossVariant} />
                </Fab>
                {
                    <motion.div variants={variants} className={classes.buttonContainer}>
                        {
                            buttons.map((child, i) => (
                                <motion.div
                                    key={i}
                                    variants={buttonVariants}
                                    className={classes.button}
                                >
                                    {child}
                                </motion.div>
                            ))
                        }
                    </motion.div>
                }
            </motion.div>
        </div>
    )
}