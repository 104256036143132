import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import {connect} from "react-redux"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import moment from "moment"
import _cloneDeep from "lodash/cloneDeep"
import _isEqual from "lodash/isEqual"
import { purple } from "@mui/material/colors"
import { Box } from "@mui/material"
import clsx from "clsx"

import PlanningSearchTabs from "../../components/Planning/PlanningFilters/PlanningSearchTabs"
import CalendarSection from "../../components/Calendar/CalendarSection"
import {
    updateShowInfo,
    updateBrand,
    showMealPlanner,
    loadPlanningSpecificDay,
    loadSellPlanning,
    downloadExtract,
    findProduct,
    removeRightProduct,
    showWeeklyMealPlanner
} from "../../actions/Planning/Planning"
import {
    COLORS, setSearchValue
} from "../../utils"
import PlanningBody from "./PlanningBody/PlanningBody"
import PlanningDays from "../../components/Planning/PlanningDays"
import {
    getPlanningSections,
} from "../../utils/planning"
import PlanningToolbar from "../../components/Planning/PlanningToolbar"
import {CAN_UPDATE_SELL_PLANNING} from "../../utils/accessRights"
import PlanningProductModal from "../../components/Planning/PlanningProductModal"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import { StyledPlanningPageHeader } from "../../components/Planning/StyledPlanningPageHeader"
import CalendarSectionHeader from "../../components/Calendar/CalendarSectionHeader"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import dayjs from "dayjs"
import MoreMenu from "../../components/MoreMenu"
import AddProductToCardModal from "../Products/AddProductToCardModal"
import SnackbarMealPlanner from "../../components/WeeklyMealPlanner/SnackbarMealPlanner"

const styles = {
    sectionContainer: {
        top: 166,
        position: "relative",
        paddingBottom: 70
    },
    changeDay: {
        width: 20,
        justifyContent: "center"
    },
    topTextfield: {
        fontSize: "1rem"
    },
    planningDaysBloc: {
        overflow: "auto",
        width: "100%",
    },
    dragZone: {
        top: 166,
        position: "relative",
        width: 32,
        height: document.getElementsByClassName("sectionContainer").innerHeight,
        backgroundColor: COLORS.WHITE
    },
    bodyContainer: {
        display: "flex",
        position: "relative"
    },
    moreActions: {
        padding: 10
    }
}

export class PlanningSell extends PureComponent {
    constructor(props) {
        super(props)
        const { days, dataPlanning, planningSections } = this.props
        const storage = JSON.parse(localStorage.getItem("searchValue"))

        this.state = {
            typesFilters: planningSections,
            filteredTypes: planningSections, // default initial filter
            showDays: days.filter((day, index) => index < 7),
            showDaysCards: dataPlanning.filter((data, index) => index < 7),
            data: [],
            cardDragging: false,
            inputValue: "",
            currentIndex: 0,
            searchFilter: (storage && storage.planningSell)?storage.planningSell:null,
            openProductInfo: false,
            selectedCard: null,
            showAddProductModal: false,
            rangeDateSelected: [],
            dateSelected: null
        }

    }

    componentDidMount() {
        if (this.state.searchFilter && this.state.searchFilter !== "") {
            this.onSearchValueChange({target: {value: this.state.searchFilter, noStorageUpdate: true}})
        }

        document.title = "KFC - Vente"
    }

    componentDidUpdate(prevProps) {
        const { days, dataPlanning, brand } = this.props
        const { currentIndex } = this.state
        if (prevProps.days.length !== days.length){
            if (prevProps.days[0].timestamp !== days[0].timestamp){
                this.setState({
                    showDays: days.filter((day, index) => index < 7),
                    showDaysCards: this.applyFilter(dataPlanning.filter((data, index) => index < 7), brand),
                    currentIndex: 0
                })
            }
            else {
                this.setState({
                    showDays: days.filter((day, index) => index > days.length - 8),
                    showDaysCards: this.applyFilter(dataPlanning.filter((data, index) => index > dataPlanning.length - 8), brand),
                    currentIndex: days.length - 7
                })
            }
        }
        else if (!_isEqual(prevProps.dataPlanning, dataPlanning) || prevProps.brand !== brand){
            this.setState({
                showDays: days.filter((day, index) => index < 7),
                showDaysCards: this.applyFilter(dataPlanning.filter((data, index) => index < 7), brand),
                currentIndex: currentIndex
            })
        }
    }

    componentWillUnmount() {
        document.title = "KFC"
    }

    onDateChange = (date) => {
        const { loadSellPlanning } = this.props
        loadSellPlanning(dayjs.tz(date).startOf("week").endOf("day").valueOf())
    }

    onBrandChange = (brand) => {
        const { updateBrand } = this.props
        updateBrand(brand)
    }

    applyFilter = (days, brand) => {
        const { inputValue } = this.state

        const copy = _cloneDeep(days)
        const regex = new RegExp(inputValue, "ig")

        for (const i in copy){
            const currentDay = copy[i]
            for (const j in currentDay.cards[brand.name]){
                const currentCards = currentDay.cards[brand.name][j]
                currentDay.cards[brand.name][j] = currentCards.filter(card => card.commercialName.match(regex) !== null)
            }
        }

        return copy
    }

    onTypesChange = (event) => {
        const { filteredTypes } = this.state
        const { planningSections } = this.props

        if (event && event.target && Array.isArray(event.target.value)) {
            const newFilteredTypes = event.target.value
            const oldCurrent = filteredTypes.find(elem => elem.key === "ALL")
            const newCurrent = newFilteredTypes.find(elem => elem.key === "ALL")
            if (oldCurrent && !newCurrent) {
                this.setState({filteredTypes: []})
            } else if (!oldCurrent && newCurrent) {
                this.setState({filteredTypes: planningSections})
            } else if (oldCurrent && newCurrent) {
                this.setState({filteredTypes: event.target.value.filter(elem => elem.key !== "ALL")})
            } else if (!newCurrent && event.target.value.length === planningSections.length - 1) {
                this.setState({filteredTypes: planningSections})
            } else {
                this.setState({filteredTypes: event.target.value})
            }
        }
    }

    onSearchValueChange = (event) => {
        const { dataPlanning, brand } = this.props
        const { currentIndex } = this.state

        const copy = _cloneDeep(dataPlanning.filter((day, index) => index >= currentIndex && index < currentIndex + 7))
        const regex = new RegExp(event.target.value, "ig")
        if (!event.target.noStorageUpdate) setSearchValue(event.target.value, "planningSell")

        for (const i in copy){
            const currentDay = copy[i]
            for (const j in currentDay.cards[brand.name]){
                const currentCards = currentDay.cards[brand.name][j]
                currentDay.cards[brand.name][j] = currentCards.filter(card => card.commercialName.match(regex) !== null || (card.uniqueCode && card.uniqueCode.match(regex) !== null))
            }
        }

        this.setState({
            showDaysCards: copy,
            inputValue: event.target.value
        })
    }

    onClickEdit = (day) => {
        const { showMealPlanner, brand } = this.props
        const { showDays } = this.state
        showMealPlanner(moment(day.timestamp).format("YYYY-MM-DD"), brand.name)
        localStorage.setItem("Planning/Sell/FirstDay", JSON.stringify(showDays[0]))
    }

    isSectionEmpty = (section) => {
        const { showDaysCards } = this.state
        const { brand } = this.props

        for (const i in showDaysCards){
            const currentDay = showDaysCards[i]
            for (const j in currentDay.cards[brand.name]){
                const currentCards = currentDay.cards[brand.name][j]
                if (currentCards.filter(card => card.productType === section.key).length > 0){
                    return false
                }
            }
        }

        return true
    }

    getSections = () => {
        const { filteredTypes, inputValue } = this.state

        let sections = filteredTypes.filter(elem => elem.key !== "ALL")

        if (inputValue !== ""){
            return sections.filter(section => !this.isSectionEmpty(section)).map(elem => {
                return {
                    key: elem.key,
                    label: elem.label
                }
            })
        }
        else {
            const index = sections.find(elem => elem.isChecked === true)
            if (index) {
                sections = sections.filter(elem => elem.isChecked === true)
            }
            return sections.map(elem => {
                return {
                    key: elem.key,
                    label: elem.label
                }
            })
        }
    }

    isCardDragging = (isDragging) => {
        this.setState({
            cardDragging: isDragging
        })
    }

    onClickPrev = () => {
        const { showDays } = this.state
        const {
            days,
            dataPlanning,
            loadPlanningSpecificDay,
            brand
        } = this.props

        const firstShowDay = showDays[0]
        const oldIndex = days.indexOf(firstShowDay)

        if (oldIndex > 0){
            const newIndex = oldIndex - 1
            this.setState({
                showDays: days.filter((day, index) => index >= newIndex && index < newIndex + 7),
                showDaysCards: this.applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7), brand),
                currentIndex: newIndex
            })
        }
        else {
            loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[0].timestamp).subtract(1, "days").startOf("day").valueOf(),
                true,
                "SELL"
            )
        }
    }

    onClickNext = () => {
        const { showDays } = this.state
        const {
            days,
            dataPlanning,
            loadPlanningSpecificDay,
            brand
        } = this.props

        const lastShowDay = showDays[showDays.length - 1]
        const firstShowDay = showDays[0]
        const oldFirstIndex = days.indexOf(firstShowDay)
        const oldLastIndex = days.indexOf(lastShowDay)

        if ((oldLastIndex + 1) < days.length){
            const newIndex = oldFirstIndex + 1
            this.setState({
                showDays: days.filter((day, index) => index >= newIndex && index < newIndex + 7),
                showDaysCards: this.applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7), brand.name),
                currentIndex: newIndex
            })
        }
        else {
            loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[days.length - 1].timestamp).add(1, "days").startOf("day").valueOf(),
                false,
                "SELL"
            )
        }
    }

    getExpectedSaleFromPrev(currentDay, currentSection, brand) {
        if (Array.isArray(currentDay.prev)) {
            const brandPrev = currentDay.prev.find(elem => elem.brand === brand.name) || {targets: []}
            const sectionPrev = brandPrev.targets.find(target => target.key === currentSection.key) || {value: 0}
            return sectionPrev.value
        }
        return 0
    }
    getProductData = (sections) => {
        const { showDaysCards } = this.state
        const { brand } = this.props
        const finalData = []

        for (const i in showDaysCards){
            const currentDay = showDaysCards[i]
            const productTypesData = []
            let totalIntern = 0
            let totalExtern = 0

            for (const j in sections) {
                const currentSection = sections[j]
                const sectionCards = currentDay.cards[brand.name][currentSection.key]

                const intern = sectionCards ? sectionCards.filter(card => card.itemType === "Recipe").length : 0
                const extern = sectionCards ? sectionCards.filter(card => card.itemType === "SubcontractorProduct").length : 0

                totalIntern += intern
                totalExtern += extern

                const tags = []
                brand.sellPageTags.forEach(tagConf => {
                    tags.push({
                        name: tagConf.displayName,
                        count: this.countByTag(sectionCards, tagConf.key),
                        sections: tagConf.sections.length ? tagConf.sections : null
                    })
                })
                const expectedSale = this.getExpectedSaleFromPrev(currentDay, currentSection, brand)

                productTypesData.push({
                    intern: intern,
                    extern: extern,
                    total: intern + extern,
                    expectedSale: expectedSale,
                    label: currentSection.label,
                    key: currentSection.key,
                    tags: tags
                })
            }

            finalData.push({
                date: currentDay.date,
                totalIntern: totalIntern,
                totalExtern: totalExtern,
                productTypesData: productTypesData
            })
        }

        return finalData
    }

    countByTag = (cards, tag) => {
        return cards ? cards.filter(card => undefined !== card.internalTag.find(t => t.name === tag)).length : 0
    }

    onClickExtract = () => {
        const { downloadExtract } = this.props
        const { showDays } = this.state
        const date = Array.isArray(showDays) && showDays.length > 0
            ? showDays[0].timestamp
            : moment.utc().startOf("day").valueOf()
        const lastDate = Array.isArray(showDays) && showDays.length > 0
            ? showDays[showDays.length - 1].timestamp
            : moment.utc().startOf("day").valueOf()
        downloadExtract("saleDate", date, lastDate, "vente")
    }

    updateDatePicker = (date) => {
        //first day of the week
        const dateRangeSelected = moment(date).startOf("week")
        this.setState({
            dateSelected: dateRangeSelected,
            rangeDateSelected: dateRangeSelected
        })
    }

    toggleAddProductModal = () => {
        const { showAddProductModal } = this.state
        this.setState({ showAddProductModal: !showAddProductModal })
    }
    closeProductInfo = () => {
        this.props.removeRightProduct()
        this.setState({openProductInfo: false, selectedCard: null})
    }
    
    openProductInfo = (card) => {
        this.props.findProduct(card)
        this.setState({openProductInfo: true, selectedCard: card})
    }
    
    goToProduct = () => {
        const { selectedCard } = this.state
        const isRecipe = (selectedCard.itemType === "Recipe")
        
        if (isRecipe) window.open(`/products/recipe/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
        if (!isRecipe) window.open(`/products/subcontractor-product/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
    }

    createProductToCard = () => {
        const { rangeDateSelected } = this.state
        const {showWeeklyMealPlanner} = this.props
        const year = moment(rangeDateSelected).year()
        const week = moment(rangeDateSelected).week()
        showWeeklyMealPlanner(year, week)
    }

    render() {
        const {
            typesFilters,
            showDays,
            showDaysCards,
            filteredTypes,
            inputValue,
            openProductInfo,
            selectedCard,
            showAddProductModal,
            dateSelected
        } = this.state

        const {
            classes,
            brand,
            menuIsOpen,
            rightProduct,
            planningSnackBar,
            //canEdit = false
        } = this.props

        const sections = this.getSections()
        const productData = this.getProductData(sections)
        const date = Array.isArray(showDays) && showDays.length > 0
            ? moment(showDays[0].timestamp)
            : moment.utc().startOf("day").valueOf()

        const moreMenus = [
            {
                onClick: this.toggleAddProductModal,
                label: "Ajouter des produits à la carte hebdo"
            },
            {
                onClick: this.onClickExtract,
                label: "Extraire la carte"
            }
        ]

        return (
            <Box display="flex" flexDirection="column" >
                <Box position="relative" width="100%">
                    <StyledPlanningPageHeader sx={{ height: 64 }} open={menuIsOpen}>
                        <GenericTopBarHeader
                            title={<PageHeaderTitle title="Carte" icon={<ShoppingBasketIcon />} color={purple[500]} />}
                        />
                    </StyledPlanningPageHeader>
                </Box>
                <PlanningToolbar menuIsOpen={menuIsOpen}>
                    <PlanningSearchTabs
                        onTypesChange={this.onTypesChange}
                        onSearchValueChange={this.onSearchValueChange}
                        onDateChange={this.onDateChange}
                        date={date}
                        types={typesFilters}
                        menuIsOpen={menuIsOpen}
                        filteredTypes={filteredTypes}
                        isDisplayingBrandButton={true}
                        brand={brand}
                        onBrandChange={this.onBrandChange}
                        searchFilter={this.state.searchFilter}
                    />
                    <MoreMenu
                      menus={moreMenus}
                    />
                </PlanningToolbar>
                <div className={classes.planningDaysBloc}>
                    <PlanningDays
                        days={showDays}
                        planningType="SELL"
                        onEdit={this.onClickEdit}
                        onClickPrev={this.onClickPrev}
                        onClickNext={this.onClickNext}
                        productData={productData}
                        /*canEdit={canEdit}*/
                        canEdit={true}
                    />
                </div>
                <div className={classes.bodyContainer}>
                    <div className={classes.dragZone}>
                    </div>
                    <div className={clsx(classes.sectionContainer,"flexColumn")}>
                        {
                            sections.map((section, index) => {
                                return (
                                    <CalendarSection
                                        key={index}
                                        title={section.label}
                                        section={section}
                                        color={brand.color}
                                        headerInfos={productData.map((product, index) => {
                                            const sectionData = product.productTypesData.find(productType => productType.key === section.key)
                                            return <CalendarSectionHeader sectionData={sectionData} section={section} key={index} />
                                        })}
                                        day={showDays}
                                        searching={"" !== inputValue}
                                    >
                                        <CalendarSection
                                            isSub={true}
                                            color={COLORS.LIGHT_GREY}
                                            title="Interne"
                                            showInfoBtn={false}
                                            searching={"" !== inputValue}
                                        >
                                            <PlanningBody
                                                data={showDaysCards}
                                                isCardDragging={this.isCardDragging}
                                                section={section}
                                                brand={brand}
                                                isIntern="Recipe"
                                                planningType="SELL"
                                                openProductInfo={this.openProductInfo}
                                            />
                                        </CalendarSection>
                                        <CalendarSection
                                            isSub={true}
                                            color={COLORS.LIGHT_GREY}
                                            title="Externe"
                                            showInfoBtn={false}
                                            searching={"" !== inputValue}
                                        >
                                            <PlanningBody
                                                data={showDaysCards}
                                                isCardDragging={this.isCardDragging}
                                                section={section}
                                                brand={brand}
                                                isIntern="SubcontractorProduct"
                                                planningType="SELL"
                                                openProductInfo={this.openProductInfo}
                                            />
                                        </CalendarSection>
                                    </CalendarSection>
                                )
                            })
                        }
                    </div>
                    <div className={classes.dragZone}>
                    </div>
                    <AddProductToCardModal
                      handleClose={this.toggleAddProductModal}
                      open={showAddProductModal}
                      createProductToCard={this.createProductToCard}
                      date={dateSelected}
                      onDateChange={this.updateDatePicker}
                    />
                    <PlanningProductModal
                        open={openProductInfo}
                        handleClose={this.closeProductInfo}
                        selectedCard={selectedCard}
                        rightProduct={rightProduct}
                        goToProduct={this.goToProduct}
                    />
                </div>
                <SnackbarMealPlanner planningSnackBar={planningSnackBar} />
                <HelpButton link={helpLinks.planning}/>
            </Box>
        )
    }
}

const PlanningSellWithStyle = withStyles(styles)(PlanningSell)

export default connect((state) => {
    const productTypeOptions = getProductTypeOptionsSelector(state)
    const planningSections = getPlanningSections(productTypeOptions)

    return {
        days: state.planning.sellDays,
        showInfo: state.planning.showInfo,
        brand: state.planning.brand,
        dataPlanning: state.planning.sellDataPlanning,
        menuIsOpen: state.app.menuIsOpen,
        canEdit: state.app.rights[CAN_UPDATE_SELL_PLANNING],
        rightProduct: state.planning.rightProduct,
        planningSections: planningSections,
        planningSnackBar: state.planning.planningSnackBar,
    }
}, {
    updateShowInfo,
    updateBrand,
    showMealPlanner,
    loadPlanningSpecificDay,
    loadSellPlanning,
    downloadExtract,
    findProduct,
    removeRightProduct,
    showWeeklyMealPlanner
})(PlanningSellWithStyle)
