import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import BackIcon from "@mui/icons-material/KeyboardBackspace"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import {
    getProductPackagingDlc,
    getProductPackagingProductTypeLabel
} from "../../utils/receptionWarehouse"
import moment from "moment"

const useStyles = makeStyles(() => ({
    header: {
        composes: "flexRow",
        height: 75,
        width: "100%",
        alignItems: "center"
    },
    backIconContainer: {
        marginLeft: 15
    },
    backIcon: {
        color: COLORS.DARK_GREY
    },
    titleItem: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "100%",
        fontStyle: "normal",
        maxWidth: 400
    },
    detailInfoBloc: {
        marginLeft: 20
    },
    moreInfosItem: {
        color: COLORS.DARK_GREY,
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "normal",
        marginTop: 5
    },
    spanDetail: {
        marginRight: 15
    },
    uniqueCode: {
        fontSize: 34
    },
    rightBlocInfos: {
        composes: "flewRow",
        marginLeft: "auto"
    },
    floatLeft: {
        float: "left",
        textAlign: "center",
        marginRight: 15
    },
    dividerHeader: {
        composes: "$floatLeft",
        border: "1px solid #e8e8e8",
        height: 34
    },
    headerRightTextDesc: {
        color: COLORS.DARK_GREY,
        fontSize: 10
    },
    headerRightTextNumber: {
        fontSize: 21,
        color: COLORS.BLACK
    },
}))

const ReceptionWarehouseHeader = (props) => {
    const {
        date,
        selectedProductPackaging,
        isDone,
        onClose
    } = props

    const classes = useStyles()

    return (
        <div className={classes.header}>
            <IconButton
                className={classes.backIconContainer}
                aria-label="Retour"
                onClick={onClose}
                size="large">
                <BackIcon className={classes.backIcon}/>
            </IconButton>
            {
                !isDone &&
                <>
                    <div className={classes.uniqueCode}>
                        {selectedProductPackaging && selectedProductPackaging.productionItems.length > 0 && selectedProductPackaging.productionItems[0].uniqueCode}
                    </div>
                    <div className={classes.detailInfoBloc}>
                        <div className={classes.titleItem}>
                            {selectedProductPackaging && selectedProductPackaging.productionItems.length > 0 && selectedProductPackaging.productionItems[0].commercialName}
                        </div>
                        <div className={classes.moreInfosItem}>
                            {
                                selectedProductPackaging &&
                                <span className={classes.spanDetail}>
                                    ID : {selectedProductPackaging.objectId}
                                </span>
                            }
                            {
                                selectedProductPackaging && selectedProductPackaging.productionItems.length > 0 &&
                                <span className={classes.spanDetail}>
                                    {getProductPackagingProductTypeLabel(selectedProductPackaging.productionItems[0].productType)}
                                </span>
                            }
                            <span className={classes.spanDetail}>
                                DATE : {moment(parseFloat(date)).format("DD/MM")}
                            </span>
                            {
                                selectedProductPackaging && selectedProductPackaging.productionItems.length > 0 &&
                                <span>
                                    DLC : {moment(getProductPackagingDlc(selectedProductPackaging.productionItems[0].dlc), selectedProductPackaging.productionItems[0].lifetime).format("DD/MM")}
                                </span>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ReceptionWarehouseHeader