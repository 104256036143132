import React from "react"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import ViewModuleICon from "@mui/icons-material/ViewModule"
import { Stack } from "@mui/material"

import { COLORS } from "../../utils"

const LotGroupHeaderTitle = () => {
    return (
        <Stack direction="row" alignItems="center" spacing={1.2}>
            <Icon sx={{ color: COLORS.BLUE_LOT_GROUP }}>
                <ViewModuleICon />
            </Icon>
            <Typography variant="h6" color="textPrimary" sx={{ color: COLORS.BLUE_LOT_GROUP }}>
                Allotissement Entrepôt
            </Typography>
        </Stack>
    )
}

export default LotGroupHeaderTitle
