import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import { makeStyles } from "@mui/styles"
import Snackbar from "@mui/material/Snackbar"
import { useDispatch, useSelector } from "react-redux"
import _cloneDeep from "lodash/cloneDeep"
import _isEqual from "lodash/isEqual"
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"

import {
    loadPlanningSpecificDay,
    updateInternCapacity,
    saveExpectedProduction,
    saveSumExpectedProduction,
    removeProductionItemIdFromSaved,
    saveSplitProduction,
    loadProductionPlanning,
    closePlanningSnackBar,
    downloadExtract,
    downloadBread,
    calculDispatch,
    updateMultipleStocks,
    orderReport,
    createSingleProductionItem,
    deleteProductionItemsPlanning,
    findProduct,
    removeRightProduct,
    setAnyFocused,
    updateProductionCardOnDragAndDrop
} from "../../actions/Planning/Planning"
import {
    printSingleCard,
    printMultipleCards,
    printHeadPage,
    downloadReleaseVoucher,
    downloadTrackingVoucher,
    downloadProductionReportCsv
} from "../../actions/Planning/PlanningProduction"
import {
    COLORS,
    setSearchValue
} from "../../utils"
import PlanningDays from "../../components/Planning/PlanningDays"
import {
    countProduction,
    getDifficulty,
    getAverageDifficulty,
    getSumGesters,
    planningBrands
} from "../../utils/planning"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import PlanningSplitProductionModal from "../../components/Planning/PlanningSplitProductionModal"
import CircularProgress from "@mui/material/CircularProgress"
import PlanningProductionActions from "../../components/Planning/PlanningActions/PlanningProductionActions"
import PlanningToolbar from "../../components/Planning/PlanningToolbar"
import PlanningProductionPackagingFilters from "../../components/Planning/PlanningFilters/PlanningProductionPackagingFilters"
import {
    CAN_UPDATE_PROD_PLANNING,
    CAN_PRINT_PROD_PLANNING,
    CAN_DELETE_PRODUCTION_PLANNING_PRODUCTION,
    CAN_INPUT_VOLUME_PROD_PLANNING,
    CAN_DRAG_N_DROP_PROD_PLANNING,
    CAN_ADD_PROD_PLANNING,
    CAN_SPLIT_PROD_PLANNING,
    CAN_PRINT_CARD_PROD_PLANNING,
    CAN_USE_GENERAL_ACTIONS_PROD_PLANNING
} from "../../utils/accessRights"
import OrderReportModal from "../../components/Planning/OrderReportModal"
import PlanningAddProductionItemModal from "../../components/Planning/PlanningAddProductionItemModal"
import PlanningDeleteProductionIdemModal from "../../components/Planning/PlanningDeleteProductionIdemModal"
import PlanningProductModal from "../../components/Planning/PlanningProductModal"
import PlanningProductionCommandModal from "../../components/Planning/PlanningProductionCommandModal"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import PlanningsProductionCalendarSection from "./PlanningsProductionCalendarSection"
import Parse from "parse"
import { createProductionDayLocked } from "../../parseManager/planning/parsePlanningManager"
import { debounce } from "lodash"

const useStyle = makeStyles({
    sectionContainer: {
        top: 210,
        position: "relative",
        paddingBottom: 70
    },
    changeDay: {
        width: 20,
        justifyContent: "center"
    },
    topTextfield: {
        fontSize: "1rem"
    },
    toolbar: {
        top: 112
    },
    planningDaysBloc: {
        overflow: "auto",
        width: "100%"
    },
    planningDaysBlocContent: {
        zIndex: 500,
        top: 176
    },
    dragZone: {
        top: 210,
        position: "relative",
        width: 32,
        height: document.getElementsByClassName("sectionContainer").innerHeight,
        backgroundColor: COLORS.WHITE
    },
    bodyContainer: {
        display: "flex",
        position: "relative"
    },
    calculLoadingContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 2000,
        position: "absolute",
        top: 0,
        left: 0
    },
    circularProgress: {
        color: COLORS.BLACK,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: "auto"
    }
})

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    })

    return ref.current
}

const PlanningsProduction = () => {
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const classes = useStyle()
    const dispatch = useDispatch()

    const days = useSelector(state => state.planning.prodDays)
    const dataPlanning = useSelector(state => state.planning.prodDataPlanning)
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    const planningSnackBar = useSelector(state => state.planning.planningSnackBar)
    const calculDispatchLoading = useSelector(state => state.planning.calculDispatchLoading)
    const rules = useSelector(state => state.planning.rules)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_PROD_PLANNING])
    const canPrint = useSelector(state => state.app.rights[CAN_PRINT_PROD_PLANNING])
    const canPrintCard = useSelector(state => state.app.rights[CAN_PRINT_CARD_PROD_PLANNING])
    const canAdd = useSelector(state => state.app.rights[CAN_ADD_PROD_PLANNING])
    const canSplit = useSelector(state => state.app.rights[CAN_SPLIT_PROD_PLANNING])
    const canDragAndDrop = useSelector(state => state.app.rights[CAN_DRAG_N_DROP_PROD_PLANNING])
    const canInputVolume = useSelector(state => state.app.rights[CAN_INPUT_VOLUME_PROD_PLANNING])
    const canDeleteProductionItem = useSelector(state => state.app.rights[CAN_DELETE_PRODUCTION_PLANNING_PRODUCTION])
    const canUseGeneralActions = useSelector(state => state.app.rights[CAN_USE_GENERAL_ACTIONS_PROD_PLANNING])
    const rightProduct = useSelector(state => state.planning.rightProduct)
    const anyFocused = useSelector(state => state.planning.anyFocused)
    const difficultiesGesters = useSelector(state => state.planning.difficultiesGesters)
    const productTypeOptions = useSelector(state => state.productTypes.productTypeOptions)
    const [showDays, setShowDays] = useState(days.filter((day, index) => index < 7))
    const [showDaysCards, setShowDaysCards] = useState([])
    const [inputValue, setInputValue] = useState(storage?.planningProduction || "")
    const [currentIndex, setCurrentIndex] = useState(0)
    const [openSplitModal, setOpenSplitModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openCommandModal, setOpenCommandModal] = useState(false)
    const [openOrderReport, setOpenOrderReport] = useState(false)
    const [currentCard, setCurrentCard] = useState(null)
    const [currentCards, setCurrentCards] = useState(null)
    const [openProductInfo, setOpenProductInfo] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [filteredBrands, setFilteredBrands] = useState(planningBrands)
    const [filteredSaleDate, setFilteredSaleDate] = useState(null)
    const [productData, setProductData] = useState([])
    const [filteredType, setFilteredType] = useState(productTypeOptions.filter(productTypeOption => productTypeOption.type === "MAIN_COURSE"))

    const prevDays = usePrevious(days)
    const prevDataPlanning = usePrevious(dataPlanning)

    const sections = useMemo(() => filteredType, [filteredType])

    const getProductData = useCallback(() => {
        const finalData = []

        for (const i in showDaysCards) {
            const currentDay = showDaysCards[i]
            const productTypesData = []
            let totalIntern = 0
            let totalExtern = 0

            for (const j in sections) {
                const currentSection = sections[j]
                const sectionCards = currentDay.cards[currentSection.type]
                const capacity = currentDay.capacities
                    ? currentDay.capacities.find(el => el.key === currentSection.type)
                    : null

                const sectionCapacity = capacity ? capacity.value : null

                const intern = sectionCards ? sectionCards.filter(card => card.itemType === "Recipe") : []
                const extern = sectionCards ? sectionCards.filter(card => card.itemType === "SubcontractorProduct") : []

                const internProd = countProduction(intern)
                const externProd = countProduction(extern)

                const { recipeNumber: nbDiff1, totalNumber: difficulty1 } = getDifficulty(intern, difficultiesGesters, 1)
                const { recipeNumber: nbDiff2, totalNumber: difficulty2 } = getDifficulty(intern, difficultiesGesters, 2)
                const { recipeNumber: nbDiff3, totalNumber: difficulty3 } = getDifficulty(intern, difficultiesGesters, 3)
                const averageDifficulty = getAverageDifficulty(difficulty1, difficulty2, difficulty3)
                const sumGesters = getSumGesters(intern, difficultiesGesters)

                totalIntern += intern.length
                totalExtern += extern.length

                productTypesData.push({
                    intern: intern.length,
                    extern: extern.length,
                    internProd: internProd,
                    externProd: externProd,
                    sectionCapacity: sectionCapacity,
                    total: intern.length + extern.length,
                    expectedSale: 0,
                    label: currentSection.label,
                    key: currentSection.type,
                    difficulty1: nbDiff1,
                    difficulty2: nbDiff2,
                    difficulty3: nbDiff3,
                    averageDifficulty,
                    sumGesters
                })
            }

            finalData.push({
                date: currentDay.date,
                totalIntern: totalIntern,
                totalExtern: totalExtern,
                productTypesData: productTypesData
            })
        }

        return finalData
    }, [sections, showDaysCards, filteredType])

    useEffect(() => {
        setProductData(getProductData(sections))
    }, [sections, getProductData, filteredType])

    useEffect(() => {
        if ("" !== inputValue) {
            onSearchValueChange({ target: { value: inputValue, noStorageAdd: true } })
        }
    }, [])

    useEffect(() => {
        if (prevDays && prevDays.length !== days.length) {
            if (prevDays[0].timestamp !== days[0].timestamp) {
                setShowDays(days.filter((day, index) => index < 7))
                setShowDaysCards(applyFilter(dataPlanning.filter((data, index) => index < 7)))
                setCurrentIndex(0)
            } else {
                setShowDays(days.filter((day, index) => index > days.length - 8))
                setShowDaysCards(applyFilter(dataPlanning.filter((data, index) => index > dataPlanning.length - 8)))
                setCurrentIndex(days.length - 7)
            }
        } else if (prevDataPlanning && !_isEqual(prevDataPlanning, dataPlanning)) {
            setShowDays(days.filter((day, index) => index >= currentIndex && index < currentIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= currentIndex && index < currentIndex + 7)))
            setCurrentIndex(currentIndex)
        } else {
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= currentIndex && index < currentIndex + 7)))
        }
    }, [days, dataPlanning, inputValue])

    useEffect(() => {
        if ("" !== inputValue) {
            onSearchValueChange(inputValue, true)
        }
    }, [dataPlanning])

    const date = Array.isArray(showDays) && showDays.length > 0
        ? moment(showDays[0].timestamp)
        : moment.utc().startOf("day").valueOf()

    const applyFilter = (days) => {
        const copy = _cloneDeep(days)
        const regex = new RegExp(inputValue, "ig")

        for (const i in copy) {
            const currentDay = copy[i]
            for (const j in currentDay.cards) {
                const currentCards = currentDay.cards[j]
                currentDay.cards[j] = currentCards.filter(card => card.name.match(regex) !== null || (card.originalCards[0].uniqueCode && card.originalCards[0].uniqueCode.match(regex) !== null))
            }
        }

        return copy
    }

    const onDateChange = (date) => {
        dispatch(loadProductionPlanning(moment.utc(date).startOf("day").valueOf()))
    }

    const onSearchValueChange = debounce((value, noStorageAdd) => {
        setInputValue(value)

        if (!noStorageAdd) setSearchValue(value, "planningProduction")
    }, 500)

    const onChangeSearch = useCallback(({ target: { value, noStorageAdd } }) => {
        onSearchValueChange(value || "", !!noStorageAdd)
    }, [])

    const onClickDownloadCards = (column) => {
        const timestamp = column.timestamp
        const showDay = showDaysCards.filter(day => day.date === timestamp)

        if (showDay.length === 1) {
            dispatch(printMultipleCards(timestamp, "KITCHEN"))
        }
    }

    const onClickDownloadHeadPage = (column) => {
        const timestamp = column.timestamp
        const showDay = showDaysCards.filter(day => day.date === timestamp)

        if (showDay.length !== 1) return
        dispatch(printHeadPage(timestamp, "KITCHEN"))
    }

    // TODO: this is not used anymore, maybe it will be re-used later
    const onClickDownloadReleaseVoucher = (column) => {
        const timestamp = column.timestamp
        const showDay = showDaysCards.filter(day => day.date === timestamp)

        if (showDay.length !== 1) return
        dispatch(downloadReleaseVoucher(timestamp))
    }

    const onClickDownloadTrackingVoucher = (column) => {
        const timestamp = column.timestamp
        const showDay = showDaysCards.filter(day => day.date === timestamp)

        if (showDay.length !== 1) return
        dispatch(downloadTrackingVoucher(timestamp))
    }

    const onClickCart = (day) => {
        const timestamp = day.timestamp
        extractOrderReport(timestamp, timestamp)
    }

    const onCreateCommand = (data, cards) => {
        const reloadDate = showDays[0].timestamp
        dispatch(downloadProductionReportCsv(cards, reloadDate, data))

        
    }

    const onClickPrev = () => {
        const firstShowDay = showDays[0]
        const oldIndex = days.indexOf(firstShowDay)

        if (oldIndex > 0) {
            const newIndex = oldIndex - 1

            setShowDays(days.filter((day, index) => index >= newIndex && index < newIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7)))
            setCurrentIndex(newIndex)
        }
        else {
            dispatch(loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[0].timestamp).subtract(1, "days").startOf("day").valueOf(),
                true,
                "PRODUCTION"
            ))
        }
    }

    const onClickNext = () => {
        const lastShowDay = showDays[showDays.length - 1]
        const firstShowDay = showDays[0]
        const oldFirstIndex = days.indexOf(firstShowDay)
        const oldLastIndex = days.indexOf(lastShowDay)

        if ((oldLastIndex + 1) < days.length) {
            const newIndex = oldFirstIndex + 1

            setShowDays(days.filter((day, index) => index >= newIndex && index < newIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7)))
            setCurrentIndex(newIndex)
        }
        else {
            dispatch(loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[days.length - 1].timestamp).add(1, "days").startOf("day").valueOf(),
                false,
                "PRODUCTION"
            ))
        }
    }

    const updateExpectedProduction = useCallback((value, productionItemId, card) => {
        dispatch(saveExpectedProduction(showDaysCards[0].date, value, productionItemId, card))
    }, [showDaysCards])

    const updateSumExpectedProduction = useCallback((data, productionItemIds, card) => {
        dispatch(saveSumExpectedProduction(data, productionItemIds, card))
    }, [])

    const _updateProductionCard = useCallback((destinationDate, card, sourceDate) => {
        dispatch(updateProductionCardOnDragAndDrop(sourceDate, destinationDate, card))
    }, [showDaysCards])

    const _openSplitModal = useCallback(card => {
        setOpenSplitModal(true)
        setCurrentCard(card)
    }, [])

    const _openAddModal = useCallback(card => {
        setOpenAddModal(true)
        setCurrentCard(card)
    }, [])

    const _openCommandModal = useCallback((dayMenu) => {
        const dayCards = showDaysCards.filter(day => day.date === dayMenu.timestamp)
        const showModal = Object.keys(dayCards[0].cards).some(type => dayCards[0].cards[type].length > 0)

        if (showModal) {
            setOpenCommandModal(true)
            setCurrentCards(dayCards)
        }
    }, [showDaysCards])

    const _openDeleteModal = useCallback(card => {
        setOpenDeleteModal(true)
        setCurrentCard(card)
    }, [])

    const closeSplitModal = useCallback(() => {
        setOpenSplitModal(false)
        setCurrentCard(null)
    }, [])

    const closeAddModal = useCallback(() => {
        setOpenAddModal(false)
        setCurrentCard(null)
    }, [])

    const closeDeleteModal = useCallback(() => {
        setOpenDeleteModal(false)
        setCurrentCard(null)
    }, [])

    const closeCommandModal = useCallback(() => {
        setOpenCommandModal(false)
        setCurrentCards(null)
    }, [])

    const closeOrderReportModal = useCallback(() => {
        setOpenOrderReport(false)
    }, [])

    const _saveSplitProduction = useCallback((data) => {
        dispatch(saveSplitProduction(showDaysCards[0].date, data))

        setOpenSplitModal(false)
        setCurrentCard(null)
    }, [showDaysCards])

    const addProductionItem = useCallback((saleDate, itemId, brand, type) => {
        dispatch(createSingleProductionItem(showDaysCards[0].date, saleDate, itemId, brand, type))

        setOpenAddModal(false)
        setCurrentCard(null)
    }, [showDaysCards])

    const deleteProductionItem = useCallback((values) => {
        dispatch(deleteProductionItemsPlanning(showDaysCards[0].date, values))
        setOpenDeleteModal(false)
        setCurrentCard(null)
    }, [showDaysCards])

    const onClickComputeDispatch = async () => {
        await dispatch(calculDispatch(showDays))
    }

    const onClickUpdateStocks = async () => {
        dispatch(updateMultipleStocks(
            showDays[0].timestamp,
            showDays[showDays.length - 1].timestamp,
            "PRODUCTION"
        ))
    }

    const printSingleKitchenCard = useCallback((card) => {
        dispatch(printSingleCard(card, card.productionDate, "KITCHEN"))
    }, [])

    const onTypesChange = (event) => {
        if (event?.target?.value) {
            setFilteredType([event.target.value])
        }
    }

    const onBrandsChange = (event) => {
        if (event && event.target && Array.isArray(event.target.value)) {
            let newFilteredBrands = event.target.value
            const oldCurrent = filteredBrands.find(elem => elem === "ALL")
            const newCurrent = newFilteredBrands.find(elem => elem === "ALL")

            if (oldCurrent && !newCurrent) {
                newFilteredBrands = []
            } else if (!oldCurrent && newCurrent) {
                newFilteredBrands = planningBrands
            } else if (oldCurrent && newCurrent) {
                newFilteredBrands = event.target.value.filter(elem => elem !== "ALL")
            } else if (!newCurrent && event.target.value.length === planningBrands.length - 1) {
                newFilteredBrands = planningBrands
            }

            setFilteredBrands(newFilteredBrands)
        }
    }

    const onSaleDateChange = (date) => {
        setFilteredSaleDate((date) ? moment.utc(date).startOf("day").valueOf() : null)
    }

    const onClickExtract = () => {
        const date = Array.isArray(showDays) && showDays.length > 0
            ? showDays[0].timestamp
            : moment.utc().startOf("day").valueOf()
        const lastDate = Array.isArray(showDays) && showDays.length > 0
            ? showDays[showDays.length - 1].timestamp
            : moment.utc().startOf("day").valueOf()
        dispatch(downloadExtract("productionDate", date, lastDate, "production"))
    }

    const onClickBread = () => {
        const start = Array.isArray(showDays) && showDays.length > 0
            ? showDays[0].timestamp
            : moment.utc().startOf("day").valueOf()
        const end = Array.isArray(showDays) && showDays.length > 0
            ? showDays[showDays.length - 1].timestamp
            : moment.utc().startOf("day").valueOf()

        dispatch(downloadBread(start, end))
    }

    const onClickOrderReport = () => {
        setOpenOrderReport(true)
    }

    const extractOrderReport = (startDate, endDate, forPreorderReport = false) => {
        dispatch(orderReport(startDate, endDate, forPreorderReport))
    }

    const closeProductInfo = useCallback(() => {
        dispatch(removeRightProduct())
        setOpenProductInfo(false)
        setSelectedCard(null)
    }, [])

    const _openProductInfo = useCallback((card) => {
        if (anyFocused) return
        dispatch(findProduct(card))
        setOpenProductInfo(true)
        setSelectedCard(card)
    }, [])

    const goToProduct = () => {
        const isRecipe = (selectedCard.itemType === "Recipe")

        if (isRecipe) window.open(`/products/recipe/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
        if (!isRecipe) window.open(`/products/subcontractor-product/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
    }

    const _updateSectionCapacity = (event, item, currentProductTypeData) => {
        const copy = cloneDeep(productData)
        const currentItem = copy.find(el => el.date === item.date)
        const currentProductType = currentItem.productTypesData.find(el => el.key === currentProductTypeData.key)

        currentProductType.sectionCapacity = event.target.value
        setProductData(copy)
    }

    const onAnyFocused = (value) => dispatch(setAnyFocused(value))
    const onRemoveProductionItemIdFromSaved = (id) => dispatch(removeProductionItemIdFromSaved(id))
    const onUpdateInternCapacity = (cardDate, value, item, productTypeData) => {
        dispatch(updateInternCapacity(cardDate, value, item, productTypeData))
    }

    const onProductionLockingUpdate = async (day) => {
        let data = await new Parse.Query("ProductionDay")
            .equalTo("date", day.timestamp)
            .first()

        if (data) {
            data.set("isLocked", !day.isLocked)
            await data.save()
        } else {
            await createProductionDayLocked(day.timestamp)
        }

        const oldDayToUpdateIndex = days.findIndex(elem => elem.timestamp === day.timestamp)
        days[oldDayToUpdateIndex].isLocked = !day.isLocked

        setShowDays(days.filter((day, index) => index < 7))
    }

    return (
        <>
            <PlanningToolbar menuIsOpen={menuIsOpen} className={classes.toolbar}>
                <PlanningProductionPackagingFilters
                    onSearchValueChange={onChangeSearch}
                    onTypesChange={onTypesChange}
                    onDateChange={onDateChange}
                    types={productTypeOptions}
                    filteredType={filteredType}
                    date={date}
                    brands={filteredBrands}
                    onBrandsChange={onBrandsChange}
                    saleDate={filteredSaleDate}
                    onSaleDateChange={onSaleDateChange}
                    searchFilter={inputValue} // just for initialization
                />
                {
                    canUseGeneralActions &&
                    <PlanningProductionActions
                        onClickComputeDispatch={onClickComputeDispatch}
                        onClickExtract={onClickExtract}
                        onClickUpdateStocks={onClickUpdateStocks}
                        onClickBread={onClickBread}
                        onClickOrderReport={onClickOrderReport}
                    />
                }
            </PlanningToolbar>
            <div className={classes.planningDaysBloc}>
                <PlanningDays
                    rootClassName={classes.planningDaysBlocContent}
                    days={showDays}
                    planningType="PRODUCTION"
                    onDownloadCards={onClickDownloadCards}
                    onDownloadHeadPage={onClickDownloadHeadPage}
                    onDownloadReleaseVoucher={onClickDownloadReleaseVoucher}
                    onDownloadTrackingVoucher={onClickDownloadTrackingVoucher}
                    onClickPrev={onClickPrev}
                    onClickNext={onClickNext}
                    onClickCart={onClickCart}
                    productData={productData}
                    openCommandModal={_openCommandModal}
                    canPrint={canPrint}
                    onProductionLockingUpdate={onProductionLockingUpdate}
                />
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.dragZone}>
                </div>
                <div className={classes.sectionContainer}>
                    {
                        sections.map((section, index) => {
                            return (
                                <PlanningsProductionCalendarSection
                                    key={index}
                                    rules={rules}
                                    canEdit={canEdit}
                                    canDeleteProductionItem={canDeleteProductionItem}
                                    setAnyFocused={onAnyFocused}
                                    removeProductionItemIdFromSaved={onRemoveProductionItemIdFromSaved}
                                    canPrintCard={canPrintCard}
                                    canAdd={canAdd}
                                    canSplit={canSplit}
                                    canDragAndDrop={canDragAndDrop}
                                    canInputVolume={canInputVolume}
                                    filteredBrands={filteredBrands}
                                    filteredSaleDate={filteredSaleDate}
                                    productData={productData}
                                    inputValue={inputValue}
                                    showDaysCards={showDaysCards}
                                    showDays={showDays}
                                    section={section}

                                    updateExpectedProduction={updateExpectedProduction}
                                    updateSumExpectedProduction={updateSumExpectedProduction}
                                    updateProductionCard={_updateProductionCard}
                                    openSplitModal={_openSplitModal}
                                    openAddModal={_openAddModal}
                                    openDeleteModal={_openDeleteModal}
                                    printSingleKitchenCard={printSingleKitchenCard}
                                    openProductInfo={_openProductInfo}
                                    updateSectionCapacity={_updateSectionCapacity}
                                    deleteProductionItem={deleteProductionItem}
                                    updateInternCapacity={onUpdateInternCapacity}
                                />
                            )
                        })
                    }
                </div>
                <div className={classes.dragZone}>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={planningSnackBar.open}
                autoHideDuration={planningSnackBar.duration}
                onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    variant={planningSnackBar.type}
                    message={planningSnackBar.message}
                    onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
                />
            </Snackbar>
            <PlanningProductModal
                open={openProductInfo}
                handleClose={closeProductInfo}
                selectedCard={selectedCard}
                rightProduct={rightProduct}
                goToProduct={goToProduct}
            />
            {
                canSplit && openSplitModal &&
                <PlanningSplitProductionModal
                    currentCard={currentCard}
                    closeModal={closeSplitModal}
                    saveSplitProduction={_saveSplitProduction}
                />
            }
            {
                canAdd && openAddModal &&
                <PlanningAddProductionItemModal
                    currentCard={currentCard}
                    closeModal={closeAddModal}
                    addProductionItem={addProductionItem}
                />
            }
            {
                canDeleteProductionItem && openDeleteModal &&
                <PlanningDeleteProductionIdemModal
                    currentCard={currentCard}
                    closeModal={closeDeleteModal}
                    deleteProductionItem={deleteProductionItem}
                />
            }
            {
                openCommandModal &&
                <PlanningProductionCommandModal
                    currentCards={currentCards}
                    closeModal={closeCommandModal}
                    onCreateCommand={onCreateCommand}
                />
            }
            {
                openOrderReport &&
                <OrderReportModal
                    extractOrderReport={extractOrderReport}
                    closeModal={closeOrderReportModal}
                />
            }
            {
                calculDispatchLoading &&
                <div className={classes.calculLoadingContainer}>
                    <CircularProgress
                        className={classes.circularProgress}
                        size={100}
                        thickness={2.5}
                    />
                </div>
            }
            <HelpButton link={helpLinks.planning} />
        </>
    )
}

export default PlanningsProduction
