import React from "react"
import Button from "@mui/material/Button"
import WarningIcon from "@mui/icons-material/Warning"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/AddCircleOutline"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "fixed",
      bottom: 57,
      zIndex: 1,
      justifyContent: "center",
      marginBottom: 40,
    },
    button: {
      fontWeight: "bold",
      borderRadius: 70,
      fontSize: 19,
      width: 137,
      height: 39,
      padding: 0,
      color: COLORS.WHITE,
      backgroundColor: COLORS.RED_WARNING,
      marginLeft: "auto",
      "&:hover": {
        color: COLORS.WHITE,
        backgroundColor: COLORS.RED_WARNING,
      },
      "& > div:first-child": {
        alignItems: "center",
        marginRight: 6,
      },
      "&.is-tablet": {
        fontSize: 24,
        height: 59,
        width: 286,
        marginLeft: "initial",
      },
    },
    icon: {
      width: 22,
      height: 20,
      "&.is-tablet": {
        width: 27,
        height: 25,
      },
    },
  })
)

const ButtonFlaw = (props) => {
  const { isTablet, onSetFlaw, addButton, addAction, rootClassName = "" } = props
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, rootClassName)}>
      {addButton && (
        <IconButton aria-label="Ajouter" onClick={addAction} size="large">
          <AddIcon fontSize="large" />
        </IconButton>
      )}
      <Button
        disableElevation
        variant="contained"
        size="large"
        className={clsx(classes.button, { "is-tablet": isTablet })}
        startIcon={
          <WarningIcon
            className={clsx(classes.icon, { "is-tablet": isTablet })}
          />
        }
        onClick={onSetFlaw}
      >
        Casse
      </Button>
    </div>
  )
}

export default ButtonFlaw
