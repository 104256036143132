import React, { useEffect } from "react"
import { Table, TableBody, TableRow, TableHead } from "@mui/material"
import { ACTIVE_KFC_BRANDS } from "../../../utils"
import { packagingNature } from "../../../utils/packaging"
import { PETableDivider, PETableCell, PETableHeaderCell } from "../StyledPackagingExecutionPreviewComponents"

const PackagingExecutionPackagings = ({ packagings = [], expectedPackagingNumber = 0, packagingForecastNumber = 0, setFieldValue }) => {

	const _updatePackagingForecastNumber = (packagings, packagingForecastNumber) => {
		if (packagings.length === 1) {
			setFieldValue("packagings[0].forecastNumber", packagingForecastNumber)
			return
		}
		let currentPackagingForecastNumber = packagingForecastNumber
		for (const packaging of packagings) {
			const index = packagings.indexOf(packaging)
			const isLast = index === packagings.length - 1
			const packagingTheoreticalNumber = packaging.theoreticalNumber || 0
			const usedPackagingNumber = isLast ? currentPackagingForecastNumber : Math.min(packagingTheoreticalNumber, currentPackagingForecastNumber)
			setFieldValue(`packagings[${index}].forecastNumber`, usedPackagingNumber)
			currentPackagingForecastNumber -= usedPackagingNumber
			currentPackagingForecastNumber = Math.max(0, currentPackagingForecastNumber)
		}
	}

	useEffect(() => {
		_updatePackagingForecastNumber(packagings, packagingForecastNumber)
	}, [packagings, packagingForecastNumber])

	return (
		<Table>
			<TableHead>
				<TableRow>
					<PETableHeaderCell align="start">Type</PETableHeaderCell>
					{ACTIVE_KFC_BRANDS.map((brand, index) => (
						<PETableHeaderCell key={index}>
							<img
								style={{ width: 40, height: 40 }}
								src={brand.image}
								alt={`${brand.name}-logo`}
							/>
						</PETableHeaderCell>
					))}
					<PETableDivider />
					<PETableHeaderCell>Total théorique ({expectedPackagingNumber})</PETableHeaderCell>
					<PETableDivider />
					<PETableHeaderCell>Total prévisionnel ({packagingForecastNumber})</PETableHeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{packagings.map((currentPackaging, index) => {
					return (
						<TableRow key={index}>
							<PETableCell align="start">
								{packagingNature.find(p => p.value === currentPackaging.type)?.label}
							</PETableCell>
							{ACTIVE_KFC_BRANDS.map((brand, index) => (
								<PETableCell disabled key={index}>
									{(currentPackaging.brands || []).find(packagingBrand => packagingBrand.brand === brand.name)?.theoreticalNumber || 0}
								</PETableCell>
							)
							)}
							<PETableDivider />
							<PETableCell fontWeight={700}>{currentPackaging.theoreticalNumber || 0}</PETableCell>
							<PETableDivider />
							<PETableCell fontWeight={700} width={293}>{currentPackaging.forecastNumber || 0}</PETableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)
}

export default PackagingExecutionPackagings
