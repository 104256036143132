import React, { useRef } from "react"
import { Formik, Form, FieldArray } from "formik"
import { Button, TextField, FormControl, Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import { openUploadWidget } from "../../utils/cloudinary"
import Parse from "parse"
import CommentsImageThumbnail from "./CommentsImageThumbnail"
import { CommentSchema } from "../../utils/yupValidators"

const useStyles = makeStyles({
	root: {
		width: "100%",
		flex: 1
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "end"
	},
	button: {
		alignSelf: "end"
	},
	commentField: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		marginBottom: 16,
		width: "100%"
	},
	imagesContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "start",
		alignItems: "center",
		gap: "24px",
		marginTop: "28px",
		marginBottom: "16px"
	},
	error: {
		marginLeft: "0px !important",
		color: "#F44259"
	}
})

const CommentsFormContainer = ({ onAddComment, collection }) => {

	const formRef = useRef(null)

	const classes = useStyles()

	const handleSubmitCommentForm = (newComment) => {
		onAddComment && onAddComment(newComment)
	}

	const uploadImageWithCloudinary = (existingImages = []) => {
		openUploadWidget({ tags: [] }, (error, result) => {
			if (error) {
				console.error(error)
				return
			}
			if (result.event !== "success") {
				return
			}

			const publicId = result.info.public_id
			const lastIndex = existingImages.length - 1
			const newImages = [...existingImages, { publicId: publicId, name: `comment_${lastIndex + 1}_${collection}` }]
			formRef.current?.setFieldValue("images", newImages)
		})
	}

	return (
		<div className={classes.root}>
			<Formik
				onSubmit={(values, { resetForm }) => {
					handleSubmitCommentForm(values)
					resetForm()
				}}
				innerRef={formRef}
				initialValues={{
					comment: "",
					username: Parse.User.current() ? Parse.User.current().get("username") : null,
					images: []
				}}
				validationSchema={CommentSchema}
				validateOnBlur={false}
				validateOnChange={false}
			>
				{({ values, handleChange, errors }) => {
					return (
						<Form style={{ height: "100%" }}>
							<FormControl className={classes.commentField}>
								<TextField
									variant="standard"
									label="Commentaire"
									name="comment"
									multiline
									value={values.comment}
									onChange={handleChange}
									sx={{
										gap: "8px"
									}}
									InputProps={{
										endAdornment: <CameraAltOutlinedIcon
											sx={{
												cursor: "pointer",
												color: "#555555",
												alignSelf: "end"
											}}
											onClick={() => uploadImageWithCloudinary(values.images)}
										/>,
									}}
								/>
								{errors.comment && <Typography variant="error">{errors.comment}</Typography>}
							</FormControl>
							<FieldArray name="images">
								{({ remove }) => {
									return values.images.length ? (
										<div
											className={classes.imagesContainer}
										>
											{values.images.map((image, index) => (
												<CommentsImageThumbnail
													key={index}
													imageId={image.publicId}
													onDelete={() => remove(index)}
												/>
											))}
										</div>
									) : null
								}}
							</FieldArray>
							<Box className={classes.buttonContainer}>
								<Button
									variant="contained"
									className={classes.button}
									onClick={() => formRef.current?.submitForm()}
								>
									Envoyer
								</Button>
							</Box>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

export default CommentsFormContainer