import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Autocomplete, TextField, Typography, Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { cloneDeep } from "lodash"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

import { checkProductStock, updateProductsStockReceptionStatus } from "../../utils/productStock"
import {
    getProductsStockByStatus,
    updateInitialVolumeProductStocks
} from "../../parseManager/reception/parseProductStockManager"
import { createProductItemsByLots } from "../../actions/Dispatch/dispatch"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
    main: {
        backgroundColor: COLORS.WHITE,
        width: 438,
        outline: "none",
        margin: "0 auto",
        top: "25%",
        position: "relative",
        borderRadius: 1,
        color: COLORS.BLACK,
        padding: "30px 30px 20px 30px"
    },
    inputContainer: {
        width: "100%",
        marginBottom: 10
    },
    datePicker: {
        width: "100%"
    },
    actionContainer: {
        marginTop: 20,
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    firstButton: {
        marginRight: 10
    },
    titleLabel: {
        fontWeight: 500,
        fontSize: 20,
        textTransform: "uppercase",
        lineHeight: "11px",
        paddingBottom: 40
    },
    errorLabel: {
        textAlign: "center",
        marginTop: 15,
        marginBottom: 15,
        color: COLORS.RED
    },
})

const formatAutocompleteOptions = (products = []) => {
    const options = []
    products.forEach(product => {
        if (product.uniqueCode && product.commercialName && product.objectId) {
            options.push({
                label: product.uniqueCode + " - " + product.commercialName,
                value: product.objectId,
                data: product,
            })
        }
    })

    return options
}

const InventoryAddModal = ({
    subcontractorsProducts,
    handleClose,
    hub,
    handleUpdateItem,
    refresh
}) => {
    const classes = useStyles()

    const [selectedProduct, setSelectedProduct] = useState("")
    const [date, setDate] = useState(null)
    const [showError, setShowError] = useState(false)
    const [buttonEnabled, setButtonEnabled] = useState(false)
    const dispatch = useDispatch()

    const onDlcChange = (date) => {
        let isShowingError = false
        if (date) {
            const currentDate = dayjs.tz().endOf("day").valueOf()
            setDate(dayjs.tz(date).endOf("day").valueOf())
            if (currentDate > date.valueOf()) {
                isShowingError = true
            }
        }

        setShowError(isShowingError)
        setButtonEnabled(!isShowingError && selectedProduct && date)
    }

    const handleProductSelection = (value) => {
        setSelectedProduct(value)
        setButtonEnabled(!showError && date && value)
    }

    const handleValidate = async () => {
        let productStock = await checkProductStock(selectedProduct, hub, date, "DONE")
        if (productStock) {
            handleUpdateItem(selectedProduct.data.uniqueCode, date)
        } else {
            // to be sure that these stocks appear in the inventory, we need to update reception status to DONE and update initialVolume
            const productStocks = await getProductsStockByStatus(hub, date, selectedProduct.data.objectId, "TODO")
            if (productStocks.length > 0) {
                const productsStocksIds = productStocks.map(element => element.id)

                await updateProductsStockReceptionStatus(productStocks, "DONE")
                await updateInitialVolumeProductStocks(productsStocksIds)
            } else {
                //If no productStock is found, we need to create them + productItem + productDispatch
                dispatch(createProductItemsByLots([selectedProduct.data], true, date, [{ hubId: hub, volume: 0 }]))
            }
        }

        handleClose()

        refresh()
    }

    return (
        <div className={classes.main}>
            <Typography className={classes.titleLabel}>
                Ajouter un produit à l'inventaire
            </Typography>
            <Typography>
                Indiquez le produit que vous souhaitez ajouter à l'inventaire ainsi que la DLC associée.
            </Typography>
            {Array.isArray(subcontractorsProducts)
                ? (
                    <div className={classes.inputContainer}>
                        <Autocomplete
                            options={formatAutocompleteOptions(cloneDeep(subcontractorsProducts))}
                            getOptionLabel={(option) => option.label}
                            onChange={(_, value) => handleProductSelection(value)}
                            isOptionEqualToValue={(option, value) =>
                                option.objectId === value.objectId
                            }
                            renderInput={(params) => (
                                <TextField
                                    variant="standard"
                                    {...params}
                                    label="Code - Nom commercial"
                                    value={selectedProduct ? selectedProduct.value : ""}
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                />
                            )}
                        />
                    </div>
                )
                : null
            }
            <div className={classes.inputContainer}>
                <DatePicker
                    disableCloseOnSelect={false}
                    inputFormat="DD/MM/YYYY"
                    value={date}
                    onChange={onDlcChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="DLC"
                            placeholder="XX/XX/XXXX"
                            className={classes.datePicker}
                        />
                    )}
                />
                {showError &&
                    <div className={classes.errorLabel}>
                        Vous ne pouvez pas choisir de date passée
                    </div>
                }
            </div>
            <div className={classes.actionContainer}>
                <Button
                    className={classes.firstButton}
                    color="primary"
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidate}
                    disabled={!buttonEnabled}
                >
                    ajouter
                </Button>
            </div>
        </div>
    )
}

export default InventoryAddModal