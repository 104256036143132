import React from "react"
import {makeStyles} from "@mui/styles"
import ActionHeader from "./ActionHeader"
import Button from "@mui/material/Button"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {COLORS} from "../../utils"
import InputFlawProduct from "./InputFlawProduct"
import InputFlawDLC from "./InputFlawDLC"
import InputFlawNumberAndReason from "./InputFlawNumberAndReason"

const baseAction = {
	textAlign: "center",
	alignSelf: "center",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}

const useStyles = makeStyles(() => ({
	root: {
		...baseAction,
		"& form": {
			...baseAction
		}
	},
	buttonBack : {
		position: "absolute",
		width: 259,
		height: 59,
		left: 12,
		top: 164,
		background: COLORS.WHITE,
		border: `1px solid ${COLORS.GREY_BACK_BUTTON}`,
		borderRadius: 5,
		textTransform: "none",
		color: COLORS.GREY_SEARCH,
		fontSize: 19
	}
}))

const ActionFlawContent = (props) => {
	const {
		stepFlaw,
		handleClose,
		handleValidate,
		isTablet,
		setValue,
		flawProduct,
		flawDlc,
		lostActionInfos
	} = props
	const classes = useStyles()

	return (
		<>
			{/* header */}
			{ isTablet &&
				<ActionHeader text={""} handleClose={handleClose} noBorder={true} />
			}

			<div className={classes.root}>
				{
					!isTablet &&
						<Button
							className={classes.buttonBack}
							variant="outlined"
							startIcon={<ArrowBackIcon />}
							onClick={handleClose}
						>
							Annuler la déclaration
						</Button>
				}
				
				{/* select product display */}
				{ stepFlaw === 1  &&
					<InputFlawProduct
						handleValidate={handleValidate}
						isTablet={isTablet}
						setValue={setValue}
					/>
				}

				{/* select DLC display */}
				{ stepFlaw === 2  &&
					<InputFlawDLC
						handleValidate={handleValidate}
						isTablet={isTablet}
						setValue={setValue}
						flawProduct={flawProduct}
						flawDlc={flawDlc}
					/>
				}

				{/* select volume and reason display */}
				{ stepFlaw === 3  &&
					<InputFlawNumberAndReason
						handleValidate={handleValidate}
						isTablet={isTablet}
						setValue={setValue}
						flawProduct={flawProduct}
						lostActionInfos={lostActionInfos}
					/>
				}
			</div>
		</>
	)
}

export default ActionFlawContent