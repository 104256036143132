import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import {
    getFamilyIngredient
} from "../../../../../reducers/Ingredients/Classifications/FamiliesIngredients"
import {
    showFamiliesClassificationsIngredients,
    showSingleFamilyClassificationIngredientsEdit
} from "../../../../../actions/Ingredients/Classifications/Families/Families"
import Button from "@mui/material/Button"

const styles = {
    container: {
        padding: 20
    },
    closeWindow: {
        textAlign: "right"
    },
    subContainer: {
        marginLeft: 15,
        marginBottom: 15,
        width: 200
    },
    titleLabelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    editBtn: {
        textAlign: "right",
        marginRight: 15
    }
}

export class FamilyIngredientRead extends PureComponent {

    _onClose = () => {
        const { showFamiliesClassificationsIngredients } = this.props
        showFamiliesClassificationsIngredients()
    }

    _onEditClick = () => {
        const {
            showSingleFamilyClassificationIngredientsEdit,
            familyIngredient
        } = this.props

        showSingleFamilyClassificationIngredientsEdit(familyIngredient.objectId, "edit")
    }

    render() {
        const {
            classes,
            familyIngredient
        } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.subContainer}>
                    <div className={classes.titleLabelContainer}>
                        Nom
                    </div>
                    <div>
                        {familyIngredient && familyIngredient.name}
                    </div>
                </div>
                <div className={classes.editBtn}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this._onEditClick}
                    >
                        Editer
                    </Button>
                </div>
            </div>
        )
    }
}

const StyledFamilyIngredientRead = withStyles(styles)(FamilyIngredientRead)

export default connect((state, props) => {
    const { params: { id } } = props
    const familyIngredient = id ? getFamilyIngredient(state, id) : null
    return {
        familyIngredient: familyIngredient
    }
}, {
    showFamiliesClassificationsIngredients,
    showSingleFamilyClassificationIngredientsEdit
})(StyledFamilyIngredientRead)
