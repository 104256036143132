import React, {PureComponent} from "react"
import AsyncSelect from "react-select/async"
import MenuItem from "@mui/material/MenuItem"
import Parse from "parse"
import AutoCompleteBase from "./AutoCompleteBase"
import { parseLimitRequest, getBrandBy } from "../../utils"

const SubcontractorProduct = Parse.Object.extend("SubcontractorsProducts")
const SubcontractorsIngredients = Parse.Object.extend("SubcontractorsIngredients")

const loadOptions  = async (inputValue, callback) => {
    callback(await filterSubcontractorProducts(inputValue))
}

const filterSubcontractorProducts = async (inputValue) => {
    if (inputValue) {
        const innerQuery = new Parse.Query(SubcontractorsIngredients).matches("name", inputValue, "i")
        const subcontractorProducts = await new Parse.Query(SubcontractorProduct)
            .matchesQuery("name", innerQuery)
            //.equalTo("status", "6")
            .include("name")
            .include("subcontractor")
            .limit(parseLimitRequest)
            .find()

        let result = []
        for (const i in subcontractorProducts) {
            const obj = subcontractorProducts[i]
            result.push({id: obj.id, label: obj.get("name").get("name").toLowerCase()+" ("+obj.get("subcontractor").get("name")+")", brand: obj.get("brand")})
        }
        return result
    }
    return []
}

function getLogo(brand) {
    const completeBrand = getBrandBy("name", brand)

    return (
        <div className="brandLogoContainer">
            <img src={completeBrand.image} className="brandLogo" alt={completeBrand.shortname} />
        </div>
    )
}

function Option(hiddenOptions) {
    return function(props) {
        const hiddenIds = hiddenOptions.map(item => item.id)
        const {data} = props
        if (!hiddenIds.includes(data.id)) {
            const brand = data ? data.brand : null
            const logo = getLogo(brand)
            return (
                <MenuItem
                    buttonRef={props.innerRef}
                    selected={props.isFocused}
                    component="div"
                    style={{
                        fontWeight: props.isSelected ? 500 : 400,
                        display: "flex",
                        flexDirection: "row"
                    }}
                    {...props.innerProps}
                >
                    {logo} {props.children}
                </MenuItem>
            )
        }

        return null
    }
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function SingleValue(props) {
    const { getValue } = props
    const value = getValue()[0]
    const logo = getLogo(value.brand)
    return (
        <div
            {...props.innerProps}
            className="flexRow center"
        >
            {logo} {props.children}
        </div>
    )
}

class SubcontractorProductAutoComplete extends PureComponent {
    render() {
        const {onChange: OnInputChange, name, value: newValue, autoFocus, hiddenOptions} = this.props
        return (
            <AutoCompleteBase onInputChange={OnInputChange}>
                {({classes: baseClasses, styles, components, onChange}) => (
                    <AsyncSelect
                        name={name}
                        classes={baseClasses}
                        styles={styles}
                        components={{...components, Option: Option(hiddenOptions), ValueContainer, SingleValue}}
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        onChange={onChange}
                        placeholder="Fiche produit sous traitant"
                        value={newValue}
                        isClearable={true}
                        autoFocus={autoFocus}
                    />
                )}
            </AutoCompleteBase>
        )
    }
}

export default SubcontractorProductAutoComplete
