import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectBillingCreditNotesType, showBillingAndCreditNotes } from "../../../actions/BillingCreditNotes/BillingCreditNotes"
import CreditNotesTab from "./CreditNotesTab"
import BillingTab from "./BillingTab"
import BillingCreditNotesHeader from "./BillingCreditNotesHeader"
import { getBillingSitesSelector, getBillingSuppliersSelector } from "../../../reducers/BillingCreditNotes/billingCreditNotes"

const BillingCreditNotesTabs = () => {

	const dispatch = useDispatch()

	const creditNotesSavedFilter = useSelector(state => state.billingCreditNotes.filters.creditNotes)
	const sites = useSelector(getBillingSitesSelector)
	const suppliers = useSelector(getBillingSuppliersSelector)
	const orderType = useSelector(state => state.billingCreditNotes.orderType)
	const siteNameList = sites.map(site => site.name).join(", ")

	const [tabValue, setTabValue] = useState(0)

	useEffect(() => {
		if (!orderType) return
		if (orderType === "BILLING") return
		// if the saved type (tab) is credit note, set the default tab value to creditNotes
		setTabValue(1)
	}, [orderType])

	const onBack = () => {
		dispatch(showBillingAndCreditNotes())
	}

	const onTabChange = (_, tabValue) => {
		localStorage.setItem("useStoredDataGridFilters", "1")
		setTabValue(tabValue)

		// save the last selected tab
		const orderType = tabValue === 0 ? "BILLING" : "CREDIT_NOTE"
		dispatch(selectBillingCreditNotesType(orderType))
	}
	return (
		<>
			<BillingCreditNotesHeader
				onBack={onBack}
				subtitle={siteNameList}
			/>
			<Tabs
				value={tabValue}
				onChange={onTabChange}
				indicatorColor="primary"
				textColor="primary"
				centered
				sx={{
					borderBottom: "1px solid #E6E6E6",
					"& .MuiTab-root": {
						padding: "16px 24px"
					}
				}}
			>
				<Tab value={0} label="CONTRÔLE FACTURATION" />
				<Tab value={1} label="AVOIRS" />
			</Tabs>
			{
				tabValue === 0 && <BillingTab />
			}
			{
				tabValue === 1 && (
					<CreditNotesTab
						suppliers={suppliers}
						savedFilter={creditNotesSavedFilter}
					/>
				)
			}
		</>

	)
}

export default BillingCreditNotesTabs
