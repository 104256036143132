import React, { useState } from "react"
import clsx from "clsx"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { CircularProgress } from "@mui/material"
import LotInventoryOutputSnackBar from "../../components/LotInventory/LotInventoryOutputSnackBar"
import { closeInventoryOutputSnackBar } from "../../actions/LotInventory/LotInventory"
import { COLORS } from "../../utils"
import LotReturnModal from "../../components/LotInventory/LotReturnModal"

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  container: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND,
    "@media (max-width: 825px)": {
      alignItems: "flex-start",
      paddingTop: "72px",
    },
  },
}))

const LotReturn = (props) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const inventoryOutputSnackBar = useSelector(
    (state) => state.lotInventory.inventoryOutputSnackBar
  )
  const mode = props.mode

  if (isLoading) {
    return (
      <div className={clsx(classes.wrapper, "flexCenter")}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <LotReturnModal
          mode={mode}
          loading={isLoading}
          setIsLoading={setIsLoading}
        />

        <LotInventoryOutputSnackBar
          data={inventoryOutputSnackBar}
          onClose={() =>
            dispatch(closeInventoryOutputSnackBar(inventoryOutputSnackBar.type))
          }
        />
      </div>
    </div>
  )
}
export default LotReturn
