import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { LayoutGroup, AnimatePresence, motion } from "framer-motion"
import { List, ListItem } from "@mui/material"
import CustomSnackbar from "./CustomSnackbar"

import { closeEtiquettableSnackBar, closeRecipeSnackBar } from "../actions/Recipe/recipes"

const SnackbarsChain = ({ snackbars, autohide = true }) => {

    const dispatch = useDispatch()

    const _defineCloseAction = (snackbar) => {
        let closeAction = null
        const { label, type } = snackbar
        switch (label) {
            case "recipe":
                closeAction = () => {
                    dispatch(closeRecipeSnackBar(type))
                }
                break
            case "etiquettable":
                closeAction = () => {
                    dispatch(closeEtiquettableSnackBar(type))
                }
                break
            default:
                break
        }
        return closeAction
    }

    const formattedSnackbars = useMemo(() => {
        if (!snackbars || !Array.isArray(snackbars) || snackbars.length === 0) return []
        return snackbars.map((snackbar) => {
            return {
                ...snackbar,
                closeAction: _defineCloseAction(snackbar)
            }
        })
    }, [snackbars])

    // we don't add "|| !formattedSnackbars.length" in the line below otherwise the animate on exit won't work
    if (!formattedSnackbars || !Array.isArray(formattedSnackbars)) return null

    // --------------------------------------------------------//
    // ---------------------- animations --------------------- //
    // --------------------------------------------------------//

    const snackbarVariants = {
        hidden: {
            opacity: 0,
            height: 0
        },
        visible: {
            opacity: 1,
            height: "auto"
        }
    }

    const snackbarTransition = { duration: 0.4, ease: "easeInOut" }

    return <List spacing={4} sx={{
        bottom: "24px",
        left: "24px",
        position: "fixed",
        zIndex: 1400,
        height: "auto"
    }}>
        <LayoutGroup id="multiple-snackbars">
            <AnimatePresence>
                {formattedSnackbars.map((snackbar, index) => {
                    return (
                        <motion.div
                            key={`animated_snackbar_${index}`}
                            id="multiple-snackbars"
                            variants={snackbarVariants}
                            transition={snackbarTransition}
                            initial="hidden"
                            animate="visible"
                            exit="hidden">
                            <ListItem>
                                {/**  
                                 * CUSTOM SNACKBAR : 
                                 * we can't use MUI native snackbar because MUI prevents from displaying several snackbars at the same time 
                                 * */}
                                <CustomSnackbar  
                                    snackbarItem={snackbar}
                                    index={index}
                                    enableAutohide={autohide}
                                    duration={snackbar.duration} />
                            </ListItem>
                        </motion.div>
                    )
                })}
            </AnimatePresence>
        </LayoutGroup>
    </List>
}

export default SnackbarsChain

SnackbarsChain.propTypes = {
    snackbars: PropTypes.array.isRequired, // Array of objets, {open: bool, type: string, message: string, duration: number}
    autohide: PropTypes.bool // if true (default), snackbar will disappear after the time specified in duration field has elapsed
}