import React, {useState} from "react"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import LotFilterHeaderTitle from "../../components/Order/Lot/LotFilterHeaderTitle"
import { Box, IconButton } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem } from "@mui/material"
import LotFilter from "./LotFilter"
import ExtractLotModal from "./ExtractLotModal"
import { LOT_INVENTORY_MODE } from "../../utils/lotInventoryUtils"
import { Tab, Tabs } from "@mui/material"
import LotsHistory from "./LotsHistory"
import {useDispatch, useSelector} from "react-redux"
import {changeLotTab} from "../../actions/Dispatch/dispatch"


const LotInventory = () => {

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [extractInventory, setExtractInventory] = useState(false)
  const tabValue = useSelector(state => state.lotInventory.tabValue)
  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExtractInventory(false)
  }


  const openExtractModal = () => {
    setExtractInventory(true)
    setAnchorEl(null)
  }

  const _onTabChange = (event, tabValue) => {
    dispatch(changeLotTab(tabValue))
  }

  return (
    <>
      <Box sx={{ height: "64px" }}>
        <GenericTopBarHeader
          title={<LotFilterHeaderTitle mode={"inventoryColor"} />}
          rightAction={
            <span>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={id}
                open={Boolean(open)}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                {
                  <MenuItem
                    onClick={() => openExtractModal()}
                  >
                    Extraire l'inventaire
                  </MenuItem>

                }
              </Menu>
            </span>
          }
        />
      </Box>
      <Tabs
        value={tabValue !== null && tabValue !== undefined ? tabValue : false}
        onChange={_onTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
        centered
        sx={{
          borderBottom: "1px solid #E6E6E6"
        }}
      >
        <Tab label="INVENTAIRE" />
        <Tab label="HISTORIQUE DES LOTS" />
      </Tabs>
      {tabValue === 0 && <LotFilter />}
      {tabValue === 1 && <LotsHistory />}
      <ExtractLotModal
        open={extractInventory}
        onClose={handleClose}
        mode={LOT_INVENTORY_MODE}
      />
    </>
  )
}

export default LotInventory