import React from "react"
import { Stack, Typography } from "@mui/material"
import { StyledPaper, StyledPaperTitle, StyledPaperLabel } from "../../../../components/StyledSingleParseObjectComponents"
import GenericEditIcon from "../../../../components/GenericEditIcon"
import { KFC_BRANDS } from "../../../../utils"
import { packagingNature } from "../../../../utils/packaging"

const DistributionCenterResume = ({ distributionCenter = {}, onEdit }) => {

	return (
		<StyledPaper>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center">
				<StyledPaperTitle>Résumé du centre de distribution</StyledPaperTitle>
				<GenericEditIcon onEdit={onEdit} />
			</Stack>
			<Stack gap="16px">
				<Stack direction="row">
					<StyledPaperLabel>Nom</StyledPaperLabel>
					<Typography>{distributionCenter.name}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Id externe (NU)</StyledPaperLabel>
					<Typography>{distributionCenter.externalId}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Marque</StyledPaperLabel>
					<Typography>{KFC_BRANDS.find(brand => brand.name ===distributionCenter.brand)?.label}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Ville de rattachement</StyledPaperLabel>
					<Typography>{distributionCenter.sector}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Type de packaging</StyledPaperLabel>
					<Typography>{distributionCenter && distributionCenter.packagingTypes ? distributionCenter.packagingTypes.map(el => { return packagingNature.find(e => e.value === el).label }).join(", ") : ""}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Priorité</StyledPaperLabel>
					<Typography>{distributionCenter.dispatch?.priority}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Actif</StyledPaperLabel>
					<Typography>{distributionCenter.isActive ? "Oui" : "Non"}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Province</StyledPaperLabel>
					<Typography>{distributionCenter.national ? "Oui" : "Non"}</Typography>
				</Stack>
				<Stack direction="row">
					<StyledPaperLabel>Seulement précommande</StyledPaperLabel>
					<Typography>{distributionCenter.onlyPreorders ? "Oui" : "Non"}</Typography>
				</Stack>
			</Stack>
		</StyledPaper>
	)
}

export default DistributionCenterResume