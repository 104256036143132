import React from "react"
import Radio from "@mui/material/Radio"

export default ({input: {name, onChange: onInputChange}, onChange, ...rest}) => {
    const handleChange = (event) => {
        onInputChange(event)
        onChange && onChange(rest.selectedValue)
    }

    return (
        <Radio {...rest} name={name} onChange={handleChange} checked={rest.selectedValue === rest.value} color="secondary" />
    )
}
