import moment from "moment"

export const productTypes = [
    "Matière première",
    "Produit vendable",
    "Packaging"
]

export const minimumCommandUnits = [
    "€",
    "Kg"
]

export const deliveryLeadTimeList = [

    {value: 0, label: "A pour A soit le jour même"},
    {value: 1, label: "A pour B soit 1 jour"},
    {value: 2, label: "A pour C soit 2 jours"},
    {value: 3, label: "A pour D soit 3 jours"},
    {value: 4, label: "A pour E soit 4 jours"},
    {value: 5, label: "A pour F soit 5 jours"},
    {value: 6, label: "A pour G soit 6 jours"},
    {value: 7, label: "A pour H soit 7 jours"},
    {value: 8, label: "A pour I soit 8 jours"},
    {value: 9, label: "A pour J soit 9 jours"},
    {value: 10, label: "A pour K soit 10 jours"},
    {value: 11, label: "A pour L soit 11 jours"},
    {value: 12, label: "A pour M soit 12 jours"},
    {value: 13, label: "A pour N soit 13 jours"},
    {value: 14, label: "A pour O soit 14 jours"},
    {value: 15, label: "A pour P soit 15 jours"},
    {value: 16, label: "A pour Q soit 16 jours"},
    {value: 17, label: "A pour R soit 17 jours"},
    {value: 18, label: "A pour S soit 18 jours"},
    {value: 19, label: "A pour T soit 19 jours"},
    {value: 20, label: "A pour U soit 20 jours"},
    {value: 21, label: "A pour V soit 21 jours"},
    {value: 22, label: "A pour W soit 22 jours"},
    {value: 23, label: "A pour X soit 23 jours"},
    {value: 24, label: "A pour Y soit 24 jours"},
    {value: 25, label: "A pour Z soit 25 jours"}
]

export const deliveryDaysList = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche"
]

export const openDayslist = [
    {label: "Jours ouvrés (samedi, dimanche, jours fériés et jours fermés exclus)", value: "OPEN_DAYS", days: [1,2,3,4,5]},
    {label: "Jours ouvrables (dimanche, jours fériés et jours fermés exclus)", value: "WORKING_DAYS", days: [1,2,3,4,5,6]},
    {label: "Jours calendaires (jours fermés exclus)", value: "CALENDAR_DAYS", days: [1,2,3,4,5,6,7]}
]

export const sendingOrderTypes = [
    {name: "manually", label: "Manuellement :"},
    {name: "email", label: "Par e-mail :"},
    {name: "fax", label: "Par fax :"}
]

export const creationInitialValues = {
    name: "",
    corporateName:  "",
    contactName: "",
    phone: "",
    siret: "",
    intraTVA: "",
    commercialEmails: "",
    defaultDeliverySite: ""
}

export const getSupplierResumeInitialValues = jsonSupplier => {
    return {
        name: jsonSupplier.name || "",
        isActive: jsonSupplier.isActive || false,
        canExportEtiquettable: jsonSupplier.canExportEtiquettable || false,
        corporateName: (jsonSupplier.informations && jsonSupplier.informations.corporateName) || "",
        countryCode: (jsonSupplier.informations && jsonSupplier.informations.countryCode) || "",
        continentCode:  (jsonSupplier.informations && jsonSupplier.informations.continentCode) || "",
        sanitaryApprovalNumber:  (jsonSupplier.informations && jsonSupplier.informations.sanitaryApprovalNumber) || "",
        siret: (jsonSupplier.informations && jsonSupplier.informations.siret) || "",
        intraTVA: (jsonSupplier.informations && jsonSupplier.informations.intraTVA) || "",
        referenceStartDate: (jsonSupplier.informations && jsonSupplier.informations.referenceStartDate) || moment.utc(),
        referenceEndDate:  (jsonSupplier.informations && jsonSupplier.informations.referenceEndDate) || moment.utc(),
        defaultDeliverySite: (jsonSupplier.informations && jsonSupplier.informations.defaultDeliverySite && jsonSupplier.informations.defaultDeliverySite.objectId) || "",
        clientReference: (jsonSupplier.informations && jsonSupplier.informations.clientReference) || "FoodChéri"
    }
}

export const getSupplierContactInitialValues = jsonSupplier => {
    return {
        country: (jsonSupplier.contact && jsonSupplier.contact.country) || "",
        city: (jsonSupplier.contact && jsonSupplier.contact.city) || "",
        zipCode: (jsonSupplier.contact && jsonSupplier.contact.zipCode) || "",
        address: (jsonSupplier.contact && jsonSupplier.contact.address) || "",
        phone: (jsonSupplier.contact && jsonSupplier.contact.phone) || "",
        fax: (jsonSupplier.contact && jsonSupplier.contact.fax) || "",
        contactName: (jsonSupplier.contact && jsonSupplier.contact.contactName) || "",
        commercialEmails: (
            jsonSupplier.contact &&
            jsonSupplier.contact.emails &&
            jsonSupplier.contact.emails.commercialEmails &&
            jsonSupplier.contact.emails.commercialEmails.join(", ")
        ) || "",
        orderEmails: (
            jsonSupplier.contact &&
            jsonSupplier.contact.emails &&
            jsonSupplier.contact.emails.orderEmails &&
            jsonSupplier.contact.emails.orderEmails.join(", ")
        ) || ""
    }
}

export const getSupplierCommandInitialValues = jsonSupplier => {
    return {
        minimumCommand: jsonSupplier.order && jsonSupplier.order.minimumCommand,
        minimumCommandUnit: (jsonSupplier.order && jsonSupplier.order.minimumCommandUnit) || "€",
        sendOrderMode: (jsonSupplier.order && jsonSupplier.order.sendOrderMode) || {manually: false, email: false, fax: false},
        openDays: jsonSupplier.order && jsonSupplier.order.openDays,
        deliveryDays: (jsonSupplier.order && jsonSupplier.order.deliveryDays) || [],
        orderPrecision: jsonSupplier.order && jsonSupplier.order.orderPrecision,
        forbidMinimumCommand: !!jsonSupplier.order && jsonSupplier.order.forbidMinimumCommand,
        showLinkInEmail: !!jsonSupplier.order && jsonSupplier.order.showLinkInEmail,
        shippingFees: jsonSupplier.order && jsonSupplier.order.shippingFees,
        allowShippingFeesCancel: !!jsonSupplier.order && jsonSupplier.order.allowShippingFeesCancel,
        allowEarlyDelivery: !!jsonSupplier.order && jsonSupplier.order.allowEarlyDelivery,
        deliveryLeadTime: jsonSupplier.order && jsonSupplier.order.deliveryLeadTime,
        orderHourLimit: jsonSupplier.order && jsonSupplier.order.orderHourLimit
    }
}

export const getSupplierInvoiceInitialValues = jsonSupplier => {
    return {
        invoiceEqualToBL: !!jsonSupplier.billing && jsonSupplier.billing.invoiceEqualToBL,
        autoComplain: !!jsonSupplier.billing && jsonSupplier.billing.autoComplain,
        autoSendEmailComplain: !!jsonSupplier.billing && jsonSupplier.billing.autoSendEmailComplain,
        autoSendEmailCredit: !!jsonSupplier.billing && jsonSupplier.billing.autoSendEmailCredit
    }
}

export const getSupplierDeliveryDays = (supplierDeliveryDays) => {
    return deliveryDaysList
        .filter((day, index) => supplierDeliveryDays.indexOf(index+1) > -1)
        .join(", ")
}
