import React from "react"
import SuppliersPage from "./SuppliersPage"
import SuppliersList from "./Suppliers/SuppliersList"

const SuppliersTab = () =>  {
	return (
		<SuppliersPage tabValue="suppliers">
			<SuppliersList/>
		</SuppliersPage>
	)
}

export default SuppliersTab