import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import GenericHeaderReturnButton from "./GenericHeaderReturnButton"
import { COLORS } from "../utils"
import GenericTopBarHeader from "./GenericTopBarHeader"
import GenericHeaderInfo from "./GenericHeaderInfo"
import { styled } from "@mui/styles"
import { Box } from "@mui/system"


const StyledTabsContainer = styled("div")({
    padding: "0 20px",
    borderBottom: `1px solid ${COLORS.DEFAULT_GREY}`,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff"
})

const PageTabs = (props) => {
    const {
        tabValue,
        children,
        tabs,
        onTabChange,
        title,
        info,
        onBack
    } = props

    return (
        <Box display="flex" flexDirection="column">
            <Box height={64}>
                <GenericTopBarHeader
                    leftAction={onBack ? <GenericHeaderReturnButton handleClick={onBack} /> : null}
                    title={title}
                    info={info ? <GenericHeaderInfo info={info}/> : null}
                />
            </Box>
            <StyledTabsContainer className="flexRow justifyCenter">
                <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                  {tabs.map((tab, index) => (
                    <Tab key={index} value={tab.value} label={tab.label} sx={{ px: 5 }} />
                ))}
                </Tabs>
            </StyledTabsContainer>
            {children}
        </Box>
    )
}

export default PageTabs