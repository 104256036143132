import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Button } from "@mui/material"
import { Formik } from "formik"
import { cloneDeep } from "lodash"
import ProductionStepsResumeBanner from "./ProductionStepsResumeBanner"
import ProductionStepsTableHead from "./ProductionStepsTableHead"
import Sections from "./sections/Sections"
import ProductionStepsTable from "./ProductionStepsTable"
import { computeStepNumbers, recipeSectionsFormInitialValues } from "../../../actions/Utils/utils"
import { RecipeProductionStepsSchema } from "../../../utils/yupValidators"
import ProductionStepsContainer from "./ProductionStepsContainer"
import { getMachineTypesSelector } from "../../../reducers/Machines/machines"
import { loadMachineTypes } from "../../../actions/Machines/Machines"
import { getKitchenAreasSelector, getSupplierItemsSelector } from "../../../reducers/Site/sites"
import { loadKitchenAreaBySiteId } from "../../../actions/Site/sites"
import { loadSupplierItemsToReplace } from "../../../actions/Supplier/supplierItems"
import {
  loadTransformationModes
} from "../../../actions/TransformationModes/TransformationModes"
import { getTransformationModesSelector } from "../../../reducers/TransformationModes/transformationModes"
import { loadReusableProductionSteps } from "../../../actions/Steps/reusableProductionSteps."
import { getReusableProductionStepsSelector } from "../../../reducers/Steps/steps"
import SwitchProductionSteps from "./SwitchProductionSteps"
import { RECIPE_STEPS_TABLE_HEADS } from "../../../utils/recipes"

const ProductionSteps = ({
  toggleEditForm,
  recipe,
  genericSections,
  onCancel,
  onSave,
  isEdition = false
}) => {
  const formRef = useRef()
  const [initialValues, setInitialValues] = useState(null)
  const [switchProductionSteps, setSwitchProductionSteps] = useState(false)

  /*
  * this will not be changed,
  * unlike the initial values that changed every input of the form change
  */
  const [defaultValues, setDefaultValues] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [fieldFocused, setFieldFocused] = useState(false)
  const [deleteHover, setDeleteHover] = useState(null)
  const [stepNumbers, setStepNumbers] = useState({})

  const machineTypes = useSelector(getMachineTypesSelector)
  const kitchenAreas = useSelector(getKitchenAreasSelector)
  const supplierItems = useSelector(getSupplierItemsSelector)
  const transformationModes = useSelector(getTransformationModesSelector)

  const allReusableSteps = useSelector(getReusableProductionStepsSelector)

  const dispatch = useDispatch()

  // load form initial values
  useEffect(() => {
    if (!recipe) return
    const formInitialValues = recipeSectionsFormInitialValues(recipe, true)
    const stepNumbersStorage = computeStepNumbers(formInitialValues)

    setStepNumbers(stepNumbersStorage)
    setInitialValues(formInitialValues)
    setDefaultValues(cloneDeep(formInitialValues))
  }, [recipe, machineTypes])

  // load production steps select data
  useEffect(() => {
    if (isEdition) return
    if (machineTypes.length) return
    dispatch(loadMachineTypes(null, []))

    if (kitchenAreas.length) return
    dispatch(loadKitchenAreaBySiteId())

    if (supplierItems.length) return
    dispatch(loadSupplierItemsToReplace(true))

    if (transformationModes.length) return
    dispatch(loadTransformationModes(true))

    // TODO: use autocomplete search
    dispatch(loadReusableProductionSteps())

  }, [isEdition, machineTypes, kitchenAreas])

  useEffect(() => {
    if (!recipe) return
    const defaultChecked = !!recipe.get("switchProductionSteps")
    setSwitchProductionSteps(defaultChecked)
  }, [recipe])

  const _onRowBlur = () => {
    if (fieldFocused) return
    setHoveredRow(null)
  }

  /**
   * ComponentIndex is used for productionSteps > stepComponents
   * PriorComponentIndex is used for productionSteps > stepComponents > priorSteps
   */
  const _onRowHover = (component, index, parentIndex = null, componentIndex = null, priorComponentIndex = null, stepComponentIndexesList = []) => {
    if (fieldFocused) return
    setHoveredRow({ component, index, parentIndex, componentIndex, priorComponentIndex, stepComponentIndexesList })
  }

  const _onClearFocus = () => setFieldFocused(false)

  const _onFieldFocus = () => setFieldFocused(true)

  const _onFieldBlur = (event, setFieldTouched) => {
    setFieldFocused(false)
    setFieldTouched(event.target.name)
  }

  const _onKeyUp = (event, setFieldTouched) => {
    if (!setFieldTouched) return
    setFieldTouched(event.target.name)
  }

  const _onDeleteHover = (component, index, parentIndex = null) => {
    setDeleteHover({ component, index, parentIndex })
  }

  const _onDeleteBlur = () => setDeleteHover(null)

  const handleSubmit = () => {
    if (!formRef.current) return
    formRef.current.handleSubmit()
  }

  const handleCancel = () => {
    setInitialValues(defaultValues)
    onCancel?.()
  }

  const handleSwitchProductionSteps = (checked) => setSwitchProductionSteps(checked)


  const _onSubmit = (values) => {
    const newValues = { ...values, switchProductionSteps }
    onSave(newValues)
  }

  return (
    <ProductionStepsContainer
      title={recipe?.commercialName}
      onSave={handleSubmit}
      onCancel={handleCancel}
      withTitle={isEdition}
    >
      <div>
        {/* header */}
        <Box
          className="flexRow spaceBetween center"
          sx={{ px: 4, py: isEdition ? 2 : 3 }}
        >
          <SwitchProductionSteps checked={switchProductionSteps} isEdition={isEdition} onCheck={handleSwitchProductionSteps} />
          {/* button */}
          {!isEdition && (
            <Button variant="contained" color="primary" onClick={toggleEditForm}>
              Éditer
            </Button>
          )}
        </Box>

        {/* table body */}
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={RecipeProductionStepsSchema}
          onSubmit={_onSubmit}
          validateOnChange={false}
          enableReinitialize
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            setFieldTouched,
            setValues,
          }) => {
            return (
              <>
                {/* resume banner */}
                <ProductionStepsResumeBanner foodcost={values?.cost} netWeight={values?.netWeight} isEdition={isEdition} />
                {/* table */}
                <ProductionStepsTable>
                  {/* table head */}
                  <ProductionStepsTableHead headers={RECIPE_STEPS_TABLE_HEADS} hasAnyHoveredRow={!!hoveredRow} />
                  <Box className="flexColumn">
                    <Sections
                      recipe={values}
                      sections={values?.sections || []}
                      isEdition={isEdition}
                      onRowBlur={_onRowBlur}
                      onRowHover={_onRowHover}
                      hoveredRow={hoveredRow}
                      genericSections={genericSections}
                      onClearFocus={_onClearFocus}
                      onFieldFocus={_onFieldFocus}
                      onFieldBlur={(e) => _onFieldBlur(e, setFieldTouched)}
                      onKeyUp={(e) => _onKeyUp(e, setFieldTouched)}
                      onDeleteHover={_onDeleteHover}
                      deleteHover={deleteHover}
                      setFieldValue={setFieldValue}
                      onDeleteBlur={_onDeleteBlur}
                      errors={errors}
                      formValues={values}
                      setValues={setValues}
                      machineTypes={machineTypes}
                      kitchenAreas={kitchenAreas}
                      supplierItems={supplierItems}
                      transformationModes={transformationModes}
                      handleChange={handleChange}
                      allReusableSteps={allReusableSteps}
                      stepNumbers={stepNumbers}
                    />
                  </Box>
                  <div style={{ height: "200px" }} />
                </ProductionStepsTable>
              </>
            )
          }}
        </Formik>

      </div>
    </ProductionStepsContainer>
  )
}

export default ProductionSteps