import React from "react"
import AssignmentIcon from "@mui/icons-material/Assignment"

import PageHeaderTitle from "../../components/PageHeaderTitle"
import { Box } from "@mui/material"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"

const ProductPageHeader = ({ subtitle, onBack, withBackButton = false }) => {
    const title = <PageHeaderTitle title="Produits" subtitle={subtitle} icon={<AssignmentIcon />} color="#a00101" />

    if (withBackButton) {
        return (
            <Box sx={{ height: 64 }}>
                <GenericTopBarHeader
                    title={title}
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                />
            </Box>
        )
    }
    return title
}

export default ProductPageHeader