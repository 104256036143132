import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import DispatchTable from "./Product/DispatchTable"
import HubDispatchTable from "./Hub/HubDispatchTable"
import { updateDispatchTypeFilter } from "../../actions/Dispatch/dispatch"

const useClasses = makeStyles(() => ({
    container: {
        width: "100%"
    }
}))

const DispatchToDo = (props) => {
    const {
        hubs=false,
        selectedTypeFilter,
        dispatchDataToDo,
        updateDispatchTypeFilter,
        productTypeOptions
    } = props

    const classes = useClasses()
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const searchFilter = (storage && storage.dispatchToDo)?storage.dispatchToDo:""

    const _onProductTypeChange = (value) => {
        updateDispatchTypeFilter(value)
    }

    return (
        <div className={classes.container}>
            {
                !hubs &&
                <DispatchTable
                    tableType="todo"
                    dispatchData={dispatchDataToDo}
                    selectedType={selectedTypeFilter}
                    onProductTypeChange={_onProductTypeChange}
                    searchFilter={searchFilter}
                    storageIndex="dispatchToDo"
                    productTypeOptions={productTypeOptions}
                />
            }
            {
                hubs &&
                <HubDispatchTable
                    canOpen={false}
                    tableType="error"
                    dispatchData={dispatchDataToDo}
                    selectedType={selectedTypeFilter}
                    onProductTypeChange={_onProductTypeChange}
                    searchFilter={searchFilter}
                    storageIndex="dispatchToDo"
                />
            }
        </div>
    )
}

export default connect((state) => {
    return {
        date: state.dispatch.date,
        dispatchDataToDo: state.dispatch.dispatchDataToDo,
        selectedTypeFilter: state.dispatch.selectedTypeFilter,
        productTypeOptions: state.dispatch.productTypeOptions
    }
}, {
    updateDispatchTypeFilter
})(DispatchToDo)
