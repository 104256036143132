import {
  createTransformationMode,
  getTransformationModeById,
  getTransformationModes,
  updateTransformationMode,
} from "../../parseManager/transformationModes/transformationModesManager"
import {
  actionWithLoader,
  getReplacingPath,
  onEnter,
  push,
} from "../Utils/utils"

export function loadTransformationMode(params) {
    return actionWithLoader(async (dispatch) => {
        const id = params ? params.id : ""
        const transformationMode = await getTransformationModeById(id)
    
        dispatch({
            type: "TRANSFORMATION_MODE_LOADED",
            transformationMode
        })
    })
}

export const loadTransformationModes = (filters, order) => {
    return actionWithLoader(async (dispatch) => {

        const transformationModes = await getTransformationModes(filters, true, order)

        dispatch({
            type: "TRANSFORMATION_MODES_LOADED",
            transformationModes
        })
    })
}

export function addTransformationModes(values) {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const transformationModes = state.transformationModes.transformationModes

    const newTransformationMode = await createTransformationMode(values)
    const newTransformationModes = [
      newTransformationMode,
      ...transformationModes,
    ]

    dispatch({
      type: "TRANSFORMATION_MODES_LOADED",
      transformationModes: newTransformationModes,
    })
  })
}

export function editTransformationModes(id, values) {
  return actionWithLoader(async (dispatch, getState) => {
    const state = getState()
    const transformationModes = state.transformationModes.transformationModes

    const updatedTransformationMode = await updateTransformationMode(id, values)

    const newTransformationModes = transformationModes.map((transformationMode) => {
        if (transformationMode.objectId === updatedTransformationMode.objectId) {
            return updatedTransformationMode
        }

        return transformationMode
    })

    dispatch({
      type: "TRANSFORMATION_MODES_LOADED",
      transformationModes: newTransformationModes,
    })
  })
}

export const onEnterTransformationModes = (store) => {
  return onEnter({
    store,
    actionThunk: () => loadTransformationModes(null, { orderBy: "name" }),
  })
}

export function onEnterSingleTransformationMode(store) {
  return onEnter({
    store,
    actionThunk: loadTransformationMode,
    getReplacingPath: getReplacingPath({ needUser: true }),
  })
}

export function showMachineTypes() {
  return push("/settings/production/machines")
}

export function showMachineType(id) {
  return push("/settings/production/machines/" + id + "/models")
}
