import React, { useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box, Card, Stack, Typography } from "@mui/material"

import {
    updateBrandDateAndSite,
    loadDispatchData,
    showHubDispatchInterface,
    showProductDispatchInterface
} from "../../actions/Dispatch/dispatch"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import DispatchHeaderTitle from "./DispatchHeaderTitle"
import { COLORS } from "../../utils"
import DispatchForm from "../../components/Dispatch/DispatchForm"
import { getDispatchBrandSelector, getDispatchDateSelector } from "../../reducers/Dispatch/dispatch"
import { getSitesSelector } from "../../reducers/Site/sites"

const Dispatch = () => {
    const date = useSelector(getDispatchDateSelector)
    const brand = useSelector(getDispatchBrandSelector)
    const sites = useSelector(getSitesSelector)

    const dispatch = useDispatch()

    useLayoutEffect(() => {
        document.title = "Dispatch - KFC"
    }, [])

    const onConfirm = async (values) => {
        dispatch(updateBrandDateAndSite(values))
        await dispatch(loadDispatchData(null, values.typeSubmit))

        if (values.typeSubmit === "hubs") dispatch(showHubDispatchInterface(values.site.objectId))
        // show productsDispatch by site
        if (values.typeSubmit === "products") dispatch(showProductDispatchInterface(values.site.objectId))
    }

    return (
        <div className="flexColumn stretchSelf flex1">
            <GenericTopBarHeader
                title={<DispatchHeaderTitle />}
                className="stretchSelf"
            />
            
            <Box className="flexCenter stretchSelf flex1" sx={{ color: COLORS.GREY_SUPPLIER_BACKGROUND, flexGrow: 1  }}>
                <Card sx={{ px: 5, py: 5, minWidth: 400 }} className="flexCenter">
                    <Stack spacing={2.8} alignItems="center">
                        <Typography variant="h6">
                            Sélectionnez le site, le jour de vente, la marque, et le mode de dispatch
                        </Typography>

                        <DispatchForm
                            sites={sites}
                            brand={brand}
                            date={date}
                            onSubmit={onConfirm}
                        />
                    </Stack>
                </Card>
            </Box>
        </div>
    )
}

export default Dispatch
