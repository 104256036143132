import React from "react"
import { Form, Formik } from "formik"
import { MenuItem, Select, Stack, TextField } from "@mui/material"

import { newPackagingSchema } from "../../utils/yupValidators"
import ModalForm from "../../components/form/ModalForm"
import ModalActions from "../../components/ModalActions"
import { packagingTypes } from "../../utils/packaging"

const PackagingFormDialog = ({
  onClose,
  open,
  onSave,
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Ajouter un packaging"
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={{ name: ""}}
        onSubmit={onSave}
        validationSchema={newPackagingSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <TextField
                  variant="standard"
                  name="name"
                  label="Nom de la matière"
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                />
                <Select
                  labelId="type"
                  variant="standard"
                  name="type"
                  label="Type"
                  value={values.type}
                  onChange={handleChange}
                >
                  {packagingTypes.map(type => (
                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                  ))}
                </Select>
              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingFormDialog
