import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import { StockZoneSchema } from "../../utils/yupValidators"
import moment from "moment"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import { stockZoneInitialValues } from "../../actions/Utils/utils"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 460
        }
    },
    formControl: {
        marginLeft: "unset",
        width: 316,
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14,
            lineHeight: "11px"
        },
        "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            color: COLORS.BLACK,
            height: 20,
            transform: "none",
            position: "relative",
            "&.single-label": {
                height: 30,
                display: "flex",
                alignItems: "center"
            }
        }
    },
    dialogTitle: {
        paddingLeft: 26,
        paddingTop: 26
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    dialogContent: {
        padding: "0  26px"
    },
    rootDialogActions: {
        justifyContent: "space-between",
        paddingLeft: 26,
        paddingRight: 30,
        marginBottom: 15,
        marginTop: 55
    }
}))

export const SiteEditCreate = (props) => {
    const {
        onClose,
        isCreating,
        onSave,
        stockZone
    } = props

    const classes = useStyles()

    const submitForm = (values) => {
        stockZone ?
            onSave(stockZone.objectId, values) :
            onSave(values)

        onClose()
    }

    const initialValues =  stockZoneInitialValues(stockZone)

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={initialValues}
            validationSchema={StockZoneSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isCreating}
                            className={classes.dialog}
                            maxWidth="md"
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>
                                    {
                                        stockZone ? "EDITER" : "AJOUTER"
                                    } UNE ZONE DE STOCKAGE
                                </span>
                            </DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel>Nom de la zone de stockage</InputLabel>
                                    <TextField
                                        name="name"
                                        variant="standard"
                                        inputProps={{
                                            placeHolder:"BOF"
                                        }}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.name?
                                            (
                                                <div className={classes.errorField}>{errors.name}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel>Localisation</InputLabel>
                                    <TextField
                                        name="spot"
                                        variant="standard"
                                        inputProps={{
                                            placeHolder:"Chambre froide 1"
                                        }}
                                        value={values.spot}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.spot?
                                            (
                                                <div className={classes.errorField}>{errors.spot}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel>Température</InputLabel>
                                    <TextField
                                        name="temperature"
                                        variant="standard"
                                        inputProps={{
                                            placeHolder:"9999"
                                        }}
                                        value={values.temperature}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.temperature?
                                            (
                                                <div className={classes.errorField}>{errors.temperature}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel>Nombre d'emplacements</InputLabel>
                                    <TextField
                                        name="storeNumber"
                                        variant="standard"
                                        inputProps={{
                                            placeHolder:"9999"
                                        }}
                                        value={values.storeNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.storeNumber?
                                            (
                                                <div className={classes.errorField}>{errors.storeNumber}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button onClick={onClose}>
                                    ANNULER
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SiteEditCreate
