import { Stack, Chip } from "@mui/material"
import React from "react"
const AdditionalFiltersOptions = ({
	filters,
	selectedFilters,
	onUpdateSelectedFilters,
	visible = true
}) => {

	if (!visible) return null

	return (
		<Stack
			direction="row"
			spacing={2}
			useFlexGap
			sx={{ flexWrap: "wrap", rowGap: 2, paddingTop: "24px" }}
		>
			{filters.map((filter, index) => {
				const isSelected = selectedFilters.some(selectedFilter => selectedFilter.key === filter.key)
				return (
					<Chip
						key={index}
						label={filter.label}
						color={isSelected ? "info" : "default"}
						onClick={() => {
							onUpdateSelectedFilters(filter, !isSelected)
						}}
					/>
				)
			}
			)}
		</Stack>
	)
}

export default AdditionalFiltersOptions