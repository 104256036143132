import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Formik, Form, Field } from "formik"
import TextField from "@mui/material/TextField"
import Switch from "@mui/material/Switch"
import {COLORS, KFC_BRANDS, roundCurrency, roundNumber} from "../../utils"
import { getSubcontractorProductResumeSchema } from "../../utils/yupValidators"
import StatusSelect from "../Recipe/StatusSelect"
import TypeSelect from "../Recipe/TypeSelect"
import TvaSelect from "../Recipe/TvaSelect"
import SpicySelect from "../Recipe/SpicySelect"
import InternalTagMultiSelect from "../Recipe/InternalTagMultiSelect"
import { productTagsTypes } from "../../utils/productTagsTypes"
import { ChefSelect } from "../ChefSelect"
import PlanningBrandSelector from "../Planning/PlanningBrandSelector"
import InputLabel from "@mui/material/InputLabel"
import SupplierItemsAutocomplete from "../autoComplete/SupplierItemsAutoComplete"
import FormControl from "@mui/material/FormControl"
import { productType } from "../../utils/dispatchUtils"
import { getPVC, recipePreparations, recipePreparationsBasic } from "../../utils/recipes"
import { getSupplierItemPointer } from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"

import { green, red } from "@mui/material/colors"

export const ProductResumeEdit = (props) => {
    const {
        classes,
        initialValues,
        jsonSubcontractorProduct,
        parseSubcontractorProduct,
        onClose,
        isEditing,
        onSaveProductResume,
        productsTags,
        chefs
    } = props
    
    const [schema, setSchema] = useState(getSubcontractorProductResumeSchema(jsonSubcontractorProduct.status))

    const submitForm = async (values) => {
        if (values.brand !== jsonSubcontractorProduct.brand) {
            values.packagingCost = 0
            values.packaging = null
            values.subPackaging = []

            if (values.preparation && values.preparation !== "1") {
                values.heatingInstructions = KFC_BRANDS.find(elm => elm.name === values.brand).defaultHeatingInstructions
            }
        }

        values.totalCost = roundCurrency(values.subcontractorIngredientCost + values.packagingCost)
        await onSaveProductResume(values)
        onClose()
    }
    const recipeInternalTag = Array.isArray(jsonSubcontractorProduct.internalTag)
        ? jsonSubcontractorProduct.internalTag
        : []
        
    const productTagsTypesEnable = productTagsTypes.filter(productTagType => productTagType.enableValue)
    const newInitialValues = {
        brand: jsonSubcontractorProduct.brand,
        commercialName: jsonSubcontractorProduct.commercialName,
        tva: jsonSubcontractorProduct.tva,
        spicy: jsonSubcontractorProduct.spicy,
        name: parseSubcontractorProduct.get("name"),
        ean: jsonSubcontractorProduct.ean,
        status: jsonSubcontractorProduct.status,
        type: jsonSubcontractorProduct.type,
        isActive: jsonSubcontractorProduct.isActive,
        chef: jsonSubcontractorProduct.chef ? jsonSubcontractorProduct.chef.objectId : "",
        netWeight: initialValues.netWeight,
        subcontractorIngredientCost: initialValues.subcontractorIngredientCost,
        totalCost: initialValues.totalCost,
        packagingCost: Number(initialValues.packagingCost),
        specialInstruction: initialValues.specialInstruction,
        preparation: initialValues.preparation,
        pvc: initialValues.pvc,
        packaging: initialValues.packaging,
        subPackaging: initialValues.subPackaging,
        canExportEtiquettable: initialValues.canExportEtiquettable,
        legalName: jsonSubcontractorProduct.legalName,
    }

    const _onSupplierItemChange = async (selectedSupplierItem, setFieldValue) => {
        setFieldValue("name", selectedSupplierItem ? getSupplierItemPointer(selectedSupplierItem.id) : "")

        if (selectedSupplierItem){
            setFieldValue("netWeight", selectedSupplierItem.netWeight)
            setFieldValue("subcontractorIngredientCost", selectedSupplierItem.units ? roundNumber(selectedSupplierItem.units.stock.price / selectedSupplierItem.units.stock.unity.quantity , 2) : 0)
        } else {
            setFieldValue("subcontractorIngredientCost", 0)
        }
    }

    const _onTypeChange = (values, value, setFieldValue) => {
        setFieldValue("type", value)

        if (values.preparation) {
            const currentRecipeType = productType.find(item => item.value === value)

            let currentPreparation
            if (currentRecipeType === "DRINK")   {
                currentPreparation = recipePreparationsBasic.find(item => item.value === values.preparation)
            }
            else {
                currentPreparation = recipePreparations.find(item => item.value === values.preparation)
            }

            const text = `${currentRecipeType.adj} ${currentRecipeType.label.toLowerCase()} ${currentRecipeType.adj === "Ce" ? currentPreparation.label.toLowerCase() : currentPreparation.labelFem.toLowerCase()}`
            setFieldValue("specialInstruction", text)
        }

        const pvcInfo = getPVC("SUBCONTRACTORPRODUCT", value, values.subcontractorIngredientCost)
        setFieldValue("pvc", pvcInfo.pvc)
    }

    const _onBrandChange = (values, completeBrand, setFieldValue) => {
        setFieldValue("brand", completeBrand.name)
    }
    
    const _onStatusChange = (values, status, setFieldValue) => {
        setSchema(getSubcontractorProductResumeSchema(status))
        setFieldValue("status", status)
    }

    productTagsTypesEnable.forEach(productTagType => {
        newInitialValues[`internalTag${productTagType.value}`] = recipeInternalTag.filter(elem => elem.type === productTagType.value)
            .map(elem => elem.objectId)
    })

    return (
        <Formik
            initialValues={newInitialValues}
            validationSchema={schema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer le résumé du produit</span>
                                <Switch
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        track: values.isActive ? classes.switchTrackChecked : classes.switchTrack,
                                    }}
                                    color="default"
                                    id="status"
                                    name="isActive"
                                    placeholder="Status"
                                    checked={values.isActive}
                                    onChange={handleChange}
                                />
                            </DialogTitle>
                            <DialogContent dividers>
                                <TextField
                                    variant="standard"
                                    className={classes.inputContainer}
                                    id="commercialName"
                                    label="Nom commercial"
                                    name="commercialName"
                                    value={values.commercialName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                                {
                                    touched.commercialName && errors.commercialName
                                        ?
                                        (
                                            <div className={classes.errorField}>{errors.commercialName}</div>
                                        )
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    label="Dénomination légale"
                                    name="legalName"
                                    value={values.legalName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    sx={{ mt: 0.5 }}
                                />
                                <Field
                                    name="name"
                                    placeHolder="Nom"
                                    type="text"
                                    value={values.name}
                                    disabled={jsonSubcontractorProduct.subcontractorCommercialNames.length > 0 ? true : false}
                                >
                                    {({field, meta}) => {
                                        field.onChange = (name) => _onSupplierItemChange(name, setFieldValue)
                                        return (
                                            <SupplierItemsAutocomplete
                                                input={field}
                                                name="name"
                                                placeHolder="Nom"
                                                meta={meta}
                                            />
                                        )
                                    }}
                                </Field>
                                <div>
                                    <FormControl margin="normal" className={classes.statusFormControl}>
                                        <InputLabel>Marque</InputLabel>
                                        <PlanningBrandSelector
                                            name="brand"
                                            brand={KFC_BRANDS.find(elm => elm.name === values.brand)}
                                            onBrandClick={(brand) => _onBrandChange(values, brand, setFieldValue)}
                                        />
                                    </FormControl>
                                </div>
                                <div className={classes.selectsContainer}>
                                    <StatusSelect
                                        classes={classes}
                                        id="status"
                                        name="status"
                                        value={values.status}
                                        onChange={(e) => {
                                            _onStatusChange(values, e.target.value, setFieldValue)
                                        }}
                                    />
                                    <TypeSelect
                                        classes={classes}
                                        name="type"
                                        id="type"
                                        value={values.type}
                                        onChange={(e) => {
                                            _onTypeChange(values, e.target.value, setFieldValue)
                                        }}
                                    />
                                    <TvaSelect
                                        classes={classes}
                                        name="tva"
                                        id="tva"
                                        value={values.tva}
                                        onChange={handleChange}
                                    />
                                    {
                                        touched.tva && errors.tva
                                            ?
                                            (
                                                <div className={classes.errorField}>{errors.tva}</div>
                                            )
                                            : null
                                    }
                                    <SpicySelect
                                        classes={classes}
                                        name="spicy"
                                        id="spicy"
                                        value={values.spicy}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        variant="standard"
                                        className={classes.eanInputContainer}
                                        id="ean"
                                        label="EAN"
                                        name="ean"
                                        value={values.ean}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        touched.ean && errors.ean
                                            ?
                                            (
                                                <div className={classes.errorField}>{errors.ean}</div>
                                            )
                                            : null
                                    }
                                </div>
                                <div>
                                    <ChefSelect
                                        id="chef"
                                        label="chef"
                                        name="chef"
                                        value={values.chef}
                                        chefs={chefs}
                                        onChange={handleChange}
                                        classes={classes}
                                    />
                                </div>
                                {
                                    productTagsTypesEnable.map((productTagsType) =>
                                        <InternalTagMultiSelect
                                            label={`Tags ${productTagsType.label}`}
                                            name={`internalTag${productTagsType.value}`}
                                            id={`${productTagsType.value}`}
                                            key={productTagsType.value}
                                            productsTags={productsTags.filter(elem => elem.get("type") === productTagsType.value)}
                                            classes={classes}
                                            value={values[`internalTag${productTagsType.value}`]}
                                            onChange={handleChange}
                                        />
                                    )
                                }
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    onClick={onClose}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Sauvegarder
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = {
    rootDialogActions: {
        justifyContent: "space-between"
    },
    inputContainer: {
        marginTop: 16
    },
    eanInputContainer: {
        composes: "$inputContainer",
        width: 160
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: "0px 4px 4px 4px"
    },
    input: {
        display: "flex",
        padding: 0,
        height: "100%"
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
    },
    switchFormControlLabel: {
      marginLeft: "unset",
    },
    statusFormControl: {
        width: 160,
        marginLeft: "unset",
        marginTop: 16
    },
    typeFormControl: {
        width: 160,
        marginLeft: "unset",
        marginTop: 16,
    },
    formControl: {
        marginLeft: "unset",
        width: "100%",
        marginTop: 16
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 400,
        margin: "5px 0px 0px 0px",
        fontSize: "1.3em"
    },
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: green[400]
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: green[400],
    },
    selectsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    errorField: {
        color: COLORS.RED
    },
    logo: {
        width: 16,
        marginRight: 10
    }
}

export default withStyles(styles)(ProductResumeEdit)
