import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"

import { COLORS } from "../../utils"
import {
    pxTOvw,
    isInternCapacityIsOk
} from "../../utils/planning"

const useStyles = makeStyles(() => ({
    productionPlanningInfoColumn: {
        color: COLORS.DARK_GREY,
        width: pxTOvw((window.innerWidth - 118.4) / 7),
        fontSize: 12,
        lineHeight: "130%",
        padding: "12px 18px 12px 12px",
        backgroundColor: COLORS.WHITE,
        border: `0.5px solid ${COLORS.MEDIUM_GREY}`
    },
    infoNumber: {
        marginLeft: "auto"
    },
    divider: {
        width: "100%",
        height: 1,
        backgroundColor: COLORS.MEDIUM_GREY,
        marginTop: 5,
        marginBottom: 5
    },
    productionPlanningInfoInputContainer: {
        marginLeft: "auto"
    },
    productionPlanningInfoInput: {
        width: 50
    },
    productionPlanningInfoInputProps: {
        height: 5,
        fontSize: 12,
        textAlign: "right"
    },
    productionPlanningInfoInputTitle: {
        paddingTop: 3
    },
    isOkTest: {
        display: "flex",
        color: COLORS.DARK_GREY,
    },
    isError: {
        display: "flex",
        color: COLORS.RED
    }
}))

const ProductionPlanningSectionInfos = (props) => {
    const {
        sectionData,
        product,
        canEdit,
        updateInternCapacity,
        updateSectionCapacity,
        type = "PRODUCTION"
    } = props

    const classes = useStyles()

    if (!sectionData) return null

    const saveSectionCapacity = (event, product, currentProductTypeData) => {
        if (!event.target.value) return
        const newCurrentProductTypeData = {
            ...currentProductTypeData,
            sectionCapacity: event.target.value
        }
        updateInternCapacity(parseFloat(event.target.value), product, newCurrentProductTypeData)
    }

    return (
        <>
            {
                <div className={classes.productionPlanningInfoColumn}>
                    <div className="flexRow">
                        Produits internes <div
                            className={classes.infoNumber}>{sectionData.intern}</div>
                    </div>
                    <div className="flexRow">
                        Produits ST <div
                            className={classes.infoNumber}>{sectionData.extern}</div>
                    </div>
                    <div className={classes.divider}></div>
                    {
                        type === "PRODUCTION" &&
                        <>
                            <div className="flexRow">
                                <div className={classes.productionPlanningInfoInputTitle}>
                                    Capacité interne
                                </div>
                                <div className={classes.productionPlanningInfoInputContainer}>
                                    {
                                        canEdit ?
                                            (
                                                <TextField
                                                    variant="standard"
                                                    type="number"
                                                    value={sectionData.sectionCapacity ? sectionData.sectionCapacity : ""}
                                                    className={classes.productionPlanningInfoInput}
                                                    inputProps={{
                                                        className: classes.productionPlanningInfoInputProps,
                                                        inputprops: { min: 0 }
                                                    }}
                                                    onChange={(event) => {
                                                        updateSectionCapacity(event, product, sectionData)
                                                    }}
                                                    onBlur={(event) => {
                                                        saveSectionCapacity(event, product, sectionData)
                                                    }}
                                                />
                                            )
                                            :
                                            (
                                                <div>
                                                    {sectionData.sectionCapacity}
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                            <div className={classes.divider}></div>
                        </>
                    }
                    <div
                        className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}>
                        Prévu interne <div
                            className={classes.infoNumber}>{sectionData.internProd}</div>
                    </div>
                    <div className="flexRow">
                        Prévu externe <div
                            className={classes.infoNumber}>{sectionData.externProd}</div>
                    </div>
                    <div className="flexRow">
                        Prévu total <div
                            className={classes.infoNumber}>{sectionData.externProd + sectionData.internProd}</div>
                    </div>
                    {/* New indicators */}
                    {
                        type === "PRODUCTION" &&
                        <>
                            <div
                                className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                            >
                                Nb diff 1
                                <div className={classes.infoNumber}>{sectionData.difficulty1}</div>
                            </div>
                            <div
                                className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                            >
                                Nb diff 2
                                <div className={classes.infoNumber}>{sectionData.difficulty2}</div>
                            </div>
                            <div
                                className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                            >
                                Nb diff 3
                                <div className={classes.infoNumber}>{sectionData.difficulty3}</div>
                            </div>
                            <div
                                className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                            >
                                Moyenne diff
                                <div className={classes.infoNumber}>{sectionData.averageDifficulty}</div>
                            </div>
                        </>
                    }
                    <div
                        className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                    >
                        Somme gestes
                        <div className={classes.infoNumber}>{sectionData.sumGesters}</div>
                    </div>
                    {
                        type === "PACKAGING" &&
                        <div
                            className={isInternCapacityIsOk(sectionData.sectionCapacity, sectionData.internProd) ? classes.isOkTest : classes.isError}
                        >
                            Somme pots
                            <div className={classes.infoNumber}>{sectionData.sumPots}</div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default ProductionPlanningSectionInfos
