import React, { useState, useEffect, useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS, roundNumber } from "../../../utils"
import IconButton from "@mui/material/IconButton"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Stack } from "@mui/material"
import { useSelector, useDispatch, batch } from "react-redux"
import { AddShoppingCart } from "@mui/icons-material"
import ProductsToReceive from "./ProductsToReceive"
import ProductsReceived from "./ProductsReceived"
import {
    showOrderList,
    addSupplierItemToOrderReception,
    showOrderSupplierItemQuantities,
    showOrderDeliveryNote,
    completeOrderDeliveryNoteUnitPrices, showOrderDeliveryNoteControl, initializeOrderReception, clearSelectedOrderReception, clearOrderCreditNotes, startReceptionTreament,
    onCloseOrderReception,
} from "../../../actions/OrderReception/OrderReception"
import { useCallback } from "react"
import SupplierItemModal from "../../../components/Order/Reception/SupplierItemModal"
import OrderReceptionCreditNote from "./OrderReceptionCreditNote"
import { duplicateOrderAction, showOrdersList } from "../../../actions/Orders/Orders"
import {getOrderTotalAmount, STORAGE_ORDER_RECEPTION_KEY} from "../../../utils/orderReception"
import { showBillingAndCreditNotesList } from "../../../actions/BillingCreditNotes/BillingCreditNotes"
import OrderReceptionLots from "./OrderReceptionLots"
import { StyledMainContainer } from "../../ProductionStepExecutions/styledComponents"
import {CollapsibleButton} from "./CollapsibleButton"
import ActionFooter from "../../../components/common/ActionFooter"
import SingleOrderReceptionFooter from "./SingleOrderReceptionFooter"
import OrderReceptionResume from "./OrderReceptionResume"
import OrderReceptionHeader from "./OrderReceptionHeader"
import {initializeDeliveryNoteUnitPrices} from "../../../parseManager/orders/parseOrdersManager"

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    tab: {
        paddingLeft: 45,
        paddingRight: 45,
    },
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    headerBloc: {
        flex: "0 0 72px"
    },
    tabsContainer: {
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    lotsTab: {
        marginLeft: "auto"
    },
    btnContainer: {
        display: "flex",
        alignItems: "center"
    },
    lastTab: {
        marginLeft: "auto"
    },
    floatingButton: {
        position: "absolute",
        bottom: 20,
        left: 20
    },
    firstBtn: {
        marginRight: 20
    },
    creditNotesTab: {
        marginRight: 0
    }
})

export const SingleOrderReception = () => {
    const localStorage = window.localStorage
    const navigationHistory = JSON.parse(localStorage.getItem("singleOrderReceptionNavigationHistory")) || {}
    const lastPath = navigationHistory.lastPath || ""

    const classes = useStyles()
    const dispatch = useDispatch()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const parseSupplier = useSelector(state => state.orderReception.parseSupplier)
    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)
    const creditNotes = useSelector(state => state.orderReception.creditNotes)
    const lots = useSelector(state => state.orderReception.lots)
    const orderType = useSelector(state => state.billingCreditNotes.orderType)
    const ordersBySites = useSelector(state => state.billingCreditNotes.sites)
    const [showSupplierItemModal, setShowSupplierItemModal] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [articlesTodo, setArticlesTodo] = useState([])
    const [articlesDone, setArticlesDone] = useState([])
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        if (!orderReception) return
        if (orderReception.supplierItems) {
            setArticlesTodo(orderReception.supplierItems.filter(order => order.receptionStatus === "TODO"))
            setArticlesDone(orderReception.supplierItems.filter(order => order.receptionStatus === "DONE"))
        }

        const selectedOrderReceptionIdStorage = JSON.parse(localStorage.getItem(STORAGE_ORDER_RECEPTION_KEY))

        if (selectedOrderReceptionIdStorage && orderReception.objectId === selectedOrderReceptionIdStorage) {
            setCanEdit(true)
        } else {
            localStorage.removeItem(STORAGE_ORDER_RECEPTION_KEY)
        }

    }, [orderReception])

    useEffect(() => {
        if (orderType && orderType === "CREDIT_NOTE") {
            setTabValue(3)
        }
    }, [orderType])

    const onTabChange = (e, tabValue) => {
        setTabValue(tabValue)
    }

    const onEditItem = useCallback((orderSupplierItemId) => {
        if (canEdit || orderReception.receptionStatus === "TODO") return
        dispatch(showOrderSupplierItemQuantities(site.objectId, date, orderReception.objectId, orderSupplierItemId))
    }, [orderReception, canEdit])

    const onClickReturn = useCallback(() => {
        if (orderType && orderType === "CREDIT_NOTE") {
            const sitesIds = ordersBySites ? ordersBySites.map((site) => site.objectId) : []
            batch(() => {
                dispatch(clearOrderCreditNotes())
                dispatch(clearSelectedOrderReception())
                dispatch(showBillingAndCreditNotesList(sitesIds))
            })
            return
        }

        localStorage.setItem("useStoredDataGridFilters", "1")
        if (!canEdit) {
            dispatch(showOrderList(site.objectId, date))
            return
        }

        dispatch(showOrdersList(site.objectId))
        localStorage.removeItem(STORAGE_ORDER_RECEPTION_KEY)
    }, [site, date, orderReception, canEdit])

    const addSupplierItem = useCallback((supplierItemId) => {
        dispatch(addSupplierItemToOrderReception(orderReception.objectId, supplierItemId))
    }, [orderReception])

    const handleDeliveryNote = useCallback(async () => {
        if (!orderReception) return
        dispatch(showOrderDeliveryNote(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const handleDeliveryNoteControl = useCallback(async () => {
        await dispatch(completeOrderDeliveryNoteUnitPrices(orderReception))
        dispatch(showOrderDeliveryNoteControl(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const duplicateOrder = useCallback(() => {
        dispatch(duplicateOrderAction(orderReception.objectId, site.objectId))
    }, [orderReception, site])

    const moreMenus = useMemo(() => {
        if (lastPath.split("/").at(0) === "orders") {
                return [
                    {
                        label: "Dupliquer la commande",
                        onClick: duplicateOrder,
                        isVisible: !!orderReception
                    }
                ].filter(({ isVisible }) => isVisible)
        }
        return []
    }, [lastPath, orderReception])

    const onInitializeOrderReception = useCallback((orderSupplierItemId) => {
        if (canEdit) return
        dispatch(initializeOrderReception(orderReception.objectId, orderSupplierItemId))
    }, [orderReception, canEdit])


    const startTreatReception = () => {
        dispatch(startReceptionTreament(orderReception.objectId))
    }

    const handleCloseReception = async () => {
        /** use to setup delivery note unit prices for order total amount **/
        const updatedOrder = await initializeDeliveryNoteUnitPrices(orderReception.objectId)

        await dispatch(onCloseOrderReception({
            orderReceptionId: updatedOrder.objectId,
            totalAmount: roundNumber(getOrderTotalAmount(updatedOrder), 2)
        }))
    }

    return (
        <div className={classes.wrapper}>
            <OrderReceptionHeader onBack={onClickReturn} />
            <StyledMainContainer> {/** for the fixed header */}
                <Stack>
                    <Stack sx={{ p: 3 }} gap={3}>
                        <OrderReceptionResume />
                        <Tabs
                            value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                            onChange={onTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            sx={{
                                borderBottom: "1px solid #E6E6E6",
                                "& .MuiTab-root": {
                                    padding: "16px 24px"
                                },
                                marginTop: "8px"
                            }}
                        >
                            <Tab label={`produits à réceptionner (${articlesTodo.length})`}  classes={{ root: classes.tab }} />
                            <Tab label={`produits réceptionnés (${articlesDone.length})`} classes={{ root: classes.tab }} />
                            <Tab classes={{ root: classes.tab }} label={`Lots créés (${lots && lots.length})`} />
                            <Tab classes={{ root: classes.tab }} label={`avoirs et réclamations (${creditNotes.length})`} />
                        </Tabs>
                        <Stack spacing={4} pb={"86px"}>
                            {
                                tabValue === 0 &&
                                <ProductsToReceive
                                    orderSupplierItems={articlesTodo}
                                    onEditItem={onEditItem}
                                />
                            }

                            {
                                tabValue === 1 &&
                                <ProductsReceived
                                    articles={articlesDone}
                                    receptionStatus={orderReception && orderReception.receptionStatus}
                                    orderReception={orderReception}
                                    site={site}
                                    date={date}
                                    onInitializeOrderReception={onInitializeOrderReception}
                                    canEdit={canEdit}
                                    canInitialize={orderReception && orderReception.receptionStatus === "IN_PROGRESS"}
                                />
                            }
                            {
                                tabValue === 2 &&
                                <OrderReceptionLots lots={lots} />
                            }
                            {
                                tabValue === 3 &&
                                <OrderReceptionCreditNote
                                creditNotes={creditNotes}
                                supplierItems={orderReception?.supplierItems}
                                />
                            }
                        </Stack>
                        {/* verified BL */}
                        {!orderReception?.deliveryNote && (
                            <Stack spacing={4}>
                                {!canEdit && orderReception && orderReception.receptionStatus === "IN_PROGRESS" &&
                                    <CollapsibleButton>
                                        <IconButton
                                        color="primary"
                                        onClick={() => setShowSupplierItemModal(true)}
                                        size="large">
                                            <AddShoppingCart />
                                        </IconButton>
                                    </CollapsibleButton>
                                }
                            </Stack>
                        )}
                        {orderReception?.receptionStatus !== "DONE" && (
                            <ActionFooter
                                rightAction={(
                                    <SingleOrderReceptionFooter
                                        orderReception={orderReception}
                                        canEdit={canEdit}
                                        articlesTodo={articlesTodo}
                                        moreMenus={moreMenus}
                                        startTreatReception={startTreatReception}
                                        handleDeliveryNote={handleDeliveryNote}
                                        handleDeliveryNoteControl={handleDeliveryNoteControl}
                                        onDeliveryNoteControl={handleDeliveryNote}
                                        onCloseReception={handleCloseReception}
                                    />
                                )}
                            />
                        )}
                        {
                            showSupplierItemModal &&
                            <SupplierItemModal
                                addSupplierItem={addSupplierItem}
                                supplier={parseSupplier}
                                onClose={() => setShowSupplierItemModal(false)}
                            />
                        }
                    </Stack>
                </Stack>
            </StyledMainContainer>
        </div>
    )
}
