import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Formik, Form } from "formik"
import MUITextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { COLORS } from "../../utils"
import {
  creationInitialValues,
  supplierItemTypes,
  productTypeToAdd
} from "../../utils/supplierItemUtils"
import { SupplierItemCreationSchema } from "../../utils/yupValidators"
import { CircularProgress, MenuItem, Select, TextField } from "@mui/material"
import { loadCommercialNames } from "../../actions/Ingredients/Internal/CommercialsNames"
import { useDispatch, useSelector } from "react-redux"
import ModalForm from "../form/ModalForm"
import ModalActions from "../ModalActions"
import clsx from "clsx"
import { getProductTypes } from "../../../src/parseManager/products/productTypeManager"

const useStyles = makeStyles({
  dialog: {
    "& .MuiDialog-paper": {
      width: 500,
    },
  },
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      fontSize: 16,
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      height: 20,
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  formControlDisabled: {
    opacity: 0.4,
    pointerEvents: "none",
  },
  errorField: {
    color: COLORS.RED,
    marginTop: 7,
  },
  textField: {
    marginTop: 7,
  },
})

export const SupplierItemCreate = (props) => {
  const {
    onClose,
    open,
    onSaveSupplierItem,
    supplier
  } = props

  const [commercialName, setCommercialName] = useState("")
  const [supplierItemProductTypes, setSupplierItemProductTypes] = useState([])
  const dispatch = useDispatch()
  const commercialNames = useSelector(
    (state) => state.commercialNames.commercialNamesList
  )
  const loading = useSelector((state) => state.app.loading)
  const classes = useStyles()

  useEffect(() => {
    const fetchProductTypes = async () => {
      setSupplierItemProductTypes(await getProductTypes())
    }
    fetchProductTypes()
  }, [])


  const _handleTypeChange = (e) => {
    if (e.target.value === supplierItemTypes.RAW_MATERIAL.value) {
      dispatch(loadCommercialNames())
    }
  }

  const submitForm = async (values) => {
    const newValues = { ...values, commercialName }
    await onSaveSupplierItem(newValues)
    onClose()
  }

  const renderProductTypeOptions = () => {
    return supplierItemProductTypes.map((el, i) => (
      <MenuItem key={i} value={el.kfcName}>
        {el.label}
      </MenuItem>
    ))
  }

  const renderProductTypes = () => {
    return productTypeToAdd.map((el, i) => (
      <MenuItem key={i} value={el.value}>
        {el.label}
      </MenuItem>
    ))
  }

  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Créer un article fournisseur"
    >
      <Formik
        initialValues={creationInitialValues(supplier)}
        validationSchema={SupplierItemCreationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
          return (
            <Form>
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel className={classes.formLabel}>
                  Nom de l'article
                </InputLabel>
                <TextField
                  variant="standard"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name ? (
                  <div className={classes.errorField}>{errors.name}</div>
                ) : null}
              </FormControl>
              <FormControl
                variant="standard"
                className={clsx(
                  classes.formControl,
                  classes.formControlDisabled
                )}
              >
                <InputLabel className={classes.formLabel}>
                  Fournisseur
                </InputLabel>
                <TextField
                  variant="standard"
                  name="supplier"
                  value={values.supplier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.supplier ? (
                  <div className={classes.errorField}>{errors.supplier}</div>
                ) : null}
              </FormControl>

              <FormControl
                variant="standard"
                className={classes.selectFormControl}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  variant="standard"
                  name="type"
                  value={values.type}
                  onChange={(e) => {
                    handleChange(e)
                    _handleTypeChange(e)
                  }}
                  label="Type"
                >
                  {Object.keys(supplierItemTypes).map((key) => (
                    <MenuItem
                      index={supplierItemTypes[key].key}
                      key={supplierItemTypes[key].key}
                      value={supplierItemTypes[key].value}
                    >
                      {supplierItemTypes[key].label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type ? (
                  <div className={classes.errorField}>{errors.type}</div>
                ) : null}
              </FormControl>

              {values.type === "PACKAGING_CONSUMABLE" &&
                <FormControl
                  variant="standard"
                  className={classes.selectFormControl}
                >
                  <>
                    <InputLabel>Type de produit</InputLabel>
                    <Select
                      variant="standard"
                      name="productType"
                      value={values.productType}
                      onChange={handleChange}
                    >
                      {renderProductTypes()}
                    </Select>
                    {errors.productType ? (
                      <div className={classes.errorField}>{errors.productType}</div>
                    ) : null}
                  </>
                </FormControl>
              }

              {values.type === supplierItemTypes.RAW_MATERIAL.value && (
                <FormControl
                  variant="standard"
                  className={classes.selectFormControl}
                >
                  <Autocomplete
                    id="commercialName"
                    name="commercialName"
                    options={commercialNames}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => value && setCommercialName(value)}
                    loading={loading}
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        variant="standard"
                        onChange={handleChange}
                        onBlur={handleChange}
                        label="Ingredient"
                        value={values.commercialName}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading && commercialNames.length === 0 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              {values.type === supplierItemTypes.SALABLE_PRODUCT.value && (
                <FormControl
                  variant="standard"
                  className={classes.selectFormControl}
                >
                  <InputLabel>Type de produit</InputLabel>
                  <Select
                    variant="standard"
                    name="productType"
                    value={values.productType}
                    onChange={handleChange}
                  >
                    {renderProductTypeOptions()}
                  </Select>
                  {errors.productType ? (
                    <div className={classes.errorField}>
                      {errors.productType}
                    </div>
                  ) : null}
                </FormControl>
              )}
              <ModalActions onCancel={onClose} onSubmit={handleSubmit} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default SupplierItemCreate
