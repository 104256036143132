import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { Form, Formik } from "formik"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { Box, FormControl, FormHelperText, InputLabel, Select, TextField } from "@mui/material"

import {
    createOrUpdatePackaging,
    showPackaging
} from "../../actions/Packaging/packaging"
import {
    showPackagingResources
} from "../../actions/Products/Products"
import { packagingFormInitialValues } from "../../actions/Utils/utils"
import { getPackagingCategoriesSelector, getPackagingSelector, getPackagingTypesSelector } from "../../reducers/Packaging/packagingList"

import { getBrandsOptions } from "../../utils"
import {packagingNature} from "../../utils/packaging"
import { packagingSchema } from "../../utils/yupValidators"
import { formatSupplierItemsAutocompleteOptions } from "../../utils/supplierItemUtils"
import { searchPackagingSupplierItems } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import SearchableAutocomplete from "../../components/form/SearchableAutocomplete"

const sx = {
    select: {
        minWidth: 120
    },
}

const PackagingForm = ({
    location
}) => {
    const [searchedSupplierItems, setSearchedSupplierItems] = useState([])

    const dispatch = useDispatch()

    const packaging = useSelector(getPackagingSelector)
    const types = useSelector(getPackagingTypesSelector)
    const categories = useSelector(getPackagingCategoriesSelector)

    const handleClosePage = () => {
        if (location && location.state && location.state.returnPath === "readOnly"){
            dispatch(showPackaging(packaging.id))
            return
        }
        dispatch(showPackagingResources())
    }

    const handleClearSupplierItemSearch = () => {
        setSearchedSupplierItems([])
    }

    const handleSearchSupplierItem = async (search) => {
        const supplierItems = await searchPackagingSupplierItems(search)
        setSearchedSupplierItems(supplierItems)
    }

    const handleSubmit = (values) => {
        dispatch(createOrUpdatePackaging(values, packaging))
    }

    return (
        <Box>
            {/* close button */}
            <div className="flexRow justifyEnd">
                <IconButton
                    aria-label="Fermer"
                    onClick={handleClosePage}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </div>
            {/* form */}
            <Box sx={{ pl: 3, pr: 6 }}>
                <Formik
                    initialValues={packagingFormInitialValues(packaging)}
                    onSubmit={handleSubmit}
                    validationSchema={packagingSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {({ values, handleChange, errors, setFieldValue }) => {
                        return (
                            <Form>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="reference"
                                            label="Référence"
                                            value={values.reference}
                                            error={!!errors.reference}
                                            helperText={errors.reference}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="name"
                                            label="Nom"
                                            value={values.name}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="price"
                                            label="Prix"
                                            value={values.price}
                                            type="number"
                                            error={!!errors.price}
                                            helperText={errors.price}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="quantity"
                                            label="Q/Carton"
                                            value={values.quantity}
                                            type="number"
                                            error={!!errors.quantity}
                                            helperText={errors.quantity}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" className="flexColumn alignSelf" error={!!errors.type}>
                                        <InputLabel id="type">Type</InputLabel>
                                        <Select
                                            labelId="type"
                                            variant="standard"
                                            name="type"
                                            label="Type"
                                            value={values.type}
                                            onChange={handleChange}
                                            sx={sx.select}
                                        >
                                            {types.map(type => (
                                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.type}</FormHelperText>
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf"  error={!!errors.type}>
                                        <InputLabel id="nature">Nature du packaging</InputLabel>
                                        <Select
                                            labelId="nature"
                                            variant="standard"
                                            name="nature"
                                            label="Nature du packaging"
                                            value={values.nature}
                                            onChange={handleChange}
                                            sx={sx.select}
                                        >
                                            {packagingNature.map(type => (
                                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.nature}</FormHelperText>
                                    </FormControl>
            
                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="weight"
                                            label="Poids (kg)"
                                            value={values.weight}
                                            type="number"
                                            error={!!errors.weight}
                                            helperText={errors.weight}
                                            onChange={handleChange}
                                        />
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf" error={!!errors.brands}>
                                        <InputLabel id="brands">Marques</InputLabel>
                                        <Select
                                            labelId="brands"
                                            variant="standard"
                                            name="brands"
                                            label="Marques"
                                            placeholder="Marques"
                                            value={values.brands}
                                            onChange={handleChange}
                                            multiple
                                            sx={sx.select}
                                        >
                                            {getBrandsOptions().map(brand => (
                                                <MenuItem key={brand.value} value={brand.value}>{brand.label}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.brands}</FormHelperText>
                                    </FormControl>
            

                                    <FormControl margin="normal" className="flexColumn alignSelf" error={!!errors.categories}>
                                        <InputLabel id="categories">Catégorie</InputLabel>
                                        <Select
                                            labelId="categories"
                                            variant="standard"
                                            name="categories"
                                            label="Catégorie"
                                            value={values.categories}
                                            onChange={handleChange}
                                            sx={sx.select}
                                        >
                                            {categories.map(type => (
                                                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.categories}</FormHelperText>
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="height"
                                            label="Hauteur&nbsp;(mm)"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            value={values.height}
                                            type="number"
                                            error={!!errors.height}
                                            helperText={errors.height}
                                            onChange={handleChange}
                                        />
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="deepth"
                                            label="Profondeur&nbsp;(mm)"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            value={values.deepth}
                                            type="number"
                                            error={!!errors.deepth}
                                            helperText={errors.deepth}
                                            onChange={handleChange}
                                        />
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <TextField
                                            variant="standard"
                                            name="width"
                                            label="Largeur&nbsp;(mm)"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            value={values.width}
                                            type="number"
                                            error={!!errors.width}
                                            helperText={errors.width}
                                            onChange={handleChange}
                                        />
                                    </FormControl>

                                    <FormControl margin="normal" className="flexColumn alignSelf">
                                        <SearchableAutocomplete
                                            name="supplierItem"
                                            label="Article fournisseur associé"
                                            value={values.supplierItem}
                                            setFieldValue={setFieldValue}
                                            options={formatSupplierItemsAutocompleteOptions(searchedSupplierItems)}
                                            onSearch={handleSearchSupplierItem}
                                            onBlur={handleClearSupplierItemSearch}
                                            error={!!errors.supplierItem}
                                            helperText={errors.supplierItem}
                                            textFieldSx={{ minWidth: 250 }}
                                        />
                                    </FormControl>

                                </Box>
                                <div className="flexRow justifyEnd">
                                    <Button
                                        type="submit"
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Box>
    )

}

export default PackagingForm
