import React from "react"
import { makeStyles } from "@mui/styles"
import OrderCartList from "./OrderCartList"
import OrderCartView from "./OrderCartView"

const useStyles = makeStyles({
    container: {
        height: "100%"
    }
})

const OrderRightPanel = (props) => {
    const {
        mode,
        order,
        selectedCard,
        cart,
        actions,
        selectedSupplier,
        expectedDeliveryDate,
        ordersComing
    } = props
    
    const classes = useStyles()

    return (
        <div className={classes.container}>
            {
                selectedCard
                    ?
                        <OrderCartView
                            selectedCard={selectedCard}
                            cart={cart}
                            actions={actions}
                            ordersComing={ordersComing}
                        />
                    :
                        <OrderCartList
                            mode={mode}
                            order={order}
                            cart={cart}
                            actions={actions}
                            selectedSupplier={selectedSupplier}
                            expectedDeliveryDate={expectedDeliveryDate}
                        />
            }
            
        </div>
    )
}

export default OrderRightPanel