import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import PropTypes from "prop-types"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { COLORS } from "../../../utils"
import clsx from "clsx"

let rows = []

const useClasses = makeStyles(() => ({
  tableMenu: {
    paddingLeft: 20,
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
    fontWeight: "bold",
    "&.is-reusable": {
      backgroundColor: COLORS.PACKAGING_REUSABLE,
    },
    "&.is-disposable": {
      backgroundColor: COLORS.PACKAGING_DISPOSABLE,
    },
    "&.is-capped": {
      backgroundColor: COLORS.PACKAGING_CAPPED,
    },
  },
  smallColumn: {
    composes: "$tableMenu",
    width: 30,
    paddingRight: 10,
    textAlign: "center",
    fontSize: 14,
  },
  sortColumn: {
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  productType: {
    composes: "$tableMenu",
    textTransform: "uppercase",
    width: 100,
    textAlign: "center",
  },
  collapse: {
    visibility: "collapse",
  },
}))

const ReceptionWarehouseEnhancedTableHead = (props) => {
  const { order, orderBy, tableType, productType, collapse, packaging } = props
  const classes = useClasses()

  const getTableCellClasse = (row) => {
    if (row.id === "name") {
      return classes.tableMenu
    }

    if (row.id === "uniqueCode") {
      return classes.productType
    }
    return classes.smallColumn
  }

  if (tableType !== "todo") {
    rows = [
      {
        id: "uniqueCode",
        numeric: false,
        disablePadding: true,
        label: `${productType}s`,
      },
      { id: "name", numeric: false, disablePadding: false, label: "" },
      { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
      { id: "dlc", numeric: false, disablePadding: false, label: "DLC" },
      {
        id: "waiting",
        numeric: false,
        disablePadding: false,
        label: "ATTENDU",
      },
      {
        id: "warehouse",
        numeric: false,
        disablePadding: false,
        label: "ENTREPÔT",
      },
    ]
  } else {
    rows = [
      {
        id: "uniqueCode",
        numeric: false,
        disablePadding: true,
        label: `${productType}s`,
      },
      { id: "name", numeric: false, disablePadding: false, label: "" },
      { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
      { id: "dlc", numeric: false, disablePadding: false, label: "DLC" },
      {
        id: "waiting",
        numeric: false,
        disablePadding: false,
        label: "ENTREPÔT",
      },
    ]
  }

  return (
    <TableHead className={collapse ? classes.collapse : ""}>
      <TableRow>
        {rows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            sortDirection={orderBy === row.id ? order : false}
            className={clsx(
              getTableCellClasse(row),
              `is-${packaging.toLowerCase()}`
            )}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

ReceptionWarehouseEnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default ReceptionWarehouseEnhancedTableHead
