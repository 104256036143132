import React, { useEffect, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBrandBy, COLORS } from "../../utils"
import { loadInventory, closeInventorySnackBar, brandChange, downloadProductStocksInventoryCsv } from "../../actions/Reception/reception"
import InventoryOutputFilter from "../../components/Reception/Inventory/InventoryOutputFilter"
import ReceptionSnackBar from "../../components/Reception/ReceptionSnackBar"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import { makeStyles } from "@mui/styles"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import { HubsInventoryHeader } from "../../components/Reception/Inventory/HubsInventoryHeader"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import { Menu, MenuItem } from "@mui/material"

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	headerBloc: {
		flex: "0 0 64px"
	}
})

const InventoryFilter = () => {
	const classes = useStyles()
	const hubs = useSelector(state => state.inventory.hubs)
	const brand = useSelector(state => state.inventory.brand)
	const inventorySnackBar = useSelector(state => state.inventory.inventorySnackBar)
	const dispatch = useDispatch()
	const [selectedHubs, setHubs] = useState(hubs.map(el => ({
		value: el.objectId,
		label: el.name
	})))
	const [selectedHub, setSelectedHub] = useState("")
	const [selectedBrand, setSelectedBrand] = useState(brand)
	const [anchorEl, setAnchorEl] = useState(null)

	useLayoutEffect(() => {
		document.title = "Inventaire - KFC"

		return () => (document.title = "KFC")
	}, [])

	useEffect(() => {	
		setSelectedBrand(brand)

		const activeHubs = hubs.filter((el) => el.isActive === true)

		setHubs(activeHubs.map(el => ({
			value: el.objectId,
			label: el.name
		})))
	}, [brand])

	const onBrandClick = async (brandSelected) => {
		await dispatch(brandChange(brandSelected.name))
	}

	const onHubChange = (eventHub) => {
		setSelectedHub(eventHub)
	}

	// sort hubs alphabetically
	const sortByLabel = (arr) => {
		return arr.sort((a, b) => a.label.localeCompare(b.label))
	}


	// validate and go next
	const onValidate = (e) => {
		e.preventDefault()

		if (selectedBrand && selectedHub !== "") {
			dispatch(loadInventory(selectedBrand, selectedHub))
			localStorage.setItem("inventoryFilter", JSON.stringify({ brand: selectedBrand, hub: selectedHub }))
		}
	}

	const onExtractInventory = () => {
		dispatch(downloadProductStocksInventoryCsv())
	}

	const handleOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const completeBrand = getBrandBy("name", selectedBrand)
	const open = Boolean(anchorEl)

	return (
		<>
			<div className={classes.headerBloc}>
				<GenericTopBarHeader
					title={<HubsInventoryHeader />}
					rightAction={
						<div>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								onClick={handleOpen}
								size="large">
								<MoreVertIcon />
							</IconButton>
							<Menu
								id={open ? "menu-popover" : undefined}
								open={Boolean(open)}
								anchorEl={anchorEl}
								onClose={handleClose}
								keepMounted
								transformOrigin={{
										vertical: "top",
										horizontal: "center"
								}}
							>
								<MenuItem
										onClick={onExtractInventory}
								>
									Extraire l'inventaire
								</MenuItem>
							</Menu>
						</div>
					}
				/>
			</div>
			<div className={classes.container}>
				{/* Filter*/}
				<InventoryOutputFilter
					hubs={sortByLabel(selectedHubs)}
					brand={completeBrand}
					selectedHub={selectedHub}
					onBrandClick={onBrandClick}
					onHubChange={onHubChange}
					onValidate={onValidate}
					activeBrandsOnly
				/>

				{/* Error snackbar */}
				<ReceptionSnackBar data={inventorySnackBar} onClose={() => dispatch(closeInventorySnackBar)} />
				<HelpButton link={helpLinks.hub} />
			</div>
		</>
	)
}

export default InventoryFilter
