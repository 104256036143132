import {
    createTransformationMode, getTransformationModes
} from "../../parseManager/transformationModes/transformationModesManager"
import { actionWithLoader, push } from "../Utils/utils"

export const addTransformationMode = (values) => {
    return actionWithLoader(async (dispatch) => {
        const newTransformationMode = await createTransformationMode(values)

        dispatch({
            type: "TRANSFORMATION_MODE_ADDED",
            transformationMode: newTransformationMode,
        })
    })
}

export const addTransformationModesToSupplierItem = (values) => {
    return actionWithLoader(async (dispatch) => {
        const newTransformationMode = await createTransformationMode(values)

        dispatch({
            type: "TRANSFORMATION_MODE_ADDED",
            transformationMode: newTransformationMode
        })
    })
}

export const loadTransformationMode = () => {
    return actionWithLoader(async (dispatch) => {
        const transformationModes = await getTransformationModes()

        dispatch({
            type: "TRANSFORMATION_MODES_LOADED",
            transformationModes: transformationModes
        })
    })
}

export function showTransformationModes() {
    return push("/settings/production/transformationModes")
}
