import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { Stack } from "@mui/material"

import DataGrid from "../../components/DataGrid/DataGrid"
import GridThumbnail from "../../components/DataGrid/GridThumbnail"
import { getPackagingsSelector } from "../../reducers/Packaging/packagingList"
import { packagingNature, packagingTypes, packagingCategories } from "../../utils/packaging"
import { ACTIVE_KFC_BRANDS, KFC_BRANDS } from "../../utils"
import { createOrUpdatePackaging, showPackaging } from "../../actions/Packaging/packaging"
import PackagingFormDialog from "./PackagingFormDialog"
import FixedAddButton from "../../components/FixedAddButton"

const columns = [
	{
		key: "id",
		label: "ID",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "text",
			strict: true
		},
		width: 120
	},
	{
		key: "name",
		label: "Nom",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "text",
			fullText: true
		}
	},
	{
		key: "reference",
		label: "Réference",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "text",
			fullText: true
		}
	},
	{
		key: "type",
		label: "Type",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "select",
			options: packagingTypes.map((packagingType) => ({
				key: packagingType.value,
				value: packagingType.label,
				label: packagingType.label
			}))
		}
	},
	{
		key: "nature",
		label: "Nature",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "select",
			options: packagingNature.map((packagingNature) => ({
				key: packagingNature.value,
				value: packagingNature.label,
				label: packagingNature.label
			}))
		}
	},
	{
		key: "category",
		label: "Catégorie",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "select",
			options: packagingCategories.map((packagingCategory) => ({
				key: packagingCategory.value,
				value: packagingCategory.label,
				label: packagingCategory.label
			}))
		}
	},
	{
		key: "brands",
		label: "Marque",
		dataType: "text",
		sortable: false,
		filter: {
			filterable: true,
			type: "select",
			options: ACTIVE_KFC_BRANDS.map((brand) => ({
				key: brand.name,
				value: brand.label,
				label: brand.label
			}))
		},
		render: (brandsToString) => (
			<Stack
				direction="row"
				flexWrap="wrap">
				{brandsToString.split(",").map((brand, index) => {
					const completeBrand = KFC_BRANDS.find(activeBrand => activeBrand.label === brand)
					return (
						<GridThumbnail
							key={index}
							src={completeBrand?.image}
							alt={`${completeBrand?.shortname}_logo`}
						/>
					)
				})}
			</Stack>
		),
		width: 220
	},
	{
		key: "updatedAt",
		label: "Date de modification",
		dataType: "text",
		sortable: true,
		filter: {
			filterable: false
		}
	}
]

const Packagings = () => {
	const [data, setData] = useState([])
	const [openFormDialog, setOpenFormDialog] = useState(false)

	const packagings = useSelector(getPackagingsSelector)

	const dispatch = useDispatch()

	useEffect(() => {
		const newPackagings = packagings.map((packaging) => {
			return {
				id: packaging.id,
				name: packaging.get("name"),
				reference: packaging.get("reference"),
				type: packagingTypes.find(p => p.value === packaging.get("type"))?.label,
				nature: packagingNature.find(p => p.value === packaging.get("nature"))?.label,
				category: packagingCategories.find(p => p.value === packaging.get("categories"))?.label,
				brands: (packaging.get("brands") || []).map((brand) => KFC_BRANDS.find(b => b.name === brand)?.label).join(","),
				updatedAt: dayjs(packaging.updatedAt).format("DD/MM/YYYY"),
			}
		})
		setData(newPackagings)
	}, [packagings])

	const toggleCreate = () => {
		setOpenFormDialog(!openFormDialog)
	}
	const onShowPackaging = (packaging) => {
		dispatch(showPackaging(packaging.id))
	}

	const handleCreatePackaging = (values) => {
		dispatch(createOrUpdatePackaging(values, null, true))
		toggleCreate()
	}

	return (
		<>
			<DataGrid
				title="Packagings"
				columns={columns}
				data={data}
				onRowClick={onShowPackaging}
				withFilters
			/>
			<FixedAddButton color="primary" onClick={toggleCreate} sx={{ left: "initial" }} />

			{/* creation */}
			<PackagingFormDialog
				onClose={toggleCreate}
				open={openFormDialog}
				onSave={handleCreatePackaging}
			/>
		</>
	)
}

export default Packagings