import React from "react"

import { TableHead, TableRow } from "@mui/material"
import { StyledTableCell } from "../../../../../components/styled/StyledTableCell"

const sx = {
  lastColumn: {
    width: 100
  },
}

const KitchenAreasTableHead = ({ withActionColum = false }) => {
  return (
      <TableHead>
        <TableRow>
          <StyledTableCell sx={sx.firstColumn} align="left">Nom de l'atelier </StyledTableCell>
          <StyledTableCell align="left">Ordre dans la marche en avant</StyledTableCell>
          <StyledTableCell align="left">Nombre de machines</StyledTableCell>
          {withActionColum && <StyledTableCell align="left" sx={sx.lastColumn}></StyledTableCell>}
        </TableRow>
      </TableHead>
  )
}

export default KitchenAreasTableHead