import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Snackbar from "@mui/material/Snackbar"
import moment from "moment-timezone"

import WeeklyMealPlanner from "../../components/WeeklyMealPlanner/WeeklyMealPlanner"
import { Typography, Button, Dialog, DialogContent, DialogActions } from "@mui/material"

import { getProductionSchemasSelector } from "../../reducers/ProductionSchemas/productionSchemas"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

import { isMealOpenForSales } from "../../api/mealPlanner"
import { createProductionItemsByProductionSchemas } from "../../actions/Planning/ProductionItem"
import { closePlanningSnackBar } from "../../actions/Planning/Planning"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"

const WeeklyMealPlannerPage = ({ params: { year, week }, router }) => {
    const dispatch = useDispatch()

    const productionSchemas = useSelector(getProductionSchemasSelector) // we need to retrieve the full object for the cart, not just the options
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)
    const planningSnackBar = useSelector(state => state.planning.planningSnackBar)


    // --------- CURRENT USER SELECTION (MEALS AND PRODUCTION SCHEMAS)------------//
    const [mealsSelectionIds, setMealsSelectionIds] = useState([])
    const [currentProductionSchema, setCurrentProductionSchema] = useState(null)
    const [selectionBySections, setSelectionSections] = useState(new Map())
    const [formattedSelectionBySections, setFormattedSelectionSections] = useState([])

    // ---------------------------------MODALS ----------------------------------//
    const [productAddingModalOpen, setProductAddingModalOpen] = useState(false)
    const [productAlreadyInSalesModalOpen, setProductAlreadyInSalesModalOpen] = useState(false)

    const productionSchemasOptions = useMemo(() => {
        return productionSchemas.map((schema) => ({ key: schema.objectId, label: schema.name }))
    }, [productionSchemas])

    const dateRange = useMemo(() => {
        const startDate = moment().year(year).isoWeek(week).startOf("isoweek").format("YYYY-MM-DD")
        const endDate = moment().year(year).isoWeek(week).endOf("isoweek").format("YYYY-MM-DD")
        return { start: startDate, end: endDate }
    }, [week, year])

    const _closeProductAddingModal = () => setProductAddingModalOpen(false)
    const _closeProductInSalesModal = () => setProductAlreadyInSalesModalOpen(false)

    const _updateCurrentProductionSchema = (e) => setCurrentProductionSchema(e.target.value)

    const _onSaveItemsMealPlanner = async () => {
        const selectedDate = dateRange.start
        dispatch(createProductionItemsByProductionSchemas(selectionBySections, selectedDate))
    }

    useEffect(() => {
        const formattedSelection = []
        for (const [productionSchemaId, products] of selectionBySections.entries()) {
            formattedSelection.push({
                productionSchemaId,
                products
            })
        }
        setFormattedSelectionSections(formattedSelection)
    }, [selectionBySections])

    const _addMealToSelectionSections = (meal) => {
        const updatedSelectionBySections = new Map(selectionBySections)
        if (!updatedSelectionBySections.has(currentProductionSchema)) {
            updatedSelectionBySections.set(currentProductionSchema, [meal])
        }
        else {
            const currentProductionSchemaSelection = updatedSelectionBySections.get(currentProductionSchema)
            currentProductionSchemaSelection.push(meal)
            updatedSelectionBySections.set(currentProductionSchema, currentProductionSchemaSelection)
        }
        setSelectionSections(updatedSelectionBySections)
    }

    const _deleteMealFromSelectionSections = (meal) => {
        const updatedSelectionBySections = new Map(selectionBySections)
        for (const [key, meals] of updatedSelectionBySections.entries()) {
            if (meals.includes(meal)) {
                const index = meals.findIndex((m) => m.itemId === meal.itemId)
                meals.splice(index, 1)
                if (meals.length) {
                    updatedSelectionBySections.set(key, meals)
                } else {
                    updatedSelectionBySections.delete(key)
                }
                setSelectionSections(updatedSelectionBySections)
                return
            }
        }
    }

    const _selectMeal = async (meal) => {
        let isAddition = true
        if (mealsSelectionIds.includes(meal.itemId)) {
            isAddition = false
        }

        // ----------------------------------------//
        // ---------CHECK SELECTION VALIDITY-------//
        // ----------------------------------------//
        if (isAddition) {
            if (!currentProductionSchema) {
                setProductAddingModalOpen(true)
                return
            }
            const isMealAvailableForSales = await isMealOpenForSales(meal, dateRange.start, dateRange.end)
            if (!isMealAvailableForSales) {
                setProductAlreadyInSalesModalOpen(true)
                return
            }
        }
        // ----------------------------------------//
        // ---ADD OR DELETE MEAL FROM SELECTION----//
        // ----------------------------------------//
        if (isAddition) {
            _addMealToSelectionSections(meal)
            setMealsSelectionIds((prev) => ([...prev, meal.itemId]))
            return
        }

        _deleteMealFromSelectionSections(meal)
        setMealsSelectionIds((prev) => (prev.filter((id) => id !== meal.itemId)))
    }

    return (
        <>
            <WeeklyMealPlanner
                {...{
                    mealsSelectionIds,
                    currentProductionSchema,
                    productionSchemas
                }}
                selection={formattedSelectionBySections}
                date={dateRange.start}
                dateRange={dateRange}
                filters={{
                    productionSchemasOptions,
                    productTypeOptions
                }}
                actions={{
                    selectProductionSchema: _updateCurrentProductionSchema,
                    clickProduct: _selectMeal,
                    onCancel: router && router.goBack,
                    onSave: _onSaveItemsMealPlanner
                }}
                modalContent={
                    <>
                        <Dialog
                            onClose={_closeProductAddingModal}
                            aria-labelledby="duplicate-supplierItem-dialog"
                            open={productAddingModalOpen}
                        >
                            <DialogContent>
                                <Typography variant={"span"}>
                                    Vous devez sélectionner un schéma de production avant d'ajouter un produit.
                                </Typography>
                            </DialogContent>
                            <DialogActions
                            >
                                <Button
                                    color="primary"
                                    onClick={_closeProductAddingModal}
                                >
                                    FERMER
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/** PRODUCT ALREADY FOR SALES */}
                        <Dialog
                            onClose={_closeProductInSalesModal}
                            aria-labelledby="duplicate-supplierItem-dialog"
                            open={productAlreadyInSalesModalOpen}
                        >
                            <DialogContent>
                                <Typography variant={"span"}>
                                    Vous ne pouvez pas ajouter ce produit car il est déjà présent à la carte cette semaine.
                                </Typography>
                            </DialogContent>
                            <DialogActions
                            >
                                <Button
                                    color="primary"
                                    onClick={_closeProductInSalesModal}
                                >
                                    FERMER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={planningSnackBar.open}
                autoHideDuration={planningSnackBar.duration}
                onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    variant={planningSnackBar.type}
                    message={planningSnackBar.message}
                    onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
                />
            </Snackbar>
        </>
    )

}



export default WeeklyMealPlannerPage
