import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"

const Packaging = Parse.Object.extend("Packaging")

export async function countPackagings() {
    try {
        return await new Parse.Query(Packaging).count()
    }
    catch (e) {
        console.error("parseManager.products.resources.packaging.parsePackagingManager.countPackagings error : ", e)
        return Promise.reject(e)
    }
}

export async function getPackagings() {
    try {
        const packagings = await new Parse.Query(Packaging)
            .notEqualTo("deleted", true)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()

        return packagings ? packagings.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.products.resources.productTag.parseProductTagManager.getPackagins error : ", e)
        return Promise.reject(e)
    }
}

export const getSinglePackaging = async (packagingId, toJSON = false) => {
    try {
        const packaging = await new Parse.Query(Packaging)
            .equalTo("objectId", packagingId)
            .include(["supplierItem", "materials.packagingMaterial"])
            .first()

        if (!packaging) return null
        return toJSON ? packaging.toJSON() : packaging
    }
    catch (e) {
        console.error("parseManager.products.resources.productTag.parseProductTagManager.getSinglePackagin error : ", e)
        return Promise.reject(e)
    }
}
