import React from "react"
import { MenuItem, Select } from "@mui/material"

const PEPackagingLineSelect = ({ onChange, value, name, options = [] }) => {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      variant="standard"
      sx={{ minWidth: 200 }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default PEPackagingLineSelect 