import React, { forwardRef } from "react"
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik"
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch, useSelector } from "react-redux"

import FormikAutocompleteField from "../../../components/form/FormikAutocompleteField"
import { ReportOrderRDSchema } from "../../../utils/yupValidators"
import { getSearchedRecipesSelector } from "../../../reducers/Recipe/recipes"
import { clearSearchRecipesAutocomplete, searchRecipesAutocomplete } from "../../../actions/Recipe/recipes"

const FormikTextField = ({ field, ...props }) => (
  <TextField {...field} {...props} />
)

const getOptionLabel = (recipe) => {
  const name = recipe.name.toLowerCase()
  const label = recipe.uniqueCode ? recipe.uniqueCode + " - " + name : name
  return label
}

const formatOptions = (recipes) => {
  return recipes.map((recipe) => ({
    value: recipe.objectId,
    data: recipe
  }))
}

const initialValues = {
  products: []
}

const ReportOrderRDForm = forwardRef(({ onSubmit }, ref) => {
  const dispatch = useDispatch()

  const searchedRecipes = useSelector(getSearchedRecipesSelector)

  const handleSearchRecipes = async (search) => {
    await dispatch(searchRecipesAutocomplete(search))
  }

  const handleClearProductSearch = () => {
    dispatch(clearSearchRecipesAutocomplete())
  }

  return (
    <Formik
      initialValues={initialValues}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={ReportOrderRDSchema}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FieldArray name="products">
              {({ remove, push }) => (
                <Stack spacing={1}>
                  {values.products.length > 0 &&
                    values.products.map((_, index) => (
                      <div key={index}>
                        <Grid container spacing={4}>
                          <Grid item xs={8}>
                            <Stack spacing={1}>
                              <FormikAutocompleteField
                                name={`products.${index}.product`}
                                label="Produit"
                                setFieldValue={setFieldValue}
                                options={formatOptions(searchedRecipes)}
                                getOptionLabel={getOptionLabel}
                                onSearch={handleSearchRecipes}
                                onBlur={handleClearProductSearch}
                              />
                              <ErrorMessage
                                name={`products.${index}.product`}
                                render={(message) => (
                                  <FormHelperText error>
                                    {message}
                                  </FormHelperText>
                                )}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack spacing={1}>
                              <Field
                                name={`products.${index}.quantity`}
                                component={FormikTextField}
                                label="Quantité"
                                type="number"
                                variant="standard"
                              />
                              <ErrorMessage
                                name={`products.${index}.quantity`}
                                render={(message) => (
                                  <FormHelperText error>
                                    {message}
                                  </FormHelperText>
                                )}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={1} sx={{ position: "relative" }}>
                            <IconButton
                              type="button"
                              onClick={() => remove(index)}
                              sx={{ position: "absolute", bottom: 0 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  <Stack spacing={0}>
                    <ErrorMessage
                      name={"products"}
                      render={(message) => {
                        if (typeof message !== "string") return
                        return <FormHelperText error>{message}</FormHelperText>
                      }}
                    />
                    <div>
                      <Button
                        onClick={() => {
                          push({ product: null, quantity: null })
                        }}
                        variant="text"
                        startIcon={<AddIcon />}
                        sx={{ textTransform: "initial" }}
                      >
                        Ajouter un produit
                      </Button>
                    </div>
                  </Stack>
                </Stack>
              )}
            </FieldArray>
          </Form>
        )
      }}
    </Formik>
  )}
)

export default ReportOrderRDForm
