import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { Box, Modal, Slide, Backdrop } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import EmptyCommentsContainer from "./EmptyCommentsContainer"
import CommentsFormContainer from "./CommentsFormContainer"
import CommentsTimeline from "./CommentsTimeline"
import { getIsMenuOpen } from "../../reducers/Utils/app"

const useStyles = makeStyles({
	drawer: {
		width: "320px",
		position: "absolute",
		right: 0,
		height: "calc(100% - 64px)",
		top: 64,
		left: "unset",
		backgroundColor: "#FFF",
		display: "flex",
		flexDirection: "column",
		outline: "none",
		"& > *": {
			paddingLeft: 24,
			paddingRight: 24
		}
	},
	header: {
		display: "flex",
		alignItems: "end",
		justifyContent: "end",
		paddingBottom: 8,
		paddingTop: 24
	},
	title: {
		color: "#7C7C7C",
		fontSize: 14,
		lineHeight: "22px"
	},
	titleWithBorderBottom: {
		composes: "$title",
		paddingBottom: "8px",
		borderBottom: "1px solid #F0F0F0"
	},
	content: {
		flex: 1,
		overflow: "auto"
	},
	form: {
		display: "flex",
		alignItems: "stretch",
		justifyContent: "stretch",
		boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.15)",
		padding: "16px 24px 24px 24px"
	},
	icon: {
		cursor: "pointer",
		color: "#555555"
	}
})

const CommentsDrawer = ({
	collection = "",
	comments = [],
	onAddComment,
	open,
	onClose,
	menuShown = false
}) => {

	const classes = useStyles()

	const isEmpty = !comments || comments.length === 0

	const isMenuOpen = useSelector(getIsMenuOpen)

	let title = "Commentaires"
	if (comments.length > 1) {
		title = comments.length + " Commentaires"
	}
	if (comments.length === 1) {
		title = "1 Commentaire"
	}

	const sortCommentByLast = (comments) => {
		return comments.sort((a, b) => {
			return b.creation_dt - a.creation_dt
		})
	}

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					sx: {
						top: 64,
						left: !menuShown ? 0 : isMenuOpen ? 260 : 64
					},
				},
			}}
		>
			<Slide direction="left" in={open} mountOnEnter unmountOnExit>
				<Box
					className={classes.drawer}>
					<Box className={classes.header}>
						<CloseIcon className={classes.icon} onClick={onClose} />
					</Box>
					<Box className={isEmpty ? classes.title : classes.titleWithBorderBottom}>
						<span>{title}</span>
					</Box>
					<Box className={classes.content}>
						{isEmpty && <EmptyCommentsContainer />}
						{!isEmpty && <CommentsTimeline comments={sortCommentByLast(comments)} />}
					</Box>
					<Box className={classes.form}>
						<CommentsFormContainer onAddComment={onAddComment} collection={collection} />
					</Box>
				</Box>
			</Slide>
		</Modal>
	)
}

export default CommentsDrawer