import React, { useMemo, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Stack } from "@mui/material"
import PSEPreparation from "../preview/PSEPreparation"
import ProductionStepExecutionLotsTable from "./ProductionStepExecutionLotsTable"
import { StyledErrorMessage, StyledSubStatusTitle } from "../styledComponents"
import ProductionStepExecutionCoolingFormContainer from "./ProductionStepExecutionCoolingFormContainer"
import { getPSEMachinesOptionsSelector, getProductionStepsKitchenAreaSelector } from "../../../reducers/Production/ProductionSteps"
import { loadPSEMachinesOptions } from "../../../actions/ProductionSteps/ProductionStepExecutions"


const ProductionStepExecutionStepCompletion = ({ productionStepExecution, handleSubmit, priorStepData }) => {

	const dispatch = useDispatch()

	const [errorMessage, setErrorMessage] = useState("")

	const transformationType = useMemo(() => productionStepExecution.productionStepSnapshot.transformation, [productionStepExecution])

	const title = useMemo(() => {
		if (transformationType === "COOLING") {
			return "Réalisez la préparation et saisissez l’heure et la température de début de refroidissement."
		}
		return "Réalisez la préparation."
	}, [transformationType])

	const kitchenArea = useSelector(getProductionStepsKitchenAreaSelector)
	const machinesOptions = useSelector(getPSEMachinesOptionsSelector)

	const _loadMachinesOptions = (productionStepExecution, kitchenAreaId) => {
		dispatch(loadPSEMachinesOptions({ productionStepExecution, kitchenAreaId }))
	}

	useEffect(() => {
		if (transformationType === "COOLING" && kitchenArea && productionStepExecution) {
			_loadMachinesOptions(productionStepExecution, kitchenArea.objectId)
		}

	}, [productionStepExecution, transformationType, kitchenArea])

	const handleErrorMessageUpdate = (message) => setErrorMessage(message)

	return (
		<>
			<Stack gap={2}>
				<StyledSubStatusTitle>{title}</StyledSubStatusTitle>
				<PSEPreparation productionStepExecution={productionStepExecution} />
			</Stack>
			<Stack gap={2}>
				<ProductionStepExecutionCoolingFormContainer
					productionStepExecution={productionStepExecution}
					visible={transformationType === "COOLING"}
					machinesOptions={machinesOptions}
					handleSubmit={handleSubmit}
					updateErrorMessage={handleErrorMessageUpdate}
				/>
				{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
			</Stack>
			<ProductionStepExecutionLotsTable
				productionStepExecution={productionStepExecution}
				priorStepData={priorStepData}
			/>
		</>
	)
}

export default ProductionStepExecutionStepCompletion