import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import AsyncSelect from "react-select/async"
import { emphasize } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import NoSsr from "@mui/material/NoSsr"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import CancelIcon from "@mui/icons-material/Cancel"
import Parse from "parse"
import { parseLimitRequest } from "../../utils"

const suggestions = [
  { label: "Carotte" },
  { label: "Chou-Fleur" },
  { label: "Celeri" },
].map((suggestion) => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const SubcontractorCommercialName = Parse.Object.extend(
  "SubcontractorsCommercialNames"
)
const Suppliers = Parse.Object.extend("Suppliers")

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
})

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      elevation={0}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class SubcontractorCommercialNameAutocomplete extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: null,
      currentInputValue: null,
      result: [],
      enterPress: false,
    }
  }

  loadOptions = async (inputValue, callback) => {
    this.setState({ currentInputValue: inputValue })
    callback(await this.filterSubcontractorCommecialName(inputValue))
  }

  filterSubcontractorCommecialName = async (inputValue) => {
    const { supplierId, currentSubcontractorCommercialNames } = this.props

    if (inputValue) {
      const supplier = await new Parse.Query(Suppliers)
        .equalTo("objectId", supplierId)
        .first()

      const finalSubcontractorCommercialNames =
        currentSubcontractorCommercialNames.map((subcontractorCommercialName) =>
          subcontractorCommercialName.id ? subcontractorCommercialName.id : null
        )

      const commercialNames = await new Parse.Query(SubcontractorCommercialName)
        .contains("name", inputValue)
        .equalTo("supplier", supplier)
        .notContainedIn("objectId", finalSubcontractorCommercialNames)
        .limit(parseLimitRequest)
        .find()

      let result = []
      for (const i in commercialNames) {
        const obj = commercialNames[i]
        result.push({
          id: obj.id,
          label: obj.get("name"),
          pourcentage: 0,
        })
      }
      this.setState({ result: result })
      return result
    }

    return []
  }

  componentDidUpdate() {
    const { onEnterValidation } = this.props
    if (this.state.enterPress === true) {
      onEnterValidation(this.state.selectedValue)
      this.setState({ enterPress: false })
    }
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props
    let finalValue = null
    if (value) {
      finalValue = {
        id: value.id,
        label: value.get("name"),
      }
    }
    this.setState({
      selectedValue: finalValue,
    })
  }

  handleChange = (selectedValue) => {
    const {
      input: { onChange },
    } = this.props
    this.setState({ selectedValue })
    onChange && onChange(selectedValue)
  }

  handleKeyDown = (event) => {
    const { result } = this.state
    if (event.keyCode === 13) {
      if (result.length > 0) {
        this.setState({ enterPress: true })
      } else {
        event.preventDefault()
      }
    }
  }

  render() {
    const {
      classes,
      theme,
      input: { name },
      autoFocus,
      currentValue,
    } = this.props

    if (currentValue === null) {
      this.setState({ selectedValue: null })
    }

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <AsyncSelect
            name={name}
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            components={components}
            loadOptions={this.loadOptions}
            defaultOptions
            onChange={this.handleChange}
            value={this.state.selectedValue || ""}
            placeholder="Nom commercial"
            autoFocus={autoFocus}
            isClearable={true}
            onKeyDown={this.handleKeyDown}
          />
        </NoSsr>
      </div>
    )
  }
}

SubcontractorCommercialNameAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(
  SubcontractorCommercialNameAutocomplete
)
