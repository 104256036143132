import React from "react"
import { useDispatch } from "react-redux"

import IngredientIcon from "@mui/icons-material/Restaurant"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import PageTabs from "../../components/PageTabs"
import { showExternalCommercialNames } from "../../actions/Ingredients/External/SubcontractorsCommercialNames"
import { showInternalCommercialNames } from "../../actions/Ingredients/Internal/CommercialsNames"

// list of tab
const tabs = [
    {
        value: "internals",
        label: "Ingrédients internes"
    },
    {
        value: "externals",
        label: "Ingrédients externes"
    }
]

const IngredientsPage = ({ children, tabValue }) => {
    const dispatch = useDispatch()

    const onTabChange = (event, tabValue) => {
        // externals
        if (tabValue === tabs[1].value) {
            dispatch(showExternalCommercialNames())
            return
        }
        // internals
        dispatch(showInternalCommercialNames())
    }

    return (
        <PageTabs
            title={<PageHeaderTitle title="Ingrédients" icon={<IngredientIcon />} color="#6E3EFF" />}
            tabs={tabs}
            tabValue={tabValue}
            onTabChange={onTabChange}
        >
            {children}
        </PageTabs>
    )
}

export default IngredientsPage