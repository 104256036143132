import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import { Fab } from "@mui/material"
import { Add } from "@mui/icons-material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"

import SupplierItemCreate from "../SupplierItem/SupplierItemCreate"
import { onCreateSupplierItem, showSupplierItem, extractSupplierItemsBySupplierCsv } from "../../actions/Supplier/supplierItems"

import DataGrid from "../DataGrid/DataGrid"
import { getSupplierItemTypeLabel, getSupplierItemUnitPrice, getUnitValue, supplierItemTypesArray } from "../../utils/supplierItemUtils"
import { roundNumber } from "../../utils"

const useStyles = makeStyles({
  fab: {
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
  }
})

const ArticlesList = props => {
  const {
    articles = [],
  } = props

  const supplier = useSelector(state => state.suppliers.selectedSupplier)

  const [data, setData] = useState([])

  const [openCreationDialog, setOpenCreationDialog] = useState(false)

  const columns = [
    {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      },
      width: 120
    },
    {
      key: "name",
      label: "Nom",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "type",
      label: "Type",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: supplierItemTypesArray.map((supplierItemType) => ({
          key: supplierItemType.value,
          value: supplierItemType.label,
          label: supplierItemType.label
        }))
      },
      width: 220
    },
    {
      key: "orderUnit",
      label: "Unité de commande",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      width: 150
    },
    {
      key: "pricePerOrderUnit",
      label: "Prix par unité de commande (€)",
      dataType: "number",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      width: 130
    },
    {
      key: "pricePerKg",
      label: "Prix au kg (€)",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      width: 100
    },
    {
      key: "pricePerUnit",
      label: "Prix d'une pièce (€)",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: false,
        type: "text"
      },
      width: 100
    },
    {
      key: "state",
      label: "Etat",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: [
          { key: "active", value: "active", label: "Actif" },
          { key: "inactive", value: "inactive", label: "Inactif" },
        ]
      },
      render: (state) => {
        if (state === "active") {
          return <CheckCircleIcon sx={{ color: "#4bb200" }} />
        } else {
          return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
        }
      },
      width: 100
    }
  ]

  const classes = useStyles()
  const dispatch = useDispatch()

  const createData = (supplierItem) => {
    const serializedSupplierItem = {
      id: supplierItem.objectId,
      name: supplierItem.name,
      type: getSupplierItemTypeLabel(supplierItem.type),
      orderUnit: getUnitValue(supplierItem.units, "order", "unity"),
      pricePerOrderUnit: getUnitValue(supplierItem.units, "order", "price"),
      pricePerKg: roundNumber(supplierItem.pricePerKg, 3) || "",
      pricePerUnit: getSupplierItemUnitPrice(supplierItem.units) || "",
      state: supplierItem.isActive ? "active" : "inactive",
    }

    return serializedSupplierItem
  }

  const createDataObject = useCallback(() => {
    setData(articles.map(createData))
  }, [articles])

  useEffect(() => {
    createDataObject()
  }, [articles])

  const _toggleCreationDialog = () => {
    setOpenCreationDialog(!openCreationDialog)
  }

  const _createNewSupplierItem = values => {
    const newValues = { ...values, supplier }
    dispatch(onCreateSupplierItem(newValues))
    _toggleCreationDialog()
  }

  const _onArticleClick = (article) => {
    dispatch(showSupplierItem(article.id))
  }

  const onExtractSupplierItemsBySupplierCsv = () => {
    dispatch(extractSupplierItemsBySupplierCsv(supplier.objectId))
  }

  const moreMenus = [
    {
      onClick: onExtractSupplierItemsBySupplierCsv,
      label: "Extraire la base article"
    }
  ]

  return (
    <>
      <DataGrid
        title="Articles rattachés au fournisseur"
        columns={columns}
        data={data}
        withFilters
        rowLabel="articles"
        menus={moreMenus}
        onRowClick={_onArticleClick}
      />
      <SupplierItemCreate
        onClose={_toggleCreationDialog}
        open={openCreationDialog}
        supplier={supplier}
        onSaveSupplierItem={_createNewSupplierItem}
      />
      <div className={classes.fab}>
        <Fab color="primary" onClick={_toggleCreationDialog}>
          <Add />
        </Fab>
      </div>
    </>
  )
}

export default ArticlesList
