import {
    actionWithLoader,
    getReplacingPath,
    onEnter,
    push
} from "../../../Utils/utils"
import {
    getGroupsIngredients,
    createGroupIngredients
} from "../../../../parseManager/ingredients/classifications/group/parseGroupManager"
import {
    getFamiliesIngredients
} from "../../../../parseManager/ingredients/classifications/family/parseFamilyManager"

export function loadGroupsClassificationsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const groupsIngredients = await getGroupsIngredients(["family"])

        dispatch({
            type: "CLASSIFICATIONS_GROUPS_LOADED",
            groupsIngredients
        })
    })
}

export function loadFamiliesIngredients () {
    return actionWithLoader(async (dispatch) => {
        const familiesIngredients = await getFamiliesIngredients()

        dispatch({
            type: "CLASSIFICATIONS_GROUP_FAMILIES_LOADED",
            familiesIngredients
        })
    })
}

export function createClassificationsGroupIngredient(values, dispatch, { groupIngredient, location }) {
    return actionWithLoader(async (dispatch) => {
        const newGroupIngredient = await createGroupIngredients(groupIngredient, values)

        dispatch({
            type: "CLASSIFICATIONS_GROUP_SAVED"
        })

        dispatch(loadGroupsClassificationsIngredients())
        if (location.state && location.state.returnPath === "edit"){
            dispatch(showSingleGroupClassificationsIngredientsRead(newGroupIngredient.objectId))
        }
        else {
            dispatch(showGroupsClassificationsIngredients())
        }
    })
}

export function onEnterGroupsClassificationsIngredients(store) {
    return onEnter({
        store,
        actionThunk: loadGroupsClassificationsIngredients,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function onEnterSingleGroupClassificationIngredients(store) {
    return async (nextState, replace, callback) => {
        store.dispatch(loadFamiliesIngredients())
        callback()
    }
}

export function showGroupsClassificationsIngredients() {
    return push("/settings/ingredients/classifications/groups")
}

export function showSingleGroupClassificationsIngredientsEdit(id, location) {
    if (id) {
        return push(`/settings/ingredients/classifications/group/${id}/edit`, location)
    } else {
        return push("/settings/ingredients/classifications/group")
    }
}

export function showSingleGroupClassificationsIngredientsRead(id) {
    if (id) {
        return push(`/settings/ingredients/classifications/group/${id}`)
    } else {
        return push("/settings/ingredients/classifications/group")
    }
}
