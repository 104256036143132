import React from "react"
import { Stack, Typography } from "@mui/material"
import { StyledPaper, StyledPaperTitle, StyledPaperLabel } from "../../../../components/StyledSingleParseObjectComponents"
import GenericEditIcon from "../../../../components/GenericEditIcon"

const DistributionCenterAddress = ({ addressConfig = {}, onEdit = null }) => {
  return (
    <StyledPaper>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <StyledPaperTitle>
            Adresse
          </StyledPaperTitle>
          <GenericEditIcon onEdit={onEdit} />
        </Stack>
        <Stack gap="16px">
          <Stack direction="row">
            <StyledPaperLabel>Adresse</StyledPaperLabel>
            <Typography>{addressConfig.address}</Typography>
          </Stack>
          <Stack direction="row">
            <StyledPaperLabel>Complément</StyledPaperLabel>
            <Typography>{addressConfig.complement}</Typography>
          </Stack>
          <Stack direction="row">
            <StyledPaperLabel>Code postal</StyledPaperLabel>
            <Typography>{addressConfig.zipCode}</Typography>
          </Stack>
          <Stack direction="row">
            <StyledPaperLabel>Ville</StyledPaperLabel>
            <Typography>{addressConfig.city}</Typography>
          </Stack>
          <Stack direction="row">
            <StyledPaperLabel>Latitude</StyledPaperLabel>
            <Typography>{addressConfig.latitude}</Typography>
          </Stack>
          <Stack direction="row">
            <StyledPaperLabel>Longitude</StyledPaperLabel>
            <Typography>{addressConfig.longitude}</Typography>
          </Stack>
        </Stack>
    </StyledPaper>
  )
}

export default DistributionCenterAddress