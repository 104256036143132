import { getUserById } from "../rights/parseRights"

export async function deleteUsersAndRole (users) {
	try {
		for (const user of users){
			const currentUser = await getUserById(user, [], false)
				
			currentUser.set("deleted", true)
			await currentUser.save()
		}
	}
	catch (e) {
		return Promise.reject(e)
	}
}