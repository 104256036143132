import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { getLotFilterModeLabel, LOT_INVENTORY_MODE, LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"
import moment from "moment"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"
import IconButton from "@mui/material/IconButton"

const useStyles = makeStyles({
	container: {
		color: COLORS.BLACK,
		boxShadow: "0 0 3px rgba(0,0,0,.2)",
		backgroundColor: COLORS.WHITE,
		padding: "15px 30px 25px 30px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	title: {
		fontSize: 20,
		fontFamily: "Roboto",
		fontWeight: 500
	},
	filterContainer: {
		marginTop: 10,
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	selectContainer: {
		margin: "5px 25px 0 25px"
	},
	siteSelector: {
		width: 130
	},
	stockZoneSelector: {
		width: 220
	},
	separator: {
		marginTop: 20,
		width: "100%",
		height: 1,
		backgroundColor: COLORS.GREY_FLAW_ICON
	},
	submitButton: {
		marginTop: 21,
		width: 328,
		height: 38,
		borderRadius: 0
	},
	dateInput: {
		fontSize: 16,
		width: 180,
		textAlign: "left",
		marginTop: 1,
	},

	/** PENCIL **/
	iconContainer: {
		width: 38,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 3,
		padding: 0,
	},
	selectZoneContainer: {
		composes: "flexRow"
	},

	zoneItem: {
		composes: "flexRow"
	}
})

const outputTypes = [
	{ name: "Matière première", value: "RAW_MATERIAL" },
	{ name: "Packaging", value: "PACKAGING" }
]

const LotFilterModal = (props) => {
	const {
		sites,
		mode,
		outputDateSelected,
		validate,
		outputTypeSelected,
		siteSelected,
		stockZoneSelected
	} = props

	const findOutputTypeByValue = (outputTypes, outputTypeSelectedToUse) => {
		return outputTypes.find((item) => (
			item.value === outputTypeSelectedToUse.value
		))
	}

	const findSiteByValue = (sites, siteSelectedToUse) => {
		return sites.find((item) => (
			item.objectId === siteSelectedToUse.objectId
		))
	}
	const findStockZoneByValue = (siteSelectedToUse, stockZoneSelectedToUse) => {
		if (stockZoneSelectedToUse.objectId === 0) {
			return stockZoneSelectedToUse
		} else {
			return siteSelectedToUse.stockZones.find((item) => (
				item.objectId === stockZoneSelectedToUse.objectId
			))
		}
	}

	const findStockZones = (siteSelectedToUse) => {
		return siteSelectedToUse && siteSelectedToUse.stockZones
	}

	const classes = useStyles()
	const siteSelectedToUse = siteSelected ? siteSelected : sites[0]
	const [selectedSite, setSelectedSite] = useState(findSiteByValue(sites, siteSelectedToUse))
	const [stockZones, setStockZones] = useState(findStockZones(siteSelectedToUse))
	const stockZoneSelectedToUse = stockZoneSelected ? stockZoneSelected : sites[0] && sites[0].stockZones[0]
	const [selectedStockZone, setSelectedStockZone] = useState(findStockZoneByValue(siteSelectedToUse, stockZoneSelectedToUse))
	const outputTypeSelectedToUse = outputTypeSelected ? outputTypeSelected : outputTypes[0]
	const [selectedOutputType, setSelectedOutputType] = useState(findOutputTypeByValue(outputTypes, outputTypeSelectedToUse))
	const dateInputToUse = outputDateSelected ? outputDateSelected : moment.utc().add(1, "days").startOf("day").valueOf()
	const [date, setDate] = useState(dateInputToUse)

	const renderElements = (items) => {
		return items.map((item, i) => {
			return <MenuItem value={item} key={i} className={classes.selectZoneContainer}>
				<div className={classes.zoneItem}>
					{item.name}
					{item.objectId === 0 && (
						<IconButton
							className={classes.iconContainer}
							size="large">
							<img src="/img/edit_disabled.png" />
						</IconButton>
					)}
				</div>
			</MenuItem>
		})
	}

	const handleSiteChange = (e) => {
		const selectedSite = e.target.value
		const selectedStockZone = selectedSite.stockZones[0] || null

		setSelectedSite(selectedSite)
		setSelectedStockZone(selectedStockZone)
		setStockZones(findStockZones(selectedSite))
	}

	const onDateChange = (value) => {
		// the input field and picker does not have the same hour
		// the date picker set the current date with current hour
		// the input field set the current date but with hour at 00:00:00
		// so we set the hour, min and sec manually for both
		if (value) {
			const dateWithCurrentTime = value.set({
				h: moment().get("hour"),
				m: moment().get("minute"),
				s: moment().get("second"),
			})
			setDate(dateWithCurrentTime.valueOf())
		}
	}

	const onSubmit = () => {
		const values = {
			site: selectedSite,
			stockZone: selectedStockZone,
			date,
			mode,
			outputType: selectedOutputType
		}

		validate(values)
	}

	return (
		<div className={classes.container}>
			<h3 className={classes.title}>
				Sélectionnez le site et la zone de stockage {getLotFilterModeLabel(mode)}
			</h3>
			<div className={classes.filterContainer}>
				{mode === LOT_OUTPUT_MODE &&
					<FormControl className={classes.selectContainer} variant="standard">
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							Type de sortie
						</InputLabel>
						<Select
							variant="standard"
							value={selectedOutputType}
							className={classes.siteSelector}
							onChange={(e) => { setSelectedOutputType(e.target.value) }}
						>
							{renderElements(outputTypes)}
						</Select>
					</FormControl>
				}
				<FormControl className={classes.selectContainer} variant="standard">
					<InputLabel variant="standard" htmlFor="uncontrolled-native">
						Site
					</InputLabel>
					<Select
						variant="standard"
						value={selectedSite}
						className={classes.siteSelector}
						onChange={handleSiteChange}
					>
						{renderElements(sites)}
					</Select>
				</FormControl>
				<FormControl className={classes.selectContainer} variant="standard">
					<InputLabel variant="standard" htmlFor="uncontrolled-native">
						Zone de stockage
					</InputLabel>
					<Select
						variant="standard"
						value={selectedStockZone}
						className={classes.stockZoneSelector}
						onChange={(e) => {
							setSelectedStockZone(e.target.value)
						}
						}
					>
						{renderElements(stockZones)}
					</Select>
				</FormControl>
				{mode === LOT_OUTPUT_MODE && (
					<FormControl className={classes.selectContainer} variant="standard">
						<DatePicker
							disableCloseOnSelect={false}
							showToolbar={false}
							value={date}
							name="date"
							variant="inline"
							inputFormat="DD/MM/YYYY"
							required
							onChange={onDateChange}
							renderInput={(params) => <TextField {...params} variant="standard" label={selectedOutputType?.value === "RAW_MATERIAL" ? "Jour d'utilisation en production" : "Jour d'utilisation en barquettage"} />}
							inputProps={{
								className: classes.dateInput
							}}
						/>
					</FormControl>
				)}

			</div>
			<span className={classes.separator} />
			<Button
				className={classes.submitButton}
				color="primary"
				variant="contained"
				type="submit"
				onClick={onSubmit}
			>
				{
					mode === LOT_INVENTORY_MODE
						? "Accéder à l'inventaire"
						: "Accéder à l’interface de sortie"
				}
			</Button>
		</div>
	)
}

export default LotFilterModal