import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import LockIcon from "@mui/icons-material/Lock"
import { Table } from "@devexpress/dx-react-grid-material-ui"
import { COLORS, KFC_BRANDS } from "../../../../utils"
import { TextField, Chip, Select, MenuItem } from "@mui/material"
import IconButton from "@mui/material/IconButton"

const styles = {
    tableStriped: {
        "& tbody tr td:first-child": {
            backgroundColor: COLORS.BLUE_50,
            textAlign: "center",
            paddingLeft: 5
        },
        "& tbody tr td:nth-child(2)": {
            backgroundColor: COLORS.BLUE_50,
            paddingLeft: 5
        },
        "& tbody tr td": {
            paddingTop: 8,
            paddingBottom: 8
        }

        /* Commentaire laissé comme trace du travail effectué pour essayer de fixer le header du tableau. Utile plus tard.
        "& tbody": {
            display: "block",
            height: "calc(100vh - 320px)",
            overflowY: "auto",
            width: "100vw"
        },
        "& tbody tr": {
            display: "table",
            width: "100%",
            tableLayout: "fixed"
        }
        */
    },
    lockedCell: {
        lineHeight: "2",
        backgroundColor: COLORS.GREEN_100,
        borderRight: `1px solid ${COLORS.GREEN_200}`,
    },
    lockedFixedCell: {
        composes: "$fixedCell",
        lineHeight: "2",
        backgroundColor: COLORS.BLUE_50,
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    lockIcon: {
        float: "right",
        width: "15px",
        cursor: "pointer",
        position: "relative"
    },
    lockIconContainer: {
        composes: "$lockIcon",
        width: 10,
        top: -16,
        right: -8
    },
    fixedCellLockIcon: {
        position: "relative",
        top: 0,
        left: 0,
        width: 10,
        height: 10,
        textAlign: "center",
        verticalAlign: "middle",
    },
    headerRow: {
        // TODO : position: fixed
    },
    bold: {
        fontWeight: "bold",
        color: COLORS.BLUEGREY_900
    },
    textField: {
        marginTop: 0,
        marginBottom: 0
    },
    fixedCell: {
        zIndex: 300,
        position: "sticky"
    },
    greenCell: {
        composes: "$fixedCell",
        backgroundColor: COLORS.GREEN_100,
        borderLeft: `1px solid ${COLORS.GREEN_200}`
    },
    redCell: {
        composes: "$fixedCell",
        backgroundColor: COLORS.RED_100,
        borderLeft: `1px solid ${COLORS.RED_200}`
    },
    chip: {
        width: "100%",
        textAlign: "center"
    },
    select: {
        width: "100%"
    },
    reusable: {
        mask: "url(/img/recycle.svg) no-repeat center",
        width: 11,
        height: 10,
        marginRight: 5,
        marginTop: 2,
        display: "inline-block"
    }
}

const TableComponentBase = ({ classes, ...restProps }) => (
    <Table.Table
        {...restProps}
        className={classes.tableStriped}
    />
)

const ItemComponentBase = ({ value, classes }) => (
    <span className={classes.bold}>{value}</span>
)

const LockedCellBase = ({ onCellUnlock, cellData, classes, value, ...restProps }) => (
    <Table.Cell
        {...restProps}
        className={classes.lockedCell}
    >
        {value}
        <LockIcon className={classes.lockIcon} onClick={() => onCellUnlock(cellData)} />
    </Table.Cell>
)

const LockedFixedCellBase = ({ value, cellData, onCellUnlock, classes, ...restProps }) => {
    return <Table.Cell
        {...restProps}
        className={classes.lockedFixedCell}
    >
        {
            restProps.row.isReusable === "Oui" &&
            <div
                className={classes.reusable}
                style={{ backgroundColor: KFC_BRANDS.find(el => el.name === restProps.row.brand)["color"] }}
            />
        }
        <IconButton
            className={classes.lockIconContainer}
            onClick={() => onCellUnlock(cellData)}
            size="large">
            <LockIcon className={classes.fixedCellLockIcon} />
        </IconButton>
        {value}
    </Table.Cell>
}

const HighlightedCellComponentBase = ({ statusOk = true, classes, value, ...restProps }) => (
    <Table.Cell
        {...restProps}
        className={statusOk ? classes.greenCell : classes.redCell}
    >
        {value}
    </Table.Cell>
)

const ProductCell = ({ value, onCellClick, classes, ...restProps }) => (
    <Table.Cell
        {...restProps}
        className={classes.lockedFixedCell}
        onClick={() => { onCellClick(restProps.row) }}
    >
        {
            restProps.row.isReusable === "Oui" &&
            <div
                className={classes.reusable}
                style={{ backgroundColor: KFC_BRANDS.find(el => el.name === restProps.row.brand)["color"] }}
            />
        }
        {value}
    </Table.Cell>
)

const EditingCellComponentBase = ({ onCommitChange, classes, value, ...restProps }) => {

    const [cell, setCell] = useState(value)

    const handleChange = event => {
        const value = event.target.value
        setCell(value !== "" ? parseInt(value) : "")
    }

    const handleBlur = () => {
        onCommitChange(cell)
    }

    return (
        <Table.Cell
            {...restProps}
        >
            <TextField
                variant="standard"
                id="editing-cell"
                value={cell}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="normal"
                autoFocus
            />
        </Table.Cell>
    )
}

const HeaderRowComponentBase = ({ classes, ...restProps }) => (
    <Table.Row {...restProps} className={classes.headerRow} />
)

const ChipCellComponentBase = ({ classes, value, ...restProps }) => (
    <Table.Cell
        {...restProps}
    >
        <Chip label={value} className={classes.chip} />
    </Table.Cell>
)

const SelectStatusCellComponentBase = ({ value, classes, onStatusChange, rowIndex, canEdit, ...restProps }) => {
    const [status, setStatus] = useState(value)

    const handleChange = event => {
        setStatus(event.target.value)
        onStatusChange(event.target.value, rowIndex)
    }

    return (
        <Table.Cell {...restProps}>
            <Select
                variant="standard"
                className={classes.select}
                value={status}
                onChange={handleChange}
                disabled={!canEdit}
            >
                <MenuItem value={value}>{value}</MenuItem>
                <MenuItem value="TODO">TODO</MenuItem>
            </Select>
        </Table.Cell>
    )
}

export const TableComponent = withStyles(styles, { name: "TableComponent" })(TableComponentBase)
export const ItemComponent = withStyles(styles, { name: "ItemComponent" })(ItemComponentBase)
export const ProductCellComponent = withStyles(styles, { name: "ProductCell" })(ProductCell)
export const LockedCellComponent = withStyles(styles, { name: "LockedCellComponent" })(LockedCellBase)
export const LockedFixedCellComponent = withStyles(styles, { name: "LockedFixedCellComponent" })(LockedFixedCellBase)
export const HeaderRowComponent = withStyles(styles, { name: "HeaderRowComponent" })(HeaderRowComponentBase)
export const EditingCellComponent = withStyles(styles, { name: "EditingCellComponent" })(EditingCellComponentBase)
export const HighlightedCellComponent = withStyles(styles, { name: "HighlightedCellComponent" })(HighlightedCellComponentBase)
export const ChipCellComponent = withStyles(styles, { name: "ChipCellComponent" })(ChipCellComponentBase)
export const SelectStatusCellComponent = withStyles(styles, { name: "SelectStatusCellComponent" })(SelectStatusCellComponentBase)
