import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { COLORS } from "../../../utils"
import clsx from "clsx"

let rows = []

const styles = {
  tableMenu: {
    paddingLeft: 20,
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
    "&.is-foodcheri": {
      backgroundColor: COLORS.HEAD_FC,
    },
    "&.is-seazon": {
      backgroundColor: COLORS.HEAD_SEAZON,
    },
    "&.is-monoprix": {
      backgroundColor: COLORS.HEAD_MONOP,
    },
    "&.is-frigonu": {
      backgroundColor: COLORS.HEAD_FRIGO,
    },
  },
  smallColumn: {
    composes: "$tableMenu",
    width: 30,
    paddingRight: 10,
    textAlign: "center",
  },
  sortColumn: {
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  mediumColumn: {
    composes: "$smallColumn",
    minWidth: 100,
  },
}

class DispatchEnhancedTableHead extends PureComponent {
  getTableCellClasse = (row) => {
    const { classes } = this.props
    if (row.id === "name") {
      return classes.tableMenu
    }
    if (row.id === "reference") {
      return classes.mediumColumn
    }
    return classes.smallColumn
  }

  render() {
    const { order, orderBy, tableType, brand, hub, hubTab } = this.props

    if (!hub) {
      if (tableType !== "todo") {
        rows = [
          {
            id: "uniqueCode",
            numeric: false,
            disablePadding: true,
            label: "CODE",
          },
          { id: "name", numeric: false, disablePadding: false, label: "NOM" },
          {
            id: "packaging",
            numeric: false,
            disablePadding: false,
            label: "PACKAGING",
          },
          { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
          { id: "dlc", numeric: false, disablePadding: false, label: "DLC" },
          {
            id: "kitchenTheoretical",
            numeric: false,
            disablePadding: false,
            label: "THÉORIQUE CUISINE",
          },
          {
            id: "warehouse",
            numeric: false,
            disablePadding: false,
            label: "ENTREPÔT",
          },
          {
            id: "dispatch",
            numeric: false,
            disablePadding: false,
            label: "DISPATCHÉ",
          },
        ]
      } else {
        rows = [
          {
            id: "uniqueCode",
            numeric: false,
            disablePadding: true,
            label: "CODE",
          },
          { id: "name", numeric: false, disablePadding: false, label: "NOM" },
          {
            id: "packaging",
            numeric: false,
            disablePadding: false,
            label: "PACKAGING",
          },
          { id: "type", numeric: false, disablePadding: false, label: "TYPE" },
          { id: "dlc", numeric: false, disablePadding: false, label: "DLC" },
          {
            id: "waiting",
            numeric: false,
            disablePadding: false,
            label: "ENTREPÔT",
          },
        ]
      }
    }

    if (hub) {
      if (tableType === "todo") {
        const optionalRow =
          hubTab === 0
            ? [
                {
                  id: "wanted",
                  numeric: false,
                  disablePadding: false,
                  label: "QUANTITÉ\nATTENDUE\n",
                },
              ]
            : []

        rows = [
          {
            id: "uniqueCode",
            numeric: false,
            disablePadding: true,
            label: "CODE",
          },
          { id: "name", numeric: false, disablePadding: false, label: "NOM" },
          {
            id: "reference",
            numeric: false,
            disablePadding: false,
            label: "PRODUITS\nÀ TRAITER\n",
          },
          ...optionalRow,
          {
            id: "toDispatched",
            numeric: false,
            disablePadding: false,
            label: "PRODUITS\nDISPATCHÉS/TOTAL\n",
          },
        ]
      } else {
        rows = [
          {
            id: "uniqueCode",
            numeric: false,
            disablePadding: true,
            label: "CODE",
          },
          { id: "name", numeric: false, disablePadding: false, label: "NOM" },
          {
            id: "toDispatched",
            numeric: false,
            disablePadding: false,
            label: "PRODUITS\nDISPATCHÉS/TOTAL\n",
          },
          {
            id: "wanted",
            numeric: false,
            disablePadding: false,
            label: "QUANTITÉ\nATTENDUE\n",
          },
          {
            id: "dispatched",
            numeric: false,
            disablePadding: false,
            label: "QUANTITÉ\nDISPATCHÉE\n",
          },
        ]
      }
    }

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              sortDirection={orderBy === row.id ? order : false}
              className={clsx(
                this.getTableCellClasse(row),
                `is-${brand.toLowerCase()}`
              )}
            >
              {row.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

DispatchEnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(DispatchEnhancedTableHead)
