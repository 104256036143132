import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import { SupplierCreationSchema } from "../../utils/yupValidators"
import moment from "moment"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import Divider from "@mui/material/Divider"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { creationInitialValues } from "../../utils/suppliers"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        width: "50%",
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14,
            lineHeight: "11px"
        },
        "& .MuiInputLabel-formControl": {
            "&.single-label": {
                fontWeight: 500,
                position: "relative",
                color: COLORS.BLACK,
                height: 30,
                display: "flex",
                alignItems: "center",
                transform: "none",
            }
        }
    },
    formControl40: {
        composes: "$formControl",
        width: "40%",
        marginBottom: 25
    },
    formControl60: {
        composes: "$formControl",
        width: "60%"
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 40
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        textTransform: "uppercase",
        lineHeight: "11px",
        margin: "5px 0px 0px 0px"
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: 15,
        marginBottom: 15,
        marginTop: 70
    }
}))

export const SupplierCreate = (props) => {
    const {
        onClose,
        isCreating,
        sites,
        onSaveSupplier
    } = props

    const classes = useStyles()

    const submitForm = async (values) => {
        await onSaveSupplier(values)
        onClose()
    }

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={creationInitialValues}
            validationSchema={SupplierCreationSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isCreating}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Créer un fournisseur</span>
                            </DialogTitle>
                            <DialogContent >
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">Nom du fournisseur</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="name"
                                        inputProps={{
                                            placeHolder:"FoodChéri"
                                        }}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.name?
                                            (
                                                <div className={classes.errorField}>{errors.name}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">Raison sociale</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="corporateName"
                                        inputProps={{
                                            placeHolder:"FoodChéri"
                                        }}
                                        value={values.corporateName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.corporateName?
                                            (
                                                <div className={classes.errorField}>{errors.corporateName}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">SIRET</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="siret"
                                        inputProps={{
                                            placeHolder:"245 560..."
                                        }}
                                        value={values.siret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                    />
                                    {
                                        errors.siret?
                                            (
                                                <div className={classes.errorField}>{errors.siret}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">TVA intracommunautaire</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="intraTVA"
                                        inputProps={{
                                            placeHolder:"245 560..."
                                        }}
                                        value={values.intraTVA}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                    />
                                    {
                                        errors.intraTVA?
                                            (
                                                <div className={classes.errorField}>{errors.intraTVA}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">Nom du contact</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="contactName"
                                        inputProps={{
                                            placeHolder:"Nom du contact"
                                        }}
                                        value={values.contactName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.contactName?
                                            (
                                                <div className={classes.errorField}>{errors.contactName}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">Téléphone</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="phone"
                                        inputProps={{
                                            placeHolder:"01 23 45 67 89"
                                        }}
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.phone?
                                            (
                                                <div className={classes.errorField}>{errors.phone}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel className="single-label">Emails commerciaux</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl60}>
                                    <TextField
                                        variant="standard"
                                        name="commercialEmails"
                                        inputProps={{
                                            placeHolder:"nom@email.com"
                                        }}
                                        value={values.commercialEmails}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.commercialEmails?
                                            (
                                                <div className={classes.errorField}>{errors.commercialEmails}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <Divider />
                                <FormControl variant="standard" className={classes.formControl40}>
                                    <InputLabel style={{marginTop: 13}} className="single-label">Site de livraison par défaut</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel>Site de livraison</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="defaultDeliverySite"
                                        value={values.defaultDeliverySite}
                                        onChange={handleChange}
                                    >
                                        {
                                            sites.map(site => (
                                                <MenuItem
                                                    key={site.objectId}
                                                    value={site.objectId}
                                                >
                                                    {site.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.defaultDeliverySite?
                                            (
                                                <div className={classes.errorField}>{errors.defaultDeliverySite}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    onClick={onClose}
                                    color="primary"
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SupplierCreate
