import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS
} from "../../../utils"
import SellPlanningCard from "../PlanningCard/SellPlanningCard"
import { pxTOvw } from "../../../utils/planning"

const styles = {
    planningColumnContainerDefault: {
        width: pxTOvw((window.innerWidth - 118.4) / 7),
        backgroundColor: COLORS.LIGHT_GREY,
        borderLeft: "1px dashed #E7E7E7",
        borderRight: "1px dashed #E7E7E7",
        paddingLeft: 5,
        paddingRight: 5
    },
    planningColumnContainerValid: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.ENABLED_DRAG
    },
    planningColumnContainerError: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.DISABLED_DRAG
    },
    cardContainer: {
        backgroundColor: COLORS.WHITE,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 4,
        cursor: "pointer",
        position: "relative"
    }
}

const SellPlanningBody = (props) => {
    const {
        data,
        isIntern,
        brand,
        section,
        classes,
        currentCard,
        currentColumn,
        canEdit,
        openProductInfo
    } = props

    const getColumnClasses = (columnId) => {
        if (currentCard){
            if (currentColumn.id === columnId){
                return classes.planningColumnContainerValid
            }
            else {
                return classes.planningColumnContainerError
            }
        }

        return classes.planningColumnContainerDefault
    }

    return (
        data.map((item) => {
                const cards = Array.isArray(item.cards[brand.name][section.key])
                    ? item.cards[brand.name][section.key].filter(el => el.itemType === isIntern)
                    : []
                return (
                    <div
                        key={item.id}
                        className={getColumnClasses(item.id)}
                    >
                        {
                            cards.map((card, index) => {
                                return (
                                    <div className={classes.cardContainer} key={index}>
                                        <SellPlanningCard
                                            card={card}
                                            canEdit={canEdit}
                                            openProductInfo={openProductInfo}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
        })
    )
}

export default withStyles(styles)(SellPlanningBody)
