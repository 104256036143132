import React from "react"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { connect } from "react-redux"
import { COLORS } from "../../../../../utils"
import { getChef } from "../../../../../reducers/Chefs/Chefs"
import { showChefsList } from "../../../../../actions/Products/Products"
import {
    showChefRead,
    createOrUpdateChef,
    addImageToChef,
    removeImageToChef
} from "../../../../../actions/Chefs/Chefs"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { ChefSchema } from "../../../../../utils/yupValidators"
import { openUploadWidget } from "../../../../../utils/cloudinary"
import CloudinaryImage from "../../../../../components/Image/CloudinaryImage"

export const ChefForm = (props) => {

    const { chef, classes, createOrUpdateChef,
        showChefRead, showChefsList, location
    } = props

    const initialValues = {
        firstName: chef ? chef.firstName: "",
        lastName: chef ? chef.lastName: "",
        image: chef ? chef.image : null,
    }

    const deleteChefImage = (setFieldValue) => {
        setFieldValue("image", null)
    }

    const uploadChefImage = (values, setFieldValue) => {
        openUploadWidget({ tags: ["chef"]}, (error, result) => {
            if (!(Array.isArray(result) && result.length) ||error) {
                return
            }
            else {
                const images = { publicId: result[0].public_id }
                setFieldValue("image", images)
            }
        })
    }

    const renderChefImage = (publicId, onDelete) => {
        return (
            <div className={classes.imageContainer}>
                <CloudinaryImage
                    imageId={publicId}
                    containerClassName={classes.image}
                    width={200}
                    height={150}
                    customQuality="auto"
                />
                <div className={classes.imageOverlay}>
                    {
                        onDelete  &&
                        <div className={classes.deleteImageIcon}>
                            <DeleteIcon onClick={onDelete}/>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const _onClose = () => {

        if (location.state && location.state.returnPath === "edit" && chef){
            showChefRead(chef.objectId)
        }
        else {
            showChefsList()
        }
    }

    return (
        <Paper elavation={0} className={classes.root}>
            <div className={classes.closeWindow}>
                <IconButton aria-label="Fermer" onClick={_onClose} size="large">
                    <CloseIcon/>
                </IconButton>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={ChefSchema}
                enableReinitialize
                onSubmit={(values) => createOrUpdateChef(values, chef ? chef.objectId : null, location)}
            >
                {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit
                }) => {
                    return (
                        <Form>
                            <div className={classes.form}>
                               {
                                    values.image && values.image && values.image.publicId
                                        ?
                                            renderChefImage(values.image.publicId, () => deleteChefImage(setFieldValue))
                                        :
                                            <div className={classes.errorField}>
                                                <p>Aucune photo trouvée</p>
                                                <span className={classes.addPhoto} onClick={() => uploadChefImage(values, setFieldValue)}>Ajouter une photo</span>
                                            </div>
                                }
                                <TextField
                                    variant="standard"
                                    className={classes.inputContainer}
                                    id="firstName"
                                    label="Prénom"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {
                                    touched.firstName && errors.firstName
                                        ?
                                        (
                                            <div className={classes.errorField}>
                                                {errors.firstName}
                                            </div>
                                        )
                                        : null
                                }

                                <TextField
                                    variant="standard"
                                    className={classes.inputContainer}
                                    id="lastName"
                                    label="Nom"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {
                                    touched.lastName && errors.lastName
                                        ?
                                        (
                                            <div className={classes.errorField}>
                                                {errors.lastName}
                                            </div>
                                        )
                                        : null
                                }
                            </div>

                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                onClick={handleSubmit}
                                className={classes.submitButton}
                            >
                                Enregistrer
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </Paper>
    )
}

const styles = {
    root: {
        width: "100%",
        height: "100%",
        padding: 16
    },
    form: {
        border: "1px solid rgba(0,0,0, 0.3)",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 64
    },
    errorField: {
        color: COLORS.RED
    },
    inputContainer: {
        margin: 16
    },
    submitButton: {
        margin: 16,
        float: "right"
    },
    closeWindow: {
        textAlign: "end"
    },
    imageContainer: {
        position: "relative",
        "&:hover $imageOverlay": {
            opacity: 1
        },
        marginBottom: 10
    },
    image: {
        display: "block",
        width: "100%",
        height: "auto",
        contentFit: "cover"
    },
    imageOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: ".5s ease"
    },
    deleteImageIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 5,
        backgroundColor: COLORS.BLACK,
        color: "white",
        "& svg": {
            cursor: "pointer",
            fontSize: 15
        }
    },
    addPhoto: {
        color: "#2196f3",
        fontSize: 14,
        cursor: "pointer",
        textAlign: "left",
        marginLeft: 10
    },
}

const StyledChefForm =  withStyles(styles)(ChefForm)

export default connect((state, props) => {
    const {params: {id}} = props
    const chef = id ? getChef(state, id) : null

    return {
        chef: chef
    }
}, {
    createOrUpdateChef,
    addImageToChef,
    removeImageToChef,
    showChefRead,
    showChefsList
})(StyledChefForm)
