import React from "react"
import { withStyles } from "@mui/styles"
import {COLORS, KFC_BRANDS, roundNumber} from "../../utils"
import ProductHeaderActions from "../Recipe/ProductHeaderActions"
import {
    getSubcontractorProductById
} from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import {deleteSubcontractorProduct} from "../../actions/Subcontractor/subcontractorsProducts"
import {useDispatch, useSelector} from "react-redux"
import { getProductTypeLabelByProduct } from "../../utils/productTypesUtils"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import CloudinaryImage from "../Image/CloudinaryImage"

export const Header = (props) => {
    const { image, jsonSubcontractorProduct, classes } = props
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const dispatch = useDispatch()
    const commercialName = jsonSubcontractorProduct
        ? jsonSubcontractorProduct.commercialName
        : ""

    const status = jsonSubcontractorProduct && jsonSubcontractorProduct.isActive
        ? "Actif"
        : "Inactif"

    const uniqueCode = jsonSubcontractorProduct && jsonSubcontractorProduct.uniqueCode
        ? jsonSubcontractorProduct.uniqueCode
        : ""

    const arrBrand = jsonSubcontractorProduct && KFC_BRANDS.filter(elem => elem.name === jsonSubcontractorProduct.brand)

    const onClickDelete = async () => {
        const subcontractorProduct = await getSubcontractorProductById(jsonSubcontractorProduct.objectId, [], false)
        dispatch(deleteSubcontractorProduct(subcontractorProduct, true))
    }

    return (
        <div className={classes.header}>
            {
                jsonSubcontractorProduct
                    ?
                    <>
                        {
                            image ?
                                <CloudinaryImage
                                    containerClassName={classes.recipeImage}
                                    imageId={image && image.publicId}
                                    width={240}
                                    height={160}
                                    customQuality="auto"
                                />
                                : <img
                                    src="/img/noImageAvailabled.png"
                                    alt="Indisponible"
                                    className={classes.recipeImage}
                                />
                        }
                        <div className={classes.infosContainer}>
                            <div className={classes.recipeCommercialNameContainer}>
                                {commercialName}
                            </div>
                            <div className={classes.subInfosContainer}>
                                <div className={classes.singleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Code
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {uniqueCode}
                                    </span>
                                </div>

                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Statut
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {status}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Type
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {getProductTypeLabelByProduct(jsonSubcontractorProduct, productTypeOptions)}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Marque
                                    </span>
                                    <div className={classes.brandsValueContainer}>
                                        {
                                            arrBrand.map((brand, index) =>
                                                <div key={index} className={classes.singleBrandContainer}>
                                                    <img className={classes.logo} src={brand.whiteImage} alt="logo"/>
                                                    <span className={classes.brandName}>{brand.label}</span>
                                                    {
                                                        jsonSubcontractorProduct.price &&
                                                        <span className={classes.brandName}>{`(${(roundNumber(jsonSubcontractorProduct.price, 2)+"").replace(".",",")}€)`}</span>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Sous-traitant
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {
                                            jsonSubcontractorProduct &&
                                            jsonSubcontractorProduct.name &&
                                            jsonSubcontractorProduct.name.supplier &&
                                            jsonSubcontractorProduct.name.supplier.name
                                                ? jsonSubcontractorProduct.name.supplier.name
                                                : ""
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            <ProductHeaderActions
              onClickDelete={onClickDelete}
              jsonData={jsonSubcontractorProduct}
              type={"SUBCONTRACTOR_PRODUCT"}
            />
        </div>
    )
}

const styles = {
    header: {
        width: "100%",
        backgroundColor: COLORS.BLUEGREY_700,
        height: 160,
        display: "flex",
        color: COLORS.WHITE
    },

    recipeImage: {
        width: 245,
        height: "100%"
    },
    infosContainer: {
        width: "calc(100% - 245px)",
        paddingLeft: 48,
        paddingTop: 16,
        paddingRight: 16
    },
    recipeCommercialNameContainer: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 30,
    },
    subInfosContainer: {
        display: "flex",
        marginTop: 16
    },
    singleSubInfoContainer: {
        display: "flex",
        flexDirection: "column"
    },
    notFirstSingleSubInfoContainer: {
        composes: "$singleSubInfoContainer",
        marginLeft: 32
    },
    subInfoLabel: {
        fontSize: 12
    },
    subInfoValue: {
        fontSize: 16,
        marginTop: 8
    },
    brandsValueContainer: {
        display: "flex",
    },
    logo: {
        width: 16,
        height: 16
    },
    singleBrandContainer: {
        marginRight: 24,
        marginTop: 8
    },
    brandName: {
        fontSize: 16,
        marginLeft: 8
    }
}

export default withStyles(styles)(Header)
