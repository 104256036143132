import Parse from "parse"

const ProductionSchema = Parse.Object.extend("ProductionSchema")

export const getProductionSchemas = async (toJson = true) => {
    try {
        const productionSchemas = await new Parse.Query(ProductionSchema).notEqualTo("deleted", true).findAll()
        return toJson ? productionSchemas.map(schema => schema.toJSON()) : productionSchemas
    }
    catch (e) {
        console.error("parseManager.productionSchema.parseProductionSchemaManager.getProductionSchemas error : ", e)
        return Promise.reject(e)
    }
}

export const getProductionSchemaById = async (objectId, toJson = true) => {
    try {
        const productionSchema = await new Parse.Query(ProductionSchema).equalTo("objectId", objectId).first()
        return toJson ? productionSchema.toJSON() : productionSchema
    }
    catch (e) {
        console.error("parseManager.productionSchema.parseProductionSchemaManager.getProductionSchemaById error : ", e)
        return Promise.reject(e)
    }
}