import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ButtonFlawAction from "./ButtonFlawAction"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  withSelection: {
    fontSize: 27,
    fontWeight: "normal",
    lineHeight: "32px",
    marginTop: 81,
    "&.is-tablet": {
      marginTop: 46,
    },
  },
  input: {
    fontWeight: "normal",
    fontSize: 60,
    color: COLORS.BLACK_CARD,
    textAlign: "center",
    lineHeight: "70px",
    width: 246,
    marginTop: 26,
    padding: "0 12px",
  },
}))

const InputFlawProduct = (props) => {
  const { handleValidate, isTablet, setValue } = props
  const classes = useStyles()
  const inputRef = useRef()

  const [disabled, setDisabled] = useState(true)

  useEffect(() => inputRef.current.focus(), [])

  const enableInput = (e) => {
    setDisabled(e.target.value === "")
    setValue(e.target.value)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection, { "is-tablet": isTablet })}>
        Quel est le code du produit cassé ?
      </div>

      {/* text summary */}
      <TextField
        variant="standard"
        inputProps={{
          className: classes.input,
          placeholder: "A001",
          type: "text",
        }}
        onChange={enableInput}
        inputRef={inputRef}
      />

      {/* button submit */}
      <ButtonFlawAction isTablet={isTablet} disabled={disabled} isCasse={true}>
        Confirmer
      </ButtonFlawAction>
    </form>
  )
}

export default InputFlawProduct
