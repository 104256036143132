import React, {
    useState,
    useEffect,
} from "react"
import { Box, Fab, styled } from "@mui/material"
import { Add } from "@mui/icons-material"
import { useSelector, useDispatch } from "react-redux"

import AdministrationTabs from "../AdministrationTabs"

import DataGrid from "../../../../components/DataGrid/DataGrid"
import { getPackagingMaterialsSelector } from "../../../../reducers/Settings/settings"
import PackagingMaterialFormDialog from "./PackagingMaterialFormDialog"
import { createPackagingMaterial, updatePackagingMaterial } from "../../../../actions/packagingMaterials/packagingMaterials"
import BooleanIcons from "../../../../components/BooleanIcons"

const columns = [
    {
        key: "id",
        label: "ID",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            strict: true
        },
        width: 120
    },
    {
        key: "name",
        label: "Nom de la matière",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        },
    },
    {
        key: "shortName",
        label: "Nom court",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        },
    },
    {
        key: "kgCO2PerMaterialTon",
        label: "kg CO2e / tonne de matière",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: false,
            type: "text"
        },
    },
    {
        key: "isRecyclableMaterial",
        label: "Matière recyclée",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: false,
            type: "text"
        },
        render: (state) => <BooleanIcons checked={!!state} />,
    },
    {
        key: "isRecyclableInFrance",
        label: "Recyclabilité en France",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: false,
            type: "text"
        },
        render: (state) => <BooleanIcons checked={!!state} />,
    },
]

const StyledFabContainer = styled("div")({
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
})

const PackagingMaterials = () => {
    const [data, setData] = useState([])
    const [openFormDialog, setOpenFormDialog] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)

    const packagingMaterials = useSelector(getPackagingMaterialsSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        setData(packagingMaterials.map((packagingMaterial) => {
            return {
                id: packagingMaterial.objectId,
                name: packagingMaterial.name,
                shortName: packagingMaterial.shortName,
                kgCO2PerMaterialTon: packagingMaterial.kgCO2PerMaterialTon,
                isRecyclableMaterial: packagingMaterial.isRecyclableMaterial,
                isRecyclableInFrance: packagingMaterial.isRecyclableInFrance,
            }
        }))
    }, [packagingMaterials])

    const toggleCreate = () => {
        setOpenFormDialog(!openFormDialog)
    }
    
    const handleRowClick = (row) => {
        setSelectedRow(row)
    }

    const handleClearRow = () => {
        setSelectedRow(null)
    }

    const handleCreatePackagingMaterial = async (values) => {
        await dispatch(createPackagingMaterial(values))
        toggleCreate()
    }

    const handleEditPackagingMaterial = async (values) => {
        if (!selectedRow) return
        await dispatch(updatePackagingMaterial(selectedRow.id, values))
        handleClearRow()
    }

    return (
        <AdministrationTabs tabValue="packagingMaterials">
          <Box className="flexColumn" sx={{ position: "relative", top: 40 }}>
                <DataGrid
                    title="Matières packaging"
                    columns={columns}
                    data={data}
                    withFilters
                    rowLabel="matières packaging"
                    onRowClick={handleRowClick}
                />
                <StyledFabContainer>
                    <Fab color="primary" onClick={toggleCreate}>
                        <Add />
                    </Fab>
                </StyledFabContainer>
            </Box>

            {/* creation */}
            <PackagingMaterialFormDialog
                onClose={toggleCreate}
                open={openFormDialog}
                onSave={handleCreatePackagingMaterial}
            />
            {/* edition */}
            <PackagingMaterialFormDialog
                onClose={handleClearRow}
                open={!!selectedRow}
                onSave={handleEditPackagingMaterial}
                packagingMaterial={selectedRow}
            />
        </AdministrationTabs>
    )
}

export default PackagingMaterials
