import React from "react"
import PropTypes from "prop-types"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexGrow: 1,
    width: "57%",
    overflow: "auto",
    justifyContent: "center",
    backgroundColor: COLORS.LIGHT_GREY,
    "&.full-width": {
      width: "100%",
    },
  },
  mainContentPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: COLORS.LIGHT_GREY,
    height: "calc(100vh - 64px)",
    borderRadius: 0,
  },
}))

const LeftSideWrapper = (props) => {
  const { children, fullWidth } = props
  const classes = useStyles()

  return (
    <main className={clsx(classes.content, { "full-width": fullWidth })}>
      <Paper className={classes.mainContentPaper} elevation={0}>
        {children}
      </Paper>
    </main>
  )
}

LeftSideWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LeftSideWrapper
