import React from "react"
import SupervisionsTabs from "../SupervisionsTabs"
import KitchenOutput from "./KitchenOutput"

const KitchenOutputTab = () => {
    return (
        <SupervisionsTabs tabValue="ko">
            <KitchenOutput />
        </SupervisionsTabs>
    )
}

export default KitchenOutputTab