import React, { useEffect } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { makeStyles } from "@mui/styles"
import { useDispatch, useSelector } from "react-redux"

import { showRecipeList } from "../../actions/Recipe/recipes"
import { showSubcontractorsProductsList } from "../../actions/Subcontractor/subcontractorsProducts"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import { COLORS } from "../../utils"
import PageWithTabsContainer, { StyledSubNavBar } from "../../components/PageWithTabsContainer"

const useStyles = makeStyles({
    tab: {
        color: COLORS.TEXT_COLOR_FOR_PRIMARY,
        width: 160,
        height: 64,
        minHeight: 64,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "13px"
    },
    selectedTab: {
        composes: "$tab",
        backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
    }
})

const ProductsPage = (props) => {
    const {
        tabValue,
        children,
    } = props

    const nbRecipes = useSelector(state => state.products.nbRecipes)
    const nbSubcontractorsProducts = useSelector(state => state.products.nbSubcontractorsProducts)

    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        document.title = "KFC - Fiches techniques"
        return () => {
            document.title = "KFC"
        }
    }, [])

    const _onTabChange = (event, tabValue) => {
        if (tabValue === "recipes") {
            dispatch(showRecipeList())
            return
        }
        if (tabValue === "subcontractors-products") {
            dispatch(showSubcontractorsProductsList())
            return
        }
        // resources
    }

    const labelRecipes = "Produits Internes (" + nbRecipes + ")"
    const labelSubcontractorsProducts = "Produits sous-traitants (" + nbSubcontractorsProducts + ")"

    return (
        <PageWithTabsContainer>
            <StyledSubNavBar>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab
                        value="recipes"
                        label={labelRecipes}
                        className={tabValue === "recipes"
                            ? classes.selectedTab
                            : classes.tab
                        }
                    />
                    <Tab
                        value="subcontractors-products"
                        label={labelSubcontractorsProducts}
                        className={tabValue === "subcontractors-products"
                            ? classes.selectedTab
                            : classes.tab
                        }
                    />
                </Tabs>
            </StyledSubNavBar>
            {children}
            <HelpButton link={helpLinks.product}/>
        </PageWithTabsContainer>
    )
}

export default ProductsPage
