import React, { useMemo } from "react"
import { COLORS } from "../../../utils"
import makeStyles from "@mui/styles/makeStyles"
import moment from "moment"
import { Box } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import HighlightOff from "@mui/icons-material/HighlightOff"
import Warning from "@mui/icons-material/Warning"
import Button from "@mui/material/Button"
import { generateStockUnitLabel } from "../../../utils/orderReception"

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        width: "100%",
        borderRadius: 8,
        padding: 16,
        gap: "16px",
        color: COLORS.DARK_GREY,
        backgroundColor: "#FFF"
    },
    card: {
        composes: "$root",
        border: "1px solid #E6E6E6"
    },
    cardSelected: {
        composes: "$root",
        border: "2px solid #2196F3",
    },
    values: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: "16px",
        color: "#555",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    title: {
        color: "#262626",
        fontFamily: "Roboto",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        margin: 0
    },
    lotTitle: {
        composes: "$title",
        color: COLORS.GREY_900,
    },
    leftRow: {
        color: COLORS.LABEL_GREY,
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    row: {
        display: "flex"
    },
    tableLabel: {
        textAlign: "left",
        fontSize: "16px",
        display: "flex",
        gap: "8px",
        fontWeight: 400,
        lineHeight: "24px"
    },
    tableValue: {
        marginLeft: "auto",
        fontSize: 10,
        padding: 0
    },
    creditNoteRow: {
        composes: "$row",
        fontSize: 10,
        textTransform: "uppercase",
        color: COLORS.ORANGE_CREDIT_NOTE
    },
    lastRow: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        paddingBottom: 8,
        paddingRight: 16,
        width: "100%"
    },
    lastRowTableLabel: {
        textTransform: "uppercase",
        textAlign: "left",
        fontSize: 10,
        paddingTop: 6
    },
    lastRowTableValue: {
        marginLeft: "auto",
        fontSize: 16,
        fontWeight: 500,
        color: "#262626"
    },
    stockUnit: {
        color: "#7C7C7C"
    },
    icon: {
        height: 16,
        width: 16,
        marginTop: "4px"
    },
    actionsContainer: {
        marginTop: 8,
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
        gap: "16px"
    },
    brokenButton: {
        borderRadius: "4px",
        backgroundColor: "#EB173E"
    },
    strikeThrough: {
        textDecoration: "line-through",
        color: "#9B9B9B"
    },
    creditNoteAmount: {
        marginLeft: 8
    }
}))

const LotCard = (props) => {
    const {
        lot,
        onSelectLot,
        selectedLot,
        creditNotes,
        handleClickBroken,
        deleteLot
    } = props

    const classes = useStyles()

    const isSelected = useMemo(() => {
        return selectedLot && lot.id === selectedLot.id
    }, [selectedLot, lot])

    return (
        <Box
            className={isSelected ? classes.cardSelected : classes.card}
            onClick={() => {
                onSelectLot(lot)
            }}
        >
            <p className={classes.lotTitle}>
                {`LOT ${lot.lotNumber}`}
            </p>
            <div className={classes.values}>
                <div className={classes.leftRow}>
                    <div className={classes.tableLabel}>
                        <AccessTimeIcon className={classes.icon} />
                        <span>DLC : {moment(lot.dlc).format("DD/MM/YYYY")}</span>
                    </div>
                    <div className={classes.tableLabel}>
                        <LocationOnOutlinedIcon className={classes.icon} />
                        <span>Stock : {lot?.stockZone?.name}</span>
                    </div>
                    {creditNotes && (
                        <div className={classes.tableLabel}>
                            <HighlightOff className={classes.icon} />
                            <span>Casse : {creditNotes[0]?.creditNote?.quantity}</span>
                        </div>
                    )}
                </div>
                <div>
                    <div className={classes.lastRowTableValue}>
                        <span className={!!creditNotes && classes.strikeThrough}>{lot.quantity}</span>
                        {!!creditNotes && (
                            <span className={classes.creditNoteAmount}>
                                {lot.quantity - creditNotes[0]?.creditNote?.quantity}
                            </span>
                        )}
                        <span className={classes.stockUnit}> ({generateStockUnitLabel(lot.orderSupplierItem)})</span>
                    </div>
                </div>
            </div>
            {isSelected && <div className={classes.actionsContainer}>
                <Button
                    className={classes.brokenButton}
                    variant="contained"
                    startIcon={<Warning />}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleClickBroken()
                    }}
                >
                    Casse
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.secondBtn}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteLot(lot)
                    }}
                >
                    Supprimer ce lot
                </Button>
            </div>}
        </Box>
    )
}

export default LotCard
