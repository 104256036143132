import React from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"

import { COLORS } from "../../../utils"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import BillingCreditNotesSiteSelector from "../../../components/BillingCreditNotes/BillingCreditNotesSiteSelector"
import BillingCreditNotesHeaderTitle from "../../../components/BillingCreditNotes/BillingCreditNotesHeaderTitle"
import { clearBillingCreditNotesFilters, clearBillingCreditNotesType, selectSitesForBillingAndCreditNotes } from "../../../actions/BillingCreditNotes/BillingCreditNotes"

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    headerBloc: {
        height: 64
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
    }
})

const BillingCreditNotes = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const sites = useSelector(state => state.sites.sites)

    const onConfirmSites = (sitesIds) => {
        batch(() => {
            // clear last saved tab
            dispatch(clearBillingCreditNotesType())
            // clear all saved filters
            dispatch(clearBillingCreditNotesFilters())
            // go to the next page
            dispatch(selectSitesForBillingAndCreditNotes(sitesIds))
        })
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerBloc}>
                <GenericTopBarHeader
                    title={<BillingCreditNotesHeaderTitle />}
                />
            </div>
            
            <div className={classes.container}>
                <BillingCreditNotesSiteSelector
                    sites={sites}
                    onSubmit={onConfirmSites}
                />
            </div>
        </div>
    )
}

export default BillingCreditNotes