import React, { useState, useEffect, useLayoutEffect } from "react"
import {connect, useSelector} from "react-redux"
import Parse from "parse"
import makeStyles from "@mui/styles/makeStyles"

import ReceptionHeader from "../../components/Reception/ReceptionHeader"
import GlobalStyles from "../../components/Reception/GlobalStyle"
import LeftSideWrapper from "../../components/Reception/LeftSideWrapper"
import RightSideWrapper from "../../components/Reception/RightSideWrapper"
import FullScreenWrapper from "../../components/FullScreenWrapper"
import Filter from "../../components/Reception/Filter"
import GroupItems from "../../components/Reception/GroupItems"
import ButtonFlaw from "../../components/Reception/ButtonFlaw"
import ActionContent from "../../components/Reception/ActionContent"
import ModalWrapper from "../../components/Reception/ModalWrapper"
import ActionHeaderText from "../../components/Reception/ActionHeaderText"
import ReceptionSnackBar from "../../components/Reception/ReceptionSnackBar"
import ActionFlawContent from "../../components/Reception/ActionFlawContent"
import { checkTablet } from "../../utils"
import {
	closeReceptionDetailSnackBar,
	showReceptionInterface,
	updateProductStock,
	updateStatus,
	filterByStatus,
	filterByNameOrCode,
	resetStatus,
	showErrors,
	loadReception,
	validateAddDisplay
} from "../../actions/Reception/reception"
import {findProductStock} from "../../utils/receptionUtils"
import { CAN_UPDATE_PRODUCT_STOCK } from "../../utils/accessRights"
import ListToShowTable from "../../components/Reception/ListToShowTable"
import { setSearchValue } from "../../utils"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

const useStyles = makeStyles(() => ({
	filter: {
		position: "relative",
		width: "57%",
		marginTop: 64
	}
}))

const Reception = (props) => {
	const {
		selectedHub,
		selectedDate,
		formattedReceptionData,
		update,
		showReceptionInterface,
		resetStatus,
		filterByStatus,
		filterByNameOrCode,
		updateStatus,
		updateProductStock,
		receptionSnackBar,
		closeReceptionDetailSnackBar,
		originalReceptionData,
		showErrors,
		canEdit,
		noDisplay,
		loadReception,
		validateAddDisplay
	} = props

	const classes = useStyles()

	// constants
	const user = Parse.User.current()
	const hub = (selectedHub)?selectedHub[0].name:""

		// selected card state
		const [selectedCard, setSelectedCard] = useState([])
	const [open, setOpen] = useState(false)
	const [openList, setOpenList] = useState(false)
	const [step, setStep] = useState(0)
	const [stepFlaw, setStepFlaw] = useState(0)
		const [status, setStatus] = useState("TODO")
	const [firstInput, setFirstInput] = useState()
	const [confirmInput, setConfirmInput] = useState()
	const [lostInput, setLostInput] = useState("")
	const [lostReason, setLostReason] = useState()
	const [lostVolume, setLostVolume] = useState()
	const [receptionData, setReceptionData] = useState(formattedReceptionData)
	const [flawProduct, setFlawProduct] = useState()
	const [flawDlc, setFlawDlc] = useState("")
	const [selectedFlaw, setSelectedFlaw] = useState([])
	const [focusSearch, setFocusSearch] = useState(true)
	const listToShow = originalReceptionData.filter(elm => !elm.display)
	const [productSelected, setProductSelected] = useState([])
	const [searchValue, updateSearchValue] = useState("")
	const productTypeOptions = useSelector(getProductTypeOptionsSelector)

		// for device connected
		const [isTablet, setIsTablet] = useState(checkTablet)

		// effect on receptionData change
	// type array of objects
		useEffect(() => {
			setReceptionData(formattedReceptionData)
		}, [update])

		// back to filter if no hub selected
	useEffect(() => {
		if (!selectedHub) {
			showReceptionInterface()
		}

		const storage = JSON.parse(localStorage.getItem("searchValue"))
		if (storage && storage.reception) {
			updateSearchValue(storage.reception)
			filterByNameOrCode(storage.reception)
		}
	}, [])

	// focus search input
	useEffect(() => {
		if (step === 0) setFocusSearch(true)
		if (step !== 0) setFocusSearch(false)
	}, [step])

		// set isTablet state
		useLayoutEffect(() => {
				const checkisTablet = () => {
						setIsTablet(checkTablet())
				}

				// title
				document.title = "Réception - KFC"

				// check size
				window.addEventListener("resize", checkisTablet)

				// remove check
				return () => window.removeEventListener("resize", checkisTablet)
		}, [])

		// open modal
		const handleClickOpenModal = () => {
				setOpen(true)
		}

	// open list modal
	const handleOpenListModal = () => {
		if (listToShow.length !== 0) {
			setOpenList(true)
		} else {
			showErrors("Aucun produit à ajouter")
		}
	}

		// close modal
		const handleCloseModal = async () => {
		if (stepFlaw > 0) {
			setStepFlaw(0)
			resetLost()

			if (open) setOpen(false)
			return
		}

				setStep((step === 0)?0:step - 1)

				// close modal then undo selection
				if (step === 1) {
					await resetStatus(selectedCard)
						resetSelection()
				}
		}

	// close list modal
	const handleCloseListModal = () => {
		setOpenList(false)
	}

		const resetSelection = () => {
			setSelectedCard([])

			if (open) setOpen(false)
		}

		// on filter by status
		const onStatusChange = (selectedStatus) => {
			setStatus(selectedStatus)
			filterByStatus(selectedStatus)
		}

	// on filter by name or code
	const onNameChange = (nameOrCode) => {
		filterByNameOrCode(nameOrCode)
		setSearchValue(nameOrCode, "reception")
	}

		// on set flaw
		const onSetFlaw = () => {
		if (canEdit) {
			setStepFlaw(1)

			// open action in modal
			if (isTablet) {
				handleClickOpenModal()
			}
		}
		}

		// action on page quit
		const onClose = () => {
				filterByNameOrCode("")
				setSearchValue("", "reception")
				showReceptionInterface()
		}

		const handleCardClick = async (element) => {
			if (selectedCard.length !== 0) await resetStatus(selectedCard)
			await updateStatus(element)

			setSelectedCard(element)
			setStep(1)

				// open action in modal
				if (isTablet) {
						handleClickOpenModal()
				}
		}

	const directValidation = async (input) => {
		await updateProductStock(selectedCard, input, null, "RECEPTION")
		resetSelection()
		setStep(0)
	}

		const handleValidate = async (e) => {
		e.preventDefault()

			if (step === 1) {
				if (selectedCard.initialVolume === parseInt(firstInput)) {
					directValidation(firstInput)
					return
				}
			}

			if (step === 2) {
				if (selectedCard.initialVolume <= parseInt(confirmInput)) {
					directValidation(confirmInput)
					return
				}

				setLostVolume(selectedCard.initialVolume - parseInt(confirmInput))
			}

			if (step === 3) {
				if (lostReason === "") {
					showErrors("Veuillez sélectionner un motif")
					return
				}

				const lost = {
					"volume": lostVolume,
					"reason": lostReason,
					"explanation": lostInput,
					"isInventory": false
				}
				await updateProductStock(selectedCard, confirmInput, lost, "RECEPTION")
				resetSelection()
				resetLost()
				setStep(0)
				return
			}

			setStep(step + 1)
		}

	const handleFlawValidate = async (e) => {
		e.preventDefault()

		let products

		switch (stepFlaw) {
			case 1:
				products = findProductStock(originalReceptionData, flawProduct)
				setSelectedFlaw(products)

				if (products.length === 0) showErrors("Ce produit n'est pas sensé être en vente")
				if (products.length === 1) {
					if (products[0].receptionStatus !== "DONE") {
						showErrors("Ce produit n'est pas encore réceptionné")
						return
					}
					setStepFlaw(3)
				}
				if (products.length > 1) setStepFlaw(2)

				return
			case 2:
				products = findProductStock(originalReceptionData, flawProduct, flawDlc)
				setSelectedFlaw(products)

				if (products.length === 0) showErrors("Aucun Produit trouvé")
				if (products.length > 0) {
					if (products[0].receptionStatus !== "DONE") {
						showErrors("Ce produit n'est pas encore réceptionné")
						return
					}

					setStepFlaw(3)
				}

				return
			case 3:
				if (lostReason === "") {
					showErrors("Veuillez sélectionner un motif")
					return
				}

				const lost = {
					"volume": lostVolume,
					"reason": lostReason,
					"explanation": lostInput,
					"isInventory": false
				}
				await updateProductStock(selectedFlaw[0], lostVolume, lost, "LOSS")
				setStepFlaw(0)
				resetLost()

				return
			default:
				setStepFlaw(stepFlaw + 1)
		}
	}

		const inputActionChange = (value) => {
			if (step === 1) setFirstInput(value)
			if (step === 2) setConfirmInput(value)
			if (step === 3) setLostInput(value)
		}

	const inputFlawActionChange = (value) => {
		if (stepFlaw === 1) setFlawProduct(value)
		if (stepFlaw === 2) setFlawDlc(value)
		if (stepFlaw === 3) setLostInput(value)
	}

	const resetLost = () => {
		setLostVolume("")
		setLostInput("")
		setLostReason("")
		setFlawDlc("")
		setFlawProduct("")
	}

	const refresh = () => {
		const storage = JSON.parse(localStorage.getItem("receptionFilter"))
		if (storage) loadReception(storage.date, storage.brand, storage.hub)
	}

	// toggle product in selection
	const toggleProduct = (product) => {
		if (productSelected.includes(product.objectId)) {
			setProductSelected(productSelected.filter(elm => elm !== product.objectId))
		} else {
			setProductSelected([...productSelected, product.objectId])
		}
	}

	const validateDisplay = async () => {
		if (productSelected.length > 0) {
			await validateAddDisplay(productSelected)
			setOpenList(false)
		} else {
			showErrors("Sélectionner des produits à afficher")
		}
	}

		const getAction = () => {
		if (stepFlaw > 0) {
			return (
				<ActionFlawContent
					stepFlaw={stepFlaw}
					handleClose={handleCloseModal}
					handleValidate={handleFlawValidate}
					setValue={inputFlawActionChange}
					isTablet={isTablet}
					flawProduct={flawProduct}
					flawDlc={flawDlc}
					lostActionInfos={[lostVolume, setLostReason, setLostVolume]}
				/>
			)
		}

				return (
						<ActionContent
								step={step}
								lostActionInfos={[lostVolume, setLostReason]}
								textHeader={(
										<ActionHeaderText
												selectedCard={selectedCard}
												productTypeOptions={productTypeOptions}
										/>
								)}
								selectedCard={selectedCard}
								openModal={() => {}}
								handleClose={handleCloseModal}
								handleValidate={handleValidate}
								setValue={inputActionChange}
								isTablet={isTablet}
								noDisplay={noDisplay}
						/>
				)
		}

		const listKeys = Object.keys(receptionData)

		return (
				<FullScreenWrapper>
						{/* page header */}
						<ReceptionHeader user={user} date={selectedDate} hub={hub} onClose={onClose}/>
						<div className={classes.filter}>
							{/* filter upon device */}
							<Filter
								onStatusChange={onStatusChange}
								onSetFlaw={onSetFlaw}
								onNameChange={onNameChange}
								status={status}
								isTablet={isTablet}
								refresh={refresh}
								focusSearch={focusSearch}
								searchValue ={searchValue}
								isInventory={false}
							/>
						</div>
					{
						stepFlaw === 0 &&
							<>
								{/* left side section */}
							<LeftSideWrapper fullWidth={isTablet}>
								<>
									{ listKeys && listKeys.map((elm, i) => {
										return (
												<GroupItems
													key={elm}
													canEdit={canEdit}
													name={elm}
													list={receptionData[elm]}
													last={(i === listKeys.length - 1)}
													isTablet={isTablet}
													onCardClick={handleCardClick}
													selectedCard={selectedCard}
												/>
											)
										})
									}

									{/* Button for flaw (break) */}
									<ButtonFlaw isTablet={true} onSetFlaw={onSetFlaw} addButton={true} addAction={handleOpenListModal}/>
								</>
							</LeftSideWrapper>

							{/* Right side section */}
							{!isTablet &&
								<RightSideWrapper>
										{getAction()}
								</RightSideWrapper>
							}
						</>
					}

					{
						stepFlaw !== 0 && !isTablet && getAction()
					}

						{/* modal for input */}
						{ isTablet &&
								<ModalWrapper
										open={open}
										handleClose={handleCloseModal}
								>
										{getAction()}
								</ModalWrapper>
						}

					{ listToShow.length > 0 &&
						<ListToShowTable
							open={openList}
							onClose={handleCloseListModal}
							data={listToShow}
							productSelected={productSelected}
							onAddProduct={toggleProduct}
							validateDisplay={validateDisplay}
						/>
					}

					{/* Error snackbar */}
					<ReceptionSnackBar data={receptionSnackBar} onClose={closeReceptionDetailSnackBar} />

						{/* apply styles adjustments */}
						<GlobalStyles />
				</FullScreenWrapper>
		)
}

export default connect((state) => {
		return {
				selectedDate: state.reception.date,
			selectedHub: state.reception.hub,
			formattedReceptionData: state.reception.receptionData,
			receptionSnackBar: state.reception.receptionDetailSnackBar,
			update: state.reception.update,
			originalReceptionData: state.reception.originalReceptionData,
			canEdit: state.app.rights[CAN_UPDATE_PRODUCT_STOCK],
			noDisplay: state.reception.noDisplay
		}
}, {
		showReceptionInterface,
	updateProductStock,
	updateStatus,
	resetStatus,
	filterByStatus,
	filterByNameOrCode,
	closeReceptionDetailSnackBar,
	showErrors,
	loadReception,
	validateAddDisplay
})(Reception)
