import React from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import DeleteIcon from "@mui/icons-material/Delete"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 1140,
        marginTop: 20
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16,
        lineHeight: "11px"
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 232,
        display: "flex",
        alignItems: "center"
    },
    flex: {
        display: "flex"
    }
}))

export const StockZoneResume = (props) => {
    const {
        stockZone,
        setEdition,
        confirmDelete,
        canEdit,
        canDelete
    } = props
    
    const classes = useStyles()

    return <>
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    {
                        stockZone.name
                            ? stockZone.name
                            : ""
                    }
                </div>
                <div className={classes.flex}>
                    {
                        canEdit &&
                        <IconButton
                            className={classes.iconContainer}
                            disableRipple={true}
                            onClick={setEdition}
                            size="large">
                            <EditIcon className={classes.icon}/>
                        </IconButton>
                    }
                    {
                        canDelete &&
                        <IconButton
                            className={classes.iconContainer}
                            disableRipple={true}
                            onClick={confirmDelete}
                            size="large">
                            <DeleteIcon className={classes.icon}/>
                        </IconButton>
                    }
                </div>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Localisation
                </div>
                <span>
                    {
                        stockZone.spot
                            ? stockZone.spot
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Température
                </div>
                <span>
                    {
                        stockZone.temperature
                            ? stockZone.temperature
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Nombre d'emplacements
                </div>
                <span>
                {
                    stockZone.storeNumber
                        ? stockZone.storeNumber
                        : ""
                }
            </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Commentaire
                </div>
                <span>
                    {
                        stockZone.comments
                            ? stockZone.comments
                            : "-"
                    }
                </span>
            </div>
        </Paper>
    </>
}

export default StockZoneResume
