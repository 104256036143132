import Parse from "parse"
import { parseLimitRequest } from "../../utils"
import moment from "moment"
import { ProductsDispatch } from "../dispatch/parseProductDispatchManager"

const ProductionItem = Parse.Object.extend("ProductionItem")

export async function getProductInCard(productId, type) {
    try {
        const date = moment().startOf("day").valueOf()
        const productionItems = await new Parse.Query(ProductionItem)
            .notEqualTo("deleted", true)
            .equalTo("itemId", productId)
            .equalTo("itemType", type)
            .greaterThanOrEqualTo("saleDate", date)
            .ascending("saleDate")
            .limit(parseLimitRequest)
            .find()

        return productionItems ? productionItems.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getProductInCard error : ", e)
        return Promise.reject(e)
    }
}

export async function findProductionItem(saleDate, itemId, brand, toJSON = true) {
    const productItem = await new Parse.Query(ProductionItem)
        .equalTo("brand", brand)
        .equalTo("itemId", itemId)
        .equalTo("saleDate", saleDate)
        .first()

    return productItem ? toJSON ? productItem.toJSON() : productItem : null
}

export async function getProductionItemByProductDispatch(productDispatchId, toJSON = true) {
    const productDispatch = new ProductsDispatch()
    productDispatch.id = productDispatchId
    const productItem = await new Parse.Query(ProductionItem)
        .equalTo("productDispatch", productDispatch)
        .first()

    return productItem ? toJSON ? productItem.toJSON() : productItem : null
}
