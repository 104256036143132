import { Add } from "@mui/icons-material"
import { Box, Fab, Table, TableBody, TableHead, TableRow, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SettingsIcon from "@mui/icons-material/Settings"
import { debounce } from "lodash"
import { goBack } from "react-router-redux"

import { addMachineModels, editMachineModel } from "../../../../../actions/Machines/Machines"
import GenericTopBarHeader from "../../../../../components/GenericTopBarHeader"
import PageHeaderTitle from "../../../../../components/PageHeaderTitle"
import { COLORS } from "../../../../../utils"
import MachineModelFormModal from "./MachineModelFormModal"
import { getMachineModelsSelector, getMachineTypeSelector } from "../../../../../reducers/Machines/machines"
import { filterMachineModels } from "../../../../../utils/machinesutils"
import GenericHeaderReturnButton from "../../../../../components/GenericHeaderReturnButton"
import { StyledTableCell } from "../../../../../components/styled/StyledTableCell"

const COL_WIDTH = 100 / 5 + "%"
const sx = {
  genericHeaderContainer: {
    height: 64,
    width: "100%",
},
  firstColumn: {
    width: COL_WIDTH,
    pl: 5,
  },
  column: {
    width: COL_WIDTH
  },
  row: {
    cursor: "pointer",
  }
}

const MachineModels = () => {
  const dispatch = useDispatch()
  const [openCreationDialog, setOpenCreationDialog] = useState(false)
  const machineType = useSelector(getMachineTypeSelector)
  const machineModels = useSelector(getMachineModelsSelector)
  const [data, setData] = useState([])
  const [selectedMachineModel, setSelectedMachineModel] = useState(null)

  useEffect(() => {
    setData(machineModels)
  }, [machineModels])

  const toggleDialog = () => {
    if (selectedMachineModel) {
      setSelectedMachineModel(null)
    }
    setOpenCreationDialog(!openCreationDialog)
  }

  const onSubmit = (values) => {
    // edition
    if (selectedMachineModel) {
      dispatch(editMachineModel(selectedMachineModel.objectId, values))
      return
    }

    // creation
    dispatch(addMachineModels(values))
  }

  const onBack = () => {
    dispatch(goBack())
  }
  
  const onRowClick = (machineModel) => {
    setSelectedMachineModel(machineModel)
    toggleDialog()
  }

  // search 500ms after the user typed
  const onSearch = debounce((e) => {
    const filteredMachineModels = filterMachineModels(machineModels, e.target.value)
    setData(filteredMachineModels)
  }, 500)

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box sx={sx.genericHeaderContainer}>
          <GenericTopBarHeader
            leftAction={<GenericHeaderReturnButton handleClick={onBack}/>}
            title={<PageHeaderTitle title="Réglages - Machine" subtitle={machineType.name} icon={<SettingsIcon />} color={COLORS.HEAD_SEAZON} />}
          />
      </Box>

      <Box>
        <Box sx={{ pl: 6, pb: 3, pt: 1 }}>
          <TextField
            variant="standard"
            onChange={onSearch}
            label="Modèle"
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={sx.firstColumn} align="left">Modèle</StyledTableCell>
              <StyledTableCell align="left" sx={sx.column}>Type de machine</StyledTableCell>
              <StyledTableCell align="left" sx={sx.column}>Capacité max (en kg)</StyledTableCell>
              <StyledTableCell align="left" sx={sx.column}>Temps de nettoyage entre 2 utilisations</StyledTableCell>
              <StyledTableCell align="left" sx={sx.column}>Taux de disponibilité</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((machineModel) => (
              <TableRow key={machineModel.objectId} bgcolor="#fff" sx={sx.row} onClick={() => onRowClick(machineModel)}>
                <StyledTableCell align="left" sx={sx.firstColumn}>{machineModel.name}</StyledTableCell>
                <StyledTableCell align="left" sx={sx.column}>{machineType.name}</StyledTableCell>
                <StyledTableCell align="left" sx={sx.column}>{machineModel.capacity}</StyledTableCell>
                <StyledTableCell align="left" sx={sx.column}>{machineModel.cleanTime}</StyledTableCell>
                <StyledTableCell align="left" sx={sx.column}>{machineModel.availabilityRate}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    
      <Box alignSelf="stretch" flex={1} position="relative" bgcolor={COLORS.GREY_IMAGE_LIST}>
        <Box sx={{ position: "absolute", left: 30, bottom: 40 }}>
          <Fab color="primary" onClick={toggleDialog}>
            <Add />
          </Fab>
        </Box>
        <MachineModelFormModal
          open={openCreationDialog}
          onClose={toggleDialog}
          onConfirm={onSubmit}
          machineType={machineType}
          machineModel={selectedMachineModel}
        />
      </Box>
    </Box>
  )
}

export default MachineModels