import React, { useLayoutEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Button from "@mui/material/Button"
import {
    computeOrderSupplierItemWeight,
    generateOrderUnitLabel,
    getSelectedCardQuantity,
    selectedCardIsInCart
} from "../../../utils/ordersUtils"
import moment from "moment/moment"

const useStyles = makeStyles({
    container: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        backgroundColor: COLORS.WHITE
    },
    header: {
        backgroundColor: COLORS.PRIMARY_COLOR,
        color: COLORS.WHITE,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "24px",
        padding: "17px 24px"
    },
    infoBloc: {
        padding: "40px 24px",
        flex: "1 1 auto"
    },
    quantityHeading: {
        fontSize: 14,
        fontWeight: 500,
        color: COLORS.LABEL_GREY
    },
    inputBloc: {
        width: "100%",
        marginTop: 24,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    formControl: {
        maxWidth: 243,
        paddingTop: 30
    },
    inputLabel: {
        top: -22,
        fontSize: 12,
        fontWeight: 400
    },
    weightLabel: {
        fontSize: 12,
        color: COLORS.GREY_700
    },
    weightValue: {
        marginTop: 3,
        fontWeight: "bold",
        color: COLORS.BLACK,
        fontSize: 14
    },
    weight: {
        fontWeight: "bold",
        color: COLORS.BLACK,
        fontSize: 14
    },
    receptionDate: {
        color: COLORS.BLACK_500,
        fontWeight: 400,
        fontFamily: "Roboto",
        fontSize: 12,
        textTransform: "capitalize"
    },
    actionBloc: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "33px 30px",
        "& > :first-child": {
            marginRight: 30
        }
    },
    stockBloc: {
        marginBottom: 30
    },
    inputWeightLabel: {
        "& > p": {
            color: COLORS.BLACK_500,
            fontWeight: 400,
            fontFamily: "Roboto",
            fontSize: 12
        }
    }
})

const OrderCartView = (props) => {
    const {
        selectedCard,
        cart,
        actions,
        ordersComing
    } = props

    const classes = useStyles()

    const [quantity, setQuantity] = useState(getSelectedCardQuantity(selectedCard, cart))
    const [isInCart, setIsInCart] = useState(selectedCardIsInCart(selectedCard, cart))

    useLayoutEffect(() => {
        setIsInCart(selectedCardIsInCart(selectedCard, cart))
    }, [selectedCard, cart])

    const handleQuantityChange = (value) => {
        if ((typeof +value === "number" && value > 0) || (value === "")) {
            setQuantity(value)
        }
    }

    const cancel = () => {
        setQuantity("")
        actions({
            method: "CANCEL_CART"
        })
    }

    const addCart = () => {
        actions({
            method: "UPDATE_CART",
            data: {
                card: selectedCard,
                quantity: quantity
            }
        })
        setQuantity("")
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                {`${selectedCard.name} (${selectedCard.supplier.name})`}
            </div>
            <div className={classes.infoBloc}>
                <div className={classes.stockBloc}>
                    <Typography className={classes.quantityHeading}>
                        Informations sur le stock :
                    </Typography>
                    <div className={classes.inputBloc}>
                        <div>
                            <Typography className={classes.weightLabel}>
                                Stock actuel
                            </Typography>
                            <Typography className={classes.weightValue}>
                                <span className={classes.weight}>{selectedCard?.stockQuantity}</span>
                                <span className={classes.receptionDate}> kg</span>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.inputBloc}>
                        <div>
                            <Typography className={classes.weightLabel}>
                                Réceptions à venir
                            </Typography>
                            {ordersComing.map((order, i) => {
                                const orderSupplierItem = order.supplierItems.find(item => item.supplierItem.supplierItemId === selectedCard.objectId)
                                const quantity = orderSupplierItem?.quantities?.order || 0
                                const unitWeight = selectedCard.units?.order?.weight || 1
                                return (
                                    <Typography key={i} className={classes.weightValue}>
                                        <span className={classes.weight}>{quantity * unitWeight}</span>
                                        {/*<span className={classes.receptionDate}> kg ({order.receptionDate && moment(order.receptionDate).format("DD/MM")})</span>*/}
                                        {/*moment("01-06-2018", 'MM-DD-YYYY').locale("fr").format("dddd")*/}
                                        <span className={classes.receptionDate}> kg ({order.receptionDate && moment(order.receptionDate).locale("fr").format("dddd DD/MM")})</span>
                                    </Typography>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className={classes.quantityBloc}>
                    <Typography className={classes.quantityHeading}>
                        Ajouter la quantité à commander :
                    </Typography>
                    <div className={classes.inputBloc}>
                        <FormControl className={classes.formControl} variant="standard">
                            <InputLabel className={classes.inputLabel}>
                                Quantité commandée
                            </InputLabel>
                            <TextField
                                variant="standard"
                                value={quantity}
                                className={classes.weight}
                                type="number"
                                onChange={(e) => handleQuantityChange(e.target.value)}
                                InputProps={{
                                    type: "number",
                                    className: classes.weight,
                                    pattern: "[0-9]*",
                                    endAdornment: <InputAdornment className={classes.inputWeightLabel}>{` / ${generateOrderUnitLabel(selectedCard, "order")}`}</InputAdornment>
                                }}
                            />
                        </FormControl>
                        <div>
                            <Typography className={classes.weightLabel}>
                                Poids global
                            </Typography>
                            <Typography className={classes.weightValue}>
                                {computeOrderSupplierItemWeight(selectedCard, quantity, "order")}
                                <span className={classes.receptionDate}> kg</span>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.actionBloc}>
                <Button
                    color="primary"
                    onClick={cancel}
                >
                    ANNULER
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!quantity}
                    onClick={addCart}
                >
                    {
                        isInCart
                            ? <span>METTRE A JOUR</span>
                            : <span>AJOUTER</span>
                    }
                </Button>
            </div>
        </div>
    )
}

export default OrderCartView
