import { productTagsTypes } from "../../utils/productTagsTypes"
import { getData } from "../index"

const types = productTagsTypes.filter(elem => elem.enableValue === true)
const defaultState = {
    productsTags: [],
    productTypesOptions: [],
    types: types
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "RESOURCES_PRODUCTS_TAGS_LOADED": {
            return {
                ...state,
                productsTags: action.productsTags
            }
        }
        case "PRODUCT_TAGS_OPTIONS_LOADED": {
            return {
                ...state,
                productTagsOptions: action.productTagsOptions
            }
        }
        case "RESOURCES_PRODUCT_TAG_SAVED": {
            return {
                ...state
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export function getProductTag(state, id) {
    return state.productsTags.productsTags.find(productTag => productTag.objectId === id)
}

export function getProductTagsOptionsSelector(state, errorIfNotFound = false) {
    return getData(state, "productsTags.productTagsOptions", errorIfNotFound && "No product tags options loaded")
}
