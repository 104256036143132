import React from "react"
import PlanningBrandSelector from "../../Planning/PlanningBrandSelector"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import { FormControl } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	container: {
		color: COLORS.BLACK,
		boxShadow: "0 0 3px rgba(0,0,0,.2)",
		backgroundColor: COLORS.WHITE,
		padding: "15px 30px 25px 30px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	title: {
		fontSize: 20,
		fontFamily: "Roboto",
		fontWeight: 500
	},
	select: {
		width: 180,
	},
	filterContainer: {
		alignItems: "flex-end",
		marginTop: 10,
		width: "100%",
		display: "flex",
	},
	selectContainer: {
		margin: "5px 25px 0 25px"
	},
	separator: {
		marginTop: 20,
		marginBottom: 20,
		width: "100%",
		height: 1,
		backgroundColor: COLORS.GREY_FLAW_ICON
	},
	submitButton: {
		marginTop: 21,
		width: 328,
		height: 38,
		borderRadius: 0
	},
})

const InventoryOutputFilter = (props) => {
	const classes = useStyles()
	const {
		hubs,
		brand,
		selectedHub,
		onBrandClick,
		onHubChange,
		onValidate
	} = props

	const renderSelectResult = (selected, resources) => {
		if (selected.includes("ALL")) {
			return resources[0].label
		}
		else {
			return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
		}
	}

	const renderData = (objects, selectedObjects = false) => {
		return (
			objects.map((item, index) =>
				<MenuItem
					value={item.value}
					key={index}
				>
					{selectedObjects &&
						<Checkbox
							checked={selectedObjects.includes(item.value)}
							color="secondary"
						/>
					}
					<ListItemText primary={item.label} />
				</MenuItem>
			)
		)
	}

	return (
		<>
			<div className={classes.container}>
				<h3 className={classes.title}>
					Sélectionnez le centre de distribution
				</h3>
				<div className={classes.filterContainer}>
					{/* Brand */}
					<FormControl className={classes.selectContainer} variant="standard">
						<PlanningBrandSelector
							brand={brand}
							onBrandClick={onBrandClick}
							activeBrandsOnly
						/>
					</FormControl>

					{/* Hub */}
					<FormControl className={classes.selectContainer} variant="standard">
						<InputLabel htmlFor="hub">Hub</InputLabel>
						<Select
							variant="standard"
							className={classes.select}
							value={selectedHub}
							onChange={(e) => onHubChange(e.target.value)}
							inputProps={{
								name: "hub",
								id: "hub",
							}}
							renderValue={
								selected => renderSelectResult(selected, hubs)
							}
						>
							{renderData(hubs)}
						</Select>
					</FormControl>
				</div>
				<span className={classes.separator} />
				{/* validate */}
				<Button
					variant="contained"
					color="primary"
					className={classes.validateButton}
					type="submit"
					onClick={onValidate}
				>
					accéder à l'inventaire
				</Button>
			</div>
		</>
	)
}

export default InventoryOutputFilter
