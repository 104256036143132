import Parse from "parse"
import { KFC_BRANDS, roundNumber } from "../utils"
import { getPVC } from "./recipes"

const SubcontractorsProducts = Parse.Object.extend("SubcontractorsProducts")

export async function checkDuplicate(subcontractorProduct) {
    const currentSubcontractorProduct = await new Parse.Query(SubcontractorsProducts)
        .include("name")
        .equalTo("name", subcontractorProduct.get("name"))
        .equalTo("type", subcontractorProduct.get("type"))
        .equalTo("brand", subcontractorProduct.get("brand"))
        .first()

    return currentSubcontractorProduct ? true : false
}

export function getSubContractorProductCostDataColumn(jsonSubcontractorProduct, subcontractorIngredientCost, packagingCost) {
    const data = [
        {label: "Coût du produit", value: "--"},
        {label: "Packaging", value: "--"},
        {label: "Total des coûts", value: "--"},
        {label: "Prix de vente conseillé", value: "--"},
        {label: "Prix de vente", value: "--"}
    ]

    const brand = jsonSubcontractorProduct.brand
    const KFCBRAND = KFC_BRANDS.find(elem => elem.name === brand)

    //PRICE
    const price = jsonSubcontractorProduct.price
    const HTPrice = jsonSubcontractorProduct.tva && price && price !== 0 ? price / (1 + (parseFloat(jsonSubcontractorProduct.tva) / 100)) : null

    //PVC
    const pvcValue = KFCBRAND && KFCBRAND.priceEnabled
        ? getPVC("SUBCONTRACTORPRODUCT", jsonSubcontractorProduct.type, subcontractorIngredientCost).pvc
        : null
    const pvc = pvcValue
        ? `${pvcValue}€`
        : "--"
    const HTPVC = jsonSubcontractorProduct.tva && (!price || price === 0) ? pvcValue / (1 + (parseFloat(jsonSubcontractorProduct.tva) / 100)) : null
    const finalPrice = HTPrice || HTPVC

    //PACKAGING / SUB_PACKAGING
    const packagingCostPct = KFCBRAND && KFCBRAND.priceEnabled && finalPrice
        ? roundNumber(packagingCost / finalPrice * 100, 2) + "%"
        : ""

    //FOODCOST
    const subcontractorIngredientCostPct = KFCBRAND && KFCBRAND.priceEnabled && finalPrice
        ? roundNumber(subcontractorIngredientCost / finalPrice * 100, 2) + "%"
        : ""

    data[0]["value"] = {pct: subcontractorIngredientCostPct, value: subcontractorIngredientCost, redPCT: 24}
    data[1]["value"] = {pct: packagingCostPct, value: packagingCost}
    data[2]["value"] = {value: roundNumber(Number(subcontractorIngredientCost) + Number(packagingCost), 3), redPCT: 50}
    data[3]["value"] = pvc
    data[4]["value"] = price ? price + "€" : "--"

    return data
}

export const filterSubcontractorProductsBySupplier = (subcontractorProducts, suppliers) => {
    const filteredSubcontractorProducts = [] 
    
    suppliers.forEach(supplier => {
        let items = subcontractorProducts.filter(subcontractorProduct => subcontractorProduct.name.supplier && subcontractorProduct.name.supplier.objectId === supplier)
        if (items.length > 0) {
            filteredSubcontractorProducts.push(...items)
        }
    })

    return filteredSubcontractorProducts
}

export const filterSubcontractorProductsByType = (subcontractorProducts, types) => {
    const filteredSubcontractorProducts = [] 
    
    types.forEach(type => {
        let items = subcontractorProducts.filter(subcontractorProduct => subcontractorProduct.type && subcontractorProduct.type === type)
        if (items.length > 0) {
            filteredSubcontractorProducts.push(...items)
        }
    })

    return filteredSubcontractorProducts
}

export const computeSubcontractorProductNetWeight = (units) => {
    return units.stock.weight / units.stock.unity.quantity
}
