import React, { PureComponent } from "react"
import { withStyles } from "@mui/styles"
import Dialog from "@mui/material/Dialog"
import { connect } from "react-redux"
import DialogContent from "@mui/material/DialogContent"
import AddButton from "../../components/Rights/AddButton"
import RolesTable from "../../components/Rights/RolesTable"
import ConfirmDelete from "../../components/Rights/ConfirmDelete"
import RoleForm from "./RoleForm"
import RightsPage from "./RightsPage"
import {
    saveRole,
    updateRightOfRole,
    deleteRole
} from "../../actions/Rights/Rights"

const styles = {
    modalStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

export class RightsRolesTab extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isCreatingRole: false,
            data: props.tableRolesRows,
            role: null,
            isDeleting: false
        }
    }
    _clickAddButton = () => {
        const { isCreatingRole } = this.state

        this.setState({
            isCreatingRole: !isCreatingRole,
            role: null
        })
    }

    _onCloseModal = () => {
        this.setState({
            isCreatingRole: false,
            isDeleting: false
        })
    }
    _saveRole = (values) => {
        const { saveRole } = this.props
        const { role } = this.state
        const objectId = role ? role.objectId : null

        saveRole(values, objectId)
        this.setState({
            isCreatingRole : false,
            role: null
        })
    }

    _onClickDeleteButton = () => {
        this.setState({
            isDeleting: false,
            role: null
        })
    }

    _onChange = (id, right) => {
        const { updateRightOfRole } = this.props

        updateRightOfRole(id, right)
    }

    _onClickDeleteIcon = (id) => {
        const { roles } = this.props

        const role = roles.find(elem => elem.objectId === id)
        this.setState({
            role: role,
            isDeleting: true
        })
    }

    _onCancelConfirmDeleteModal = () => {
        this.setState({
            isDeleting: false,
            role: null
        })
    }

    _onDeleteRole = (role) => {
        const { deleteRole } = this.props

        deleteRole(role.objectId)
        this.setState({
            isDeleting: false,
            isCreatingRole: false,
            role: null
        })
    }

    render() {
        const {
            classes,
            tableRolesColumns,
            tableRolesRows
        } = this.props
        const {
            isCreatingRole,
            role,
            isDeleting
        } = this.state

        return (
            <>
                <RightsPage tabValue="roles">
                    <>
                        <AddButton onClick={this._clickAddButton}/>
                        <RolesTable
                            tableRows={tableRolesRows}
                            tableColumns={tableRolesColumns}
                            onChange={this._onChange}
                            onClickDeleteIcon={this._onClickDeleteIcon}
                        />
                    </>
                    <Dialog
                        aria-labelledby="add-roles-modal"
                        aria-describedby="simple-modal-description"
                        open={isCreatingRole || isDeleting}
                        onClose={this._onCloseModal}
                        className={classes.modal}
                    >
                        <DialogContent className={classes.modalStyle}>
                            {
                                isDeleting ?
                                    <ConfirmDelete
                                        role={role}
                                        onConfirm={() => this._onDeleteRole(role)}
                                        onCancel={() => this._onCancelConfirmDeleteModal()}
                                    />
                                    : isCreatingRole
                                        ?
                                            <RoleForm
                                                saveRole={this._saveRole}
                                                onDelete={this._onClickDeleteButton}
                                                onClose={this._clickAddButton}
                                            />
                                        : null
                            }
                        </DialogContent>
                    </Dialog>
                </RightsPage>
            </>
        )
    }
}

const styledRightsRolesTab = withStyles(styles)(RightsRolesTab)

export default connect(state => {
    return {
        roles: state.rights.roles,
        rights: state.rights.rights,
        tableRolesColumns: state.rights.tableRolesColumns,
        tableRolesRows: state.rights.tableRolesRows
    }
}, {
    saveRole,
    deleteRole,
    updateRightOfRole
})(styledRightsRolesTab)
