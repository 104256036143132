import React, { useState } from "react"

import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material"
import { debounce } from "lodash"
import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { goBack } from "react-router-redux"

import { getLotsEventsSelector, getLotsFiltersSelector } from "../../reducers/LotInventory/LotInventory"
import { COLORS } from "../../utils"
import { getStockUnitWeight } from "../../utils/ordersUtils"
import Header from "../../components/LotInventory/Header"
import FullScreenWrapper from "../../components/FullScreenWrapper"
import { loadLotsEventsMain } from "../../actions/LotInventory/LotInventory"
import TablePagination from "../../components/TablePagination"

const sx = {
  tableHeadCell: {
    bgcolor: COLORS.PRIMARY_COLOR,
    color: COLORS.WHITE,
  }
}

const LotOutputEvents = () => {
  const site = useSelector(state => state.lotInventory.site)
  const stockZone = useSelector(state => state.lotInventory.stockZone)
  const date = useSelector(state => state.lotInventory.date)
  const lotsEvents = useSelector(getLotsEventsSelector)
  const filters = useSelector(getLotsFiltersSelector)

  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState("")
  
  const dispatch = useDispatch()

  const _goBack = () => dispatch(goBack())

  const decrementPage = () => {
    setPage(page - 1)
    dispatch(loadLotsEventsMain({ page: (page - 1) * rowsPerPage, rowsPerPage, search }))
  }

  const incrementPage = () => {
    setPage(page + 1)
    dispatch(loadLotsEventsMain({ page: (page + 1) * rowsPerPage, rowsPerPage, search }))
  }

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value)
    dispatch(loadLotsEventsMain({ rowsPerPage: value , search }))
  }

  // search 500ms after the user typed
  const onSearch = debounce((event) => {
    const { value } = event.target
    setSearch(value)
    dispatch(loadLotsEventsMain({ search: value, page, rowsPerPage }))
  }, 500)

  return (
    <FullScreenWrapper>
      <Header
        onClickReturn={_goBack}
        site={site}
        stockZone={stockZone}
        date={moment(date).startOf("day").format("DD/MM/YYYY")}
        fixed={false}
      />
      <Box bgcolor={COLORS.GREY_IMAGE_LIST} className="stretchSelf">
        <Box display="flex" justifyContent="space-between" sx={{ px: 5, py: 3 }}>
          <div>
            <TextField
              variant="standard"
              onChange={onSearch}
              label="Rechercher un article fournisseur"
              sx={{ width: 270 }}
            />
          </div>
        </Box>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...sx.tableHeadCell, fontWeight: 600 }} align="center">Nom de l'article</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Numéro de lot</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">DLC</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Date de réception du lot</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Quantité sortie</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Unité de conditionnement</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Poids total sorti</TableCell>
                <TableCell sx={sx.tableHeadCell} align="center">Date de la sortie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lotsEvents?.map((lotEvent, index) => ( 
                <TableRow
                  key={lotEvent.lot.objectId + index}
                  bgcolor="#fff"
                >
                  <TableCell align="center" sx={{ fontWeight: 600 }}>{lotEvent.lot.orderSupplierItem?.name}</TableCell>
                  <TableCell align="center">{lotEvent.lot.lotNumber}</TableCell>
                  <TableCell align="center">{dayjs(lotEvent.lot.dlc).format("DD/MM/YY")}</TableCell>
                  <TableCell align="center">{dayjs(lotEvent.lot.receptionDate).format("DD/MM/YY")}</TableCell>
                  <TableCell align="center">{lotEvent.event.quantity}</TableCell>
                  <TableCell align="center">{getStockUnitWeight(lotEvent.lot.orderSupplierItem)}</TableCell>
                  <TableCell align="center">
                    {(lotEvent.lot.orderSupplierItem.units?.stock?.weight || 1) * lotEvent.event.quantity}kg
                  </TableCell>
                  <TableCell align="center">{dayjs(lotEvent.event.date).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
            <TablePagination
              rowsPerPage={rowsPerPage}
              dataPage={page}
              handleChangeRowPerPage={handleChangeRowsPerPage}
              decrementPage={decrementPage}
              incrementPage={incrementPage}
              total={filters.total}
            />
        </Box>
      </Box>
    </FullScreenWrapper>
  )
}

export default LotOutputEvents