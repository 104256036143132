import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
    Button,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../../utils"
import { getSelectedAmbientsLabel } from "../../../utils/dispatchUtils"
import { SelectAmbientsSchema } from "../../../utils/yupValidators"

const initialValues = {
    productTypes: [],
}

const useStyles = makeStyles({
    dialog: {
        width: 500,
    },
    formControl: {
        marginLeft: "unset",
        width: "100%",
        marginTop: 20,
        "& *": {
            fontSize: 16,
            color: COLORS.BLACK,
        },
        "& .MuiFormLabel-root": {
            position: "relative",
            height: 20,
            display: "flex",
            alignItems: "center",
            transform: "none",
            marginTop: 4
        }
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    dialogDescription: {
        color: COLORS.DARK_BLACK
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 2,
        fontSize: 12
    }
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300
        }
    }
}

export const AddToDispatchByAlgoModal = (props) => {
    const {
        onClose,
        open,
        onSave,
        productTypeOptions,
    } = props

    const formikRef = useRef()

    const classes = useStyles()


    const handleConfirm = () => {
        formikRef.current.submitForm()
        if (formikRef.current.values.productTypes.length === 0) return
        onClose()
    }

    const handleSubmit = (values) => {
        onSave(values)
    }

    const handleCancel = () => {
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialog }}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                Ajout au dispatch par l'algorithme
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogDescription}>
                    Indiquez quels types de produits sous-traitants vous souhaitez ajouter au dispatch.
                </DialogContentText>
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={SelectAmbientsSchema}
                    >
                    {({
                        values,
                        handleChange,
                        errors,
                    }) => {
                        return (
                            <Form>
                                <FormControl className={classes.formControl} variant="standard">
                                    <InputLabel>
                                        Type de produits
                                    </InputLabel>
                                    <Select
                                        variant="standard"
                                        name="productTypes"
                                        multiple
                                        value={values.productTypes}
                                        onChange={handleChange}
                                        input={<Input />}
                                        renderValue={(selected) => getSelectedAmbientsLabel(selected, productTypeOptions)}
                                        MenuProps={MenuProps}
                                        >
                                        {productTypeOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Checkbox
                                                    checked={values.productTypes.indexOf(option.value) > -1}
                                                    color="secondary"
                                                />
                                                <ListItemText primary={option.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.productTypes ?
                                    (
                                        <div className={classes.errorField}>{errors.productTypes}</div>
                                    )
                                    : null
                                }
                                </FormControl>
                            </Form>
                        )}}
                    </Formik>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained">
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddToDispatchByAlgoModal
