import React, {useEffect, useState} from "react"
import Paper from "@mui/material/Paper"
import ReceptionGapFilter from "../../../components/Reception/ReceptionGapFilter"
import ReceptionCheckTable from "../../../components/Reception/ReceptionCheckTable"
import CheckCommentModal from "../../../components/Reception/CheckCommentModal"
import {connect} from "react-redux"
import { changeDate, checkEvent, filterByNameOrCode, brandChange } from "../../../actions/Reception/receptionGap"
import { CAN_CHECK_EVENT_PRODUCT_STOCK_GAP } from "../../../utils/accessRights"
import {getBrandBy, setSearchValue} from "../../../utils"
import { productType } from "../../../utils/dispatchUtils"
import { sectors } from "../../../utils/planning"

const sx = {
	paper: {
		width: "100%"
	}
}

const ReceptionGapTable = (props) => {
	const {
		brand,
		tableType,
		startDate,
		endDate,
		nameOrCode,
		changeDate,
		data,
		filterByNameOrCode,
		checkEvent,
		hubs,
		canCheck,
		brandChange
	} = props

	const [selectedElement, setSelectedElement] = useState()
	const [isChecking, setIschecking] = useState(false)
	const [selectedHubs, setHubs] = useState(() => {
		const selectedHubs = Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : []
		selectedHubs.push("ALL")

		return selectedHubs
	})

	const [formatedHubs, setFormatedHubs] = useState(
		[
			{
				value: "ALL",
				label: "Tous"
			}, ...hubs.map(el => ({
				value: el.objectId,
				label: el.name
			}))
		]
	)
	const [selectedBrand, setSelectedBrand] = useState(brand)
	const [allSectors, setSectors] = useState(sectors)
	const [selectedSectors, setSelectedSectors] = useState(sectors.map(elem => elem.value))
	const [selectedProductTypes, setSelectedProductTypes] = useState(productType.map(elem => elem.value))

	useEffect(() => {
		const newSectors = ["ALL", ...Array.from(new Set(hubs.map(el => el.sector)))]
		const newHubs = ["ALL", ...(Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : [])]

		setSelectedBrand(brand)
		setHubs(newHubs)
		setFormatedHubs([
			{
				value: "ALL",
				label: "Tous"
			}, ...hubs.map(el => ({
				value: el.objectId,
				label: el.name
			}))
		])
		setSelectedSectors(newSectors)
		setSectors(sectors.filter(elm => newSectors.includes(elm.value)))
	}, [brand])

	const onCheckEvent = async (element) => {
		setSelectedElement(element)

		if (canCheck && tableType === "TO_CHECK") toggleCheckComment()
	}

	// on filter by name or code
	const onNameChange = (nameOrCode) => {
		filterByNameOrCode(nameOrCode)
		setSearchValue(nameOrCode, "receptionGap")
	}

	const toggleCheckComment = () => {
		setIschecking(!isChecking)
	}

	const onBrandClick = async (brandSelected) => {
		await brandChange(brandSelected.name, startDate, endDate)
	}

	const onSectorsChange = (eventSectors) => {
		const newSectors = checkInclude(sectors, selectedSectors, eventSectors)
		const newHubs = [{
			value: "ALL",
			label: "Tous"
		},...hubs.filter(el => newSectors.includes(el.sector)).map(el => ({
			value: el.objectId,
			label: el.name
		}))]

		setHubs(newHubs.map(elem => elem.value))
		setSelectedSectors(newSectors)
		setFormatedHubs(newHubs)
	}

	const onHubsChange = (eventHubs) => {
		setHubs(checkInclude(formatedHubs, selectedHubs, eventHubs))
	}

	const onProductTypesChange = (eventProductTypes) => {
		setSelectedProductTypes(checkInclude(productType, selectedProductTypes, eventProductTypes))
	}

	const checkInclude = (source, selected, value) => {
		let newSelected = value

		if (!selected.includes("ALL") && value.includes("ALL")) {
			newSelected = source.map(elem => elem.value)
		}
		else if (selected.includes("ALL") && !value.includes("ALL")) {
			newSelected = []
		}
		else if (!newSelected.includes("ALL") && newSelected.length === source.length -1) {
			newSelected.push("ALL")
		}
		else if (newSelected.includes("ALL") && newSelected.length < source.length) {
			newSelected = newSelected.filter(elem => elem !== "ALL")
		}

		return newSelected
	}

	const filterByHubsAndTypes = (data) => {
		let newData = data

		newData = newData.filter(elm => selectedHubs.includes(elm.idHub))
		newData = newData.filter(elm => selectedProductTypes.includes(elm.productType))

		return newData
	}

	const completeBrand = getBrandBy("name", selectedBrand)

	return (
		<Paper elevation={0} sx={sx.paper}>
			{/* Filter */}
			<ReceptionGapFilter
				brand={completeBrand}
				startDate={startDate}
				endDate={endDate}
				nameOrCode={nameOrCode}
				changeDate={changeDate}
				sectors={allSectors}
				productTypes={productType}
				selectedSectors={selectedSectors}
				selectedHubs={selectedHubs}
				selectedProductTypes={selectedProductTypes}
				hubs={formatedHubs}
				onBrandClick={onBrandClick}
				onNameChange={onNameChange}
				onSectorsChange={onSectorsChange}
				onHubsChange={onHubsChange}
				onProductTypesChange={onProductTypesChange}
			/>

			{/* Table */}
			<ReceptionCheckTable
				onCheckEvent={onCheckEvent}
				data={filterByHubsAndTypes(data)}
				tableType={tableType}
			/>

			<CheckCommentModal
				onClose={toggleCheckComment}
				onValidate={checkEvent}
				isChecking={isChecking}
				selectedElement={selectedElement}
			/>
		</Paper>
	)
}

export default connect((state) => {
	return {
		startDate: state.receptionGap.startDate,
		endDate: state.receptionGap.endDate,
		nameOrCode: state.receptionGap.nameOrCode,
		hubs: state.receptionGap.hubs,
		brand: state.receptionGap.brand,
		canCheck: state.app.rights[CAN_CHECK_EVENT_PRODUCT_STOCK_GAP]
	}
}, {
	changeDate,
	checkEvent,
	filterByNameOrCode,
	brandChange
})(ReceptionGapTable)