import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

export const DatePickerField = (props) => {
    const {field, form, ...other} = props
    const currentError = form.errors[field.name]

    return (
        <DatePicker
            disableCloseOnSelect={false}
            showToolbar={false}
            variant="inline"
            disablePast
            name={field.name}
            value={field.value}
            inputFormat="DD/MM/YYYY"
            error={Boolean(currentError)}
            onError={error => {
                if (error !== currentError) {
                    form.setFieldError(field.name, error)
                }
            }}
            renderInput={(params) => <TextField {...params} variant="standard" helperText={currentError} />}
            onChange={date => form.setFieldValue(field.name, date, false)}
            {...other}
        />
    )
}

export default DatePickerField
