import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import SecondaryDLCFilterForm from "./SecondaryDLCFilterForm"
import { getSecondaryDLCSitesSelector, getSecondaryDLCKitchenAreaSelector, getSecondaryDLCSiteSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { Formik } from "formik"
import * as Yup from "yup"
import { showSecondaryDLC, updateSecondaryDLCFilters } from "../../actions/SecondaryDLC/secondaryDLC"

const SecondaryDLCFilterFormWrapper = () => {

  const sites = useSelector(getSecondaryDLCSitesSelector)
  const selectedSite = useSelector(getSecondaryDLCSiteSelector)
  const selectedKitchenArea = useSelector(getSecondaryDLCKitchenAreaSelector)
  const [kitchenAreas, setKitchenAreas] = useState([])

  useEffect(() => {
    const updatedKitchenAreas = sites.reduce((acc, site) => {
      return [...acc, ...site.kitchenAreas]
    }, [])
    setKitchenAreas(updatedKitchenAreas)
  }, [sites])

  const dispatch = useDispatch()

  const SecondaryDLCSchema = Yup.object().shape({
    site: Yup.string().required("Veuillez sélectionner un site"),
    kitchenArea: Yup.string()
  })


	const _submitForm = (values) => {
		const site = sites.find(site => site.objectId === values.site)
		const kitchenArea = kitchenAreas.find(kitchenArea => kitchenArea.objectId === values.kitchenArea)
		// save filters into local storage
    updateSecondaryDLCFilters(site, kitchenArea)
    // redirection
    dispatch(showSecondaryDLC(values.site, values.kitchenArea))
	}

  return (
    <Formik
      validationSchema={SecondaryDLCSchema}
      initialValues={{ 
        sites, 
        site: selectedSite?.objectId || sites[0]?.objectId || "",
        kitchenArea: selectedKitchenArea?.objectId || sites[0]?.kitchenAreas[0]?.objectId || ""
      }}
      onSubmit={_submitForm}
    >
      {({ values, setFieldValue, errors, handleChange, handleSubmit }) => {
        return (
          <SecondaryDLCFilterForm
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            sites={sites}
          />
        )
      }}
      </Formik>
  )
}

export default SecondaryDLCFilterFormWrapper