import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import { DeleteOutline, Edit } from "@mui/icons-material"
import { generateOrderUnitLabel, getStockUnitWeightLabel } from "../../../utils/ordersUtils"
import {
	COLORS,
	roundNumber
} from "../../../utils"

const useStyles = makeStyles({
	container: {
		padding: "16px 16px 0px 16px",
		position: "relative",
		backgroundColor: COLORS.WHITE,
		width: "100%"
	},
	button: {
		position: "absolute",
		top: 0,
		right: 0
	},
	title: {
		fontSize: 14,
		fontWeight: 500,
		marginBottom: 5
	},
	infoRow: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	infoLabel: {
		fontSize: 12,
		color: COLORS.GREY_700
	},
	infoUnit: {
		fontSize: 12,
		fontWeight: 400
	},
	infoValue: {
		fontSize: 14,
		fontWeight: 500
	},
	deleteIcon:{
		paddingBottom: 48
	}
})

const OrderCartItem = (props) => {
	const {
		card,
		actions,
		isValidOrder = { isValidOrder },
		order
	} = props

	const classes = useStyles()

	const onEdit = () => {
		actions({
			method: "SELECT_CARD",
			data: {
				card: card.card
			}
		})
	}
	
	const onDelete = () => {
		actions({
			method: "REMOVE_CART",
			data: {
				card: card.card
			}
		})
	}

	return (
		<>
			{!order || order.orderStatus === "TO_VERIFY" ?
				<div className={classes.container}>
					<div className={classes.button}>
						<IconButton color="primary" onClick={onEdit} size="large">
							<Edit />
						</IconButton>
					</div>
					<Typography className={classes.title}>
						{card.card.name}
					</Typography>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							Quantité commandée
						</Typography>
						<Typography className={classes.infoValue}>
							{card.quantity} <span className={classes.infoLabel}>{generateOrderUnitLabel(card.card, "order")}</span>
						</Typography>
					</div>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							{getStockUnitWeightLabel(card.card)}
						</Typography>
						<Typography className={classes.infoValue}>
							{`${roundNumber(card.quantity * card.card.units.order.weight, 2)} kg`}
						</Typography>
					</div>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							{`${card.card.units.order.price}€ / ${generateOrderUnitLabel(card.card, "order")}`}
						</Typography>
						<Typography className={classes.infoValue}>
							{`${roundNumber(card.quantity * card.card.units.order.price, 2)} €`}
						</Typography>
					</div>
					<div>
						<IconButton color="secondary" onClick={onDelete} size="large">
							<DeleteOutline />
						</IconButton>

					</div>
				</div>
				:
				<div className={classes.container}>
					{isValidOrder &&
						<div className={classes.button}>
							<IconButton color="primary" onClick={onEdit} size="large">
								<Edit />
							</IconButton>
						</div>
					}
					<Typography className={classes.title}>
						{card.card.name}
					</Typography>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							Quantité commandée
						</Typography>
						<Typography className={classes.infoValue}>
							{card.quantity} <span className={classes.infoLabel}>{generateOrderUnitLabel(card.card, "order")}</span>
						</Typography>
					</div>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							{getStockUnitWeightLabel(card.card)}
						</Typography>
						<Typography className={classes.infoValue}>
							{`${roundNumber(card.quantity * card.card.units.order.weight, 2)} kg`}
						</Typography>
					</div>
					<div className={classes.infoRow}>
						<Typography className={classes.infoLabel}>
							{`${card.card.units.order.price}€ / ${generateOrderUnitLabel(card.card, "order")}`}
						</Typography>
						<Typography className={classes.infoValue}>
							{`${roundNumber(card.quantity * card.card.units.order.price, 2)} €`}
						</Typography>
					</div>
					<div className={!isValidOrder ? classes.deleteIcon : ""}>
						{isValidOrder &&
							<IconButton color="secondary" onClick={onDelete} size="large">
								<DeleteOutline />
							</IconButton>
						}
					</div>
				</div>
			}
		</>
	)
}

export default OrderCartItem