import React, { useState } from "react"
import { Box, Table, TableBody, TableHead, TableRow, TextField, Button } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import GenericTopBarHeader from "../../../../../components/GenericTopBarHeader"
import PageHeaderTitle from "../../../../../components/PageHeaderTitle"
import { COLORS } from "../../../../../utils"
import GenericHeaderReturnButton from "../../../../../components/GenericHeaderReturnButton"
import { StyledTableCell } from "./StyledTableCell"
import { debounce } from "lodash"
import { goBack } from "react-router-redux"
import { useDispatch, useSelector } from "react-redux"
import { getMachinesSelector } from "../../../../../reducers/Machines/machines"
import KitchenAreaMachinesEdit from "./KitchenAreaMachinesEdit"
import { loadMachineModels, loadKitchenAreaMachines } from "../../../../../actions/Site/machines"
import { getKitchenAreaSelector } from "../../../../../reducers/Site/sites"

const COL_WIDTH = 100 / 5 + "%"
const sx = {
    searchAndEdit: {
        paddingLeft: 6,
        paddingBottom: 3,
        paddingTop: 1,
        paddingRight: 6,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    genericHeaderContainer: {
        height: 64,
        width: "100%",
    },
    firstColumn: {
        width: COL_WIDTH,
        pl: 5,
    },
    column: {
        width: COL_WIDTH
    },
}

export const KitchenAreaMachines = () => {
    const dispatch = useDispatch()
    const machines = useSelector(getMachinesSelector)
    const [showEdition, setShowEdition] = useState(false)
    const kitchenArea = useSelector(getKitchenAreaSelector)

    const toggleEdition = () => setShowEdition(!showEdition)

    const onBack = () => {
        dispatch(goBack())
    }

    const onEdit = async () => {
        toggleEdition()
        await dispatch(loadMachineModels())
    }

    const onCancel = async () => {
        await dispatch(loadMachineModels())
        toggleEdition()
    }

    // search 500ms after the user typed
    const onSearch = debounce((e) => {
        const filters = { name: e.target.value }
        dispatch(loadKitchenAreaMachines(kitchenArea.objectId, filters))
    }, 500)

    if (showEdition) {
        return (
            <KitchenAreaMachinesEdit
                machines={machines}
                kitchenArea={kitchenArea}
                onCancel={onCancel}
            />
        )
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Box sx={sx.genericHeaderContainer}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                    title={<PageHeaderTitle title="Réglages - Sites" subtitle={<i>{kitchenArea?.name}</i>} icon={<SettingsIcon />} color={COLORS.HEAD_SEAZON} />}
                />
            </Box>

            <Box>
                <Box sx={sx.searchAndEdit}>
                    <TextField
                        variant="standard"
                        onChange={onSearch}
                        label="Nom de la machine"
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        className={sx.editButton}
                        onClick={onEdit}
                    >
                        Éditer
                    </Button>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={sx.column}>Nom de la machine</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Modèle</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Type de machine</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Capacité max <br></br>(en kg)</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Temps de nettoyage<br></br> entre 2 utilisations <br></br>(en min)</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Taux de disponibilité (en %)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {machines.map((machine) => (
                            <TableRow key={machine.objectId} bgcolor="#fff" sx={sx.row} >
                                <StyledTableCell align="left" sx={sx.column}>{machine.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineType.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.capacity}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.cleanTime}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.availabilityRate}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Box>
    )
}
