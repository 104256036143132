import React, {useCallback, useEffect, useState} from "react"
import DataGrid from "../../components/DataGrid/DataGrid"
import {Box} from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {useDispatch} from "react-redux"
import {showProductionReceptionToReceive} from "../../actions/ProductionReception/ProductionReception"
import {generateOrderUnitLabel} from "../../utils/ordersUtils"

const ProductionReceptionToDo = (props) => {
    const {
        // site,
        productionSupplies,
    } = props

    const dispatch = useDispatch()
    const [data, setData] = useState([])

    useEffect(() => {
        createDataObject()
    }, [productionSupplies])

    const createDataObject = useCallback(() => {
        productionSupplies && setData(productionSupplies.map(createData))
    })

    const columns = [
        {
            key: "name",
            label: "Nom du produit",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "quantity",
            label: "Quantité",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const quantity = value.split("-")[0]
                const unit = value.split("-")[1]
                return (
                    <div><span>{quantity}</span><span style={{color: "#7C7C7C"}}>({unit})</span></div>
                )
            }
        },
        {
            key: "weight",
            label: "Poids",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const amount = value.split(" ")[0]
                const currency = value.split(" ")[1]
                return (
                  <div><span>{amount}</span><span style={{color: "#7C7C7C"}}>{" " + currency}</span></div>
                )
            }
        },
    ]

    const createData = (productionSupply) => {
        return {
            id: productionSupply.objectId,
            name: productionSupply.supplierItem.name,
            quantity: `${productionSupply.outputQuantity} -${generateOrderUnitLabel(productionSupply.supplierItem)}`,
            weight: productionSupply.outputQuantity * productionSupply.units.weight + " kg",
        }
    }

    return (
        <>
            {data && data.length > 0 ?
                (<DataGrid
                    title={"Produits à réceptionner"}
                    columns={columns}
                    data={data}
                    withFilters={true}
                    rowLabel={null}
                    onRowClick={(productionSupply) => dispatch(showProductionReceptionToReceive(productionSupply.id))}
                    withAdditionalFiltersOptions={false}
                    tableContainerStyle={{boxShadow: "none"}}
                />)
                :
                (
                    <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                        <Box sx={{ textAlign: "center"}}>
                            <CheckCircleIcon sx={{ color: "#44b46c", textAlign: "center", width: 52, height: 52 }} />
                        </Box>
                        Tous les produits ont été réceptionnés
                    </Box>
                )
            }
        </>
    )
}

export default ProductionReceptionToDo