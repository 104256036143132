import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import StockZoneResume from "./StockZoneResume"
import RoundedAddButton from "../RoundedAddButton"
import StockZoneEditCreate from "./StockZoneEditCreate"
import { confirm } from "../react-await-dialog/react-await-dialog"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        background: "#E5E5E5",
        paddingTop: 20,
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 40,
        minHeight: "calc(100vh - 275px)"
    }
}))

const StockZone = props => {
    const {
        jsonSite,
        onAddStockZone,
        onSaveStockZone,
        deleteStockZone,
        menuIsOpen,
        canEdit,
        canDelete
    } = props
    
    const classes = useStyles()
    const [isCreating, setIsCreating] = useState(false)
    const [editedStockZone, setEditedStockZone] = useState(null)

    const closeModal = () => {
        setIsCreating(false)
        setEditedStockZone(null)
    }

    const setEdition = stockZone => {
        setEditedStockZone(stockZone)
        setIsCreating(true)
    }

    const confirmDelete = async stockZone => {
        if (await confirm({
            title: "Confirm",
            message: "Voulez-vous supprimer cette zone de stockage ?",
            confirmText: "Oui",
            cancelText: "Non"
        })) {
            deleteStockZone(stockZone.objectId)
        }
    }
    
    return (
        <div className={classes.container}>
            {
                jsonSite.stockZones.map((stockZone, index) => (
                    <StockZoneResume
                        stockZone={stockZone}
                        setEdition={() => setEdition(stockZone)}
                        confirmDelete={() => confirmDelete(stockZone)}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        key={index}
                    />
                ))
            }
            {
                canEdit &&
                <>
                    <RoundedAddButton
                        hoverText="Ajouter une zone de stockage"
                        onClick={() => setIsCreating(true)}
                        styles={ menuIsOpen ?
                            {
                                left: 262
                            } :
                            {
                                left: 75
                            }
                        }
                    />
                    <StockZoneEditCreate
                        onClose={closeModal}
                        isCreating={isCreating}
                        onSave={ editedStockZone ? onSaveStockZone : onAddStockZone }
                        stockZone={ editedStockZone }
                    />
                </>
            }
        </div>
    )
}

export default StockZone
