import React from "react"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"

const LotInventoryOutputSnackBar = (props) => {
	const { data, onClose } = props

	return (
		<Snackbar
			style={{
				position: "absolute", 
				bottom: "24px", left: "24px" 
			}}
			open={data.open}
			autoHideDuration={data.duration}
			onClose={() => onClose(data.type)}
		>
			<MySnackbarContentWrapper
				variant={data.type || "success"}
				message={data.message}
				onClose={() => onClose(data.type)}
			/>
		</Snackbar>
	)
}

export default LotInventoryOutputSnackBar
