import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { COLORS } from "../../utils"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import _cloneDeep from "lodash/cloneDeep"
import { dataMapping } from "../../utils/planning"

const useStyles = makeStyles(() => ({
    commandModalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 700,
        outline: "none",
        margin: "0 auto",
        top: "25%",
        position: "relative",
        borderRadius: 3,
        color: COLORS.BLACK,
        padding: 10
    },
    closeContainer: {
        textAlign: "right"
    },
    closeIcon: {
        cursor: "pointer"
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        marginBottom: 10
    },
    linesContainer: {
        maxHeight: 400,
        overflow: "auto"
    },
    lineContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "23%"
    },
    commercialName: {
        marginTop: 10,
        flexBasis: "calc(100% - 75px)",
        textAlign: "left",
        marginLeft: "23%"
    },
    actionContainer: {
        textAlign: "center"
    },
    confirmBtn: {
        marginBottom: 10,
        marginTop: 30,
        color: COLORS.WHITE
    },
    commandProductionInfoInput: {
        width: 55,
        marginLeft: 20
    },
}))

const PlanningProductionCommandModal = (props) => {
    const {
        closeModal,
        currentCards,
        onCreateCommand
    } = props
    
    const classes = useStyles()
    const [editedCurrentCards, setEditedCurrentCards] = useState(dataMapping(currentCards, true))
    
    const confirmChoice =  () => {
        onCreateCommand(editedCurrentCards.filter(el => el.totalVolume !== 0 && el.totalVolume !== ""), currentCards)
        closeModal()
    }
    
    const updateEditedCurrentCards = (event, index) => {
        const newEditedCurrentCards = _cloneDeep(editedCurrentCards)
        newEditedCurrentCards[index].totalVolume = event.target.value === "" ? "" : Number(event.target.value)
    
        setEditedCurrentCards(newEditedCurrentCards)
    }
    
    const isDisable = () => {
        return !editedCurrentCards.some(card => card.totalVolume !== "" && card.totalVolume !== 0)
    }
    
    return (
        <Modal open={true}>
            <div className={classes.commandModalContainer}>
                <div className={classes.closeContainer}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={closeModal}
                    />
                </div>
                <div className={classes.title}>
                    Commande d'appoint
                </div>
                <div className={classes.linesContainer}>
                    {
                        editedCurrentCards.map((currentCard, index) =>
                            <div key={index} className={classes.lineContainer}>
                                <div className={classes.commercialName}>
                                    { `${currentCard.uniqueCode} - ${currentCard.commercialName}` }
                                </div>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    value={currentCard.totalVolume}
                                    onChange={(event) => {
                                        updateEditedCurrentCards(event, index)
                                    }}
                                    className={classes.commandProductionInfoInput}
                                />
                            </div>
                        )
                    }
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.confirmBtn}
                        onClick={() => {confirmChoice()}}
                        disabled={isDisable()}
                    >
                        VALIDER
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default PlanningProductionCommandModal
