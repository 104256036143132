import React from "react"
import { Add } from "@mui/icons-material"
import { Box, Fab } from "@mui/material"

const FixedAddButton = ({ onClick, sx }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        left: 80,
        bottom: 20,
        width: "fit-content",
        ...sx
      }}
    >
      <Fab color="primary" onClick={onClick}>
          <Add />
      </Fab>
    </Box>
  )
}

export default FixedAddButton