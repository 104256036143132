import React, { useEffect, useState } from "react"
import { Form } from "formik"
import { makeStyles } from "@mui/styles"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { Typography, Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"

const useStyles = makeStyles({
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "24px",
		padding: [24, 32],
		border: "1px solid #E6E6E6"
	},
	filtersForm: {
		display: "flex"
	},
	input: {
		width: 220,
		marginRight: 16
	},
	dateInput: {
		fontSize: 16,
		width: 180,
		textAlign: "left",
	},
	submitButton: {
		padding: [11, 16],
		width: "fit-content"
	},
	option: {
		fontSize: 14
	}
})

const ProductionStepsFilterForm = ({
	values,
	handleChange,
	setFieldValue,
	handleSubmit,
	sites
}) => {
	const classes = useStyles()
	const [kitchenAreasOptions, setKitchenAreasOptions] = useState([])

	useEffect(() => {
		if (values.site) {
			const selectedSite = sites.find(site => site.objectId === values.site)
			const kitchenAreas = selectedSite.kitchenAreas || []
			setKitchenAreasOptions(kitchenAreas)
		}
	}, [values.site])

	const _renderOptions = (items) => {
		return items.map((item, i) => {
			return <MenuItem value={item.objectId} key={i}>
				<div className={classes.option}>
					{item.name}
				</div>
			</MenuItem>
		})
	}

	return (
		<Form className={classes.form}>
			<Typography variant={"h7"} sx={{ fontWeight: 500 }}>Sélectionner le site, l'atelier et le jour de production</Typography>
			<div className={classes.filtersForm}>
				<FormControl>
					<InputLabel variant="standard" htmlFor="site">Site</InputLabel>
					<Select
						variant="standard"
						value={values.site}
						name="site"
						className={classes.input}
						onChange={(e) => {
							handleChange(e)
							const selectedSite = sites.find(site => site.objectId === e.target.value)
							const kitchenAreas = selectedSite.kitchenAreas || []
							const firstKitchenArea = kitchenAreas[0]?.objectId || ""
							setFieldValue("kitchenArea", firstKitchenArea)
						}}
					>
						{_renderOptions(sites)}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel variant="standard" htmlFor="kitchenArea" shrink={!!values.kitchenArea}>Atelier</InputLabel>
					<Select
						variant="standard"
						value={values.kitchenArea}
						name="kitchenArea"
						className={classes.input}
						onChange={handleChange}
						disabled={!kitchenAreasOptions.length}
					>
						{_renderOptions(kitchenAreasOptions)}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel variant="standard" htmlFor="productionDay">Jour de production</InputLabel>
				</FormControl>
				<FormControl>
					<DatePicker
						disableCloseOnSelect={false}
						showToolbar={false}
						value={values.productionDay}
						name="productionDay"
						variant="inline"
						inputFormat="DD/MM/YYYY"
						required
						onChange={val => {
							setFieldValue("productionDay", moment.utc(val).valueOf())
						}}
						renderInput={(params) => <TextField {...params} variant="standard" label={"Jour de production"} />}
						inputProps={{
							className: classes.dateInput
						}}
					/>
				</FormControl>
			</div>
			<Button
				className={classes.submitButton}
				color="primary"
				type="submit"
				variant="contained"
				onClick={handleSubmit}
			>
				Voir les étapes de production
			</Button>
		</Form>
	)
}


export default ProductionStepsFilterForm