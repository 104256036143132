import { DatePicker } from "@mui/x-date-pickers"
import { FormControl, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { makeStyles } from "@mui/styles"
import React from "react"
import { COLORS } from "../../utils"
import PlanningBrandSelector from "../Planning/PlanningBrandSelector"

const useStyles = makeStyles({
    container: {
        color: COLORS.BLACK,
        boxShadow: "0 0 3px rgba(0,0,0,.2)",
        backgroundColor: COLORS.WHITE,
        padding: "15px 30px 25px 30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    title: {
        fontSize: 20,
        fontFamily: "Roboto",
        fontWeight: 500
    },
    select: {
        width: 180,
    },
    filterContainer: {
        alignItems: "flex-end",
        marginTop: 10,
        width: "100%",
        display: "flex",
    },
    selectContainer: {
        margin: "5px 25px 0 25px"
    },
    separator: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
        height: 1,
        backgroundColor: COLORS.GREY_FLAW_ICON
    },
    submitButton: {
        marginTop: 21,
        width: 328,
        height: 38,
        borderRadius: 0
    },
    dateInput: {
        fontSize: 16,
        width: 180,
        textAlign: "left",
        marginTop: 1,
    },
})

const ReceptionOutputFilter = (props) => {
    const classes = useStyles()
    const {
        date,
        hubs,
        brand,
        selectedHub,
        onBrandClick,
        onDateChange,
        onHubChange,
        onValidate
    } = props

    const renderSelectResult = (selected, resources) => {
        if (selected.includes("ALL")) {
            return resources[0].label
        }
        else {
            return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
        }
    }

    const renderData = (objects, selectedObjects = false) => {
        return (
            objects.map((item, index) => {
                return <MenuItem
                    value={item.value}
                    key={index}
                >
                    {selectedObjects &&
                        <Checkbox
                            checked={selectedObjects.includes(item.value)}
                            color="secondary"
                        />
                    }
                    <ListItemText primary={item.label} />
                </MenuItem>
            })
        )
    }


    return (
        <>
            <div className={classes.container}>
                <h3 className={classes.title}>
                    Sélectionnez le centre de distribution et le jour de réception
                </h3>
                <div className={classes.filterContainer}>
                    {/* Brand */}
                    <FormControl className={classes.selectContainer} variant="standard">
                        <PlanningBrandSelector
                            brand={brand}
                            onBrandClick={onBrandClick}
                            activeBrandsOnly
                        />
                    </FormControl>

                    {/* Hub */}
                    <FormControl className={classes.selectContainer} variant="standard">
                        <InputLabel htmlFor="hub">Hub</InputLabel>
                        <Select
                            variant="standard"
                            className={classes.select}
                            value={selectedHub}
                            onChange={(e) => onHubChange(e.target.value)}
                            inputProps={{
                                name: "hub",
                                id: "hub",
                            }}
                            renderValue={
                                selected => renderSelectResult(selected, hubs)
                            }
                        >
                            {renderData(hubs)}
                        </Select>
                    </FormControl>

                    {/* date */}
                    <FormControl className={classes.selectContainer} variant="standard">
                        <DatePicker
                            disableCloseOnSelect={false}
                            showToolbar={false}
                            variant="inline"
                            value={date}
                            inputFormat="DD/MM/YYYY"
                            onChange={onDateChange}
                            renderInput={(params) => <TextField {...params} variant="standard" className={classes.date} />}
                        />
                    </FormControl>


                </div>
                <span className={classes.separator} />
                {/* validate */}
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.validateButton}
                    type="submit"
                    onClick={onValidate}
                >
                    accéder à la réception
                </Button>
            </div>
        </>
    )
}

export default ReceptionOutputFilter
