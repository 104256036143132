import React from "react"
import withStyles from "@mui/styles/withStyles"
import QuickActions from "./QuickActions"
import NutritionalValues from "./NutritionalValues"
import NutritionalSourcing from "./NutritionalSourcing"
import NutritionalScore from "./NutritionalScore"
import ProductResume from "./ProductResume"
import SubcontractorProductResume from "./SubcontractorProductResume"
import Cost from "./Cost"

const General = props => {
    const {
        classes,
        initialValues,
        jsonSubcontractorProduct,
        subcontractorIngredientCost,
        packagingCost,
        parseSubcontractorProduct,
        asyncPrintLabel,
        synchronizeASubcontractorProductWithEtiquettable,
        productsTags,
        onSaveProductResume,
        onSaveCommercialNames,
        onSaveNutritionsValues,
        onSaveNutritionsSourcing,
        onSaveNutriScore,
        chefs,
        checkProductInCard,
        showModalProductInCard
    } = props

    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <ProductResume
                    initialValues={initialValues}
                    jsonSubcontractorProduct={jsonSubcontractorProduct}
                    parseSubcontractorProduct={parseSubcontractorProduct}
                    productsTags={productsTags}
                    onSaveProductResume={onSaveProductResume}
                    chefs={chefs}
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <SubcontractorProductResume
                    jsonSubcontractorProduct={jsonSubcontractorProduct}
                    parseSubcontractorProduct={parseSubcontractorProduct}
                    onSaveCommercialNames={onSaveCommercialNames}
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <Cost
                    jsonSubcontractorProduct={jsonSubcontractorProduct}
                    subcontractorIngredientCost={subcontractorIngredientCost}
                    packagingCost={packagingCost}
                />
            </div>
            <div className={classes.secondColumnContainer}>
                <QuickActions
                    onClickSynchronizeEtiquettable={synchronizeASubcontractorProductWithEtiquettable}
                    asyncPrintLabel={asyncPrintLabel}
                    jsonSubcontractorProduct={jsonSubcontractorProduct}
                />
                <NutritionalScore
                    onSaveNutriScore={onSaveNutriScore}
                    nutritionInformation={
                        jsonSubcontractorProduct && jsonSubcontractorProduct.nutritionInformation
                            ? jsonSubcontractorProduct.nutritionInformation
                            : {}
                    }
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <NutritionalSourcing
                    onSaveNutritionsSourcing={onSaveNutritionsSourcing}
                    nutritionInformation={
                        jsonSubcontractorProduct && jsonSubcontractorProduct.nutritionInformation
                            ? jsonSubcontractorProduct.nutritionInformation
                            : {}
                    }
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
                <NutritionalValues
                    onSaveNutritionsValues={onSaveNutritionsValues}
                    nutritionInformation={
                        jsonSubcontractorProduct && jsonSubcontractorProduct.nutritionInformation
                        ? jsonSubcontractorProduct.nutritionInformation
                        : {}
                    }
                    checkProductInCard={checkProductInCard}
                    showModalProductInCard={showModalProductInCard}
                />
            </div>
        </div>
    )
}

const styles = ({
    container: {
        display: "flex",
        background: "#E5E5E5",
        justifyContent: "center"
    },
    firstColumnContainer: {
        width: "75%",
        margin: 16,
        minWidth: 500,
        maxWidth: 1000,
    },
    secondColumnContainer: {
        width: "25%",
        margin: 16,
        minWidth: 275,
        maxWidth: 300
    }
})

export default withStyles(styles)(General)
