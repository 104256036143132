import React from "react"
import AdministrationTabs from "../AdministrationTabs"
import ProductTypes from "./ProductTypes"

export const ProductTypesTab = () => {
    return (
        <AdministrationTabs tabValue="productTypes">
            <ProductTypes />
        </AdministrationTabs>
    )
}

export default ProductTypesTab