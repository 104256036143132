import React from "react"
import { Form, Formik } from "formik"
import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material"

import ModalForm from "../../../../components/form/ModalForm"
import { packagingMaterialSchema } from "../../../../utils/yupValidators"
import ModalActions from "../../../../components/ModalActions"

const getInitialValues = (packagingMaterial) => {
  if (packagingMaterial) {
    return {
      name: packagingMaterial?.name || "",
      shortName: packagingMaterial?.shortName || "",
      kgCO2PerMaterialTon: packagingMaterial?.kgCO2PerMaterialTon || "",
      isRecyclableMaterial: packagingMaterial?.isRecyclableMaterial || false,
      isRecyclableInFrance: packagingMaterial?.isRecyclableInFrance || false,
    }
  }
  return {
    name: "",
    isRecyclableInFrance: false,
    isRecyclableMaterial: false
  }
}
const PackagingMaterialFormDialog = ({
  onClose,
  open,
  onSave,
  packagingMaterial,
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title={packagingMaterial ? "Modifier une matière packaging" : "Ajouter une matière packaging"}
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={getInitialValues(packagingMaterial)}
        onSubmit={onSave}
        validationSchema={packagingMaterialSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <TextField
                  variant="standard"
                  name="name"
                  label="Nom de la matière"
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  name="shortName"
                  label="Nom court"
                  value={values.shortName}
                  error={!!errors.shortName}
                  helperText={errors.shortName}
                  onChange={handleChange}
                />
                <TextField
                  variant="standard"
                  name="kgCO2PerMaterialTon"
                  label="kg CO2e / tonne de matière"
                  type="number"
                  value={values.kgCO2PerMaterialTon}
                  error={!!errors.kgCO2PerMaterialTon}
                  helperText={errors.kgCO2PerMaterialTon}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Checkbox checked={values.isRecyclableMaterial} />}
                  label="Matière recyclée"
                  name="isRecyclableMaterial"
                  value={values.isRecyclableMaterial}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Checkbox checked={values.isRecyclableInFrance} />}
                  label="Recyclabilité en France"
                  name="isRecyclableInFrance"
                  value={values.isRecyclableInFrance}
                  onChange={handleChange}
                />
              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingMaterialFormDialog
