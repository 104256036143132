import Parse from "parse"
import { parseLimitRequest } from "../../utils"
import { escapeRegExp } from "lodash"

export const TransformationModes = Parse.Object.extend("TransformationModes")

export const getTransformationModeById = async (id, includes = [], toJson = true) => {
    try {
        const machineModel = await new Parse.Query(TransformationModes)
            .equalTo("objectId", id)
            .include(includes)
            .first()

        return toJson ? machineModel.toJSON() : machineModel
    }
    catch (e) {
        console.error("parseManager.machine.machineModelsManager.getTransformationModeById error : ", e)
        return Promise.reject(e)
    }
}

export const createTransformationMode = async (values, toJSON = true) => {
    try {
        const transformationMode = new TransformationModes()

        transformationMode.set("name", values.name)
        transformationMode.set("transformationType", values.transformationType)

        const newTransformationMode = await transformationMode.save()
        return toJSON ? newTransformationMode.toJSON() : newTransformationMode
    }
    catch (e) {
        console.error("parseManager.machine.machineTypesManager.createTransformationMode error : ", e)
        return Promise.reject(e)
    }
}

export const getTransformationModes = async (filters, toJson = true, order) => {
    try {
        const query = await new Parse.Query(TransformationModes)

        if (order) {
            if (order.order === "desc") {
                query.descending(order.orderBy)
            } else {
                query.ascending(order.orderBy)
            }
        }
        
        query.limit(parseLimitRequest)

       
        if (filters?.search) {
            const regex = new RegExp(escapeRegExp(filters.search), "ig")
            query.matches("name", regex)
        }

        if (filters?.transformationTypes) {
            query.containedIn("transformationType", filters.transformationTypes)
        }

        const transformationModes = await query.find() || []

        return toJson
            ? transformationModes.map(transformationMode => transformationMode.toJSON())
            : transformationModes
    }
    catch (e) {
        console.error("parseManager.transformationMode.transformationModesManager.getTransformationModes error : ", e)
        return Promise.reject(e)
    }
}

export const updateTransformationMode = async (id, values, toJSON = true) => {
    try {
        // ------------ TransformationModes ------------ //
        const transformationMode = await getTransformationModeById(id, [], false)

        transformationMode.set("name", values.name)
        transformationMode.set("transformationType", values.transformationType)

        const newTransformationMode = await transformationMode.save()

        return toJSON ? newTransformationMode.toJSON() : newTransformationMode
    }
    catch (e) {
        console.error("parseManager.transformationMode.transformationModeManager.updateTransformationMode error : ", e)
        return Promise.reject(e)
    }
}

export async function getTransformationMode(id, includes = [], toJson = true) {
    try {
        const transformationMode = await new Parse.Query(TransformationModes)
            .equalTo("objectId", id)
            .include(includes)
            .first()

        if (!transformationMode) {
            throw new Error("TransformationMode not found")
        }

        return toJson ? transformationMode.toJSON() : transformationMode
    }
    catch (e) {
        console.error("parseManager.transformationMode.transformationModesManager.getTransformationMode error : ", e)
        return Promise.reject(e)
    }
}
