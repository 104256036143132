import Parse from "parse"
import moment from "moment"
import dayjs from "dayjs"
import { parseLimitRequest, roundNumber } from "../../utils"
import { getProductTypeLabel, newAmbientProducts } from "../../utils/dispatchUtils"

const DistributionCenters = Parse.Object.extend("DistributionCenters")
const Recipe = Parse.Object.extend("Recipe")
const SubcontractorsProducts = Parse.Object.extend("SubcontractorsProducts")

export const getProductStockById = async function (idProductStock) {
	try {
		const productStock = await new Parse.Query("ProductsStock")
			.include("events.user.username")
			.equalTo("objectId", idProductStock)
			.first()

		return productStock
	} catch (e) {
		throw e
	}
}

export const getReceptionProductsStock = async function (date, brand, idHub) {
	try {
		const productStocks = []
		await new Parse.Query("ProductsStock")
			.include("events.user.username")
			.include("uniqueCode")
			.equalTo("saleDate", moment.utc(parseFloat(date)).startOf("day").valueOf())
			.equalTo("brand", brand)
			.greaterThanOrEqualTo("initialVolume", 0)
			.equalTo("hub", DistributionCenters.createWithoutData(idHub))
			// .ascending("uniqueCode")
			.each(async productsStock => {
				const productDispatch = productsStock.get("productDispatch")
				const distributionCenter = productsStock.get("hub")
				const productionItem = await new Parse.Query("ProductionItem")
					.equalTo("productDispatch", productDispatch)
					.first()

				if (
					(distributionCenter.has("packagingTypes")
						&& productionItem
						&& distributionCenter.get("packagingTypes").includes(productionItem.get("packaging")))
					|| newAmbientProducts.includes(productsStock.get("productType"))
				) {
					productStocks.push(productsStock)
				}
			})

		return productStocks ? productStocks.map(productStock => productStock.toJSON()) : []
	} catch (e) {
		throw e
	}
}

export const downloadProductsStocksInventory = async function () {
	try {
		const currentDate = dayjs.utc().startOf("day").valueOf()

		const rows = []
		await new Parse.Query("ProductsStock")
			.select(["events", "productType", "uniqueCode", "commercialTitle", "dlc", "volume", "hub.name", "productDispatch.idProduct", "isReusable"])
			.include(["hub", "productDispatch"])
			.lessThanOrEqualTo("saleDate", currentDate)
			.greaterThanOrEqualTo("dlc", currentDate)
			.greaterThan("volume", 0)
			.equalTo("receptionStatus", "DONE")
			.each(async productsStock => {
				const distributionCenter = productsStock.get("hub")
				const productDispatch = productsStock.get("productDispatch")

				const inventoryEvents = productsStock.get("events").filter(event => event.type === "INVENTORY")
				const lastInventoryEvent = inventoryEvents[inventoryEvents.length - 1]

				const row = {
					hub: distributionCenter.get("name"),
					productType: getProductTypeLabel(productsStock.get("productType")),
					uniqueCode: productsStock.get("uniqueCode"),
					commercialTitle: productsStock.get("commercialTitle"),
					isReusable: productsStock.get("isReusable") ? "Oui" : "Non",
					dlc: dayjs.utc(productsStock.get("dlc")).format("DD/MM/YYYY"),
					volume: productsStock.get("volume"),
					lastInventoryEventDate: lastInventoryEvent ? dayjs(lastInventoryEvent.date).format("DD/MM/YYYY HH:mm:ss") : ""
				}

				const recipe = await new Parse.Query(Recipe).select("foodcost").equalTo("objectId", productDispatch.get("idProduct")).first()
				let foodCost = null

				if (recipe) {
					foodCost = recipe.get("foodcost")
				} else {
					const subcontractorsProduct = await new Parse.Query(SubcontractorsProducts).select("totalCost").equalTo("objectId", productDispatch.get("idProduct")).first()
					if (subcontractorsProduct) {
						foodCost = subcontractorsProduct.get("totalCost")
					}
				}

				row.foodCost = foodCost ? roundNumber(foodCost, 3) : null
				rows.push(row)
			})

		return rows
	} catch (e) {
		throw e
	}
}

export const getInventoryProductsStock = async (brand, idHub) => {
	try {
		const date = moment.utc().startOf("day").valueOf()

		// request on sale date
		const saleDateQuery = new Parse.Query("ProductsStock")
			.equalTo("saleDate", date)

		// request on dlc
		const dlcQuery = new Parse.Query("ProductsStock")
			.greaterThanOrEqualTo("dlc", date)

		// distributionCenter pointer
		const distributionCenter = new DistributionCenters()
		distributionCenter.id = idHub

		const productsStocks = await Parse.Query.or(saleDateQuery, dlcQuery)
			.include("uniqueCode")
			.greaterThan("initialVolume", 0)
			.equalTo("receptionStatus", "DONE")
			.equalTo("brand", brand)
			.equalTo("hub", distributionCenter)
			.include("events.user.username")
			.ascending("uniqueCode")
			.limit(parseLimitRequest)
			.find()

		return productsStocks ? productsStocks.map(productStock => productStock.toJSON()) : []
	} catch (e) {
		throw e
	}
}

export const updateProductStockVolume = async function (idProductStock, volume, data, type) {
	try {
		const productStock = await getProductStockById(idProductStock)

		if (productStock) {
			productStock.set("volume", parseInt(volume))
			if (type === "RECEPTION") productStock.set("receptionStatus", "DONE")
			if (type === "INVENTORY") productStock.set("inventoryStatus", "DONE")
			productStock.set("events", [...productStock.get("events"), ...data])

			await productStock.save()
			return productStock.toJSON()
		} else {
			return Promise.reject(`productStock : ${idProductStock} not exist`)
		}
	} catch (e) {
		throw e
	}
}

export const updateProductStockStatus = async function (productStock, reset = false) {
	try {
		if (reset) {
			productStock.set("receptionStatus", "TODO")
		} else {
			productStock.set("receptionStatus", "IN_PROGRESS")
		}

		await productStock.save()
		return productStock.toJSON()
	}
	catch (e) {
		throw e
	}
}

export const updateProductStockInventoryStatus = async function (productStock, reset = false) {
	try {
		if (reset) {
			productStock.set("inventoryStatus", "TODO")
		} else {
			productStock.set("inventoryStatus", "IN_PROGRESS")
		}

		await productStock.save()
		return productStock.toJSON()
	}
	catch (e) {
		throw e
	}
}

export const getProductStockWithEventsByDate = async function (startDate, endDate, brand) {
	try {
		const productsStock = []

		await new Parse.Query("ProductsStock")
			.include("hub")
			.include("events.user")
			.include("events.type")
			.include("events.date")
			.containedIn("events.type", ["RECEPTION", "FLAW"])
			.greaterThanOrEqualTo("events.date", startDate)
			.lessThanOrEqualTo("events.date", endDate)
			.equalTo("brand", brand)
			.each(
				productStock => productsStock.push(productStock),
				{
					batchSize: 500
				}
			)

		return productsStock.length ? productsStock.map(el => el.toJSON()) : []
	} catch (e) {
		throw e
	}
}

export const setEventChecked = async function (event, comment) {
	try {
		const productStock = await getProductStockById(event.idProductStock)

		if (productStock) {
			// checked log
			const checked = {
				user: Parse.User.current(),
				date: moment.utc().valueOf(),
				comment
			}

			const events = productStock.get("events")

			// update events
			event.indexes.forEach(i => {
				events[i].checked = checked
			})

			productStock.set("events", events)
			await productStock.save()
			return checked
		} else {
			return Promise.reject(`productStock : ${event.idProductStock} not exist`)
		}
	} catch (e) {
		throw e
	}
}

export const updateInitialVolumeProductStocks = async function (productStocks) {
	try {
		const listProducts = []
		await new Parse.Query("ProductsStock")
			.containedIn("objectId", productStocks)
			.each(async productStock => {
				productStock.set("initialVolume", 1)
				await productStock.save()
				listProducts.push(productStock.toJSON())
			})

		return listProducts
	} catch (e) {
		throw e
	}
}

export async function findProductStock(hub, dlc, subcontractorProductId) {
	const item = await new Parse.Query("ProductsStock")
		.include(["productDispatch"])
		.equalTo("hub", hub)
		.equalTo("dlc", dlc)
		.equalTo("receptionStatus", "DONE")
		.first()

	if (item) {
		const jsonItem = item.toJSON()
		if (jsonItem.productDispatch && jsonItem.productDispatch.idProduct === subcontractorProductId) {
			return true
		}
	}

	return false
}

export async function getProductsStockByStatus(hubId, dlc, subcontractorProductId, status) {
	const hubPointer = DistributionCenters.createWithoutData(hubId)
	const productStocks = []
	await new Parse.Query("ProductsStock")
		.include(["productDispatch"])
		.equalTo("hub", hubPointer)
		.equalTo("dlc", dlc)
		.equalTo("receptionStatus", status)
		.each(productStock => {
			if (productStock.get("productDispatch") && productStock.get("productDispatch").get("idProduct") === subcontractorProductId) {
				productStocks.push(productStock)
			}
		})

	return productStocks
}

