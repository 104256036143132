import React, { useState } from "react"
import AsyncSelect from "react-select/async"
import clsx from "clsx"
import Typography from "@mui/material/Typography"
import NoSsr from "@mui/material/NoSsr"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import CancelIcon from "@mui/icons-material/Cancel"
import { querySearchCommercialNameByName } from "../../parseManager/ingredients/internal/parseCommercialNameManager"
import { emphasize } from "@mui/material/styles"

import makeStyles from "@mui/styles/makeStyles"

const suggestions = [
  { label: "Carotte" },
  { label: "Chou-Fleur" },
  { label: "Celeri" },
].map((suggestion) => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

const loadOptions = async (inputValue, callback) => {
  callback(await filterCommecialName(inputValue))
}

const filterCommecialName = async (inputValue) => {
  if (inputValue) {
    const commercialNames = await querySearchCommercialNameByName(inputValue)

    let result = []
    for (const i in commercialNames) {
      const obj = commercialNames[i]
      result.push({ objectId: obj.objectId, label: obj.name })
    }
    return result
  }

  return []
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      elevation={0}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

const CommercialNameAutocomplete = ({
  field: { value, name },
  form: { setFieldValue },
  onCommercialNameChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    value ? { objectId: value.objectId, label: value.name } : null
  )

  const handleChange = (newValue) => {
    setSelectedValue(newValue)
    setFieldValue(newValue)
    onCommercialNameChange(newValue, setFieldValue)
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NoSsr>
        <AsyncSelect
          name={name}
          classes={classes}
          options={suggestions}
          components={components}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleChange}
          value={selectedValue}
          placeholder="Nom commercial"
          isClearable={true}
        />
      </NoSsr>
    </div>
  )
}

export default CommercialNameAutocomplete
