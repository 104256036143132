import Parse from "parse"
import { parseLimitRequest } from "../../utils"

export const ProductionSupply = Parse.Object.extend("ProductionSupply")
const SupplierItem = Parse.Object.extend("SupplierItems")

export const getProductionSupply = async (
	stockZone,
	productionDate = null,
	receptionStatus = null,
	includes = [],
	toJson = true,
) => {
	try {
		const productionSupplyQuery = new Parse.Query(ProductionSupply)
			.equalTo("stockZone", stockZone)
			.include(includes)

		if (receptionStatus) {
			productionSupplyQuery.equalTo("receptionStatus", receptionStatus)
		}

		if (productionDate) {
			productionSupplyQuery.equalTo("productionDate", productionDate)
		}

		const productionSupplies = await productionSupplyQuery.limit(parseLimitRequest).find()

		if (!productionSupplies) {
			throw new Error("No productionSupply find")
		}

		return toJson ? productionSupplies.map(productionSupply => productionSupply.toJSON()) : productionSupplies
	} catch (e) {
		return Promise.reject(e)
	}
}


export const getProductionSupplyById = async (
	productionSupplyId,
	includes = [],
	toJson = true,
) => {
	try {
		const productionSupply = await new Parse.Query(ProductionSupply)
			.equalTo("objectId", productionSupplyId)
			.include(includes)
			.first()

		if (!productionSupply) {
			throw new Error("No productionSupply find")
		}

		return toJson ? productionSupply.toJSON() : productionSupply
	} catch (e) {
		return Promise.reject(e)
	}
}

export const getProductionSuppliesForSecondaryDlCs = async ({
	date,
	toJSON = true,
	include = ["lots", "lots.supplierItem", "lots.orderSupplierItem"],
	select = ["lots"]
}) => {
	try {

		const ProductionSupplyQuery = new Parse.Query(ProductionSupply)
			.include(include)
			.equalTo("productionDate", date)

		if (select && select.length > 0) {
			ProductionSupplyQuery.select(select)
		}

		const productionSupplies = await ProductionSupplyQuery.findAll()

		return toJSON ? productionSupplies.map(productionSupply => productionSupply.toJSON()) : productionSupplies
	} catch (e) {
		return Promise.reject(e)
	}
}

/**
 * Allow to retrieve production supplies for production step execution lots selection feature
 * @param {*} productionDate // day of production
 * @param {[]} supplierItemsIds // supplier items ids of the production step execution (it may be the supplierItems of substitutes array)
 * @param {boolean} replacement // if the production supplies are for replacement
 * @param {boolean} toJSON // if the result should be JSON
 */

export const getProductionSuppliesFromSupplierItemAndProductionDate = async ({
	productionDate,
	supplierItemsIds,
	replacement = false,
	toJSON = true
}) => {
	try {

		const supplierItemQuery = new Parse.Query(SupplierItem)
			.containedIn("objectId", supplierItemsIds)

		const productionSupplyQuery = new Parse.Query(ProductionSupply)
			.equalTo("productionDate", productionDate)
			.include("lots")
			.select("lots")


		if (!replacement) {
			productionSupplyQuery.matchesQuery("supplierItem", supplierItemQuery)
		} else {
			productionSupplyQuery.containedIn("replacedSupplierItems.objectId", supplierItemsIds)
		}

		const productionSupplies = await productionSupplyQuery.find()

		return toJSON ? productionSupplies.map(productionSupply => productionSupply.toJSON()) : productionSupplies

	}
	catch (e) {
		console.error("parseProductionSupplyManager.getProductionSuppliesFromSupplierItemAndProductionDate", e)
		return Promise.reject(e)
	}
}