import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import DispatchTable from "./Product/DispatchTable"
import HubDispatchTable from "./Hub/HubDispatchTable"
import { updateDispatchTypeFilter } from "../../actions/Dispatch/dispatch"

const useClasses = makeStyles(() => ({
    container: {
        width: "100%"
    }
}))

const DispatchInProgress = (props) => {
    const {
        hubs=false,
        started=false,
        selectedTypeFilter,
        dispatchDataInProgress,
        updateDispatchTypeFilter,
        productTypeOptions
    } = props

    const classes = useClasses()
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const searchFilter = (storage && storage.dispatchToDo)?storage.dispatchToDo:""

    const _onProductTypeChange = (value) => {
        updateDispatchTypeFilter(value)
    }

    const getDispatchtable = () => {
        const data = (started)?
            dispatchDataInProgress.filter(elm => elm.prodWarehouse !== null && elm.hubs.map(n => n.locked).includes(true)):
            dispatchDataInProgress.filter(elm => elm.prodWarehouse !== null && !elm.hubs.map(n => n.locked).includes(true))

        return (
            <DispatchTable
                tableType="todo"
                dispatchData={data}
                selectedType={selectedTypeFilter}
                onProductTypeChange={_onProductTypeChange}
                searchFilter={searchFilter}
                storageIndex="dispatchInProgress"
                productTypeOptions={productTypeOptions}
            />
        )
    }

    return (
        <div className={classes.container}>
            { !hubs && getDispatchtable() }
            { hubs &&
                <HubDispatchTable
                    tableType="todo"
                    selectedType={selectedTypeFilter}
                    onProductTypeChange={_onProductTypeChange}
                    searchFilter={searchFilter}
                    storageIndex="dispatchInProgress"
                    started={started}
                />
            }
        </div>
    )
}

export default connect((state) => {
    return {
        date: state.dispatch.date,
        dispatchDataInProgress: state.dispatch.dispatchDataInProgress,
        selectedTypeFilter: state.dispatch.selectedTypeFilter,
        productTypeOptions: state.dispatch.productTypeOptions
    }
}, {
    updateDispatchTypeFilter
})(DispatchInProgress)
