import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import { grey } from "@mui/material/colors"
import SettingsIcon from "@mui/icons-material/Settings"
import CircularProgress from "@mui/material/CircularProgress"
import _isEqual from "lodash/isEqual"
import { withCookies } from "react-cookie"

import {
    editNews,
    saveSite,
    saveStockZone,
    createStockZone,
    showSiteTab,
    closeSiteSnackBar,
    saveSiteImage,
    deleteStockZone
} from "../../../../actions/Site/sites"
import MySnackbarContentWrapper from "../../../../components/MySnackbarContentWrapper"
import General from "../../../../components/Site/General"
import Header from "../../../../components/Site/Header"
import { COLORS } from "../../../../utils"
import StockZone from "../../../../components/Site/StockZone"
import {
    CAN_UPDATE_SITE_STOCK_ZONE,
    CAN_DELETE_SITE_STOCK_ZONE
} from "../../../../utils/accessRights"
import PageHeader from "../../../../components/PageHeader"
import KitchenAreas from "./KitchenAreas/KitchenAreas"
import EditKitchenAreas from "./KitchenAreas/EditKitchenAreas"
import { getKitchenAreasSelector } from "../../../../reducers/Site/sites"
import PackagingLine from "../../../../components/Site/packagingLine/PackagingLine"

const useStyles = makeStyles({
    container: {
        display: "block",
        overflow: "auto",
        minHeight: "100vh"
    },
    tabs: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
    appBar: {
        backgroundColor: "white"
    },
    appBarMenuClosed: {
        composes: "$appBar",
        width: "calc(100% - 54.4px)"
    },
    appBarMenuOpenned: {
        composes: "$appBar",
        width: "calc(100% - 239px)"
    },
    appBarRoot: {
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        height: 64
    },
    title: {
        flexGrow: 1,
        color: COLORS.RED_RECEPTION
    },
    cancel: {
        marginRight: 15
    },
    recipeImage: {
        height: 64,
        marginRight: 15
    },
    toolbarGutters: {
        paddingLeft: 0,
        paddingRight: 0,
        "& > div": {
            marginBottom: -5
        }
    },
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    },
    backIcon: {
        color: COLORS.GREY_SEARCH
    },
    width100: {
        width: "100%",
        "& button": {
            margin: "0 20px"
        }
    },
    titleIcon: {
        color: COLORS.RED_RECEPTION,
        marginLeft: 10
    }
})

const tabNames = ["general", "kitchenAreas", "zone", "packagingLine"]

const Site = (props) => {
    const { params: { tab, id: siteId } } = props
    const jsonSite = useSelector(state => state.sites.selectedSite, _isEqual)
    const kitchenAreas = useSelector(getKitchenAreasSelector)
    const siteSnackBar = useSelector(state => state.sites.siteSnackBar, _isEqual)
    const isMenuOpen = useSelector(state => state.app.menuIsOpen)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_SITE_STOCK_ZONE])
    const canDelete = useSelector(state => state.app.rights[CAN_DELETE_SITE_STOCK_ZONE])

    const [tabValue, setTabValue] = useState(tab)
    const [selectedSite, setSelectedSite] = useState(jsonSite)
    const [editKitchenArea, setEditKitchenArea] = useState(false)

    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        if (jsonSite !== selectedSite) {
            setSelectedSite(jsonSite)
        }
    }, [jsonSite])

    const toggleEditKitchenArea = () => setEditKitchenArea(!editKitchenArea)

    const _onTabChange = (_event, tabValue) => {
        setTabValue(tabValue)

        switch (tabValue) {
            case tabNames[1]:
                dispatch(showSiteTab(tabNames[1], siteId))
                break
            case tabNames[2]:
                dispatch(showSiteTab(tabNames[2], siteId))
                break
            case tabNames[3]:
                dispatch(showSiteTab(tabNames[3], siteId))
                break
            default:
                dispatch(showSiteTab(tabNames[0], siteId))
                break
            }
    }

    const _closeSnackbar = () => {
        dispatch(closeSiteSnackBar(siteSnackBar.type))
    }

    if (editKitchenArea) {
        return <EditKitchenAreas site={jsonSite} kitchenAreas={kitchenAreas} onClose={toggleEditKitchenArea} />
    }


    const _renderTabs = () => {
        if (!jsonSite) {
            return (
                <CircularProgress
                    size={80}
                    className={classes.circularProgress}
                />    
            )
        }
        return (
            <>
                {tabValue === tabNames[0] &&
                    <General
                        jsonSite={jsonSite}
                        onEditNews={value => dispatch(editNews(jsonSite.objectId, value))}
                        onSaveSite={values => dispatch(saveSite(jsonSite.objectId, values))}
                        canEdit={canEdit}
                    />
                }

                {tabValue === tabNames[1] && <KitchenAreas site={jsonSite} onEdit={toggleEditKitchenArea} />}

                {tabValue === tabNames[2] &&
                    <StockZone
                        jsonSite={jsonSite}
                        onAddStockZone={values => dispatch(createStockZone(values, jsonSite.objectId))}
                        onSaveStockZone={(stockZoneId, values) => dispatch(saveStockZone(stockZoneId, values, jsonSite.objectId))}
                        deleteStockZone={stockZoneId => dispatch(deleteStockZone(stockZoneId, jsonSite.objectId))}
                        menuIsOpen={isMenuOpen}
                        canEdit={canEdit}
                        canDelete={canDelete}
                    />
                }

                {tabValue === tabNames[3] && <PackagingLine packagingLines={jsonSite?.packagingLines || []} siteId={jsonSite.objectId} />}
            </>    
        )
    }

    const image = selectedSite ? selectedSite.image : null
    const zoneNumber = selectedSite ? selectedSite.stockZones.length : 0
    const packagingLineCount = selectedSite?.packagingLines?.length || 0

    return (
        <div className={classes.container}>
            <PageHeader
                withBackButton
                title="Réglages - Sites"
                subtitle={jsonSite?.name}
                icon={<SettingsIcon />}
            />
            <Header
                image={image}
                jsonSite={selectedSite}
                saveImage={img => dispatch(saveSiteImage(img, jsonSite.objectId))}
                canEdit={canEdit}
            />
            <Paper className={classes.tabs}>
                <Tabs
                    className={classes.width100}
                    // value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                    value={tabValue}
                    onChange={_onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Général" value={tabNames[0]} />
                    <Tab label={`Ateliers (${jsonSite?.kitchenAreas?.length || 0})`} value={tabNames[1]} />
                    <Tab label={`Zone de stockage (${zoneNumber})`} value={tabNames[2]} />
                    <Tab label={`Lignes de barquettage (${packagingLineCount})`} value={tabNames[3]} />
                </Tabs>
            </Paper>
            {_renderTabs()}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={siteSnackBar.open}
                autoHideDuration={siteSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={siteSnackBar.type}
                    message={siteSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default withCookies(Site)
