import React from "react"
import Button from "@mui/material/Button"

const CloseButton = (props) => {
    const {
        closeReception
    } = props

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={closeReception}
        >
            CLÔTURER LA RÉCEPTION
        </Button>
    )
}

export default CloseButton
