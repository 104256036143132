import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import News from "./News"
import SupplierResume from "./SupplierResume"
import SupplierContact from "./SupplierContact"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        background: "#E5E5E5",
        justifyContent: "center",
        paddingTop: 40,
        paddingBottom: 103,
        minHeight: "calc(100vh - 275px)"
    },
    firstColumnContainer: {
        width: "75%",
        minWidth: 500,
        maxWidth: 800,
    },
    secondColumnContainer: {
        width: "25%",
        minWidth: 275,
        maxWidth: 322,
        marginLeft: 20
    }
}))

const SupplierGeneral = props => {
    const {
        jsonSupplier,
        onEditNews,
        onSaveSupplier,
        canEdit,
        sites
    } = props
    
    const classes = useStyles()
    
    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <SupplierResume
                    jsonSupplier={jsonSupplier}
                    onSaveSupplier={onSaveSupplier}
                    canEdit={canEdit}
                    sites={sites}
                />
                <SupplierContact
                    jsonSupplier={jsonSupplier}
                    onSaveSupplier={onSaveSupplier}
                    canEdit={canEdit}
                />
            </div>
            <div className={classes.secondColumnContainer}>
                <News
                    news={jsonSupplier ? jsonSupplier.news : ""}
                    onEdit={onEditNews}
                    canEdit={canEdit}
                />
            </div>
        </div>
    )
}

export default SupplierGeneral
