import Parse from "parse"
import _cloneDeep from "lodash/cloneDeep"
import moment from "moment"

import {
    parseLimitRequest
} from "../../utils"
import { planningSections } from "../../utils/planning"

const ProductionItem = Parse.Object.extend("ProductionItem")
const ProductionDay = Parse.Object.extend("ProductionDay")
const PlanningRules = Parse.Object.extend("PlanningRules")
const DistributionCenters = Parse.Object.extend("DistributionCenters")
const PlanningPrevs = Parse.Object.extend("PlanningPrevs")
const Recipe = Parse.Object.extend("Recipe")
const SubcontractorsProducts = Parse.Object.extend("SubcontractorsProducts")
const ProductsPackaging = Parse.Object.extend("ProductsPackaging")
const Sites = Parse.Object.extend("Site")

export async function getSellPlanningItemData(startDate, endDate) {
    try {
        let data = await new Parse.Query(ProductionItem)
            .include("image")
            .include("internalTag")
            .greaterThanOrEqualTo("saleDate", startDate)
            .lessThanOrEqualTo("saleDate", endDate)
            .notEqualTo("isReusable", true)
            .descending("productionDate")
            .limit(parseLimitRequest)
            .find()

        data = data ? data.map(Item => Item.toJSON()) : []

        //deduplicate productionItems according to their itemId
        return data.filter((obj, index) => data.findIndex((item) => item.itemId === obj.itemId && item.brand === obj.brand && item.saleDate === obj.saleDate) === index)
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const useProductionItemProductFoodCost = (productionItem) => {
    // Recipe
    if (productionItem.itemType === "Recipe") {
        productionItem.foodcost = productionItem.recipe ? productionItem.recipe.foodcost : 0
        // SubcontractorProduct
    } else if (productionItem.itemType === "SubcontractorProduct") {
        productionItem.foodcost = productionItem.subcontractorProduct ? productionItem.subcontractorProduct.subcontractorIngredientCost : 0
    }

    return productionItem
}

export const getProductionPlanningItemData = async (startDate, endDate) => {
    try {
        const productionItems = []
        await new Parse.Query(ProductionItem)
            .greaterThanOrEqualTo("productionDate", startDate)
            .lessThanOrEqualTo("productionDate", endDate)
            .include("recipe", "subcontractorProduct")
            .select([
                "saleDate", "productionDate", "packagingDate", "itemType", "itemId", "printKitchen", "printReport",
                "productType", "brand", "expectedProduction", "availableProductionDates", "packagingDate", "lunchbag",
                "uniqueCode", "commercialName", "name", "dlc", "dlcBrand", "brand", "foodcost", "image.publicId", "isReusable",
                "recipe", "subcontractorProduct"
            ])
            .include("recipe", "subcontractorProduct")
            .each(productionItem => {
                const productionItemJson = productionItem.toJSON()
                const newProductionItem = useProductionItemProductFoodCost(productionItemJson)

                productionItems.push(newProductionItem)
            }, {
                batchSize: 2000
                // without specifying batchSize, the default limit of 100 is applied
                // the size has to be optimized based on possible quantity to retrieve
            })

        return productionItems
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const getProductionItemByDatesAndItem = async({
    startDate,
    endDate,
    itemType = "Recipe",
    include = []
}) => {
    try {
        const productionItems = []

        await new Parse.Query(ProductionItem)
            .equalTo("itemType", itemType)
            .greaterThanOrEqualTo("productionDate", startDate)
            .lessThanOrEqualTo("productionDate", endDate)
            .include([
                "recipe", "subcontractorProduct",
                ...include
            ])
            .select([
                "saleDate", "productionDate", "packagingDate", "itemType", "itemId", "printKitchen", "printReport",
                "productType", "brand", "expectedProduction", "availableProductionDates", "packagingDate", "lunchbag",
                "uniqueCode", "commercialName", "name", "dlc", "dlcBrand", "brand", "foodcost", "image.publicId", "isReusable",
                "recipe", "subcontractorProduct",
            ])
            .each(productionItem => {
                const productionItemJson = productionItem.toJSON()
                
                const newProductionItem = useProductionItemProductFoodCost(productionItemJson)
                productionItems.push(newProductionItem)
            }, {
                batchSize: 2000
                // without specifying batchSize, the default limit of 100 is applied
                // the size has to be optimized based on possible quantity to retrieve
            })

        return productionItems
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const getProductionItemByPackagingDates = async ({
    startDate,
    endDate,
    include = []
}) => {
    try {
        const productionItems = []

        await new Parse.Query(ProductionItem)
           .equalTo("itemType", "Recipe")
            .greaterThanOrEqualTo("packagingDate", startDate)
            .lessThanOrEqualTo("packagingDate", endDate)
            .include([
                "recipe", "subcontractorProduct",
                ...include
            ])
            .select([
                "recipe", "subcontractorProduct", "expectedProduction", "brand", "isReusable", "itemId"
            ])
            .each(productionItem => {
                const productionItemJson = productionItem.toJSON()
                
                const newProductionItem = useProductionItemProductFoodCost(productionItemJson)
                productionItems.push(newProductionItem)
            }, {
                batchSize: 2000
                // without specifying batchSize, the default limit of 100 is applied
                // the size has to be optimized based on possible quantity to retrieve
            })

        return productionItems
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getProductsDifficultiesGesters(recipeIds) {
    try {
        const data = await new Parse.Query(Recipe)
            .select("difficulty", "gesters")
            .containedIn("objectId", recipeIds)
            .limit(parseLimitRequest)
            .find()

        return data ? data.map(e => e.toJSON()) : []
    }
    catch (e) {
        return Promise.reject(e)
    }
}


export async function getProductsGestersPots(recipeIds) {
    try {
        const recipeData = (await new Parse.Query(Recipe)
            .select("gesters", "subPackaging", "reusableSubPackaging")
            .include(["subPackaging.value", "reusableSubPackaging.value"])
            .containedIn("objectId", recipeIds)
            .limit(parseLimitRequest)
            .find()) || []

        return recipeData.map(e => e.toJSON())
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getProductionPlanningDayData(startDate, endDate) {
    try {
        const data = await new Parse.Query(ProductionDay)
            .greaterThanOrEqualTo("date", startDate)
            .lessThanOrEqualTo("date", endDate)
            .limit(parseLimitRequest)
            .find()

        return data ? data.map(e => e.toJSON()) : []
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getDayInfoByDate(date) {
    try {
        const data = await new Parse.Query(ProductionDay)
            .equalTo("date", date)
            .first()

        return data ? data.toJSON() : null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createProductionDay(value, item, productTypeData) {
    try {
        const productionDay = new ProductionDay()

        const capacities = []
        const sections = planningSections.filter(section => section.key !== "ALL")

        for (const i in sections) {
            const currentSection = sections[i]

            capacities.push({
                key: currentSection.key,
                value: productTypeData.key === currentSection.key ? parseInt(value) : 0
            })
        }

        productionDay.set("date", item.date)
        productionDay.set("capacities", capacities)

        await productionDay.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createProductionDayLocked(date) {
    try {
        const productionDay = new ProductionDay()

        productionDay.set("date", date)
        productionDay.set("isLocked", true)
        productionDay.set("capacities", [])

        await productionDay.save()

        return productionDay
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateProductionDay(dayInfo, value, productTypeData) {
    try {
        const dayInfoParse = await new Parse.Query(ProductionDay)
            .equalTo("objectId", dayInfo.objectId)
            .first()

        if (dayInfoParse) {
            const copyCapacities = _cloneDeep(dayInfoParse.get("capacities"))
            const current = copyCapacities.find(el => el.key === productTypeData.key)
            current.value = value

            dayInfoParse.set("capacities", copyCapacities)

            await dayInfoParse.save()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateExpectedProduction(value, id, origin) {
    if (value != null && id) {
        try {
            const data = await new Parse.Query(ProductionItem)
                .equalTo("objectId", id)
                .first()

            if (data) {
                data.set("expectedProduction", parseFloat(value))

                // need context to know from where the expectedProduction has changed
                await data.save(null, { context: { origin } })
            }
        }
        catch (e) {
            return Promise.reject(e)
        }
    }
}

export async function updatePackagingProduction(value, id) {
    try {
        const data = await new Parse.Query(ProductsPackaging)
            .equalTo("objectId", id)
            .first()

        data.set("packagingProduction", value)

        await data.save()
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function getPackagingPlanningItemData(startDate, endDate) {
    try {
        const productPackagings = []

        await new Parse.Query(ProductsPackaging)
            .greaterThanOrEqualTo("packagingDate", startDate)
            .lessThanOrEqualTo("packagingDate", endDate)
            .select([
                "productionItems.image",
                "productionItems.internalTag",
                "productionItems.itemType",
                "productionItems.itemId",
                "productionItems.productType",
                "productionItems.uniqueCode",
                "productionItems.availablePackagingDates",
                "productionItems.commercialName",
                "productionItems.name",
                "productionItems.foodcost",
                "productionItems.expectedProduction",
                "productionItems.printPackaging",
                "productionItems.lunchbag",
                "productionItems.dlc",
                "productionItems.brand",
                "productionItems.dlcBrand",
                "packagingDate",
                "productType",
                "itemId",
                "packagingType",
                "packagingProduction",
                "saleDate"
            ])
            .each(async productPackaging => {
                const productPackagingJson = productPackaging.toJSON()

                const newProductionItems = []
                const productionsItems = productPackagingJson.productionItems
                for (const productionItem of productionsItems) {
                    const newProductionItem = useProductionItemProductFoodCost(productionItem)

                    newProductionItems.push(newProductionItem)
                }
                productPackagingJson.productionItems = newProductionItems
                productPackagings.push(productPackagingJson)
            }, {
                batchSize: 1000
                // without specifying batchSize, the default limit of 100 is applied
                // the size has to be optimized based on possible quantity to retrieve
            })

        return productPackagings
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getPrevPlanningItemData(startDate, endDate) {
    try {
        const planningPrevs = await new Parse.Query(PlanningPrevs)
            .greaterThanOrEqualTo("date", startDate)
            .lessThanOrEqualTo("date", endDate)
            .limit(parseLimitRequest)
            .find()

        return planningPrevs ? planningPrevs.map(planningPrev => planningPrev.toJSON()) : []
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updatePrevisionsPlanningValidationState(cards, status) {
    try {
        const data = await getDataFromCards(cards)

        for (const currentData of data) {
            currentData.set("state", status)
        }

        await Parse.Object.saveAll(data)
    } catch (e) {
        return Promise.reject(e)
    }
}

async function getDataFromCards(cards) {
    try {
        return (await new Parse.Query(PlanningPrevs)
            .containedIn("objectId", cards.map(card => card.objectId))
            .limit(parseLimitRequest)
            .find()) || []
    } catch (e) {
        return Promise.reject(e)
    }
}

export function getPlanningReportRulesJson(parseRules) {
    return parseRules ? parseRules.toJSON() : null
}

export function getValuesFromParseObject(object, field) {
    return object.get(field)
}

export function setValueToParseObject(object, field, value) {
    object.set(field, value)
}

export async function getPlanningReportRulesParse() {
    const parseRules = await new Parse.Query(PlanningRules).first()
    return parseRules
}

export async function getPlanningReportRules(toJson = true) {
    try {
        const rules = (await new Parse.Query(PlanningRules).first()) || {}

        return toJson ? rules.toJSON() : rules
    } catch (e) {
        console.error("parseManager.getPlanningReportRules : ", e)

        return Promise.reject(e)
    }
}

export async function savePlanningReportRules(rules) {
    await rules.save()
}

export async function getPlanningsSettingsDistributionCentersList() {
    const distributionCenters = (await new Parse.Query(DistributionCenters)
        .notEqualTo("deleted", true)
        .include("image")
        .descending("updatedAt")
        .limit(parseLimitRequest)
        .find()) || []

    return distributionCenters.map(elem => elem.toJSON())
}

export async function getParseDistributionCentersFromIds(ids) {
    const distributionCenters = (await new Parse.Query(DistributionCenters)
        .include("image")
        .containedIn("objectId", ids)
        .limit(parseLimitRequest)
        .find()) || []

    return distributionCenters
}

export async function getPlanningsSettingsDistributionCenterById(id, toJson = true) {
    const distributionCenter = await new Parse.Query(DistributionCenters)
        .include("image", "dispatchSites.site")
        .equalTo("objectId", id)
        .first()

    return toJson
        ? distributionCenter
            ? distributionCenter.toJSON()
            : null
        : distributionCenter
}

export async function getOrCreatePlanningsSettingsDistributionCenterById(distributionCenterId) {
    return distributionCenterId
        ? await new Parse.Query(DistributionCenters)
            .include("image")
            .equalTo("objectId", distributionCenterId)
            .first()
        : new DistributionCenters()
}

export async function updateParseDistributionCenterFromJsonValue(parseDistributionCenter, values) {
    parseDistributionCenter.set("attachRates", values.attachRates ? values.attachRates : {})
    parseDistributionCenter.set("capacities", values.capacities ? values.capacities : {})
    parseDistributionCenter.set("address", values.address)
    parseDistributionCenter.set("name", values.name)
    parseDistributionCenter.set("type", values.type)
    parseDistributionCenter.set("brand", values.brand)
    parseDistributionCenter.set("sector", values.sector)
    parseDistributionCenter.set("externalId", values.externalId)
    parseDistributionCenter.set("packagingTypes", values.packagingTypes)

    if (undefined !== values.counterMark) parseDistributionCenter.set("counterMark", values.counterMark)
    if (undefined !== values.supplierCode) parseDistributionCenter.set("supplierCode", values.supplierCode)
    if (undefined !== values.internalMail) parseDistributionCenter.set("internalMail", values.internalMail)
    if (undefined !== values.externalMail) parseDistributionCenter.set("externalMail", values.externalMail)

    const cleanCloseDates = []
    for (const i in values.closeDates) {
        const current = values.closeDates[i]

        if (current.startDate !== null && current.endDate !== null) {
            cleanCloseDates.push(current)
        }
    }
    parseDistributionCenter.set("closeDates", cleanCloseDates)

    if (values.isActive !== null) {
        parseDistributionCenter.set("isActive", values.isActive)
    }
    else {
        parseDistributionCenter.set("isActive", false)
    }

    if (values.national !== null) {
        parseDistributionCenter.set("national", values.national)
    }
    else {
        parseDistributionCenter.set("national", false)
    }

    if (values.onlyPreorders !== null) {
        parseDistributionCenter.set("onlyPreorders", values.onlyPreorders)
    }
    else {
        parseDistributionCenter.set("onlyPreorders", false)
    }

    if (values.openDate && values.openDate._isValid) {
        parseDistributionCenter.set("openDate", moment(values.openDate).startOf("day").valueOf())
    }

    if (values.dispatchSites) {
        let dispatchSites = []
        const siteIds = values.dispatchSites.map(el => el.objectId)
        const sites = (await new Parse.Query(Sites)
            .containedIn("objectId", siteIds)
            .limit(parseLimitRequest)
            .find()) || []

        sites.map((site) => {
            const newDispatchToSaved = values.dispatchSites.find(el => el.objectId === site.id)
            dispatchSites.push({
                "priority": newDispatchToSaved.priority,
                "site": site
            })
        })

        parseDistributionCenter.set("dispatchSites", dispatchSites)
    }
}

export async function saveDistributionCenter(distributionCenter) {
    await distributionCenter.save()
}

export async function getDistributionCentersOrderByPriority() {
    const distributionCenters = (await new Parse.Query(DistributionCenters)
        .include("image")
        .limit(parseLimitRequest)
        .find()) || []

    return distributionCenters.map(elem => elem.toJSON())
}

export async function saveObjects(objects) {
    for (const idx in objects) {
        const object = objects[idx]
        await object.save()
    }
}

export async function getAvailableBrand(itemId, type) {
    let result = null

    if (type === "Recipe") {
        result = await new Parse.Query(Recipe)
            .equalTo("objectId", itemId)
            .first()

        return result ? result.toJSON().brands.map(brand => ({ name: brand })) : []
    }
    else {
        result = await new Parse.Query(SubcontractorsProducts)
            .equalTo("objectId", itemId)
            .first()

        return result ? [{ name: result.toJSON().brand }] : []
    }
}

export const getProductionItemsByRecipe = async (recipeId) => {
    const productionItems = await new Parse.Query(ProductionItem)
        .equalTo("itemId", recipeId)
        .equalTo("itemType", "Recipe")
        .notEqualTo("deleted", true)
        .find()

    return productionItems ? productionItems.map(productionItem => productionItem.toJSON()) : []
}

export async function checkProductionItemExist(saleDate, itemId, brand) {
    const productionItem = await new Parse.Query(ProductionItem)
        .equalTo("brand", brand)
        .equalTo("itemId", itemId)
        .equalTo("saleDate", saleDate)
        .first()

    return productionItem ? true : false
}

export async function deleteProductionItems(productionItemIds) {
    const productionItems = await new Parse.Query(ProductionItem)
        .containedIn("objectId", productionItemIds)
        .find()

    return await Parse.Object.destroyAll(productionItems)
}

// count of productionItems in sales for a given item id and a given period
export const getActiveProductionItemsCount = async (itemId, startTimestamp, endTimestamp) => {
    return await new Parse.Query(ProductionItem)
        .equalTo("itemId", itemId)
        .exists("saleDate")
        .greaterThanOrEqualTo("saleDate", startTimestamp)
        .lessThanOrEqualTo("saleDate", endTimestamp)
        .count()
}


export async function getRecipesForProductionReport(recipesIds, toJson = true) {
    const recipes = await new Parse.Query(Recipe)
    .containedIn("objectId", recipesIds)
    .select("commercialName", "uniqueCode", "type")
    .find()

    return toJson ? recipes.map(recipe => recipe.toJSON()) : recipes
}