import React, { useState} from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.BLUEGREY_700,
        borderRadius: 4,
        paddingTop: 25,
        paddingRight: 20,
        paddingLeft: 15
    },
    emptyContainer: {
        composes: "$container",
        backgroundColor: COLORS.WHITE
    },
    filledContainer: {
        composes: "$container",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: COLORS.WHITE
    },
    emptyTitle: {
        composes: "$title",
        color: COLORS.BLACK
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
        "&.filled": {
            backgroundColor: COLORS.BLUEGREY_700
        }
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        },
        "&.filled": {
            color: COLORS.WHITE,
            "&:hover": {
                backgroundColor: COLORS.BLUEGREY_700
            }
        }
    },
    newsContainer: {
        padding: 16,
        fontFamily: "Roboto",
        fontSize: 19,
        lineHeight: "130%",
        color: COLORS.WHITE,
        whiteSpace: "pre-wrap"
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: "12px 17px"
    }
}))

export const News = props => {
    const {
        news,
        onEdit,
        canEdit
    } =  props

    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)
    const [value, setValue] = useState(news)

    const clickIconButton = () => {
        setIsEditing(!isEditing)
        if (isEditing) {
            onEdit(value)
        }
    }

    const edit = () => setIsEditing(true)

    return (
        <Paper className={news ? classes.filledContainer : classes.emptyContainer}>
            <div className={classes.titleContainer}>
                <span className={news ? classes.title : classes.emptyTitle}>
                    Remarques et actualité
                </span>
                {
                    canEdit
                        ?
                            <IconButton
                                className={clsx(classes.iconContainer, news ? "filled" : "")}
                                disableRipple={true}
                                onClick={edit}
                                size="large">
                                <EditIcon
                                    className={clsx(classes.icon, news ? "filled" : "")}
                                />
                            </IconButton>
                        : null

                }
            </div>
            <div className={classes.newsContainer}>
                {
                    news
                }
            </div>
            {
                canEdit &&
                <Dialog onClose={() => setIsEditing(false)} aria-labelledby="customized-dialog-title" open={isEditing}>
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => setIsEditing(false)}
                        style={{fontSize: 20}}
                    >
                        Editer les remarques et actualités
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField
                            variant="standard"
                            multiline
                            autoFocus
                            fullWidth
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            InputProps={{disableUnderline: false}}
                        />
                    </DialogContent>
                    <DialogActions classes={{root: classes.rootDialogActions}}>
                        <Button
                            onClick={() => setIsEditing(false)}
                        >
                            ANNULER
                        </Button>
                        <Button
                            onClick={clickIconButton}
                            color="primary"
                            variant="contained"
                        >
                            ENREGISTRER
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Paper>
    )
}

export default News
