import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"

import SecondaryDLCMenu from "./SecondaryDLCMenu"
import SecondaryDLCCart from "./SecondaryDLCCart"

import LeftSideWrapper from "../../components/Order/Lot/LeftSideWrapper"
import RightSideWrapper from "../../components/Order/Lot/RightSideWrapper"
import { getLastSelectedSecondaryDLCSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"

const useStyles = makeStyles({
  secondaryDLCView: {
    paddingTop: 64,
    height: "100%"
  },
  rightSide: {
    maxWidth: 350,
    width: 350
  }
})

const SecondaryDLCView = ({ secondaryDLCsBySections = [] }) => {

  const lastSelectedSecondaryDLC = useSelector(getLastSelectedSecondaryDLCSelector)

  const handleScrollToLastUpdated = (lastSecondaryDLC) => {
    setTimeout(() => {
      document.getElementById(`secondarydlc-${lastSecondaryDLC.objectId}`)?.parentElement?.parentElement?.scrollIntoView({
          behavior: "instant",
          block: "center"
      })
  }, 50)
}

  useEffect(() => {
    if (lastSelectedSecondaryDLC) {
      handleScrollToLastUpdated(lastSelectedSecondaryDLC)
    }
  }, [lastSelectedSecondaryDLC])

  const [selectedSecondaryDLC, setSelectedSecondaryDLC] = useState(null)

  const handleSecondaryDLCSelect = (secondaryDLC) => {
    if (selectedSecondaryDLC === secondaryDLC) {
      setSelectedSecondaryDLC(null)
    }
    else {
      setSelectedSecondaryDLC(secondaryDLC)
    }
  }

  const handleSecondaryDLCUnselect = () => setSelectedSecondaryDLC(null)

  const classes = useStyles()

  return (
    <div className={classes.secondaryDLCView}>
      <LeftSideWrapper fullWidth={false}>
        <SecondaryDLCMenu
          secondaryDLCsBySections={secondaryDLCsBySections}
          onSelect={handleSecondaryDLCSelect}
          selectedSecondaryDLC={selectedSecondaryDLC}
        />
      </LeftSideWrapper>
      <RightSideWrapper
        className={classes.rightSide}>
        <SecondaryDLCCart
          selectedSecondaryDLC={selectedSecondaryDLC}
          onUnselect={handleSecondaryDLCUnselect}
        />
      </RightSideWrapper>
    </div>
  )

}

export default SecondaryDLCView