import { getServerUrl } from "../utils"
import {axiosCall} from "../utils/axiosUtils"

export async function updateProductStockFCVolume(productStockId, type, volume) {
	try {
		const url = `${getServerUrl()}/stocks/updateProductStockFCVolume`
		const data = {
			productStockId,
			type,
			volume
		}
		
		const result = await axiosCall("PUT", url, data, {})
		
		return result.status === 200 && result.data.success
	}
	catch (e) {
		return false
	}
}