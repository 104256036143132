import React from "react"
import SecondaryDLCHeader from "./SecondaryDLCHeader"
import clsx from "clsx"
import { COLORS } from "../../utils"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"
import SecondaryDLCFilterFormWrapper from "./SecondaryDLCFilterFormWrapper"

const useStyles = makeStyles({
  container: {
    alignSelf: "stretch",
    composes: "flexClassicCenter flex1",
    backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
  }
})

const SecondaryDLCFilter = () => {

  const classes = useStyles()

  return (
    <Box className={clsx("flexCenter", "flex1")}>
      <Box sx={{ alignSelf: "stretch" }}>
        <SecondaryDLCHeader />
      </Box>
      <Box className={classes.container}>
        <SecondaryDLCFilterFormWrapper />
      </Box>
    </Box>
  )

}

export default SecondaryDLCFilter