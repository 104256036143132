import React, {useCallback, useEffect, useMemo, useState} from "react"
import { useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import {
    computeOrderSupplierItemQuantity,
    generateStockUnitLabel
} from "../../../utils/orderReception"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import DataGrid from "../../../components/DataGrid/DataGrid"
import { LOT_DAMAGE_MODE, LOT_INVENTORY_MODE, LOT_OUTPUT_MODE } from "../../../utils/lotInventoryUtils"
import { showOrderSupplierItemLots } from "../../../actions/OrderReception/OrderReception"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiPaper-rounded": {
          width: 560,
          padding: "35px 20px"
        }
    },
    dialogContent: {
        paddingTop: 0,
        paddingBottom: 13
    },
    cell: {
        textAlign: "center"
    },
    pointer: {
        cursor: "pointer"
    }
}))

const ProductsReceived = (props) => {
    const {
        articles,
        onInitializeOrderReception,
        canEdit,
        canInitialize,
        receptionStatus = "",
        orderReception, 
        site,
        date
    } = props

    const classes = useStyles()
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [data, setData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        createDataObject()
    }, [articles])

    const createDataObject = useCallback(() => {
        articles && setData(articles.map(createData))
    })

    const onClickItem = useCallback((orderSupplierItemId) => {
        if (canEdit || !canInitialize) return
        const article = articles.find(article => article.supplierItem.objectId === orderSupplierItemId)
        // open initialize dialog
        if (receptionStatus === "IN_PROGRESS" && article.lots.find(lot => (lot.events || []).some(event => [LOT_OUTPUT_MODE, LOT_INVENTORY_MODE, LOT_DAMAGE_MODE].includes(event.mode)))) {
            dispatch(showOrderSupplierItemLots(site.objectId, date, orderReception.objectId, orderSupplierItemId, true))
            return
        }
        setSelectedArticle(article)
    }, [articles, canEdit, canInitialize, receptionStatus, site, date, orderReception])

    const articleHasOutputEvents = useMemo(() => {
        if (!selectedArticle) return
        return !!selectedArticle.lots.find(lot => (lot.events || []).some(event => [LOT_OUTPUT_MODE, LOT_INVENTORY_MODE, LOT_DAMAGE_MODE].includes(event.mode)))
    }, [selectedArticle])


    const handleClose = () => {
        setSelectedArticle(null)
    }

    const handleOrderReceptionInitialize = () => {
        onInitializeOrderReception(selectedArticle.supplierItem.objectId)
        handleClose()
    }

    const createData = (orderSupplierItem) => {
        return {
            id: orderSupplierItem.supplierItem.objectId,
            name: orderSupplierItem.supplierItem.name,
            reference: orderSupplierItem.supplierItem.reference,
            quantity: computeOrderSupplierItemQuantity(orderSupplierItem) + "-" + ((orderSupplierItem.quantities && orderSupplierItem.quantities.reception) || "0") + "-" + generateStockUnitLabel(orderSupplierItem.supplierItem),
        }
    }

    const columns = [
        {
            key: "name",
            label: "Nom de l'article",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "reference",
            label: "Références",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "quantity",
            label: "Quantité commandée",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const quantity = value.split("-")[0]
                const receivedQuantity = value.split("-")[1]
                const unit = value.split("-")[2]
                return (
                  <div><span>{receivedQuantity} / {quantity}</span><span style={{color: "#7C7C7C"}}> ({unit})</span></div>
                )
            }
        }
    ]

    return (
        <div>
            <DataGrid
              title={"Produits réceptionnés"}
              columns={columns}
              data={data}
              withFilters={true}
              rowLabel={null}
              onRowClick={(orderSupplierItem) => onClickItem(orderSupplierItem.id)}
              withAdditionalFiltersOptions={false}
              tableContainerStyle={{boxShadow: "none"}}
            />
            <Dialog
                open={!!selectedArticle}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                className={classes.dialog}
              >
                <DialogTitle id="alert-dialog-title">
                  {
                        articleHasOutputEvents
                            ? "Impossible de recommencer la réception de cet article"
                            : " Voulez-vous recommencer la réception de cette article ?"
                    }
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {
                        articleHasOutputEvents
                        ? (
                            <Typography>
                                Un inventaire ou une sortie a déjà été effectué sur le(s) lot(s) associ(é)s à cet article. Il n'est pas possible de recommencer la réception.
                            </Typography>
                        ) : (
                            <Typography>
                                Tous les lots et avoirs générés pour cette référence seront supprimés.
                            </Typography>  
                        )
                    }
                </DialogContent>
                {
                    selectedArticle ? (
                        <DialogActions>
                            {!articleHasOutputEvents && (
                                <Button onClick={handleOrderReceptionInitialize} color="primary" variant="outlined">
                                    Recommencer
                                </Button>
                            )}
                            <Button
                                onClick={handleClose}
                                color="primary"
                                variant="contained"
                            >
                                {articleHasOutputEvents ? "Retour" : "Annuler"}
                            </Button>
                        </DialogActions>
                    ) : null
                }
              </Dialog>
        </div>
    )
}

export default ProductsReceived