import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import withStyles from "@mui/styles/withStyles"
import {
    createClassificationAllergenIngredient,
    showAllergensClassificationsIngredients,
    showSingleAllergenClassificationsIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Allergens/Allergens"
import { allergenIngredientFormInitialValues } from "../../../../../actions/Utils/utils"
import { getAllergenIngredient } from "../../../../../reducers/Ingredients/Classifications/AllergensIngredients"
import {recipeFormstyles} from "../../../../../styles"
import { allergenIngredientValidator } from "../../../../../utils/validators"
import ReduxTextField from "../../../../../components/form/ReduxTextField"

let AllergenIngredientForm = (props) => {
    const {
        showAllergensClassificationsIngredients,
        showSingleAllergenClassificationsIngredientsRead,
        allergenIngredient,
        location,
        classes,
        handleSubmit,
        createClassificationAllergenIngredient,
    } = props

    const _onClose = () => {
        if (location.state && location.state.returnPath === "edit"){
            showSingleAllergenClassificationsIngredientsRead(allergenIngredient.objectId)
            return
        }
        showAllergensClassificationsIngredients()
    }

    return (
        <div className={classes.container}>
            <div className={classes.closeWindow}>
                <IconButton aria-label="Fermer" onClick={_onClose} size="large">
                    <CloseIcon/>
                </IconButton>
            </div>
            <div className={classes.formContainer}>
                <form onSubmit={handleSubmit(createClassificationAllergenIngredient, location)}>
                    <div className="flexRow">
                        <FormControl margin="normal" classes={{root: classes.formControlMax400}} required>
                            <Field
                                name="name"
                                component={ReduxTextField}
                                label="Nom"
                            />
                        </FormControl>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            classes={{root: classes.actionButton}}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

AllergenIngredientForm = reduxForm({
    form: "allergenIngredient",
    validate: allergenIngredientValidator,
    enableReinitialize: false
})(AllergenIngredientForm)

AllergenIngredientForm = withStyles(recipeFormstyles)(AllergenIngredientForm)

export default connect((state, props) => {
    const { params: {id} } = props
    const allergenIngredient = id ? getAllergenIngredient(state, id) : null
    const allergenIngredientFormValues = allergenIngredient !== null ? state.form.allergenIngredient ? state.form.allergenIngredient.values : undefined : undefined
    return {
        initialValues: allergenIngredientFormInitialValues(allergenIngredient),
        allergenIngredientFormValues,
        allergenIngredient: allergenIngredient
    }
}, {
    createClassificationAllergenIngredient,
    showAllergensClassificationsIngredients,
    showSingleAllergenClassificationsIngredientsRead
})(AllergenIngredientForm)
