import React from "react"
import MySnackbarContentWrapper from "../MySnackbarContentWrapper"
import Snackbar from "@mui/material/Snackbar"
import {useDispatch} from "react-redux"
import {closePlanningSnackBar} from "../../actions/Planning/Planning"

export const SnackbarMealPlanner = ({ planningSnackBar }) => {
  const dispatch = useDispatch()
    return (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={planningSnackBar.open}
          autoHideDuration={planningSnackBar.duration}
          onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
        >
          <MySnackbarContentWrapper
            variant={planningSnackBar.type}
            message={planningSnackBar.message}
            onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
          />
        </Snackbar>
    )
}

export default SnackbarMealPlanner
