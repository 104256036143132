// TODO: comment the plus button below before the prod release
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
    useDispatch,
    useSelector
} from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import IconButton from "@mui/material/IconButton"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import clsx from "clsx"
import { Modal } from "@mui/material"
import Parse from "parse"
import Snackbar from "@mui/material/Snackbar"

import {
    closeDispatchSnackBar,
    changeProductTab,
    downloadBL,
    downloadFridgePlano,
    createProductItemsByLots,
    createAmbientProductsDispatchFromDataAPI,
    showDispatchInterface
} from "../../../actions/Dispatch/dispatch"
import DispatchToDo from "../DispatchToDo"
import DispatchError from "../DispatchError"
import DispatchDone from "../DispatchDone"
import {
    COLORS,
} from "../../../utils"
import DispatchInProgress from "../DispatchInProgress"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import DispatchActions from "../../../components/Dispatch/DispatchActions"
import DispatchBLModal from "../../../components/Dispatch/DispatchBLModal"
import HelpButton from "../../../components/HelpButton"
import helpLinks from "../../../utils/HelpLinks"
import { CollapsibleFloatingButton } from "../../../components/CollapsibleFloatingButton"
import AddToDispatchManualModal from "./AddToDispatchManualModal"
import { loadSubcontractorsProductsByBrand } from "../../../actions/Subcontractor/subcontractorsProducts"
import AddToDispatchByAlgoModal from "./AddToDispatchByAlgoModal"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import DispatchHeaderTitle from "../DispatchHeaderTitle"
import { getProductTypeOptionsSelector } from "../../../reducers/ProductTypes/productTypes"
import {
    getDefaultProductOptions,
    getProductTypesByOptions
} from "../../../utils/productTypesUtils"

const useClasses = makeStyles({
    tab: {
        composes: "flex1",
        outline: "none"
    },
    dispatchModal: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll"
    },
    divider: {
        border: "1px solid #e8e8e8",
        width: "100%"
    },
    username: {
        color: COLORS.DARK_GREY
    },
    tabContainer: {
        display: "flex"
    },
    tabActions: {
        position: "absolute",
        right: 0
    },
    rightInfosBloc: {
        justifyContent: "flex-end",
        marginLeft: "auto",
        marginRight: 0,
        marginTop: 5
    },
    dispatchModalContainer: {
        outline: "none",
        paddingBottom: 70
    },
    floatingButton: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)",
    }
})

const ProductDispatch = () => {
    const date = useSelector(state => parseFloat(state.dispatch.date))
    const brand = useSelector(state => state.dispatch.brand)
    const tabValue = useSelector(state => state.dispatch.productTab)
    const dispatchSnackBar = useSelector(state => state.dispatch.dispatchSnackBar)
    const dispatchDataToDo = useSelector(state => state.dispatch.dispatchDataToDo)
    const dispatchDataInProgress = useSelector(state => state.dispatch.dispatchDataInProgress)
    const dispatchDataError = useSelector(state => state.dispatch.dispatchDataError)
    const dispatchDataDone = useSelector(state => state.dispatch.dispatchDataDone)
    const suppliers = useSelector(state => state.subcontractorsProducts.suppliers)
    const subcontractorsProducts = useSelector(state => state.subcontractorsProducts.subcontractorsProductsList)
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const dispatch = useDispatch()
    const classes = useClasses()
    const user = Parse.User.current()
    
    const dispatchDataInProgressStarted = dispatchDataInProgress.filter(elm => elm.prodWarehouse !== null && elm.hubs.map(n => n.locked).includes(true))
    const dispatchDataInProgressNotStarted = dispatchDataInProgress.filter(elm => elm.prodWarehouse !== null && !elm.hubs.map(n => n.locked).includes(true))
    
    const [openDispatchBLModal, setOpenDispatchBLModal] = useState(false)
    const [openAddAmbientModal, setOpenAddAmbientModal] = useState(false)
    const [openSelectAmbientsModal, setOpenSelectAmbientsModal] = useState(false)
    const [loading, setLoading] = useState(false)

    useLayoutEffect(() => {
        document.title = "KFC - Dispatch"

        return () => (document.title = "KFC")
    }, [])

    useEffect(() => {
        let isMounted = true
        async function checkTab() {
            if (undefined === tabValue) await changeProductTab(0)
        }

        if (isMounted) checkTab()
        return () => (isMounted = false)
    }, [])

    const onBack = () => dispatch(showDispatchInterface())

    const _onTabChange = (event, tabValue) => {
        dispatch(changeProductTab(tabValue))
    }

    const handleOpenAddAmbientDialog = async () => {
        setOpenAddAmbientModal(true)
        setLoading(true)
        const productTypes = getProductTypesByOptions(productTypeOptions)
        await dispatch(loadSubcontractorsProductsByBrand("FOODCHERI", productTypes))
        setLoading(false)
    }

    const _onSaveProductTypes = (values) => {
        dispatch(createAmbientProductsDispatchFromDataAPI(values.productTypes))
    }

    const handleSelectProducts = (products) => {
        dispatch(createProductItemsByLots(products))
    }

    return (
        <>
            <Modal key="dispatch-modal" BackdropProps={{invisible: true}} className={classes.dispatchModal} open={true}>
                <div className={classes.dispatchModalContainer}>
                    <GenericTopBarHeader
                        leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                        title={<DispatchHeaderTitle />}
                        className="stretchSelf"
                        rightAction={<span className={classes.username}>Picker : {user.get("username")}</span>}
                    />
                    <div className={classes.tabContainer}>
                        <div className={clsx(classes.tab, "flexCenter")}>
                            <Tabs
                                value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                                onChange={_onTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label={`À RECEPTIONNER (${dispatchDataToDo && dispatchDataToDo.length})`}/>
                                <Tab label={`À DISPATCHER (${dispatchDataInProgressNotStarted && dispatchDataInProgressNotStarted.length})`}/>
                                <Tab label={`EN COURS (${dispatchDataInProgressStarted && dispatchDataInProgressStarted.length})`}/>
                                <Tab label={`À VÉRIFIER (${dispatchDataError && dispatchDataError.length})`}/>
                                <Tab label={`DISPATCHÉ (${dispatchDataDone && dispatchDataDone.length})`}/>
                            </Tabs>
                            <div className={classes.divider}/>
                            {tabValue === 0 && <DispatchToDo/>}
                            {tabValue === 1 && <DispatchInProgress/>}
                            {tabValue === 2 && <DispatchInProgress started={true} />}
                            {tabValue === 3 && <DispatchError/>}
                            {tabValue === 4 && <DispatchDone/>}
                        </div>
                        <div className={classes.tabActions}>
                            <DispatchActions
                                brand={brand}
                                onClickBL={() => {setOpenDispatchBLModal(true)}}
                                onClickFridge={() => {dispatch(downloadFridgePlano(date))}}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <DispatchBLModal
                open={openDispatchBLModal}
                onConfirm={(values) => {
                    dispatch(downloadBL(values, brand))
                    setOpenDispatchBLModal(false)
                }}
                onClose={() => {setOpenDispatchBLModal(false)}}
                date={date}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom", horizontal: "left"
                }}
                open={dispatchSnackBar.open}
                autoHideDuration={dispatchSnackBar.duration}
                onClose={() => {dispatch(closeDispatchSnackBar(dispatchSnackBar.type))}}
            >
                <MySnackbarContentWrapper
                    onClose={() => {dispatch(closeDispatchSnackBar(dispatchSnackBar.type))}}
                    variant={dispatchSnackBar.type}
                    message={dispatchSnackBar.message}
                />
            </Snackbar>
            {brand === "FOODCHERI" && (
                <CollapsibleFloatingButton>
                    <IconButton
                        color="primary"
                        className={classes.floatingButton}
                        onClick={() => setOpenSelectAmbientsModal(true)}
                        size="large">
                        <AutorenewIcon /> 
                    </IconButton>
                    <IconButton
                        color="primary"
                        className={classes.floatingButton}
                        onClick={handleOpenAddAmbientDialog}
                        size="large">
                        <AddCircleOutlineIcon /> 
                    </IconButton>
                </CollapsibleFloatingButton>                
            )}

            <HelpButton link={helpLinks.warehouse}/>
            <AddToDispatchManualModal
                open={openAddAmbientModal}
                onClose={() => setOpenAddAmbientModal(false)}
                suppliers={suppliers}
                subcontractorsProducts={subcontractorsProducts}
                loading={loading}
                onSelectProducts={handleSelectProducts}
                productTypeOptions={getDefaultProductOptions(productTypeOptions)}
            />
            <AddToDispatchByAlgoModal
                open={openSelectAmbientsModal}
                onClose={() => setOpenSelectAmbientsModal(false)}
                onSave={_onSaveProductTypes}
                productTypeOptions={getDefaultProductOptions(productTypeOptions)}
            />
        </>
    )
}

export default ProductDispatch
