import Parse from "parse"
import { parseLimitRequest } from "../../../utils"
import { getAllergensWithIds } from "../classifications/allergen/parseAllergenManager"
import { getGroupIngredientWithId } from "../classifications/group/parseGroupManager"

const CommercialNames = Parse.Object.extend("CommercialName")

export async function getCommercialNamesForAllergens(includes, toJson = true) {
    try {
        const commercialNames = (await new Parse.Query(CommercialNames)
            .ascending("name")
            .exists("allergens")
            .include(includes)
            .limit(parseLimitRequest)
            .find()) || []

        return toJson ? commercialNames.map(el => el.toJSON()) : commercialNames
    }
    catch (e) {
        console.error("parseManager.ingredients.internal.parseCommercialNameManager.getCommercialNamesForAllergens error : ", e)
        return Promise.reject(e)
    }
}

export const getCommercialNames = async ({ includes = [], toJson = true, sortBy = "name", sortDirection = "asc" }) => {
    try {
        const commercialNamesQuery = new Parse.Query(CommercialNames)
            .notEqualTo("deleted", true)
            .include(includes)

        if (sortDirection === "desc") {
            commercialNamesQuery.descending(sortBy)
        } else {
            commercialNamesQuery.ascending(sortBy)
        }

        const commercialNames = (await commercialNamesQuery.limit(parseLimitRequest).find()) || []

        return toJson ? commercialNames.map(commercialName => commercialName.toJSON()) : commercialNames
    } catch (err) {
        console.error("parseManager.ingredients.internal.parseCommercialNameManager.getCommercialNames : ", err)
        return Promise.reject(err)
    }
}

export async function querySearchCommercialNameByName(search, includes = [], toJson = true) {
    try {
        const commercialNames = (await new Parse.Query(CommercialNames)
            .contains("name", search)
            .include(includes)
            .limit(parseLimitRequest)
            .find()) || []

        return toJson
            ? commercialNames.map(elem => elem.toJSON())
            : commercialNames
    } catch (err) {
        console.error("parseManager.ingredients.internal.parseCommercialNameManager.querySearchCommercialNameByName : ", err)
        return Promise.reject(err)
    }

}

export async function getIngredientWithId(id, includes = [], toJson = true) {
    try {
        const ingredient = await new Parse.Query(CommercialNames)
            .include(includes)
            .equalTo("objectId", id)
            .first()

        return toJson
            ? ingredient && ingredient.toJSON()
            : ingredient
    } catch (err) {
        console.error("parseManager.ingredients.internal.getCommercialNamesWithId : ", err)
        return Promise.reject(err)
    }
}

export async function deleteCommercialNameWithId(id) {
    try {
        const commercialName = await new Parse.Query(CommercialNames)
            .equalTo("objectId", id)
            .limit(parseLimitRequest)
            .first()
        if (commercialName) {
            await commercialName.destroy()
        }
    } catch (e) {
        console.error("parseManager.ingredients.internal.deleteCommercialName : ", e)
        return Promise.reject(e)
    }
}

export const ingredientKeysToIgnore = [
    "addAllergen", "family", "allergens", "group", "commercialName",
    "firstOriginAverage", "label", "firstOrigin", "conservationMode",
    "secondOrigin", "secondOriginAverage", "supplier"
]

export async function createACommercialName(values, original, toJson = true) {
    try {

        const commercialName = original
            ? await getIngredientWithId(original.objectId, ["group.family", "allergens"], false)
            : new CommercialNames()

        /* Allergens */
        if (values.allergens) {
            const allergensIds = values.allergens.map(allergen => allergen.objectId)
            const allergens = await getAllergensWithIds(allergensIds, [], false)
            commercialName.set("allergens", allergens ? allergens : null)
        }

        /* Group */
        if (values.group) {
            const group = await getGroupIngredientWithId(values.group, [], false)
            commercialName.set("group", group ? group : null)
        }

        /* Sourcing */
        if (values.firstOrigin !== null) {
            commercialName.set("firstOrigin", values.firstOrigin)
            if (values.secondOrigin !== null) {
                commercialName.set("secondOrigin", values.secondOrigin)
            }
        }
        else {
            commercialName.set("firstOrigin", null)
            commercialName.set("secondOrigin", null)
        }

        if (values.firstOrigin !== null && values.firstOriginAverage) {
            commercialName.set("firstOriginAverage", parseFloat(values.firstOriginAverage))
            if (values.firstOriginAverage !== 100) {
                commercialName.set("secondOriginAverage", parseFloat(values.secondOriginAverage))
            } else {
                commercialName.set("secondOriginAverage", 0)
            }
        }
        else {
            commercialName.set("firstOriginAverage", null)
            commercialName.set("secondOriginAverage", null)
        }

        commercialName.set("conservationMode", values.conservationMode !== null ? values.conservationMode : null)
        commercialName.set("label", values.label !== null ? values.label : null)

        Object.keys(values).forEach(function (key) {
            const val = values[key]
            if (!ingredientKeysToIgnore.includes(key) && val) {
                commercialName.set(key, val)
            }
        })

        await commercialName.save()
        return toJson
            ? commercialName.toJSON()
            : commercialName
    } catch (e) {
        console.error("parseManager.ingredients.internal.createACommercialName :", e)
    }
}
