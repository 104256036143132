import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"
import SecondaryDLCHeader from "./SecondaryDLCHeader"
import SecondaryDLCView from "./SecondaryDLCView"
import SecondaryDLCCreationModal from "./SecondaryDLCCreationModal"
import { getSecondaryDLCKitchenAreaSelector, getSecondaryDLCSiteSelector, getSecondaryDLCSnackbarSelector, getSecondaryDLCsBySectionsSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { closeSecondaryDLCSnackbar, createSecondaryDLCAction, showSecondaryDLCFilter } from "../../actions/SecondaryDLC/secondaryDLC"
import CreateDocumentFab from "../../components/common/CreateDocumentFab"

import FullScreenWrapper from "../../components/FullScreenWrapper"
import { AppBar, styled, Snackbar } from "@mui/material"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1
}))

const SecondaryDLC = () => {

  const dispatch = useDispatch()

  const secondaryDLCsBySections = useSelector(getSecondaryDLCsBySectionsSelector)

  const secondaryDLCSnackbar = useSelector(getSecondaryDLCSnackbarSelector)

  const [createModalOpen, setCreateModalOpen] = useState(false)

  const backAction = dispatch(showSecondaryDLCFilter)

  const site = useSelector(getSecondaryDLCSiteSelector)
  const kitchenArea = useSelector(getSecondaryDLCKitchenAreaSelector)
  const date = dayjs().format("DD/MM/YYYY")

  const subtitle = "- " + site?.name + (kitchenArea ? `, ${kitchenArea.name}` : "") + ` - ${date}`

  const openCreateModal = () => setCreateModalOpen(true)
  const closeCreateModal = () => setCreateModalOpen(false)

  const handleCreateSecondaryDLC = async (values) => {
    await dispatch(createSecondaryDLCAction(values))
    closeCreateModal()
  }

  const closeSnackbar = () => {
    dispatch(closeSecondaryDLCSnackbar())
  }


  return (
    <FullScreenWrapper>
      <StyledAppBar>
        <SecondaryDLCHeader
          backAction={backAction}
          info={subtitle}
        />
      </StyledAppBar>
      <SecondaryDLCView
        secondaryDLCsBySections={secondaryDLCsBySections}
      />
      <CreateDocumentFab onClick={openCreateModal} />
      <SecondaryDLCCreationModal
        open={createModalOpen}
        onClose={closeCreateModal}
        onSubmit={handleCreateSecondaryDLC}
        siteId={site?.objectId}
        kitchenAreaId={kitchenArea?.objectId}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={secondaryDLCSnackbar.open}
        autoHideDuration={secondaryDLCSnackbar.duration}
        onClose={closeSnackbar}
      >
        <MySnackbarContentWrapper
          variant={secondaryDLCSnackbar.type || "success"}
          message={secondaryDLCSnackbar.message}
          onClose={closeSnackbar}
        />
      </Snackbar>
    </FullScreenWrapper>
  )

}

export default SecondaryDLC