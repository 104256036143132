import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"

const useStyles = makeStyles(() => ({
	container: {
		fontSize: 20,
		width: "auto",
		minWidth: 500,
		height: "auto",
		padding: 20
	},
	title: {
		margin: "10px 0",
		fontSize: 20
	},
	selectContainer: {
		width: 200,
		marginBottom: 20
	},
	buttonsContainer: {
		marginTop: 50,
		width: "100%",
		display: "flex",
		justifyContent: "space-between"
	},
	selectField: {
		width: "100%"
	}
}))

const StockageForm = (props) => {
	const {
		supplierItem,
		onUpdateSupplierItem,
		onClose,
		sites
	} = props
	
	const classes = useStyles()
	const [selectedSite, setSelectedSite] = useState(supplierItem && supplierItem.site ? supplierItem.site.objectId : sites[0].objectId)
	const [selectedStockZone, setSelectedStockZone] = useState(supplierItem && supplierItem.stockZone ? supplierItem.stockZone.objectId : sites[0].stockZones[0].objectId)
	
	const submit = async () => {
		const values = {
			site: selectedSite,
			stockZone: selectedStockZone
		}
		
		onUpdateSupplierItem("STOCKAGE", values)
		onClose()
	}
	
	const renderSites = () => {
		return sites.map((site, i) =>
			<MenuItem key={i} value={site.objectId}>{site.name}</MenuItem>
		)
	}
	
	const renderStockZone = () => {
		const currentSite = sites.find(el => el.objectId === selectedSite)
		
		if (currentSite) {
			return currentSite.stockZones.map((stockZone, i) =>
				<MenuItem key={i} value={stockZone.objectId}>{stockZone.name}</MenuItem>
			)
		}
	}
	
	const onChangeSelectedSite = (event) => {
		const currentSite = sites.find(el => el.objectId === event.target.value)
		
		setSelectedSite(currentSite.objectId)
		setSelectedStockZone(currentSite.stockZones[0].objectId)
	}
	
	return (
		<div className={classes.container}>
			<p className={classes.title}>Editer le stockage</p>
			<div className={classes.selectContainer}>
				<InputLabel>Sites</InputLabel>
				<Select
					variant="standard"
					value={selectedSite}
					onChange={onChangeSelectedSite}
					className={classes.selectField}
				>
					{renderSites()}
				</Select>
			</div>
			<div className={classes.selectContainer}>
				<InputLabel>Zones de stockage</InputLabel>
				<Select
					variant="standard"
					value={selectedStockZone}
					onChange={(e) => {setSelectedStockZone(e.target.value)}}
					className={classes.selectField}
				>
					{renderStockZone()}
				</Select>
			</div>
			<div className={classes.buttonsContainer}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="button"
					onClick={submit}
				>
					Valider
				</Button>
			</div>
		</div>
	)
}

export default StockageForm