import React from "react"
import Dialog from "@mui/material/Dialog"
import { makeStyles } from "@mui/styles"
import {Button, DialogActions, DialogContent, Typography} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import {WeekCalendarPicker} from "../../components/Calendar/WeekCalendarPicker"
import { COLORS } from "../../utils"

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paperScrollPaper": {
      width: 372,
      height: 298,
      padding: "25px 25px"
    }
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 13
  },
  selectDateLabel: {
    fontSize: "0.7em"
  },
  datePicker: {
    width: 280
  }
}))

const AddProductToCardModal = (props) => {
    const { open, handleClose, date, onDateChange, createProductToCard } = props
    const classes = useStyles()

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
      >
        <DialogContent className={classes.dialogContent}>
          <Typography sx={{ color: COLORS.BLACK_500 }}>
            Sélectionnez la semaine pour laquelle vous souhaitez ajouter des produits à la carte hebdo ?
          </Typography>
        </DialogContent>
        <>
          <DialogContent className={classes.dialogContent}>
            <div>
                <InputLabel className={classes.selectDateLabel}>Choix de la semaine</InputLabel>
                <WeekCalendarPicker onDateChange={onDateChange} date={date} classes={classes} />
            </div>
          </DialogContent>
        </>
        {
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" variant="text" onClick={handleClose} sx={{marginRight: 5}}>
              Annuler
            </Button>
            <Button
              onClick={createProductToCard}
              color="primary"
              variant="contained"
              sx={{marginLeft: 5}}
            >
              Créer la carte
            </Button>
          </DialogActions>
        }
      </Dialog>
    )
}
export default AddProductToCardModal