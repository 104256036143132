import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import CheckIcon from "@mui/icons-material/Check"

const useStyles = makeStyles({
    insideicon:{
        borderRadius: "50%",
        width: 20,
        height: 20,
    },
    withMargin: {
        composes: "$insideicon",
        marginTop: -3
    }
})

function IconCheck({color, bgColor, withMargin}) {
    const classes = useStyles()
    return (
        <div className={withMargin ? classes.withMargin : ""}>
            <CheckIcon className={classes.insideicon} style={{ backgroundColor:bgColor, color:color}}/>
        </div>
    )
}

export default IconCheck
