import React from "react"
import { productType } from "../../utils/dispatchUtils"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const MultipleTypeSelect = (props) => {
    const {classes, value, ...otherProps} = props

    const renderData = (objects, selectedObjects) => {
        return (
            objects.map((item, index) =>
                <MenuItem
                    value={item.value}
                    key={index}
                >
                    <Checkbox
                        checked={selectedObjects.includes(item.value) || selectedObjects.includes("ALL")}
                        color="secondary"
                    />
                    <ListItemText primary={item.label} />
                </MenuItem>
            )
        )
    }

    const renderSelectResult = (selected, resources) => {
        if (selected.includes("ALL")) {
            return resources[0].label
        }
        else {
            return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
        }
    }

    return (
        <FormControl className={classes.typeFormControl} variant="standard">
            <InputLabel>Types</InputLabel>
            <Select
                variant="standard"
                multiple={true}
                name="types"
                label="types"
                id="types"
                fullWidth {...otherProps}
                renderValue={ selected => renderSelectResult(selected, productType) }
                value={value}
                MenuProps={MenuProps}
            >
                { renderData(productType, value) }
            </Select>
        </FormControl>
    )
}

export default MultipleTypeSelect
