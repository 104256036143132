import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import ForwardIcon from "@mui/icons-material/Forward"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { lighten } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
})

class ExportProductsErrorsTableToolbar extends PureComponent {
  _resolveAll = () => {
    const { resolveErrors } = this.props
    resolveErrors()
  }

  render() {
    const { classes, numSelected, tableTitle, isFem } = this.props
    const s = numSelected > 1 ? "s" : ""
    const e = isFem ? "e" : ""

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} sélectionné{e}
              {s}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {tableTitle}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className="flexRow">
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <Tooltip title="Résoudre" disableInteractive>
                <IconButton
                  aria-label="Résoudre"
                  onClick={this._resolveAll}
                  size="large"
                >
                  <ForwardIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </Toolbar>
    )
  }
}

ExportProductsErrorsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  resolveErrors: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
  tableTitleGender: PropTypes.string,
}

ExportProductsErrorsTableToolbar = withStyles(toolbarStyles)(
  ExportProductsErrorsTableToolbar
)

export default ExportProductsErrorsTableToolbar
