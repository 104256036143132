import React, { PureComponent } from "react"

class NotFound extends PureComponent {
    render() {
        return (
            <div>
                Page Not Found !
            </div>
        )
    }
}

export default NotFound