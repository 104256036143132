import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import {COLORS, forEachBrand, KFC_BRANDS} from "../../utils"
import moment from "moment"

const useStyles = makeStyles(() => {
    const styles = {}
    
    forEachBrand(brand => {
        styles[`point-${brand.shortname}`] = {
            composes: "$pointBrand",
            backgroundColor: brand.color
        }
    })
    
    return {
        pointBrand: {
            height: 7,
            width: 7,
            borderRadius: "50%",
            marginLeft: 2,
            marginRight: 8,
            marginTop: 3
        },
        ...styles,
        dialog: {
            textAlign: "justify",
            color: COLORS.GREY_SEARCH,
            fontSize: 16,
            "& .MuiBackdrop-root": {
                backgroundColor: "rgba(0,0,0,0.1)"
            },
            "& .MuiPaper-root": {
                minWidth: 500
            }
        },
        rootDialogActions: {
            justifyContent: "flex-end",
            padding: 15,
            marginBottom: 15
        },
        title: {
            fontWeight: 500,
            fontSize: 17,
        },
        dialogTitle: {
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 0,
            "& span": {
                marginTop: 20
            }
        },
        list: {
            textAlign: "left",
            marginLeft: 15,
            display: "flex",
            alignItems: "baseline",
            marginBottom: 14,
            textTransform: "capitalize"
        }
    }
})

const ProductInCardModal = (props) => {
    const {
        open,
        productItems,
        onClose,
        action
    } = props

    const classes = useStyles()
    
    const validate = () => {
        onClose()
        if (null !== action) action()
    }

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.dialog}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{root: classes.dialogTitle}}>
                <span className={classes.dialogTitleLabel}> Attention, une modification peut avoir des impacts. Ce produit est à la carte le : </span>
            </DialogTitle>
            <DialogContent>
                {
                    productItems.map((productItem, index) => {
                        const brand = KFC_BRANDS.find(elm => elm.name === productItem.brand)
                        
                        return (
                            <div key={`product-${index}`} className={classes.list}>
                                <div className={classes[`point-${brand.shortname}`]} />
                                { moment.utc(productItem.saleDate).format("ddd DD/MM") } pour { brand.label }
                            </div>
                        )
                    })
                }
            </DialogContent>
            <DialogActions classes={{root: classes.rootDialogActions}}>
                <Button
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={validate}
                >
                    Poursuivre
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProductInCardModal
