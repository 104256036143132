import React from "react"
import { useSelector } from "react-redux"
import { Field, FieldArray } from "formik"
import {
  Box,
  IconButton,
  Stack
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"

import { productionSchemaBatchInitialValues,renderSellDaysLabels, sellDaysOptions, weekDaysOptions } from "../../../../utils/productionSchemasUtils"
import FormikErrorMessage from "../../../../components/FormikErrorMessage"

import CustomFormikField from "../../../../components/form/CustomFormikField"
import { StyledProductionSchemaBodyCell } from "./ProductionSchemaStyledComponents"
import { getProductionSchemaCellWidth } from "../../../../reducers/ProductionSchemas/productionSchemas"

export const weekDays = ["productionDay", "packagingDay"]

const ProductionSchemaBatchesField = ({
  name,
  batches,
  parentIndex,
  hoveredBatchIndex,
  hoveredBatchParentIndex,
  interactions
}) => {

  const productionSchemaCellWidth = useSelector(getProductionSchemaCellWidth)

  const { onHover, onCancelHover } = interactions

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <div className="flexColumn stretchSelf">
          {batches.length && (
            <Stack spacing={2} className="stretchSelf">
              {batches.map((batch, index) => {
                // only allow add remove batches and show actions button if row hovered
                const actionsEnabled = hoveredBatchIndex === index && hoveredBatchParentIndex === parentIndex
                return (
                  // row
                  <div
                    key={parentIndex + index}
                    className="flexRow center"
                    onMouseEnter={() => onHover(parentIndex, index)}
                    onTouchStart={() => onHover(parentIndex, index)}
                    onMouseLeave={onCancelHover}
                    onTouchEnd={onCancelHover}
                  >
                    {/* --------- left --------- */}
                    <Box sx={{ width: 46 }} className="flexCenter">
                      <IconButton
                        sx={{ visibility: !actionsEnabled ? "hidden" : "auto" }}
                        onClick={!actionsEnabled ? null : () => {
                          push(productionSchemaBatchInitialValues)
                        }}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>

                    {/* --------- center --------- */}
                    <Stack direction="row" spacing={0} className="flex1">
                      {/* production day & packaging day (2 first fields) */}
                      {weekDays.map((weekday, weekDayIndex) => (
                        <Stack
                          spacing={0}
                          key={weekday + weekDayIndex}
                          className="flex1"
                        >
                          <StyledProductionSchemaBodyCell isEdition={true} width={`${productionSchemaCellWidth}px !important`}>
                            <Field
                              name={`${name}.${index}.${weekday}`}
                              component={CustomFormikField}
                              type="select"
                              placeholder="Day"
                              variant="standard"
                              options={weekDaysOptions}
                            />
                            <FormikErrorMessage
                              name={`${name}.${index}.${weekday}`}
                            />
                          </StyledProductionSchemaBodyCell>
                        </Stack>
                      ))}

                      {/* sell day by brand (3 last fields) */}
                      {batch.sellDays.map((sellDay, sellDayIndex) => (
                        <Stack
                          spacing={0}
                          key={sellDay + sellDayIndex}
                          className="flexColumn flex1"
                        >
                          <StyledProductionSchemaBodyCell isEdition width={`${productionSchemaCellWidth}px !important`}>
                            <Field
                              name={`${name}.${index}.sellDays.${sellDayIndex}.days`}
                              component={CustomFormikField}
                              type="select"
                              selectType="checkbox"
                              placeholder="Day"
                              variant="standard"
                              options={sellDaysOptions}
                              multiple
                              renderValue={renderSellDaysLabels}
                              className="stretchSelf flex1"
                            />
                          </StyledProductionSchemaBodyCell>
                          <FormikErrorMessage
                            name={`${name}.${index}.sellDays.${sellDayIndex}`}
                          />
                        </Stack>
                      ))}
                    </Stack>
                    {/* --------- right --------- */}
                    <Box sx={{ width: "46px !important" }} className="flexCenter">
                      <IconButton
                        sx={{ visibility: !actionsEnabled ? "hidden" : "auto" }}
                        onClick={!actionsEnabled ? null : () => {
                          remove(index)
                          if (batches.length === 1) {
                            push(productionSchemaBatchInitialValues)
                          }
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </div>
                )
              })}
              <Stack spacing={0}>
                <FormikErrorMessage name={name} />
              </Stack>
            </Stack>
          )}
        </div>
      )}
    </FieldArray>
  )
}

export default ProductionSchemaBatchesField
