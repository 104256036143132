import Parse from "parse"

const Section = Parse.Object.extend("Section")

export async function countGenericsSections() {
    try {
        return await new Parse.Query(Section)
            .equalTo("reusable", true)
            .count()
    }
    catch (e) {
        console.error("parseManager.products.resources.section.parseSectionManager.countGenericsSections error : ", e)
        return Promise.reject(e)
    }
}
