import React from "react"
import { makeStyles } from "@mui/styles"
import { Box, Icon, Typography } from "@mui/material"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import { COLORS } from "../../../utils"
import EuroIcon from "@mui/icons-material/Euro"

const useStyles = makeStyles(() => ({
  headerBlock: {
    flex: "0 0 64px"
  },
  header: {
    width: "100%"
  },
  headerTitle: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.ORANGE_CREDIT_NOTE
  },
  headerSubtitle: {
    paddingLeft: 8,
    fontSize: 16,
    color: COLORS.DRAFT_GREY
  }
}))

const BillingCreditNotesHeader = ({ onBack, rightAction = null, subtitle = null }) => {

  const classes = useStyles()

  return (
    <Box className={classes.headerBlock}>
    <GenericTopBarHeader
      leftAction={!onBack ? null : <GenericHeaderReturnButton onBack={onBack} />}
      title={(
        <div className={classes.headerTitle}>
          <Icon>
            <EuroIcon />
          </Icon>
          <Typography variant="h7" sx={{ fontWeight: 500 }}>
            Facturations et avoirs
          </Typography>
        </div>
      )}
      info={subtitle && <Typography className={classes.headerSubtitle}>/ {subtitle}</Typography>}
      className={classes.header}
      rightAction={rightAction}
    />
  </Box>
  )
}

export default BillingCreditNotesHeader