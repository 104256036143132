import React from "react"
import { Form, Formik } from "formik"
import { Checkbox, FormControlLabel, Stack } from "@mui/material"

import { packagingLifeCycleSchema } from "../../../../../utils/yupValidators"
import ModalForm from "../../../../../components/form/ModalForm"
import ModalActions from "../../../../../components/ModalActions"
import CountrySelect from "../../../../../components/form/CountrySelect"

const getInitialValues = (packaging) => {
  if (packaging) {
    return {
      manufacturingCountry: packaging.manufacturingCountry,
      isRecyclableInFrance: !!packaging.isRecyclableInFrance,
      isReusable: !!packaging.isReusable
    }
  }

  return {
    manufacturingCountry: "",
    isRecyclableInFrance: false,
    isReusable: false
  }
}

const PackagingLifeCycleFormDialog = ({
  packaging,
  onClose,
  open,
  onSave,
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Modifier le résumé cycle de vie"
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={getInitialValues(packaging)}
        onSubmit={onSave}
        validationSchema={packagingLifeCycleSchema}
        validateOnChange={false}
      >
        {({ values, handleChange, errors, setFieldValue }) => {
          return (
            <Form>
              <Stack spacing={2}>
                <CountrySelect
                  variant="standard"
                  name="manufacturingCountry"
                  label="Pays de fabrication"
                  value={values.manufacturingCountry}
                  error={!!errors.manufacturingCountry}
                  helperText={errors.manufacturingCountry}
                  onChange={setFieldValue}
                />
                <FormControlLabel
                  control={<Checkbox checked={values.isReusable} />}
                  label="Réemployable"
                  name="isReusable"
                  value={values.isReusable}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={<Checkbox checked={values.isRecyclableInFrance} />}
                  label="Recyclable en France"
                  name="isRecyclableInFrance"
                  value={values.isRecyclableInFrance}
                  onChange={handleChange}
                />
              </Stack>
              <ModalActions onCancel={onClose} sx={{ mt: "40px" }} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingLifeCycleFormDialog
