import React from "react"
import Icon from "@mui/material/Icon"
import { COLORS } from "../utils"

export const LunchbagColors = { WHITE: COLORS.WHITE, BLACK: COLORS.BLACK}

export const LunchbagIcon = (props) => {
    const { color = LunchbagColors.WHITE } = props

    return (
        <Icon>
            <img
                src={color === LunchbagColors.WHITE ? "/img/LunchbagWhite.svg" : "/img/LunchbagBlack.svg"}
                alt="Lunchbag"
            />
        </Icon>
    )
}

export default LunchbagIcon