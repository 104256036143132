import React, {useCallback, useEffect, useState} from "react"
import {
    computeOrderSupplierItemQuantity,
    generateStockUnitLabel,
} from "../../../utils/orderReception"
import {Box} from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DataGrid from "../../../components/DataGrid/DataGrid"

const ProductsToReceive = (props) => {
    const {
        orderSupplierItems,
        onEditItem
    } = props

    const [data, setData] = useState([])

    useEffect(() => {
        createDataObject()
    }, [orderSupplierItems])

    const createDataObject = useCallback(() => {
        orderSupplierItems && setData(orderSupplierItems.map(createData))
    })

    const createData = (orderSupplierItem) => {
        return {
            id: orderSupplierItem.supplierItem.objectId,
            name: orderSupplierItem.supplierItem.name,
            reference: orderSupplierItem.supplierItem.reference,
            quantity: computeOrderSupplierItemQuantity(orderSupplierItem) + "-" + generateStockUnitLabel(orderSupplierItem.supplierItem),
        }
    }

    const columns = [
        {
            key: "name",
            label: "Nom de l'article",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "reference",
            label: "Références",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "quantity",
            label: "Quantité commandée",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const quantity = value.split("-")[0]
                const unit = value.split("-")[1]
                return (
                  <div><span>{quantity}</span><span style={{color: "#7C7C7C"}}> ({unit})</span></div>
                )
            }
        }
    ]

    return (
        <div>
            {
                data.length > 0
                    ?
                  <DataGrid
                    title={"Produits à réceptionner"}
                    columns={columns}
                    data={data}
                    withFilters={true}
                    rowLabel={null}
                    onRowClick={(orderSupplierItem) => onEditItem(orderSupplierItem.id)}
                    withAdditionalFiltersOptions={false}
                    tableContainerStyle={{boxShadow: "none"}}
                  />
                    :
                  (
                      <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                          <Box sx={{ textAlign: "center"}}>
                              <CheckCircleIcon sx={{ color: "#44b46c", textAlign: "center", width: 52, height: 52 }} />
                          </Box>
                          Vous avez effectué la reception de l'ensemble des produits de votre commande
                      </Box>
                  )
            }

        </div>
    )
}

export default ProductsToReceive