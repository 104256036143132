

import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { Dialog, DialogContent, DialogTitle, DialogActions, Typography, FormControl, InputLabel, Select, Alert, MenuItem, Button, TextField } from "@mui/material"
import { LOT_INVENTORY_MODE, LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import dayjs from "dayjs"
import { downloadEventsLotsCsv } from "../../actions/LotInventory/LotInventory"
import { downloadLotsInventoryCsv } from "../../actions/LotInventory/LotInventory"

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paperScrollPaper": {
			width: 560,
			padding: "35px 20px"
		}
	},
	dialogContent: {
		paddingTop: 0,
		paddingBottom: 13
	},
	cell: {
		textAlign: "center"
	},
	pointer: {
		cursor: "pointer"
	},
	siteSelector: {
		width: 220,
		marginRight: 16
	},
	dateInput: {
		fontSize: 16,
		width: 180,
		textAlign: "left",
	},
	endDateInput: {
		marginTop: 16,
		fontSize: 16,
		width: 180,
		textAlign: "left",
	},
	selectContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: 16
	},
	error: {
		margin: "16px 0px"
	}
}))

const ExtractLotModal = ({
	mode = LOT_INVENTORY_MODE,
	open,
	onClose
}) => {

	const classes = useStyles()

	const dispatch = useDispatch()

	const sites = useSelector(state => state.lotInventory.sites)
	const [selectedSite, setSelectedSite] = useState(sites[0])
	const [startDate, setStartDate] = useState(moment.utc().startOf("day").valueOf())
	const [endDate, setEndDate] = useState(moment.utc().add(1, "days").startOf("day").valueOf())
	const [error, setError] = useState("")

	useEffect(() => {
		const dayDiff = dayjs(endDate).diff(dayjs(startDate), "day")
		if (dayjs(endDate).diff(dayjs(startDate), "day") > 100) {
			setError("La différence entre les dates doit être de 100 jours maximum. actuellement : " + dayDiff)
			return
		} if (dayjs(endDate).isBefore(startDate)) {
			setError("La date de fin doit être supérieur à la date de début")
			return
		}
		setError("")
	}, [startDate, endDate])


	const renderElements = (items) => {
		return items.map((item, i) =>
			<MenuItem value={item} key={i}> {item.name} </MenuItem>
		)
	}

	const onStartDateChange = (value) => {
		setStartDate(moment.utc(value).valueOf())
	}
	const onEndDateChange = (value) => {
		setEndDate(moment.utc(value).valueOf())
	}


	const downloadInventoryFile = () => {
		if (mode === LOT_OUTPUT_MODE) {
			dispatch(downloadEventsLotsCsv(selectedSite, startDate, endDate))
		} else {
			dispatch(downloadLotsInventoryCsv(selectedSite))
		}
		onClose && onClose()
	}
	return (<Dialog
		open={open}
		onClose={onClose}
		aria-labelledby="alert-dialog-title"
		className={classes.dialog}
	>
		{
			mode === LOT_INVENTORY_MODE ?
				(
					<DialogTitle id="alert-dialog-title">
						Extraire l'inventaire
					</DialogTitle>
				) : (
					<DialogTitle id="alert-dialog-title">
						Extraire les sorties
					</DialogTitle>
				)
		}
		{
			mode === LOT_INVENTORY_MODE ?
				(
					<DialogContent className={classes.dialogContent}>
						<Typography>
							Sélectionnez le site pour lequel vous souhaitez extraire l'inventaire.
						</Typography>
					</DialogContent>
				) : (
					<DialogContent className={classes.dialogContent}>
						<Typography>
							Sélectionnez le site ainsi que la période d'utilisation en production pour laquelle vous souhaitez extraire les sorties.
						</Typography>

					</DialogContent>
				)
		}
		<>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.filterContainer}>
					<FormControl className={classes.selectSite} variant="standard">
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							Site
						</InputLabel>
						<Select
							variant="standard"
							value={selectedSite}
							className={classes.siteSelector}
							onChange={(e) => { setSelectedSite(e.target.value) }}
						>
							{renderElements(sites)}
						</Select>
					</FormControl>
					{mode === LOT_OUTPUT_MODE && (
						<FormControl className={classes.selectContainer} variant="standard">
							<DatePicker
								disableCloseOnSelect={false}
								showToolbar={false}
								value={startDate}
								name="date"
								variant="inline"
								inputFormat="DD/MM/YYYY"
								required
								onChange={onStartDateChange}
								renderInput={(params) =>
									<TextField
										{...params}
										variant="standard"
										label="Période d'utilisation en production" />}
								inputProps={{
									className: classes.dateInput
								}}
							/>
							<DatePicker
								disableCloseOnSelect={false}
								showToolbar={false}
								value={endDate}
								name="date"
								variant="inline"
								inputFormat="DD/MM/YYYY"
								required
								onChange={onEndDateChange}
								renderInput={(params) => <TextField {...params} variant="standard" />}
								inputProps={{
									className: classes.endDateInput
								}}
							/>
						</FormControl>
					)}
					<div >
						{
							error && <Alert className={classes.error} severity="error">{error}</Alert>
						}
					</div>
				</div>
			</DialogContent>
		</>
		{
			<DialogActions>
				<Button color="primary" variant="text" onClick={onClose}>
					Annuler
				</Button>
				<Button
					onClick={downloadInventoryFile}
					color="primary"
					variant="contained"
					disabled={dayjs(endDate).diff(dayjs(startDate), "day") > 100 || dayjs(endDate).isBefore(startDate) && true}
				>
					Extraire
				</Button>
			</DialogActions>
		}
	</Dialog>)
}

export default ExtractLotModal