import Parse from "parse"

const PackagingExecution = Parse.Object.extend("PackagingExecutions")

import { parseLimitRequest } from "../../utils"
import { sortPEByTimeFields } from "../../utils/productionStepExecution"

import { PE_STATUSES } from "../../utils/packagingExecutions"

export const getFilteredPackagingExecutions = async ({ filters, include = [], select = [], toJson = true }) => {
	try {
		const packagingExecutionQuery = new Parse.Query(PackagingExecution)
			.notEqualTo("deleted", true)

		if (include && include.length) {
			packagingExecutionQuery.include(include)
		}
		if (select && select.length) {
			packagingExecutionQuery.select(select)
		}
		if (filters && filters.packagingDate) {
			packagingExecutionQuery.equalTo("packagingDate", filters.packagingDate)
		}
		if (filters && filters.status && Array.isArray(filters.status)) {
			packagingExecutionQuery.containedIn("status", filters.status)
		}
		const packagingExecutions = await packagingExecutionQuery
			.ascending("theoreticalStartTime")
			.addAscending("startTime")
			.addAscending("endTime")
			.limit(parseLimitRequest)
			.find() || []

		const sortedResults = sortPEByTimeFields(packagingExecutions)

		return toJson ? sortedResults.map(packagingExecution => packagingExecution.toJSON()) : sortedResults

	}
	catch (e) {
		console.error(e.message)
	}
}

export const getPackagingExecutionById = async ({ id, toJson = true, select = [], include = [] }) => {
	try {
		const packagingExecutionQuery = new Parse.Query(PackagingExecution)
			.notEqualTo("deleted", true)
			.equalTo("objectId", id)

		if (include && include.length) {
			packagingExecutionQuery.include(include)
		}
		if (select && select.length) {
			packagingExecutionQuery.select(select)
		}
		const packagingExecution = await packagingExecutionQuery.first() || {}

		return toJson ? packagingExecution.toJSON() : packagingExecution
	}
	catch (e) {
		console.error(e.message)
	}
}

export const savePackagingExecutionNewStatus = async (packagingExecution, newStatus) => {
	try {
		const currentStatus = packagingExecution.get("status")
		if (currentStatus === newStatus) {
			return packagingExecution
		}
		// add other validation if need be
		const forbiddenStatusError = "Changement de status interdit"
		if (currentStatus === PE_STATUSES.toValidate && newStatus !== PE_STATUSES.todo) {
			throw new Error(forbiddenStatusError)
		}
		if (currentStatus === PE_STATUSES.locked && newStatus !== PE_STATUSES.toValidate) {
			throw new Error(forbiddenStatusError)
		}
		if (currentStatus === PE_STATUSES.todo && newStatus !== PE_STATUSES.inProgress) {
			throw new Error(forbiddenStatusError)
		}
		if (currentStatus === PE_STATUSES.waste && newStatus !== PE_STATUSES.done) {
			throw new Error(forbiddenStatusError)
		}
		packagingExecution.set("status", newStatus)
		await packagingExecution.save()
		return packagingExecution
	}
	catch (e) {
		console.error(e.message)
	}
}