import React, {  useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { Box, Stack, styled } from "@mui/material"
import clsx from "clsx"
import CheckIcon from "@mui/icons-material/Check"

const useStyles = makeStyles({
	progressBarAndLabels: {
		width: "100%",
		margin: "0px 8px"
	},
	progressBar: {
		display: "flex",
		justifyContent: "stretch",
		alignItems: "center",
		width: "100%"
	},
	connectorsAndFlagsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flex: 1
	},
	oneStatus: {
		flex: 1
	},
	item: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
		width: "100%"
	},
	flagContainer: {
		margin: "0px 8px",
		composes: "$inlineBlock",
		borderRadius: "50%",
		minWidth: "24px",
		minHeight: "24px",
		padding: "3px 7px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		fontSize: "12px",
		fontWeight: 700,
		lineHeight: "18px",
		color: "#FFF",
		backgroundColor: "#9B9B9B",
	},
	flagContainerCompleted: {
		backgroundColor: "white",
		border: "1px solid #2196F3",
		padding: "3px 3px"
	},
	flagContainerOngoing: {
		backgroundColor: "#2196F3"
	},
	labelContainer: {
		marginTop: 8,
		textAlign: "center"
	},
	label: {
		color: "#9B9B9B",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "22px",
		textAlign: "center"
	},
	labelDone: {
		composes: "$label",
		color: "#2196F3"
	},
	labelOngoing: {
		composes: "$labelDone",
		fontWeight: 500
	},
})

const NumberedProgressBar = ({
	statusAndLabels = [],
	currentStatus = null,
	onClick,
	withSidelines = true,
	isCompleted = false
}) => {

	const classes = useStyles()

	const currentStatusIndex = statusAndLabels.findIndex(s => s.value === currentStatus)

	const _getFlagContent = (index, isDone) => {
		if (isDone) {
			return (
				<CheckIcon htmlColor="#2196F3" sx={{ 
					height: 16,
					width: 16 
				}} />
			)
		}
		return index + 1
	}

	const startEndConnectorFlexBasis = useMemo(() => {
		if (statusAndLabels.length === 2) {
			return "15%"
		}
		return "10%"
	},
		[statusAndLabels])

	const StartEndConnector = styled(Box, {
		shouldForwardProp: (prop) => prop !== "flexBasis" && prop !== "done"
	})(({ flexBasis = "10%", done = false }) => ({
		height: 2,
		backgroundColor: done ? "#2196F3" : "#DBDBDB",
		flexBasis: flexBasis,
		alignSelf: "center",
		// marginTop: -56
	}))

	const MiddleConnector = styled(Box, {
		shouldForwardProp: (prop) => prop !== "done"
	})(({ done = false }) => ({
		backgroundColor: done ? "#2196F3" : "#DBDBDB",
		height: 2,
		flex: 1,
	}))

	return (
		<Stack className={classes.progressBarAndLabels}>
			<Box className={classes.progressBar}>
				<Stack
					className={classes.connectorsAndFlagsContainer}>
					{statusAndLabels.map((status, index) => {
						const isDone = currentStatusIndex > index || isCompleted
						const isOngoing = currentStatusIndex === index
						const isFirst = index === 0
						const isLast = index === (statusAndLabels.length - 1)
						return (
							<div
								key={index}
								className={classes.oneStatus}>
								<Stack
									className={classes.item}
								>
									{isFirst && withSidelines && <StartEndConnector done={isDone || isOngoing} flexBasis={startEndConnectorFlexBasis} />}
									<MiddleConnector done={isDone || isOngoing} />
									<Box
										className={clsx(classes.flagContainer,
											{
												[classes.flagContainerCompleted]: isDone,
												[classes.flagContainerOngoing]: isOngoing,
											})}
										onClick={() => onClick && onClick(status)}>
										{_getFlagContent(index, isDone)}
									</Box>
									<MiddleConnector done={isDone} />
									{isLast && withSidelines && <StartEndConnector done={isDone} flexBasis={startEndConnectorFlexBasis} />}
								</Stack>
								<Box 
								className={classes.labelContainer}
								sx={{
									paddingLeft: withSidelines && isFirst ? startEndConnectorFlexBasis : "unset",
									paddingRight: withSidelines && isLast ? startEndConnectorFlexBasis : "unset"
								}}
								>
									<span className={clsx(
										classes.label,
										{
											[classes.labelDone]: isDone,
											[classes.labelOngoing]: isOngoing
										}
									)}>{status.label}</span>
								</Box>
							</div>
						)
					})}
				</Stack>
			</Box>
		</Stack>
	)
}

export default NumberedProgressBar