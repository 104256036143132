import { getData } from ".."
import { KFC_BRANDS } from "../../utils"
import { RecipeIsActive, RecipeStatus } from "../../utils/recipes"
import dayjs from "dayjs"

const storage = JSON.parse(localStorage.getItem("searchValue"))
const storageRecipeList = JSON.parse(localStorage.getItem("recipeList"))

const defaultState = {
    recipes: [],
    searchedRecipes: [],
    selectedRecipe: null,
    packagings: [],
    sections: [],
    ingredients: [],
    logs: [],
    chefs: [],
    recipeSnackBar: { open: false, type: "", message: "", duration: 0, triggerDate: dayjs() },
    etiquettableSnackBar: { open: false, type: "", message: "", duration: 0, triggerDate: dayjs() },
    productsTags: [],
    productItems: [],
    recipeFilters: {
        rowsPerPage: 10,
        page: 0,
        search: (storage && storage.recipeList) ? storage.recipeList : "",
        brands: (storageRecipeList && storageRecipeList.brands) ? storageRecipeList.brands : ["ALL", ...KFC_BRANDS.map(el => el.name)],
        types: (storageRecipeList && storageRecipeList.types) ? storageRecipeList.types : [],
        status: (storageRecipeList && storageRecipeList.status) ? storageRecipeList.status : ["ALL", ...(RecipeStatus || []).map(el => el.value)],
        isActive: (storageRecipeList && storageRecipeList.isActive) ? storageRecipeList.isActive : ["ALL", ...RecipeIsActive.map(el => el.value)]
    },
    total: 0
}

export default function (state = { ...defaultState }, action) {
    switch (action.type) {
        case "RECIPES_LOADED":
            return {
                ...state,
                recipes: action.recipes
            }
        case "RECIPE_SAVED":
            return {
                ...state,
                selectedRecipe: action.selectedRecipe
            }
        case "RECIPE_SELECTED":
            return {
                ...state,
                selectedRecipe: action.selectedRecipe
            }
        case "SEARCH_RECIPES_LOADED":
            return {
                ...state,
                searchedRecipes: action.recipes,
            }
        case "SEARCH_RECIPES_CLEARED":
            return {
                ...state,
                searchedRecipes: [],
            }
        case "SELECTED_RECIPE_UPDATED":
            return {
                ...state,
                selectedRecipe: action.selectedRecipe
            }
        case "RECIPE_UPDATE_LIST_FILTERS":
            return {
                ...state,
                recipeFilters: {
                    ...state.recipeFilters,
                    ...action.change
                }
            }
        case "RECIPE_FILTERS_TYPES_UPDATED":
            return {
                ...state,
                recipeFilters: {
                    ...state.recipeFilters,
                    types: action.types
                }
            }
        case "RECIPE_PACKAGING_LOADED":
            return {
                ...state,
                packagings: action.packagings
            }
        case "RECIPE_CHEFS_LOADED":
            return {
                ...state,
                chefs: action.chefs
            }
        case "RECIPE_PRODUCTS_TAGS_LOADED":
            return {
                ...state,
                productsTags: action.productsTags
            }
        case "RECIPE_LOGS_LOADED":
            return {
                ...state,
                logs: action.logs
            }
        case "SECTIONS_LOADED":
            return {
                ...state,
                sections: action.sections
            }
        case "EXPORT_SELECTED_RECIPE":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "DUPLICATE_RECIPE":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "UPDATE_RECIPE_SNACKBAR":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "UPDATE_ETIQUETTABLE_SNACKBAR":
            return {
                ...state,
                etiquettableSnackBar: action.etiquettableSnackBar
            }
        case "CLOSE_RECIPE_SNACKBAR":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "CLOSE_ETIQUETTABLE_SNACKBAR":
            return {
                ...state,
                etiquettableSnackBar: action.etiquettableSnackBar
            }
        case "INGREDIENTS_LOADED":
            return {
                ...state,
                ingredients: action.ingredients
            }
        case "EXPORT_RECIPES_ERRORS":
            return {
                ...state,
                exportRecipesErrors: action.exportRecipesErrors,
                exportRecipesMessage: action.exportRecipesMessage
            }
        case "RECIPES_ERROR_LOADED":
            return {
                ...state,
                recipesErrors: action.recipesErrors,
                selectedRecipe: action.selectedRecipe
            }
        case "RECIPE_COMMENT_SAVED":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar,
                selectedRecipe: action.selectedRecipe
            }
        case "RECIPE_COMMENT_SAVED_ERROR":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "SYNCHRONIZE_A_RECIPE_WITH_ETIQUETTABLE":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "SYNCHRONIZE_A_RECIPE_WITH_ETIQUETTABLE_ERROR":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "SYNCHRONIZE_RECIPES_WITH_ETIQUETTABLE":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "DUPLICATE_GENERIC_SECTION_ERROR":
        case "SYNCHRONIZE_RECIPES_WITH_ETIQUETTABLE_ERROR":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "RECIPE_IMAGE_SAVED":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "RECIPE_IMAGE_REMOVE":
            return {
                ...state,
                recipeSnackBar: action.recipeSnackBar
            }
        case "RECIPE_LABEL_MODAL_OPEN":
            return {
                ...state,
                labelModalIsOpen: action.labelModalIsOpen
            }
        case "RECIPE_LABEL_MODAL_CLOSE":
            return {
                ...state,
                labelModalIsOpen: action.labelModalIsOpen
            }
        case "REUSABLE_SECTION_SAVED":
            return {
                ...state,
                sections: action.sections
            }
        case "RECIPE_PRODUCT_ITEMS_CHECKED":
            return {
                ...state,
                productItems: action.productItems
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getRecipe(state, id) {
    if (!id) {
        return null
    }
    return (state.recipes.recipes || []).find(recipe => recipe.id === id || recipe.objectId === id)
}

export function getSelectedRecipe(state, errorIfNotFound = false) {
    return getData(state, "recipes.selectedRecipe", errorIfNotFound && "No Recipe selected")
}

export const getSearchedRecipesSelector = (state, errorIfNotFound = false) => {
    return getData(state, "recipes.searchedRecipes", errorIfNotFound && "No Recipe selected")
}

export const getRecipeSnackBarSelector = (state, errorIfNotFound = false) => {
    return getData(state, "recipes.recipeSnackBar", errorIfNotFound && "No Recipe snackbar found")
}

export const getEtiquettableSnackBarSelector = (state, errorIfNotFound = false) => {
    return getData(state, "recipes.etiquettableSnackBar", errorIfNotFound && "No Etiquettable snackbar found")
}

export const getRecipes = (state, errorIfNotFound = false) => {
    return getData(state, "recipes.recipes", errorIfNotFound && "No Recipe found")
}