import {
		KFC_BRANDS
} from "../../utils"

import { formatReceptionData, handleReceptionEvent } from "../../utils/receptionUtils"

const defaultState = {
		hubs: [],
		receptionData: [],
	originalReceptionData: [],
		brand: KFC_BRANDS[0].name,
	selectedStatus: "TODO",
	nameOrCode: "",
	noDisplay: false,
	receptionSnackBar: { open: false, type: "", message: "", duration: 0 },
	receptionDetailSnackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultState, action) {
	let selectedPosition, newOriginalReceptionData, newReceptionData

		switch (action && action.type) {
				case "RECEPTION_FILTER_LOADED":
						return {
								...state,
								hubs: action.hubs,
								date: action.date
						}
			case "FILTER_BRAND_UPDATED":
				return {
					...state,
					brand: action.brand,
					hubs: action.hubs
				}
				case "RECEPTION_LOADED":
					newOriginalReceptionData = action.originalReceptionData.map(elm => handleReceptionEvent(elm))
					newReceptionData = formatReceptionData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

					return {
								...state,
								hub: state.hubs.filter(elm => elm.objectId === action.hub),
								date: action.date,
								brand: action.brand,
						selectedStatus: state.selectedStatus,
						nameOrCode:  state.nameOrCode,
						update: JSON.stringify(newReceptionData),
								receptionData: newReceptionData,
							originalReceptionData: newOriginalReceptionData
						}

			case "RECEPTION_SELECTED_PRODUCT_STOCK_UPDATED_STATUS":
				selectedPosition = state.originalReceptionData.findIndex(elm => elm.objectId === action.selectedProduct.objectId)
				newOriginalReceptionData = state.originalReceptionData
				
			if (action.selectedProduct.receptionStatus !== "IN_PROGRESS") newOriginalReceptionData[selectedPosition] = handleReceptionEvent(action.selectedProduct)
				newReceptionData = formatReceptionData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))
				
				return {
					...state,
					originalReceptionData: newOriginalReceptionData,
					receptionData: newReceptionData,
					update: JSON.stringify(newReceptionData),
					noDisplay: (action.noDisplay !== undefined)?action.noDisplay:state.noDisplay
				}
			
			case "RECEPTION_SELECTED_PRODUCT_STOCK_UPDATED":
				selectedPosition = state.originalReceptionData.findIndex(elm => elm.objectId === action.selectedProduct.objectId)
				newOriginalReceptionData = state.originalReceptionData

				newOriginalReceptionData[selectedPosition] = handleReceptionEvent(action.selectedProduct)
				newReceptionData = formatReceptionData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

				return {
					...state,
					originalReceptionData: newOriginalReceptionData,
					receptionData: newReceptionData,
					update: JSON.stringify(newReceptionData),
					receptionDetailSnackBar: action.receptionSnackBar
				}
				
			case "RECEPTION_FILTER_STATUS_SET":
				newReceptionData = formatReceptionData(filter(state.originalReceptionData, action.receptionStatus, state.nameOrCode))

				return {
					...state,
					receptionData: newReceptionData,
					selectedStatus: action.receptionStatus,
					update: JSON.stringify(newReceptionData)
				}

			case "RECEPTION_FILTER_NAME_OR_CODE_SET":
				newReceptionData = formatReceptionData(filter(state.originalReceptionData, state.selectedStatus, action.searchFilter))

				return {
					...state,
					receptionData: newReceptionData,
					nameOrCode: action.searchFilter,
					update: JSON.stringify(newReceptionData)
				}

			case "RECEPTION_ADD_PRODUCTS":
				newOriginalReceptionData = state.originalReceptionData
			action.productStocks.forEach(productStock => {
				selectedPosition = state.originalReceptionData.findIndex(elm => elm.objectId === productStock.objectId)
				newOriginalReceptionData[selectedPosition] = handleReceptionEvent(productStock)
			})

				newReceptionData = formatReceptionData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

				return {
					...state,
					originalReceptionData: newOriginalReceptionData,
					receptionData: newReceptionData,
					update: JSON.stringify(newReceptionData),
					receptionDetailSnackBar: action.receptionSnackBar
				}

			case "RECEPTION_OPEN_DETAIL_SNACKBAR":
			case "RECEPTION_CLOSE_DETAIL_SNACKBAR":
				return {
					...state,
					receptionDetailSnackBar: action.receptionSnackBar,
					noDisplay: (action.noDisplay !== undefined)?action.noDisplay:state.noDisplay
				}

				case "RECEPTION_OPEN_SNACKBAR":
			case "RECEPTION_CLOSE_SNACKBAR":
				return {
					...state,
					receptionSnackBar: action.receptionSnackBar
				}
				default:
						return {
								...state
						}
		}
}

function filter(result, status, nameOrCode) {
	let newResult = result.filter(elm => elm.display)

	// filter on status
	if (status !== "ALL") {
		newResult = newResult.filter(elm => elm.receptionStatus === status)
	}

	// filter on name or code
	const regex = new RegExp(nameOrCode, "ig")
	newResult = newResult.filter(elm =>  (elm.commercialTitle.match(regex) !== null || elm.uniqueCode.match(regex) !== null))

	return newResult
}
