import React from "react"
import { useDispatch } from "react-redux"
import { Box, styled } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import HowToRegIcon from "@mui/icons-material/HowToReg"

import { COLORS, KFC_BRANDS } from "../../utils"

import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import { showKo } from "../../actions/Planning/PlanningDashboard"
import { showReceptionGapInterface } from "../../actions/Reception/receptionGap"
import PageHeaderTitle from "../../components/PageHeaderTitle"

const StyledTabsContainer = styled("div")({
    padding: "0 20px",
    borderBottom: `1px solid ${COLORS.DEFAULT_GREY}`,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    backgroundColor: "#fff",
    flexGrow: 1
})

const StyledTab = styled(Tab)({
    paddingLeft: 45,
    paddingRight: 45
})

const SupervisionsTabs = (props) => {
    const {
        tabValue,
        children,
    } = props

    const dispatch = useDispatch()

    const _onTabChange = (event, tabValue) => {
        switch (tabValue) {
            case "receptionGap":
                dispatch(showReceptionGapInterface())
                break
            default: // vue ko
                dispatch(showKo(KFC_BRANDS[0].name))
                break
        }
    }

    return (
        <Box display="flex" flexDirection="column" >
            <Box sx={{ height: 64 }}>
                <GenericTopBarHeader
                    title={<PageHeaderTitle title="Supervision" icon={<HowToRegIcon />} color={COLORS.HEAD_SEAZON} />}
                />
            </Box>
            <StyledTabsContainer className="flexRow justifyCenter">
                <Tabs
                    value={tabValue}
                    onChange={_onTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    <StyledTab label="Dispatch" value="ko" />
                    <StyledTab label="Écarts et casse" value="receptionGap" />
                </Tabs>
            </StyledTabsContainer>
            {children}
        </Box>
    )
}

export default SupervisionsTabs
