import React from "react"
import { StyledContainer } from "./StyledExecutionPreviewComponents"
import { Stack, Typography } from "@mui/material"
import AcUnit from "@mui/icons-material/AcUnit"
import dayjs from "dayjs"

export const sx = {
	label: {
		color: "#555",
		fontSize: 14,
		fontWeight: 400,
		lineHeight: "22px"
	},
	value: {
		color: "#262626",
		fontSize: 16,
		fontWeight: 400,
		lineHeight: "24px"
	}
}

const reformatTime = (machineBatch) => {
	return dayjs(machineBatch.coolingStartTime).format("HH:mm") + " - " + dayjs(machineBatch.coolingEndTime).format("HH:mm")
}

const computeDuration = (machineBatch) => {
	return dayjs(machineBatch.coolingEndTime).diff(dayjs(machineBatch.coolingStartTime), "minute")
}

const reformatTemperature = (machineBatch) => {
	return "" + machineBatch.startTemperature + " °C - " + machineBatch.endTemperature + " °C"

}


const PSECoolingRecap = ({
	visible = true,
	machinesBatch = []
}) => {

	if (!visible) {
		return null
	}

	return (
		<StyledContainer>
			<Stack gap={3}>
				<Stack gap={1} direction="row" alignItems="center">
					<AcUnit sx={{
						width: 16,
						height: 16
					}}
					/>
					<Typography sx={{
						color: "#262626",
						fontSize: 14,
						fontWeight: 500,
						lineHeight: "22px"
					}}>
						Refroidissement
					</Typography>
				</Stack>
				<Stack gap={3} direction="row">
					<Stack gap={2}>
						<Typography sx={sx.label}>Machine</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{machineBatch.machineName}</Typography>)}
					</Stack>
					<Stack gap={2}>
						<Typography sx={sx.label}>Heure</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{reformatTime(machineBatch)}</Typography>)}
					</Stack>
					<Stack gap={2}>
						<Typography sx={sx.label}>Durée</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{computeDuration(machineBatch) + " min"}</Typography>)}
					</Stack>
					<Stack gap={2}>
						<Typography sx={sx.label}>Température</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{reformatTemperature(machineBatch)}</Typography>)}
					</Stack>
				</Stack>
			</Stack>
		</StyledContainer>
	)

}

export default PSECoolingRecap
