import React from "react"
import { Box, Typography, styled } from "@mui/material"

import { PRODUCTION_SCHEMA_BORDER_COLOR } from "../../../../utils/productionSchemasUtils"
import BrandIcon from "../../../../icons/BrandIcon"
import { cellStyle } from "./ProductionSchemaStyledComponents"
import { ACTIVE_KFC_BRANDS, COLORS } from "../../../../utils"

const border = "1px solid " + PRODUCTION_SCHEMA_BORDER_COLOR

const StyledFormRowLabel = styled("div")({
  backgroundColor: COLORS.HEADER_BLUE,
  borderTop: border,
  borderBottom: border
})

const StyledBox = styled(Box)(({ theme, flexBasis }) => ({
  flexBasis: flexBasis || "unset",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const ProductionSchemasFormHeader = ({ isEdition = true, cellWidth, brands = ACTIVE_KFC_BRANDS.map(ac => ac.name) }) => {

  return (
    <StyledFormRowLabel className="flexRow stretchSelf">
      {/* empty first row to align with plus button */}
      {isEdition && <Box sx={{ width: 46 }} />}
      {/* 2nd row */}
      <StyledBox sx={{ ...cellStyle }}
        className="flexRow center stretchSelf flex1">
        <Typography variant={"subtitle2"}>Jour de production</Typography>
      </StyledBox>
      {/* 3nd row */}
      <StyledBox
        sx={{ ...cellStyle, borderLeft: border }}
        className="flexRow center stretchSelf flex1"
      >
        <Typography variant={"subtitle2"}>Jour de barquettage</Typography>
      </StyledBox>
      {/* last row divide to 3 rows (brands) */}
      <StyledBox className="flexCenter" flexBasis={`${cellWidth * brands.length}px`} >
        {/* top: label */}
        <Box
          sx={{ ...cellStyle, height: 48, borderLeft: border, borderBottom: border }}
          className="flexCenter stretchSelf"
        >
          <Typography variant={"subtitle2"}>Jour de vente</Typography>
        </Box>
        {/* bottom: fields */}
        <Box sx={{ height: 52 }} className="flexCenter stretchSelf">
          <div className="center flex1 flexRow justifyCenter stretchSelf">
            {brands.map((brand, index) => (
              <Box
                sx={{ ...cellStyle, borderLeft: border }}
                className="flex1 flexRow justifyCenter center stretchSelf"
                key={brand + index}
              >
                <BrandIcon name={brand} width={24} height={24} />
              </Box>
            ))}
          </div>
        </Box>
      </StyledBox>
      {/* empty right col to align with trash button */}
      {isEdition && (
        <Box sx={{ width: 46 }} className="flexCenter stretchSelf">
          {/* mock the top */}
          <div className="flex1" />
          <Box sx={{ height: 52, borderTop: border }} className="stretchSelf" />
        </Box>
      )}
    </StyledFormRowLabel>
  )
}

export default ProductionSchemasFormHeader
