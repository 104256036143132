import React from "react"
import { Card, CardContent, Typography, Stack } from "@mui/material"
import CloudinaryImage from "../../../components/Image/CloudinaryImage"

export const sx = {
	card: {
		flex: 1,
		padding: "32px 48px",
		border: "1px solid #E6E6E6",
		background: "#FFF",
		boxShadow: "none",
		borderRadius: "6px",
		height: "100%",
	},
	image: {
		width: "100%",
		height: "auto",
		marginBottom: "20px",
	},
	label: {
		color: "#555"
	},
	item: {
		fontWeight: 400
	}
}

const PackagingExecutionPresentation = ({ packaging, openImagePreview }) => {

	return (
		<Card sx={sx.card}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={2}>
						<Typography variant="body" sx={sx.label}>
							Photo barquettage
						</Typography>
						<div style={{
							cursor: "pointer"
						}}
							onClick={openImagePreview}>
							<CloudinaryImage
								imageId={packaging.packagingImage}
								crop="fill"
								width={183}
								height={118}
							/>
						</div>
					</Stack>
					<Stack spacing={2}>
						<Typography variant="body" sx={sx.label}>
							Instructions
						</Typography>
						<Typography variant="h6" sx={sx.item}>
							{packaging?.instructions}
						</Typography>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	)
}

export default PackagingExecutionPresentation