import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import {
	allergenSchema,
} from "../../utils/yupValidators"
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ModalForm from "../form/ModalForm"
import ModalActions from "../ModalActions"
import { COLORS } from "../../utils"
import { getAllergensIngredients } from "../../parseManager/ingredients/classifications/allergen/parseAllergenManager"

const useStyles = makeStyles({
	formControl: {
		marginLeft: "unset",
		width: "100%",
		marginTop: 20,
		"& *": {
			fontSize: 16,
			color: COLORS.BLACK,
		},
		"& .MuiFormLabel-root": {
			position: "relative",
			height: 20,
			display: "flex",
			alignItems: "center",
			transform: "none",
			marginTop: 4,
		},
	},
	selectFormControl: {
		composes: "$formControl",
		marginTop: 16,
	},
	formControlDisabled: {
		opacity: 0.4,
		pointerEvents: "none",
	},
	errorField: {
		color: COLORS.RED,
		marginTop: 7,
	},
	textField: {
		marginTop: 7,
	},
})

const IngredientAllergensForm = (props) => {
	const {
		onClose,
		open,
		ingredient,
		onSave
	} = props

	const [allergens, setAllergens] = useState([])

	useEffect(() => {
		const fetchAllergens = async () => {
			const allergen = await getAllergensIngredients()
			setAllergens(allergen)
		}
		fetchAllergens()
	}, [])

	const submitForm = async (values) => {
		const newValues = { ...values }
		onSave("ALLERGENS", newValues)
		onClose()
	}

	const classes = useStyles()

	return (
		<ModalForm
			onClose={onClose}
			open={open}
			title="Modifier les allergènes de l'ingrédient"
			width={500}
		>
			<Formik
				initialValues={{
					allergens: allergens.filter(allergen => {
						return Array.isArray(ingredient?.allergens) && ingredient?.allergens.length > 0 ? ingredient.allergens.some(selected => selected.objectId === allergen.objectId) : false
					})
				}}
				validationSchema={allergenSchema}
				onSubmit={submitForm}
			>
				{({ values, handleChange, handleSubmit }) => {
					return (
						<Form>
							<FormControl className={classes.selectFormControl} variant="standard">
								<InputLabel id="allergens-label">Allergènes</InputLabel>
								<Select
									variant="standard"
									labelId="allergens-label"
									id="allergens"
									name="allergens"
									multiple
									value={values.allergens}
									onChange={handleChange}
									renderValue={(selected) =>
										selected.map((item) => item.name).join(", ")
									}
								>
									{allergens.map((allergen) => (
										<MenuItem key={allergen.objectId} value={allergen}>
											<Checkbox
												checked={values.allergens.some(
													(selected) =>
														selected.objectId === allergen.objectId
												)}
											/>
											<ListItemText primary={allergen.name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<ModalActions
								onSave={handleSubmit}
								onCancel={onClose}
							/>
						</Form>
					)
				}}
			</Formik>
		</ModalForm>

	)
}

export default IngredientAllergensForm