import React, { Fragment, useEffect, useRef, useState } from "react"
import { Formik, Form } from "formik"
import {
	Button,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogActions,
	TextField,
	styled,
	FormControl,
	InputLabel,
	FormHelperText,
	Box,
	Typography,
	InputAdornment,
} from "@mui/material"
import Select, { components } from "react-select"

import { getTransformationTypeLabel, isTransformationTypePreparation } from "../../../../utils/supplierItemUtils"
import { getSupplierItemTransformationModeSchema } from "../../../../utils/yupValidators"

const findTransformationMode = (transformationModes, selectedTransformationMode) => {
	if (!selectedTransformationMode) {
		return null
	}
	return transformationModes.find(mode => mode.objectId === selectedTransformationMode.value)
}

const getTransformationMode = (transformationModes, value) => {
	const id = typeof value === "string" ? value : value.objectId
	return transformationModes.find(transformationMode => transformationMode.objectId === id)
}
// form initial values
const getInitialValues = (data) => {
	// creation
	if (!data) {
		return {
			transformationMode: null
		}
	}
	// edition
	const defaultValues = {
		transformationMode: { // initial values should be option format
			label: data.transformationMode.name,
			value: data.transformationMode.objectId
		},
		transformationRate: data.transformationRate
	}

	if (isTransformationTypePreparation(data.transformationMode)) {
		defaultValues.machineType = data.machineType?.objectId
	}

	return defaultValues
}



const StyledDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		width: 500,
		padding: 10
	},
})

// add zindex to the menus so it appears before the dialog
const reactSelectStyles = {
	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({ ...provided, zIndex: 9999 }),
	valueContainer: (provided) => ({
		...provided,
		overflow: "visible"
	}),
}

const controlSx = {
	pointerEvents: "none",
	position: "absolute",
	transition: "0.2s ease all",
	MozTransition: "0.2s ease all",
	WebkitTransition: "0.2s ease all",
	zIndex: 9000
}
const TransformationModeInput = (props) => {
	const isFloating = props.isFocused || props.hasValue
	return (
		<>
			<InputLabel
				sx={{
					...controlSx,
					top: isFloating ? -46 : -13,
					left: isFloating ? 0 : 10,
				}}
			>
				Mode de transformation
			</InputLabel>
			<components.Control {...props} />
		</>
	)
}

export const SupplierItemTransformationModeFormModal = ({
	onClose, open, onConfirm, isEdition, transformationModes,
	supplierItemTransformationModes, transformationMode,
}) => {
	const [selectedTransformationMode, setSelectedTransformationMode] = useState(null)

	// initial values for edition
	useEffect(() => {
		if (!transformationMode) return
		setSelectedTransformationMode(transformationMode.transformationMode)
	}, [transformationMode])

	const formikRef = useRef(null)

	const handleConfirm = () => {
		formikRef.current.submitForm()
	}

	const handleSubmit = async (values) => {
		if (typeof values.transformationMode === "object") {
			const selectedTransformationModeObject = findTransformationMode(transformationModes, values.transformationMode)
			const newValues = {
				...values,
				transformationMode: selectedTransformationModeObject ? selectedTransformationModeObject : null
			}
			await onConfirm(newValues)
			onClose()
			setSelectedTransformationMode(null) // clear selected data
		} else {
			const selectedTransformationModeObject = getTransformationMode(transformationModes, values.transformationMode)
			const newValues = {
				...values,
				transformationMode: selectedTransformationModeObject ? selectedTransformationModeObject : null
			}
			await onConfirm(newValues)
			onClose()
			setSelectedTransformationMode(null) // clear selected data
		}
	}

	const handleCancel = () => {
		onClose()
		setSelectedTransformationMode(null) // clear selected data
	}

	const transformationModeOptions = transformationModes.map(transformationMode => ({
		label: transformationMode.name,
		value: transformationMode.objectId,
	}))

	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
				{isEdition ? "Éditer un mode de transformation à l'article" : "Ajouter un mode de transformation à l'article"}
			</DialogTitle>
			<DialogContent sx={{ mt: 0.6 }}>
				<Formik
					innerRef={formikRef}
					onSubmit={handleSubmit}
					validationSchema={getSupplierItemTransformationModeSchema()}
					initialValues={getInitialValues(transformationMode)}
				>
					{({ values, handleChange, handleBlur, errors, setFieldError, setFieldValue }) => {
						return (
							<Form>
								<FormControl variant="standard" disabled={!!transformationMode} fullWidth error={!!errors.transformationMode} sx={{ pt: 4 }}>
									<Select
										isClearable
										isSearchable
										disabled
										isDisabled={!!transformationMode}
										name={values.transformationMode}
										defaultValue={values.transformationMode}
										options={transformationModeOptions}
										placeholder="" // empty the default placeholder
										menuPortalTarget={document.body} // as zIndex 
										styles={reactSelectStyles}
										components={{ Control: TransformationModeInput }}
										onChange={(option) => {
											const { value } = option
											const supplierItemTransformationModesIds = supplierItemTransformationModes.map(transformationMode => transformationMode.transformationMode.objectId)

											if (supplierItemTransformationModesIds.includes(value)) {
												setFieldError("transformationMode", "Ce mode de transformation a déjà été ajouté")
												setSelectedTransformationMode(null)
												return
											}

											const transformationMode = getTransformationMode(transformationModes, value)
											setSelectedTransformationMode(transformationMode)
											setFieldValue("transformationMode", value)
										}}
									/>
									{errors.transformationMode && <FormHelperText>{errors.transformationMode}</FormHelperText>}
								</FormControl>
								{selectedTransformationMode && (
									<Fragment>
										<Box sx={{ mt: 2, opacity: 0.7 }}>
											<InputLabel>Type de transformation</InputLabel>
											<Typography sx={{ mt: 0.4 }}>{getTransformationTypeLabel(selectedTransformationMode.transformationType)}</Typography>
										</Box>

										<TextField
											variant="standard"
											label="Rendement (%)"
											name="transformationRate"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.transformationRate}
											error={!!errors.transformationRate}
											helperText={errors.transformationRate}
											fullWidth
											type="number"
											sx={{ mt: 1 }}
											InputProps={{
												endAdornment: <InputAdornment position="end">%</InputAdornment>
											}}
										/>
									</Fragment>
								)}
							</Form>
						)
					}}
				</Formik>
			</DialogContent>
			<DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
				<Button onClick={handleCancel} color="primary">
					Annuler
				</Button>
				<Button onClick={handleConfirm} color="primary" variant="contained">
					Enregistrer
				</Button>
			</DialogActions>
		</StyledDialog>
	)
}

export default SupplierItemTransformationModeFormModal
