import React from "react"
import ProductionTab from "../ProductionTabs"
import ProductionSchemas from "./ProductionSchemas"

const ProductionSchemasTab = ({ children }) => {
  if (children !== null) { // form edition
    return children
  }

  return (
      <ProductionTab tabValue="productionSchemas">
          <ProductionSchemas/>
      </ProductionTab>
  )
}

export default ProductionSchemasTab