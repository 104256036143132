import { KFC_BRANDS } from "../utils"
import { sectors } from "../utils/planning"
import { getOrderedProductTypes } from "./productTypesUtils"

export const DistributionCenterTypes = [
    {
        value: "BIG_RETAIL",
        label: "Gros linéaire"
    },
    {
        value: "SMALL_RETAIL",
        label: "Petit linéaire"
    },
    {
        value: "ONLINE",
        label: "Vente en ligne"
    },
    {
        value: "COLLECT",
        label: "Point de retrait"
    },
    {
        value: "CLICK_AND_COLLECT",
        label: "Point de vente"
    },
    {
        value: "CUSTOM",
        label: "Sur mesure"
    }
]

// TODO: remove this later, not used anymore, it's now from database
// see functions bellow
export const PRODUCT_TYPE_CAPACITIES = [
    {
        label: "Plats",
        field: "MAIN_COURSE"
    },
    {
        label: "Entrée",
        field: "STARTER"
    },
    {
        label: "Deserts",
        field: "DESSERT"
    },
    {
        label: "Fromages",
        field: "CHEESE"
    },
    {
        label: "Snack",
        field: "SNACK"
    },
    {
        label: "Boisson",
        field: "DRINK",
    }
]

export const getDistributionCenterProductTypes = (productTypeOptions) => {
    const filteredProductTypes = productTypeOptions.filter(option => !["ALL", "MAIN_COURSE"].includes(option.value))
    const orderedProductTypes = getOrderedProductTypes(filteredProductTypes)

    return orderedProductTypes
}

export const getDistributionCenterProductTypeCapacities = (productTypeOptions = []) => {
    const filteredProductTypeOptions = productTypeOptions.filter(option => ["DRINK", "SNACK", "DESSERT", "CHEESE", "STARTER", "MAIN_COURSE"].includes(option.value))
    const productTypeCapacities = filteredProductTypeOptions.map(productType => ({
        label: productType.label,
        field: productType.value,
    }))

    return productTypeCapacities
}

export const getInitialCapacities = (productTypeOptions = []) => {
    const obj = {}

    const filteredProductTypeOptions = getDistributionCenterProductTypeCapacities(productTypeOptions)

    for (const productType of filteredProductTypeOptions) {
        obj[productType.field] = null
    }

    return obj
}

export const distributionCenterFormDefaultValues = ( dispatchSites, productTypeOptions) => {
    return {
        name: null,
        brand: KFC_BRANDS[0].name,
        sector: sectors.filter(elem => elem.enableValue === true)[0].value,
        type: DistributionCenterTypes[0].value,
        isActive: true,
        national: false,
        onlyPreorders: false,
        capacities: getInitialCapacities(),
        attachRates: getInitialAttachRates(productTypeOptions),
        address: getInitialAddress(),
        counterMark:  null,
        supplierCode:  null,
        internalMail:  null,
        externalMail: null,
        openDate: null,
        closeDates: [],
        externalId: null,
        packagingTypes:  [],
        dispatchSites: dispatchSites
    }
}

export const fridgeFillDays = [
    {
        label: "Lundi",
        name: "monday"
    },
    {
        label: "Mardi",
        name: "tuesday"
    },
    {
        label: "Mercredi",
        name: "wednesday"
    },
    {
        label: "Jeudi",
        name: "Thurday"
    },
    {
        label: "Vendredi",
        name: "friday"
    },
    {
        label: "Samedi",
        name: "Saturday"
    },
    {
        label: "Dimanche",
        name: "Sunday"
    }
]

export const getInitialAttachRates = (productTypeOptions = []) => {
    const obj = {}
    const productTypes = getDistributionCenterProductTypes(productTypeOptions)

    for (const elem of productTypes) {
        obj[elem.value] = null
    }

    return obj
}

export const getInitialAddress = () => {
    return {
        address: null,
        complement: null,
        zipCode: null,
        city: null,
        latitude: null,
        longitude: null
    }
}

export const formatDispatchSitesForm = (dispatchSites) => {
    let dispatchSitesFormatted = []
    dispatchSites.map((dispatchSite => {
        dispatchSitesFormatted.push({
            objectId: dispatchSite.site.objectId,
            name: dispatchSite.site.name,
            priority: dispatchSite.priority
        })
    }))

    return dispatchSitesFormatted
}

export const formatDistributionCenterWithDispatchSites = (distributionCenter) => {
    if (distributionCenter) {
        if (distributionCenter.dispatchSites) {
            distributionCenter.dispatchSites.sort(function (a, b) {
                return a.priority < b.priority ? -1 : 1
            })

            distributionCenter.dispatchSites = formatDispatchSitesForm(distributionCenter.dispatchSites)
        }
    }
    return distributionCenter
}
