import React from "react"
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../utils"

const sx = {
    title: {
        fontSize: 14,
        fontWeight: 400,
        color: COLORS.BLACK800,
        lineHeight: 1.57
    }
}

const OrderCardTitle = ({ icon, title }) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="start">
            {icon}
            <Typography variant="h7" sx={sx.title}>
                {title}
            </Typography>
        </Stack>
    )
}

export default OrderCardTitle