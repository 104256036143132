import PropTypes from "prop-types"
import React, { useState } from "react"
import MealPlannerCartItem from "./MealPlannerCartItem"
import withStyles from "@mui/styles/withStyles"
import List from "@mui/material/List"
import Checkbox from "@mui/material/Checkbox"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Icon } from "@mui/material"
import { EiffelTowerIcon, EiffelTowerColors } from "../../icons/EiffelTowerIcon"
import PanoramaVerticalIcon from "@mui/icons-material/PanoramaVertical"
import { LunchbagIcon, LunchbagColors } from "../../icons/LunchbagIcon"
import { COLORS, getBrandBy } from "../../utils"
import CapitalShippingIcon from "../../icons/CapitalShippingIcon"

const sectionTitleStyle = {
    boxSizing: "border-box",
    width: "100%",
    padding: 0,
    "&$sectionTitleExpanded": {
        minHeight: 0,
        padding: 0
    }
}

const styles = {
    section: {
        boxSizing: "border-box",
        width: "100%",
        padding: 0,
        margin: 0
    },
    sectionTitle: {
        ...sectionTitleStyle,
        height: 40
    },
    weeklyMpSectionTitle: {
        ...sectionTitleStyle,
        height: 56,
    },
    sectionTitleExpanded: {},
    sectionTitleWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    sectionTitleElement: {
        flex: "0 0 auto",
        color: COLORS.WHITE,
        fontWeight: 600,
        textTransform: "Capitalize"
    },
    sectionTitleCheckbox: {
        color: COLORS.WHITE,
        fontSize: 12,
        "&$sectionTitleCheckboxChecked": {
            color: COLORS.WHITE
        },
        marginRight: "10px"

    },
    sectionTitleCheckboxChecked: {},
    sectionDetails: {
        boxSizing: "border-box",
        width: "100%",
        padding: 0,
        margin: 0
    },
    sectionList: {
        boxSizing: "border-box",
        padding: 0,
        width: "100%"
    },
    actions: {
        color: COLORS.WHITE,
        marginLeft: "auto",
        padding: 0
    },
    actionButton: {
        padding: "2px",
        color: COLORS.WHITE
    },
    expanded: {
        "&$expanded": {
            margin: "0px 0"
        }
    }
}

/**
 * useCheckbox custom React hook
 * Allow to check / uncheck state sectionItems and apply an action to all selected sectionItems
 * @param  {object[]} sectionItems The initial item array
 * @return {array}       An array containing the checked item list, a toggle and toggleAll function and an applyAction function creator
 */
export const useCheckbox = (sectionItems) => {
    const [checked, setChecked] = useState([])

    const toggle = value => () => {
        const newChecked = [...checked]
        const currentIndex = checked.indexOf(value)

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const toggleAll = () => {
        const newChecked = sectionItems.length === checked.length ? [] : sectionItems.map(({ itemId }) => itemId)

        setChecked(newChecked)
    }

    const reset = () => {
        setChecked([])
    }

    return [checked, toggle, toggleAll, reset]
}


export const MealPlannerCartSection = props => {
    const {
        sectionItems = [],
        items = [],
        classes = {},
        defaultExpanded = true,
        title = "Section",
        targetBrand = "",
        isWeekly = false,
        updateItems,
        handleDelete
    } = props

    const [checked, toggleCheck, checkAll, reset] = useCheckbox(sectionItems)

    const handleRemoveSectionItems = () => {
        const newItems = items.filter(({ itemId }) => !checked.includes(itemId))
        reset()
        updateItems(newItems)
    }

    const handleNationalSend = () => {
        const completeSelectedItems = []
        checked.forEach(item => {
            completeSelectedItems.push(items.find(({ itemId }) => item === itemId))
        })

        const countNoNationalSend = completeSelectedItems.reduce((sum, item) => sum + ((!item.nationalSend) ? 1 : 0), 0)

        const newItems = items.map(item => {
            if (completeSelectedItems.findIndex(selectedItem => selectedItem.itemId === item.itemId) !== -1) {
                item.nationalSend = countNoNationalSend === completeSelectedItems.length

                // if not nationalSend should be sendCapital
                if (!item.nationalSend) item.sendCapital = true
            }
            return item
        })

        reset()
        updateItems([...newItems])
    }

    const handleSendCapital = () => {
        const completeSelectedItems = []
        checked.forEach(item => {
            completeSelectedItems.push(items.find(({ itemId }) => item === itemId))
        })

        const countNoSendCapital = completeSelectedItems.reduce((sum, item) => sum + ((!item.sendCapital) ? 1 : 0), 0)

        const newItems = items.map(item => {
            if (completeSelectedItems.findIndex(selectedItem => selectedItem.itemId === item.itemId) !== -1) {
                item.sendCapital = countNoSendCapital === completeSelectedItems.length

                // if not sendCapital should be nationalSend
                if (!item.sendCapital) item.nationalSend = true
            }
            return item
        })

        reset()
        updateItems([...newItems])
    }

    const handleLunchbag = () => {
        const completeSelectedItems = []
        checked.forEach(item => {
            completeSelectedItems.push(items.find(({ itemId }) => item === itemId))
        })

        const countLunchbag = completeSelectedItems.reduce((sum, item) => sum + ((item.lunchbag) ? 1 : 0), 0)

        const newItems = items.map(item => {
            if (completeSelectedItems.findIndex(selectedItem => selectedItem.itemId === item.itemId) !== -1) {
                item.lunchbag = !(countLunchbag === completeSelectedItems.length)
            }
            return item
        })

        reset()
        updateItems([...newItems])
    }

    const handleSmallRetail = () => {
        const completeSelectedItems = []
        checked.forEach(item => {
            completeSelectedItems.push(items.find(({ itemId }) => item === itemId))
        })

        const countNoSmallRetail = completeSelectedItems.reduce((sum, item) => sum + ((!item.smallRetail) ? 1 : 0), 0)

        const newItems = items.map(item => {
            if (completeSelectedItems.findIndex(selectedItem => selectedItem.itemId === item.itemId) !== -1) {
                item.smallRetail = countNoSmallRetail === completeSelectedItems.length
            }
            return item
        })

        reset()
        updateItems([...newItems])
    }

    const allChecked = sectionItems.length === checked.length
    const completeBrand = getBrandBy("name", targetBrand) || {}

    return (
        <Accordion
            classes={{ root: classes.section, expanded: classes.expanded }}
            defaultExpanded={defaultExpanded}
            square
            elevation={0}
            disabled={!sectionItems || sectionItems.length === 0}>

            <AccordionSummary
                classes={{ root: isWeekly ? classes.weeklyMpSectionTitle : classes.sectionTitle, expanded: classes.sectionTitleExpanded }}
                style={{ backgroundColor: isWeekly ? COLORS.DOWNLOAD_DOCUMENT_BLUE : completeBrand.color }}
                expandIcon={<ExpandMoreIcon className={classes.sectionTitleElement} />}>
                <div className={classes.sectionTitleWrapper}>
                    <Checkbox
                        classes={{
                            root: classes.sectionTitleCheckbox,
                            checked: classes.sectionTitleCheckboxChecked
                        }}
                        edge="end"
                        onChange={checkAll}
                        onClick={e => e.stopPropagation()}
                        checked={allChecked}
                        color="secondary"
                    />
                    <Typography className={classes.sectionTitleElement} variant="button">
                        {title} ({sectionItems.length})
                    </Typography>
                    {
                        checked.length > 0
                            ? (
                                <div className={classes.actions}>
                                    {
                                        completeBrand.sendCapitalField &&
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                handleSendCapital()
                                            }}
                                            title="Envoyer / Ne pas envoyer en capital"
                                            aria-label="Envoyer / Ne pas envoyer en capital"
                                            className={classes.actionButton}
                                            size="large">
                                            <Icon>
                                                <CapitalShippingIcon color={COLORS.WHITE} />
                                            </Icon>
                                        </IconButton>
                                    }
                                    {
                                        completeBrand.nationalSendField &&
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                handleNationalSend()
                                            }}
                                            title="Envoyer / Ne pas envoyer en province"
                                            aria-label="Envoyer / Ne pas envoyer en province"
                                            className={classes.actionButton}
                                            size="large">
                                            <Icon>
                                                <EiffelTowerIcon color={EiffelTowerColors.WHITE} />
                                            </Icon>
                                        </IconButton>
                                    }
                                    {
                                        completeBrand.smallRetailField &&
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                handleSmallRetail()
                                            }}
                                            title={"Envoyer / Ne pas envoyer en petit points de vente"}
                                            aria-label={"Envoyer / Ne pas envoyer en petit points de vente"}
                                            className={classes.actionButton}
                                            size="large">
                                            <PanoramaVerticalIcon />
                                        </IconButton>
                                    }
                                    {completeBrand.lunchbagField &&
                                        <IconButton
                                            onClick={e => {
                                                e.stopPropagation()
                                                handleLunchbag()
                                            }}
                                            title="Ajouter / Ne pas ajouter au lunchbag"
                                            aria-label="Ajouter / Ne pas ajouter au lunchbag"
                                            className={classes.actionButton}
                                            size="large">
                                            <Icon>
                                                <LunchbagIcon color={LunchbagColors.WHITE} />
                                            </Icon>
                                        </IconButton>
                                    }
                                    <IconButton
                                        onClick={e => {
                                            e.stopPropagation()
                                            handleDelete ? handleDelete(checked) : handleRemoveSectionItems()
                                        }}
                                        title="Supprimer la sélection"
                                        aria-label="Supprimer la sélection"
                                        className={classes.actionButton}
                                        size="large">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            )
                            : null
                    }
                </div>
            </AccordionSummary>

            <AccordionDetails className={classes.sectionDetails}>
                <List dense className={classes.sectionList}>
                    {
                        sectionItems.map(item => (
                            <MealPlannerCartItem
                                key={item.itemId}
                                item={item}
                                checked={checked.indexOf(item.itemId) !== -1}
                                onChange={toggleCheck(item.itemId)}
                                isWeekly={isWeekly} />
                        ))
                    }
                </List>
            </AccordionDetails>

        </Accordion>
    )
}

MealPlannerCartSection.propTypes = {
    sectionItems: PropTypes.arrayOf(
        PropTypes.shape({
            itemId: PropTypes.string
        })
    ),
    classes: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string,
    targetBrand: PropTypes.string,
    handleDelete: PropTypes.func,
    handleNationalSend: PropTypes.func,
    handleSendCapital: PropTypes.func,
    handleLunchbag: PropTypes.func,
    isWeekly: PropTypes.bool
}

export default withStyles(styles)(MealPlannerCartSection)
