import Parse from "parse"

export const CreditNote = Parse.Object.extend("CreditNotes")
const Lots = Parse.Object.extend("Lots")

export async function createCreditNote(values, type, parseElement) {
    try {
        const creditNote = new CreditNote()

        creditNote.set("type", type)
        creditNote.set("reason", values.reason)
        creditNote.set("lot", values.lot)
        creditNote.set("quantity", values.quantity)
        creditNote.set("comment", values.comment)
        creditNote.set("images", values.images)
        creditNote.set("amount", values.amount)

        if (parseElement && parseElement.className === "Lots") {
            creditNote.set("lot", parseElement)
        }

        if (parseElement && parseElement.className === "OrderSupplierItem") {
            creditNote.set("supplierItem", parseElement)
        }

        return await creditNote.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getCreditNotesForLots(ids,toJson=true) {
    try {
        const Pointers = ids.map(el => Lots.createWithoutData(el))
        
        const creditNotes = (await new Parse.Query(CreditNote)
            .containedIn("lot", Pointers)
            .find()) || []

        return toJson ? creditNotes.map(el => el.toJSON()) : creditNotes
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getCreditNotesForSupplierItem(ids, toJson=true) {
    try {
        const creditNotes = (await new Parse.Query(CreditNote)
            .containedIn("objectId", ids)
            .find()) || []

        return toJson ? creditNotes.map(el => el.toJSON()) : creditNotes
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function deleteCreditNote(creditNoteId, toJson = true) {
	try {
		const creditNote = await new Parse.Query(CreditNote)
			.equalTo("objectId", creditNoteId)
			.first()

		const deletedCreditNote = await creditNote.destroy()
		
		return toJson ? deletedCreditNote.toJSON() : deletedCreditNote
	}
	catch (e) {
		return Promise.reject(e)
	}
}