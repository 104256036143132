import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import IngredientSourcingForm from "./IngredientSourcingForm"
import {
	conservationModes,
	origins,
	labels
} from "../../utils/commercialNamesUtils"


const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		borderRadius: 5,
		paddingBottom: 40,
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
	},
	titleContainer: {
		display: "flex",
		flex: "1 1",
		justifyContent: "space-between",
		alignItems: "center",
	},
	title: {
		padding: "20px 32px 16px 32px",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: 16,
	},
	singleValueContainer: {
		display: "flex",
		paddingLeft: 32,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 16,
	},
	valueLabel: {
		color: COLORS.BLUEGREY_700,
		width: 200,
		minWidth: 200,
		display: "flex",
		alignItems: "center",
	},
}))

const IngredientSourcing = (props) => {
	const { ingredient, onSave } = props
	const classes = useStyles()

	const defaultLabel = { label: "-" }
	const firstOriginLabel = ingredient
		? (origins.find(elem => elem.value === ingredient.firstOrigin) || defaultLabel)
		: defaultLabel
	const secondOriginLabel = ingredient
		? (origins.find(elem => elem.value === ingredient.secondOrigin) || defaultLabel)
		: defaultLabel
	const conservativeMode = ingredient
		? conservationModes.find(conservationMode => conservationMode.value === ingredient.conservationMode) || defaultLabel
		: defaultLabel
	const label = ingredient
		? labels.find(label => label.value === ingredient.label) || defaultLabel
		: defaultLabel
	const firstOriginAverage = ingredient ? ingredient.firstOriginAverage : "-"
	const secondOriginAverage = ingredient ? ingredient.secondOriginAverage : "-"

	const [openEditModal, setOpenEditModal] = useState(false)

	const handleCloseModal = () => {
		setOpenEditModal(false)
	}

	return (
		<div className={classes.container}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>Sourcing</div>
				<div>
					<NewEditIcon onClick={() => setOpenEditModal(true)} />
				</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Label</div>
				<div>{label.label}</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Mode de conservation</div>
				<div>{conservativeMode.label}</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Provenance 1</div>
				<div>{firstOriginLabel.label}</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Moyenne provenance 1 %</div>
				<div>{firstOriginAverage}</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Provenance 2</div>
				<div>{secondOriginLabel.label}</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Moyenne provenance 2 % </div>
				<div>{secondOriginAverage}</div>
			</div>
			<IngredientSourcingForm
				open={openEditModal}
				onClose={handleCloseModal}
				ingredient={ingredient}
				onSave={onSave}
			/>
		</div>
	)
}

export default IngredientSourcing