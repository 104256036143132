import React from "react"
import { Stack } from "@mui/material"
import { Table, TableBody, TableRow, TableCell } from "@mui/material"
import { StyledPaper, StyledPaperTitle } from "../../../../components/StyledSingleParseObjectComponents"
import DistributionCenterDispatchSitesTableHead from "./DistributionCenterDispatchSitesTableHead"
import GenericEditIcon from "../../../../components/GenericEditIcon"

const DistributionCenterDispatchSites = ({ dispatchSites = [], onEdit }) => {

	return (
		<StyledPaper>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center">
				<StyledPaperTitle>Sites de dispatch</StyledPaperTitle>
				<GenericEditIcon onEdit={onEdit} />
			</Stack>
			<Table>
				<DistributionCenterDispatchSitesTableHead />
				<TableBody>
					{
						dispatchSites.map((dispatchSite, index) => {
							return (
								<TableRow key={index}>
									<TableCell />
									<TableCell sx={{ textAlign: "center" }}>{dispatchSite.name}</TableCell>
									<TableCell sx={{ textAlign: "center" }}>{dispatchSite.priority}</TableCell>
								</TableRow>
							)
						})
					}
				</TableBody>
			</Table>
		</StyledPaper>
	)

}

export default DistributionCenterDispatchSites