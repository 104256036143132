import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
    Button,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    styled,
    FormControl,
    MenuItem,
    FormHelperText,
} from "@mui/material"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import { useSelector } from "react-redux"
import { getMachineModelsSelector } from "../../../../../reducers/Machines/machines"
import { kitchenAreaMachinesSchema } from "../../../../../utils/yupValidators"


const getInitialValues = (machine) => {
    if (machine) {
        return {
            name: machine.name,
            machineModel: machine.machineModel.objectId
        }
    }

    return {
        name: ""
    }
}

const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        width: 500,
        padding: 10
    },
})

export const MachineKitchenAreaModal = ({ onClose, open, onConfirm, machine }) => {
    const machineModels = useSelector(getMachineModelsSelector)
    const formikRef = useRef(null)

    const handleConfirm = () => {
        formikRef.current.submitForm()
    }

    const handleSubmit = (values) => {       
        const machineModel = machineModels.find(machineModel => machineModel.objectId === values.machineModel)
        const newValues = { ...values, machineModel }

        onConfirm(newValues)
        onClose()
    }

    const handleCancel = () => {
        onClose()
    }

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
                Ajouter une machine
            </DialogTitle>
            <DialogContent sx={{ mt: 1 }}>
                <Formik
                    innerRef={formikRef}
                    initialValues={getInitialValues(machine)}
                    validationSchema={kitchenAreaMachinesSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, errors }) => {
                        return (
                            <Form>
                                <TextField
                                    variant="standard"
                                    label="Nom de la machine"
                                    name="name"
                                    onChange={(value) => {
                                        handleChange(value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    fullWidth
                                />
                                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.machineModel}>
                                    <InputLabel id="machineModel-label">Modèle</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="machineModel"
                                        value={values.machineModel}
                                        onChange={(value) => {
                                            handleChange(value)
                                        }}
                                        error={!!errors.machineModel}
                                        helperText={errors.machineModel}
                                        fullWidth
                                    >
                                        {machineModels.map((machineModel, index) => (
                                            <MenuItem key={index} value={machineModel.objectId}>
                                                {machineModel.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.machineModel && <FormHelperText>{errors.machineModel}</FormHelperText>}
                                </FormControl>
                            </Form>
                        )
                    }}
                </Formik>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}

export default MachineKitchenAreaModal