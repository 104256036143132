import React from "react"

export const ThumbDownIcon = ({selected}) => {
    return (
            <img
                src={selected?"/img/ThumbDownBlack.svg":"/img/ThumbDown.svg"}
                alt="down"
            />
    )
}

export default ThumbDownIcon