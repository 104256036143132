import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"
import { useSelector } from "react-redux"

import { COLORS } from "../../utils"
import { KFC_BRANDS } from "../../utils"
import ProductResumeEdit from "./ProductResumeEdit"
import {
    getSpicyImg,
    RecipeStatus
} from "../../utils/recipes"
import { productTagsTypes } from "../../utils/productTagsTypes"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import { getProductTypeLabelByProduct } from "../../utils/productTypesUtils"

const useStyles = makeStyles({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        paddingBottom: 16,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    singleValueContainer: {
        display: "flex",
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: 128,
        minWidth: 128,
        display: "flex",
        alignItems: "center"
    },
    greenStatus: {
        color: COLORS.GREEN_STATUS
    },
    redStatus: {
        color: COLORS.SECONDARY_COLOR
    },
    internalTagLabel: {
    },
    internalTagValueContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    internalTagChip: {
        marginRight: 8,
        marginBottom: 4,
        marginTop: 4
    },
    chefContainer: {
        display: "flex"
    },
    chefImage: {
        borderRadius: 40,
        height: 20,
        width: 20,
        marginRight: 8
    }
})

export const ProductResume = (props) => {
    const {
        jsonRecipe,
        parseRecipe,
        productsTags,
        onSaveProductResume,
        chefs,
        checkProductInCard,
        showModalProductInCard
    } = props

    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const classes = useStyles()

    const brands = jsonRecipe && Array.isArray(jsonRecipe.brands) && jsonRecipe.brands.map(brand =>
        KFC_BRANDS.find(elem => elem.name === brand).label
    ).join(", ")

    const status = jsonRecipe && RecipeStatus.find(elem => elem.value === jsonRecipe.status)
        ? RecipeStatus.find(elem => elem.value === jsonRecipe.status).label
        : ""
    
    const spicy = jsonRecipe.spicy && jsonRecipe.spicy !== "0" ? jsonRecipe.spicy : null

    const [isEditing, setIsEditing] = useState(false)

    const productTagsTypesTypesEnable = productTagsTypes.filter(elem => elem.enableValue)
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Résumé du produit
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Nom commercial
                </div>
                <span>
                    {
                        jsonRecipe
                            ? jsonRecipe.commercialName
                            : ""
                    }
                </span>
            </div>
            {
                jsonRecipe && jsonRecipe.commercialNames && jsonRecipe.commercialNames.length > 0 &&
                jsonRecipe.commercialNames.map((commercialName, index) => {
                    return (
                        <div className={classes.singleValueContainer} key={index}>
                            <div className={classes.valueLabel}></div>
                            <span style={{color: "#455A64"}}>{ commercialName.value }</span>
                        </div>
                    )
                })
            }

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Dénomination légale
                </div>
                <span>{jsonRecipe.legalName}</span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Nom technique
                </div>
                <span>
                    {
                        jsonRecipe
                            ? jsonRecipe.name
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Etat
                </div>
                <span
                    className={jsonRecipe.isActive ? classes.greenStatus : classes.redStatus}
                >
                    {
                        jsonRecipe.isActive
                            ? "Actif"
                            : "Inactif"
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Status
                </div>
                <span>
                    {
                        status
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Type
                </div>
                <span>
                    {getProductTypeLabelByProduct(jsonRecipe, productTypeOptions)}
                </span>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Marque{jsonRecipe && jsonRecipe.brands.length > 1 ? "s" : ""}
                </div>
                <span>
                    {
                        brands
                    }
                </span>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    EAN
                </div>
                <span>
                    {
                        jsonRecipe
                            ? jsonRecipe.ean
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Production maximale
                </div>
                <span>
                    {
                        jsonRecipe
                            ? jsonRecipe.maxProd
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Chef
                </div>
                {
                    jsonRecipe && jsonRecipe.chef
                        ?
                        <div className={classes.chefContainer}>
                            {
                                jsonRecipe.chef && jsonRecipe.chef.image && jsonRecipe.chef.image.storageData && jsonRecipe.chef.image.storageData.secure_url
                                    ?
                                    <img
                                        className={classes.chefImage}
                                        src={jsonRecipe.chef.image.storageData.secure_url}
                                        alt="Chef"
                                    />
                                    : null
                            }
                            <span>
                                {jsonRecipe.chef.firstName} {jsonRecipe.chef.lastName}
                            </span>
                        </div>
                        : null
                }
            </div>
            {
                spicy && jsonRecipe.spicy &&
                    <div className={classes.singleValueContainer}>
                        <div className={classes.valueLabel}>
                            Épicé
                        </div>
                        <span>
                            <div>{getSpicyImg(parseFloat(jsonRecipe.spicy))}</div>
                        </span>
                    </div>
            }
            {
                productTagsTypesTypesEnable.map((productTagType, index) =>
                    <div className={classes.singleValueContainer} key={index}>
                        <div className={classes.valueLabel}>
                            Tags {productTagType.label}
                        </div>
                        <div
                            className={classes.internalTagValueContainer}
                        >
                            {
                                jsonRecipe && Array.isArray(jsonRecipe.internalTag)
                                    ? jsonRecipe.internalTag.filter(internalTag => internalTag.type === productTagType.value).map((internalTag, index) =>
                                        <Chip
                                            key={index}
                                            label={internalTag.name}
                                            className={classes.internalTagChip}
                                        />
                                    )
                                    : null
                            }
                        </div>
                    </div>
                )
            }
            <ProductResumeEdit
                jsonRecipe={jsonRecipe}
                parseRecipe={parseRecipe}
                onClose={() => setIsEditing(false)}
                isEditing={isEditing}
                onSaveProductResume={onSaveProductResume}
                productsTags={productsTags}
                chefs={chefs}
            />
        </Paper>
    )
}

export default ProductResume


