import React from "react"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  StyledStickyLastBodyColumn,
} from "./StyledSectionComponents"

const RemoveColumn = ({ isHover, onClick, type }) => {
  return (
    <StyledStickyLastBodyColumn type={type} addBackground={isHover}>
      {isHover && (
        <IconButton onClick={onClick} className="flexCenter" disableRipple>
          <DeleteIcon />
        </IconButton>
      )}
    </StyledStickyLastBodyColumn>
  )
}

export default RemoveColumn
