import React, { useEffect, useState } from "react"
import Parse from "parse"
import dayjs from "dayjs"
import CommentsDrawer from "../../Comments/CommentsDrawer"

const CreditNoteImagesAndCommentForm = ({ data, open, onClose }) => {
	const [comment, setComment] = useState(null)
	const [images, setImages] = useState([])
	const [username, setUsername] = useState("")
	const [date, setDate] = useState(dayjs.tz().valueOf())

	useEffect(() => {
		const event = data.lot?.events?.find(event => event.mode === "BROKEN")
		if (!event) return
		
		if (event.comment) setComment(event.comment)
		if (event.images) setImages(event.images)
		if (event.date) setDate(dayjs.tz(event.date).valueOf())
		if (event.user) {
			setUsername(event.user.username)
			return
		}
		if (Parse.User.current()) {
			setUsername(Parse.User.current().get("username"))
		}
	}, [data.lot?.events])

	return (
		<CommentsDrawer
			comments={!comment && !images.length ? [] : [{
				comment: comment || "",
				images,
				username,
				creation_dt: date
			}]}
			open={open}
			onAddComment={(values) => {
				const { comment, images = [] } = values
				data.callback({
					method: "UPDATE_COMMENT",
					data: {
						comment,
					}
				})
				data.callback({
					method: "UPDATE_IMAGES",
					data: {
						images,
					}
				})
				setImages(images)
				setComment(comment)
			}}
			onClose={onClose}
		/>
	)
}

export default CreditNoteImagesAndCommentForm