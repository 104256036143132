
import { actionWithLoader } from "../Utils/utils"


import { generateRandomToken } from "../../utils"
import { createMachine, deleteMachinesByIds, getMachinesByKitchenArea, updateMachine } from "../../parseManager/machines/machinesManager"
import { getMachineModels } from "../../parseManager/machines/machineModelsManager"
import { getKitchenAreaById } from "../../parseManager/kitchenAreas/kitchenAreasManager"
import { getMachineTypes } from "../../parseManager/machines/machineTypesManager"

export function loadMachineModels() {
    return actionWithLoader(async (dispatch) => {
        const machineModels = await getMachineModels()

        dispatch({
            type: "MACHINE_MODELS_LOADED",
            machineModels,
        })
    })
}

export const addKitchenAreaMachine = (values) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machines = state.machines.machines || []

        const newKitchenAreaMachine = {
            objectId: generateRandomToken(),
            isNew: true,
            ...values,
        }

        const newKitchenAreaMachines = [...machines, newKitchenAreaMachine]

        dispatch({
            type: "MACHINES_LOADED",
            machines: newKitchenAreaMachines
        })
    })
}

/**
 * edit single kitchen area
 * note that the modification is not saved to database yet
 * so we just need to update the store (redux)
 * @param {*} id 
 * @param {*} values 
 * @returns 
 */
 export function editKitchenAreaMachine(id, values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machines = state.machines.machines || []

        const newMachines = []

        for (const machine of machines) {
            if (machine.objectId === id) {
                newMachines.push({
                    status: "edited",
                    ...machine,
                    ...values,
                })
            } else {
                newMachines.push(machine)
            }
        }

        dispatch({
            type: "MACHINES_LOADED",
            machines: newMachines
        })
    })
}

export const deleteKitchenAreaMachine = (id) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const machines = state.machines.machines || []

        const newMachines = machines.filter(machines => machines.objectId !== id)
    
        dispatch({
            type: "MACHINES_LOADED",
            machines: newMachines
        })
        dispatch({
            type: "OLD_MACHINES_LOADED",
            oldMachines: machines
        })
    })
}


export function onEnterKitchenArea(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState

        if (params.id) {
            store.dispatch(loadKitchenAreaMachines(params.id))
        }

        callback()
    }
}

export const loadKitchenAreaMachines = (kitchenAreaId, filters) => {
    return actionWithLoader(async (dispatch) => {
        const kitchenArea = await getKitchenAreaById(kitchenAreaId, false)

        const machines = await getMachinesByKitchenArea(kitchenArea, filters)
        const machineTypes = await getMachineTypes()

        const newMachines = []
        for (const machine of machines) {
          const newMachine = { ...machine }
          for (const machineType of machineTypes) {
            const machineModel = machineType.machineModels?.find(machineModel => machineModel.objectId === machine.machineModel.objectId)
            
            if (machineModel) {
              newMachine.machineType = machineType
            }
          }
          newMachines.push(newMachine)
        }

        dispatch({
            type: "MACHINES_LOADED",
            machines: newMachines
        })
        dispatch({
            type: "KITCHEN_AREA_LOADED",
            kitchenArea: kitchenArea?.toJSON()
        })
    })
}

/**
 * save and update the database for the edited, added, and deleted kitchen areas
 * @param {*} siteId 
 * @returns 
 */
 export function saveKitchenAreaMachines(kitchenAreaId, actions) {
    return actionWithLoader(async (dispatch, getState) => {
        // const site = await getParseSite(siteId, ["stockZones", "kitchenAreas.kitchenArea"])
        // let oldSiteKitchenAreas = site.get("kitchenAreas")
        const state = getState()
        const machines = state.machines.machines

        // ------------------------------------------------- //
        // ------------ Create new KitchenAreas ------------ //
        // ------------------------------------------------- //
        if (actions.hasAdded) {
            // get only the new added from form
            const newMachines = machines.filter(machine => machine.isNew)

            for (const newMachine of newMachines) {
                const values = {
                    name: newMachine.name,
                    machineModelId: newMachine.machineModel.objectId,
                    kitchenAreaId
                }
                await createMachine(values)
            }
        }

        // ------------------------------------------------- //
        // ----------- Edit existing KitchenAreas ---------- //
        // ------------------------------------------------- //
        if (actions.hasEdited) {
            // update only those temporary marked as "edited" and if it exist in the database
            const editedMachines = machines.filter(machine => machine.status === "edited" && machine.createdAt)

            // we just need to update the reference (KitchenArea), no need to update the pointer (in Site)
            for (const editedMachine of editedMachines) {
                const values = {
                    name: editedMachine.name,
                    machineModelId: editedMachine.machineModel.objectId,
                    kitchenAreaId
                }
                await updateMachine(editedMachine.objectId, values)
            }
        }

        if (actions.hasDeleted) {
            const oldMachines = state.machines.oldMachines || []
            // update only those temporary marked as "edited" and if it exist in the database
            const deletedMachines = oldMachines.filter(oldMachine => !machines.find(machine => machine.objectId === oldMachine.objectId))
            const deletedMachinesIds = deletedMachines.map(machine => machine.objectId)
            await deleteMachinesByIds(deletedMachinesIds)
            dispatch({ type: "CLEAR_OLD_MACHINES" })
        }

        dispatch(loadKitchenAreaMachines(kitchenAreaId))

        dispatch({
            type: "CLEAR_MACHINES",
        })
    })
}
