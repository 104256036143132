import React from "react"
import {makeStyles} from "@mui/styles"

import InputCardNumber from "./InputCardNumber"
import ConfirmCardNumber from "./ConfirmCardNumber"
import InputFlawNumber from "./InputFlawNumber"
import ActionHeader from "./ActionHeader"

const baseAction = {
	textAlign: "center",
	alignSelf: "center",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}

const useStyles = makeStyles(() => ({
	root: {
		...baseAction,
		"& form": {
			...baseAction
		}
	},
		noSelection: {
				maxWidth: 438,
				fontWeight: 300,
				fontSize: 37,
				lineHeight: "43px",
				marginTop: 120
		}
}))

const ActionContent = (props) => {
		const { 
			step,
			selectedCard,
			openModal,
			textHeader,
			handleClose,
			handleValidate,
			isTablet,
			setValue,
			lostActionInfos,
			isInventory,
			noDisplay
		} = props
	const classes = useStyles()

		return (
				<>
						{/* header */}
						{ selectedCard.length !== 0 &&
								<ActionHeader text={textHeader} handleClose={handleClose}/>
						}

						<div className={classes.root}>
								{/* none selected display */}
								{ step === 0 && selectedCard.length === 0  &&
										<div className={classes.noSelection}>
											{!isInventory &&
												<>
													Choisissez un produit à réceptionner
												</>
											}
											{isInventory &&
												<>
													Choisissez un produit
												</>
											}
										</div>
								}
		
								{/* one selected display */}
								{ step === 1 && !noDisplay && selectedCard.length !== 0 &&
										<InputCardNumber 
											selectedCard={selectedCard} 
											openModal={openModal}
											handleValidate={handleValidate}
											isTablet={isTablet}
											setValue={setValue}
											isInventory={isInventory}
										/>
								}

								{/* Confirm input */}
								{ step === 2 && !noDisplay  && selectedCard.length !== 0 &&
										<ConfirmCardNumber 
											selectedCard={selectedCard} 
											openModal={openModal} 
											handleValidate={handleValidate} 
											isTablet={isTablet} 
											setValue={setValue}
											isInventory={isInventory}
										/>
								}

								{/* set flaw or break meal */}
								{ step === 3 && !noDisplay && selectedCard.length !== 0 &&
										<InputFlawNumber
											openModal={openModal}
											handleValidate={handleValidate} 
											isTablet={isTablet}
											setValue={setValue}
											lostActionInfos={lostActionInfos}
										/>
								}
						</div>
				</>
		)
}

export default ActionContent