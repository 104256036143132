import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Box, Modal, Typography } from "@mui/material"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  header: {
    composes: "flexRow spaceBetween",
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
  },
  content: {
    marginTop: 0,
  },
  paper: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    width: (props) => props.width,
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: "30px",
    borderRadius: 7,
  },
}))

export const ModalForm = (props) => {
  const {
    onClose,
    open,
    children,
    title,
    icon,
    sx,
    width = 500,
    paperClassName,
  } = props

  const classes = useStyles({ width })

  return (
    <Modal onClose={onClose} open={open}>
      <div className={clsx(classes.paper, paperClassName)}>
        <div className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          {icon}
        </div>
        <Box className={classes.content} sx={sx}>
          {children}
        </Box>
      </div>
    </Modal>
  )
}

export default ModalForm
