import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import {useDispatch, useSelector} from "react-redux"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import { RequestPasswordChangeSchema } from "../../utils/yupValidators"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { sendRequestPasswordChange, closeAppSnackBar } from "../../actions/Utils/app"
import _isEqual from "lodash/isEqual"
import InputLabel from "@mui/material/InputLabel"
import Input from "@mui/material/Input"
import FormControl from "@mui/material/FormControl"

const useStyles = makeStyles({
    card: {
        background: COLORS.WHITE,
        border: `0.5px solid ${COLORS.SITE_BORDER}`,
        boxSizing: "border-box",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        borderRadius: 4,
        minHeight: 218,
        minWidth: 395,
        paddingLeft: 43,
        paddingTop: 40,
        paddingRight: 54,
        "& .MuiCardContent-root": {
            padding: 0,
            width: 273
        }
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 10
    },
    cardAction: {
        justifyContent: "center",
        paddingTop: 40,
        paddingBottom: 18
    },
    textField: {
        marginTop: 20,
        width: "100%",
        fontSize: 16,
        "& input": {
            color: COLORS.GREY_DISABLED,
        }
    },
    label: {
        color: COLORS.GREY_DISABLED,
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7,
        fontSize: 14
    }
})

const RequestPasswordChange = (props) => {
    const {
        location
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const appSnackBar = useSelector(state => state.app.appSnackBar, _isEqual)

    const _sendRequestPasswordChange = values => {
        dispatch(sendRequestPasswordChange(values.email, location.state && location.state.returnPath))
    }

    const _closeSnackbar = () => {
        dispatch(closeAppSnackBar(appSnackBar.type))
    }

    return (
        <div className="flexCenter height100">
            <Formik
                enableReinitialize
                initialValues={{
                    email: ""
                }}
                onSubmit={_sendRequestPasswordChange}
                validationSchema={RequestPasswordChangeSchema}
            >
                {({
                    values,
                    errors,
                    handleChange
                }) => {
                    return (
                        <Form>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.title}>
                                        Changement de mot de passe
                                    </Typography>
                                    <FormControl className={classes.textField} variant="standard">
                                        <InputLabel
                                            className={classes.label}
                                            shrink={true}
                                        >
                                            Email
                                        </InputLabel>
                                        <Input
                                            name="email"
                                            label="E-mail"
                                            type="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {
                                            errors.email ?
                                                (
                                                    <div className={classes.errorField}>{errors.email}</div>
                                                )
                                                : null
                                        }
                                    </FormControl>
                                </CardContent>
                                <CardActions className={classes.cardAction}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        RÉINITIALISER LE MOT DE PASSE
                                    </Button>
                                </CardActions>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={appSnackBar.open}
                autoHideDuration={appSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={appSnackBar.type}
                    message={appSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default RequestPasswordChange
