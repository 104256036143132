import React from "react"
import dayjs from "dayjs"

import DistributionCenterResume from "./DistributionCenterResume"
import DistributionCenterAddress from "./DistributionCenterAddress"
import DistributionCenterDates from "./DistributionCenterDates"

import { Grid, Box } from "@mui/material"

const DistributionCenterGeneral = ({ distributionCenter = {}, onEditResume, onEditAddress, onEditOpeningDate }) => {

  return (
    <Box m={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DistributionCenterResume
            distributionCenter={distributionCenter}
            onEdit={onEditResume}
          />
        </Grid>
        <Grid item xs={12}>
          <DistributionCenterAddress
            addressConfig={distributionCenter.address}
            onEdit={onEditAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <DistributionCenterDates
            openDate={dayjs(distributionCenter.openDate).format("DD/MM/YYYY")}
            onEdit={onEditOpeningDate}
          />
        </Grid>
      </Grid>
    </Box>
  )

}

export default DistributionCenterGeneral