import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import BackIcon from "@mui/icons-material/KeyboardBackspace"
import IconButton from "@mui/material/IconButton"
import { COLORS, KFC_BRANDS } from "../../utils"
import {
	getProductDispatchDlc,
	getProductDispatchProductTypeLabel
} from "../../utils/dispatchUtils"
import moment from "moment"

const useStyles = makeStyles(() => ({
	header: {
		composes: "flexRow",
		height: 75,
		width: "100%",
		alignItems: "center"
	},
	backIconContainer: {
		marginLeft: 15
	},
	backIcon: {
		color: COLORS.DARK_GREY
	},
	titleItem: {
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "100%",
		fontStyle: "normal",
		maxWidth: 400
	},
	detailInfoBloc: {
		marginLeft: 20
	},
	moreInfosItem: {
		color: COLORS.DARK_GREY,
		fontSize: 12,
		fontStyle: "normal",
		fontWeight: "normal",
		marginTop: 5
	},
	spanDetail: {
		marginRight: 15
	},
	uniqueCode: {
		fontSize: 34
	},
	rightBlocInfos: {
		composes: "flewRow",
		marginLeft: "auto"
	},
	floatLeft: {
		float: "left",
		textAlign: "center",
		marginRight: 15
	},
	dividerHeader: {
		composes: "$floatLeft",
		border: "1px solid #e8e8e8",
		height: 34
	},
	headerRightTextDesc: {
		color: COLORS.DARK_GREY,
		fontSize: 10
	},
	headerRightTextNumber: {
		fontSize: 21,
		color: COLORS.BLACK
	},
	reusable: {
		mask: "url(/img/recycle.svg) no-repeat center",
		maskSize: "contain",
		width: 36,
		height: 28,
		marginTop: 6,
		marginLeft: 10,
		display: "inline-block"
	}
}))

const DispatchHeader = (props) => {
	const {
		date, selectedProductDispatch, isDone, onClose,
		currentHub, hub, quantity, dispatched, remained, iteration
	} = props
	
	const classes = useStyles()
	
	return (
        <div className={classes.header}>
			<IconButton
                className={classes.backIconContainer}
                aria-label="Retour"
                onClick={onClose}
                size="large">
				<BackIcon className={classes.backIcon}/>
			</IconButton>
			{
				!isDone &&
				<>
					<div className={classes.uniqueCode}>
						{!currentHub && selectedProductDispatch.uniqueCode}
						{currentHub && hub.name.toUpperCase().replace(" ", "").slice(0,4)}
					</div>
					<div className={classes.detailInfoBloc}>
						<div className={classes.titleItem}>
							{!currentHub && selectedProductDispatch.commercialTitle}
							{currentHub && hub.name}
						</div>
						<div className={classes.moreInfosItem}>
							{
								!currentHub &&
								<span className={classes.spanDetail}>
                                    ID : {selectedProductDispatch.objectId}
                                </span>
							}
							{
								!currentHub &&
								<span className={classes.spanDetail}>
                                    {getProductDispatchProductTypeLabel(selectedProductDispatch)}
                                </span>
							}
							<span className={classes.spanDetail}>
                                DATE : {moment(parseFloat(date)).format("DD/MM")}
                            </span>
							{
								!currentHub &&
								<span>
                                    DLC : {moment(getProductDispatchDlc(selectedProductDispatch)).format("DD/MM")}
                                </span>
							}
						</div>
					</div>
					{
						!currentHub && selectedProductDispatch.isReusable &&
						<div
							className={classes.reusable}
							style={{backgroundColor: KFC_BRANDS.find(el => el.name === selectedProductDispatch.brand)["color"]}}
						/>
					}
				</>
			}
			{
				!isDone && currentHub && quantity &&
				<div className={classes.rightBlocInfos}>
					<div className={classes.floatLeft}>
						<div className={classes.headerRightTextNumber}>
							{`${iteration}/${quantity.ref}`}
						</div>
						<div className={classes.headerRightTextDesc}>
							REF A DISPATCHER
						</div>
					</div>
					<div className={classes.floatLeft}>
						<div className={classes.headerRightTextNumber}>
							{quantity.total}
						</div>
						<div className={classes.headerRightTextDesc}>
							PRODUITS TOTAL
						</div>
					</div>
					<div className={classes.dividerHeader}/>
					<div className={classes.floatLeft}>
						<div className={classes.headerRightTextNumber}>
							{remained}
						</div>
						<div className={classes.headerRightTextDesc}>
							RESTE
						</div>
					</div>
					<div className={classes.floatLeft}>
						<div className={classes.headerRightTextNumber}>
							{dispatched}
						</div>
						<div className={classes.headerRightTextDesc}>
							DISPATCHÉ
						</div>
					</div>
				</div>
			}
		</div>
    )
}

export default DispatchHeader