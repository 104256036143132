import React from "react"
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { showProductTypesEdition } from "../../../../actions/ProductTypes/productTypes"
import { getProductTypesSelector } from "../../../../reducers/ProductTypes/productTypes"
import Brands from "../../../../components/Brands"
import ProductTypesTableHead from "./ProductTypesTableHead"
import ProductTypesPagination from "./ProductTypesPagination"
import { formatManagementMode } from "../../../../utils/productTypesUtils"

const COL_WIDTH = 100 / 5 + "%"

const sx = {
    tabPanelHeader: {
        padding: "30px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    genericHeaderContainer: {
        height: 64,
        width: "100%",
    },
    firstColumn: {
        width: COL_WIDTH,
        pl: 5,
    },
    column: {
        width: COL_WIDTH
    },
}

const ProductTypes = () => {
    const dispatch = useDispatch()
    const productTypes = useSelector(getProductTypesSelector)

    const goToEditionPage = () => dispatch(showProductTypesEdition())

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh" bgcolor="#fff">
            <Box>
                <Box sx={sx.tabPanelHeader}>
                    <Typography variant="h6">
                        Types de produits
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        className={sx.editButton}
                        onClick={goToEditionPage}
                    >
                        Modifier
                    </Button>
                </Box>
            </Box>
            {/* --------- table --------- */}
            <Table aria-labelledby="tableTitle">
                <ProductTypesTableHead />
                <TableBody>
                    {productTypes.map((productType, index) => (
                        <TableRow
                            hover
                            key={productType.objectId + index}
                        >
                            <TableCell>{productType.label}</TableCell>
                            <TableCell>
                                {productType.order}
                            </TableCell>
                            <TableCell>{productType.adj}</TableCell>
                            <TableCell>{productType.kfcName}</TableCell>
                            <TableCell>{productType.fcSzName}</TableCell>
                            <TableCell>{productType.uniqueCode}</TableCell>
                            <TableCell>{formatManagementMode(productType.managementMode)}</TableCell>
                            <TableCell>
                                <Brands brands={productType.brands} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* --------- pagination --------- */}
            <ProductTypesPagination />
        </Box>
    )
}

export default ProductTypes