import {getData} from "../index"

const defaultSate = {
    productions: [],
    selectedProduction: null,
    productionDetailLength: 0,
    recipeSnackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultSate, action) {
    switch (action.type) {
        case "PRODUCTIONS_LOADED":
            return {
                ...state,
                productions: action.productions,
                selectedProduction: action.selectedProduction
            }
        case "PRODUCTION_SELECTED":
            return {
                ...state,
                selectedProduction: action.selectedProduction,
                productionDetailLength: action.productionDetailLength
            }
        case "PRODUCTION_DETAIL_DELETED":
            return {
                ...state,
                selectedProduction: action.selectedProduction,
                productionDetailLength: action.productionDetailLength
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//

export function getProduction(state, id) {
    return state.productions.productions.find(production => production.id === id)
}
export function getSelectedProduction(state, errorIfNotFound = false) {
    return getData(state, "productions.selectedProduction", errorIfNotFound && "No production selected")
}
