import {actionWithLoader, getReplacingPath, onEnter, push} from "../Utils/utils"
import {getProduction, getSelectedProduction} from "../../reducers/Recipe/productions"
import Parse from "parse"
import { parseLimitRequest, getServerUrl } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import moment from "moment"
import {downloadSinglePdf, downloadZipPdf} from "../../utils/downloadPdf"

const Production = Parse.Object.extend("Production")
const Recipe = Parse.Object.extend("Recipe")
const SubcontractorsProducts = Parse.Object.extend("SubcontractorsProducts")

export function downloadProductionReport(productions) {
    return actionWithLoader(async() => {
        for (const i in productions){
            try {
                const current = productions[i]
                const url = getServerUrl() + "/productions/" + current.id + "/downloadReport"
                const result = await axiosCall("POST", url,{downloadDate: moment().valueOf()}, {"Content-Type": "application/json"}, "blob")

                if (result.status === 200){
                    const pdfUrl = window.URL.createObjectURL(new Blob([result.data]))
                    const link = document.createElement("a")
                    const fileName = "Rapport_" + moment(current.get("date")).startOf("day").format("DD-MM-YYYY") + ".pdf"
                    link.href = pdfUrl
                    link.setAttribute("download", fileName)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }
                else {
                    //TODO: add error snackBar
                }
            }
            catch (e){
                //TODO: add error snackBar
            }
        }
    })
}

export function printProduction(production) {
    return async () => {
        await downloadZipPdf(production.get("date"),"KITCHEN", "PRODUCTION")
    }
}

export function printProductionDetail(date, productionDetail) {
    return async () => {
        const itemSource = productionDetail.hasOwnProperty("recipe")
            ? "recipe"
            : "subcontractorProduct"

        const id = productionDetail[itemSource] ? productionDetail[itemSource].id : null
        if (id) {
            await downloadSinglePdf(date, "KITCHEN", "PRODUCTION", id, itemSource)
        }
    }
}

export function createProduction(value) {
    const date = value.date
    const data = value.cart
    return actionWithLoader(async(dispatch) => {
        const dataToAdd = []
        for (const i in data) {
            const current = data[i]
            if (current.type === "recipe") {
                const recipe = await new Parse.Query(Recipe)
                    .equalTo("objectId", current.data.id)
                    .first()

                dataToAdd.push({
                    recipe: recipe,
                    volume: current.volume
                })
            }
            else {
                const subcontractorProduct = await new Parse.Query(SubcontractorsProducts)
                    .equalTo("objectId", current.data.id)
                    .first()

                dataToAdd.push({
                    subcontractorProduct: subcontractorProduct,
                    volume: current.volume
                })
            }
        }

        let production
        production = await new Parse.Query(Production)
            .equalTo("date", date)
            .first()

        if (!production){
            production = new Production()
            production.set("date", date)
            production.set("data", dataToAdd)
        }
        else {
            const finalData = [].concat(production.get("data"))
            for (const i in dataToAdd){
                const a = dataToAdd[i]
                let canAdd = true
                for (const j in production.get("data")){
                    const b = production.get("data")[j]
                    if (a.recipe && b.recipe && b.recipe.id === a.recipe.id){
                        canAdd = false
                    }
                    else if (
                          a.subcontractorProduct &&
                        b.subcontractorProduct &&
                        b.subcontractorProduct.id === a.subcontractorProduct.id
                    ){
                        canAdd = false
                    }
                }
                if (canAdd){
                    finalData.push(a)
                }
            }
            production.set("data", finalData)
        }

        await production.save()

        //TODO: gestion d'erreur via une snackBar
        dispatch(printProduction(production))
        dispatch(loadProductions())
        dispatch(showProductionList())
    })
}

export function deleteProductionDetail(productionId, data) {
    return actionWithLoader(async(dispatch) => {
        const production = await new Parse.Query(Production)
            .equalTo("objectId", productionId)
            .first()

        const newData = [].concat(production.get("data"))
        for (const i in production.get("data")){
            const current = production.get("data")[i]
            if (current.recipe && data.recipe){
                if (current.recipe.id === data.recipe.id){
                    newData.splice(i, 1)
                }
            }
            else if (current.subcontractorProduct && data.subcontractorProduct){
                if (current.subcontractorProduct.id === data.subcontractorProduct.id){
                    newData.splice(i, 1)
                }
            }
        }

        production.set("data", newData)

        await production.save()

        const productionDetailLength = production.get("data").length

        dispatch({
            type: "PRODUCTION_DETAIL_DELETED",
            selectedProduction: production,
            productionDetailLength
        })
    })
}

export function loadProductions() {
    return actionWithLoader(async (dispatch, getState) => {
        const productions = await new Parse.Query(Production)
            .notEqualTo("deleted", true)
            .include("data.recipe")
            .include("data.subcontractorProduct.name")
            .include("data.subcontractorProduct.name.supplier")
            .descending("date")
            .limit(parseLimitRequest)
            .find()

        const state = getState()
        const oldSelectedProduction = getSelectedProduction(state)
        const newSelectedProduction = oldSelectedProduction ? getProduction(state, oldSelectedProduction.id) : null
        dispatch({
            type: "PRODUCTIONS_LOADED",
            productions,
            selectedProduction: newSelectedProduction
        })
    })
}

export function editProductionDetailVolume(productionId, dataId, volume) {
    return actionWithLoader(async(dispatch) => {
        const production = await new Parse.Query(Production)
            .equalTo("objectId", productionId)
            .first()

        for (const i in production.get("data")){
            const current = production.get("data")[i]
            if (current.recipe){
                if (current.recipe.id === dataId){
                    current.volume = parseFloat(volume)
                }
            }
            else if (current.subcontractorProduct.id === dataId) {
                current.volume = parseFloat(volume)
            }
        }

        await production.save()

        dispatch({
            type: "PRODUCTION_DETAIL_VOLUME_UPDATED",
            production
        })
    })
}

export function deleteProduction(production) {
    return actionWithLoader(async (dispatch) => {
        await production.destroy()

        dispatch({
            type: "PRODUCTION_REMOVED"
        })
        dispatch(loadProductions())
    })
}

export function clearSelectedProduction() {
    return dispatch => {
        dispatch({
            type: "PRODUCTION_SELECTED",
            selectedProduction: null,
            productionDetailLength: 0
        })
    }
}

/** Routing **/

export function onEnterProductions(store) {
    return onEnter({
        store,
        actionThunk: loadProductions,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function onEnterSingleProduction(store) {
    return async (nextState, replace, callback) => {
        const {params} = nextState

        store.dispatch(clearSelectedProduction())
        if (params) {
            const state = store.getState()
            const selectedProduction = getProduction(state, params.id)
            const productionDetailLength = selectedProduction ? selectedProduction.get("data").length : 0

            store.dispatch({
                type: "PRODUCTION_SELECTED",
                selectedProduction,
                productionDetailLength
            })
        }
        callback()
    }
}

/** Routers **/

export function showProductionList() {
    return push("/productions")
}

export function showProduction (productionId) {
    if (productionId) {
        return push(`/production/${productionId}`)
    } else {
        return push("/production")
    }
}

