import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import {
    showProduction,
    deleteProduction,
    clearSelectedProduction,
    downloadProductionReport,
    printProduction
} from "../../actions/Recipe/productions"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import ProductionEnhancedTableToolbar from "../../components/table-utils/Production/ProductionEnhancedTableToolbar"
import ProductionEnhancedTableHead from "../../components/table-utils/Production/ProductionEnhancedTableHead"
import TablePagination from "../../components/TablePagination"
import { stableSort, getSorting } from "../../components/table-utils/utils"

const styles = {
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    column: {
        cursor: "pointer"
    },
    datePicker: {
        marginLeft: 25,
        width: 200
    },
    clearBtn: {
        marginTop: 10,
        marginLeft: 10
    }
}

class ProductionList extends PureComponent {
    state = {
        order: "asc",
        orderBy: "name",
        selected: [],
        page: 0,
        dataPage: 0,
        rowsPerPage: 10,
        startDate: null,
        endDate: null,
        filterProductions: [],
        data: []
    }

    componentDidMount() {
        const { clearSelectedProduction } = this.props
        clearSelectedProduction()
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const { productions } = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.startDate !== this.state.startDate
            || prevState.endDate !== this.state.endDate
            || prevProps.productions.length !== productions.length){
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { productions } = this.props
        const { startDate, endDate } = this.state
        let productionsFilter = []

        if (startDate && endDate){
            productionsFilter = productions.filter(production => moment(production.get("date")) >= startDate  && moment(production.get("date")) <= endDate)
        }
        else {
            productionsFilter = productions
        }

        this.setState({filterProductions: productionsFilter, data: productionsFilter.map(this.createData)})
    }

    createData = (production) => {
        const format = { year: "numeric", month: "numeric", day: "numeric" }
        let productionNbFP = 0
        let productionVolume = 0
        for (const j in production.get("data")){
            const currentData = production.get("data")[j]
            productionNbFP += 1
            productionVolume += currentData.volume
        }

        return {
            id: production.id,
            date: moment(production.get("date")).startOf("day").format("DD/MM/YYYY"),
            nbFP: productionNbFP,
            volume: productionVolume,
            createdAt: production.createdAt.toLocaleDateString("fr-FR", format),
            updatedAt: production.updatedAt.toLocaleDateString("fr-FR", format)
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterProductions } = this.state
            this.setState({selected: filterProductions.map(production => production.id)})
            return
        }
        this.setState({selected: []})
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected })
    }

    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    updateSelectedItems = () => {
        this.setState({selected: []})
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    _startDateFilterChange = (date) => {
        this.setState({startDate: moment(date).startOf("day").valueOf()})
        this.setState({endDate: moment(date).endOf("day").valueOf()})
    }

    _endDateFilterChange = (date) => {
        this.setState({endDate: moment(date).endOf("day").valueOf()})
    }

    _clearDate = () => {
        this.setState({
            startDate: null,
            endDate: null
        })
    }

    render() {
        const {
            classes, productions, showProduction,
            deleteProduction, printProduction, downloadProductionReport,
        } = this.props
        const {
            order, orderBy, selected, rowsPerPage, page, data,
            dataPage, filterProductions, startDate, endDate
        } = this.state

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper elevation={0} className={classes.root}>
                <ProductionEnhancedTableToolbar
                    numSelected={selected.length}
                    productions={productions}
                    showProduction={showProduction}
                    deleteProduction={deleteProduction}
                    printProduction={printProduction}
                    downloadProductionReport={downloadProductionReport}
                    onDeleteItem={this.updateSelectedItems}
                    selected={selected}
                />
                <div className="flexRow">
                    <DatePicker
                        disableCloseOnSelect={false}
                        showToolbar={false}
                        variant="inline"
                        value={startDate}
                        onChange={this._startDateFilterChange}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField {...params} variant="standard" label="Date de début" className={classes.datePicker} />}
                    />
                    <DatePicker
                        disableCloseOnSelect={false}
                        showToolbar={false}
                        variant="inline"
                        value={endDate}
                        onChange={this._endDateFilterChange}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField {...params} variant="standard" label="Date de fin" className={classes.datePicker} />}
                    />
                    { (startDate || endDate) &&
                        <IconButton
                            aria-label="Supprimer les dates"
                            onClick={this._clearDate}
                            className={classes.clearBtn}
                            size="large">
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    }
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <ProductionEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterProductions.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell className={classes.column} padding="none" onClick={() => showProduction(n.id)}>{n.date}</TableCell>
                                            <TableCell className={classes.column} onClick={() => showProduction(n.id)}>{n.nbFP}</TableCell>
                                            <TableCell className={classes.column} onClick={() => showProduction(n.id)}>{n.volume ? n.volume : 0}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={() => showProduction(n.id)}>{n.createdAt}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={() => showProduction(n.id)}>{n.updatedAt}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterProductions}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />
            </Paper>
        )
    }
}

ProductionList.propTypes = {
    classes: PropTypes.object.isRequired,
}

ProductionList = withStyles(styles)(ProductionList)

export default connect(state => {
    return {
        productions: state.productions.productions
    }
}, {
    showProduction,
    printProduction,
    downloadProductionReport,
    deleteProduction,
    clearSelectedProduction
})(ProductionList)
