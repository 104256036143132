//TODO remove this 
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import LightTooltip from "../LightTooltip"
import { COLORS, KFC_BRANDS, isAmbientProduct } from "../../utils"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import moment from "moment"

const useClasses = makeStyles(() => ({
  root: {
    color: COLORS.DARK_GREY,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&.date": {
      fontWeight: "normal",
      fontSize: 12,
      width: "80px",
      "&.dlcClose": {
        backgroundColor: COLORS.ORANGE_INVENTORY_BACKGROUND
      },
      "&.dlcPassedBg": {
        backgroundColor: COLORS.RED_INVENTORY_BACKGROUND
      },
    },
    "&.dlcPassed": {
      border: `2px solid ${COLORS.RED_INVENTORY_BACKGROUND}`,
    },
    "&.done": {
      backgroundColor: COLORS.GREEN_CARD_INVENTORY,
      border: "none",
      boxShadow: "none",
      "& *": {
        color: COLORS.GREEN_CARD_TEXT,
      },
    },
    "&.zero, &.zero.done": {
      backgroundColor: COLORS.GREY_CARD_BLURED,
      border: "none",
      boxShadow: "none",
      "& *": {
        color: COLORS.DARK_GREY,
      },
    },
    "&.selected, &.selected.done, &.selected.zero, &.selected.zero.done": {
      backgroundColor: COLORS.PRIMARY_COLOR,
      "& *": {
        color: COLORS.WHITE,
      },
    },
  },
  number: {
    fontWeight: "normal",
    fontSize: 24,
    "& span": {
      fontWeight: "bold",
      color: COLORS.BLACK_CARD,
    },
  },
  reusable: {
    mask: "url(/img/recycle.svg) no-repeat center",
    width: 10,
    height: 10,
    marginRight: 5,
    marginTop: 2,
  },
}))

const Card = (props) => {
  const {
    type,
    number,
    date,
    onCardClick,
    selectedCard,
    element,
    done,
    isInventory,
    canEdit,
  } = props

  const classes = useClasses()
  const selected = selectedCard && element.objectId === selectedCard.objectId
  const [dlcClose, setDlcClose] = useState()
  const [dlcPassed, setDlcPassed] = useState()

  const onClick = () => {
    if (canEdit && ((!done && !isInventory) || isInventory)) {
      onCardClick(element)
    }
  }

  useEffect(() => {
    let currentDate = moment.utc().startOf("day").valueOf()
    if (element.dlc < currentDate) {
      setDlcPassed(true)
    } else if (element.dlc === currentDate) {
      setDlcClose(true)
    }
  }, [element])
  const dates = (element && element.events.filter(e => e.type === "INVENTORY").map(e => e.date && moment.utc(e.date).startOf("day").valueOf())) || []
  const isUpdated = dates.includes(moment.utc().startOf("day").valueOf())
  const isZero = element.zero
  return (
    <>
      {(!isZero || isUpdated || !isAmbientProduct(element)) &&
        <LightTooltip title={element.toolTip}>
          <Paper
            className={clsx(
              classes.root,
              { dlcPassed: dlcPassed },
              { zero: element.zero },
              { done: done },
              { selected: selected },
            )}
            onClick={onClick}
          >
            {element.isReusable && (
              <div
                className={classes.reusable}
                style={{
                  backgroundColor: KFC_BRANDS.find(
                    (el) => el.name === element.brand
                  )["color"],
                }}
              />
            )}
            <Typography className={classes.number} noWrap>
              {type}
              <span>{number}</span>
            </Typography>
            <div className={clsx(
              classes.root,
              { dlcPassedBg: dlcPassed },
              { dlcClose: dlcClose },
              { date },
            )}>{date}</div>
          </Paper>
        </LightTooltip>
      }
    </>
  )
}

export default Card
