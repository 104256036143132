import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import { Modal } from "@mui/material"
import { checkTablet, COLORS } from "../../utils"
import {
  closeSingleReceptionWarehouseDetail,
  closeReceptionWarehouseSnackBar,
  updateProductPackaging,
} from "../../actions/ReceptionWarehouse/receptionWarehouse"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import _cloneDeep from "lodash/cloneDeep"
import { confirm } from "../../components/react-await-dialog/react-await-dialog"
import ReceptionWarehouseHeader from "../../components/ReceptionWarehouse/ReceptionWarehouseHeader"
import InputBlock from "../../components/ReceptionWarehouse/InputBlock"
import clsx from "clsx"
import { packagingNature } from "../../utils/packaging"
import { checkIfHaveRight } from "../../actions/Utils/utils"
import { rightReadProductPackaging } from "../../utils/accessRights"

const useClasses = makeStyles(() => ({
  singleReceptionWarehouseModalDetail: {
    backgroundColor: COLORS.WHITE,
    opacity: 1,
    outline: "none",
    overflow: "scroll",
    zIndex: 1300,
    height: "100%",
  },
  divider: {
    border: "1px solid #e8e8e8",
    width: "100%",
  },
  noOutline: {
    outline: "none",
  },
  contentBloc: {
    textAlign: "center",
    marginTop: 65,
    color: COLORS.BLACK,
    fontSize: 27,
    lineHeight: "32px",
  },
  confirmButtonBasic: {
    composes: "flexRow",
    height: 72,
    width: 410,
    fontSize: 20,
    textAlign: "center",
    alignItems: "center",
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 5,
    marginLeft: 25,
    marginRight: 25,
  },
  confirmButtonEmpty: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.DARK_GREY,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  confirmButtonFill: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  confirmButtonRedirect: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.WHITE,
    color: COLORS.PRIMARY_COLOR,
    border: `solid 2px ${COLORS.PRIMARY_COLOR}`,
  },
  buttonText: {
    margin: "0 auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  uniqueCode: {
    fontSize: 34,
  },
  doneIcon: {
    color: COLORS.PRIMARY_COLOR,
    fontSize: 70,
  },
}))

const SingleReceptionWarehouseDetail = (props) => {
  const {
    date,
    rights,
    selectedProductPackaging,
    snackBar,
    closeReceptionWarehouseSnackBar,
    closeSingleReceptionWarehouseDetail,
    updateProductPackaging,
  } = props

  const classes = useClasses()
  const inputRef = useRef(null)
  const wantedInput =
    selectedProductPackaging &&
    (selectedProductPackaging.packagingProduction ||
      selectedProductPackaging.expectedProduction)
  const [inputValue, setInputValue] = useState(wantedInput === 0 ? "0" : "")
  const [isTablet, setIsTablet] = useState(checkTablet)

  const _resetProductPackagingStatus = async (ev) => {
    ev.preventDefault()
    await closeSingleReceptionWarehouseDetail(selectedProductPackaging)
    setTimeout(() => ({}), 15000)
  }
  window.addEventListener("unload", _resetProductPackagingStatus, false)

  // set isTablet state
  useLayoutEffect(() => {
    const checkisTablet = () => {
      setIsTablet(checkTablet())
    }

    // title
    document.title = "Réception - KFC"

    // check size
    window.addEventListener("resize", checkisTablet)

    // remove check
    return () => window.removeEventListener("resize", checkisTablet)
  }, [])

  useEffect(() => {
    if (!selectedProductPackaging) {
      closeSingleReceptionWarehouseDetail(selectedProductPackaging)
    }
  }, [])

  const _onClose = () => {
    closeSingleReceptionWarehouseDetail(selectedProductPackaging)
  }

  const _onInputValueChange = (event) => {
    setInputValue(event.target.value)
  }

  const _confirmStep = async (redirect) => {
    if (
      inputValue !== "" &&
      !inputValue.includes(",") &&
      !inputValue.includes(".")
    ) {
      if (
        wantedInput === parseFloat(inputValue) ||
        (await confirm({
          title: "Confirm",
          message:
            "La valeur indiquée est différente de celle attendue. Êtes vous sûr?",
          confirmText: "Oui",
        }))
      ) {
        await updateProductPackaging(
          selectedProductPackaging.objectId,
          parseFloat(inputValue),
          redirect
        )
      }
    }
  }

  const _onKeyDown = (event) => {
    if (event && event.keyCode === 13) {
      _confirmStep()
      event.preventDefault()
    }
  }

  return (
    <Modal
      key="single-reception-warehouse-detail-modal"
      BackdropProps={{ invisible: true }}
      className={classes.singleReceptionWarehouseModalDetail}
      open={true}
    >
      <div className={classes.noOutline}>
        {selectedProductPackaging && (
          <div>
            <ReceptionWarehouseHeader
              onClose={_onClose}
              selectedProductPackaging={selectedProductPackaging}
              date={date}
            />
            <div className={classes.divider} />
            <div className={classes.contentBloc}>
              <span>
                Entrez le total des{" "}
                <strong>
                  {selectedProductPackaging &&
                    selectedProductPackaging.productionItems.length > 0 &&
                    selectedProductPackaging.productionItems[0].uniqueCode}
                </strong>{" "}
                en packaging{" "}
                <strong>
                  {
                    packagingNature.find(
                      (el) =>
                        el.value === selectedProductPackaging.packagingType
                    ).label
                  }
                </strong>{" "}
                en entrepôt
              </span>
              <InputBlock
                inputRef={inputRef}
                onKeyDown={_onKeyDown}
                onInputValueChange={_onInputValueChange}
                inputValue={inputValue}
                wantedInput={wantedInput}
              />
              <div className={classes.buttonContainer}>
                {checkIfHaveRight(rightReadProductPackaging, rights) && (
                  <div
                    className={clsx(
                      inputValue !== null && inputValue !== "" && !snackBar.open
                        ? classes.confirmButtonRedirect
                        : classes.confirmButtonEmpty,
                      { "is-tablet": isTablet }
                    )}
                    onClick={() => {
                      if (!snackBar.open) _confirmStep(true)
                    }}
                  >
                    <span className={classes.buttonText}>
                      CONFIRMER ET ALLOTIR
                    </span>
                  </div>
                )}
                <div
                  className={clsx(
                    inputValue !== null && inputValue !== "" && !snackBar.open
                      ? classes.confirmButtonFill
                      : classes.confirmButtonEmpty,
                    { "is-tablet": isTablet }
                  )}
                  onClick={() => {
                    if (!snackBar.open) _confirmStep(false)
                  }}
                >
                  <span className={classes.buttonText}>CONFIRMER</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBar.open}
          autoHideDuration={snackBar.duration}
          onClose={() => closeReceptionWarehouseSnackBar(snackBar.type)}
        >
          <MySnackbarContentWrapper
            onClose={() => closeReceptionWarehouseSnackBar(snackBar.type)}
            variant={snackBar.type}
            message={snackBar.message}
          />
        </Snackbar>
      </div>
    </Modal>
  )
}

export default connect(
  (state) => {
    return {
      date: state.receptionWarehouse.date,
      selectedProductPackaging: _cloneDeep(
        state.receptionWarehouse.selectedProductsPackaging[0]
      ),
      snackBar: state.receptionWarehouse.snackBar,
      rights: state.app.rights,
    }
  },
  {
    closeReceptionWarehouseSnackBar,
    closeSingleReceptionWarehouseDetail,
    updateProductPackaging,
  }
)(SingleReceptionWarehouseDetail)
