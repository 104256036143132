import { getData } from "../index"

const defaultState = {
	selectedSite: null,
	selectedKitchenArea: null,
	selectedProductionDay: null,
	sites: [],
	productionStepsExecutions: [],
	productionStepExecution: null,
	recipeOptions: [],
	sectionOptions: [],
	machinesOptions: [],
	lotsBySupplierItems: new Map(),
	secondaryDLCsBySupplierItems: new Map(),
	snackbar: { open: false, type: "", message: "", duration: 1000 },
	PSEgenerationDialogOpen: false,
	PSEgenerationForOneRecipe: false
}

export default function (state = defaultState, action) {
	switch (action && action.type) {
		case "FILTERS_LOADED_FOR_PRODUCTION_STEPS":
			return {
				...state,
				selectedSite: action.selectedSite,
				selectedKitchenArea: action.selectedKitchenArea,
				selectedProductionDay: action.selectedProductionDay
			}
		case "SITES_LOADED_FOR_PRODUCTION_STEPS":
			return {
				...state,
				sites: action.sites,
			}
		case "RECIPE_OPTIONS_LOADED_FOR_PRODUCTION_STEPS":
			return {
				...state,
				recipeOptions: action.recipeOptions
			}
		case "SECTION_OPTIONS_LOADED_FOR_PRODUCTION_STEPS":
			return {
				...state,
				sectionOptions: action.sectionOptions
			}
		case "PRODUCTION_STEPS_EXECUTIONS_LOADED":
			return {
				...state,
				productionStepsExecutions: action.productionStepsExecutions,
			}
		case "PRODUCTION_STEPS_SNACKBAR_LOADED":
			return {
				...state,
				snackbar: action.snackbar,
			}
		case "LOAD_PRODUCTION_STEP_EXECUTION":
			return {
				...state,
				productionStepExecution: action.productionStepExecution,
				allPSEComments: action.allPSEComments || state.allPSEComments
			}
		case "LOAD_PRODUCTION_STEP_EXECUTION_LOTS_SECONDARYDLCS": {
			return {
				...state,
				lotsBySupplierItems: action.lotsBySupplierItems,
				secondaryDLCsBySupplierItems: action.secondaryDLCsBySupplierItems

			}
		}
		case "PRODUCTION_STEPS_SNACKBAR_CLOSED":
			return {
				...state,
				snackbar: { open: false, type: "", message: "", duration: 1000 }
			}
		case "TOGGLE_PSE_GENERATION_DIALOG":
			return {
				...state,
				PSEgenerationDialogOpen: action.open,
				PSEgenerationForOneRecipe: action.isForOneRecipe,
			}
		case "TOGGLE_PSE_FUSION_DIALOG":
			return {
				...state,
				PSEFusionDialogOpen: action.open,
				PSEFusionStep2DialogOpen: action.step2
			}
		case "LOAD_PSE_MACHINES_OPTIONS":
			return {
				...state,
				machinesOptions: action.machinesOptions
			}
		default:
			return {
				...state
			}
	}
}

export function getProductionStepsKitchenAreaSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.selectedKitchenArea", errorIfNotFound && "No selected kitchenArea found")
}

export function getProductionStepsSiteSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.selectedSite", errorIfNotFound && "No selected site found")
}

export function getProductionStepsProductionDaySelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.selectedProductionDay", errorIfNotFound && "No selected productionDay found")
}

export function getProductionStepsSitesSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.sites", errorIfNotFound && "No sites found")
}

export function getProductionStepsRecipeOptionsSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.recipeOptions", errorIfNotFound && "No recipeOptions found")
}

export function getProductionStepsSectionOptionsSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.sectionOptions", errorIfNotFound && "No sectionOptions found")
}

export function getProductionStepsExecutionsSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.productionStepsExecutions", errorIfNotFound && "No productionStepsExecutions found")
}

export function getProductionStepExecutionsSelector(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.productionStepsExecutions", errorIfNotFound && "No productionStepsExecutions found")
}

export function getProductionStepsSnackbar(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.snackbar", errorIfNotFound && "No snackbar found")
}

export function isPSEGenerationDialogOpen(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.PSEgenerationDialogOpen", errorIfNotFound && "No PSEgenerationDialogOpen found")
}

export function isPSEFusionDialogOpen(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.PSEFusionDialogOpen", errorIfNotFound && "No PSEFusionDialogOpen found")
}

export function isPSEFusionStep2DialogOpen(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.PSEFusionStep2DialogOpen", errorIfNotFound && "No PSEFusionStep2DialogOpen found")
}

export function isGenerationForOneRecipe(state, errorIfNotFound = false) {
	return getData(state, "productionSteps.PSEgenerationForOneRecipe", errorIfNotFound && "No PSEgenerationDialogOpen found")
}

export const getProductionStepExecutionSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSteps.productionStepExecution", errorIfNotFound && "No productionStepsExecutions found")
}

export const getAllPSECommentsExecutionSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSteps.allPSEComments", errorIfNotFound && "No allPSEComments found")
}

export const getProductionStepExecutionLotsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSteps.lotsBySupplierItems", errorIfNotFound && "No lots found")
}

export const getProductionStepExecutionSecondaryDLCsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSteps.secondaryDLCsBySupplierItems", errorIfNotFound && "No secondaryDLCs found")
}

export const getPSEMachinesOptionsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSteps.machinesOptions", errorIfNotFound && "No machinesOptions found")
}