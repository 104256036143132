import React, { forwardRef } from "react"
import Dialog from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import { makeStyles } from "@mui/styles"
import ProductInfo from "./ProductInfo/ProductInfo"
import { checkTablet } from "../../utils"

const useStyles = makeStyles(() => ({
    modal: {
        "& .MuiDialog-container": {
            width: 600,
            position: "absolute",
            right: 0
        }
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />
})

const PlanningProductModal = (props) => {
    const { open, handleClose, selectedCard, rightProduct, goToProduct } = props
    const classes = useStyles()
    
    return (
        <Dialog
            fullScreen
            open={open}
            className={classes.modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <ProductInfo
                cardInfo={selectedCard}
                rightProduct={rightProduct}
                isTablet={checkTablet()}
                goToProduct={goToProduct}
            />
        </Dialog>
    )
}

export default PlanningProductModal
