import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import Divider from "@mui/material/Divider"
import CommandResumeEdit from "./CommandResumeEdit"
import clsx from "clsx"
import RoundCheckBoxIcon from "../RoundCheckBoxIcon"
import InformationLine from "./InformationLine"
import DayCheck from "./DayCheck"
import SendingOrderCheck from "./SendingOrderCheck"
import {
    deliveryDaysList,
    sendingOrderTypes,
    deliveryLeadTimeList,
    openDayslist
} from "../../utils/suppliers"

const useStyles = makeStyles({
    rootContainer: {
        display: "flex",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        paddingTop: 20,
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 40,
        minHeight: "calc(100vh - 275px)"
    },
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 1140,
        marginTop: 20
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    price: {
        marginLeft: 20,
        fontSize: 16,
        color: COLORS.SITE_INFORMATION_VALUE,
        marginTop: -2
    },
    checkIcon:{
        marginLeft:20,
        fontSize:1,
    },
    dayList:{
        width:409,
        height:11,
        marginLeft:-10,
        color: COLORS.SITE_INFORMATION_VALUE,
        display: "flex",
        justifyContent: "flex-start",
        "& span" : {
            marginLeft:20,
            width:17,
            height:11,
            fontWeight:500,
            fontSize:15,
        }
    },
    listValue: {
        height:11,
        fontSize:16,
        color: COLORS.SITE_INFORMATION_VALUE,
        display: "flex",
        justifyContent:"space-around"
    },
    checkBoxLabel: {
      padding: "0 10px"
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16,
        lineHeight: "15px",
        "&> div:not(.list-value)": {
            width: "50%"
        }
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 232,
        display: "flex",
        alignItems: "center"
    },
    deliveryDays: {
        color: COLORS.SUPPLIER_DELIVERY_COlOR
    }
})

export const CommandResume = props => {
    const {
        jsonSupplier,
        onSaveSupplier,
        canEdit
    } = props

    let deliveryLeadTime, openDayLabel = null
    if (jsonSupplier.order) {
        deliveryLeadTime = deliveryLeadTimeList.find(elem => elem.value === jsonSupplier.order.deliveryLeadTime)
        openDayLabel = openDayslist.find(elem => elem.value === jsonSupplier.order.openDays)
    }

    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)

    const edit = () => setIsEditing(true)

    const _toggleEdit = () => setIsEditing(!isEditing)

    return (
        <div className={classes.rootContainer}>
            <Paper className={classes.container}>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>
                        Informations liées aux commandes
                    </div>
                    {
                        canEdit &&
                        <IconButton
                            className={classes.iconContainer}
                            disableRipple={true}
                            onClick={edit}
                            size="large">
                            <EditIcon className={classes.icon}/>
                        </IconButton>
                    }
                </div>
                <InformationLine
                    classes={classes}
                    label="Minimum de commande"
                    value={`${(jsonSupplier.order && jsonSupplier.order.minimumCommand) || "0,00"}${(jsonSupplier.order && jsonSupplier.order.minimumCommandUnit) || "€"}`}
                />
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Interdire une commande inférieur au montant minimum de commande</div>
                    <div className={classes.checkIcon}>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.order && jsonSupplier.order.forbidMinimumCommand}
                        />
                    </div>
                </div>
                <Divider style={{ marginBottom:25 }}/>
                <InformationLine
                    classes={classes}
                    label="Frais de port"
                    value={`${(jsonSupplier.order && jsonSupplier.order.shippingFees) || "0,00"} €`}
                />
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Annuler les frais de port si la commande est supérieure au minimum de commande</div>
                    <div className={classes.checkIcon}>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.order && jsonSupplier.order.allowShippingFeesCancel}
                        />
                    </div>
                </div>
                <InformationLine
                    classes={classes}
                    label="Délai de commande"
                    value={jsonSupplier.order && jsonSupplier.order.deliveryLeadTime && deliveryLeadTime && deliveryLeadTime.label}
                />
                <InformationLine
                    classes={classes}
                    label="Heure limite de commande"
                    value={jsonSupplier.order && jsonSupplier.order.orderHourLimit}
                />
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Autoriser une date de livraison inférieure au délai minimum</div>
                    <div className={classes.checkIcon}>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.order && jsonSupplier.order.allowEarlyDelivery}
                        />
                    </div>
                </div>
                <Divider style={{ marginBottom:25 }}/>
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Jours de livraison</div>
                    <div className={classes.dayList}>
                        {
                            deliveryDaysList.map((day, index) => (
                                <DayCheck
                                    key={index}
                                    className={jsonSupplier.order && jsonSupplier.order.deliveryDays && jsonSupplier.order.deliveryDays.includes(index + 1) ? classes.deliveryDays : ""}
                                    value={day.substring(0,2)}
                                />
                            ))
                        }
                    </div>
                </div>
                <InformationLine
                    classes={classes}
                    label="Jours d’ouverture"
                    value={jsonSupplier.order && jsonSupplier.order.openDays && openDayLabel && openDayLabel.label}
                />
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Mode d’envoi</div>
                    <div className={clsx(classes.listValue, "list-value")}>
                        {
                            sendingOrderTypes.map((el, index) => (
                                <SendingOrderCheck
                                    key={index}
                                    classes={classes}
                                    label={el.label}
                                    checked={jsonSupplier.order && jsonSupplier.order.sendOrderMode && jsonSupplier.order.sendOrderMode[el.name]}
                                />
                            ))
                        }
                    </div>
                </div>
                <Divider style={{ marginBottom:25 }}/>
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>Afficher un lien cliquable de confirmation de lecture dans l’email de commande</div>
                    <div className={classes.checkIcon}>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.order && jsonSupplier.order.showLinkInEmail}
                        />
                    </div>
                </div>
                <InformationLine
                    classes={classes}
                    label="Précisions à imprimer sur les bons de commande"
                    value={jsonSupplier.order && jsonSupplier.order.orderPrecision}
                />
            </Paper>
            {
                canEdit &&
                <CommandResumeEdit
                    onClose={_toggleEdit}
                    isEditing={isEditing}
                    jsonSupplier={jsonSupplier}
                    onSaveSupplier={onSaveSupplier}
                />
            }
        </div>
    )
}

export default CommandResume
