import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { useSelector } from "react-redux"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import { getDefaultProductOptions } from "../../utils/productTypesUtils"

const TypeSelect = (props) => {
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const { classes, ...otherProps } = props

    return (
        <FormControl className={classes.typeFormControl} variant="standard">
            <InputLabel>Type</InputLabel>
            <Select
				variant="standard"
                name="type"
                label="type"
                id="type"
                fullWidth
                {...otherProps}
            >
                {
                    getDefaultProductOptions(productTypeOptions).map((type, index) =>
                        <MenuItem key={index} value={type.value}>{type.label}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default TypeSelect
