import moment from "moment"

export function formatInventoryData(receptionData) {
	const newReceptionData = {}

	receptionData.forEach(elm => {
		const group = elm.uniqueCode.replace(/\d/g,"")

		if (!newReceptionData[elm.productType]) newReceptionData[elm.productType] = {}
		if (!newReceptionData[elm.productType][group])  newReceptionData[elm.productType][group] = []
		newReceptionData[elm.productType][group].push(elm)
	})
	
	return newReceptionData
}

export function handleLastInventory(productStock) {
	let checked = false
	let tooltipText = ""
	let zero =  false

	const inventories = productStock.events.filter(elm => elm.type === "INVENTORY")

	if (inventories.length > 0) {
		const lastInventory = inventories[inventories.length - 1]

		const date = moment(lastInventory.date).format("DD/MM HH:mm")
		tooltipText = `${lastInventory.data.newVolume} - ${lastInventory.user.username} - ${date}`

		const duration = moment.duration(moment.utc().diff(moment.utc(lastInventory.date))).asHours()

		if (duration < 2) checked = true
		if (productStock.volume <= 0) zero = true
	}

	productStock.zero = zero
	const dlcRemain = moment.duration(moment.utc(productStock.dlc).diff(moment.utc().startOf("day"))).asSeconds()

	if (undefined === productStock.uniqueCode) productStock.uniqueCode = "NC"
	productStock.display = (["DRINK", "SNACK", "YAOURT"].includes(productStock.productType))?true:(dlcRemain >= 0)
	productStock.toolTip = tooltipText
	productStock.checked = checked
	return productStock
}