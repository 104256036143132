import Parse from "parse"
import { COLLECTION_NAMES } from "../../parseUtils"

const ProductionSubstitution = Parse.Object.extend("ProductionSubstitution")

/**
 * create production substitution for the future and for already existing production step executions
 * in the second case the substitution is already applied to pse
 * @param {String} originalSupplierItemId
 * @param {String} replacementSupplierItemId
 * @param {Array<String>} productionStepExecutionsIds
 * @param {Array<String>} recipesIds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns 
 */

export const createProductionSubstitution = async ({
	originalSupplierItemId,
	replacementSupplierItemId,
	productionStepExecutionsIds = [],
	recipesIds = [],
	startDate,
	endDate,
	usernameId
}) => {

	try {
		const productionSubstitution = new ProductionSubstitution()

		productionSubstitution.set("startDate", startDate)
		productionSubstitution.set("endDate", endDate)

		productionSubstitution.set("originalSupplierItem", {
			__type: "Pointer",
			className: COLLECTION_NAMES.supplierItem,
			objectId: originalSupplierItemId
		})
		productionSubstitution.set("replacementSupplierItem", {
			__type: "Pointer",
			className: COLLECTION_NAMES.supplierItem,
			objectId: replacementSupplierItemId
		})
		productionSubstitution.set("username", {
			__type: "Pointer",
			className: "_User",
			objectId: usernameId
		})

		const type = recipesIds.length ? "oneOff" : "periodic"

		productionSubstitution.set("type", type)

		if (recipesIds.length) {
			productionSubstitution.set("recipes", recipesIds.map(id => ({
				__type: "Pointer",
				className: COLLECTION_NAMES.recipe,
				objectId: id
			})))
		}

		const productionStepExecutionsPointers = productionStepExecutionsIds.map(id => ({
			__type: "Pointer",
			className: COLLECTION_NAMES.productionStepExecution,
			objectId: id
		}))
		productionSubstitution.set("substitutedProductionStepExecutions", productionStepExecutionsPointers)
		
		return await productionSubstitution.save()
	}
	catch (error) {
		console.error("Error creating production substitution", error)
		throw error
	}
}


export const loadProductionSubstitutions = async ({
	includes = [],
	selects = [],
	toJSON = true
}) => {

	try {
		const productionSubstitutionsQuery = new Parse.Query(ProductionSubstitution)
			.notEqualTo("deleted", true)
			.descending("startDate")

		if (includes && includes.length && Array.isArray(includes)) {
			productionSubstitutionsQuery.include(includes)
		}
		if (selects && selects.length && Array.isArray(selects)) {
			productionSubstitutionsQuery.select(selects)
		}

		const productionSubstitutions = await productionSubstitutionsQuery.find()
		return toJSON ? productionSubstitutions.map(productionSubstitution => productionSubstitution.toJSON()) : productionSubstitutions
	}
	catch (e) {
		console.error("Error loading production substitutions", e)
		return []
	}
}
