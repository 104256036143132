import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import withStyles from "@mui/styles/withStyles"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import {
    createClassificationsGroupIngredient,
    showGroupsClassificationsIngredients,
    showSingleGroupClassificationsIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Groups/Groups"
import { groupIngredientFormInitialValues } from "../../../../../actions/Utils/utils"
import {getGroupIngredient} from "../../../../../reducers/Ingredients/Classifications/GroupsIngredients"
import {recipeFormstyles} from "../../../../../styles"
import {groupIngredientValidator} from "../../../../../utils/validators"
import ReduxSelectField from "../../../../../components/form/ReduxSelectField"
import ReduxTextField from "../../../../../components/form/ReduxTextField"

class GroupIngredientForm extends PureComponent {

    _onClose = () => {
        const {
            showGroupsClassificationsIngredients,
            showSingleGroupClassificationsIngredientsRead,
            groupIngredient,
            location
        } = this.props

        if (location.state && location.state.returnPath === "edit"){
            showSingleGroupClassificationsIngredientsRead(groupIngredient.objectId)
        }
        else {
            showGroupsClassificationsIngredients()
        }
    }

    renderFamiliesIngredientsItems = () => {
        const { familiesIngredients } = this.props
        if (familiesIngredients){
            return familiesIngredients.map(familyIngredient =>
                <MenuItem key={familyIngredient.objectId} value={familyIngredient.objectId}>{familyIngredient.name}</MenuItem>
            )
        }
    }

    render(){
        const {
            classes,
            handleSubmit,
            createClassificationsGroupIngredient,
            location
        } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={classes.formContainer}>
                    <form onSubmit={handleSubmit(createClassificationsGroupIngredient, location)}>
                        <div className="flexRow">
                            <FormControl margin="normal" classes={{root: classes.formControlMax400}} required>
                                <Field
                                    name="name"
                                    component={ReduxTextField}
                                    label="Nom"
                                />
                            </FormControl>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="family"
                                    label="Famille d'ingrédient"
                                    placeholder="Famille d'ingrédient"
                                    onChange={this._onFamilyIngredientChange}
                                >
                                    {this.renderFamiliesIngredientsItems()}
                                </ReduxSelectField>
                            </FormControl>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                classes={{root: classes.actionButton}}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

GroupIngredientForm = reduxForm({
    form: "groupIngredient",
    validate: groupIngredientValidator,
    enableReinitialize: false
})(GroupIngredientForm)

GroupIngredientForm = withStyles(recipeFormstyles)(GroupIngredientForm)

export default connect((state, props) => {
    const { params: {id} } = props
    const groupIngredient = id ? getGroupIngredient(state, id) : null
    const groupIngredientFormValues = groupIngredient !== null ? state.form.groupIngredient ? state.form.groupIngredient.values : undefined : undefined
    return {
        initialValues: groupIngredientFormInitialValues(groupIngredient),
        groupIngredientFormValues,
        groupIngredient: groupIngredient,
        familiesIngredients: state.groupsIngredients.familiesIngredients
    }
}, {
    createClassificationsGroupIngredient,
    showSingleGroupClassificationsIngredientsRead,
    showGroupsClassificationsIngredients
})(GroupIngredientForm)
