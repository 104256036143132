import React from "react"
import { makeStyles } from "@mui/styles"
import { Stack, Typography } from "@mui/material"
import { COLORS, roundNumber } from "../../../utils"
import styled from "@emotion/styled"

const useClasses = makeStyles(() => ({
  banner: {
    backgroundColor: COLORS.BACKGROUND_GREY,
    padding: [14, 32],
    borderTop: ({ isEdition }) => isEdition ? "1px solid #cccccc" : "1px solid transparent"
  }
}))

export const StyledBannerText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "weight"
})(({ weight = 400 }) => {
  return {
    fontSize: 14,
    fontWeight: weight,
    lineHeight: "22px"
  }
})

const ProductionStepsResumeBanner = ({ foodcost, netWeight, isEdition = false }) => {

  const classes = useClasses({ isEdition })

  return (
    <Stack className={classes.banner} direction="row" spacing={2} align="center">
      <Stack direction="row">
        <StyledBannerText weight={500}>
          Foodcost :&nbsp;
        </StyledBannerText>
        <StyledBannerText>
          {roundNumber(foodcost, 3)} €
        </StyledBannerText>
      </Stack>
      <Stack>
        <StyledBannerText>
          /
        </StyledBannerText>
      </Stack>
      <Stack direction="row">
        <StyledBannerText weight={500}>
          Poids net :&nbsp;
        </StyledBannerText>
        <StyledBannerText >
          {roundNumber(netWeight * 1000, 0)} g
        </StyledBannerText>
      </Stack>
    </Stack>
  )
}

export default ProductionStepsResumeBanner