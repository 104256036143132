import React, { Fragment, useState } from "react"
import { makeStyles } from "@mui/styles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ArrowForward from "@mui/icons-material/ArrowForwardIos"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	container: {
		height: "100%"
	},
	panelDetails: {
		padding: 0,
		display: "flex",
		flexWrap: "wrap",
		backgroundColor: COLORS.LIGHT_GREY
	},
	panel: {
		backgroundColor: COLORS.ORDER_BLUE,
		"& .MuiExpansionPanelSummary-expandIcon.Mui-expanded": {
			transform: "rotate(90deg)",
		},
		height: 30,
		paddingLeft: 13,
		paddingRight: 13,
		minHeight: 30,
		"&$panelExpanded": {
			height: 30,
			minHeight: 30,
			paddingLeft: 13,
			paddingRight: 13
		}
	},
	panelExpanded: {},
	expandIcon: {
		fontSize: 14,
		fontWeight: 500
	}
})

const OrderCartListViewSupplier = (props) => {
	const {
		supplierName,
		children = null
	} = props
	
	const classes = useStyles()
	const [ isExpanded, setIsExpanded ] = useState(true)

	return (
		<Fragment>
			<Accordion
				defaultExpanded={ true }
				className={classes.test}
				square
				elevation={ 0 }
				onChange={(event, expanded) => setIsExpanded(expanded)}
				expanded={isExpanded}
			>
				<AccordionSummary
					classes={{root: classes.panel, expanded: classes.panelExpanded}}
					expandIcon={<ArrowForward className={classes.expandIcon} />}
				>
					<Typography>
						{ supplierName }
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.panelDetails}>
					{ children }
				</AccordionDetails>
			</Accordion>
		</Fragment>
	
	)
}

export default OrderCartListViewSupplier
