
import React from "react"
import { useDispatch } from "react-redux"
import dayjs from "dayjs"
import { Button, Grid, Box, Stack } from "@mui/material"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import PackagingExecutionHeader from "../PackagingExecutionHeader"
import PackagingExecutionResume from "../packagingExecutionToValidate/PackagingExecutionResume"
import PSEPETimes from "../../ProductionStepExecutions/preview/PSEPETimes"
import PackagingExecutionSectionsAndWasteForm, { packagingExecutionWasteFormId } from "./PackagingExecutionSectionsAndWasteForm"
import PackagingExecutionPackagingsRecap from "../packagingExecutionDone/PackagingExecutionPackagingsRecap"
import { showPackagingExecutionRead, validatePackagingExecutionRealWaste } from "../../../actions/PackagingExecution/packagingExecution"
import { ACTIVE_KFC_BRANDS } from "../../../utils"

const PackagingExecutionWasteToWeight = ({ packagingExecution, selectedSite, selectedDate }) => {

	const dispatch = useDispatch()

	const onUpdatePackagingRealWaste = (values) => {
		dispatch(validatePackagingExecutionRealWaste(packagingExecution.objectId, values.sections))
	}

	const goToPackagingExecutionRead = () => {
		dispatch(showPackagingExecutionRead(selectedSite.objectId, selectedDate))
	}

	return (
		<>
			<PackagingExecutionHeader
				leftAction={<GenericHeaderReturnButton handleClick={goToPackagingExecutionRead} />}
				subtitle={`${selectedSite.name} - ${dayjs(selectedDate).format("DD/MM/YYYY")}`}
				rightAction={
					<Button
						type="submit"
						form={packagingExecutionWasteFormId}
						variant="contained"
					>
						Terminer le barquettage
					</Button>
				}
			/>
			<Box sx={{ p: 4 }}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<Stack direction="row" spacing={3}>
							<div className="flex1">
								<PackagingExecutionResume
									recipeLabel={packagingExecution.recipeName}
									status={packagingExecution.status}
									packagingLine={packagingExecution.packagingLine?.name}
									expectedPackagingNumber={packagingExecution.expectedPackagingNumber}
									uniqueCode={packagingExecution.uniqueCode}
								/>
							</div>
							<PSEPETimes
								executionObject={packagingExecution} />
						</Stack>
					</Grid>
					<Grid item xs={12} md={12}>
						<PackagingExecutionSectionsAndWasteForm
							sections={packagingExecution.sections}
							handleSubmit={onUpdatePackagingRealWaste}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<PackagingExecutionPackagingsRecap
							packagings={packagingExecution.packagings}
							brands={ACTIVE_KFC_BRANDS}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)

}

export default PackagingExecutionWasteToWeight