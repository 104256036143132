import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import SupplierItemResumeForm from "./SupplierItemResumeForm"
import { getSupplierItemProductType } from "../../actions/Utils/utils"

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    paddingBottom: 40,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: "20px 32px 16px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
  },
  singleValueContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
  },
  valueLabel: {
    color: COLORS.BLUEGREY_700,
    width: 200,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
  },
  stateValue: {
    textTransform: "upperCase",
  }
}))

const SupplierItemResume = (props) => {
  const {
    supplierItem,
    onSaveSupplierItem,
    canEdit
  } = props

  const [productType, setProductType] = useState("")

  useEffect(() => {
    async function fetchSupplierItemProductType() {
        const productTypeResult = supplierItem.productType
            ? await getSupplierItemProductType(supplierItem)
            : ""
        setProductType(productTypeResult)
    }
    fetchSupplierItemProductType()
}, [])

  const classes = useStyles()

  const [openEditModal, setOpenEditModal] = useState(false)

  const handleCloseModal = () => {
    setOpenEditModal(false)
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Résumé de l'article</div>
        {canEdit && <NewEditIcon onClick={() => setOpenEditModal(true)} />}
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Nom de l'article</div>
        <span>{supplierItem.name}</span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Nom pour le fournisseur</div>
        <span>{supplierItem.supplierArticleName}</span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Référence fournisseur</div>
        <span>{supplierItem.supplierArticleId}</span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Disponibilité</div>
        <span>{supplierItem.availabilityAtSupplier ? "En stock chez le fournisseur" : "En rupture de stock chez le fournisseur"}</span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>EAN</div>
        <span>{supplierItem.ean}</span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>
          Prix d'une unité de facturation
        </div>
        <span>
          {supplierItem.units ? `${supplierItem.units.billing.price} €` : ""}
        </span>
      </div>
      <div className={classes.singleValueContainer}>
        <div className={classes.valueLabel}>Prix au kg</div>
        <span>
          {supplierItem.pricePerKg ? `${supplierItem.pricePerKg} €` : ""}
        </span>
      </div>
      {(supplierItem.type === "PACKAGING_CONSUMABLE" ||
        supplierItem.type === "SALABLE_PRODUCT") && (
          <div className={classes.singleValueContainer}>
            <div className={classes.valueLabel}>Type de produit</div>
            <span>
              {productType ? productType : ""}
            </span>
          </div>
        )}

      <SupplierItemResumeForm
        open={openEditModal}
        onClose={handleCloseModal}
        supplierItem={supplierItem}
        onSave={onSaveSupplierItem}
      />
    </Paper>
  )
}

export default SupplierItemResume
