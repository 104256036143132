import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import { Euro } from "@mui/icons-material"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: COLORS.ORANGE_CREDIT_NOTE
    },
    icon: {
        marginRight: 10,
        color: COLORS.ORANGE_CREDIT_NOTE,
        height: 24
    }
})

const BillingCreditNotesHeaderTitle = () => {
    const classes = useStyles()
    
    return (
        <div className={classes.container}>
            <Icon className={classes.icon}>
                <Euro />
            </Icon>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                Facturation et Avoirs
            </Typography>
        </div>
    )
}

export default BillingCreditNotesHeaderTitle
