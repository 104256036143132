import React from "react"

import { Box, Button, Table, TableBody, TableRow } from "@mui/material"

import { COLORS } from "../../../../../utils"
import { StyledTableCell } from "../../../../../components/styled/StyledTableCell"
import KitchenAreasTableHead from "./KitchenAreasTableHead"
import { showKitchenAreaMachines } from "../../../../../actions/Machines/Machines"
import { useDispatch } from "react-redux"

const sx = {
  firstColumn: {
    width: 450,
    pl: 5,
  },
  row: {
    cursor: "pointer",
}
}

const KitchenAreas = ({ site, onEdit }) => {

    const dispatch = useDispatch()

    const handleRowClick = (areaId) => {
        dispatch(showKitchenAreaMachines(areaId))
    }

  return (
    <Box bgcolor={COLORS.GREY_IMAGE_LIST}>
      <Box display="flex" justifyContent="flex-end" sx={{ px: 5, py: 3 }}>
        <Button onClick={onEdit} variant="contained">
          Modifier
        </Button>
      </Box>
      <Box>
        <Table>
          <KitchenAreasTableHead />
          <TableBody>
            {site.kitchenAreas?.map((kitchenArea) => (
              <TableRow key={kitchenArea.kitchenArea.objectId} bgcolor="#fff" sx={sx.row} onClick={() => handleRowClick(kitchenArea.kitchenArea.objectId)}>
                <StyledTableCell align="left" sx={sx.firstColumn}>{kitchenArea.kitchenArea.name}</StyledTableCell>
                <StyledTableCell align="left">{kitchenArea.order}</StyledTableCell>
                <StyledTableCell align="left">{kitchenArea.machinesCount || 0}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default KitchenAreas
