import React from "react"
import { COLORS } from "../../../utils"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  root: {
    color: COLORS.DARK_GREY,
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    width: 160,
    border: `1px solid ${COLORS.GREY_BORDER}`,
    boxSizing: "border-box",
    borderRadius: 10,
    marginRight: 15,
    justifyContent: "flex-end",
    "&.last": {
      marginRight: 0,
    },
    "&.selected": {
      border: `2px solid ${COLORS.BLACK_CARD}`,
      "& > button": {
        color: COLORS.BLACK_CARD,
      },
    },
    "&.error": {
      border: "1px solid #F44259"
    },
    "& > svg": {
      width: 32,
      height: 32,
    },
    "& > img": {
      width: 32,
      height: 32,
    },
  },
  text: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
    minHeight: 32,
    maxHeight: 32,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 14,
    color: "#555",
    lineHeight: "22px",
  },
}))

const CreditNoteCardReason = (props) => {
  const { text, value, icon, selected, onSelected, last, hasError = false } = props
  const classes = useStyles()

  const _onSelected = () => {
    onSelected(value)
  }

  return (
    <div
      className={clsx(classes.root, { selected: selected, last: last, error: hasError })}
      onClick={_onSelected}
    >
      {icon}
      <div className={classes.text}>{text}</div>
    </div>
  )
}

export default CreditNoteCardReason
