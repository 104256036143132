import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
  CircularProgress,
  FormHelperText,
  InputLabel,
  styled,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import { COLORS } from "../../../utils"

import Autocomplete from "@mui/material/Autocomplete"
import { replaceSupplierItemsSchema } from "../../../utils/yupValidators"
import { formatSupplierItemsAutocompleteOptions } from "../../../utils/supplierItemUtils"

const StyledInputLabel = styled(InputLabel)({
  fontWeight: 400,
  marginBottom: 2,
  fontSize: 18
})

const StyledHelperText = styled(FormHelperText)({
  fontSize: 18,
  marginTop: 4,
  fontWeight: 400
})

const useStyles = makeStyles({
  dialog: {
    maxWidth: 680,
    height: 422,
  },
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
})

const initialValues = {
  originalSupplierItem: null,
  replacedSupplierItem: null,
}

export const ReplaceSupplierItemsModal = ({ onClose, open, loading, supplierItems, onConfirm, isProductionSteps = false }) => {
  const formikRef = useRef(null)
  const classes = useStyles()

  const handleConfirm = () => {
    formikRef.current.submitForm()
    if (!formikRef.current.values.originalSupplierItem || !formikRef.current.values.replacedSupplierItem) return
    onClose()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Remplacer un article fournisseur dans toutes les {isProductionSteps ? "fiches étapes de production" : "recettes"}
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={replaceSupplierItemsSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => {
            return (
              <Form>
                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <StyledInputLabel>Indiquez l’article fournisseur que vous souhaitez remplacer : </StyledInputLabel>
                  <Autocomplete
                    id="originalSupplierItem"
                    name="originalSupplierItem"
                    options={formatSupplierItemsAutocompleteOptions(supplierItems)}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => value && setFieldValue("originalSupplierItem", value)}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Article à remplacer"
                        value={values?.originalSupplierItem ? values.originalSupplierItem?.value : ""}
                        error={!!errors.originalSupplierItem}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.originalSupplierItem && (
                    <FormHelperText error>
                      {errors.originalSupplierItem}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <StyledHelperText>Indiquez l’article fournisseur qui le remplace dans toutes les {isProductionSteps ? "fiches étapes de production" : "recettes"} :</StyledHelperText>
                  <Autocomplete
                    id="replacedSupplierItem"
                    name="replacedSupplierItem"
                    options={formatSupplierItemsAutocompleteOptions(supplierItems)}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => value && setFieldValue("replacedSupplierItem", value)}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    error={!!errors.originalSupplierItem}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Article de remplacement"
                        value={values?.replacedSupplierItem ? values.replacedSupplierItem?.value : ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.replacedSupplierItem && (
                    <FormHelperText error>
                      {errors.replacedSupplierItem}
                    </FormHelperText>
                  )}
                </FormControl>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions className="flexRow spaceBetween">
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReplaceSupplierItemsModal
