import leven from "leven"
import FlexSearch from "flexsearch"

// Create a FlexSearch index
const index = new FlexSearch.Document({
  document: {
    id: "objectId",
    index: ["name"], // Fields to index for search
    store: true, // Fields to retrieve in results
  },
  language: "fr", // Language support for tokenization
  tokenize: "forward", // Tokenization for autocompletion
  encoder: "simple", // Simplified text encoding for search
  threshold: 3, // Fuzzy search tolerance
  resolution: 5, // Higher resolution for more accurate results
})

// Add data to the index
export const addDataToIndex = (data) => {
  data.forEach((item) => {
    index.add(item)
  })
}

export const searchIndex = async (query) => {
  try {
    // BUG: puck does not exists in flexseach types
    const result = await index.searchAsync(query, {
      index: ["name"],
      suggest: true,
      pluck: "name", // we only need the name
      enrich: true, // enrich the result format
    })

    return result
  } catch (error) {
    console.error("Error during FlexSearch tokenization:", error)
    return []
  }
}

/**
 * Get autocorrected results using Levenshtein distance
 * e.g: fexsearch => flexsearch
 */
export const suggestIndex = (query, data) => {
  const suggestions = data.filter((item) => {
    const maxDistance = item.name.length - leven(query.toLocaleLowerCase(), item.name.toLocaleLowerCase())
    return maxDistance >= 5
  })
  
  return suggestions
}
