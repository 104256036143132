import moment from "moment"
import {isEqual, cloneDeep} from "lodash"

export function formatGapData(receptionGapData, startDate, endDate) {
	const receptionGapCheckedData = []
	const receptionGapUncheckedData = []
	
	receptionGapData && receptionGapData.forEach(elm => {
		let flawTakenDate = []

		// build event objects to be handle
		elm.events.forEach((event, i) => {
			if (event.date >= startDate && event.date <= endDate) {
				if (!["RECEPTION", "FLAW"].includes(event.type)) return

				const copyEvent = {}
				copyEvent.idHub = elm.hub.objectId
				copyEvent.productType = elm.productType
				copyEvent.hub = elm.hub.name
				copyEvent.uniqueCode = elm.uniqueCode
				copyEvent.idProductStock =  elm.objectId
				copyEvent.indexes = [i]
				copyEvent.commercialTitle = elm.commercialTitle
				copyEvent.checker = ""
				copyEvent.user = event.user.username
				copyEvent.date = moment(event.date).format("DD/MM HH:mm")

				if (event.type === "RECEPTION") {
					copyEvent.gap = event.data.volume - elm.initialVolume

					if (copyEvent.gap !== 0) {
						const flaw = elm.events.filter(elm => (elm.type === "FLAW" && elm.date === event.date))

						if (flaw[0]) {
							copyEvent.reason =
								(flaw[0].data.commentary !== "")?
									`${flaw[0].data.reason} (${flaw[0].data.commentary})`:
									flaw[0].data.reason

							copyEvent.indexes.push(elm.events.findIndex(elm => JSON.stringify(elm) === JSON.stringify(flaw[0])))
							flawTakenDate.push(flaw[0].date)
						}

						// only reception with gap
						if (event.checked) {
							const checkedDate = moment(event.checked.date).format("DD/MM HH:mm")
							copyEvent.checker = `${event.checked.user.username} - ${checkedDate}`
							copyEvent.comment = (event.checked.comment)?event.checked.comment:""

							if (!eventExist(receptionGapCheckedData, copyEvent) && !eventExist(receptionGapUncheckedData, copyEvent)) {
								receptionGapCheckedData.push(copyEvent)
							}
						}

						if (!event.checked &&
							!eventExist(receptionGapUncheckedData, copyEvent) &&
							!eventExist(receptionGapCheckedData, copyEvent)
						) {
							receptionGapUncheckedData.push(copyEvent)
						}
					}
				}

				if (event.type === "FLAW" && !flawTakenDate.includes(event.date)) {
					copyEvent.gap = - event.data.volume
					copyEvent.reason =
						(event.data.commentary !== "")?
							`${event.data.reason} (${event.data.commentary})`:
							event.data.reason
					copyEvent.pureFlaw = true

					if (event.checked) {
						const checkedDate = moment(event.checked.date).format("DD/MM HH:mm")
						copyEvent.checker = `${event.checked.user.username} - ${checkedDate}`
						copyEvent.comment = (event.checked.comment)?event.checked.comment:""

						if (!eventExist(receptionGapCheckedData, copyEvent) && !eventExist(receptionGapUncheckedData, copyEvent)) {
							receptionGapCheckedData.push(copyEvent)
						}
					}

					if (!event.checked &&
						!eventExist(receptionGapUncheckedData, copyEvent) &&
						!eventExist(receptionGapCheckedData, copyEvent)
					) {
						receptionGapUncheckedData.push(copyEvent)
					}
				}
			}
		})
	})

	return [receptionGapUncheckedData, receptionGapCheckedData]
}

export function sortOnGap(data) {
	return data.sort((a,b) => (Math.abs(a.gap) < Math.abs(b.gap)) ? 1 : ((Math.abs(b.gap) < Math.abs(a.gap)) ? -1 : 0))
}

function eventExist(data, event) {
	const exist = data.filter(elm => {
		const copyElm = cloneDeep(elm)
		const copyEvent = cloneDeep(event)

		copyElm.indexes = copyEvent.indexes = []
		copyElm.checked = copyEvent.checked = null
		copyElm.checker = copyEvent.checker = ""

		return isEqual(copyElm, copyEvent)
	})

	return exist.length
}