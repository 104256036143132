import React, { useState } from "react"
import { Box, Table, TableBody, TableHead, TableRow, IconButton } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import { useDispatch, useSelector } from "react-redux"
import DeleteIcon from "@mui/icons-material/Delete"

import MachineKitchenAreaModal from "./MachineKitchenAreaModal"
import { addKitchenAreaMachine, deleteKitchenAreaMachine, editKitchenAreaMachine, saveKitchenAreaMachines } from "../../../../../actions/Site/machines"
import { getKitchenAreaSelector } from "../../../../../reducers/Site/sites"
import EditionPageHeader from "../../../../../components/EditionPageHeader"
import { StyledTableCell } from "./StyledTableCell"
import { COLORS } from "../../../../../utils"
import FixedAddButton from "../../../../../components/FixedAddButton"
import { EDITION_PAGE_INITIAL_ACTIONS } from "../../../../../utils/constant"

const COL_WIDTH = 100 / 5 + "%"
const sx = {
    searchAndEdit: {
        paddingLeft: 6,
        paddingBottom: 3,
        paddingTop: 1,
        paddingRight: 6,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline"
    },
    genericHeaderContainer: {
        height: 64,
        width: "100%",
    },
    firstColumn: {
        width: COL_WIDTH,
        pl: 5,
    },
    column: {
        width: COL_WIDTH
    },
    row: {
        cursor: "pointer",
    },
    deleteIcons: {
        color: COLORS.DARK_GREY,
        cursor: "pointer",
        fontSize: 15
    },
    deleteIconColumn: {
        minWidth: "80px"
    },
    cancelButton: {
        paddingRight: 4
    },
    deleteIconsHeadCell: {
        color: COLORS.WHITE,
        fontSize: 20
    },
    addButton: {
        position: "absolute",
        left: 30,
        bottom: 10
    },
}


const KitchenAreaMachinesEdit = ({ machines, onCancel }) => {
    const dispatch = useDispatch()
    const [lineIndexWithMouse, setLineIndexWithMouse] = useState(-1)
    const [openCreationDialog, setOpenCreationDialog] = useState(false)
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [actions, setActions] = useState(EDITION_PAGE_INITIAL_ACTIONS)

    const kitchenArea = useSelector(getKitchenAreaSelector)

    const toggleDialog = () => setOpenCreationDialog(!openCreationDialog)

    const handleRowClick = (machine) => setSelectedMachine(machine)
    const clearSelectedMachine = () => setSelectedMachine(null)

    const handleDeleteRow = (id) => (e) => {
        e.stopPropagation()
        dispatch(deleteKitchenAreaMachine(id))
        setActions(prev => ({ ...prev, hasDeleted: true }))
    }

    const onSubmitModal = (values) => {
        if (selectedMachine) {
            dispatch(editKitchenAreaMachine(selectedMachine.objectId, values))
            setActions(prev => ({ ...prev, hasEdited: true }))
            clearSelectedMachine()
            return
        }

        dispatch(addKitchenAreaMachine(values))
        setActions(prev => ({ ...prev, hasAdded: true }))
    }

    const onSaveAll = async () => {
        // just close if nothing are changed
        const hasChanged = Object.values(actions).some(value => value)
        if (!hasChanged) {
            onCancel()
            return
        }
        // save all modification to the database
        await dispatch(saveKitchenAreaMachines(kitchenArea.objectId, actions))
        onCancel()
    }

    return (
        <>
            <Box>
                <EditionPageHeader
                    icon={<SettingsIcon />}
                    onCancel={onCancel}
                    onSave={onSaveAll}
                    title="Réglages - Sites"
                    subtitle={kitchenArea?.name}
                    color={COLORS.HEAD_SEAZON}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={sx.column}>Nom de la machine</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Modèle</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Type de machine</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Capacité max <br></br>(en kg)</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Temps de nettoyage<br></br> entre 2 utilisations <br></br>(en min)</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.column}>Taux de disponibilité (en %)</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.deleteIconColumn}>&nbsp;&nbsp;</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {machines.map((machine, index) => (
                            <TableRow
                                key={machine.objectId}
                                bgcolor="#fff"
                                sx={sx.row}
                                onMouseEnter={() => setLineIndexWithMouse(index)}
                                onMouseLeave={() => setLineIndexWithMouse(-1)}
                                onClick={() => handleRowClick(machine)}
                            >
                                <StyledTableCell align="left" sx={sx.column}>{machine.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineType?.name}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.capacity}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.cleanTime}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.column}>{machine.machineModel.availabilityRate}</StyledTableCell>
                                <StyledTableCell align="left" sx={sx.deleteIconColumn}>

                                    {lineIndexWithMouse === index
                                        ? (
                                            <IconButton
                                                aria-label="delete"
                                                sx={{ pointer: "cursor" }}
                                                onClick={handleDeleteRow(machine.objectId)}
                                            >
                                                <DeleteIcon sx={{ fontSize: 22 }} />
                                            </IconButton>
                                        )
                                        : <span>&nbsp;&nbsp;&nbsp;</span>
                                    }
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <FixedAddButton onClick={toggleDialog} />

                <MachineKitchenAreaModal
                    open={!!selectedMachine || openCreationDialog}
                    onClose={selectedMachine ? clearSelectedMachine : toggleDialog}
                    onConfirm={onSubmitModal}
                    machine={selectedMachine}
                />
                {/* <EditMachineKitchenArea open={openEditDialog} onClose={handleRowClick} /> */}
                {/*onConfirm={onSubmit}*/}
            </Box>
        </>
    )
}


export default KitchenAreaMachinesEdit