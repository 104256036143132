import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FieldArray, Formik, Form } from "formik"
import { Button, Stack, Table, TableHead, TableBody, TableCell, TableRow } from "@mui/material"
import dayjs from "dayjs"

import PackagingExecutionHeader from "./PackagingExecutionHeader"
import PackagingExecutionStatus from "./PackagingExecutionStatus"
import FormikErrorMessage from "../../components/FormikErrorMessage"
import { PE_STATUSES_AND_LABELS } from "../../utils/packagingExecutions"
import { getPackagingExecutionSiteSelector, getPackagingExecutionDateSelector, getPackagingExecutionsSelector } from "../../reducers/Packaging/packagingExecution"
import {
	showPackagingExecutionRead,
	updatePackagingExecutions
} from "../../actions/PackagingExecution/packagingExecution"
import { formatPackagingExecutionsInitialValues } from "../../utils/packagingExecutions"
import HoursMaskedField from "../../components/form/MaskedField"
import PEPackagingLineSelect from "./PEPackagingLineSelect"
import { packagingExecutionsValidationSchema } from "../../utils/yupValidators"

const getPackagingLineOptionsBySite = (site) => {
	if (!site.packagingLines) return []

	return site.packagingLines.map(packagingLine => ({
    key: packagingLine.name,
		value: packagingLine.objectId,
		label: packagingLine.name
	}))
}

const tableHeaders = [
	{
		key: "theoreticalTime",
		label: "Heures théoriques"
	},
	{
		key: "recipe",
		label: "Recette concernée"
	},
	{
		key: "packagingLine",
		label: "Ligne de barquettage"
	},
	{
		key: "expectedPackagingNumber",
		label: "Nb théorique de produits barquettés"
	},
	{
		key: "status",
		label: "État du barquettage"
	}
]

const PackagingExecutionHoursEdit = () => {
	const dispatch = useDispatch()

	const formRef = useRef(null)

	const selectedSite = useSelector(getPackagingExecutionSiteSelector)
	const selectedDate = useSelector(getPackagingExecutionDateSelector)
	const packagingExecutions = useSelector(getPackagingExecutionsSelector)

	const [packagingExecutionsInitialValues, setPackagingExecutionsInitialValues] = useState([])

	const goToPackagingExecutionRead = () => {
		dispatch(showPackagingExecutionRead(selectedSite.objectId, selectedDate))
	}

	useEffect(() => {
		const initialValues = formatPackagingExecutionsInitialValues(packagingExecutions)
		setPackagingExecutionsInitialValues(initialValues)
	}, [packagingExecutions])

	const _renderStatus = (productionStep) => {
		const statusName = productionStep.status
		const statusValue = PE_STATUSES_AND_LABELS.find(elem => elem.label === statusName)?.key
		return <PackagingExecutionStatus statusValue={statusValue} statusName={statusName} />
	}

	const handleSubmit = async (values) => {
		if (!values.packagingExecutions) return
		await dispatch(updatePackagingExecutions(values.packagingExecutions))
		dispatch(showPackagingExecutionRead(selectedSite.objectId, selectedDate))
	}

	return (
		<>
			<PackagingExecutionHeader
				subtitle={`${selectedSite.name} - ${dayjs(selectedDate).format("DD/MM/YYYY")}`}
				rightAction={
					<Stack direction="row" spacing={2}>
						<Button
							variant="outlined"
							onClick={goToPackagingExecutionRead}
						>
							ANNULER
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => formRef.current?.submitForm()}
						>
							ENREGISTRER
						</Button>
					</Stack>
				}
			/>
			<Formik
				innerRef={formRef}
				initialValues={packagingExecutionsInitialValues}
				validationSchema={packagingExecutionsValidationSchema}
				initialTouched={{}}
				enableReinitialize
				validateOnBlur={false}
				onSubmit={handleSubmit}
			>
				{({ values, touched, handleChange }) => {
					return (
						<Form>
							<Table>
								<TableHead>
									{tableHeaders.map(({ key, label }) => (
										<TableCell key={key}>{label}</TableCell>
									))}
								</TableHead>
								<TableBody>
									<FieldArray name="packagingExecutions">
										{() => (
											<>
												{values.packagingExecutions?.map((_, index) => (
													<TableRow key={index}>
														<TableCell>
															<Stack direction="row" spacing={1} alignItems="center">
																<Stack>
																	<HoursMaskedField
																		name={`packagingExecutions.${index}.theoreticalStartTime`}
																		value={values.packagingExecutions[index].theoreticalStartTime}
																		onChange={handleChange}
																	/>
																	{touched?.packagingExecutions?.[index]?.theoreticalStartTime && <FormikErrorMessage name={`packagingExecutions.${index}.theoreticalStartTime`} />}
																</Stack>
																<div>-</div>
																<Stack>
																	<HoursMaskedField
																		name={`packagingExecutions.${index}.theoreticalEndTime`}
																		value={values.packagingExecutions[index].theoreticalEndTime}
																		onChange={handleChange}
																	/>
																	{touched?.packagingExecutions?.[index]?.theoreticalEndTime && <FormikErrorMessage name={`packagingExecutions.${index}.theoreticalEndTime`} />}
																</Stack>
															</Stack>
														</TableCell>
														<TableCell>
															{values.packagingExecutions[index].code} - {values.packagingExecutions[index].recipe}    {/** not editable */}
														</TableCell>
														<TableCell>
															<PEPackagingLineSelect
																name={`packagingExecutions.${index}.packagingLine`}
																value={values.packagingExecutions[index].packagingLine}
																onChange={handleChange}
																options={getPackagingLineOptionsBySite(selectedSite)}
															/>
														</TableCell>
														<TableCell>
															{values.packagingExecutions[index].expectedProduction}   {/** not editable */}
														</TableCell>
														<TableCell>
															{_renderStatus(values.packagingExecutions[index])}    {/** not editable */}
														</TableCell>
													</TableRow>
												))}
											</>
										)}
									</FieldArray>
								</TableBody>
							</Table>
						</Form>)
				}}
			</Formik>
		</>
	)
}

export default PackagingExecutionHoursEdit