import React from "react"
import { Table, TableHead, TableBody, TableRow } from "@mui/material"

import { PETableCell, PETableHeaderCell, PETotalTableCell } from "../StyledPackagingExecutionPreviewComponents"
import { ACTIVE_KFC_BRANDS } from "../../../utils"
import { timestampToDate } from "../../../utils"
import { packagingNature } from "../../../utils/packaging"
import { getPackagingsRealNumber, getPackagingsTheoreticalNumber } from "../../../utils/packagingExecutions"

const PackagingExecutionPackagingsRecap = ({ packagings, brands = ACTIVE_KFC_BRANDS }) => {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<PETableHeaderCell align="start">Type de barquette</PETableHeaderCell>
					{brands.map((brand, index) => {
						return (
							<PETableHeaderCell key={index}>
								<img src={brand.image} alt={`${brand.shortname}-logo`} style={{
									width: 40,
									height: 40
								}} />
							</PETableHeaderCell>
						)
					})}
					<PETableHeaderCell>Total théorique ({getPackagingsTheoreticalNumber(packagings)})</PETableHeaderCell>
					<PETableHeaderCell>Total réel ({getPackagingsRealNumber(packagings) || "-"})</PETableHeaderCell>
					<PETableHeaderCell>Heures réelles</PETableHeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{packagings.map((packaging, index) => {
					return (
						<TableRow key={index}>
							<PETableCell align="start">{packagingNature.find(n => n.value === packaging.type)?.label}</PETableCell>
							{brands.map((brand, index) => {
								return (
									<PETableCell key={index} disabled>
										{(packaging.brands || []).find(b => b.brand === brand.name)?.theoreticalNumber || 0}
									</PETableCell>
								)
							})}
							<PETableCell>{packaging.theoreticalNumber}</PETableCell>
							<PETotalTableCell>{packaging.realNumber || 0}</PETotalTableCell>
							<PETableCell>{timestampToDate(packaging.startTime, "HH:mm").concat(" - ", timestampToDate(packaging.endTime, "HH:mm"))}</PETableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)


}

export default PackagingExecutionPackagingsRecap