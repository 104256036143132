import React from "react"
import Drawer from "@mui/material/Drawer"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() =>
    createStyles({
        drawer: {
            width: "43%",
            flexShrink: 0,
        },
        drawerPaper: {
            width: "43%",
            paddingTop: 64
        },
        drawerContainer: {
            overflow: "auto",
        }
    }),
)

const RightSideWrapper = (props) => {
	const { children } = props
    const classes = useStyles()

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
        >
            {children}
        </Drawer>
    )
}


export default RightSideWrapper