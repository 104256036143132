import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import { Chip } from "@mui/material"
import IngredientAllergensForm from "./IngredientAllergensForm"


const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		borderRadius: 5,
		paddingBottom: 40,
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
	},
	titleContainer: {
		display: "flex",
		flex: "1 1",
		justifyContent: "space-between",
		alignItems: "center",
	},
	title: {
		padding: "20px 32px 16px 32px",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: 16,
	},
	singleValueContainer: {
		display: "flex",
		paddingLeft: 32,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 16,
	},
	valueLabel: {
		color: COLORS.BLUEGREY_700,
		width: 200,
		minWidth: 200,
		display: "flex",
		alignItems: "center",
	},
	stateValue: {
		textTransform: "upperCase",
		padding: "0 8px"
	}
}))

const IngredientAllergens = (props) => {
	const { ingredient, onSave } = props
	const classes = useStyles()

	const [openEditModal, setOpenEditModal] = useState(false)

	const handleCloseModal = () => {
		setOpenEditModal(false)
	}

	return (
		<Paper className={classes.container}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>Allergènes</div>
				<div>
					<NewEditIcon onClick={() => setOpenEditModal(true)} />
				</div>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Allergènes</div>
				{
					ingredient && ingredient.allergens && ingredient.allergens.length > 0 ? (
						ingredient.allergens.map((allergen, index) => {
							return (
								<div key={index} className={classes.stateValue}>
									<Chip label={allergen.name} />
								</div>
							)
						})
					) : (
						<div className={classes.stateValue}>Aucun allergène</div>
					)
				}
			</div>
			<IngredientAllergensForm
				open={openEditModal}
				onClose={handleCloseModal}
				ingredient={ingredient}
				onSave={onSave}
			/>

		</Paper>
	)
}

export default IngredientAllergens