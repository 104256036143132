import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import DispatchEnhancedTableHead from "../../../components/table-utils/Dispatch/DispatchEnhancedTableHead"
import SearchIcon from "@mui/icons-material/Search"
import {
	COLORS,
	setSearchValue,
	KFC_BRANDS
} from "../../../utils"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import {
	computeDispatchQuantity,
	getProductDispatchDlc
} from "../../../utils/dispatchUtils"
import { showDispatchDetail } from "../../../actions/Dispatch/dispatch"
import {
	stableSort,
	getSorting
} from "../../../components/table-utils/utils"
import moment from "moment"
import { packagingNature } from "../../../utils/packaging"
import {LunchbagIcon, LunchbagColors} from "../../../icons/LunchbagIcon"

const useClasses = makeStyles(() => ({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 24,
        width: "100%",
        paddingBottom: 20,
        backgroundColor: COLORS.LIGHT_GREY
    },
    searchIcon: {
        marginTop: 15,
        marginRight: 15
    },
    searchFieldInput: {
        width: 250
    },
    idColumn: {
        color: COLORS.BLACK,
        cursor: "pointer",
        fontSize: 24,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    typeColumn: {
        fontSize: 12,
        color: COLORS.BLACK,
        letterSpacing: 0.25,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    dlcColumn: {
        fontSize: 12,
        color: COLORS.BLACK,
        letterSpacing: 0.25,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    waitingColumn: {
        fontSize: 17,
        color: COLORS.BLACK,
        letterSpacing: 0.25,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    selectType: {
        marginLeft: "auto",
        marginRight: 20,
        marginTop: 15,
        width: 200
    },
    error: {
        color: COLORS.RED
    },
    reusable: {
        mask: "url(/img/recycle.svg) no-repeat center",
        width: 11,
        height: 10,
        marginRight: 5,
        marginTop: 2,
        display: "inline-block"
    },
	nameColumn: {
		fontSize: 16,
		color: COLORS.DARK_GREY,
		letterSpacing: 0.25,
		lineHeight: "250%",
		display: "flex",
		alignItems: "center",
	}
}))

const HubDispatchTable = (props) => {
	const {
		storageIndex,
		dispatchData,
		selectedType,
		tableType,
		brand,
		date,
		site,
		canOpen = true,
		showDispatchDetail,
		onProductTypeChange,
		productTypeOptions
	} = props
	const classes = useClasses()

	const order = "asc"
	const orderBy = "type"
	const selected = []
	const [searchFilter, setSearchFilter] = useState(props.searchFilter)
	const [filterDispatchData, setFilterDispatchData] = useState([])
	const [data, setData] = useState([])

	useEffect(() => {
		createDataObject()

		if ("" !== searchFilter) {
			updateSearchValue({ target: { value: searchFilter, noStorageAdd: true } })
		}
	}, [])

	useEffect(() => {
		createDataObject()
	}, [selectedType, dispatchData, searchFilter])

	const createDataObject = () => {
		const finalData = []
		let dispatchDataFilter = []
		let filterData

		if (selectedType !== "ALL") {
			filterData = [].concat(dispatchData.filter(el => el.productType === selectedType))
		}
		else {
			filterData = dispatchData
		}

		if (searchFilter !== null && searchFilter !== "") {
			const regex = new RegExp(searchFilter, "ig")
			dispatchDataFilter = filterData.filter(el => el.commercialTitle.match(regex) !== null || el.uniqueCode.match(regex) !== null)
		}
		else {
			dispatchDataFilter = filterData
		}

		setFilterDispatchData(dispatchDataFilter)

		for (const i in dispatchDataFilter) {
			const currentData = dispatchDataFilter[i]

			const dispatchQuantity = computeDispatchQuantity(currentData)
			const currentProductType = productTypeOptions.find(el => el.type === currentData.productType)

			finalData.push({
				uniqueCode: currentData.uniqueCode,
				idProductDispatch: currentData.objectId,
				idProduct: currentData.idProduct,
				commercialTitle: currentData.commercialTitle,
				productType: currentProductType ? currentProductType.label : "",
				dlc: moment(getProductDispatchDlc(currentData)),
				prod: currentData.prod,
				prodWarehouse: currentData.prodWarehouse,
				kfcStatus: currentData.kfcStatus,
				dispatchQuantity: dispatchQuantity,
				isReusable: currentData.isReusable,
				packaging: (
					currentData.productionItem && currentData.productionItem.packaging &&
					packagingNature.find(el => el.value === currentData.productionItem.packaging).label
				) || "",
				lunchbag: currentData.productionItem?.lunchbag
			})
		}

		setData(finalData)
	}

	const updateSearchValue = (event) => {
		if (event) {
			setSearchFilter(event.target.value)
			if (!event.target.noStorageAdd) setSearchValue(event.target.value, storageIndex)
		}
		else {
			setSearchFilter(null)
		}
	}

	const renderProductType = () => {
		return productTypeOptions && productTypeOptions.map((el, index) => {
			return (
				<MenuItem key={index} value={el.type}>{el.label}</MenuItem>
			)
		})
	}

	const onSelectProductTypeChange = (event) => {
		onProductTypeChange(event.target.value)
	}

	const _showDispatchDetail = (event, id_product_dispatch) => {
		if (canOpen) {
			showDispatchDetail(id_product_dispatch, moment(date).startOf("day").format("YYYY-MM-DD"), site.objectId)
		}
	}

	const isSelected = id => selected.indexOf(id) !== -1

	return (
		<Paper elevation={0} className={classes.root}>
			<div className={classes.searchFieldDiv}>
				<SearchIcon className={classes.searchIcon} />
				<TextField
					variant="standard"
					className={classes.searchFieldInput}
					onChange={updateSearchValue}
					label="Rechercher par nom ou code"
					defaultValue={searchFilter}
				/>
				<Select
					variant="standard"
					className={classes.selectType}
					name="productType"
					label="Type"
					placeholder="Type"
					value={selectedType}
					onChange={onSelectProductTypeChange}
				>
					{renderProductType()}
				</Select>
			</div>
			<div className={classes.tableWrapper}>
				<Table aria-labelledby="tableTitle">
					<DispatchEnhancedTableHead
						tableType={tableType}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						rowCount={filterDispatchData ? filterDispatchData.length : 0}
						brand={brand}
					/>
					<TableBody>
						{stableSort(data, getSorting(order, orderBy))
							.map(n => {
								const isSelectedRow = isSelected(n.idProductDispatch)
								const dlc = n.dlc.isSame(new Date(), "year")
									? n.dlc.format("DD/MM")
									: n.dlc.format("DD/MM/YY")
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={isSelectedRow}
										tabIndex={-1}
										key={n.idProductDispatch}
										selected={isSelectedRow}
									>
										<TableCell className={classes.idColumn} component="th" scope="row">{n.uniqueCode}</TableCell>
										<TableCell className={classes.nameColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>
											{
												n.isReusable &&
												<div
													className={classes.reusable}
													style={{ backgroundColor: KFC_BRANDS.find(el => el.name === brand)["color"] }}
												/>
											}
											{
												n.lunchbag ? (
													<React.Fragment>
														{n.commercialTitle}&nbsp;
														<LunchbagIcon color={LunchbagColors.BLACK}/>
													</React.Fragment>
												) : (
													n.commercialTitle
												)
											}
										</TableCell>
										<TableCell className={classes.typeColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>{n.packaging.toUpperCase()}</TableCell>
										<TableCell className={classes.typeColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>{n.productType.toUpperCase()}</TableCell>
										<TableCell className={classes.dlcColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>{dlc}</TableCell>
										<TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>{n.prod}</TableCell>
										{tableType !== "todo" &&
											<TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>{n.prodWarehouse}</TableCell>
										}
										{tableType !== "todo" &&
											<TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idProductDispatch)}>
												<span className={n.prodWarehouse !== n.dispatchQuantity ? classes.error : ""}>
													{n.dispatchQuantity}
												</span>
											</TableCell>
										}

									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</div>
		</Paper>
	)
}

export default connect(state => {
	return {
		date: state.dispatch.date,
		brand: state.dispatch.brand,
		site: state.dispatch.site,
		productTypeOptions: state.dispatch.productTypeOptions
	}
}, {
	showDispatchDetail
})(HubDispatchTable)
