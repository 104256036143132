import React from "react"
import { Card, Stack } from "@mui/material"
import dayjs from "dayjs"

import PSEItem from "./PSEItem"

const sx = {
	card: {
		width: 293,
		padding: "30px",
		border: "1px solid #E6E6E6",
		background: "#FFF",
		boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
	}
}

const PSEPETimes = ({ executionObject }) => {
	const theoreticalStartTime = executionObject.theoreticalStartTime ? dayjs(executionObject.theoreticalStartTime).format("HH:mm") : ""
	const theoreticalEndTime = executionObject.theoreticalEndTime ? dayjs(executionObject.theoreticalEndTime).format("HH:mm") : ""
	const realStartTime = executionObject.startTime ? dayjs(executionObject.startTime).format("HH:mm") : ""
	const realEndTime = executionObject.endTime ? dayjs(executionObject.endTime).format("HH:mm") : ""

	return (
		<Card sx={sx.card}>
			<Stack spacing={3.8}>
				<PSEItem
					label="Heures théoriques"
					value={`${theoreticalStartTime} - ${theoreticalEndTime}`}
				/>
				<PSEItem
					label="Heures réelles"
					value={`${realStartTime} - ${realEndTime}`}
					isRealValue
				/>
			</Stack>
		</Card>
	)
}

export default PSEPETimes