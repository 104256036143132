import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { makeStyles } from "@mui/styles"
import { Modal } from "@mui/material"
import {
	getProductionReceptionProductionDateSelector,
	getProductionReceptionSiteSelector,
	getProductionReceptionStockZoneSelector, getProductionReceptionProductionSupplySelector
} from "../../reducers/ProductionReception/ProductionReception"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ProductionReceptionHeaderTitle from "./ProductionReceptionHeaderTitle"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import dayjs from "dayjs"
import {Box, FormControlLabel, Radio, RadioGroup} from "@mui/material"
import Typography from "@mui/material/Typography"
import CommentIcon from "@mui/icons-material/Comment"
import TextField from "@mui/material/TextField"
import {generateOrderUnitLabel} from "../../utils/ordersUtils"
import {checkTablet, COLORS} from "../../utils"
import Button from "@mui/material/Button"
import {
	showProductionReception,
	updateProductionReceptionToReceive
} from "../../actions/ProductionReception/ProductionReception"
import ProductionReceptionWarningModal from "./ProductionReceptionWarningModal"
import ProductionReceptionHeaderInfo from "./Header/ProductionReceptionHeaderInfo"

const DEFAULT_QUANTITY_COLOR = "#2196F3"
const QUANTITY_OK_COMPLIANT_NOT_OK = "Les lots ne correspondent pas aux sorties réalisées. Êtes-vous sûr de vouloir valider la non conformité des lots ?"
const QUANTITY_NOT_OK_COMPLIANT_NOT_OK = "La quantité et les lots ne correspondent pas aux sorties réalisées. Êtes-vous sûr de vouloir valider la quantité saisie et la non conformité des lots ?"
const QUANTITY_NOT_OK_COMPLIANT_OK = "La quantité ne correspond pas aux sorties réalisées. Êtes-vous sûr de vouloir valider la quantité ?"

const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		flex: 1,
		justifyContent: "space-between"
	},
	title: {
		color: "#262626",
		fontSize: 32
	},
	tab: {
		paddingLeft: 45,
		paddingRight: 45,
	},
	separator: {
		borderRight: "1px solid #F0F0F0",
		verticalAlign: "middle",
	},
	quantityLabel: {
		fontWeight: 400,
		fontSize: 20,
		color: "#262626"
	},
	input: {
		width: 68,
		fontSize: 40,
		textAlign: "center",
		padding: 0,
		color: DEFAULT_QUANTITY_COLOR
	},
	inputNotEqual: {
		width: 68,
		fontSize: 40,
		textAlign: "center",
		padding: 0,
		color: COLORS.RED_ERROR
	},
	defaultInput: {
		width: 68,
		fontSize: 40,
		textAlign: "center",
		padding: 0,
		color: "#CCCCCC"
	},
	list: {
		textAlign: "center",
		width: 300,
		justifyContent: "center",
		alignSelf: "center",
		fontSize: 14,
		color: "#262626",
		paddingTop: 5
	},
	itemList: {
		textAlign: "left",
		paddingLeft: 5
	},
	complianceError: {
		fontSize: 14,
		color: "#F44259"
	},
	quantityOutputError: {
		fontSize: 14,
		color: "#F44259"
	},
	inputWrapper: {
		color: "#262626"
	}
})

const ProductionReceptionToReceive = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const productionSupply = useSelector(getProductionReceptionProductionSupplySelector)
	const selectedSite = useSelector(getProductionReceptionSiteSelector)
	const selectedStockZone = useSelector(getProductionReceptionStockZoneSelector)
	const productionDate = useSelector(getProductionReceptionProductionDateSelector)

	const [quantityOutputValue, setQuantityOutputValue] = useState(null)
	const [isCompliant, setIsCompliant] = useState(null)
	const [complianceError, setComplianceError] = useState(false)
	const [quantityOutputError, setQuantityOutputError] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const isTablet = checkTablet()

	const updateOutputValue = (e) => {
		const value = e.target.value ? parseInt(e.target.value) : null
		setQuantityOutputValue(value)
		if (value >= 0) {
			setQuantityOutputError(false)
		}
	}

	const onChangeCompliance = (e) => {
		setIsCompliant(e.target.value)
		setComplianceError(false)
	}

	const submitProductionReception = async () => {
		if (isCompliant === null) {
			setComplianceError(true)
		}
		if (quantityOutputValue === null) {
			setQuantityOutputError(true)
		}

		if (isCompliant !== null && quantityOutputValue) {
			// HANDLE ALL 4 CASES POSSIBLE
			if (quantityOutputValue === productionSupply.outputQuantity && isCompliant === "OK") {
				/** Quantité OK and conformité OK **/
				await validateProductionReceptionToReceive()
			} else if (quantityOutputValue !== productionSupply.outputQuantity && isCompliant === "OK") {
				/** Quantité NOT_OK and conformité OK **/
				setErrorMessage(QUANTITY_NOT_OK_COMPLIANT_OK)
				setOpenModal(true)
			} else if (quantityOutputValue === productionSupply.outputQuantity && isCompliant === "NOT_OK") {
				/** Quantité OK and conformité NOT_OK **/
				setErrorMessage(QUANTITY_OK_COMPLIANT_NOT_OK)
				setOpenModal(true)
			} else if (quantityOutputValue !== productionSupply.outputQuantity && isCompliant === "NOT_OK") {
				/** Quantité NOT_OK and conformité NOT_OK **/
				setErrorMessage(QUANTITY_NOT_OK_COMPLIANT_NOT_OK)
				setOpenModal(true)
			}
		}
	}

	const validateProductionReceptionToReceive = async () => {
		await dispatch(updateProductionReceptionToReceive(productionSupply.objectId, quantityOutputValue, isCompliant))
		await dispatch(showProductionReception(selectedSite.objectId, selectedStockZone.objectId, productionDate))
	}

	const headerLabel = !isTablet
		? ` - ${selectedSite.name} - ${selectedStockZone.name} - ${dayjs.tz(productionDate).format("DD/MM/YYYY")} - ${productionSupply.supplierItem.name}`
		: ` / ... / ${productionSupply.supplierItem.name}`

	return (
		<div className={classes.wrapper}>
			<GenericTopBarHeader
				leftAction={<GenericHeaderReturnButton handleClick={() => dispatch(showProductionReception(selectedSite.objectId, selectedStockZone.objectId, productionDate))}/>}
				title={<ProductionReceptionHeaderTitle />}
				info={productionSupply && selectedSite && <ProductionReceptionHeaderInfo info={headerLabel}/>}
			/>
			<Box sx={{ textAlign: "right", padding: "24px 24px", color: "#555555" }}>
				<CommentIcon />
			</Box>
			<Box sx={{ justifyContent: "center", textAlign: "center" }}>
				<Typography variant="h6" color="textPrimary" className={classes.title}>
					{productionSupply.supplierItem.name}
				</Typography>
			</Box>
			<Box sx={{ paddingTop: 9, width: 434, alignSelf: "center", justifyContent: "center", textAlign: "center", display: "flex", flexDirection: "row" }}>
				<Box sx={{ width: 235, paddingRight: 6 }}>
					<Typography className={classes.quantityLabel}>
						Quantité sortie
					</Typography>
					<Typography sx={{fontSize: 40, color: "#262626"}}>
						{productionSupply.outputQuantity}
					</Typography>
					<Typography sx={{fontSize: 16, color: "#7C7C7C"}}>
						{generateOrderUnitLabel(productionSupply.supplierItem)} <br />
						= {productionSupply.units.weight * productionSupply.outputQuantity} kg
					</Typography>
				</Box>
				<span className={classes.separator} />
				<Box sx={{ width: 235, paddingLeft: 6 }}>
					<Typography className={classes.quantityLabel}>
						Quantité comptée
					</Typography>
					<TextField
						variant="standard"
						inputProps={{
							className: quantityOutputValue === null ? classes.defaultInput : productionSupply.outputQuantity === quantityOutputValue ? classes.input : classes.inputNotEqual,
							type: "number",
							pattern: "[0-9]*",
						}}
						placeholder={0}
						className={classes.inputWrapper}
						value={quantityOutputValue}
						onChange={updateOutputValue}
						// inputRef={inputRef}
						type="number"
					/>
					<Typography sx={{fontSize: 16, color: "#7C7C7C", paddingTop: 0.5}}>
						{generateOrderUnitLabel(productionSupply.supplierItem)} <br />
						= {quantityOutputValue !== null && (productionSupply.units.weight * quantityOutputValue)} kg
					</Typography>
					{quantityOutputError &&
						(<span className={classes.quantityOutputError}>
							Veuillez indiquer le nombre de colis associé à ce produit.
						</span>)}
				</Box>
			</Box>
			<Box sx={{ marginTop: 8, border: "1px solid #F0F0F0", padding: 3, width: 434, alignSelf: "center", display: "flex", flexDirection: "column" }}>
				<Typography sx={{fontSize: 20, color: "#262626"}}>
					Vérifiez la conformité d’un lot en consultant la liste ci-dessous pour voir s’il s’y trouve :
				</Typography>
				<Box>
					<ul className={classes.list}>
						{productionSupply.lots.map((lot, index) => (
							<li className={classes.itemList} key={index}>{lot.lotNumber} ({dayjs.tz(lot.dlc).format("DD/MM/YYYY")})</li>
						))}
					</ul>
				</Box>
				<RadioGroup sx={{ paddingTop: 3}} row value={isCompliant} onChange={onChangeCompliance}>
					<FormControlLabel
						value={"OK"}
						label={"Conforme"}
						control={<Radio color="primary" />}
					/>
					<FormControlLabel
						value={"NOT_OK"}
						label={"Non conforme"}
						control={<Radio color="primary" />}
						sx={{ paddingLeft: 3 }}
					/>
					{complianceError &&
						(<span className={classes.complianceError}>
							Veuillez indiquer si les lots sont conformes ou non.
						</span>)
					}
				</RadioGroup>
			</Box>
			<Box sx={{ width: "100%", textAlign: "right", marginTop: 10, padding: "30px", borderTop: "1px solid #F0F0F0" }}>
				<Button
					variant="contained"
					color="primary"
					type="submit"
					onClick={submitProductionReception}
					sx={{ height: 48 }}
				>
					VALIDER
				</Button>
			</Box>

			{
				openModal &&
				<Modal
					open={openModal}
					onClose={() => setOpenModal(false)}
					aria-labelledby="form-dialog-title"
				>
					<ProductionReceptionWarningModal
						handleClose={() => setOpenModal(false)}
						handleValidate={() => validateProductionReceptionToReceive()}
						errorMessage={errorMessage}
					/>
				</Modal>
			}
		</div>
	)
}
export default ProductionReceptionToReceive

