import React from "react"
import { Box, Stack } from "@mui/material"

import {
  StyledStepBodyCell,
  StyledStepFirstBodyColumn,
  StyledStepText
} from "../StyledSectionComponents"
import { PRODUCTION_STEPS_COL_WIDTHS, COLS_OFFSETS } from "../../../../utils/constant"
import {COLORS, roundNumber} from "../../../../utils"
import { convertKilosIntoGrams } from "../../../../utils/ressources"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const PriorStepComponentPreview = ({ stepComponent, stepNumbers }) => {
  const currentStepNumber = stepNumbers && stepNumbers[stepComponent.priorSteps.index]
  return (
    <>
      <StyledStepFirstBodyColumn className="flexRow center" leftStep={COLS_OFFSETS.stepComponents} style={{backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE}}>
        <Stack spacing={1}>
          <Box sx={{ height: 30 }} className="flexRow center">
            <Stack direction="row" spacing={1}>
              <StyledStepText weight={0}>{currentStepNumber}.</StyledStepText>
              <StyledStepText weight={0}>{stepComponent.priorSteps.name || "-"}</StyledStepText>
            </Stack>
          </Box>
        </Stack>
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText weight={0}>{roundNumber(convertKilosIntoGrams(stepComponent.grossWeight), 3)}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText weight={0}>
          {"-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText weight={0}>{roundNumber(convertKilosIntoGrams(stepComponent.netWeight), 2)}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledStepText weight={0}>{"-"}</StyledStepText>
      </StyledStepBodyCell>
    </>
  )
}

export default PriorStepComponentPreview
