import React from "react"
import IconCheck from "./Supplier/IconCheck"
import IconClose from "./Supplier/IconClose"

const RoundCheckBoxIcon =  (props) => {
    const {
        checked,
        withMargin
    } =  props

    return (
        checked ?
            <IconCheck bgColor="green" color="white" withMargin={withMargin}/> :
            <IconClose bgColor="grey" color="white" withMargin={withMargin}/>
    )
}

export default RoundCheckBoxIcon