import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import {COLORS} from "../../utils"

const useStyles = makeStyles(() => ({
    dialog: {
        textAlign: "justify",
        color: COLORS.GREY_SEARCH,
        fontSize: 16,
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.1)"
        }
    },
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    title: {
        fontWeight: 500,
        fontSize: 17,
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0,
    },
    dialogTitleLabel: {
        fontWeight: 500,
        marginTop: 5,
        fontSize: 20,
        lineHeight: "24px",
        width: 596
    }
}))

const GrossWeightChangedModal = (props) => {
    const {
        open,
        onClose,
        part,
        accept
    } = props

    const classes = useStyles()

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.dialog}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{root: classes.dialogTitle}}>
                <span className={classes.dialogTitleLabel}> Attention :</span>
            </DialogTitle>
            <DialogContent>
                <p>
                    Ceci va modifier les poids brutes sur les ingredients.
                </p>
            </DialogContent>
            <DialogActions classes={{root: classes.rootDialogActions}}>
                <Button
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={e => accept(e, part)}
                >
                    Accepter
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default GrossWeightChangedModal
