import React, { useLayoutEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import moment from "moment"
import { DatePicker } from "@mui/x-date-pickers"
import { COLORS } from "../../../utils"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"

const useStyles = makeStyles(() => ({
	input: {
		fontSize: 16,
		width: 200,
		textAlign: "left"
	},
	dateInput: {
		fontSize: 16,
		width: 144,
		textAlign: "left"
	},
	selectInput: {
		fontSize: 16,
		width: 200,
		textAlign: "left"
	},
	inputLabel: {
		textAlign: "center",
		fontSize: 20,
		marginBottom: 10,
		textTransform: "uppercase"
	},
	firstBloc: {
		marginTop: 50,
	},
	bloc: {
		marginTop: 70,
	},
	btnBloc: {
		textAlign: "center",
		marginTop: 162,
		"@media (max-height: 760px)": {
			marginTop: 62
		}
	},
	firstBtn: {
		width: 219
	},
	error: {
		fontSize: 16,
		color: COLORS.RED_ERROR
	},
	selectInputLabel: {
		fontSize: "0.9em"
	}
}))

const EditLotBloc = (props) => {
	const {
		orderSupplierItem,
		stockZones,
		currentLot,
		editLot,
		site
	} = props
	
	useLayoutEffect(() => {
	}, [orderSupplierItem])
	
	const classes = useStyles()

	const [lotNumber, setLotNumber] = useState(currentLot.lotNumber)
	const [selectedStockZone, setSelectedStockZone] = useState(orderSupplierItem && orderSupplierItem.defaultStockZone ? orderSupplierItem.defaultStockZone.objectId : stockZones[0].objectId)
	const [isDlcValid, setIsDlcValid] = useState(moment(currentLot.dlc)._isValid)
	const [disableCta, setDisableCta] = useState(true)
	const [dlc, setDlc] = useState(currentLot.dlc)

	useLayoutEffect(() => {
		if ((dlc && !isDlcValid) || selectedStockZone === "") {
			setDisableCta(true)
		} else {
			setDisableCta(false)
		}

	},[isDlcValid, lotNumber, selectedStockZone])

	useLayoutEffect(() => {
		if (stockZones.filter(zone => zone.objectId === selectedStockZone).length === 0) {
			setSelectedStockZone(stockZones[0].objectId)
		}
	}, [stockZones])

	const onDlcChange = (date) => {
		setIsDlcValid(date ? date._isValid : false)
		setDlc(moment(date).valueOf())
	}
	
	const renderStockZones = () => {
		return site.stockZones.map((stockZone, i) =>
			<MenuItem key={i} value={stockZone.objectId}>{stockZone.name}</MenuItem>
		)
	}

	const confirm = async () => {
		const dlcPlus10Days =  moment.utc(moment(currentLot.receptionDate).add(10, "days")).valueOf()

		await editLot({
			id: currentLot.objectId,
			orderSupplierItem,
			lotNumber: lotNumber || `Reçu le ${moment(currentLot.receptionDate).format("DD/MM/YYYY")}`,
			dlc: dlc || dlcPlus10Days,
			quantity: currentLot.quantity,
			stockZone: site.stockZones.find(el => el.objectId === selectedStockZone),
			receptionDate : currentLot.receptionDate,
			events: currentLot.events
		})
	}
	
	return (
		<div className={classes.headBloc}>
			<div className={classes.firstBloc}>
				<TextField
					variant="standard"
					label="Lot"
					name="lotNumber"
					onChange={(e) => {setLotNumber(e.target.value)}}
					value={lotNumber}
					placeholder="00"
					inputProps={{
						className: classes.input
					}}
				/>
			</div>
			<div className={classes.bloc}>
				<DatePicker
					disableCloseOnSelect={false}
					value={dlc}
					inputFormat="DD/MM/YYYY"
					onChange={onDlcChange}
					invalidDateMessage="Dlc invalide"
					minDateMessage="Dlc invalide"
					maxDateMessage="Dlc invalide"
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label="DLC"
							placeholder="XX/XX/XXXX"
						/>
					)}
				/>
			</div>
			<div className={classes.bloc}>
				<InputLabel className={classes.selectInputLabel}>
					Zone de stockage
				</InputLabel>
				<Select
					variant="standard"
					name="stockZone"
					onChange={(e) => {setSelectedStockZone(e.target.value)}}
					value={selectedStockZone}
					className={classes.selectInput}
				>
					{
						renderStockZones()
					}
				</Select>
				{
					!selectedStockZone &&
						<p className={classes.error}>
							Zone de stockage requise
						</p>
				}
			</div>
			<div className={classes.btnBloc}>
				<Button
					variant="contained"
					color="primary"
					className={classes.firstBtn}
					onClick={() => {confirm()}}
					disabled={disableCta}
				>
					Sauvegarder les modifications
				</Button>
			</div>
		</div>
	)
}

export default EditLotBloc
