import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import {COLORS} from "../../utils"
import TableBody from "@mui/material/TableBody"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import {TableCell} from "@mui/material"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import _cloneDeep from "lodash/cloneDeep"
import DeleteIcon from "@mui/icons-material/Delete"
import {getLabelIndex} from "../../utils/supplierItemUtils"

const useStyles = makeStyles(() => ({
	container: {
		fontSize: 20,
		width: "auto",
		minWidth: 500,
		height: "auto",
		padding: 20
	},
	title: {
		margin: "10px 0",
		fontSize: 20,
		fontWeight: "bold"
	},
	titleContainer: {
		display: "flex",
		flex: "1 1",
		justifyContent: "space-between",
		alignItems: "center",
	},
	deleteIcon: {
		cursor: "pointer",
	},
	subtitle: {
		margin: "10px 0",
		fontSize: 18,
		color: COLORS.DARK_GREY
	},
	selectContainer: {
		width: 200,
		marginBottom: 20
	},
	buttonsContainer: {
		marginTop: 50,
		width: "100%",
		display: "flex",
		justifyContent: "space-between"
	},
	selectField: {
		width: "100%"
	},
	addSubstitution: {
		width: 48,
		color: COLORS.BLUE_CREDIT_TOOLBAR
	},
	blockAddSubstitution: {
		color: COLORS.BLUE_CREDIT_TOOLBAR,
		cursor: "pointer"
	},
	checkboxLabel: {
		color: COLORS.DARK_GREY,
		fontSize: 16
	},
	errorField: {
		color: COLORS.RED
	},
}))

const SupplierItemSubstitutionForm = (props) => {
	const {
		supplierItem,
		supplierItemList,
		onUpdateSubstitutionList,
		onClose,
	} = props
	
	const classes = useStyles()
	const [substitutionList, setSubstitutionList] = useState(supplierItem.substitutionList ? supplierItem.substitutionList : [])

	const submit = async () => {
		const oldSubstitutionList = _cloneDeep(substitutionList)
		let hasErrors = false
		let hasDuplicateError = false
		const listItemIds = [] // to avoid same supplier items ids in substitution
		oldSubstitutionList.forEach(sub => {
			if (sub.objectId === "") {
				sub.hasError = true
				hasErrors = true
			} else {
				if (listItemIds.length > 0 && listItemIds.findIndex(elm => elm === sub.objectId) !== -1) {
					sub.hasDuplicateError = true
					hasDuplicateError = true
				} else {
					sub.hasDuplicateError = false
					listItemIds.push(sub.objectId)
				}
			}
		})

		if (hasErrors || hasDuplicateError) {
			setSubstitutionList(oldSubstitutionList)
		} else {
			supplierItem.substitutionList = oldSubstitutionList
			await onUpdateSubstitutionList(supplierItem)
			onClose()
		}
	}

	const formatAutocompleteOptions = () =>
		supplierItemList.map((supplierItem) => ({
			label: supplierItem.objectId + " - " + supplierItem.name,
			value: supplierItem.objectId,
			data: supplierItem,
		}))

	const handleTransformChange = (substitutionItem) => {
		const oldSubstitutionData = _cloneDeep(substitutionList)
		const indexToChange = substitutionList.findIndex(elm => elm.objectId === substitutionItem.objectId)
		oldSubstitutionData[indexToChange].useTransform = !substitutionList[indexToChange].useTransform

		setSubstitutionList(oldSubstitutionData)
	}

	const handleSubstitutionChoice = (substitutionItem, value, index) => {
		const oldSubstitutionData = _cloneDeep(substitutionList)
		oldSubstitutionData[index].objectId = value.value
		oldSubstitutionData[index].label = value.label
		oldSubstitutionData[index].name = value.data.name
		setSubstitutionList(oldSubstitutionData)
	}

	const addSubstitution = () => {
		const oldSubstitutionData = _cloneDeep(substitutionList)
		const newData = {objectId: "", useTransform: false, label: "", name: ""}
		oldSubstitutionData.push(newData)

		setSubstitutionList(oldSubstitutionData)
	}

	const deleteSubstitution = (substitutionItem) => {
		const oldSubstitutionData = _cloneDeep(substitutionList)
		const indexToChange = substitutionList.findIndex(elm => elm.objectId === substitutionItem.objectId)
		oldSubstitutionData.splice(indexToChange, 1)

		setSubstitutionList(oldSubstitutionData)
	}
	return (
		<div className={classes.container}>
			<p className={classes.title}>Articles de substitution</p>
			<p className={classes.subtitle}>
				Ajouter un ou plusieurs articles de substitution. Ces articles remplaceront l’article principal ({supplierItem.name}) si ce dernier est en rupture de stock.
			</p>
			<div>
				<div>
					<Table>
						<TableBody>
							{
								substitutionList.map((substitutionItem, i) => {
									const labelIndex = getLabelIndex(i)
									return (
										<TableRow key={i}>
											<TableCell classes={{root: classes.dispatchSiteCell}}>
												<div className={classes.titleContainer}>
													<p className={classes.title}>{labelIndex} article de substitution</p>
													<DeleteIcon className={classes.deleteIcon} onClick={() => deleteSubstitution(substitutionItem)}/>
												</div>
												<Autocomplete
													name={substitutionItem.name}
													value={substitutionItem}
													options={formatAutocompleteOptions()}
													getOptionLabel={(option) => option.label}
													onChange={(_, value) => value && handleSubstitutionChoice(substitutionItem, value, i)}
													isOptionEqualToValue={(option, value) => {
														return option.value === value.objectId
													}
													}
													renderInput={(params) => {
														return (
															<TextField
																variant="standard"
																{...params}
																name={substitutionItem.name}
																label="Article"
																value={substitutionItem.objectId}
																InputProps={{
																	...params.InputProps,
																}}
															/>
														)}
													}
												/>
												{substitutionItem.hasError && <div className={classes.errorField}>Vous devez sélectionner un supplier item</div>}
												{substitutionItem.hasDuplicateError && <div className={classes.errorField}>Vous avez déjà sélectionner ce supplier item</div>}
												<div className={classes.checkboxLabel}>
													<FormControlLabel
														control={
															<Checkbox
																checked={substitutionItem.useTransform}
																value={substitutionItem.useTransform}
																onChange={() => handleTransformChange(substitutionItem)}
																color="primary"
															/>
														}
														label={`Utiliser les modes de transformation de l'article principal : ${supplierItem.name}`}
														className={classes.checkboxLabel}
														key={substitutionItem.objectId}
													/>
												</div>
											</TableCell>
											<TableCell classes={{root: classes.fridgeInfosButton}}/>
										</TableRow>
									)
								})
							}
						</TableBody>
					</Table>
				</div>
				<div className={classes.blockAddSubstitution} onClick={addSubstitution}>
					<IconButton
						className={classes.addSubstitution}
						size="large">
						<AddCircleOutlineIcon />
					</IconButton>
					Ajouter un article
				</div>
			</div>
			<div className={classes.buttonsContainer}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="button"
					onClick={submit}
				>
					Enregistrer
				</Button>
			</div>
		</div>
	)
}

export default SupplierItemSubstitutionForm