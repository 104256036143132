import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import { useSelector } from "react-redux"
import { Dashboard } from "@mui/icons-material"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        fontWeight: 500,
    },
    inventoryTitle: {
        composes: "$title",
        color: COLORS.SUPPLIER_DELIVERY_COlOR
    },
    outputTitle: {
        composes: "$title",
        color: COLORS.YELLOW_OUTPUT
    },
    inventoryIcon: {
        marginRight: 10,
        color: COLORS.SUPPLIER_DELIVERY_COlOR,
        height: 24
    },
    outputIcon: {
        marginRight: 10,
        color: COLORS.YELLOW_OUTPUT,
        height: 24
    },
})
const LotFilterHeaderTitle = () => {
    const classes = useStyles()
    const mode = useSelector(state => state.lotInventory.mode)
    return (
        <>
            {mode &&
                <div className={classes.container}>
                    {mode === "INVENTORY" ?
                        (
                            <Icon className={classes.inventoryIcon}>
                                <Dashboard />
                            </Icon>
                        ) : (
                            <Icon className={classes.outputIcon}>
                                <Dashboard />
                            </Icon>
                        )}
                    {mode === "INVENTORY" ?
                        (
                            <Typography variant="h7" color="textPrimary" className={classes.inventoryTitle}>
                                Inventaire
                            </Typography>
                        ) : (
                            <Typography variant="h7" color="textPrimary" className={classes.outputTitle}>
                                Sortie
                            </Typography>
                        )
                    }
                </div>
            }
        </>
    )
}

export default LotFilterHeaderTitle