import {
	actionWithLoader,
} from "../Utils/utils"
import { getServerUrl } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"

export function migrateRecipeComments() {
	return actionWithLoader(async () => {
		try {
			const url = getServerUrl() + "/migrations/migrateRecipeCommentsFormat"
			await axiosCall("POST", url, {}, { "Content-Type": "application/json" })
		} catch (e) {
			console.error("Misc recipes.migrateRecipeComments : ", e)
			return Promise.reject(e)
		}
	})
}
