import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import AsyncSelect from "react-select/async"
import { emphasize } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import NoSsr from "@mui/material/NoSsr"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import CancelIcon from "@mui/icons-material/Cancel"
import Parse from "parse"
import { escapeRegExp } from "lodash"

import { parseLimitRequest, COLORS } from "../../utils"
import { supplierItemTypes } from "../../utils/supplierItemUtils"

const suggestions = [
  { label: "Carotte" },
  { label: "Chou-Fleur" },
  { label: "Celeri" },
].map((suggestion) => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const SupplierItems = Parse.Object.extend("SupplierItems")

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 12,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  errorEmpty: {
    margin: 0,
    fontSize: "0.75em",
    textAlign: "left",
    position: "absolute",
    marginTop: 8,
    minHeight: "1em",
    lineHeight: "1em",
    color: COLORS.RED,
  },
})

const loadOptions = async (inputValue, callback) => {
  callback(await filterSupplierItems(inputValue))
}

const filterSupplierItems = async (inputValue) => {
  if (inputValue) {
    const name = new RegExp(escapeRegExp(inputValue), "ig")
    const supplierItems = await new Parse.Query(SupplierItems)
      .equalTo("type", supplierItemTypes.SALABLE_PRODUCT.key)
      .notEqualTo("deleted", true)
      .matches("name", name)
      .limit(parseLimitRequest)
      .find()

    let result = []
    for (const i in supplierItems) {
      const obj = supplierItems[i]
      result.push({
        id: obj.id,
        label: obj.get("name").toLowerCase(),
        pricePerPortion: obj.get("pricePerPortion"),
        supplierName: obj.get("supplierName")
          ? obj.get("supplierName").toLowerCase()
          : "",
        netWeight: obj.get("weightPerPortion"),
        units: obj.get("units"),
      })
    }
    return result
  }

  return []
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control(props) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      elevation={0}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class SupplierItemsAutocomplete extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedValue: null,
    }
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props
    let finalValue = null
    if (value) {
      finalValue = {
        id: value.id,
        label: value.get("name").toLowerCase(),
      }
    }
    this.setState({
      selectedValue: finalValue,
    })
  }

  handleChange = (selectedValue) => {
    const {
      input: { onChange },
    } = this.props
    this.setState({ selectedValue })
    onChange && onChange(selectedValue)
  }

  render() {
    const {
      classes,
      theme,
      input: { name },
      autoFocus,
      meta,
      onKeyDown,
      disabled,
    } = this.props

    const showError =
      ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
      meta.touched
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <AsyncSelect
            name={name}
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            components={components}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onChange={this.handleChange}
            value={this.state.selectedValue || ""}
            placeholder="Nom"
            autoFocus={autoFocus}
            isClearable={true}
            onKeyDown={onKeyDown}
            isDisabled={disabled}
          />
        </NoSsr>
        {showError && (
          <span className={classes.errorEmpty}>
            {meta.error || meta.submitError}
          </span>
        )}
      </div>
    )
  }
}

SupplierItemsAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(
  SupplierItemsAutocomplete
)
