import Parse from "parse"

import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"
import { setValues } from "../../parseUtils"

const PACKAGING_MATERIALS_PROPERTIES = new Set([
    "name", "shortName", "kgCO2PerMaterialTon", "isRecyclableMaterial", "isRecyclableInFrance",
])

export const PackagingMaterials = Parse.Object.extend("PackagingMaterials")

export const loadPackagingMaterials = () => {
    return actionWithLoader(async (dispatch) => {
        const packagingMaterials = await new Parse.Query(PackagingMaterials)
            .notEqualTo("deleted", true)
            .ascending("name")
            .find() || []

        dispatch({
            type: "PACKAGING_MATERIALS_LOADED",
            packagingMaterials: packagingMaterials.map((packagingMaterial) => packagingMaterial.toJSON()),
        })
    })
}

export const createPackagingMaterial = (values) => {
    return actionWithLoader(async (dispatch) => {
        const packagingMaterial = new PackagingMaterials()  

        setValues(packagingMaterial, values, PACKAGING_MATERIALS_PROPERTIES)
        
        const newPackagingMaterial = await packagingMaterial.save()

        dispatch({
            type: "PACKAGING_MATERIAL_ADDED",
            packagingMaterial: newPackagingMaterial.toJSON(),
        })
    })
}

export const updatePackagingMaterial = (id, values) => {
    return actionWithLoader(async (dispatch) => {
        const packagingMaterial = await new Parse.Query(PackagingMaterials).equalTo("objectId", id).first()
        if (!packagingMaterial) {
            throw new Error("Packaging material not found")
        }

        setValues(packagingMaterial, values, PACKAGING_MATERIALS_PROPERTIES)
        
        const updatedPackagingMaterial = await packagingMaterial.save()

        dispatch({
            type: "PACKAGING_MATERIAL_UPDATED",
            packagingMaterial: updatedPackagingMaterial.toJSON(),
        })
    })
}

export const onEnterPackagingMaterials = (store) => {
    return onEnter({
        store,
        actionThunk: loadPackagingMaterials,
        getReplacingPath: getReplacingPath({ needUser: true }),
    })
}

export const showAdministrationPackagingMaterials = () => {
    return push("/settings/administration/packagingMaterials")
}
