import React, { useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import { red } from "@mui/material/colors"
import HeaderMenus from "./HeaderMenus"
import { Table, TableBody, TableCell, TableHead, TableRow, styled } from "@mui/material"

import clsx from "clsx"

const useStyles = makeStyles({
	header: {
		width: "100%",
		backgroundColor: COLORS.BLUEGREY_700,
		height: "160px",
		display: "flex",
		color: COLORS.WHITE,
	},
	supplierItemImage: {
		width: 165,
		height: "100%"
	},
	infosContainer: {
		width: "calc(100% - 245px)",
		paddingLeft: 48,
		paddingTop: 16,
		paddingRight: 16
	},
	commercialNameName: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		fontWeight: 500,
		fontSize: 30,
	},
	subInfosContainer: {
		display: "flex",
		marginTop: 16
	},
	subInfoLabel: {
		fontSize: 12,
		fontWeight: "normal",
		color: COLORS.WHITE
	},
	subInfoValue: {
		fontSize: 16,
		lineHeight: "20px",
		color: COLORS.WHITE,
		marginTop: 8
	},
	modal: {
		height: 50,
		width: 60
	},
	infoRaw: {
		display: "flex"
	},
	deleteAction: {
		padding: "16px"
	},
	IconButton: {
		color: COLORS.WHITE
	},
	activeStateValue: {
		color: COLORS.GREEN_INVENTORY,
	},
	inactiveStateValue: {
		color: red[400],
	},
})

const FIXED_TABLE_HEADERS = [
	{ key: "ingredientsFamily", label: "Famille d'ingrédients" },
	{ key: "ingredientsGroup", label: "Groupe d'ingrédients" },
	{ key: "allergens", label: "Allergènes" },
	{ key: "quantityInRecipes", label: "Nb d'utilisations de l'ingrédient" }
]

const InvisibleTableCell = styled(TableCell)({
	padding: 0, borderBottom: "none", height: "auto", verticalAlign: "top"
})


const Header = (props) => {
	const { commercialName, commercialNameType, menus } = props
	const classes = useStyles()

	const tableHeaders = useMemo(() => {
		const HEADERS = [...FIXED_TABLE_HEADERS]
		const isSubcontractorProduct = commercialNameType === "externe"
		if (isSubcontractorProduct) {
			const dynamicHeader = { "key": "supplier", "label": "Fournisseur" }
			HEADERS.unshift(dynamicHeader)
		}
		return HEADERS
	}, [commercialNameType])


	const tableData = useMemo(() => {
		const isSubcontractorProduct = commercialNameType === "externe"
		const data = [
			{ key: "ingredientsFamily", value: commercialName.group?.family?.name || "" },
			{ key: "ingredientsGroup", value: commercialName.group?.name || "" },
			{ key: "allergens", value: (commercialName.allergens || []).map(elem => elem.name).join(", ") || "" },
			{ key: "quantityInRecipes", value: commercialNameType === "interne" ? (commercialName.recipeQuantity || 0) : (commercialName.productsQuantity || 0) }
		]
		if (isSubcontractorProduct) {
			data.unshift({ key: "supplier", value: commercialName.supplier?.name || "" })
		}
		return data
	}, [commercialName, commercialNameType])

	if (!commercialName) return null

	return (
		<div className={clsx(classes.header, "flexRow spaceBetween")}>
			<img
				src="/img/noImageAvailabled.png"
				alt="Indisponible"
				className={classes.supplierItemImage}
			/>
			<div className={classes.infosContainer}>
				<div>
					<div className={classes.commercialNameName}>
						{commercialName.name.toUpperCase()}
					</div>
				</div>
				<div className={classes.subInfosContainer}>
					<HeaderShadowTable data={tableData} headers={tableHeaders} classes={classes} />
				</div>
			</div>

			{menus && <HeaderMenus menus={menus} />}
		</div>
	)
}

export default Header


const HeaderShadowTable = ({ data, headers, classes }) => {
	return (
		<Table style={{ tableLayout: "fixed" }}>
			<TableHead sx={{borderBottom: `4px ${COLORS.BLUEGREY_700} solid`}}>
				<TableRow>
					{headers.map(header => (
						<InvisibleTableCell key={header.key}><span className={classes.subInfoLabel}>{header.label}</span></InvisibleTableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					{data.map(elem => (
						<InvisibleTableCell key={elem.key}><span className={classes.subInfoValue}>{elem.value}</span></InvisibleTableCell>
					))}
				</TableRow>
			</TableBody>
		</Table>
	)

}