import React from "react"
import PropTypes from "prop-types"
import { MenuItem, ListItemText, Select } from "@mui/material"
import styled from "@emotion/styled"

// styled select
export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => !["customStyles", "isCheckbox", "selectType", "dynamicStyles"].includes(prop)
})(({ customStyles = {} }) => {

  let defaultStyles = {
    "& .MuiInputBase-root, .MuiAutocomplete-inputRoot.MuiInputBase-root": {
      height: "100%",
    },
  }
  const { fontSize, fontWeight = "unset" } = customStyles
  if (fontSize) {
    defaultStyles["& .MuiInput-input, .MuiAutocomplete-input"] = {
      fontSize,
      fontWeight
    }
  }
  return defaultStyles
})

const styles = {
  fontSize: "14px !important",
  fontWeight: "400px !important"
}

// form select field
const FormikSelectField = ({ field, form, ...props }) => {
  const { options  } = props

  return (
    <StyledSelect
      variant="standard"
      {...field}
      {...props}
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.value)
      }}
      customStyles={styles}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <ListItemText primary={option.label}  sx={{
            "& .MuiTypography-body1": styles
          }}/>
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default FormikSelectField

FormikSelectField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  dynamicStyles: PropTypes.object.isRequired
}