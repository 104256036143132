import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"
import {
    roundCurrency,
    roundNumber
} from "../../../utils"
import {
    showNewOrder,
    showUpdateOrder
} from "../../../actions/Orders/Orders"

import ReceptionSnackBar from "../../../components/Reception/ReceptionSnackBar"
import { closeOrderSnackBar } from "../../../actions/Orders/Orders"
import DataGrid from "../../../components/DataGrid/DataGrid"
import FixedAddButton from "../../../components/FixedAddButton"

const OrdersList = (props) => {
    const {
        suppliers,
        ordersData,
        site
    } = props
    const dispatch = useDispatch()
    const orderSnackBar = useSelector(state => state.orders.ordersSnackBar)

    const columns = [
        {
            key: "orderNumber",
            label: "N° de commande",
            dataType: "text",
            sortable: true,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            }
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: suppliers.map((supplier) => ({
                    key: supplier.id,
                    value: supplier.name,
                    label: supplier.name
                }))
            }
        },
        {
            key: "createdAt",
            label: "Date de création",
            dataType: "date",
            sortable: false,
            filter: {
                filterable: true,
                type: "date",
                strict: true
            }
        },
        {
            key: "productAmount",
            label: "Nombre de produits",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            }
        },
        {
            key: "weight",
            label: "Poids",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const amount = value.split(" ")[0]
                const currency = value.split(" ")[1]
                return (
                    <div><span>{amount}</span><span style={{ color: "#7C7C7C" }}>{" " + currency}</span></div>
                )
            }
        },
        {
            key: "amount",
            label: "Montant des produits",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const amount = value.split(" ")[0]
                const euroLabel = value.split(" ")[1]
                return (
                    <div><span>{amount}</span><span style={{ color: "#7C7C7C" }}>{" " + euroLabel}</span></div>
                )
            }
        },
    ]

    const createData = (order) => {
        return {
            orderId: order.objectId,
            createdAt: moment(order.createdAt).format("DD/MM/YYYY"),
            supplier: order.supplier.name,
            orderNumber: order.orderNumber,
            productAmount: order.supplierItems.length,
            weight: order.totalWeight ? `${roundNumber(order.totalWeight)} kg` : "0 kg",
            amount: order.totalAmount ? `${roundCurrency(order.totalAmount)} €` : "0 €"
        }
    }

    const orders = useMemo(() => {
        let orders = cloneDeep(ordersData)

        if (orders.length === 0) {
            return orders
        }

        return orders.map(el => createData(el))
    }, [ordersData])

    const onClickNew = () => {
        dispatch(showNewOrder(site.objectId))
    }

    const onClickOrder = async (order) => {
        await dispatch(showUpdateOrder(site.objectId, order.orderId))
    }

    const onCloseOrderSnackBar = (currentType) => {
        dispatch(closeOrderSnackBar(currentType))
    }

    return (
        <>
            <DataGrid
                title="Passage de commande"
                columns={columns}
                data={orders}
                withFilters
                rowLabel="Passage de commande"
                onRowClick={onClickOrder}
                withAdditionalFiltersOptions={false}
            />
            <FixedAddButton onClick={onClickNew} sx={{ left: 20 }} />
            <ReceptionSnackBar data={orderSnackBar} onClose={onCloseOrderSnackBar} />
        </>
    )
}

export default OrdersList