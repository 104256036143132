import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import {
    showSingleFamilyClassificationIngredientsEdit,
    showSingleFamilyClassificationIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Families/Families"
import FamilyIngredientEnhancedTableToolbar from "../../../../../components/table-utils/Ingredient/FamilyIngredientEnhancedTableToolbar"
import FamilyIngredientEnhancedTableHead from "../../../../../components/table-utils/Ingredient/FamilyIngredientEnhancedTableHead"
import TablePagination from "../../../../../components/TablePagination"
import moment from "moment"
import { TableDateFormat, stableSort, getSorting } from "../../../../../components/table-utils/utils"
import {setSearchValue} from "../../../../../utils"

const styles = {
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    searchFieldInput: {
        width: 300
    },
    column: {
        cursor: "pointer"
    }
}

class FamilyIngredientList extends PureComponent {
    constructor(props) {
        super(props)

        const storage = JSON.parse(localStorage.getItem("searchValue"))
        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            dataPage: 0,
            rowsPerPage: 10,
            searchFilter: (storage && storage.familyIngredientList)?storage.familyIngredientList:null,
            filterFamiliesIngredients: [],
            data: []
        }
    }

    componentDidMount() {
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const { familiesIngredients } = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.familiesIngredients.length !== familiesIngredients.length){
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { familiesIngredients } = this.props
        let familiesIngredientsFilter = []

        if (this.state.searchFilter !== null && this.state.searchFilter !== ""){
            const regex = new RegExp(this.state.searchFilter, "ig")
            familiesIngredientsFilter = familiesIngredients.filter(familyIngredient => familyIngredient.name.match(regex) !== null)
        }
        else {
            familiesIngredientsFilter = familiesIngredients
        }
        this.setState({filterFamiliesIngredients: familiesIngredientsFilter, data: familiesIngredientsFilter.map(this.createData)})
    }

    createData = (familyIngredient) => {
        return {
            id: familyIngredient.objectId,
            name: familyIngredient.name.toLowerCase(),
            createdAt: moment(familyIngredient.createdAt).format(TableDateFormat),
            updatedAt: moment(familyIngredient.updatedAt).format(TableDateFormat)
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterFamiliesIngredients } = this.state
            this.setState({selected: filterFamiliesIngredients.map(familyIngredient => familyIngredient.objectId)})
            return
        }
        this.setState({selected: []})
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected })
    }

    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    updateSearchValue = (event) => {
        if (event) {
            this.setState({searchFilter: event.target.value, dataPage: 0})
            setSearchValue(event.target.value, "familyIngredientList")
        }
        else {
            this.setState({searchFilter: null})
        }
    }

    _showSingleFamilyClassificationIngredients = (event, familyIngredientId) => {
        const { showSingleFamilyClassificationIngredientsRead } = this.props
        showSingleFamilyClassificationIngredientsRead(familyIngredientId)
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    render() {
        const {
            classes,
            familiesIngredients,
            showSingleFamilyClassificationIngredientsEdit
        } = this.props
        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            data,
            dataPage,
            filterFamiliesIngredients
        } = this.state

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper elevation={0} className={classes.root}>
                <FamilyIngredientEnhancedTableToolbar
                    numSelected={selected.length}
                    familiesIngredients={familiesIngredients}
                    createFamilyIngredient={showSingleFamilyClassificationIngredientsEdit}
                    selected={selected}
                />
                <div className={classes.searchFieldDiv}>
                    <TextField
                        variant="standard"
                        defaultValue={this.state.searchFilter}
                        className={classes.searchFieldInput}
                        onChange={this.updateSearchValue}
                        label="Rechercher une famille d'ingrédient"
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <FamilyIngredientEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterFamiliesIngredients.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell className={classes.column} padding="none" onClick={event => this._showSingleFamilyClassificationIngredients(event, n.id)}>{n.name}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleFamilyClassificationIngredients(event, n.id)}>{n.createdAt}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleFamilyClassificationIngredients(event, n.id)}>{n.updatedAt}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterFamiliesIngredients}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />
            </Paper>
        )
    }
}

FamilyIngredientList.propTypes = {
    classes: PropTypes.object.isRequired,
}

FamilyIngredientList = withStyles(styles)(FamilyIngredientList)

export default connect(state => {
    return {
        familiesIngredients: state.familiesIngredients.familiesIngredients
    }
}, {
    showSingleFamilyClassificationIngredientsEdit,
    showSingleFamilyClassificationIngredientsRead
})(FamilyIngredientList)
