import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getLotSelector } from "../../reducers/LotInventory/LotInventory"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import LotFilterHeaderTitle from "../../components/Order/Lot/LotFilterHeaderTitle"
import { Breadcrumbs, Stack, Box } from "@mui/material"
import dayjs from "dayjs"
import LotBanner from "./LotBanner"
import LotEventsTable from "./LotEventsTable"
import { showLotFilter } from "../../actions/LotInventory/LotInventory"
import {LOT_HISTORY_MODE} from "../../utils/lotInventoryUtils"


const LotEventsHistory = () => {

	const lot = useSelector(getLotSelector)
	const dispatch = useDispatch()

	const handleBack = () => {
		dispatch(showLotFilter(LOT_HISTORY_MODE)) //history tab
	}

	return (
		<>
			<div style={{ height: 64 }}>
				<GenericTopBarHeader
					leftAction={
						<GenericHeaderReturnButton handleClick={handleBack} />
					}
					title={
						<Breadcrumbs>
							<LotFilterHeaderTitle mode={"inventoryColor"} />
							<span>Historique des lots</span>
							<span>{lot.supplierItem?.name + " - " + lot.lotNumber + `(${dayjs(lot.dlc).format("DD/MM/YYYY")})`}</span>
						</Breadcrumbs>
					}
				/>
			</div>
			<Stack
				pt={3}
				gap={3}
			>
				<Box
					ml={3}
					mr={3}>
					<LotBanner
						lot={lot}
					/>
				</Box>
				<LotEventsTable
					lot={lot}
				/>
			</Stack>
		</>
	)

}

export default LotEventsHistory