import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import WidgetsIcon from "@mui/icons-material/Widgets"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: COLORS.GREEN_INVENTORY
    },
    icon: {
        marginRight: 10,
        color: COLORS.GREEN_INVENTORY,
        height: 24
    }
})
export const HubsInventoryHeader = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Icon className={classes.icon}>
                <WidgetsIcon />
            </Icon>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                Inventaire Hubs
            </Typography>
            
        </div>
    )
}