import React from "react"

import Recipes from "../Recipe/Recipes"
import RecipesPage from "../Recipe/RecipesPage"
const RecipesTab = () => {
    return (
        <RecipesPage tabValue="recipes">
            <Recipes />
        </RecipesPage>
    )
}

export default RecipesTab