import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS
} from "../../../utils"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { pxTOvw } from "../../../utils/planning"
import PrevisionsPlanningCard from "../PlanningCard/PrevisionsPlanningCard"

const styles = {
    planningColumnContainerDefault: {
        width: pxTOvw((window.innerWidth - 118.4) / 5),
        backgroundColor: COLORS.LIGHT_GREY,
        borderLeft: "1px dashed #E7E7E7",
        borderRight: "1px dashed #E7E7E7",
        paddingLeft: 5,
        paddingRight: 5
    },
    planningColumnContainerValid: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.ENABLED_DRAG
    },
    planningColumnContainerError: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.DISABLED_DRAG
    },
    cardContainer: {
        backgroundColor: COLORS.WHITE,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 4,
        cursor: "pointer",
        position: "relative"
    }
}

const PrevisionsPlanningBody = (props) => {
    const {
        data,
        brand,
        classes,
        currentCard,
        currentColumn,
        openPrevEditModal,
        foldedCards,
        canEdit,
        selectedProductTypes,
        selectedHubs,
        productTypeOptions
    } = props

    const getColumnClasses = (columnId) => {
        if (currentCard){
            if (currentColumn.id === columnId){
                return classes.planningColumnContainerValid
            }
            else {
                return classes.planningColumnContainerError
            }
        }

        return classes.planningColumnContainerDefault
    }

    return (
        data.map((item) => {
            const cards = Array.isArray(item.cards[brand.name])
                ? item.cards[brand.name]
                : []
            return (
                <Droppable key={item.id} droppableId={item.id}>
                    {
                        (provided) => {
                            return (
                                <div
                                    key={item.id}
                                    ref={provided.innerRef}
                                    className={getColumnClasses(item.id)}
                                >
                                    {
                                        cards.filter(el => selectedHubs.includes(el.idHub)).map((card, index) => {
                                            return canEdit
                                                ?
                                                 (
                                                    <Draggable
                                                        key={card.objectId}
                                                        draggableId={card.objectId}
                                                        index={index}
                                                    >
                                                        {
                                                            (provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={classes.cardContainer}
                                                                >
                                                                    <PrevisionsPlanningCard
                                                                        card={card}
                                                                        totalHubs={item.totalCards[brand.name]}
                                                                        color={brand.color}
                                                                        openPrevEditModal={openPrevEditModal}
                                                                        foldedCard={foldedCards}
                                                                        canEdit={canEdit}
                                                                        selectedProductTypes={selectedProductTypes}
                                                                        productTypeOptions={productTypeOptions}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </Draggable>
                                                )
                                                :
                                                    (
                                                        <div
                                                            className={classes.cardContainer}
                                                            key={index}
                                                        >
                                                            <PrevisionsPlanningCard
                                                                card={card}
                                                                totalHubs={item.totalCards[brand.name]}
                                                                color={brand.color}
                                                                openPrevEditModal={openPrevEditModal}
                                                                foldedCard={foldedCards}
                                                                canEdit={canEdit}
                                                                selectedProductTypes={selectedProductTypes}
                                                                productTypeOptions={productTypeOptions}
                                                            />
                                                        </div>
                                                    )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                        }
                    }
                </Droppable>
            )
        })
    )
}

export default withStyles(styles)(PrevisionsPlanningBody)
