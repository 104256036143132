import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
	COLORS,
	roundNumber
} from "../../../utils"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import { generateStockUnitLabel } from "../../../utils/orderReception"

const useStyles = makeStyles({
	flex: {
		display: "flex"
	},
	flexColumn: {
		display: "flex",
		flexDirection: "column"
	},
	root: {
		position: "relative",
		display: "flex",
		height: 85,
		width: 809,
		paddingTop: 8,
		paddingLeft: 24,
		paddingBottom: 8,
		boxShadow: `0px 0px 4px ${COLORS.DELIVERY_NOTE_CARD_SHADOW2}, 0px 4px 4px ${COLORS.DELIVERY_NOTE_CARD_SHADOW2}`
	},
	title: {
		fontWeight: 500,
		fontSize: 16,
		color: COLORS.SITE_INFORMATION_VALUE,
		marginTop: 24,
		marginBottom: 16
	},
	editContainer: {
		position: "absolute",
		right: 0,
		top: 0
	},
	icon: {
		color: COLORS.PRIMARY_COLOR
	},
	divider: {
		borderBottom: `1.5px solid ${COLORS.GREY_FLAW_ICON}`,
		marginRight: 55
	},
	informationTitle: {
		fontSize: 12,
		paddingTop: 8,
		color: COLORS.DELIVERY_NOTE_CARD_TITLE
	},
	informationTitle1: {
		composes: "$informationTitle",
		fontWeight: 500,
		color: COLORS.INFORMATION_TITLE,
		opacity: 0.87
	},
	informationRow: {
		composes: "$flex"
	},
	informationColumn: {
		composes: "$flexColumn",
		minWidth: 266
	},
	informationColumn1: {
		composes: "$flexColumn",
		minWidth: 78,
		paddingRight: 55
	},
	informationValue: {
		fontSize: 14,
		fontWeight: 500
	},
	informationValue1: {
		fontSize: 14,
		color: COLORS.BLACK
	},
})

const DeliveryNoteCard = (props) => {
	const {
		article,
		editSelectedArticle
	} = props

	const classes = useStyles()

	const quantity = article.quantities
		? !!article.quantities.deliveryNote || article.quantities.deliveryNote === 0 
			? article.quantities.deliveryNote
			: article.quantities.reception
		: 0

	const label = generateStockUnitLabel(article.supplierItem)
	const weight = article.supplierItem.units && article.supplierItem.units.stock.weight ? article.supplierItem.units.stock.weight : 0

	return (
        <div>
			<div className={classes.title}>
				{article.supplierItem.name}
				{article.supplierItem.reference && ` - ${article.supplierItem.reference}`
				}
			</div>
			<div className={classes.root}>
				<div className={classes.informationRow}>
					<div className={classes.informationColumn1}>
						<Typography className={classes.informationTitle1}>
							Quantité Bon de Livraison
						</Typography>
						<Typography className={classes.informationValue1}>
							{quantity}
						</Typography>
					</div>
					<Divider
						className={classes.divider}
						orientation="vertical"
						flexItem
					/>
					<div className={classes.informationColumn}>
						<Typography className={classes.informationTitle}>
							{article.deliveryNoteUnitPrice} € / {label}
						</Typography>
						<Typography className={classes.informationValue}>
							{roundNumber(quantity * article.deliveryNoteUnitPrice, 2)} €  au total
						</Typography>
					</div>
					<div className={classes.informationColumn}>
						<Typography className={classes.informationTitle}>
							{weight} Kg / {label}
						</Typography>
						<Typography className={classes.informationValue}>
							{roundNumber(quantity * weight, 2)} Kg  au total
						</Typography>
					</div>
				</div>
				<IconButton
                    className={classes.editContainer}
                    disableRipple={true}
                    onClick={editSelectedArticle}
                    size="large">
					<EditIcon className={classes.icon}/>
				</IconButton>
			</div>
		</div>
    )
}

export default DeliveryNoteCard
