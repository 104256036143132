import React, {
  useState
} from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {makeStyles} from "@mui/styles"
import {COLORS} from "../../utils"
import {confirm} from "../react-await-dialog/react-await-dialog"
import {useSelector} from "react-redux"
import {CAN_DELETE_PRODUCTS, CAN_UPDATE_PRODUCTS} from "../../utils/accessRights"
import CopyRecipeModal from "../CopyRecipeModal"
import {Dialog} from "@mui/material"

const useStyles = makeStyles({
  icon: {
    color: COLORS.WHITE,
    height: "fit-content",
    width: "fit-content",
    marginTop: "8px",
    padding: "4px"
  }
})

// these actions are used for Recipe and SubcontractorProduct
const ProductHeaderActions = (props) => {
    const {
        onClickDelete,
        onClickDuplicate,
        jsonData,
        type = "RECIPE"
    } = props

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
    const [currentSelectedRecipeName, setCurrentSelectedRecipeName] = useState("")
    const canDeleteProducts = useSelector(state => state.app.rights[CAN_DELETE_PRODUCTS])
    const canUpdateProducts = useSelector(state => state.app.rights[CAN_UPDATE_PRODUCTS])
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

  const _deleteData = async () => {
      const message = type === "RECIPE" ? "Voulez-vous supprimer cette recette ?" : "Voulez-vous supprimer ce produit ?"
    if (
      await confirm({
        title: "Confirm",
        message: message,
        confirmText: "SUPPRIMER",
      })
    ) {
      onClickDelete()
    }
  }

  const _copyRecipe = () => {
      setIsCopyModalOpen(!isCopyModalOpen)
      setCurrentSelectedRecipeName(jsonData.name)
  }

    return (
      <span>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.icon}
              size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
              id={id}
              open={Boolean(open)}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted
              transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
              }}
            >
              {canUpdateProducts && onClickDuplicate && <MenuItem onClick={_copyRecipe}>Dupliquer cette fiche</MenuItem>}
              {canDeleteProducts && onClickDelete && <MenuItem onClick={_deleteData}>Supprimer cette fiche</MenuItem>}
            </Menu>
            <Dialog
              open={isCopyModalOpen}
              onClose={_copyRecipe}
              disableEnforceFocus
            >
              <CopyRecipeModal
                currentRecipeName={currentSelectedRecipeName}
                currentRecipeId={jsonData.objectId}
                duplicateRecipe={onClickDuplicate}
              />
            </Dialog>
        </span>

    )
}

export default ProductHeaderActions