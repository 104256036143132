import React, { useRef, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import { Button, Stack, TextField } from "@mui/material"
import dayjs from "dayjs"
import { DatePicker } from "@mui/x-date-pickers"
import { Formik, Form } from "formik"
import { dateToTimestamp } from "../../utils"

const useStyles = makeStyles({
	root: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		textAlign: "center",
		paddingTop: 75,
		paddingBottom: 80
	},
	title: {
		color: "#262626",
		textAlign: "center",
		fontSize: 24,
		fontWeight: 700,
		lineHeight: "32px"
	},
	informations: {
		fontSize: 14
	},
	bold: {
		fontWeight: 700
	},
	buttons: {
		display: "flex",
		flexDirection: "column"
	},
	button: {
		height: 44,
		fontSize: 15,
		margin: 8,
		"&.disabled": {
			opacity: 0.6
		}
	}
})

const SecondaryDLCCartDate = ({ secondaryDLC, onCancel, onValidate }) => {

	const classes = useStyles()

	const formikRef = useRef()

	useEffect(() => {
		formikRef.current && formikRef.current.setValues({
			secondaryDLC: secondaryDLC.secondaryDLC || dayjs().startOf("day").valueOf()
		})
	}, [secondaryDLC])

	return (
		<div className={classes.root}>
			<Stack spacing={3}>
				<div className={classes.title}>
					{secondaryDLC.orderSupplierItem.name}
				</div>
				<div className={classes.informations}>
					<div>
						LOT : <span className={classes.bold}>{secondaryDLC.lotNumber}</span>
					</div>
					<div>
						DLC : <span className={classes.bold}>{dayjs(secondaryDLC.dlc).format("DD/MM/YYYY")}</span>
					</div>
				</div>
				<Stack spacing={2} className={classes.informations}>
					<div>
						Date d'ouverture : <span className={classes.bold}>{dayjs(secondaryDLC.openingDate).format("DD/MM/YYYY")}</span>
					</div>
					<div>
						<Formik
							innerRef={formikRef}
							initialValues={{
								secondaryDLC: secondaryDLC.secondaryDLC || dayjs().startOf("day").valueOf()
							}}
							onSubmit={onValidate}>
							{({ values, errors, setFieldValue }) => (
								<Form>
									<DatePicker
										showToolbar={false}
										value={values.secondaryDLC}
										name="secondaryDLC"
										inputFormat="DD/MM/YY"
										label={"DLC secondaire"}
										disablePast
										renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.secondaryDLC} InputLabelProps={{ shrink: true }} />}
										InputProps={{
											sx: {
												maxWidth: 190
											}
										}}
										onChange={(date) => {
											setFieldValue("secondaryDLC", dateToTimestamp(date))
										}}
									/>
								</Form>)}
						</Formik>
					</div>
				</Stack>
			</Stack>
			<div className={classes.buttons}>
				<Button
					className={classes.button}
					variant="contained"
					color="primary"
					disableElevation
					type="submit"
					onClick={() => formikRef.current?.submitForm()}
				>
					Enregistrer
				</Button>
				<Button
					className={classes.button}
					variant="outlined"
					color="primary"
					disableElevation
					type="submit"
					onClick={onCancel}
				>
					Annuler
				</Button>
			</div>
		</div>
	)
}

export default SecondaryDLCCartDate