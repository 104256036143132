import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import AddAPhoto from "@mui/icons-material/AddAPhoto"
import { COLORS } from "../../../utils"
import { openUploadWidget } from "../../../utils/cloudinary"
import { Chip } from "@mui/material"

const useStyles = makeStyles({
    container: {
        maxWidth: 506
    },
    commentLabel: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "24px",
        color: COLORS.SITE_INFORMATION_VALUE
    },
    commentInput: {
        minWidth: 370
    },
    imagesContainer: {
        marginTop: 33,
        display: "flex",
        "& > div": {
            marginRight: 17
        }
    },
    addImage: {
        width: 102,
        height: 62,
        border: `1px solid ${COLORS.PRIMARY_COLOR}`,
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    addImageIcon: {
        color: COLORS.PRIMARY_COLOR
    },
    imageChip: {
        maxWidth: 120,
        borderRadius: 4,
        height: 32,
        backgroundColor: COLORS.GREY_IMAGE_LIST
    }
})

const CreditNoteComment = (props) => {
    const {
        imageName,
        callback
    } = props

    const classes = useStyles()
    const [images, setImages] = useState([])
    const [comment, setComment] = useState("")

    const handleDeleteImage = (img) => {
        const newImages = [...images]

        const index = newImages.indexOf(img)
        newImages.splice(index, 1)

        setImages(newImages)
        callback({
            method: "UPDATE_IMAGES",
            data: {
                images: newImages
            }
        })
    }
    
    const handleCommentChange = (event) => {
        setComment(event.target.value)
        callback({
            method: "UPDATE_COMMENT",
            data: {
                comment: event.target.value
            }
        })
    }

    const handleAddImage = (images) => {
        openUploadWidget({ tags: ["creditNote"]}, (error, result) => {
            if (error) {
                console.error(error)
                return
            }
            if (result.event !== "success") {
                // we don't use this event
                return
            }

            const publicId = result.info.public_id


            const newImages = [...images, {
                publicId: { publicId }
            }].map((img, key) => {
                img.name = `${imageName}_${key + 1}`

                return img
            })

            setImages(newImages)
            callback({
                method: "UPDATE_IMAGES",
                data: {
                    images: newImages
                }
            })
        })
    }

    return (
        <div className={classes.container}>
            <div>
                <Typography className={classes.commentLabel}>
                    Ajouter un commentaire et des photos (facultatifs)
                </Typography>
                <TextField
                    variant="standard"
                    className={classes.commentInput}
                    label="commentaire"
                    value={comment}
                    onChange={handleCommentChange}
                />
                <div className={classes.imagesContainer}>
                    <div
                        className={classes.addImage}
                        onClick={() => handleAddImage(images)}
                    >
                        <AddAPhoto className={classes.addImageIcon}/>
                    </div>
                    {
                        images.map((img, key) => (
                            <Chip
                                className={classes.imageChip}
                                key={key}
                                label={img.name}
                                onDelete={() => handleDeleteImage(img)}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default CreditNoteComment