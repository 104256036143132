import React, { Fragment } from "react"
import { makeStyles } from "@mui/styles"
import OrderCartListViewSupplier from "./OrderCartListViewSupplier"
import { orderMode } from "../../../utils/ordersUtils"
import OrderCartItem from "./OrderCartItem"

const useStyles = makeStyles({
	container: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	card: {
		width: "100%",
		marginBottom: 3
	}
})

const OrderCartListView = (props) => {
	const {
		cart,
		mode,
		actions,
		isValidOrder,
		order
	} = props
	const classes = useStyles()
	return (
		<div className={classes.container}>
			{
				Array.from(cart).map(([, values], i) => {
					if (values.length > 0){
						const supplier = values[0].card.supplier
						
						if (mode === orderMode.CREATE){
							return (
								<div key={i}>
									<OrderCartListViewSupplier
										supplierName={supplier.name}
										mode={mode}
									>
										{
											values.map((value, j) =>
												<div
													key={j}
													className={classes.card}
												>
													<OrderCartItem
														isValidOrder={isValidOrder}
														key={j}
														card={value}
														actions={actions}
													/>
												</div>
											)
										}
									</OrderCartListViewSupplier>
								</div>
							)
						}
						else {
							return (
								<div key={i}>
									{
										values.map((value, j) =>
											<div
												key={j}
												className={classes.card}
											>
												<OrderCartItem
													order={order}
													isValidOrder={isValidOrder}
													card={value}
													actions={actions}
												/>
											</div>
										)
									}
								</div>
							)
						}
					}
					
					return <Fragment key={i}></Fragment>
				})
			}
		</div>
	)
}

export default OrderCartListView