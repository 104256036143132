import React from "react"
import { Draggable } from "react-beautiful-dnd"

const DraggableWrapper = (props) => {
    const {
        children,
        isDraggable,
        draggableId,
        draggableClass,
        ...restProps
    } = props

    return (
        <>
            {
                isDraggable &&
                <Draggable
                    draggableId={draggableId}
                    {...restProps}
                >
                    {
                        (provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={draggableClass}
                            >
                                {children}

                            </div>
                        )
                    }
                </Draggable>
            }
            {
                !isDraggable &&
                <div
                    key={draggableId}
                    className={draggableClass}
                >
                    {children}
                </div>
            }
        </>
    )
}

export default DraggableWrapper