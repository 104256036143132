import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import { Modal } from "@mui/material"
import { checkTablet, COLORS, KFC_BRANDS } from "../../utils"
import {
  closeLotGroupDetail,
  closeLotGroupSnackBar,
  computeDispatch,
  updateProductPackaging,
} from "../../actions/LotGroup/lotGroup"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { confirm } from "../../components/react-await-dialog/react-await-dialog"
import Header from "../../components/LotGroup/Header"
import InputBlock from "../../components/LotGroup/InputBlock"
import clsx from "clsx"
import _cloneDeep from "lodash/cloneDeep"
import { packagingNature } from "../../utils/packaging"
import moment from "moment"

const useClasses = makeStyles(() => ({
  singleLotGroupModalDetail: {
    backgroundColor: COLORS.WHITE,
    opacity: 1,
    outline: "none",
    overflow: "scroll",
    zIndex: 1300,
    height: "100%",
  },
  divider: {
    border: "1px solid #e8e8e8",
    width: "100%",
  },
  noOutline: {
    outline: "none",
  },
  contentBloc: {
    textAlign: "center",
    marginTop: 65,
    color: COLORS.BLACK,
    fontSize: 27,
    lineHeight: "32px",
  },
  confirmButtonBasic: {
    composes: "flexRow",
    height: 72,
    width: 410,
    fontSize: 20,
    textAlign: "center",
    alignItems: "center",
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 5,
  },
  confirmButtonEmpty: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.DARK_GREY,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  confirmButtonFill: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  buttonText: {
    margin: "0 auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  uniqueCode: {
    fontSize: 34,
  },
  doneIcon: {
    color: COLORS.PRIMARY_COLOR,
    fontSize: 70,
  },
  logo: {
    width: 25,
    marginRight: 10,
  },
}))

const LotGroupDetail = (props) => {
  const {
    date,
    selectedProductPackaging,
    selectedProductDispatch,
    snackBar,
    closeLotGroupSnackBar,
    closeLotGroupDetail,
    computeDispatch,
    updateProductPackaging,
  } = props

  const classes = useClasses()
  const inputRef = useRef(null)
  const [firstPage, setFirstPage] = useState(true)
  const wantedInput = firstPage
    ? selectedProductPackaging &&
      (selectedProductPackaging.warehouseProduction ||
        selectedProductPackaging.packagingProduction ||
        selectedProductPackaging.expectedProduction)
    : selectedProductDispatch && selectedProductDispatch.prod
  const [inputValue, setInputValue] = useState(wantedInput === 0 ? "0" : "")
  const [isTablet, setIsTablet] = useState(checkTablet)

  const packagingType =
    selectedProductPackaging &&
    packagingNature.find(
      (el) => el.value === selectedProductPackaging.packagingType
    ).label
  const currentBrand =
    selectedProductDispatch &&
    KFC_BRANDS.find((el) => el.name === selectedProductDispatch.brand)

  const _closeLotGroupDetail = async (ev) => {
    ev.preventDefault()
    await closeLotGroupDetail(
      selectedProductPackaging,
      selectedProductDispatch
    )
    setTimeout(() => ({}), 15000)
  }
  window.addEventListener("unload", _closeLotGroupDetail, false)

  // set isTablet state
  useLayoutEffect(() => {
    const checkisTablet = () => {
      setIsTablet(checkTablet())
    }

    // title
    document.title = "Allotissement - KFC"

    // check size
    window.addEventListener("resize", checkisTablet)

    // remove check
    return () => window.removeEventListener("resize", checkisTablet)
  }, [])

  useEffect(() => {
    if (
      (!selectedProductPackaging && !selectedProductDispatch) ||
      selectedProductPackaging.allDone
    ) {
      closeLotGroupDetail(selectedProductPackaging, selectedProductDispatch)
    }
  }, [])

  useEffect(() => {
    setFirstPage(selectedProductDispatch === null)

    if (
      selectedProductDispatch === null &&
      selectedProductPackaging &&
      selectedProductPackaging.allDone
    )
      closeLotGroupDetail(selectedProductPackaging, selectedProductDispatch)
  }, [selectedProductDispatch])

  useEffect(() => {
    if (
      (!selectedProductPackaging && !selectedProductDispatch) ||
      selectedProductPackaging.allDone
    ) {
      closeLotGroupDetail(selectedProductPackaging, selectedProductDispatch)
    }
  }, [selectedProductPackaging])

  useEffect(() => {
    if (firstPage) setInputValue(`${wantedInput}`)
    if (!firstPage) setInputValue(wantedInput === 0 ? "0" : "")
  }, [firstPage, wantedInput])

  const _onClose = () => {
    closeLotGroupDetail(selectedProductPackaging, selectedProductDispatch)
  }

  const _onInputValueChange = (event) => {
    setInputValue(event.target.value)
  }

  const _confirmStep = async () => {
    if (
      inputValue !== "" &&
      !inputValue.includes(",") &&
      !inputValue.includes(".")
    ) {
      if (
        wantedInput === parseFloat(inputValue) ||
        (await confirm({
          title: "Confirm",
          message:
            "La valeur indiquée est différente de celle attendue. Êtes vous sûr?",
          confirmText: "Oui",
        }))
      ) {
        firstPage
          ? await updateProductPackaging(
              selectedProductPackaging.objectId,
              parseFloat(inputValue)
            )
          : await computeDispatch(
              selectedProductDispatch,
              parseFloat(inputValue)
            )
      }
    }
  }

  const _onKeyDown = (event) => {
    if (event && event.keyCode === 13) {
      _confirmStep()
      event.preventDefault()
    }
  }

  const computeVolumeAvailable = () => {
    if (!firstPage)
      return (
        selectedProductPackaging.warehouseProduction - computeVolumeDispatch()
      )
    return null
  }

  const computeVolumeDispatch = () => {
    if (!firstPage) {
      let total = 0
      selectedProductPackaging.productDispatches.map((productDispatch) => {
        total += productDispatch.prodWarehouse
          ? productDispatch.prodWarehouse
          : 0

        return productDispatch
      })

      return total
    }

    return null
  }

  return (
    <Modal
      key="single-reception-warehouse-detail-modal"
      BackdropProps={{ invisible: true }}
      className={classes.singleLotGroupModalDetail}
      open={true}
    >
      <div className={classes.noOutline}>
        {selectedProductPackaging && (
          <div>
            <Header
              onClose={_onClose}
              selectedProductPackaging={selectedProductPackaging}
              date={date}
              firstPage={firstPage}
              available={computeVolumeAvailable()}
              dispatched={computeVolumeDispatch()}
            />
            <div className={classes.divider} />
            <div className={classes.contentBloc}>
              <p>
                Entrez le total des
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {selectedProductPackaging &&
                    selectedProductPackaging.productionItems.length > 0 &&
                    selectedProductPackaging.productionItems[0].uniqueCode}{" "}
                </span>
                en packaging{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {packagingType.toUpperCase()}{" "}
                </span>
                {!firstPage && <span> pour:</span>}
              </p>
              {!firstPage && selectedProductDispatch && (
                <p>
                  {currentBrand && (
                    <>
                      <img
                        className={classes.logo}
                        src={currentBrand.image}
                        alt={`Logo ${currentBrand.label}`}
                      />
                      <span style={{ color: currentBrand.color }}>
                        {currentBrand.label}
                      </span>
                      <span> du </span>
                    </>
                  )}
                  <span>
                    {" "}
                    {moment
                      .utc(selectedProductDispatch.saleDate)
                      .format("ddd DD MMM YYYY")}{" "}
                  </span>
                </p>
              )}
              <InputBlock
                inputRef={inputRef}
                onKeyDown={_onKeyDown}
                onInputValueChange={_onInputValueChange}
                inputValue={inputValue}
                wantedInput={wantedInput}
                firstPage={firstPage}
              />
              <div className={classes.buttonContainer}>
                <div
                  className={clsx(
                    inputValue !== null && inputValue !== "" && !snackBar.open
                      ? classes.confirmButtonFill
                      : classes.confirmButtonEmpty,
                    { "is-tablet": isTablet }
                  )}
                  onClick={() => {
                    if (!snackBar.open) _confirmStep()
                  }}
                >
                  <span className={classes.buttonText}>CONFIRMER</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBar.open}
          autoHideDuration={snackBar.duration}
          onClose={() => closeLotGroupSnackBar(snackBar.type)}
        >
          <MySnackbarContentWrapper
            onClose={() => closeLotGroupSnackBar(snackBar.type)}
            variant={snackBar.type}
            message={snackBar.message}
          />
        </Snackbar>
      </div>
    </Modal>
  )
}

export default connect(
  (state) => {
    return {
      date: state.lotGroup.date,
      selectedProductPackaging: _cloneDeep(
        state.lotGroup.selectedProductPackaging
      ),
      selectedProductDispatch: state.lotGroup.selectedProductDispatch || null,
      snackBar: state.lotGroup.snackBar,
    }
  },
  {
    closeLotGroupSnackBar,
    closeLotGroupDetail,
    computeDispatch,
    updateProductPackaging,
  }
)(LotGroupDetail)
