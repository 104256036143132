import React, { Fragment, useState } from "react"
import withStyles from "@mui/styles/withStyles"
import EditIcon from "@mui/icons-material/Edit"

import {
    COLORS
} from "../../../utils"
import {
    getPrevisionHubsStatusLabel
} from "../../../utils/planning"

const styles = {
    hubRaw: {
        display: "flex",
        padding: 5,
        color: COLORS.WHITE
    },
    hubTitle: {
        fontWeight: "bold",
        fontSize: 16
    },
    hubPosition: {
        fontSize: 12,
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "auto"
    },
    raw: {
        display: "flex"
    },
    secondRaw: {
        marginTop: 5,
        composes: "$raw"
    },
    rawElement: {
        marginLeft: 10
    },
    firstRawElement: {
        marginRight: "auto"
    },
    secondRawElement: {
        display: "flex",
        marginLeft: "auto"
    },
    prevProd: {
        marginLeft: "auto",
        fontSize: 12
    },
    productTypeData: {
        padding: "10px 5px 10px 5px",
        "&:hover": {
            backgroundColor: COLORS.MEDIUM_GREY,
            cursor: "pointer"
        }
    },
    titleLabelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    productTypeTitle: {
        fontWeight: "bold"
    },
    divider: {
        height: 1,
        width: "100%",
        backgroundColor: COLORS.MEDIUM_GREY,
    },
    dividerVertical: {
        width: 1,
        height: 25,
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: COLORS.MEDIUM_GREY
    },
    repartitionKey: {
        position: "absolute",
        zIndex: 100,
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        cursor: "pointer",
        marginLeft: 40,
        paddingLeft: 2,
        paddingRight: 2,
        border: `1px solid ${COLORS.MEDIUM_GREY}`
    },
    labelValue: {
        fontSize: 14
    },
    editIcon: {
        color: COLORS.DARK_GREY,
        fontSize: 16,
        marginTop: 2,
        marginLeft: "auto"
    },
    hubStatus: {
        fontSize: 12,
        paddingTop: 4,
        marginLeft: 10
    },
    validated: {
        backgroundColor: COLORS.GREEN_PLANNING
    }
}

export const PrevisionsPlanningCard = (props) => {
    const {
        card,
        totalHubs,
        classes,
        openPrevEditModal,
        color,
        foldedCard,
        canEdit,
        selectedProductTypes,
        productTypeOptions
    } = props

    const [showPercentage, updatePercentage] = useState(false)
    const [showEdit, updateEdit] = useState(false)
    const [productTypeOver, updateProductTypeOver] = useState(null)

    const _showRepartitionKey = () => {
        updatePercentage(true)
    }

    const _hideRepartitionKey = () => {
        updatePercentage(false)
    }

    const _showEdit = (productType) => {
        updateEdit(true)
        updateProductTypeOver(productType)
    }

    const _hideEdit = () => {
        updateEdit(false)
        updateProductTypeOver(null)
    }

    const _openPrevEditModal = (hubStatus, productType) => {
        if (hubStatus === "OPEN") {
            openPrevEditModal(card, productType)
        }
    }

    const formatRepartitionKey = (number) => {
        const percentage = number * 100

        return percentage.toFixed(2)
    }

    return (
        <Fragment>
            <div
                className={classes.hubRaw}
                style={{ backgroundColor: card.status === "OPEN" ? color : COLORS.DARK_GREY }}
            >
                <div className={classes.hubTitle}>
                    {card.hubName}
                </div>
                {
                    card.status !== "OPEN" &&
                    <div className={classes.hubStatus}>
                        ({getPrevisionHubsStatusLabel(card.status)})
                    </div>
                }
                <div className={classes.hubPosition}>
                    {card.priority}/{totalHubs}
                </div>
            </div>
            {
                card.status === "OPEN" && !foldedCard && productTypeOptions.filter(option => selectedProductTypes.includes(option.type)).map((productType, index) => {
                    const currentProductType = productType.type
                    const prev = card.prev.find(el => el.productType === currentProductType)
                    if (prev) {
                        const productTypeLabel = productTypeOptions.find(el => el.type === prev.productType).label
                        return (
                            <div key={index} className={"VALIDATED" === card.state ? classes.validated : ""}>
                                <div
                                    className={classes.productTypeData}
                                    onMouseOver={() => { _showEdit(currentProductType) }}
                                    onMouseLeave={_hideEdit}
                                    onClick={() => { canEdit && _openPrevEditModal(card.status, currentProductType) }}
                                >
                                    <div className={classes.raw}>
                                        <div className={classes.productTypeTitle}>
                                            {productTypeLabel}
                                        </div>
                                        {
                                            canEdit && showEdit && productTypeOver === currentProductType && card.status === "OPEN" &&
                                            <EditIcon
                                                className={classes.editIcon}
                                            />
                                        }
                                    </div>
                                    <div className={classes.secondRaw}>
                                        <div
                                            className={classes.firstRawElement}
                                        >
                                            <div className={classes.titleLabelContainer}>
                                                Prev
                                            </div>
                                            <div className={classes.labelValue}>
                                                {prev.prevData}
                                            </div>
                                        </div>
                                        <div
                                            className={classes.firstRawElement}
                                        >
                                            <div className={classes.titleLabelContainer}>
                                                Prev&nbsp;vente
                                            </div>
                                            <div className={classes.labelValue}>
                                                {prev.prevSell}
                                            </div>
                                        </div>
                                        <div className={classes.dividerVertical} />
                                        <div className={classes.secondRawElement}>
                                            {
                                                showPercentage && productTypeOver === currentProductType &&
                                                <div
                                                    className={classes.repartitionKey}
                                                >
                                                    <div className={classes.titleLabelContainer}>
                                                        Clé
                                                    </div>
                                                    <div>
                                                        {formatRepartitionKey(prev.repartitionKey)}%
                                                    </div>
                                                </div>
                                            }
                                            <div
                                                className={classes.rawElement}
                                                onMouseOver={_showRepartitionKey}
                                                onMouseLeave={_hideRepartitionKey}
                                            >
                                                <div className={classes.titleLabelContainer}>
                                                    Cible
                                                </div>
                                                <div className={classes.labelValue}>
                                                    {prev.target}
                                                </div>
                                            </div>
                                            <div className={classes.rawElement}>
                                                <div className={classes.titleLabelContainer}>
                                                    Bonus
                                                </div>
                                                <div className={classes.labelValue}>
                                                    {prev.bonus} %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    index !== card.prev.length - 1 &&
                                    <div className={classes.divider} />
                                }
                            </div>
                        )
                    }
                    else {
                        return null
                    }
                })
            }
        </Fragment>
    )
}

export default withStyles(styles)(PrevisionsPlanningCard)
