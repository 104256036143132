import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS,
} from "../../utils"
import TextField from "@mui/material/TextField"
import {DatePicker, PickersDay} from "@mui/x-date-pickers"
import cloneDeep from "lodash/cloneDeep"
import {styled} from "@mui/material"

const styles = {
    calendarHeaderInfoContainer: {
        color: COLORS.DARK_GREY,
        fontSize: 12,
        lineHeight: "130%",
        width: 200,
        padding: "12px 18px 12px 12px"
    },
    infoNumber: {
        marginLeft: "auto"
    },
    divider: {
        height: 1,
        backgroundColor: COLORS.MEDIUM_GREY,
        width: "100%",
        marginTop: 12,
        marginBottom: 12
    },
    input: {
        marginLeft: 20
    },
    inputProps: {
        textAlign: "right"
    }
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}))

export const WeekCalendarPicker = ({ date, onDateChange, classes }) => {
  const renderWeekPickerDay = (
    dateRendering,
    selectedDates,
    pickersDayProps,
  ) => {
    if (!date) {
      return <PickersDay {...pickersDayProps} />
    }

    const startValue = cloneDeep(date)
    const endValue = cloneDeep(date)
    const start = startValue.startOf("week")
    const end = endValue.endOf("week")
    const dayIsBetween = dateRendering.isBetween(start, end, null, "[]")
    const isFirstDay = dateRendering.isSame(start, "day")
    const isLastDay = dateRendering.isSame(end, "day")

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        showDaysOutsideCurrentMonth={true}
      />
    )
  }

  return <DatePicker
    disableCloseOnSelect={false}
    showToolbar={false}
    variant="inline"
    value={date}
    onChange={onDateChange}
    renderDay={renderWeekPickerDay}
    calendarStartDay={1}
    renderInput={(params) => <TextField {...params} variant="standard" className={classes.datePicker}/>}
  />
}

export default withStyles(styles)(WeekCalendarPicker)
