import Parse from "parse"
import moment from "moment"

const LockerProductPackaging = Parse.Object.extend("LockerProductPackaging")
const ProductPackaging = Parse.Object.extend("ProductsPackaging")

export async function createOrLockProductPackagingLocker(idProductPackaging) {
    try {
        let locker = await new Parse.Query("LockerProductPackaging")
            .equalTo("productPackaging", ProductPackaging.createWithoutData(idProductPackaging))
            .equalTo("user", Parse.User.current())
            .equalTo("isOver", true)
            .descending("createdAt")
            .first()
        
        if (locker) {
            locker.set("isOver", false)
        } else {
            locker = new LockerProductPackaging()
            
            locker.set("isOver", false)
            locker.set("type", "warehouseReception")
            locker.set("productPackaging", ProductPackaging.createWithoutData(idProductPackaging))
            locker.set("user", Parse.User.current())
        }
        
        locker.set("startedAt", moment().valueOf())
        await locker.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function freeProductPackagingLocker(productPackagingId) {
    try {
        const locker = await new Parse.Query("LockerProductPackaging")
            .equalTo("productPackaging", ProductPackaging.createWithoutData(productPackagingId))
            .equalTo("user", Parse.User.current())
            .equalTo("isOver", false)
            .descending("createdAt")
            .first()
        
        if (locker) {
            locker.set("isOver", true)
            await locker.save()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getProductPackagingLocker(idProductPackaging) {
    try {
        return await new Parse.Query("LockerProductPackaging")
            .equalTo("productPackaging", ProductPackaging.createWithoutData(idProductPackaging))
            .equalTo("isOver", false)
            .notEqualTo("user", Parse.User.current())
            .first()
    }
    catch (e) {
        return Promise.reject(e)
    }
}