import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import withStyles from "@mui/styles/withStyles"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import Paper from "@mui/material/Paper"
import DeleteIcon from "@mui/icons-material/Clear"
import Divider from "@mui/material/Divider"
import InputBase from "@mui/material/InputBase"
import Parse from "parse"
import {
    createCommercialName,
    showInternalCommercialNames,
    showCommercialName
} from "../../../actions/Ingredients/Internal/CommercialsNames"
import { commercialNameFormInitialValues } from "../../../actions/Utils/utils"
import { getCommercialName } from "../../../reducers/Ingredients/Internal/CommercialName"
import MenuItem from "@mui/material/MenuItem"
import ReduxSelectField from "../../../components/form/ReduxSelectField"
import { recipeFormstyles } from "../../../styles"
import { commercialNameValidator } from "../../../utils/validators"
import AllergenAutoComplete from "../../../components/autoComplete/AllergenAutoComplete"
import {
    conservationModes,
    labels,
    origins
} from "../../../utils/commercialNamesUtils"
import InputLabel from "@mui/material/InputLabel"
import SeasonChooser from "../../../components/form/SeasonChooser"
import ReduxTextField from "../../../components/form/ReduxTextField"

const GroupsIngredients = Parse.Object.extend("GroupsIngredients")

class CommercialNameForm extends PureComponent {

    constructor(props){
        super(props)
        const { initialValues } = this.props
        this.state = {
            allergens: initialValues.allergens || [],
            newAllergen: null,
            familyId: initialValues.family || null,
            firstOriginAverage: initialValues.firstOriginAverage !== null ? initialValues.firstOriginAverage : 0,
            secondOriginAverage: initialValues.secondOriginAverage !== null ? initialValues.secondOriginAverage : 0,
            complexity: initialValues.complexity ? initialValues.complexity : 0
        }
    }

    componentDidUpdate(prevProps) {
        const { initialValues, change } = this.props
        if (prevProps.initialValues.family !== initialValues.family){
            this.setState({familyId: initialValues.family})
            change("family", initialValues.family)
        }
    }

    _onClose = () => {
        const {
            showInternalCommercialNames,
            showCommercialName,
            commercialName,
            location
        } = this.props
        
        if (location && location.state && location.state.returnPath === "edit") {
            showCommercialName(commercialName.objectId)
        } else {
            showInternalCommercialNames()
        }
    }

    renderConservationModesItems = () => {
        return conservationModes.map(conservationMode => {
            return <MenuItem key={conservationMode.value + conservationMode.label} value={conservationMode.value}>{conservationMode.label}</MenuItem>
        })
    }

    renderOriginsItems = () => {
        return origins.map(origin => {
            return <MenuItem key={origin.value + origin.label} value={origin.value}>{origin.label}</MenuItem>
        })
    }

    renderLabelsItems = () => {
        return labels.sort((a, b) => a.label.localeCompare(b.label)).map(label => {
            return <MenuItem key={label.value + label.label} value={label.value}>{label.label}</MenuItem>
        })
    }

    renderFamiliesItems = () => {
        const {familiesIngredients} = this.props
        if (familiesIngredients) {
            return familiesIngredients.map(familyIngredient =>
                <MenuItem
                    key={familyIngredient.objectId}
                    value={familyIngredient.objectId}>{familyIngredient.name}
                </MenuItem>
            )
        }
    }

    renderGroupsItems = () => {
        const { groupsIngredients } = this.props
        const { familyId } = this.state

        if (groupsIngredients) {
            if (familyId){
                return groupsIngredients.map(groupIngredient => {
                    if (groupIngredient.family.objectId === familyId) {
                        return <MenuItem key={groupIngredient.objectId} value={groupIngredient.objectId}>{groupIngredient.name}</MenuItem>
                    }
                    return null
                })
            }
            else {
                return groupsIngredients.map(groupIngredient =>
                    <MenuItem key={groupIngredient.objectId} value={groupIngredient.objectId}>{groupIngredient.name}</MenuItem>
                )
            }
        }
    }

    renderComplexity = () => {
        const complexities = [1, 2, 3, 5, 8, 13]

        return complexities.map(complexity =>
            <MenuItem key={complexity} value={complexity}>{complexity}</MenuItem>
        )
    }

    _removeAllergen = (index) => {
        const { change } = this.props
        const { allergens } = this.state
        this.setState({allergens: allergens.filter((elem, i) => i !== index)})
        change("allergens", allergens.filter((elem, i) => i !== index))
    }

    _addAllergen = (value = null) => {
        const { newAllergen, allergens } = this.state
        const { change } = this.props
        if (value !== null){
            this.setState({allergens: [ ...allergens, value ], newAllergen: null})
            change("allergens", [ ...allergens, value ])
        }
        else if (newAllergen !== null){
            this.setState({allergens: [ ...allergens, newAllergen ], newAllergen: null})
            change("allergens", [ ...allergens, newAllergen ])
        }
        change("addAllergen", null)
    }

    renderAllergens = () => {
        const { classes } = this.props
        const { allergens } = this.state
        if (allergens) {
            const paperBlock = allergens.map((allergen, index) =>
                <Paper key={"allergen" + index} className={classes.subcontractorCommercialNameBloc} elevation={1}>
                    <InputBase className={classes.subcontractorCommercialNameInput} disabled value={allergen.label}/>
                    <Divider className={classes.divider}/>
                    <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="Directions"
                        onClick={this._removeAllergen.bind(this, index)}
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </Paper>
            )
            const finalResult = []
            let i = 0
            while (i < allergens.length){
                finalResult.push(
                    <div key={"allergenBloc" + i} className="flexRow marginBottom20">
                        {paperBlock[i] ? paperBlock[i] : ""}
                        {paperBlock[i + 1] ? paperBlock[i + 1] : ""}
                        {paperBlock[i + 2] ? paperBlock[i + 2] : ""}
                        {paperBlock[i + 3] ? paperBlock[i + 3] : ""}
                    </div>
                )
                i+=4
            }
            return finalResult
        }
    }

    _handleNewAllergenChange = (event, value) => {
        this.setState({newAllergen: value})
    }

    _onEnterNewAllergen = (value) => {
        this.setState({newAllergen: value})
        this._addAllergen(value)
    }

    _onFamilyChange = (_, value) => {
        const { change } = this.props
        this.setState({familyId: value})
        change("group", null)
    }

    _onGroupChange = async (event) => {
        const { change } = this.props
        if (event.target.value){
            const newGroup = await new Parse.Query(GroupsIngredients)
                .equalTo("objectId", event.target.value)
                .first()

            this.setState({familyId: newGroup.family ? newGroup.family.objectId : null})
            change("family", newGroup.family ? newGroup.family.objectId : null)
        }
    }

    _onFirstOriginAverageChange = (event) => {
        const { change } = this.props
        const current = parseFloat(event.target.value)

        if (current > 100 || current < 0){
            event.preventDefault()
        }
        else {
            this.setState({
                firstOriginAverage: current,
                secondOriginAverage: (100 - current) || 100
            })
            change("firstOriginAverage", current)
            change("secondOriginAverage", (100 - current) || 0)
        }
    }

    render(){
        const { classes, handleSubmit, createCommercialName, location } = this.props
        const {
            familyId, allergens, newAllergen, firstOriginAverage,
            secondOriginAverage
        } = this.state

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={classes.formContainer}>
                    <form onSubmit={handleSubmit(createCommercialName, location)}>
                        <div className="flexRow">
                            <FormControl margin="normal" classes={{root: classes.formControlMax400}} required>
                                <Field
                                    name="name"
                                    component={ReduxTextField}
                                    label="Nom"
                                />
                            </FormControl>
                        </div>
                        <div className="flexRow">
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="family"
                                    label="Famille d'ingrédient"
                                    placeholder="Famille d'ingrédient"
                                    value={familyId}
                                    onChange={this._onFamilyChange}
                                >
                                    {this.renderFamiliesItems()}
                                </ReduxSelectField>
                            </FormControl>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="group"
                                    label="Groupe d'ingrédient"
                                    placeholder="Groupe d'ingrédient"
                                >
                                    {this.renderGroupsItems()}
                                </ReduxSelectField>
                            </FormControl>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="complexity"
                                    label="Complexité"
                                    placeholder="Complexité"
                                >
                                    {this.renderComplexity()}
                                </ReduxSelectField>
                            </FormControl>

                        </div>
                        <div className={classes.seasonContainer}>
                            <InputLabel htmlFor="season" classes={{root: classes.seasonLabel}}>
                                Saison
                            </InputLabel>
                            <Field
                                name="season"
                                component={SeasonChooser}
                            />
                        </div>

                        <div className="flexRow marginLeft10">
                            <h4>Allergènes</h4>
                        </div>
                        <div className="showBlock">
                            <div className="flexColumn">
                                {this.renderAllergens()}
                            </div>
                            <div className="flexRow">
                                <FormControl margin="normal" classes={{root: classes.formControlMax400}}>
                                    <Field
                                        name="addAllergen"
                                        currentAllergens={allergens}
                                        onEnterValidation={this._onEnterNewAllergen}
                                        currentValue={newAllergen}
                                        component={AllergenAutoComplete}
                                        onChange={this._handleNewAllergenChange}
                                        value={newAllergen}
                                        placeHolder="Ajouter un allergène"
                                    />
                                </FormControl>
                                <FormControl margin="normal" classes={{root: classes.formControl}}>
                                    <AddIcon className={classes.addIcon} onClick={() => this._addAllergen(null)}/>
                                </FormControl>
                            </div>
                        </div>
                        <div className="flexRow marginLeft10">
                            <h4>Sourcing</h4>
                        </div>
                        <div>
                            <FormControl margin="normal" classes={{root: classes.statusSelectLarge}} required>
                                <ReduxSelectField
                                    name="conservationMode"
                                    label="Mode de conservation"
                                    placeholder="Mode de conservation"
                                >
                                    {this.renderConservationModesItems()}
                                </ReduxSelectField>
                            </FormControl>
                            <br/>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="firstOrigin"
                                    label="Provenance 1"
                                    placeholder="Provenance 1"
                                >
                                    {this.renderOriginsItems()}
                                </ReduxSelectField>
                            </FormControl>
                            <FormControl margin="normal" classes={{root: classes.formControlMax250}} required>
                                <Field
                                    name="firstOriginAverage"
                                    type="number"
                                    component={ReduxTextField}
                                    label="Moyenne&nbsp;provenance&nbsp;1&nbsp;en&nbsp;%"
                                    value={firstOriginAverage}
                                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                                    onChange={this._onFirstOriginAverageChange}
                                />
                            </FormControl>
                            {firstOriginAverage !== 100 &&
                                <div className="flexRow">
                                    <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                        <ReduxSelectField
                                            name="secondOrigin"
                                            label="Provenance 2"
                                            placeholder="Provenance 2"
                                        >
                                            {this.renderOriginsItems()}
                                        </ReduxSelectField>
                                    </FormControl>
                                    <div className={classes.secondOriginAverage}>
                                        { secondOriginAverage } %
                                    </div>
                                </div>
                            }
                            <br/>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="label"
                                    label="Label"
                                    placeholder="Label"
                                >
                                    {this.renderLabelsItems()}
                                </ReduxSelectField>
                            </FormControl>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                classes={{root: classes.actionButton}}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

CommercialNameForm = reduxForm({
    form: "commercialName",
    validate: commercialNameValidator,
    enableReinitialize: false
})(CommercialNameForm)

CommercialNameForm = withStyles(recipeFormstyles)(CommercialNameForm)

export default connect((state, props) => {
    const { params: {id} } = props
    const commercialName = id ? getCommercialName(state, id) : null
    const commercialNameFormValues = commercialName !== null ? state.form.commercialName ? state.form.commercialName.values : undefined : undefined
    const familiesIngredients = state.commercialNames.familiesIngredients

    return {
        initialValues: commercialNameFormInitialValues(commercialName, familiesIngredients),
        commercialNameFormValues,
        commercialName: commercialName,
        familiesIngredients: familiesIngredients,
        groupsIngredients: state.commercialNames.groupsIngredients
    }
}, {
    createCommercialName,
    showInternalCommercialNames,
    showCommercialName
})(CommercialNameForm)
