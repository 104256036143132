import React from "react"
import { Box, Fab } from "@mui/material"
import Add from "@mui/icons-material/Add"

const CreateDocumentFab = ({ onClick }) => {

	return (
		<Box className="createDocumentFab">
			<Fab color="primary" onClick={onClick}>
				<Add />
			</Fab>
		</Box>
	)
}

export default CreateDocumentFab