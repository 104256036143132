import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { DatePicker } from "@mui/x-date-pickers"
import PlanningBrandSelector from "../PlanningBrandSelector"
import TextField from "@mui/material/TextField"
import { FormControl } from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/DeleteSweep"
import moment from "moment"
import { KFC_BRANDS } from "../../../utils"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: 10,
        zIndex: 1000,
        marginLeft: 30,
        "& > *": {
            marginLeft: 10
        }
    },
    datePicker: {
        minWidth: 140,
        maxWidth: 170
    },
    searchTextField: {
        minWidth: 180
    },
    formControl: {
        minWidth: 120
    },
    resetButton: {
        paddingBottom: 5
    },
    inputLabel: {
        fontSize: "1em"
    }
}))

export const PlanningPrevFilters = (props) => {
    const {
        date,
        sectors,
        hubs,
        productTypeOptions,
        onDateChange,
        onBrandChange,
        onSearchChange,
        search,
        brand,
        selectedSectors,
        selectedHubs,
        selectedProductTypes,
        onSectorsChange,
        onHubsChange,
        onProductTypesChange
    } = props

    const showResetButton = date.valueOf() !== moment.utc().startOf("day").valueOf() ||
        !selectedProductTypes.includes("ALL") ||
        !selectedHubs.includes("ALL") ||
        !selectedSectors.includes("ALL") ||
        brand !== KFC_BRANDS[0] ||
        search !== ""

    const classes = useStyles()


    const resetFilter = () => {
        if (date.valueOf() !== moment.utc().startOf("day").valueOf()) onDateChange(moment.utc().startOf("day"))
        if (!selectedProductTypes.includes("ALL")) onProductTypesChange(productTypeOptions.map(el => el.value))
        if (!selectedHubs.includes("ALL")) onHubsChange(hubs.map(el => el.value))
        if (!selectedSectors.includes("ALL")) onSectorsChange(sectors.map(el => el.value))
        if (brand !== KFC_BRANDS[0]) onBrandChange(KFC_BRANDS[0])
        if (search !== "") onSearchChange({target: {value: ""}})
    }

    const renderSelectResult = (selected, resources) => {
        if (selected.includes("ALL")) {
            return resources[0].label
        }
        else {
            return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
        }
    }
    
    const renderData = (objects, selectedObjects) => {
        return (
            objects.map((item, index) =>
                <MenuItem
                    value={item.value}
                    key={index}
                >
                    <Checkbox
                        checked={selectedObjects.includes(item.value)}
                        color="secondary"
                    />
                    <ListItemText primary={item.label} />
                </MenuItem>
            )
        )
    }
    
    return (
        <span>
            <div className={classes.container}>
                <PlanningBrandSelector
                    brand={brand}
                    onBrandClick={onBrandChange}
                    activeBrandsOnly
                />
                <DatePicker
					disableCloseOnSelect={false}
                    showToolbar={false}
                    variant="inline"
                    value={date}
                    onChange={onDateChange}
                    inputFormat="DD/MM/YYYY"
                    className={classes.datePicker}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />
                <TextField
                    variant="standard"
                    onChange={onSearchChange}
                    label="Rechercher un hub"
                    className={classes.searchTextField}
                    InputLabelProps={{
                        classes: { root: classes.inputLabel }
                    }}
                    value={search}
                />
                <FormControl className={classes.formControl} variant="standard">
                    <InputLabel htmlFor="sector">Secteur</InputLabel>
                    <Select
                        variant="standard"
                        multiple={true}
                        value={selectedSectors}
                        onChange={(e) => onSectorsChange(e.target.value)}
                        inputProps={{
                            name: "sector",
                            id: "sector",
                        }}
                        renderValue={
                            selected => renderSelectResult(selected, sectors)
                        }
                    >
                        { renderData(sectors, selectedSectors) }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="standard">
                    <InputLabel htmlFor="hub">Hub</InputLabel>
                    <Select
                        variant="standard"
                        multiple={true}
                        value={selectedHubs}
                        onChange={(e) => onHubsChange(e.target.value)}
                        inputProps={{
                            name: "hub",
                            id: "hub",
                        }}
                        renderValue={
                            selected => renderSelectResult(selected, hubs)
                        }
                    >
                        { renderData(hubs, selectedHubs) }
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl} variant="standard">
                    <InputLabel>Type</InputLabel>
                    <Select
                        variant="standard"
                        multiple={true}
                        value={selectedProductTypes}
                        onChange={(e) => onProductTypesChange(e.target.value)}
                        renderValue={
                            selected => renderSelectResult(selected, productTypeOptions)
                        }
                        inputProps={{
                            name: "type",
                            id: "type",
                        }}
                    >
                        { renderData(productTypeOptions, selectedProductTypes) }
                    </Select>
                </FormControl>
                {
                    showResetButton &&
                    <IconButton className={classes.resetButton} onClick={resetFilter} size="large">
                        <DeleteIcon/>
                    </IconButton>
                }
            </div>
        </span>
    )
}

export default PlanningPrevFilters