import React from "react"

import IngredientsPage from "./IngredientsPage"
import CommercialNames from "./Internal/CommercialNames"

const InternalCommercialNamesTab = () =>  {
	return (
		<IngredientsPage tabValue="internals">
			<CommercialNames/>
		</IngredientsPage>
	)
}

export default InternalCommercialNamesTab