import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const HubSelect = (props) => {
    const {classes, hubs, ...otherProps} = props

    return (
        <FormControl className={classes.formControl} variant="standard">
            <InputLabel>Hub</InputLabel>
            <Select
                variant="standard"
                name="hub"
                label="hub"
                id="hub"
                fullWidth
                {...otherProps}
            >
                {
                   hubs.map((hub, index) =>
                        <MenuItem key={index} value={hub.value}>{hub.label}</MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default HubSelect
