import {actionWithLoader, onEnter, push} from "../Utils/utils"
import {
	getSiteById,
	getSitesStockZones,
	getStockZone
} from "../../parseManager/site/parseSiteManager"
import {getParamsFromPath} from "../../utils/productionReceptionUtils"
import {
	getProductionSupply,
	getProductionSupplyById
} from "../../parseManager/productionSupply/parseProductionSupplyManager"

export function loadProductionReceptionFilter() {
	return actionWithLoader(async (dispatch) => {
		const sites = await getSitesStockZones()
		const selectedSite = localStorage.getItem("siteSelected") ? JSON.parse(localStorage.getItem("siteSelected")) : sites[0]
		const stockZoneSelected = localStorage.getItem("stockZoneSelected") ? JSON.parse(localStorage.getItem("stockZoneSelected")) : selectedSite.stockZones[0]

		dispatch({
			type: "PRODUCTION_RECEPTION_FILTER_LOADED",
			sites,
			selectedSite,
			selectedStockZone: stockZoneSelected
		})
	})
}

export function loadProductionReception() {
	return actionWithLoader(async (dispatch, getState) => {
		const state = getState()
		const path = state.routing.locationBeforeTransitions.pathname
		const { siteId, stockZoneId, date } = getParamsFromPath(path)
		if (siteId && stockZoneId && date) {
			const [site, stockZone, sites] = await Promise.all([
				getSiteById(siteId),
				getStockZone({stockZoneId: stockZoneId, toJson: false}),
				getSitesStockZones()
			])

			const [productionSuppliesToDo, productionSuppliesDone] = await Promise.all([
				getProductionSupply(stockZone, date, "TODO", ["supplierItem.name"]),
				getProductionSupply(stockZone, date, "DONE", ["supplierItem.name"])
			])

			localStorage.setItem("siteSelected", JSON.stringify(site))
			localStorage.setItem("stockZoneSelected", JSON.stringify(stockZone))
			localStorage.setItem("productionDate", JSON.stringify(date))

			dispatch({
				type: "PRODUCTION_RECEPTION_FILTER_LOADED",
				selectedSite: site,
				selectedStockZone: stockZone.toJSON(),
				productionDate: date,
				sites,
				productionSuppliesToDo,
				productionSuppliesDone
			})
		}
	})
}

export function loadProductionSupplyToReceive(params) {
	return actionWithLoader(async (dispatch) => {
		const productionSupplyId = params.productionSupplyId
		const productionSupply = await getProductionSupplyById(productionSupplyId, ["outputs.lot.lotNumber", "supplierItem.name"])
		const selectedSite = JSON.parse(localStorage.getItem("siteSelected"))
		const stockZoneSelected = JSON.parse(localStorage.getItem("stockZoneSelected"))
		const productionDate = JSON.parse(localStorage.getItem("productionDate"))

		dispatch({
			type: "PRODUCTION_RECEPTION_FILTER_LOADED",
			selectedSite,
			selectedStockZone: stockZoneSelected,
			productionDate
		})

		dispatch({
			type: "PRODUCTION_RECEPTION_TO_RECEIVE_LOADED",
			productionSupply
		})
	})
}

export function updateProductionReceptionToReceive(productionSupplyId, quantityOutput, isCompliant) {
	return actionWithLoader(async () => {
		const productionSupply = await getProductionSupplyById(productionSupplyId, ["outputs.lot.lotNumber"], false)

		productionSupply.set("receptionStatus", "DONE")
		productionSupply.set("realQuantity", quantityOutput)
		productionSupply.set("compliance", isCompliant)
		await productionSupply.save()
	})
}

export const showProductionReceptionFilter = () => {
	return push("/productionReception/filter")
}

export const showProductionReception = (siteId, stockZoneId, productionDate) => {
	return push(`/productionReception/${siteId}/${stockZoneId}/${productionDate}`)
}

export const showProductionReceptionToReceive = (productionSupplyId) => {
	return push(`/productionReception/productionSupplytoReceive/${productionSupplyId}`)
}


// -------------- ONENTER -------------- //
export const onEnterProductionReceptionFilterExecution = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionReceptionFilter
	})
}

export const onEnterProductionReceptionExecution = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionReception
	})
}

export const onEnterProductionReceptionToReceiveExecution = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionSupplyToReceive
	})
}


