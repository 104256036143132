export function computeOrderSupplierItemQuantityLeft2(orderSupplierItem) {
	if (!orderSupplierItem){
		return 0
	}
	
	const alreadyCreated = orderSupplierItem.lots.length > 0 ? orderSupplierItem.lots.reduce(el => (el.volume || 0)) : 0
	const received = (orderSupplierItem.quantities && orderSupplierItem.quantities.reception) || 0

	return received - alreadyCreated
}

export function computeOrderSupplierItemQuantityLeft(orderSupplierItem) {
	if (!orderSupplierItem){
		return 0
	}
	
	const alreadyCreated = orderSupplierItem.lots.reduce((acc, lot) => acc + lot.initialQuantity, 0)

	const received = (orderSupplierItem.quantities && orderSupplierItem.quantities.reception) || 0

	return received - alreadyCreated
}