import withStyles from "@mui/styles/withStyles"
import {COLORS} from "../utils"
import Tooltip from "@mui/material/Tooltip"

const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: COLORS.BLACK_CARD,
		boxShadow: theme.shadows[1],
		fontSize: 12,
	},
}))(Tooltip)

export default LightTooltip