import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterResumeForm, { distributionCenterResumeFormId } from "./DistributionCenterResumeForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"

const DistributionCenterResumeFormContainer = ({ open, onClose, distributionCenter }) => {

	const dispatch = useDispatch()

	const onSubmitResumeForm = (values) => {
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, values, "resume"))
		onClose && onClose()
	}

	return (
		<Dialog
			sx={{
				"& .MuiPaper-root": {
					width: 500
				}
			}}
			open={open}
			onClose={onClose}>
			<DialogTitle>
				Éditer le centre de distribution
			</DialogTitle>
			<DialogContent>
				<DistributionCenterResumeForm
					distributionCenter={distributionCenter}
					handleSubmit={onSubmitResumeForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterResumeFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DistributionCenterResumeFormContainer