import Parse from "parse"
import { getTimezoneDate, parseLimitRequest } from "../../utils"
import dayjs from "dayjs"
import {
    createOrderSupplierItem, getOrderSupplierItemsBySupplierItem
} from "./parseOrderSupplierItemManager"
import {
    addLotEvent,
    createLot, deleteLot
} from "../lot/parseLotManager"
import moment from "moment"
import cloneDeep from "lodash/cloneDeep"
import { creditNotesType } from "../../utils/creditNoteUtils"
import { createCreditNote, deleteCreditNote, getCreditNotesForSupplierItem } from "./parseCreditNotesManager"
import { getLot } from "../lot/parseLotManager"
import {getOrderTotalAmount} from "../../utils/orderReception"

const Orders = Parse.Object.extend("Orders")

export async function getOrders(site, date, toJson = true) {
    try {
        const orders = (await new Parse.Query(Orders)
            .include(["supplierItems.supplierItem", "supplierItems.lots", "site.stockZones", "creditNotes", "supplierItems.creditNotes", "supplierItems.lots.creditNotes"])
            .notEqualTo("deleted", true)
            .equalTo("site", site)
            .equalTo("receptionDate", moment.utc(parseFloat(date)).startOf("day").valueOf())
            .limit(parseLimitRequest)
            .find()) || []

        return toJson ? orders.map(order => order.toJSON()) : orders
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getOrdersForStatus(site, status = "TO_VERIFY", toJson = true) {
    try {
        const orders = (await new Parse.Query(Orders)
            .include(["supplierItems.supplierItem", "supplierItems.lots", "site.stockZones"])
            .equalTo("site", site)
            .equalTo("orderStatus", status)
            .notEqualTo("deleted", true)
            .ascending("orderNumber")
            .find()) || []

        return toJson ? orders.map(el => el.toJSON()) : orders
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getOrdersReceptions(site, values, toJson = true) {
    try {

        const query = await new Parse.Query(Orders)
            .include(["supplierItems.supplierItem", "site"])
            .equalTo("site", site)
            .containedIn("orderStatus", ["DONE", "CANCELLED"])
            .notEqualTo("deleted", true)

        if (values.suppliers && values.suppliers.length > 0) {
            query.containedIn("supplier.objectId", values.suppliers.map((supplier) => supplier.value))
        }

        if (values.receptionStatus && values.receptionStatus.length > 0) {
            query.containedIn("receptionStatus", values.receptionStatus)
        }

        if (values.deliveryNoteNumber) {
            query.matches("deliveryNote.number", values.deliveryNoteNumber.toString(), "i")
        }

        if (values.receptionPeriodStart) {
            query.greaterThanOrEqualTo("receptionDate", getTimezoneDate(values.receptionPeriodStart, "timestamp", true))
        }

        if (values.receptionPeriodEnd) {
            query.lessThanOrEqualTo("receptionDate", getTimezoneDate(values.receptionPeriodEnd, "timestamp", false))
        }

        if (values.orderDate) {
            const orderDateEndOfDay = dayjs(values.orderDate).endOf("day").format()
            query
                .greaterThanOrEqualTo("orderDate", getTimezoneDate(values.orderDate, "timestamp", true))
                .lessThanOrEqualTo("orderDate", getTimezoneDate(orderDateEndOfDay, "timestamp", false))
        }

        let { sortBy = "asc", sortField = null } = values
        if (sortField !== null) {
            sortBy === "asc" ? query.ascending(sortField) : query.descending(sortField)
        }

        let orders = await query.limit(parseLimitRequest).find() || []
        if (values.orderNumber) {
            orders = orders.filter(ord => ord.get("orderNumber").toString().includes(values.orderNumber))
        }

        if (values.supplierItem) {
            orders = orders.filter(ord => {
                return ord.get("supplierItems").find(orderSupplierItem => {
                    return orderSupplierItem.supplierItem.get("supplierItemId") === values.supplierItem.value
                })
            })
        }

        return toJson ? orders.map((order) => order.toJSON()) : orders
    }
    catch (e) {
        return Promise.reject(e)
    }
}
/**
 * 
 * @param {Array} sites // array parse objects 
 * @param {Boolean} toJson 
 * @param {Object} filters 
 * @param {String} type  // creditNote | billings
 * @returns 
 */
export const getOrdersBySites = async (sites, toJson = true, filters = {}, type) => {
    try {
        if (!filters.dataPage) {
            filters.dataPage = 0
        }

        const sitesForQuery = filters.site ? [sites.find(site => site.id === filters.site.objectId)] : sites

        const query = new Parse.Query(Orders)
            .notEqualTo("deleted", true)
            .containedIn("site", sitesForQuery)

        if (type && type === "creditNote") {
            query.exists("creditNotesAmount")
                .greaterThan("creditNotesAmount", 0)
        }

        let { sortBy = "asc", sortField = null } = filters

        if (!sortField && type === "creditNote") {
            sortBy = "desc"
            sortField = "creditNotesAmount"
        }
        if (!sortField && type === "billings") {
            sortBy = "asc"
            sortField = "receptionDate"
        }

        sortBy === "asc" ? query.ascending(sortField) : query.descending(sortField)

        if (filters.deliveryNoteNumber) {
            query.matches("deliveryNote.number", filters.deliveryNoteNumber, "i")
        }

        if (filters.supplier) {
            query.containedIn("supplier.objectId", [filters.supplier.objectId])
        }

        if (filters.suppliers && filters.suppliers.length > 0) { // to remove still used for tab "avoirs" (removed next ticket)
            query.containedIn("supplier.objectId", filters.suppliers.map((supplier) => supplier.value))
        }

        if (filters.isCreditNotesPaid) {
            query.containedIn("isCreditNotesPaid", filters.isCreditNotesPaid)
        }

        if (filters.receptionPeriodStart) {
            query.greaterThanOrEqualTo("receptionDate", getTimezoneDate(filters.receptionPeriodStart, "timestamp", true))
        } else {
            // default reception start date filter
            const periodStart = type === "creditNote" ? dayjs().subtract(3, "months").startOf("day").valueOf() : dayjs().startOf("month").startOf("day").valueOf()
            query.greaterThan("receptionDate", periodStart)

        }

        if (filters.receptionPeriodEnd) {
            query.lessThanOrEqualTo("receptionDate", getTimezoneDate(filters.receptionPeriodEnd, "timestamp", false))
        } else {
            // default reception end date filter
            const periodEnd = type === "creditNote" ? dayjs().startOf("day").valueOf() : dayjs().startOf("day").valueOf()
            query.lessThanOrEqualTo("receptionDate", periodEnd)
        }

        if (filters.isBillingErrorOptions) {
            query.containedIn("billError", filters.isBillingErrorOptions)
        } else {
            // default billError filter
            query.containedIn("billError", [true, false])
        }

        if (filters.include) {
            query.include(filters.include)
        }

        let orders, total
        if (!filters.orderNumber) {
            [orders, total] = await Promise.all([
                (await query
                    .limit(parseLimitRequest)
                    .find()) || [],
                await query.count()
            ])
        } else {
            orders = await query.limit(parseLimitRequest).find()
            orders = orders.filter(ord => ord.get("orderNumber").toString().includes(filters.orderNumber))
            total = orders.length
        }

        let result = orders
        if (toJson) {
            result = orders.map((order) => order.toJSON())
        }

        return {
            orders: result,
            total
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getOrder(orderId, toJson = true) {
    try {
        const order = (await new Parse.Query(Orders)
            .equalTo("objectId", orderId)
            .include([
                "site.stockZones", "supplierItems.supplierItem", "supplierItems.lots", 
                "creditNotes", "supplierItems.creditNotes", "supplierItems.lots.creditNotes"
            ])
            .first()) || null

        return order ? toJson ? order.toJSON() : order : null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getOrderByOrderNumber(orderNumber, toJson = true) {
    try {
        const order = (await new Parse.Query(Orders)
            .equalTo("orderNumber", orderNumber)
            .include(["site", "supplierItems.supplierItem", "supplierItems.lots"])
            .first()) || null

        return order ? toJson ? order.toJSON() : order : null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getLastOrderNumber() {
    try {
        const lastOrderNumber = (await new Parse.Query(Orders)
            .select("orderNumber")
            .descending("createdAt")
            .first()) || null

        return lastOrderNumber ? lastOrderNumber.get("orderNumber") : null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createOrUpdateOrder(values, orderId = null) {
    try {
        let order

        if (!orderId) {
            order = new Orders()
        }
        else {
            order = await getOrder(orderId, false)
        }

        Object.entries(values).forEach(([key, value]) => {
            order.set(key, value)
        })

        await order.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createOrderReception(site, date, supplier) {
    try {
        const order = new Orders()

        const lastOrderNumber = (await new Parse.Query(Orders)
            .select("orderNumber")
            .descending("createdAt")
            .first()) || null

        const orderNumber = lastOrderNumber ? lastOrderNumber.get("orderNumber") + 1 : 1

        order.set("site", site)
        order.set("supplier", supplier)
        order.set("receptionDate", date)
        order.set("orderNumber", orderNumber)
        order.set("orderStatus", "DONE")
        order.set("receptionStatus", "TODO")
        order.set("supplierItems", [])

        await order.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function addOrderSupplierItem(orderId, supplierItemId) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const orderSupplierItem = await createOrderSupplierItem(supplierItemId)
            const supplierItems = [...order.get("supplierItems")]

            const newSupplierItem = {
                supplierItem: orderSupplierItem,
                quantities: { order: 0 },
                receptionStatus: "TODO",
                lots: [],
                deliveryNoteUnitPrice: null
            }

            supplierItems.push(newSupplierItem)

            order.set("supplierItems", supplierItems)

            const newOrder = await order.save()

            return newOrder.toJSON()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createOrderSupplierItemsForOrder(values, receptionStatus = null) {
    try {
        const supplierItems = []

        for (const { card, quantity } of values) {
            const orderSupplierItem = await createOrderSupplierItem(card.objectId)

            const newSupplierItem = {
                supplierItem: orderSupplierItem,
                quantities: { order: quantity },
                receptionStatus: receptionStatus,
                lots: [],
                deliveryNoteUnitPrice: null
            }

            supplierItems.push(newSupplierItem)
        }

        return supplierItems
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function deleteOrder(orderId) {
    try {
        const order = await getOrder(orderId, false)

        await order.destroy()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function cancelOrder(orderId, toJSON = true) {
    try {
        const order = await getOrder(orderId, false)

        order.set("orderStatus", "CANCELLED")
        order.set("receptionStatus", "CANCELLED")

        const newOrder = await order.save()

        return toJSON ? newOrder.toJSON() : newOrder
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateOrderSupplierItem(orderId, supplierItemId, status, values) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const supplierItems = [...order.get("supplierItems")]
            const current = supplierItems.find(el => el.supplierItem.id === supplierItemId)

            if (current) {
                if (!current.quantities) current.quantities = {}

                if (values) {
                    current.quantities.reception = values.quantity
                    current.cancelQuantitiesCreditNotes = values.cancelQuantitiesCreditNotes
                }
                current.receptionStatus = status

                order.set("supplierItems", supplierItems)

                const updatedOrder = await order.save()

                return updatedOrder.toJSON()
            }

            return null
        }

        return null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateOrderShippingFees(orderId, shippingFees) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const supplier = order.get("supplier")

            if (!supplier.order) supplier.order = {}
            supplier.order.shippingFees = Number(shippingFees)
            order.set("supplier", supplier)

            const updatedOrder = await order.save()

            return updatedOrder.toJSON()
        }

        return null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const updateArticleDeliveryNoteQuantityAndPrice = async (orderId, supplierItemId, values) => {
    try {
        const order = await getOrder(orderId, false)

        if (!order) return

        const supplierItems = order.get("supplierItems")?.map(orderSupplierItem => {
            if (orderSupplierItem.supplierItem.id === supplierItemId) {
                orderSupplierItem.quantities.deliveryNote = Number(values.quantity)
                if (values.deliveryNoteUnitPrice) {
                    orderSupplierItem.deliveryNoteUnitPrice = Number(values.deliveryNoteUnitPrice)
                }
            }
            return orderSupplierItem
        })

        order.set("supplierItems", supplierItems || [])

        const updatedOrder = await order.save()

        return updatedOrder.toJSON()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateArticleBillQuantityAndPrice(orderId, supplierItemId, values) {

    const order = await getOrder(orderId, false)
    if (order) {
        const supplierItems = [...order.get("supplierItems")]
        const supplierItem = supplierItems.find(el => el.supplierItem.id === supplierItemId)

        if (supplierItem) {
            supplierItem.quantities.bill = Number(values.quantity)
            supplierItem.billUnitPrice = Number(values.billUnitPrice)

            order.set("totalAmount", supplierItems.reduce((amount, item) => {
                const accumulatedValue = item.quantities.bill * item.billUnitPrice + amount
                return accumulatedValue
            }, 0))
            order.set("supplierItems", supplierItems)

            const updatedOrder = await order.save()
            return updatedOrder.toJSON()
        }
    }
    return null
}

export const initializeDeliveryNoteUnitPrices = async (orderId, isRefusedReception = false) => {
    try {
        const order = await getOrder(orderId, false)
        if (!order) return

        // no need to the deliveryNoteUnitPrice if the order is refused
        if (!isRefusedReception) {
            const supplierItems = [...order.get("supplierItems")]

            supplierItems.map(supplierItem => {
                if (!supplierItem.deliveryNoteUnitPrice) {
                    supplierItem.deliveryNoteUnitPrice = supplierItem.supplierItem.get("units").stock.price
                }
    
                return supplierItem
            })
    
            order.set("supplierItems", supplierItems)
    
            const updatedOrder = await order.save()
    
            return updatedOrder.toJSON()
        }

        return order.toJSON()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function startOrderReception(orderId) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            order.set("receptionStatus", "IN_PROGRESS")
            order.set("receptionDate", moment.utc().startOf("day").valueOf())

            const newOrder = await order.save()

            return newOrder.toJSON()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export const getCreditNotesLotsTotalAmount = (lots = []) => {
    let lotsTotalAmount = 0
    for (const lot of lots) {
        if (lot.creditNotes) {
            const totalAmount = lot.creditNotes.reduce((acc, creditNote) => {
                return acc + creditNote.amount
            }, 0)

            lotsTotalAmount += totalAmount
        }
    }

    return lotsTotalAmount
}

export const updateOrderCreditNotesByTotalAmount = async (orderId) => {
    try {
        const order = await getOrder(orderId, false)
        const orderJson = order.toJSON()

        let totalCreditNoteAmount = 0

        for (const supplierItem of orderJson.supplierItems) {
            // credit notes for supplier item global
            if (supplierItem.creditNotes) {
                const ids = supplierItem.creditNotes.map(creditNote => creditNote.objectId)
                let price

                if (supplierItem.deliveryNoteUnitPrice) {
                    price = supplierItem.deliveryNoteUnitPrice
                }
                else {
                    price = supplierItem.supplierItem.units.order.price
                }

                const creditNotes = await getCreditNotesForSupplierItem(ids, false)

                for (const creditNote of creditNotes) {
                    if (creditNote.get("type") === creditNotesType.BROKEN) {
                        const quantity = creditNote.get("quantity")
                        creditNote.set("amount", parseFloat(price * quantity))
                    }

                    if (creditNote.get("amount") !== null) {
                        totalCreditNoteAmount += creditNote.get("amount")
                    }

                    await creditNote.save()
                }
            }

            // credit notes for supplier item lots
            if (supplierItem.lots) {
                totalCreditNoteAmount += getCreditNotesLotsTotalAmount(supplierItem.lots)
            }
        }

        order.set("creditNotesAmount", totalCreditNoteAmount)
        await order.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

/**
 * the creditNotesAmount is the same as the order totalAmount
 * instead of the sum of creditNotes amount
 * when the order is refused
 */
export const updateOrderCreditNotesAmount = async (orderId, totalAmount) => {
    try {
        const order = await getOrder(orderId, false)

        if (!order) return

        order.set("creditNotesAmount", totalAmount)
        await order.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}


export async function closeOrderReception(orderId, creditNotes, totalAmount) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const supplierItems = [...order.get("supplierItems").map((supplierItem) => ({
                ...supplierItem,
                billUnitPrice: supplierItem.deliveryNoteUnitPrice,
                quantities: {
                    ...supplierItem.quantities,
                    bill: supplierItem.quantities.deliveryNote != null ? supplierItem.quantities.deliveryNote : supplierItem.quantities.reception
                }
            }))]

            if (creditNotes && creditNotes.size > 0) {
                await Promise.all(supplierItems.map(async supplierItem => {
                    const creditNoteData = creditNotes.get(supplierItem.supplierItem.id)

                    const newCreditNotes = []

                    if (creditNoteData && Array.isArray(creditNoteData)) {
                        await Promise.all(creditNoteData.map(async el => {
                            const creditNote = await createCreditNote(el, creditNotesType.DELIVERY_NOTE, supplierItem.supplierItem)

                            newCreditNotes.push(creditNote)
                        }))
                    }

                    supplierItem.creditNotes = Array.isArray(supplierItem.creditNotes)
                        ? [...supplierItem.creditNotes, ...newCreditNotes]
                        : newCreditNotes
                }))
            }

            order.set("supplierItems", supplierItems)
            order.set("receptionStatus", "DONE")
            order.set("totalAmount", totalAmount)
            order.set("billError", false)
            order.set("isCreditNotesPaid", false)

            const updatedOrder = await order.save()
            return updatedOrder.toJSON()
        }

        return null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function saveOrderDeliveryNote(orderId, values) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            if (!order.get("deliveryNote")?.inputDate) {
                values.inputDate = moment.utc().valueOf()
            } else {
                values.inputDate = order.get("deliveryNote").inputDate
            }

            order.set("deliveryNote", values)

            const newOrder = await order.save()

            return newOrder.toJSON()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createOrderLots(orderId, orderSupplierItem, lots, creditNotes) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const copy = cloneDeep(order.get("supplierItems"))
            const currentObject = copy.find(el => el.supplierItem.id === orderSupplierItem.supplierItem.objectId)
            const parseLots = await Promise.all(lots.map(async lot => {
                const newLot = await createLot(lot, currentObject.supplierItem)
                const parseLot = await newLot.save()

                const lotCreditNotesData = creditNotes.get(lot.id) || []
                let decrementedQuantity = 0
                const lotCreditNotes = await Promise.all(lotCreditNotesData.map(async creditNote => {
                    const newCreditNote = await createCreditNote(creditNote.creditNote, creditNotesType.LOT, parseLot)
                    await addLotEvent(parseLot.id, {
                        mode: "BROKEN",
                        user: Parse.User.current().toJSON(),
                        date: moment.utc().valueOf(),
                        quantity: newCreditNote.get("quantity"),
                        reason: newCreditNote.get("reason"),
                        comment: newCreditNote.get("comment"),
                        images: newCreditNote.get("images")
                    })
                    decrementedQuantity += newCreditNote.get("quantity")
                    return newCreditNote
                }))

                let update = false

                if (lotCreditNotes.length > 0) {
                    parseLot.set("creditNotes", lotCreditNotes)
                    update = true
                }
                if (decrementedQuantity > 0) {
                    parseLot.set("quantity", parseLot.get("quantity") - decrementedQuantity)
                    update = true
                }

                return update ? parseLot.save() : parseLot
            }))

            const supplierItemCreditNotesData = creditNotes.get("global") || []
            const supplierItemCreditNotes = await Promise.all(supplierItemCreditNotesData.map(async creditNote => {
                const newCreditNote = await createCreditNote(creditNote.creditNote, creditNotesType.BROKEN, currentObject.supplierItem)
                return newCreditNote
            }))

            if (supplierItemCreditNotes.length > 0) {
                currentObject.creditNotes = supplierItemCreditNotes
            }

            currentObject.lots = parseLots
            currentObject.receptionStatus = "DONE"


            order.set("supplierItems", copy)

            return await order.save()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function addLotCreditNote(lotId, creditNote, toJson = true) {
    try {
        const parseLot = await getLot({ id: lotId, toJson: false })

        let decrementedQuantity = 0
        const newCreditNote = await createCreditNote(creditNote, creditNotesType.LOT, parseLot)
        await addLotEvent(parseLot.id, {
            mode: "BROKEN",
            user: Parse.User.current().toJSON(),
            date: moment.utc().valueOf(),
            quantity: newCreditNote.get("quantity"),
            reason: newCreditNote.get("reason"),
            comment: newCreditNote.get("comment"),
            images: newCreditNote.get("images")
        })
        decrementedQuantity += newCreditNote.get("quantity")


        parseLot.set("creditNotes", [newCreditNote])

        parseLot.set("quantity", parseLot.get("quantity") - decrementedQuantity)

        const savedLot = await parseLot.save()

        return toJson ? savedLot.toJSON() : savedLot
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function addOrderCreditNote(orderId, creditNotesData, toJson = false) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            let newCreditNotes = []
            const creditNotes = await Promise.all(creditNotesData.map(async item => await createCreditNote(item, creditNotesType.DELIVERY)))

            if (Array.isArray(order.get("creditNotes"))) {
                newCreditNotes = newCreditNotes.concat(order.get("creditNotes"), creditNotes)
            }
            else {
                newCreditNotes = newCreditNotes.concat(creditNotes)
            }

            order.set("creditNotes", newCreditNotes)

            await order.save()

            return toJson ? order.toJSON() : order
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateOrderReceptionStatus(orderId, supplierItemId, toJson = true) {
    try {
        const order = await getOrder(orderId, false)

        if (order) {
            const supplierItems = [...order.get("supplierItems")]
            const currentSupplierItem = supplierItems.find(el => el.supplierItem.id === supplierItemId)

            if (currentSupplierItem) {
                if (!currentSupplierItem.quantities) currentSupplierItem.quantities = {}
                const newQuantities = { ...currentSupplierItem.quantities }

                if (newQuantities.reception) {
                    delete newQuantities.reception
                }

                if (newQuantities.deliveryNote) {
                    delete newQuantities.deliveryNote
                }
                currentSupplierItem.quantities = { ...newQuantities }
                currentSupplierItem.receptionStatus = "TODO"

                for (const lot of currentSupplierItem.lots) {
                    await deleteLot(lot.id)
                }

                currentSupplierItem.lots = []

                if (currentSupplierItem.creditNotes) {
                    for (const creditNote of currentSupplierItem.creditNotes) {
                        await deleteCreditNote(creditNote.id)
                    }

                    currentSupplierItem.creditNotes = []
                }

                order.set("supplierItems", supplierItems)
            }

            const updatedOrder = await order.save()

            return toJson ? updatedOrder.toJSON() : updatedOrder
        }

        return null
    }
    catch (e) {
        return Promise.reject(e)
    }
}

// Used to update isBillError field for order document
export async function updateIsBillingError(orderId, isBillingError) {
    try {
        const order = await new Parse.Query(Orders)
            .equalTo("objectId", orderId)
            .first()

        order.set("billError", isBillingError)

        return await order.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function createOrderDuplicata(orderId, orderNumber) {
    try {
        await Parse.Cloud.run("duplicateOrder", { orderId, orderNumber })
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getComingOrders(supplierItemId, toJson = true) {
    try {
        const orderSupplierItems = await getOrderSupplierItemsBySupplierItem(supplierItemId)
        const orderSupplierItemIds = orderSupplierItems.map(orderSupplierItem => orderSupplierItem.objectId)

        const orders = await new Parse.Query(Orders)
            .include(["supplierItems.receptionStatus"])
            .containedIn("receptionStatus", ["IN_PROGRESS", "TODO"])
            .equalTo("supplierItems.receptionStatus", "TODO")
            .greaterThanOrEqualTo("receptionDate", dayjs.tz().startOf("day").valueOf())
            .containedIn("supplierItems.supplierItem.objectId", orderSupplierItemIds)
            .ascending("receptionDate")
            .limit(parseLimitRequest).find() || []

        return toJson ? orders.map((order) => order.toJSON()) : orders
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function recalculateTotalAmountOrdersByDate(orderDate) {
    try {
        const orders = await new Parse.Query(Orders)
          .include(["supplierItems"])
          .greaterThanOrEqualTo("receptionDate", orderDate)
          .equalTo("totalAmount", 0)
          .equalTo("receptionStatus", "DONE")
          .limit(1000)
          .find()

        orders.forEach(async order => {
            const totalAmount = getOrderTotalAmount(order.toJSON())
            order.set("totalAmount", totalAmount)
            await order.save()
        })
    }
    catch (e) {
        console.error("parseManager.recipe.parseOrdersManager.recalculateTotalAmountOrders error : ", e)
        return Promise.reject(e)
    }
}