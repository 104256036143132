import React from "react"
import { Formik, Form } from "formik"
import { TextField, FormControl } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flexWrap: "wrap"
  }
}))

export const distributionCenterDatesFormId = "distributionCenterDatesFormId"

const DistributionCenterDatesForm = ({ distributionCenter, handleSubmit }) => {

  const classes = useStyles()


  const openDateChange = (value, setFieldValue) => {
    setFieldValue("openDate", value)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        openDate: distributionCenter.openDate

      }}>
      {({ values, setFieldValue }) => (
        <Form
          id={distributionCenterDatesFormId}>
          <div className={classes.dateContainer}>
            <div>
              <FormControl variant="standard">
                <DatePicker
                  disableCloseOnSelect={false}
                  showToolbar={false}
                  variant="inline"
                  value={values.openDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => openDateChange(e, setFieldValue)}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Date d'inauguration" />}
                />
              </FormControl>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DistributionCenterDatesForm