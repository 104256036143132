import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import OrdersHeaderTitle from "../../../components/Order/Orders/OrdersHeaderTitle"
import { showOrdersSiteSelector } from "../../../actions/Orders/Orders"
import { Tab, Tabs } from "@mui/material"
import OrdersList from "./OrdersList"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import GenericHeaderInfo from "../../../components/GenericHeaderInfo"
import OrdersReceptionsList from "./OrderReceptionsList"

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    headerBloc: {
        height: 64
    },
    tab: {
        paddingLeft: 45,
        paddingRight: 45,
    }
}))

const OrdersTabs = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const suppliers = useSelector(state => state.orders.suppliers)
    const orders = useSelector(state => state.orders.orders)
    const site = useSelector(state => state.orders.site)
    const supplierItems = useSelector(state => state.orders.supplierItems)
    const savedFilter = useSelector(state => state.orderReception.orderReceptionsFilters)

    const [tabValue, setTabValue] = useState(0)

    useEffect(() => {
        if (!savedFilter) return
        setTabValue(1)
    }, [savedFilter])


    const onBack = () => {
        dispatch(showOrdersSiteSelector())
    }

    const onTabChange = async (e, tabValue) => {
        localStorage.setItem("useStoredDataGridFilters", "1")
        setTabValue(tabValue)
    }
    return (
      <>
          <GenericTopBarHeader
            leftAction={<GenericHeaderReturnButton handleClick={onBack}/>}
            title={<OrdersHeaderTitle />}
            info={<GenericHeaderInfo info={` - ${site ? site.name.toUpperCase() : ""}`}/>}
          />
          <Tabs
            value={tabValue !== null && tabValue !== undefined ? tabValue : false}
            onChange={onTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
                borderBottom: "1px solid #E6E6E6",
                "& .MuiTab-root": {
                    padding: "16px 24px"
                }
            }}
            centered
          >
              <Tab label="Passage de commande"  classes={{ root: classes.tab }} />
              <Tab label="Commandes passées"  classes={{ root: classes.tab }} />
          </Tabs>
          {
            tabValue === 0 &&
            <OrdersList
              site={site}
              suppliers={suppliers}
              ordersData={orders}
            />
          }
          {
            tabValue === 1 &&
            <OrdersReceptionsList
              suppliers={suppliers}
              site={site}
              savedFilter={savedFilter}
              supplierItems={supplierItems}
            />
          }
      </>
    )
}

export default OrdersTabs