import React from "react"
import { withStyles} from "@mui/styles"
import Paper from "@mui/material/Paper"
import { COLORS, roundNumber } from "../../utils"
import { mapSeasonsToPeriodsLabels, getMergedCommercialNames } from "../../utils/recipes"

export const RecipeResume = (props) => {
    const {classes, jsonRecipe} = props

    const seasonsPeriodsLabels = jsonRecipe && Array.isArray(jsonRecipe.season)
        ? mapSeasonsToPeriodsLabels(jsonRecipe.season)
        : []
    
    const commercialNames = getMergedCommercialNames(jsonRecipe && jsonRecipe.ingredients)
    const commercialNamesValues = commercialNames.map(elem => elem.commercialName && elem.commercialName.name && `${elem.commercialName.name} (${elem.percentage} %)`).filter(Boolean).join(", ")
    const commercialNamesLabel = commercialNames.length > 1 ? "Ingrédients" : "Ingrédient"

    const complexity = commercialNames.map(elem => elem.commercialName ? elem.commercialName.complexity : 0).reduce((sum, value) => sum + value, 0)

    const allergens = commercialNames.map(elem => elem.commercialName && elem.commercialName.allergens).flat().filter(Boolean)
    const allergensNames = Array.from(new Set(allergens.map(allergen => allergen.name).filter(Boolean)))
    const allergensValues = allergensNames.join(", ")
    const allergensLabel = "Allergène".concat(allergensNames.length > 1 ? "s" : "")
    const netWeight = jsonRecipe && roundNumber(jsonRecipe.netWeight * 1000, 0)

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Résumé de la recette
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Poids net
                </div>
                <div className={classes.valueContainer}>
                    {netWeight}&nbsp;g
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                        Saison
                </div>
                <div className={classes.valueContainer}>
                       {
                           seasonsPeriodsLabels.map((label, index) =>
                               <div key={index}>{label}</div>
                           )
                       }
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    {commercialNamesLabel}
                </div>
                <div className={classes.valueContainer}>
                    {commercialNamesValues}
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    {allergensLabel}
                </div>
                <div className={allergensValues.length ? classes.valueContainer : classes.greyValueContainer}>
                    {
                        allergensValues.length
                            ? allergensValues
                            : "Pas d'allergène"
                    }
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Complexité
                </div>
                <div className={classes.valueContainer}>
                    {complexity}
                </div>
            </div>

        </Paper>
    )
}

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        marginTop: 16,
        paddingBottom: 16,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)"
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    singleValueContainer: {
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 16,
        display: "flex"
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: 128,
        minWidth: 128,
        display: "flex",
        alignItems: "center"
    },
    valueContainer: {
    },
    greyValueContainer: {
        composes: "$valueContainer",
        color: COLORS.DISABLED
    }
}

export default withStyles(styles)(RecipeResume)
