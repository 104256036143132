import Parse from "parse"
import { maxBy } from "lodash"

import { generateRandomToken } from "../../utils"
import { canAccessSettingAdministration } from "../../utils/accessRights"
import { actionWithLoader, getReplacingPath, haveAccessRight, onEnter, push } from "../Utils/utils"
import { setValues } from "../../parseUtils"
import { formProductTypesToSave } from "../../utils/productTypesUtils"
import { getProductTypesFiltersSelector, getProductTypesSelector } from "../../reducers/ProductTypes/productTypes"
import { getFilteredProductTypes, getProductTypeById, getProductTypes } from "../../parseManager/products/productTypeManager"
import { productTypeAllOption } from "../../utils/dispatchUtils"

const PRODUCT_TYPES_PROPERTIES = new Set(["label", "kfcName", "fcSzName", "uniqueCode", "managementMode", "brands", "order", "adj"])

export const ProductTypes = Parse.Object.extend("ProductTypes")

export const loadProductTypes = (filters) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const savedFilters = getProductTypesFiltersSelector(state)
        const newFilters = { ...savedFilters, ...filters }

        const result = await getFilteredProductTypes(newFilters)
        const productTypes = result.results
        const total = result.count

        dispatch({
            type: "PRODUCT_TYPES_LOADED",
            productTypes: productTypes.map((step) => step.toJSON()),
        })
        dispatch({
            type: "PRODUCT_TYPES_TOTAL_LOADED",
            total,
        })
        dispatch({
            type: "PRODUCT_TYPES_FILTERS_LOADED",
            filters: newFilters
        })
    })
}

export const loadProductTypeOptions = (filters, withAll = true) => {
    return actionWithLoader(async (dispatch) => {
        const productTypeOptions = await getProductTypeOptions(filters)

        dispatch({
            type: "PRODUCT_TYPE_OPTIONS_LOADED",
            productTypeOptions: withAll ? [productTypeAllOption, ...productTypeOptions] : [...productTypeOptions],
        })
    })
}

export const getProductTypeOptions = async (filters) => {
    const productTypes = await getProductTypes(filters)

    // for refactoring purpose (static to database data),
    // the data format should be the same as the static one in /utils/dispatchUtils productType
    return productTypes.map(productType => ({
        value: productType.kfcName,
        type: productType.kfcName,
        label: productType.label,
        managementMode: productType.managementMode,
        order: productType.order,
        adj: productType.adj
    }))
}

/**
 * save the form values to the store, not in the database yet
 * @param {*} values form values
 * @returns 
 */
export const addProductTypesValues = (values) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const productTypes = getProductTypesSelector(state) || []

        const productTypeWithMaxOrder = maxBy(productTypes, productType => productType.order)
        const order = productTypeWithMaxOrder ? productTypeWithMaxOrder.order + 1 : 1

        const newProductType = {
            objectId: generateRandomToken(),
            isNew: true,
            order,
            ...values,
        }

        const newProductTypes = [...productTypes, newProductType]

        dispatch({
            type: "PRODUCT_TYPES_LOADED",
            productTypes: newProductTypes
        })
    })
}

/**
 * save and update the database for the edited, added, and deleted product types
 * @param {*} actions action to know if the selected product types is new, edited or deleted
 * @returns 
 */
export const saveProductTypes = (productTypes) => {
    return actionWithLoader(async (dispatch) => {
        const editedProductTypesPromise = []
        const newProducTypesPromise = []

        /*
            we are going to launch update action in parallel
        */
        for (const productType of productTypes) {
            if (productType.isNew) {
                newProducTypesPromise.push(createProductType(productType))
            } else if (productType.edited) {
                editedProductTypesPromise.push(updateProductTypeOrder(productType))
            }
        }

        await Promise.all([...newProducTypesPromise, ...editedProductTypesPromise])

        dispatch({
            type: "CLEAR_PRODUCT_TYPES",
        })
        dispatch(showAdministrationProductTypes())
    })
}

const updateProductTypeOrder = async (productType) => {
    const parseObjectProductType = await getProductTypeById(productType.objectId, false)

    if (parseObjectProductType) {
        setValues(parseObjectProductType, productType, PRODUCT_TYPES_PROPERTIES)
        await parseObjectProductType.save()
    }
}

const createProductType = async productType => {
    const values = formProductTypesToSave(productType)

    const newProductType = new ProductTypes()
    setValues(newProductType, values, PRODUCT_TYPES_PROPERTIES)

    await newProductType.save()
}

export function onEnterProductTypes(store) {
    return onEnter({
        store,
        actionThunk: loadProductTypes,
        getReplacingPath: getReplacingPath({ needUser: true }),
    })
}

export function onEnterSettingsAdministrationtTab(store) {
    return onEnter({
        store,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight(canAccessSettingAdministration)
    })
}

export const showAdministrationProductTypes = () => {
    return push("/settings/administration/productTypes")
}

export const showProductTypesEdition = () => {
    return push("/settings/administration/productTypes/edition")
}
