import React from "react"
import { Stack, styled } from "@mui/material"
import { getBrandsData } from "../utils"

const StyledImage = styled("img")({
    width: 24,
})

const Brands = ({ brands }) => (
    <Stack spacing={1} direction="row">
        {
            getBrandsData(brands).map((brand, index) => (
                <div key={brand.shortname + index}>
                    <StyledImage alt={`logo${brand.shortname}`} src={brand.image} />
                </div>
            ))
        }
    </Stack>
)

export default Brands