import React from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	modal: {
		position: "absolute",
		width: 438,
		minHeight: 183,
		background: COLORS.WHITE,
		textAlign: "justify",
		color: COLORS.BLACK,
		padding: "40px 30px",
		fontSize: 16,
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	},
	actions: {
		display: "flex",
		justifyContent: "flex-end",
		paddingTop: 15,
		marginBottom: 10
	},
	title: {
		fontWeight: 500,
		fontSize: 15,
		lineHeight: "24px",
		paddingBottom: 15
	},
	text: {
		fontSize: 12
	},
	firstButton: {
		marginRight: 10,
	}
})

const OrderDeleteModal = (props) => {
	const {
		onClose,
		onDelete,
		open
	} = props
	
	const classes = useStyles()
	
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<div className={classes.modal}>
				<div className={classes.title}>
					Supprimer la commande
				</div>
				<div className={classes.text}>
					Êtes-vous certains de vouloir supprimer cette commande ?
				</div>
				<div className={classes.actions}>
					<Button
						color="primary"
						onClick={onClose}
						variant="outlined"
						className={classes.firstButton}
					>
						ANNULER
					</Button>
					<Button
						color="primary"
						type="submit"
						onClick={onDelete}
						variant="contained"
					>
						SUPPRIMER
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default OrderDeleteModal