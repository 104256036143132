import React, {useCallback, useEffect, useState} from "react"
import DataGrid from "../../components/DataGrid/DataGrid"
import {generateOrderUnitLabel} from "../../utils/ordersUtils"

const ProductionReceptionDone = (props) => {
    const {
        // site,
        productionSupplies,
    } = props

    const [data, setData] = useState([])

    useEffect(() => {
        createDataObject()
    }, [productionSupplies])

    const createDataObject = useCallback(() => {
        productionSupplies && setData(productionSupplies.map(createData))
    })

    const columns = [
        {
            key: "name",
            label: "Nom du produit",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "compliance",
            label: "Lots sortis",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (value) => {
                const labelColor = value === "OK" ? "#555" : "#F44259"
                const label = value === "OK" ? "Conforme" : "Non conforme"

                return (
                  <div><span style={{color: labelColor}}>{label}</span></div>
                )
            }
        },
        {
            key: "quantity",
            label: "Quantité comptée / sortie",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
            },
            render: (productionSupply) => {
                const realQuantity = productionSupply.realQuantity
                const outputQuantity = productionSupply.outputQuantity
                const unit = generateOrderUnitLabel(productionSupply.supplierItem)
                const realQuantityColor = realQuantity !== outputQuantity ? "#F44259" : "#7C7C7C"

                return (
                    <div>
                        <span style={{ color: realQuantityColor }}>{realQuantity}</span>
                        <span style={{color: "#555"}}> / {outputQuantity} </span>
                        <span style={{color: "#7C7C7C"}}>({unit})</span>
                    </div>
                )
            }
        },
    ]

    const createData = (productionSupply) => {
        return {
            id: productionSupply.objectId,
            name: productionSupply.supplierItem.name,
            quantity: productionSupply,
            compliance: productionSupply.compliance,
        }
    }

    return (
        <>
            <DataGrid
                title={"Produits réceptionnés"}
                columns={columns}
                data={data}
                withFilters={true}
                rowLabel={null}
                onRowClick={() => console.log("onRowClick TODO")}
                withAdditionalFiltersOptions={false}
                tableContainerStyle={{boxShadow: "none"}}
            />
        </>
    )
}

export default ProductionReceptionDone