import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import {
  canMarkSupplierItemAsActive,
  editionInitialValues,
  productTypeToAdd,
  calculatePricePerKgByBillings
} from "../../utils/supplierItemUtils"
import {
  salableSupplierItemResumeEditionSchema,
  supplierItemResumeEditionSchema,
} from "../../utils/yupValidators"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ModalForm from "../form/ModalForm"
import ModalActions from "../ModalActions"
import Switch from "../form/Switch"
import { COLORS } from "../../utils"
import clsx from "clsx"
import { getProductTypes } from "../../../src/parseManager/products/productTypeManager"

const useStyles = makeStyles({
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      fontSize: 16,
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      height: 20,
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  formControlDisabled: {
    opacity: 0.4,
    pointerEvents: "none",
  },
  errorField: {
    color: COLORS.RED,
    marginTop: 7,
  },
  textField: {
    marginTop: 7,
  },
})

export const SupplierItemResumeForm = (props) => {
  const {
    onClose,
    open,
    supplierItem,
    onSave
  } = props

  const [isActive, setIsActive] = useState(false)
  const [canActivate, setCanActivate] = useState(false)
  const [openActivateInfoDialog, setOpenActivateInfoDialog] = useState(false)
  const [supplierItemProductTypes, setSupplierItemProductTypes] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setIsActive(!!supplierItem.isActive)

    setCanActivate(canMarkSupplierItemAsActive(supplierItem))
  }, [supplierItem])

  // const dispatch = useDispatch();
  const handleStateChange = async (e) => {
    const { checked } = e.target
    setIsActive(checked)
  }

  const submitForm = async (values) => {
    const newValues = { ...values, isActive }
    onSave("RESUME", newValues)
    onClose()
  }

  const getPricePerKgValue = (billingUnitPrice) => {
    if (
      billingUnitPrice &&
      supplierItem.units &&
      supplierItem.units.billing &&
      supplierItem.units.billing.weight
    ) {
      const pricePerKg = calculatePricePerKgByBillings(billingUnitPrice, supplierItem.units.billing.weight)
      return pricePerKg
    }
  }
  useEffect(() => {
    const fetchProductTypes = async () => {
      setSupplierItemProductTypes(await getProductTypes())
    }
    fetchProductTypes()
  }, [])

  const renderProductTypeOptions = () => {
    return supplierItemProductTypes.map((el, i) => (
      <MenuItem key={i} value={el.kfcName}>
        {el.label}
      </MenuItem>
    ))
  }

  const toggleActivateInfoDialog = () => setOpenActivateInfoDialog(!openActivateInfoDialog)

  const renderProductTypes = () => {
    return productTypeToAdd.map((el, i) => (
      <MenuItem key={i} value={el.value}>
        {el.label}
      </MenuItem>
    ))
  }
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Modifier le résumé de l’article"
      width={500}
      icon={
        <Switch
          name="isActive"
          checked={isActive}
          onChange={canActivate ? handleStateChange : toggleActivateInfoDialog}
          color="secondary"
        />
      }
    >
      <Formik
        initialValues={editionInitialValues(supplierItem)}
        validationSchema={
          (supplierItem.type === "PACKAGING_CONSUMABLE" ||
            supplierItem.type === "SALABLE_PRODUCT")
            ? salableSupplierItemResumeEditionSchema
            : supplierItemResumeEditionSchema
        }
        onSubmit={submitForm}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {
          return (
            <Form>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel}>
                  Nom de l'article
                </InputLabel>
                <TextField
                  variant="standard"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name ? (
                  <div className={classes.errorField}>{errors.name}</div>
                ) : null}
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel}>
                  Nom pour le fournisseur
                </InputLabel>
                <TextField
                  variant="standard"
                  name="supplierArticleName"
                  value={values.supplierArticleName}
                  onChange={handleChange}
                />
                {errors.supplierArticleName ? (
                  <div className={classes.errorField}>
                    {errors.supplierArticleName}
                  </div>
                ) : null}
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel}>
                  Référence fournisseur
                </InputLabel>
                <TextField
                  variant="standard"
                  name="supplierArticleId"
                  value={values.supplierArticleId}
                  onChange={handleChange}
                  type="text"
                />
                {errors.supplierArticleId ? (
                  <div className={classes.errorField}>
                    {errors.supplierArticleId}
                  </div>
                ) : null}
              </FormControl>
              <FormControl className={classes.formControl} variant="standard">
                <InputLabel className={classes.formLabel}>
                  Disponibilité
                </InputLabel>
                <Select
                  variant="standard"
                  name="availabilityAtSupplier"
                  value={values.availabilityAtSupplier}
                  onChange={handleChange}
                >
                  {[{ label: "En stock chez le fournisseur", value: true },
                  { label: "En rupture de stock chez le fournisseur", value: false }].map((option, index) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel}>EAN</InputLabel>
                <TextField
                  variant="standard"
                  name="ean"
                  value={values.ean}
                  onChange={handleChange}
                />
                {errors.ean ? (
                  <div className={classes.errorField}>{errors.ean}</div>
                ) : null}
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel className={classes.formLabel}>
                  Prix par unité de facturation
                </InputLabel>
                <TextField
                  variant="standard"
                  name="billingUnitPrice"
                  value={values.billingUnitPrice}
                  InputProps={{
                    type: "number",
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    disableUnderline: false,
                  }}
                  onChange={(e) => {
                    handleChange(e)
                    setFieldValue(
                      "pricePerKg",
                      getPricePerKgValue(e.target.value)
                    )
                  }}
                />
                {errors.billingUnitPrice ? (
                  <div className={classes.errorField}>
                    {errors.billingUnitPrice}
                  </div>
                ) : null}
              </FormControl>
              <FormControl
                className={clsx(
                  classes.formControl,
                  classes.formControlDisabled
                )}
              >
                <InputLabel className={classes.formLabel}>
                  Prix au kg
                </InputLabel>
                <TextField
                  variant="standard"
                  name="pricePerKg"
                  value={values.pricePerKg}
                  onChange={handleChange}
                  InputProps={{
                    type: "number",
                    disableUnderline: false,
                  }}
                />
                {errors.pricePerKg ? (
                  <div className={classes.errorField}>{errors.pricePerKg}</div>
                ) : null}
              </FormControl>
              {supplierItem.type === "SALABLE_PRODUCT" && (
                <FormControl className={classes.formControl} variant="standard">
                  <InputLabel className={classes.formLabel}>
                    Type de produit
                  </InputLabel>
                  <Select
                    variant="standard"
                    name="productType"
                    value={values.productType}
                    onChange={handleChange}
                  >
                    {renderProductTypeOptions()}
                  </Select>
                  {errors.productType ? (
                    <div className={classes.errorField}>
                      {errors.productType}
                    </div>
                  ) : null}
                </FormControl>
              )}
              {supplierItem.type === "PACKAGING_CONSUMABLE" && (
                <FormControl className={classes.formControl} variant="standard">
                  <InputLabel className={classes.formLabel}>
                    Type de produit
                  </InputLabel>
                  <Select
                    variant="standard"
                    name="productType"
                    value={values.productType}
                    onChange={handleChange}
                  >
                    {renderProductTypes()}
                  </Select>
                  {errors.productType ? (
                    <div className={classes.errorField}>
                      {errors.productType}
                    </div>
                  ) : null}
                </FormControl>
              )}
              <ModalActions onCancel={onClose} onSubmit={handleSubmit} />
            </Form>
          )
        }}
      </Formik>

      {/* -------------- can activate supplier item dialog -------------- */}
      <Dialog
        onClose={toggleActivateInfoDialog}
        aria-labelledby="customized-dialog-title"
        maxWidth="xs"
        open={openActivateInfoDialog}
      >
        <DialogContent>
          <Typography>
            Il faut paramétrer entièrement l'article pour pouvoir le passer en actif
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={toggleActivateInfoDialog}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </ModalForm>
  )
}

export default SupplierItemResumeForm
