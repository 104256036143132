import { Dialog, styled } from "@mui/material"

const StyledCreationDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "minWidth"
})((props) => ({
  "& .MuiDialog-paper": {
    minWidth: props.width || "unset",
    width: props.width || "unset",
    borderRadius: 10,
    padding: 32,
    display: "flex",
    flexDirection: "column",
    gap: 40,
    "& > .MuiDialogTitle-root, & > .MuiDialogContent-root, & > .MuiDialogActions-root": {
      padding: 0
    }
  }
}))

export default StyledCreationDialog