import React from "react"
import PropTypes from "prop-types"

import {
  StyledStepBodyCell,
  StyledStepFirstBodyColumn,
  StyledStepText
} from "../StyledSectionComponents"
import StepNameDescription from "./StepNameDescription"
import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../../utils/constant"
import { roundNumber } from "../../../../utils"
import { getTransformationTypeLabel } from "../../../../utils/supplierItemUtils"
import { convertKilosIntoGrams } from "../../../../utils/ressources"
import {getEndStepWeighingLabelByValue, getStepDateLabelByValue} from "../../../../utils/productionStepExecution"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const StepPreview = ({ step, isEdition, isReusable = false }) => {

  return (
    <>
      <StyledStepFirstBodyColumn className="flexRow center" isReusable={isReusable}>
        <StepNameDescription
          name={step.name}
          // display "Instruction:" when adding an empty section
          description={isEdition && step.error ? "Instructions :" : step.description}
          stepNumber={step.stepNumber}
        />
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText>{roundNumber(convertKilosIntoGrams(step.grossWeight), 3) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText>
          {step.cost ? `${roundNumber(step.cost, 3)} €` : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText>{getTransformationTypeLabel(step.transformation) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText>{roundNumber(convertKilosIntoGrams(step.netWeight), 3) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText>{step.kitchenArea?.name || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText>{step.machineType?.name || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText>{step.machineSetting || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText>{step.stepDuration || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText>{step.stepDurationUnit || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledStepText>{typeof step.stepDate === "number" ? getStepDateLabelByValue(step.stepDate) : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[widths.length - 2]}>
        <StyledStepText>{step.endStepWeighing || step.endStepWeighing === false ? getEndStepWeighingLabelByValue(step.endStepWeighing) : "-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* remove column */}
      <StyledStepBodyCell align="left" width={widths[widths.length - 1]} />
    </>
  )
}


StepPreview.propTypes = {
  step: PropTypes.object.isRequired,
  isEdition: PropTypes.bool
}


export default StepPreview
