import React from "react"
import Icon from "@mui/material/Icon"

import { KFC_BRANDS } from "../utils"

export const BrandIcon = ({ name, height, width }) => {

	const brand = KFC_BRANDS.find((brand) => brand.name === name.toUpperCase()) || KFC_BRANDS[0]

	return (
		<Icon>
			<img
				style={{ height, width }}
				src={brand.image}
				alt={brand.name}
			/>
		</Icon>
	)
}

export default BrandIcon