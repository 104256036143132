import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"
import clsx from "clsx"
import moment from "moment"
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material"
import { sectors } from "../../../../utils/planning"
import {
    showPlanningSettingsDistributionCenterView
} from "../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"
import { ACTIVE_KFC_BRANDS } from "../../../../utils"
import SitesAndHubsTabs from "../SitesAndHubsTabs"
import DataGrid from "../../../../components/DataGrid/DataGrid"
import { TableDateFormat } from "../../../../components/table-utils/utils"
import { getBrandBy } from "../../../../utils"
import DistributionCenterCreateForm, {createDistributionCenterFormId} from "./forms/DistributionCenterCreateForm"

const useStyles = makeStyles({
    container: {
        top: 70,
        position: "relative",
    },
    brandLogoContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    brandLogoLeft: {
        width: 25,
        marginRight: 5
    },
    column: {
        cursor: "pointer"
    },
    fab: {
        position: "fixed",
        marginLeft: 20,
        bottom: 20,
        width: "fit-content"
    },
    rootDialogActions: {
        justifyContent: "space-between"
    }
})

const DistributionCentersTab = () => {
    const distributionCenters = useSelector(state => state.planningSettings.distributionCenters)
    const [data, setData] = useState([])

    const [createModalOpen, setCreateModalOpen] = useState(false)

    const onCloseForm = () => setCreateModalOpen(false)
    const onOpenForm = () => setCreateModalOpen(true)

    const columns = [
        {
            key: "id",
            label: "ID",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
            width: 120
        },
        {
            key: "name",
            label: "Nom technique",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            },
        },
        {
            key: "sector",
            label: "Secteur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: sectors?.filter(s => s.enableValue).map((sector) => ({
                    key: sector.value,
                    value: sector.label,
                    label: sector.label
                }))
            },
            width: 100
        },
        {
            key: "brand",
            label: "Marque",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: ACTIVE_KFC_BRANDS.map((brand) => ({
                    key: brand.name,
                    value: brand.name,
                    label: brand.label
                }))
            },
            render: (brands) => (
                <div className={classes.brandLogoContainer}>
                    {brands.split(",").map((brand) => {
                        const completeBrand = getBrandBy("name", brand)
                        return (
                            <img
                                key={brand}
                                src={completeBrand?.image}
                                alt={`logo${completeBrand?.shortname}`}
                                className={classes.brandLogoLeft}
                            />
                        )
                    })}
                </div>
            ),
            width: 220
        },
        {
            key: "priority",
            label: "Priorité",
            dataType: "number",
            sortable: true,
            filter: {
                filterable: false,
                type: "number"
            },
        },
        {
            key: "state",
            label: "Etat",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: [
                    { key: "active", value: "active", label: "Actif" },
                    { key: "inactive", value: "inactive", label: "Inactif" },
                ]
            },
            render: (state) => {
                if (state === "active") {
                    return <CheckCircleIcon sx={{ color: "#4bb200" }} />
                } else {
                    return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
                }
            },
            width: 100
        },
        {
            key: "updatedAt",
            label: "Date de modification",
            dataType: "date",
            type: "date",
            sortable: true,
            filter: {
                filterable: false,
                type: "date"
            },
            width: 130
        }
    ]

    const classes = useStyles()
    const dispatch = useDispatch()


    const createData = (distributionCenter) => {
        const serializedDistributionCenter = {
            id: distributionCenter.objectId,
            name: distributionCenter.name,
            sector: distributionCenter.sector,
            brand: distributionCenter.brand,
            priority: (distributionCenter.dispatch || {}).priority,
            state: distributionCenter.isActive ? "active" : "inactive",
            updatedAt: moment(distributionCenter.updatedAt).format(TableDateFormat),
        }
        return serializedDistributionCenter
    }

    const createDataObject = useCallback(() => {
        setData(distributionCenters.map(createData))
    }, [distributionCenters])

    useEffect(() => {
        createDataObject()
    }, [distributionCenters])

    const _showDistributionCenter = (distributionCenter) => {
        dispatch(showPlanningSettingsDistributionCenterView(distributionCenter.id))
    }

    return (
        <SitesAndHubsTabs tabValue="distributionCenters">
            <div className={clsx(classes.container, "flexColumn")}>
                <DataGrid
                    title="Centres de distribution"
                    columns={columns}
                    data={data}
                    withFilters
                    rowLabel="centres de distribution"
                    onRowClick={_showDistributionCenter}
                />
                <div className={classes.fab}>
                    <Fab color="primary" onClick={onOpenForm}>
                        <Add />
                    </Fab>
                </div>
            </div>
            <Dialog
                open={createModalOpen}
                onClose={onCloseForm}>
                <DialogTitle>
                    Créer un centre de distribution
                </DialogTitle>
                <DialogContent>
                    <DistributionCenterCreateForm
                        onClose={onCloseForm}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes.rootDialogActions }}>
                    <Button
                        onClick={onCloseForm}
                    >
                        Annuler
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        form={createDistributionCenterFormId}
                    >
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>
        </SitesAndHubsTabs>
    )
}

export default DistributionCentersTab