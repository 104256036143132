import Parse from "parse"
import {
    actionWithLoader,
    downloadFile,
    getReplacingPath,
    haveAccessRight,
    onEnter,
    push
} from "../Utils/utils"
import moment from "moment"
import { getItem } from "../../reducers/Dispatch/dispatch"
import { axiosCall } from "../../utils/axiosUtils"
import {
    getDataServerUrl,
    getServerUrl
} from "../../utils"
import {
    ambientProducts,
    formatSubcontractorProduct,
    getParamsFromPath,
    isAmbientProduct,
    isDispatchComplete,
    productDispatchFormatHubs,
    sortProductDispatchData
} from "../../utils/dispatchUtils"
import {
    getDispatchLocker,
    createDispatchLocker,
    freeLocker
} from "../../parseManager/dispatch/parseLockerManager"
import cloneDeep from "lodash/cloneDeep"
import { getDistributionCentersByBrand } from "../../parseManager/planning/parsePlanningDashboardManager"
import {
	resetStatusToToDo,
	getProductItemByProductDispatch
} from "../../parseManager/dispatch/parseProductDispatchManager"
import { showReceptionWarehouseInterface } from "../ReceptionWarehouse/receptionWarehouse"
import {batch} from "react-redux"
import { getSites, SUCY_ID, getSiteById } from "../../parseManager/site/parseSiteManager"
import { getLotsForDispatch } from "../../parseManager/lot/parseLotManager"
import { getLotsBySupplierItemsAndStockZones } from "../../parseManager/lot/parseLotManager"
import { groupBy, sumBy } from "lodash"
import { getSubcontractorProductById } from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { decrementLotsQuantity } from "../LotInventory/LotInventory"
import {loadInventory} from "../Reception/reception"
import {MANAGEMENT_MODES_ENUM} from "../../utils/productTypesUtils"
import {getProductTypeOptions, loadProductTypeOptions} from "../ProductTypes/productTypes"

export function loadDispatchData(selectedProductDispatchId = null, willContinue = "") {
    return actionWithLoader(async (dispatch, getState) => {
        let data = []
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const params = getParamsFromPath(path)

        const brand = state.dispatch.brand
        let site = state.dispatch.site
        const date = state.dispatch.date ? moment.utc(state.dispatch.date).startOf("day").valueOf() : moment.utc().add(1, "days").startOf("day").valueOf()
        const hasHubError = state.dispatch.hubError
        let hubs = state.dispatch.hubs

        const isHub = (getState().routing.locationBeforeTransitions.pathname.includes("/dispatch/hubs"))

        if (params && params.siteId && !site) {
            site = await getSiteById(params.siteId)
        }

        try {
            let url = `${getServerUrl()}/dispatch/?date=${date}&brand=${brand}`
            if (selectedProductDispatchId && typeof selectedProductDispatchId === "string") {
                url += `&idProductDispatch=${selectedProductDispatchId}`
            }
            if (params && params.siteId) {
                url += `&siteId=${params.siteId}`
            }

            const result = await axiosCall("GET", url, null, {"Content-Type": "application/json"})
            if (result.status === 200){
                const copy = cloneDeep(result.data.data)

                for (const i in copy){
                    const current = copy[i]

                    productDispatchFormatHubs(current)
                }

                data = copy
            }
            else {
                data = []
            }
        }
        catch (e){
            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {open: true, duration: 5000, type: "error", message: "Erreur en récupérant les données"}
            })
            data = []
        }

        let selectedProductDispatch = null
        if (selectedProductDispatchId !== null){
            selectedProductDispatch = data.find(data => data.objectId === selectedProductDispatchId)
        }

        const productTypeOptions = await getProductTypeOptions()
        data = sortProductDispatchData(data, productTypeOptions)
        await Promise.all(data.map(async dat => {
            if (!dat.productionItem) dat.productionItem = await getProductItemByProductDispatch(dat.objectId)
        }))

        if (willContinue === "hubs" || isHub) {
            const prodWarehouseTodo = data.filter(el => null === el.prodWarehouse) || []

            if (prodWarehouseTodo.length > 0) {
                dispatch({
                    type: "DISPATCH_HUB_HAS_ERROR",
                    error: true,
                })
            }
        }

        if (isHub) hubs = await getDistributionCentersByBrand(brand)


        if (brand === "FOODCHERI") {
            const filters = { managementMode: MANAGEMENT_MODES_ENUM.none, brands: [brand] }
            dispatch(loadProductTypeOptions(filters))
        }

        const sites = await getSites()

        dispatch({
            type: "SITES_LOADED",
            sites,
        })
        dispatch({
            type: "DISPATCH_DATA_LOADED",
            data,
            date,
            brand,
            site,
            hubs,
            productTypeOptions,
            selectedProductDispatch: selectedProductDispatch,
            isHub
        })

        if (hasHubError) dispatch({
            type: "DISPATCH_OPEN_SNACKBAR",
            dispatchSnackBar: {open: true, duration: 10000, type: "error", message: "Attention : certains produits n’ont pas encore été réceptionnés."}
        })
    })
}

export function createProductItemsByLots(products, isDlcAddingForAmbient = false, manualDlcSelected = null, hubs = []) {
    return actionWithLoader(async (dispatch, getState) => {
        try {

            const state = getState()
            let site = state.dispatch.site
            //DLC has to be at least tomorrow to create new ProductStock
            const date = isDlcAddingForAmbient ? moment.utc().add(1, "days").startOf("day").valueOf() : state.dispatch.date
            const brand = state.dispatch.brand

            if (!site) {
                site = await getSiteById(SUCY_ID, ["*"])
            }

            const supplierItemIds = products.map((supplierItem) => supplierItem.name.objectId)
            const lots = await getLotsBySupplierItemsAndStockZones(supplierItemIds, site.stockZones)
            const productsByLotsDlc = []
            if (!isDlcAddingForAmbient) {
                for (const product of products) {
                    const lotsByProduct = lots.filter((lot) => lot.orderSupplierItem.supplierItemId === product.name.objectId)

                    const groupedLotsByDlc = groupBy(lotsByProduct, "dlc")
                    for (const [key, values] of Object.entries(groupedLotsByDlc)) {
                        const stockQuantity = values[0].orderSupplierItem.units.stock.unity.quantity
                        const quantity = sumBy(values, "quantity") * stockQuantity
                        const dlc = +key
                        const formattedProduct = formatSubcontractorProduct(product, dlc, quantity, false)
                        productsByLotsDlc.push(formattedProduct)
                    }
                }
            } else {
                for (const product of products) {
                    const quantity = product.name.units && product.name.units.stock ? product.name.units.stock.unity.quantity : 0
                    const formattedProduct = formatSubcontractorProduct(product, manualDlcSelected, quantity, isDlcAddingForAmbient)
                    productsByLotsDlc.push(formattedProduct)
                }
            }

            const data = {
                saleDate: date,
                products: productsByLotsDlc,
                brand,
                user: Parse.User.current() ? Parse.User.current().get("email") : null,
                siteId: site.objectId,
                context: { dataFromDataAPI: { hubs, isDlcAddingForAmbient, isManuelAdding: true } }
            }

            const url = `${getServerUrl()}/mealPlanner/_createProductionItems`
            const response = await axiosCall("POST", url, data, { "Content-Type": "application/json" })

            if (response.status === 200) {
                if (isDlcAddingForAmbient) {
                    dispatch(loadInventory(brand, hubs[0].hubId))
                } else {
                    dispatch({
                        type: "DISPATCH_OPEN_SNACKBAR",
                        dispatchSnackBar: {
                            open: true,
                            type: "success",
                            message: "Les produits ont été crée avec succès",
                            duration: 5000
                        }
                    })
                    dispatch(loadDispatchData())
                }
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "La mise à la carte du produit a échoué" }
                })
                dispatch({
                    type: "DISPATCH_OPEN_SNACKBAR",
                    dispatchSnackBar: {
                        open: true,
                        type: "error",
                        message: "La création des produits a échoué",
                        duration: 5000
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue ",
                    duration: 5000
                }
            })
        }
    })
}

export async function createHubLocker(selectedProductDispatch, idHub) {
    await createDispatchLocker(`${idHub}-${selectedProductDispatch.objectId}`)
}

export async function removeHubLocker(selectedProductDispatch, idHub) {
    await freeLocker(`${idHub}-${selectedProductDispatch.objectId}`)
}

export function removeHubLockerAction(selectedProductDispatch, idHub) {
    return actionWithLoader(async() => {
        await freeLocker(`${idHub}-${selectedProductDispatch.objectId}`)
    })
}

export function closeDispatchDetail(selectedProductDispatch) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const site = state.dispatch.site
        await freeLocker(selectedProductDispatch.objectId)

        if (null === selectedProductDispatch.prodWarehouse && "TODO" !== selectedProductDispatch.kfcStatus) await resetStatusToToDo(selectedProductDispatch)

        batch(() => {
            dispatch(clearSelectedProductDispatch())
            dispatch(showProductDispatchInterface(site.objectId))
        })
    })
}

export function closeHubDispatchDetail(selectedProductDispatch, idHub) {
    return actionWithLoader(async(dispatch, getState) => {
        const state = getState()
        const site = state.dispatch.site
        if (selectedProductDispatch) await removeHubLocker(selectedProductDispatch, idHub)

        batch(() => {
            dispatch(clearSelectedProductDispatch())
            dispatch(showHubDispatchInterface(site.objectId))
        })
    })
}

export function finishDispatch(selectedProductDispatch) {
    return actionWithLoader(async(dispatch, getState) => {
        try {
            const state = getState()
            let site = state.dispatch.site

            const url = `${getServerUrl()}/dispatch/end`
            const data = {
                idProductDispatch: selectedProductDispatch.objectId,
                productName: selectedProductDispatch.commercialTitle,
                user: Parse.User.current() ? Parse.User.current().get("username") : null
            }

            await axiosCall("POST", url, data, {"Content-Type": "application/json"})
            await freeLocker(selectedProductDispatch.objectId)

            let siteId
            if (site) {
                siteId = site.objectId
            } else {
                const path = state.routing.locationBeforeTransitions.pathname
                const paths = path.split("/")
                siteId = paths[paths.length - 3]
            }

            batch(() => {
                dispatch(clearSelectedProductDispatch())
                dispatch(showProductDispatchInterface(siteId))
            })
        }
        catch (e) {
            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue ",
                    duration: 5000
                }
            })
        }
    })
}

export function updateSelectedProductDispatch(productDispatch) {
    return (dispatch, getState) => {
        const state = getState()
        const subcontractorProductLots = state.dispatch.subcontractorProductLots
        const site = state.dispatch.site
        dispatch({
            type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
            selectedProductDispatch: productDispatch,
            subcontractorProductLots,
            site
        })
    }
}

export function updateDispatchDate(date) {
    return dispatch => {
        dispatch({
            type: "DISPATCH_DATE_UPDATED",
            date: date
        })
    }
}

export function updateDispatchBrand(brand) {
    return dispatch => {
        dispatch({
            type: "DISPATCH_BRAND_UPDATED",
            brand
        })

        dispatch(loadDispatchData())
    }
}

export function updateDispatchTypeFilter(type) {
    return dispatch => {
        dispatch({
            type: "DISPATCH_SELECTED_TYPE_UPDATED",
            selectedTypeFilter: type
        })
    }
}

export function downloadBL(values, brand) {
    return actionWithLoader(async(dispatch) => {
        try {
            const url = `${getDataServerUrl()}/blMonop?saleDate=${moment(values.date).format("YYYY-MM-DD")}&sector=${values.sector}&brand=${brand}`
            await axiosCall("GET", url, null, {"Content-Type": "application/json"})

            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "success",
                    message: "Les BL ont été envoyés par mail",
                    duration: 5000
                }
            })
        }
        catch (e) {
            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Impossible d'envoyer les BL par mail",
                    duration: 5000
                }
            })
        }
    })
}

export function clearSelectedProductDispatch() {
    return (dispatch, getState) => {
        const state = getState()
        const site = state.dispatch.site
        dispatch({
            type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
            selectedProductDispatch: null,
            subcontractorProduct: null,
            subcontractorProductLots: [],
            site
        })
    }
}

export function updateProductDispatchHubVolume(selectedProductDispatch, currentHub, volume, checkDone= false, allDispatchInOne = false) {
    return actionWithLoader(async(dispatch, getState) => {
        try {
            const state = getState()
            const subcontractorProduct = state.dispatch.subcontractorProduct
            const subcontractorProductLots = state.dispatch.subcontractorProductLots
            const site = state.dispatch.site

            const data = {
                productName: selectedProductDispatch.commercialTitle,
                idHub: currentHub.idHub,
                hubName: currentHub.name,
                volume: volume,
                user: Parse.User.current() ? Parse.User.current().get("username") : null
            }

            const url = `${getServerUrl()}/dispatch/${selectedProductDispatch.objectId}/updateHubVolume`

            const result = await axiosCall("PATCH", url, data, {"Content-Type": "application/json"})

            if (result.status === 200){
                const copy = cloneDeep(selectedProductDispatch)

                const indexHub = copy.hubs.map(el => el.idHub).indexOf(currentHub.idHub)

                copy.hasError = result.data.hasError
                if (indexHub !== -1){
                    const currentHubData = result.data.hubs.find((hub) => hub.idHub === copy.hubs[indexHub].idHub)

                    copy.hubs[indexHub].volume = currentHubData.volume
                    copy.hubs[indexHub].realQuantity = currentHubData.volume
                    copy.hubs[indexHub].locked = currentHubData.locked
                }

                let lots = []

                if (isAmbientProduct(copy)) {
                    const stockUnitQuantity = subcontractorProduct.name.units.stock.unity.quantity
                    const defaultVolume = volume / stockUnitQuantity

                    lots = await decrementLotsQuantity(subcontractorProductLots, defaultVolume, copy.hubs[indexHub].idHub)
                }

                if (checkDone && isDispatchComplete(copy) && !allDispatchInOne) {
                    const dataEnd = {
                        idProductDispatch: copy.objectId,
                        productName: copy.commercialTitle,
                        user: Parse.User.current() ? Parse.User.current().get("username") : null
                    }

                    await axiosCall("POST", `${getServerUrl()}/dispatch/end`, dataEnd, {"Content-Type": "application/json"})
                }

                return dispatch({
                    type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                    selectedProductDispatch: copy,
                    subcontractorProduct,
                    subcontractorProductLots: lots,
                    site
                })
            }
            else {
                return dispatch({
                    type: "DISPATCH_OPEN_SNACKBAR",
                    dispatchSnackBar: {
                        open: true,
                        type: "error",
                        message: "Une erreur est survenue lors de la mise à jour du volume",
                        duration: 5000
                    }
                })
            }

        }
        catch (err) {
            return dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue lors de la mise à jour du volume",
                    duration: 5000
                }
            })
        }
    })
}

export function computeDispatch(selectedProductDispatch, prodWarehouse) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const subcontractorProduct = state.dispatch.subcontractorProduct
        const subcontractorProductLots = state.dispatch.subcontractorProductLots
        const site = state.dispatch.site

        try {
            const url = `${getServerUrl()}/dispatch/compute`
            const data = {
                prodWarehouse,
                idProductDispatch: selectedProductDispatch.objectId,
                productName: selectedProductDispatch.commercialTitle,
                user: Parse.User.current() ? Parse.User.current().get("username") : null
            }

            const result = await axiosCall("POST", url, data, {"Content-Type": "application/json"})

            if (result.status === 200){
                const copy = cloneDeep(result.data)

                productDispatchFormatHubs(copy)

                return dispatch({
                    type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                    selectedProductDispatch: copy,
                    subcontractorProduct,
                    subcontractorProductLots,
                    site
                })
            }
            else {
                return batch(() => {
                    dispatch({
                        type: "DISPATCH_OPEN_SNACKBAR",
                        dispatchSnackBar: {
                            open: true,
                            type: "error",
                            message: "Une erreur est survenue lors du calcul du dispatch",
                            duration: 5000
                        }
                    })
                    dispatch({
                        type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                        selectedProductDispatch,
                        subcontractorProduct,
                        subcontractorProductLots,
                        site
                    })
                })
            }
        }
        catch (e) {
            return batch(() => {
                dispatch({
                    type: "DISPATCH_OPEN_SNACKBAR",
                    dispatchSnackBar: {
                        open: true,
                        type: "error",
                        message: "Une erreur est survenue lors du calcul du dispatch",
                        duration: 5000
                    }
                })
                dispatch({
                    type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                    selectedProductDispatch,
                    site,
                    subcontractorProductLots: []
                })
            })
        }
    })
}

export function createAmbientProductsDispatchFromDataAPI(productTypes) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const brand = state.dispatch.brand
        const date = state.dispatch.date 
        const site = state.dispatch.site

        try {
            const data = {
                saleDate: date,
                productTypes,
                brand,
                user: Parse.User.current() ? Parse.User.current().get("email") : null,
                siteId: site ? site.objectId : SUCY_ID
            }
    
            const url = `${getServerUrl()}/dispatch/prevAmbiants`
    
            const response = await axiosCall("POST", url, data, { "Content-Type": "application/json" })
    
            if (response.status === 200){
                batch(() => {
                    dispatch({
                        type: "DISPATCH_OPEN_SNACKBAR",
                        dispatchSnackBar: {
                            open: true,
                            type: "success",
                            message: "Les produits ont été crée avec succès",
                            duration: 5000
                        }
                    })
                    dispatch(loadDispatchData())                
                })
            } else {
                dispatch({
                    type: "DISPATCH_OPEN_SNACKBAR",
                    dispatchSnackBar: {
                        open: true,
                        type: "error",
                        message: "La création des produits a échoué",
                        duration: 5000
                    }
                })                
            }
        } catch (error) {
            dispatch({
                type: "DISPATCH_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue ",
                    duration: 5000
                }
            })
        }
    })
}

export function loadModalDispatch() {
    return actionWithLoader(async(dispatch, getState) => {
        const state = getState()
        const brand = state.dispatch.brand
        const date = state.dispatch.date ? moment.utc(state.dispatch.date).startOf("day").valueOf() : moment.utc().add(1, "days").startOf("day").valueOf()

        dispatch({
            type: "DISPATCH_DATA_LOADED",
            data: null,
            date: date,
            brand: brand,
            selectedProductDispatch: null
        })            
    })
}

export function downloadFridgePlano(date) {
    return actionWithLoader(async () => {
        const fileName = "Planogramme.zip"
        const url = `${getServerUrl()}/dispatch/fridgePlano/${date}`
        downloadFile(url, fileName)
    })
}

export function onEnterModalDispatch(store) {
    return onEnter({
        store,
        actionThunk: loadModalDispatch,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight({canDispatch: true})
    })
}

export function onEnterDispatch(store, reception=false) {
    return onEnter({
        store,
        actionThunk: loadDispatchData,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight(reception?{canReceiveDispatch: true}:{canDispatch: true})
    })
}

export function onEnterDispatchDetail(store, reception=false, hubDetail=false) {
    return async (nextState, replace, callback) => {
        const { params } = nextState
        const state = store.getState()
        let site = state.dispatch.site

        if (!site) {
            site = await getSiteById(params.siteId)
        }

        const dispatchLocker = await getDispatchLocker((params.idHub)?`${params.idHub}-${params.id}`:String(params.id))

        // Redirection on dispatch product list if locker exist
        if (dispatchLocker) {
            store.dispatch(clearSelectedProductDispatch())
            if (hubDetail && params) store.dispatch(showHubDispatchInterface(params.siteId))
            if (!hubDetail && !reception && params) store.dispatch(showProductDispatchInterface(params.siteId))
            if (!hubDetail && reception) store.dispatch(showReceptionWarehouseInterface())
            callback()
            return
        }

        if (params) {
            store.dispatch(clearSelectedProductDispatch())
            if (moment.utc(state.dispatch.date).startOf("day").valueOf() !== moment.utc(params.date).startOf("day").valueOf()){
                store.dispatch(updateDispatchDate(moment.utc(params.date).startOf("day").valueOf()))
            }
            const selectedProductDispatch = getItem(state, params.id) || null

            // redirection if no hubs
            let redirect = false
            if (params.idHub) {
                const hubs = state.dispatch.hubs
                if (hubs.length > 0) {
                    store.dispatch({
                        type: "DISPATCH_HUB_DISPATCH_UPDATED",
                        hub: hubs.find(elm => elm.objectId === params.idHub),
                        site
                    })
                } else {
                    redirect = true
                }
            }

            //Redirection on dispatch product list if selectedProductDispatch kfcStatus is DONE
            if ((selectedProductDispatch && selectedProductDispatch.kfcStatus === "DONE") || redirect) {
                store.dispatch(clearSelectedProductDispatch())
                if (hubDetail) store.dispatch(showHubDispatchInterface(params.siteId))
                if (!hubDetail && !reception) store.dispatch(showProductDispatchInterface(params.siteId))
                if (!hubDetail && reception) store.dispatch(showReceptionWarehouseInterface())
                callback()
                return
            }
            else {
                if (selectedProductDispatch && selectedProductDispatch.kfcStatus !== "IN_PROGRESS") {
                    try {
                        const url = `${getServerUrl()}/dispatch/start`
                        const data = {
                            idProductDispatch: selectedProductDispatch.objectId,
                            productName: selectedProductDispatch.commercialTitle,
                            user: Parse.User.current() ? Parse.User.current().get("username") : null
                        }
                        const response = await axiosCall("POST", url, data, {"Content-Type": "application/json"})

                        if (response.status === 200){
                            const copy = cloneDeep(response.data)
                            await createDispatchLocker((params.idHub)?`${params.idHub}-${selectedProductDispatch.objectId}`:selectedProductDispatch.objectId)
                            productDispatchFormatHubs(copy)
                            copy.productionItem = selectedProductDispatch.productionItem

                            let subcontractorProduct
                            let lots = []
                            if (ambientProducts.includes(copy.productType)) {
                                subcontractorProduct = await getSubcontractorProductById(copy.idProduct, ["name"])
                                lots = await getLotsForDispatch(subcontractorProduct, site.stockZones, selectedProductDispatch)
                            }

                            store.dispatch({
                                type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                                selectedProductDispatch: copy,
                                subcontractorProduct,
                                site,
                                subcontractorProductLots: lots
                            })

                            callback()
                            return
                        }
                        else {
                            store.dispatch(clearSelectedProductDispatch())
                            if (hubDetail && params) store.dispatch(showHubDispatchInterface(params.siteId))
                            if (!hubDetail && !reception && params) store.dispatch(showProductDispatchInterface(params.siteId))
                            if (!hubDetail && reception) store.dispatch(showReceptionWarehouseInterface())
                            callback()
                            return
                        }
                    }
                    catch (err) {
                        store.dispatch({
                            type: "DISPATCH_OPEN_SNACKBAR",
                            dispatchSnackBar: {
                                open: true,
                                duration: 5000,
                                type: "error",
                                message: "Une erreur est survenue lors du changement du status du produit dispatché"
                            }
                        })
                        callback()
                        return
                    }
                }
                else {
                    if (!selectedProductDispatch) {
                        store.dispatch(loadDispatchData(params.id))
                        await createDispatchLocker((params.idHub)?`${params.idHub}-${params.id}`:params.id)
                    }
                    else {
                        let subcontractorProduct
                        let lots = []
                        if (ambientProducts.includes(selectedProductDispatch.productType)) {
                            subcontractorProduct = await getSubcontractorProductById(selectedProductDispatch.idProduct, ["name"])
                            lots = await getLotsForDispatch(subcontractorProduct, site.stockZones, selectedProductDispatch)
                        }

                        await createDispatchLocker((params.idHub)?`${params.idHub}-${selectedProductDispatch.objectId}`:selectedProductDispatch.objectId)
                        store.dispatch({
                            type: "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED",
                            selectedProductDispatch,
                            subcontractorProduct,
                            site,
                            subcontractorProductLots: lots
                        })
                    }

                    callback()
                    return
                }
            }
        }
    }
}

export function updateBrandDateAndSite(values) {
    return dispatch => {
        dispatch({
            type: "DISPATCH_BRAND_DATE_AND_SITE_UPDATED",
            brand: values.brand,
            date: values.date,
            site: values.site,
        })
    }
}

export function changeHubTab(value) {
    return actionWithLoader((dispatch) => {
        dispatch({
            type: "DISPATCH_HUB_TAB_CHANGED",
            hubTab: value
        })
    })
}

export function changeProductTab(value) {
    return actionWithLoader((dispatch) => {
        dispatch({
            type: "DISPATCH_PRODUCT_TAB_CHANGED",
            productTab: value
        })
    })
}

export function changeLotTab(value) {
    return actionWithLoader((dispatch) => {
        dispatch({
            type: "DISPATCH_LOT_TAB_CHANGED",
            tabValue: value
        })
    })
}

export function closeDispatchSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "DISPATCH_CLOSE_SNACKBAR",
            dispatchSnackBar: {open: false, type: currentType, message: "", duration: 1000}
        })
    })
}

export function showHubDispatchInterface(siteId = SUCY_ID) {
    return push("/dispatch/hubs/" + siteId)
}

export function showProductDispatchInterface(siteId = SUCY_ID) {
    return push("/dispatch/products/" + siteId)
}

export function showDispatchInterface() {
    return push("/dispatch")
}

export function showDispatchDetail(idProductDispatch, date, siteId) {
    return push(`/dispatch/${siteId}/${date}/${idProductDispatch}`)
}

export function showHubDispatchDetail(date, idHub, idProductDispatch, siteId) {
    return push(`/dispatch/hubs/${siteId}/${date}/${idProductDispatch}/${idHub}`)
}
