import React from "react"
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton"
import { ArrowBack } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { goBack } from "react-router-redux"

const useStyles = makeStyles({
    iconButton: {
        height: "fit-content"
    }
})

const GenericHeaderReturnButton = (props) => {
    const {
        handleClick
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const onClick = () => {
        if (handleClick) {
            handleClick()
            return
        }
        dispatch(goBack())
    }

    return (
        <IconButton onClick={onClick} className={classes.iconButton} size="large">
            <ArrowBack />
        </IconButton>
    )
}

export default GenericHeaderReturnButton