import React from "react"
import { makeStyles } from "@mui/styles"
import SupplierItemResume from "./SupplierItemResume"
import News from "./News"
import { COLORS } from "../../utils"
import SupplierItemUnits from "./SupplierItemUnits"
import SupplierItemStockage from "./SupplierItemStockage"
import Documentation from "../Supplier/Documentation"


const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        padding: "40px 0",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        justifyContent: "center",
        height: "100%"
    },
    firstColumnContainer: {
        width: "75%",
        margin: "0 16px",
        minWidth: 500,
        maxWidth: 1000,
    },
    secondColumnContainer: {
        width: "25%",
        margin: "0 16px",
        minWidth: 275,
        maxWidth: 300
    },
    blocContainer: {
        marginTop: 20
    }
}))

const SupplierItemGeneral = (props) => {
    const {
        supplierItem,
        sites,
        canEdit,
        onUpdateSupplierItem
    } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <div>
                    <SupplierItemResume
                        onSaveSupplierItem={onUpdateSupplierItem}
                        supplierItem={supplierItem}
                        canEdit={canEdit}
                    />
                </div>
                <div className={classes.blocContainer}>
                    <SupplierItemUnits
                        supplierItem={supplierItem}
                        onUpdateSupplierItem={onUpdateSupplierItem}
                        canEdit={canEdit}
                    />
                </div>
                <div className={classes.blocContainer}>
                    <SupplierItemStockage
                        supplierItem={supplierItem}
                        onUpdateSupplierItem={onUpdateSupplierItem}
                        sites={sites}
                        canEdit={canEdit}
                    />
                </div>
            </div>
            <div className={classes.secondColumnContainer}>
                <News
                    supplierItem={supplierItem}
                    onUpdateSupplierItem={onUpdateSupplierItem}
                    canEdit={canEdit}
                />
                <Documentation
                  supplierItem={supplierItem}
                />
            </div>
    
        </div>
    )
}

export default SupplierItemGeneral