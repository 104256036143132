import { getData } from "../index"

const defaultState = {
	selectedSite: null,
	selectedStockZone: null,
	selectedProductionDate: null,
	sites: [],
	snackbar: { open: false, type: "", message: "", duration: 1000 },
}

export default function (state = defaultState, action) {
	switch (action && action.type) {
		case "PRODUCTION_RECEPTION_FILTER_LOADED":
			return {
				...state,
				selectedSite: action.selectedSite,
				selectedStockZone: action.selectedStockZone,
				selectedProductionDate: action.selectedProductionDate,
				sites: action.sites,
				productionDate: action.productionDate,
				productionSuppliesToDo: action.productionSuppliesToDo,
				productionSuppliesDone: action.productionSuppliesDone
			}
		case "PRODUCTION_RECEPTION_TO_RECEIVE_LOADED":
			return {
				...state,
				productionSupply: action.productionSupply
			}
		default:
			return {
				...state
			}
	}
}

export function getProductionReceptionStockZoneSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.selectedStockZone", errorIfNotFound && "No selected selectedStockZone found")
}

export function getProductionReceptionSiteSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.selectedSite", errorIfNotFound && "No selected site found")
}

export function getProductionReceptionProductionDateSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.productionDate", errorIfNotFound && "No selected productionDate found")
}

export function getProductionReceptionSitesSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.sites", errorIfNotFound && "No sites found")
}

export function getProductionReceptionProductionSuppliesToDoSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.productionSuppliesToDo", errorIfNotFound && "No productionSuppliesToDo found")
}

export function getProductionReceptionProductionSuppliesDoneSelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.productionSuppliesDone", errorIfNotFound && "No productionSuppliesDone found")
}

export function getProductionReceptionProductionSupplySelector(state, errorIfNotFound = false) {
	return getData(state, "productionReception.productionSupply", errorIfNotFound && "No productionSupply found")
}
