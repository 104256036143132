import React, { Fragment, useState } from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS
} from "../../../utils"
import { EiffelTowerIcon, EiffelTowerColors } from "../../../icons/EiffelTowerIcon"
import LunchbagIcon, { LunchbagColors } from "../../../icons/LunchbagIcon"
import PanoramaVerticalIcon from "@mui/icons-material/PanoramaVertical"
import CapitalShippingIcon from "../../../icons/CapitalShippingIcon"
import CloudinaryImage from "../../../components/Image/CloudinaryImage"

const styles = {
    planningCardContainer: {
        padding: "7px 7px 7px 7px",
    },
    planningCardTopContainer: {
        display: "flex",
        height: 42,
    },
    planningCardImg: {
        width: "auto",
        flex: "1 1 auto",
    },
    planningCardTitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "120%",
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        marginLeft: 13,
        flex: "1 1 auto",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    defaultPlanningCardImage: {
        height: 33,
        width: 33
    },
    planningCardInfos: {
        fontWeight: "normal",
        lineHeight: "130%",
        fontSize: 12,
        marginTop: 5,
        color: COLORS.DARK_GREY
    },
    planningStarIcon: {
        color: COLORS.DEFAULT_GREY,
        fontSize: 16,
        marginLeft: -2
    },
    planningCardAction: {
        bottom: 10,
        position: "absolute",
        display: "flex"
    },
    planningRateBloc: {
        fontSize: 12,
        color: COLORS.DARK_GREY
    },
    subcontractorName: {
        fontStyle: "oblique",
        fontWeight: "bold"
    },
    hover: {
        position: "absolute",
        width: "100%",
        top: 20,
        border: `1px solid ${COLORS.DEFAULT_GREY}`,
        zIndex: "400",
        backgroundColor: COLORS.WHITE,
        fontSize: 12,
        fontWeight: 500,
        padding: 4,
        overflowWrap: "break-word"
    }
}

export const SellPlanningCard = (props) => {
    const {
        card,
        classes,
        openProductInfo
    } = props

    const [showName, setShowName] = useState(false)

    const showProductInfo = () => {
        openProductInfo(card)
    }

    return (
        <Fragment>
            {
                card
                    ?
                        (
                            <div
                                className={classes.planningCardContainer}
                            >
                                <div className={classes.planningCardTopContainer}>
                                    <div>
                                        {
                                            card.image && card.image.publicId
                                                ?
                                                    <CloudinaryImage
                                                        containerClassName={classes.planningCardImg}
                                                        width={33}
                                                        height={33}
                                                        crop="fill"
                                                        imageId={ card.image && card.image.publicId }
                                                    />
                                                :
                                                    <img
                                                        className={classes.defaultPlanningCardImage}
                                                        src="/img/noImageAvailabled.png"
                                                        alt="Non disponible"
                                                    />
                                        }
                                    </div>
                                    <div
                                        className={classes.planningCardTitle}
                                        onClick={showProductInfo}
                                    >
                                        { card.commercialName }
                                    </div>
                                    {
                                      card.lunchbag &&
                                      <LunchbagIcon color={LunchbagColors.BLACK}/>
                                    }
                                    {
                                        card.nationalSend === true &&
                                        <CapitalShippingIcon color={COLORS.BLACK} />
                                    }
                                    {
                                        card.sendCapital === true &&
                                        <EiffelTowerIcon color={EiffelTowerColors.BLACK} />
                                    }
                                    {
                                        !card.smallRetail &&
                                            <PanoramaVerticalIcon />
                                    }
                                </div>
                            </div>
                        )
                    :
                        null
            }
            {
                showName &&
                <div
                    className={classes.hover}
                    onMouseEnter={() => { setShowName(true)}}
                    onMouseLeave={() => { setShowName(false)}}
                >
                    { card.name }
                </div>
            }
        </Fragment>
    )
}

export default withStyles(styles)(SellPlanningCard)
