import { Cloudinary } from "@cloudinary/url-gen"
import { convertKeysToSnakeCase } from "../utils"
export const CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
export const UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET

export const CLOUDINARY_CONFIG = {
    cloudName: CLOUD_NAME,
    uploadPreset: UPLOAD_PRESET
}

export const CLOUDINARY_FOLDER_NAME = `kfc/${(
    process.env
    && process.env.REACT_APP_ENV
    && process.env.REACT_APP_ENV.toLowerCase()
) || "xxx"}`

export const CLOUDINARY_UPLOAD_OPTIONS = {
    ...CLOUDINARY_CONFIG,
    multiple: false,
    folder: CLOUDINARY_FOLDER_NAME,
    tags: ["kfc"],
    resourceType: "image",
    maxFileSize: null,
    maxImageWidth: null,
    maxImageHeight: null,
    showPoweredBy: false,
    singleUploadAutoClose: false
}

export const generateCloudinaryDownloadUrl = (publicId) => {
    try {
        const cld = new Cloudinary({
            cloud: {
                cloudName: CLOUD_NAME
            }
        })
        const image = cld.image(publicId)
        return image.toURL()
    } catch (e) {
        console.error(e)
        return null
    }
}

// common to cloudinary legacy ("cloudinary-core" + "cloudinary-react") && latest "@cloudinary/url-gen" + "@cloudinary-react)

export const openUploadWidget = (options = {}, callback) => {
    options.tags = Array.isArray(options.tags)
        ? [...CLOUDINARY_UPLOAD_OPTIONS.tags, ...options.tags]
        : [...CLOUDINARY_UPLOAD_OPTIONS.tags]

    options = {
        ...CLOUDINARY_UPLOAD_OPTIONS,
        ...options,
    }

    const scOptions = convertKeysToSnakeCase(options)

    window.cloudinary
        && window.cloudinary.openUploadWidget(scOptions, callback)
}
