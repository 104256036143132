import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import { COLORS, forEachBrand, KFC_BRANDS } from "../../../utils"
import moment from "moment"
import clsx from "clsx"
import Divider from "@mui/material/Divider"
import {
  sourcingInfo,
  preparationInfo,
  nutrionalValues,
  moreNutrionalValues,
} from "../../../utils/planning"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ExpandLess from "@mui/icons-material/ExpandLess"
import CloudinaryImage from "../../Image/CloudinaryImage"

const useStyles = makeStyles(() => {
  const styles = {}

  forEachBrand((brand) => {
    styles[`point-${brand.shortname}`] = {
      composes: "$pointBrand",
      backgroundColor: brand.color,
    }
  })

  return {
    pointBrand: {
      height: 7,
      width: 7,
      borderRadius: "50%",
      marginLeft: 2,
      marginRight: 8,
      marginTop: 3,
    },

    ...styles,
    name: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 26,
      lineHeight: "37px",
      color: COLORS.BLACK,
    },
    name2: {
      composes: "$name",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    grid: {
      padding: "33px 50px",
    },
    gridDescription: {
      composes: "$grid",
      background: COLORS.RIGHT_DESCRIPTION_BKG,
    },
    defaultPlanningCardImage: {
      width: "100%",
      height: 420,
      objectFit: "cover",
    },
    planningCardImg: {
      composes: "$defaultPlanningCardImage",
    },
    description: {
      composes: "$name",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "25px",
    },
    paragraph: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 15,
      lineHeight: "22px",
      color: COLORS.BLACK,
    },
    title: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "20px",
      color: COLORS.BLACK,
    },
    title2: {
      fontSize: 22,
      maxWidth: 100,
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      marginRight: 10,
      padding: "3px 10px",
      background: COLORS.RIGHT_TAG_CHIP,
      marginBottom: 10,
      display: "flex",
      alignItems: "center",
      maxHeight: 26,
    },
    chipText: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 13,
      lineHeight: "20px",
    },
    noPadding: {
      paddingBottom: 0,
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    headerInfo: {
      composes: "$flex",
      marginTop: 16,
      maxHeight: 45,
    },
    price: {
      fontFamily: "Avenir LT Std",
      fontStyle: "normal",
      fontWeight: 550,
      fontSize: 16,
      lineHeight: "19px",
    },
    divider: {
      margin: "0 19px",
    },
    divider2: {
      height: 1,
      marginTop: 30,
    },
    kcal: {
      fontFamily: "Roboto",
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: 9,
      lineHeight: "19px",
    },
    icon: {
      fontFamily: "Roc Grotesk",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "26px",
      color: COLORS.BLACK,
    },
    iconText: {
      fontFamily: "Roc Grotesk",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "10px",
      letterSpacing: "0.5px",
      color: COLORS.BLACK,
      order: 1,
      alignSelf: "center",
      flexGrow: 0,
      margin: "4px 0px",
    },
    icon2: {
      composes: "$icon",
      display: "flex",
      lineHeight: "20px",
      "& img": {
        paddingRight: 15,
      },
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    lastSourcing: {
      display: "flex",
      maxHeight: "144px",
      flexFlow: "row wrap",
      "& > *": {
        flex: "0 0 50%",
      },
    },
    nutritionValue: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "20px",
    },
    nutritionValue2: {
      composes: "$nutritionValue",
      fontWeight: 500,
      "&.indented": {
        paddingLeft: 16,
      },
    },
    nutritionValue3: {
      composes: "$nutritionValue2",
      fontSize: 11,
    },
    paper: {
      boxShadow: "none",
    },
    list: {
      textAlign: "left",
      display: "flex",
      alignItems: "baseline",
    },
    table: {
      "& .MuiTableCell-root": {
        paddingTop: 10,
        paddingBottom: 10,
        "&:not(.indented)": {
          paddingLeft: 0,
        },
        "&.indented": {
          fontWeight: 400,
        },
      },
      "& thead": {
        borderBottom: `3px solid ${COLORS.BLACK}`,
      },
      "& .MuiTableCell-alignRight": {
        "&:last-child": {
          paddingRight: 0,
          maxWidth: 45,
        },
      },
      "& tbody tr:last-child td": {
        border: "none",
      },
    },
    seeMore: {
      fontFamily: "Roc Grotesk",
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: 13,
      lineHeight: "13px",
      display: "flex",
      textDecoration: "underline",
      marginTop: 30,
      cursor: "pointer",
      "& svg": {
        width: 13,
        height: 13,
      },
    },
  }
})

const ProductInfo = (props) => {
  const { cardInfo, rightProduct, goToProduct } = props

  const classes = useStyles()
  const [seeMore, setSeeMore] = useState(false)

  useEffect(() => {
    setSeeMore(false)
  }, [rightProduct])

  const nutritionInformation =
    rightProduct && rightProduct.nutritionInformation
  const nutriscore =
    nutritionInformation && nutritionInformation.nutriscore
      ? nutritionInformation.nutriscore.toUpperCase()
      : ""
  const srcNutriscore = `/img/product/Nutri-score-${nutriscore}.svg`

  const ecoscore =
    nutritionInformation && nutritionInformation.carboneScore
      ? nutritionInformation.carboneScore.toUpperCase()
      : ""
  const srcEcoscore = `/img/product/Eco-Score-Contour-${ecoscore}.svg`

  const toggleSeeMore = () => {
    setSeeMore(!seeMore)
  }

  const renderRow = (value) => {
    if (value.nutritionSecondValueKey) {
      return (
        <TableRow
          key={`${value.nutritionValueKey}-${value.nutritionSecondValueKey}`}
        >
          <TableCell
            className={clsx(classes.nutritionValue2, {
              indented: value.indent,
            })}
          >
            {value.nutritionLabel}
          </TableCell>
          <TableCell align="right" className={classes.nutritionValue}>
            {rightProduct &&
              rightProduct.nutritionInformation &&
              rightProduct.nutritionInformation[value.nutritionValueKey] &&
              rightProduct.nutritionInformation[value.nutritionValueKey][
              value.nutritionSecondValueKey
              ] &&
              rightProduct.nutritionInformation[value.nutritionValueKey][
              value.nutritionSecondValueKey
              ]
              ? `${rightProduct.nutritionInformation[value.nutritionValueKey][
                value.nutritionSecondValueKey
              ].toFixed(2)}${value.unity}`
              : "-"}
          </TableCell>
          <TableCell align="right" className={classes.nutritionValue2}>
            {rightProduct &&
              rightProduct.nutritionInformation &&
              rightProduct.nutritionInformation[value.nutritionValueKey] &&
              rightProduct.nutritionInformation[value.nutritionValueKey][
              value.nutritionSecondValueKey
              ] &&
              rightProduct.nutritionInformation[value.nutritionValueKey][
              value.nutritionSecondValueKey
              ]
              ? `${(
                rightProduct.nutritionInformation[value.nutritionValueKey][
                value.nutritionSecondValueKey
                ] *
                10 *
                rightProduct.netWeight
              ).toFixed(2)}${value.unity}`
              : "-"}
          </TableCell>
        </TableRow>
      )
    }

    return (
      <TableRow key={value.nutritionValueKey}>
        <TableCell
          className={clsx(classes.nutritionValue2, {
            indented: value.indent,
          })}
        >
          {value.nutritionLabel}
        </TableCell>
        <TableCell align="right" className={classes.nutritionValue}>
          {rightProduct &&
            rightProduct.nutritionInformation &&
            rightProduct.nutritionInformation[value.nutritionValueKey]
            ? `${(
              (rightProduct.nutritionInformation[value.nutritionValueKey] *
                0.1) /
              rightProduct.netWeight
            ).toFixed(2)}${value.unity}`
            : "-"}
        </TableCell>
        <TableCell align="right" className={classes.nutritionValue2}>
          {rightProduct &&
            rightProduct.nutritionInformation &&
            rightProduct.nutritionInformation[value.nutritionValueKey]
            ? `${rightProduct.nutritionInformation[value.nutritionValueKey]}${value.unity
            }`
            : "-"}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div className="App">
      {cardInfo && (
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.image}>
              {cardInfo.image && cardInfo.image.publicId ? (
                <CloudinaryImage
                  containerClassName={classes.planningCardImg}
                  width={600}
                  height={420}
                  crop="fill"
                  imageId={cardInfo.image && cardInfo.image.publicId}
                  customQuality="auto"
                />
              ) : (
                <img
                  className={classes.defaultPlanningCardImage}
                  src="/img/noImageAvailabled.png"
                  alt="Non disponible"
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {rightProduct && rightProduct.marketingTags.length > 0 && (
              <div className={classes.chipContainer}>
                {rightProduct.marketingTags.map((tag, index) => (
                  <div
                    key={`tag-marketing-${index}`}
                    className={classes.chip}
                  >
                    <p className={classes.chipText}>{tag.name}</p>
                  </div>
                ))}
              </div>
            )}
            <div className={classes.name2} onClick={goToProduct}>
              {`${cardInfo.uniqueCode ? `${cardInfo.uniqueCode} - ` : ""}${cardInfo.commercialName
                }`}
            </div>
            <div className={classes.headerInfo}>
              <div className={classes.flex}>
                {nutriscore ? (
                  <img
                    src={srcNutriscore}
                    alt="Nutriscore"
                    className={classes.nutritionInformation}
                  />
                ) : (
                  <span className={classes.nutriscoreLabel}>
                    Nutriscore Inconnu
                  </span>
                )}
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                {ecoscore ? (
                  <img
                    src={srcEcoscore}
                    alt="Ecoscore"
                    className={classes.ecoscoreImg}
                  />
                ) : (
                  <span className={classes.ecoscoreLabel}>
                    Ecoscore inconnu
                  </span>
                )}
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <div className={classes.price}>
                  {rightProduct &&
                    rightProduct.nutritionInformation &&
                    rightProduct.nutritionInformation.calories}
                  <div className={classes.kcal}>KCAL</div>
                </div>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <div className={classes.price}>
                  {cardInfo && cardInfo.foodcost}€ (foodcost)
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.gridDescription}>
            <div className={classes.description}>
              {rightProduct && rightProduct.description}
            </div>
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid, classes.noPadding)}>
            <div>
              <h3 className={classes.title}>Les + de cette recette</h3>
            </div>
            {rightProduct &&
              rightProduct.nutritionInformation &&
              sourcingInfo.map((tab, index) => {
                if (
                  tab.key !== "carbonFootPrint" &&
                  tab.min <= rightProduct.nutritionInformation[tab.key]
                ) {
                  return (
                    <div
                      key={`sourcing-${index}`}
                      className={classes.iconContainer}
                    >
                      <div className={classes.icon}>
                        <img src={tab.img} alt="sourcing" />
                      </div>
                      <div className={classes.iconText}>
                        <p>
                          {rightProduct.nutritionInformation[tab.key]}
                          {tab.unit} {tab.label}
                        </p>
                      </div>
                    </div>
                  )
                }

                return ""
              })}
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid, classes.noPadding)}>
            <div className={classes.chipContainer}>
              {rightProduct &&
                rightProduct.tags.map((tag, index) => (
                  <div
                    key={`tag-${index}`}
                    className={classes.chip}
                  >
                    <p className={classes.chipText}>{tag.name}</p>
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid, classes.noPadding)}>
            <div>
              <h3 className={classes.title}>Ingrédients</h3>
              <p className={classes.paragraph}>
                {rightProduct && rightProduct.ingredients}
              </p>
            </div>
            <div>
              <h3 className={classes.title}>Allergènes</h3>
              <p className={classes.paragraph}>
                {rightProduct && rightProduct.allergens}
                {rightProduct && !rightProduct.allergens && "Aucun"}
              </p>
            </div>
            <div>
              <h3 className={classes.title}>DLC</h3>
              <p className={classes.paragraph}>
                {cardInfo &&
                  Array.isArray(cardInfo.dlc) &&
                  cardInfo.dlc.map((dlc, index) => {
                    const brand = KFC_BRANDS.find(
                      (elm) => elm.name === dlc.brand
                    )
                    return (
                      <div key={`dlc-${index}`} className={classes.list}>
                        <div className={classes[`point-${brand.shortname}`]} />
                        {moment.utc(dlc.value).format("DD/MM/YYYY")}
                      </div>
                    )
                  })}
                {cardInfo && !Array.isArray(cardInfo.dlc) && (
                  <div className={classes.list}>
                    <div
                      className={
                        classes[
                        `point-${KFC_BRANDS.find(
                          (elm) => elm.name === cardInfo.brand
                        ).shortname
                        }`
                        ]
                      }
                    />
                    {moment.utc(cardInfo.dlc).format("DD/MM/YYYY")}
                  </div>
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid, classes.noPadding)}>
            <TableContainer component={Paper} className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.title}>
                      Valeurs nutritionnelles
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right" className={classes.title2}>
                      {rightProduct &&
                        rightProduct.nutritionInformation &&
                        rightProduct.nutritionInformation.calories}{" "}
                      kcal
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} className={classes.paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      align="right"
                      className={classes.nutritionValue3}
                    >
                      Pour 100g
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.nutritionValue3}
                    >
                      Au total
                    </TableCell>
                  </TableRow>
                  {nutrionalValues.map((value) => renderRow(value))}
                  {seeMore &&
                    moreNutrionalValues.map((value) => renderRow(value))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={classes.seeMore} onClick={toggleSeeMore}>
              {!seeMore && (
                <>
                  Voir plus <ExpandMore />
                </>
              )}
              {seeMore && (
                <>
                  Voir moins <ExpandLess />
                </>
              )}
            </div>
            <Divider flexItem className={classes.divider2} />
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid, classes.noPadding)}>
            <div>
              <div className={classes.iconContainer}>
                <div className={classes.icon2}>
                  {rightProduct &&
                    preparationInfo.map((prep, index) => {
                      if (
                        (rightProduct.preparation !== "1" &&
                          rightProduct.heatingInstructions &&
                          rightProduct.heatingInstructions[prep.key] &&
                          rightProduct.heatingInstructions[prep.key].duration &&
                          rightProduct.heatingInstructions[prep.key].duration >
                          0) ||
                        (rightProduct.preparation !== "2" &&
                          prep.key === "cold")
                      ) {
                        return (
                          <img
                            key={`icon-${index}`}
                            src={prep.img}
                            alt="preparation"
                          />
                        )
                      }

                      return ""
                    })}
                </div>
                <div className={classes.iconText}>
                  <p>{rightProduct && rightProduct.specialInstruction}</p>
                </div>
              </div>
              <p className={classes.paragraph}>
                {rightProduct &&
                  rightProduct.heatingInstructions &&
                  preparationInfo
                    .map((prep) => {
                      return rightProduct.preparation !== "1" &&
                        rightProduct.heatingInstructions[prep.key] &&
                        rightProduct.heatingInstructions[prep.key].duration &&
                        rightProduct.heatingInstructions[prep.key].instructions
                        ? rightProduct.heatingInstructions[prep.key]
                          .instructions
                        : ""
                    })
                    .filter((elm) => elm && elm !== "")
                    .join(", ")}
              </p>
            </div>
            <Divider flexItem className={classes.divider2} />
          </Grid>
          <Grid item xs={12} className={clsx(classes.grid)}>
            <div>
              <h3 className={classes.title}>Dans cette recette</h3>
            </div>
            <div className={classes.lastSourcing}>
              {rightProduct &&
                rightProduct.nutritionInformation &&
                sourcingInfo.map((tab, index) => {
                  return (
                    <div
                      key={`sourcing-${index}`}
                      className={classes.iconContainer}
                    >
                      <div className={classes.icon}>
                        <img src={tab.img} alt="sourcing" />
                      </div>
                      <div className={classes.iconText}>
                        <p>
                          {rightProduct.nutritionInformation[tab.key]}
                          {tab.unit} {tab.label}
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ProductInfo
