import React, { useCallback, useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS, roundNumber } from "../../../utils"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import {
    getOrderShippingFees,
    getOrderTotalAmount
} from "../../../utils/orderReception"
import DeliveryNoteHeaderTitle from "../../../components/Order/DeliveryNote/DeliveryNoteHeaderTitle"
import {
    onCloseOrderReception,
    updateShippingFees,
    updateArticleChanges
} from "../../../actions/OrderReception/OrderReception"
import {
    showSingleOrder
} from "../../../actions/OrderReception/OrderReception"
import RowInformation from "../../../components/Order/DeliveryNote/RowInformation"
import CloseButton from "../../../components/Order/DeliveryNote/CloseButton"
import SearchInput from "../../../components/Order/DeliveryNote/SearchInput"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import ClosingReceptionModal from "../../../components/Order/DeliveryNote/ClosingReceptionModal"
import DeliveryNoteCard from "../../../components/Order/DeliveryNote/DeliveryNoteCard"
import EditInformationModal from "../../../components/Order/DeliveryNote/EditInformationModal"
import EditArticleModal from "../../../components/Order/DeliveryNote/EditArticleModal"

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        overflow: "hidden",
        position: "relative"
    },
    headerBloc: {
        flex: "0 0 87px"
    },
    mainBloc: {
        backgroundColor: COLORS.WHITE,
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        paddingBottom: 50
    },
    infoBloc: {
        flex: "0 0 68px",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        borderBottom: `1px solid ${COLORS.GREY_FLAW_ICON}`,
        marginTop: 3
    },
    editContainer: {
        position: "absolute",
        right: 12,
        top: 12
    },
    searchBloc: {
        flex: "0 0 75px",
        display: "flex",
        justifyContent: "center",
        marginTop: 24
    },
    productsBloc: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margingTop: 8
    },
    fab: {
        position: "absolute",
        left: 63,
        bottom: 40
    },
    icon: {
        color: COLORS.PRIMARY_COLOR
    }
})


const MainControl = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)

    const shippingFees = useMemo(() => roundNumber(getOrderShippingFees(orderReception), 2), [orderReception])
    const totalAmount = useMemo(() => roundNumber(getOrderTotalAmount(orderReception), 2), [orderReception])
    const totalHT = roundNumber(shippingFees + totalAmount, 2)

    const site = useSelector(state => state.orderReception.site)
    const date = useSelector(state => state.orderReception.date)

    const [isExpanded, setIsExpanded] = useState(true)
    const [searchInput, setSearchInput] = useState("")
    const [showClosingModal, setShowClosingModal] = useState(false)
    const [showEditInformationModal, setShowEditInformationModal] = useState(false)
    const [showEditArticleModal, setShowEditArticleModal] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState(null)

    const [articles, setArticles] = useState(null)

    useEffect(() => {
        setArticles(getFilteredArticles())
    }, [searchInput, orderReception])

    const onBack = useCallback(() => {
        dispatch(showSingleOrder(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const getFilteredArticles = () => {
        const regex = new RegExp(searchInput, "ig")

        return orderReception && orderReception.supplierItems && orderReception.supplierItems.filter(article =>
            article.supplierItem.objectId.match(regex) !== null ||
            article.supplierItem.name.match(regex) !== null
        )
    }

    // this will create deliveryNote (obj), billError (false), isCreditNotesPaid (false), creditNotesAmount fields to orders collection
    const closeReception = () => {
        dispatch(onCloseOrderReception({
            orderReceptionId: orderReception.objectId,
            totalAmount
        }))
    }

    const onSearchChange = (e) => {
        const value = e.target.value

        setSearchInput(value)
    }

    const editDeliveryNoteInformation = () => setShowEditInformationModal(true)

    const validateShippingFees = (values) => {
        dispatch(updateShippingFees(orderReception, values.shippingFees))
    }

    const onSubmitArticleChange = (values) => {
        dispatch(updateArticleChanges(orderReception.objectId, selectedArticle, values))
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerBloc}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                    title={
                        <DeliveryNoteHeaderTitle
                            deliveryNoteNumber={(orderReception && orderReception.deliveryNote && orderReception.deliveryNote.number) || ""}
                            supplierName={(
                                orderReception &&
                                orderReception.supplier &&
                                orderReception.supplier.name
                            ) || ""}
                            orderNumber={(orderReception && orderReception.orderNumber) || ""}
                        />
                    }
                    rightAction={
                        <CloseButton
                            closeReception={() => setShowClosingModal(true)}
                        />
                    }
                />
            </div>
            <div className={classes.infoBloc}>
                <RowInformation
                    shippingFees={shippingFees}
                    totalAmount={totalAmount}
                    totalHT={totalHT}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                />
                {
                    isExpanded &&
                    <IconButton
                        className={classes.editContainer}
                        disableRipple={true}
                        onClick={editDeliveryNoteInformation}
                        size="large">
                        <EditIcon className={classes.icon} />
                    </IconButton>
                }
            </div>
            <div className={classes.searchBloc}>
                <SearchInput
                    onSearchChange={onSearchChange}
                    searchInput={searchInput}
                />
            </div>
            <div className={classes.mainBloc}>
                <div className={classes.productsBloc}>
                    {
                        articles && articles.map((article, index) => (
                            <DeliveryNoteCard
                                key={index}
                                article={article}
                                editSelectedArticle={() => {
                                    setSelectedArticle(article)
                                    setShowEditArticleModal(true)
                                }}
                            />
                        ))
                    }
                </div>
            </div>
            {
                showClosingModal &&
                <ClosingReceptionModal
                    open={showClosingModal}
                    onClose={closeReception}
                    onBack={() => setShowClosingModal(false)}
                />
            }
            {
                showEditInformationModal &&
                <EditInformationModal
                    open={showEditInformationModal}
                    validate={validateShippingFees}
                    onBack={() => setShowEditInformationModal(false)}
                    orderReception={orderReception}
                />
            }
            {
                showEditArticleModal &&
                <EditArticleModal
                    open={showEditArticleModal}
                    onSubmit={onSubmitArticleChange}
                    onBack={() => {
                        setShowEditArticleModal(false)
                        setSelectedArticle(null)
                    }}
                    article={selectedArticle}
                />
            }
        </div>
    )
}

export default MainControl