import React from "react"
import { useDispatch } from "react-redux"
import { showSubcontractorsProductsList } from "../../actions/Subcontractor/subcontractorsProducts"
import { showRecipeList } from "../../actions/Recipe/recipes"
import PageTabs from "../../components/PageTabs"
import ProductPageHeader from "./ProductPageHeader"

// list of tab
const tabs = [
    {
        value: "recipes",
        label: "Produits internes"
    },
    {
        value: "subcontractorsProducts",
        label: "Produits sous-traitants"
    }
]

const RecipesPage = ({ children, tabValue }) => {
    const dispatch = useDispatch()

    const onTabChange = (event, tabValue) => {
        // subcontractorsProducts
        if (tabValue === tabs[1].value) {
            dispatch(showSubcontractorsProductsList())
            return
        }
        // recipes
        dispatch(showRecipeList())
    }

    return (
        <PageTabs
            title={<ProductPageHeader />}
            tabs={tabs}
            tabValue={tabValue}
            onTabChange={onTabChange}
        >
            {children}
        </PageTabs>
    )
}

export default RecipesPage