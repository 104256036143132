import React, {
    useState,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef
} from "react"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import {Button, Grid} from "@mui/material"
import {
    createSectionGeneric,
    showSectionsGenericList
} from "../../actions/Section/SectionsGeneric"
import { RecipeStylesNew } from "../../styles"
import { sectionGenericFormInitialValues } from "../../actions/Utils/utils"
import ValidateChangeModal from "../../components/Section/ValidateChangeModal"
import ValidateCreationModal from "../../components/Section/ValidateCreationModal"
import { RecipeSchema } from "../../utils/yupValidators"
import Icon from "@mui/material/Icon"
import { Form, Formik, Field } from "formik"
import Sections from "../../components/Recipe/Sections"
import { usePrevious } from "react-use"
import { computeRecipeDataOnFieldChange } from "../../utils/recipes"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Clear"
import Paper from "@mui/material/Paper"
import _isEqual from "lodash/isEqual"
import { grey } from "@mui/material/colors"

const useStyles = makeStyles({
    appBarMenuClosed: {
        composes: "$appBar",
        width: "calc(100% - 54.4px)"
    },
    appBarMenuOpenned: {
        composes: "$appBar",
        width: "calc(100% - 239px)"
    },
    appBarRoot: {
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        display: "flex",
        flexDirection: "row-reverse",
        background: "initial"
    },
    backIcon: {
        paddingLeft: 25
    },
    cancel: {
        marginRight: 15
    },
    toolbarGutters: {
        paddingLeft: 0,
        "& > div": {
            marginBottom: -5
        }
    },
    ...RecipeStylesNew
})

const SectionGenericForm = () => {
    const sectionsGeneric = useSelector(state => state.sectionsGeneric.sectionsGeneric, _isEqual)
    const selectedSectionGeneric = useSelector(state => state.sectionsGeneric.selectedSectionGeneric, _isEqual)
    const relatedSectionsCount = useSelector(state => state.sectionsGeneric.relatedSectionsCount, _isEqual)
    const isMenuOpen = useSelector(state => state.app.menuIsOpen)
    const [initialValues, setInitialValues] =  useState(sectionGenericFormInitialValues(selectedSectionGeneric))

    const [openModal, setOpenModal] = useState(false)
    const [openModalCreation, setOpenModalCreation] = useState(false)
    const [submittedValues, setSubmittedValues] = useState(null)
    const [editing, setEditing] = useState(initialValues.sections[0].id ? false : true)
    const [fieldFocused, setFieldFocused] = useState(false)
    const [rowHover, setRowHover] = useState(null)
    const [deleteHover, setDeleteHover] = useState(null)
    const [paddingHeader, setPaddingHeader] = useState(0)

    const dispatch = useDispatch()
    const classes = useStyles()
    const nextRowHoverRef = useRef(null)
    const nextRowHover = nextRowHoverRef.current
    const submitFormRef = useRef(null)
    const submitForm = submitFormRef.current
    const sectionRef = useRef()

    useEffect(() => {
        setInitialValues(sectionGenericFormInitialValues(selectedSectionGeneric))
    }, [editing, selectedSectionGeneric])

    useLayoutEffect(() => {
        if (sectionRef.current) {
            setPaddingHeader(sectionRef.current.offsetWidth - sectionRef.current.clientWidth)
        }
    }, [])

    const _onClose = () => dispatch(showSectionsGenericList())

    const _onFieldFocus = () => setFieldFocused(true)

    const _onClearFocus = () => setFieldFocused(false)

    const _onFieldBlur = (event, setFieldTouched) => {
        setFieldFocused(false)

        if (nextRowHover) {
            setRowHover(nextRowHover)
            nextRowHoverRef.current = null
        }

        setFieldTouched(event.target.name)
    }

    const _onRowHover = (component, index, parentIndex = null) => {
        if (!fieldFocused) {
            setRowHover({ component, index, parentIndex })
        } else {
            nextRowHoverRef.current = { component, index, parentIndex }
        }
    }

    const _onRowBlur = () => {
        if (!fieldFocused) {
            setRowHover(null)
        } else {
            nextRowHoverRef.current = null
        }
    }

    const _onDeleteHover = (component, index, parentIndex = null) => setDeleteHover({ component, index, parentIndex })

    const _onDeleteBlur = () => setDeleteHover(null)

    const _onKeyUp = (event, setFieldTouched) => setFieldTouched(event.target.name)

    const _onSubmit = useCallback((values) => {
        if (relatedSectionsCount > 0) {
            setOpenModal(true)
            setSubmittedValues(values)
            return
        }
        if (!selectedSectionGeneric) {
            setOpenModalCreation(true)
            setSubmittedValues(values)
            return
        }
        
        dispatch(createSectionGeneric(values, selectedSectionGeneric))
        _handleResetForm()
    }, [relatedSectionsCount, selectedSectionGeneric])

    const _validateSubmit = useCallback(() => {
        if (openModal) {
            setOpenModal(false)
        }

        if (openModalCreation) {
            setOpenModalCreation(false)
        }

        dispatch(createSectionGeneric(submittedValues, selectedSectionGeneric))
        _handleResetForm()
    }, [submittedValues, selectedSectionGeneric])

    const _checkChanges = (current, previousProps, setFieldValue, validateForm) => {
        if (! previousProps || ! previousProps.values) {
            return
        }

        const previous = previousProps.values

        if (! current || ! previous || ! current.sections || ! previous.sections) {
            return
        }

        // Check for sections length diff
        if (current.sections.length !== previous.sections.length) {
            _handleComputation(current, setFieldValue)
            validateForm()
            return
        }

        // Check for steps length diff
        let found = false
        for (let i = 0, l = current.sections.length ; i < l && !found ; i++) {
            if (current.sections[i].steps.length !== previous.sections[i].steps.length) {
                _handleComputation(current, setFieldValue, i)
                validateForm()
                found = true
            }
        }

        if (found) {
            return
        }

        // Check for ingredients length and data diff
        for (let i = 0, l = current.sections.length ; i < l && !found ; i++) {
            for (let j = 0, ll = current.sections[i].steps.length ; j < ll && !found ; j++) {
                const currentIngredients = current.sections[i].steps[j].ingredients
                const previousIngredients = previous.sections[i].steps[j].ingredients

                if (currentIngredients.length !== previousIngredients.length) {
                    _handleComputation(current, setFieldValue, i, j)
                    validateForm()
                    found = true
                } else {
                    for (let k = 0, lll = currentIngredients.length ; k < lll && !found ; k++) {
                        if (
                            currentIngredients[k].grossWeight !== previousIngredients[k].grossWeight ||
                            currentIngredients[k].cookingMode !== previousIngredients[k].cookingMode
                        ) {
                            _handleComputation(current, setFieldValue, i, j, k)
                            validateForm()
                            found = true
                        }
                    }
                }
            }
        }
    }

    const _handleComputation = (values, setFieldValue, sectionIndex = 0, stepIndex = null, ingredientIndex = null) => {
        computeRecipeDataOnFieldChange(values, sectionIndex, stepIndex, ingredientIndex)
        for (const key of ["sections", "cost", "netWeight", "asp"]) {
            setFieldValue(key, values[key])
        }
    }

    const _bindSubmitAndResetForm = submitForm => {
        submitFormRef.current = submitForm
    }

    const _handleSubmitForm = event => {
        if (submitForm) submitForm(event)
    }

    const _handleResetForm = () => {
        if (initialValues.sections[0].id) {
            setEditing(false)
        } else {
            _onClose()
        }
    }

    const _onEdit = () => setEditing(true)

    return (
        <Paper>
            {
                !editing &&
                <IconButton
                    className={classes.backIcon}
                    edge="start"
                    color="inherit"
                    onClick={_onClose}
                    aria-label="close"
                    size="large">
                    <CloseIcon />
                </IconButton>
            }
            <Formik
                initialValues={initialValues}
                validationSchema={RecipeSchema}
                onSubmit={_onSubmit}
                validateOnChange={false}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, setFieldError, setFieldTouched, submitForm, validateForm }) => {
                    _bindSubmitAndResetForm(submitForm)
                    const previousProps = usePrevious({ values })

                    useLayoutEffect(() => _checkChanges(values, previousProps, setFieldValue, validateForm))

                    return (
                        <>
                            {
                                !editing && (
                                    <div className={classes.header}>
                                        <p>{/* Here will be the last edition */}</p>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.editButton}
                                            endIcon={<Icon>edit</Icon>}
                                            onClick={_onEdit}
                                        >
                                            Editer la section
                                        </Button>
                                    </div>
                                )
                            }
                            {
                                editing && (
                                    <AppBar className={isMenuOpen ? classes.appBarMenuOpenned : classes.appBarMenuClosed} classes={{
                                        root: classes.appBarRoot
                                    }}>
                                        <Toolbar classes={{
                                            gutters: classes.toolbarGutters
                                        }}>
                                            <Button
                                                color="primary"
                                                className={classes.cancel}
                                                onClick={_handleResetForm}
                                            >
                                                ANNULER
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={_handleSubmitForm}
                                            >
                                                ENREGISTRER
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                )
                            }
                            <Form>
                                <Grid container className={`${classes.container} ${editing ? classes.marginTop : ""}`}>
                                    <Grid container item xs={12} className={classes.natHeader} style={{
                                        paddingRight: `${paddingHeader}px`
                                    }}>
                                        <Grid container item xs={5} className={classes.natBorder} alignItems="center">
                                            <Grid item xs={12} className={`${classes.natCell} ${classes.natHeaderInfos}`}>
                                                <span>
                                                    <Field
                                                        type="checkbox"
                                                        name="sections[0].print"
                                                        disabled={!editing}
                                                        style={{maxWidth: 20}}
                                                    />
                                                    Imprimer
                                                </span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={3} className={classes.natBorder} alignItems="center">
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>P. BRUT (KG)</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>PRIX / KG</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.pricePadding}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>PRIX</Grid>
                                                <Grid item xs={12} className={classes.bold}></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={4} className={classes.natBorder} alignItems="center">
                                            <Grid container item xs={4} className={`${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>CUISSON</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid container item xs={4} className={`${classes.alignRight} ${classes.natCell}`} alignItems="flex-end">
                                                <Grid item xs={12} className={classes.natColumnTitle}>TRANSFO</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid container item xs={3} className={`${classes.alignRight} ${classes.natCell}`} alignItems="flex-end">
                                                <Grid item xs={12} className={classes.natColumnTitle}>P. NET (KG)</Grid>
                                                <Grid item xs={12} className={classes.bold}></Grid>
                                            </Grid>
                                            <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}></Grid>
                                        </Grid>
                                    </Grid>
                                    <div
                                        className={editing ? classes.scrollContainerEditing : classes.scrollContainer}
                                        ref={sectionRef}
                                    >
                                        <Sections
                                            sections={values.sections}
                                            genericSections={sectionsGeneric}
                                            editing={editing}
                                            onFieldFocus={_onFieldFocus}
                                            onClearFocus={_onClearFocus}
                                            onFieldBlur={(e) => _onFieldBlur(e, setFieldTouched)}
                                            rowHover={rowHover}
                                            onRowHover={_onRowHover}
                                            onRowBlur={_onRowBlur}
                                            deleteHover={deleteHover}
                                            onDeleteHover={_onDeleteHover}
                                            onDeleteBlur={_onDeleteBlur}
                                            onKeyUp={(e) => _onKeyUp(e, setFieldTouched)}
                                            setFieldValue={setFieldValue}
                                            setFieldError={setFieldError}
                                            errors={errors}
                                            setFieldTouched={setFieldTouched}
                                            createReusableSection={() => {}}
                                            computationHandler={
                                                (sectionIndex, stepIndex, ingredientIndex) =>
                                                    _handleComputation(values, setFieldValue, sectionIndex, stepIndex, ingredientIndex)
                                            }
                                            status={"1"}
                                            fromGenericSections={true}
                                        />
                                    </div>
                                </Grid>
                            </Form>
                        </>
                    )
                }}
            </Formik>
            <ValidateChangeModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                action={_validateSubmit}
                count={relatedSectionsCount}
            />
            <ValidateCreationModal
                open={openModalCreation}
                onClose={() => setOpenModalCreation(false)}
                action={_validateSubmit}
                title={submittedValues && submittedValues.sections[0].name}
            />
        </Paper>
    )
}

export default SectionGenericForm
