import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import Divider from "@mui/material/Divider"
import { supplierInvoiceSchema } from "../../utils/yupValidators"
import { getSupplierInvoiceInitialValues } from "../../utils/suppliers"
import RoundFormControlLabel from "../RoundFormControlLabel"
import { red, green } from "@mui/material/colors"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        width: 424,
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14,
            lineHeight: "11px"
        },
        "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            color: COLORS.BLACK,
            height: 20,
            transform: "none",
            position: "relative"
        }
    },
    formControl50: {
        composes: "$formControl",
        width: 198
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px",
        margin: "5px 0px 0px 0px"
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: 15,
        marginBottom: 15
    },
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: green[400]
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: green[400]
    }
}))

export const InvoiceResumeEdit = (props) => {
    const {
        onClose,
        isEditing,
        jsonSupplier,
        onSaveSupplier
    } = props

    const classes = useStyles()

    const submitForm = async (values) => {
        await onSaveSupplier(values)
        onClose()
    }

    return (
        <Formik
            initialValues={getSupplierInvoiceInitialValues(jsonSupplier)}
            onSubmit={submitForm}
            validationSchema={supplierInvoiceSchema}
        >
            {({
                  values,
                  setFieldValue,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer la Facturation</span>
                            </DialogTitle>
                            <DialogContent dividers>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Facture</InputLabel>
                                </FormControl>
                                <RoundFormControlLabel
                                    classes={classes}
                                    checked={values.invoiceEqualToBL}
                                    onChange={() => setFieldValue("invoiceEqualToBL", !values.invoiceEqualToBL)}
                                    label="N° Facture = N° BL"
                                    labelPlacement="start"
                                />
                                <Divider />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Réclamation</InputLabel>
                                </FormControl>
                                <RoundFormControlLabel
                                    classes={classes}
                                    checked={values.autoComplain}
                                    onChange={() => setFieldValue("autoComplain", !values.autoComplain)}
                                    label="Créer automatiquement les réclamations :"
                                    labelPlacement="start"
                                />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel></InputLabel>
                                </FormControl>
                                <RoundFormControlLabel
                                    classes={classes}
                                    checked={values.autoSendEmailComplain}
                                    onChange={() => setFieldValue("autoSendEmailComplain", !values.autoSendEmailComplain)}
                                    label="Envoi automatique par e-mail :"
                                    labelPlacement="start"
                                />
                                <Divider />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Avoirs</InputLabel>
                                </FormControl>
                                <RoundFormControlLabel
                                    classes={classes}
                                    checked={values.autoSendEmailCredit}
                                    onChange={() => setFieldValue("autoSendEmailCredit", !values.autoSendEmailCredit)}
                                    label="Envoi automatique par e-mail :"
                                    labelPlacement="start"
                                />
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    className={classes.button1}
                                    onClick={onClose}
                                    color="primary"
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default InvoiceResumeEdit
