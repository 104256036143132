import React, { useLayoutEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { TextField, Tooltip } from "@mui/material"
import clsx from "clsx"
import InfoIcon from "@mui/icons-material/Info"

import { computeMinDateDelivery, getDateDeliveryErrorMessage } from "../../../utils/ordersUtils"
import { COLORS, getTimezoneDate } from "../../../utils"

const useStyles = makeStyles({
    container: {
        width: "100%",
        padding: "8px 12px 16px 8px",
        backgroundColor: COLORS.WHITE
    },
    dateRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 48px 16px 5px",
        borderBottom: `1px solid ${COLORS.GREY_ORDER_BORDER}`
    },
    weightRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 48px 8px 5px",
        borderBottom: `1px solid ${COLORS.GREY_ORDER_BORDER}`
    },
    minimumDateDeliveryRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 48px 8px 5px",
        borderBottom: `1px solid ${COLORS.GREY_ORDER_BORDER}`
    },
    pricesRow: {
        width: "100%",
        borderBottom: `1px solid ${COLORS.GREY_ORDER_BORDER}`,
        paddingTop: 12,
        "& > div": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 48px 8px 5px"
        }
    },
    infoText: {
        fontSize: 14,
        lineHeight: "24px"
    },
    datePicker: {
        maxWidth: 140
    },
    productLabel: {
        composes: "flexRow alignCenter"
    },
    titleError: {
        marginRight: 7,
        color: COLORS.RED,
    },
    errorIcon: {
        color: COLORS.RED,
        cursor: "pointer",
        fontSize: 20
    },
    errorTooltip: {
        backgroundColor: COLORS.RED,
        fontSize: 14,
    },
    arrow: {
        color: COLORS.RED,
    },
})

const OrderFooter = (props) => {
    const {
        totalWeight,
        totalPrice,
        shippingFees,
        expectedDeliveryDate,
        actions,
        order,
        selectedOrder,
        updateValidatedOrder
    } = props

    const [unitError, setUnitError] = useState("")
    const [orderShippingFees, setOrderShippingFees] = useState(shippingFees)
    const [dateDeliveryError, setDateDeliveryError] = useState("")
    const [minDateDelivery, setMinDateDelivery] = useState(order ? computeMinDateDelivery(order) : null)
    const classes = useStyles()

    useLayoutEffect(() => {
        if (!order) return

        let isValidatedOrder = true
        setMinDateDelivery(computeMinDateDelivery(order))
        order.minDateDelivery = computeMinDateDelivery(order)
        if (order.minimumCommandUnit === "€") {
            if (totalPrice >= order.minimumCommand) {
                setUnitError("")
                if (order.allowShippingFeesCancel) {
                    setOrderShippingFees(0)
                }
            } else {
                setUnitError("€")
                isValidatedOrder = false
            }
        } else if (order.minimumCommandUnit === "kg" || order.minimumCommandUnit === "Kg") {
            if (totalWeight >= order.minimumCommand) {
                setUnitError("")
                if (order.allowShippingFeesCancel) {
                    setOrderShippingFees(0)
                }
            } else {
                setUnitError("kg")
                isValidatedOrder = false
            }
        }

        if (expectedDeliveryDate && isValidatedOrder) {
            const deliveryDateError = getDateDeliveryErrorMessage(moment(expectedDeliveryDate), minDateDelivery, order)

            isValidatedOrder = (deliveryDateError === "")
            setDateDeliveryError(deliveryDateError)
        }
        updateValidatedOrder(expectedDeliveryDate && isValidatedOrder)
    }, [order, totalWeight, totalPrice])

    const onExpectedDeliveryDateChange = (date) => {
        const deliveryDateError = getDateDeliveryErrorMessage(moment(date), minDateDelivery, order)

        setDateDeliveryError(deliveryDateError)
        updateValidatedOrder(deliveryDateError === "")

        actions({
            method: "DELIVERY_DATE_UPDATED",
            data: {
                expectedDeliveryDate: getTimezoneDate(date)
            }
        })

    }

    return (
        <div className={classes.container}>
            <div className={classes.dateRow}>
                {selectedOrder?.orderStatus !== "DONE" ?
                    <Typography className={clsx(dateDeliveryError !== "" ? classes.titleError : "", classes.infoText)}>
                        Date de livraison souhaitée
                    </Typography>
                    :
                    <Typography className={classes.infoText}>
                        Date de livraison souhaitée
                    </Typography>
                }
                {dateDeliveryError !== "" && selectedOrder?.orderStatus !== "DONE" &&(
                    <Tooltip
                        placement="top"
                        title={dateDeliveryError}
                        enterDelay={500}
                        leaveDelay={200}
                        arrow
                        disableInteractive
                        classes={{
                            tooltip: classes.errorTooltip,
                            arrow: classes.arrow,
                        }}
                    >
                        <InfoIcon className={classes.errorIcon} />
                    </Tooltip>
                )}
                {selectedOrder && selectedOrder.orderStatus === "DONE"
                    ? (
                        <div className={classes.datePicker}>
                            <DatePicker
                                disableCloseOnSelect={false}
                                showToolbar={false}
                                value={moment(expectedDeliveryDate).valueOf()}
                                onChange={onExpectedDeliveryDateChange}
                                inputFormat="DD/MM/YYYY"
                                minDate={moment(expectedDeliveryDate)}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                            />
                        </div>
                    ) : (
                        <div className={classes.datePicker}>
                            <DatePicker
                                disableCloseOnSelect={false}
                                showToolbar={false}
                                value={moment(expectedDeliveryDate).valueOf()}
                                onChange={onExpectedDeliveryDateChange}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                            />
                        </div>
                    )
                }
            </div>
            <div className={classes.weightRow}>
                <div className={classes.productLabel}>
                    <Typography className={clsx(unitError === "kg" ? classes.titleError : "", classes.infoText)}>
                        Poids des produits
                    </Typography>
                    {unitError === "kg" && (
                        <Tooltip
                            placement="top"
                            title={`Le minimum de commande en poids n’est pas atteint. Il manque ${(order.minimumCommand - totalWeight).toFixed(3)} kg pour atteindre ce minimum.`}
                            enterDelay={500}
                            leaveDelay={200}
                            arrow
                            disableInteractive
                            classes={{
                                tooltip: classes.errorTooltip,
                                arrow: classes.arrow,
                            }}
                        >
                            <InfoIcon className={classes.errorIcon} />
                        </Tooltip>
                    )}
                </div>
                <Typography className={classes.infoText}>
                    {`${totalWeight} kg`}
                </Typography>
            </div>
            <div className={classes.pricesRow}>
                <div>
                    <div className={classes.productLabel}>
                        <Typography className={clsx(unitError === "€" ? classes.titleError : "", classes.infoText)}>
                            Montant des produits
                        </Typography>
                        {unitError === "€" && (
                            <Tooltip
                                placement="top"
                                title={`Le montant minimum de commande n’est pas atteint. Il manque ${(order.minimumCommand - totalPrice).toFixed(3)} € pour atteindre ce minimum.`}
                                enterDelay={500}
                                leaveDelay={200}
                                disableInteractive
                                arrow
                                classes={{
                                    tooltip: classes.errorTooltip,
                                    arrow: classes.arrow,
                                }}
                            >
                                <InfoIcon className={classes.errorIcon} />
                            </Tooltip>
                        )}
                    </div>
                    <Typography className={classes.infoText}>
                        {`${totalPrice} €`}
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.infoText}>
                        Frais de port
                    </Typography>
                    <Typography className={classes.infoText}>
                        {`${orderShippingFees} €`}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default OrderFooter
