import React, { useState } from "react"
import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { COLORS } from "../utils"

export const MoreMenu = ({ menus, color }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectMenu = (menu) => {
    if (menu) {
      menu.onClick()
    }
    handleClose()
  }

  if (!menus || !menus.length || !Array.isArray(menus)) return null

  return (
    <>
      <IconButton
        id="more-menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large">
        <MoreVertIcon sx={{ color: color || COLORS.LABEL_GREY }} />
      </IconButton>
      <Menu
        id="more-menu"
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        MenuListProps={{
          "aria-labelledby": "more-menu-button",
        }}
      >
        {menus.map((menu, index) => (
          <MenuItem
            onClick={() => handleSelectMenu(menu)}
            key={index}
          >
            {menu.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MoreMenu