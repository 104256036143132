import React from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import { Typography } from "@mui/material"

const useStyles = makeStyles({
    modal: {
        position: "absolute",
        width: 590,
        minHeight: 183,
        background: COLORS.WHITE,
        textAlign: "justify",
        color: COLORS.BLACK,
        padding: "30px 30px 15px 30px",
        borderRadius: 5,
        fontSize: 16,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 15,
        marginBottom: 10
    },
    title: {
        fontWeight: 500,
        lineHeight: "24px",
        paddingBottom: 15
    },
    description: {
        marginBottom: 12,
    },
    firstButton: {
        marginRight: 28,
    }
})

const orderCancelModal = (props) => {
    const {
        onCancel,
        onClose,
        open
    } = props

    const classes = useStyles()

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modal}>
                <div className={classes.title}>
                    <Typography variant="h6">
                        Annuler la commande
                    </Typography>
                </div>
                <div className={classes.description}>
                    <Typography>
                        Êtes-vous certains de vouloir annuler cette commande ? Une demande d’annulation sera envoyée par mail au fournisseur.
                    </Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        color="primary"
                        onClick={onClose}
                        className={classes.firstButton}
                    >
                        Retour
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={onCancel}
                        variant="contained"
                    >
                        Annuler la commande
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default orderCancelModal