import React, { useCallback, useState, useEffect } from "react"
import { Add } from "@mui/icons-material"
import {
    Box,
    Fab
} from "@mui/material"
import DataGrid from "../../components/DataGrid/DataGrid"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { showReusableProductionStepCreation, showReusableProductionStepView } from "../../actions/Steps/reusableProductionSteps."
import { getReusableProductionStepsSelector } from "../../reducers/Steps/steps"

const columns = [
    {
        key: "id",
        label: "ID",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            strict: true
        },
        width: 120
    },
    {
        key: "name",
        label: "Nom",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        }
    },
    {
        key: "updatedAt",
        label: "Date de modification",
        dataType: "date",
        type: "date",
        sortable: true,
        filter: {
            filterable: false,
            type: "date"
        },
        width: 130
    }
]

const ReusableSteps = () => {
    const [data, setData] = useState([])

    const dispatch = useDispatch()
    const steps = useSelector(getReusableProductionStepsSelector)

    const goToReusableStepCreation = () => dispatch(showReusableProductionStepCreation())

    const createData = (step) => {
        const serializedStep = {
            id: step.objectId,
            name: step.name,
            updatedAt: dayjs(step.updatedAt).format("DD/MM/YYYY")
        }
        return serializedStep
    }

    const createDataObject = useCallback(() => {
        setData(steps.map(createData))
    }, [steps])

    useEffect(() => {
        createDataObject()
    }, [steps])


    const handleRowClick = (step) => {
        dispatch(showReusableProductionStepView(step.id))
    }

    return (
        <>
            <DataGrid
                title="Étapes réutilisables"
                columns={columns}
                data={data}
                withFilters
                rowLabel="étapes réutilisables"
                onRowClick={handleRowClick}
            />
            {/* --------- add button --------- */}
            <Box sx={{ position: "fixed", left: 260, bottom: 20 }}>
                <Fab color="primary" onClick={goToReusableStepCreation}>
                    <Add />
                </Fab>
            </Box>
        </>
    )
}

export default ReusableSteps
