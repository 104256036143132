import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import { IconButton, Menu, MenuItem, Dialog, DialogContent, DialogActions, Button, Typography, Snackbar } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import MySnackbarContentWrapper from "../MySnackbarContentWrapper"
import { COLORS, capitalizeFirstLetter } from "../../utils"
import { red } from "@mui/material/colors"
import CantDeleteSupplierItemModal from "./CantDeleteSupplierItemModal"
import { supplierItemTypes } from "../../utils/supplierItemUtils"
import { getSupplierItemProductType } from "../../actions/Utils/utils"
import { deleteSupplierItem, showSupplierItems, duplicateSupplierItem, extractRecipesWithSupplierItemId, closeSupplierItemsSnackBar } from "../../actions/Supplier/supplierItems"
import { getSupplierItemSnackbarSelector } from "../../reducers/Suppliers/SupplierItems/supplierItems"
import CloudinaryImage from "../Image/CloudinaryImage"

const useStyles = makeStyles(() => ({
    header: {
        width: "100%",
        backgroundColor: COLORS.BLUEGREY_700,
        height: 160,
        display: "flex",
        color: COLORS.WHITE
    },
    supplierItemImage: {
        width: 245,
        height: "100%"
    },
    infosContainer: {
        width: "calc(100% - 245px)",
        paddingLeft: 48,
        paddingTop: 16,
        paddingRight: 16
    },
    supplierItemName: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 30,
    },
    supplierItemCommercialName: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 14,
    },
    subInfosContainer: {
        display: "flex",
        marginTop: 16
    },
    singleSubInfoContainer: {
        display: "flex",
        flexDirection: "column"
    },
    notFirstSingleSubInfoContainer: {
        composes: "$singleSubInfoContainer",
        marginLeft: 32
    },
    subInfoLabel: {
        fontSize: 12
    },
    subInfoValue: {
        fontSize: 16,
        marginTop: 8
    },
    modal: {
        height: 50,
        width: 60
    },
    infoRaw: {
        display: "flex",
    },
    deleteAction: {
        padding: "16px"
    },
    IconButton: {
        color: COLORS.WHITE
    },
    activeStateValue: {
        color: COLORS.GREEN_INVENTORY,
    },
    inactiveStateValue: {
        color: red[400],
    },
}))

export const Header = (props) => {
    const {
        supplierItem,
        image,
        canDelete
    } = props

    const dispatch = useDispatch()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false)
    const [openCantDeleteDialog, setOpenCantDeleteDialog] = useState(false)
    const [productType, setProductType] = useState("")
    const supplierItemSnackBar = useSelector(getSupplierItemSnackbarSelector)

    // -------------------------------------- //
    // --------------- deletion ------------- //
    // -------------------------------------- //
    const handleDeleteSupplierItem = async () => {
        await dispatch(deleteSupplierItem(supplierItem.objectId))
        dispatch(showSupplierItems(true))
    }

    const _openCantDeleteDialog = () => {
        setOpenCantDeleteDialog(true)
    }
    const _closeCantDeleteDialog = () => {
        setOpenCantDeleteDialog(false)
        setOpenDeleteDialog(false)
        handleClose()
    }
    // -------------------------------------- //
    // ------------- duplication ------------ //
    // -------------------------------------- //

    const _closeDuplicationDialog = () => {
        setDuplicateDialogOpen(false)
        handleClose && handleClose()
    }
    const _duplicateSupplierItem = async (supplierItem) => {
        await dispatch(duplicateSupplierItem(supplierItem.objectId))
        setDuplicateDialogOpen(false)
        handleClose && handleClose()
    }

    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

    const allergens = supplierItem.commercialName && Array.isArray(supplierItem.commercialName.allergens)
        ? supplierItem.commercialName.allergens.map(elem => capitalizeFirstLetter(elem.name)).join(", ")
        : ""

    const type = supplierItemTypes[supplierItem.type].label

    const supplierName = supplierItem.supplier
        ? supplierItem.supplier.name
        : ""

    const supplierItemExportAllowed = useMemo(() => supplierItem && supplierItem.type === "RAW_MATERIAL", [supplierItem])

    useEffect(() => {
        async function fetchSupplierItemProductType() {
            const productTypeResult = supplierItem.productType
                ? await getSupplierItemProductType(supplierItem)
                : ""
            setProductType(productTypeResult)
        }
        fetchSupplierItemProductType()
    }, [])

    return (
        <div className={classes.header}>
            {
                image ?
                    <CloudinaryImage
                        containerClassName={classes.supplierItemImage}
                        imageId={image && image.publicId}
                        width={240}
                        height={160}
                    />
                    : <img
                        src="/img/noImageAvailabled.png"
                        alt="Indisponible"
                        className={classes.supplierItemImage}
                    />
            }
            <div className={classes.infosContainer}>
                <div>
                    <div className={classes.supplierItemName}>
                        {supplierItem.name}
                    </div>
                    {
                        supplierItem && supplierItem.type === supplierItemTypes.RAW_MATERIAL.key && supplierItem.commercialName &&
                        <div className={classes.supplierItemCommercialName}>
                            {supplierItem.commercialName.name}
                        </div>
                    }
                </div>
                <div className={classes.subInfosContainer}>
                    <div className={classes.notFirstSingleSubInfoContainer}>
                        <span className={classes.subInfoLabel}>
                            État
                        </span>
                        <span className={clsx(
                            classes.subInfoValue,
                            supplierItem.isActive
                                ? classes.activeStateValue
                                : classes.inactiveStateValue
                        )}>
                            {supplierItem.isActive ? "Actif" : "Inactive"}
                        </span>
                    </div>
                    <div className={classes.notFirstSingleSubInfoContainer}>
                        <span className={classes.subInfoLabel}>
                            Fournisseur
                        </span>
                        <span className={classes.subInfoValue}>
                            {supplierName}
                        </span>
                    </div>
                    <div className={classes.notFirstSingleSubInfoContainer}>
                        <span className={classes.subInfoLabel}>
                            Type
                        </span>
                        <span className={classes.subInfoValue}>
                            {type}
                        </span>
                    </div>
                    {
                        supplierItem &&
                        (supplierItem.type === "PACKAGING_CONSUMABLE" ||
                            supplierItem.type === "SALABLE_PRODUCT") &&
                        <div className={classes.notFirstSingleSubInfoContainer}>
                            <span className={classes.subInfoLabel}>
                                Type de produit
                            </span>
                            <span className={classes.subInfoValue}>
                                {productType}
                            </span>
                        </div>
                    }
                    {
                        supplierItem.type === "SALABLE_PRODUCT" &&
                        <div className={classes.notFirstSingleSubInfoContainer}>
                            <span className={classes.subInfoLabel}>
                                Nb d'utilisations de l'article
                            </span>
                            <span className={classes.subInfoValue}>
                                {supplierItem?.subcontractorProductQuantity || 0}
                            </span>
                        </div>
                    }
                    {
                        supplierItem.type === supplierItemTypes.RAW_MATERIAL.key &&
                        <div className={classes.infoRaw}>
                            <div className={classes.notFirstSingleSubInfoContainer}>
                                <span className={classes.subInfoLabel}>
                                    Allergènes
                                </span>
                                <span className={classes.subInfoValue}>
                                    {allergens}
                                </span>
                            </div>
                            <div className={classes.notFirstSingleSubInfoContainer}>
                                <span className={classes.subInfoLabel}>
                                    Nb d'utilisations de l'article
                                </span>
                                <span className={classes.subInfoValue}>
                                    {supplierItem.recipeQuantity || 0}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {canDelete &&
                <>
                    <div className={classes.deleteAction}>
                        <IconButton
                            className={classes.IconButton}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            size="large">
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id={id}
                            open={Boolean(open)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                        >
                            {supplierItemExportAllowed && <MenuItem onClick={() => dispatch(extractRecipesWithSupplierItemId(supplierItem.objectId))}>Exporter les utilisations de l'article</MenuItem>}
                            <MenuItem onClick={() => setDuplicateDialogOpen(true)}>Dupliquer cet article</MenuItem>
                            <MenuItem onClick={() => setOpenDeleteDialog(true)}>Supprimer cet article</MenuItem>
                        </Menu>
                    </div>
                    {/* confirmation before deletion */}
                    <Dialog
                        onClose={() => setOpenDeleteDialog(false)}
                        aria-labelledby="delete-supplierItem-dialog"
                        open={openDeleteDialog}
                        className={classes.dialog}
                    >
                        <DialogContent>
                            <p>
                                Voulez-vous supprimer cet article fournisseur ?
                            </p>
                        </DialogContent>
                        <DialogActions classes={{ root: classes.rootDialogActions }}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setOpenDeleteDialog(false)
                                    handleClose && handleClose()
                                }}
                            >
                                ANNULER
                            </Button>
                            {supplierItem.type === "PACKAGING_CONSUMABLE" &&
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={handleDeleteSupplierItem}
                                >
                                    SUPPRIMER
                                </Button>
                            }
                            {supplierItem.type === "RAW_MATERIAL" &&
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={!supplierItem.recipeQuantity || supplierItem.recipeQuantity === 0 ? handleDeleteSupplierItem : _openCantDeleteDialog}
                                >
                                    SUPPRIMER
                                </Button>
                            }
                            {supplierItem.type === "SALABLE_PRODUCT" &&
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={!supplierItem.subcontractorProductQuantity || supplierItem.subcontractorProductQuantity === 0 ? handleDeleteSupplierItem : _openCantDeleteDialog}
                                >
                                    SUPPRIMER
                                </Button>
                            }

                        </DialogActions>
                    </Dialog>
                    {/* confirmation before duplication */}
                    <Dialog
                        onClose={() => setDuplicateDialogOpen(false)}
                        aria-labelledby="duplicate-supplierItem-dialog"
                        open={duplicateDialogOpen}
                        className={classes.dialog}>
                        <DialogContent>
                            <Typography variant={"span"}>
                                Voulez-vous dupliquer cet article fournisseur ?
                            </Typography>
                        </DialogContent>
                        <DialogActions classes={{ root: classes.rootDialogActions }}>
                            <Button
                                color="primary"
                                onClick={_closeDuplicationDialog}
                            >
                                ANNULER
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => _duplicateSupplierItem(supplierItem)}
                            >
                                CONFIRMER
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* deletion disallowed */}
                    <CantDeleteSupplierItemModal
                        supplierItem={supplierItem}
                        isOpen={openCantDeleteDialog}
                        handleClose={_closeCantDeleteDialog}
                    />

                </>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={supplierItemSnackBar.open}
                autoHideDuration={supplierItemSnackBar.duration}
                onClose={() => dispatch(closeSupplierItemsSnackBar(supplierItemSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    onClose={() => dispatch(closeSupplierItemsSnackBar(supplierItemSnackBar.type))}
                    variant={supplierItemSnackBar.type}
                    message={supplierItemSnackBar.message}
                />
            </Snackbar>

        </div>
    )
}



export default Header