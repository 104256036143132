import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useDispatch } from "react-redux"

import SettingsTabs from "../SettingsTabs"
import TabsContainer from "../Tabs/TabsContainer"
import TabPanel from "../Tabs/TabPanel"
import { showAdministrationProductTypes } from "../../../actions/ProductTypes/productTypes"
import { showAdministrationPackagingMaterials } from "../../../actions/packagingMaterials/packagingMaterials"

const AdministrationTabs = ({ tabValue, children }) => {
    const dispatch = useDispatch()

    const _onTabChange = (event, tabValue) => {
        if (tabValue === "productTypes") {
            dispatch(showAdministrationProductTypes())
            return
        }
        if (tabValue === "packagingMaterials") {
            dispatch(showAdministrationPackagingMaterials())
            return
        }
    }
    
    return (
        <SettingsTabs tabValue="administration">
            <TabsContainer>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                    sx={{
                        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
                        borderBottom: "1px solid #D8D8D8",
                    }}
                >
                    <Tab value="productTypes" label="Types de produits" />
                    <Tab value="packagingMaterials" label="Matières packaging" />
                </Tabs>
            </TabsContainer>
            <TabPanel top={20}>
                {children}
            </TabPanel>
        </SettingsTabs>
    )
}

export default AdministrationTabs