import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import {
	COLORS,
	forEachBrand
} from "../../utils"
import Button from "@mui/material/Button"
import moment from "moment"
import { confirm } from "../react-await-dialog/react-await-dialog"
import { formatDeleteProductionItemModalData } from "../../utils/planning"
import Checkbox from "@mui/material/Checkbox"

const styles = {
	deleteModalContainer: {
		backgroundColor: COLORS.WHITE,
		width: 700,
		outline: "none",
		margin: "0 auto",
		top: "25%",
		position: "relative",
		borderRadius: 3,
		color: COLORS.BLACK,
		padding: 10
	},
	closeContainer: {
		textAlign: "right"
	},
	closeIcon: {
		cursor: "pointer"
	},
	title: {
		fontSize: 20,
		textAlign: "center"
	},
	deleteProductionItemModalInfos: {
		margin: "20px 160px 20px 160px",
		textAlign: "center"
	},
	commercialName: {
		textAlign: "center",
		marginTop: 10
	},
	actionContainer: {
		textAlign: "center"
	},
	confirmBtn: {
		marginBottom: 10,
		color: COLORS.WHITE
	},
	planningBrand: {
		height: 7,
		width: 7,
		borderRadius: "50%",
		marginLeft: 2
	},
	saleDateRow: {
		display: "flex",
		marginTop: 25,
		marginBottom: 25
	},
	saleDateRowDisable: {
		composes: "$saleDateRow",
		marginLeft: 41
	},
	saleDate: {
		marginTop: 12,
		fontWeight: "bold"
	},
	volumeInfo: {
		marginTop: 12,
		marginLeft: 10
	}
}

forEachBrand(brand => {
	styles[`${brand.name}`] = {
		composes: "$planningBrand",
		backgroundColor: brand.color,
		marginRight: 10,
		marginTop: 18
	}
    styles[`reusable${brand.name}`] = {
        background: brand.color,
        mask: "url(/img/recycle.svg) no-repeat center",
        width: 10,
        height: 10,
        marginRight: 10,
        marginTop: 16
    }
})

const PlanningDeleteProductionItemModal = (props) => {
	const {
		closeModal,
		classes,
		currentCard,
		deleteProductionItem
	} = props
	
	const data = formatDeleteProductionItemModalData(currentCard)
	const [selected, setSelected] = useState([])
	const today = moment.utc().startOf("day").valueOf()
	
	const confirmChoice = async () => {
		if (await confirm({
			title: "Confirm",
			message: "Etes vous sûr de supprimer les éléments sélectionnés ?",
			confirmText: "CONFIRMER"
		})) {
			deleteProductionItem(selected.map(el => el.id))
		}
	}
	
	const isSelected = (value) => {
		return selected.find(el => el.id === value.id) ? true : false
	}
	
	const handleChange = (e, value) => {
		if (e.target.checked){
			const newSelection = [...selected, value]
			setSelected(newSelection)
		}
		else {
			const newSelection = selected.filter(el => el.id !== value.id)
			setSelected(newSelection)
		}
	}
	
	const isDisable = () => {
		return selected.length === 0
	}
 
	return (
		<Modal open={true}>
			<div className={classes.deleteModalContainer}>
				<div className={classes.closeContainer}>
					<CloseIcon
						className={classes.closeIcon}
						onClick={closeModal}
					/>
				</div>
				<div className={classes.title}>
					Sélectionner les dates que vous voulez supprimer
				</div>
				<div className={classes.commercialName}>
					{ `${currentCard.commercialName} - ${currentCard.uniqueCode}` }
				</div>
				<div className={classes.deleteProductionItemModalInfos}>
					{
						data.map((item, i) =>
							<div key={i}>
								<div className={item.expectedProduction === 0 && item.saleDate >= today ? classes.saleDateRow : classes.saleDateRowDisable}>
									{
										item.expectedProduction === 0 && item.saleDate >= today &&
											<Checkbox
												checked={isSelected(item)}
												onChange={(e) => {handleChange(e, item)}}
												inputProps={{ "aria-label": "primary checkbox" }}
												color="secondary"
											/>
									}
									<div className={classes[`${item.isReusable ? "reusable": ""}${item.brand}`]} />
									<div className={classes.saleDate}>
										{ moment.utc(item.saleDate).format("ddd DD/MM") }
									</div>
									{
										item.expectedProduction !== 0 &&
											<div className={classes.volumeInfo}>
												volume : {item.expectedProduction}
											</div>
									}
								</div>
							</div>
						)
					}
				</div>
				<div className={classes.actionContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classes.confirmBtn}
						onClick={() => {confirmChoice()}}
						disabled={isDisable()}
					>
						SUPPRIMER
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default withStyles(styles)(PlanningDeleteProductionItemModal)
