import React, {} from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import ProductionReceptionFilterForm from "./ProductionReceptionFilterForm"
import {
	getProductionReceptionSiteSelector,
	getProductionReceptionSitesSelector, getProductionReceptionStockZoneSelector
} from "../../reducers/ProductionReception/ProductionReception"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ProductionReceptionHeaderTitle from "./ProductionReceptionHeaderTitle"

const useStyles = makeStyles({
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	headerBloc: {
		flex: "0 0 64px"
	},
})

const ProductionReceptionFilter = () => {
	const classes = useStyles()
	const sites = useSelector(getProductionReceptionSitesSelector)
	const selectedSite = useSelector(getProductionReceptionSiteSelector)
	const selectedStockZone = useSelector(getProductionReceptionStockZoneSelector)

	return (
		<div className={classes.wrapper}>
			<>
				<div className={classes.headerBloc}>
					<GenericTopBarHeader
						leftAction={null}
						title={<ProductionReceptionHeaderTitle />}
					/>
				</div>
			</>
			<div className={classes.container}>
				<ProductionReceptionFilterForm
					sites={sites}
					siteSelectedToUse={selectedSite}
					stockZoneSelected={selectedStockZone}
				/>
			</div>
		</div>
	)
}
export default ProductionReceptionFilter

