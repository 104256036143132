import React, { useEffect, useState } from "react"
import {
  TableRow,
  TableCell,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { makeStyles } from "@mui/styles"

import UnhencedSelect from "./filterInputs/UnhencedSelect"
import clsx from "clsx"
import UnhencedTextField from "./filterInputs/UnhencedTextField"
import { COLORS } from "../../utils"

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: ({ type }) => type === "tableRow" ? COLORS.HEADER_BLUE : null,
    display: "flex",
  },
  menuPaper: {
    borderRadius: "0 0 4px 4px"
  },
  cell: {
    display: ({ hasLabel }) => hasLabel ? "flex" : null,
    padding: [[17, 16]],
    "&:first-of-type": {
      paddingLeft: ({ type }) => type !== "tableRow" ? 0 : null
    },
    "&:last-of-type": {
      paddingRight: ({ type }) => type !== "tableRow" ? 0 : null
    }
  },
  input: {
    alignSelf: ({ hasLabel }) => hasLabel ? "end" : null,
    "& ::placeholder": {
      color: COLORS.LABEL_GREY,
      opacity: 1
    },
    "& .MuiInputBase-root": {
      "&:hover": {
        "&::before": {
          borderBottom: "2px solid " + COLORS.LABEL_GREY
        }
      },
      "&::after": {
        borderBottom: "2px solid " + COLORS.LABEL_GREY
      }
    }
  },
  select: {
    "&::after": {
      borderBottom: "2px solid " + COLORS.LABEL_GREY
    }
  },
  flexCell: {
    flex: 1
  }
}))

const Filter = ({
  initialFilters = {},
  filters,
  onFilterChange,
  resetFiltersCallback,
  resetFilters,
  type = "tableRow"
}) => {
  const [filterValues, setFilterValues] = useState({ ...initialFilters })

  const hasLabel = filters.some(filter => filter["filter"].label)
  const classes = useStyles({ type, hasLabel })

  useEffect(() => {
    if (resetFilters) {
      setFilterValues({})
      resetFiltersCallback()
    }
  }, [resetFilters, resetFiltersCallback])

  useEffect(() => {
    onFilterChange && onFilterChange(sanitizeFilters(filterValues))
  }, [JSON.stringify(filterValues)])

  const sanitizeFilters = filters => {
    return Object.fromEntries(
      Object.entries(filters).filter((field) => !!field[1])
    )
  }

  const handleChange = (key, value) => {
    setFilterValues(prevFilters => ({ ...prevFilters, [key]: value }))
  }

  return (
    <MainWrapper className={classes.root} type={type}>
      {filters.map((filter) => (
        <ColumnWrapper type={type} className={clsx(classes.cell, { [classes.flexCell]: !filter.width || filter.width === "auto" })} key={filter.key} sx={{ minWidth: filter.width || "auto", maxWidth: filter.width || "auto", width: filter.width || "auto" }}>
          {filter["filter"].type === "text" && filter["filter"].filterable ? (
            <UnhencedTextField
              label={filter["filter"].label}
              placeholder={filter["filter"].placeholder || "Rechercher"}
              variant="standard"
              value={filterValues[filter.key] || ""}
              onChange={(event) =>
                handleChange(filter.key, event.target.value)
              }
              fullWidth
              classes={{ root: classes.input }}
              InputProps={{
                startAdornment: filter["filter"]?.inputIcon
                  ? (
                    <InputAdornment position="start">
                      {filter["filter"].inputIcon}
                    </InputAdornment>
                  ) : null
              }}
            />
          ) : filter["filter"].type === "select" && filter["filter"].filterable ? (
            <UnhencedSelect
              variant="standard"
              fullWidth
              label={filter["filter"].label}
              value={filter["filter"]?.multiple ? (filterValues[filter.key] ? [...filterValues[filter.key]] : []) : (filterValues[filter.key] || "")}
              onChange={
                (event) => {
                  handleChange(filter.key, event.target.value)
                }
              }
              inputProps={{
                name: `filter-select-${filter.key}`,
                id: `filter-select-${filter.key}`,
              }}
              classes={{ root: classes.select }}
              multiple={!!filter["filter"]?.multiple}
              options={filter["filter"].options}
              withSearch={!!filter["filter"]?.withSearch}
              reset={() => handleChange(filter.key, filter["filter"]?.multiple ? [] : "")}
              MenuProps={{
                classes: {
                  paper: classes.menuPaper
                }
              }}
              render={value => filter["filter"].render ? filter["filter"].render(value) : value}
            />
          ) : filter["filter"].type === "date" && filter["filter"].filterable ? (
            <DatePicker
              label={filter["filter"].label}
              inputFormat="DD/MM/YYYY"
              variant="inline"
              inputVariant="outlined"
              value={filterValues[filter.key] || null}
              onChange={(date) => handleChange(filter.key, date)}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "99.5% " }}
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: filter.placeholder || "Rechercher"
                  }}
                  classes={{ root: classes.input }}
                />
              )}
              fullWidth
            />
          ) : null}
        </ColumnWrapper>
      ))
      }
    </MainWrapper >
  )
}

export default Filter

const MainWrapper = ({ type = "tableRow", children, ...rest }) => {
  return type === "tableRow"
    ? <TableRow {...rest}>{children}</TableRow>
    : <div {...rest}>{children}</div>
}

const ColumnWrapper = ({ type = "tableRow", children, ...rest }) => {
  return type === "tableRow"
    ? <TableCell {...rest}>{children}</TableCell>
    : <Box {...rest}>{children}</Box>
}