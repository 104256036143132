import React, {useEffect, useState} from "react"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../../utils"
import IconButton from "@mui/material/IconButton"
import FilledInput from "@mui/material/FilledInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import SearchIcon from "@mui/icons-material/Search"

const useStyles = makeStyles({
    textField: {
        background: COLORS.LIGHT_GREY_3,
        width: 812,
        border: "none",
        height: 48
    },
    input: {
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& input": {
            marginLeft: 37,
            padding: 0,
            fontSize: 16
        }
    },
    label: {
        color: COLORS.DARK_GREY,
        marginLeft: 80,
        fontSize: 16,
        "&.MuiInputLabel-filled": {
            transform: "translate(12px, 17px) scale(1)"
        }
    },
    hide: {
        display: "none"
    }
})

const SearchInput = (props) => {
    const {
        onSearchChange,
        searchInput
    } = props

    const classes = useStyles()

    const [shrink, setShrink] = useState(false)

    useEffect(() => {
        setShrink(searchInput ? true : false)
    }, [searchInput])

    return (
        <FormControl className={classes.textField} variant="filled">
            <InputLabel
                htmlFor="filled-adornment-search"
                className={!shrink ? classes.label : classes.hide}
                shrink={shrink}
            >
                Chercher un article
            </InputLabel>
            <FilledInput
                className={classes.input}
                id="filled-adornment-search"
                type="text"
                value={searchInput}
                onChange={onSearchChange}
                disableUnderline
                startAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="search" edge="end" size="large">
                            <SearchIcon htmlColor={COLORS.DARK_GREY}/>
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default SearchInput
