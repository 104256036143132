import React from "react"
import { Box, Button } from "@mui/material"
import GenericTopBarHeader from "./GenericTopBarHeader"
import PageHeaderTitle from "./PageHeaderTitle"

const sx = {
    genericHeaderContainer: {
        height: 64,
        width: "100%",
    },
    cancelButton: {
      paddingRight: 4
  },
}

const EditionPageHeader = ({ icon, onCancel, onSave, title, subtitle, color }) => {
    return (
      <Box sx={sx.genericHeaderContainer}>
          <GenericTopBarHeader
              rightAction={
                  <>
                      <Button sx={sx.cancelButton} onClick={onCancel}>Annuler</Button>
                      <Button variant="contained" onClick={onSave}>Enregistrer</Button>
                  </>
              }
              title={
                  <PageHeaderTitle
                      title={title}
                      subtitle={subtitle ? <i>{subtitle}</i> : ""}
                      icon={icon}
                      color={color}
                  />
              }
          />
      </Box>
    )
}

export default EditionPageHeader