import React, { useMemo, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"
import moment from "moment"
import * as yup from "yup"
import { Form, Formik } from "formik"
import { Typography, Card, CardContent, Stack, TextField, FormControl, FormLabel, Button, styled, Chip, Box } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import ClearIcon from "@mui/icons-material/Clear"
import AddAPhoto from "@mui/icons-material/AddAPhoto"
import Header from "../../components/LotInventory/Header"
import FullScreenWrapper from "../../components/FullScreenWrapper"
import LotInventoryOutputSnackBar from "../../components/LotInventory/LotInventoryOutputSnackBar"
import { openUploadWidget } from "../../utils/cloudinary"
import { updateLotWithDamageReport, closeLotInventoryDamageReportSnackBar, showLotMain } from "../../actions/LotInventory/LotInventory"
import { getLotInventoryDamageReportSnackbarSelector, getLotSelector, getLotsSiteSelector, getLotsStockZoneSelector } from "../../reducers/LotInventory/LotInventory"
import { LOT_INVENTORY_MODE } from "../../utils/lotInventoryUtils"
import { generateOrderUnitLabel } from "../../utils/ordersUtils"


const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "144px",
    paddingBottom: "118px !important",
    display: "flex",
    overflowY: "scroll",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  photoIconContainer: {
    borderRadius: "4px",
    border: "1.5px solid #2196F3",
    padding: "19px 39px",
    width: "102px",
    cursor: "pointer"
  },
  stockUnit: {
    textTransform: "uppercase",
    fontSize: "16px",
    color: "#262626"
  }
}))

const StyledCard = styled(Card)({
  flexBasis: "33%",
  border: "2px solid #E6E6E6",
  borderRadius: "6px"
})

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== "selected"
})(() => ({
  padding: "24px 32px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  cursor: "pointer"
}))

const ImageBox = styled(Box)({
  borderRadius: "4px",
  border: "1.5px solid #2196F3",
  padding: "19px 39px",
  width: "102px",
  cursor: "pointer"
})

const DamagedLotImgSrc = "/img/inventory/damaged_lot.svg"
const DamagedLotImgSrcSelected = "/img/inventory/damaged_lot_selected.svg"

const LotDamageReport = () => {

  const classes = useStyles()
  const dispatch = useDispatch()

  const lot = useSelector(getLotSelector)
  const site = useSelector(getLotsSiteSelector)
  const stockZone = useSelector(getLotsStockZoneSelector)
  const date = moment.utc().startOf("day").valueOf()
  const errorSnackbar = useSelector(getLotInventoryDamageReportSnackbarSelector)

  const formRef = useRef(null)

  const stockWeight = useMemo(() => {
    return lot.orderSupplierItem.units && lot.orderSupplierItem.units.stock && lot.orderSupplierItem.units.stock.weight
  }, [lot])

  const lotQuantity = useMemo(() => lot.quantity, [lot])

  const damageReportSchema = yup.object().shape({
    reason: yup.string().required("Veuillez sélectionner un motif"),
    damagedQuantity: yup.number().required("Veuillez saisir une quantité").min(1, "Veuillez saisir une quantité").max(lotQuantity, "La quantité déclarée ne peut pas être supérieure à la quantité restante sur le lot"),
    comment: yup.string(),
    images: yup.array().of(yup.object().shape({
      publicId: yup.string(),
      name: yup.string()
    }))
  })

  const goToLotMain = useCallback(() => {
    dispatch(showLotMain(stockZone.objectId, LOT_INVENTORY_MODE))
  }, [stockZone])

  const uploadImageWithCloudinary = useCallback((existingImages = []) => {
    openUploadWidget({ tags: ["kfc"] }, (error, result) => {
      if (error) {
        console.error(error)
        return
      }
      if (result.event !== "success") {
        return
      }

      const publicId = result.info.public_id
      const lastIndex = existingImages.length - 1
      const newImages = [...existingImages, { publicId: publicId, name: `damageReport_${lastIndex + 1}_lot_${lot.objectId}` }]
      formRef.current?.setFieldValue("images", newImages)
    })
  }, [lot])

  const onDeleteImage = (image, existingImages) => {
    const index = existingImages.findIndex((existingImage) => existingImage.publicId === image.publicId)
    const newImages = [...existingImages]
    newImages.splice(index, 1)
    formRef.current?.setFieldValue("images", newImages)
  }

  const handleConfirm = useCallback(async (values) => {
    await dispatch(updateLotWithDamageReport(lot, values))
    goToLotMain()
  }, [lot])

  return (<FullScreenWrapper>
    <Header
      onClickReturn={goToLotMain}
      site={site}
      stockZone={stockZone}
      date={moment(date).startOf("day").format("DD/MM/YYYY")}
      isInventory={true}
      rightAction={
        <Button
          variant="contained"
          onClick={() => {
            formRef.current?.submitForm()
          }}>DÉCLARER LA CASSE</Button>}
    />
    <div className={classes.container}>
      <Stack spacing={9} align="center">
        <Stack spacing={3} align="center">
          <Typography variant="h5" sx={{ fontWeight: 700 }}>Déclaration de casse</Typography>
          <Stack spacing={1}>
            <Typography variant="line">Article : <strong>{lot.orderSupplierItem.name}</strong></Typography>
            <Typography variant="line">Lot : <strong>{lot.lotNumber}</strong></Typography>
            <Typography variant="line">DLC : <strong>{dayjs(lot.dlc).format("DD/MM/YYYY")}</strong></Typography>
            <Typography variant="line">Reçu le : <strong>{dayjs(lot.receptionDate).format("DD/MM/YYYY")}</strong></Typography>
          </Stack>
        </Stack>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            damagedQuantity: undefined,
            reason: "",
            comment: "",
            images: []
          }}
          validationSchema={damageReportSchema}
          onSubmit={handleConfirm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue
          }) => {
            return (
              <Form>
                <Stack spacing={9} align="center">
                  <Stack direction="row" spacing={2} align="center">
                    <StyledCard
                      onClick={() => setFieldValue("reason", "damaged")}>
                      <StyledCardContent>
                        <img src={values.reason === "damaged" ? DamagedLotImgSrcSelected : DamagedLotImgSrc} alt="damaged_good" />
                        <Typography variant="h6">Abimé</Typography>
                      </StyledCardContent>
                    </StyledCard>
                    <StyledCard
                      onClick={() => setFieldValue("reason", "dlc")}>
                      <StyledCardContent>
                        <CalendarTodayIcon
                          color={values.reason === "dlc" ? "primary" : "disabled"}
                          sx={{
                            width: 30, height: 30
                          }}
                        />
                        <Typography variant="h6">DLC dépassée</Typography>
                      </StyledCardContent>
                    </StyledCard>
                    <StyledCard
                      onClick={() => setFieldValue("reason", "missing")}>
                      <StyledCardContent>
                        <HelpOutlineIcon
                          color={values.reason === "missing" ? "primary" : "disabled"}
                          sx={{
                            width: 30, height: 30
                          }}
                        />
                        <Typography variant="h6">Disparition</Typography>
                      </StyledCardContent>
                    </StyledCard>
                  </Stack>
                  {errors.reason && touched.reason && <Typography variant={"error"}>{errors.reason}</Typography>}
                  <Stack direction="row" spacing={9} justifyContent="space-between">
                    <Stack spacing={2}>
                      <Typography variant="h6">Quantité restante sur le lot</Typography>
                      <Typography variant={"h3"}>
                        {lot.quantity}
                      </Typography>
                      <Typography variant="span">
                        {generateOrderUnitLabel(lot.orderSupplierItem).toUpperCase()}
                      </Typography>
                      {
                        lot.orderSupplierItem && lot.orderSupplierItem &&
                        <Typography variant="span" className={classes.unit}>
                          = {stockWeight * lot.quantity} kg
                        </Typography>
                      }
                    </Stack>
                    <Stack spacing={2}>
                      <Typography variant="h6">Quantité déclarée en casse</Typography>
                      <Typography variant={"h3"}>
                        <TextField
                          name={"damagedQuantity"}
                          variant={"standard"}
                          onChange={handleChange}
                          value={values.damagedQuantity}
                          error={!!errors.damagedQuantity}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          inputProps={{
                            placeholder: lot.quantity,
                            style: {
                              width: 125,
                              fontSize: 40,
                              textAlign: "center",
                              padding: 0
                            },
                            type: "number",
                            pattern: "[0-9]*",
                          }}
                        />
                      </Typography>
                      <Typography variant="span">
                        {generateOrderUnitLabel(lot.orderSupplierItem).toUpperCase()}
                      </Typography>
                      {
                        lot.orderSupplierItem && lot.orderSupplierItem &&
                        <Typography variant="span">
                          = {stockWeight * (values.damagedQuantity || 0)} kg
                        </Typography>
                      }
                    </Stack>
                  </Stack>
                  {errors.damagedQuantity && touched.damagedQuantity && <Typography variant={"error"}>{errors.damagedQuantity}</Typography>}
                  <Stack spacing={2}>
                    <FormLabel >Ajouter un commentaire et des photos (facultatif) </FormLabel>
                    <FormControl>
                      <TextField
                        type="textarea"
                        label="Ajouter un commentaire"
                        variant="standard"
                        rows={19}
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <Stack direction="row" justifyContent="space-between">
                        <ImageBox
                          onClick={() => uploadImageWithCloudinary(values.images)}>
                          <AddAPhoto />
                        </ImageBox>
                        {values.images.map((image, index) => {
                          return <Chip
                            key={index}
                            label={image.name}
                            onDelete={() => onDeleteImage(image, values.images)}
                            deleteIcon={<ClearIcon />} />
                        })
                        }
                      </Stack>
                      {errors.images && touched.images && <Typography variant={"error"}>{errors.images}</Typography>}
                    </FormControl>
                  </Stack>
                </Stack>
              </Form>)
          }}
        </Formik>
      </Stack>
    </div>
    {/* form error message */}
    <LotInventoryOutputSnackBar
      data={errorSnackbar}
      onClose={() => dispatch(closeLotInventoryDamageReportSnackBar(errorSnackbar.type))}
    />
  </FullScreenWrapper>)
}

export default LotDamageReport
