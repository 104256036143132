import React, { useRef, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      width: 373,
      height: 45,
      border: `1px solid ${COLORS.GREY_BORDER}`,
      boxSizing: "border-box",
      borderRadius: 5,
      boxShadow: "none",
      backgroundColor: COLORS.LIGHT_GREY,
      "&.is-fixed": {
        position: "fixed",
        zIndex: 1,
      },
      "&.is-tablet": {
        width: 500,
      },
      "&.is-tablet.is-inventory": {
        width: 551,
      },
    },
    input: {
      color: COLORS.DARK_GREY,
      marginLeft: theme.spacing(0),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  })
)

export default function SearchInput(props) {
  const { defaultValue, isTablet, onNameChange, focused, isInventory } = props
  const classes = useStyles()
  const inputRef = useRef()

  useEffect(() => {
    if (focused) inputRef.current.focus()
  }, [focused])

  return (
    <Paper
      component="form"
      className={clsx(
        classes.root,
        { "is-tablet": isTablet },
        { "is-inventory": isInventory },
        { "is-fixed": undefined !== isInventory }
      )}
    >
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        size="large"
      >
        <SearchIcon htmlColor={COLORS.GREY_SEARCH} />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Rechercher un code ou un nom de produit"
        inputProps={{ "aria-label": "Rechercher un code ou un nom de produit" }}
        inputRef={inputRef}
        defaultValue={defaultValue}
        onChange={(e) => onNameChange(e.target.value)}
      />
    </Paper>
  )
}
