import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import LotGroupTable from "./LotGroupTable"

const useClasses = makeStyles(() => ({
    container: {
        width: "100%"
    }
}))

const LotGroupToDo = (props) => {
    const {
        modalRef,
        lotGroupDataToDo
    } = props
    
    const classes = useClasses()
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const searchFilter = (storage && storage.lotGroupDataToDo)?storage.lotGroupDataToDo:""

    return (
        <div className={classes.container}>
            <LotGroupTable
                tableType="todo"
                lotGroupData={lotGroupDataToDo}
                searchFilter={searchFilter}
                storageIndex="lotGroupToDo"
                modalRef={modalRef}
            />
        </div>
    )
}

export default connect((state) => {
    return {
        date: state.lotGroup.date
    }
}, {
})(LotGroupToDo)
