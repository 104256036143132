import React, { useState } from "react"

import styled from "@emotion/styled"
import {
  Box,
  Button,
  Stack,
  TextField,
} from "@mui/material"
import { ErrorMessage, } from "formik"

import { COLORS, roundNumber } from "../../../../utils"
import { StyledErrorMessage, StyledSectionFirstBodyColumn, StyledStepBodyCell } from "../StyledSectionComponents"
import { computeSectionData, getDefaultSection, parseSectionToObject } from "../../../../utils/recipes"
import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../../utils/constant"
import RemoveColumn from "../RemoveColumn"
import { convertKilosIntoGrams } from "../../../../utils/ressources"

const widths = PRODUCTION_STEPS_COL_WIDTHS
export const COMPONENT_NAME = "SECTIONS"

const StyledText = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled"
})(({ disabled = true }) => {
  let defaultStyles = {
    fontWeight: 600,
    fontSize: 14
  }

  if (disabled) {
    defaultStyles.color = COLORS.PRODUCTION_STEPS_DISABLE_TEXT
  } else {
    defaultStyles.color = COLORS.PRODUCTION_STEPS_TEXT_GREY
  }

  return defaultStyles
})

const StyledTextField = styled(TextField)({
  width: 512,
  height: 30,
  background: "#fff",
  borderRadius: 4,
  "& .MuiInputBase-root": {
    "&:before, :after": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
    "& .MuiInputBase-input": {
      paddingLeft: 7
    }
  },
  "& .MuiInput-input": {
    fontWeight: 600,
    fontSize: 14,
    color: "#414141"
  }
})


const EditableSection = ({
  sections,
  section,
  index,
  isHover,
  genericSections,
  setFieldValue,
  onClearFocus,
  onFieldFocus,
  onFieldBlur,
  onKeyUp,
  hasError,
  onDeleteBlur,
  computeSectionsFormValues
}) => {
  const [changed, setChanged] = useState(0)

  const _stopPropagation = (event) => event && event.stopPropagation()


  const _onGenericSectionChange = (event, formValue, sectionIndex, reason) => {
    if (!event) return

    let value = formValue
    if (reason === "selectOption") {
      if (value.get) {
        value = value.get("name")
      } else {
        value = value.name
      }
    }

    const section = genericSections.find(
      (section) => (section.get ? section.get("name") : section.name) === value
    )

    const newSections = [...sections]

    newSections[sectionIndex].name = value

    if (reason === "selectOption" && section) {
      const newSection =
        parseSectionToObject([section])[0] || getDefaultSection()
      newSections[sectionIndex] = newSection

      newSections[sectionIndex].error = false
      newSections[sectionIndex].id = null
      newSections[sectionIndex].parentId = section.id
      newSections[sectionIndex].parentPercent = 100

      computeSectionsFormValues(newSections, sectionIndex)
    }

    if (reason === "input-change" && section) {
      setFieldValue("sections", newSections)
    }

    if (section && !newSections[sectionIndex].parentId) {
      newSections[sectionIndex].parentId = null
      newSections[sectionIndex].parentPercent = 0
    }

    if (reason === "selectOption" && section) {
      setChanged(changed + 1)
      onClearFocus()
    }

    if (event.target) {
      _stopPropagation(event)
    }
  }

  const _addSection = (index, event = null) => {
    const newSections = [...sections]
    newSections.splice(index + 1, 0, getDefaultSection())

    // update section with computed production steps and step components data
    const newSection = newSections[newSections.length - 1]
    if (newSection) {
      computeSectionData(newSection, "productionSteps", "stepComponents")
    }

    onDeleteBlur()
    setFieldValue("sections", newSections)
    _stopPropagation(event)
  }

  const _removeSection = (index, event = null) => {
    const newSections = [...sections]
    newSections.splice(index, 1)
    if (!newSections.length) {
      newSections.splice(0, 0, getDefaultSection())
    }

    computeSectionsFormValues(newSections, index > 0 ? index - 1 : 0)
    _stopPropagation(event)
  }

  return (
    <Box
      sx={{
        display: "flex"
      }}
      onClick={_stopPropagation}
    >
      <StyledSectionFirstBodyColumn className="flexRow center">
        {isHover ? (
          <>
            {/* plus button */}
            <Button onClick={(e) => _addSection(index, e)} className="flexCenter" sx={{ position: "absolute", left: -8 }}>
              <img alt="plus icon" src="/icons/plus-blue.svg" />
            </Button>
            {/* input */}
            <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
              <StyledTextField
                name={`sections[${index}].name`}
                value={
                  typeof section.name === "string"
                    ? section.name
                    : section.name.get("name")
                }
                onClick={_stopPropagation}
                onFocus={onFieldFocus}
                onBlur={onFieldBlur}
                onKeyUp={onKeyUp}
                variant="standard"
                fullWidth
                onChange={(event) => {
                  _onGenericSectionChange(
                    event,
                    event.target.value,
                    index,
                    "input-change"
                  )
                }}
              />
              <ErrorMessage
                name={`sections[${index}].name`}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            </Stack>
          </>
        ) : (
          hasError(index)
            ? (
              <ErrorMessage
                name={`sections[${index}].name`}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            ) : (
              <Box sx={{ height: 30 }} className="flexRow center">
                <StyledText disabled={false}>{section.name}</StyledText>
              </Box>
            )
        )}
      </StyledSectionFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledText>{roundNumber(convertKilosIntoGrams(section.grossWeight), 3) || "-"}</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledText>
          {section.cost ? `${roundNumber(section.cost, 3)} €` : "_"}
        </StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledText>{roundNumber(convertKilosIntoGrams(section.netWeight), 3) || "-"}</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      {/* -------- delete icon -------- */}
      <RemoveColumn
        type="section"
        isHover={isHover}
        onClick={(e) => _removeSection(index, e)}
      />
    </Box>
  )
}

export default EditableSection
