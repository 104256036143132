import React from "react"
import PropTypes from "prop-types"
import TableHead from "@mui/material/TableHead"
import Tooltip from "@mui/material/Tooltip"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableCell from "@mui/material/TableCell"
import Checkbox from "@mui/material/Checkbox"
import TableRow from "@mui/material/TableRow"

const rowsRecipes = [
    { id: "brand", numeric: false, disablePadding: false, label: "Marque" },
    { id: "name", numeric: false, disablePadding: false, label: "Recette" },
    { id: "message", numeric: false, disablePadding: false, label: "Message" }
]

const rowsSubcontractorsProducts= [
    { id: "brand", numeric: false, disablePadding: false, label: "Marque" },
    { id: "name", numeric: false, disablePadding: false, label: "Produit sous-traitant" },
    { id: "message", numeric: false, disablePadding: false, label: "Message" }
]

const ExportProductsErrorsTableHead = (props) => {

    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        isRecipes,
        onRequestSort
    } = props

    const rows = isRecipes ? rowsRecipes : rowsSubcontractorsProducts

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        color="secondary"
                    />
                </TableCell>
                {rows.map(
                    row => (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "default"}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <Tooltip
                                title="Filtrer"
                                placement={row.numeric ? "bottom-end" : "bottom-start"}
                                enterDelay={300}
                                disableInteractive
                            >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order}
                                    onClick={(event) => onRequestSort(event, row.id)}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    )
}

ExportProductsErrorsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

export default ExportProductsErrorsTableHead