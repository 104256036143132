import React, { useRef, useCallback } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  styled,
} from "@mui/material"
import * as Yup from "yup"

const sx = {
  title: {
    color: "#7C7C7C",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.37
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 357,
    padding: 10
  }
})

const validationSchema = Yup.object({
  file: Yup.mixed().required("Un fichier CSV est requis"),
})


const UnpackingStepUploadDialogForm = ({ open, onClose, onConfirm }) => {
  const formikRef = useRef()

  const handleConfirm = () => {
    formikRef.current?.submitForm()
  }

  const handleCancel = () => onClose()

  const handleSubmit = useCallback((values) => {
    onConfirm(values)
    onClose()
  }, [onConfirm, onClose])

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={sx.title}>
        Sélectionner le fichier à utiliser pour la migration
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Formik
          innerRef={formikRef}
          initialValues={{ file: null }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched }) => {
            return (
              <Form>
                <TextField
                  name="file"
                  type="file"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: ".csv" }}
                  onChange={(event) => setFieldValue("file", event.currentTarget.files[0])}
                  error={touched.file && Boolean(errors.file)}
                  helperText={touched.file && errors.file}
                />
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          VALIDER
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default UnpackingStepUploadDialogForm
