import { push, actionWithLoader, onEnter, sendOrderVoucherMail } from "../Utils/utils"
import { getSites, getSiteById } from "../../parseManager/site/parseSiteManager"
import {
    createOrUpdateOrder,
    createOrderSupplierItemsForOrder,
    getLastOrderNumber,
    getOrder,
    getOrdersForStatus,
    deleteOrder,
    getOrdersReceptions,
    cancelOrder,
    createOrderDuplicata, recalculateTotalAmountOrdersByDate,
} from "../../parseManager/orders/parseOrdersManager"
import {
    getParamsFromPath,
    orderStatus,
    computeOrderTotalWeightPrice,
    orderUpdateStatus
} from "../../utils/ordersUtils"
import { batch } from "react-redux"
import {
    getSupplierItems,
    getSupplierItemWithId
} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import moment from "moment"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"
import dayjs from "dayjs"

export function loadSites() {
    return actionWithLoader(async (dispatch) => {
        const sites = await getSites()

        dispatch({
            type: "SITES_LOADED",
            sites
        })
    })
}

export const loadOrders = () => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const { siteId } = getParamsFromPath(path)

        if (siteId) {
            const site = await getSiteById(siteId, ["*"], false)
            const suppliers = await getSuppliers({ toJSON: true })
            const orders = await getOrdersForStatus(site, orderStatus.TO_VERIFY, true)
            const supplierItems = await getSupplierItems({ toJSON: true })

            if (site) {
                dispatch(clearSelectedOrder())
                dispatch({
                    type: "UPDATE_MENU",
                    menuIsOpen: false
                })
                dispatch({
                    type: "ORDERS_LOADED",
                    site: site.toJSON(),
                    suppliers,
                    orders,
                    supplierItems
                })
            }
            else {
                dispatch(showOrdersSiteSelector())
            }
            return
        }

        dispatch(showOrdersSiteSelector())
    })
}

export function loadUpdateOrder() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const { siteId, orderId } = getParamsFromPath(path)

        dispatch(clearSelectedOrder())

        if (siteId && orderId) {
            const site = state.orders.site || (await getSiteById(siteId, ["*"]))
            let selectedOrder = await getOrder(orderId)
            const suppliers = state.orders.suppliers.length > 0 ? state.orders.suppliers : await getSuppliers({ toJSON: true })
            const supplierItems = state.supplierItems.supplierItems.length > 0 ? state.supplierItems.supplierItems : await getSupplierItems({ includes: ["supplier"], toJSON: true })

            const data = formatSupplierItems(suppliers, supplierItems)
            selectedOrder = await formatSelectedOrderSupplierItems(selectedOrder)

            if (site && selectedOrder) {
                batch(() => {
                    dispatch({
                        type: "UPDATE_MENU",
                        menuIsOpen: false
                    })
                    dispatch({
                        type: "SUPPLIER_ITEMS_LOADED",
                        supplierItems
                    })
                    dispatch({
                        type: "ORDER_LOADED",
                        site,
                        data,
                        selectedOrder,
                        suppliers
                    })
                })
            }
            else {
                dispatch(showOrdersSiteSelector())
            }
        }
        else {
            dispatch(showOrdersSiteSelector())
        }
    })
}

async function formatSelectedOrderSupplierItems(selectedOrder) {
    try {
        for (const item of selectedOrder.supplierItems) {
            const supplierItem = await getSupplierItemWithId(item.supplierItem.supplierItemId, ["supplier"])

            if (supplierItem) {
                item.supplierItem = supplierItem
            }
        }

        return selectedOrder
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export function loadNewOrder() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const { siteId } = getParamsFromPath(path)

        if (siteId) {
            const site = state.orders.site || (await getSiteById(siteId, ["*"]))
            const suppliers = state.orders.suppliers.length > 0 ? state.orders.suppliers : await getSuppliers({ toJSON: true })
            const supplierItems = state.supplierItems.supplierItems.length > 0 ? state.supplierItems.supplierItems : await getSupplierItems({ includes: ["supplier"], toJSON: true })

            const data = formatSupplierItems(suppliers, supplierItems)

            if (site) {
                batch(() => {
                    dispatch(clearSelectedOrder())
                    dispatch({
                        type: "UPDATE_MENU",
                        menuIsOpen: false
                    })
                    dispatch({
                        type: "SUPPLIER_ITEMS_LOADED",
                        supplierItems
                    })
                    dispatch({
                        type: "NEW_ORDER_LOADED",
                        site,
                        data,
                        suppliers
                    })
                })
            }
            else {
                dispatch(showOrdersSiteSelector())
            }
        }
        else {
            dispatch(showOrdersSiteSelector())
        }
    })
}

export function filterOrdersReceptions(values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const { siteId } = getParamsFromPath(path)

        if (siteId) {
            const site = await getSiteById(siteId, ["*"], false)
            const orders = await getOrdersReceptions(site, values, true)
            if (site) {
                batch(() => {
                    dispatch(clearSelectedOrder())
                    dispatch({
                        type: "UPDATE_MENU",
                        menuIsOpen: false
                    })
                    dispatch({
                        type: "ORDER_RECEPTION_LOADED",
                        site: site.toJSON(),
                        data: orders,
                        filter: values
                    })
                })
            }
            else {
                dispatch(showOrdersSiteSelector())
            }

        }
        else {
            dispatch(showOrdersSiteSelector())
        }
    })
}

function formatSupplierItems(suppliers, supplierItems) {
    const data = new Map()

    suppliers.sort((a, b) => a.name.localeCompare(b.name))

    for (const supplier of suppliers) {
        const filterSupplierItems = supplierItems.filter(el => el.units && el.supplier && el.supplier.objectId === supplier.objectId)

        filterSupplierItems.sort((a, b) => a.name.localeCompare(b.name))

        data.set(supplier.objectId, filterSupplierItems)
    }

    return data
}

export function clearSelectedOrder() {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_SELECTED_ORDER",
            selectedOrder: null
        })
    }
}

export function createOrders(cart) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const lastOrderNumber = (await getLastOrderNumber()) + 1
        const site = state.orders.site
        const parseSite = await getSiteById(site.objectId, ["*"], false)

        await Promise.all(Array.from(cart).map(async ([key, values], i) => {
            const supplier = state.orders.suppliers.find(el => el.objectId === key)
            const orderNumber = lastOrderNumber + parseInt(i)
            const supplierItems = await createOrderSupplierItemsForOrder(values, null)
            const numberProducts = supplierItems.length
            const expectedDeliveryDate = null
            const { totalAmount, totalWeight } = computeOrderTotalWeightPrice(values)
            const status = orderStatus.TO_VERIFY
            const receptionStatus = null

            const data = {
                site: parseSite,
                supplier,
                orderNumber,
                supplierItems,
                numberProducts,
                expectedDeliveryDate,
                totalWeight,
                totalAmount,
                orderStatus: status,
                receptionStatus
            }

            await createOrUpdateOrder(data, null)

        }))

        dispatch(showOrdersList(site.objectId))
    })
}

export function updateOrderAction(orderId, supplier, date, cart) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const site = state.orders.site

        const values = cart.get(supplier.objectId)

        const supplierItems = await createOrderSupplierItemsForOrder(values, null)
        const numberProducts = supplierItems.length
        const { totalAmount, totalWeight } = computeOrderTotalWeightPrice(values)
        const expectedDeliveryDate = date ? moment.utc(date).startOf("day").valueOf() : null
        delete supplier.order.minDateDelivery

        const data = {
            supplier,
            supplierItems,
            numberProducts,
            expectedDeliveryDate,
            totalWeight,
            totalAmount
        }

        await createOrUpdateOrder(data, orderId)

        dispatch(showOrdersList(site.objectId))
    })
}

export function validateOrderAction(orderId, supplier, date, cart, comment, orderUpdateStatus) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const site = state.orders.site
        const values = cart.get(supplier.objectId)

        const supplierItems = await createOrderSupplierItemsForOrder(values, "TODO")
        const numberProducts = supplierItems.length
        const { totalAmount, totalWeight } = computeOrderTotalWeightPrice(values)
        const receptionStatus = "TODO"
        const expectedDeliveryDate = moment.utc(date).startOf("day").valueOf()
        const orderDate = moment.utc().valueOf()
        delete supplier.order.minDateDelivery
        delete supplier.order.expectedDeliveryDate

        const data = {
            supplier,
            supplierItems,
            numberProducts,
            expectedDeliveryDate,
            receptionDate: expectedDeliveryDate,
            totalWeight,
            totalAmount,
            orderStatus: orderStatus.DONE,
            orderDate: orderDate,
            receptionStatus,
            comment
        }

        await createOrUpdateOrder(data, orderId)

        dispatch(sendOrderVoucherMail(orderId, orderUpdateStatus))
        dispatch(showOrdersList(site.objectId))
    })
}

export function cancelOrderAction(orderId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const site = state.orders.site
        const orders = state.orderReception

        await cancelOrder(orderId)

        batch(() => {
            dispatch(sendOrderVoucherMail(orderId, orderUpdateStatus.CANCELLED))
            dispatch({
                type: "ORDER_RECEPTION_LOADED",
                site: site,
                data: orders.data.filter(order => order.objectId !== orderId),
                filter: orders.orderReceptionsFilters
            })
            dispatch(showOrdersList(site.objectId))
        })
    })
}

export function deleteOrderAction(orderId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const site = state.orders.site

        await deleteOrder(orderId)

        batch(() => {
            dispatch(clearSelectedOrder())
            dispatch(showOrdersList(site.objectId))
        })
    })
}

export function duplicateOrderAction(orderId, siteId) {
    return actionWithLoader(async (dispatch) => {
        const orderNumber = (await getLastOrderNumber()) + 1
        await createOrderDuplicata(orderId, orderNumber)
        dispatch(showOrdersList(siteId))
    })
}

export function closeOrderSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "ORDERS_CLOSE_SNACKBAR",
            ordersSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function recalculateTotalAmountOrders() {
    return actionWithLoader(
      async () => {
          await recalculateTotalAmountOrdersByDate(dayjs("19-11-2024", "DD-MM-YYYY").utc().startOf("day").valueOf())
      }
    )
}

export function onEnterOrders(store) {
    return onEnter({
        store,
        actionThunk: loadSites
    })
}

export function onEnterOrdersList(store) {
    return onEnter({
        store,
        actionThunk: loadOrders
    })
}

export function onEnterOrderUpdate(store) {
    return onEnter({
        store,
        actionThunk: loadUpdateOrder
    })
}

export function onEnterNewOrder(store) {
    return onEnter({
        store,
        actionThunk: loadNewOrder
    })
}

/** Routers **/

export function showOrdersSiteSelector() {
    return push("/orders")
}

export function showOrdersList(site) {
    if (site) {
        return push(`/orders/${site}`)
    }
}

export function showNewOrder(site) {
    if (site) {
        return push(`/order/${site}`)
    }
}

export function showUpdateOrder(site, orderId) {
    if (site && orderId) {
        return push(`/order/${site}/${orderId}`)
    }
}
