import Parse from "parse"

export const Site = Parse.Object.extend("Site")
const StockZone = Parse.Object.extend("StockZone")

export const SUCY_ID = "UPK3lbT78C"

export async function getFilteredSites(filters) {
    try {
        let query = new Parse.Query(Site)

        if (filters.search) {
            const regex = new RegExp(filters.search, "ig")

            query = new Parse.Query.or(
                new Parse.Query(Site).matches("name", regex),
                new Parse.Query(Site).matches("deliveryAddress.line1", regex),
                new Parse.Query(Site).matches("deliveryAddress.line2", regex),
                new Parse.Query(Site).matches("invoiceAddress.line1", regex),
                new Parse.Query(Site).matches("invoiceAddress.line2", regex)
            )
        }

        query
            .notEqualTo("deleted", true)

        const [sites, total] = await Promise.all([
            (await query
                .find()) || [],
            await query.count()
        ])

        return {
            sites: sites.map(el => el.toJSON()),
            total
        }
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getFilteredSites error : ", e)
        return Promise.reject(e)
    }
}

export async function getSites(includes = [], toJson = true) {
    try {
        const sites = (await new Parse.Query(Site)
            .include(includes)
            .find()) || []

        return {
            sites: toJson ? sites.map((site) => site.toJSON()) : sites
        }
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSites error : ", e)
        return Promise.reject(e)
    }
}

export async function getSitesByIds(ids, includes = [], toJson = true) {
    try {
        const sites = (await new Parse.Query(Site)
            .containedIn("objectId", ids)
            .include(includes)
            .find()) || []

        return toJson ? sites.map(site => site.toJSON()) : sites
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getStockZones error : ", e)
        return Promise.reject(e)
    }
}

export async function getSitesNames(toJson = true) {
    try {
        const sites = (await new Parse.Query(Site)
            .select("name")
            .notEqualTo("deleted", true)
            .find()) || []

        return toJson ? sites.map(el => el.toJSON()) : sites
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSitesNames error : ", e)
        return Promise.reject(e)
    }
}

export async function getSitesWithKitchenAreas(toJson = true) {
    try {
        const sites = (await new Parse.Query(Site)
            .select(["name", "kitchenAreas.kitchenArea"])
            .include(["kitchenAreas.kitchenArea"])
            .notEqualTo("deleted", true)
            .find()) || []

        const formattedSites = sites.map(site => {
            return {
                ...
                site.toJSON(),
                kitchenAreas: (site.get("kitchenAreas") || []).map(kitchenAreaObj => kitchenAreaObj && kitchenAreaObj.kitchenArea.toJSON())
            }
        })

        return toJson ? formattedSites : sites
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSitesWithKitchenAreas error : ", e)
        return Promise.reject(e)
    }
}

export async function getSitesStockZones(toJson = true) {
    try {
        const sites = (await new Parse.Query(Site)
            .select(["name", "stockZones"])
            .include(["stockZones"])
            .notEqualTo("deleted", true)
            .find()) || []

        return toJson ? sites.map(el => el.toJSON()) : sites
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSitesStockZones error : ", e)
        return Promise.reject(e)
    }
}

export async function getSiteById(siteId, includes = ["*"], toJson = true) {
    try {
        const site = await new Parse.Query(Site)
            .include(includes)
            .equalTo("objectId", siteId)
            .first()

        return toJson ? site.toJSON() : site
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSiteById error : ", e)
        return Promise.reject(e)
    }
}

export async function getParseSite(siteId, includes = [], toJson = false) {
    try {
        const site = await new Parse.Query(Site)
            .equalTo("objectId", siteId)
            .include(includes)
            .first()

        return toJson ? site.toJSON() : site
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSite error : ", e)
        return Promise.reject(e)
    }
}

export async function getDefaultSite(toJson = true) {
    try {
        const site = await new Parse.Query(Site)
            .first()

        return toJson ? site.toJSON() : site
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getDefaultSite error : ", e)
        return Promise.reject(e)
    }
}

export async function getParseStockZone(stockZoneId) {
    try {
        return await new Parse.Query(StockZone)
            .equalTo("objectId", stockZoneId)
            .first()
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getParseStockZone error : ", e)
        return Promise.reject(e)
    }
}

export async function getStockZone({ stockZoneId, select = [], toJson = true }) {
    try {
        const query = new Parse.Query(StockZone)
            .equalTo("objectId", stockZoneId)

        if (select.length) {
            query.select(select)
        }

        const stockZone = await query.first() || null
        return stockZone ? toJson ? stockZone.toJSON() : stockZone : null
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getStockZone error : ", e)
        return Promise.reject(e)
    }
}

export async function getStockZones(stockZonesIds, toJson = true) {
    try {
        const zones = (await new Parse.Query(StockZone)
            .containedIn("objectId", stockZonesIds)
            .find()) || []

        return toJson ? zones.map(zone => zone.toJSON()) : zones
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getStockZones error : ", e)
        return Promise.reject(e)
    }
}

export async function deleteParseSites(sites) {
    try {
        return await new Parse.Query(Site)
            .containedIn("objectId", sites)
            .each(async site => {
                site.get("stockZones").map(async stockZone => {
                    await stockZone.destroy()
                })

                await site.destroy()
            })
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.deleteParseSites error : ", e)
        return Promise.reject(e)
    }
}

export async function getSiteByStockZone(stockZone = null, toJson = true) {
    try {
        const site = (await new Parse.Query(Site)
            .containedIn("stockZones", [stockZone])
            .include(["stockZones"])
            .first()) || null

        return site ? toJson ? site.toJSON() : site : null
    }
    catch (e) {
        console.error("parseManager.products.site.parseSiteManager.getSiteByStockZones error : ", e)
        return Promise.reject(e)
    }
}
