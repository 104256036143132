import React, { useRef } from "react"
import { DialogActions, DialogContent, DialogTitle, Button, Typography } from "@mui/material"
import { Formik } from "formik"
import StyledCreationDialog from "../../components/styled/StyledCreationDialog"
import dayjs from "dayjs"
import SecondaryDLCCreationForm from "./SecondaryDLCCreationForm"
import { secondaryDLCValidationSchema } from "../../utils/yupValidators"

const SecondaryDLCCreationModal = ({ open, onClose, onSubmit, siteId, kitchenAreaId }) => {

  const formikRef = useRef(null)

  const handleSubmit = (values) => {
    onSubmit && onSubmit(values)
  }

  const currentDate = dayjs.utc().startOf("day").valueOf()

  return (
    <StyledCreationDialog open={open} onClose={onClose} width={580}>
      <DialogTitle>
        Ajouter un produit en DLC secondaire
        <Typography variant="body1" sx={{marginTop: "16px", color: "#7C7C7C"}}>Ajoutez les informations du produit que vous souhaitez mettre en DLC secondaire.</Typography>
        </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={{
            site: siteId,
            kitchenArea: kitchenAreaId,
            supplierItem: null,
            orderSupplierItem: null,
            name: null,
            lot: null,
            productionSupply: null,
            openingDate: currentDate,
            secondaryDLC: dayjs(currentDate).add(1, "day"),
            quantity: null,
            dlc: null,
            lotNumber: null
          }}
          validationSchema={secondaryDLCValidationSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <SecondaryDLCCreationForm 
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          )}
        </Formik>
      </DialogContent>
      <DialogActions className="spaceBetween">
        <Button
          onClick={onClose}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={() => formikRef.current?.submitForm()}
        >
          Ajouter
        </Button>
      </DialogActions>
    </StyledCreationDialog>
  )

}

export default SecondaryDLCCreationModal