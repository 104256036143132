import React from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"

export const NutritionalScore = (props) => {
    const { classes, nutritionInformation } = props

    const nutriscore = nutritionInformation && nutritionInformation.nutriscore
        ? nutritionInformation.nutriscore.toUpperCase()
        : ""
    const srcNutriscore  = `/img/nutriscore/nutriscore_${nutriscore}.png`

    const ecoscore = nutritionInformation && nutritionInformation.carboneScore
        ? nutritionInformation.carboneScore.toUpperCase()
        : "unknown"
    const srcEcoscore = `/img/ecoscore/ecoscore_${ecoscore}.svg`

    return (
        <Paper className={classes.container}>
            {
                nutriscore
                    ? <img src={srcNutriscore} alt="Nutriscore" className={classes.nutritionInformation}/>
                    : <span className={classes.nutriscoreLabel}>Nutriscore Inconnu</span>
            }
            {
                ecoscore
                    ? <img src={srcEcoscore} alt="Nutriscore" className={classes.ecoscoreImg}/>
                    : <span className={classes.ecoscoreLabel}>Ecoscore inconnu</span>

            }
         </Paper>
    )
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },

    unknownLabel: {
        marginTop: "auto",
        marginBottom: "auto"
    },
    nutriscoreLabel: {
        composes: "$unknownLabel",
        marginRight: 10
    },
    ecoscoreLabel: {
        composes: "$unknownLabel",
        marginLeft: 10
    },
    nutriscoreImg: {
        marginRight: 10
    },
    ecoscoreImg: {
        marginLeft: 10
    }
}

export default withStyles(styles)(NutritionalScore)
