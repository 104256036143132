import React, { useState } from "react"
import clsx from "clsx"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { CircularProgress } from "@mui/material"

import { COLORS } from "../../utils"
import {
	LOT_OUTPUT_MODE
} from "../../utils/lotInventoryUtils"
import LotFilterModal from "../../components/LotInventory/LotFilterModal"
import { validateFilterSelection, showLotMain } from "../../actions/LotInventory/LotInventory"
import { initializeStockZones } from "../../utils/lotsUtils"


const useStyles = makeStyles(() => ({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	pointer: {
		cursor: "pointer"
	}
}))

const LotFilter = () => {
	const classes = useStyles()
	const sites = useSelector(state => state.lotInventory.sites)
	const mode = useSelector(state => state.lotInventory.mode)
	const outputDateSelected = useSelector(state => state.lotInventory.outputDateSelected)
	const outputTypeSelected = useSelector(state => state.lotInventory.outputTypeSelected)
	const siteSelected = useSelector(state => state.lotInventory.siteSelected)
	const stockZoneSelected = useSelector(state => state.lotInventory.stockZoneSelected)
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()

	if (mode === LOT_OUTPUT_MODE && siteSelected) {
		siteSelected.stockZones = initializeStockZones(stockZoneSelected, siteSelected)
	}

	const validate = (values) => {
		setIsLoading(true)
		dispatch(validateFilterSelection(values))
		dispatch(showLotMain(values.stockZone.objectId, mode, values.date))
	}

	if (isLoading) {
		return <div className={clsx(classes.wrapper, "flexCenter")}>
			<CircularProgress />
		</div>
	}

	return (
		<div className={classes.wrapper}>
			<div className={classes.container}>
				<LotFilterModal
					sites={sites}
					mode={mode}
					outputDateSelected={outputDateSelected}
					validate={validate}
					outputTypeSelected={outputTypeSelected}
					siteSelected={siteSelected}
					stockZoneSelected={stockZoneSelected}
				/>
			</div>
		</div>
	)
}
export default LotFilter