import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import { SupplierEdit } from "./SupplierEdit"
import Chip from "@mui/material/Chip"
import moment from "moment"
import RoundCheckBoxIcon from "../RoundCheckBoxIcon"

const useStyles = makeStyles({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 800
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16,
        lineHeight: "11px"
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 232,
        display: "flex",
        alignItems: "center"
    },
    chip: {
        textAlign: "center",
        padding: "0 10px",
        margin: "0 10px"
    }
})

export const SupplierResume = (props) => {
    const {
        jsonSupplier,
        onSaveSupplier,
        canEdit,
        sites
    } = props
    
    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)
    
    const edit = () => setIsEditing(true)

    const _toggleEdit = () => setIsEditing(!isEditing)

    return <>
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Resumé fournisseur
                </div>
                {
                    canEdit &&
                    <IconButton
                        className={classes.iconContainer}
                        disableRipple={true}
                        onClick={edit}
                        size="large">
                        <EditIcon className={classes.icon}/>
                    </IconButton>
                }
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Statut
                </div>
                <span>
                    { jsonSupplier.isActive ? "Actif" : "Inactif" }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Export Etiquettable
                </div>
                <span>
                    <RoundCheckBoxIcon
                        checked={jsonSupplier.canExportEtiquettable}
                    />
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Nom
                </div>
                <span>
                    { jsonSupplier.name }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Raison Sociale
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.corporateName }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Code du pays
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.countryCode }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Code du continent
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.continentCode }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    N° d'agrément sanitaire
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.sanitaryApprovalNumber }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    SIRET
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.siret }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    TVA intracommunautaire
                </div>
                <span>
                    { jsonSupplier.informations && jsonSupplier.informations.intraTVA }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Période de référencement
                </div>
                <span>
                    Du
                    <Chip
                        label={
                            jsonSupplier.informations && jsonSupplier.informations.referenceStartDate ?
                                moment(jsonSupplier.informations.referenceStartDate).format("DD/MM/YYYY") :
                                ""
                        }
                        className={classes.chip}
                    />
                    au
                    <Chip
                        label={
                            jsonSupplier.informations && jsonSupplier.informations.referenceEndDate ?
                                moment(jsonSupplier.informations.referenceEndDate).format("DD/MM/YYYY") :
                                ""
                        }
                        className={classes.chip}
                    />
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Référence client
                </div>
                <span>
                    {
                        jsonSupplier.informations && jsonSupplier.informations.clientReference
                            ? jsonSupplier.informations.clientReference
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Site de livraison par défaut
                </div>
                <span>
                    {
                        jsonSupplier.informations && jsonSupplier.informations.defaultDeliverySite
                            ? jsonSupplier.informations.defaultDeliverySite.name
                            : ""
                    }
                </span>
            </div>
        </Paper>
        {
            canEdit &&
            <SupplierEdit
                onClose={_toggleEdit}
                isEditing={isEditing}
                sites={sites}
                jsonSupplier={jsonSupplier}
                onSaveSupplier={onSaveSupplier}
            />
        }
    </>
}

export default SupplierResume


