import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { red } from "@mui/material/colors"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import Dialog from "@mui/material/Dialog"
import SupplierItemRecipeUseForm from "./SupplierItemRecipeUseForm"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    paddingBottom: 40,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: "20px 32px 16px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
  },
  singleValueContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
  },
  valueLabel: {
    color: COLORS.BLUEGREY_700,
    width: 300,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
  },
  stateValue: {
    textTransform: "upperCase",
  },
  useOnRecipeValue: {
    color: COLORS.GREEN_LOT_INVENTORY_TEXT,
  },
  notUseOnRecipeValue: {
    color: red[400],
  },
  infosBloc: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px 16px 32px",
    fontWeight: 400,
    color: "#898989"
  },
  info: {
    paddingBottom: "32px"
  },
}))

const SupplierItemSettingRecipeUses = (props) => {
  const {
    supplierItem,
    canEdit,
    onUpdateSupplierItem
  } = props

  const classes = useStyles()
  const [openEditModal, setOpenEditModal] = useState(false)

  const handleCloseModal = () => {
    setOpenEditModal(false)
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Utilisation sur les fiches recettes</div>
        {canEdit &&
          <div className={classes.editIcon}>
            <NewEditIcon onClick={setOpenEditModal} />
          </div>
        }
      </div>
      {
        openEditModal
          ? <Dialog
            open={openEditModal}
            onClose={handleCloseModal}
            aria-labelledby="form-dialog-title"
          >
            <SupplierItemRecipeUseForm
              supplierItem={supplierItem}
              onClose={handleCloseModal}
              onUpdateSupplierItem={onUpdateSupplierItem}
            />
          </Dialog>
          : null
      }
      {supplierItem &&
        <>
          <div className={classes.singleValueContainer}>
            <div className={classes.valueLabel}>Ingrédient associé à l’article</div>
            <span>{supplierItem.commercialName?.name}</span>
          </div>
          <div className={classes.singleValueContainer}>
            <div className={classes.valueLabel}>Famille d’ingrédients</div>
            <span>{supplierItem.commercialName?.group.family.name}</span>
          </div>
          <div className={classes.singleValueContainer}>
            <div className={classes.valueLabel}>Groupe d’ingrédients</div>
            <span>{supplierItem.commercialName?.group.name}</span>
          </div>
          <div className={classes.singleValueContainer}>
            <div className={classes.valueLabel}>Ajout possible sur une fiche recette</div>
            <span
              className={clsx(
                classes.stateValue,
                supplierItem.useOnRecipe
                  ? classes.useOnRecipeValue
                  : classes.notUseOnRecipeValue
              )}
            >
              {supplierItem.useOnRecipe ? "Oui" : "Non"}
            </span>
          </div>
        </>
      }
    </Paper>
  )
}

export default SupplierItemSettingRecipeUses
