import React from "react"
import {
    useSelector,
} from "react-redux"
import { Box, styled } from "@mui/material"

import { COLORS } from "../utils"

const StyledContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "open"
})((props) => ({
    width: props.open ? "calc(100vw - 240px)" : "calc(100vw - 54.4px)",
    paddingBottom: 70
}))

export const StyledSubNavBar = styled("div")({
    height: 64,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
    backgroundColor: COLORS.PRIMARY_COLOR,
    justifyContent: "center",
    width: "100%"
})

const PageWithTabsContainer = ({ children }) => {
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    
    return (
        <StyledContainer open={menuIsOpen} display="flex" flexDirection="column">
            {children}
        </StyledContainer>
    )
}

export default PageWithTabsContainer
