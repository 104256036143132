import React from "react"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import makeStyles from "@mui/styles/makeStyles"
import {COLORS} from "../utils"

const useStyles = makeStyles({
    iconContainer: {
        margin: 16,
            width: 38,
            height: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: COLORS.WHITE,
            borderRadius: 3,
            padding: 0,
            "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
            "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    whiteIconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    whiteIcon: {
        color: COLORS.WHITE,
    }
})

const NewEditIcon = props => {
    const {
        onClick,
        isWhite
    } = props
    const classes = useStyles()

    return (
        <IconButton
            className={ isWhite ? classes.whiteIconContainer : classes.iconContainer }
            disableRipple={true}
            onClick={onClick}
            size="large">
            <EditIcon className={ isWhite ? classes.whiteIcon : classes.icon }/>
        </IconButton>
    )
}

export default NewEditIcon