import React, { useState, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import DataGrid from "../../components/DataGrid/DataGrid"
import PackagingExecutionStatus from "./PackagingExecutionStatus"
import { PE_STATUSES, PE_STATUSES_AND_LABELS } from "../../utils/packagingExecutions"
import { timestampToDate } from "../../utils"
import PackagingExecutionCommentBadge from "./PackagingExecutionCommentBadge"
import { showPackagingExecution, showPackagingExecutionsHoursEdit } from "../../actions/PackagingExecution/packagingExecution"

const HOURS_FORMAT = "HH:mm"

const PackagingExecutionTable = ({ packagingExecutions, status = "TODO", date, site }) => {
	const dispatch = useDispatch()

	const [tableData, setTableData] = useState([])
	const [commentModalShown, setCommentModalShown] = useState(false)
	const [selectedComments, setSelectedComments] = useState([])

	const tableTitle = useMemo(() => {
		const commonTitle = "Barquettages"
		switch (status) {
			case PE_STATUSES.toValidate:
				return `${commonTitle} à valider`
			case PE_STATUSES.todo:
				return `${commonTitle} à démarrer`
			case PE_STATUSES.inProgress:
				return `${commonTitle} en cours`
			case PE_STATUSES.done:
				return `${commonTitle} terminés`
			default:
				return commonTitle
		}
	}, [status])

	const goToPackagingExecutionHoursEdit = () => {
		const localStorage = window.localStorage
		localStorage.setItem("packagingExecutionStatus", status)
		dispatch(showPackagingExecutionsHoursEdit(site.objectId, date))
	}


	const moreMenus = useMemo(() => {
		if (status === PE_STATUSES.toValidate || status === PE_STATUSES.todo) {
			return [
				{
					label: "Éditer les heures et les lignes de barquettage",
					onClick: goToPackagingExecutionHoursEdit
				}
			]
		}
		return []
	}, [status])

	const columns = useMemo(() => {
		const defaultColumns = [
			{
				label: "Heure théorique",
				key: "theoreticalTime",
				dataType: "text",
				sortable: true,
				filter: {
					filterable: false,
				},
				statuses: [PE_STATUSES.toValidate, PE_STATUSES.todo, PE_STATUSES.locked, PE_STATUSES.inProgress]
			},
			{
				label: "Heure réelle",
				key: "time",
				dataType: "text",
				sortable: true,
				filter: {
					filterable: false
				},
				statuses: [PE_STATUSES.done]
			},
			{
				label: "Recette",
				key: "recipe",
				sortable: false,
				filter: {
					filterable: true,
					type: "text"
				},
			},
			{
				label: "Ligne de barquettage",
				key: "packagingLine",
				sortable: false,
				dataType: "text",
				filter: {
					filterable: true,
					type: "select",
					options: site.packagingLines?.map(packagingLine => ({
						key: packagingLine.objectId,
						value: packagingLine.name,
						label: packagingLine.name
					})) || []
				},
				width: 250
			},
			{
				label: "Nb théorique de produits barquettés",
				key: "expectedPackagingNumber",
				dataType: "number",
				sortable: false,
				filter: {
					filterable: false,
				}
			},
			{
				label: "État du barquettage",
				key: "status",
				sortable: false,
				filter: {
					filterable: false,
				},
				render: (statusLabel) => {
					const statusValue = PE_STATUSES_AND_LABELS.find(elem => elem.label === statusLabel)?.key
					return <PackagingExecutionStatus statusValue={statusValue} statusName={statusLabel} />
				}
			},
			{
				label: "",
				key: "comments",
				sortable: false,
				filter: {
					filterable: false
				},
				render: (comments) => {
					return <PackagingExecutionCommentBadge count={comments.length} handleClick={() => showComments(comments)} />
				},
				width: 100
			}]
		return defaultColumns.filter(({ statuses }) => !statuses || statuses.includes(status))
	}, [status, site])

	const createData = (pe) => {
		const theoreticalStart = timestampToDate(pe.theoreticalStartTime, HOURS_FORMAT) || ""
		const theoreticalEnd = timestampToDate(pe.theoreticalEndTime, HOURS_FORMAT) || ""
		const startTime = timestampToDate(pe.startTime, HOURS_FORMAT) || ""
		const endTime = timestampToDate(pe.endTime, HOURS_FORMAT) || ""
		return {
			id: pe.objectId,
			theoreticalTime: theoreticalStart + " - " + theoreticalEnd,
			time: startTime + " - " + endTime,
			recipe: `${pe.uniqueCode || ""} - ${pe.recipeName || ""}`,
			packagingLine: pe.packagingLine?.name || "",
			expectedPackagingNumber: pe.expectedPackagingNumber || 0,
			status: PE_STATUSES_AND_LABELS.find(elem => elem.key === pe.status)?.label || "",
			comments: (pe.comments || []),
		}
	}

	useEffect(() => {
		const formattedData = packagingExecutions.map(createData)
		setTableData(formattedData)
	}, [packagingExecutions])


	const showComments = (comments) => {
		console.log("to be continued", commentModalShown, selectedComments)
		setSelectedComments(comments)
		setCommentModalShown(true)
	}

	const onClickPe = (pe) => {
			dispatch(showPackagingExecution(site.objectId, date, pe.id))
	}

	return <DataGrid
		title={tableTitle}
		menus={moreMenus}
		columns={columns}
		data={tableData}
		onRowClick={onClickPe}
		withFilters
	/>

}

export default PackagingExecutionTable