const defaultSate = {
    supplierItems: [],
    supplierItemsOptions: [],
    suppliers: [],
    selectedSupplierItem: null,
    supplierItemSnackBar: { open: false, type: "", message: "", duration: 0 },
    cookingModes: [],
    transformationModes: [],
    supplierItemTransformationModes: [],
    oldSupplierItemTransformationModes: [],
    supplierItemTransformationMode: null,
    sites: [],
    filters: {
        name: "",
        productTypes: [],
        suppliers: [],
    }
}

export default function (state = defaultSate, action) {
    switch (action && action.type) {
        case "SUPPLIER_ITEMS_LOADED":
            return {
                ...state,
                supplierItems: action.supplierItems,
                suppliers: action.suppliers,
            }
        case "SUPPLIER_ITEMS_OPTIONS_LOADED":
            return {
                ...state,
                supplierItemsOptions: action.supplierItemsOptions,
            }
        case "UPDATED_SUPPLIER_ITEMS_SNACKBAR":
            return {
                ...state,
                supplierItemSnackBar: action.supplierItemSnackBar
            }
        case "SELECTED_SUPPLIER_ITEM_LOADED":
            return {
                ...state,
                selectedSupplierItem: action.selectedSupplierItem,
                cookingModes: action.cookingModes,
                sites: action.sites,
                transformationModes: action.transformationModes,
                supplierItemsRelatives: action.supplierItemsRelatives
            }
        case "UPDATE_SUPPLIER_ITEM_IMAGE_NAME":
            return {
                ...state,
                selectedSupplierItem: {
                    ...state.selectedSupplierItem,
                    images: action.images
                },
            }
        case "SELECTED_SUPPLIER_ITEM_UPDATED":
            return {
                ...state,
                selectedSupplierItem: action.selectedSupplierItem
            }
        case "UPDATE_SUPPLIER_ITEM_TRANSFORMATION_MODE":
            return {
                ...state,
                selectedSupplierItem: {
                    ...state.selectedSupplierItem,
                    transformationModes: action.transformationModes
                },
            }
        case "FILTER_SUPPLIER_ITEMS":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.field]: action.value
                }
            }
        case "TRANSFORMATION_MODE_ADDED":
            return {
                ...state,
                transformationModes: [action.transformationMode, ...state.transformationModes]
            }
        case "TRANSFORMATION_MODES_LOADED":
            return {
                ...state,
                transformationModes: action.transformationModes
            }
        case "SUPPLIER_ITEM_TRANSFORMATION_MODES_LOADED":
            return {
                ...state,
                supplierItemTransformationModes: action.supplierItemTransformationModes,
            }
        case "OLD_SUPPLIER_ITEM_TRANSFORMATION_MODES_LOADED":
            return {
                ...state,
                oldSupplierItemTransformationModes: action.oldSupplierItemTransformationModes,
            }
        case "SUPPLIER_ITEM_TRANSFORMATION_MODES_CLEARED":
            return {
                ...state,
                supplierItemTransformationModes: [],
                oldSupplierItemTransformationModes: []
            }
        case "SUPPLIER_ITEM_TRANSFORMATION_MODE_LOADED":
            return {
                ...state,
                supplierItemTransformationMode: action.supplierItemTransformationMode,
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getSelectedSupplierItem(state, id) {
    return state.supplierItems.supplierItems.find(supplierItem => supplierItem.objectId === id) || null
}

export function getSupplierItemsSelector(state) {
    return state.supplierItems.supplierItems
}

export function getSupplierItemsOptionsSelector(state) {
    return state.supplierItems.supplierItemsOptions
}

export function getSupplierItemFilterByNameSelector(state) {
    return state.supplierItems.filters.name
}

export function getSupplierItemFilterByProductTypesSelector(state) {
    return state.supplierItems.filters.productTypes
}

export function getSupplierItemFilterBySuppliersSelector(state) {
    return state.supplierItems.filters.suppliers
}

export function getSupplierItemFilterByReferenceSelector(state) {
    return state.supplierItems.filters.reference
}

export function getSupplierItemFilterByIngredientSelector(state) {
    return state.supplierItems.filters.ingredient
}

export function getTransformationModesSelector(state) {
    return state.supplierItems.transformationModes
}

export function getSupplierItemTransformationModesSelector(state) {
    return state.supplierItems.supplierItemTransformationModes
}

export function getSupplierItemTransformationModeSelector(state) {
    return state.supplierItems.supplierItemTransformationMode
}

export function getSupplierItemSnackbarSelector(state) {
    return state.supplierItems.supplierItemSnackBar
}
