import {
    actionWithLoader,
    push,
} from "../Utils/utils"

export function closePlanningSettingsSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "PLANNING_SETTINGS_SNACKBAR",
            planningSettingsSnackBar: {open: false, type: currentType, message: "", duration: 1000}
        })
    })
}

export function showPlanningSettingSales() {
    return push("/planning/settings/sales")
}

export function showPlanningSettingValidation() {
    return push("/planning/settings/validation")
}

export function showPlanningSettingDashboard() {
    return push("/planning/settings/dashboard")
}

export function showPlanningSettingDispatchKey() {
    return push("/planning/settings/dispatchKey")
}