import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import { Stack } from "@mui/material"
import {
	PSE_STATUS,
	showProductionStepsSupervision
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import PSEResume from "./preview/PSEResume"
import PSEProgressBar from "./preview/PSEProgressBar"
import PSEPreparation from "./preview/PSEPreparation"
import PSEWeights from "./preview/PSEWeights"
import PSECookingRecap from "./preview/PSECookingRecap"
import PSECoolingRecap from "./preview/PSECoolingRecap"
import { getPriorStepData } from "../../utils/productionStepExecution"
import ProductionStepExecutionLotsTable from "./productionStepExecutionInProgressCases/ProductionStepExecutionLotsTable"
import { StyledMainContainer } from "./styledComponents"
import ProductionStepsExecutionFixedHeader from "./ProductionSteoExecutionsFixedHeader"

const ProductionStepExecutionDone = ({
	productionStepExecution,
	onClickCommentsIcon,
	commentsCount
}) => {

	const dispatch = useDispatch()

	const transformationType = useMemo(() => productionStepExecution.productionStepSnapshot.transformation, [productionStepExecution])

	const handleBack = () => dispatch(showProductionStepsSupervision())

	return (
		<>
			{/* header */}
			<ProductionStepsExecutionFixedHeader onBack={handleBack} />

			{/* body */}
			<StyledMainContainer>
				<Stack spacing={3} sx={{ p: 3, borderBottom: "1px solid #E0E0E0" }}>
					<PSEResume
						name={productionStepExecution.productionStepSnapshot.name}
						status={productionStepExecution.status}
						transformation={productionStepExecution.productionStepSnapshot.transformation}
						section={productionStepExecution.sectionName}
						recipe={productionStepExecution.recipeName}
						uniqueCode={productionStepExecution.uniqueCode}
						onClickComments={onClickCommentsIcon}
						commentsCount={commentsCount}
					/>
					<PSEProgressBar
						currentSubStatus={productionStepExecution.subStatus}
						productionStepExecution={productionStepExecution}
						isDone={[PSE_STATUS.toTest, PSE_STATUS.done].includes(productionStepExecution.status)}
						times={{
							startTime: productionStepExecution.startTime,
							endTime: productionStepExecution.endTime,
							theoreticalStartTime: productionStepExecution.theoreticalStartTime,
							theoreticalEndTime: productionStepExecution.theoreticalEndTime
						}}
					/>
				</Stack>
				<Stack spacing={3} sx={{ p: 3 }}>
					<PSEWeights
						productionStepExecution={productionStepExecution}
						status={productionStepExecution.status}
						isVisible={productionStepExecution.productionStepSnapshot?.endStepWeighing === true}
					/>
					<PSEPreparation productionStepExecution={productionStepExecution} />
					<PSECookingRecap
						visible={transformationType === "COOKING"}
						machinesBatch={productionStepExecution.machinesBatch}
					/>
					<PSECoolingRecap
						visible={transformationType === "COOLING"}
						machinesBatch={productionStepExecution.machinesBatch}
					/>
					<ProductionStepExecutionLotsTable
						productionStepExecution={productionStepExecution}
						priorStepData={getPriorStepData(productionStepExecution)}
					/>
				</Stack>
			</StyledMainContainer>
		</>
	)

}

export default ProductionStepExecutionDone