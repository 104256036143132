import React, { useState } from "react"

import { Box, Fab, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDispatch } from "react-redux"

import { COLORS } from "../../../../../utils"
import { StyledTableCell } from "../../../../../components/styled/StyledTableCell"
import PageHeader from "../../../../../components/PageHeader"
import KitchenAreasTableHead from "./KitchenAreasTableHead"
import KitchenAreaFormModal from "./KitchenAreaFormModal"
import { addKitchenArea, deleteKitchenArea, editKitchenArea, saveSiteKitchenAreas } from "../../../../../actions/Site/sites"
import KitchenAreaDeleteModal from "./KitchenAreaDeleteModal"
import { DragDropContext, Draggable } from "react-beautiful-dnd"
import StrictModeDroppable from "../../../../../components/StrictModeDroppable"

const initialActions = { hasDeleted: false, hasEdited: false, hasAdded: false, hasOrderChanged: false }

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const sx = {
  firstColumn: {
    width: 450,
    pl: 5,
  },
  lastColumn: {
    width: 100
  },
  fab: {
    bottom: 32,
    left: 40,
    position: "absolute"
  },
  deleteIcon: {
    pointer: "cursor"
  }
}

const EditKitchenAreas = ({ site, onClose, kitchenAreas }) => {
  const [hoveredRow, setHoveredRow] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [actions, setActions] = useState(initialActions)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openCreationFormDialog, setOpenCreationFormDialog] = useState(false)

  const dispatch = useDispatch()

  
  const toggleOpenDeleteModal = () => setOpenDeleteModal(!openDeleteModal)

  const onSaveAll = async () => {
    // just close if nothing are changed
    const hasChanged = Object.values(actions).some(value => value)
    if (!hasChanged) {
      onClose()
      return
    }
    // save all modification to the database
    await dispatch(saveSiteKitchenAreas(site.objectId, actions))
    onClose()
  }

  const onCancel = () => {
    // clear the stored edition values
    dispatch({ type: "KITCHEN_AREAS_CLEARED" })
    onClose()
    setActions(initialActions)
  }

  const toggleCreationDialog = () => {
    setOpenCreationFormDialog(!openCreationFormDialog)
  }

  const onCreationSubmitForm = (values) => {
    dispatch(addKitchenArea(values))
    setActions(prev => ({ ...prev, hasAdded: true }))
  }

  const onEditionSubmitForm = (values) => {
    setActions(prev => ({ ...prev, hasEdited: true }))
    dispatch(editKitchenArea(selectedRow.kitchenArea.objectId, values))
  }

  const handleSelectRow = (kitchenArea) => setSelectedRow(kitchenArea)

  const closeEditionModal = () => setSelectedRow(null)

  const handleRowHover = (kitchenArea) => setHoveredRow(kitchenArea)

  const clearHoveredRow = () => setHoveredRow(null)

  const handleDeleteRow = (e) => {
    e.stopPropagation()
    setActions(prev => ({ ...prev, hasDeleted: true }))

    if (hoveredRow.machinesCount > 0) {
      toggleOpenDeleteModal()
      return
    }

    dispatch(deleteKitchenArea(hoveredRow.kitchenArea.objectId))
  }

  const onCloseDeleteModal = () => {
    clearHoveredRow(null)
    toggleOpenDeleteModal()
  }

  
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const orderedKitchenArea = reorder(
      kitchenAreas,
      result.source.index,
      result.destination.index
    )

    // update the kitchen area order depending on the drag and drop order
    const updatedKitchenAreas = orderedKitchenArea.map(
      (kitchenArea, index) => ({
        ...kitchenArea,
        order: index + 1
      })
    )

    dispatch({
      type: "KITCHEN_AREAS_LOADED",
      kitchenAreas: updatedKitchenAreas
    })

    setActions(prev => ({ ...prev, hasOrderChanged: true }))

    // console.log("updatedKitchenAreas: ", updatedKitchenAreas.map(k => ({ name: k.kitchenArea.name, order: k.order })))
  }

  return (
    <Box sx={{ minHeight: "100vh", position: "relative" }} className="flexColumn">
      <PageHeader
        title="Réglages - Sites"
        subtitle={site?.name}
        fixed={false}
        icon={<SettingsIcon />}
        onConfirm={onSaveAll}
        onCancel={onCancel}
      />
      <Box bgcolor={COLORS.GREY_IMAGE_LIST} flex={1} alignSelf="stretch">
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="droppable">
              {(provided) => (
                <Table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <KitchenAreasTableHead withActionColum />
                  <TableBody>
                    {kitchenAreas?.map((kitchenArea, index) => (
                      <Draggable
                        key={kitchenArea.kitchenArea.objectId}
                        draggableId={kitchenArea.kitchenArea.objectId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              userSelect: "none",
                              background: snapshot.isDragging ? "#f7f7f7" : "#fff"
                            }}
                            style={{ ...provided.draggableProps.style }}
                            onMouseOver={() => handleRowHover(kitchenArea)}
                            onMouseOut={clearHoveredRow}
                            onClick={() => handleSelectRow(kitchenArea)}
                          >
    
                            <StyledTableCell align="left" sx={sx.firstColumn}>{kitchenArea.kitchenArea.name}</StyledTableCell>
                            <StyledTableCell align="left">{kitchenArea.order}</StyledTableCell>
                            {snapshot.isDragging && (<TableCell align="left" width={200} />)}
                            <StyledTableCell align="left">{kitchenArea.machinesCount || 0}</StyledTableCell>
                            <StyledTableCell align="left" sx={sx.lastColumn}>
                                <IconButton
                                  aria-label="delete"
                                  sx={{
                                    ...sx.deleteIcon,
                                    // use visibility instead of null or display none, we need the size and presence of the element in the DOM
                                    // even if it is not visible, it is still in the DOM, so it will not affect or change the height or width of the row and cell
                                    visibility: hoveredRow && hoveredRow.kitchenArea.objectId === kitchenArea.kitchenArea.objectId ? "visible": "hidden"
                                  }}
                                  onClick={handleDeleteRow}
                                >
                                  <DeleteIcon sx={{ fontSize: 22 }} />
                                </IconButton>
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </Box>
        <Fab color="primary" onClick={toggleCreationDialog} sx={sx.fab}>
            <AddIcon />
        </Fab>

        {/* -------- creation modal -------- */}
        <KitchenAreaFormModal
          open={openCreationFormDialog}
          onClose={toggleCreationDialog}
          onConfirm={onCreationSubmitForm}
          // machineType={machineType}
        />

        {/* ------ single edition modal ------ */}
        <KitchenAreaFormModal
          open={!!selectedRow}
          onClose={closeEditionModal}
          onConfirm={onEditionSubmitForm}
          kitchenArea={selectedRow}
          isEdition
        />

        {/* ------ single edition modal ------ */}
        <KitchenAreaDeleteModal
          open={openDeleteModal}
          onClose={onCloseDeleteModal}
        />
      </Box>
    </Box>
  )
}

export default EditKitchenAreas