import React, {Fragment} from "react"
import TextField from "@mui/material/TextField"
import withStyles from "@mui/styles/withStyles"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import CheckBox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import PlanningBrandSelector from "../PlanningBrandSelector"
import {WeekCalendarPicker} from "../../Calendar/WeekCalendarPicker"

const styles = {
    container: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: 10,
        zIndex: 1000,
        marginLeft: 30,
        "& > *": {
            marginLeft: 10
        }
    },
    searchTextField: {
        minWidth: 190
    },
    datePicker: {
        minWidth: 140,
        maxWidth: 170
    },
    selectTypes: {
        minWidth: 120
    },
    inputLabel: {
        fontSize: "1em"
    }
}

const PlanningSearchTabs = (props) => {
    const {
        onSearchValueChange,
        types,
        filteredTypes,
        onTypesChange,
        classes,
        date,
        onDateChange,
        brand,
        onBrandChange,
        showFilter = true,
        searchFilter
    } = props


    return (
        <div>
            <div className={classes.container}>
                <PlanningBrandSelector
                    brand={brand}
                    onBrandClick={onBrandChange}
                    className={classes.brandSelector}
                    activeBrandsOnly
                />
                <WeekCalendarPicker onDateChange={onDateChange} date={date} classes={classes} />
                {
                    showFilter &&
                        <Fragment>
                            <TextField
                                variant="standard"
                                defaultValue={searchFilter}
                                onChange={onSearchValueChange}
                                label="Rechercher par nom ou code"
                                className={classes.searchTextField}
                                InputLabelProps={{
                                    classes: { root: classes.inputLabel }
                                }}
                            />
                            <Select
                                variant="standard"
                                className={classes.selectTypes}
                                name="type"
                                placeholder="Type de produit"
                                value={filteredTypes}
                                multiple={true}
                                onChange={onTypesChange}
                                displayEmpty
                                renderValue={selected =>
                                    selected.length > 0
                                        ? selected.find(elem => elem.key === "ALL")
                                        ? "Tous les types"
                                        : selected.map(elem => elem.label).join(", ")
                                        : "Aucun type sélectionné"
                                }
                            >
                                {
                                    types.map(elem => {
                                        return (
                                            <MenuItem key={elem.label} value={elem}>
                                                <CheckBox color="secondary" checked={Array.isArray(filteredTypes) && !!filteredTypes.find(filtered => filtered.key === elem.key)} />
                                                <ListItemText primary={elem.label} />
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Fragment>
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(PlanningSearchTabs)
