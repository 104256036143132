import Parse from "parse"

const Production = Parse.Object.extend("Production")

export const expectedProductionModifOrigin = {
	PROD_PLANNING: "production_planning",
	SZN_PAYMENT: "seazon_payment", // used in server
    REPORT: "report"
}

export async function canAddToCart(date, dataId) {
    const production = await new Parse.Query(Production)
        .equalTo("date", date)
        .first()

    if (production && production.get("data")){
        for (const i in production.get("data")){
            const current = production.get("data")[i]
            if (current.recipe){
                if (current.recipe.id === dataId){
                    return false
                }
            }
            else {
                if (current.subcontractorProduct.id === dataId){
                    return false
                }
            }
        }
    }

    return true
}
