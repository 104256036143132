const defaultSate = {
    packagingMaterial: null,
    packagingMaterials: [],
}

export default (state = defaultSate, action) => {
    switch (action.type) {
        case "PACKAGING_MATERIALS_LOADED":
            return {
                ...state,
                packagingMaterials: action.packagingMaterials,
            }
        // load single data
        case "PACKAGING_MATERIAL_LOADED":
            return {
                ...state,
                packagingMaterial: action.packagingMaterial,
            }
        case "PACKAGING_MATERIAL_ADDED":
            return {
                ...state,
                packagingMaterials: [action.packagingMaterial, ...state.packagingMaterials],
            }
        case "PACKAGING_MATERIAL_UPDATED":
            return {
                ...state,
                packagingMaterials: state.packagingMaterials.map((packagingMaterial) => {
                    if (packagingMaterial.objectId === action.packagingMaterial.objectId) {
                        return action.packagingMaterial
                    }
                    return packagingMaterial
                }),
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export const getPackagingMaterialsSelector = (state) => {
    return state.settings.packagingMaterials
}

export const getPackagingMaterialSelector = (state) => {
    return state.settings.packagingMaterial
}