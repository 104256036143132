import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"
const FamiliesIngredients = Parse.Object.extend("FamiliesIngredients")

export async function getFamiliesIngredients() {
    try {
        const familiesIngredients = await new Parse.Query(FamiliesIngredients)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()

        return familiesIngredients ? familiesIngredients.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.family.getFamiliesIngredients error : ", e)
        return Promise.reject(e)
    }
}

export async function getPointerFamilyIngredientsById(familyIngredientsId) {
    try {
        const familyIngredients = await new Parse.Query(FamiliesIngredients)
            .equalTo("objectId", familyIngredientsId)
            .first()

        return familyIngredients || null
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.family.parseFamilyManager.getPointerFamilyIngredientsById error : ", e)
        return Promise.reject(e)
    }
}

export async function createFamilyIngredient(familyIngredient, values) {
    try {
        let parseFamilyIngredient

        if (!familyIngredient) {
            parseFamilyIngredient = new FamiliesIngredients()
        }
        else {
            parseFamilyIngredient = await new Parse.Query(FamiliesIngredients)
                .equalTo("objectId", familyIngredient.objectId)
                .first()
        }

        if (parseFamilyIngredient){
            Object.keys(values).forEach( function(key) {
                const val = values[key]
                if (val !== null){
                    parseFamilyIngredient.set(key, val)
                }
            })

            await parseFamilyIngredient.save()

            return parseFamilyIngredient.toJSON()
        }
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.family.parseFamilyManager.createFamilyIngredient error : ", e)
        return Promise.reject(e)
    }
}
