import React, { useLayoutEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import OrderHeader from "./OrderHeader"
import OrderFooter from "./OrderFooter"
import OrderInfosAction from "./OrderInfosAction"
import {
    orderMode,
    computeCartData,
    isValidOrder
} from "../../../utils/ordersUtils"
import OrderCartListView from "./OrderCartListView"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    },
    mainBloc: {
        flex: "1 1 auto",
        overflow: "scroll"
    }
})

const OrderCartList = (props) => {
    const {
        mode,
        order,
        cart,
        actions,
        selectedSupplier,
        expectedDeliveryDate
    } = props
    
    const classes = useStyles()
    
    const [cartData, setCartData] = useState(computeCartData(cart))
    const [validatedOrder, setValidatedOrder] = useState(true)
    const [isFormValid, setIsFormValid] = useState(true)

    useLayoutEffect(() => {
        const computedCartData = computeCartData(cart)
        setCartData(computedCartData)

        if (order && order.expectedDeliveryDate && !selectedSupplier.order.expectedDeliveryDate) {
            selectedSupplier.order.expectedDeliveryDate = order.expectedDeliveryDate
        }

        setValidatedOrder(selectedSupplier && isFormValid ? isValidOrder(selectedSupplier.order, computedCartData) : false)
    }, [cart, selectedSupplier, isFormValid])

    const updateValidatedOrder = (isFormValid) => {
        setIsFormValid(isFormValid)
    }

    return (
        <div className={classes.container}>
            <OrderHeader
                mode={mode}
                order={order}
            />
            <div className={classes.mainBloc}>
                <OrderCartListView
                    order={order}
                    cart={cart}
                    mode={mode}
                    actions={actions}
                    isValidOrder={validatedOrder}
                />
            </div>
            {
                mode === orderMode.UPDATE &&
                    <OrderFooter
                        mode={mode}
                        selectedOrder={order}
                        totalPrice={cartData.price}
                        totalWeight={cartData.weight}
                        shippingFees={selectedSupplier && selectedSupplier.order && selectedSupplier.order.shippingFees ? selectedSupplier.order.shippingFees : ""}
                        expectedDeliveryDate={expectedDeliveryDate}
                        actions={actions}
                        order={selectedSupplier.order}
                        updateValidatedOrder={updateValidatedOrder}
                    />
            }
            <OrderInfosAction
                mode={mode}
                actions={actions}
                cart={cart}
                isValidOrder={validatedOrder}
                order={order}
            />
        </div>
    )
}

export default OrderCartList