import React from "react"
import { Table, TableRow, TableBody, TableHead } from "@mui/material"
import { PETableCell, PETotalTableCell, PETableHeaderCell, PETableDivider } from "../StyledPackagingExecutionPreviewComponents"
import { convertKilosIntoGrams } from "../../../utils/ressources"
import { roundNumber } from "../../../utils"
import { TextField } from "@mui/material"

const PackagingExecutionSectionsAndWasteRecap = ({ sections, isEdition = false, handleChange, errors, touched }) => {

	const _renderKilosToGrams = (weight) => {
		return roundNumber(convertKilosIntoGrams(weight), 0)
	}

	const _renderWaste = (waste) => {
		return roundNumber(waste, 2)
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<PETableHeaderCell align="start">Éléments à barquetter</PETableHeaderCell>
					<PETableHeaderCell>Poids fiche recette (g)</PETableHeaderCell>
					<PETableHeaderCell>Poids réel barquetté (g)</PETableHeaderCell>
					<PETableDivider />
					<PETableHeaderCell>Waste théorique (kg)</PETableHeaderCell>
					<PETableHeaderCell>Waste réel (kg)</PETableHeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sections.map((section, index) => (
					<TableRow key={index}>
						<PETableCell align="start">{section.sectionName}</PETableCell>
						<PETableCell>{_renderKilosToGrams(section.recipeSectionWeight)}</PETableCell>
						<PETableCell>{_renderKilosToGrams(section.realWeight)}</PETableCell>
						<PETableDivider />
						<PETableCell>{_renderWaste(section.forecastWaste)}</PETableCell>
						<PETableCell>
							{isEdition ? <TextField
								onChange={handleChange}
								name={`sections[${index}].realWaste`}
								type="number"
								variant="standard"
								error={errors?.sections && touched.sections && touched?.sections[index]?.realWaste && errors.sections[index]?.realWaste}
								helperText={errors?.sections && touched?.sections && touched?.sections[index]?.realWaste && errors.sections[index]?.realWaste}
								disabled={!isEdition}
								value={section.realWaste}
								inputProps={{
									style: {
										textAlign: "center"
									}
								}}
							/> : _renderWaste(section.realWaste)}
						</PETableCell>
					</TableRow>
				))}
				<TableRow>
					<PETotalTableCell align="start">Total</PETotalTableCell>
					<PETotalTableCell>{_renderKilosToGrams(sections.reduce((acc, section) => acc += section.recipeSectionWeight, 0))}</PETotalTableCell>
					<PETotalTableCell>{_renderKilosToGrams(sections.reduce((acc, section) => acc += section.realWeight, 0))}</PETotalTableCell>
					<PETableDivider />
					<PETotalTableCell>{_renderWaste(sections.reduce((acc, section) => acc += section.forecastWaste, 0))}</PETotalTableCell>
					<PETotalTableCell>{_renderWaste(sections.reduce((acc, section) => acc += section.realWaste, 0))}</PETotalTableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
}

export default PackagingExecutionSectionsAndWasteRecap