import React, { useCallback } from "react"
import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material"

import { PRODUCTION_STEPS_SPACINGS } from "../../../../../utils/constant"
import { computeReusableProductionStepsOnFieldChange } from "../../../../../utils/recipes"
import Steps from "../../../../../components/Recipe/ProductionSteps/steps/Steps"
import ReusableStepParent from "./ReusableStepParent"

// -------------- Accordion -------------- //
const StyledAccordion = styled((props) => (
  <Accordion {...props} />
))({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
})

export const StyledAccordionSummary = styled(
  (props) => <AccordionSummary {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "expandedIconLeftStep"
  }
)(({ expandedIconLeftStep = 0 }) => ({
  // the accordion is opened only in icon button click in edition mode
  flexDirection: "row-reverse",
  position: "relative",
  // opened and closed expanded icon
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded,& .MuiAccordionSummary-expandIconWrapper": {
    position: "sticky",
    left:
      PRODUCTION_STEPS_SPACINGS.ACCORDION_EXPANDED_ICON_LEFT +
      PRODUCTION_STEPS_SPACINGS.STEP_FIRST_COL_PL_DIFF +
      expandedIconLeftStep,
    zIndex: 30
  },
  // row
  "& .MuiAccordionSummary-content": {
    height: 54,
    padding: 0,
    margin: 0,
    borderBottom: "1px solid #cccccc",
    marginLeft: -28, // important! for the summary to not take account of the expand icon space
  }
}))

export const StyledIcon = styled("img",
  {
    shouldForwardProp: (prop) => prop !== "isEdition"
  })(({ isEdition = false }) => ({
    // the accordion is opened only in icon button click in edition mode
    pointerEvents: isEdition ? "auto" : "initial"
  }))

const ReusableStepFormRow = ({
  onRowHover,
  onRowBlur,
  stepValues,
  onFieldFocus,
  onKeyUp,
  onFieldBlur,
  hoveredRow,
  errors,
  setFieldValue,
  setValues,
  machineTypes,
  kitchenAreas,
  transformationModes,
  parentStepIndex,
  grossWeight,
  cost,
  updateSectionByReusableStepCoeff,
  updateSectionInReusableStepDeletion,
  fromRecipe = false,
  handleChange,
  isEdition = true,
  index = null,
  steps = [],
  sectionIndex,
  withMarginBottom = false,
  stepNumbers
}) => {
  const computeReusableStepsFormValues = useCallback((steps) => {
    const newFormValues = { ...stepValues }

    steps.forEach((step, stepIndex) => {
      step.stepComponents && step.stepComponents.forEach((_, ingredientIndex) => {
        computeReusableProductionStepsOnFieldChange(
          newFormValues,
          stepIndex,
          ingredientIndex
        )
      })
    })

    newFormValues.productionSteps = steps

    setValues(newFormValues)
  },
    [stepValues, setValues]
  )

  const _hasError = (index, field) => {
    return errors.productionSteps?.[index]?.[field]
  }

  return (
    <StyledAccordion
      elevation={0}
      defaultExpanded
      square
      disableGutters
      isReusable
    >
      <StyledAccordionSummary
        expandIcon={<StyledIcon isEdition={isEdition} alt="chevron" src="/icons/chevron-down.svg" />}
      >
        <ReusableStepParent
          step={stepValues}
          index={index}
          hoveredRow={hoveredRow}
          onRowHover={onRowHover}
          onRowBlur={onRowBlur}
          setFieldValue={setFieldValue}
          steps={steps}
          isEdition={isEdition}
          sectionIndex={sectionIndex}
          fromRecipe={fromRecipe}
          grossWeight={grossWeight}
          cost={cost}
          updateSectionByReusableStepCoeff={updateSectionByReusableStepCoeff}
          updateSectionInReusableStepDeletion={updateSectionInReusableStepDeletion}
          stepNumbers={stepNumbers}
        />
      </StyledAccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Steps
          steps={stepValues?.productionSteps || []}
          isEdition={isEdition}
          hoveredRow={hoveredRow}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          onKeyUp={onKeyUp}
          onRowHover={onRowHover}
          onRowBlur={onRowBlur}
          errors={errors}
          machineTypes={machineTypes}
          kitchenAreas={kitchenAreas}
          setFieldValue={setFieldValue}
          hasError={_hasError}
          computeReusableStepsFormValues={computeReusableStepsFormValues}
          transformationModes={transformationModes}
          isReusable
          fromRecipe={fromRecipe}
          parentStepIndex={parentStepIndex}
          handleChange={handleChange}
          section={stepValues}
          stepNumbers={stepNumbers}
        />
      </AccordionDetails>
      {/** empty div for scroll **/}
      {withMarginBottom && <div style={{ height: "200px" }} />}
    </StyledAccordion>
  )
}

export default ReusableStepFormRow