import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import moment from "moment"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material"

import { CustomKeyboardDatePicker } from "../CustomKeyboardDatePicker"
import { useToggle } from "../../hooks/useToggle"
import { COLORS, getTimezoneDate } from "../../utils"

const styles = {
    orderReportModalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 600,
        outline: "none",
        margin: "0 auto",
        top: "25%",
        position: "relative",
        borderRadius: 3,
        color: COLORS.BLACK,
        padding: 10
    },
    switchContainer: {
      marginTop: 10,
      composes: "flexCenter",
      padding: 10
    },
    closeContainer: {
        textAlign: "right"
    },
    closeIcon: {
        cursor: "pointer"
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "Bold"
    },
    orderReportModalInfos: {
        margin: "30px 20px 0px 20px",
        display: "flex"
    },
    actionContainer: {
        textAlign: "center"
    },
    cancelBtn: {
        marginRight: 20,
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.DARK_GREY,
        backgroundColor: COLORS.MEDIUM_GREY
    },
    confirmBtn: {
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.WHITE
    },
    endDate: {
        marginLeft: "auto"
    }
}

export const OrderReportModal = ({
    classes,
    closeModal,
    extractOrderReport,
}) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const { open: openConfirmationModal, toggle: toggleConfirmationModal } = useToggle()
    const minEndDate = moment().utc(startDate)

    const [preordersSelected, setPreordersSelected] = useState(false)

    const handleConfirm = () => toggleConfirmationModal()

    const handleExtractOrderReport = () => {
        extractOrderReport(startDate, endDate, preordersSelected)
        toggleConfirmationModal()
        closeModal()
    }

    const handleChangeDate = (value, type = "start") => {
        if (type === "start") {
            setStartDate(getTimezoneDate(value))
            return
        }
        setEndDate(getTimezoneDate(value))
    }

    return (
        <>
            <Modal open={true}>
                <div className={classes.orderReportModalContainer}>
                    <div className={classes.closeContainer}>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={closeModal}
                        />
                    </div>
                    <div className={classes.title}>
                        Extract rapport de commande
                    </div>
                    <div className={classes.orderReportModalInfos}>
                        <CustomKeyboardDatePicker
                            value={startDate}
                            onChange={(e) => handleChangeDate(e, "start")}
                            variant="inline"
                            label="Date de début"
                            placeholder="Date de début"
                            format="DD/MM/YYYY"
                            disabled={false}
                        />
                        <CustomKeyboardDatePicker
                            value={endDate}
                            onChange={(e) => handleChangeDate(e, "end")}
                            variant="inline"
                            label="Date de fin"
                            placeholder="Date de fin"
                            format="DD/MM/YYYY"
                            disabled={!startDate}
                            minDate={minEndDate}
                            className={classes.endDate}
                        />
                    </div>
                    <div className={classes.switchContainer}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={preordersSelected}
                                    onChange={() => setPreordersSelected(!preordersSelected)}
                                    color="secondary"
                                />}
                            label={<Typography variant="body2" sx={{ color: "grey.600" }}>
                                {preordersSelected ? "Rapports de pré-commandes pour les fournisseurs" : "Rapport interne pour l'équipe d'approvisionnement"}
                            </Typography>}
                        />
                    </div>
                    <div className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            className={classes.cancelBtn}
                            onClick={closeModal}
                            color="inherit"
                        >
                            ANNULER
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmBtn}
                            onClick={handleConfirm}
                            disabled={startDate === null || endDate === null}
                        >
                            VALIDER
                        </Button>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={openConfirmationModal}
                onClose={toggleConfirmationModal}
                aria-labelledby="order-report-modal-title"
                aria-describedby="order-report-modal-description"
            >
                <DialogTitle id="order-report-modal-title">
                    Confirmer
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="order-report-modal-description">
                        Vous validez les dates pour le rapport de commande ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleConfirmationModal} autoFocus>
                        Non
                    </Button>
                    <Button onClick={handleExtractOrderReport} variant="contained">Oui</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withStyles(styles)(OrderReportModal)
