import { filter } from "./utils"

import isEqual from "lodash/isEqual"

/**
 * @param {Parse.Object} parseObject
 * @param {Array|Set} names
 * @param dest (optional)
 */
export function getValues(parseObject, names, dest = {}) {
	names.forEach(name => {
		const value = parseObject.get ? parseObject.get(name) : parseObject[name]
		if (value != null) {
			dest[name] = value
		}
	})
	return dest
}

/**
 * . null or undefined values aren't set
 * . a value is set only when it's different
 * @param parseObject
 * @param values
 * @param {Array|Set} names (optional), ensures we only set the right properties
 */
export function setValues(parseObject, values, names) {
	if (names) {
		values = filter(values, names)
	}
	for (const key in values) {
		if (!values.hasOwnProperty(key)) {
			continue
		}

		const value = values[key]
		setValue(parseObject, key, value)
	}
}

export function setValue(parseObject, name, value) {
	const oldValue = parseObject.get(name)
	if (value == null) {
		parseObject.unset(name)
	} else if (oldValue !== value) {
		parseObject.set(name, value)
	}
}

/**
 * compares by id
 * @param parseObjects
 * @param searchedParseObject
 * @returns {boolean}
 */
export function includes(parseObjects, searchedParseObject) {
	if (!parseObjects || !searchedParseObject) {
		return false
	}
	const searchedId = searchedParseObject.id
	return !!parseObjects.find(obj => obj.id === searchedId)
}


export function isParseObjDirty(parseObject, values, names) {
	if (names) values = filter(values, names)
	let isDirty = false
	for (const key in values) {
		const newValue = values[key]
		const currentValue = parseObject.get(key)
		if (!isEqual(newValue, currentValue)) {
			isDirty = true
			break
		}
	}
	return isDirty
}

export const toPointerForQuery = (className, objectId) => {
	return `${className}$${objectId}`
}

// classnames to avoid plural singular mistakes

export const COLLECTION_NAMES = {
	site: "Site",
	kitchenArea: "KitchenArea",
	lot: "Lots",
	orderSupplierItem: "OrderSupplierItem",
	supplierItem: "SupplierItems",
	productionSupply: "ProductionSupply",
	secondaryDLC: "SecondaryDLC",
	transformationMode: "TransformationModes",
	recipe: "Recipe",
	productionStepExecution: "ProductionStepExecutions"
}