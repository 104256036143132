import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles({
  appBar: {
    position: "relative",
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
    height: 75,
    "&.no-border": {
      border: "none",
      boxShadow: "none",
    },
    "& > div": {
      height: "100%",
      paddingLeft: 28,
      "& > button": {
        paddingRight: 19,
        "& span": {
          width: 15.58,
          height: 15.17,
        },
      },
    },
  },
})

const ActionHeader = (props) => {
  const { handleClose, noBorder } = props
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.appBar, { "no-border": noBorder })}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default ActionHeader
