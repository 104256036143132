import React, {useCallback, useEffect, useState} from "react"
import { generateStockUnitLabel } from "../../../utils/orderReception"
import { roundNumber } from "../../../utils"
import DataGrid from "../../../components/DataGrid/DataGrid"

const OrderReceptionCreditNote = (props) => {
	const {
		creditNotes,
		supplierItems
	} = props

	const lots = []
	if (Array.isArray(supplierItems)) {
		supplierItems.forEach(item => {
			lots.push(...item.lots)
		})
	}
	const [data, setData] = useState([])

	useEffect(() => {
		createDataObject()
	}, [creditNotes])

	const createDataObject = useCallback(() => {
		creditNotes && setData(creditNotes.map(createData))
	})

	const createData = (creditNote) => {
		switch (creditNote.type) {
			case "LOT":
				const lot = creditNote.lot ? lots.find(el => el.objectId === creditNote.lot.objectId) : null

				return {
					id: creditNote.objectId,
					type: "Avoir sur un lot",
					name: lot ? lot.orderSupplierItem.name : "",
					quantity: creditNote.quantity + "-" + (creditNote.supplierItem ? generateStockUnitLabel(creditNote.supplierItem) : ""),
					amount: `${roundNumber(creditNote.amount, 2)} €`,
					reference: lot && lot.orderSupplierItem.reference,
				}
			case "BROKEN":
				return {
					id: creditNote.objectId,
					type: "Avoir sur une référence",
					name: creditNote.supplierItem ? creditNote.supplierItem.name : "",
					quantity: creditNote.quantity + "-" + (creditNote.supplierItem ? generateStockUnitLabel(creditNote.supplierItem) : ""),
					amount: `${roundNumber(creditNote.amount, 2)} €`,
					reference: "",
				}
			case "DELIVERY":
				return {
					id: creditNote.objectId,
					type: "Avoir sur réception globale",
					name: "",
					quantity: "",
					stockUnit: "",
					amount: `${roundNumber(creditNote.amount, 2)} €`,
					reference: "",
				}
			case "DELIVERY_NOTE":
				return {
					id: creditNote.objectId,
					type: "Avoir sur écart de quantité",
					name: creditNote.supplierItem ? creditNote.supplierItem.name : "",
					quantity: creditNote.quantity + "-" + (creditNote.supplierItem ? generateStockUnitLabel(creditNote.supplierItem) : ""),
					amount: `${roundNumber(creditNote.amount, 2)} €`,
					reference: "",
				}
			default:
				return {}
		}
	}

	const columns = [
		{
			key: "type",
			label: "Type d'avoir",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "name",
			label: "Nom de l'article",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "reference",
			label: "Référence",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "quantity",
			label: "Quantité",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: false,
			},
			render: (value) => {
				const quantity = value.split("-")[0]
				const unit = value.split("-")[1]
				return (
					<div><span>{quantity}</span><span style={{color: "#7C7C7C"}}> ({unit})</span></div>
				)
			}
		},
		{
			key: "amount",
			label: "Montant de l'avoir",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: false,
				type: "text"
			}
		},
	]

	return (
		<div>
			<DataGrid
				title={"Avoirs et réclamations"}
				columns={columns}
				data={data}
				withFilters={true}
				rowLabel={null}
				withAdditionalFiltersOptions={false}
				tableContainerStyle={{boxShadow: "none"}}
			/>
		</div>
	)
}

export default OrderReceptionCreditNote