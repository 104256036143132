import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import { Stack, Typography } from "@mui/material"
import CloudinaryImage from "../Image/CloudinaryImage"
import CommentsImagePreview from "./CommentsImagePreview"
import dayjs from "dayjs"

var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const useStyles = makeStyles({
  name: {
    color: "#555555",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "22px",
  },
  time: {
    color: "#9B9B9B",
    fontSize: 12,
    lineHeight: "22px",
  },
  imageContainer: {
    cursor: "pointer"
  }
})

const CommentsTimeline = ({ comments }) => {
  const [selectedImageId, setSelectedImageId] = useState(null)

  const classes = useStyles()

  const formatUsername = (username) => {
    let formattedUsername = username
    const indexOfAt = username.indexOf("@")
    if (indexOfAt !== -1) {
      formattedUsername = username.substring(0, indexOfAt)
    }
    return (
      formattedUsername.charAt(0).toUpperCase() + formattedUsername.slice(1)
    )
  }

  const formatRelativeCreationDate = (creationDate) => {
    const formattedCreationTime = dayjs(creationDate).fromNow()
    return (
      formattedCreationTime.charAt(0).toUpperCase() +
      formattedCreationTime.slice(1)
    )
  }

  const handleImageClick = (image) => {
    setSelectedImageId(image.publicId)
  }

  const handleImageClose = () => {
    setSelectedImageId(null)
  }

  return (
    <>
      <Stack spacing={3} marginTop={4}>
        {comments.map((comment, index) => (
          <Stack
            key={index}
            spacing={1}
            borderBottom={`1px solid ${index < comments.length - 1 ? "#E6E6E6" : "transparent"
              }`}
            paddingBottom={2}
          >
            <Stack spacing={1} direction="row">
              <span className={classes.name}>
                {formatUsername(comment.username)}
              </span>
              <span className={classes.time}>
                {formatRelativeCreationDate(comment.creation_dt)}
              </span>
            </Stack>
            <Typography variant="line" sx={{
              color: "#555555",
              wordWrap: "break-word",
              overflowWrap: "break-word"
            }}>
              {comment.comment}
            </Typography>
            {comment.images &&
              comment.images.map((image, index) => (
                <div className={classes.imageContainer} key={index} onClick={() => handleImageClick(image)}>
                  <CloudinaryImage
                    imageId={image.publicId}
                    width={272}
                    height={116}
                    resize="fit"
                    customQuality="auto"
                    handleClick={() => setSelectedImageId(image.publicId)}
                  />
                </div>
              ))}
          </Stack>
        ))}
      </Stack>
      <CommentsImagePreview
        open={!!selectedImageId}
        imageId={selectedImageId}
        onClose={handleImageClose}
      />
    </>
  )
}

export default CommentsTimeline
