import React  from "react"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../utils"
import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"

const useStyles = makeStyles({
    textField: {
        marginTop: 20,
        width: "100%",
        fontSize: 16,
        "& input": {
            color: COLORS.GREY_DISABLED,
        }
    },
    label: {
        color: COLORS.GREY_DISABLED,
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7,
        fontSize: 14
    }
})

const LoginForm = (props) => {
    const { values, errors, handleChange } = props
    const classes = useStyles()

    return (
        <>
            <FormControl className={classes.textField} variant="standard">
                <InputLabel
                    className={classes.label}
                    shrink={true}
                >
                    Email
                </InputLabel>
                <Input
                    name="username"
                    label="E-mail"
                    type="email"
                    value={values.username}
                    onChange={handleChange}
                />
                {
                    errors.username ?
                        (
                            <div className={classes.errorField}>{errors.username}</div>
                        )
                        : null
                }
            </FormControl>
            <FormControl className={classes.textField} variant="standard">
                <InputLabel
                    className={classes.label}
                    shrink={true}
                >
                    Mot de passe
                </InputLabel>
                <Input
                    name="password"
                    label="Mot de passe"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                />
                {
                    errors.password ?
                        (
                            <div className={classes.errorField}>{errors.password}</div>
                        )
                        : null
                }
            </FormControl>
        </>
    )
}

export default LoginForm
