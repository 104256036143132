import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Formik, Form, Field } from "formik"
import TextField from "@mui/material/TextField"
import {COLORS, ACTIVE_KFC_BRANDS} from "../../utils"
import TypeSelect from "./TypeSelect"
import BrandsSelect from "./BrandsSelect"
import { ProductResumeCreateSchema, ProductResumeCreateSchema2 } from "../../utils/yupValidators"
import moment from "moment"
import PlanningBrandSelector from "../Planning/PlanningBrandSelector"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import SupplierItemsAutoComplete from "../autoComplete/SupplierItemsAutoComplete"

export const ProductResumeCreate = (props) => {
    const {
        classes,
        onClose,
        isCreating,
        onSaveProductResume,
        isSubcontractorProduct
    } = props

    const submitForm = (values) => {
        onSaveProductResume(values)
    }

    const initialValues = isSubcontractorProduct ?
        {brand: "", name: "", type: ""} :
        {brands: [], name: "", type: ""}

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={initialValues}
            validationSchema={isSubcontractorProduct ? ProductResumeCreateSchema2 : ProductResumeCreateSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isCreating}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Créer un nouveau produit</span>
                            </DialogTitle>
                            <DialogContent>
                                {
                                    isSubcontractorProduct &&
                                    <FormControl className={classes.formControl} variant="standard">
                                        <Field
                                            name="name"
                                            placeHolder="Nom"
                                            type="text"
                                            value={values.name}
                                        >
                                            {({field, meta}) => {
                                                field.onChange = (name) => setFieldValue("name", name ? name:"")
                                                return (
                                                    <SupplierItemsAutoComplete
                                                        input={field}
                                                        name="name"
                                                        placeHolder="Nom"
                                                        meta={meta}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </FormControl>
                                }
                                {
                                    !isSubcontractorProduct &&
                                    <TextField
                                        variant="standard"
                                        id="name"
                                        label="Nom technique"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                    />
                                }
                                {
                                    errors.name?
                                        (
                                            <div className={classes.errorField}>{errors.name}</div>
                                        )
                                        : null
                                }
                                <TypeSelect
                                    classes={classes}
                                    name="type"
                                    id="type"
                                    value={values.type}
                                    onChange={handleChange}
                                />
                                {
                                    errors.type?
                                        (
                                            <div className={classes.errorField}>{errors.type}</div>
                                        )
                                        : null
                                }
                                {
                                    isSubcontractorProduct &&
                                    <FormControl className={classes.formControl} variant="standard">
                                        <InputLabel>Marque</InputLabel>
                                        <PlanningBrandSelector
                                            name="brand"
                                            brand={values.brand}
                                            onBrandClick={(brand) => setFieldValue("brand", brand.name)}
                                            activeBrandsOnly
                                        />
                                    </FormControl>
                                }
                                {
                                    (isSubcontractorProduct && errors.brand)?
                                        (
                                            <div className={classes.errorField}>{errors.brand}</div>
                                        )
                                        : null
                                }
                                {
                                    !isSubcontractorProduct &&
                                    <BrandsSelect
                                        id="brands"
                                        name="brands"
                                        value={values.brands}
                                        onChange={handleChange}
                                        productBrands={ACTIVE_KFC_BRANDS}
                                        classes={{
                                            ...classes,
                                            formControl: classes.formControl50
                                        }}
                                    />
                                }
                                {
                                    (!isSubcontractorProduct && errors.brands)?
                                    (
                                    <div className={classes.errorField}>{errors.brands}</div>
                                    )
                                    : null
                                }
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    créer le produit
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = {
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: "0px 4px 4px 4px"
    },
    input: {
        display: "flex",
        padding: 0,
        height: "100%"
    },
    typeFormControl: {
        width: "100%",
        marginLeft: "unset",
        marginTop: 16,
    },
    formControl: {
        marginLeft: "unset",
        width: "100%",
        marginTop: 16
    },
    formControl50: {
        marginLeft: "unset",
        width: "50%",
        marginTop: 16
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0
    },
    dialogTitleLabel: {
        fontWeight: 500,
        marginTop: 5,
        fontSize: 20,
        lineHeight: "24px",
        width: 596
    },
    errorField: {
        color: COLORS.RED
    },
    logo: {
        width: 16,
        marginRight: 10
    }
}

export default withStyles(styles)(ProductResumeCreate)
