export const fieldLabels = [
    {label: "Waste autorisé", field: "waste", labelType: null, productType: null, type: "number"},
    {label: "% plat réutilisable", field: "reusableMAIN_COURSE", labelType: "reusable", productType: "MAIN_COURSE", type: "number"},
    {label: "Min Plat", field: "minMAIN_COURSE", labelType: "min", productType: "MAIN_COURSE", type: "number"},
    {label: "Max Plat", field: "maxMAIN_COURSE", labelType: "max", productType: "MAIN_COURSE", type: "number"},
    {label: "% entrée réutilisable", field: "reusableSTARTER", labelType: "reusable", productType: "STARTER", type: "number"},
    {label: "Min entrée", field: "minSTARTER", labelType: "min", productType: "STARTER", type: "number"},
    {label: "Max entrée", field: "maxSTARTER", labelType: "max", productType: "STARTER", type: "number"},
    {label: "% dessert réutilisable", field: "reusableDESSERT", labelType: "reusable", productType: "DESSERT", type: "number"},
    {label: "Min dessert", field: "minDESSERT", labelType: "min", productType: "DESSERT", type: "number"},
    {label: "Max dessert", field: "maxDESSERT", labelType: "max", productType: "DESSERT", type: "number"},
    {label: "% fromage réutilisable", field: "reusableCHEESE", labelType: "reusable", productType: "CHEESE", type: "number"},
    {label: "Min fromage", field: "minCHEESE", labelType: "min", productType: "CHEESE", type: "number"},
    {label: "Max fromage", field: "maxCHEESE", labelType: "max", productType: "CHEESE", type: "number"}
]

export const distributionCentersProductTypes = [
    "MAIN_COURSE",
    "DESSERT",
    "STARTER",
    "CHEESE"
]

export function getDispatchFormat (hubDispatch) {
    const obj = {
        priority: hubDispatch.priority
    }

    for (const i in distributionCentersProductTypes) {
        const productType = distributionCentersProductTypes[i]

        if (hubDispatch[productType]) {
            const minString = "min" + productType
            const maxString = "max" + productType
            const reusableString = "reusable" + productType

            obj[minString] = hubDispatch[productType].min
            obj[maxString] = hubDispatch[productType].max
            obj[reusableString] = hubDispatch[productType].reusable
        }
    }

    return obj
}
