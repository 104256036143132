import Parse from "parse"
import { parseLimitRequest } from "../../../utils"
import { getSupplierWithId } from "../../products/resources/supplier/parseSupplierManager"
import { getGroupIngredientWithId } from "../classifications/group/parseGroupManager"
import { getAllergensWithIds } from "../classifications/allergen/parseAllergenManager"

const SubcontractorsCommercialNames = Parse.Object.extend("SubcontractorsCommercialNames")

export async function getSubcontractorsCommercialNamesForAllergens(includes = [], toJson = true) {
    try {
        const subcontractorsCommercialNames = (await new Parse.Query(SubcontractorsCommercialNames)
            .exists("allergens")
            .include(includes)
            .limit(parseLimitRequest)
            .find()) || []

        return toJson
            ? subcontractorsCommercialNames.map(el => el.toJSON())
            : subcontractorsCommercialNames
    }
    catch (e) {
        console.error("parseManager.ingredients.external.parseSubcontractorCommercialNameManager.getSubcontractorsCommercialNames error : ", e)
        return Promise.reject(e)
    }
}

export async function createASubcontractorCommercialName(values, original, toJson = true) {
    try {
        const keyToIgnore = [
            "supplier", "addAllergen", "family", "allergens",
            "group", "commercialName", "firstOriginAverage", "secondOriginAverage", "label",
            "firstOrigin", "secondOrigin", "conservationMode"
        ]
        const subcontractorCommercialName = original && original.objectId
            ? await getSubcontractorIngredientWithId(original.objectId, ["subcontractor", "group.family", "allergens"], false)
            : new SubcontractorsCommercialNames()

        /* supplier */
        if (values.supplier) {
            const currentSupplier = await getSupplierWithId(values.supplier, [], false)
            subcontractorCommercialName.set("supplier", currentSupplier)
        }

        /* Allergens */
        if (values.allergens) {
            const allergensId = values.allergens.map(allergen => allergen.objectId)
            const allergens = await getAllergensWithIds(allergensId, [], false)
            subcontractorCommercialName.set("allergens", allergens ? allergens : null)
        }

        /* Group */
        if (values.group) {
            const group = await getGroupIngredientWithId(values.group, [], false)
            subcontractorCommercialName.set("group", group ? group : null)
        }

        /* Sourcing */
        if (values.firstOrigin !== null) {
            subcontractorCommercialName.set("firstOrigin", values.firstOrigin)
            if (values.secondOrigin !== null) {
                subcontractorCommercialName.set("secondOrigin", values.secondOrigin)
            }
        } else {
            subcontractorCommercialName.set("firstOrigin", null)
            subcontractorCommercialName.set("secondOrigin", null)
        }

        if (values.firstOrigin !== null && values.firstOriginAverage) {
            subcontractorCommercialName.set("firstOriginAverage", parseFloat(values.firstOriginAverage))
            if (values.firstOriginAverage !== 100) {
                subcontractorCommercialName.set("secondOriginAverage", parseFloat(values.secondOriginAverage))
            }
        } else {
            subcontractorCommercialName.set("firstOriginAverage", null)
            subcontractorCommercialName.set("secondOriginAverage", null)
        }

        subcontractorCommercialName.set("conservationMode", values.conservationMode !== null ? values.conservationMode : null)
        subcontractorCommercialName.set("label", values.label !== null ? values.label : null)

        Object.keys(values).forEach(function (key) {
            const val = values[key]
            if (!keyToIgnore.includes(key) && val) {
                subcontractorCommercialName.set(key, val)
            }
        })

        await subcontractorCommercialName.save()

        return toJson
            ? subcontractorCommercialName.toJSON()
            : subcontractorCommercialName
    } catch (err) {
        console.error("parseManager.ingredients.external.parseSubcontractorsCommercialNameManager.getSubcontractorsCommercialNames : ", err)
        return Promise.reject(err)
    }
}

export async function getSubcontractorIngredientWithId(id, includes = [], toJson = true) {
    try {
        const subcontractorCommercialName = await new Parse.Query(SubcontractorsCommercialNames)
            .include(includes)
            .equalTo("objectId", id)
            .first()

        return toJson
            ? subcontractorCommercialName && subcontractorCommercialName.toJSON()
            : subcontractorCommercialName
    } catch (err) {
        console.error("parseManager.ingredients.external.parseSubcontractorsCommercialNameManager.getSubcontractorIngredientWithId : ", err)
        return Promise.reject(err)
    }
}

export async function getSubcontractorsCommercialNames(includes = [], toJson = true) {
    try {
        const subcontractorsCommercialNames = (await new Parse.Query(SubcontractorsCommercialNames)
            .include(includes)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()) || []
        return toJson
            ? subcontractorsCommercialNames.map(elem => elem.toJSON())
            : subcontractorsCommercialNames
    } catch (err) {
        console.error("parseManager.ingredients.external.parseSubcontractorsCommercialNameManager.getSubcontractorsCommercialNames : ", err)
        return Promise.reject(err)
    }
}

export async function getSubcontractorIngredients({ includes = [], selects = [], sortBy = "name", sortDirection = "asc", toJSON = false } = {}) {
    try {
        const query = new Parse.Query(SubcontractorsCommercialNames)
            .notEqualTo("deleted", true)

        if (includes?.length) {
            query.include(includes)
        }

        if (selects?.length) {
            query.select(selects)
        }

        if (sortDirection === "desc") {
            query.descending(sortBy)
        } else {
            query.ascending(sortBy)
        }

        const subcontractorIngredients = await query.limit(3000).find()

        return toJSON ? (subcontractorIngredients ? subcontractorIngredients.map(el => el.toJSON()) : []) : subcontractorIngredients
    } catch (e) {
        console.error("parseManager.ingredients.external.parseSubcontractorsCommercialNameManager.getSubcontractorsCommercialNames : ", e)
        return []
    }
}


export async function deleteSubcontractorCommercialNameWithId(id) {
    try {
        const subcontractorCommercialName = await new Parse.Query(SubcontractorsCommercialNames)
            .equalTo("objectId", id)
            .first()

        if (subcontractorCommercialName) {
            await subcontractorCommercialName.destroy()
        }
    } catch (err) {
        console.error("parseManager.ingredients.external.parseSubcontractorsCommercialNameManager.deleteSubcontractorCommercialName : ", err)
    }
}