import React from "react"
import { Form, Formik } from "formik"
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material"
import ModalForm from "../../form/ModalForm"
import { packagingLineSchema } from "../../../utils/yupValidators"
import ModalActions from "../../ModalActions"

const sx = {
  formControl: {
    "& .MuiFormLabel-root": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      transform: "none",
      fontSize: 14,
    },
    "& .MuiInputBase-root": {
      marginTop: 0
    }
  }
}
const PackagingLineFormDialog = ({
  onClose,
  open,
  onSave,
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Ajouter une ligne de barquettage."
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={{ name: "" }}
        onSubmit={onSave}
        validationSchema={packagingLineSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="name"
                    label="Nom"
                    value={values.name}
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange}
                  />
                  {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
                </FormControl>
              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingLineFormDialog
