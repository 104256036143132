import React from "react"
import { Box } from "@mui/material"

const GridThumbnail = ({ src, alt }) => {

	if (!src) return null

	return (<Box
		component="img"
		src={src}
		alt={alt || "thumbnail_logo"}
		sx={{
			width: 25,
			height: 25,
			marginRight: "5px"
		}}
	/>)
}

export default GridThumbnail