import dayjs from "dayjs"

export const formatOldComment = (comment, refDate, username) => {
	const creationDateToTimestamp = dayjs(refDate).valueOf()
	return {
		username,
		creation_dt: creationDateToTimestamp,
		comment: comment,
		images: []
	}
}