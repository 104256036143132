import React from "react"
import IngredientsTabs from "../IngredientsTabs"
import FamilyIngredientList from "./Families/FamilyIngredientList"

const FamiliesIngredientsTab = () => {
    return (
        <IngredientsTabs tabValue="families">
            <FamilyIngredientList/>
        </IngredientsTabs>
    )
}

export default FamiliesIngredientsTab