import React from "react"
import { makeStyles } from "@mui/styles"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Checkbox from "@mui/material/Checkbox"
import { COLORS } from "../../../../utils"

const useStyles = makeStyles(() => ({
    headerCell: {
        backgroundColor: COLORS.PRIMARY_COLOR,
        color: COLORS.WHITE,
        textAlign: "center",
        padding: 8
    }
}))

export const CreditNotesEnhancedTableHead = (props) => {
    const {
        onSelectAll,
        numSelected,
        rowCount
    } = props

    const classes = useStyles()

    const rows = [
        { id: "creditNoteType", label: "Type d'avoir ou réclamation"},
        { id: "lotNumber", label: "Numéro de lot"},
        { id: "dlc", label: "DLC"},
        { id: "stockZone", label: "Zone de stockage"},
        { id: "supplierItemAmount", label: "Nb d'articles concernés"},
        { id: "reason", label: "Motifs"}
    ]

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headerCell}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAll}
                        color="secondary"
                    />
                </TableCell>
                {
                    rows.map(row => 
                            <TableCell
                                key={row.id}
                                className={classes.headerCell}
                            >
                                {row.label}
                            </TableCell>
                        )
                }
            </TableRow>
        </TableHead>
    )
}

export default CreditNotesEnhancedTableHead