import React from "react"

import SubcontractorProducts from "../Subcontractors/SubcontractorProduct/SubcontractorProducts"
import RecipesPage from "../Recipe/RecipesPage"

const SubcontractorProductsTab = () => {
    return (
        <RecipesPage tabValue="subcontractorsProducts">
            <SubcontractorProducts />
        </RecipesPage>
    )
}

export default SubcontractorProductsTab