import React, { PureComponent } from "react"
import { withStyles } from "@mui/styles"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import { Field, reduxForm } from "redux-form"
import ReduxTextField from "../../components/form/ReduxTextField"
import { userValidator } from "../../utils/validators"
import { COLORS } from "../../utils"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll",
        zIndex: 1300,
        height: "100%"
    },
    titleContainer: {
        textAlign: "center",
        margin: 16
    },
    fieldsContainer: {
        width: "100%"
    },
    formControl: {
        width: 200,
        margin: 16
    },
    buttonContainer: {
        width: "100%"
    },
    submitButton: {
        float: "right",
        margin: 16
    }
}

export class UserForm extends PureComponent {
    render() {
        const { classes, handleSubmit, createUser, onClose } = this.props

        return (
            <div className={classes.container}>
                <h2 className={classes.titleContainer}>
                    Créer un nouvel utilisateur
                    <IconButton aria-label="Fermer" onClick={onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </h2>

                <form onSubmit={handleSubmit(createUser)}>
                    <div className={classes.fieldsContainer}>
                        <div>
                            <FormControl margin="normal" className={classes.formControl} required>
                                <Field name="email" label="Email" component={ReduxTextField} />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl margin="normal" className={classes.formControl}>
                                <Field name="password" label="Mot de passe" component={ReduxTextField} />
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            className={classes.submitButton}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

UserForm = reduxForm({
    form: "user",
    enableReinitialize: false,
    validate: userValidator
})(UserForm)

const styledUserForm = withStyles(styles)(UserForm)

export default styledUserForm