import React from "react"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import { COLORS } from "../utils"


export const CapitalShippingIcon = (props) => {
    const { color = COLORS.WHITE } = props

    return (
        <LocalShippingIcon color={color}/>
    )
}

export default CapitalShippingIcon