import React from "react"
import { Box, Typography } from "@mui/material"
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  box: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    justifyContent: "center",
    color: "#555"
  },
  icon: {
    width: 52,
    height: 52
  }
})

const EmptyCommentsContainer = () => {

  const classes = useStyles()

  return (
    <Box
      className={classes.box}
    >
      <ChatBubbleOutlineIcon className={classes.icon} />
      <Typography variant="h7">Pas de commentaires</Typography>
    </Box>)

}

export default EmptyCommentsContainer