import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import {
    COLORS
} from "../../utils"
import Button from "@mui/material/Button"
import moment from "moment"
import { productType } from "../../utils/dispatchUtils"
import TextField from "@mui/material/TextField"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import Table from "@mui/material/Table"
import Switch from "@mui/material/Switch"
import Collapse from "@mui/material/Collapse"
import { getWastePercentage } from "../../utils/planning"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ExpandLess from "@mui/icons-material/ExpandLess"

const styles =  makeStyles(() => ({
    prevModalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 700,
        outline: "none",
        margin: "0 auto",
        top: "25%",
        position: "relative",
        borderRadius: 3,
        color: COLORS.BLACK,
        padding: 10
    },
    closeContainer: {
        textAlign: "right"
    },
    closeIcon: {
        cursor: "pointer"
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "Bold"
    },
    prevEditModalInfos: {
        margin: "30px 20px 0px 20px"
    },
    actionContainer: {
        textAlign: "center"
    },
    cancelBtn: {
        marginRight: 20,
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.DARK_GREY,
        backgroundColor: COLORS.MEDIUM_GREY
    },
    confirmBtn: {
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.WHITE
    },
    raw: {
        display: "flex"
    },
    input: {
        width: 50,
        textAlign: "right"
    },
    cell: {
        padding: 0,
        borderBottom: "none"
    },
    inputProps: {
        textAlign: "right"
    },
    prevEditModalData: {
        marginBottom: 30
    },
    rawTextDetail: {
        marginTop: 8,
        marginRight: 15
    },
    textDecoration: {
        fontSize: 12,
        fontStyle: "italic",
        fontWeight: 300
    },
    prevEventsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    prevEventsCollapse: {
        maxHeight: "130px",
        overflowY: "scroll"
    },
    prevEventsExpand: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    prevEventText: {
        marginTop: 5,
        marginBottom: 5,
        textAlign: "left"
    }
}))

const PlanningPrevEditModal = (props) => {
    const {
        closeModal,
        currentCard,
        currentProductType,
        update,
        brand,
        productTypeObj = productType.find(el => el.type === currentProductType) || null
    } = props

    const classes = styles()
    const currentPrev = currentCard.prev.find(el => el.productType === currentProductType)

    const [newPrevSell, updatePrevSell] = useState("")
    const [newTarget, updateTarget] = useState("")
    const [newBonus, updateBonus] = useState(currentPrev.bonus)
    const [newMin, updateMin] = useState(currentPrev.min)
    const [newMax, updateMax] = useState("")
    const [newReusable, updateReusable] = useState(currentPrev.reusable)
    const [globalChange, updateGlobalChange] = useState(true)
    const [globalDays, updateGlobalDays] = useState(true)
    const [expanded, setExpanded] = useState(false)

    const formatPrevEventType = (type) => {
        let finalType
        switch (type) {
            case "MANUAL":
                finalType = "Manuel"
                break
            case "ALGORITHM":
                finalType = "Algo"
                break
            default:
                finalType = "Initial"
        }

        return finalType
    }

    const prevEvents = Array.isArray(currentPrev.events) && currentPrev.events
        .sort((a, b) => b.date - a.date)
        .map((event, i) => {
            return <p  className={classes.prevEventText} key={i}>{`${moment(event.date).format("DD/MM HH:mm")} - ${formatPrevEventType(event.updateType)} - ${event.prevProd}`}</p>
        }
    )

    const handleExpandClick = () => {
        setExpanded(!expanded)
      }

    const _updatePrevSell = (event) => {
        if (event){
            updatePrevSell(event.target.value)
            if (event.target.value === ""){
                updateTarget("")
            }
            else {
                const wastePercentage = brand.applyWaste ? getWastePercentage(parseFloat(event.target.value)) : 0
                const diffValue = parseFloat(event.target.value) * wastePercentage
                updateTarget(Math.round(parseFloat(event.target.value) + diffValue))
            }
        }
    }

    const confirm = () => {
        const data = {
            prevSell: newPrevSell,
            target: newTarget,
            min: newMin,
            max: newMax,
            reusable: newReusable,
            bonus: newBonus,
            globalChange: globalChange,
            globalDays: globalDays,
            events: currentPrev.events || null
        }
        
        update(currentCard, currentProductType, data)
    }

    return (
        <Modal open={true}>
            <div className={classes.prevModalContainer}>
                <div className={classes.closeContainer}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={() => {closeModal()}}
                    />
                </div>
                <div className={classes.title}>
                    { moment.utc(currentCard.date).startOf("day").format("ddd DD/MM") } - { currentCard.hubName } - { productTypeObj ? productTypeObj.label : "" }
                </div>
                <div className={classes.prevEditModalInfos}>
                    <Table className={classes.prevEditModalData} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell}></TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    Prev vente
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    Cible
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    Bonus (en %)
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    Min
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    Max
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    % réutilisable
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className={classes.cell}
                                    component="th"
                                    scope="row"
                                >
                                    Anciennes valeurs
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.prevSell }
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.target }
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.bonus }
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.min }
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.max }
                                </TableCell>

                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { currentPrev.reusable }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.cell}
                                    component="th"
                                    scope="row"
                                >
                                    Nouvelles valeurs
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    <TextField
                                        variant="standard"
                                        value={newPrevSell}
                                        type="number"
                                        inputProps={{
                                            className: classes.inputProps
                                        }}
                                        className={classes.input}
                                        onChange={_updatePrevSell}
                                        required={true}
                                    />*
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    { newTarget }
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    <TextField
                                        variant="standard"
                                        value={newBonus}
                                        type="number"
                                        inputProps={{
                                            className: classes.inputProps
                                        }}
                                        className={classes.input}
                                        onChange={(e) => {updateBonus(e.target.value)}}
                                        required={true}
                                    />*
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    <TextField
                                        variant="standard"
                                        value={newMin}
                                        type="number"
                                        inputProps={{
                                            className: classes.inputProps
                                        }}
                                        className={classes.input}
                                        onChange={(e) => {updateMin(e.target.value)}}
                                        required={true}
                                    />
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    <TextField
                                        variant="standard"
                                        value={newMax}
                                        type="number"
                                        inputProps={{
                                            className: classes.inputProps
                                        }}
                                        className={classes.input}
                                        onChange={(e) => {updateMax(e.target.value)}}
                                        required={true}
                                    />
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    align="right"
                                >
                                    <TextField
                                        variant="standard"
                                        value={newReusable}
                                        type="number"
                                        inputProps={{
                                            className: classes.inputProps
                                        }}
                                        className={classes.input}
                                        onChange={(e) => {updateReusable(e.target.value)}}
                                        required={true}
                                    />*
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className={classes.raw}>
                        <div className={classes.raw}>
                            <Switch
                                checked={globalChange}
                                onChange={(e) => {updateGlobalChange(e.target.checked)}}
                                color="secondary"
                            />
                        </div>
                        <div className={classes.rawTextDetail}>
                            Appliquer cette différence sur le volume global
                        </div>
                    </div>
                    <div className={classes.raw}>
                        <div className={classes.raw}>
                            <Switch
                                checked={globalDays}
                                onChange={(e) => {updateGlobalDays(e.target.checked)}}
                                color="secondary"
                            />
                        </div>
                        <div className={classes.rawTextDetail}>
                            Appliquer cette différence sur les prochains jours de { currentCard.hubName }
                        </div>
                    </div>
                    <div className={classes.textDecoration}>
                        Les champs * sont obligatoires
                    </div>
                </div>
                <div className={classes.prevEventsContainer}>
                    {
                        Array.isArray(prevEvents)
                            ? (
                                <>
                                    <div className={classes.prevEventsExpand} onClick={handleExpandClick}>
                                        Voir l'historique des modifications
                                        {
                                        expanded
                                            ? <ExpandLess />
                                            : <ExpandMore />
                                        }
                                    </div>
                                    
                                    <Collapse key={1} timeout="auto" in={expanded} unmountOnExit className={classes.prevEventsCollapse}>
                                        {prevEvents}
                                    </Collapse>
                                </>
                            )
                            : <p>Aucune modification recente.</p>
                    }           
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={() => {closeModal()}}
                    >
                        ANNULER
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.confirmBtn}
                        onClick={() => {confirm()}}
                        disabled={newTarget === "" || newBonus === ""}
                    >
                        VALIDER
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default PlanningPrevEditModal
