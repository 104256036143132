import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import withStyles from "@mui/styles/withStyles"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import TablePagination from "../../components/TablePagination"
import { showSectionGeneric, clearSelectedSectionGeneric, deleteSectionGeneric} from "../../actions/Section/SectionsGeneric"
import SectionEnhancedTableHead from "../../components/table-utils/Section/SectionEnhancedTableHead"
import SectionEnhancedTableToolbar from "../../components/table-utils/Section/SectionEnhancedTableToolbar"
import {calculSectionData, parseSectionToObject} from "../../utils/recipes"
import { stableSort, getSorting } from "../../components/table-utils/utils"
import { setSearchValue } from "../../utils"
import { browserHistory } from "react-router"

const styles = {
    root: {
        width: "100%"
    },

    searchFieldInput: {
        width: 300
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
}

class SectionsGenericList extends PureComponent {
    constructor(props) {
        super(props)
        const storage = JSON.parse(localStorage.getItem("searchValue"))

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            dataPage: 0,
            rowsPerPage: 10,
            searchFilter: (storage && storage.sectionsList)?storage.sectionsList:null,
            filterSections: [],
            data: []
        }
    }

    componentDidMount() {
        if (browserHistory.getCurrentLocation().query && browserHistory.getCurrentLocation().query.redirect) {
            const { showSectionGeneric } = this.props
            showSectionGeneric(browserHistory.getCurrentLocation().query.redirect)
        }

        const { clearSelectedSectionGeneric } = this.props
        clearSelectedSectionGeneric()
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const {sections} = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.sections.length !== sections.length) {
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { sections } = this.props
        const finalData = []
        let sectionsFilter = []
        const format = { year: "numeric", month: "numeric", day: "numeric" }
        if (this.state.searchFilter !== null && this.state.searchFilter !== ""){
            const regex = new RegExp(this.state.searchFilter, "ig")
            sectionsFilter = sections.filter(section => section.get("name").match(regex) !== null)
        }
        else {
            sectionsFilter = sections
        }
        this.setState({filterSections: sectionsFilter})

        const parsedSections = parseSectionToObject(sections)
        for (const i in sectionsFilter) {
            const section = sectionsFilter[i]
            const cost = calculSectionData(parsedSections[i].steps).cost
            finalData.push({
                id: section.id,
                name: section.get("name").toLowerCase(),
                cost: cost,
                createdAt: section.createdAt.toLocaleDateString("fr-FR", format),
                updatedAt: section.updatedAt.toLocaleDateString("fr-FR", format)
            })
        }
        this.setState({data: finalData})
    }

    updateSearchValue = (event) => {
        if (event) {
            this.setState({searchFilter: event.target.value})
            setSearchValue(event.target.value, "sectionsList")
        }
        else {
            this.setState({searchFilter: null})
        }
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else {
            newSelected = selected.filter((elem) => {
                return elem !== id
            })
        }
        this.setState({ selected: newSelected })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterSections } = this.state
            this.setState({selected: filterSections.map(section => section.id)})
            return
        }
        this.setState({selected: []})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }
        this.setState({ order, orderBy })
    }

    _showSection = (event, sectionId) => {
         const { showSectionGeneric } = this.props
         showSectionGeneric(sectionId)
     }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }


    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }


    isSelected = id => this.state.selected.indexOf(id) !== -1

    updateSelectedItems = () => {
        this.setState({selected: []})
    }

    render() {
        const { classes, sections, deleteSectionGeneric, showSectionGeneric } = this.props
        const { order, orderBy, selected, data, page, rowsPerPage, dataPage, filterSections } = this.state
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper elevation={0} className={classes.root}>
                <SectionEnhancedTableToolbar
                    numSelected={selected.length}
                    sections={sections}
                    showSection={showSectionGeneric}
                    deleteSectionGeneric={deleteSectionGeneric}
                    onDeleteItem={this.updateSelectedItems}
                    selected={selected}
                />
                <div className={classes.searchFieldDiv}>
                    <TextField
                        variant="standard"
                        defaultValue={this.state.searchFilter}
                        className={classes.searchFieldInput}
                        onChange={this.updateSearchValue}
                        label="Rechercher une section"
                    />
                </div>
                <div>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <SectionEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterSections.length}
                        />

                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showSection(event, n.id)}>{n.name}</TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showSection(event, n.id)}>{n.cost}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSection(event, n.id)}>{n.createdAt}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSection(event, n.id)}>{n.updatedAt}</TableCell>
                                        </TableRow>
                                    )

                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterSections}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />

            </Paper>
        )
    }
}


SectionsGenericList.propTypes = {
    classes: PropTypes.object.isRequired
}

SectionsGenericList = withStyles(styles)(SectionsGenericList)


export default connect(state => {
    return {
        sections: state.sectionsGeneric.sectionsGeneric
    }
}, {
    showSectionGeneric, deleteSectionGeneric, clearSelectedSectionGeneric
})(SectionsGenericList)
