import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import RightPageIcon from "@mui/icons-material/KeyboardArrowRight"
import LeftPageIcon from "@mui/icons-material/KeyboardArrowLeft"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const styles = {
    spanPagination: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75em",
        marginRight: 15
    },
    selectPagination: {
        fontSize: "0.75em",
        height: 25,
        marginRight: 25
    },
    disabledArrowPagination: {
        color: "rgba(0, 0, 0, 0.26)",
        marginLeft: 15
    },
    availableArrowPagination: {
        color: "rgba(0, 0, 0, 0.54)",
        marginLeft: 15
    },
    pagination: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingTop: 15,
        paddingBottom: 20,
        paddingRight: 10,
        display: "flex"
    }
}

class TablePagination extends PureComponent {

    handleChangeRowsPerPage = event => {
        const { handleChangeRowPerPage } = this.props
        handleChangeRowPerPage(event.target.value)
    }

    decrementPage = () => {
        const { decrementPage, dataPage } = this.props
        if (dataPage > 0){
            decrementPage()
        }
    }

    incrementPage = () => {
        const { incrementPage, rowsPerPage, dataPage, data, total } = this.props

        const dataLength = total || data?.length
        if (rowsPerPage * dataPage + rowsPerPage < dataLength) {
            incrementPage()
        }
    }
    
    renderRows = () => {
        const {choices = [5, 10, 25]} = this.props
        return (choices.map((item, index) => <MenuItem key={`pagination-${index}`} value={item}>{item}</MenuItem>))
    }

    render() {
        const { classes, rowsPerPage, data, dataPage, isDisabled = false, inputStyle = {disabledUnderline: false}, total } = this.props
        const dataLength = total || data?.length

        return (
            <div className={classes.pagination}>
                <span className={classes.spanPagination}>Rows per page :</span>
                <FormControl className={[classes.margin, classes.selectPagination].join(" ")} variant="standard">
                    <Select
                        variant="standard"
                        disabled={isDisabled}
                        disableUnderline={inputStyle.disabledUnderline}
                        value={rowsPerPage}
                        onChange={this.handleChangeRowsPerPage}
                    >
                        {this.renderRows()}
                    </Select>
                </FormControl>
                <span className={classes.spanPagination}>{dataLength > 0 ? rowsPerPage * dataPage + 1 : 0}-{rowsPerPage * dataPage + rowsPerPage < dataLength ? rowsPerPage * dataPage + rowsPerPage : dataLength} of {dataLength}</span>
                <LeftPageIcon className={rowsPerPage * dataPage + 1 > 1 ? classes.availableArrowPagination : classes.disabledArrowPagination} onClick={this.decrementPage}/>
                <RightPageIcon className={rowsPerPage * dataPage + rowsPerPage < dataLength ? classes.availableArrowPagination : classes.disabledArrowPagination} onClick={this.incrementPage}/>
            </div>
        )
    }
}
export default withStyles(styles)(TablePagination)
