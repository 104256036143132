import React, { useRef, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import moment from "moment"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import clsx from "clsx"
import ActionHeader from "./ActionHeader"
import { COLORS } from "../../utils"
import { LOT_INVENTORY_MODE, LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"
import EditLotBloc from "../Order/Lot/EditLotBloc"
import { generateOrderUnitLabel } from "../../utils/ordersUtils"
import { getSupplierItemWithId } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

import ButtonFlaw from "../Reception/ButtonFlaw"
import { showLotDamageReport } from "../../actions/LotInventory/LotInventory"

const useStyles = makeStyles(() => ({
	root: {
		marginBottom: 55,
		paddingBottom: 57,
		textAlign: "center",
		alignSelf: "center",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		flexGrow: 1,
		minWidth: "350px"
	},
	title: {
		fontSize: 24,
		margin: "0px 16px"
	},
	subtitle: {
		marginTop: 24
	},
	headerText: {
		fontSize: 20
	},
	bold: {
		fontWeight: 700
	},
	quantity: {
		margin: "0px auto",
		width: "100%",
		fontSize: 40,
		textAlign: "center",
		color: COLORS.GREY_DISABLED
	},
	inputWrapper: {
		marginBottom: 12
	},
	input: {
		width: 125,
		fontSize: 40,
		textAlign: "center",
	},
	inputDisabled: {
		color: COLORS.GREY_BACK_BUTTON
	},
	unit: {
		fontSize: 16,
		textTransform: "uppercase",
		color: COLORS.BLACK_500
	},
	buttons: {
		display: "flex",
		flexDirection: "column"
	},
	button: {
		height: 44,
		fontSize: 15,
		margin: 12,
		"&.disabled": {
			opacity: 0.6
		}
	},
	headerTextSubtitle: {
		fontSize: 14
	},
	stockUnit: {
		marginTop: "-8px",
		marginBottom: 8,
		fontSize: 16
	},
	supplierName: {
		fontSize: 18,
	},
	flexForAllZones: {
		flexGrow: 0.7,
	},
	buttonFlaw: {
		bottom: 33,
		marginBottom: 0
	}
}))

const ActionContent = (props) => {
	const {
		mode,
		lot,
		inputValue,
		setValue,
		handleValidate,
		handleEdit,
		disableSelection,
		showEditLot,
		editLot,
		site,
		isAllZones = false,
		stockZone
	} = props

	const classes = useStyles()
	const dispatch = useDispatch()
	const inputRef = useRef()

	const [disabled, setDisabled] = useState(true)
	const [outputValue, setOutputValue] = useState()
	const [supplierItem, setSupplierItem] = useState(null)
	const stockWeight = lot.orderSupplierItem.units && lot.orderSupplierItem.units.stock && lot.orderSupplierItem.units.stock.weight

	const enableInput = (e) => {
		setDisabled(e.target.value === "")
		inputChanges(e.target.value)
		setValue(e.target.value)
	}

	const inputChanges = (value) => {
		if (mode !== LOT_OUTPUT_MODE) {
			if ((typeof +value === "number" && value >= 0) || value === "") {
				setOutputValue(value)
			}
			return
		}
		// can make a negative output
		if ((typeof +value === "number" && value <= lot.quantity) || value === "") {
			setOutputValue(value)
		}
	}

	useEffect(() => {
		const fetchSupplierItem = async () => {
			if (lot) {
				const supplierItem = await getSupplierItemWithId(lot.orderSupplierItem.supplierItemId, ["supplier"])
				setSupplierItem(supplierItem)
			}
		}
		fetchSupplierItem()
	}, [lot])

	const onShowLotDamagedReportForm = () => {
    dispatch(showLotDamageReport(stockZone.objectId, lot.objectId))
	}


	const placeHolderValue = lot && mode === LOT_OUTPUT_MODE ? lot.quantityOutput : (lot.quantity ? lot.quantity : "0")
	return (
		<>
			<div className={classes.root}>
				<ActionHeader
					handleClose={disableSelection}
					noBorder={true}
				/>
				{
					showEditLot &&
					<>
						<div>
							<EditLotBloc
								orderSupplierItem={lot.orderSupplierItem}
								stockZones={[lot.stockZone]}
								currentLot={lot}
								editLot={editLot}
								site={site}
							/>
						</div>
					</>
				}
				{
					!showEditLot && !lot &&
					<>
						{`Choisissez un produit à ${mode === LOT_INVENTORY_MODE ? "réceptionner" : "sortir"}`}
					</>
				}
				{!showEditLot && lot &&
					<>
						<div>
							<div className={classes.title}>
								<div className={classes.title}>
									<div className={classes.bold}>
										{lot.orderSupplierItem.name}
									</div>
								</div>
								<div className={classes.supplierName}>
									{supplierItem && supplierItem.supplier.name}
								</div>
							</div>
							<div className={classes.subtitle}>
								<div className={classes.headerTextSubtitle}>
									LOT : <span className={classes.bold}>{lot.lotNumber}</span>
								</div>
								<div className={classes.headerTextSubtitle}>
									DLC : <span className={classes.bold}>{lot.dlc && moment(lot.dlc).format("DD/MM/YYYY")}</span>
								</div>
								<div className={classes.headerTextSubtitle}>
									{lot.receptionDate && <span>Reçu le : <span className={classes.bold}>{moment(lot.receptionDate).format("DD/MM/YYYY")}</span></span>}
								</div>
							</div>
						</div>
						{
							!showEditLot && mode === LOT_OUTPUT_MODE &&
							<div className={isAllZones ? classes.flexForAllZones : null}>
								<div className={classes.headerText}>
									Quantité disponible
								</div>
								<div className={classes.quantity}>
									{lot.quantity}
								</div>
								<div className={classes.stockUnit}>
									{generateOrderUnitLabel(lot.orderSupplierItem)}
								</div>
								{
									lot.orderSupplierItem && lot.orderSupplierItem &&
									<div className={classes.unit}>
										= {stockWeight * lot.quantity} kg
									</div>
								}
							</div>
						}
						{!isAllZones &&
							(
								<div>
									{
										mode === LOT_OUTPUT_MODE &&
										<div className={classes.headerText}>
											Quantité sortie
										</div>

									}
									<TextField
										variant="standard"
										inputProps={{
											className: classes.input,
											placeholder: placeHolderValue,
											type: "number",
											pattern: "[0-9]*",
										}}
										className={classes.inputWrapper}
										value={inputValue}
										onChange={enableInput}
										inputRef={inputRef}
										type="number"
									/>
									{
										lot.orderSupplierItem && lot.orderSupplierItem &&
										<>
											<div className={classes.stockUnit}>
												{generateOrderUnitLabel(lot.orderSupplierItem)}
											</div>
											{mode === LOT_OUTPUT_MODE ?
												<div className={classes.unit}>
													= {outputValue ? stockWeight * outputValue : stockWeight * lot.quantityOutput} kg
												</div>
												:
												<div className={classes.unit}>
													= {outputValue ? stockWeight * outputValue : stockWeight * lot.quantity} kg
												</div>
											}
										</>
									}
								</div>
							)
						}

						{!isAllZones &&
							(
								<div className={classes.buttons}>
									<Button
										className={clsx(classes.button, disabled && mode !== LOT_INVENTORY_MODE ? "disabled" : "")}
										variant="contained"
										color="primary"
										disableElevation
										disabled={mode !== LOT_INVENTORY_MODE && !inputValue}
										type="submit"
										onClick={handleValidate}
									>
										CONFIRMER
									</Button>
									{mode === "INVENTORY" &&
										<Button
											className={classes.button}
											variant="outlined"
											color="primary"
											disableElevation
											type="submit"
											onClick={handleEdit}
										>
											ÉDITER CE LOT
										</Button>
									}
								</div>
	
							)
						}
					<ButtonFlaw rootClassName={classes.buttonFlaw} onSetFlaw={onShowLotDamagedReportForm} />
					</>
				}
			</div>
		</>
	)
}

export default ActionContent