import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import moment from "moment"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import DialogActions from "@mui/material/DialogActions"

import { getSiteInitialValues } from "../../actions/Utils/utils"
import { COLORS } from "../../utils"
import { SiteSchema } from "../../utils/yupValidators"

const useStyles = makeStyles({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        width: 424,
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14,
            lineHeight: "11px"
        },
        "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            color: COLORS.BLACK,
            height: 20,
            transform: "none",
            position: "relative",
            "&.single-label": {
                height: 30,
                display: "flex",
                alignItems: "center"
            }
        }
    },
    formControl50: {
        composes: "$formControl",
        width: 198
    },
    dialogTitle: {
        paddingLeft: 0
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    half: {
        width: 424
    },
    secondHalf: {
        composes: "$half",
        marginLeft: 20,
        marginTop: 100
    },
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15,
        marginTop: 70
    }
})

const SiteFormDialog = ({
    onClose,
    open,
    onSave,
    editing,
    site
}) => {
    const classes = useStyles()

    const handleSubmit = async (values) => {
        await onSave(values)
        onClose()
    }

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={getSiteInitialValues(site, editing)}
            validationSchema={SiteSchema}
            onSubmit={handleSubmit}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            className={classes.dialog}
                            maxWidth="md"
                        >
                            <DialogContent>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                >
                                    <div className={classes.half}>
                                        <DialogTitle
                                            id="customized-dialog-title"
                                            onClose={onClose}
                                            classes={{root: classes.dialogTitle}}>
                                            <span className={classes.dialogTitleLabel}>
                                                {
                                                    editing ? "EDITER" : "AJOUTER"
                                                } UN SITE
                                            </span>
                                        </DialogTitle>
                                        <FormControl variant="standard" className={classes.formControl}>
                                            <InputLabel>Nom du site</InputLabel>
                                            <TextField
                                                name="name"
                                                variant="standard"
                                                inputProps={{
                                                    placeHolder:"Nom du site"
                                                }}
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                errors.name?
                                                    (
                                                        <div className={classes.errorField}>{errors.name}</div>
                                                    )
                                                    : null
                                            }
                                        </FormControl>
                                        <FormControl variant="standard" className={classes.formControl}>
                                            <InputLabel>Adresse de livraison</InputLabel>
                                            <TextField
                                                name="deliveryAddress[line1]"
                                                variant="standard"
                                                inputProps={{
                                                    placeHolder:"Zone Industrielle des Marais, 7 rue de la Scierie"
                                                }}
                                                value={values.deliveryAddress.line1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                errors.deliveryAddress && errors.deliveryAddress.line1 ?
                                                    (
                                                        <div className={classes.errorField}>{errors.deliveryAddress.line1}</div>
                                                    )
                                                    : null
                                            }
                                        </FormControl>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel>Complément d' adresse</InputLabel>
                                                <TextField
                                                    name="deliveryAddress[line2]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Etage, interphone (_optionnel)"
                                                    }}
                                                    value={values.deliveryAddress.line2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.deliveryAddress && errors.deliveryAddress.line2 ?
                                                        (
                                                            <div className={classes.errorField}>{errors.deliveryAddress.line2}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel></InputLabel>
                                                <TextField
                                                    name="deliveryAddress[codeAccess]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Code d' accès (_optionnel)"
                                                    }}
                                                    value={values.deliveryAddress.codeAccess}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.deliveryAddress && errors.deliveryAddress.codeAccess ?
                                                        (
                                                            <div className={classes.errorField}>{errors.deliveryAddress.codeAccess}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Code postal</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="deliveryAddress[zipCode]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"7500"
                                                    }}
                                                    value={values.deliveryAddress.zipCode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.deliveryAddress && errors.deliveryAddress.zipCode ?
                                                        (
                                                            <div className={classes.errorField}>{errors.deliveryAddress.zipCode}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Ville</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="deliveryAddress[city]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Paris"
                                                    }}
                                                    value={values.deliveryAddress.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.deliveryAddress && errors.deliveryAddress.city ?
                                                        (
                                                            <div className={classes.errorField}>{errors.deliveryAddress.city}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Pays</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="deliveryAddress[country]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"France"
                                                    }}
                                                    value={values.deliveryAddress.country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.deliveryAddress && errors.deliveryAddress.country ?
                                                        (
                                                            <div className={classes.errorField}>{errors.deliveryAddress.country}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                    </div>
                                    <Divider className={classes.divider} orientation="vertical" flexItem />
                                    <div className={classes.secondHalf}>
                                        <FormControl variant="standard" className={classes.formControl}>
                                        </FormControl>
                                        <FormControl variant="standard" className={classes.formControl}>
                                            <InputLabel>Adresse de facturation</InputLabel>
                                            <TextField
                                                name="invoiceAddress[line1]"
                                                variant="standard"
                                                inputProps={{
                                                    placeHolder:"Zone Industrielle des Marais, 7 rue de la Scierie"
                                                }}
                                                value={values.invoiceAddress.line1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {
                                                errors.invoiceAddress && errors.invoiceAddress.line1 ?
                                                    (
                                                        <div className={classes.errorField}>{errors.invoiceAddress.line1}</div>
                                                    )
                                                    : null
                                            }
                                        </FormControl>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel>Complément d' adresse</InputLabel>
                                                <TextField
                                                    name="invoiceAddress[line2]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Etage, interphone (_optionnel)"
                                                    }}
                                                    value={values.invoiceAddress.line2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.invoiceAddress && errors.invoiceAddress.line2 ?
                                                        (
                                                            <div className={classes.errorField}>{errors.invoiceAddress.line2}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel></InputLabel>
                                                <TextField
                                                    name="invoiceAddress[codeAccess]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Code d' accès (_optionnel)"
                                                    }}
                                                    value={values.invoiceAddress.codeAccess}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.invoiceAddress && errors.invoiceAddress.codeAccess ?
                                                        (
                                                            <div className={classes.errorField}>{errors.invoiceAddress.codeAccess}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Code postal</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="invoiceAddress[zipCode]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"7500"
                                                    }}
                                                    value={values.invoiceAddress.zipCode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.invoiceAddress && errors.invoiceAddress.zipCode ?
                                                        (
                                                            <div className={classes.errorField}>{errors.invoiceAddress.zipCode}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Ville</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="invoiceAddress[city]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"Paris"
                                                    }}
                                                    value={values.invoiceAddress.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.invoiceAddress && errors.invoiceAddress.city ?
                                                        (
                                                            <div className={classes.errorField}>{errors.invoiceAddress.city}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <InputLabel className="single-label">Pays</InputLabel>
                                            </FormControl>
                                            <FormControl variant="standard" className={classes.formControl50}>
                                                <TextField
                                                    name="invoiceAddress[country]"
                                                    variant="standard"
                                                    inputProps={{
                                                        placeHolder:"France"
                                                    }}
                                                    value={values.invoiceAddress.country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {
                                                    errors.invoiceAddress && errors.invoiceAddress.country ?
                                                        (
                                                            <div className={classes.errorField}>{errors.invoiceAddress.country}</div>
                                                        )
                                                        : null
                                                }
                                            </FormControl>
                                        </Grid>
                                        <DialogActions classes={{root: classes.rootDialogActions}}>
                                            <Button
                                                className={classes.button1}
                                                onClick={onClose}
                                            >
                                                ANNULER
                                            </Button>
                                            <Button
                                                className={classes.button2}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                ENREGISTRER
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SiteFormDialog
