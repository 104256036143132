import React from "react"
import withStyles from "@mui/styles/withStyles"
import { fieldLabels } from "../../../../utils/dispatchPriority"
import { COLORS } from "../../../../utils"

const styles = {
    container: {
        marginBottom: 10,
        position: "relative",
        display: "inline-flex",
        padding: "5px 10px",
        backgroundColor: COLORS.WHITE,
        width: "100%"
    },
    nameContainer: {
        width: "16%",
        textAlign: "center"
    },
    fieldContainer: {
        width: "12%",
        textAlign: "center"
    },
    priorityContainer: {
        width: "4%",
        textAlign: "center"
    },

}

export const DistributionCenterPriorityListTitle = ({classes}) => {

    return (
        <div className={classes.container}>
            <div className={classes.priorityContainer}>
                Priorité
            </div>
            <div className={classes.nameContainer}>
                Nom
            </div>
            {
                fieldLabels.map(elem => {
                    return (
                        <div className={classes.fieldContainer} key={elem.label}>
                            {elem.label}
                        </div>
                    )
                })
            }
        </div>
    )
}

const DistributionCenterPriorityCardTitleWithStyles = withStyles(styles)(DistributionCenterPriorityListTitle)

export default DistributionCenterPriorityCardTitleWithStyles
