import { getData } from "../.."

const defaultSate = {
    commercialNamesList: [],
    commercialNamesOptions: [],
    familiesIngredients: [],
    groupsIngredients: [],
    commercialNameSnackBar: { open: false, type: "", message: "", duration: 0 },
}

export default function (state = defaultSate, action) {
    switch (action && action.type) {
        case "COMMERCIAL_NAME_LOADED":
            return {
                ...state,
                commercialNamesList: action.commercialNamesList,
            }
        case "COMMERCIAL_NAMES_OPTIONS_LOADED":
            return {
                ...state,
                commercialNamesOptions: action.commercialNamesOptions,
            }
        case "FAMILIES_INGREDIENTS_LOADED":
            return {
                ...state,
                familiesIngredients: action.familiesIngredients
            }
        case "GROUPS_INGREDIENTS_LOADED":
            return {
                ...state,
                groupsIngredients: action.groupsIngredients
            }
        case "SYNCHRO_COMMERCIAL_NAME_ETIQUETTABLE":
            return {
                ...state,
                commercialNameSnackBar: action.commercialNameSnackBar
            }
        case "CLOSE_COMMERCIAL_NAME_SNACKBAR":
            return {
                ...state,
                commercialNameSnackBar: action.commercialNameSnackBar
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getCommercialName(state, id) {
    return state.commercialNames.commercialNamesList.find(commercialName => commercialName.objectId === id)
}

export function getCommercialNamesOptions(state, errorIfNotFound = false) {
    return getData(state, "commercialNames.commercialNamesOptions", errorIfNotFound && "No commercial names options loaded")
}

export function getFamiliesIngredients(state, errorIfNotFound = false) {
    return getData(state, "commercialNames.familiesIngredients", errorIfNotFound && "No families ingredients loaded")
}

export function getGroupsIngredients(state, errorIfNotFound = false) {
    return getData(state, "commercialNames.groupsIngredients", errorIfNotFound && "No groups ingredients loaded")
}