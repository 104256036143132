import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import { TextField } from "@mui/material"
import { Popover, Button, Stack } from "@mui/material"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import dayjs from "dayjs"
import CustomReactDayPicker from "../Calendar/CustomReactDayPicker"

const useStyles = makeStyles({
  popover: {
    transform: "translateX(-115px)"
  },
  input: {
    fontSize: "16px",
    paddingBottom: 8
  }
})


const FormikReactDayPickerField = ({
  handleChange,
  date,
  popoverClassname,
  label = "label",
  hasError = false,
  errorText = null,
  maxWidth,
  toTimestamp = true
}) => {

    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
  
    const handleOpen = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
  
    return (
      <Stack direction="column" alignItems="center">
        <TextField
          error={hasError}
          helperText={errorText}
          label={label}
          variant={"standard"}
          value={dayjs(date).format("DD/MM/YYYY")}
          readOnly
          clearable={false}
          sx={{
            cursor: "none",
            caretColor: "transparent",
            maxWidth: maxWidth || "unset"
          }}
          InputProps={{
            endAdornment: (
              <Button
                aria-describedby={"popover-calendar"}
                onClick={handleOpen}
                sx={{
                  padding: 0,
                  minWidth: "fit-content"
                }}
              >
                <InsertInvitationIcon size={24} sx={{ color: "#0000008a" }} />
              </Button>
            ),
            inputProps: {
              className: classes.input
            }
          }}
        ></TextField>
        <Popover
          id={"popover-calendar"}
          open={Boolean(open)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          classes={{ root: popoverClassname || classes.popover }}
        >
          <CustomReactDayPicker
            selectedDate={new Date(date)}
            onSelectDate={(value) => {
              const formattedValue = toTimestamp ? dayjs(value).valueOf() : dayjs(value)
              handleChange(formattedValue)
              handleClose()
            }}
          />
        </Popover>
      </Stack>
    )
}

export default FormikReactDayPickerField