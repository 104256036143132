import { actionWithLoader, getReplacingPath, onEnter, push } from "../../Utils/utils"
import { getSuppliers } from "../../../parseManager/products/resources/supplier/parseSupplierManager"
import { getFamiliesIngredients } from "../../../parseManager/ingredients/classifications/family/parseFamilyManager"
import { getGroupsIngredients } from "../../../parseManager/ingredients/classifications/group/parseGroupManager"
import { deleteSubcontractorCommercialNameWithId, createASubcontractorCommercialName, getSubcontractorIngredientWithId, getSubcontractorIngredients } from "../../../parseManager/ingredients/external/parseSubcontractorCommercialNameManager"
import { getServerUrl } from "../../../utils"
import { axiosCall } from "../../../utils/axiosUtils"

export function loadSubContractorIngredient(ingredientId) {
    return async dispatch => {
        if (ingredientId) {
            const ingredient = await getSubcontractorIngredientWithId(ingredientId, ["subcontractor", "group.family", "allergens"], true)
            dispatch({
                type: "INGREDIENT_LOADED",
                ingredient
            })
        }
    }
}

export function loadSubcontractorIngredients() {
    return actionWithLoader(async (dispatch) => {
        const [subcontractorIngredients, suppliers] = await Promise.all([
            getSubcontractorIngredients({
                includes: ["group.family"],
                selects: ["name", "supplier.name", "group.name", "group.family.name"],
                sortBy: "updatedAt",
                sortDirection: "desc"
            }),
            getSuppliers({
                selects: ["name"],
                sortBy: "name",
                sortDirection: "asc",
                toJSON: true
            }),
            dispatch(loadFamiliesIngredients()),
            dispatch(loadGroupsIngredients())
        ])

        dispatch({
            type: "SUPPLIERS_COMMERCIAL_NAME_LOADED",
            suppliers,
            subcontractorsCommercialNamesList: subcontractorIngredients
        })
    })
}

export function loadSuppliers() {
    return async dispatch => {
        const suppliers = await getSuppliers({ toJSON: true })
        dispatch({
            type: "SUPPLIERS_LOADED",
            suppliers
        })
    }
}

export function loadFamiliesIngredients() {
    return actionWithLoader(async (dispatch) => {
        const familiesIngredients = await getFamiliesIngredients()

        dispatch({
            type: "FAMILIES_INGREDIENTS_LOADED",
            familiesIngredients
        })
    })
}

export function loadGroupsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const groupsIngredients = await getGroupsIngredients(["family"])

        dispatch({
            type: "GROUPS_INGREDIENTS_LOADED",
            groupsIngredients
        })
    })
}

export function createSubcontractorCommercialName(values, dispatch, { subcontractorCommercialName, location }) {
    return actionWithLoader(async (dispatch) => {
        const newSubcontractorCommercialName = await createASubcontractorCommercialName(values, subcontractorCommercialName)
        dispatch(loadSubcontractorIngredients())

        if (location.state && location.state.returnPath === "edit") {
            dispatch(showSubcontractorCommercialName(newSubcontractorCommercialName.objectId))
        }
        else {
            dispatch(showExternalCommercialNames())
        }
    })
}

export function extractSubcontractorProductsWithCommercialNameId(id) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/subcontractorsCommercialNames/${id}/_extractSubcontractorProducts`
            const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" }, "blob")
            const csvUrl = window.URL.createObjectURL(new Blob([result.data]))
            const link = document.createElement("a")
            const fileName = "Export-" + id + "-PST.csv"
            link.href = csvUrl
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()

            dispatch({
                type: "EXTRACT_SUPPLIER_PRODUCTS_WITH_COMMERCIAL_NAME_ID",
                subcontractorsCommercialNamesSnackBar: { open: true, type: "success", message: `La liste des produits sous traitants contentant l'ingrédient ${id} a été exportée.`, duration: 5000 }
            })
        } catch (err) {
            return dispatch({
                type: "EXTRACT_SUPPLIER_PRODUCTS_WITH_COMMERCIAL_NAME_ID_ERROR",
                subcontractorsCommercialNamesSnackBar: { open: true, type: "error", message: `L'extraction des produits sous traitants contenant l'ingredient sous traitant avec l'id ${id} a échoué.`, duration: 5000 }
            })
        }
    })
}

export function closeSubcontractorsCommercialNamesSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return await dispatch({
            type: "CLOSE_SUBCONTRACTORS_COMMERCIAL_NAMES_SNACKBAR",
            subcontractorsCommercialNamesSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function deleteSubcontractorCommercialName(id) {
    return actionWithLoader(async (dispatch) => {
        await deleteSubcontractorCommercialNameWithId(id)

        dispatch(loadSubcontractorIngredients())
    })
}

export function onEnterSubcontractorsCommercialNames(store) {
    return onEnter({
        store,
        actionThunk: loadSubcontractorIngredients,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSingleSubcontractorCommercialName(store) {
    return async (nextState, replace, callback) => {
        await Promise.all([
            store.dispatch(loadSubContractorIngredient(nextState.params?.id)),
            store.dispatch(loadSuppliers()),
            store.dispatch(loadFamiliesIngredients()),
            store.dispatch(loadGroupsIngredients())
        ])

        callback()
    }
}

/** Routers */
export function showExternalCommercialNames() {
    return push("/ingredients/externals")
}

export function showSubcontractorCommercialName(id, location) {
    if (id) {
        return push(`/ingredients/external/${id}`, location)
    }
}

export function showSubcontractorCommercialNameForm(id, location) {
    if (id) {
        return push(`/ingredients/external/${id}/edit`, location)
    } else {
        return push("/ingredients/external/", "/ingredients/externals/")
    }
}
