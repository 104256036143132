import Parse from "parse"
import moment from "moment"

const Locker = Parse.Object.extend("LockerStock")
const ProductStock = Parse.Object.extend("ProductsStock")

export async function createOrLockStockLocker(idProductStock) {
		try {
			let locker = await new Parse.Query("LockerStock")
				.equalTo("productStock", ProductStock.createWithoutData(idProductStock))
				.equalTo("user", Parse.User.current())
				.equalTo("isOver", true)
				.descending("createdAt")
				.first()

			if (locker) {
				locker.set("isOver", false)
			} else {
				locker = new Locker()

				locker.set("isOver", false)
				locker.set("type", "reception")
				locker.set("productStock", ProductStock.createWithoutData(idProductStock))
				locker.set("user", Parse.User.current())
			}

			locker.set("startedAt", moment().valueOf())
				await locker.save()
		}
		catch (e) {
				return Promise.reject(e)
		}
}