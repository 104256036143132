import React from "react"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { COLORS } from "../../../utils"


const styles = theme => ({
    root: {
        display: "flex"
    },
    input: {
        flex: 1,
        boxSizing: "border-box",
        font: "inherit",
        fontFamily: "inherit",
        lineHeight: "25px",
        padding: "0 15px",
        fontSize: "13px",
        width: "100%",
        transition: "all .3s",
        borderRadius: 0,
        height: "48px",
        border: 0,
        position: "relative",
        zIndex: 1,
        margin: 0,
        paddingRight: "45px",
        backgroundColor: COLORS.WHITE
    },

    bar: {
        height: "100px"
    },
    iconButton: {
        padding: 10,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
})

const  SearchInput = (props) => {
    const {
        classes,
        field,
        placeholder,
        value,
        onInputChange
    } = props

    return (
        <Paper className={classes.root}>
            <InputBase
                name={field}
                className={classes.input}
                placeholder={placeholder}
                onChange={onInputChange}
                value={value}
            />
            <IconButton className={classes.iconButton} aria-label="search" size="large">
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}

export default withStyles(styles)(SearchInput)
