import React from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    modal: {
        position: "absolute",
        width: 438,
        minHeight: 183,
        background: COLORS.WHITE,
        textAlign: "justify",
        color: COLORS.BLACK,
        padding: "40px 30px",
        fontSize: 16,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 15,
        marginBottom: 10
    },
    title: {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "24px",
    },
    subtitle: {
        fontSize: 12,
        marginTop: 15
    },
    backButton: {
        marginRight: 10
    }
})

const ClosingModal = (props) => {
    const {
        onBack,
        onClose,
        open
    } = props

    const classes = useStyles()

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.modal}>
                <div className={classes.title}>
                    Voulez-vous clôturer cette réception ?
                </div>
                <div className={classes.subtitle}>
                    Après la clôture, vous ne pourrez plus modifier votre réception.
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        onClick={onBack}
                        variant="outlined"
                        className={classes.backButton}
                    >
                        Retour
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={onClose}
                        variant="contained"
                    >
                        Clôturer
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ClosingModal