import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"
import { getPointerFamilyIngredientsById } from "../family/parseFamilyManager"

const GroupsIngredients = Parse.Object.extend("GroupsIngredients")

export async function getGroupsIngredients(includes = [], select = []) {
    try {
        const query = new Parse.Query(GroupsIngredients)
            .ascending("name")
            .include(includes)

        if (select.length) {
            query.select(select)
        }


        const groupsIngredients = await query
            .limit(parseLimitRequest)
            .find()

        return groupsIngredients ? groupsIngredients.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.group.parseGroupManager.getGroupsIngredients error : ", e)
        return Promise.reject(e)
    }
}

export async function getGroupIngredientWithId(id, includes = []) {
    const group = (await new Parse.Query(GroupsIngredients)
        .equalTo("objectId", id)
        .include(includes)
        .first()) || null

    return group
}

export async function createGroupIngredients(groupIngredient, values) {
    try {
        let parseGroupIngredient
        const keyToIgnore = ["family"]

        if (!groupIngredient) {
            parseGroupIngredient = new GroupsIngredients()
        }
        else {
            parseGroupIngredient = await new Parse.Query(GroupsIngredients)
                .equalTo("objectId", groupIngredient.objectId)
                .first()
        }

        if (parseGroupIngredient) {
            /* Family Ingredient */
            if (values.family) {
                const currentFamilyIngredient = await getPointerFamilyIngredientsById(values.family)

                parseGroupIngredient.set("family", currentFamilyIngredient)
            }

            Object.keys(values).forEach(function (key) {
                const val = values[key]
                if (!keyToIgnore.includes(key) && val !== null) {
                    parseGroupIngredient.set(key, val)
                }
            })

            await parseGroupIngredient.save()

            return parseGroupIngredient.toJSON()
        }
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.group.parseGroupManager.createGroupIngredients error : ", e)
        return Promise.reject(e)
    }
}
