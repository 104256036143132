import React from "react"
import { Stack } from "@mui/material"

import { StyledCard, StyledCardLabel, StyledCardTitle, StyledCardTitleContainer } from "../../../../components/styled/StyledPreview"
import { convertKilosIntoGrams } from "../../../../utils/ressources"
import { useState } from "react"
import NewEditIcon from "../../../../components/NewEditIcon"
import PackagingLogisticsFormDialog from "./forms/PackagingLogisticsFormDialog"

const PackagingLogistics = ({ packaging, onSubmit }) => {
  const [openModalForm, setOpenModalForm] = useState(false)

  const toggleEditTransformationMode = () => setOpenModalForm(!openModalForm)
  const handleCloseModalForm = () => setOpenModalForm(false)

  const handleSubmitResume = async (values) => {
    await onSubmit(values)
    toggleEditTransformationMode()
  }

  return (
    <StyledCard>
      <Stack spacing={3}>
        <StyledCardTitleContainer>
          <StyledCardTitle>Logistique</StyledCardTitle>
          <NewEditIcon onClick={toggleEditTransformationMode} />

        </StyledCardTitleContainer>
        <Stack spacing={2}>
          <div className="flexRow">
            <StyledCardLabel>Poids</StyledCardLabel>
            <span>{convertKilosIntoGrams(+packaging.weight)} g</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Hauteur</StyledCardLabel>
            <span>{packaging.height} mm</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Profondeur</StyledCardLabel>
            <span>{packaging.deepth} mm</span>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Largeur</StyledCardLabel>
            <span>{packaging.width} mm</span>
          </div>
        </Stack>
      </Stack>

      <PackagingLogisticsFormDialog
        open={openModalForm}
        onClose={handleCloseModalForm}
        packaging={packaging}
        onSave={handleSubmitResume}
      />
    </StyledCard>
  )
}

export default PackagingLogistics
