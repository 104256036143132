import React, { useRef, useState, useLayoutEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"

import PropTypes from "prop-types"

import { Box, Fab, Stack, TextField, Typography, styled, Snackbar } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import { useDispatch } from "react-redux"
import { Formik, Field, Form, FieldArray } from "formik"
import AddIcon from "@mui/icons-material/Add"
import MySnackbarContentWrapper from "../../../../components/MySnackbarContentWrapper"

import EditionPageHeader from "../../../../components/EditionPageHeader"
import { COLORS } from "../../../../utils"
import { showProductionSchemas, closeProductionSchemaSnackbar, updateCellWidth } from "../../../../actions/ProductionSchemas/ProductionSchemas"
import { PRODUCTION_SCHEMA_BORDER_COLOR, getProductionSchemasInitialValues, productionSchemaInitialValues } from "../../../../utils/productionSchemasUtils"
import { saveProductionSchemas } from "../../../../actions/ProductionSchemas/ProductionSchemas"
import { productionSchemasSchema } from "../../../../utils/productionSchemaValidations"
import FormikErrorMessage from "../../../../components/FormikErrorMessage"
import ProductionSchemasFormHeader from "./ProductionSchemasFormHeader"
import ProductionSchemaBatchesField from "./ProductionSchemaBatchesField"
import ProductionSchemaBatchesPreview from "./ProductionSchemaBatchesPreview"
import { getProductionSchemaSnackbarSelector, getProductionSchemasSelector, getProductionSchemaCellWidth } from "../../../../reducers/ProductionSchemas/productionSchemas"


const useStyles = makeStyles(() => ({
  form: {
    composes: "flexColumn stretchSelf flex1",
    margin: 24
  }
}))

const StyledTextFieldName = styled(TextField)({
  minWidth: 240,
  "& .MuiInputBase-root": {
    height: "100%"
  },
  "& .MuiInputBase-root, .MuiAutocomplete-inputRoot.MuiInputBase-root": {
    height: "100%",
  },
  "& .MuiInput-input, .MuiAutocomplete-input": {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5
  }
})

const FormikTextField = ({ field, ...props }) => (
  <StyledTextFieldName {...field} {...props} />
)

const StyledFormRow = styled("div")({
  borderRadius: 6,
  border: "1px solid " + PRODUCTION_SCHEMA_BORDER_COLOR,
  background: "#FFF"
})

const StyledFormRowHeader = styled("div")({
  backgroundColor: "#F0F0F0",
  padding: 16
})

const ProductionSchemasReadOrEdit = ({ isEdition = true, isMenuOpened = true }) => {
  const classes = useStyles()

  const productionSchemas = useSelector(getProductionSchemasSelector)
  const productionSchemaSnackbar = useSelector(getProductionSchemaSnackbarSelector)
    const cellWidth = useSelector(getProductionSchemaCellWidth)

  const [hoveredBatchRowIndex, setHoveredBatchRowIndex] = useState(-1)
  const [hoveredBatchRowParentIndex, setHoveredBatchRowParentIndex] = useState(-1)

  const formikRef = useRef()
  const dispatch = useDispatch()

  const handleConfirm = () => {
    if (!formikRef.current) return
    formikRef.current.submitForm()
  }

  const handleCancel = () => dispatch(showProductionSchemas())

  const handleSubmit = async (values) => dispatch(saveProductionSchemas(values.productionSchemas))

  const _closeSnackbar = (snackbarType) => dispatch(closeProductionSchemaSnackbar(snackbarType))

  // store cell width for form components
  const _updateCellWidth = useCallback(() => {
    dispatch(updateCellWidth(isEdition))
  }, [dispatch])

  useLayoutEffect(() => {
    _updateCellWidth()
    window.addEventListener("resize", _updateCellWidth)
    return () => window.removeEventListener("resize", _updateCellWidth)
  }, [isEdition, isMenuOpened])


  if (!isEdition && (!productionSchemas || !productionSchemas.length)) return null

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" position="relative">
      {/* header */}
      {isEdition && <EditionPageHeader
        icon={<SettingsIcon />}
        onCancel={handleCancel}
        onSave={handleConfirm}
        title="Réglages"
        color={COLORS.HEAD_SEAZON}
      />}

      <Formik
        initialValues={getProductionSchemasInitialValues(productionSchemas)}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={productionSchemasSchema}
      >
        {({ values }) => {
          return (
            <Form className={classes.form} id="productionSchemaForm">
              <FieldArray name="productionSchemas">
                {({ insert }) => (
                  <div className="flexColumn stretchSelf flex1">
                    <Stack spacing={3} className="stretchSelf">
                      {values.productionSchemas.length &&
                        values.productionSchemas.map((_, index) => (
                          <StyledFormRow
                            key={index}
                            className="flexColumn justifyCenter"
                          >
                            <StyledFormRowHeader className="flexCenter stretchSelf">
                              {isEdition ? (
                                <Stack spacing={1}>
                                  <Field
                                    name={`productionSchemas.${index}.name`}
                                    component={FormikTextField}
                                    placeholder="Name"
                                    variant="standard"
                                    sx={{ minWidth: 240 }}
                                  />
                                  <FormikErrorMessage
                                    name={`productionSchemas.${index}.name`}
                                  />
                                </Stack>
                              ) : (
                                <Stack spacing={1}>
                                  <Typography variant="subtitle2">{values.productionSchemas[index].name}</Typography>
                                </Stack>
                              )}
                            </StyledFormRowHeader>
                            {/* header */}
                            <ProductionSchemasFormHeader 
                            {...{isEdition, cellWidth}}
                            />
                            <div className="stretchSelf">
                              {/* batches inputs row edition mode */}
                              {isEdition
                                ? (
                                  <ProductionSchemaBatchesField
                                    batches={values.productionSchemas[index].batches}
                                    parentIndex={index}
                                    name={`productionSchemas.${index}.batches`}
                                    hoveredBatchIndex={hoveredBatchRowIndex}
                                    hoveredBatchParentIndex={hoveredBatchRowParentIndex}
                                    interactions={{
                                      onHover: (newParentIndex, newIndex) => {
                                        setHoveredBatchRowParentIndex(newParentIndex)
                                        setHoveredBatchRowIndex(newIndex)
                                      },
                                      onCancelHover: () => {
                                        setHoveredBatchRowParentIndex(-1)
                                        setHoveredBatchRowIndex(-1)
                                      }
                                    }}
                                  />
                                ) : (
                                  // batches inputs row consultation mode
                                  <ProductionSchemaBatchesPreview
                                    batches={values.productionSchemas[index].batches}
                                  />
                                )
                              }
                            </div>
                          </StyledFormRow>
                        ))}
                      <div>
                        <FormikErrorMessage name="productionSchemas" />
                      </div>
                    </Stack>
                    {/* --------- add button --------- */}
                    {isEdition && (
                      <Box sx={{ position: "fixed", left: 260, bottom: 20 }}>
                        <Fab
                          color="primary"
                          onClick={() =>
                            insert(
                              values.productionSchemas.length,
                              productionSchemaInitialValues
                            )
                          }
                        >
                          <AddIcon />
                        </Fab>
                      </Box>
                    )}
                  </div>
                )}
              </FieldArray>
            </Form>
          )
        }}
      </Formik>
      {/* --------- error snackbar--------- */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={productionSchemaSnackbar.open}
        autoHideDuration={productionSchemaSnackbar.duration}
        onClose={() => _closeSnackbar(productionSchemaSnackbar.type)}
      >
        <MySnackbarContentWrapper
          onClose={() => _closeSnackbar(productionSchemaSnackbar.type)}
          variant={productionSchemaSnackbar.type}
          message={productionSchemaSnackbar.message}
        />
      </Snackbar>
    </Box>
  )
}

ProductionSchemasReadOrEdit.propTypes = {
  isEdition: PropTypes.bool // if true, form in edition mode, otherwise list of existing productionSchemas
}

export default ProductionSchemasReadOrEdit