import Parse from "parse"

import { actionWithLoader } from "../Utils/utils"
import { getServerUrl } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import { SUCY_ID } from "../../parseManager/site/parseSiteManager"
import { showPlanningSell } from "./Planning"
import { getProductionSchemaById } from "../../parseManager/productionSchema/parseProductionSchemasManager"
import { getPlanningDateByProductionSchemaDate } from "../../utils/planning"

export function createProductionItem(saleDate, productionDate, packagingDate, product, itemId, brand) {
    return actionWithLoader(async () => {
        try {
            const productImages = product.image
            const price = product.price
            const dlcBrand = product.dlcBrand
            const name = product.name

            const formatProduct = {
                itemId: itemId,
                itemType: product.itemType,
                createdAt: product.createdAt,
                updatedAt: product.updatedAt,
                name: name,
                commercialName: product.commercialName,
                brand: brand,
                uniqueCode: product.uniqueCode,
                itemBrand: product.itemBrand,
                productType: product.productType,
                internalTag: product.internalTag,
                image: Array.isArray(productImages) && productImages.length > 0 ? productImages[0] : productImages,
                rating: product.rating,
                nutriscore: (product.nutritionInformation && product.nutritionInformation.nutriscore) || null,
                subcontractor: product.itemType === "SubcontractorProduct" && product.subcontractor ? product.subcontractor.name : null,
                price: price,
                foodcost: product.foodcost || product.totalCost,
                dlcBrand: dlcBrand,
                productionDate: productionDate,
                packagingDate: packagingDate,
                saleDate: saleDate,
                season: product.season,
                nationalSend: true,
                sendCapital: true,
                smallRetail: true,
                lunchbag: false
            }

            const url = `${getServerUrl()}/mealPlanner/_createSingleProductionItem`
            const data = {
                saleDate,
                product: formatProduct,
                brand,
                user: Parse.User.current() ? Parse.User.current().get("email") : null,
                siteId: SUCY_ID
            }

            await axiosCall("POST", url, data, { "Content-Type": "application/json" })
        } catch (e) {
            console.log("error on creating production items : ", e)
        }
    })
}

export const createProductionItemsByProductionSchemas = (selectionBySections, selectedDate) => {
    return actionWithLoader(async (dispatch) => {
        for (const [productionSchemaId, products] of selectionBySections.entries()) {
            const schema = await getProductionSchemaById(productionSchemaId)

            products.forEach(product => {
                schema.batches.forEach(batch => {
                    /** PRODUCTION DATE & PACKAGING DATE CALCULATION **/
                    const packagingDate = getPlanningDateByProductionSchemaDate(selectedDate, batch.packagingDay)
                    const productionDate = getPlanningDateByProductionSchemaDate(selectedDate, batch.productionDay)

                    batch.sellDays.forEach(sellDay => {
                        const brand = sellDay.brand
                        
                        sellDay.days.forEach(day => {
                            const saleDate = getPlanningDateByProductionSchemaDate(selectedDate, day)
                            dispatch(createProductionItem(saleDate, productionDate, packagingDate, product, product.itemId, brand))
                        })
                    })
                })
            })
        }

        dispatch(redirectToPlanningSell())
    })
}

export function redirectToPlanningSell() {
    return actionWithLoader(async (dispatch) => {
        setTimeout(() => {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: {
                    open: true,
                    duration: 5000,
                    type: "success",
                    message: "Les produits ont été ajoutés à la carte"
                }
            })
        }, 3000)
        dispatch(showPlanningSell())
    })
}