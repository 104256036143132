import React, { useCallback, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"
import DataGrid from "../../../components/DataGrid/DataGrid"

import { COLORS } from "../../../utils"
import { computeReceivedArticleWeight, computeReceptionDeliveryDelay } from "../../../utils/orderReception"

const useStyles = makeStyles({
	container: {
		flex: 1
	},
	row: {
		height: 72,
		backgroundColor: COLORS.WHITE
	},
	cell: {
		textAlign: "center"
	},
})

const OrderReceptionReceived = (props) => {
	const {
		orders,
		onOrderClick,
		supplierOptions,
		typeOptions,
		deliveryDelayOptions
	} = props

	const classes = useStyles()

	const [data, setData] = useState([])


	const columns = [
		{
			key: "orderNumber",
			label: "N° de commande",
			dataType: "text",
			filter: {
				filterable: true,
				type: "text",
				strict: true
			},
			sortable: false
		},
		{
			key: "blNumber",
			label: "N° de BL",
			sortable: false,
			dataType: "text",
			filter: {
				filterable: true,
				type: "text",
				strict: true
			}
		},
		{
			key: "supplier",
			label: "Fournisseur",
			dataType: "text",
			filter: {
				filterable: true,
				type: "select",
				withSearch: true,
				options: supplierOptions
			},
			sortable: false
		},
		{
			key: "expectedDeliveryDate",
			label: "Date de livraison attendue",
			sortable: false,
			dataType: "date",
			filter: {
				filterable: true,
				type: "date"
			}
		},
		{
			key: "receivedWeightPart",
			label: "Poids global réceptionné / commandé",
			sortable: false,
			dataType: "number",
			filter: {
				filterable: false,
				type: "number"
			},
			render: (value) => {
				const kiloLabel = "kg"
				return (
					<div><span style={{ color: "#55555" }}>{value}</span><span style={{ color: "#7C7C7C" }}>{" " + kiloLabel}</span></div>
				)
			}
		}
	]


	const additionalFilters = [
		{
			key: "type",
			label: "Type de produit",
			dataType: "text",
			filter: {
				filterable: true,
				type: "select",
				options: typeOptions
			},
		},
		{
			key: "deliveryDelay",
			label: "Respect de la date de livraison",
			dataType: "text",
			filter: {
				filterable: true,
				type: "select",
				options: deliveryDelayOptions
			},
		}
	]

	useEffect(() => {
		createDataObject()
	}, [orders])

	const createDataObject = useCallback(() => {
		setData(orders.map(createData))
	})

	const createData = (order) => {
		return {
			id: order.objectId,
			orderNumber: order.orderNumber,
			supplier: order.supplier && order.supplier.name ? order.supplier.name : "",
			expectedDeliveryDate: order.expectedDeliveryDate ? dayjs.utc(parseFloat(order.expectedDeliveryDate)).format("DD/MM/YY") : "",
			blNumber: order.deliveryNote && order.deliveryNote.number ? order.deliveryNote.number : "",
			receivedWeightPart: `${computeReceivedArticleWeight(order.supplierItems)} / ${order.totalWeight || 0}`,
			type: order.supplierItems.map(item => typeOptions.find(type => type.key === item.supplierItem.type)?.label).join(", "),
			deliveryDelay: computeReceptionDeliveryDelay(order)
		}
	}

	const handleOrderClick = (order) => {
		onOrderClick(order.id)
	}

	return (
		<div className={classes.container}>
			<DataGrid
				title="Réceptions en cours"
				columns={columns}
				data={data}
				withFilters
				additionalFilters={additionalFilters}
				rowLabel="réception"
				onRowClick={handleOrderClick}
			/>
		</div>
	)
}

export default OrderReceptionReceived