import React from "react"
import { Stack, Typography } from "@mui/material"
import PackagingExecutionStatus from "../PackagingExecutionStatus"
import { PE_STATUSES_AND_LABELS } from "../../../utils/packagingExecutions"
import { PEPSEResumeLabel, PEPSEResumeInfo, PEPSEResumeContainer } from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import { capitalizeFirstLetter } from "../../../utils"

const PackagingExecutionResume = ({ recipeLabel, status, packagingLine = "_", expectedPackagingNumber = 0, uniqueCode }) => {

	return (<PEPSEResumeContainer>
		<Stack spacing={4}>
			<Stack direction="row" alignItems="center" spacing={2.2}>
				<Typography variant="h6">
					{uniqueCode} - {recipeLabel}
				</Typography>
				<PackagingExecutionStatus
					statusValue={status}
					statusName={PE_STATUSES_AND_LABELS.find(item => item.key === status)?.label} />
			</Stack>
			<Stack spacing={5} direction="row">
				<Stack spacing={2}>
					<PEPSEResumeLabel>
						Ligne de barquettage
					</PEPSEResumeLabel>
					<PEPSEResumeInfo>
						{capitalizeFirstLetter(packagingLine)}
					</PEPSEResumeInfo>
				</Stack>
				<Stack spacing={2}>
					<PEPSEResumeLabel>
						Nb théorique de produits barquettés
					</PEPSEResumeLabel>
					<PEPSEResumeInfo>
						{expectedPackagingNumber}
					</PEPSEResumeInfo>
				</Stack>
			</Stack>
		</Stack>
	</PEPSEResumeContainer>)
}

export default PackagingExecutionResume