import React, { useState } from "react"

import { Box, Stack, Button } from "@mui/material"
import { ErrorMessage, Field } from "formik"

import { StyledErrorMessage, StyledProductionStepTextField, StyledStepBodyCell, StyledStepFirstBodyColumn, StyledStepText } from "../../../../../components/Recipe/ProductionSteps/StyledSectionComponents"
import RemoveColumn from "../../../../../components/Recipe/ProductionSteps/RemoveColumn"
import { COLORS, roundNumber } from "../../../../../utils"
import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../../../utils/constant"
import { convertGramsIntoKilos, convertKilosIntoGrams, formatValuesWithWeight } from "../../../../../utils/ressources"
import {
  getDefaultSteps,
  computeStepData,
  getDefaultReusableSteps,
  computeRecipeReusableStepWeights,
  getDefaultSectionProductionStep
} from "../../../../../utils/recipes"
import FormikErrorMessage from "../../../../../components/FormikErrorMessage"
import { computeProductionStepsNumbers } from "../../../../../actions/Utils/utils"

const widths = PRODUCTION_STEPS_COL_WIDTHS
export const COMPONENT_NAME = "STEP_PARENT"

const boxStyle = {
  bgcolor: COLORS.YELLOW_REUSABLE_STEP_PARENT,
  borderBottom: "1px solid #CCCCCC",
}

const stickyboxStyle = {
  ...boxStyle,
  position: "sticky",
  top: 60,
  zIndex: 100
}

const renderCost = (step, cost) => {
  if (cost) {
    return `${roundNumber(cost, 3)} €`
  }

  if (step?.cost) {
    return `${roundNumber(step.cost, 3)} €`
  }

  return "-"
}

const getNetWeightValue = (netWeight, isHovered) => {
  if (!netWeight) return 0
  // convert to gram only for display
  if (isHovered) return convertKilosIntoGrams(netWeight)
  return netWeight
}

const FormikTextField = ({ field, ...props }) => (
  <StyledProductionStepTextField {...field} {...props} />
)

const ReusableStepParent = ({
  step,
  steps,
  index,
  sectionIndex,
  onRowHover,
  onRowBlur,
  hoveredRow,
  setFieldValue,
  grossWeight,
  cost,
  updateSectionByReusableStepCoeff,
  updateSectionInReusableStepDeletion,
  isEdition = false,
  fromRecipe = false,
  stepNumbers
}) => {
  // we need to check manually if the input is empty
  // because if 0, result will be Infinity (number / 0)
  const [isEmptyNetWeight, setIsEmptyNetWeight] = useState(false)

  const _isHover = () => hoveredRow && COMPONENT_NAME === hoveredRow.component

  const _stopPropagation = (event) => event && event.stopPropagation()

  const _addStep = (event = null) => {
    const newSteps = [...steps]
    const defaultStep = fromRecipe ? getDefaultSectionProductionStep() : getDefaultSteps()
    newSteps.splice(index + 1, 0, defaultStep)
    // update production steps and step components data
    const newStep = newSteps[index + 1]

    if (newStep) {
      computeStepData(newStep, "stepComponents", fromRecipe)
    }

    computeProductionStepsNumbers(newSteps, stepNumbers, 1)
    // ------- recipe steps fields ------- //
    setFieldValue(`sections[${sectionIndex}].productionSteps`, newSteps)

    _stopPropagation(event)
  }

  const handleNetWeightChange = (event) => {
    // to integer
    const value = +event.target.value

    if (!value) {
      setFieldValue(`sections[${sectionIndex}].productionSteps[${index}].netWeight`, 0)
      setIsEmptyNetWeight(true)
      return
    }
  
    setIsEmptyNetWeight(false)
    const netWeightInput = convertGramsIntoKilos(value)
    let newStep = computeRecipeReusableStepWeights(step, netWeightInput)
    
    if (fromRecipe) {
      const { reusableStepTotalGrossWeight, cost, realCost } = formatValuesWithWeight(newStep)

      newStep = { ...newStep, grossWeight: reusableStepTotalGrossWeight, cost, realCost }

      const stepObj = {
        step: newStep,
        grossWeight: reusableStepTotalGrossWeight,
        cost,
        realCost,
        netWeight: newStep.netWeight,
        reusable: true
      }

      if (updateSectionByReusableStepCoeff) {
        // we update the production step to the section level
        // so that the (parent) section is updated too
        updateSectionByReusableStepCoeff(stepObj, index)
        // instead of:
        // setFieldValue(`sections[${sectionIndex}].productionSteps[${index}]`, stepObj)
      }
      return
    }

    setFieldValue(`productionSteps[${index}]`, newStep)
  }

  const _removeReusableStep = (event = null) => {
    const newSteps = [...steps]
    newSteps.splice(index, 1)
    if (!newSteps.length) {
      const defaultStep = fromRecipe ? getDefaultSectionProductionStep() : getDefaultReusableSteps()
      newSteps.splice(0, 0, defaultStep)      
    }

    if (updateSectionInReusableStepDeletion) {
      // we update the production step to the section level
      // so that the (parent) section is updated too
      updateSectionInReusableStepDeletion(newSteps)
      // instead of:
      // setFieldValue(`sections[${sectionIndex}].productionSteps[${index}]`, stepObj)
    }

    if (!event) return
    _stopPropagation(event)
  }

  const isRowHovered = fromRecipe && isEdition && _isHover()

  return (
    <Box
      onMouseEnter={() =>  onRowHover?.(COMPONENT_NAME)}
      onMouseLeave={onRowBlur}
      onClick={(e) => _stopPropagation(e)} // prevent accordion from opening except when clicking on the plus icon
      className="flexRow"
      sx={fromRecipe ? boxStyle : stickyboxStyle}
    >
      {/* ------------ name and description ------------ */}
      <StyledStepFirstBodyColumn
        className="flexColumn"
        bgcolor={COLORS.YELLOW_REUSABLE_STEP_PARENT}
        // pb={fromRecipe ? 15 : undefined}
      >
        {/* plus button */}
        {isRowHovered && (
          <Button onClick={(e) => _addStep(e)} className="flexCenter" sx={{ position: "absolute", left: -8 }}>
            <img alt="plus icon" src="/icons/plus-blue.svg" />
          </Button>
        )}
        {/* input */}
        <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
          <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
            <StyledStepText>{step?.name || "-"}</StyledStepText>
            <ErrorMessage
              name="name"
              render={(message) => (
                <StyledErrorMessage>{message}</StyledErrorMessage>
              )}
            />
          </Stack>
        </Stack>
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText>{roundNumber(convertKilosIntoGrams(grossWeight ?? step?.grossWeight), 3) || "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText>{renderCost(step, cost)}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ transformation ------------ */}
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        {/* the input is only for recipe */}
        <Stack>
          {isRowHovered
            ? (
                <Field
                  component={FormikTextField}
                  name={`sections[${sectionIndex}].productionSteps[${index}].netWeight`}
                  onClick={_stopPropagation}
                  value={isEmptyNetWeight ? 0 : getNetWeightValue(step.netWeight, isRowHovered)}
                  type="number"
                  onChange={handleNetWeightChange}
                  onWheel={(e) => e.target.blur()} // prevent increase and decrease on mouse wheel
                />
            )
            : (
              <StyledStepText>
                {roundNumber(convertKilosIntoGrams(step?.netWeight), 3) || isEmptyNetWeight || "-"}
              </StyledStepText>
            )}
            <FormikErrorMessage name={`sections[${sectionIndex}].productionSteps[${index}].netWeight`} />
        </Stack>
      </StyledStepBodyCell>
      {/* ------------ kitchenArea ------------ */}
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ machineType ------------ */}
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ machineSetting ------------ */}
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ stepDuration ------------ */}
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ stepDurationUnit ------------ */}
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ stepDurationUnit ------------ */}
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ endStepWeight ------------ */}
      <StyledStepBodyCell align="left" width={widths[13]}>
        <StyledStepText>-</StyledStepText>
      </StyledStepBodyCell>
      {/* -------- delete icon -------- */}
      <RemoveColumn
        type="reusableStepParent"
        isHover={fromRecipe && isEdition && _isHover()}
        onClick={(e) => _removeReusableStep(e)}
      />
    </Box>
  )
}

export default ReusableStepParent
