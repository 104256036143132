import React from "react"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
	unit: {
		margin: "3px 0",
		fontSize: 8,
		fontWeight: 400,
		textAlign: "right",
		textTransform: "upperCase",
		color: COLORS.SITE_INFORMATION_VALUE,
		"&.selected": {
			color: COLORS.WHITE
		},
		"&.updated": {
			color: COLORS.GREEN_LOT_INVENTORY_TEXT
		}
	}
})


const LotCardSubfooterContent = ({
	document,
	selected = false
}) => {

	const classes = useStyles()

	return (
		<p className={clsx(
			classes.unit,
			(document.isUpdated && !document.isGrey && !selected && !document.hypothetical) ? "updated" : "",
			selected ? "selected" : ""
		)}
		>
			{document.unit}
		</p>
	)

}

export default LotCardSubfooterContent