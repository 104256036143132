import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogTitle, DialogActions, DialogContent } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterDatesForm, { distributionCenterDatesFormId } from "./DistributionCenterDatesForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"
import moment from "moment"

const DistributionCenterDatesFormContainer = ({ distributionCenter, onClose, open }) => {

	const dispatch = useDispatch()

	const onSubmitOpenDateForm = (values) => {
		const openDateTimestamp = moment(values.openDate).startOf("day").valueOf()
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, { openDate: openDateTimestamp }, "openDate"))
		onClose && onClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}>
			<DialogTitle>
				Éditer la date d'inauguration
			</DialogTitle>
			<DialogContent>
				<DistributionCenterDatesForm
					distributionCenter={distributionCenter}
					handleSubmit={onSubmitOpenDateForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-around" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterDatesFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>
	)

}

export default DistributionCenterDatesFormContainer