import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Accordion from "@mui/material/Accordion"
import Divider from "@mui/material/Divider"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const useStyles = makeStyles({
    flex: {
        display: "flex",
    },
    flexColumn: {
        composes:"$flex",
        flexDirection: "column",
    },
    expansionPanel: {
        minWidth: 812
    },
    heading: {
        fontSize: 16,
        color: COLORS.PRIMARY_COLOR
    },
    summary: {
        height: 68,
        padding: 0,
        justifyContent: "flex-start",
        "& .MuiExpansionPanelSummary-content": {
            flexGrow: 0
        }
    },
    details: {
        padding: 0,
        marginBottom: 32
    },
    informationRow: {
        composes: "$flex"
    },
    informationColumn: {
        composes: "$flexColumn",
        minWidth: 200,
        paddingRight: 86
    },
    informationTitle: {
        fontSize: 12,
        opacity: 0.87,
        color: COLORS.INFORMATION_TITLE,
        marginBottom: 16
    },
    informationValue: {
        fontSize: 14
    },
    divider: {
        borderBottom: `1px solid ${COLORS.SITE_INFORMATION_VALUE}`,
        marginRight: 85
    },
    icon: {
        color: COLORS.PRIMARY_COLOR
    }
})

const RowInformation = (props) => {
    const {
        shippingFees,
        totalAmount,
        totalHT,
        isExpanded,
        setIsExpanded
    } = props

    const classes = useStyles()
    
    return (
        <Accordion
            defaultExpanded={ true }
            square
            className={classes.expansionPanel}
            elevation={ 0 }
            onChange={(event, expanded) => setIsExpanded(expanded)}
            expanded={isExpanded}
        >
            <AccordionSummary
                className={classes.summary}
                expandIcon={ <ExpandMoreIcon className={classes.icon}/>}
            >
                <Typography className={classes.heading}>Récapitulatif du bon de livraison</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.informationRow}>
                    <div className={classes.informationColumn}>
                        <Typography className={classes.informationTitle}>
                            Frais de port
                        </Typography>
                        <Typography className={classes.informationValue}>
                            {shippingFees} €
                        </Typography>
                    </div>
                    <div className={classes.informationColumn}>
                        <Typography className={classes.informationTitle}>
                            Montant global des produits
                        </Typography>
                        <Typography className={classes.informationValue}>
                            {totalAmount} €
                        </Typography>
                    </div>
                    <Divider
                        className={classes.divider}
                        orientation="vertical"
                        flexItem
                    />
                    <div className={classes.informationColumn}>
                        <Typography className={classes.informationTitle}>
                            Total (HT)
                        </Typography>
                        <Typography className={classes.informationValue}>
                            {totalHT} €
                        </Typography>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default RowInformation
