
import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"
import Parse from "parse"

export function showMisc() {
    return push("/misc")
}

export function loadPlanningRules() {
    return actionWithLoader(async (dispatch) => {
        const rules = await new Parse.Query("PlanningRules").first() || {}

        dispatch({
            type: "PLANNING_RULES_LOADED",
            planningRules: rules.toJSON()
        })
    })
}

export function updatePlanningRulesSwitchOutputProduction(idPlanningRule, switchOutputProduction) {
    return actionWithLoader(async () => {
        const rules = await new Parse.Query("PlanningRules").first() || {}
        rules.set("switchOutputProduction", Boolean(switchOutputProduction))

        rules.save()
    })
}

export function onEnterMisc(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningRules,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export const uploadUnpackingStepCSVForMigration = (lines) => {
    return actionWithLoader(async () => {
        await Parse.Cloud.run("migrateStepsToUnpackingProductionSteps", { lines })
    })
}  