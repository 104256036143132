import React, { useLayoutEffect, useState, useCallback, useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS, roundCurrency } from "../../../utils"
import FullScreenWrapper from "../../../components/FullScreenWrapper"
import {
    useSelector,
    useDispatch
} from "react-redux"
import {
    addCreditNoteToLot,
    cancelLot,
    resetOrderSupplierItem,
    showSingleOrder,
    updateLotAndCreditNotes,
    validateLotReception
} from "../../../actions/OrderReception/OrderReception"
import Button from "@mui/material/Button"
import { Box, Stack } from "@mui/material"
import BackModal from "../../../components/Order/Lot/BackModal"
import SupplierItemLotsTopBlock from "./SupplierItemLotsTopBlock"
import SupplierItemLotsBottomBlock from "./SupplierItemsLotsBottomBlock"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import { computeOrderSupplierItemQuantityLeft } from "../../../utils/supplierItemLotsUtils"
import { generateStockUnitLabel, generateStockUnitWeightLabel } from "../../../utils/orderReception"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import OrderReceptionHeaderInfo from "../Reception/OrderReceptionHeaderInfo"
import OrderReceptionHeaderTitle from "../Reception/OrderReceptionHeaderTitle"
import SupplierItemCreditNotes from "../../../components/Order/Lot/SupplierItemCreditNotes"
import CreditNoteLot from "../../../components/Order/CreditNote/CreditNoteLot"
import { creditNotesType } from "../../../utils/creditNoteUtils"
import { StyledMainContainer } from "../../ProductionStepExecutions/styledComponents"
import ActionFooter from "../../../components/common/ActionFooter"
import SupplierItemLot from "./SupplierItemLot"
import SupplierItemLotsResume from "./SupplierItemLotsResume"
import dayjs from "dayjs"

const useStyles = makeStyles({
    tabletMode: {
        transform: "rotate(-90deg)",
        transformOrigin: "left top"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        height: "91%"
    },
    headerBloc: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10000
    },
    mainContent: {
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    title: {
        fontSize: 20,
        display: "flex",
        flexDirection: "column",
        color: "#262626",
        alignItems: "center",
        fontWeight: 500
    },
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    appBarInfos: {
        margin: "10px auto 10px 20px"
    },
    appBarTitle: {
        color: COLORS.GREY_700,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "uppercase"
    },
    appBarSubtitle: {
        marginTop: 10,
        color: COLORS.DARK_GREY,
        fontSize: 12,
        textTransform: "uppercase"
    },
    tabsContainer: {
        marginTop: 72,
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    lastTab: {
        marginLeft: "auto"
    },
    floatingButton: {
        position: "absolute",
        bottom: 20,
        left: 20
    },
    leftSide: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
    },
    rightSide: {
        height: "100%",
        flex: "0 0 299px",
        justifyContent: "center",
        alignItems: "center"
    },
    firstButton: {
        marginRight: 10,
    },
    black: {
        color: COLORS.BLACK
    },
})

const SupplierItemLots = () => {
    const dispatch = useDispatch()
    const selectedOrderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const selectedOrderSupplierItem = useSelector(state => state.orderReception.selectedOrderSupplierItem)
    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)

    const classes = useStyles()
    const [showModalReturn, setShowModalReturn] = useState(false)
    const [showCreditNotesModal, setShowCreditNotesModal] = useState(false)
    const [showAddCreditNoteModal, setShowAddCreditNoteModal] = useState(false)
    const [creditNotetype, setCreditNoteType] = useState(null)
    const [creditNoteReedition, setCreditNoteReedition] = useState(false)
    const [isUnsavedCreditNoteUpdated, setIsUnsavedCreditNoteUpdated] = useState(false)
    const [lots, setLots] = useState(selectedOrderSupplierItem ? selectedOrderSupplierItem.lots : [])
    const [creditNotes, setCreditNotes] = useState(new Map())
    const [creditNotesAmount, setCreditNotesAmount] = useState(0)
    const [quantityLeft, setQuantityLeft] = useState(0)
    const [showAddLot, setShowAddLot] = useState(false)
    const [selectedLot, setSelectedLot] = useState(null)
    const [errorMessageShown, setErrorMessageShown] = useState(false)
    const [openLotPreview, setOpenLotPreview] = useState(false)
    const [isNotSavedCreditNotesUpdated, setIsNotSavedCreditNotesUpdated] = useState(false)

    const receptionStatus = useMemo(() => {
        return selectedOrderSupplierItem?.receptionStatus
    }, [selectedOrderSupplierItem])

    useLayoutEffect(() => {
        if (selectedOrderSupplierItem) {
            setLots(selectedOrderSupplierItem.lots)
            setQuantityLeft(computeOrderSupplierItemQuantityLeft(selectedOrderSupplierItem))
        }
    }, [selectedOrderSupplierItem])

    useLayoutEffect(() => {
        const length = getCreditNotesAmount(creditNotes)
        setCreditNotesAmount(length)
    }, [creditNotes])

    const onClickReturn = () => {
        if (receptionStatus === "DONE") {
            dispatch(showSingleOrder(selectedOrderReception.site.objectId, selectedOrderReception.receptionDate, selectedOrderReception.objectId))
            return
        }
        setShowModalReturn(true)
    }

    const toggleLotPreview = () => setOpenLotPreview(!openLotPreview)

    const cancelReturn = () => setShowModalReturn(false)

    const goBack = useCallback(() => {
        dispatch(resetOrderSupplierItem(selectedOrderReception, selectedOrderSupplierItem.supplierItem.objectId))
    }, [site, date, selectedOrderReception])

    const onValidateReception = useCallback(() => {
        if (quantityLeft > 0) {
            setErrorMessageShown(true)
            return
        }
        dispatch(validateLotReception(selectedOrderReception, selectedOrderSupplierItem, lots, creditNotes))
    }, [quantityLeft, creditNotes, selectedOrderReception, selectedOrderSupplierItem, lots])

    const onAddLot = () => {
        setSelectedLot(null)
        setShowAddLot(true)
    }

    const onSelectLot = (lot) => {
        setShowAddLot(false)

        setSelectedLot(lot)
    }

    const onHideCreditNotes = () => {
        setShowCreditNotesModal(false)
    }

    const addLot = (newLot) => {
        setLots(prev => [...prev, newLot])
        setQuantityLeft(prev => parseFloat((prev - newLot.quantity).toFixed(3)))
        setShowAddLot(false)
    }

    const deleteLot = (lot) => {
        const creditNotesCopy = new Map(creditNotes)
        creditNotesCopy.delete(lot.id)
        setQuantityLeft(prev => parseFloat((prev + lot.quantity).toFixed(3)))
        setLots(prev => prev.filter(prevLot => prevLot.id !== lot.id))
        setSelectedLot(null)
        setCreditNotes(creditNotesCopy)
    }

    const addCreditNote = data => {
        const values = creditNotes

        // always add the last value to the array
        values.set(data.lotId, [data.creditNoteData])

        if (data.lotId === "global") {
            const newQuantity = quantityLeft - data.creditNoteData.creditNote.quantity
            setQuantityLeft(parseFloat(newQuantity.toFixed(3)))
        }

        setCreditNotes(new Map(values))
    }

    const addCreditNoteOnLotDone = useCallback((data) => {
        dispatch(addCreditNoteToLot(selectedOrderReception.objectId, data.lotId, data.creditNoteData.creditNote))
    }, [selectedOrderReception])

    const handleUpdateLot = useCallback(async (data) => {
        await dispatch(updateLotAndCreditNotes(data, isNotSavedCreditNotesUpdated))
        setSelectedLot(prev => ({
            ...prev,
            creditNotes: prev.creditNotes.map(note => ({
                ...note,
                quantity: data.values.quantity,
                amount: data.values.amount,
                reason: data.values.reason
            }))
        }))
        setOpenLotPreview(prev => !prev)
        setCreditNoteReedition(false)
        setShowAddCreditNoteModal(false)
    }, [isNotSavedCreditNotesUpdated])

    const handleCancelLot = useCallback(async () => {
        await dispatch(cancelLot(selectedLot))
        setOpenLotPreview(prev => !prev)
        setCreditNoteReedition(false)
        setShowAddCreditNoteModal(false)
    }, [selectedLot])

    const deleteCreditNotes = data => {
        const values = creditNotes
        let quantity = quantityLeft

        data.forEach(item => {
            const notes = values.get(item.lot) || []
            const elem = notes.find(el => el.creditNote.id === item.id)
            const index = notes.indexOf(elem)

            if (item.lot === "global") {
                quantity += elem.creditNote.quantity
            }

            if (index > -1) {
                notes.splice(index, 1)
            }
            if (notes.length === 0) {
                values.delete(item.lot)
            } else {
                values.set(item.lot, notes)
            }
        })

        setQuantityLeft(parseFloat(quantity.toFixed(3)))
        setCreditNotes(new Map(values))
    }

    const actions = useCallback((callBack) => {
        switch (callBack.method) {
            case "ADD_LOT":
                addLot(callBack.data)
                return
            case "DELETE_LOT":
                deleteLot(callBack.data)
                return
            case "ADD_CREDIT_NOTE":
                if (receptionStatus === "DONE") {
                    addCreditNoteOnLotDone(callBack.data)
                    return
                }
                addCreditNote(callBack.data)
                setShowAddCreditNoteModal(false)
                return
            case "UPDATE_CREDIT_NOTE":
                handleUpdateLot(callBack.data)
                return
            case "DELETE_CREDIT_NOTE":
                deleteCreditNotes(callBack.data)
                return
            case "SHOW_ADD_CREDIT_NOTE_MODAL":
                setCreditNoteType(callBack.data.type)
                setShowAddCreditNoteModal(true)
                return
            case "SHOW_ADD_CREDIT_NOTE_MODAL_REEDITION":
                setCreditNoteType(callBack.data.type)
                setCreditNoteReedition(true)
                setShowAddCreditNoteModal(true)
                return
            case "SHOW_UPDATE_CREDIT_NOTE_MODAL":
                if (selectedLot && !selectedLot.creditNotes && creditNotes.size) {
                    const notes = creditNotes.get(selectedLot.id) || []
                    setSelectedLot(prev => ({
                        ...prev,
                        creditNotes: notes.map(note => note.creditNote)
                    }))
                }
                setCreditNoteType(callBack.data.type)
                setCreditNoteReedition(false)
                setIsNotSavedCreditNotesUpdated(true)
                setShowAddCreditNoteModal(true)
                setIsUnsavedCreditNoteUpdated(true)
                return
            default:
                return
        }
    }, [receptionStatus, selectedLot, creditNotes])

    const getCreditNotesAmount = (items) => {
        let length = 0
        Array.from(items).forEach(([, values]) => {
            length += values.length
        })

        return length
    }

    const generateHeadings = useCallback(() => {
        if (!selectedOrderSupplierItem) return
        return {
            title: selectedOrderSupplierItem.supplierItem?.name,
            subtitle: supplierItemTypes[selectedOrderSupplierItem.supplierItem?.type]?.label.toUpperCase()
        }
    }, [selectedOrderSupplierItem])

    const generateHeaderInfos = useCallback(() => {
        if (!selectedOrderSupplierItem) return
        const pricePerStockUnit = (selectedOrderSupplierItem.supplierItem?.units && selectedOrderSupplierItem.supplierItem?.units.stock && selectedOrderSupplierItem.supplierItem?.units.stock.price) || ""
        const unitType = generateStockUnitLabel(selectedOrderSupplierItem.supplierItem)
        const stockUnitWeight = generateStockUnitWeightLabel(selectedOrderSupplierItem.supplierItem)
        return {
            reference: `${selectedOrderSupplierItem.supplierItem?.reference}`,
            stockUnitWeight: `${stockUnitWeight}`,
            pricePerStockUnit: `${roundCurrency(pricePerStockUnit)} € / ${unitType}`
        }
    }, [selectedOrderSupplierItem])


    const creditNoteData = useMemo(() => {
        if (!selectedOrderReception || !selectedOrderSupplierItem) return
        return {
            headerData: {
                goBack: () => setShowAddCreditNoteModal(false),
                headings: generateHeadings(),
                infos: generateHeaderInfos()
            },
            lotData: selectedLot,
            orderNumber: selectedOrderReception.orderNumber,
            supplierItemName: selectedOrderSupplierItem.supplierItem?.supplierArticleName,
            supplierItem: selectedOrderSupplierItem.supplierItem,
            quantityLeft,
            lotActions: actions
        }
    }, [selectedLot, selectedOrderReception, selectedOrderSupplierItem, quantityLeft, actions])

    return (
        <FullScreenWrapper>
            <Box className={classes.mainContent} sx={{ paddingBottom: openLotPreview ? 0 : "85px" }}>
                {
                    selectedOrderReception && selectedOrderSupplierItem &&
                    <>
                        <div className={classes.headerBloc}>
                            <GenericTopBarHeader
                                leftAction={<GenericHeaderReturnButton handleClick={onClickReturn} />}
                                title={<OrderReceptionHeaderTitle sx={{ fontSize: 16 }} />}
                                info={selectedOrderSupplierItem && <OrderReceptionHeaderInfo info={`/ Commande n°${selectedOrderReception.orderNumber} / ${selectedOrderSupplierItem.supplierItem?.name}`} />}
                                // className={classes.appBar}
                            />
                        </div>
                        <StyledMainContainer sx={{ pt: 0, flex: 1, marginTop: "63px" }}> {/** for the fixed header */}
                            <Stack sx={{ p: 3 }} gap={3} className="flexColumn flex1 stretchSelf positionRelative">
                                <SupplierItemLotsResume
                                    title={selectedOrderSupplierItem?.supplierItem?.name}
                                    options={openLotPreview && selectedLot
                                        ? [
                                            { label: "LOT", value: selectedLot.lotNumber },
                                            { label: "DLC", value: dayjs(selectedLot.dlc).format("DD/MM/YYYY") },
                                        ] : [
                                            { label: "Référence", value: selectedOrderSupplierItem.supplierItem.reference },
                                            { label: "Unité de stock", value: generateStockUnitLabel(selectedOrderSupplierItem?.supplierItem) }
                                        ]
                                    }
                                />
                                <div className={classes.leftSide}>
                                    {openLotPreview
                                        ? (
                                            <SupplierItemLot
                                                selectedLot={selectedLot}
                                                onCancel={handleCancelLot}
                                                actions={actions}
                                            />
                                        ) : (
                                            <SupplierItemLotsTopBlock
                                                quantityLeft={quantityLeft}
                                                lots={lots}
                                                selectedLot={selectedLot}
                                                onAddLot={onAddLot}
                                                onSelectLot={onSelectLot}
                                                actions={actions}
                                                creditNotes={creditNotes}
                                                supplierItemReceptionStatus={receptionStatus}
                                                toggleLotPreview={toggleLotPreview}
                                            />
                                        )
                                    }
                                </div>
                                {quantityLeft > 0 && (<div className={classes.rightSide}>
                                    <SupplierItemLotsBottomBlock
                                        showAddLot={showAddLot}
                                        selectedLot={selectedLot}
                                        quantityLeft={quantityLeft}
                                        orderSupplierItem={selectedOrderSupplierItem.supplierItem}
                                        stockZones={selectedOrderReception.site.stockZones}
                                        actions={actions}
                                        receptionDate={selectedOrderReception.receptionDate}
                                        errorMessageShown={errorMessageShown}
                                    />
                                </div>)}
                            </Stack>
                        </StyledMainContainer>
                        {
                            showModalReturn &&
                            <BackModal
                                open={showModalReturn}
                                goBack={goBack}
                                onClose={cancelReturn}
                            />
                        }
                        {
                            showCreditNotesModal &&
                            <SupplierItemCreditNotes
                                creditNotes={creditNotes}
                                creditNotesAmount={creditNotesAmount}
                                goBack={onHideCreditNotes}
                                selectedOrderReception={selectedOrderReception}
                                selectedOrderSupplierItem={selectedOrderSupplierItem}
                                actions={actions}
                            />
                        }
                        {
                            showAddCreditNoteModal && creditNotetype === creditNotesType.LOT &&
                            <CreditNoteLot
                                {...creditNoteData}
                                creditNoteReedition={creditNoteReedition}
                                isUnsavedCreditNoteUpdated={isUnsavedCreditNoteUpdated}
                            />
                        }
                    </>
                }
            </Box>
            {receptionStatus !== "DONE" && (<ActionFooter
                rightAction={<Button
                    variant="contained"
                    color="primary"
                    onClick={onValidateReception}
                >
                    Valider la réception
                </Button>}
            />)}
        </FullScreenWrapper>
    )
}

export default SupplierItemLots