import React from "react"
import { makeStyles } from "@mui/styles"
import CustomReactDayPicker from "./CustomReactDayPicker"

const useStyles = makeStyles({
  dayPicker: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "12px",
    border: "1px solid #F0F0F0",
    padding: 16,
  }
})


const IntervalCalendar = ({ selectedDate, onSelectDate }) => {

  const classes = useStyles()

  return (
    <CustomReactDayPicker
      selectedDate={selectedDate}
      onSelectDate={onSelectDate}
      className={classes.dayPicker}
      showSelectionInBetween
    />
  )

}

export default IntervalCalendar