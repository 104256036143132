import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { getBrandBy } from "../utils"

const styles = () => {
    return ({
        root: {
            composes: "flexRow"
        }
    })
}

function getLogo(brand) {
    const completeBrand = getBrandBy("name", brand)

    if (completeBrand) {
        return (
            <div className="brandLogoContainer">
                <img src={completeBrand.image} className="brandLogo" alt={completeBrand.shortname}/>
            </div>
        )
    }

    return (<div>&nbsp;</div>)
}

class ListCartItem extends PureComponent {

    onDeleteCart = () => {
        const { onDelete, index } = this.props
        onDelete && onDelete(index)
    }

    render() {
        const {classes, values : {data, volume}} = this.props
        const brands = data.brand
        const labelCart = data.label
        const brandLabel = (brands === "FOODCHERI") ? "FC" : (brands === "SEAZON") ? "SZ" : "FC + SZ"

        return (
            <ListItem alignItems="flex-start" divider dense classes={{root: classes.root}}>
                {getLogo(brands)}
                <ListItemText primary={labelCart} secondary={volume+" "+brandLabel} />
                <IconButton aria-label="Supprimer" onClick={this.onDeleteCart} size="large">
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </ListItem>
        )
    }
}

ListCartItem.propTypes = {
    classes: PropTypes.object,
    values: PropTypes.object.isRequired,
    onDelete: PropTypes.func
}

export default withStyles(styles)(ListCartItem)
