import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import { MenuItem, NoSsr, TextField, Typography, Paper } from "@mui/material"
import AsyncSelect from "react-select/async"
import { querySearchSupplierItems } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        display: "flex",
        padding: 0,
        height: "100%"
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden"
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    singleValue: {
        fontSize: 16
    },
    placeholder: {
        position: "absolute",
        left: 2,
        fontSize: 16
    },
    paper: {
        position: "absolute",
        zIndex: 1,
        border: "1px solid rgba(0, 0, 0, .4)",
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
    divider: {
        height: theme.spacing(2)
    }
}))

const loadOptions = async (inputValue, callBack, supplier) => {
    callBack(await filterSupplierItems(inputValue, supplier))
}

const filterSupplierItems = async (inputValue, supplier) => {
    if (inputValue && supplier) {
        const supplierItems = await querySearchSupplierItems(inputValue, supplier)

        let result = []
        for (const supplierItem of supplierItems) {
            if(supplierItem.isActive){
                result.push({
                    objectId: supplierItem.objectId,
                    label: supplierItem.name.toLowerCase()
                })
            }
        }
    
        return result
    }

    return []
}

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
        >
            {props.children}
        </Typography>
    )
}

function inputComponent ({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    return (
        <TextField
            variant="standard"
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    )
}

const Placeholder = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

function Menu(props) {
    return <Paper square className={props.selectProps.classes.paper} {...props.innerProps} elevation={0}>
        {props.children}
    </Paper>
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
} 

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
}

const OrderReceptionSupplierItemAutoComplete = ({ field: {value, name}, form:{setFieldValue}, supplier, onSupplierItemSelected}) => {
    const classes = useStyles()
    
    const [selectedValue, setSelectedValue] = useState(value ? value : null)

    const handleChange = (newValue) => {
        setSelectedValue(newValue)
        setFieldValue(newValue)
        onSupplierItemSelected(newValue, setFieldValue)
    }

    return (
        <div className={classes.root}>
            <NoSsr>
                <AsyncSelect
                    name={name}
                    classes={classes}
                    supplier={supplier}
                    components={components}
                    cacheOptions
                    loadOptions={(inputValue, callBack) => loadOptions(inputValue, callBack, supplier)}
                    defaultOptions
                    onChange={handleChange}
                    value={selectedValue}
                    placeHolder="Nom de l'article"
                    isClearable
                />
            </NoSsr>
        </div>
    )
}

export default OrderReceptionSupplierItemAutoComplete