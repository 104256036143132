import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import withStyles from "@mui/styles/withStyles"
import MealPlannerMenu from "./MealPlannerMenu"
import MealPlannerCart from "./MealPlannerCart"
import GenericTopBarHeader from "../GenericTopBarHeader"
import PageHeaderTitle from "../PageHeaderTitle"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import Grid from "@mui/material/Grid"
import { Stack, Box, Button } from "@mui/material"
import {
    backgroundGray
} from "../../styles"
import { COLORS, KFC_BRANDS, capitalizeFirstLetter } from "../../utils"
import moment from "moment"
import { getSelectedItems } from "../../api/mealPlanner"

const styles = {
    root: {
        backgroundColor: backgroundGray,
        height: "100%",
    },
    appbar: {
        backgroundColor: COLORS.PRIMARY_COLOR
    },
    toolbar: {
        justifyContent: "flex-end"
    },
    cancelButton: {
        padding: [[10, 20]],
        lineHeight: "14px"
    },
    submitButton: {
        padding: [[11, 16]],
        boxShadow: "none",
        lineHeight: "14px"
    },
    title: {
        flexGrow: 1,
        color: COLORS.WHITE
    }
}

export const MealPlanner = props => {
    const {
        classes = {},
        targetBrand = [KFC_BRANDS[0].name],
        date,
        onSubmit,
        onCancel,
        productTypeOptions
    } = props

    const [selectedProducts, setSelectedProducts] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const selection = await getSelectedItems(targetBrand, date, productTypeOptions)

            setSelectedProducts(selection)
        }
        if (targetBrand && date && productTypeOptions.length) {
            fetchData()
        }
    }, [targetBrand, productTypeOptions, date])

    const _addToCart = (product) => {
        const existingIndex = selectedProducts.findIndex((item) => item.itemId === product.itemId)

        if (existingIndex !== -1) {
            // If the product is already in the cart, remove it
            const updatedCart = [...selectedProducts]
            updatedCart.splice(existingIndex, 1)
            setSelectedProducts(updatedCart)
        } else {
            // If the product is not in the cart, add it
            setSelectedProducts([...selectedProducts, product])
        }
    }

    const _updateItems = products => {
        setSelectedProducts([...products])
    }

    return (
        <Grid className={classes.root} container spacing={0}>
            <Grid item xs={12}>
                <Box sx={{ height: 64 }}>
                    <GenericTopBarHeader
                        title={
                            <PageHeaderTitle
                                title={"Carte"}
                                subtitle={capitalizeFirstLetter(moment.utc(date).format("dddd LL"))}
                                color="#7900D9"
                                icon={<ShoppingBasketIcon />}
                            />
                        }
                        rightAction={
                            <Stack direction="row" spacing={4}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={onCancel}>
                                    Annuler
                                </Button>
                                <Button
                                    className={classes.submitButton}
                                    variant="contained"
                                    onClick={() => onSubmit(selectedProducts)}>
                                    Enregistrer
                                </Button>
                            </Stack>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={9} sx={{ height: "calc(100% - 64px)" }}>
                <MealPlannerMenu
                    onClick={_addToCart}
                    targetBrand={targetBrand}
                    selectedProductIds={selectedProducts.map(({ itemId }) => itemId)}
                    date={date}
                    productTypeOptions={productTypeOptions}
                />
            </Grid>
            <Grid item xs={3} sx={{ height: "calc(100% - 64px)", overflow: "auto" }}>
                <MealPlannerCart
                    targetBrand={targetBrand}
                    items={selectedProducts}
                    productTypeOptions={productTypeOptions}
                    updateItems={_updateItems}
                />
            </Grid>
        </Grid>
    )
}

MealPlanner.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.arrayOf(
        PropTypes.object
    ),
    targetBrand: PropTypes.string,
    isLoading: PropTypes.bool,
    applySearch: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    internalTags: PropTypes.array,
    suppliers: PropTypes.array
}

export default withStyles(styles)(MealPlanner)
