import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Tab, Tabs } from "@mui/material"
import { makeStyles } from "@mui/styles"

import { COLORS } from "../../utils"
import { getProductionStepsExecutionsSelector } from "../../reducers/Production/ProductionSteps"
import ProductionStepExecutionsTable from "./ProductionStepExecutionsTable"

const useStyles = makeStyles({
	tab: {
		color: COLORS.PRODUCTION_STEPS_TEXT_GREY,
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: 500,
		textTransform: "uppercase",
		padding: "16px 24px"
	}
})

const ProductionStepsRead = () => {

	const classes = useStyles()

	const productionSteps = useSelector(getProductionStepsExecutionsSelector)
	const [tabValue, setTabValue] = useState(null)
	const [todoProductionSteps, setTodoProductionSteps] = useState([])
	const [inProgressProductionSteps, setInProgressProductionSteps] = useState([])
	const [toTestProductionSteps, setToTestProductionSteps] = useState([])
	const [doneProductionSteps, setDoneProductionSteps] = useState([])
	const [productionStepsToDisplay, setProductionStepsToDisplay] = useState([])

	useEffect(() => {
		setTodoProductionSteps(productionSteps.filter(productionStep => productionStep.status === "TODO" || productionStep.status === "LOCKED"))
		setInProgressProductionSteps(productionSteps.filter(productionStep => productionStep.status === "IN_PROGRESS"))
		setDoneProductionSteps(productionSteps.filter(productionStep => productionStep.status === "DONE"))
		setToTestProductionSteps(productionSteps.filter(productionStep => productionStep.status === "TO_TEST"))
	}, [productionSteps])

	useEffect(() => {
		if (!tabValue) {
			setTabValue("TODO")
		}
	}, [tabValue])

	useEffect(() => {
		if (tabValue === "TODO") {
			setProductionStepsToDisplay(todoProductionSteps)
		}
		if (tabValue === "IN_PROGRESS") {
			setProductionStepsToDisplay(inProgressProductionSteps)
		}
		if (tabValue === "DONE") {
			setProductionStepsToDisplay(doneProductionSteps)
		}
		if (tabValue === "TO_TEST") {
			setProductionStepsToDisplay(toTestProductionSteps)
		}
	}, [tabValue])

	const _onTabChange = (_, value) => setTabValue(value)

	return (
		<>
			<Tabs
				value={tabValue}
				onChange={_onTabChange}
				indicatorColor="primary"
				textColor="primary"
				centered
				sx={{
					borderBottom: "1px solid #E6E6E6"
				}}
			>
				<Tab className={classes.tab} value="TODO" label={`A RÉALISER (${todoProductionSteps.length})`} />
				<Tab className={classes.tab} value="IN_PROGRESS" label={`EN COURS (${inProgressProductionSteps.length})`} />
				<Tab className={classes.tab} value="TO_TEST" label={`À TESTER (${toTestProductionSteps.length})`} />
				<Tab className={classes.tab} value="DONE" label={`TERMINÉES (${doneProductionSteps.length})`} />
			</Tabs>
			<ProductionStepExecutionsTable productionStepExecutions={productionStepsToDisplay} state={tabValue} />
		</>)
}

export default ProductionStepsRead