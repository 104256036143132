import React, { useState, useEffect }  from "react"
import makeStyles from "@mui/styles/makeStyles"
import {connect, useDispatch} from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import Modal from "@mui/material/Modal"
import { withCookies } from "react-cookie"
import { grey } from "@mui/material/colors"
import AppBar from "@mui/material/AppBar"
import CircularProgress from "@mui/material/CircularProgress"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import ExportProductsResultModal from "../../../components/ExportProductsResultModal"
import {
    closeSubcontractorProductSnackBar,
    synchronizeASubcontractorProductWithEtiquettable,
    saveProductResume,
    saveCommercialNamesAndSeason,
    saveNutritionValues,
    saveNutritionSourcing,
    saveNutritionScore,
    showSubcontractorProductTab,
    flushExportSubcontractorsProductsErrors,
    updateSubcontractorDetails,
    showSubcontractorsProductsList,
    checkProductInCard
} from "../../../actions/Subcontractor/subcontractorsProducts"
import {
    asyncPrintLabel
} from "../../../actions/LabelProduct/LabelProduct"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import General from "../../../components/SubcontractorProduct/General"
import Details from "../../../components/SubcontractorProduct/Details"
import Header from "../../../components/SubcontractorProduct/Header"
import { subcontractorProductFormInitialValues } from "../../../actions/Utils/utils"
import { getPVC } from "../../../utils/recipes"
import ProductInCardModal from "../../../components/Recipe/ProductInCardModal"
import ProductPageHeader from "../../Recipe/ProductPageHeader"
import {displaySidebar} from "../../../actions/Utils/app"
import CloudinaryImage from "../../../components/Image/CloudinaryImage"

const createStyles = makeStyles(() => ({
    mainContainer: {
        display: "block",
        overflow: "auto"
    },
    tabs: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
    appBar: {
        backgroundColor: "white"
    },
    appBarMenuClosed: {
        composes: "$appBar",
        width: "calc(100% - 54.4px)"
    },
    appBarMenuOpenned: {
        composes: "$appBar",
        width: "calc(100% - 239px)"
    },
    appBarRoot: {
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)"
    },
    title: {
        flexGrow: 1,
        marginLeft: 20
    },
    cancel: {
        marginRight: 15
    },
    recipeImage: {
        height: 64,
        marginRight: 15
    },
    toolbarGutters: {
        paddingLeft: 0
    },
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    },
    backIcon: {
        paddingLeft: 25
    },
    width100: {
        "& .MuiButtonBase-root": {
            width: 162,
        },
        width: "100%"
    }
}))

const SubcontractorProductNew = (props) => {
    const classes = createStyles()
    const {
        tab,
        isMenuOpen,
        subcontractorProductSnackBar,
        subcontractorProductId,
        parseSubcontractorProduct,
        jsonSubcontractorProduct,
        exportSubcontractorsProductsErrors,
        packagings,
        packagingCost,
        subcontractorIngredientCost,
        subcontractorIngredientNetWeight,
        initialValues,
        pvcIsRed,
        productsTags,
        updateSubcontractorDetails,
        saveProductResume,
        saveCommercialNamesAndSeason,
        saveNutritionValues,
        saveNutritionSourcing,
        saveNutritionScore,
        showSubcontractorProductTab,
        closeSubcontractorProductSnackBar,
        asyncPrintLabel,
        synchronizeASubcontractorProductWithEtiquettable,
        chefs,
        productItems,
        checkProductInCard,
        cookies,
        showSubcontractorsProductsList,
    } = props

    const [tabValue, setTabValue] = useState(() => {
        if (tab) {
            if ("details" === tab) return 1
        }
        return 0
    })
    const dispatch = useDispatch()
    const [subcontractorProductEditing, setSubcontractorProductEditing] = useState(false)
    const [selectedSubcontractorProduct, setSelectedSubcontractorProduct] = useState(jsonSubcontractorProduct)
    const [openModalProductInCard, setOpenModalProductInCard] = useState(false)
    const [actionModal, setActionModal] = useState({action: () => {}})
    const [editing, setEditing] = useState(0)
    let submitForm = null
    let resetForm = null
    
    useEffect(() => {
        if (productItems.length) setOpenModalProductInCard(true)
    }, [editing])
    
    useEffect(() => {
        if (jsonSubcontractorProduct && jsonSubcontractorProduct !== selectedSubcontractorProduct) {
            setSelectedSubcontractorProduct(jsonSubcontractorProduct)
        }
    }, [jsonSubcontractorProduct])

    useEffect(() => {
        document.getElementById("subcontractorProduct-container")?.scrollIntoView()
    }, [])

    const _onTabChange = (_event, tabValue) => {
        let tabName = "general"

        switch (tabValue) {
            case 1:
                tabName = "details"
                break
            case 0:
            default:
                tabName = "general"
        }

        showSubcontractorProductTab(tabName, subcontractorProductId)
        setTabValue(tabValue)
    }

    const  _closeSnackbar = () => {
        closeSubcontractorProductSnackBar(subcontractorProductSnackBar.type)
    }

    const _onEditSubcontractorProduct = async () => {
        const check = await checkIsProductInCard()

        dispatch(displaySidebar(false))
        if (check) {
            showModalProductInCard(() => {setSubcontractorProductEditing(true)})
            return
        }
        
        setSubcontractorProductEditing(true)
    }

    const _onStopEditSubcontractorProduct = () => {
        dispatch(displaySidebar(true))
        setSubcontractorProductEditing(false)
    }

    const _bindSubmitAndResetForm = (submit, reset = null) => {
        submitForm = submit
        resetForm = reset
    }

    const _handleSubmitForm = (event) => {
        if (submitForm) {
            submitForm(event)
        }
    }

    const _handleResetForm = (event) => {
        if (resetForm) {
            resetForm(event)
        }

        _onStopEditSubcontractorProduct()
    }

    const _onSaveCommercialNames = (values) => {
        saveCommercialNamesAndSeason(parseSubcontractorProduct, values)
    }

    const _onSaveNutritionValues = (values, nutritionInformation) => {
        saveNutritionValues(parseSubcontractorProduct, values, nutritionInformation)
    }

    const _onSaveNutritionSourcing = (values, nutritionInformation) => {
        saveNutritionSourcing(parseSubcontractorProduct, values, nutritionInformation)
    }

    const _onSaveNutriScore = (values, nutritionInformation) => {
        saveNutritionScore(parseSubcontractorProduct, values, nutritionInformation)
    }
    
    const checkIsProductInCard = async () => {
        if (jsonSubcontractorProduct.status === "6") {
            const cookie = cookies.get(`SubcontractorProduct-${jsonSubcontractorProduct.objectId}`)
            
            if (!cookie) {
                await checkProductInCard(jsonSubcontractorProduct.objectId)
                let expires = new Date()
                expires.setTime(expires.getTime() + (5*60*1000))
                cookies.set(`SubcontractorProduct-${jsonSubcontractorProduct.objectId}`, true, { path: "/", expires })
                return true
            }
        }
        
        return false
    }
    
    const showModalProductInCard = (callback) => {
        setActionModal({action: callback})
        setEditing(editing + 1)
    }

    
    const onBack = () => {
        dispatch(displaySidebar(true))
        showSubcontractorsProductsList()
    }
    
    const _renderTabs = () => {
        return jsonSubcontractorProduct
            ?
            <>
                {
                    0 === tabValue &&
                    <General
                        initialValues={initialValues}
                        pvcIsRed={pvcIsRed}
                        jsonSubcontractorProduct={jsonSubcontractorProduct}
                        synchronizeASubcontractorProductWithEtiquettable={() => synchronizeASubcontractorProductWithEtiquettable(jsonSubcontractorProduct.objectId)}
                        asyncPrintLabel={asyncPrintLabel}
                        productsTags={productsTags}
                        onSaveProductResume={(values) => saveProductResume(parseSubcontractorProduct, values)}
                        parseSubcontractorProduct={parseSubcontractorProduct}
                        subcontractorIngredientCost={subcontractorIngredientCost}
                        packagingCost={packagingCost}
                        subcontractorIngredientNetWeight={subcontractorIngredientNetWeight}
                        onSaveCommercialNames={_onSaveCommercialNames}
                        onSaveNutritionsValues={_onSaveNutritionValues}
                        onSaveNutritionsSourcing={_onSaveNutritionSourcing}
                        onSaveNutriScore={_onSaveNutriScore}
                        chefs={chefs}
                        checkProductInCard={checkIsProductInCard}
                        showModalProductInCard={showModalProductInCard}
                    />
                }
                {
                    1 === tabValue &&
                    <Details
                        onEdit={_onEditSubcontractorProduct}
                        onStopEdit={_onStopEditSubcontractorProduct}
                        parseSubcontractorProduct={parseSubcontractorProduct}
                        bindSubmitAndResetForm={_bindSubmitAndResetForm}
                        jsonSubcontractorProduct={jsonSubcontractorProduct}
                        updateSubcontractorDetails={updateSubcontractorDetails}
                        editing={subcontractorProductEditing}
                        packagings={packagings}
                        initialValues={initialValues}
                    />
                }
            </>
            :
            <CircularProgress
                size={80}
                className={classes.circularProgress}
            />
    }


    const image = selectedSubcontractorProduct ? selectedSubcontractorProduct.appImage : null

    return (
        <div className={classes.container} id="subcontractorProduct-container">
            <ProductPageHeader subtitle={selectedSubcontractorProduct.commercialName} withBackButton onBack={onBack} />
            {
                !subcontractorProductEditing && (
                    <>
                        <Header image={image} jsonSubcontractorProduct={selectedSubcontractorProduct}/>
                        <Paper  className={classes.tabs}>
                            <Tabs
                                className={classes.width100}
                                value={tabValue !== null && tabValue !== undefined ? tabValue: false}
                                onChange={_onTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Général" />
                                <Tab label="Détails" />
                            </Tabs>
                        </Paper>
                    </>
                )
            }
            {
                subcontractorProductEditing && (
                    <AppBar className={isMenuOpen ? classes.appBarMenuOpenned : classes.appBarMenuClosed} classes={{
                        root: classes.appBarRoot
                    }}>
                        <Toolbar classes={{
                            gutters: classes.toolbarGutters
                        }}>
                            {
                                image &&
                                <CloudinaryImage
                                    containerClassName={classes.recipeImage}
                                    imageId={image && image.publicId}
                                    customQuality="auto"
                                />
                            }
                            <Typography variant="h6" color="textPrimary" className={classes.title}>
                                { jsonSubcontractorProduct.name.name }
                            </Typography>
                            <Button
                                color="primary"
                                className={classes.cancel}
                                onClick={_handleResetForm}
                            >
                                ANNULER
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={_handleSubmitForm}
                            >
                                ENREGISTRER
                            </Button>
                        </Toolbar>
                    </AppBar>
                )
            }
            {_renderTabs()}
            <ProductInCardModal
                productItems={productItems}
                action={actionModal.action}
                open={openModalProductInCard}
                onClose={() => setOpenModalProductInCard(false)}
            />
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={!!(exportSubcontractorsProductsErrors && exportSubcontractorsProductsErrors.length)}
                onClose={flushExportSubcontractorsProductsErrors}
            >
                <ExportProductsResultModal
                    errors={exportSubcontractorsProductsErrors}
                    title="Erreur lors de l'export d'un produit sous traitant"
                    subTitle="Une erreur est survenue lors de l'export du produit sous-traitant : "
                    tableTitle="Produit Sous-Traitant"
                    isFem={false}
                    searchFieldsLabel="Rechercher le produit sous traitant"
                    isRecipes={false}
                    closeModal={flushExportSubcontractorsProductsErrors}/>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={subcontractorProductSnackBar.open}
                autoHideDuration={subcontractorProductSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={subcontractorProductSnackBar.type}
                    message={subcontractorProductSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default connect((state, props) => {
    const subcontractorProduct = state.subcontractorsProducts.selectedSubcontractorProduct
    const { params: {tab, id} } = props
    const packagingCost = state.subcontractorsProducts.selectedSubcontractorProductPackagingCost
    const subcontractorIngredientCost = state.subcontractorsProducts.selectedSubcontractorProductIngredientCost
    const subcontractorIngredientNetWeight = state.subcontractorsProducts.selectedSubcontractorProductIngredientNetWeight
    const productType = subcontractorProduct && subcontractorProduct.get ? subcontractorProduct.get("type") : ""
    const pvcInfo = subcontractorProduct && subcontractorProduct.get
        ? getPVC("SUBCONTRACTORPRODUCT", productType, subcontractorIngredientCost)
        : {pvc: 0, hasColor: false}

    return {
        packagingCost,
        subcontractorIngredientCost,
        subcontractorIngredientNetWeight,
        jsonSubcontractorProduct: subcontractorProduct ? subcontractorProduct.toJSON() : null,
        productsTags: state.subcontractorsProducts.productsTags,
        parseSubcontractorProduct: subcontractorProduct,
        subcontractorProductSnackBar: state.subcontractorsProducts.subcontractorProductSnackBar,
        packagings: state.subcontractorsProducts.packagings,
        genericSections: state.subcontractorsProducts.sections,
        chefs: state.subcontractorsProducts.chefs,
        exportSubcontractorsProductsErrors: state.subcontractorsProducts.exportSubcontractorsProductsErrors,
        isMenuOpen: state.app.menuIsOpen,
        tab: tab,
        subcontractorProductId: id,
        initialValues: subcontractorProductFormInitialValues(subcontractorProduct, packagingCost, subcontractorIngredientCost, subcontractorIngredientNetWeight),
        pvc: pvcInfo.pvc,
        pvcIsRed: pvcInfo.hasColor,
        productItems: state.subcontractorsProducts.productItems
    }
}, {
    closeSubcontractorProductSnackBar,
    synchronizeASubcontractorProductWithEtiquettable,
    asyncPrintLabel,
    updateSubcontractorDetails,
    showSubcontractorProductTab,
    saveProductResume,
    saveCommercialNamesAndSeason,
    saveNutritionValues,
    saveNutritionSourcing,
    saveNutritionScore,
    flushExportSubcontractorsProductsErrors,
    showSubcontractorsProductsList,
    checkProductInCard,
})(withCookies(SubcontractorProductNew))
