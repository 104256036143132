import React from "react"
import { makeStyles } from "@mui/styles"
import { Stack } from "@mui/material"

const useStyles = makeStyles({
  root: {
    border: "1px solid #E6E6E6",
    padding: "24px",
    gap: "24px",
    borderRadius: "8px"
  },
  label: {
    color: "#262626",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8
  },
  title: {
    color: "#555",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px"
  },
  titleP24: {
    composes: "$title",
    paddingLeft: 24
  },
  instructions: {
    color: "#262626",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px"
  },
  singleValueContainer: {
    display: "flex",
  },
  singleValueContainerP24: {
    display: "flex",
    paddingLeft: 24
  },
  blocMachine: {
    display: "flex",
    flexDirection: "row",
  },
  fieldValue: {
    fontFamily: "Roboto",
    color: "#262626",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px"
  },
  fieldValueMachinesNames: {
    composes: "$fieldValue"
  },
})

const PSEPreparation = ({ productionStepExecution, isEnd}) => {

  const classes = useStyles()

  const displayMachineInformations = !!(productionStepExecution.productionStepSnapshot?.machineTypeName || (productionStepExecution.machinesBatch || []).length || productionStepExecution.productionStepSnapshot?.machineSetting) && !isEnd

  return (
    <Stack className={classes.root}>
      <Stack direction="row" spacing={1} alignItems="center">
        <img src="/img/recipes/restaurantIconOutlined.svg" alt="restaurantIconOutlined" />
        <span className={classes.label}>Préparation</span>
      </Stack>
      <Stack spacing={1}>
        <span className={classes.title}>
          Instructions
        </span>
        <span className={classes.instructions}>{productionStepExecution.productionStepSnapshot.description}</span>
      </Stack>

      {displayMachineInformations && (<div className={classes.blocMachine}>
        <div className={classes.subBlocMachine}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              Type de machine
            </div>
          </div>
          <div className={classes.singleValueContainer}>
            <span className={classes.fieldValue}>{productionStepExecution.productionStepSnapshot?.machineTypeName}</span>
          </div>
        </div>
        <div className={classes.subBlocMachine}>
          <div className={classes.titleContainer}>
            <div className={classes.titleP24}>
              Paramétrage
            </div>
          </div>
          <div className={classes.singleValueContainerP24}>
            <span className={classes.fieldValue}>{productionStepExecution.productionStepSnapshot?.machineSetting}</span>
          </div>
        </div>
        <div className={classes.subBlocMachine}>
          <div className={classes.titleContainer}>
            <div className={classes.titleP24}>
              Machine
            </div>
          </div>
          <div className={classes.singleValueContainerP24}>
            <div className={classes.fieldValueMachinesNames}>{(productionStepExecution.machinesBatch || []).map(machine => (
              <div key={machine.machineName}>{machine.machineName}</div>
            ))}</div>
          </div>
        </div>
      </div>)}
    </Stack>
  )
}

export default PSEPreparation