import React, { useState } from "react"
import {
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableBody,
    TableRow
} from "@mui/material"
import { withStyles } from "@mui/styles"
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Timer as TimerIcon,
    OfflineBolt as OfflineBoltIcon
} from "@mui/icons-material"
import Button from "@mui/material/Button"
import { Formik, Form, Field, ErrorMessage } from "formik"
import cloneDeep from "lodash/cloneDeep"
import _get from "lodash/get"
import { RecipeDetailsStyles } from "../../styles"
import { getDefaultHeatingInstruction, recipeDetailsFormInitialValues } from "../../actions/Utils/utils"
import { getBrandBy, forEachBrand } from "../../utils"
import FormikTextArea from "./FormikTextArea"
import {
    getPackagings,
    getSubPackagings,
    getReusablePackagings,
    getReusableSubPackagings
} from "../../reducers/Subcontractor/subcontractorsProducts"
import MultiSelectAutoCompleteNew from "../autoComplete/MultiSelectAutoCompleteNew"
import { emptySchema, getRecipeDetailsSchema } from "../../utils/yupValidators"
import { openUploadWidget } from "../../utils/cloudinary"
import {
    recipePreparations,
    difficultiesChooser,
    recipePreparationsBasic
} from "../../utils/recipes"
import CloudinaryImage from "../Image/CloudinaryImage"

const ENABLED_CONFIG = { price: "priceEnabled" }
const FIELD_TYPES = {
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    MULTISELECTAUTOCOMPLETE: "multiselectautocomplete",
    ICON: "icon",
    NUMBER: "number"
}
const HEATING_FIELDS = [
    { key: "power", fieldType: FIELD_TYPES.ICON, icon: <OfflineBoltIcon /> },
    { key: "duration", fieldType: FIELD_TYPES.ICON, icon: <TimerIcon /> },
    { key: "instructions", fieldType: FIELD_TYPES.TEXTAREA, icon: null, props: { placeholder: "Instructions" } }
]
const PREPARATION_VALUES = [
    { value: "1", label: "Se mange froid" },
    { value: "2", label: "Se mange chaud" },
    { value: "3", label: "Se mange chaud ou froid" }
]

const Details = (props) => {
    const {
        classes,
        editing,
        onEdit,
        bindSubmitAndResetForm,
        parseRecipe,
        onStopEdit,
        updateRecipeDetails,
        packagings,
        productType
    } = props

    const [schema, setSchema] = useState(parseRecipe ? getRecipeDetailsSchema(parseRecipe.get("status"), parseRecipe.get("preparation")) : emptySchema)

    const _buildPackagings = (packagings, subPackagings = false, allowNull = false, reusable = false) => {
        const result = {}

        forEachBrand(brand => {
            let filteredpackagings = []

            if (reusable) {
                filteredpackagings = subPackagings
                    ? getReusableSubPackagings(packagings, brand.name)
                    : getReusablePackagings(packagings, brand.name)
            }
            else {
                filteredpackagings = subPackagings
                    ? getSubPackagings(packagings, brand.name)
                    : getPackagings(packagings, brand.name)
            }

            result[brand.name] = filteredpackagings && Array.isArray(filteredpackagings)
                ? filteredpackagings.map(p => ({ value: p.id, label: p.get("name") }))
                : []

            if (allowNull) {
                result[brand.name].unshift({ value: "", label: "Aucun" })
            }
        })

        return result
    }

    const _tvaChooser = () => {
        return [5.5, 10, 20].map(el => ({ value: el, label: el }))
    }

    const _buildDifficultyChooser = () => {
        return difficultiesChooser.map(el => ({ value: el, label: el }))
    }

    const _onSubmit = (values) => {
        updateRecipeDetails(cloneDeep(values), parseRecipe, false).then(onStopEdit)
    }

    const _updatePreparation = (values, value, recipeType, setFieldValue) => {
        const type = productType.find(item => item.value === recipeType)
        let preparation
        if (type.value === "DRINK") {
            preparation = recipePreparationsBasic.find(item => item.value === value)
        }
        else {
            preparation = recipePreparations.find(item => item.value === value)
        }

        setFieldValue("preparation", value, false) // No validation here, because there's a second setFieldValue after
        setFieldValue(
            "specialInstruction",
            `${type.adj} ${type.label.toLowerCase()} ${type.adj === "Ce" ? preparation.label.toLowerCase() : preparation.labelFem.toLowerCase()}`,
            false
        ) // No validation here, because there's a second setFieldValue after

        if (value !== "1") {
            const finalHeatingInstructions = []
            let updated = false

            for (const brand of values.brands) {
                const currentHeatingInstruction = values.heatingInstructions.find(el => el.brand === brand)

                if (!currentHeatingInstruction) {
                    updated = true
                    finalHeatingInstructions.push({
                        brand: brand,
                        value: getDefaultHeatingInstruction(brand)
                    })
                }
            }

            if (updated) {
                setFieldValue("heatingInstructions", finalHeatingInstructions)
            }
        }
        else {
            setFieldValue("heatingInstructions", [])
        }

        setSchema(getRecipeDetailsSchema(parseRecipe.get("status"), value))
    }

    const _uploadAppImage = (values, setFieldValue, brand) => {
        openUploadWidget({ tags: ["app"] }, (error, result) => {
            if (error) {
                console.error(error)
                return
            }

            if (result.event !== "success") {
                // we don't use this event
                return
            }

            const publicId = result.info.public_id

            //---------- Where do we put the publicId ? ---------//
            if (!brand) {
                const defaultValues = values.defaultValues
                defaultValues.appImage = { publicId }
                setFieldValue("defaultValues", defaultValues)
            }
            else {
                const images = [...(values.appImage ? values.appImage : [])]
                const brandImage = images.find(img => img.brand === brand)

                if (brandImage) {
                    brandImage.value = { publicId }
                    setFieldValue("appImage", images)
                }
                else {
                    images.push({
                        brand: brand,
                        value: { publicId }
                    })
                    setFieldValue("appImage", images)
                }
            }
        })
    }

    const _uploadKitchenImage = (values, setFieldValue, brand) => {
        openUploadWidget({ tags: ["kitchen"] }, (error, result) => {
            if (error) {
                console.error(error)
                return
            }
            if (result.event !== "success") {
                // we don't use this event
                return
            }

            const publicId = result.info.public_id
            const images = values.kitchenImage || []
            const brandImage = images && images.find(img => img.brand === brand) || null

            if (brandImage) {
                brandImage.value = { publicId }
                setFieldValue("kitchenImage", images)
            }
            else {
                images.push({
                    brand: brand,
                    value: { publicId }
                })
                setFieldValue("kitchenImage", images)
            }
        })
    }

    const _deleteAppImage = (values, brand, setFieldValue) => {
        if (!brand) {
            const defaultValues = values.defaultValues
            defaultValues.appImage = null
            setFieldValue("defaultValues", defaultValues)
        }
        else {
            const images = values.appImage
            images.splice(images.findIndex(el => el.brand === brand), 1)
            setFieldValue("appImage", images)
        }
    }

    const _deleteKitchenImage = (values, brand, setFieldValue) => {
        const images = values.kitchenImage

        images.splice(images.findIndex(el => el.brand === brand), 1)
        setFieldValue("kitchenImage", images)
    }

    const _renderField = (fieldType, { options, brand, icon, secondType, ...props }) => {
        let result = null

        switch (fieldType) {
            case FIELD_TYPES.TEXT:
            case FIELD_TYPES.NUMBER:
                result = <Field type={fieldType} {...props} />
                break
            case FIELD_TYPES.TEXTAREA:
                result = <Field component={FormikTextArea} {...props} />
                break
            case FIELD_TYPES.SELECT:
                const toMap = options[brand] ? options[brand] : options
                result = (
                    <Field component="select" {...props}>
                        {
                            props.placeholder &&
                            <option value="" disabled="disabled" selected="disabled">{props.placeholder}</option>
                        }
                        {
                            toMap.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))
                        }
                    </Field>
                )
                break
            case FIELD_TYPES.MULTISELECTAUTOCOMPLETE:
                result = <Field component={MultiSelectAutoCompleteNew} options={options[brand]} {...props} />
                break
            case FIELD_TYPES.ICON:
                result = (
                    <div className={classes.iconField}>
                        {icon}
                        {_renderField(secondType, props)}
                    </div>
                )
                break
            default:
            // do nothing
        }

        return result
    }

    const _renderGenericRow = (values, fieldName, fieldType, label, errors, enableDefault = true, props = {}) => {
        const field = values[fieldName]

        const isBrandField = field !== null && field !== undefined && Array.isArray(field)
        const defaultFieldName = `${isBrandField ? "defaultValues." : ""}${fieldName === "commercialNames" ? "commercialName" : fieldName}`
        const { placeholder } = props

        return (
            <TableRow>
                <TableCell className={classes.leftHeader}>{label}</TableCell>
                <TableCell>
                    {
                        enableDefault && (
                            <>
                                {_renderField(fieldType, {
                                    name: defaultFieldName,
                                    disabled: !editing,
                                    className: _get(errors, defaultFieldName, false) ? classes.fieldError : null,
                                    ...props
                                })}
                                <ErrorMessage
                                    name={defaultFieldName}
                                    render={msg => <div className={classes.error}>{msg}</div>}
                                />
                            </>
                        )
                    }
                </TableCell>
                {
                    isBrandField && field.map(({ brand }, index) => {
                        let enabled = true

                        if (ENABLED_CONFIG[fieldName]) {
                            enabled = getBrandBy("name", brand)[ENABLED_CONFIG[fieldName]]
                        }

                        return (
                            <TableCell key={index}>
                                {!enabled && <span className={classes.default}>Non applicable</span>}
                                {enabled &&
                                    <>
                                        {_renderField(fieldType, {
                                            name: `${fieldName}[${index}].value`,
                                            disabled: !editing,
                                            brand: brand,
                                            placeholder: undefined === placeholder ? "Par défaut" : placeholder,
                                            className: _get(errors, `${fieldName}[${index}].value`, false) ? classes.fieldError : null,
                                            ...props
                                        })}
                                        <ErrorMessage
                                            name={`${fieldName}[${index}].value`}
                                            render={msg => <div className={classes.error}>{msg}</div>}
                                        />
                                    </>
                                }
                            </TableCell>
                        )
                    })
                }
                {
                    !isBrandField && values.brands.map((_, index) =>
                        <TableCell key={index}>
                            <span className={classes.default}>Non applicable</span>
                        </TableCell>
                    )
                }
            </TableRow>
        )
    }

    const _renderHeatingRow = (values, type, label, errors, props = {}) =>
        <TableRow>
            <TableCell className={classes.leftHeader}>{label}</TableCell>
            <TableCell></TableCell>
            {
                values.heatingInstructions.map((_, brandIndex) =>
                    <TableCell key={brandIndex}>
                        {
                            HEATING_FIELDS.map(({ key, fieldType, icon }, index) =>
                                <div key={index} className={classes.iconFieldWrapper}>
                                    {_renderField(fieldType, {
                                        name: `heatingInstructions[${brandIndex}].value["${type}"]["${key}"]`,
                                        disabled: !editing,
                                        icon: icon,
                                        secondType: null !== icon ? FIELD_TYPES.NUMBER : null,
                                        className: _get(errors, `heatingInstructions[${brandIndex}].value["${type}"]["${key}"]`, false) ? classes.fieldError : null,
                                        ...props[key]
                                    })}
                                    <ErrorMessage
                                        name={`heatingInstructions[${brandIndex}].value["${type}"]["${key}"]`}
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                </div>
                            )
                        }
                    </TableCell>
                )
            }
        </TableRow>

    const _renderAppImage = (publicId, onDelete, defaultShown = false) =>
        <div className={classes.imageContainer}>
            <CloudinaryImage
                imageId={publicId}
                containerClassName={defaultShown ? classes.defaultImage : classes.image}
                width={200}
                height={134}
            />
            {
                editing && !defaultShown &&
                <div className={classes.imageOverlay}>
                    {
                        onDelete &&
                        <div className={classes.deleteImageIcon}>
                            <DeleteIcon onClick={onDelete} />
                        </div>
                    }
                </div>
            }
        </div>

    const _renderKitchenImage = (publicId, onDelete) =>
        <div className={classes.imageContainer}>
            <CloudinaryImage
                imageId={publicId}
                containerClassName={classes.image}
                width={200}
                height={134}
            />
            {
                editing &&
                <div className={classes.imageOverlay}>
                    <div className={classes.deleteImageIcon}>
                        <DeleteIcon onClick={onDelete} />
                    </div>
                </div>
            }
        </div>

    const _renderAppImageRow = (values, setFieldValue, label, errors) => {
        const defaultImage = values.defaultValues.appImage

        return (
            <TableRow>
                <TableCell className={classes.leftHeader}>{label}</TableCell>
                <TableCell>
                    {
                        editing
                            ?
                            defaultImage && defaultImage.publicId
                                ?
                                _renderAppImage(
                                    defaultImage.publicId,
                                    () => _deleteAppImage(values, null, setFieldValue)
                                )
                                :
                                <div className={_get(errors, "defaultValues.appImage", false) ? classes.fieldError : null}>
                                    <ErrorMessage
                                        name="defaultValues.appImage"
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                    <span
                                        className={classes.addPhoto}
                                        onClick={() => _uploadAppImage(values, setFieldValue, null)}
                                    >
                                        Ajouter une photo
                                    </span>
                                </div>
                            :
                            defaultImage && defaultImage.publicId
                                ?
                                _renderAppImage(defaultImage.publicId, null)
                                :
                                <span className={classes.default}>Aucune image</span>
                    }
                </TableCell>
                {
                    values.brands.map((brand, index) => {
                        const currentAppImage = (values.appImage && values.appImage.find(el => el.brand === brand)) || null

                        return (
                            <TableCell key={index}>
                                {
                                    editing
                                        ?
                                        <>
                                            {
                                                currentAppImage && currentAppImage.value && currentAppImage.value.publicId
                                                    ?
                                                    _renderAppImage(
                                                        currentAppImage.value.publicId,
                                                        () => _deleteAppImage(values, brand, setFieldValue)
                                                    )
                                                    :
                                                    defaultImage && defaultImage.publicId &&
                                                    <>
                                                        {_renderAppImage(defaultImage.publicId, null, true)}
                                                        <span
                                                            className={classes.addPhoto}
                                                            onClick={() => _uploadAppImage(values, setFieldValue, brand)}
                                                        >
                                                            Ajouter une photo
                                                        </span>
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                currentAppImage && currentAppImage.value && currentAppImage.value.publicId
                                                    ?
                                                    _renderAppImage(currentAppImage.value.publicId, null)
                                                    :
                                                    defaultImage && defaultImage.publicId &&
                                                    _renderAppImage(defaultImage.publicId, null, true)
                                            }
                                        </>
                                }
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        )
    }

    const _renderKitchenImageRow = (values, setFieldValue, label) => {
        return (
            <TableRow>
                <TableCell className={classes.leftHeader}>{label}</TableCell>
                <TableCell>
                </TableCell>
                {
                    values.brands.map((brand, index) => {
                        const currentKitchenImage = (values.kitchenImage && values.kitchenImage.find(el => el.brand === brand)) || null

                        return (
                            <TableCell key={index}>
                                {
                                    editing
                                        ?
                                        <>
                                            {
                                                currentKitchenImage && currentKitchenImage.value && currentKitchenImage.value.publicId
                                                    ?
                                                    _renderKitchenImage(
                                                        currentKitchenImage.value.publicId,
                                                        () => _deleteKitchenImage(values, brand, setFieldValue)
                                                    )
                                                    :
                                                    <span className={classes.addPhoto} onClick={() => _uploadKitchenImage(values, setFieldValue, brand)}>Ajouter une photo</span>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                currentKitchenImage && currentKitchenImage.value && currentKitchenImage.value.publicId
                                                    ?
                                                    _renderKitchenImage(currentKitchenImage.value.publicId, null)
                                                    :
                                                    <span className={classes.default}>Aucune image</span>
                                            }
                                        </>
                                }
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        )
    }

    const _renderSectionSeparator = (title, colSpan) =>
        <TableRow className={classes.sectionSeparator}>
            <TableCell className={classes.sectionTitle} colSpan={colSpan}>{title}</TableCell>
        </TableRow>

    let initialValues = null
    let brandPackagings = null
    let brandSubPackagings = null
    let brandReusablePackagings = null
    let brandReusableSubPackagings = null
    let difficultyChooser = null

    if (parseRecipe) {
        initialValues = recipeDetailsFormInitialValues(parseRecipe)
        difficultyChooser = _buildDifficultyChooser()
    }

    if (packagings) {
        brandPackagings = _buildPackagings(packagings, false, true, false)
        brandSubPackagings = _buildPackagings(packagings, true, true, false)
        brandReusablePackagings = _buildPackagings(packagings, false, true, true)
        brandReusableSubPackagings = _buildPackagings(packagings, true, true, true)
    }

    return initialValues && (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize
            onSubmit={_onSubmit}
        >
            {({ values, errors, submitForm, resetForm, setFieldValue }) => {
                bindSubmitAndResetForm(submitForm, resetForm)

                return (
                    <>
                        {
                            !editing && (
                                <div className={classes.header}>
                                    <p>{/* Here will be the last edition */}</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.editButton}
                                        endIcon={<EditIcon />}
                                        onClick={onEdit}
                                    >
                                        Editer la recette
                                    </Button>
                                </div>
                            )
                        }
                        <Form>
                            <TableContainer className={`${classes.container} ${editing ? classes.marginTop : ""}`}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Marque</TableCell>
                                            <TableCell className={classes["brandHeaderDefault"]}>
                                                <div>Par défaut</div>
                                            </TableCell>
                                            {
                                                values.brands.map((brand, index) => {
                                                    const completeBrand = getBrandBy("name", brand)

                                                    return (
                                                        <TableCell key={index} className={classes[`brandHeader${completeBrand.name}`]}>
                                                            <div>
                                                                {
                                                                    completeBrand.image &&
                                                                    <img src={completeBrand.image} alt={`${completeBrand.label} logo`} className={classes.logo} />
                                                                }
                                                                {completeBrand.label}
                                                            </div>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* MARKETING */}
                                        {_renderSectionSeparator("Marketing", values.brands.length + 2)}
                                        {_renderAppImageRow(values, setFieldValue, "Photo vue client", true, "app", errors)}
                                        {_renderGenericRow(values, "commercialNames", FIELD_TYPES.TEXT, "Nom commercial", errors, true, {
                                            placeholder: values.defaultValues.commercialName
                                        })}
                                        {_renderGenericRow(values, "price", FIELD_TYPES.NUMBER, "Prix de vente", errors, true, {
                                            placeholder: values.defaultValues.price
                                        })}
                                        {_renderGenericRow(values, "tva", FIELD_TYPES.SELECT, "TVA", errors, true, {
                                            placeholder: values.defaultValues.tva,
                                            options: _tvaChooser()
                                        })}
                                        {_renderGenericRow(values, "dlc", FIELD_TYPES.NUMBER, "Date limite de consommation (en jours)", errors, true, {
                                            placeholder: values.defaultValues.dlc
                                        })}
                                        {_renderGenericRow(values, "description", FIELD_TYPES.TEXTAREA, "Description", errors, true, {
                                            placeholder: values.defaultValues.description
                                        })}

                                        {/* DRESSING */}
                                        {_renderSectionSeparator("Dressage", values.brands.length + 2)}
                                        {_renderKitchenImageRow(values, setFieldValue, "Photo barquette", false, "kitchen")}
                                        {_renderGenericRow(values, "instructions", FIELD_TYPES.TEXTAREA, "Instruction de dressage", errors, true, {
                                            placeholder: values.defaultValues.instructions
                                        })}
                                        {_renderGenericRow(values, "gesters", FIELD_TYPES.NUMBER, "Nombre de gestes", errors)}
                                        {_renderGenericRow(values, "portionPerPlate", FIELD_TYPES.NUMBER, "Portion / Plaque", errors)}
                                        {_renderGenericRow(values, "difficulty", FIELD_TYPES.SELECT, "Difficulté de production", errors, true, {
                                            options: difficultyChooser
                                        })}

                                        {/* PACKAGING */}
                                        {_renderSectionSeparator("Packaging", values.brands.length + 2)}
                                        {_renderGenericRow(values, "packaging", FIELD_TYPES.SELECT, "Packaging", errors, false, {
                                            options: brandPackagings
                                        })}
                                        {_renderGenericRow(values, "subPackaging", FIELD_TYPES.MULTISELECTAUTOCOMPLETE, "Sous-Packaging", errors, false, {
                                            options: brandSubPackagings,
                                            placeholder: "" // We don't want to show "par défaut" here
                                        })}
                                        {_renderGenericRow(values, "reusablePackaging", FIELD_TYPES.SELECT, "Packaging réutilisable", errors, false, {
                                            options: brandReusablePackagings
                                        })}
                                        {_renderGenericRow(values, "reusableSubPackaging", FIELD_TYPES.MULTISELECTAUTOCOMPLETE, "Sous-Packaging réutilisable", errors, false, {
                                            options: brandReusableSubPackagings,
                                            placeholder: "" // We don't want to show "par défaut" here
                                        })}
                                        {/* HEATING */}
                                        {_renderSectionSeparator("Chauffage", values.brands.length + 2)}
                                        {_renderGenericRow(values, "preparation", FIELD_TYPES.SELECT, "Préparation", errors, true, {
                                            options: PREPARATION_VALUES,
                                            onChange: (e) => _updatePreparation(values, e.target.value, values.type, setFieldValue)
                                        })}
                                        {_renderGenericRow(values, "specialInstruction", FIELD_TYPES.TEXT, "Instruction spécifique", errors)}
                                        {
                                            "1" !== values.preparation &&
                                            <>
                                                {_renderHeatingRow(values, "microwave", "Micro-ondes", errors, {
                                                    power: { placeholder: _get(values, "defaultValues.heatingInstructions.microwave.power") },
                                                    duration: { placeholder: _get(values, "defaultValues.heatingInstructions.microwave.duration") },
                                                    instructions: { placeholder: _get(values, "defaultValues.heatingInstructions.microwave.instructions") }
                                                })}
                                                {_renderHeatingRow(values, "oven", "Four", errors, {
                                                    power: { placeholder: _get(values, "defaultValues.heatingInstructions.oven.power") },
                                                    duration: { placeholder: _get(values, "defaultValues.heatingInstructions.oven.duration") },
                                                    instructions: { placeholder: _get(values, "defaultValues.heatingInstructions.oven.instructions") }
                                                })}
                                                {_renderHeatingRow(values, "pan", "Poele", errors, {
                                                    power: { placeholder: _get(values, "defaultValues.heatingInstructions.pan.power") },
                                                    duration: { placeholder: _get(values, "defaultValues.heatingInstructions.pan.duration") },
                                                    instructions: { placeholder: _get(values, "defaultValues.heatingInstructions.pan.instructions") }
                                                })}
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Form>
                    </>
                )
            }}
        </Formik>
    )
}

export default withStyles(RecipeDetailsStyles)(Details)