import React from "react"
import LotCardContent from "./LotCardContent"

const SelectableAlphabetCardContent = ({ 
  document,
  collection,
  mode,
  selected = false,
  currentDate,
  dlcWarningDate,
 }) => {

  const isLotOrSecondaryDLC = collection === "Lot" || collection === "SecondaryDLC"

  if (isLotOrSecondaryDLC) {
    return (
      <LotCardContent
        document={document}
        collection={collection}
        mode={mode}
        selected={selected}
        currentDate={currentDate}
        dlcWarningDate={dlcWarningDate}
      />)
  }
  return null
}

export default SelectableAlphabetCardContent