import React from "react"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Card from "./Card"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../utils"
import Divider from "@mui/material/Divider"
import moment from "moment"

const useStyles = makeStyles(() => ({
    listItem: {
        padding: "0 6px 19px 34px"
    },
    title: {
        flex: "0 0 auto",
        width: 55,
        marginTop: 21,
        "& span": {
            color: COLORS.PRIMARY_COLOR,
            fontSize: 42,
            fontWeight: "bold"
        }
    },
    title2: {
        composes: "$title",
        "& span": {
            fontSize: 30,
            paddingTop: 10
        }
    },
    groupCard: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            width: 80,
            height: 80,
            margin: "17px 10px 0 0",
            background: COLORS.WHITE,
            boxShadow: `0px 1px 1px ${COLORS.BLUE_PAPER_SELECTED}`,
            borderRadius: 10
        },
    }
}))


const ListCard = (props) => {
    const { title, listItem, last, onCardClick, selectedCard, isInventory, canEdit } = props
    const classes = useStyles()
    const listItemSorted = listItem.sort((a,b) => a.uniqueCode.localeCompare(b.uniqueCode) || a.dlc - b.dlc)
    return (
        <>
            <ListItem className={classes.listItem} alignItems="flex-start">
                <ListItemText className={(title.length === 2) ? classes.title2 : classes.title} primary={title} />
                <div className={classes.groupCard}>
                    {
                        listItemSorted && listItemSorted.map((cardElement) => {
                            const date = moment.utc(cardElement.dlc)
                            const displayDate = date.isSame(new Date(), "year")
                                ? date.format("DD/MM")
                                : date.format("DD/MM/YY")

                            return <Card
                                key={title + cardElement.objectId}
                                type={title}
                                number={cardElement.uniqueCode.replace(title, "")}
                                date={displayDate}
                                done={isInventory ? (cardElement.checked) : (cardElement.receptionStatus === "DONE")}
                                onCardClick={onCardClick}
                                element={cardElement}
                                selectedCard={selectedCard}
                                isInventory={isInventory}
                                canEdit={canEdit}
                            />
                        })
                    }
                </div>
            </ListItem>
            {!last &&
                <Divider component="li" />
            }
        </>
    )
}

export default ListCard