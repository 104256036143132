import React, { Fragment } from "react"
import withStyles from "@mui/styles/withStyles"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"

import { COLORS } from "../../utils"
import {
    pxTOvw,
} from "../../utils/planning"


const styles = {
    previsionsHeaderContainer: {
        display: "flex",
        position: "fixed",
        zIndex: 200,
        backgroundColor: COLORS.WHITE
    },
    dayIcon: {
        width: 32,
        textAlign: "center",
        marginTop: 10,
        cursor: "pointer"
    },
    previsionsHeaderInfos: {
        width: pxTOvw((window.innerWidth - 118.4) / 5),
        padding: 5,
        border: `1px solid ${COLORS.MEDIUM_GREY}`,
        fontSize: 14
    },
    iconContainer: {
        marginLeft: "auto",
        color: COLORS.DARK_GREY,
        marginTop: -7
    },
    prevHeaderInfoDetail: {
        display: "flex",
        width: "100%"
    },
    prevHeaderPrev: {
        textAlign: "end",
        marginRight: 15,
        width: "100%"
    },
    prevHeaderTarget: {
        textAlign: "end",
        marginRight: 10,
        width: 40
    },
    cell: {
        padding: 0,
        borderBottom: "none"
    }
}

const PrevisionsHeader = (props) => {
    const {
        data,
        brand,
        classes,
        selectedProductTypes,
        productTypeOptions
    } = props
    
    const computeSum = (brandData, productType) => {
        let result = {
            totalPrevSell: 0,
            totalTarget: 0
        }

        for (const i in brandData) {
            const current = brandData[i]

            if (current.prev){
                const prev = current.prev.find(el => el.productType === productType)

                if (prev){
                    result.totalPrevSell += parseFloat(prev.prevSell)
                    result.totalTarget += parseFloat(prev.target)
                }
            }
        }

        return result
    }

    return (
        <Fragment>
            <div className={classes.previsionsHeaderContainer}>
                {
                    data.map((item, index) => {
                        const currentBrandData = item.cards[brand.name]
                        return (
                            <div
                                className={classes.previsionsHeaderInfos}
                                key={index}
                            >
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cell}></TableCell>
                                            <TableCell
                                                className={classes.cell}
                                                align="right"
                                            >
                                                Prev vente
                                            </TableCell>
                                            <TableCell
                                                className={classes.cell}
                                                align="right"
                                            >
                                                Cible
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            productTypeOptions.filter(option => selectedProductTypes.includes(option.type) && option.value !== "ALL").map(productTypeOption => {
                                                const productTypeLabel = productTypeOption.label
                                                const prevsPlanningProductType = productTypeOption.type

                                                const rowData = computeSum(currentBrandData, prevsPlanningProductType)

                                                return (
                                                    <TableRow key={productTypeLabel}>
                                                        <TableCell
                                                            className={classes.cell}
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            { productTypeLabel }
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.cell}
                                                            align="right"
                                                        >
                                                            {rowData.totalPrevSell}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.cell}
                                                            align="right"
                                                        >
                                                            {rowData.totalTarget}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

export default withStyles(styles)(PrevisionsHeader)
