import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import moment from "moment"

import {
    showCommercialName,
    showCommercialNameForm,
    synchroEtiquettableIngredient,
    closeCommercialNameSnackBar,
    extractIngredientsBaseCsv
} from "../../../actions/Ingredients/Internal/CommercialsNames"
import {
    TableDateFormat
} from "../../../components/table-utils/utils"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"

import DataGrid from "../../../components/DataGrid/DataGrid"
import { getFamiliesIngredients, getGroupsIngredients } from "../../../reducers/Ingredients/Internal/CommercialName"

const useStyles = makeStyles(() => ({
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    searchFieldInput: {
        width: 300
    },
    brandLogo: {
        width: 25
    },
    brandLogoLeft: {
        composes: "$brandLogo",
        marginRight: 5
    },
    column: {
        cursor: "pointer"
    },
    fab: {
        position: "sticky",
        marginLeft: 30,
        bottom: 30
    },
}))

const CommercialNames = () => {

    const classes = useStyles()

    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const commercialNames = useSelector(state => state.commercialNames.commercialNamesList)
    const commercialNameSnackBar = useSelector(state => state.commercialNames.commercialNameSnackBar)

    const ingredientsGroups = useSelector(getGroupsIngredients)
    const familiesGroups = useSelector(getFamiliesIngredients)

    useEffect(() => {
        createDataObject()
    }, [commercialNames])

    const createDataObject = useCallback(() => {
        setData(commercialNames.map(createData))
    }, [commercialNames])

    const createData = (commercialName) => {
        return {
            id: commercialName.objectId,
            name: commercialName.name.toLowerCase(),
            ingredientsGroup: commercialName.group ? commercialName.group.name : "",
            ingredientsFamily: commercialName.group && commercialName.group.family ? commercialName.group.family.name : "",
            updatedAt: moment(commercialName.updatedAt).format(TableDateFormat),
        }
    }

    const onExtractIngredientsCsv = () => {
        dispatch(extractIngredientsBaseCsv())
    }

    const onSynchroEtiquettableIngredient = () => {
        dispatch(synchroEtiquettableIngredient())
    }

    const columnTableHeaders = [
        {
            key: "id",
            label: "ID",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
            width: 120
        },
        {
            key: "name",
            label: "Nom",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "ingredientsFamily",
            label: "Famille d'ingrédients",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: (familiesGroups || []).map((familiesGroup) => ({
                    key: familiesGroup.objectId,
                    value: familiesGroup.name,
                    label: familiesGroup.name
                }))
            },
        },
        {
            key: "ingredientsGroup",
            label: "Groupe d'ingrédients",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: (ingredientsGroups || []).map((ingredientsGroup) => ({
                    key: ingredientsGroup.objectId,
                    value: ingredientsGroup.name,
                    label: ingredientsGroup.name
                }))
            },
        },
        {
            key: "updatedAt",
            label: "Date de modification",
            dataType: "date",
            type: "date",
            sortable: true,
            filter: {
                filterable: false,
                type: "date"
            },
            width: 130
        }
    ]

    const onShowCommercialName = (commercialName) => dispatch(showCommercialName(commercialName.id))

    const onShowCommercialNameForm = () => dispatch(showCommercialNameForm())

    const onCloseCommercialNameSnackbar = () => dispatch(closeCommercialNameSnackBar(commercialNameSnackBar.type))

    const moreMenus = [
        {
            onClick: onExtractIngredientsCsv,
            label: "Extraire la base ingrédient"
        },
        {
            onClick: onSynchroEtiquettableIngredient,
            label: "Synchroniser les ingrédients avec Étiquettable"
        }
    ]

    return (
        <>
            <Paper elevation={0} className={classes.root}>
                <DataGrid
                    title="Ingrédients"
                    columns={columnTableHeaders}
                    data={data}
                    withFilters
                    rowLabel="ingrédients"
                    menus={moreMenus}
                    onRowClick={onShowCommercialName}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={commercialNameSnackBar.open}
                    autoHideDuration={commercialNameSnackBar.duration}
                    onClose={onCloseCommercialNameSnackbar}
                >
                    <MySnackbarContentWrapper
                        onClose={onCloseCommercialNameSnackbar}
                        variant={commercialNameSnackBar.type}
                        message={commercialNameSnackBar.message}
                    />
                </Snackbar>
            </Paper>
            <div className={classes.fab}>
                <Fab color="primary" onClick={onShowCommercialNameForm}>
                    <Add />
                </Fab>
            </div>
        </>
    )
}

export default CommercialNames
