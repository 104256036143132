import React, { Fragment, useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import InfoIcon from "@mui/icons-material/Info"
import { makeStyles } from "@mui/styles"

import { pxTOvw } from "../../utils/planning"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
    expansionPanel: {
        margin: 0,
        backgroundColor: COLORS.DISABLED_INPUT_COLOR
    },
    typo: {
        color: COLORS.WHITE,
        fontWeight: "bold"
    },
    typoSub: {
        color: COLORS.DARK_GREY,
        fontWeight: "bold"
    },
    expansionPanelDetail: {
        display: "block",
        padding: 0,
        margin: 0,
        marginTop: 0
    },
    icon: {
        cursor: "pointer",
        fontSize: 15,
        marginTop: 5,
        marginLeft: 10,
        color: COLORS.WHITE
    },
    sectionHeaderInfo: {
        display: "flex",
        width: "100%",
    },
    calendarHeaderInfoContainer: {
        color: COLORS.DARK_GREY,
        width: pxTOvw((window.innerWidth - 118.4) / 7),
        fontSize: 12,
        lineHeight: "130%",
        padding: "12px 18px 12px 12px",
        backgroundColor: COLORS.WHITE,
        border: `0.5px solid ${ COLORS.MEDIUM_GREY }`
    },
    infoNumber: {
        marginLeft: "auto"
    },
    cantExpand: {
        cursor: "not-allowed"
    }
})

const CalendarSection = (props) => {
    const {
        title,
        children = null,
        color = COLORS.GREEN_SEAZON,
        isSub = false,
        headerInfos,
        showInfoBtn = true,
        searching = false
    } = props

    const classes = useStyles()

    const [openInfo, setInfoOpen] = useState(false)
    const [isExpanded, setIsExpanded] = useState(true)

    const _onClick = (e) => {
        if (!isSub){
            setInfoOpen(!openInfo)
        }
        e.stopPropagation()
    }

    return (
        <Fragment>
            <Accordion
                defaultExpanded={ true }
                square
                className={classes.expansionPanel}
                elevation={ 0 }
                disabled={ !children }
                onChange={(event, expanded) => setIsExpanded(expanded)}
                expanded={isExpanded || searching}
            >
                <AccordionSummary
                    style={{ backgroundColor: color }}
                    expandIcon={ <ExpandMoreIcon className={searching ? classes.cantExpand : null} /> }
                >
                    <div className="flexRow">
                        <Typography
                            className={isSub ? classes.typoSub : classes.typo}
                            variant="button"
                        >
                            {title}
                        </Typography>
                        {
                            showInfoBtn &&
                                <div>
                                    <InfoIcon
                                        className={classes.icon}
                                        onClick={(e) => {_onClick(e)}}
                                    />
                                </div>
                        }
                    </div>
                </AccordionSummary>
                <div className={classes.sectionHeaderInfo}>
                    {openInfo && headerInfos}
                </div>
                <AccordionDetails className={classes.expansionPanelDetail}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </Fragment>

    )
}

export default CalendarSection
