import React from "react"
import PropTypes from "prop-types"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexGrow: 1,
    width: "68%",
    overflow: "auto",
    backgroundColor: COLORS.LIGHT_GREY,
    justifyContent: "center",
    "&.full-width": {
      width: "100%",
    },
  },
  mainContentPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: 0,
  },
})

const LeftSideWrapper = (props) => {
  const { children, fullWidth } = props
  const classes = useStyles()

  return (
    <main className={clsx(classes.content, { "full-width": fullWidth })}>
      <Paper className={classes.mainContentPaper} elevation={0}>
        {children}
      </Paper>
    </main>
  )
}

LeftSideWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LeftSideWrapper
