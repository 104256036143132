import { getData } from ".."
import _cloneDeep from "lodash/cloneDeep"
import { KFC_BRANDS } from "../../utils"
import { RecipeIsActive, RecipeStatus } from "../../utils/recipes"

const storage = JSON.parse(localStorage.getItem("searchValue"))
const storageSubcontractorProductList = JSON.parse(localStorage.getItem("subcontractorProductList"))

const defaultState = {
    subcontractorsProductsList: [],
    packagings: [],
    suppliers: [],
    selectedSubcontractorProduct: null,
    selectedSubcontractorProductPackagingCost: 0,
    selectedSubcontractorProductIngredientCost: 0,
    selectedSubcontractorProductIngredientNetWeight: 0,
    exportSubcontractorsProductsErrors: [],
    subcontractorProductSnackBar: { open: false, type: "", message: "", duration: 0 },
    productsTags: [],
    chefs: [],
    productItems: [],
    subcontractorsProductFilters: {
        rowsPerPage: 10,
        page: 0,
        search: (storage && storage.subcontractorProduct) ? storage.subcontractorProduct : null,
        brands: (storageSubcontractorProductList && storageSubcontractorProductList.brands) ? storageSubcontractorProductList.brands : ["ALL", ...KFC_BRANDS.map(el => el.name)],
        types: (storageSubcontractorProductList && storageSubcontractorProductList.types) ? storageSubcontractorProductList.types : [],
        status: (storageSubcontractorProductList && storageSubcontractorProductList.status) ? storageSubcontractorProductList.status : ["ALL", ...RecipeStatus.map(el => el.value)],
        isActive: (storageSubcontractorProductList && storageSubcontractorProductList.isActive) ? storageSubcontractorProductList.isActive : ["ALL", ...RecipeIsActive.map(el => el.value)],
        suppliers: (storageSubcontractorProductList && storageSubcontractorProductList.suppliers) ? storageSubcontractorProductList.suppliers : ["ALL"]
    },
    total: 0
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "SUBCONTRACTORS_PRODUCTS_LOADED":
            return {
                ...state,
                subcontractorsProductsList: action.subcontractorsProductsList,
                suppliers: action.suppliers
            }
        case "SUBCONTRACTOR_PRODUCT_SAVED":
            return {
                ...state,
                selectedSubcontractorProduct: action.selectedSubcontractorProduct
            }
        case "SUBCONTRACTOR_PRODUCT_SELECTED":
            return {
                ...state,
                selectedSubcontractorProduct: action.selectedSubcontractorProduct,
                selectedSubcontractorProductPackagingCost: action.selectedSubcontractorProductPackagingCost,
                selectedSubcontractorProductIngredientCost: action.selectedSubcontractorProductIngredientCost,
                selectedSubcontractorProductIngredientNetWeight: action.selectedSubcontractorProductIngredientNetWeight
            }
        case "SELECTED_SUBCONTRACTOR_PRODUCT_UPDATED":
            return {
                ...state,
                selectedSubcontractorProduct: action.selectedSubcontractorProduct
            }
        case "PACKAGING_LOADED":
            return {
                ...state,
                packagings: action.packagings
            }
        case "SUPPLIERS_LOADED":
            return {
                ...state,
                suppliers: action.suppliers
            }
        case "EXPORT_SELECTED_SUBCONTRACTOR_PRODUCT": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "EXPORT_SUBCONTRACTORS_PRODUCTS_ERRORS":
            return {
                ...state,
                exportSubcontractorsProductsErrors: action.exportSubcontractorsProductsErrors,
                exportSubcontractorsProductsMessage: action.exportSubcontractorsProductsMessage
            }
        case "SYNCHRONIZE_SUBCONTRACTORS_PRODUCTS_WITH_ETIQUETTABLE": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SYNCHRONIZE_SUBCONTRACTORS_PRODUCTS_WITH_ETIQUETTABLE_ERROR": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SYNCHRONIZE_A_SUBCONTRACTOR_PRODUCT_WITH_ETIQUETTABLE": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_SAVED": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_ERROR": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SYNCHRONIZE_A_SUBCONTRACTOR_PRODUCT_WITH_ETIQUETTABLE_ERROR": {
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        }
        case "SUBCONTRACTOR_PRODUCT_LABEL_MODAL_OPEN": {
            return {
                ...state,
                labelModalIsOpen: action.labelModalIsOpen
            }
        }
        case "SUBCONTRACTOR_PRODUCT_LABEL_MODAL_CLOSE": {
            return {
                ...state,
                labelModalIsOpen: action.labelModalIsOpen
            }
        }
        case "SUBCONTRACTOR_PRODUCT_PRODUCTS_TAGS_LOADED": {
            return {
                ...state,
                productsTags: action.productsTags
            }
        }
        case "SUBCONTRACTOR_PRODUCT_CHEF_LOADED": {
            return {
                ...state,
                chefs: action.chefs
            }
        }
        case "SUBCONTRACTOR_PRODUCT_UPDATE_LIST_FILTERS":
            return {
                ...state,
                subcontractorsProductFilters: {
                    ...state.subcontractorsProductFilters,
                    ...action.change
                }
            }
        case "SUBCONTRACTOR_PRODUCT_FILTERS_TYPES_UPDATED":
            return {
                ...state,
                subcontractorsProductFilters: {
                    ...state.subcontractorsProductFilters,
                    types: action.types
                }
            }
        case "CLOSE_SUBCONTRACTOR_PRODUCT_SNACKBAR":
        case "SUBCONTRACTOR_PRODUCT_IMAGE_SAVED":
        case "SUBCONTRACTOR_PRODUCT_IMAGE_REMOVED":
        case "SUBCONTRACTOR_PRODUCT_DUPLICATE_CREATION":
            return {
                ...state,
                subcontractorProductSnackBar: action.subcontractorProductSnackBar
            }
        case "SUBCONTRACTOR_PRODUCT_PRODUCT_ITEMS_CHECKED":
            return {
                ...state,
                productItems: _cloneDeep(action.productItems)
            }
        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getSubcontractorProduct(state, id) {
    return state.subcontractorsProducts.subcontractorsProductsList.find(subcontractorProduct => subcontractorProduct.id === id)
}
export function getSelectedSubcontractorProduct(state, errorIfNotFound = false) {
    return getData(state, "subcontractorsProducts.selectedSubcontractorProduct", errorIfNotFound && "No supplier product selected")
}

export function getPackagings(packagings, brand) {
    if (!brand) {
        return (packagings || []).filter(packaging => packaging.get("type") === "1" && !packaging.get("reusable"))
    }

    return (packagings || []).filter(packaging => (
        packaging.get("type") === "1" && (packaging.get("brands") && packaging.get("brands").includes(brand) && !packaging.get("reusable"))
    ))
}

export function getSubPackagings(packagings, brand) {
    if (!brand) {
        return (packagings || []).filter(packaging => packaging.get("type") === "2" && !packaging.get("reusable"))
    }
    return (packagings || []).filter(packaging => (
        packaging.get("type") === "2" && (packaging.get("brands") && packaging.get("brands").includes(brand) && !packaging.get("reusable"))
    ))
}

export function getReusablePackagings(packagings, brand) {
    if (!brand) {
        return (packagings || []).filter(packaging => packaging.get("type") === "1" && packaging.get("nature") === "REUSABLE")
    }

    return (packagings || []).filter(packaging => (
        packaging.get("type") === "1" && (packaging.get("brands") && packaging.get("brands").includes(brand) && packaging.get("nature") === "REUSABLE")
    ))
}

export function getReusableSubPackagings(packagings, brand) {
    if (!brand) {
        return (packagings || []).filter(packaging => packaging.get("type") === "2" && packaging.get("nature") === "REUSABLE")
    }
    return (packagings || []).filter(packaging => (
        packaging.get("type") === "2" && (packaging.get("brands") && packaging.get("brands").includes(brand) && packaging.get("nature") === "REUSABLE")
    ))
}

export function getSubcontractorProducts(state, errorIfNotFound = false) {
    return getData(state, "subcontractorsProducts.subcontractorsProductsList", errorIfNotFound && "No subcontractorsProduct found")
}

export function getSubcontractorProductSuppliers(state, errorIfNotFound = false) {
    return getData(state, "subcontractorsProducts.suppliers", errorIfNotFound && "No supplier found")
}
