import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { makeStyles } from "@mui/styles"
import TableCell from "@mui/material/TableCell"
import { filterOrdersReceptions, showUpdateOrder } from "../../../actions/Orders/Orders"
import {
    getOptionLabel,
    receptionStatus,
    STORAGE_ORDER_RECEPTION_KEY
} from "../../../utils/orderReception"
import { showSingleOrder } from "../../../actions/OrderReception/OrderReception"
import { computeOrdersLineSupplierItemsForCSV, ordersLineCsvHeader } from "../../../utils/ordersUtils"
import { exportCSVFile } from "../../../actions/Products/SearchAdvanced"
import DataGrid from "../../../components/DataGrid/DataGrid"
import { cloneDeep, debounce } from "lodash"
import { formatFiltersBeforeStorage, getDataGridFiltersStored } from "../../../utils/dataUtils"
import _cloneDeep from "lodash/cloneDeep"
import { isObject } from "lodash"
import dayjs from "dayjs"

const useStyles = makeStyles({
    statusCell: {
        padding: "4px 16px",
        borderRadius: 43,
        color: "#FFF",
        fontWeight: 500,
        borderBottom: 0
    }
})

const createData = (order) => {
    return {
        orderId: order.objectId,
        orderNumber: order.orderNumber,
        deliveryNoteNumber: order.deliveryNote ? order.deliveryNote.number : "",
        supplier: order.supplier.name,
        orderDate: order.orderDate ? moment(order.orderDate).format("DD/MM/YYYY") : "",
        receptionDateUnique: moment(order.receptionDate).format("DD/MM/YYYY"),
        receptionStatus: order.receptionStatus ? getOptionLabel(order.receptionStatus) : "",
        supplierItems: order.supplierItems.map(supplierItem => supplierItem.supplierItem && supplierItem.supplierItem.objectId)
    }
}


const OrdersReceptionsList = (props) => {
    const {
        suppliers,
        site,
        supplierItems,
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()
    const orders = useSelector(state => state.orderReception.data)
    const [loadingFilters, setLoadingFilters] = useState({})
    const [previousFilters, setPreviousFilters] = useState({})
    const [ordersData, setOrdersData] = useState([])
    const fixedAdditionalFilters = {
        receptionDate: {
            start: dayjs().subtract(1, "week").startOf("day").format(),
            end: dayjs().add(1, "week").endOf("day").format(),
            isRange: true
        }
    }

    const defaultAdditionalFilters = _cloneDeep(fixedAdditionalFilters)


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            // Custom logic to handle the refresh
            localStorage.removeItem("useStoredDataGridFilters")
            localStorage.removeItem("dataGridFilters")
        }
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const initDefaultFilters = () => {
        let defaultFilters = {}
        const useStoredDataGridFilters = localStorage.getItem("useStoredDataGridFilters")
        if (useStoredDataGridFilters && useStoredDataGridFilters === "1") {
            defaultFilters = getDataGridFiltersStored(supplierItems, defaultAdditionalFilters, "orderReception")
        }

        return defaultFilters || {}
    }

    const defaultFilters = initDefaultFilters()

    
    const now = moment().format("DD-MM-YY_HH-mm")
    const csvTitle = "orders_" + now

    const columns = [
        {
            key: "orderNumber",
            label: "N° de commande",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            }
        },
        {
            key: "deliveryNoteNumber",
            label: "N° de BL",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            }
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: suppliers.map((supplier) => ({
                    key: supplier.objectId,
                    value: supplier.name + " - " + supplier.objectId,
                    label: supplier.name
                }))
            }
        },
        {
            key: "orderDate",
            label: "Date de commande",
            dataType: "date",
            sortable: false,
            filter: {
                filterable: true,
                type: "date",
                strict: true
            }
        },
        {
            key: "receptionDateUnique",
            label: "Date de réception",
            dataType: "date",
            sortable: false,
            filter: {
                filterable: true,
                type: "date",
                strict: true
            }
        },
        {
            key: "receptionStatus",
            label: "Status de la réception",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: receptionStatus.map((status) => ({
                    key: status.value,
                    value: status.label,
                    label: status.label
                }))
            },
            render: (state) => {
                switch (state) {
                    case "Commande à réceptionner":
                        return <TableCell className={classes.statusCell} sx={{ backgroundColor: "#2196F3" }}>{state}</TableCell>
                    case "Réception en cours":
                        return <TableCell className={classes.statusCell} sx={{ backgroundColor: "#F2C046" }}>{state}</TableCell>
                    case "Réception clôturée":
                        return <TableCell className={classes.statusCell} sx={{ backgroundColor: "#48B468" }}>{state}</TableCell>
                    case "Réception annulée":
                        return <TableCell className={classes.statusCell} sx={{ backgroundColor: "#F44259" }}>{state}</TableCell>
                }
            }
        }
    ]

    const additionalFilters = [
        {
            key: "supplierItems",
            label: "Article fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                withSearch: true,
                filterable: true,
                type: "select",
                options: supplierItems.map((supplierItem) => ({
                    label: supplierItem.objectId + " - " + supplierItem.name,
                    value: supplierItem.name + " - " + supplierItem.objectId,
                    data: supplierItem,
                })),
                initialValue: ""
            },
        },
        {
            key: "receptionDate",
            label: "Période de réception",
            dataType: "dateRange",
            formLabel: "Indiquer la période de réception que vous souhaitez filtrer",
            sortable: false,
            undeletable: true,
            filter: {
                filterable: true,
                type: "dateRange"
            },
        },
    ]

    useEffect(() => {
        reloadData(loadingFilters)
    }, [loadingFilters])

    const reloadData = debounce(async (loadingFilters) => {
        if (Object.keys(loadingFilters).length > 0 && JSON.stringify(previousFilters) !== JSON.stringify(loadingFilters)) {
            await dispatch(filterOrdersReceptions(loadingFilters))
            setPreviousFilters(loadingFilters)
        }
    })

    useEffect(() => {
        setOrdersData(orders.map(order => createData(order)))
    }, [orders])

    const handleRowClick = (rowOrder) => {
        const selectedOrder = orders.find(order => order.objectId === rowOrder.orderId)
        if (selectedOrder.receptionStatus === "TODO") {
            dispatch(showUpdateOrder(site.objectId, selectedOrder.objectId))
            return
        }

        dispatch(showSingleOrder(site.objectId, selectedOrder.receptionDate, selectedOrder.objectId))
        // need the selected order reception to the other page even the page is reloaded
        localStorage.setItem(STORAGE_ORDER_RECEPTION_KEY, JSON.stringify(selectedOrder.objectId))
    }

    const exportOrdersLineToCSV = () => {
        const csvData = computeOrdersLineSupplierItemsForCSV(orders)
        dispatch(exportCSVFile(ordersLineCsvHeader, csvData, csvTitle))
    }

    const moreMenus = [
        {
            onClick: exportOrdersLineToCSV,
            label: "Extraire les lignes de commande"
        }
    ]

    const prepareFiltersForLoading = (filters, sortBy = "asc", sortField) => {
        const clonedFilters = cloneDeep(filters)
        if (clonedFilters.receptionDateUnique) {
            clonedFilters.receptionPeriodStart = isObject(clonedFilters.receptionDateUnique) ? clonedFilters.receptionDateUnique.valueOf() : dayjs.utc(clonedFilters.receptionDateUnique).startOf("day").valueOf()
            clonedFilters.receptionPeriodEnd = isObject(clonedFilters.receptionDateUnique) ? clonedFilters.receptionDateUnique.endOf("day").valueOf() : dayjs.utc(clonedFilters.receptionDateUnique).endOf("day").valueOf()
        }
        if (clonedFilters.receptionDate && !clonedFilters.receptionDateUnique) {
            clonedFilters.receptionPeriodStart = clonedFilters.receptionDate.start
            clonedFilters.receptionPeriodEnd = clonedFilters.receptionDate.end
            delete clonedFilters.receptionDate
        }

        if (clonedFilters.supplier) {
            // [0] = name | [1] = objectId
            // but some suppliers have a name with a dash already
            const indexOfLastDash = clonedFilters.supplier.lastIndexOf(" - ")
            const supplierValue = clonedFilters.supplier.substring(indexOfLastDash + 3)
            clonedFilters.suppliers = [{ value: supplierValue }]
        }

        if (clonedFilters.supplierItems) {
            // [0] = name | [1] = objectId
            // but some suppliers items have a name with a dash already
            const indexOfLastDash = clonedFilters.supplierItems.lastIndexOf(" - ")
            const supplierItemValue = clonedFilters.supplierItems.substring(indexOfLastDash + 3)
            clonedFilters.supplierItem = { value: supplierItemValue }
        }

        if (clonedFilters.receptionStatus) {
            const status = receptionStatus.find((r) => r.label === clonedFilters.receptionStatus).value
            clonedFilters.receptionStatus = [status]
        }
        clonedFilters.sortBy = sortBy
        clonedFilters.sortField = sortField

        return clonedFilters
    }

    const handleFiltersChange = async (filters, sortBy = "asc", sortField) => {
        if (sortField === "") {
            sortField = "receptionDate"
        }

        if (Object.keys(filters).length > 0) {
            const orderReceptionsFilters = prepareFiltersForLoading(filters, sortBy, sortField)
            const formattedFilters = formatFiltersBeforeStorage(filters, "orderReception")
            localStorage.setItem("dataGridFilters", JSON.stringify(formattedFilters))
            setLoadingFilters(orderReceptionsFilters)
        }
    }

    return (
        <DataGrid
            title="Commandes passées"
            columns={columns}
            data={ordersData}
            withFilters={true}
            onRowClick={handleRowClick}
            menus={moreMenus}
            additionalFilters={additionalFilters}
            defaultAdditionalFilters={defaultAdditionalFilters}
            asyncFilterFunction={handleFiltersChange}
            withAdditionalFiltersOptions={false}
            defaultPostResetFilter={true}
            initialValues={defaultFilters}
            fixedAdditionalFilters={fixedAdditionalFilters}
        />
    )
}

export default OrdersReceptionsList