const defaultState = {
    nbRecipes: 0,
    nbSubcontractorsProducts: 0,
    nbPackaging: 0,
    nbSections: 0,
    nbSubcontractors: 0,
    nbProductsTags: 0,
    nbChefs: 0
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "COUNT_PRODUCTS_QUANTITY":
            return {
                ...state,
                nbRecipes: action.nbRecipes,
                nbSubcontractorsProducts: action.nbSubcontractorsProducts,
            }
        case "COUNT_PRODUCTS_RESOURCES":
            return {
                ...state,
                nbPackaging: action.nbPackaging,
                nbSections: action.nbSections,
                nbProductsTags: action.nbProductsTags,
                nbChefs: action.nbChefs
            }
        default:
            return {
                ...state
            }
    }
}
