import PropTypes from "prop-types"
import React from "react"
import "typeface-roboto"
import MealPlannerCartSection from "./MealPlannerCartSection"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../utils"

const styles = {
    root: {
        backgroundColor: COLORS.WHITE,
        width: "100%",
        height: "calc(100vh - 65px)",
        overflowY: "auto",
        boxShadow: `0px 1px 1px ${COLORS.DEFAULT_GREY}`
    },
    warning: {
        width: "100%",
        padding: "5%",
        color: COLORS.LIGHT_GREY,
        textAlign: "center"
    }
}

/**
 * itemsToSections
 * Function to arrange an array of item by their productType property
 * @param  {object[]} items The flat items array
 * @return {object[]}       An array of product types, each containing the respective original items
 */
export const itemsToSections = (items, productTypeOptions = []) => {
    const result = items
        .reduce((result, item) => {
            if (item.productType) {
                const option = productTypeOptions.find(option => option.type === item.productType)

                if (option) {
                    result[item.productType] = result[item.productType]
                        || {
                        ...option,
                        title: option.label,
                        items: []
                    }

                    result[item.productType].items = [
                        ...result[item.productType].items,
                        item
                    ]
                }
            }

            return result
        }, {})

    return Object.values(result)
}

export const MealPlannerCart = ({
    classes = {},
    items = [],
    targetBrand = "FOODCHERI",
    productTypeOptions = [],
    updateItems
}) => {

    if (!Array.isArray(items) || items.length === 0) {
        return (
            <div className={classes.root}>
                <Typography className={classes.warning} variant="h6" align="left">
                    Vous n'avez aucun produit dans votre sélection
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            {itemsToSections(items, productTypeOptions)
                .map(section =>
                    <MealPlannerCartSection
                        key={section.type}
                        sectionItems={section.items}
                        title={section.title}
                        items={items}
                        targetBrand={targetBrand}
                        updateItems={updateItems}
                    />
                )
            }
        </div>
    )
}

MealPlannerCart.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            productType: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        })
    ),
    targetBrand: PropTypes.string,
    handleDelete: PropTypes.func,
    handleNationalSend: PropTypes.func,
    handleSendCapital: PropTypes.func,
    handleLunchbag: PropTypes.func
}

export default withStyles(styles)(MealPlannerCart)
