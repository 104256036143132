import React from "react"
import { Dialog, DialogContent } from "@mui/material"
import CloudinaryImage from "../../../components/Image/CloudinaryImage"
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined"
import { COLORS } from "../../../utils"

import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(({
	imageContainer: {
		composes: "flexCenter",
		height: "100%",
	},
	image: {
		width: "100%",
		maxHeight: "100%",
		objectFit: "cover"
	}
}))

const PackagingExecutionImagePreview = ({ open, onClose, packaging }) => {

	const classes = useStyles()

	return (
		<Dialog
			open={open}
			fullScreen
			sx={{
				"& .MuiDialog-paper": {
					borderRadius: "0px",
				}
			}}
		>
			<DialogContent
				sx={{
					padding: 0,
					overflow: "hidden",
				}}>
				<CloseFullscreenOutlinedIcon
					htmlColor={COLORS.RED}
					sx={{
						position: "absolute",
						top: 20,
						right: 20,
						width: 40,
						height: 40,
						cursor: "pointer"
					}}
					onClick={onClose}
				/>
				<div className={classes.imageContainer}>
					<CloudinaryImage
						imageId={packaging.packagingImage}
						containerClassName={classes.image}
					/>
				</div>
			</DialogContent>
		</Dialog>
	)

}

export default PackagingExecutionImagePreview