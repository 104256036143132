import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import dayjs from "dayjs"
import { Tabs, Tab } from "@mui/material"
import PackagingExecutionHeader from "./PackagingExecutionHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import PackagingExecutionTable from "./PackagingExecutionTable"
import { getPackagingExecutionDateSelector, getPackagingExecutionSiteSelector, getPackagingExecutionsSelector } from "../../reducers/Packaging/packagingExecution"
import { PE_STATUSES } from "../../utils/packagingExecutions"
import {showPackagingExecutionFilter} from "../../actions/PackagingExecution/packagingExecution"
import { sortPEByTimeFields } from "../../utils/productionStepExecution"
import { updateTab } from "../../actions/Utils/app"
import { getTab } from "../../reducers/Utils/app"

const useStyles = makeStyles(() => ({
	tab: {
		color: COLORS.PRODUCTION_STEPS_TEXT_GREY,
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: 500,
		textTransform: "uppercase",
		padding: "16px 24px"
	}
}))

const PackagingExecutionRead = () => {
	const dispatch = useDispatch()
	const defaultTabValue = "WEIGHT_TO_VALIDATE"
	const tabValue = useSelector((state) => getTab(state, "PackagingExecutionRead", defaultTabValue))
	const selectedSite = useSelector(getPackagingExecutionSiteSelector)
	const selectedDate = useSelector(getPackagingExecutionDateSelector)
	const packagingExecutions = useSelector(getPackagingExecutionsSelector)

	const [packagingExecutionsToDisplay, setPackagingExecutionsToDisplay] = useState(packagingExecutions)

	const toValidatePackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.toValidate)
	const lockedPackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.locked)
	const todoPackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.todo)
	const inProgressPackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.inProgress)
	const wastePackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.waste)
	const donePackagingExecutions = packagingExecutions.filter(packagingExecution => packagingExecution.status === PE_STATUSES.done)

	const _onTabChange = (_, newValue) => {
		dispatch(updateTab("PackagingExecutionRead", newValue))
	}

	useEffect(() => {
		switch (tabValue) {
			case "WEIGHT_TO_VALIDATE": {
				const sortedResults = sortPEByTimeFields([...toValidatePackagingExecutions, ...lockedPackagingExecutions])
				setPackagingExecutionsToDisplay(sortedResults)
				break
			}
			case "TODO":
				setPackagingExecutionsToDisplay(todoPackagingExecutions)
				break
			case "IN_PROGRESS": {
				const sortedResults = sortPEByTimeFields([...inProgressPackagingExecutions, ...wastePackagingExecutions])
				setPackagingExecutionsToDisplay(sortedResults)
				break
			}
			case "DONE":
				setPackagingExecutionsToDisplay(donePackagingExecutions)
				break
			default:
				break
		}
	}, [tabValue])



	const classes = useStyles()
	return (
		<div>
			<PackagingExecutionHeader
				leftAction={<GenericHeaderReturnButton handleClick={() => dispatch(showPackagingExecutionFilter())} />}
				subtitle={`${selectedSite.name} - ${dayjs(selectedDate).format("DD/MM/YYYY")}`}
			/>
			<Tabs
				value={tabValue}
				onChange={_onTabChange}
				indicatorColor="primary"
				textColor="primary"
				centered
				sx={{
					borderBottom: "1px solid #E6E6E6"
				}}
			>
				<Tab className={classes.tab} value="WEIGHT_TO_VALIDATE" label={`À VALIDER (${toValidatePackagingExecutions.length + lockedPackagingExecutions.length})`} />
				<Tab className={classes.tab} value="TODO" label={`A DÉMARRER (${todoPackagingExecutions.length})`} />
				<Tab className={classes.tab} value="IN_PROGRESS" label={`EN COURS (${inProgressPackagingExecutions.length + wastePackagingExecutions.length})`} />
				<Tab className={classes.tab} value="DONE" label={`TERMINÉS (${donePackagingExecutions.length})`} />
			</Tabs>
			<PackagingExecutionTable
				packagingExecutions={packagingExecutionsToDisplay}
				
				status={tabValue}
				date={selectedDate}
				site={selectedSite} />
		</div>
	)
}

export default PackagingExecutionRead