import React from "react"
import { useDispatch } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import {
    showSectionsGenericsResources,
    showPackagingResources,
    showChefsList,
    showReusableSteps
} from "../../../actions/Products/Products"
import {
    showProductsTagsList
} from "../../../actions/ProductsTags/ProductsTags"
import SettingsTabs from "../SettingsTabs"
import TabsContainer from "../Tabs/TabsContainer"
import TabPanel from "../Tabs/TabPanel"

const TechnicalSheetTabs = (props) => {
    const {
        tabValue,
        children
    } = props

    const dispatch = useDispatch()
    const _onTabChange = (event, value) => {
        if (value === "packaging") {
            dispatch(showPackagingResources())
            return
        }
        if (value === "sections") {
            dispatch(showSectionsGenericsResources())
            return
        }
    
        if (value === "steps") {
            dispatch(showReusableSteps())
            return
        }
        
        if (value === "productsTags") {
            dispatch(showProductsTagsList())
            return
        }
         if (value === "chefs") {
            dispatch(showChefsList())
        }
    }

    return (
        <SettingsTabs tabValue="technicalSheet">
            <TabsContainer>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                    sx={{
                        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
                        borderBottom: "1px solid #D8D8D8",
                    }}
                >
                    <Tab value="packaging" label="Packagings" />
                    <Tab value="sections" label="Sections" />
                    <Tab value="steps" label="Étapes" />
                    <Tab value="productsTags" label="Tags" />
                    <Tab value="chefs" label="Chefs" />
                </Tabs>
            </TabsContainer>
            <TabPanel top={20}>
                {children}
            </TabPanel>
        </SettingsTabs>
    )
}

export default TechnicalSheetTabs