import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import {
    COLORS,
    roundNumber
} from "../../../utils"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    label: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: 500,
        color: COLORS.SITE_INFORMATION_VALUE
    },
    amount: {
        fontSize: 40
    }
})

const CreditNoteAmount = (props) => {
    const {
        amount
    } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.label}>
                MONTANT  DE L’AVOIR <br /> (= MONTANT DE LA COMMANDE)
            </Typography>
            <Typography className={classes.amount}>
                {amount ? roundNumber(amount, 2).toFixed(2) : 0} €
            </Typography>
        </div>
    )
}

export default CreditNoteAmount