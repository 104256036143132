import React, { useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Stack } from "@mui/material"
import PSEPreparation from "../preview/PSEPreparation"
import ProductionStepExecutionLotsTable from "./ProductionStepExecutionLotsTable"
import { getProductionStepsKitchenAreaSelector } from "../../../reducers/Production/ProductionSteps"
import { loadPSEMachinesOptions } from "../../../actions/ProductionSteps/ProductionStepExecutions"
import PSEWeights from "../preview/PSEWeights"
import PSECookingRecap from "../preview/PSECookingRecap"
import PSECoolingRecap from "../preview/PSECoolingRecap"
import { getPriorStepData } from "../../../utils/productionStepExecution"

const ProductionStepExecutionInsufficientWeight = ({ productionStepExecution }) => {
	const dispatch = useDispatch()
	const transformationType = useMemo(() => productionStepExecution.productionStepSnapshot.transformation, [productionStepExecution])
	const kitchenArea = useSelector(getProductionStepsKitchenAreaSelector)

	const _loadMachinesOptions = (productionStepExecution, kitchenAreaId) => {
		dispatch(loadPSEMachinesOptions({ productionStepExecution, kitchenAreaId }))
	}

	useEffect(() => {
		if (transformationType === "COOLING" && kitchenArea && productionStepExecution) {
			_loadMachinesOptions(productionStepExecution, kitchenArea.objectId)
		}
	}, [productionStepExecution, transformationType, kitchenArea])

	return (
		<Stack spacing={3} sx={{ p: 3 }}>
			<PSEWeights
				productionStepExecution={productionStepExecution}
				status={productionStepExecution.status}
				isVisible
			/>
			<PSEPreparation productionStepExecution={productionStepExecution} />
			<PSECookingRecap
				visible={transformationType === "COOKING"}
				machinesBatch={productionStepExecution.machinesBatch}
			/>
			<PSECoolingRecap
				visible={transformationType === "COOLING"}
				machinesBatch={productionStepExecution.machinesBatch}
			/>
			<ProductionStepExecutionLotsTable
				productionStepExecution={productionStepExecution}
				priorStepData={getPriorStepData(productionStepExecution)}
			/>
		</Stack>
	)
}

export default ProductionStepExecutionInsufficientWeight