import Parse from "parse"
import {parseLimitRequest} from "../../utils"

const SupplierItem = Parse.Object.extend("SupplierItems")
const OrderSupplierItem = Parse.Object.extend("OrderSupplierItem")

export async function createOrderSupplierItem(supplierItemId) {
	try {
		const supplierItem = (await new Parse.Query(SupplierItem)
			.equalTo("objectId", supplierItemId)
			.include(["cookingModes.cookingMode", "commercialName", "supplier"])
			.first()) || null
		
		if (supplierItem){
			const orderSupplierItem = new OrderSupplierItem()
			
			orderSupplierItem.set("reference", supplierItem.get("supplierArticleId"))
			orderSupplierItem.set("type", supplierItem.get("type"))
			orderSupplierItem.set("units", supplierItem.get("units"))
			orderSupplierItem.set("name", supplierItem.get("name"))
			orderSupplierItem.set("commercialName", supplierItem.get("commercialName"))
			orderSupplierItem.set("supplierArticleName", supplierItem.get("supplierArticleName"))
			orderSupplierItem.set("supplierItemId", supplierItemId)
			orderSupplierItem.set("defaultSite", supplierItem.get("site") || null)
			orderSupplierItem.set("productType", supplierItem.get("productType") || null)
			orderSupplierItem.set("defaultStockZone", supplierItem.get("stockZone") || null)
		
			return await orderSupplierItem.save()
		}
		else {
			return null
		}
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getOrderSupplierItemsBySupplierItem(orderSupplierItemId, toJson=true) {
	try {
		const orderSupplierItems = await new Parse.Query(OrderSupplierItem)
			.equalTo("supplierItemId", orderSupplierItemId)
			.limit(parseLimitRequest)
			.find() || []

		return toJson ? orderSupplierItems.map(el => el.toJSON()) : orderSupplierItems
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getOrderSupplierItemsBySupplierItemList(orderSupplierItemIds, toJson=true) {
	try {
		const orderSupplierItems = await new Parse.Query(OrderSupplierItem)
			.containedIn("supplierItemId", orderSupplierItemIds)
			.limit(parseLimitRequest)
			.find() || []

		return toJson ? orderSupplierItems.map(el => el.toJSON()) : orderSupplierItems
	}
	catch (e) {
		return Promise.reject(e)
	}
}