import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import DispatchEnhancedTableHead from "../../../components/table-utils/Dispatch/DispatchEnhancedTableHead"
import SearchIcon from "@mui/icons-material/Search"
import InputLabel from "@mui/material/InputLabel"
import { FormControl } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import {
    COLORS, setSearchValue, checkMultiSelectInclude
} from "../../../utils"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { showHubDispatchDetail } from "../../../actions/Dispatch/dispatch"
import {
    stableSort,
    getSorting
} from "../../../components/table-utils/utils"
import { sectors } from "../../../utils/planning"
import moment from "moment"

const useClasses = makeStyles({
    root: {
        width: "100%"
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 24,
        width: "100%",
        paddingBottom: 20,
        backgroundColor: COLORS.LIGHT_GREY
    },
    searchIcon: {
        marginTop: 15,
        marginRight: 15
    },
    searchFieldInput: {
        width: 275
    },
    idColumn: {
        color: COLORS.BLACK,
        cursor: "pointer",
        fontSize: 24,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    nameColumn: {
        fontSize: 16,
        color: COLORS.DARK_GREY,
        letterSpacing: 0.25,
        lineHeight: "100%"
    },
    waitingColumn: {
        fontSize: 17,
        color: COLORS.BLACK,
        letterSpacing: 0.25,
        lineHeight: "100%",
        paddingRight: 10,
        paddingLeft: 15,
        textAlign: "center"
    },
    selectType: {
        marginLeft: "auto",
        marginRight: 20,
        marginTop: 15,
        width: 200
    },
    error: {
        color: COLORS.RED
    }
})

const getHubOptions = (hubs = []) => hubs.map((hub) => ({
    value: hub.objectId,
    label: hub.name
}))

const HubDispatchTable = (props) => {
    const {
        storageIndex,
        tableType,
        brand,
        date,
        hubs,
        hubTab,
        computedQuantity,
        canOpen = true,
        showHubDispatchDetail,
        site
    } = props
    const classes = useClasses()

    const order = "asc"
    const orderBy = "type"
    const selected = []
    const [searchFilter, setSearchFilter] = useState(props.searchFilter)
    const [filterData, setFilterData] = useState([])
    const [data, setData] = useState([])
    const [selectedHubs, setHubs] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])

    useEffect( () => {
        createDataObject()

        if ("" !== searchFilter) {
            updateSearchValue({target: {value: searchFilter, noStorageAdd: true}})
        }
    }, [])

    useEffect(() => {
        const hubOptions = getHubOptions(hubs)
        setHubs(hubOptions)
        createDataObject()
    }, [hubs, hubTab, selectedSectors, computedQuantity, searchFilter])

    const createDataObject = () => {
        const finalData = []
        let dataFilter = []

        if (searchFilter !== null && searchFilter !== ""){
            const regex = new RegExp(searchFilter, "ig")
            dataFilter = selectedHubs.filter(el => el.label.match(regex) !== null)
        }
        else {
            dataFilter = selectedHubs
        }
        setFilterData(dataFilter)
		
        for (const i in dataFilter) {
            const currentData = dataFilter[i]
            const currentComputed = computedQuantity[currentData.value]

            if (currentComputed) {
                let goOn = false

                if ([0].includes(hubTab) && currentComputed.ref !== 0) goOn = true

                if (([2,3].includes(hubTab) && currentComputed.ref === 0) || (1 === hubTab)) {
                    goOn =  (1 === hubTab)?currentComputed.hasToDo:
                        ((2 === hubTab)?(!currentComputed.hasToDo  && currentComputed.hasError):
                            (!currentComputed.hasToDo && !currentComputed.hasError))
                }

                if (goOn) {
                    const totalRef = currentComputed.ref + currentComputed.refToDo + currentComputed.refDispatched
                    const nbReference = (0 === hubTab)?currentComputed.ref:
                        ((1 === hubTab)?currentComputed.refToDo:
                        currentComputed.totalRef)

                    finalData.push({
                        uniqueCode: currentData.label.toUpperCase().replace(" ", "").slice(0,4),
                        idHub: currentData.value,
                        nbReference,
                        name: currentData.label,
                        dispatchedByTotal: `${currentComputed.refDispatched}/${totalRef}`,
                        dispatched: currentComputed.dispatched,
                        nbToDispatch: currentComputed.total
                    })
                }
            }
        }

        setData(finalData)
    }

    const updateSearchValue = (event) => {
        if (event) {
            setSearchFilter(event.target.value)
            if (!event.target.noStorageAdd) setSearchValue(event.target.value, storageIndex)
        }
        else {
            setSearchFilter(null)
        }
    }

    const _showDispatchDetail = (event, idHub) => {
        if (canOpen) {
            showHubDispatchDetail(
                moment(date).startOf("day").format("YYYY-MM-DD"),
                idHub,
                computedQuantity[idHub].elements[0],
                site.objectId,
            )
        }
    }

    const onSectorsChange = (eventSectors) => {
        const newSectors = checkMultiSelectInclude(sectors, selectedSectors, eventSectors)
        const newHubs = hubs.filter(el => newSectors.includes(el.sector)).map(el => ({
            value: el.objectId,
            label: el.name
        }))

        setHubs(newHubs)
        setSelectedSectors(newSectors)
    }

    const renderData = (objects, selectedObjects= false) => {
        return (
            objects.map((item, index) =>
                <MenuItem
                    value={item.value}
                    key={index}
                >
                    {selectedObjects &&
                    <Checkbox
                        checked={selectedObjects.includes(item.value)}
                        color="secondary"
                    />
                    }
                    <ListItemText primary={item.label} />
                </MenuItem>
            )
        )
    }

    const renderSelectResult = (selected, resources) => {
        if (selected.includes("ALL")) {
            return resources[0].label
        }
        else {
            return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
        }
    }

    const isSelected = id => selected.indexOf(id) !== -1

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.searchFieldDiv}>
                <SearchIcon className={classes.searchIcon}/>
                <TextField
                    variant="standard"
                    className={classes.searchFieldInput}
                    onChange={updateSearchValue}
                    label="Rechercher un centre de distribution"
                    defaultValue={searchFilter}
                />
                <FormControl className={classes.selectType} variant="standard">
                    <InputLabel htmlFor="sector">Secteur</InputLabel>
                    <Select
                        variant="standard"
                        multiple={true}
                        value={selectedSectors}
                        onChange={(e) => onSectorsChange(e.target.value)}
                        inputProps={{
                            name: "sector",
                            id: "sector",
                        }}
                        renderValue={
                            selected => renderSelectResult(selected, sectors)
                        }
                    >
                        { renderData(sectors, selectedSectors) }
                    </Select>
                </FormControl>
            </div>
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle">
                    <DispatchEnhancedTableHead
                        tableType={tableType}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        rowCount={filterData.length}
                        brand={brand}
                        hub={true}
                        hubTab={hubTab}
                    />
                    <TableBody>
                        {stableSort(data, getSorting(order, orderBy))
                            .map(n => {
                                const isSelectedRow = isSelected(n.id)
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelectedRow}
                                        tabIndex={-1}
                                        key={`${n.uniqueCode}${hubTab}${n.idHub}`}
                                        selected={isSelectedRow}
                                    >
                                        <TableCell className={classes.idColumn} component="th" scope="row">{n.uniqueCode}</TableCell>
                                        <TableCell className={classes.nameColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.name}</TableCell>
                                        {
                                            tableType === "todo" &&
                                            <TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.nbReference}</TableCell>
                                        }
                                        {
                                            tableType === "todo" && hubTab === 0 &&
                                            <TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.nbToDispatch}</TableCell>
                                        }
                                        <TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.dispatchedByTotal}</TableCell>
                                        {
                                            tableType !== "todo" &&
                                            <TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.nbToDispatch}</TableCell>
                                        }
                                        {
                                            tableType !== "todo" &&
                                            <TableCell className={classes.waitingColumn} onClick={event => _showDispatchDetail(event, n.idHub)}>{n.dispatched}</TableCell>
                                        }
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default connect(state => {
    return {
        date: state.dispatch.date,
        brand: state.dispatch.brand,
        hubs: state.dispatch.hubs,
        hubTab: state.dispatch.hubTab,
        computedQuantity: state.dispatch.computedQuantity,
        site: state.dispatch.site,
    }
}, {
    showHubDispatchDetail
})(HubDispatchTable)
