import {
    actionWithLoader,
    getReplacingPath,
    onEnter,
    haveAccessRight,
    push
} from "../Utils/utils"
import {
    createUser,
    getUsers,
    getUserById
} from "../../parseManager/rights/parseRights"
import {
    getRoles,
    getRoleById,
    getRights,
    deleteRoleWithId,
    createARole,
    removeParseUserToAParseRole,
    addParseUserToAParseRole, updateRightOfAParseRole
} from "../../parseManager/rights/parseRoles"
import { defaultRolesColumns } from "../../utils/rights"
import {rightReadRights} from "../../utils/accessRights"
import {deleteUsersAndRole} from "../../parseManager/user/parseUserManager"

function formatTableRolesColumns(roles) {
    const ret = [...defaultRolesColumns]
    for (const role of roles) {
        const obj = {name: role.objectId, title: role.name}
        ret.push(obj)
    }
    return ret
}

function formatTableRolesRows(roles, rights) {
    const ret = []
    const rubrics = []

    for (const right of rights) {
        if (!rubrics.includes(right.rubric)) {
            ret.push({
                id: right.rubric + "_parent",
                rubric: right.rubric,
                parentId: null
            })
            rubrics.push(right.rubric)
        }
        const obj = {
            id: right.name,
            parentId: right.rubric + "_parent",
            label: right.label
        }
        for (const role of roles) {
            obj[role.objectId] = !!(role.rights && role.rights[right.name])
        }
        ret.push(obj)
    }
    return ret
}

function loadRoles() {
    return actionWithLoader(async(dispatch) => {
        const roles = await getRoles()
        const rights = await getRights()
        const tableRolesColumns = formatTableRolesColumns(roles)
        const tableRolesRows = formatTableRolesRows(roles, rights)
        dispatch({
            type: "LOAD_ROLES",
            roles: roles,
            rights: rights,
            tableRolesColumns: tableRolesColumns,
            tableRolesRows: tableRolesRows
        })
    })
}

function loadUsers() {
    return actionWithLoader(async(dispatch) => {
        const users = await getUsers()//WithRoles()
        const roles = await getRoles()
        dispatch({
            type: "LOAD_USERS",
            users: users,
            roles: roles
        })
    })
}

export function updateRightOfRole(right, roleId) {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            const state = getState()

            // update database
            const role = await getRoleById(roleId, [], false)
            const rights = role.get("rights") || {}
            rights[right] = !rights[right]

            await updateRightOfAParseRole(role, rights)

            // update state
            const rightsState = { ...state.app.rights }
            rightsState[right] = !rightsState[right]
            dispatch({
                type: "USER_RIGHTS_LOADED",
                rights: rightsState
            })

            await dispatch(loadRoles())
        } catch(err) {
            await dispatch(openSnackBar("error", "Les droits d'un rôle n'ont pas pas eux être mis à jour"))
        }
    })
}

export function saveRole(values, id) {
    return actionWithLoader(async (dispatch) => {
        try {

            await createARole(id, values.name)
            const message = id ? "Un profil a été mis à jour" : "Un profil a été créé"
            await dispatch(openSnackBar("success", message))
            await dispatch(loadRoles())

        } catch(err) {
            const message = id ? "La mise a jour du profil n'a pas pu être effectué." : "La création du profil n'a pas pu aboutir."
            await dispatch(openSnackBar("error", message))
        }
    })
}

export function deleteRole(id) {
    return actionWithLoader(async (dispatch) => {
        try {
            await deleteRoleWithId(id)
            await dispatch(loadRoles())
            await dispatch(openSnackBar("success", "Le profil a été supprimé"))

        } catch(err) {
            await dispatch(openSnackBar("error", "Le profil n'a pas pu être supprimé."))
        }
    })
}

export function addUserToRole(userId, roleId) {
    return actionWithLoader(async (dispatch) => {
        try {
            const user = await getUserById(userId, [], false)
            const role = await getRoleById(roleId, [], false)

            if (!user) {
                await dispatch(openSnackBar("error", "L'utilisateur n'a pas pu être trouvé."))
                return
            }
            if (!role) {
                await dispatch(openSnackBar("error", "Le rôle n'a pas pu être trouvé"))
                return
            }
            await addParseUserToAParseRole(role, user)
            await dispatch(loadRoles())
        } catch(err) {
            await dispatch(openSnackBar("error", "Une erreur est survenue lors de l'ajout d'un rôle à un utilisateur "))
        }
    })

}
export function removeUserToRole(userId, roleId) {
    return actionWithLoader(async (dispatch) => {
        try {
            const user = await getUserById(userId, [], false)
            const role = await getRoleById(roleId, [], false)

            if (!user) {
                await dispatch(openSnackBar("error", "L'utilisateur n'a pas pu être trouvé."))
                return
            }
            if (!role) {
                await dispatch(openSnackBar("error", "Le rôle n'a pas pu être trouvé"))
                return
            }

            await removeParseUserToAParseRole(role, user)
            await dispatch(loadRoles())
        } catch(err) {
            await dispatch(openSnackBar("error", "Une erreur est survenue lors de la suppréssion d'un rôle à un utilisateur "))
        }
    })
}

export function createNewUser(values) {
    return actionWithLoader(async (dispatch) => {
        try {
            const userJson = {
                username: values.email,
                email: values.email,
                password: values.password,
                role: 0
            }
            await createUser(userJson)
            dispatch(loadUsers())
            await dispatch(openSnackBar("success", "Un utilisateur a été créé"))
        } catch(err) {
            await dispatch(openSnackBar("error", "Un nouvel utilisateur n'a pas pu être créé"))
        }
    })
}

export function deleteUsers(users) {
    return actionWithLoader(async (dispatch) => {
        try {
            await deleteUsersAndRole(users)
            await dispatch(loadUsers())

            await dispatch(openSnackBar("success", "Suppression réussi"))
        } catch(err) {
            await dispatch(openSnackBar("error", "Problème lors de la suppression"))
        }
    })
}

export function onEnterRightsRoles(store) {
    return onEnter({
        store,
        actionThunk: loadRoles,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight(rightReadRights)
    })
}

export function onEnterRightsUsers(store) {
    return onEnter({
        store,
        actionThunk: loadUsers,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight(rightReadRights)
    })
}
export function showRightsRoles() {
    return push("/rights/roles")
}

export function showRightsUsers() {
    return push("/rights/users")
}

export function openSnackBar(type, message) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "OPEN_RIGHTS_SNACKBAR",
            snackBar: {
                open: true,
                type: type,
                message: message,
                duration: 5000
            }
        })
    })
}

export function closeSnackBar(type) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "CLOSE_RIGHTS_SNACKBAR",
            snackBar: {
                open: false,
                type: type,
                message: "",
                duration: 0
            }
        })
    })
}
