import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useDispatch, useSelector } from "react-redux"
import SettingsTabs from "../SettingsTabs"
import TabsContainer from "../Tabs/TabsContainer"
import TabPanel from "../Tabs/TabPanel"
import { showPlanningSettingReport } from "../../../actions/Planning/Settings/PlanningReport"
import { showMachineTypes } from "../../../actions/Machines/Machines"
import { showTransformationModes } from "../../../actions/Supplier/transformationModes"
import { showProductionSchemas } from "../../../actions/ProductionSchemas/ProductionSchemas"

import { getUserRights } from "../../../reducers/Utils/app"
import { checkIfHaveRight } from "../../../actions/Utils/utils"

const ProductionTabs = (props) => {
    const {
        tabValue,
        children
    } = props

    const dispatch = useDispatch()

    const userRights = useSelector(getUserRights)
    const canReadPlanningRules = checkIfHaveRight( { readPlanningRules: true }, userRights)

    const _onTabChange = (event, tabValue) => {
        if (tabValue === "reports") {
            dispatch(showPlanningSettingReport())
            return
        }
        if (tabValue === "productionSchemas") {
            dispatch(showProductionSchemas())
        }
        if (tabValue === "machines") {
            dispatch(showMachineTypes())
        }
        if (tabValue === "transformationModes") {
            dispatch(showTransformationModes())
        }
    }

    return (
        <SettingsTabs tabValue="production">
            <TabsContainer>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                    sx={{
                        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
                        borderBottom: "1px solid #D8D8D8",
                    }}
                >
                    <Tab value="reports" label="Reports" />
                    {canReadPlanningRules && <Tab value="productionSchemas" label="Séquences de production" />}
                    <Tab value="machines" label="Machines" />
                    <Tab value="transformationModes" label="Mode de transformation" />
                </Tabs>
            </TabsContainer>
            <TabPanel top={21}>
                {children}
            </TabPanel>
        </SettingsTabs>
    )
}

export default ProductionTabs