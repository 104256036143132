import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    container: {
        height: 72,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    title: {
        color: COLORS.GREY_700,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "uppercase"
    },
    subTitle: {
        marginTop: 10,
        color: COLORS.DARK_GREY,
        fontSize: 12,
        textTransform: "uppercase"
    },
})

const SingleReceptionHeaderTitle = (props) => {
    const {
        headings,
    } = props
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>
                {(headings && headings.title) || ""}
            </Typography>
            <Typography className={classes.subtitle}>
                {(headings && headings.subtitle) || ""}
            </Typography>
        </div>
    )
}

export default SingleReceptionHeaderTitle