import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import AllergenIngredientEnhancedTableToolbar from "../../../../../components/table-utils/Ingredient/AllergenIngredientEnhancedTableToolbar"
import AllergenIngredientEnhancedTableHead from "../../../../../components/table-utils/Ingredient/AllergenIngredientEnhancedTableHead"
import TablePagination from "../../../../../components/TablePagination"
import {
    showSingleAllergenClassificationsIngredientsEdit,
    showSingleAllergenClassificationsIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Allergens/Allergens"
import moment from "moment"
import { stableSort, getSorting } from "../../../../../components/table-utils/utils"
import {setSearchValue} from "../../../../../utils"

const styles = {
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    searchFieldInput: {
        width: 300
    },
    column: {
        cursor: "pointer"
    }
}

class AllergenIngredientList extends PureComponent {
    constructor(props) {
        super(props)

        const storage = JSON.parse(localStorage.getItem("searchValue"))
        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            dataPage: 0,
            rowsPerPage: 10,
            searchFilter: (storage && storage.allergenIngredientList)?storage.allergenIngredientList:null,
            filterAllergensIngredients: [],
            data: []
        }
    }

    componentDidMount() {
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const { allergensIngredients } = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.allergensIngredients.length !== allergensIngredients.length){
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { allergensIngredients } = this.props
        let allergensIngredientsFilter = []

        if (this.state.searchFilter !== null && this.state.searchFilter !== ""){
            const regex = new RegExp(this.state.searchFilter, "ig")
            allergensIngredientsFilter = allergensIngredients.filter(allergenIngredient => allergenIngredient.name.match(regex) !== null)
        }
        else {
            allergensIngredientsFilter = allergensIngredients
        }

        this.setState({filterAllergensIngredients: allergensIngredientsFilter, data: allergensIngredientsFilter.map(this.createData)})
    }

    createData = (allergenIngredient) => {
        const { commercialsNames, subcontractorsCommercialsNames } = this.props
        const numberInterneProduct = commercialsNames.filter(commercialName => {
            const allergens = commercialName.allergens
            for (const i in allergens){
                if (allergens[i].objectId === allergenIngredient.objectId){
                    return commercialName
                }
            }
            return null
        })

        const numberSubcontractorProduct = subcontractorsCommercialsNames.filter(subcontractorCommercialName => {
            const allergens = subcontractorCommercialName.allergens
            for (const i in allergens){
                if (allergens[i].objectId === allergenIngredient.objectId){
                    return subcontractorCommercialName
                }
            }
            return null
        })

        return {
            id: allergenIngredient.objectId,
            name: allergenIngredient.name.toLowerCase(),
            interneIngredients: numberInterneProduct.length,
            subcontractorIngredients: numberSubcontractorProduct.length,
            createdAt: moment(allergenIngredient.createdAt).format("DD/MM/YY"),
            updatedAt: moment(allergenIngredient.updatedAt).format("DD/MM/YY")
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterAllergensIngredients } = this.state
            this.setState({selected: filterAllergensIngredients.map(allergenIngredient => allergenIngredient.objectId)})
            return
        }
        this.setState({selected: []})
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected })
    }

    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    updateSearchValue = (event) => {
        if (event) {
            this.setState({searchFilter: event.target.value, dataPage: 0})
            setSearchValue(event.target.value, "allergenIngredientList")
        }
        else {
            this.setState({searchFilter: null})
        }
    }

    _showSingleAllergenClassificationsIngredients = (event, allergenIngredientId) => {
        const { showSingleAllergenClassificationsIngredientsRead } = this.props
        showSingleAllergenClassificationsIngredientsRead(allergenIngredientId)
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    render() {
        const {
            classes,
            allergensIngredients,
            showSingleAllergenClassificationsIngredientsEdit
        } = this.props
        const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            data,
            dataPage,
            filterAllergensIngredients
        } = this.state

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper elevation={0} className={classes.root}>
                <AllergenIngredientEnhancedTableToolbar
                    numSelected={selected.length}
                    allergensIngredients={allergensIngredients}
                    createAllergenIngredient={showSingleAllergenClassificationsIngredientsEdit}
                    selected={selected}
                />
                <div className={classes.searchFieldDiv}>
                    <TextField
                        variant="standard"
                        defaultValue={this.state.searchFilter}
                        className={classes.searchFieldInput}
                        onChange={this.updateSearchValue}
                        label="Rechercher un allergène"
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <AllergenIngredientEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterAllergensIngredients.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell className={classes.column} padding="none" onClick={event => this._showSingleAllergenClassificationsIngredients(event, n.id)}>{n.name}</TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showSingleAllergenClassificationsIngredients(event, n.id)}>{n.interneIngredients}</TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showSingleAllergenClassificationsIngredients(event, n.id)}>{n.subcontractorIngredients}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleAllergenClassificationsIngredients(event, n.id)}>{n.createdAt}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showSingleAllergenClassificationsIngredients(event, n.id)}>{n.updatedAt}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterAllergensIngredients}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />
            </Paper>
        )
    }
}

AllergenIngredientList.propTypes = {
    classes: PropTypes.object.isRequired,
}

AllergenIngredientList = withStyles(styles)(AllergenIngredientList)

export default connect(state => {
    return {
        allergensIngredients: state.allergensIngredients.allergensIngredients,
        commercialsNames: state.allergensIngredients.commercialsNames,
        subcontractorsCommercialsNames: state.allergensIngredients.subcontractorsCommercialsNames
    }
}, {
    showSingleAllergenClassificationsIngredientsRead,
    showSingleAllergenClassificationsIngredientsEdit
})(AllergenIngredientList)
