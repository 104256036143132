import React from "react"
import ProductionStepsSupervisionHeader from "./ProductionStepsSupervisionHeader"

const ProductionStepsSupervision = ({ children, location }) => {
 return (
    <>
        <ProductionStepsSupervisionHeader pathname={location.pathname} />
        {children}
    </>
 )
}

export default ProductionStepsSupervision