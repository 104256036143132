import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"
import TextField from "@mui/material/TextField"
import OrderCardTitle from "../OrderCardTitle"

const useStyles = makeStyles({
    container: {
        minWidth: 700,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "nowrap"
    },
    subContentBlocEnd: {
        alignSelf: "center"
    },
    blocTitle: {
        color: "#555",
        fontFamily: "Roboto",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px"
    },
    quantity: {
        fontWeight: 400,
        fontSize: 14,
        color: COLORS.BLACK,
        maxWidth: 324,
        width: 324,
        textAlign: "start"
    },
    errorContainer: {
        marginTop: "16px"
    },
    label: {
        fontWeight: 400,
        color: COLORS.GREY_BORDER,
        fontSize: 14,
        marginTop: 10,
        textAlign: "end"
    },
})

const CreditNoteQuantity = (props) => {
    const {
        maxQuantity,
        defaultQuantity,
        callback,
        unit,
        quantityError = null,
        creditNotesAmount = 0
    } = props

    const classes = useStyles()
    const [quantity, setQuantity] = useState(null)

    useEffect(() => {
        if (!defaultQuantity) return
        setQuantity(defaultQuantity)
    }, [defaultQuantity])

    const handleQuantityChange = (e) => {
        const value = e.target.value

        if (value !== "") {
            if (parseFloat(value) > 0 && parseFloat(value) <= maxQuantity) {
                setQuantity(parseFloat(value))
                callback({
                    method: "UPDATE_QUANTITY",
                    data: {
                        quantity: parseFloat(value)
                    }
                })

            }

        }
        else {
            setQuantity(value)
            callback({
                method: "UPDATE_QUANTITY",
                data: {
                    quantity: value
                }
            })
        }
    }

    return (
        <Stack direction="column" sx={{
            width: "100%",
            flex: 1
        }}>
            <Stack
                sx={{
                    p: 2,
                    gap: 2,
                    borderRadius: "8px",
                    border: quantityError ? "1px solid #F44259" : "1px solid #E6E6E6",
                }}>
                <OrderCardTitle title="Mise à la casse" />
                <Stack spacing={3} direction="row" justifyContent="start">
                    <div className={classes.subContentBloc}>
                        <TextField
                            name="quantity"
                            type="number"
                            variant="standard"
                            value={quantity}
                            onChange={handleQuantityChange}
                            autoFocus
                            label={"Quantité mise à la casse"}
                            placeholder="0"
                            inputProps={{
                                className: classes.quantity,
                                style: { textAlign: "center" },
                                step: 1,
                                min: 1,
                                max: +maxQuantity
                            }}
                        />
                        <Typography className={classes.label}>
                            Quantité restante : {maxQuantity} ({unit})
                        </Typography>
                    </div>
                    <div className={classes.subContentBlocEnd}>
                        <Typography className={classes.blocTitle}>
                            Montant de l'avoir : {creditNotesAmount} €
                        </Typography>
                    </div>
                </Stack>
            </Stack>
            {quantityError && <div className={classes.errorContainer}>
                <Typography variant={"error"}>{quantityError}</Typography>
            </div>}
        </Stack>
    )
}

export default CreditNoteQuantity