import React from "react"
import { Stack, styled, Typography } from "@mui/material"
import { COLORS } from "../../../utils"

const StyledLabel = styled(Typography)({
	fontSize: 20,
	fontWeight: 500,
	color: COLORS.LABEL_GREY,
	textAlign: "center",
	lineHeight: 1.2
})

const StyledValue = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isEmptyValue" || prop !== "isRealValue"
})(({ isEmptyValue, isRealValue }) => ({
	fontSize: 20,
	fontWeight: 400,
	color: (isEmptyValue || isRealValue) ? "#000" : COLORS.PRODUCTION_STEPS_DISABLE_TEXT,
	textAlign: "center",
	lineHeight: 1.5
}))


const PSEItem = ({ label, value, isEmptyValue, isRealValue }) => {
	return (
		<Stack alignItems="center" spacing={1}>
			<StyledLabel>
				{label}
			</StyledLabel>
			<StyledValue isEmptyValue={isEmptyValue} isRealValue={isRealValue}>
				{value}
			</StyledValue>
		</Stack>
	)
}

export default PSEItem