import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useDispatch } from "react-redux"
import SettingsTabs from "../SettingsTabs"
import TabsContainer from "../Tabs/TabsContainer"
import TabPanel from "../Tabs/TabPanel"
import { showSitesList } from "../../../actions/Site/sites"
import { showPlanningSettingsDistributionCentersList } from "../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"
import { showPlanningSettingsDispatchPriority } from "../../../actions/Planning/Settings/PlanningSettingsDispatchPriority"

const SitesAndHubsTabs = (props) => {
    const {
        tabValue,
        children
    } = props
    
    const dispatch = useDispatch()

    const _onTabChange = (event, tabValue) => {
        if (tabValue === "sites") {
            dispatch(showSitesList())
            return
        }
        if (tabValue === "distributionCenters") {
            dispatch(showPlanningSettingsDistributionCentersList())
            return
        }
        if (tabValue === "dispatchPriority") {
            dispatch(showPlanningSettingsDispatchPriority())
        }
    }
    
    return (
        <SettingsTabs tabValue="sitesAndHubs">
            <TabsContainer>
                <Tabs
                    value={tabValue ? tabValue : false}
                    onChange={_onTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                    sx={{
                        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
                        borderBottom: "1px solid #D8D8D8",
                    }}
                >
                    <Tab value="sites" label="Sites" />
                    <Tab value="distributionCenters" label="Centres de distribution" />
                    <Tab value="dispatchPriority" label="Priorités de dispatch" />
                </Tabs>
            </TabsContainer>
            <TabPanel top={14}>
                {children}
            </TabPanel>
        </SettingsTabs>
    )
}

export default SitesAndHubsTabs