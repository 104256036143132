import React from "react"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      position: "relative",
      height: 75,
      "&.no-border": {
        border: "none",
      },
      "& > div": {
        height: "100%",
        paddingLeft: 28,
        "& > button": {
          paddingRight: 19,
          "& span": {
            width: 15.58,
            height: 15.17,
          },
        },
      },
    },
    title: {
      display: "flex",
      color: COLORS.BLACK_CARD,
      fontWeight: "bold",
      fontSize: 32,
      flex: 1,
    },
    subTitle: {},
  })
)

const ActionHeader = (props) => {
  const { text, handleClose, noBorder } = props
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.appBar, { "no-border": noBorder })}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        {text}
      </Toolbar>
    </AppBar>
  )
}

export default ActionHeader
