import PropTypes from "prop-types"
import React from "react"
import withStyles from "@mui/styles/withStyles"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import StarRate from "@mui/icons-material/StarRate"
import { lightGreen } from "../../styles"
import LinesEllipsis from "react-lines-ellipsis"
import { COLORS, roundCurrency } from "../../utils"
import CloudinaryImage from "../Image/CloudinaryImage"

const cardStyle = {
    margin: 0,
    maxWidth: 243,
    display: "flex",
    backgroundColor: COLORS.WHITE
}

const cardContentStyle = {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    boxSizing: "borderBox",
    padding: 8,
    margin: "0 auto"
}

const cardNameStyle = {
    marginBottom: 5,
    fontWeight: 600,
    overflow: "hidden"
}

const cardImageStyle = {
    display: "block",
    width: "auto",
    maxWidth: "100%",
    alignSelf: "center",
    margin: "0 auto 8px"
}

const styles = {
    card: cardStyle,
    cardSelected: {
        ...cardStyle,
        backgroundColor: lightGreen,
    },
    cardContent: {
        ...cardContentStyle,
    },
    weeklyCardContent: {
        ...cardContentStyle,
        height: 212,
    },
    cardImage: {
        ...cardImageStyle,
        height: 150,
    },
    weeklyCardImage: {
        ...cardImageStyle,
        height: 116
    },
    cardName: {
        ...cardNameStyle,
        height: 60,
        fontSize: 13,
    },
    weeklyCardName: {
        ...cardNameStyle,
        fontSize: 12
    },
    cardRating: {
        fontSize: 11,
        lineHeight: 1.3,
        letterSpacing: .15,
        color: COLORS.LIGHT_GREY,
        display: "inline-flex",
        alignItems: "center",
        fontWeight: 500,
        "& > *": {
            flex: "0 0 auto"
        }
    },
    cardRatingIcon: {
        width: 18,
        height: 18
    },
    foodcost: {
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 12,
        marginBottom: 5
    }
}


export const MealPlannerCard = props => {
    const {
        item = {},
        onClick,
        classes = {},
        selected,
        isWeekly = false
    } = props

    return (
        <Card title={item.name} className={selected ? classes.cardSelected : classes.card} sx={{ boxShadow: isWeekly && "0px 1px 5px 0px rgba(0, 0, 0, 0.15)" }} onClick={() => onClick(item)}>
            <CardActionArea>
                <CardContent className={isWeekly ? classes.weeklyCardContent : classes.cardContent}>
                    {
                        item.image && item.image.publicId
                            ?
                            <CloudinaryImage containerClassName={isWeekly ? classes.weeklyCardImage : classes.cardImage} imageId={item.image && item.image.publicId} />
                            :
                            <img
                                className={isWeekly ? classes.weeklyCardImage : classes.cardImage}
                                src="/img/noImageAvailabled.png"
                                alt="Non disponible"
                            />
                    }
                    <Typography className={isWeekly ? classes.weeklyCardName : classes.cardName}>
                        <LinesEllipsis component="span" text={item.uniqueCode + " - " + item.name + " (" + item.price + "€)"} maxLine={3} ellipsis="…" />
                    </Typography>
                    {
                        item.rating &&
                        <Typography className={classes.cardRating}>
                            <StarRate className={classes.cardRatingIcon} fontSize="inherit" />&nbsp;{item.rating}
                        </Typography>
                    }
                    {
                        item.foodcost &&
                        <Typography className={classes.foodcost}>
                            {`Foodcost = ${roundCurrency(item.foodcost)}€`}
                        </Typography>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

MealPlannerCard.propTypes = {
    item: PropTypes.shape({
        image: PropTypes.shape({
            publicId: PropTypes.string
        }),
        name: PropTypes.string,
        rating: PropTypes.number,
    }),
    onClick: PropTypes.func,
    classes: PropTypes.object,
}

export default withStyles(styles)(MealPlannerCard)
