import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"

const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		width: 438,
		outline: "none",
		margin: "0 auto",
		top: "25%",
		position: "relative",
		borderRadius: 1,
		color: COLORS.BLACK,
		padding: "30px 30px 20px 30px"
	},
	title: {
		fontSize: 15,
		fontWeight: 500
	},
	actionsBloc: {
		textAlign: "end"
	},
	btn: {
		margin: 10
	}
}))

const CreateOrderReceptionModal = (props) => {
	const {
		onCreateOrder,
		suppliers,
		onClose
	} = props
	
	const classes = useStyles()
	const [currentSupplier, setCurrentSupplier] = useState(suppliers[0].objectId)
	
	const renderSuppliers = () => {
		return suppliers.map((el, i) =>
			<MenuItem key={i} value={el.objectId}>{el.name}</MenuItem>
		)
	}
	
	const confirm = () => {
		onCreateOrder(currentSupplier)
		onClose()
	}
	
	return (
		<div className={classes.container}>
			<p className={classes.title}>
				Indiquez le fournisseur pour lequel vous souhaitez créer une réception.
			</p>
			<FormControl variant="standard">
				<InputLabel variant="standard" htmlFor="uncontrolled-native">
					Fournisseurs
				</InputLabel>
				<Select
					variant="standard"
					value={currentSupplier}
					onChange={(e) => {setCurrentSupplier(e.target.value)}}
				>
					{ renderSuppliers() }
				</Select>
			</FormControl>
			<div className={classes.actionsBloc}>
				<Button
					color="primary"
					variant="outlined"
					onClick={onClose}
					className={classes.btn}
				>
					RETOUR
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={confirm}
					className={classes.btn}
				>
					CRÉER
				</Button>
			</div>
		</div>
	)
}

export default CreateOrderReceptionModal