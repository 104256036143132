import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import { Tab, Tabs } from "@mui/material"

import {
    showInternalCommercialNames
} from "../../../actions/Ingredients/Internal/CommercialsNames"
import IngredientPageHeader from "../IngredientPageHeader"
import Header from "./Header"

import { updateIngredientDetails, updateIngredientAllergens, updateIngredientSourcing } from "../../../actions/Ingredients/Internal/CommercialsNames"
import IngredientDetails from "../IngredientDetails"
import IngredientConnections from "../IngredientConnections"
import { getIngredient } from "../../../reducers/Ingredients/IngredientsPage"

const styles = {
    container: {
        padding: 20
    },
    subContainer: {
    },
    closeButtonContainer: {
        height: 64,
        textAlign: "right"
    },
    closeButton: {
    },
    valueContainer: {
        margin: 16
    },
    redirectButtonContainer: {
        height: 64,
        textAlign: "right"
    },
    redirectButton: {
    },
    main: {
        boxSizing: "inherit",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    tabs: {
        width: "100%",
        "& button": {
            margin: "0 20px"
        }
    },
    header: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
}

export const CommercialName = (props) => {
    const { classes } = props

    // Selector
    const ingredient = useSelector(getIngredient)

    const [tabValue, setTabValue] = useState(0)
    const dispatch = useDispatch()

    const ingredientType = "interne"

    const onBack = () => {
        dispatch(showInternalCommercialNames())
    }
 
    const onUpdateIngredient = useCallback(async (key, values) => {
        // ALL UPDATE FUNCTION ARE READY TO BE USED
        switch (key) {
            case "SOURCING":
                return await dispatch(updateIngredientSourcing(ingredient.objectId, values, ingredientType))
            case "ALLERGENS":
                return await dispatch(updateIngredientAllergens(ingredient.objectId, values, ingredientType))
            case "RESUME":
                return await dispatch(updateIngredientDetails(ingredient.objectId, values, ingredientType))
            default:
                return
        }
    }, [ingredient])

    return (
        <div className={classes.main}>
            <IngredientPageHeader className={classes.headerHeight} onBack={onBack} withBackButton ingredientType={ingredientType} />
            <Header commercialName={ingredient} commercialNameType={ingredientType} />
            <Paper className={classes.header}>
                <Tabs
                    className={classes.tabs}
                    value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                    onChange={(e, value) => setTabValue(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Général" />
                    <Tab label="Rattachement" />
                </Tabs>
            </Paper>
            {tabValue === 0 && <IngredientDetails ingredient={ingredient} onSave={onUpdateIngredient} ingredientType={ingredientType} />}
            {tabValue === 1 && <IngredientConnections />}
        </div>
    )
}

export default withStyles(styles)(CommercialName)