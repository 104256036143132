import React, {useState} from "react"
import withStyles from "@mui/styles/withStyles"
import { getValues } from "../../parseUtils"
import IconButton from "@mui/material/IconButton"
import { Field, Form, Formik} from "formik"
import { recipeFormstyles } from "../../styles"
import { COLORS } from "../../utils"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputBase from "@mui/material/InputBase"
import Paper from "@mui/material/Paper"
import DeleteIcon from "@mui/icons-material/Clear"
import AddIcon from "@mui/icons-material/Add"
import Divider from "@mui/material/Divider"
import InputLabel from "@mui/material/InputLabel"
import SubcontractorCommercialNameAutocomplete from "../autoComplete/SubcontractorCommercialNameAutocomplete"
import SeasonChooser from "../../components/form/SeasonChooser"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import _cloneDeep from "lodash/cloneDeep"
import Switch from "@mui/material/Switch"

export const CommercialNamesSeasonEdit = (props) => {
    const {
        classes,
        jsonSubcontractorProduct,
        parseSubcontractorProduct,
        onClose,
        isEditing,
        onSaveCommercialNames
    } = props


    /* SubcontractorCommercialNames */
    const subcontractorProductObject = getValues(parseSubcontractorProduct, ["subcontractorCommercialNames"])

    if (subcontractorProductObject.subcontractorCommercialNames) {
        subcontractorProductObject.subcontractorCommercialNames = subcontractorProductObject.subcontractorCommercialNames.filter(subcontractorProduct => !!subcontractorProduct.commercialName).map(subcontractorCommercialName => {
            return {
                id: subcontractorCommercialName.commercialName.id,
                label: subcontractorCommercialName.commercialName.get("name"),
                pourcentage: subcontractorCommercialName.pourcentage,
                cooked: subcontractorCommercialName.cooked
            }
        })

        subcontractorProductObject.subcontractorCommercialNames.sort(function(a, b) {
            return b.pourcentage - a.pourcentage
        })
    }

    const [subcontractorCommercialNames, setSubcontractorCommercialNames] = useState(subcontractorProductObject.subcontractorCommercialNames)
    const [newSubcontractorCommercialName, setNewSubcontractorCommercialName] = useState()

    const renderSubcontractorCommercialNames = (setFieldValue) => {
        if (subcontractorCommercialNames) {
            const paperBlock = subcontractorCommercialNames.map((commercialName, index) =>
                <div key={index}>
                    <Paper key={"commercialName" + index} className={classes.subcontractorCommercialNameBloc} elevation={1}>
                        <InputBase
                            className={classes.subcontractorCommercialNameInput} disabled
                            value={commercialName.label}
                        />
                        <Divider className={classes.divider} />
                        <Switch
                            checked={commercialName.cooked}
                            onChange={(event) => {updateSubcontractorCooked(event, index, setFieldValue)}}
                            color="primary"
                        />
                        Cuit
                        <Divider className={classes.divider} />
                        <InputBase
                            className={classes.subcontractorPourcentageInput}
                            type="number"
                            value={commercialName.pourcentage}
                            onChange={(event) => {updateSubcontractorPourcentage(event, index, setFieldValue)} }
                        />
                        %
                        <Divider className={classes.divider}/>
                        <IconButton
                            color="primary"
                            className={classes.iconButton}
                            aria-label="Directions"
                            onClick={() => {_removeSubcontractorCommercialName(index, setFieldValue)} }
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Paper>
                </div>
            )
            const finalResult = []
            let i = 0
            while (i < subcontractorCommercialNames.length){
                finalResult.push(
                    <div key={"commercialNameBloc" + i} className="flexRow marginBottom20">
                        {paperBlock[i] ? paperBlock[i] : ""}
                        {paperBlock[i + 1] ? paperBlock[i + 1] : ""}
                        {paperBlock[i + 2] ? paperBlock[i + 2] : ""}
                        {paperBlock[i + 3] ? paperBlock[i + 3] : ""}
                    </div>
                )
                i+=4
            }
            return finalResult
        }
    }

    const _removeSubcontractorCommercialName = (index, setFielValue) => {
        const copy = _cloneDeep(subcontractorCommercialNames)

        copy.splice(index, 1)

        setSubcontractorCommercialNames(copy)
        setFielValue("subcontractorCommercialNames", copy)
    }

    const _onEnterNewSubcontractorCommercialName = (value, setFieldValue) => {
        setNewSubcontractorCommercialName(value)
        _addSubcontractorCommercialName(value, setFieldValue)
    }

    const sortSubcontractorCommercialNames = (setFieldValue) => {
        const copy = _cloneDeep(subcontractorCommercialNames)

        copy.sort(function(a, b) {
            return b.pourcentage - a.pourcentage
        })

        setSubcontractorCommercialNames(copy)
        setFieldValue("subcontractorCommercialNames", copy)
    }

    const _addSubcontractorCommercialName = (value = null, setFieldValue) => {
        if (value !== null) {
            setSubcontractorCommercialNames([ ...subcontractorCommercialNames, value ])
            setNewSubcontractorCommercialName(null)

            setFieldValue("subcontractorCommercialNames", [ ...subcontractorCommercialNames, value ])
        } else if (newSubcontractorCommercialName) {
            setSubcontractorCommercialNames([ ...subcontractorCommercialNames, newSubcontractorCommercialName ])
            setNewSubcontractorCommercialName(null)

            setFieldValue("subcontractorCommercialNames", [ ...subcontractorCommercialNames, newSubcontractorCommercialName ])
        }

        setFieldValue("addSubcontractorCommercialName", null)
    }

    const updateSubcontractorPourcentage = (event, index, setFieldValue) => {
        const copy = _cloneDeep(subcontractorCommercialNames)

        copy[index].pourcentage = event.target.value !== "" ? parseFloat(event.target.value) : ""

        setSubcontractorCommercialNames(copy)
        setFieldValue("subcontractorCommercialNames", copy)
    }

    const updateSubcontractorCooked = (event, index, setFieldValue) => {
        const copy = _cloneDeep(subcontractorCommercialNames)

        copy[index].cooked = event.target.checked

        setSubcontractorCommercialNames(copy)
        setFieldValue("subcontractorCommercialNames", copy)
    }

    const submitForm = (values) => {
        onSaveCommercialNames(values)
        onClose()
    }

    return (
        <Formik
            initialValues={{subcontractorCommercialNames}}
            onSubmit={submitForm}
        >
            {({
                  setFieldValue,
                  handleSubmit
              }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Ingrédients du produit :</span>
                            </DialogTitle>
                            <DialogContent  className={classes.dialogContent}>
                                <div className={!(jsonSubcontractorProduct.name && jsonSubcontractorProduct.name.supplier) ? "hiddenSize" : "showBlock"}>
                                    <div className={classes.flexRowCentered}>
                                        <FormControl margin="normal" className={classes.formControlMin400}>
                                            <Field
                                                name="addSubcontractorCommercialName"
                                                value={newSubcontractorCommercialName}
                                                placeHolder="Ajouter un ingrédient sous-traitant"
                                            >
                                                {({field, meta}) => {
                                                    field.onChange = (value) => setNewSubcontractorCommercialName(value)
                                                    return (
                                                        <SubcontractorCommercialNameAutocomplete
                                                            input={field}
                                                            name="addSubcontractorCommercialName"
                                                            supplierId={jsonSubcontractorProduct.name && jsonSubcontractorProduct.name.supplier && jsonSubcontractorProduct.name.supplier.objectId}
                                                            currentSubcontractorCommercialNames={subcontractorCommercialNames}
                                                            onEnterValidation={(value) => _onEnterNewSubcontractorCommercialName(value, setFieldValue)}
                                                            currentValue={newSubcontractorCommercialName}
                                                            meta={meta}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </FormControl>
                                        <FormControl margin="normal" classes={{root: classes.formControl}}>
                                            <AddIcon className={classes.addIcon} onClick={() => _addSubcontractorCommercialName(null, setFieldValue)}/>
                                        </FormControl>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => sortSubcontractorCommercialNames(setFieldValue)}
                                        >
                                            Trier les ingrédients
                                        </Button>
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <div className={classes.formContainer}>
                                        <div className={!(jsonSubcontractorProduct.name && jsonSubcontractorProduct.name.supplier) ? "hiddenSize" : "showBlock"}>
                                            <div className="flexColumn">
                                                {renderSubcontractorCommercialNames(setFieldValue)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.seasonContainer}>
                                    <InputLabel htmlFor="season" classes={{root: classes.labelSeason}}>
                                        Saison :
                                    </InputLabel>
                                    <Field
                                        name="season"
                                    >
                                        {({field, meta}) => {
                                            field.onChange = (value) => setFieldValue("season", value)
                                            return (
                                                <SeasonChooser
                                                    input={field}
                                                    name="season"
                                                    meta={meta}
                                                />
                                            )
                                        }}
                                    </Field>
                                </div>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    onClick={onClose}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    color="primary"
                                    variant="contained"
                                >
                                    Sauvegarder
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = {
    ...recipeFormstyles,
    dialog: {
        "& .MuiPaper-root.MuiDialog-paperWidthSm": {
            minWidth: "85vw",
            maxWidth: "auto"
        }
    },
    dialogContent: {
        minHeight: 300
    },
    flexRowCentered: {
        composes: "flexRow",
        alignItems: "center",
        marginBottom: 10,
        "& > div": {
            marginRight: 10
        }
    },
    rootDialogActions: {
        justifyContent: "space-between"
    },
    formControlMin400: {
        minWidth: 400
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    titleBase: {
        fontSize: "1.3em",
        fontWeight: 400,
    },
    dialogTitleLabel: {
        composes: "$titleBase",
        margin: "5px 0px 0px 0px"
    },
    labelSeason: {
        composes: "$titleBase",
        color: COLORS.BLACK,
        marginRight: 20,
        paddingTop: 10
    }
}

export default withStyles(styles)(CommercialNamesSeasonEdit)
