import React from "react"
import { makeStyles } from "@mui/styles"
import { orderMode } from "../../../utils/ordersUtils"
import { COLORS } from "../../../utils"
import Button from "@mui/material/Button"

const useStyles = makeStyles({
    container: {

    },
    updateActionBloc: {
        padding: 17,
        display: "flex",
        justifyContent: "center",
        backgroundColor: COLORS.LIGHT_GREY
    },
    updateButton: {
        marginLeft: 16
    },
    createActionBloc: {
        borderTop: `1px solid ${COLORS.DEFAULT_GREY}`,
        backgroundColor: COLORS.WHITE,
        display: "flex",
        justifyContent: "flex-end",
        padding: 12
    }
})

const OrderInfosAction = (props) => {
    const {
        mode,
        actions,
        cart,
        isValidOrder,
        order
    } = props
    
    const classes = useStyles()
    
    const onCreate = () => {
        actions({
            method: "CREATE"
        })
    }
    
    const onDelete = () => {
        actions({
            method: "DELETE"
        })
    }
    
    const onValidate = () => {
        actions({
            method: "COMMENT"
        })
    }

    const onCancel = () => {
        actions({
            method: "CANCEL"
        })
    }

    return (
        <div className={classes.container}>
            {
                mode === orderMode.UPDATE
                    ? order && order.orderStatus === "DONE"
                        ? (
                            <div className={classes.updateActionBloc}>
                                <Button
                                    color="primary"
                                    onClick={onCancel}
                                >
                                    ANNULER LA COMMANDE
                                </Button>
                                <Button
                                    className={classes.updateButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={onValidate}
                                >
                                    METTRE A JOUR
                                </Button>
                            </div>
                        ) : (
                            <div className={classes.updateActionBloc}>
                                <Button
                                    color="primary"
                                    onClick={onDelete}
                                >
                                    SUPPRIMER
                                </Button>
                                <Button
                                    className={classes.updateButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={onValidate}
                                    disabled={!isValidOrder}
                                >
                                    COMMANDER
                                </Button>
                            </div> 
                        ) 
                    : null
            }
            {
                mode === orderMode.CREATE &&
                    <div className={classes.createActionBloc}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={cart.size < 1}
                            onClick={onCreate}
                        >
                            VALIDER
                        </Button>
                    </div>
            }
        </div>
    )
}

export default OrderInfosAction