import React, { useState } from "react"
import { Box, Table, TableBody, TableCell, TableRow, Fab } from "@mui/material"
import SettingsIcon from "@mui/icons-material/Settings"
import { Add } from "@mui/icons-material"

import EditionPageHeader from "../../../../components/EditionPageHeader"
import ProductTypeFormModal from "./ProductTypeFormModal"
import { EDITION_PAGE_INITIAL_ACTIONS } from "../../../../utils/constant"
import { useDispatch, useSelector } from "react-redux"
import { COLORS, generateRandomToken } from "../../../../utils"
import { saveProductTypes, showAdministrationProductTypes } from "../../../../actions/ProductTypes/productTypes"
import { getProductTypesSelector } from "../../../../reducers/ProductTypes/productTypes"
import ProductTypesTableHead from "./ProductTypesTableHead"
import Brands from "../../../../components/Brands"
import ProductTypesPagination from "./ProductTypesPagination"
import { formatManagementMode } from "../../../../utils/productTypesUtils"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const ProductType = ({ productType, index, handleRowClick }) => {
    const _onClick = () => {
        handleRowClick(productType)
    }

    return (
        <Draggable index={index} draggableId={productType.objectId}>
            {
                (provided) => (
                    <TableRow
                        hover
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={_onClick}
                    >
                        <TableCell>{productType.label}</TableCell>
                        <TableCell>
                            {productType.order}
                        </TableCell>
                        <TableCell>{productType.adj}</TableCell>
                        <TableCell>{productType.kfcName}</TableCell>
                        <TableCell>{productType.fcSzName}</TableCell>
                        <TableCell>{productType.uniqueCode}</TableCell>
                        <TableCell>{formatManagementMode(productType.managementMode)}</TableCell>
                        <TableCell>
                            <Brands brands={productType.brands} />
                        </TableCell>
                    </TableRow>
                )
            }
        </Draggable>
    )
}

const ProductTypeItems = React.memo(({ productTypes, handleRowClick }) => (
    <>
        {
            productTypes.map((productType, index) => (
                <ProductType productType={productType} index={index} key={productType.objectId} handleRowClick={handleRowClick} />
            ))
        }
    </>
))

const EditProductTypes = () => {
    const storedProductTypes = useSelector(getProductTypesSelector)

    const [openCreationDialog, setOpenCreationDialog] = useState(false)
    const [actions, setActions] = useState(EDITION_PAGE_INITIAL_ACTIONS)
    const [productTypes, updateProductTypes] = useState(storedProductTypes || [])
    const [selectedProductType, setSelectedProductType] = useState(null)

    const dispatch = useDispatch()

    const toggleDialog = () => setOpenCreationDialog(!openCreationDialog)
    const clearSelectedProductType = () => setSelectedProductType(null)

    const editProducType = (productType) => setSelectedProductType(productType)

    const _addProductType = values => {
        const newProductType = {
            objectId: generateRandomToken(),
            isNew: true,
            ...values,
        }

        _updateProductTypes([...productTypes, newProductType])
    }

    const onSubmitModal = ({ values, inEditionMode = false, order }) => {

        if (!inEditionMode) {
            // in creation mode
            _addProductType(values)
            setActions(prev => ({ ...prev, hasAdded: true }))
        } else {
            // in edition mode
            const editedProductTypeIndex = productTypes.findIndex(productType => productType.order === order)
            if (editedProductTypeIndex >= 0) {
                if (Array.isArray(values?.uniqueCode)) {
                    values.uniqueCode = values.uniqueCode.join("")
                }
                Object.assign(productTypes[editedProductTypeIndex], values)
                productTypes[editedProductTypeIndex].edited = true
                setActions(prev => ({ ...prev, hasEdited: true }))
                _updateProductTypes([...productTypes])
            }
            clearSelectedProductType()
        }
    }

    const onSaveAll = () => {
        // just close if nothing are changed
        const hasChanged = Object.values(actions).some(value => value)
        if (!hasChanged) {
            dispatch(showAdministrationProductTypes())
            return
        }

        // the values is from the store in the action
        dispatch(saveProductTypes(productTypes))
    }

    const onBack = () => {
        dispatch(showAdministrationProductTypes())
    }

    const _updateProductTypes = productTypes => {
        // change order rank
        productTypes.forEach((productType, index) => {
            const prevOrder = productType.order
            productType.order = index + 1
            if (prevOrder !== productType.order) {
                productType.edited = true
            }
        })
        // then set updated product types
        updateProductTypes([...productTypes])
    }

    // a little function to help with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const _onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const reorderedProductTypes = reorder(productTypes, result.source.index, result.destination.index)
        setActions(prev => ({ ...prev, hasEdited: true }))
        _updateProductTypes([...reorderedProductTypes])
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <EditionPageHeader
                icon={<SettingsIcon />}
                onCancel={onBack}
                onSave={onSaveAll}
                title="Réglages"
                color={COLORS.HEAD_SEAZON}
            />

            {/* --------- table --------- */}
            <Table aria-labelledby="tableTitle">
                <ProductTypesTableHead />
                <DragDropContext onDragEnd={_onDragEnd}>
                    <Droppable droppableId="productTypesList">
                        {
                            (provided) => (
                                <TableBody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        !Array.isArray(productTypes) || !productTypes.length
                                            ? (
                                                <TableRow>
                                                    <TableCell colSpan={3} cellAlign='center'>Aucun type de produit</TableCell>
                                                </TableRow>
                                            )
                                            : (
                                                <>
                                                    <ProductTypeItems
                                                        productTypes={productTypes}
                                                        handleRowClick={editProducType}
                                                    />
                                                    {provided.placeholder}
                                                </>
                                            )
                                    }
                                </TableBody>
                            )
                        }
                    </Droppable>
                </DragDropContext>
            </Table>

            {/* --------- pagination --------- */}
            <ProductTypesPagination />

            {/* --------- creation modal --------- */}
            <Box sx={{ position: "fixed", left: 260, bottom: 30 }}>
                <Fab color="primary" onClick={toggleDialog}>
                    <Add />
                </Fab>
            </Box>
            <ProductTypeFormModal
                open={!!selectedProductType || openCreationDialog}
                onClose={selectedProductType ? clearSelectedProductType : toggleDialog}
                onConfirm={onSubmitModal}
                productType={selectedProductType}
            />
        </Box>
    )
}

export default EditProductTypes