import React, { useState, useEffect } from "react"
import { MenuItem, Checkbox, Stack } from "@mui/material"
import { COLLECTION_NAMES } from "../../../parseUtils"
import dayjs from "dayjs"

const ProductionStepExecutionSuppliesSelector = ({
	secondaryDLCs,
	lots,
	setFieldValue,
	values,
	errors
}) => {

	const [formattedOptions, setFormattedOptions] = useState([])

	const reformatSuppliesOptions = (supplies, className) => {
		return supplies.map((supply) => ({
			value: supply.objectId,
			className: className,
			name: className === COLLECTION_NAMES.lot ? `${supply.lotNumber} (${dayjs(supply.dlc).format("DD/MM/YYYY")})` : `DLC 2nd (${dayjs(supply.secondaryDLC).format("DD/MM/YYYY")})`
		}))
	}

	useEffect(() => {
		if (lots && secondaryDLCs) {
			setFormattedOptions([
				...reformatSuppliesOptions(secondaryDLCs, COLLECTION_NAMES.secondaryDLC),
				...reformatSuppliesOptions(lots, COLLECTION_NAMES.lot),
			])
		}
	}, [lots, secondaryDLCs])

	if (!formattedOptions.length) {
		return (<span>-</span>)
	}

	return (
		<Stack gap={1}>
			{formattedOptions.map((option) => (
				<MenuItem
					key={option.value}
					sx={{ padding: 0 }}
				>
					<Checkbox
						disableRipple
						sx={{
							paddingTop: 0,
							paddingBottom: 0,
							color: errors.lots && "#F44259"
						}}
						checked={values.secondaryDLCs.includes(option.value) || values.lots.includes(option.value)}
						onClick={() => {
							if (option.className === COLLECTION_NAMES.lot) {
								if (values.lots.includes(option.value)) {
									setFieldValue("lots", values.lots.filter((lot) => lot !== option.value))
									return
								}
								setFieldValue("lots", [...values.lots, option.value])
							}
							if (option.className === COLLECTION_NAMES.secondaryDLC) {
								if (values.secondaryDLCs.includes(option.value)) {
									setFieldValue("secondaryDLCs", values.secondaryDLCs.filter((secondaryDLC) => secondaryDLC !== option.value))
									return
								}
								setFieldValue("secondaryDLCs", [...values.secondaryDLCs, option.value])
							}
						}}
					/>
					{option.name}
				</MenuItem>
			)
			)}
		</Stack>
	)
}

export default ProductionStepExecutionSuppliesSelector