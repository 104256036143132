import React from "react"
import TechnicalSheetTabs from "../TechnicalSheetTabs"
import ChefsList from "./Chefs/ChefsList"

export const ChefsTabs = () => {
    return (
        <TechnicalSheetTabs tabValue="chefs">
            <ChefsList/>
        </TechnicalSheetTabs>
    )
}

export default ChefsTabs
