import React from "react"
import Typography from "@mui/material/Typography"
import { Box, Icon, styled } from "@mui/material"

const StyledIcon = styled(Icon, {
    shouldForwardProp: (prop) => prop !== "iconColor"
  })((props) => ({
    marginRight: 10,
    color: props.iconColor,
    height: 24
  }))

const PageHeaderTitle = ({ icon, title, color, subtitle }) => {
    return (
        <Box display="flex" alignItems="center">
            <StyledIcon iconColor={color}>
                {icon}
            </StyledIcon>
            <Typography variant="h6" color="textPrimary" sx={{ color, margin: 0 }} gutterBottom>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="h6" color="textSecondary" sx={{ fontWeight: 400 }}>
                    &nbsp;- {subtitle}
                </Typography>
            )}
        </Box>
    )
}

export default PageHeaderTitle
