import React from "react"
import { Typography, Icon } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DateRangeIcon from "@mui/icons-material/DateRange"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import { COLORS } from "../../utils"

const useStyles = makeStyles(() => ({
  secondaryDLCHeader: {
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15) !important",
    gap: 8
  },
  headerTitle: {
    composes: "flexClassicCenter",
    gap: "8px",
    color: COLORS.SECONDARY_DLC_BLUE
  }
}))

const SecondaryDLCHeader = ({ title = "DLC Secondaires ", backAction, info, rightAction }) => {

  const classes = useStyles()

  return (
      <GenericTopBarHeader
        leftAction={!backAction ? null :
          <GenericHeaderReturnButton
            onBack={backAction}
          />
        }
        title={
          <div className={classes.headerTitle}>
            <Icon>
              <DateRangeIcon />
            </Icon>
            <Typography variant="h7" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </div>
        }
        info={
          <div className="headerInfo">
            {info}
          </div>
        }
        rightAction={rightAction}
        className={classes.secondaryDLCHeader}
      />
  )
}

export default SecondaryDLCHeader

