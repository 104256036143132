import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import BackIcon from "@mui/icons-material/KeyboardBackspace"
import ConfirmIcon from "@mui/icons-material/Done"
import WarningIcon from "@mui/icons-material/ErrorOutline"
import IconButton from "@mui/material/IconButton"
import { Modal, Typography } from "@mui/material"
import { COLORS, KFC_BRANDS } from "../../../utils"
import moment from "moment"
import {
    closeDispatchSnackBar,
    computeDispatch,
    updateProductDispatchHubVolume,
    updateSelectedProductDispatch,
    finishDispatch,
    closeDispatchDetail,
    createHubLocker,
    removeHubLocker
} from "../../../actions/Dispatch/dispatch"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import {
    computeDispatchIndexPosition,
    isDispatchComplete,
    getProductDispatchProd,
    getProductDispatchDlc,
    getProductDispatchProductTypeLabel,
    getProductDispatchVolumePerStockUnit,
    isAmbientProduct,
    hasHubLocked,
    isFoodCheriAmbient,
    isProdWarehouseNull
} from "../../../utils/dispatchUtils"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import _cloneDeep from "lodash/cloneDeep"
import _isEqual from "lodash/isEqual"
import { confirm } from "../../../components/react-await-dialog/react-await-dialog"
import { packagingNature } from "../../../utils/packaging"
import { generateStockUnitLabel } from "../../../utils/orderReception"
import {refreshPage} from "../../../actions/Utils/utils"

const styles = {
    dispatchModalDetail: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll",
        zIndex: 1300,
        height: "100%",
        display: "block"
    },
    divider: {
        border: "1px solid #e8e8e8",
        width: "100%"
    },
    header: {
        composes: "flexRow",
        height: 75,
        width: "100%",
        alignItems: "center"
    },
    backIconContainer: {
        marginLeft: 15
    },
    backIcon: {
        color: COLORS.DARK_GREY
    },
    titleItem: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "100%",
        fontStyle: "normal",
        maxWidth: 400
    },
    detailInfoBloc: {
        marginLeft: 20
    },
    moreInfosItem: {
        color: COLORS.DARK_GREY,
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "normal",
        marginTop: 5
    },
    spanDetail: {
        marginRight: 15
    },
    contentBloc: {
        textAlign: "center",
        marginTop: 65,
        color: COLORS.BLACK,
        fontSize: 27,
        lineHeight: "32px"
    },
    subContentBloc: {
        composes: "flexRow",
        justifyContent: "center",
        marginTop: 43,
        marginBottom: 43
    },
    subContentBlocTitle: {
        color: COLORS.DARK_GREY,
        fontSize: 20,
        lineHeight: "100%"
    },
    verticalDivider: {
        border: "1px solid #e8e8e8",
        height: 100,
        width: 1,
        marginLeft: 40,
        marginRight: 40
    },
    contentLeftBlocValue: {
        fontSize: 70,
        color: COLORS.BLACK,
        lineHeight: "35px",
        marginTop: 60
    },
    contentRightBlocInput: {
        marginTop: 15,
        "& .MuiInputBase-input": {
            textAlign: "center"
        }
    },
    formControl: {
        width: 200
    },
    contentRightBlocTextField: {
        textAlign: "center",
        fontSize: 70,
    },
    contentRightBlocTextFieldError: {
        composes: "$contentRightBlocTextField",
        color: COLORS.RED
    },
    contentRightBlocTextFieldSuccess: {
        composes: "$contentRightBlocTextField",
        color: COLORS.PRIMARY_COLOR
    },
    confirmButtonBasic: {
        composes: "flexRow",
        height: 72,
        width: 410,
        fontSize: 20,
        textAlign: "center",
        alignItems: "center",
        fontWeight: 500,
        cursor: "pointer",
        borderRadius: 5
    },
    confirmButtonEmpty: {
        composes: "$confirmButtonBasic",
        backgroundColor: COLORS.LIGHT_GREY,
        color: COLORS.DARK_GREY
    },
    confirmButtonFill: {
        composes: "$confirmButtonBasic",
        backgroundColor: COLORS.PRIMARY_COLOR,
        color: COLORS.TEXT_COLOR_FOR_PRIMARY
    },
    noOutline: {
        outline: "none"
    },
    buttonText: {
        margin: "0 auto"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    },
    endButton: {
        composes: "flexRow",
        backgroundColor: COLORS.PRIMARY_COLOR,
        height: 72,
        width: 410,
        color: COLORS.TEXT_COLOR_FOR_PRIMARY,
        margin: "0 auto",
        marginTop: 40,
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 500,
        alignItems: "center",
        cursor: "pointer"
    },
    endButtonText: {
        margin: "0 auto"
    },
    doneIcon: {
        color: COLORS.PRIMARY_COLOR,
        fontSize: 70
    },
    rightBlocInfos: {
        composes: "flewRow",
        marginLeft: "auto"
    },
    floatLeft: {
        float: "left",
        textAlign: "center",
        marginRight: 15
    },
    dividerHeader: {
        composes: "$floatLeft",
        border: "1px solid #e8e8e8",
        height: 34
    },
    headerRightTextDesc: {
        color: COLORS.DARK_GREY,
        fontSize: 10
    },
    headerRightTextNumber: {
        fontSize: 21,
        color: COLORS.BLACK
    },
    warningBloc: {
        composes: "flexRow",
        justifyContent: "center",
        marginTop: 10
    },
    warningIcon: {
        color: COLORS.DARK_GREY,
        fontSize: 20,
        marginRight: 10
    },
    warningText: {
        fontSize: 17,
        lineHeight: "20px",
        color: COLORS.BLACK
    },
    uniqueCode: {
      fontSize: 34
    },
    hide: {
        display: "none"
    },
    reusable: {
        mask: "url(/img/recycle.svg) no-repeat center",
        maskSize: "contain",
        width: 36,
        height: 28,
        marginTop: 6,
        marginLeft: 10,
        display: "inline-block"
    },
    labelContainer: {
        marginTop: 54,
    },
    label: {
        fontWeight: 400,
        color: COLORS.DARK_GREY,
        fontSize: 14,
        marginTop: 10
    },
}

class DispatchDetail extends PureComponent {

    constructor(props) {
        super(props)
        const { selectedProductDispatch } = this.props
        const index = selectedProductDispatch ? computeDispatchIndexPosition(selectedProductDispatch) : 0

        if (selectedProductDispatch && selectedProductDispatch.hubs[index]) {
            createHubLocker(selectedProductDispatch, selectedProductDispatch.hubs[index].idHub)
        }
        this.state = {
            firstPageDetail: hasHubLocked(selectedProductDispatch),
            inputValue: (selectedProductDispatch && selectedProductDispatch.prodWarehouse !== null
                && selectedProductDispatch.hubs[index] && selectedProductDispatch.hubs[index].volume === 0)?"0":"",
            index: index
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedProductDispatch,
            closeDispatchDetail,
            subcontractorProduct,
            brand
        } = this.props
        const {
            firstPageDetail,
            index
        } = this.state

        if (prevState.index !== index) {
            if (firstPageDetail=== false && selectedProductDispatch) {
                if (selectedProductDispatch.hubs[prevState.index]) {
                    removeHubLocker(selectedProductDispatch, selectedProductDispatch.hubs[prevState.index].idHub)
                }

                if (selectedProductDispatch.hubs[index]) {
                    createHubLocker(selectedProductDispatch, selectedProductDispatch.hubs[index].idHub)
                }
            }
        }

        if (selectedProductDispatch && (selectedProductDispatch.kfcStatus === "DONE" || selectedProductDispatch.kfcStatus === "TODO")) {
            if (selectedProductDispatch.hubs[index]) {
                removeHubLocker(selectedProductDispatch, selectedProductDispatch.hubs[index].idHub)
            }

            closeDispatchDetail(selectedProductDispatch)
        }
        if (this.realQuantityInput){
            this.realQuantityInput.focus()
        }
        const isFirstPage = isFoodCheriAmbient(subcontractorProduct, brand)
            // for ambient products, the prodWareHouse is not null, so we check if at least one hub is dispatched (locked)
            ? hasHubLocked(selectedProductDispatch)
            // for non-ambient products, the prodWareHouse is null in the first page
            : isProdWarehouseNull(selectedProductDispatch)

        if (
            (!_isEqual(prevProps.selectedProductDispatch, selectedProductDispatch)) ||
            (isFirstPage && firstPageDetail === false)
        ) {
            const newIndex = selectedProductDispatch ? computeDispatchIndexPosition(selectedProductDispatch) : 0
            this.setState({
                firstPageDetail: isFirstPage,
                index: newIndex,
                inputValue: (selectedProductDispatch && selectedProductDispatch.prodWarehouse !== null
                    && selectedProductDispatch.hubs[newIndex] && selectedProductDispatch.hubs[newIndex].volume === 0)?"0":""
            })
        }

    }

    _onClose = async (end) => {
        const {
            closeDispatchDetail,
            selectedProductDispatch,
            finishDispatch
        } = this.props

        if (selectedProductDispatch && selectedProductDispatch.hubs[this.state.index]) {
            removeHubLocker(selectedProductDispatch, selectedProductDispatch.hubs[this.state.index].idHub)
        }

        if (end){
            finishDispatch(selectedProductDispatch)
        }
        else {
            closeDispatchDetail(selectedProductDispatch)
        }
    }

    getBlocTitle = (packaging) => {
        const { selectedProductDispatch } = this.props
        const {
            firstPageDetail,
            index
        } = this.state

        if (index !== selectedProductDispatch.hubs.length){
            if (firstPageDetail){
                return <span>
                    Entrez le total des { selectedProductDispatch.uniqueCode } en entrepôt
                    {
                        packaging &&
                        <span> en packaging { packaging.toUpperCase() } </span>
                    }
                </span>
            }
            else {
                return <span>
                    Quantité pour le hub : { selectedProductDispatch.hubs[index].name }
                    {
                        packaging &&
                        <span> en packaging { packaging.toUpperCase() } </span>
                    }
                </span>
            }
        }
    }

    _onInputValueChange = (event) => {
        this.setState({
            inputValue: event.target.value
        })
    }

    _onSkipStep = () => {
        const {
            selectedProductDispatch,
            updateSelectedProductDispatch
        } = this.props
        const { index } = this.state
        const copy = _cloneDeep(selectedProductDispatch)

        copy.hubs[index].skipped = true

        updateSelectedProductDispatch(copy)

        this.setState({
            inputValue: ""
        })
    }

    _confirmStep = async () => {
        const {
            selectedProductDispatch,
            computeDispatch,
            updateProductDispatchHubVolume,
            subcontractorProduct,
            brand
        } = this.props
        const {
            firstPageDetail,
            inputValue,
            index
        } = this.state
        const copy = _cloneDeep(selectedProductDispatch)

        let value = inputValue
        if (copy && isAmbientProduct(copy) && brand === "FOODCHERI") {
            const stockUnitQuantity = subcontractorProduct.name.units.stock.unity.quantity
            value = (+inputValue * stockUnitQuantity).toString() // format to number later
        }

        if (value !== "" && !value.includes(",") && !value.includes(".")) {
            if (firstPageDetail && value == "0" && (await confirm({
                title: "Confirm",
                message: "Vous avez entré un volume à 0. Le produit ne sera pas dispatché.",
                confirmText: "Valider"
            }))) {
                await computeDispatch(copy, parseFloat(value))
                for (const hub in copy.hubs) {
                    await updateProductDispatchHubVolume(copy, copy.hubs[hub], parseFloat(value), false, true)
                }

                refreshPage()
            } else if (
              (copy.hubs[index].volume === Number(value) && !firstPageDetail)
              || (getProductDispatchProd(copy, subcontractorProduct, this.props.brand) === Number(inputValue) && firstPageDetail)
              || (await confirm({
                  title: "Confirm",
                  message: "La valeur indiquée est différente de celle attendue. Êtes vous sûr?",
                  confirmText: "Oui"
              }))
            ) {
                if (firstPageDetail) {
                    this.setState({
                        firstPageDetail: false,
                        inputValue: ""
                    })

                    await computeDispatch(copy, parseFloat(value))
                }
                else {
                    this.setState({
                        inputValue: ""
                    })

                    const currentHub = index >= 0 && index < copy.hubs.length ? copy.hubs[index] : null
                    await updateProductDispatchHubVolume(copy, currentHub, parseFloat(value))
                }
            }
        }
    }

    _onKeyDown = (event) => {
        if (event && event.keyCode === 13) {
            this._confirmStep()
            event.preventDefault()
        }
    }

    computeVolumeDispatch = () => {
        const { selectedProductDispatch } = this.props
        let count = 0
        
        for (const i in selectedProductDispatch.hubs){
            const current = selectedProductDispatch.hubs[i]

            count += current.realQuantity
        }

        return count
    }

    computeVolumeAvailable = () => {
        const { selectedProductDispatch } = this.props

        return selectedProductDispatch.prodWarehouse - this.computeVolumeDispatch()
    }

    getBlocInput = () => {
        const {
            selectedProductDispatch,
            subcontractorProduct,
            classes
        } = this.props
        const {
            firstPageDetail,
            inputValue,
            index
        } = this.state

        if (isDispatchComplete(selectedProductDispatch)){
            return (
                <div>
                    <div>
                        <ConfirmIcon className={classes.doneIcon} />
                    </div>
                    <div>
                        Dispatch du produit terminé
                        {selectedProductDispatch.hasError &&
                            <div className={classes.warningBloc}>
                                <WarningIcon className={classes.warningIcon}/>
                                <div className={classes.warningText}>
                                    Envoyé pour vérification
                                </div>
                            </div>
                        }
                    </div>
                    <div className={classes.endButton} onClick={() => {this._onClose(true)}}>
                        <span className={classes.endButtonText}>
                            RETOURNER À LA LISTE
                        </span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={classes.subContentBloc}>
                    <div className="flexColumn center">
                        <div>
                            <span className={classes.subContentBlocTitle}>
                                QUANTITÉ ATTENDUE
                            </span>
                        </div>
                        <div className={classes.contentLeftBlocValue}>
                            <span>
                                {firstPageDetail
                                    ? getProductDispatchProd(selectedProductDispatch, subcontractorProduct, this.props.brand)
                                    : getProductDispatchVolumePerStockUnit(selectedProductDispatch.hubs[index].volume, selectedProductDispatch, subcontractorProduct, this.props.brand)
                                }
                            </span>
                        </div>
                        {/* unit label */}
                        {subcontractorProduct && this.props.brand === "FOODCHERI" && (
                            <div className={classes.labelContainer}>
                                <Typography className={classes.label}>
                                    {generateStockUnitLabel(subcontractorProduct.name)}
                                </Typography>
                            </div>
                        )}
                    </div>
                    <div className={classes.verticalDivider} />
                    <div>
                        <div><span className={classes.subContentBlocTitle}>
                            TOTAL À DISPATCHER
                        </span>
                        </div>
                        <div className={classes.contentRightBlocInput}>
                            {firstPageDetail &&
                                <FormControl margin="normal" classes={{root: classes.formControl}} required>
                                    <TextField
                                        variant="standard"
                                        name="realQuantity"
                                        type="number"
                                        InputProps={{
                                            className: parseFloat(inputValue) === getProductDispatchProd(selectedProductDispatch, subcontractorProduct, this.props.brand)
                                                ? classes.contentRightBlocTextFieldSuccess
                                                : classes.contentRightBlocTextFieldError
                                        }}
                                        autoFocus
                                        value={inputValue}
                                        onChange={this._onInputValueChange}
                                        onKeyDown={this._onKeyDown}
                                        inputRef={(input) => { this.realQuantityInput = input}}
                                    />
                                </FormControl>
                            }
                            {!firstPageDetail &&
                                <FormControl margin="normal" classes={{root: classes.formControl}} required>
                                    <TextField
                                        variant="standard"
                                        name="realQuantity"
                                        type="number"
                                        InputProps={{
                                            className: parseFloat(inputValue) === getProductDispatchVolumePerStockUnit(selectedProductDispatch.hubs[index].volume, selectedProductDispatch, subcontractorProduct, this.props.brand)
                                                ? classes.contentRightBlocTextFieldSuccess
                                                : classes.contentRightBlocTextFieldError
                                        }}
                                        autoFocus
                                        value={inputValue}
                                        onChange={this._onInputValueChange}
                                        onKeyDown={this._onKeyDown}
                                        inputRef={(input) => { this.realQuantityInput = input}}
                                    />
                                </FormControl>
                            }
                            {subcontractorProduct && this.props.brand === "FOODCHERI" && (
                                <Typography className={classes.label}>
                                    {generateStockUnitLabel(subcontractorProduct.name)}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const {
            classes,
            date,
            selectedProductDispatch,
            closeDispatchSnackBar,
            dispatchSnackBar
        } = this.props

        const {
            firstPageDetail,
            index,
            inputValue
        } = this.state
    
        const packaging =  (
            selectedProductDispatch && selectedProductDispatch.productionItem && selectedProductDispatch.productionItem.packaging &&
            packagingNature.find(el => el.value === selectedProductDispatch.productionItem.packaging).label
        ) || false
        
        return (
            <Modal key="dispatch-detail-modal" BackdropProps={{invisible: true}} className={classes.dispatchModalDetail} open={true}>
                <div className={classes.noOutline}>
                    {selectedProductDispatch &&
                        <div>
                            <div className={classes.header}>
                                {!isDispatchComplete(selectedProductDispatch) &&
                                    <IconButton
                                        className={classes.backIconContainer}
                                        aria-label="Retour"
                                        onClick={() => {
                                            this._onClose(false)
                                        }}
                                        size="large">
                                        <BackIcon className={classes.backIcon}/>
                                    </IconButton>
                                }
                                <div className={classes.uniqueCode}>
                                  {selectedProductDispatch.uniqueCode}
                                </div>
                                <div className={classes.detailInfoBloc}>
                                    <div className={classes.titleItem}>
                                        {selectedProductDispatch.commercialTitle}
                                    </div>
                                    <div className={classes.moreInfosItem}>
                                        <span className={classes.spanDetail}>
                                            ID : {selectedProductDispatch.objectId}
                                        </span>
                                        <span className={classes.spanDetail}>
                                            {getProductDispatchProductTypeLabel(selectedProductDispatch)}
                                        </span>
                                        <span className={classes.spanDetail}>
                                            DATE : {moment(parseFloat(date)).format("DD/MM")}
                                        </span>
                                        <span>
                                            DLC : {
                                                moment(getProductDispatchDlc(selectedProductDispatch)).isSame(new Date(), "year")
                                                    ? moment(getProductDispatchDlc(selectedProductDispatch)).format("DD/MM")
                                                    : moment(getProductDispatchDlc(selectedProductDispatch)).format("DD/MM/YY")
                                            }
                                        </span>
                                    </div>
                                </div>
                                {
                                    selectedProductDispatch.isReusable &&
                                    <div
                                        className={classes.reusable}
                                        style={{backgroundColor: KFC_BRANDS.find(el => el.name === selectedProductDispatch.brand)["color"]}}
                                    />
                                }
                                {!firstPageDetail &&
                                    <div className={classes.rightBlocInfos}>
                                        <div className={classes.floatLeft}>
                                            <div className={classes.headerRightTextNumber}>
                                                {selectedProductDispatch.prodWarehouse}
                                            </div>
                                            <div className={classes.headerRightTextDesc}>
                                                EN ENTREPRÔT
                                            </div>
                                        </div>
                                        <div className={classes.dividerHeader}/>
                                        <div className={classes.floatLeft}>
                                            <div className={classes.headerRightTextNumber}>
                                                {this.computeVolumeAvailable()}
                                            </div>
                                            <div className={classes.headerRightTextDesc}>
                                                RESTE
                                            </div>
                                        </div>
                                        <div className={classes.floatLeft}>
                                            <div className={classes.headerRightTextNumber}>
                                                {this.computeVolumeDispatch()}
                                            </div>
                                            <div className={classes.headerRightTextDesc}>
                                                DISPATCHÉ
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={classes.divider}/>
                            <div className={classes.contentBloc}>
                                {this.getBlocTitle(packaging)}
                                {this.getBlocInput()}

                                <div className={classes.buttonContainer}>
                                    {index !== selectedProductDispatch.hubs.length &&
                                    <div
                                        className={inputValue !== null && inputValue !== "" ? classes.confirmButtonFill : classes.confirmButtonEmpty}
                                        onClick={() => {
                                            if (![""].includes(inputValue)) this._confirmStep()
                                        }}
                                    >
                                        <span className={classes.buttonText}>
                                            CONFIRMER
                                        </span>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={dispatchSnackBar.open}
                        autoHideDuration={dispatchSnackBar.duration}
                        onClose={() => closeDispatchSnackBar(dispatchSnackBar.type)}
                    >
                        <MySnackbarContentWrapper
                            onClose={() => closeDispatchSnackBar(dispatchSnackBar.type)}
                            variant={dispatchSnackBar.type}
                            message={dispatchSnackBar.message}
                        />
                    </Snackbar>
                </div>
            </Modal>
        )
    }
}

DispatchDetail = withStyles(styles)(DispatchDetail)

export default connect(state => {
    return {
        date: state.dispatch.date,
        selectedProductDispatch: state.dispatch.selectedProductDispatch,
        dispatchSnackBar: state.dispatch.dispatchSnackBar,
        subcontractorProduct: state.dispatch.subcontractorProduct,
        brand: state.dispatch.brand,
        site: state.dispatch.site,
    }
}, {
    updateSelectedProductDispatch,
    closeDispatchSnackBar,
    updateProductDispatchHubVolume,
    computeDispatch,
    finishDispatch,
    closeDispatchDetail,
    createHubLocker,
    removeHubLocker
})(DispatchDetail)
