import React, {useState} from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import Chip from "@mui/material/Chip"
import { COLORS } from "../../utils"
import { KFC_BRANDS } from "../../utils"
import ProductResumeEdit from "./ProductResumeEdit"
import {
    getSpicyImg,
    RecipeStatus
} from "../../utils/recipes"
import { productTagsTypes } from "../../utils/productTagsTypes"
import { useSelector } from "react-redux"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"
import { getProductTypeLabelByProduct } from "../../utils/productTypesUtils"

export const ProductResume = (props) => {
    const {
        classes,
        initialValues,
        jsonSubcontractorProduct,
        parseSubcontractorProduct,
        productsTags,
        onSaveProductResume,
        chefs,
        checkProductInCard,
        showModalProductInCard
    } = props

    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const brand = jsonSubcontractorProduct && jsonSubcontractorProduct.brand && KFC_BRANDS.find(elem => elem.name === jsonSubcontractorProduct.brand).label

    const status = jsonSubcontractorProduct && RecipeStatus.find(elem => elem.value === jsonSubcontractorProduct.status)
        ? RecipeStatus.find(elem => elem.value === jsonSubcontractorProduct.status).label
        : ""
    
    const spicy = jsonSubcontractorProduct.spicy && jsonSubcontractorProduct.spicy !== "0" ? jsonSubcontractorProduct.spicy : null

    const [isEditing, setIsEditing] = useState(false)

    const productTagsTypesTypesEnable = productTagsTypes.filter(elem => elem.enableValue)
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Résumé du produit
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Nom commercial
                </div>
                <span>
                    {
                        jsonSubcontractorProduct
                            ? jsonSubcontractorProduct.commercialName
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Dénomination légale
                </div>
                <span>{jsonSubcontractorProduct.legalName}</span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Nom technique
                </div>
                <span>
                    {
                        jsonSubcontractorProduct
                            ? jsonSubcontractorProduct.name.name
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Tva
                </div>
                <span>
                    {
                        jsonSubcontractorProduct.tva
                            ? jsonSubcontractorProduct.tva
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Etat
                </div>
                <span
                    className={jsonSubcontractorProduct.isActive ? classes.greenStatus : classes.redStatus}
                >
                    {
                        jsonSubcontractorProduct.isActive
                            ? "Actif"
                            : "Inactif"
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Status
                </div>
                <span>
                    {
                        status
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Type
                </div>
                <span>
                    {getProductTypeLabelByProduct(jsonSubcontractorProduct, productTypeOptions)}
                </span>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Marque
                </div>
                <span>
                    {
                        brand
                    }
                </span>
            </div>

            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    EAN
                </div>
                <span>
                    {
                        jsonSubcontractorProduct
                            ? jsonSubcontractorProduct.ean
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Sous-traitant
                </div>
                <span>
                    {
                        jsonSubcontractorProduct &&
                        jsonSubcontractorProduct.name &&
                        jsonSubcontractorProduct.name.supplier &&
                        jsonSubcontractorProduct.name.supplier.name
                            ? jsonSubcontractorProduct.name.supplier.name
                            : ""
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Chef
                </div>
                {
                    jsonSubcontractorProduct && jsonSubcontractorProduct.chef
                        ?
                        <div className={classes.chefContainer}>
                            {
                                jsonSubcontractorProduct.chef && jsonSubcontractorProduct.chef.image && jsonSubcontractorProduct.chef.image.storageData && jsonSubcontractorProduct.chef.image.storageData.secure_url
                                    ?
                                    <img
                                        className={classes.chefImage}
                                        src={jsonSubcontractorProduct.chef.image.storageData.secure_url}
                                        alt="Chef"
                                    />
                                    : null
                            }
                            <span>
                                {jsonSubcontractorProduct.chef.firstName} {jsonSubcontractorProduct.chef.lastName}
                            </span>
                        </div>
                        : null
                }
            </div>
            {
                spicy && jsonSubcontractorProduct.spicy &&
                    <div className={classes.singleValueContainer}>
                        <div className={classes.valueLabel}>
                            Épicé
                        </div>
                        <span>
                            <div>{getSpicyImg(parseFloat(jsonSubcontractorProduct.spicy))}</div>
                        </span>
                    </div>
            }
            {
                productTagsTypesTypesEnable.map((productTagType, index) =>
                    <div className={classes.singleValueContainer} key={index}>
                        <div className={classes.valueLabel}>
                            Tags {productTagType.label}
                        </div>
                        <div
                            className={classes.internalTagValueContainer}
                        >
                            {
                                jsonSubcontractorProduct && Array.isArray(jsonSubcontractorProduct.internalTag)
                                    ? jsonSubcontractorProduct.internalTag.filter(internalTag => internalTag.type === productTagType.value).map((internalTag, chipIndex) =>
                                        <Chip
                                            key={chipIndex}
                                            label={internalTag.name}
                                            className={classes.internalTagChip}
                                        />
                                    )
                                    : null
                            }
                        </div>
                    </div>
                )
            }
            <ProductResumeEdit
                initialValues={initialValues}
                jsonSubcontractorProduct={jsonSubcontractorProduct}
                parseSubcontractorProduct={parseSubcontractorProduct}
                onClose={() => setIsEditing(false)}
                isEditing={isEditing}
                onSaveProductResume={onSaveProductResume}
                productsTags={productsTags}
                chefs={chefs}
            />
        </Paper>
    )
}

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        paddingBottom: 16,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    singleValueContainer: {
        display: "flex",
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: 128,
        minWidth: 128,
        display: "flex",
        alignItems: "center"
    },
    greenStatus: {
        color: COLORS.GREEN_STATUS
    },
    redStatus: {
        color: COLORS.SECONDARY_COLOR
    },
    internalTagLabel: {
    },
    internalTagValueContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    internalTagChip: {
        marginRight: 8,
        marginBottom: 4,
        marginTop: 4
    },
    chefContainer: {
        display: "flex"
    },
    chefImage: {
        borderRadius: 40,
        height: 20,
        width: 20,
        marginRight: 8
    }
}

export default withStyles(styles)(ProductResume)


