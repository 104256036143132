import React, { useEffect, useState } from "react"
import { Droppable } from "react-beautiful-dnd"

/**
 * react-beautiful-dnd does not work with the React 18 Strict mode
 * this is a workaround from https://github.com/atlassian/react-beautiful-dnd/issues/2399
 */
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}

export default StrictModeDroppable
