import React, { Component, Fragment } from "react"
import { withStyles } from "@mui/styles"
import { connect } from "react-redux"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

import RightsPage from "./RightsPage"
import UsersTable from "../../components/Rights/UsersTable"
import UserForm from "./UserForm"
import {
    createNewUser,
    addUserToRole,
    removeUserToRole,
    deleteUsers
} from "../../actions/Rights/Rights"
import _cloneDeep from "lodash/cloneDeep"
import UserEnhancedTableToolbar from "../../components/table-utils/Supplier/UserEnhancedTableToolbar"
import DeleteUserModal from "../../components/Rights/DeleteUserModal"

const styles = {
    modalStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}

export class RightsUsersTab extends Component {
    constructor(props) {
        super(props)
        const usersState = props.users.map(user => {
            return {
                ...user,
                roles: user.roles.map(elem => elem.objectId)
            }
        })
        this.state = {
            modalIsOpen: false,
            users: usersState,
            roles: _cloneDeep(props.roles),
            selection: [],
            showDeleteModal: false
        }
    }

    _clickAddButton = () => {
        const { modalIsOpen } = this.state

        this.setState({
            modalIsOpen: !modalIsOpen
        })
    }

    createUser = (values) => {
        const { createNewUser } = this.props

        createNewUser(values)
        this.setState({
            modalIsOpen: false,
        })
    }

    _deleteUsers = () => {
        const {selection, users} = this.state
        const selectedUsers = selection.map(key => users[key].objectId)

        this.props.deleteUsers(selectedUsers)
        this.setState({selection: [], showDeleteModal: false})
    }

    _onChangeRoles = (values, userId) => {
        const { users } = this.state
        const { addUserToRole, removeUserToRole } = this.props

        const index = users.findIndex(elem => elem.objectId === userId)
        if (index >= 0) {
            if (values.length < users[index].roles.length) {
                const roleId = users[index].roles.find(roleId => {
                    return !values.includes(roleId)
                })
                removeUserToRole(userId, roleId)
            } else {
                const roleId = values.find(value => {
                    return !users[index].roles.includes(value)
                })
                addUserToRole(userId, roleId)
            }
            users[index].roles = values
            this.setState({
                users: _cloneDeep(users)
            })
        }
    }

    render() {
        const { classes } = this.props
        const { modalIsOpen, roles, users, selection, showDeleteModal } = this.state

        return (
            <Fragment>
                <RightsPage tabValue="users">
                    <Fragment>
                        <UserEnhancedTableToolbar
                            numSelected={selection.length}
                            deleteUsers={() => this.setState({showDeleteModal: true})}
                            addUser={this._clickAddButton}
                        />
                        <UsersTable
                            users={users}
                            roles={roles}
                            onChange={this._onChangeRoles}
                            selection={this.state.selection}
                            setSelection={selection => this.setState({selection: selection})}
                        />
                        <Dialog
                            aria-labelledby="add-user-modal"
                            aria-describedby="simple-modal-description"
                            open={modalIsOpen}
                            onClose={this._clickAddButton}
                            className={classes.modal}
                        >
                            <DialogContent className={classes.modalStyle}>
                                <UserForm createUser={this.createUser} onClose={this._clickAddButton} />
                            </DialogContent>

                        </Dialog>
                        {
                            showDeleteModal &&
                            <DeleteUserModal
                                onDelete={this._deleteUsers}
                                onClose={() => this.setState({showDeleteModal: false})}
                                open={showDeleteModal}
                            />
                        }
                    </Fragment>
                </RightsPage>
            </Fragment>
        )
    }
}

const styledRightsUsersTab = withStyles(styles)(RightsUsersTab)

export default connect(state => {
    return {
        users: state.rights.users,
        roles: state.rights.roles,
    }
}, {
    createNewUser,
    addUserToRole,
    removeUserToRole,
    deleteUsers
})(styledRightsUsersTab)
