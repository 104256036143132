import Parse from "parse"
import { axiosCall } from "../../utils/axiosUtils"
import { actionWithLoader, downloadFile, getReplacingPath, onEnter, push } from "../Utils/utils"
import { setValues } from "../../parseUtils"
import {
    subcontractorProductFields,
    parseLimitRequest,
    getServerUrl,
    roundNumber,
    roundCurrency
} from "../../utils"
import { supplierItemTypes } from "../../utils/supplierItemUtils"
import { getSelectedSubcontractorProduct } from "../../reducers/Subcontractor/subcontractorsProducts"
import {
    emptyHeatingInstruction,
    getPackagingCost,
    getSubPackagingCost,
    recipePreparations
} from "../../utils/recipes"
import { showError } from "../Utils/app"
import { getChefs, getSingleChef } from "../../parseManager/products/resources/chef/parseChefsManager"
import {
    getSubcontractorProductById,
    parseSaveProductResume,
    parseUpdateSubcontractorDetails,
    parseSaveCommercialNamesAndSeason,
    parseSaveNutritionValues,
    parseSaveNutritionSourcing,
    parseSaveNutritionScore,
    getSubcontractorsProductsByFilters,
    getSubcontractorsProducts
} from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { checkDuplicate, computeSubcontractorProductNetWeight } from "../../utils/subcontractorProduct"
import { getProductInCard } from "../../parseManager/recipe/parseProductionItemManager"
import { batch } from "react-redux"
import { getProductTypeOptions, loadProductTypeOptions } from "../ProductTypes/productTypes"
import { WITH_MANAGEMENT_MODE_FILTER } from "../../utils/productTypesUtils"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"

const SubcontractorProduct = Parse.Object.extend("SubcontractorsProducts")
const SubcontractorCommercialName = Parse.Object.extend("SubcontractorsCommercialNames")
const Suppliers = Parse.Object.extend("Suppliers")
const Packaging = Parse.Object.extend("Packaging")
const ProductsTags = Parse.Object.extend("ProductsTags")
const SupplierItems = Parse.Object.extend("SupplierItems")

export function loadSubcontractorsProducts() {
    return actionWithLoader(async (dispatch) => {
        const [productTypeOptions, subcontractorsProductsList, suppliers] = await Promise.all([
            getProductTypeOptions(),
            getSubcontractorsProducts({
                includes: ["name.supplier"],
                selects: ["name.supplier", "name.name", "uniqueCode", "name", "commercialName", "brand", "type", "status", "isActive"],
                sortBy: "updatedAt",
                sortDirection: "desc"
            }),
            getSuppliers({
                selects: ["name"],
            })
        ])

        dispatch({
            type: "PRODUCT_TYPE_OPTIONS_LOADED",
            productTypeOptions: [...productTypeOptions]
        })
        dispatch({
            type: "SUBCONTRACTORS_PRODUCTS_LOADED",
            subcontractorsProductsList,
            suppliers,
        })
    })
}

export const loadSubcontractorsProductsByBrand = (brand, productTypes) => {
    return async (dispatch) => {
        const [subcontractorsProductsList, suppliers] = await Promise.all([
            getSubcontractorsProductsByFilters(
                ["name.supplier", "subcontractorCommercialNames.commercialName"],
                {
                    "types": productTypes,
                    "status": "6"
                }, brand
            ),
            getSuppliers({ toJSON: true })
        ])

        dispatch({
            type: "SUBCONTRACTORS_PRODUCTS_LOADED",
            subcontractorsProductsList: subcontractorsProductsList,
            suppliers,
        })
    }
}

export function loadSuppliers() {
    return async dispatch => {
        const suppliers = await new Parse.Query(Suppliers)
            .notEqualTo("deleted", true)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "SUPPLIERS_LOADED",
            suppliers
        })
    }
}

export function loadPackaging() {
    return async dispatch => {
        const packagings = await new Parse.Query(Packaging)
            .notEqualTo("deleted", true)
            .ascending("name")
            .limit(parseLimitRequest)
            .find()

        dispatch({
            type: "PACKAGING_LOADED",
            packagings
        })
    }
}

function loadChefs() {
    return async dispatch => {
        const chefs = await getChefs()

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_CHEF_LOADED",
            chefs: chefs
        })
    }
}

export function loadSubcontractorsProductsTag() {
    return actionWithLoader(async (dispatch) => {
        const productsTags = await new Parse.Query(ProductsTags)
            .limit(parseLimitRequest)
            .ascending("name")
            .find()

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_PRODUCTS_TAGS_LOADED",
            productsTags: productsTags
        })
    })
}
export function clearSelectedSubcontractorProduct() {
    return dispatch => {
        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_SELECTED",
            selectedSubcontractorProduct: null,
            selectedSubcontractorProductIngredientCost: null,
            selectedSubcontractorProductPackagingCost: null,
            selectedSubcontractorProductNetWeight: null
        })
    }
}

export function flushExportSubcontractorsProductsErrors() {
    return dispatch =>
        dispatch({
            type: "EXPORT_SUBCONTRACTORS_PRODUCTS_ERRORS",
            exportSubcontractorsProductsErrors: [],
            exportSubcontractorsProductsMessage: ""
        })
}

export function exportSubcontractorsProducts() {
    return actionWithLoader(async (dispatch) => {
        const url = getServerUrl() + "/subcontractorsProducts/exports"
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })

        if (result.status === 200 && result.data.success === "OK") {
            dispatch(flushExportSubcontractorsProductsErrors())
            return
        }

        dispatch({
            type: "EXPORT_SUBCONTRACTORS_PRODUCTS_ERRORS",
            exportSubcontractorsProductsErrors: result.data.errors,
            exportSubcontractorsProductsMessage: result.data.message
        })
    })
}

export function createSubcontractorProduct(values, subcontractorProduct, mustExport = false) {
    return actionWithLoader(async (dispatch) => {
        const keyToIgnore = [
            "subPackaging", "packaging", "name", "packagingCost",
            "subcontractorIngredientCost", "addSubcontractorCommercialName",
            "subcontractorCommercialNames", "price", "dlc", "heatingInstructions", "internalTag", "chef"
        ]

        if (!subcontractorProduct) {
            subcontractorProduct = new SubcontractorProduct()
            subcontractorProduct.set("exportedTo", [])
            subcontractorProduct.set("internalTag", [])
        }

        let currentSubcontractor

        /* SupplierItem */
        if (values.name && values.name.id) {
            const currentSupplierItem = await new Parse.Query(SupplierItems)
                .equalTo("type", supplierItemTypes.SALABLE_PRODUCT.key)
                .notEqualTo("deleted", true)
                .equalTo("objectId", values.name.id)
                .first()

            const units = currentSupplierItem.get("units")
            const netWeight = computeSubcontractorProductNetWeight(units)
            subcontractorProduct.set("name", currentSupplierItem)
            subcontractorProduct.set("netWeight", netWeight)
        }

        /* SubcontractorCommercialNames */
        if (values.subcontractorCommercialNames) {
            const currentSubcontractorCommercialNames = await Promise.all(values.subcontractorCommercialNames.map(async (subcontractorCommercialName) => {
                if (subcontractorCommercialName.id !== null) {
                    const currentCommercialName = await new Parse.Query(SubcontractorCommercialName)
                        .equalTo("objectId", subcontractorCommercialName.id)
                        .first()

                    if (currentCommercialName !== undefined) {
                        return {
                            pourcentage: subcontractorCommercialName.pourcentage,
                            commercialName: currentCommercialName
                        }
                    }

                    return null
                }
                else {
                    if (currentSubcontractor) {
                        const newCurrentCommercialName = new SubcontractorCommercialName()
                        newCurrentCommercialName.set("name", subcontractorCommercialName.label)
                        newCurrentCommercialName.set("subcontractor", currentSubcontractor)

                        return {
                            pourcentage: subcontractorCommercialName.pourcentage,
                            commercialName: newCurrentCommercialName
                        }
                    }

                    return null
                }
            }))

            subcontractorProduct.set("subcontractorCommercialNames", (
                currentSubcontractorCommercialNames && currentSubcontractorCommercialNames.length > 0
                    ? currentSubcontractorCommercialNames
                    : null
            )
            )
        }

        /* Packaging */
        if (values.brand) {
            if (values.packaging) {
                const currentPackaging = await new Parse.Query(Packaging)
                    .equalTo("objectId", values.packaging)
                    .first()

                subcontractorProduct.set("packaging", (currentPackaging ? currentPackaging : null))
            }
        }

        /* SubPackaging */
        if (values.brand) {
            if (values.subPackaging) {
                const subPackagingIds = values.subPackaging.map(subPackaging => subPackaging.value)
                const currentSubPackaging = await new Parse.Query(Packaging)
                    .containedIn("objectId", subPackagingIds)
                    .limit(parseLimitRequest)
                    .find()

                subcontractorProduct.set("subPackaging", (currentSubPackaging ? currentSubPackaging : null))
            }
        }

        /* internalTag */

        const internalTag = values.internalTag
            ? (await new Parse.Query(ProductsTags)
                .containedIn("objectId", values.internalTag.map(obj => obj.value))
                .limit(parseLimitRequest)
                .find()) || []
            : []
        subcontractorProduct.set("internalTag", internalTag)

        /* Chef */
        const chef = values.chef
            ? await getSingleChef(values.chef, false)
            : null

        subcontractorProduct.set("chef", chef)

        Object.keys(values).forEach(function (key) {
            const val = values[key]
            if (!keyToIgnore.includes(key) && val) {
                subcontractorProduct.set(key, val)
            }
        })

        subcontractorProduct.set("dlc", values.dlc ? parseFloat(values.dlc) : null)
        subcontractorProduct.set("price", values.price ? parseFloat(values.price) : null)

        if (values.preparation === "1") {
            subcontractorProduct.set("heatingInstructions", emptyHeatingInstruction)
        } else if (values.heatingInstructions) {
            subcontractorProduct.set("heatingInstructions", values.heatingInstructions)
        }

        await subcontractorProduct.save()

        if (mustExport) {
            await dispatch(exportSelectedSubcontractorProduct(subcontractorProduct.id))
        }

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_SAVED"
        })

        dispatch(loadSubcontractorsProducts())
        dispatch(showSubcontractorsProductsList())
    })
}

export function exportSelectedSubcontractorProduct() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const selectedSubcontractorProduct = getSelectedSubcontractorProduct(state)
        if (!selectedSubcontractorProduct) {
            return dispatch({
                type: "EXPORT_SELECTED_SUBCONTRACTOR_PRODUCT",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: "Aucun produit sous-traitant sélectionné",
                    duration: 500
                }
            })
        }
        const url = getServerUrl() + "/subcontractorsProducts/" + selectedSubcontractorProduct.id + "/_export"
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })
        if (result.status === 200 && result.data.success) {
            dispatch({
                type: "EXPORT_SELECTED_SUBCONTRACTOR_PRODUCT",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: selectedSubcontractorProduct.get("name").get("name").toLowerCase() + " -> Export réussi",
                    duration: 5000
                }
            })
            dispatch(loadSubcontractorsProducts())
        } else {
            return dispatch({
                type: "EXPORT_SUBCONTRACTORS_PRODUCTS_ERRORS",
                exportSubcontractorsProductsErrors: result.data.errors,
                exportSubcontractorsProductsMessage: result.data.message
            })
        }
    })
}

export function deleteSubcontractorProduct(subcontractorProduct, shouldRedirect = false) {
    return actionWithLoader(async (dispatch) => {
        subcontractorProduct.set("deleted", true)
        await subcontractorProduct.save(null, { context: { isDeletion: true } })
        const subcontractorProductQuantity = subcontractorProduct.get("name").get("subcontractorProductQuantity")
        const newSubcontractorProductQuantity = subcontractorProductQuantity && subcontractorProductQuantity > 0 ? subcontractorProductQuantity - 1 : 0
        subcontractorProduct.get("name").set("subcontractorProductQuantity", newSubcontractorProductQuantity)
        await subcontractorProduct.get("name").save()

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_REMOVED"
        })
        dispatch(loadSubcontractorsProducts())

        if (shouldRedirect) {
            dispatch(showSubcontractorsProductsList())
        }
    })
}

export function updateSelectedSubcontractorProduct(values) {
    return async (dispatch, getState) => {
        const state = getState()
        const selectedSubcontractorProduct = getSelectedSubcontractorProduct(state) || new SubcontractorProduct()
        setValues(selectedSubcontractorProduct, values, subcontractorProductFields)

        dispatch({
            type: "SELECTED_SUBCONTRACTOR_PRODUCT_UPDATED",
            selectedSubcontractorProduct
        })
    }
}

export function synchronizeEtiquettable() {
    return actionWithLoader(async (dispatch) => {
        const url = getServerUrl() + "/subcontractorsProducts/_synchronizeEtiquettable"
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })
        if (result && result.status === 200 && result.data.success === true) {
            await dispatch(loadSubcontractorsProducts())

            dispatch({
                type: "SYNCHRONIZE_SUBCONTRACTORS_PRODUCTS_WITH_ETIQUETTABLE",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "La synchronisation avec etiquettable est un succès.",
                    duration: 5000
                }
            })
        } else {
            dispatch({
                type: "SYNCHRONIZE_SUBCONTRACTORS_PRODUCTS_WITH_ETIQUETTABLE_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: result && result.data && result.data.message ? result.data.message : "La synchronisation avec etiquettable a échoué",
                    duration: 5000
                }
            })
        }

    })
}

export function closeSubcontractorProductSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "CLOSE_SUBCONTRACTOR_PRODUCT_SNACKBAR",
            subcontractorProductSnackBar: {
                open: false,
                type: currentType,
                message: "",
                duration: 1000
            }
        })
    })
}

export function updateSubcontractorDetails(values, subcontractorProduct) {
    return actionWithLoader(async (dispatch) => {
        await saveAction(await parseUpdateSubcontractorDetails(values, subcontractorProduct), dispatch)

        if (subcontractorProduct.get("status") === "6") {
            await dispatch(exportSelectedSubcontractorProduct(subcontractorProduct.id))
        }

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_SAVED",
            selectedSubcontractorProduct: subcontractorProduct
        })

        dispatch(loadSubcontractorsProducts())
    })
}

export function synchronizeASubcontractorProductWithEtiquettable(id) {
    return actionWithLoader(async (dispatch) => {
        const url = getServerUrl() + "/subcontractorsProducts/" + id + "/_synchronizeEtiquettable"
        const result = await axiosCall("post", url, {}, { "Content-Type": "application/json" })
        if (result && result.status === 200 && result.data.success === true) {
            dispatch({
                type: "SYNCHRONIZE_A_SUBCONTRACTOR_PRODUCT_WITH_ETIQUETTABLE",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Le produit sous-traitant a été synchronisé avec etiquettable",
                    duration: 5000
                }
            })
            dispatch(loadSubcontractorsProducts())
        } else {
            dispatch({
                type: "SYNCHRONIZE_A_SUBCONTRACTOR_PRODUCT_WITH_ETIQUETTABLE_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: result && result.data && result.data.message ? result.data.message : "Le produit sous-traitant n'a pas été synchronisé avec etiquettable",
                    duration: 5000
                }
            })
        }
    })
}

export function onEnterSubcontractorsProducts(store) {
    return onEnter({
        store,
        actionThunk: loadSubcontractorsProducts,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSingleSubcontractorProduct(store) {
    return onEnter({
        store,
        actionThunk: params => {
            return actionWithLoader(async (dispatch) => {
                dispatch({
                    type: "EXIT_AFTER_SAVE",
                    exit: false
                })

                if (params) {

                    const subcontractorProduct = await getSubcontractorProductById(params.id, [
                        "chef.image",
                        "name",
                        "subcontractor",
                        "subcontractorCommercialNames.commercialName",
                        "subcontractorCommercialNames.commercialName.allergens",
                        "internalTag"
                    ],
                        false
                    )

                    if (subcontractorProduct !== undefined && subcontractorProduct !== null) {
                        const packagingId = subcontractorProduct.get("packaging") ? subcontractorProduct.get("packaging").id : null
                        const packagingCost = await getPackagingCost(packagingId)
                        const subPackagingCost = getSubPackagingCost(subcontractorProduct.get("subPackaging"))
                        const subcontractorIngredientCost = subcontractorProduct.get("name").get("units") && subcontractorProduct.get("name").get("units").stock
                            ? roundNumber(subcontractorProduct.get("name").get("units").stock.price / subcontractorProduct.get("name").get("units").stock.unity.quantity)
                            : null
                        const subcontractorIngredientNetWeight = subcontractorProduct.get("name").get("weightPerPortion")
                        dispatch({
                            type: "SUBCONTRACTOR_PRODUCT_SELECTED",
                            selectedSubcontractorProduct: subcontractorProduct,
                            selectedSubcontractorProductPackagingCost: packagingCost + subPackagingCost,
                            selectedSubcontractorProductIngredientCost: subcontractorIngredientCost,
                            selectedSubcontractorProductIngredientNetWeight: subcontractorIngredientNetWeight
                        })
                    }
                    else {
                        dispatch({
                            type: "SUBCONTRACTOR_PRODUCT_SELECTED",
                            selectedSubcontractorProduct: subcontractorProduct,
                            selectedSubcontractorProductPackagingCost: null,
                            selectedSubcontractorProductIngredientCost: null,
                            selectedSubcontractorProductIngredientNetWeight: null
                        })
                    }
                }
                dispatch(loadProductTypeOptions())
                dispatch(loadSubcontractorsProductsTag())
                dispatch(loadSuppliers())
                dispatch(loadPackaging())
                dispatch(loadChefs())
            })
        }
    })
}

export function saveProductImage(image, parseProduct, type) {
    return actionWithLoader(
        async (dispatch) => {
            if (!image || !parseProduct) {
                return
            }

            try {
                if (type === "kitchen") {
                    parseProduct.set("kitchenImage", { publicId: image })
                }

                if (type === "app") {
                    parseProduct.set("appImage", { publicId: image })
                }

                await parseProduct.save()

                dispatch({
                    type: "SUBCONTRACTOR_PRODUCT_IMAGE_SAVED",
                    image: { publicId: image },
                    product: parseProduct,
                    subcontractorProductSnackBar: {
                        open: true,
                        type: "success",
                        message: "Image enregistrée",
                        duration: 5000
                    }
                })

                dispatch(loadSubcontractorsProducts())
            } catch (e) {
                const error = { ...e, code: null }
                dispatch(showError(error))
            }
        }
    )
}

export function removeProductImage(parseProduct, type) {
    return actionWithLoader(
        async (dispatch) => {
            if (!parseProduct) {
                return
            }

            try {
                if (type === "kitchen") {
                    parseProduct.set("kitchenImage", null)
                }

                if (type === "app") {
                    parseProduct.set("appImage", null)
                }

                await parseProduct.save()

                dispatch({
                    type: "SUBCONTRACTOR_PRODUCT_IMAGE_REMOVED",
                    image: null,
                    product: parseProduct,
                    subcontractorProductSnackBar: {
                        open: true,
                        type: "success",
                        message: "Image supprimée",
                        duration: 5000
                    }
                })

                dispatch(loadSubcontractorsProducts())
            } catch (e) {
                const error = { ...e, code: null }
                dispatch(showError(error))
            }
        }
    )
}

export function saveNutritionInformation(product, infoNutrition) {
    return actionWithLoader(async (dispatch) => {
        try {
            infoNutrition.fat = Number(infoNutrition.fat)
            infoNutrition.saturatedFattyAcids = Number(infoNutrition.saturatedFattyAcids)
            infoNutrition.carbohydrates = Number(infoNutrition.carbohydrates)
            infoNutrition.sugar = Number(infoNutrition.sugar)
            infoNutrition.proteins = Number(infoNutrition.proteins)
            infoNutrition.salt = Number(infoNutrition.salt)
            infoNutrition.fibers = Number(infoNutrition.fibers)
            infoNutrition.frenchIngredientsRate = Number(infoNutrition.frenchIngredientsRate)
            infoNutrition.certifiedIngredientsRate = Number(infoNutrition.certifiedIngredientsRate)
            infoNutrition.organicIngredientsRate = Number(infoNutrition.organicIngredientsRate)
            infoNutrition.localIngredientsRate = Number(infoNutrition.localIngredientsRate)
            infoNutrition.seasonalIngredientsRate = Number(infoNutrition.seasonalIngredientsRate)
            infoNutrition.carbonFootPrint = Number(infoNutrition.carbonFootPrint)
            infoNutrition.calories = Number(infoNutrition.calories)
            product.set("nutritionInformation", infoNutrition)
            await product.save()
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_SAVED",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Informations nutritionnelles sauvegardées",
                    duration: 5000
                }
            })
        } catch (err) {
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Erreur en sauvegardant les informations nutritionnelles",
                    duration: 5000
                }
            })
        }
    })
}

export function closeSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "CLOSE_SUBCONTRACTOR_PRODUCT_SNACKBAR",
            subcontractorProductSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export function exportAlkemics() {
    return async () => {
        const url = `${getServerUrl()}/subcontractorsProducts/exportAlkemics`
        downloadFile(url, "alkemics.csv")
    }
}

export function createProductResume(values) {
    return actionWithLoader(async (dispatch) => {
        let subcontractorProduct = new SubcontractorProduct()

        const keysToArray = ["exportedTo", "season", "internalTag", "kitchenImage", "subcontractorCommercialNames", "subPackaging"]
        const keysToNull = ["packaging", "chef", "appImage", "kitchenImage", "nutritionInformation"]
        const keys = ["netWeight", "subcontractorIngredientCost", "packagingCost", "totalCost", "type", "brand"]

        values["subcontractorIngredientCost"] = values.name.units && values.name.units.stock && values.name.units.stock.unity ? roundNumber(values.name.units.stock.price / values.name.units.stock.unity.quantity) : null
        values["packagingCost"] = 0
        values["totalCost"] = roundCurrency(values["subcontractorIngredientCost"] + values["packagingCost"])

        /* supplierItem */
        let currentSupplierItem
        if (values["name"] && values["name"].id) {
            currentSupplierItem = await new Parse.Query(SupplierItems)
                .equalTo("type", supplierItemTypes.SALABLE_PRODUCT.key)
                .notEqualTo("deleted", true)
                .equalTo("objectId", values["name"].id)
                .first()

            subcontractorProduct.set("name", currentSupplierItem)
            const units = currentSupplierItem.get("units")
            values["netWeight"] = units && units.stock && units.stock.unity ? computeSubcontractorProductNetWeight(currentSupplierItem.get("units")) : null
        }

        keys.forEach(key => {
            subcontractorProduct.set(key, values[key])
        })

        keysToArray.forEach(key => {
            subcontractorProduct.set(key, [])
        })

        keysToNull.forEach(key => {
            subcontractorProduct.set(key, null)
        })

        subcontractorProduct.set("price", 0)
        subcontractorProduct.set("dlc", 0)
        subcontractorProduct.set("description", "")
        subcontractorProduct.set("commercialName", "")
        subcontractorProduct.set("heatingInstructions", emptyHeatingInstruction)
        subcontractorProduct.set("isActive", false)
        subcontractorProduct.set("status", "1")
        subcontractorProduct.set("preparation", "1")

        const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
        const type = productTypeOptions.find(item => item.value === values.type)
        const preparation = recipePreparations.find(item => item.value === "1")
        let specialInstruction = null
        if (type) {
            specialInstruction = `${type.adj} ${type.label.toLowerCase()} ${type.adj === "Ce" ? preparation.label.toLowerCase() : preparation.labelFem.toLowerCase()}`
        }

        subcontractorProduct.set("specialInstruction", specialInstruction)

        if (await checkDuplicate(subcontractorProduct)) {
            return dispatch({
                type: "SUBCONTRACTOR_PRODUCT_DUPLICATE_CREATION",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: "Le produit existe déjà",
                    duration: 500
                }
            })
        } else {
            await subcontractorProduct.save()

            if (currentSupplierItem) {
                const subcontractorProductQuantity = currentSupplierItem.get("subcontractorProductQuantity") ? currentSupplierItem.get("subcontractorProductQuantity") + 1 : 1
                currentSupplierItem.set("subcontractorProductQuantity", subcontractorProductQuantity)
                await currentSupplierItem.save()
            }

            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_SAVED"
            })

            dispatch(loadSubcontractorsProducts())
            dispatch(showSubcontractorProductTab("general", subcontractorProduct.id))
        }
    })
}


export function saveProductResume(subcontractorProduct, values) {
    return actionWithLoader(async (dispatch) => {
        await saveAction(await parseSaveProductResume(subcontractorProduct, values), dispatch)
    })
}

export function saveCommercialNamesAndSeason(subcontractorProduct, values) {
    return actionWithLoader(async (dispatch) => {
        await saveAction(await parseSaveCommercialNamesAndSeason(subcontractorProduct, values), dispatch)
    })
}

export function saveNutritionValues(subcontractorProduct, values, infoNutrition) {
    return actionWithLoader(async (dispatch) => {
        try {
            await saveAction(await parseSaveNutritionValues(subcontractorProduct, values, infoNutrition), dispatch)

            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_SAVED",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Informations nutritionnelles sauvegardées",
                    duration: 5000
                }
            })
        } catch (err) {
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: "Erreur en sauvegardant les informations nutritionnelles",
                    duration: 5000
                }
            })
        }
    })
}

export function saveNutritionSourcing(subcontractorProduct, values, infoNutrition) {
    return actionWithLoader(async (dispatch) => {
        try {
            await saveAction(await parseSaveNutritionSourcing(subcontractorProduct, values, infoNutrition), dispatch)

            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_SAVED",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Informations nutritionnelles sauvegardées",
                    duration: 5000
                }
            })
        } catch (err) {
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: "Erreur en sauvegardant les informations nutritionnelles",
                    duration: 5000
                }
            })
        }
    })
}



export function saveNutritionScore(subcontractorProduct, values, infoNutrition) {
    return actionWithLoader(async (dispatch) => {
        try {
            await saveAction(await parseSaveNutritionScore(subcontractorProduct, values, infoNutrition), dispatch)

            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_SAVED",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "success",
                    message: "Informations nutritionnelles sauvegardées",
                    duration: 5000
                }
            })
        } catch (err) {
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_INFO_NUTRI_ERROR",
                subcontractorProductSnackBar: {
                    open: true,
                    type: "error",
                    message: "Erreur en sauvegardant les informations nutritionnelles",
                    duration: 5000
                }
            })
        }
    })
}

export function updateSubcontractorProductFilters(change) {
    return actionWithLoader(async (dispatch) => {
        batch(() => {
            dispatch({
                type: "SUBCONTRACTOR_PRODUCT_UPDATE_LIST_FILTERS",
                change
            })
            dispatch(loadSubcontractorsProducts())
        })
    })
}

export async function saveAction(subcontractorProduct, dispatch) {
    if (subcontractorProduct.get("status") === "6") {
        await dispatch(exportSelectedSubcontractorProduct(subcontractorProduct.id))
    }

    dispatch({
        type: "SUBCONTRACTOR_PRODUCT_SAVED",
        selectedSubcontractorProduct: subcontractorProduct
    })

    dispatch(loadSubcontractorsProducts())
}

export function checkProductInCard(recipeId) {
    return actionWithLoader(async (dispatch) => {
        const productItems = await getProductInCard(recipeId, "SubcontractorProduct")

        dispatch({
            type: "SUBCONTRACTOR_PRODUCT_PRODUCT_ITEMS_CHECKED",
            productItems
        })
    })
}

/** Routers */
export function showSubcontractorsProductsList() {
    return push("/products/subcontractors-products")
}

export function showSubcontractorProductRead(subcontractorProductId) {
    if (subcontractorProductId) {
        return push(`/products/subcontractor-product/${subcontractorProductId}`)
    }
    else {
        return push("/products/subcontractor-product")
    }
}

export function showSubcontractorProductEdit(subcontractorProductId, location) {
    if (subcontractorProductId) {
        return push(`/products/subcontractor-product-old/${subcontractorProductId}/edit`, location)
    }
    else {
        return push("/products/subcontractor-product")
    }
}

export function showSubcontractorProductTab(tab, subcontractorProductId) {
    return push(`/products/subcontractor-product/${tab}/${subcontractorProductId ? subcontractorProductId : ""}`)
}

export function showAdvancedSearch() {
    return push("/products/advanced-search")
}
