import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Snackbar from "@mui/material/Snackbar"
import { closePlanningSettingsSnackBar } from "../../actions/Planning/PlanningSetting"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { Box, styled } from "@mui/material"
import TabsContainer from "./Tabs/TabsContainer"
import { showFamiliesClassificationsIngredients } from "../../actions/Ingredients/Classifications/Families/Families"
import { showPackagingResources } from "../../actions/Products/Products"
import { showPlanningSettingReport } from "../../actions/Planning/Settings/PlanningReport"
import { showSitesList } from "../../actions/Site/sites"
import PageHeader from "../../components/PageHeader"
import SettingsIcon from "@mui/icons-material/Settings"
import { showAdministrationProductTypes } from "../../actions/ProductTypes/productTypes"
import { CAN_ACCESS_SETTINGS_ADMINISTRATION } from "../../utils/accessRights"

const StyledTab = styled(Tab)({
    paddingLeft: 45,
    paddingRight: 45
})

const sx = {
    tabs: {
        boxShadow: "0px 1px 2px rgb(0 0 0 / 15%)",
        borderBottom: "1.5px solid #D8D8D8",
    }
}

const SettingsTabs = ({ tabValue, children }) => {
    const dispatch = useDispatch()

    const canAccessAdministrationTabs = useSelector(state => state.app.rights[CAN_ACCESS_SETTINGS_ADMINISTRATION])
    const snackBar = useSelector(state => state.planningSettings.planningSettingsSnackBar)

    // default home page for each level 1 tabs
    const _onTabChange = (event, tabValue) => {
        switch (tabValue) {
            case "administration":
                dispatch(showAdministrationProductTypes())
                break
            case "ingredients":
                dispatch(showFamiliesClassificationsIngredients())
                break
            case "technicalSheet":
                dispatch(showPackagingResources())
                break
            case "production":
                dispatch(showPlanningSettingReport())
                break
            case "sitesAndHubs":
                dispatch(showSitesList())
                break
            default:
                break
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box position="relative" width="100%">
                <PageHeader icon={<SettingsIcon />} title="Réglages" />
                <TabsContainer top={64}>
                    {/* tabs */}
                    <Tabs
                        value={tabValue ? tabValue : false}
                        onChange={_onTabChange}
                        centered
                        indicatorColor="primary"
                        textColor="primary"
                        sx={sx.tabs}
                    >
                        {canAccessAdministrationTabs && <StyledTab value="administration" label="Administration" />}
                        <StyledTab value="ingredients" label="Ingrédients" />
                        <StyledTab value="technicalSheet" label="Fiches techniques" />
                        <StyledTab value="production" label="Production" />
                        <StyledTab value="sitesAndHubs" label="Sites et hubs" />
                    </Tabs>
                </TabsContainer>    

                {children}
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={snackBar.open}
                    autoHideDuration={snackBar.duration}
                    onClose={() => dispatch(closePlanningSettingsSnackBar(snackBar.type))}
                >
                    <MySnackbarContentWrapper
                        onClose={() => dispatch(closePlanningSettingsSnackBar(snackBar.type))}
                        variant={snackBar.type}
                        message={snackBar.message}
                    />
                </Snackbar>
            </Box>
        </Box>
    )
}

export default SettingsTabs