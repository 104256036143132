import React, { useState, useCallback, useEffect } from "react"
import FullScreenWrapper from "../../FullScreenWrapper"
import CreditNote from "./CreditNote"
import { creditNotesReasons, creditNotesType } from "../../../utils/creditNoteUtils"
import Button from "@mui/material/Button"
import { v4 as uuidv4 } from "uuid"
import { generateOrderUnitLabel } from "../../../utils/ordersUtils"

const CreditNoteLot = (props) => {
	const {
		headerData,
        lotData,
        orderNumber,
        supplierItemName,
        supplierItem,
        lotActions,
        creditNoteReedition,
        isUnsavedCreditNoteUpdated
	} = props

    const [reason, setReason] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const [comment, setComment] = useState(null)
    const [images, setImages] = useState([])
    const [creditNotesAmount, setCreditNotesAmount] = useState(0)
    const [errors, setErrors] = useState({
        quantity: null,
        reason: null
    })

    useEffect(() => {
        if (creditNoteReedition || isUnsavedCreditNoteUpdated) {
            if (!lotData.creditNotes?.[0]) return
            const { reason, quantity, comment, images, amount } = lotData.creditNotes[0]
            reason && setReason(reason)
            quantity && setQuantity(quantity)
            comment && setComment(comment)
            images && setImages(images)
            amount && setCreditNotesAmount(amount)
        }
    }, [creditNoteReedition, isUnsavedCreditNoteUpdated, lotData])

    const actions = useCallback((callback) => {
        switch (callback.method) {
            case "UPDATE_QUANTITY": 
                setQuantity(callback.data.quantity)
                const creditNotesAmount = callback.data.quantity * (lotData.orderSupplierItem?.units?.stock?.price || 0)
                setCreditNotesAmount(creditNotesAmount)
                return
            case "UPDATE_COMMENT":
                setComment(callback.data.comment)
                return
            case "UPDATE_IMAGES":
                setImages(callback.data.images)
                return
            case "UPDATE_REASON":
                setReason(callback.data.reason)
                return
            default: 
                return
        }
    }, [lotData])

    // on click "Valider la casse" button
    const saveCreditNote = useCallback(() => {
        if (!reason && !quantity) {
            setErrors({
                reason: "Veuillez indiquer le motif de déclaration de casse",
                quantity: "Veuillez saisir la quantité mise à la casse"
            })
            return
        }
        else if (!quantity) {
            setErrors({
                quantity: "Veuillez saisir la quantité mise à la casse",
                reason: null
            })
            return
        }
        else if (!reason) {
            setErrors({
                reason: "Veuillez indiquer le motif de déclaration de casse",
                quantity: null

            })
            return
        }
        // credit note reedition
        if (creditNoteReedition) {
            lotActions({
                method: "UPDATE_CREDIT_NOTE",
                data: {
                    lot: lotData,
                    values: {
                        quantity,
                        amount: creditNotesAmount,
                        reason,
                    }
                }
            })
            return
        }

        // credit note creation
        lotActions({
            method: "ADD_CREDIT_NOTE",
            data: {
                lotId: lotData.id || lotData.objectId,
                creditNoteData: {
                    creditNote: {
                        id: uuidv4(),
                        quantity,
                        reason,
                        comment,
                        images,
                        amount: creditNotesAmount,
                        lotId: lotData.id || lotData.objectId
                    },
                    lot: {
                        lotNumber: lotData.lotNumber,
                        dlc: lotData.dlc,
                        stockZone: lotData.stockZone,
                    }
                }
            }
        })
    }, [creditNoteReedition, reason, quantity, comment, images, creditNotesAmount, lotData, lotActions])

    const renderCreditNoteAction = () => {
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveCreditNote}
                    disabled={!reason || !quantity}
                >
                    confirmer l'avoir
                </Button>
            </div>
        )
    }

    const data = {
        type: creditNotesType.LOT,
        header: {...headerData, actions: renderCreditNoteAction()},
        lot: {...lotData},
        unit: generateOrderUnitLabel(supplierItem, "stock"),
        items: [
            creditNotesReasons.TEMPERATURE.key,
            creditNotesReasons.BROKEN.key,
            creditNotesReasons.DLC.key,
            creditNotesReasons.OTHER.key
        ],
        imageName: `order_${orderNumber}_item_${supplierItemName}_lot_${lotData.lotNumber}`,
        initialValues: {
            quantity: (creditNoteReedition || isUnsavedCreditNoteUpdated) && quantity,
            reason: (creditNoteReedition || isUnsavedCreditNoteUpdated) && reason,
            comment: (creditNoteReedition || isUnsavedCreditNoteUpdated) && comment,
            images: (creditNoteReedition || isUnsavedCreditNoteUpdated) && images
        },
        callback: actions
    }

	return (
        <FullScreenWrapper>
            <CreditNote
                data={data}
                saveCreditNote={saveCreditNote}
                errors={errors}
                creditNotesAmount={creditNotesAmount}
                onClick={saveCreditNote}
                disabled={!reason || !quantity}
                creditNoteReedition={creditNoteReedition}
            />

        </FullScreenWrapper>
	)
}

export default CreditNoteLot