import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Box, styled } from "@mui/material"

import SecondaryDLCCartInventory from "./SecondaryDLCCartInventory"
import SecondaryDLCCartDate from "./SecondaryDLCCartDate"
import { updateSecondaryDLCQuantity, updateSecondaryDLCSecondaryDLC } from "../../actions/SecondaryDLC/secondaryDLC"

const StyledEmptyCart = styled(Box)(() => ({
  marginTop: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontWeight: 300,
  fontSize: 37,
  lineHeight: "43px"
}))

const SecondaryDLCCart = ({ selectedSecondaryDLC, onUnselect }) => {

  const dispatch = useDispatch()

  const [isEdition, setIsEdition] = useState(false)

  useEffect(() => {
     setIsEdition(false)
  }, [selectedSecondaryDLC])

  const onCancelEdition = () => setIsEdition(false)

  const onShowFlawForm = () => {
    console.log("show flaw")
  }

  const handleQuantityValidation = async (formValues) => {
    const newQuantity = formValues.quantity
    await dispatch(updateSecondaryDLCQuantity(selectedSecondaryDLC.objectId, +newQuantity))
    onUnselect()
  }

  const handleSecondaryDLCValidation = async (formValues) => {
    const secondaryDLC = formValues.secondaryDLC
    await dispatch(updateSecondaryDLCSecondaryDLC(selectedSecondaryDLC.objectId, secondaryDLC))
    onUnselect()
  }

  return (
    <Box className="height100">
      {selectedSecondaryDLC ? (
        isEdition ? (
          <SecondaryDLCCartDate
            secondaryDLC={selectedSecondaryDLC}
            onValidate={handleSecondaryDLCValidation}
            onCancel={onCancelEdition}
          />
        ) : (
          <SecondaryDLCCartInventory
            secondaryDLC={selectedSecondaryDLC}
            onEdit={() => setIsEdition(true)}
            onEditFlaw={onShowFlawForm}
            onValidate={handleQuantityValidation}
            onBack={onUnselect}
          />
        )
      ) : (
        <StyledEmptyCart>
          <span>Choisissez un produit</span>
        </StyledEmptyCart>
      )}
    </Box>
  )

}

export default SecondaryDLCCart