import React from "react"

export const BreakIcon = ({selected}) => {
    return (
            <img
                src={selected?"/img/BreakBlack.svg":"/img/Break.svg"}
                alt="Break"
            />
    )
}

export default BreakIcon