import {
    actionWithLoader,
} from "../Utils/utils"
import {getServerUrl} from "../../utils"
import {axiosCall} from "../../utils/axiosUtils"


export function updateProductDispatchHubPriorities() {
    return actionWithLoader(async () => {
        try {
            const url = getServerUrl() + "/migrations/regulateProductDispatchHubPriorities"
            await axiosCall("POST", url,{}, {"Content-Type": "application/json"})
        } catch (e) {
            console.error("Misc productDispatch.updateProductDispatchHubPriorities : ", e)
            return Promise.reject(e)
        }
    })
}
