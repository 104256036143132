import React from "react"
import LotCardSubfooterContent from "./LotCardSubfooterContent"

const SelectableAlphabetCardSubFooterContent = ({
	document,
	collection,
	selected
}) => {

	const isLotOrSecondaryDLC = collection === "Lot" || collection === "SecondaryDLC"

	if (isLotOrSecondaryDLC) {
		return (
		<LotCardSubfooterContent
			document={document}
			selected={selected}
		/>)
	}
	return null
}

export default SelectableAlphabetCardSubFooterContent