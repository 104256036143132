import {
    actionWithLoader,
    getReplacingPath,
    onEnter,
    push
} from "../../../Utils/utils"
import {
    getFamiliesIngredients,
    createFamilyIngredient
} from "../../../../parseManager/ingredients/classifications/family/parseFamilyManager"

export function loadFamiliesClassificationsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const familiesIngredients = await getFamiliesIngredients()

        dispatch({
            type: "CLASSIFICATIONS_FAMILIES_LOADED",
            familiesIngredients
        })
    })
}

export function createFamilyClassificationIngredient(values, dispatch, {familyIngredient, location}) {
    return actionWithLoader(async (dispatch) => {
        const newFamilyIngredient = await createFamilyIngredient(familyIngredient, values)

        dispatch({
            type: "CLASSIFICATIONS_FAMILY_SAVED"
        })

        dispatch(loadFamiliesClassificationsIngredients())
        if (location.state && location.state.returnPath === "edit"){
            dispatch(showSingleFamilyClassificationIngredientsRead(newFamilyIngredient.objectId))
        }
        else {
            dispatch(showFamiliesClassificationsIngredients())
        }
    })
}

export function onEnterFamiliesClassificationsIngredients(store) {
    return onEnter({
        store,
        actionThunk: loadFamiliesClassificationsIngredients,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function showSingleFamilyClassificationIngredientsRead(id) {
    if (id) {
        return push(`/settings/ingredients/classifications/family/${id}`)
    }
    else {
        return push("/settings/ingredients/classifications/family")
    }
}

export function showSingleFamilyClassificationIngredientsEdit(id, location) {
    if (id) {
        return push(`/settings/ingredients/classifications/family/${id}/edit`, location)
    }
    else {
        return push("/settings/ingredients/classifications/family")
    }
}

export function showFamiliesClassificationsIngredients() {
    return push("/settings/ingredients/classifications/families")
}


