import React from "react"
import { lighten } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import clsx from "clsx"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import AddIcon from "@mui/icons-material/Add"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
})

const AllergenIngredientEnhancedTableToolbar = (props) => {
  const { createAllergenIngredient, classes, numSelected } = props

  const text =
    numSelected > 1 ? "Allergènes sélectionnés" : "Allergène sélectionné"

  const _createAllergenIngredient = () => {
    createAllergenIngredient()
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {text}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Allergènes
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      {numSelected > 0 ? null : (
        <div className={classes.actions}>
          <Tooltip title="Créer" disableInteractive>
            <IconButton
              aria-label="Créer"
              onClick={_createAllergenIngredient}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  )
}

AllergenIngredientEnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

export default withStyles(toolbarStyles)(
  AllergenIngredientEnhancedTableToolbar
)
