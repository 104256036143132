import React, { useState, useEffect, useMemo, useCallback } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { usePrevious } from "react-use"
import CalendarSection from "../../components/Calendar/CalendarSection"
import {
    showMealPlanner,
    loadPlanningSpecificDay,
    saveProd,
    updatePackagingCard,
    loadPackagingPlanning,
    closePlanningSnackBar,
    downloadExtract,
    updateMultipleStocks,
    findProduct,
    removeRightProduct,
    setAnyFocused,
    saveSplitPackaging
} from "../../actions/Planning/Planning"
import {
    printMultipleCards,
    printHeadPage,
    printSingleCard,
    downloadNameCard,
    printPackagingSections
} from "../../actions/Planning/PlanningProduction"
import _cloneDeep from "lodash/cloneDeep"
import {
    COLORS, setSearchValue
} from "../../utils"
import PlanningDays from "../../components/Planning/PlanningDays"
import moment from "moment"
import {
    countProduction,
    getSumGesters,
    getSumPots,
} from "../../utils/planning"
import _isEqual from "lodash/isEqual"
import Snackbar from "@mui/material/Snackbar"
import Modal from "@mui/material/Modal"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import LabelProductModal from "../../components/LabelProductModal"
import {
    asyncPrintLabel
} from "../../actions/LabelProduct/LabelProduct"
import PlanningToolbar from "../../components/Planning/PlanningToolbar"
import PlanningPackagingActions from "../../components/Planning/PlanningActions/PlanningPackagingActions"
import PlanningProductionPackagingFilters from "../../components/Planning/PlanningFilters/PlanningProductionPackagingFilters"
import {
    CAN_UPDATE_PACKAGING_PLANNING,
    CAN_PRINT_PACKAGING_PLANNING,
    CAN_DRAG_N_DROP_PACKAGING_PLANNING,
    CAN_INPUT_VOLUME_PACKAGING_PLANNING,
    CAN_PRINT_LABEL_PACKAGING_PLANNING,
    CAN_PRINT_CARD_PACKAGING_PLANNING,
    CAN_USE_GENERAL_ACTIONS_PACKAGING_PLANNING
} from "../../utils/accessRights"
import PlanningProductModal from "../../components/Planning/PlanningProductModal"
import { useDispatch, useSelector } from "react-redux"
import ProductionPlanningSectionInfos from "../../components/Planning/ProductionPlanningSectionInfos"
import { PlanningSplitPackagingModal } from "../../components/Planning/PlanningSplitPackagingModal"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import PlanningBody from "../Planning/PlanningBody/PlanningBody"
import CalendarSectionHeader from "../../components/Calendar/CalendarSectionHeader"
import { Box } from "@mui/material"
import { debounce } from "lodash"

const useStyles = makeStyles(() => ({
    sectionContainer: {
        top: 210,
        position: "relative",
        paddingBottom: 70
    },
    changeDay: {
        width: 20,
        justifyContent: "center"
    },
    topTextfield: {
        fontSize: "1rem"
    },
    toolbar: {
        top: 112
    },
    planningDaysBloc: {
        overflow: "auto",
        width: "100%"
    },
    planningDaysBlocContent: {
        zIndex: 500,
        top: 176
    },
    dragZone: {
        top: 210,
        position: "relative",
        width: 32,
        height: document.getElementsByClassName("sectionContainer").innerHeight,
        backgroundColor: COLORS.WHITE
    },
    bodyContainer: {
        display: "flex",
        position: "relative"
    }
}))

const PlanningsPackaging = (props) => {
    const {
        brand
    } = props

    const days = useSelector(state => state.planning.packagingDays, _isEqual)
    const dataPlanning = useSelector(state => state.planning.packagingDataPlanning, _isEqual)
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    const planningSnackBar = useSelector(state => state.planning.planningSnackBar, _isEqual)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_PACKAGING_PLANNING])
    const canPrint = useSelector(state => state.app.rights[CAN_PRINT_PACKAGING_PLANNING])
    const canPrintCard = useSelector(state => state.app.rights[CAN_PRINT_CARD_PACKAGING_PLANNING])
    const canPrintLabel = useSelector(state => state.app.rights[CAN_PRINT_LABEL_PACKAGING_PLANNING])
    const canDragAndDrop = useSelector(state => state.app.rights[CAN_DRAG_N_DROP_PACKAGING_PLANNING])
    const canInputVolume = useSelector(state => state.app.rights[CAN_INPUT_VOLUME_PACKAGING_PLANNING])
    const canUseGeneralActions = useSelector(state => state.app.rights[CAN_USE_GENERAL_ACTIONS_PACKAGING_PLANNING])
    const rightProduct = useSelector(state => state.planning.rightProduct)
    const anyFocused = useSelector(state => state.planning.anyFocused)
    const gestersPots = useSelector(state => state.planning.gestersPots, _isEqual)

    const classes = useStyles()
    const dispatch = useDispatch()
    const storage = JSON.parse(localStorage.getItem("searchValue"))

    const productTypeOptions = useSelector(state => state.productTypes.productTypeOptions)
    const [filteredType, setFilteredType] = useState(productTypeOptions.filter(productTypeOption => productTypeOption.type === "MAIN_COURSE"))
    const [showDays, setShowDays] = useState(days.filter((day, index) => index < 7))
    const [showDaysCards, setShowDaysCards] = useState([])
    const [cardDragging, setCardDragging] = useState(false)
    const [inputValue, setInputValue] = useState(storage?.planningPackaging || "")
    const [currentIndex, setCurrentIndex] = useState(0)
    const [labelProductModal, setLabelProductModal] = useState({
        packagingDate: null,
        dlc: null,
        isOpen: false,
        recipeName: null,
        recipeId: null,
        itemType: null,
        brands: null
    })

    const [openProductInfo, setOpenProductInfo] = useState(false)
    const [openSplitModal, setOpenSplitModal] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)

    const prevDays = usePrevious(days)
    const prevDataPlanning = usePrevious(dataPlanning)

    const sections = useMemo(() => filteredType, [filteredType])

    const getProductData = (sections) => {
        const finalData = []

        for (const i in showDaysCards) {
            const currentDay = showDaysCards[i]
            const productTypesData = []
            let totalIntern = 0
            let totalExtern = 0

            for (const j in sections) {
                const currentSection = sections[j]
                const sectionCards = currentDay.cards[currentSection.type]

                const intern = sectionCards ? sectionCards.filter(card => card.itemType === "Recipe") : []
                const extern = sectionCards ? sectionCards.filter(card => card.itemType === "SubcontractorProduct") : []

                const internProd = countProduction(intern)
                const externProd = countProduction(extern)

                const sumGesters = getSumGesters(intern, gestersPots)
                const sumPots = getSumPots(intern, gestersPots)

                totalIntern += intern.length
                totalExtern += extern.length

                productTypesData.push({
                    intern: intern.length,
                    extern: extern.length,
                    internProd: internProd,
                    externProd: externProd,
                    total: intern.length + extern.length,
                    expectedSale: 0,
                    label: currentSection.label,
                    key: currentSection.type,
                    sumGesters,
                    sumPots
                })
            }

            finalData.push({
                date: currentDay.date,
                totalIntern: totalIntern,
                totalExtern: totalExtern,
                productTypesData: productTypesData
            })
        }

        return finalData
    }

    useEffect(() => {
        if ("" !== inputValue) {
            onSearchValueChange(inputValue, true)
        }
    }, [])

    useEffect(() => {
        if (prevDays && prevDays.length !== days.length) {
            if (prevDays[0].timestamp !== days[0].timestamp) {
                setShowDays(days.filter((day, index) => index < 7))
                setShowDaysCards(applyFilter(dataPlanning.filter((data, index) => index < 7)))
                setCurrentIndex(0)
            }
            else {
                setShowDays(days.filter((day, index) => index > days.length - 8))
                setShowDaysCards(applyFilter(dataPlanning.filter((data, index) => index > dataPlanning.length - 8)))
                setCurrentIndex(days.length - 7)
            }
        }
        else if (prevDataPlanning && !_isEqual(prevDataPlanning, dataPlanning)) {
            setShowDays(days.filter((day, index) => index >= currentIndex && index < currentIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= currentIndex && index < currentIndex + 7)))
            setCurrentIndex(currentIndex)
        } else {
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= currentIndex && index < currentIndex + 7)))
        }
    }, [days, dataPlanning, inputValue])

    const applyFilter = (days) => {
        const copy = _cloneDeep(days)
        const regex = new RegExp(inputValue, "ig")

        for (const i in copy) {
            const currentDay = copy[i]
            for (const j in currentDay.cards) {
                const currentCards = currentDay.cards[j]
                currentDay.cards[j] = currentCards.filter(card => card.name.match(regex) !== null ||
                    (card.uniqueCode && card.uniqueCode.match(regex) !== null))
            }
        }

        return copy
    }

    const onSearchValueChange = debounce((value, noStorageAdd) => {
        setInputValue(value)

        if (!noStorageAdd) setSearchValue(value, "planningPackaging")
    }, 500)

    const onChangeSearch = useCallback(({ target: { value, noStorageAdd } }) => {
        onSearchValueChange(value || "", !!noStorageAdd)
    }, [])

    const onClickEdit = (day) => {
        dispatch(showMealPlanner(moment(day.timestamp).format("YYYY-MM-DD"), brand))
    }

    const onClickPrev = () => {
        const firstShowDay = showDays[0]
        const oldIndex = days.indexOf(firstShowDay)

        if (oldIndex > 0) {
            const newIndex = oldIndex - 1

            setShowDays(days.filter((day, index) => index >= newIndex && index < newIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7)))
            setCurrentIndex(newIndex)
        }
        else {
            dispatch(loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[0].timestamp).subtract(1, "days").startOf("day").valueOf(),
                true,
                "PACKAGING"
            ))
        }
    }

    const onClickNext = () => {
        const lastShowDay = showDays[showDays.length - 1]
        const firstShowDay = showDays[0]
        const oldFirstIndex = days.indexOf(firstShowDay)
        const oldLastIndex = days.indexOf(lastShowDay)

        if ((oldLastIndex + 1) < days.length) {
            const newIndex = oldFirstIndex + 1

            setShowDays(days.filter((day, index) => index >= newIndex && index < newIndex + 7))
            setShowDaysCards(applyFilter(dataPlanning.filter((day, index) => index >= newIndex && index < newIndex + 7)))
            setCurrentIndex(newIndex)
        }
        else {
            dispatch(loadPlanningSpecificDay(
                days,
                dataPlanning,
                moment.utc(days[days.length - 1].timestamp).add(1, "days").startOf("day").valueOf(),
                false,
                "PACKAGING"
            ))
        }
    }

    const updateProd = useCallback((value, id, card) => {
        dispatch(saveProd(value, id, card))
    }, [])

    const onClickDownloadCards = useCallback((column) => {
        const showDay = showDaysCards.filter(day => day.date === column.timestamp)

        if (showDay.length === 1) {
            dispatch(printMultipleCards(column.timestamp, "PACKAGING"))
        }
    }, [showDaysCards])


    const onClickDownloadPackagingSections = useCallback((column, format) => {
        const showDay = showDaysCards.filter(day => day.date === column.timestamp)
        if (showDay.length !== 1) return
        dispatch(printPackagingSections(showDay, format))
    }, [showDaysCards])



    const onClickDownloadHeadPage = useCallback((column, event, format) => {
        const showDay = showDaysCards.filter(day => day.date === column.timestamp)

        if (showDay.length === 1) {
            dispatch(printHeadPage(column.timestamp, "PACKAGING", format))
        }
    }, [showDaysCards])

    const onClickDownloadNameCard = useCallback((column) => {
        dispatch(downloadNameCard(column.timestamp))
    }, [])

    const _updatePackagingCard = useCallback((newPackagingDate, currentCard) => {
        dispatch(updatePackagingCard(showDaysCards[0].date, newPackagingDate, currentCard))
    }, [showDaysCards])

    const onDateChange = (date) => {
        dispatch(loadPackagingPlanning(moment.utc(date).startOf("day").valueOf()))
    }

    const onTypesChange = (event) => {
        if (event?.target?.value) {
            setFilteredType([event.target.value])
        }
    }

    const printPackagingSingleCard = useCallback((card) => {
        dispatch(printSingleCard(card, card.packagingDate, "PACKAGING"))
    }, [])

    const openRecipeLabelModal = useCallback((packagingDate, dlc, recipeName, recipeId, itemType, internalTags) => {
        setLabelProductModal({
            packagingDate: packagingDate,
            dlc: dlc,
            isOpen: true,
            recipeName: recipeName,
            recipeId: recipeId,
            itemType: itemType,
            internalTags: internalTags
        })
    }, [])

    const closeRecipeLabelModal = () => {
        setLabelProductModal({
            isOpen: false,
            recipeName: null,
            recipeId: null,
            itemType: null,
            internalTags: null
        })
    }

    const onClickExtract = () => {
        const date = Array.isArray(showDays) && showDays.length > 0
            ? showDays[0].timestamp
            : moment.utc().startOf("day").valueOf()
        const lastDate = Array.isArray(showDays) && showDays.length > 0
            ? showDays[showDays.length - 1].timestamp
            : moment.utc().startOf("day").valueOf()
        dispatch(downloadExtract("packagingDate", date, lastDate, "barquettage"))
    }

    const onClickUpdateStocks = () => {
        dispatch(updateMultipleStocks(
            showDays[0].timestamp,
            showDays[showDays.length - 1].timestamp,
            "PACKAGING"
        ))
    }

    const closeProductInfo = () => {
        dispatch(removeRightProduct())
        setOpenProductInfo(false)
        setSelectedCard(null)
    }

    const _openProductInfo = useCallback((card) => {
        if (!anyFocused) {
            dispatch(findProduct(card))
            setOpenProductInfo(true)
            setSelectedCard(card)
        }
    }, [])

    const _asyncPrintLabel = (data) => {
        dispatch(asyncPrintLabel(data))
    }

    const _setAnyFocused = useCallback((focused) => {
        dispatch(setAnyFocused(focused))
    }, [])

    const goToProduct = () => {
        const isRecipe = (selectedCard.itemType === "Recipe")

        if (isRecipe) window.open(`/products/recipe/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
        if (!isRecipe) window.open(`/products/subcontractor-product/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
    }

    const _openSplitModal = useCallback(card => {
        setSelectedCard(card)
        setOpenSplitModal(true)
    }, [])

    const closeSplitModal = useCallback(() => {
        setOpenSplitModal(false)
        setSelectedCard(null)
    }, [])

    const _saveSplitPackaging = useCallback((data) => {
        dispatch(saveSplitPackaging(showDaysCards[0].date, data))

        setOpenSplitModal(false)
        setSelectedCard(null)
    }, [])

    const color = COLORS.PRIMARY_COLOR
    const productData = getProductData(sections)
    const date = Array.isArray(showDays) && showDays.length > 0
        ? moment(showDays[0].timestamp)
        : moment.utc().startOf("day").valueOf()

    const renderCalendarSectionHeaderInfo = (section) => productData.map((product, index) => {
        const sectionData = product.productTypesData.find(productType => productType.key === section.type)
        return <CalendarSectionHeader sectionData={sectionData} section={section} key={index} />
    })

    return (
        <div>
            <PlanningToolbar menuIsOpen={menuIsOpen} className={classes.toolbar}>
                <PlanningProductionPackagingFilters
                    onTypesChange={onTypesChange}
                    onSearchValueChange={onChangeSearch}
                    onDateChange={onDateChange}
                    date={date}
                    types={productTypeOptions}
                    filteredType={filteredType}
                    searchFilter={inputValue} // just for initialization
                />
                {
                    canUseGeneralActions &&
                    <PlanningPackagingActions
                        onClickExtract={onClickExtract}
                        onClickUpdateStocks={onClickUpdateStocks}
                    />
                }
            </PlanningToolbar>
            <div className={classes.planningDaysBloc}>
                <PlanningDays
                    rootClassName={classes.planningDaysBlocContent}
                    days={showDays}
                    planningType="PACKAGING"
                    onEdit={onClickEdit}
                    onDownloadCards={onClickDownloadCards}
                    onDownloadHeadPage={onClickDownloadHeadPage}
                    onDownloadNameCard={onClickDownloadNameCard}
                    onClickPrev={onClickPrev}
                    onClickNext={onClickNext}
                    productData={productData}
                    canPrint={canPrint}
                    onDownloadPackagingSections={onClickDownloadPackagingSections}
                />
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.dragZone}>
                </div>
                <div className={classes.sectionContainer}>
                    {
                        sections.map((section, index) => {
                            return (
                                <CalendarSection
                                    key={index}
                                    title={section.label}
                                    section={section}
                                    color={color}
                                    day={showDays}
                                    searching={"" !== inputValue}
                                    cardDragging={cardDragging}
                                    headerInfos={() => renderCalendarSectionHeaderInfo(section)}
                                >
                                    <Box display="flex">
                                        {productData.map((product, index) => (
                                            <ProductionPlanningSectionInfos
                                                key={index}
                                                sectionData={product.productTypesData.find(
                                                    (productType) => productType.key === section.type
                                                )}
                                                product={product}
                                                canEdit={canEdit}
                                                type="PACKAGING"
                                            />
                                        ))}
                                    </Box>
                                    <CalendarSection
                                        isSub={true}
                                        color={COLORS.LIGHT_GREY}
                                        title="Interne"
                                        showInfoBtn={false}
                                        searching={"" !== inputValue}
                                    >
                                        <PlanningBody
                                            data={showDaysCards}
                                            isCardDragging={setCardDragging}
                                            section={section}
                                            brand={brand}
                                            isIntern="Recipe"
                                            planningType="PACKAGING"
                                            saveProd={updateProd}
                                            updatePackagingCard={_updatePackagingCard}
                                            printPackagingSingleCard={printPackagingSingleCard}
                                            openSplitModal={_openSplitModal}
                                            openRecipeLabelModal={openRecipeLabelModal}
                                            canPrint={canPrintCard}
                                            openProductInfo={_openProductInfo}
                                            setAnyFocused={_setAnyFocused}
                                            isDragAndDroppable={canDragAndDrop}
                                            canInputVolume={canInputVolume}
                                            canPrintLabel={canPrintLabel}
                                        />
                                    </CalendarSection>
                                    <CalendarSection
                                        isSub={true}
                                        color={COLORS.LIGHT_GREY}
                                        title="Externe"
                                        showInfoBtn={false}
                                        searching={"" !== inputValue}
                                    >
                                        <PlanningBody
                                            data={showDaysCards}
                                            isCardDragging={setCardDragging}
                                            section={section}
                                            brand={brand}
                                            isIntern="SubcontractorProduct"
                                            planningType="PACKAGING"
                                            saveProd={updateProd}
                                            updatePackagingCard={_updatePackagingCard}
                                            printPackagingSingleCard={printPackagingSingleCard}
                                            openSplitModal={_openSplitModal}
                                            openRecipeLabelModal={openRecipeLabelModal}
                                            canPrint={canPrintCard}
                                            openProductInfo={_openProductInfo}
                                            setAnyFocused={setAnyFocused}
                                            isDragAndDroppable={canDragAndDrop}
                                            canInputVolume={canInputVolume}
                                            canPrintLabel={canPrintLabel}
                                        />
                                    </CalendarSection>
                                </CalendarSection>
                            )
                        })
                    }
                </div>
                <div className={classes.dragZone}>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={planningSnackBar.open}
                autoHideDuration={planningSnackBar.duration}
                onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    variant={planningSnackBar.type}
                    message={planningSnackBar.message}
                    onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
                />
            </Snackbar>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={labelProductModal.isOpen}
                onClose={closeRecipeLabelModal}
            >
                <LabelProductModal
                    closeModal={closeRecipeLabelModal}
                    packagingDate={labelProductModal.packagingDate}
                    dlc={labelProductModal.dlc}
                    name={labelProductModal.recipeName}
                    type={"Recipe" === labelProductModal.itemType ? "RECIPE" : "SUBCONTRACTORS_PRODUCT"}
                    productId={labelProductModal.recipeId}
                    onSubmitPrint={_asyncPrintLabel}
                    internalTags={labelProductModal.internalTags}
                    fromPackagingPlanning
                />
            </Modal>
            <PlanningProductModal
                open={openProductInfo}
                handleClose={closeProductInfo}
                selectedCard={selectedCard}
                rightProduct={rightProduct}
                goToProduct={goToProduct}
            />
            {
                openSplitModal &&
                <PlanningSplitPackagingModal
                    currentCard={selectedCard}
                    closeModal={closeSplitModal}
                    saveSplitPackaging={_saveSplitPackaging}
                />
            }
            <HelpButton link={helpLinks.planning} />
        </div>
    )
}

export default PlanningsPackaging
