import React from "react"

import styled from "@emotion/styled"
import { Typography } from "@mui/material"

import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../../utils/constant"
import { COLORS, roundNumber } from "../../../../utils"
import { StyledSectionFirstBodyColumn, StyledStepBodyCell } from "../StyledSectionComponents"
import { convertKilosIntoGrams } from "../../../../utils/ressources"

const widths = PRODUCTION_STEPS_COL_WIDTHS
export const COMPONENT_NAME = "SECTIONS"

const StyledText = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  color: COLORS.PRODUCTION_STEPS_TEXT_GREY
})

const SectionPreview = ({ section }) => {
  return (
    <>
      <StyledSectionFirstBodyColumn className="flexRow center">
        <StyledText>{section.name || "-"}</StyledText>
      </StyledSectionFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledText>{roundNumber(convertKilosIntoGrams(section.grossWeight), 3) || "-"}</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledText>
          {section.cost ? `${roundNumber(section.cost, 3)} €` : "_"}
        </StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledText>{roundNumber(convertKilosIntoGrams(section.netWeight), 3) || "-"}</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[13]}>
        <StyledText>-</StyledText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[14]} />
    </>
  )
}

export default SectionPreview
