import React, { useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { COLORS } from "../../../utils"

const HeaderMenus = ({ menus = [] }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ color: COLORS.WHITE }}
          size="large">
            <MoreVertIcon />
        </IconButton>
        <Menu
          id={id}
          open={Boolean(open)}
          anchorEl={anchorEl}
          onClose={handleClose}
          keepMounted
          transformOrigin={{
              vertical: "top",
              horizontal: "center"
          }}
        >
          {menus.map((menu, index) => (
            <MenuItem key={menu.label + index} onClick={menu.onClick}>{menu.label}</MenuItem>
          ))}
        </Menu>
    </div>

    )
}

export default HeaderMenus