import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import MUISwitch from "@mui/material/Switch"
import { COLORS } from "../../utils"
import { red } from "@mui/material/colors"

const useStyles = makeStyles({
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: COLORS.SUPPLIER_DELIVERY_COlOR
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: COLORS.SUPPLIER_DELIVERY_COlOR
    },
})

export const Switch = (props) => {
    const {
        onChange,
        checked,
        name,
        placeholder
    } = props

    const classes = useStyles()


    return (
        <MUISwitch
            classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                track: checked ? classes.switchTrackChecked : classes.switchTrack,
            }}
            color="default"
            name={name}
            placeholder={placeholder}
            checked={checked}
            onChange={onChange}
        />
    )
}

export default Switch
