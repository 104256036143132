import { makeStyles } from "@mui/styles"
import React from "react"
import {
    Formik,
    Form
} from "formik"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import { newsInitialValues } from "../../utils/supplierItemUtils"

const useStyles = makeStyles(() => ({
    container: {
        fontSize: 20,
        width: "auto",
        minWidth: 500,
        height: 400,
        padding: 20
    },
    title: {
        margin: "10px 0",
        fontSize: 20
    },
    form: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    inputsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    field: {
        width: "100%"
    },
    buttonsContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    }
}))

const NewsForm = (props) => {
    const {
        supplierItem,
        onUpdateSupplierItem,
        onClose
    } = props

    const classes = useStyles()

    const initialValues = newsInitialValues(supplierItem)

    const submitForm = async (values) => {
        onUpdateSupplierItem("NEWS", values)
        onClose()
    }

    return (
        <div className={classes.container}>
            <p className={classes.title}>Editer les actualités</p>
            <Formik
                initialValues={initialValues}
                onSubmit={submitForm}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit
                }) => {
                    return (
                        <Form className={classes.form}>
                            <div className={classes.inputContainer}>
                                <TextField
                                    variant="standard"
                                    name="news"
                                    value={values.news}
                                    label="Actualités"
                                    className={classes.field}
                                    onChange={handleChange}
                                    multiline
                                />
                            </div>
                            <div className={classes.buttonsContainer}>
                                <Button
                                    onClick={onClose}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Valider
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default NewsForm