import React from "react"
import { makeStyles } from "@mui/styles"
import { Form, Formik } from "formik"
import moment from "moment"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import { DatePicker } from "@mui/x-date-pickers"
import { InputLabel, TextField } from "@mui/material"
import { COLORS } from "../../../utils"

const useStyles = makeStyles(() => ({
	container: {
		color: COLORS.BLACK,
		boxShadow: "0 0 3px rgba(0,0,0,.2)",
		backgroundColor: "white",
		padding: "15px 30px 25px 30px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	title: {
		fontSize: 20,
		fontFamily: "Roboto",
		fontWeight: 500
	},
	filterContainer: {
		marginTop: 10,
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
	},
	siteSelector: {
		marginTop: 5,
		width: 130
	},
	separator: {
		marginTop: 20,
		width: "100%",
		height: 1,
		backgroundColor: "#C4C4C4"
	},
	submitButton: {
		marginTop: 21,
		width: 328,
		height: 38,
		borderRadius: 0
	}
}))

const OrderReceptionFilter = (props) => {
	const {
		sites,
		onSubmit
	} = props
	
	const classes = useStyles()

	const defaultDate = moment.utc().startOf("day").valueOf()

	const onConfirm = (values) => {
		onSubmit(values.site, values.date)
	}

	const renderSites = (objects) => {
		return (
			objects.map((item, i) => 
				<MenuItem
					value={item}
					key={i}
				>
					{item.name}
				</MenuItem>
			)
		)
	}

	return (
		<Formik
			key={defaultDate}
			initialValues={{
				site: sites[0],
				date: defaultDate
			}}
			onSubmit={onConfirm}
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleSubmit
			}) => {
				return (
					<Form className={classes.container} onSubmit={handleSubmit}>
						<h3 className={classes.title}>Sélectionnez le site et la période de réception</h3>
						<div className={classes.filterContainer}>
							<div>
								<InputLabel>Site de réception</InputLabel>
								<Select
									variant="standard"
									value={values.site}
									name="site"
									className={classes.siteSelector}
									onChange={handleChange}
									required
								>
									{renderSites(sites)}
								</Select>
							</div>
							<div>
								<InputLabel>Jour de réception</InputLabel>
								<DatePicker
									disableCloseOnSelect={false}
									showToolbar={false}
									value={values.date}
									name="date"
									inputFormat="DD/MM/YY"
									required
									renderInput={(params) => <TextField {...params} variant="standard" />}
									onChange={val => {
										setFieldValue("date", moment.utc(val).startOf("day").valueOf())
									}}
								/>
							</div>
						</div>
						<span className={classes.separator}/>
						<Button
							className={classes.submitButton}
							color="primary"
							variant="contained"
							type="submit"
						>
							Accéder aux réceptions
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

export default OrderReceptionFilter