import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tab from "@mui/material/Tab"
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import RoomServiceIcon from "@mui/icons-material/RoomService"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ListIcon from "@mui/icons-material/List"
import SettingsIcon from "@mui/icons-material/Settings"
import { COLORS } from "../../utils"
import Tabs from "@mui/material/Tabs"
import {
    showPlanningSell,
    showPlanningPrev,
    showPlanningProduction,
    showPlanningPackaging,
    showPlanningDispatch,
    showPlanningSetting
} from "../../actions/Planning/Planning"
import {
    showPlanningSettingReport
} from "../../actions/Planning/Settings/PlanningReport"
import { showMonitoring } from "../../actions/Planning/PlanningDashboard"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    subNavBarContainer: {
        composes: "flexRow",
        height: 64,
        flexGrow: 1,
        textColorSecondary: COLORS.TEXT_COLOR_FOR_PRIMARY,
        backgroundColor: COLORS.PRIMARY_COLOR,
        justifyContent: "center",
        position: "fixed",
        zIndex: 200,
    },
    subNavBarContainerWithMenu: {
        composes: "$subNavBarContainer",
        width: "calc(100% - 240px)",
    },
    subNavBarContainerWithoutMenu: {
        composes: "$subNavBarContainer",
        width: "calc(100% - 54.4px)",
    },
    tabsContainer: {
        height: 64,
        textColorSecondary: COLORS.TEXT_COLOR_FOR_PRIMARY,
        composes: "flexRow",
        margin: "auto"
    },
    tab: {
        color: COLORS.TEXT_COLOR_FOR_PRIMARY,
        width: 160,
        height: 64,
        minHeight: 64,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "13px"
    },
    selectedTab: {
        composes: "$tab",
        backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
    },
    configContainer: {
        width: 64,
        height: 64,
        right: 0,
        position: "fixed",
    },
    selectedConfigContainer: {
        composes: "$configContainer",
        backgroundColor: COLORS.LIGHT_PRIMARY_COLOR,
    },
    configIcon: {
        color: COLORS.TEXT_COLOR_FOR_PRIMARY,
        width: 32,
        height: 32,
        marginTop: 16,
        marginLeft: 16,
    }
})

const PlanningPage = (props) => {
    const {
        tabValue,
        isConfig,
        children,
    } = props

    const classes = useStyles()

    const menuIsOpen = useSelector(state => state.app.menuIsOpen)

    const dispatch = useDispatch()

    const _onClickConfig = () => {
        dispatch(showPlanningSettingReport())
    }

    const _onTabChange = (event, tabValue) => {
        switch (tabValue) {
            case "sell":
                dispatch(showPlanningSell())
                break
            case "prev":
                dispatch(showPlanningPrev())
                break
            case "production":
                dispatch(showPlanningProduction())
                break
            case "packaging":
                dispatch(showPlanningPackaging())
                break
            case "dispatch":
                dispatch(showPlanningDispatch())
                break
            case "settings":
                dispatch(showPlanningSetting())
                break
            case "dashboard":
                dispatch(showMonitoring())
                break
            default:
                dispatch(showPlanningSell())
        }
    }

    return (
        <Fragment>
            <div className={menuIsOpen ? classes.subNavBarContainerWithMenu : classes.subNavBarContainerWithoutMenu}>
                <Tabs
                    value={tabValue && !isConfig ? tabValue : false}
                    className={classes.tabsContainer}
                    onChange={_onTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    centered
                >
                    <Tab
                        value="sell"
                        label="vente"
                        className={tabValue === "sell" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<ShoppingBasketIcon/>}
                    />
                    <Tab
                        value="prev"
                        label="prévisions"
                        className={tabValue === "prev" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<ListIcon/>}
                    />
                    <Tab
                        value="production"
                        label="production"
                        className={tabValue === "production" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<RestaurantMenuIcon/>}
                    />
                    <Tab
                        value="packaging"
                        label="barquettage"
                        className={tabValue === "packaging" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<RoomServiceIcon/>}
                    />
                    <Tab
                        value="dispatch"
                        label="dispatch"
                        className={tabValue === "dispatch" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<LocalShippingIcon/>}
                    />
                    <Tab
                        value="dashboard"
                        label="dashboard"
                        className={tabValue === "dashboard" && !isConfig ? classes.selectedTab : classes.tab}
                        icon={<DashboardIcon/>}
                    />
                </Tabs>
                <div
                    className={isConfig ? classes.selectedConfigContainer : classes.configContainer}
                    onClick={() => _onClickConfig()}
                >
                    <SettingsIcon className={classes.configIcon}/>
                </div>
            </div>
            {children}
        </Fragment>
    )
}

export default PlanningPage
