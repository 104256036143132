import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import IconButton from "@mui/material/IconButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import { makeStyles } from "@mui/styles"
import SearchInput from "./SearchInput"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import { FormControl, TextField } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import PlanningBrandSelector from "../Planning/PlanningBrandSelector"
import {COLORS} from "../../utils"
import moment from "moment"

const useStyles = makeStyles(() => ({
	searchFieldDiv: {
		display: "flex",
		alignItems: "baseline",
		padding: "10px 18px 18px 10px",
		width: "100%",
		backgroundColor: COLORS.LIGHT_GREY
	},
	date: {
		minWidth: 140,
		maxWidth: 170,
		marginLeft: 20
	},
	refreshButton: {
		marginLeft: "auto",
		"& svg": {
			fontSize: 30
		}
	},
	formControl: {
		minWidth: 120,
		marginLeft: 20
	},
	select: {
		width: 180
	}
}))

const ReceptionGapFilter = (props) => {
	const classes = useStyles()
	const {
		brand,
		startDate,
		endDate,
		onNameChange,
		sectors,
		hubs,
		productTypes,
		selectedSectors,
		selectedHubs,
		selectedProductTypes,
		changeDate,
		nameOrCode,
		onSectorsChange,
		onHubsChange,
		onBrandClick,
		onProductTypesChange,
	} = props

	const onStartDateChange = async (date) => {
		await changeDate(moment.utc(date).startOf("day").valueOf(), endDate)
	}

	const onEndDateChange = async (date) => {
		await changeDate(startDate, moment.utc(date).endOf("day").valueOf())
	}

	const refresh = async () => {
		await changeDate(startDate, endDate)
	}

	const renderSelectResult = (selected, resources) => {
		if (selected.includes("ALL")) {
			return resources[0].label
		}
		else {
			return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
		}
	}

	const renderData = (objects, selectedObjects) => {
		return (
			objects.map((item, index) =>
				<MenuItem
					value={item.value}
					key={index}
				>
					<Checkbox
						checked={selectedObjects.includes(item.value)}
					/>
					<ListItemText primary={item.label} />
				</MenuItem>
			)
		)
	}

	return (
        <div className={classes.searchFieldDiv}>
			<SearchInput isTablet={false} onNameChange={onNameChange} defaultValue={nameOrCode} />
			{/* start date */}
			<DatePicker
				disableCloseOnSelect={false}
				showToolbar={false}
				variant="inline"
				value={startDate}
				inputFormat="DD/MM/YYYY"
				onChange={onStartDateChange}
				renderInput={(params) => <TextField {...params} variant="standard" className={classes.date} />}
			/>
			{/* end date */}
			<DatePicker
				disableCloseOnSelect={false}
				showToolbar={false}
				variant="inline"
				value={moment.utc(endDate).startOf("day").valueOf()}
				inputFormat="DD/MM/YYYY"
				onChange={onEndDateChange}
				renderInput={(params) => <TextField {...params} variant="standard" className={classes.date} />}
			/>
			<FormControl className={classes.formControl} variant="standard">
				<PlanningBrandSelector
					brand={brand}
					onBrandClick={onBrandClick}
					activeBrandsOnly
				/>
			</FormControl>
			<FormControl className={classes.formControl}>
				<InputLabel htmlFor="sector">Secteur</InputLabel>
				<Select
					variant="standard"
					className={classes.select}
					multiple={true}
					value={selectedSectors}
					onChange={(e) => onSectorsChange(e.target.value)}
					inputProps={{
						name: "sector",
						id: "sector",
					}}
					renderValue={
						selected => renderSelectResult(selected, sectors)
					}
				>
					{ renderData(sectors, selectedSectors) }
				</Select>
			</FormControl>
			<FormControl className={classes.formControl} variant="standard">
				<InputLabel htmlFor="hub">Hub</InputLabel>
				<Select
					variant="standard"
					className={classes.select}
					multiple={true}
					value={selectedHubs}
					onChange={(e) => onHubsChange(e.target.value)}
					inputProps={{
						name: "hub",
						id: "hub",
					}}
					renderValue={
						selected => renderSelectResult(selected, hubs)
					}
				>
					{ renderData(hubs, selectedHubs) }
				</Select>
			</FormControl>
			<FormControl className={classes.formControl} variant="standard">
				<InputLabel htmlFor="type">Type</InputLabel>
				<Select
					variant="standard"
					className={classes.select}
					multiple={true}
					value={selectedProductTypes}
					onChange={(e) => onProductTypesChange(e.target.value)}
					renderValue={
						selected => renderSelectResult(selected, productTypes)
					}
					inputProps={{
						name: "type",
						id: "type",
					}}
				>
					{ renderData(productTypes, selectedProductTypes) }
				</Select>
			</FormControl>

			{/* button to refresh */}
			<IconButton className={classes.refreshButton} onClick={refresh} size="large">
				<RefreshIcon/>
			</IconButton>
		</div>
    )
}

export default ReceptionGapFilter
