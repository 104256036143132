import React from "react"
import CommentIcon from "@mui/icons-material/Comment"
import { IconButton, Badge } from "@mui/material"
import { COLORS } from "../../utils"

const PackagingExecutionCommentBadge = ({ count, handleClick }) => {

	return (
		<IconButton
			aria-label="Voir les commentaires"
			onClick={handleClick}
			size="large"
		>
			<Badge
				badgeContent={count}
				showZero
				sx={{
					"& .MuiBadge-badge": {
						transform: "translate(20px, 3px)",
						color: COLORS.LABEL_GREY,
						backgroundColor: "transparent",
						fontFamily: "Roboto",
						fontSize: "14px",
						fontWeight: "500",
					}
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<CommentIcon
					sx={{
						width: 16,
						height: 16,
						color: COLORS.LABEL_GREY
					}} />
			</Badge>
		</IconButton >
	)
}

export default PackagingExecutionCommentBadge