import Parse from "parse"

const Suppliers = Parse.Object.extend("Suppliers")

export async function getParseSupplier(supplierId, includes = []) {
    try {
        return await new Parse.Query(Suppliers)
            .equalTo("objectId", supplierId)
            .include(includes)
            .first()
    }
    catch (e) {
        console.error("parseManager.products.supplier.parseSupplierManager.getParseSupplier error : ", e)
        return Promise.reject(e)
    }
}

export async function getDefaultSupplier(toJson = true) {
    try {
        const supplier = await new Parse.Query(Suppliers)
            .first()

        return toJson ? supplier.toJSON() : supplier
    }
    catch (e) {
        console.error("parseManager.products.supplier.parseSupplierManager.getDefaultSupplier error : ", e)
        return Promise.reject(e)
    }
}

export async function deleteParseSuppliers(suppliers) {
    try {
        return await new Parse.Query(Suppliers)
            .containedIn("objectId", suppliers)
            .each(async supplier => {
                supplier.get("stockZones").map(async stockZone => {
                    await stockZone.destroy()
                })

                await supplier.destroy()
            })
    }
    catch (e) {
        console.error("parseManager.products.supplier.parseSupplierManager.deleteParseSuppliers error : ", e)
        return Promise.reject(e)
    }
}

export const getSuppliers = async (toJson = true) => {
    try {
        const suppliers = await new Parse.Query(Suppliers)
            .notEqualTo("deleted", true)
            .find()

        return toJson ? suppliers.map(supplier => supplier.toJSON()) : suppliers
    }
    catch (e) {
        console.error("parseManager.products.supplier.parseSupplierManager.getSuppliers error : ", e)
        return Promise.reject(e)
    }
}