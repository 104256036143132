import React, { useState, useEffect } from "react"
import withStyles from "@mui/styles/withStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import {COLORS, ACTIVE_KFC_BRANDS, getTimezoneDate} from "../../utils"
import Button from "@mui/material/Button"
import {
	getAvailableBrand,
	checkProductionItemExist
} from "../../parseManager/planning/parsePlanningManager"
import PlanningBrandSelector from "./PlanningBrandSelector"
import { CustomKeyboardDatePicker } from "../CustomKeyboardDatePicker"
import moment from "moment"

const styles = {
	addModalContainer: {
		backgroundColor: COLORS.WHITE,
		width: 700,
		outline: "none",
		margin: "0 auto",
		top: "25%",
		position: "relative",
		borderRadius: 3,
		color: COLORS.BLACK,
		padding: 10
	},
	closeContainer: {
		textAlign: "right"
	},
	closeIcon: {
		cursor: "pointer"
	},
	title: {
		fontSize: 20,
		textAlign: "center"
	},
	addProductionItemModalInfos: {
		margin: "20px 160px 20px 160px",
		textAlign: "center"
	},
	commercialName: {
		textAlign: "center",
		marginTop: 10
	},
	actionContainer: {
		textAlign: "center"
	},
	confirmBtn: {
		marginBottom: 10,
		color: COLORS.WHITE
	},
	errorLabel: {
		textAlign: "center",
		marginTop: 15,
		marginBottom: 15,
		color: COLORS.RED
	},
	datePickerContainer: {
		marginTop: 10
	}
}

const PlanningAddProductionItemModal = (props) => {
	const {
		closeModal,
		classes,
		currentCard,
		addProductionItem
	} = props
	
	const [brands, setBrands] = useState([])
	const [selectedBrand, setSelectedBrand] = useState()
	const [date, setDate] = useState(null)
	const [showError, setShowError] = useState(false)
	const today = moment.utc().startOf("days")

	useEffect(() => {
		(async function getAvailableBrands(){
			const result = await getAvailableBrand(currentCard.itemId, currentCard.itemType)

			setBrands(result)
			setSelectedBrand(result.length > 0 ? ACTIVE_KFC_BRANDS.find(el => el.name === result[0].name) : {})
		})()
	}, [])
	
	const confirm = async () => {
		const exist = await checkProductionItemExist(date, currentCard.itemId, selectedBrand.name)
		
		if (exist){
			setShowError(true)
		}
		else {
			setShowError(false)
			if (date && selectedBrand){
				addProductionItem(moment.utc(date).startOf("day").valueOf(), currentCard.itemId, selectedBrand.name, currentCard.itemType)
			}
		}
	}
	
	const changeBrand = (brand) => {
		setSelectedBrand(brand)
		setShowError(false)
	}
	
	const changeDate = (date) => {
		setDate(getTimezoneDate(date))
		setShowError(false)
	}
	
	return (
		<Modal open={true}>
			<div className={classes.addModalContainer}>
				<div className={classes.closeContainer}>
					<CloseIcon
						className={classes.closeIcon}
						onClick={closeModal}
					/>
				</div>
				<div className={classes.title}>
					Sur quel jour de vente voulez-vous ajouter ce produit ?
				</div>
				<div className={classes.commercialName}>
					{ `${currentCard.commercialName} - ${currentCard.uniqueCode}` }
				</div>
				<div className={classes.addProductionItemModalInfos}>
					<div>
						<PlanningBrandSelector
							brand={selectedBrand}
							allBrands={brands}
							onBrandClick={changeBrand}
						/>
					</div>
					<div className={classes.datePickerContainer}>
						<CustomKeyboardDatePicker
							value={date}
							onChange={changeDate}
							variant="inline"
							label="Jour de vente"
							minDate={today}
							placeholder="Jour de vente"
							format="DD/MM/YYYY"
							disabled={false}
						/>
					</div>
				</div>
				{
					showError &&
						<div className={classes.errorLabel}>
							Vous ne pouvez pas ajouter ce produit car il est déjà présent à la carte pour cette marque
						</div>
				}
				<div className={classes.actionContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classes.confirmBtn}
						onClick={() => {confirm()}}
						disabled={!date || !selectedBrand}
					>
						VALIDER
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default withStyles(styles)(PlanningAddProductionItemModal)
