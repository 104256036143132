import React, {useCallback, useEffect, useState} from "react"
import {generateStockUnitLabel} from "../../../utils/orderReception"
import moment from "moment/moment"
import DataGrid from "../../../components/DataGrid/DataGrid"

const OrderReceptionLots = (props) => {
	const {
		lots
	} = props

	const [data, setData] = useState([])

	useEffect(() => {
		createDataObject()
	}, [lots])

	const createDataObject = useCallback(() => {
		lots && setData(lots.map(createData))
	})

	const createData = (lot) => {
		return {
			name: lot.orderSupplierItem.name,
			reference: lot.orderSupplierItem.reference,
			lotNumber: lot ? lot.lotNumber : "",
			dlc: lot.dlc ? moment(lot.dlc).format("DD/MM/YYYY") : "",
			quantity: (lot.initialQuantity.toString()) + "-" + (lot && lot.orderSupplierItem ? generateStockUnitLabel(lot.orderSupplierItem) : ""),
		}
	}

	const columns = [
		{
			key: "name",
			label: "Nom de l'article",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "reference",
			label: "Référence",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "lotNumber",
			label: "N° de lot",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: true,
				type: "text",
				fullText: true
			}
		},
		{
			key: "dlc",
			label: "DLC",
			dataType: "date",
			sortable: false,
			filter: {
				filterable: true,
				type: "date",
				strict: true
			}
		},
		{
			key: "quantity",
			label: "Quantité réceptionnée",
			dataType: "text",
			sortable: false,
			filter: {
				filterable: false,
			},
			render: (value) => {
				const quantity = value.split("-")[0]
				const unit = value.split("-")[1]
				return (
					<div><span>{quantity}</span><span style={{color: "#7C7C7C"}}> ({unit})</span></div>
				)
			}
		}
	]

	return (
		<div>
			<DataGrid
				title={"Lots crées"}
				columns={columns}
				data={data}
				withFilters={true}
				rowLabel={null}
				withAdditionalFiltersOptions={false}
				tableContainerStyle={{boxShadow: "none"}}
			/>
		</div>
	)
}

export default OrderReceptionLots