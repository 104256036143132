import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import { Store } from "@mui/icons-material"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: COLORS.SUPPLIER_DELIVERY_COlOR
    },
    icon: {
        marginRight: 10,
        color: COLORS.SUPPLIER_DELIVERY_COlOR,
        height: 24
    }
})

const OrdersHeaderTitle = () => {
    const classes = useStyles()
    
    return (
        <div className={classes.container}>
            <Icon className={classes.icon}>
                <Store />
            </Icon>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                Commandes
            </Typography>
        </div>
    )
}

export default OrdersHeaderTitle
