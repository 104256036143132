import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import { Formik, Form } from "formik"
import moment from "moment"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiPaper-rounded": {
			width: 350,
			borderRadius: 15
		}
	},
	rootDialogActions: {
		justifyContent: "center",
		padding: 15,
		marginBottom: 15
	},
	formControl: {
		marginLeft: "unset",
		width: "100%",
		marginTop: 16
	},
	dialogTitle: {
		display: "flex",
		justifyContent: "space-between",
		paddingBottom: 0
	},
	dialogTitleLabel: {
		fontWeight: 500,
		marginTop: 5,
		fontSize: 20,
		lineHeight: "24px",
		width: 596
	}
}))

const DispatchBLModal = (props) => {
	const {
		open,
		onClose,
		onConfirm,
		date
	} = props
	
	const classes = useStyles()
	const sectors = [
		{value: "PARIS", label: "Paris"},
		{value: "PROVINCE", label: "Province"}
	]
	
	const renderSectors = () => {
		return sectors.map(sector => <MenuItem key={sector.value} value={sector.value}>{sector.label}</MenuItem>)
	}
	
	return (
				<Formik
			key={moment.utc().valueOf()}
			initialValues={{
				date,
				sector: "PARIS",
			}}
			onSubmit={onConfirm}
		>
			{({
					values,
					handleChange,
					handleSubmit
				}) => {
				return (
										<Form>
						<Dialog
							onClose={onClose}
							aria-labelledby="customized-dialog-title"
							open={open}
							className={classes.dialog}
						>
							<DialogTitle
																id="customized-dialog-title"
																onClose={onClose}
																classes={{root: classes.dialogTitle}}>
							</DialogTitle>
							<DialogContent>
								<FormControl className={classes.formControl} variant="standard">
									<DatePicker
										disableCloseOnSelect={false}
										variant="inline"
										name="date"
										value={values.date}
										inputFormat="DD/MM/YYYY"
										disabled={true}
										renderInput={(params) => <TextField {...params} variant="standard" label="Jour pour BL"/>}
									/>
								</FormControl>
								<FormControl className={classes.formControl} variant="standard">
									<InputLabel>Secteur</InputLabel>
									<Select
										variant="standard"
										name="sector"
										label="Secteurs"
										placeholder="Secteurs"
										value={values.sector}
										onChange={handleChange}
									>
										{renderSectors()}
									</Select>
								</FormControl>
								<input type="hidden" name="typeSubmit" />
							</DialogContent>
							<DialogActions classes={{root: classes.rootDialogActions}}>
								<Button
									color="primary"
									type="submit"
									variant="contained"
									onClick={handleSubmit}
								>
									Télécharger les Bons de livraison
								</Button>
							</DialogActions>
						</Dialog>
					</Form>
								)
			}}
		</Formik>
		)
}

export default DispatchBLModal

