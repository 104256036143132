import dayjs from "dayjs"
import Parse from "parse"

import { getFilteredPackagingExecutions, getPackagingExecutionById, savePackagingExecutionNewStatus } from "../../parseManager/packagingExecutions/parsePackagingExecutionManager"
import { getSiteById, getSites } from "../../parseManager/site/parseSiteManager"
import {dateToTimestamp, roundNumber} from "../../utils"
import { openAppMessageSnackBar } from "../Utils/app"
import { onEnter, push, getReplacingPath, actionWithLoader } from "../Utils/utils"
import { editableHourToTimestamp, PSE_REGEX_HOURS } from "../../utils/productionStepExecution"
import { getPackagingExecutionSiteSelector } from "../../reducers/Packaging/packagingExecution"
import { PE_STATUSES } from "../../utils/packagingExecutions"
import { PackagingLine } from "../Site/sites"

const PackagingExecution = Parse.Object.extend("PackagingExecutions")

export const packagingExecutionBaseUrl = "/packagingExecutions"

export const PACKAGING_EXECUTION_URLS = {
	root: packagingExecutionBaseUrl,
	filter: `${packagingExecutionBaseUrl}/filter`,
	read: `${packagingExecutionBaseUrl}/read`,
	hoursEdit: `${packagingExecutionBaseUrl}/hours`,
	pe: `${packagingExecutionBaseUrl}/packagingExecution`
}

export const generatePackagingExecutionsVolumes = (packagingDate) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const result = await Parse.Cloud.run("setPackagingExecutionsData", { packagingDate })
			if (result.success) {
				dispatch(
					openAppMessageSnackBar(
						"Les volumes de barquettage ont bien été générées pour la journée du " + dayjs(packagingDate).format("DD/MM/YYYY")
					)
				)
			}
			else {
				dispatch(openAppMessageSnackBar(result.message, "error"))
			}
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}


const loadPackagingExecutionsFilters = () => {
	return actionWithLoader(async (dispatch) => {
		const localStorage = window.localStorage
		const previousSelectedSite = localStorage.getItem("packagingExecutionSelectedSite")
		const date = new Date()
		const { sites } = await getSites()

		dispatch({
			type: "PACKAGING_EXECUTIONS_FILTERS_LOADED",
			date,
			sites: sites,
			site: previousSelectedSite ? JSON.parse(previousSelectedSite) : sites[0]
		})
	})
}

export const loadPackagingExecutionsRead = (params) => {
	return actionWithLoader(async (dispatch) => {
		const siteId = params.siteId
		const date = params.date
		const site = await getSiteById(siteId)
		const filters = { packagingDate: dateToTimestamp(date) }

		if (window.location.pathname.includes("hours")) {
			const status = window.localStorage.getItem("packagingExecutionStatus")
			if (status === "WEIGHT_TO_VALIDATE") {
				filters.status = ["WEIGHT_TO_VALIDATE", "LOCKED"]
			}
			if (status === "TODO") {
				filters.status = ["TODO"]
			}
		}
		const packagingExecutions = await getFilteredPackagingExecutions({ include: ["packagingLine"], filters })
		dispatch({
			type: "PACKAGING_EXECUTION_FILTERS_SELECTED",
			selectedSite: site,
			selectedDate: date
		})
		dispatch({
			type: "PACKAGING_EXECUTIONS_LOADED",
			packagingExecutions
		})
	})
}

export const loadPackagingExecutionProposedWeight = async (packagingExecutionId) => {
	return await Parse.Cloud.run("loadPackagingExecutionProposedWeight", { packagingExecutionId })
}

export const loadPackagingExecution = (params) => {
	return actionWithLoader(async (dispatch, getState) => {
		const selectedDate = params.date
		let selectedSite = getPackagingExecutionSiteSelector(getState())
		if (!selectedSite) { // page refresh
			const siteId = params.siteId
			selectedSite = await getSiteById(siteId)
		}
		const packagingExecution = await getPackagingExecutionById({ id: params.packagingExecutionId, include: ["sections.section", "recipe", "packagingLine"] })

		let proposedWeights = null
		if (packagingExecution.status === PE_STATUSES.toValidate) {
			proposedWeights = await loadPackagingExecutionProposedWeight(packagingExecution.objectId)
		}

		dispatch({
			type: "PACKAGING_EXECUTION_FILTERS_SELECTED",
			selectedSite,
			selectedDate
		})
		dispatch({
			type: "PACKAGING_EXECUTION_LOADED",
			packagingExecution,
			packagingExecutionProposedWeights: proposedWeights
		})
	})
}


export const updatePackagingExecutionFilters = (siteId) => {
	return async () => {
		const site = await getSiteById(siteId)
		const localStorage = window.localStorage
		localStorage.setItem("packagingExecutionSelectedSite", JSON.stringify(site))
	}
}


export const updatePackagingExecution = (id, values) => {
	return actionWithLoader(async (dispatch) => {
		const packagingExecution = await new Parse.Query(PackagingExecution)
			.equalTo("objectId", id)
			.first()

		if (packagingExecution) {
			// --------- updating theoritical time --------- //
			if (PSE_REGEX_HOURS.test(values.theoreticalStartTime)) {
				const startTimeToTimeStamp = editableHourToTimestamp(values.theoreticalStartTime, packagingExecution.get("packagingDate"))
				packagingExecution.set("theoreticalStartTime", startTimeToTimeStamp)
			}

			if (PSE_REGEX_HOURS.test(values.theoreticalEndTime)) {
				const endTimeToTimeStamp = editableHourToTimestamp(values.theoreticalEndTime, packagingExecution.get("packagingDate"))
				packagingExecution.set("theoreticalEndTime", endTimeToTimeStamp)
			}

			// id to parse pointer
			if (values.packagingLine) {
				const packagingLine = await new Parse.Query(PackagingLine)
					.equalTo("objectId", values.packagingLine)
					.first()

				if (packagingLine) {
					packagingExecution.set("packagingLineName", packagingLine.get("name"))
					packagingExecution.set("packagingLine", packagingLine)
				}
			}

			const savedPackagingExecution = await packagingExecution.save()
			dispatch({
				type: "PACKAGING_EXECUTIONS_UDPATED",
				packagingExecution: savedPackagingExecution.toJSON()
			})
		}
	})
}

export const updatePackagingExecutions = (packagingExecutions) => {
	return actionWithLoader(async (dispatch) => {
		for (const packagingExecution of packagingExecutions) {
			await dispatch(updatePackagingExecution(packagingExecution.id, packagingExecution))
		}
	})
}

export const validatePackagingExecutionSectionWeights = (packagingExecutionId, changedSections = [], changedPackagings = []) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const packagingExecution = await getPackagingExecutionById({ id: packagingExecutionId, toJson: false })

			// update sections
			packagingExecution.get("sections").forEach((section) => {
				const matchingChangedSection = changedSections.find((changedSection) => changedSection.section.objectId === section.section.id)
				if (matchingChangedSection) {
					section.realWeight = roundNumber(matchingChangedSection.realWeight, 3) || 0
					section.forecastWaste = roundNumber(matchingChangedSection.forecastWaste, 1) || 0
				}
			})
			// update packagings
			packagingExecution.get("packagings").forEach((packaging) => {
				const matchingChangedPackaging = changedPackagings.find((changedPackaging) => changedPackaging.packaging.objectId === packaging.packaging.id)
				if (matchingChangedPackaging) {
					packaging.forecastNumber = matchingChangedPackaging.forecastNumber || 0
				}
			})
			// todo update/add comments
			// change status and save
			await savePackagingExecutionNewStatus(packagingExecution, PE_STATUSES.todo)

			dispatch(openAppMessageSnackBar("Les poids des sections ont bien été enregistrés"))
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}

export const validatePackagingExecutionPackagingRealNumber = (packagingExecutionId, packagingId, realNumber) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const packagingExecution = await getPackagingExecutionById({ id: packagingExecutionId, toJson: false })

			const packaging = packagingExecution.get("packagings").find((packaging) => packaging.packaging.id === packagingId)
			if (!packaging) {
				throw new Error("Impossible de sauvegarder le nombre de barquettes")
			}

			packaging.realNumber = realNumber
			packaging.endTime = dateToTimestamp(new Date())

			const packagingIndex = packagingExecution.get("packagings").indexOf(packaging)
			const nextPackaging = packagingExecution.get("packagings")[packagingIndex + 1]

			if (nextPackaging) {
				nextPackaging.startTime = dateToTimestamp(new Date())
			}

			if (!nextPackaging) { //packaging is over, we set to next status 'WASTE_TO_WEIGHT' and save
				await savePackagingExecutionNewStatus(packagingExecution, PE_STATUSES.waste)
			}
			else {
				await packagingExecution.save()
			}

			dispatch({
				type: "PACKAGING_EXECUTION_LOADED",
				packagingExecution: packagingExecution.toJSON()
			})

			dispatch(openAppMessageSnackBar("Le nombre de barquettes a bien été enregistré"))
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}

export const validatePackagingExecutionRealWaste = (packagingExecutionId, changedSections = []) => {
	return actionWithLoader(async (dispatch) => {
		try {
			if (!changedSections || !changedSections.length) {
				return
			}
			const packagingExecution = await getPackagingExecutionById({ id: packagingExecutionId, toJson: false })

			packagingExecution.get("sections").forEach((section) => {
				const matchingChangedSection = changedSections.find((changedSection) => changedSection.section.objectId === section.section.id)
				if (matchingChangedSection) {
					section.realWaste = matchingChangedSection.realWaste || 0
				}
			})

			packagingExecution.set("endTime", dateToTimestamp(new Date()))

			await savePackagingExecutionNewStatus(packagingExecution, PE_STATUSES.done)

			dispatch({
				type: "PACKAGING_EXECUTION_LOADED",
				packagingExecution: packagingExecution.toJSON()
			})
			dispatch(openAppMessageSnackBar("Le waste a bien été sauvegardé"))
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}


export const setPackagingExecutionToInProgress = (packagingExecutionId) => {
	return actionWithLoader(async (dispatch) => {
		try {
			const packagingExecution = await getPackagingExecutionById({ id: packagingExecutionId, toJson: false, include: ["sections.section", "recipe"] })

			const startTime = dateToTimestamp(new Date())
			packagingExecution.set("startTime", startTime)

			const firstPackaging = packagingExecution.get("packagings")[0]

			if (firstPackaging) {
				firstPackaging.startTime = dateToTimestamp(new Date())
			}

			await savePackagingExecutionNewStatus(packagingExecution, PE_STATUSES.inProgress)
			dispatch({
				type: "PACKAGING_EXECUTION_LOADED",
				packagingExecution: packagingExecution.toJSON()
			})
			dispatch(openAppMessageSnackBar("Le barquettage est en cours"))
		} catch (error) {
			dispatch(openAppMessageSnackBar(error.message, "error"))
		}
	})
}

// ---------- REDIRECTION ---------- //
export const showPackagingExecutionFilter = () => {
	return push(PACKAGING_EXECUTION_URLS.filter)
}

export const showPackagingExecutionRead = (siteId, packagingDate) => {
	return push(`${PACKAGING_EXECUTION_URLS.read}/${siteId}/${packagingDate}`)
}

export const showPackagingExecutionsHoursEdit = (siteId, dateId) => {
	return push(`${PACKAGING_EXECUTION_URLS.hoursEdit}/${siteId}/${dateId}`)
}

export const showPackagingExecution = (siteId, date, peId) => {
	return push(`${PACKAGING_EXECUTION_URLS.pe}/${siteId}/${date}/${peId}`)
}

export const onEnterPackagingExecutionFilter = (store) => {
	return onEnter({
		store,
		actionThunk: loadPackagingExecutionsFilters,
		getReplacingPath: getReplacingPath({ needUser: true })
	})
}

export const onEnterPackagingExecutionRead = (store) => {
	return onEnter({
		store,
		actionThunk: loadPackagingExecutionsRead,
		getReplacingPath: getReplacingPath({ needUser: true })
	})
}

export const onEnterPackagingExecutionHoursEdit = (store) => {
	return onEnter({
		store,
		actionThunk: loadPackagingExecutionsRead,
		getReplacingPath: getReplacingPath({ needUser: true })
	})
}

export const onEnterPackagingExecution = (store) => {
	return onEnter({
		store,
		actionThunk: loadPackagingExecution,
		getReplacingPath: getReplacingPath({ needUser: true })
	})
}