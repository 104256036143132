import React from "react"
import { makeStyles } from "@mui/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

const useStyles = makeStyles(() => ({
content: {
    maxWidth: 500,
    padding: "0px 24px"
},
button: {
    padding: "16px 24px"
}
}))

const CantDeleteSupplierItemModal = ({handleClose, isOpen = false, supplierItem}) => {

    const classes = useStyles()

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <DialogTitle >
                Impossible de supprimer cet article
            </DialogTitle>
            <DialogContent className={classes.content}>
                {supplierItem.type === "RAW_MATERIAL" && supplierItem.recipeQuantity === 1 &&
                    <p>
                        Cet article est présent dans {supplierItem.recipeQuantity} fiche recette, donc ne peut pas être supprimé.
                    </p>
                }
                {supplierItem.type === "RAW_MATERIAL" && supplierItem.recipeQuantity > 1 &&
                    <p>
                        Cet article est présent dans {supplierItem.recipeQuantity} fiches recettes, donc ne peut pas être supprimé.
                    </p>
                }
                {supplierItem.type === "SALABLE_PRODUCT" && supplierItem.subcontractorProductQuantity === 1 &&
                    <p>
                        Cet article est relié à {supplierItem.subcontractorProductQuantity} fiche produit sous-traitant, donc ne peut pas être supprimé.
                    </p>
                }
                {supplierItem.type === "SALABLE_PRODUCT" && supplierItem.subcontractorProductQuantity > 1 &&
                    <p>
                        Cet article est relié à {supplierItem.subcontractorProductQuantity} fiches produits sous-traitants, donc ne peut pas être supprimé.
                    </p>
                }

            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleClose}
                    className={classes.button}
                >
                    ANNULER
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default CantDeleteSupplierItemModal