import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import { Modal } from "@mui/material"
import { checkTablet, COLORS } from "../../../utils"
import moment from "moment"
import {
  closeDispatchSnackBar,
  updateProductDispatchHubVolume,
  closeHubDispatchDetail,
  showHubDispatchDetail,
  removeHubLockerAction,
} from "../../../actions/Dispatch/dispatch"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import _cloneDeep from "lodash/cloneDeep"
import { confirm } from "../../../components/react-await-dialog/react-await-dialog"
import DispatchHeader from "../../../components/Dispatch/DispatchHeader"
import InputBlock from "../../../components/ReceptionWarehouse/InputBlock"
import ConfirmIcon from "@mui/icons-material/Done"
import clsx from "clsx"
import { packagingNature } from "../../../utils/packaging"

const useClasses = makeStyles(() => ({
  dispatchModalDetail: {
    backgroundColor: COLORS.WHITE,
    opacity: 1,
    outline: "none",
    overflow: "scroll",
    zIndex: 1300,
    height: "100%",
  },
  noOutline: {
    outline: "none",
  },
  divider: {
    border: "1px solid #e8e8e8",
    width: "100%",
  },
  contentBloc: {
    textAlign: "center",
    marginTop: 65,
    color: COLORS.BLACK,
    fontSize: 27,
    lineHeight: "32px",
  },
  confirmButtonBasic: {
    composes: "flexRow",
    height: 72,
    width: 410,
    fontSize: 20,
    textAlign: "center",
    alignItems: "center",
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 5,
    "&.is-tablet": {
      width: "100%",
      bottom: 0,
      position: "absolute",
    },
  },
  confirmButtonEmpty: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.LIGHT_GREY,
    color: COLORS.DARK_GREY,
  },
  confirmButtonFill: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  buttonText: {
    margin: "0 auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  uniqueCode: {
    fontSize: 34,
  },
  doneIcon: {
    color: COLORS.PRIMARY_COLOR,
    fontSize: 70,
  },
  moreInfosItem: {
    color: COLORS.GREY_BORDER,
    fontSize: 27,
    fontStyle: "normal",
    fontWeight: "normal",
    marginTop: 20,
  },
  endButtonContainer: {
    composes: "$buttonContainer",
    marginTop: 30,
  },
}))

const HubDispatchDetail = (props) => {
  const {
    date,
    selectedProductDispatch,
    dispatchSnackBar,
    hub,
    computedQuantity,
    closeDispatchSnackBar,
    updateProductDispatchHubVolume,
    closeHubDispatchDetail,
    showHubDispatchDetail,
    removeHubLockerAction,
    site
  } = props
  const classes = useClasses()
  const inputRef = useRef(null)

  const quantity = computedQuantity && computedQuantity[hub.objectId]
  const index =
    selectedProductDispatch &&
    quantity.elements.findIndex(
      (elm) => elm === selectedProductDispatch.objectId
    )
  const currentHub =
    selectedProductDispatch &&
    selectedProductDispatch.hubs.find((elm) => elm.idHub === hub.objectId)
  const wantedInput = currentHub && currentHub.volume
  const [inputValue, setInputValue] = useState(wantedInput === 0 ? "0" : "")
  const [iteration, setIteration] = useState(index)
  const [isDone, setIsDone] = useState(false)
  const [isTablet, setIsTablet] = useState(checkTablet)
  const [dispatched, setDispatched] = useState(quantity.dispatched)
  const [remained, setRemained] = useState(quantity.remained)

  const packaging =
    (selectedProductDispatch &&
      selectedProductDispatch.productionItem &&
      selectedProductDispatch.productionItem.packaging &&
      packagingNature.find(
        (el) => el.value === selectedProductDispatch.productionItem.packaging
      ).label) ||
    false

  // set isTablet state
  useLayoutEffect(() => {
    const checkisTablet = () => {
      setIsTablet(checkTablet())
    }

    // title
    document.title = "Dispatch - KFC"

    // check size
    window.addEventListener("resize", checkisTablet)

    // remove check
    return () => window.removeEventListener("resize", checkisTablet)
  }, [])

  useEffect(() => {
    if (index === -1)
      closeHubDispatchDetail(selectedProductDispatch, hub.objectId)
  }, [])

  useEffect(() => {
    if (iteration === quantity.elements.length) {
      setIteration(null)
      setIsDone(true)
    }

    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }

    setInputValue(wantedInput === 0 ? "0" : "")
  }, [index, iteration])

  const _onClose = () => {
    closeHubDispatchDetail(selectedProductDispatch, hub.objectId)
  }

  const _onInputValueChange = (event) => {
    setInputValue(event.target.value)
  }

  const _confirmStep = async () => {
    if (
      inputValue !== "" &&
      !inputValue.includes(",") &&
      !inputValue.includes(".")
    ) {
      if (
        wantedInput === parseFloat(inputValue) ||
        (await confirm({
          title: "Confirm",
          message:
            "La valeur indiquée est différente de celle attendue. Êtes vous sûr?",
          confirmText: "Oui",
        }))
      ) {
        const copy = _cloneDeep(selectedProductDispatch)

        const nextIndex = index + 1 >= quantity.elements.length ? 0 : index + 1
        setIteration(index + 1)

        await updateProductDispatchHubVolume(
          copy,
          currentHub,
          parseFloat(inputValue),
          true
        )
        setDispatched(dispatched + parseFloat(inputValue))
        setRemained(remained - parseFloat(inputValue))

        removeHubLockerAction(copy, currentHub.idHub)
        showHubDispatchDetail(
          moment(date).startOf("day").format("YYYY-MM-DD"),
          currentHub.idHub,
          quantity.elements[nextIndex],
          site.objectId,
        )
      }
    }
  }

  const _onKeyDown = (event) => {
    if (event && event.keyCode === 13) {
      _confirmStep()
      event.preventDefault()
    }
  }

  return (
    <Modal
      key="dispatch-detail-modal"
      BackdropProps={{ invisible: true }}
      className={classes.dispatchModalDetail}
      open={true}
    >
      <div className={classes.noOutline}>
        {selectedProductDispatch && (
          <div>
            <DispatchHeader
              onClose={_onClose}
              selectedProductDispatch={selectedProductDispatch}
              date={date}
              isDone={isDone}
              currentHub={currentHub}
              hub={hub}
              quantity={quantity}
              dispatched={dispatched}
              remained={remained < 0 ? 0 : remained}
              iteration={iteration > quantity.ref ? 0 : iteration}
            />
            <div className={classes.divider} />
            <div className={classes.contentBloc}>
              {!isDone && (
                <>
                  <span>
                    Quantité de {selectedProductDispatch.uniqueCode}
                    {packaging && (
                      <span> en packaging {packaging.toUpperCase()} </span>
                    )}
                  </span>
                  <div className={classes.moreInfosItem}>
                    {selectedProductDispatch.commercialTitle}
                  </div>
                  <InputBlock
                    inputRef={inputRef}
                    onKeyDown={_onKeyDown}
                    onInputValueChange={_onInputValueChange}
                    inputValue={inputValue}
                    wantedInput={wantedInput}
                  />
                  <div className={classes.buttonContainer}>
                    <div
                      className={
                        inputValue !== null &&
                        inputValue !== "" &&
                        !dispatchSnackBar.open
                          ? classes.confirmButtonFill
                          : classes.confirmButtonEmpty
                      }
                      onClick={() => {
                        if (
                          !dispatchSnackBar.open &&
                          ![""].includes(inputValue)
                        )
                          _confirmStep()
                      }}
                    >
                      <span className={classes.buttonText}>CONFIRMER</span>
                    </div>
                  </div>
                </>
              )}
              {isDone && (
                <>
                  <div>
                    <ConfirmIcon className={classes.doneIcon} />
                  </div>
                  <div>Dispatch des produits terminé</div>
                  <div className={classes.endButtonContainer}>
                    <div
                      className={clsx(classes.confirmButtonFill, {
                        "is-tablet": isTablet,
                      })}
                      onClick={_onClose}
                    >
                      <span className={classes.buttonText}>
                        RETOURNER À LA LISTE
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={dispatchSnackBar.open}
          autoHideDuration={dispatchSnackBar.duration}
          onClose={() => closeDispatchSnackBar(dispatchSnackBar.type)}
        >
          <MySnackbarContentWrapper
            onClose={() => closeDispatchSnackBar(dispatchSnackBar.type)}
            variant={dispatchSnackBar.type}
            message={dispatchSnackBar.message}
          />
        </Snackbar>
      </div>
    </Modal>
  )
}

export default connect(
  (state) => {
    return {
      date: state.dispatch.date,
      selectedProductDispatch: state.dispatch.selectedProductDispatch,
      hub: state.dispatch.hub,
      dispatchSnackBar: state.dispatch.dispatchSnackBar,
      computedQuantity: state.dispatch.computedQuantity,
      site: state.dispatch.site,
    }
  },
  {
    closeDispatchSnackBar,
    updateProductDispatchHubVolume,
    closeHubDispatchDetail,
    showHubDispatchDetail,
    removeHubLockerAction,
  }
)(HubDispatchDetail)
