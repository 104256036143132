
const defaultState = {
    transformationMode: null,
    transformationModes: [],
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // load data list
        case "TRANSFORMATION_MODES_LOADED":
            return {
                ...state,
                transformationModes: action.transformationModes,
            }
        case "TRANSFORMATION_MODE_LOADED":
            return {
                ...state,
                transformationMode: action.transformationMode,
            }

        // clear states
        case "CLEAR_TRANSFORMATION_MODES": {
            return {
                ...state,
                transformationModes: []
            }
        }
        case "CLEAR_TRANSFORMATION_MODE": {
            return {
                ...state,
                transformationMode: null
            }
        }
        default:
            return {
                ...state
            }
    }
}

// ---------------------------------------- //
// --------------- SELECTOR --------------- //
// ---------------------------------------- //
export function getTransformationModesSelector(state) {
    return state.transformationModes.transformationModes
}

export function getTransformationModeSelector(state) {
    return state.transformationModes.transformationMode
}
