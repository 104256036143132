import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: COLORS.PRODUCTION_RECEPTION_COLOR,
        fontSize: 16
    },
    outputTitle: {
        color: COLORS.YELLOW_OUTPUT
    },
    inventoryIcon: {
        marginRight: 10,
        color: COLORS.PRODUCTION_RECEPTION_COLOR,
        height: 24
    },
    outputIcon: {
        marginRight: 10,
        color: COLORS.YELLOW_OUTPUT,
        height: 24
    },
})
const OrderReceptionHeaderTitle = () => {
    const classes = useStyles()
    return (
        <>
            <div className={classes.container}>
                <Icon className={classes.inventoryIcon}>
                    <MeetingRoomIcon />
                </Icon>
                <Typography variant="h6" color="textPrimary" className={classes.title}>
                    Réceptions des commandes
                </Typography>
            </div>
        </>
    )
}

export default OrderReceptionHeaderTitle