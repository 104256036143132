import React from "react"
import { StyledContainer } from "./StyledExecutionPreviewComponents"
import { Stack, Typography } from "@mui/material"
import { sx } from "./PSECoolingRecap"
import dayjs from "dayjs"

const PSECookingRecap = ({
	machinesBatch = [],
	visible = true
}) => {

	if (!visible) {
		return null
	}

	const reformatEndTime = (endTimeTimestamp) => {
		return dayjs(endTimeTimestamp).format("HH:mm")
	}	

	return (
		<StyledContainer>
			<Stack gap={3}>
				<Stack gap={1} direction="row">
					<img src="/img/recipes/cookingPan.svg" alt="cookingPan" />
					<Typography sx={{
						color: "#262626",
						fontSize: 14,
						fontWeight: 500,
						lineHeight: "22px"
					}}>
						Cuisson
					</Typography>
				</Stack>
				<Stack gap={3} direction="row">
					<Stack gap={2}>
						<Typography sx={sx.label}>Machine</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{machineBatch.machineName}</Typography>)}
					</Stack>
					<Stack gap={2}>
						<Typography sx={sx.label}>Heure de fin</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{reformatEndTime(machineBatch.cookingEndTime)}</Typography>)}
					</Stack>
					<Stack gap={2}>
						<Typography sx={sx.label}>Température de fin</Typography>
						{machinesBatch.map((machineBatch, index) => <Typography key={index} sx={sx.value}>{machineBatch.endTemperature + " °C"}</Typography>)}
					</Stack>
				</Stack>
			</Stack>
		</StyledContainer>
	)

}

export default PSECookingRecap