import React, { useEffect, useState } from "react"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import clsx from "clsx"
import { COLORS } from "../../utils"
import ListCard from "./ListCard"

import { getProductTypes } from "../../parseManager/products/productTypeManager"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    rootList: {
      width: "100%",
      padding: 0,
      marginBottom: 17,
      "&.last": {
        marginBottom: 121,
      },
    },
    header: {
      backgroundColor: COLORS.PRIMARY_COLOR,
      boxShadow: `0px 1px 1px ${COLORS.DEFAULT_GREY}`,
      height: 39,
      paddingLeft: 20,
      "&.MuiButtonBase-root.MuiExpansionPanelSummary-root": {
        minHeight: "auto",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      color: COLORS.WHITE,
      fontStyle: "normal",
      textTransform: "uppercase",
    },
    details: {
      backgroundColor: COLORS.LIGHT_GREY,
      padding: 0,
    },
  })
)

const GroupItems = (props) => {
  const {
    name,
    list,
    last,
    onCardClick,
    selectedCard,
    isTablet,
    isInventory,
    canEdit,
  } = props
  const classes = useStyles()
  const listKeys = Object.keys(list).sort()

  const [labelProduct, setLabelProduct] = useState("")

  useEffect(() => {
    const fetchProductTypes = async () => {
      const fetchedProductTypes = await getProductTypes()
      if (fetchedProductTypes) {
        const foundProductType = fetchedProductTypes.find(option => option.kfcName === name)
        if (foundProductType) {
          setLabelProduct(foundProductType.label)
        }
      }
    }
    fetchProductTypes()
  }, [])


  return (
    <div className={classes.root}>
      <Accordion elevation={0} expanded={true} square>
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.header}
        >
          <Typography className={classes.heading}>{labelProduct}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <List
            className={clsx(classes.rootList, {
              last: last && !isTablet,
            })}
          >
            {listKeys &&
              listKeys.map((item, i) => (
                <ListCard
                  key={name + item}
                  title={item}
                  listItem={list[item]}
                  last={i === listKeys.length - 1}
                  onCardClick={onCardClick}
                  selectedCard={selectedCard}
                  isInventory={isInventory}
                  canEdit={canEdit}
                />
              ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default GroupItems
