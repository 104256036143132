import React, { PureComponent, createRef, cloneElement } from "react"
import { styled } from "@mui/material/styles"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import { Alert, Snackbar } from "@mui/material"

import { COLORS } from "../utils"
import Sidebar from "./Sidebar"
import { closeAppMessageSnackBar, closeError, closeMessage, updateMenu } from "../actions/Utils/app"

const PREFIX = "Root"

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIconOpen: `${PREFIX}-toolbarIconOpen`,
  toolbarIconClose: `${PREFIX}-toolbarIconClose`,
  appTitle: `${PREFIX}-appTitle`,
  white: `${PREFIX}-white`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  title: `${PREFIX}-title`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  content: `${PREFIX}-content`,
  h5: `${PREFIX}-h5`,
  mainContentPaper: `${PREFIX}-mainContentPaper`,
  overlay: `${PREFIX}-overlay`,
  circularProgressColor: `${PREFIX}-circularProgressColor`,
  container: `${PREFIX}-container`,
}

const drawerWidth = 260

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "& ::-webkit-scrollbar": {
    display: "none"
  },
  top: 65
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  "& ::-webkit-scrollbar": {
    display: "none"
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const StyledRoot = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex"
  },

  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  [`& .${classes.toolbarIconOpen}`]: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    justifyContent: "space-between",
    backgroundColor: global.LOCAL_PLUGGED_TO_PROD ? COLORS.RED_ERROR : COLORS.PRIMARY_COLOR,
    ...theme.mixins.toolbar,
    position: "fixed",
    height: 50,
    width: 260,
    top: 0,
    zIndex: 1000,
    cursor: "pointer"
  },

  [`& .${classes.toolbarIconClose}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    ...theme.mixins.toolbar,
    backgroundColor: global.LOCAL_PLUGGED_TO_PROD ? COLORS.RED_ERROR : COLORS.PRIMARY_COLOR,
    position: "sticky",
    height: 50,
    top: 0,
    zIndex: 1000,
    cursor: "pointer"
  },

  [`& .${classes.appTitle}`]: {
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
    fontWeight: "bold",
    marginLeft: 10,
  },

  [`& .${classes.white}`]: {
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginLeft: 12,
    marginRight: 36,
  },

  [`& .${classes.menuButtonHidden}`]: {
    display: "none",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.content}`]: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  [`& .${classes.h5}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.mainContentPaper}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative", // used by the absolute round plus button
  },

  [`&.${classes.overlay}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    // width: window.innerWidth - 1, height: window.innerHeight - 1, // - 1 to avoid rounding issues
    backgroundColor: "transparent",
    zIndex: 10001, // Login's zIndex is 1000 (and the header is even more)
    opacity: 0.6, // for the CircularProgress color
    padding: 12, // just to center the CircularProgress
  },

  [`& .${classes.circularProgressColor}`]: {
    color: "black",
  }
}))

const styles = {
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "transparent",
  zIndex: 10001, // Login's zIndex is 1000 (and the header is even more)
  opacity: 0.6, // for the CircularProgress color
  padding: "12px", // just to center the CircularProgress
}

class Root extends PureComponent {
  rootRef = createRef()

  static propTypes = {
    classes: PropTypes.object,
  }

  handleDrawer = () => {
    const { updateMenu, open } = this.props
    updateMenu(!open)
  }

  render() {
    const { user, loading, error, title, closeError, open, displaySidebar, appSnackBar, closeAppMessageSnackBar } = this.props

    let overlay
    if (loading) {
      //-- loading --//
      overlay = (
        <Box
          // className={clsx(classes.overlay, "flexColumn flexEnd")}
          className="flexColumn flexEnd"
          sx={{
            width: window.innerWidth - 1,
            height: window.innerHeight - 1,
            ...styles,
          }}
        >
          <CircularProgress sx={{ color: COLORS.DARK_BLACK }} />
        </Box>
      )
    } else if (error) {
      //-- error --/
      console.log("Error :", error)
      overlay = (
        <Dialog open>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>{JSON.stringify(error)}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeError}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    return (
      <StyledRoot className={classes.root} ref={this.rootRef}>
        <CssBaseline />

        {/*AppBar*/}

        {/*Drawer*/}
        {displaySidebar &&
          <Drawer variant="permanent"  open={open} id="leftMenu">
            <div
              className={
                open ? classes.toolbarIconOpen : classes.toolbarIconClose
              }
              onClick={this.handleDrawer}
            >
              {open && <span className={classes.appTitle}>{title}</span>}
              <IconButton size="large">
                {open && <ChevronLeftIcon className={classes.white} />}
                {!open && <MenuIcon className={classes.white} />}
              </IconButton>
            </div>

            <Sidebar isVisible={!!user} open={open} />
          </Drawer>
        }

        {/*Main Content*/}
        <main className={classes.content}>
          <Paper className={classes.mainContentPaper} elevation={0}>
            {this.props.children &&
              cloneElement(this.props.children, { rootRef: this.rootRef })}
          </Paper>

          <Snackbar open={appSnackBar.open} autoHideDuration={appSnackBar.duration} onClose={closeAppMessageSnackBar}>
            <Alert onClose={closeAppMessageSnackBar} severity={appSnackBar.type} sx={{ width: "100%" }}>
              {appSnackBar.message}
            </Alert>
          </Snackbar>
        </main>

        {/*OVERLAY*/}
        {overlay}
      </StyledRoot>
    )
  }
}

export default connect(
  (state) => {
    return {
      user: state.app.user,
      title: state.app.title,
      loading: state.app.loading,
      error: state.app.error,
      message: state.app.message,
      open: state.app.menuIsOpen,
      appSnackBar: state.app.appSnackBar,
      displaySidebar: state.app.displaySidebar,
    }
  },
  {
    closeError,
    closeMessage,
    updateMenu,
    closeAppMessageSnackBar,
  }
)(Root)
