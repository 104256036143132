import React, { useMemo, useRef, useState } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"
import Select from "react-select"
import Autocomplete from "@mui/material/Autocomplete"
import { cloneDeep } from "lodash"
import moment from "moment"
import { filterSupplierItemsBySupplier } from "../../utils/supplierItemUtils"
import { COLORS } from "../../utils"
import { filterLotsBySupplierItem } from "../../utils/lotsUtils"

const initialValues = {
  supplierItem: "",
}

const formatSupplierItemAutocompleteOptions = (supplierItems) =>
  supplierItems.map((supplierItem) => ({
    label: supplierItem.name + " - " + supplierItem.objectId,
    value: supplierItem.objectId,
  }))

const formatLotAutocompleteOptions = (lots) =>
  lots.map((lot) => ({
    label: lot.lotNumber + " (" + moment.utc(lot.dlc).startOf("day").format("DD/MM/YYYY") + ")",
    value: lot.objectId,
    data: lot
  }))

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 641,
    height: 422,
  },
  formControl: {
    marginLeft: "unset",
    width: "100%",
    marginTop: 20,
    "& *": {
      fontSize: 16,
      color: COLORS.BLACK,
    },
    "& .MuiFormLabel-root": {
      position: "relative",
      height: 20,
      display: "flex",
      alignItems: "center",
      transform: "none",
      marginTop: 4,
    },
  },
  selectFormControl: {
    composes: "$formControl",
    marginTop: 16,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogDescription: {
    color: COLORS.DARK_BLACK,
  },
  plusIconContainer: {
    composes: "flexCenter",
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    borderRadius: 3,
  },
  plusIcon: {
    color: COLORS.WHITE,
  },
  addButton: {
    textTransform: "capitalize",
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 500,
    marginTop: 18,
    padding: 0,
  },
  chips: {
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `1px solid ${COLORS.GREY_SEARCH}`,
    borderRadius: "none",
    overflowY: "scroll",
  }),
}

const LoadingIndicator = () => <CircularProgress color="inherit" size={20} />

export const DisplayLotsToInventoryModal = (props) => {
  const { onClose, open, suppliers, supplierItems, lots, loading, onConfirm } = props

  const formikRef = useRef(null)
  const [selectedSuppliers, setSelectedSuppliers] = useState([])
  const [selectedSupplierItem, setSelectedSupplierItem] = useState(null)

  const classes = useStyles()

  const filteredSupplierItems = useMemo(() => {
    let products = cloneDeep(supplierItems)

    if (products.length === 0) {
      return products
    }

    // filter by suppliers
    if (selectedSuppliers.length > 0) {
      products = filterSupplierItemsBySupplier(
        products,
        selectedSuppliers
      )
    }

    return products
  }, [supplierItems, selectedSuppliers])

  const filteredLots = useMemo(() => {
    let newLots = cloneDeep(lots)

    if (newLots.length === 0) {
      return newLots
    }

    // filter by supplier Items (by suppliers)
    if (filteredSupplierItems.length > 0) {
      const supplierItemsIds = filteredSupplierItems.map((supplierItem) => supplierItem.objectId)
      newLots = filterLotsBySupplierItem(
        newLots,
        supplierItemsIds
      )
    }

    if (selectedSupplierItem) {
      const selectedSupplierItems = [selectedSupplierItem.value]
      newLots = filterLotsBySupplierItem(
        newLots,
        selectedSupplierItems
      )
    }

    return newLots
  }, [lots, filteredSupplierItems, selectedSupplierItem])


  const handleConfirm = () => {
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    // supplierItem and lot is required to confirm the dialog
    if (!(values.supplierItem && values.lot)) return
    onConfirm(values.lot)
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const handleSuppliersSelect = (options) => {
    const supplierIds = options.map((o) => o.value)
    setSelectedSuppliers(supplierIds)
  }

  const handleSupplierItemSelect = (value) => {
    setSelectedSupplierItem(value)
  }

  const supplierOptions = suppliers
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((supplier) => ({
      value: supplier.objectId,
      label: supplier.name,
    }))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Afficher de nouveau un lot dans l’inventaire
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogDescription}>
          Sélectionnez un lot qui n’est plus présent sur l’interface inventaire  et que vous souhaitez afficher de nouveau.
        </DialogContentText>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => {
            return (
              <Form>
                <FormControl
                  className={clsx(
                    classes.formControl,
                    classes.multiSelectFormControl
                  )}
                  variant="standard"
                >
                  <InputLabel>Fournisseurs</InputLabel>
                  <Select
                    name="suppliers"
                    options={supplierOptions}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={selectStyles}
                    value={values.suppliers}
                    isLoading={loading}
                    onChange={(option) => {
                      setFieldValue("suppliers", option)
                      handleSuppliersSelect(option)
                    }}
                    components={{ LoadingIndicator }}
                  />
                </FormControl>
                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <Autocomplete
                    id="supplierItem"
                    name="supplierItem"
                    options={formatSupplierItemAutocompleteOptions(filteredSupplierItems)}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => {
                      setFieldValue("supplierItem", value)
                      handleSupplierItemSelect(value)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Nom de l'article"
                        value={values.supplierItem ? values.supplierItem.value : ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  className={classes.selectFormControl}
                  variant="standard"
                >
                  <Autocomplete
                    id="lot"
                    name="lot"
                    options={formatLotAutocompleteOptions(filteredLots)}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, value) => setFieldValue("lot", value)}
                    isOptionEqualToValue={(option, value) =>
                      option.objectId === value.objectId
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Numéro de lot (DLC)"
                        value={values.lot ? values.lot.value : ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Afficher
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisplayLotsToInventoryModal
