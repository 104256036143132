import React from "react"
import ProductionTab from "../ProductionTabs"
import MachineTypes from "./Type/MachineTypes"

export const MachinesTab = () => {
    return (
        <ProductionTab tabValue="machines">
            <MachineTypes />
        </ProductionTab>
    )
}

export default MachinesTab