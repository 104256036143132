import React from "react"
import { Box, Stack } from "@mui/material"

import { COLORS } from "../../../utils"
import PackagingLifeCycleResume from "./lifeCycle/PackagingLifeCycleResume"
import PackagingLifeCycleMaterials from "./lifeCycle/PackagingLifeCycleMaterials"

const sx = {
    root: {
        padding: "40px 0",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        height: "100%" 
    },
    firstColumnContainer: {
        width: 1000,
    },
}

const PackagingLifeCycleTab = ({ packaging, onSave, onAddMaterial, onEditMaterial, onDeleteMaterial }) => {
    return (
        <Box sx={sx.root} className="flexRow flex1 stretchSelf justifyCenter">
            <Stack sx={sx.firstColumnContainer} spacing={2.6}>
                <PackagingLifeCycleResume packaging={packaging} onSubmit={onSave} />
                <PackagingLifeCycleMaterials
                    onAddMaterial={onAddMaterial}
                    onEditMaterial={onEditMaterial}
                    materials={packaging.materials}
                    onDeleteMaterial={onDeleteMaterial}
                />
            </Stack>
        </Box>
    )
}

export default PackagingLifeCycleTab