import { cloneDeep } from "lodash"

export const getDataGridFiltersStored = (supplierItems = [], defaultAdditionalFilters, from) => {
    const filtersFromStorage = JSON.parse(localStorage.getItem("dataGridFilters"))
    const defaultFilters = cloneDeep(filtersFromStorage)
    if (defaultFilters && defaultFilters.supplierItems && supplierItems.length > 0) {
        const supplierItemStored = supplierItems.find(item => (item.name + " - " + item.objectId) === defaultFilters.supplierItems)

        if (supplierItemStored) {
            defaultFilters.supplierItems = supplierItemStored.name + " - " + supplierItemStored.objectId
            defaultAdditionalFilters.supplierItems = defaultFilters.supplierItems
        }
    }

    if (defaultFilters && from) {
        const filterToUse = from === "billing" ? "receptionDateBilling" : from === "creditNotes" ? "receptionDateCreditNotes" : "receptionDateOrderReception"
        if (defaultFilters[filterToUse]) {
            defaultAdditionalFilters.receptionDate = defaultFilters[filterToUse]
            defaultFilters.receptionDate = defaultFilters[filterToUse]
        }
        else {
            defaultFilters.receptionDate = defaultAdditionalFilters.receptionDate
        }
    }
    if (defaultFilters) {
        if (defaultFilters.receptionDateBilling) {
            delete defaultFilters.receptionDateBilling
        }
        if (defaultFilters.receptionDateCreditNotes) {
            delete defaultFilters.receptionDateCreditNotes
        }
        if (defaultFilters.receptionDateOrderReception) {
            delete defaultFilters.receptionDateOrderReception
        }
    }

    return defaultFilters
}

export const formatFiltersBeforeStorage = (filters, from) => {
    const storedFilters = cloneDeep(filters)
    const filterReceptionDate = storedFilters.receptionDate
    if (filterReceptionDate.isRange) {
        if (from === "billing") {
            storedFilters.receptionDateBilling = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
        if (from === "creditNotes") {
            storedFilters.receptionDateCreditNotes = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
        if (from === "orderReception") {
            storedFilters.receptionDateOrderReception = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
    }
    delete storedFilters.receptionDate
    return storedFilters
}

/**
 * get the uploaded file
 * parse it to extract the data (lines) without the headers
 * @param {*} file 
 * @param {*} callback to call after the file is parsed
 */
export const parseCSVFile = (file, callback) => {
    const reader = new FileReader()
    reader.onload = (e) => {
        const csv = e.target.result
        const allTextLines = csv.split(/\r\n|\n/)
        const headers = allTextLines[0].split(",")
        const filteredHeaders = headers.filter(header => header.trim() !== "")
        const lines = []
        for (const line of allTextLines.slice(1)) {
            const lineArr = line.split(",")

            if (lineArr.length === filteredHeaders.length) {
                const columns = []
                for (const [index,] of filteredHeaders.entries()) {
                    columns.push(lineArr[index])
                }
                lines.push(columns)
            }
        }
        callback(lines)
    }
    reader.readAsText(file)
}