import React, { useEffect, useState }  from "react"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import { DatePicker } from "@mui/x-date-pickers"
import { InputLabel } from "@mui/material"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import moment from "moment"
import TextField from "@mui/material/TextField"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import {
	colors,
	labelTemplates,
	getLotNumberByPackagingDate
} from "../utils/productLabels"
import { KFC_BRANDS } from "../utils"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
	modalTitle: {
		textAlign: "center",
		margin: "auto"
	},
	modalSubTitle: {
		textAlign: "center"
	},
	closeIcon: {
		float: "right"
	},
	labelModal: {
		tabIndex: 1,
		position: "absolute",
		top: "5%",
		left: "25%",
		width: "50%",
		height: 550,
		outline: "none",
		overflow: "scroll",
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
	},
	formContainer: {
		display: "flex",
		textAlign: "center",
		justifyContent: "center"
	},
	formControl: {
		paddingTop: 15,
		paddingBottom: 15,
		marginRight: 15,
		display: "flex",
		margin: "auto 0",
		width: 200
	},
	formControl300: {
		paddingTop: 15,
		paddingBottom: 15,
		marginRight: 15,
		display: "flex",
		margin: "auto 0",
		width: 300
	},
	dlc: {
		position: "relative",
		transform: "translateX(45px)",
		paddingLeft: 35
	},
	dlcMessage: {
		position: "absolute",
		bottom: -42,
		color: "red",
		fontSize: 14,
	},
	dlcError: {
		color: "red",
		position: "relative",
		fontSize: 14,
	},
	datePicker: {
		marginLeft: 25,
		width: 200
	},
	actionButton: {
		paddingTop: 15,
		paddingBottom: 15
	},
	menuItem: {
		display: "flex",
		width: "100%",
		height: "100%"
	}
}))

const getDlcByBrand = (brand, dlc) => {
	const dlcBrand = dlc && Array.isArray(dlc) ? dlc.find(d => brand === d.brand) : null

	return dlcBrand
		? moment.utc(dlcBrand.value).startOf("day").valueOf()
		: null
}

const getDefaultColor = (brandColors, internalTags) => {
	if (!Array.isArray(internalTags)) {
		return brandColors.length > 0 ? brandColors[0].value : null
	}
	const color = brandColors.find(brandColor => {
		return internalTags
			.map(i => i.name)
			.find(internalTag => Array.isArray(brandColor.internalTags) && brandColor.internalTags.includes(internalTag))
	})
	return color
		? color.value
		: brandColors.length > 0
			? brandColors[0].value
			: null
}

const getColors = (template) => {
	return template
		? colors.filter(color => {
				return color.templates.find(elemTemplate => elemTemplate === template)
			})
		: colors
}

const brand = KFC_BRANDS[0].name
const brandTemplates = labelTemplates.filter(elem => elem.brand === brand)
const template = brandTemplates[0].value
const brandColors = getColors(template)

const LabelProductModal = React.forwardRef((props, ref) => {
	const {
		packagingDate,
		internalTags,
		closeModal,
		dlc,
		productId,
		type,
		onSubmitPrint,
		name,
		fromPackagingPlanning
	} = props

	const classes = useStyles()
	const [data, setData] = useState({
		stateDlc: getDlcByBrand(brand, dlc),
		labelTemplates: brandTemplates,
		colors: brandColors,
		color: getDefaultColor(brandColors, internalTags),
		brands: KFC_BRANDS.map(b => ({ value: b.name, label: b.label })),
		template,
		brand,
		lotNumber: packagingDate ? getLotNumberByPackagingDate(packagingDate) : "",
		disabledLotNumber: packagingDate ? true : false,
		hideDlc: false
	})

	const [hasDlcError, setHasDlcError] = useState(null)

	useEffect(() => {
		const storage = JSON.parse(localStorage.getItem("LabelProductModal"))

		if (storage) {
			let state = storage

			if (storage.template) {
				const brandColors = getColors(storage.template)
				const color = getDefaultColor(brandColors, internalTags)

				state = { ...state, colors: brandColors, color }
			}

			if (storage.brand) {
				state = { ...state, stateDlc: getDlcByBrand(storage.brand, dlc) }
			}

			setData((prev) => ({ ...prev, ...state }))
			// setState(state)
		}
	}, [dlc])

	const setStorage= (objToStore) => {
		let storage = JSON.parse(localStorage.getItem("LabelProductModal"))
		if (!storage) storage = {}

		Object.keys(objToStore)
			.forEach(key => storage[key] = objToStore[key])
		localStorage.setItem("LabelProductModal", JSON.stringify(storage))
	}

	const renderBase = (arr) => {
		return arr.map(elem => (
			<MenuItem key={elem.value} value={elem.value}>
				<div className={classes.menuItem}>
					{elem.label}
				</div>
			</MenuItem>
		))
	}

	const	renderTemplatesSelect = () => {
		const { labelTemplates } = data

		if (labelTemplates) {
			return renderBase(labelTemplates)
		}
	}

	const renderBrandsSelect = () => {
		const { brands } = data

		if (brands) {
			return renderBase(brands)
		}
	}

	const renderColorsSelect = () => {
		if (!data.colors) return
		return (
			data.colors.map(elem => {
				return (
					<MenuItem key={elem.value} value={elem.value}>
						<div className={classes.menuItem}>
							{elem.label}
							<div style={{ backgroundColor: elem.value, width: 16, height: 16, marginLeft: "auto" }}>
							</div>
						</div>
					</MenuItem>
				)
			})
		)
	}

	const onChangeDlc = (value) => {
		const stateDlc = moment.utc(value).startOf("day").valueOf()
		setData((prev) => ({ ...prev, stateDlc }))
		setHasDlcError(false)
	}

	const toggleHideDlc = () => {
		setData((prev) => ({ ...prev, hideDlc: !data.hideDlc }))

		// storage management
		setStorage({hideDlc: !data.hideDlc})
	}

	const onBrandChange = (event) => {
		const { brand } = data
		const value = event.target.value

		if (brand !== value) {
			const brandLabelTemplates = labelTemplates.filter(elem => elem.brand === value)
			const newTemplate = brandLabelTemplates.length > 0 ? brandLabelTemplates[0].value : null
			const brandColors = getColors(newTemplate)
			const newColor = getDefaultColor(brandColors)

			setData((prev) => ({
				...prev,
				brand: value,
				colors: brandColors,
				labelTemplates: brandLabelTemplates,
				color: newColor,
				template: newTemplate,
				stateDlc: getDlcByBrand(value, dlc)
			}))

			// storage management
			setStorage({ brand: value, labelTemplates: brandLabelTemplates, template: newTemplate })
		}
	}

	const onColorChange = (event) => {
		setData((prev) => ({ ...prev, color: event.target.value }))
	}

	const onTemplateChange = (event) => {
		const { template, color } = data
		const newTemplate = event.target.value

		if (newTemplate !== template) {
			const templatesColors =getColors(newTemplate)
			const templateColor = templatesColors.find(elem => elem.value === color)
			const newColor = templateColor
				? templateColor.value
				: getDefaultColor(templatesColors)
			setData((prev) => ({ ...prev, template: newTemplate, colors: templatesColors, color: newColor }))
		}

		// storage management
		setStorage({ template: newTemplate })
	}

	const onLotNumberChange = (event) => {
		setData((prev) => ({ ...prev, lotNumber: event.target.value }))
	}

	const _handleSubmit = (e) => {
		e.preventDefault()
		if (!data.stateDlc) {
			setHasDlcError(true)
		} else {
			const newData = {
				// props
				productId,
				type,
				// state
				color: data.color,
				template: data.template,
				dlc: moment.utc(data.stateDlc).format("DD/MM/YYYY"),
				dlcTimestamp: data.stateDlc,
				lotNumber: data.lotNumber,
				hideDlc: data.hideDlc,
				brand: data.brand,
				fromPackagingPlanning: fromPackagingPlanning
			}
			setHasDlcError(false)
			onSubmitPrint(newData) && closeModal()
		}
	}

	return (
		// issue: MUI: The modal content node does not accept focus.
    // fix: add tabIndex={-1}
    // https://stackoverflow.com/questions/53951479/react-material-ui-modal-causing-an-error-with-the-tabindex
		<div className={classes.labelModal} ref={ref} tabIndex={-1}>
			<div className={classes.headerModal}>
				<Button aria-label="Fermer" className={classes.closeIcon} onClick={closeModal}>
					<CloseIcon/>
				</Button>
				<Typography variant="h6" id="modal-title" className={classes.modalTitle}>
					Etiquette
					<br/>
					{name || ""}
				</Typography>
			</div>
			<div className={classes.formContainer}>
				<form onSubmit={_handleSubmit}>
					<FormControl variant="standard" margin="normal" className={classes.formControl} required>
						<InputLabel
							inputlabelprops={{ shrink: true }}
						>
							Marque
						</InputLabel>

						<Select
							variant="standard"
							value={data.brand}
							label="Marque"
							placeholder="Marque"
							onChange={onBrandChange}
							required
						>
							{renderBrandsSelect()}
						</Select>
					</FormControl>
					<FormControl variant="standard" margin="normal" className={classes.formControl} required>
						<InputLabel
							inputlabelprops={{ shrink: true }}
						>
							Template
						</InputLabel>

						<Select
							variant="standard"
							value={data.template}
							label="Format"
							placeholder="Format"
							onChange={onTemplateChange}
							required
						>
							{renderTemplatesSelect()}
						</Select>
					</FormControl>
					<FormControl variant="standard" margin="normal" className={classes.formControl} required>
						<InputLabel
							inputlabelprops={{ shrink: true }}
						>
							Couleur
						</InputLabel>

						<Select
							variant="standard"
							value={data.color}
							label="Couleur"
							placeholder="Couleur"
							onChange={onColorChange}
							required
						>
							{renderColorsSelect()}
						</Select>
					</FormControl>
					<FormGroup row>
						<FormControl variant="standard" margin="normal" className={classes.formControl} required>
							<DatePicker
								disableCloseOnSelect={false}
								showToolbar={false}
								variant="inline"
								value={data.stateDlc}
								inputFormat="DD/MM/YYYY"
								onChange={onChangeDlc}
								required
								renderInput={(params) => <TextField {...params} variant="standard" label="DLC" />}
							/>
							{
								hasDlcError
									? <span className={classes.dlcError}>Merci de sélectionner une DLC</span>
									: null
							}
						</FormControl>
						<FormControlLabel
							className={`${classes.formControl} ${classes.dlc}`}
							control={
								<>
									{
										data.hideDlc
											? <span className={classes.dlcMessage}>Uniquement pour les etiquettes imprimées pour les sous-traitants</span>
											: null
									}
									<Checkbox
										checked={data.hideDlc}
										onChange={toggleHideDlc}
										color="primary"
									/>
								</>
							}
							label="Cacher la DLC"
						/>
					</FormGroup>
					<FormControl variant="standard" margin="normal" className={classes.formControl} required>
						<TextField
							value={data.lotNumber}
							placeholder="Numéro de lot"
							label="Numéro de lot"
							onChange={onLotNumberChange}
							disabled={data.disabledLotNumber}
							InputProps={{ inputProps: { maxLength: 1, minLength: 0} }}
							required
							variant="standard"
						/>
					</FormControl>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						className={classes.actionButton}
					>
						Imprimer l'étiquette
					</Button>
				</form>
			</div>
		</div>
	)
})

LabelProductModal.propTypes = {
	closeModal: PropTypes.func,
	productId: PropTypes.string,
	type: PropTypes.string,
	onSubmitPrint: PropTypes.func,
	name: PropTypes.string
}

export default LabelProductModal
