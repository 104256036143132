import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import {
    showSingleGroupClassificationsIngredientsEdit,
    showGroupsClassificationsIngredients
} from "../../../../../actions/Ingredients/Classifications/Groups/Groups"
import {
    getGroupIngredient
} from "../../../../../reducers/Ingredients/Classifications/GroupsIngredients"

const styles = {
    container: {
        padding: 20
    },
    closeWindow: {
        textAlign: "right"
    },
    subContainer: {
        marginLeft: 15,
        marginBottom: 15,
        width: 200
    },
    titleLabelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    editBtn: {
        textAlign: "right",
        marginRight: 15
    },
    infoContainer: {
        display: "flex"
    }
}

export class GroupIngredientRead extends PureComponent {

    _onClose = () => {
        const { showGroupsClassificationsIngredients } = this.props
        showGroupsClassificationsIngredients()
    }

    _onEditClick = () => {
        const {
            showSingleGroupClassificationsIngredientsEdit,
            groupIngredient
        } = this.props

        showSingleGroupClassificationsIngredientsEdit(groupIngredient.objectId, "edit")
    }

    render() {
        const {
            classes,
            groupIngredient
        } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.infoContainer}>
                    <div className={classes.subContainer}>
                        <div className={classes.titleLabelContainer}>
                            Nom
                        </div>
                        <div>
                            {groupIngredient && groupIngredient.name}
                        </div>
                    </div>
                    <div className={classes.subContainer}>
                        <div className={classes.titleLabelContainer}>
                            Famille
                        </div>
                        <div>
                            {
                                groupIngredient &&
                                groupIngredient.family &&
                                groupIngredient.family.name
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.editBtn}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this._onEditClick}
                    >
                        Editer
                    </Button>
                </div>
            </div>
        )
    }
}

const StyledGroupIngredientRead = withStyles(styles)(GroupIngredientRead)

export default connect((state, props) => {
    const { params: { id } } = props
    const groupIngredient = id ? getGroupIngredient(state, id) : null
    return {
        groupIngredient: groupIngredient,
        familiesIngredients: state.groupsIngredients.familiesIngredients
    }
}, {
    showSingleGroupClassificationsIngredientsEdit,
    showGroupsClassificationsIngredients
})(StyledGroupIngredientRead)
