import React from "react"
import Button from "@mui/material/Button"
import PlayIcon from "@mui/icons-material/PlayCircleFilled"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import {COLORS} from "../../utils"

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "fixed",
            bottom: 0,
            zIndex: 1,
            justifyContent: "center",
            marginBottom: 26,
            width: "100%",
            display: "flex"
        },
        button: {
            fontWeight: "bold",
            borderRadius: 70,
            fontSize: 24,
            width: 260,
            height: 48,
            padding: 0,
            color: COLORS.WHITE,
            backgroundColor: COLORS.PRIMARY_COLOR,
            textTransform: "capitalize",
            "&:hover": {
                color: COLORS.WHITE,
                backgroundColor: COLORS.PRIMARY_COLOR,
            },
            "& > div:first-child": {
                alignItems: "center",
                marginRight: 6
            }
        },
        icon: {
            width: 22,
            height: 20
        }
    }),
)

const ButtonStart = (props) => {
    const { onStart } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Button
                disableElevation
                variant="contained"
                size="large"
                className={classes.button}
                startIcon={<PlayIcon className={classes.icon} />}
                onClick={onStart}
            >
                commencer
            </Button>
        </div>
    )
}

export default ButtonStart