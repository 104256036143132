import React from "react"
import { withStyles } from "@mui/styles"
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui"
import {
    SelectionState,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
} from "@devexpress/dx-react-grid"
import Select from "@mui/material/Select"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import CheckBox from "@mui/material/Checkbox"
import { usersColumns } from "../../utils/rights"

const styles = {
    select: {
        width: 200,
        whiteSpace: "nowrap"
    }
}


const renderSelectResult = (selected, roles) => {
    if (!Array.isArray(selected) || selected.length === 0) {
        return "Aucun rôle"
    } else {
        return roles.filter(elem => selected.includes(elem.objectId)).map(elem => elem.name).join(", ")
    }
}

const TableCell = ({onChange, roles, classes}) => (tableProps) => {
    if (tableProps.column.name === "roles") {
        return (
            <Table.Cell {...tableProps}>
                <Select
					variant="standard"
                    className={classes.select}
                    value={tableProps.value}
                    onChange={(e) => onChange(e.target.value, tableProps.tableRow.row.objectId)}
                    inputProps={{
                        name: "roles",
                        id: "roles",
                    }}
                    displayEmpty
                    multiple={true}
                    renderValue={
                        selected => renderSelectResult(selected, roles)
                    }
                >
                    {
                        roles.map(role =>
                            <MenuItem
                                value={role.objectId}
                                key={role.objectId}
                            >
                                <CheckBox
                                    checked={Array.isArray(tableProps.value) && !!tableProps.value.find(elem => elem === role.objectId)}
                                    color="secondary"
                                />
                                <ListItemText primary={role.name} />
                            </MenuItem>
                        )
                    }
                </Select>
            </Table.Cell>
        )
    } else {
        return (
            <Table.Cell {...tableProps} />
        )
    }
}

export const UsersTable = ({users, onChange, roles, classes, selection, setSelection}) => {
    return (
        <>
            <Grid
                rows={users}
                columns={usersColumns}
            >
                <SelectionState
                    selection={selection}
                    onSelectionChange={setSelection}
                />
                <SortingState/>
                <IntegratedSorting />
                <IntegratedSelection />
                <Table
                    showSortingControls
                    cellComponent={TableCell({onChange: onChange, roles: roles, classes: classes})}
                />
                <TableHeaderRow showSortingControls/>
                <TableSelection
                    selectByRowClick
                    showSelectAll
                />
            </Grid>
        </>
    )
}

const styledUsersTable = withStyles(styles)(UsersTable)

export default styledUsersTable
