import Parse from "parse"
import { batch } from "react-redux"

import { getOrdersBySites } from "../../parseManager/orders/parseOrdersManager"
import { getSitesByIds } from "../../parseManager/site/parseSiteManager"
import { getServerUrl, parseLimitRequest } from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import { push, actionWithLoader, onEnter } from "../Utils/utils"
import { showSingleOrder } from "../OrderReception/OrderReception"
import { computeOrdersLineSupplierItemsForCSV, ordersLineCsvHeader } from "../../utils/ordersUtils"
import { exportCSVFile } from "../Products/SearchAdvanced"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"

export function loadSites() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const savedSites = state.sites.sites

        // if there are saved sites, no need to make a parse request
        // so that avoid the session token error
        if (savedSites && savedSites.length > 0) {
            return
        }
        const sites = await new Parse.Query("Site")
            .find() || []

        dispatch({
            type: "SITES_LOADED",
            sites: { sites: sites.map((site) => site.toJSON()) } // this is the structure of the state in the reducer
        })
    })
}

export const getParamsFromPath = (pathname) => {
    const paths = pathname.split("/")
    if (paths) {
        return paths[paths.length - 1].split(",")
    }

    return null
}

export const loadBillings = (sitesParseObjects, filters = {}) => {
    return async (dispatch) => {
        const billingsResult = await getOrdersBySites(sitesParseObjects, true, filters, "billings")

        dispatch({
            type: "BILLINGS_ORDERS_LOADED",
            billings: billingsResult.orders,
            total: billingsResult.total
        })
    }
}

export const loadCreditNotes = (sitesParseObjects, filters = {}) => {
    return async (dispatch) => {
        const creditNotesResult = await getOrdersBySites(sitesParseObjects, true, filters, "creditNote")

        dispatch({
            type: "CREDIT_NOTES_ORDERS_LOADED",
            creditNotes: creditNotesResult.orders,
            total: creditNotesResult.total
        })
    }
}

/**
 * filter billings with loading
 * @param {*} filters
 * @returns
 */
export const extractFilteredBillingsCSVFile = (billingFilters = {}, sitesParseObjects) => {
    return actionWithLoader(async (dispatch) => {
        const filters = {
            ...billingFilters,
            include: ["supplierItems.supplierItem"],
            rowsPerPage: parseLimitRequest
        }
        const result = await getOrdersBySites(sitesParseObjects, true, filters, "billings", parseLimitRequest)
        const csvData = computeOrdersLineSupplierItemsForCSV(result.orders)

        dispatch(exportCSVFile(ordersLineCsvHeader, csvData, "ordersExport"))
    })
}

export const extractFilteredCreditNotesCSVFile = (creditNotesFilters = {}, sitesParseObjects) => {
    return actionWithLoader(async (dispatch) => {
        const filters = {
            ...creditNotesFilters,
            include: ["supplierItems.supplierItem"]
        }
        const result = await getOrdersBySites(sitesParseObjects, true, filters, "creditNote", parseLimitRequest)
        const csvData = computeOrdersLineSupplierItemsForCSV(result.orders)

        dispatch(exportCSVFile(ordersLineCsvHeader, csvData, "ordersExport"))
    })
}


/**
 * filter creditNotes with loading
 * @param {*} filters
 * @returns
 */
export const loadFilteredCreditNotes = (sitesParseObject, filters) => {
    return actionWithLoader(async (dispatch) => dispatch(loadCreditNotes(sitesParseObject, filters)))
}

/**
 * called from data grid
 * filter billings with loading
 * @returns
 */
export const loadFilteredBillings = (sitesParseObjects, filters) => {
    return actionWithLoader(async (dispatch) => {
        dispatch(loadBillings(sitesParseObjects, filters))
    })
}

/**
 * called by the onEnter
 * @param values is it really used ?
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const loadBillingAndCreditNotes = (values) => {
    return actionWithLoader(async (dispatch) => {
        const suppliers = await getSuppliers({ toJSON: true })
        const { siteIds } = values
        const siteIdsArray = siteIds ? siteIds.split(",") : []
        const sitesParseObjects = await getSitesByIds(siteIdsArray, [], false)
        // load suppliers and sites needed for filters
        dispatch({
            type: "BILLING_CREDIT_NOTES_SUPPLIERS_LOADED",
            suppliers: suppliers,
            sites: sitesParseObjects.map((site) => site.toJSON()),
            sitesParseObjects: sitesParseObjects
        })
    })
}

export const selectSitesForBillingAndCreditNotes = (sitesIds = []) => {
    return actionWithLoader(async (dispatch) => {
        const sites = await getSitesByIds(sitesIds)
        dispatch({
            type: "BILLING_CREDIT_NOTES_SITES_LOADED",
            sites
        })
        dispatch(showBillingAndCreditNotesList(sitesIds))
    })
}

/**
 * change the isCreditNotesPaid field to true or false
 * @param {*} orderId
 * @param {*} value
 * @returns
 */
export const changeOrderIsPaidStatus = (orderId, value) => {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            const state = getState()
            const orders = state.billingCreditNotes.creditNotes
            const total = state.billingCreditNotes.total.creditNotes
            // use server side api to avoid the (unknown) expired session token error
            const url = `${getServerUrl()}/orders/_changeOrderIsPaidStatus/${orderId}`
            const body = { isCreditNotesPaid: value }

            const result = await axiosCall("PUT", url, body, { "Content-Type": "application/json" })
            if (!result.data.success) return

            // update the selected order
            // the update the order list
            const newOrders = orders.map((order) => {
                if (order.objectId === orderId) {
                    return {
                        ...order,
                        ...result.data.order
                    }
                }
                return order
            })

            dispatch({
                type: "CREDIT_NOTES_ORDERS_LOADED",
                creditNotes: newOrders,
                total
            })
        } catch (error) {
            dispatch({
                type: "BILLING_CREDIT_NOTES_OPEN_SNACKBAR",
                dispatchSnackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue lors changement de payé à non payé",
                    duration: 5000
                }
            })
        }
    })
}

export const filterCreditsNotes = (filters) => {
    return async (dispatch) => {
        dispatch({
            type: "FILTERS_CREDIT_NOTES",
            creditNotesFilter: filters
        })
    }
}

export const saveFiltersBillings = (filters) => {
    return async (dispatch) => {
        dispatch({
            type: "FILTERS_BILLINGS",
            billingsFilter: filters
        })
    }
}

export const selectBillingCreditNotesType = (orderType) => {
    return async (dispatch) => {
        dispatch({
            type: "SELECT_BILLING_CREDIT_NOTES_ORDER_TYPE",
            orderType
        })
    }
}

export const clearBillingCreditNotesFilters = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_BILLING_CREDIT_NOTES_FILTERS",
        })
    }
}

export const clearBillingCreditNotesType = () => {
    return async (dispatch) => {
        dispatch({
            type: "CLEAR_BILLING_CREDIT_NOTES_ORDER_TYPE",
        })
    }
}

export function selectSingleCreditNoteOrder(order) {
    return actionWithLoader(async (dispatch) => {
        batch(() => {
            dispatch({
                type: "ORDER_RECEPTION_SITE_LOADED",
                site: order.site
            })
            dispatch(showSingleOrder(order.site.objectId, order.receptionDate, order.objectId))
        })
    })
}

export const closeBillingAndCreditNotesSnackBar = (currentType) => {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "BILLING_CREDIT_NOTES_CLOSE_SNACKBAR",
            ordersSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    })
}

export const onEnterBillingAndCreditNotesList = (store) => {
    return onEnter({
        store,
        actionThunk: loadBillingAndCreditNotes
    })
}

export const onEnterBillingAndCreditNotes = (store) => {
    return onEnter({
        store,
        actionThunk: loadSites
    })
}

/** Routers **/

export const showOrdersSiteSelector = () => {
    return push("/orders")
}

export const showBillingAndCreditNotes = () => {
    return push("/billingCreditNotes")
}

export const showBillingAndCreditNotesList = (siteIds) => {
    return push("/billingCreditNotes/sites/" + siteIds.join(","))
}
