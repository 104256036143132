import dayjs from "dayjs"
import Parse from "parse"
import { COLLECTION_NAMES, toPointerForQuery } from "../../parseUtils"
import { uniqBy } from "lodash"
import { getProductionSuppliesForSecondaryDlCs } from "../productionSupply/parseProductionSupplyManager"

const SecondaryDLC = Parse.Object.extend("SecondaryDLC")
const Lot = Parse.Object.extend("Lots")

export const loadSecondaryDLCs = async ({ include = [], select = [], toJson = true, siteId, kitchenAreaId }) => {
	const secondaryDLCQuery = new Parse.Query(SecondaryDLC)
		.notEqualTo("deleted", true)

	if (siteId) { // pointer
		secondaryDLCQuery.equalTo("site", toPointerForQuery(COLLECTION_NAMES.site, siteId))
	}

	if (kitchenAreaId) { // pointer
		secondaryDLCQuery.equalTo("kitchenArea", toPointerForQuery(COLLECTION_NAMES.kitchenArea, kitchenAreaId))
	}

	if (include && include.length > 0) {
		secondaryDLCQuery.include(include)
	}
	if (select && select.length > 0) {
		secondaryDLCQuery.select(select)
	}

	const secondaryDLCs = await secondaryDLCQuery.findAll()
	return toJson ? secondaryDLCs.map(secondaryDLC => secondaryDLC.toJSON()) : secondaryDLCs
}

export const loadSecondaryDLCLotsAndSupplierItems = async ({ openingDate, toJSON = true }) => {

	const productionSupplies = await getProductionSuppliesForSecondaryDlCs({ date: openingDate, toJSON })

	const lots = productionSupplies.map(productionSupply => productionSupply.lots).flat()

	const supplierItems = lots.map(lot => lot.supplierItem)

	return {
		lots: lots,
		supplierItems: uniqBy(supplierItems, "name"),
		productionSupplies
	}
}

export const getSecondaryDLCsForProductionStepExecution = async ({
	kitchenAreaId,
	supplierItemsIds,
	toJSON = true
}) => {
	try {

		const retrievedSecondaryDLCs = []

		const today = dayjs().startOf("day").valueOf()

		for (const supplierItemId of supplierItemsIds) {

			const secondaryDLC = await new Parse.Query(SecondaryDLC)
				.greaterThanOrEqualTo("secondaryDLC", today)
				.equalTo("kitchenArea", toPointerForQuery(COLLECTION_NAMES.kitchenArea, kitchenAreaId))
				.equalTo("supplierItem", toPointerForQuery(COLLECTION_NAMES.supplierItem, supplierItemId))
				.include(["orderSupplierItem"])
				.find()

			const secondaryDLCReplacement = await new Parse.Query(SecondaryDLC)
				.greaterThanOrEqualTo("secondaryDLC", today)
				.equalTo("kitchenArea", toPointerForQuery(COLLECTION_NAMES.kitchenArea, kitchenAreaId))
				.exists("originalSupplierItems")
				.equalTo("originalSupplierItems.objectId", supplierItemId)
				.include(["orderSupplierItem"])
				.find()

			if (secondaryDLC.length > 0) {
				retrievedSecondaryDLCs.push(...secondaryDLC)
			}

			if (secondaryDLCReplacement.length > 0) {
				retrievedSecondaryDLCs.push(...secondaryDLCReplacement)
			}
		}

		return toJSON ? retrievedSecondaryDLCs.map(secondaryDLC => secondaryDLC.toJSON()) : retrievedSecondaryDLCs
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export const createSecondaryDLC = async (values, toJson = true) => {

	const lot = await new Parse.Query(Lot).get(values.lot)
	const formattedEvents = (lot.get("events") || []).map(event => ({ ...event, date: dayjs.utc(event.date).startOf("day").valueOf() }))
	const foundEvents = formattedEvents.filter(event => event.mode === "OUTPUT" && event.date === values.openingDate) // not sure this one is working right here

	const originalSupplierItemsIds = []

	for (const foundEvent of foundEvents) {
		if (foundEvent.originalSupplierItem?.id) {
			originalSupplierItemsIds.push(foundEvent.originalSupplierItem.id)
		}
	}

	const secondaryDLC = new SecondaryDLC()
	secondaryDLC.set("site", { __type: "Pointer", className: COLLECTION_NAMES.site, objectId: values.site })
	if (values.kitchenArea) {
		secondaryDLC.set("kitchenArea", { __type: "Pointer", className: COLLECTION_NAMES.kitchenArea, objectId: values.kitchenArea })
	}
	secondaryDLC.set("orderSupplierItem", { __type: "Pointer", className: COLLECTION_NAMES.orderSupplierItem, objectId: values.orderSupplierItem })
	secondaryDLC.set("supplierItem", { __type: "Pointer", className: COLLECTION_NAMES.supplierItem, objectId: values.supplierItem })
	secondaryDLC.set("lot", { __type: "Pointer", className: COLLECTION_NAMES.lot, objectId: values.lot })
	secondaryDLC.set("productionSupply", { __type: "Pointer", className: COLLECTION_NAMES.productionSupply, objectId: values.productionSupply })
	secondaryDLC.set("name", values.name)
	secondaryDLC.set("lotNumber", values.lotNumber)
	secondaryDLC.set("openingDate", values.openingDate)
	secondaryDLC.set("dlc", values.dlc)
	secondaryDLC.set("secondaryDLC", values.secondaryDLC)
	secondaryDLC.set("quantity", values.quantity)

	if (originalSupplierItemsIds.length) { // add original supplier item from traiter le manquant
		secondaryDLC.set("originalSupplierItems", originalSupplierItemsIds.map(originalSupplierItemId => ({ __type: "Pointer", className: COLLECTION_NAMES.supplierItem, objectId: originalSupplierItemId })))
	}

	const savedSecondaryDLC = await secondaryDLC.save()

	// refresh with include
	const updatedSecondaryDLC = await new Parse.Query(SecondaryDLC).include("orderSupplierItem").get(savedSecondaryDLC.id)
	return toJson ? updatedSecondaryDLC.toJSON() : updatedSecondaryDLC
}

export const updateSecondaryDLCFromInventory = async (secondaryDLCId, quantity, newEvent) => {
	try {
		const secondaryDLC = await new Parse.Query(SecondaryDLC).get(secondaryDLCId)

		const currentEvents = secondaryDLC.get("events") || []
		secondaryDLC.set("quantity", +quantity)
		secondaryDLC.set("events", [...currentEvents, newEvent])

		const savedSecondaryDLC = await secondaryDLC.save()

		return savedSecondaryDLC.toJSON()
	}
	catch (e) {
		console.error(e)
	}
}

export const updateSecondaryDLCDate = async (secondaryDLCId, secondaryDLCDate, newEvent) => {
	try {
		const secondaryDLCObject = await new Parse.Query(SecondaryDLC).get(secondaryDLCId)

		secondaryDLCObject.set("secondaryDLC", secondaryDLCDate)

		const currentEvents = secondaryDLCObject.get("events") || []
		secondaryDLCObject.set("events", [...currentEvents, newEvent])

		const savedSecondaryDLC = await secondaryDLCObject.save()

		return savedSecondaryDLC.toJSON()
	}
	catch (e) {
		console.error(e)
	}
}