import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { Field, reduxForm } from "redux-form"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import { familyIngredientFormInitialValues } from "../../../../../actions/Utils/utils"
import {getFamilyIngredient} from "../../../../../reducers/Ingredients/Classifications/FamiliesIngredients"
import {recipeFormstyles} from "../../../../../styles"
import {familyIngredientValidator} from "../../../../../utils/validators"
import {
    createFamilyClassificationIngredient,
    showFamiliesClassificationsIngredients,
    showSingleFamilyClassificationIngredientsRead
} from "../../../../../actions/Ingredients/Classifications/Families/Families"
import ReduxTextField from "../../../../../components/form/ReduxTextField"

class FamilyIngredientForm extends PureComponent {

    _onClose = () => {
        const {
            showFamiliesClassificationsIngredients,
            familyIngredient,
            showSingleFamilyClassificationIngredientsRead,
            location
        } = this.props

        if (location && location.state && location.state.returnPath === "edit"){
            showSingleFamilyClassificationIngredientsRead(familyIngredient.objectId)
        }
        else {
            showFamiliesClassificationsIngredients()
        }
    }

    render(){
        const {
            classes,
            createFamilyClassificationIngredient,
            location,
            handleSubmit
        } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={classes.formContainer}>
                    <form onSubmit={handleSubmit(createFamilyClassificationIngredient, location)}>
                        <div className="flexRow">
                            <FormControl margin="normal" classes={{root: classes.formControlMax400}} required>
                                <Field
                                    name="name"
                                    component={ReduxTextField}
                                    label="Nom"
                                />
                            </FormControl>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                classes={{root: classes.actionButton}}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

FamilyIngredientForm = reduxForm({
    form: "familyIngredient",
    validate: familyIngredientValidator,
    enableReinitialize: false
})(FamilyIngredientForm)

FamilyIngredientForm = withStyles(recipeFormstyles)(FamilyIngredientForm)

export default connect((state, props) => {
    const { params: {id} } = props
    const familyIngredient = id ? getFamilyIngredient(state, id) : null
    const familyIngredientFormValues = familyIngredient !== null ? state.form.familyIngredient ? state.form.familyIngredient.values : undefined : undefined
    return {
        initialValues: familyIngredientFormInitialValues(familyIngredient),
        familyIngredientFormValues,
        familyIngredient: familyIngredient
    }
}, {
    createFamilyClassificationIngredient,
    showFamiliesClassificationsIngredients,
    showSingleFamilyClassificationIngredientsRead
})(FamilyIngredientForm)
