import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    ACTIVE_KFC_BRANDS, KFC_BRANDS
} from "../../utils"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const styles = {
    select: {
        width: 180
    },
    logo: {
        width: 16,
        marginRight: 10
    },
    selected: {
        fontWeight: "bold"
    }
}

for (const i in KFC_BRANDS) { // Generates classes for text color in function of the brand
    const currentBrand = KFC_BRANDS[i]
    styles[currentBrand.name] = {
        color: currentBrand.color
    }
}

export const PlanningBrandSelector = (props) => {
    const {
        brand = KFC_BRANDS[0],
        allBrands = KFC_BRANDS,
        onBrandClick,
        classes,
        activeBrandsOnly = false
    } = props

    const BRANDS = activeBrandsOnly ? ACTIVE_KFC_BRANDS : KFC_BRANDS

    return (
        <Select
            variant="standard"
            onChange={(e) => onBrandClick(BRANDS.find(brand => brand.name === e.target.value))}
            value={brand.name}
            className={classes.select}
        >
            {
                BRANDS.map((currentBrand, index) => {
                    if (allBrands.find(el => el.name === currentBrand.name)){
                        return (
                            <MenuItem value={currentBrand.name} key={index}>
                                <img className={classes.logo} src={currentBrand.image} alt={`Logo ${currentBrand.label}`} />
                                <span className={
                                    `${classes[currentBrand.name]} ${currentBrand.name === brand.name ? classes.selected : null}`
                                }>
                                {currentBrand.label}
                            </span>
                            </MenuItem>
                        )
                    }
                    return null
                })
            }
        </Select>
    )
}

export default withStyles(styles)(PlanningBrandSelector)