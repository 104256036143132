import React from "react"
import { useState } from "react"
import {
    useDispatch,
    useSelector
} from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import SettingsIcon from "@mui/icons-material/Settings"
import { Tab, Tabs, Paper, Box } from "@mui/material"
import DistributionCenterBanner from "./DistributionCenterBanner"
import {
    showPlanningSettingsDistributionCentersList
} from "../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"
import clsx from "clsx"
import PageHeader from "../../../../components/PageHeader"
import { getProductTypeOptionsSelector } from "../../../../reducers/ProductTypes/productTypes"
import DistributionCenterGeneral from "./DistributionCenterGeneral"
import DistributionCenterLogistics from "./DistributionCenterLogistics"
import DistributionCenterResumeFormContainer from "./forms/DistributionCenterResumeFormContainer"
import DistributionCenterAddressFormContainer from "./forms/DistributionCenterAddressFormContainer"
import DistributionCenterAttachRatesFormContainer from "./forms/DistributionCenterAttachRatesFormContainer"
import DistributionCenterDispatchSitesFormContainer from "./forms/DistributionCenterDispatchSitesFormContainer"
import DistributionCenterDeliveryNoteConfigFormContainer from "./forms/DistributionCenterDeliveryNoteConfigFormContainer"
import DistributionCenterDatesFormContainer from "./forms/DistributionCenterDatesFormContainer"

const styles = makeStyles({
    container: {
        width: "100%",
        position: "relative",
        paddingTop: 64,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "start",
    },
    rootDialogActions: {
        justifyContent: "space-between"
    },
    tabsPaper: {
        width: "100%"
    }
})

const DistributionCenterViewPage = () => {
    const distributionCenter = useSelector(state => state.planningSettings.selectedDistributionCenter)
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const [tabValue, setTabValue] = useState(0)

    const [editResumeModalOpen, setEditResumeModalOpen] = useState(false)
    const [editAddressModalOpen, setEditAddressModalOpen] = useState(false)
    const [editOpeningDateModalOpen, setEditOpeningDateModalOpen] = useState(false)
    const [editAttachRatesModalOpen, setEditAttachRatesModalOpen] = useState(false)
    const [editDispatchSitesModalOpen, setEditDispatchSitesModalOpen] = useState(false)
    const [editDeliveryNoteConfigModalOpen, setEditDeliveryNoteConfigModalOpen] = useState(false)

    const onOpenResumeForm = () => setEditResumeModalOpen(true)
    const onCloseResumeForm = () => setEditResumeModalOpen(false)

    const onOpenAddressForm = () => setEditAddressModalOpen(true)
    const onCloseAddressForm = () => setEditAddressModalOpen(false)

    const onOpenOpeningDateForm = () => setEditOpeningDateModalOpen(true)
    const onCloseOpeningDateForm = () => setEditOpeningDateModalOpen(false)

    const onOpenAttachRatesForm = () => setEditAttachRatesModalOpen(true)
    const onCloseAttachRatesForm = () => setEditAttachRatesModalOpen(false)

    const onOpenDispatchSitesForm = () => setEditDispatchSitesModalOpen(true)
    const onCloseDispatchSitesForm = () => setEditDispatchSitesModalOpen(false)

    const onOpenDeliveryNotConfigForm = () => setEditDeliveryNoteConfigModalOpen(true)
    const onCloseDeliveryNotConfigForm = () => setEditDeliveryNoteConfigModalOpen(false)


    const dispatch = useDispatch()
    const classes = styles()

    const showDistributionCentersList = () => {
        dispatch(showPlanningSettingsDistributionCentersList())
    }

    return (
        <>
            <PageHeader
                icon={<SettingsIcon />}
                title="Réglages"
                withBackButton
                onGoBack={showDistributionCentersList}
            />
            <Box sx={{ background: "#F6F6F6" }}>
                <div className={clsx(classes.container)}>
                    <DistributionCenterBanner
                        distributionCenter={distributionCenter}
                    />
                    <Paper elevation={1} className={classes.tabsPaper}>
                        <Tabs
                            value={tabValue}
                            onChange={(_, newValue) => setTabValue(newValue)}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Général" value={0} />
                            <Tab label="Logistique" value={1} />
                        </Tabs>
                    </Paper>
                    {tabValue === 0 && (
                        <DistributionCenterGeneral
                            distributionCenter={distributionCenter}
                            onEditResume={onOpenResumeForm}
                            onEditAddress={onOpenAddressForm}
                            onEditOpeningDate={onOpenOpeningDateForm}
                        />)}
                    {tabValue === 1 && (
                        <DistributionCenterLogistics
                            distributionCenter={distributionCenter}
                            productTypesOptions={productTypeOptions}
                            onEditAttachRates={onOpenAttachRatesForm}
                            onEditDispatchSites={onOpenDispatchSitesForm}
                            onEditDeliveryNoteConfig={onOpenDeliveryNotConfigForm}
                        />)}
                </div>
                <DistributionCenterResumeFormContainer
                    open={editResumeModalOpen}
                    onClose={onCloseResumeForm}
                    distributionCenter={distributionCenter}
                />
                <DistributionCenterAddressFormContainer
                    open={editAddressModalOpen}
                    onClose={onCloseAddressForm}
                    distributionCenter={distributionCenter}
                />
                <DistributionCenterDatesFormContainer
                    open={editOpeningDateModalOpen}
                    onClose={onCloseOpeningDateForm}
                    distributionCenter={distributionCenter}
                />
                <DistributionCenterAttachRatesFormContainer
                    open={editAttachRatesModalOpen}
                    onClose={onCloseAttachRatesForm}
                    distributionCenter={distributionCenter}
                />
                <DistributionCenterDispatchSitesFormContainer
                    open={editDispatchSitesModalOpen}
                    onClose={onCloseDispatchSitesForm}
                    distributionCenter={distributionCenter}
                />
                <DistributionCenterDeliveryNoteConfigFormContainer
                    open={editDeliveryNoteConfigModalOpen}
                    onClose={onCloseDeliveryNotConfigForm}
                    distributionCenter={distributionCenter}
                />
            </Box>
        </>
    )
}

export default DistributionCenterViewPage
