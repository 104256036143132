export const queryFilters = (query, filters) => {
  if (!filters) return

  Object.keys(filters).forEach(key => {
    const value = filters[key]
    if (value) {
      if (Array.isArray(value)) {
        query.containedIn(key, filters[key])
      } else {
        query.equalTo(key, filters[key])
      }
    }
  })
}