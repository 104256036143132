import React from "react"
import { makeStyles } from "@mui/styles"
import { Box, Icon, Typography } from "@mui/material"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import { COLORS } from "../../utils"

const useStyles = makeStyles(() => ({
  headerBlock: {
    flex: "0 0 64px",
  },
  header: {
    width: "100%"
  },
  headerTitle: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.PACKAGING_EXECUTION_YELLOW
  },
  headerSubtitle: {
    paddingLeft: 8,
    fontSize: 16,
    color: COLORS.DRAFT_GREY
  }
}))

const PackagingExecutionHeader = ({ leftAction = null, rightAction = null, subtitle = null }) => {

  const classes = useStyles()

  return (
    <Box className={classes.headerBlock}>
    <GenericTopBarHeader
      leftAction={leftAction}
      title={(
        <div className={classes.headerTitle}>
          <Icon>
            <ShoppingBasketIcon />
          </Icon>
          <Typography variant="h7" sx={{ fontWeight: 500 }}>
            Barquettage
          </Typography>
        </div>
      )}
      info={subtitle && <Typography className={classes.headerSubtitle}>{`/ ${subtitle}`}</Typography>}
      className={classes.header}
      rightAction={rightAction}
    />
  </Box>
  )
}

export default PackagingExecutionHeader