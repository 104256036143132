import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import {COLORS} from "../../../utils"
import MultipleTypeSelect from "../../form/MultipleTypeSelect"
import HubSelect from "../../form/HubSelect"
import { DayCardCreateSchema } from "../../../utils/yupValidators"
import moment from "moment"
import {productType} from "../../../utils/dispatchUtils"

const GlobalCss = withStyles({
    "@global": {
        ".MuiMenu-paper": {
            minHeight: 583
        },
    },
})(() => null)

export const DayCardModal = (props) => {
    const {
        classes,
        onClose,
        isCreatingDayCard,
        onCreateDayCard,
        hubs
    } = props

    const submitForm = (values) => {
        onCreateDayCard(values)
        onClose()
    }

    const initialValues = {
        hub: "",
        types: []
    }

    const sortByLabel = (arr) => {
        return arr.sort((a, b) => a.label.localeCompare(b.label))
    }

    const checkInclude = (source, selected, value) => {
        let newSelected = value

        if (!selected.includes("ALL") && value.includes("ALL")) {
            newSelected = source.map(elem => elem.value)
        }
        else if (selected.includes("ALL") && !value.includes("ALL")) {
            newSelected = []
        }
        else if (!newSelected.includes("ALL") && newSelected.length === source.length -1) {
            newSelected.push("ALL")
        }
        else if (newSelected.includes("ALL") && newSelected.length < source.length) {
            newSelected = newSelected.filter(elem => elem !== "ALL")
        }

        return newSelected
    }

    const handleTypeSelect = (e, selected) => {
        const values = e.target.value
        e.target.value = checkInclude(productType, selected, values)

        return e
    }

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={initialValues}
            validationSchema={DayCardCreateSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isCreatingDayCard}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Carte du jour</span>
                            </DialogTitle>
                            <DialogContent>
                                <HubSelect
                                    classes={classes}
                                    hubs={sortByLabel(hubs)}
                                    value={values.hub}
                                    onChange={handleChange}
                                    name="hub"
                                    id="hub"
                                />
                                {
                                    errors.hub?
                                        (
                                            <div className={classes.errorField}>{errors.hub}</div>
                                        )
                                        : null
                                }
                                <MultipleTypeSelect
                                    classes={classes}
                                    name="types"
                                    id="types"
                                    value={values.types}
                                    onChange={(e) => { handleChange(handleTypeSelect(e, values.types)) }}
                                />
                                {
                                    errors.types?
                                        (
                                            <div className={classes.errorField}>{errors.types}</div>
                                        )
                                        : null
                                }
                                <GlobalCss />
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    valider
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = {
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    typeFormControl: {
        width: 452,
        marginLeft: "unset",
        marginTop: 16,
    },
    formControl: {
        marginLeft: "unset",
        width: 452,
        marginTop: 16
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0
    },
    dialogTitleLabel: {
        fontWeight: 500,
        marginTop: 5,
        fontSize: 20,
        lineHeight: "24px",
        width: 596
    },
    errorField: {
        color: COLORS.RED
    }
}

export default withStyles(styles)(DayCardModal)
