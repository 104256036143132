import {
    onEnter,
    getReplacingPath,
    actionWithLoader
} from "../Utils/utils"
import { getRecipes, getRecipeRange } from "../../parseManager/recipe/parseRecipeManager"
import { getSubcontractorsProducts, getSubcontractorsProductsRange } from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { getProductsTags } from "../../parseManager/products/resources/productTag/parseProductTagManager"
import { getSuppliers } from "../../parseManager/products/resources/supplier/parseSupplierManager"
import { getPackagings } from "../../parseManager/products/resources/packaging/parsePackagingManager"
import { convertToCSV } from "../../utils/searchAdvancedUtils"

export function loadData() {
    return actionWithLoader(async (dispatch) => {
        const recipes = []
        const subcontractorsProducts = []
        const [
            productsTags,
            suppliers,
            packagings,
            recipePriceRange,
            subcontractorProductPriceRange,
            gestersRange,
            portionPerPlateRange,
            foodcostRange,
            foodcostPCTRange,
            grossWeightRange,
            ingredientsComplexityRange,
            recipeNetWeightRange,
            spNetWeightRange,
            recipeDLCRange,
            spDLCRange,
            recipeFibersScoreRange,
            spFibersRange,
            recipeSaltRange,
            spSaltRange,
            recipeProteinsRange,
            spProteinsRange,
            recipeSugarRange,
            spSugarRange,
            recipeSaturatedFattyAcidsRange,
            spSaturatedFattyAcidsRange,
            recipeCaloriesRange,
            spCaloriesRange,
            recipeCarbohydratesRange,
            spCarbohydratesRange,
            recipeFatRange,
            spFatRange,
            recipeCarbonFootPrintRange,
            spCarbonFootPrintRange,
            recipeOrganicIngredientsRateRange,
            spOrganicIngredientsRateRange,
            recipeFrenchIngredientsRateRange,
            spFrenchIngredientsRateRange,
            recipeCertifiedIngredientsRateRange,
            spCertifiedIngredientsRateRange,
            recipeSeasonalIngredientsRateRange,
            spSeasonalIngredientsRateRange
        ] = await Promise.all([
            getProductsTags(),
            getSuppliers({ toJSON: true }),
            getPackagings(),
            getRecipeRange("defaultValues.price", "defaultValues"),
            getSubcontractorsProductsRange("price"),
            getRecipeRange("gesters"),
            getRecipeRange("portionPerPlate"),
            getRecipeRange("foodcost"),
            getRecipeRange("foodCostPCT.value", "foodCostPCT"),
            getRecipeRange("grossWeight"),
            getRecipeRange("ingredientsComplexity"),
            getRecipeRange("netWeight"),
            getSubcontractorsProductsRange("netWeight"),
            getRecipeRange("defaultValues.dlc", "defaultValues"),
            getSubcontractorsProductsRange("dlc"),
            getRecipeRange("nutritionInformation.fibers", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.fibers", "nutritionInformation"),
            getRecipeRange("nutritionInformation.salt", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.salt", "nutritionInformation"),
            getRecipeRange("nutritionInformation.proteins", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.proteins", "nutritionInformation"),
            getRecipeRange("nutritionInformation.sugar", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.sugar", "nutritionInformation"),
            getRecipeRange("nutritionInformation.saturatedFattyAcids", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.saturatedFattyAcids", "nutritionInformation"),
            getRecipeRange("nutritionInformation.calories", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.calories", "nutritionInformation"),
            getRecipeRange("nutritionInformation.carbohydrates", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.carbohydrates", "nutritionInformation"),
            getRecipeRange("nutritionInformation.fat", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.fat", "nutritionInformation"),
            getRecipeRange("nutritionInformation.carbonFootPrint", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.carbonFootPrint", "nutritionInformation"),
            getRecipeRange("nutritionInformation.organicIngredientsRate", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.organicIngredientsRate", "nutritionInformation"),
            getRecipeRange("nutritionInformation.frenchIngredientsRate", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.frenchIngredientsRate", "nutritionInformation"),
            getRecipeRange("nutritionInformation.certifiedIngredientsRate", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.certifiedIngredientsRate", "nutritionInformation"),
            getRecipeRange("nutritionInformation.seasonalIngredientsRate", "nutritionInformation"),
            getSubcontractorsProductsRange("nutritionInformation.seasonalIngredientsRate", "nutritionInformation")
        ])

        dispatch({
            type: "ADVANCED_SEARCH_DATA_LOADED",
            recipes,
            subcontractorsProducts,
            productsTags,
            suppliers,
            packagings,
            priceRange: [...new Set([
                recipePriceRange[0].price,
                recipePriceRange[1].price,
                ...subcontractorProductPriceRange
            ])].sort(),
            gestersRange,
            portionPerPlateRange,
            foodcostRange,
            foodcostPCTRange: [...new Set([
                ...foodcostPCTRange[0].map(elm => elm.value),
                ...foodcostPCTRange[1].map(elm => elm.value)
            ].sort())
            ],
            grossWeightRange,
            netWeightRange: [...new Set([
                ...recipeNetWeightRange,
                ...spNetWeightRange
            ])].sort(),
            dlcRange: [...new Set([
                recipeDLCRange[0].dlc,
                recipeDLCRange[1].dlc,
                ...spDLCRange
            ])].sort(),
            ingredientsComplexityRange,
            fibersRange: [...new Set([
                recipeFibersScoreRange[0].fibers,
                recipeFibersScoreRange[1].fibers,
                spFibersRange[0].fibers,
                spFibersRange[1].fibers
            ])].sort(),
            seasonalIngredientsRateRange: [...new Set([
                recipeSeasonalIngredientsRateRange[0].seasonalIngredientsRate,
                recipeSeasonalIngredientsRateRange[1].seasonalIngredientsRate,
                spSeasonalIngredientsRateRange[0].seasonalIngredientsRate,
                spSeasonalIngredientsRateRange[1].seasonalIngredientsRate
            ])].sort(),
            certifiedIngredientsRateRange: [...new Set([
                recipeCertifiedIngredientsRateRange[0].certifiedIngredientsRate,
                recipeCertifiedIngredientsRateRange[1].certifiedIngredientsRate,
                spCertifiedIngredientsRateRange[0].certifiedIngredientsRate,
                spCertifiedIngredientsRateRange[1].certifiedIngredientsRate
            ])].sort(),
            frenchIngredientsRateRange: [...new Set([
                recipeFrenchIngredientsRateRange[0].frenchIngredientsRate,
                recipeFrenchIngredientsRateRange[1].frenchIngredientsRate,
                spFrenchIngredientsRateRange[0].frenchIngredientsRate,
                spFrenchIngredientsRateRange[1].frenchIngredientsRate
            ])].sort(),
            organicIngredientsRateRange: [...new Set([
                recipeOrganicIngredientsRateRange[0].organicIngredientsRate,
                recipeOrganicIngredientsRateRange[1].organicIngredientsRate,
                spOrganicIngredientsRateRange[0].organicIngredientsRate,
                spOrganicIngredientsRateRange[1].organicIngredientsRate
            ])].sort(),
            carbonFootPrintRange: [...new Set([
                recipeCarbonFootPrintRange[0].carbonFootPrint,
                recipeCarbonFootPrintRange[1].carbonFootPrint,
                spCarbonFootPrintRange[0].carbonFootPrint,
                spCarbonFootPrintRange[1].carbonFootPrint
            ])].sort(),
            fatRange: [...new Set([
                recipeFatRange[0].fat,
                recipeFatRange[1].fat,
                spFatRange[0].fat,
                spFatRange[1].fat
            ])].sort(),
            carbohydratesRange: [...new Set([
                recipeCarbohydratesRange[0].carbohydrates,
                recipeCarbohydratesRange[1].carbohydrates,
                spCarbohydratesRange[0].carbohydrates,
                spCarbohydratesRange[1].carbohydrates
            ])].sort(),
            caloriesRange: [...new Set([
                recipeCaloriesRange[0].calories,
                recipeCaloriesRange[1].calories,
                spCaloriesRange[0].calories,
                spCaloriesRange[1].calories
            ])].sort(),
            saturatedFattyAcidsRange: [...new Set([
                recipeSaturatedFattyAcidsRange[0].saturatedFattyAcids,
                recipeSaturatedFattyAcidsRange[1].saturatedFattyAcids,
                spSaturatedFattyAcidsRange[0].saturatedFattyAcids,
                spSaturatedFattyAcidsRange[1].saturatedFattyAcids
            ])].sort(),
            sugarRange: [...new Set([
                recipeSugarRange[0].sugar,
                recipeSugarRange[1].sugar,
                spSugarRange[0].sugar,
                spSugarRange[1].sugar
            ])].sort(),
            proteinsRange: [...new Set([
                recipeProteinsRange[0].proteins,
                recipeProteinsRange[1].proteins,
                spProteinsRange[0].proteins,
                spProteinsRange[1].proteins
            ])].sort(),
            saltRange: [...new Set([
                recipeSaltRange[0].salt,
                recipeSaltRange[1].salt,
                spSaltRange[0].salt,
                spSaltRange[1].salt
            ])].sort()
        })
    })
}

export function filterData(filters) {
    return actionWithLoader(async (dispatch) => {
        const recipesInclude = []
        const subcontractorsProductsIncludes = ["name"]

        if (filters.includes("internalTag")) {
            recipesInclude.push("internalTag")
            subcontractorsProductsIncludes.push("internalTag")
        }

        if (filters.includes("provider")) subcontractorsProductsIncludes.push("subcontractor")

        if (filters.includes("packaging")) {
            recipesInclude.push("packaging.value")
            subcontractorsProductsIncludes.push("packaging")
        }

        if (filters.includes("subPackaging")) {
            recipesInclude.push("subPackaging.value")
            subcontractorsProductsIncludes.push("subPackaging")
        }

        if (filters.includes("reusablePackaging")) recipesInclude.push("reusablePackaging.value")
        if (filters.includes("reusableSubPackaging")) recipesInclude.push("reusableSubPackaging.value")

        const [recipes, subcontractorsProducts] = await Promise.all([
            getRecipes({ includes: recipesInclude.length ? recipesInclude : null, toJSON: true }),
            getSubcontractorsProducts({ includes: subcontractorsProductsIncludes })
        ])

        dispatch({
            type: "FILTERED_ADVANCED_SEARCH_DATA_LOADED",
            recipes,
            subcontractorsProducts
        })
    })
}

export function exportCSVFile(headers, items, fileTitle, separator) {
    return actionWithLoader(async (dispatch) => {
        if (headers) {
            items.unshift(headers)
        }

        const jsonObject = JSON.stringify(items)
        const csv = convertToCSV(jsonObject, separator)
        const exportedFilename = fileTitle + ".csv" || "export.csv"

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename)
        } else {
            const link = document.createElement("a")
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob)
                link.setAttribute("href", url)
                link.setAttribute("download", exportedFilename)
                link.style.visibility = "hidden"
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }

        dispatch({
            type: "SEARCH_CSV_CREATED"
        })
    })
}

export function onEnterAdvancedSearch(store) {
    return onEnter({
        store,
        actionThunk: loadData,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}
