import React from "react"
import { Droppable } from "react-beautiful-dnd"


const DroppableWrapper = (props) => {
    const {
        children,
        isDroppable,
        droppableId,
        columnClasses,
        ...restProps
    } = props

    return (
        <>
            {
                isDroppable &&
                <Droppable
                    droppableId={droppableId}
                    {...restProps}
                >
                    {
                        (provided) => {
                            return (
                                <div
                                    key={droppableId}
                                    ref={provided.innerRef}
                                    className={columnClasses}
                                >
                                    {children}
                                    {provided.placeholder}
                                </div>
                            )
                        }
                    }
                </Droppable>
            }
            {
                !isDroppable &&
                <div
                    key={droppableId}
                    className={columnClasses}
                >
                    {children}
                </div>
            }
        </>
    )
}

export default DroppableWrapper