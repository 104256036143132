import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import ReceptionWarehouseTable from "./ReceptionWarehouseTable"

const useClasses = makeStyles(() => ({
	container: {
		width: "100%"
	}
}))

const ReceptionWarehouseToDo = (props) => {
	const {
		modalRef,
		receptionWarehouseDataInProgress,
		productTypeOptions
	} = props
	
	const classes = useClasses()
	const storage = JSON.parse(localStorage.getItem("searchValue"))
	const searchFilter = (storage && storage.receptionWarehouseDataInProgress)?storage.receptionWarehouseDataInProgress:""
	
	return (
		<div className={classes.container}>
			<ReceptionWarehouseTable
				tableType="in_progress"
				receptionWarehouseData={receptionWarehouseDataInProgress}
				searchFilter={searchFilter}
				storageIndex="receptionWarehouseInProgress"
				modalRef={modalRef}
				productTypeOptions={productTypeOptions}
			/>
		</div>
	)
}

export default connect((state) => {
	return {
		date: state.receptionWarehouse.date,
		productTypeOptions: state.receptionWarehouse.productTypeOptions
	}
}, {
})(ReceptionWarehouseToDo)
