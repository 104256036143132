import React from "react"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import {nutrionalValues} from "../../utils/nutritionalValues"

const NutritionalValues = (props) => {
    const { classes, nutritionInformation } = props
    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                Valeurs nutritionnelles
            </div>
            {
                nutrionalValues.map((elem, index) =>
                    <div key={index} className={elem.space ? classes.spaceValueContainer : classes.valueContainer}>
                        {
                            elem.indent &&
                            <div className={classes.nutritionLabel}>
                                &nbsp;&nbsp;{elem.nutritionLabel}
                            </div>
                        }
                        {
                            !elem.indent &&
                                <div className={classes.nutritionLabel}>
                                    {elem.nutritionLabel}
                                </div>
                        }
                        {
                            elem.nutritionValueKey &&
                                <div className={classes.nutritionValue}>
                                    {
                                        !elem.nutritionSecondValueKey
                                        ?
                                            nutritionInformation[elem.nutritionValueKey]
                                                ? <span>{nutritionInformation[elem.nutritionValueKey]}{elem.unity}</span>
                                                : <span>-</span>
                                        :
                                            nutritionInformation[elem.nutritionValueKey] && nutritionInformation[elem.nutritionValueKey][elem.nutritionSecondValueKey]
                                                ? <span>{nutritionInformation[elem.nutritionValueKey][elem.nutritionSecondValueKey]}{elem.unity}</span>
                                                : <span>-</span>
                                    }
                                </div>
                        }
                    </div>
                )
            }
        </Paper>
    )
}

const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },
    titleContainer: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 16
    },
    valueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 4px"
    },
    spaceValueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 15px"
    },
    nutritionLabel: {
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "130%"

    },
    nutritionValue: {
        fontSize: 14,
        lineHeight: "130%",
        fontWeight: "bold",
    }
}

export default withStyles(styles)(NutritionalValues)
