import moment from "moment"
import { batch } from "react-redux"

import {
	actionWithLoader,
	getReplacingPath,
	haveAccessRight,
	onEnter,
	push
} from "../Utils/utils"
import {
	sortProductPackagingData
} from "../../utils/receptionWarehouse"
import {
	createOrLockProductPackagingLocker,
	getProductPackagingLocker,
	freeProductPackagingLocker
} from "../../parseManager/receptionWarehouse/parseProductPackagingLockerManager"
import {
	updateProductPackagingVolume,
	updateProductPackagingStatus,
	getProductPackaging,
	resetProductPackagingStatus
} from "../../parseManager/receptionWarehouse/parseProductPackagingManager"
import {
	getReceptionWarehouseItem
} from "../../reducers/Reception/receptionWarehouse"
import {
	updateLotGroupDate,
	updateLotGroupPackaging,
	loadLotGroupData,
	showLotGroupDetail
} from "../LotGroup/lotGroup"
import { loadSites } from "../Orders/Orders"
import {getProductTypeOptions} from "../ProductTypes/productTypes"
import {WITH_MANAGEMENT_MODE_FILTER} from "../../utils/productTypesUtils"

export const loadReceptionWarehouseFilterData = () => {
	return actionWithLoader(async (dispatch) => {
		await dispatch(loadSites())
	})
}

export function loadReceptionWarehouseData(ids = []) {
	return actionWithLoader(async (dispatch, getState) => {
		try {
			const state = getState()
			const packaging = state.receptionWarehouse.packaging
			const date = state.receptionWarehouse.date
			let data = await getProductPackaging(date, packaging)

			const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
			data = sortProductPackagingData(data, productTypeOptions)

			const selectedProductsPackaging = Array.isArray(ids) ? data.filter(el => ids.indexOf(el.objectId) !== -1) : []

			dispatch({
				type: "RECEPTION_WAREHOUSE_DATA_LOADED",
				data,
				packaging,
				date,
				selectedProductsPackaging,
				productTypeOptions
			})
		}
		catch (e) {
			dispatch({
				type: "RECEPTION_WAREHOUSE_OPEN_SNACKBAR",
				snackBar: {open: true, duration: 5000, type: "error", message: "Erreur en récupérant les données"}
			})
		}
	})
}

export function closeSingleReceptionWarehouseDetail(currentProductPackaging) {
	return actionWithLoader(async(dispatch) => {
		if (currentProductPackaging){
			await freeProductPackagingLocker(currentProductPackaging.objectId)

			if (null === currentProductPackaging.warehouseProduction && "TODO" !== currentProductPackaging.status) await updateProductPackagingStatus(currentProductPackaging.objectId, "TODO", false)

			dispatch(clearSelectedProductsPackaging())
			dispatch(loadReceptionWarehouseData())
			dispatch(showReceptionWarehouseInterface())
		}
	})
}

export function closeMultiReceptionWarehouseDetail(selectedProductsPackaging) {
	return actionWithLoader(async(dispatch) => {
		await Promise.all(selectedProductsPackaging.map(async productPackaging => {
			await freeProductPackagingLocker(productPackaging.objectId)

			if (null === productPackaging.warehouseProduction && "TODO" !== productPackaging.status) await updateProductPackagingStatus(productPackaging.objectId, "TODO", false)
		}))

		dispatch(clearSelectedProductsPackaging())
		dispatch(loadReceptionWarehouseData())
		dispatch(showReceptionWarehouseInterface())
	})
}

export function removeFromInProgress(productsPackagingIds) {
	return actionWithLoader(async(dispatch) => {
		await Promise.all(productsPackagingIds.map(async id => {
			await freeProductPackagingLocker(id)
			await resetProductPackagingStatus(id)
		}))

		dispatch(closeMultiReceptionWarehouseDetail([]))
	})
}

export function clearSelectedProductsPackaging() {
	return dispatch => {
		dispatch({
			type: "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING",
			selectedProductsPackaging: []
		})
	}
}

export function updateReceptionWarehousePackaging(packaging) {
	return dispatch => {
		dispatch({
			type: "RECEPTION_WAREHOUSE_PACKAGING_UPDATED",
			packaging
		})

		dispatch(loadReceptionWarehouseData())
	}
}

export const filterReceptionWarehouse = (values) => {
	return async (dispatch) => {
		await dispatch({
			type: "RECEPTION_WAREHOUSE_FILTERED_LOADED",
			date: values.packagingDate,
			packaging: values.packagingType,
			site: values.site,
		})
		dispatch(showReceptionWarehouseInterface())
	}
}

export function updateReceptionWarehouseDate(date) {
	return dispatch => {
		dispatch({
			type: "RECEPTION_WAREHOUSE_DATE_UPDATED",
			date
		})
	}
}

export function closeReceptionWarehouseSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		return dispatch({
			type: "DISPATCH_CLOSE_SNACKBAR",
			snackBar: {open: false, type: currentType, message: "", duration: 1000}
		})
	})
}

export function updateProductPackaging(productPackagingId, volume, redirect) {
	return actionWithLoader(async (dispatch, getState) => {
		try {
			const state = getState()

			await updateProductPackagingVolume(productPackagingId, volume)
			await freeProductPackagingLocker(productPackagingId)

			if (redirect){
				batch(async () => {
					await dispatch(updateLotGroupDate(state.receptionWarehouse.date))
					await dispatch(updateLotGroupPackaging(state.receptionWarehouse.packaging))
					await dispatch(loadLotGroupData(productPackagingId))
					dispatch(showLotGroupDetail(productPackagingId, state.receptionWarehouse.date))
				})

				return
			}

			if (state.receptionWarehouse.selectedProductsPackaging && state.receptionWarehouse.selectedProductsPackaging.length > 1){
				const newSelectProductsPackaging = state.receptionWarehouse.selectedProductsPackaging.filter(el => el.objectId !== productPackagingId)

				dispatch({
					type: "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING",
					selectedProductsPackaging: newSelectProductsPackaging
				})

				if (newSelectProductsPackaging.length === 0){
					batch(() => {
						dispatch(loadReceptionWarehouseData())
						dispatch(showReceptionWarehouseInterface())
					})
				}
			}
			else {
				batch(() => {
					dispatch(clearSelectedProductsPackaging())
					dispatch(loadReceptionWarehouseData())
					dispatch(showReceptionWarehouseInterface())
				})
			}
		}
		catch (e) {
			dispatch({
				type: "RECEPTION_WAREHOUSE_OPEN_SNACKBAR",
				snackBar: {open: true, duration: 5000, type: "error", message: "Erreur en sauvegardant le volume"}
			})
		}
	})
}


export function onEnterReceptionWarehouseFilter(store) {
	return onEnter({
		store,
		actionThunk: loadReceptionWarehouseFilterData,
		getReplacingPath: getReplacingPath({needUser: true}),
		haveAccessRight: haveAccessRight({canDispatch: true})
	})
}

export function onEnterReceptionWarehouse(store) {
	return onEnter({
		store,
		actionThunk: loadReceptionWarehouseData,
		getReplacingPath: getReplacingPath({needUser: true}),
		haveAccessRight: haveAccessRight({canDispatch: true})
	})
}

export function onEnterSingleReceptionWarehouseDetail(store) {
	return async (nextState, replace, callback) => {
		const {params} = nextState

		const productPackagingLocker = await getProductPackagingLocker(String(params.id))

		if (productPackagingLocker){
			store.dispatch(clearSelectedProductsPackaging())
			store.dispatch(showReceptionWarehouseInterface())

			callback()
			return
		}

		if (params){
			const state = store.getState()

			store.dispatch(clearSelectedProductsPackaging())

			if (moment.utc(state.receptionWarehouse.date).startOf("day").valueOf() !== moment.utc(params.date).startOf("day").valueOf()){
				store.dispatch(updateReceptionWarehouseDate(moment.utc(params.date).startOf("day").valueOf()))
			}

			const currentProductPackaging = getReceptionWarehouseItem(state, params.id) || null

			//Redirection on reception warehouse product list if selectedProductPackaging kfcStatus is DONE
			if (currentProductPackaging && currentProductPackaging.status === "DONE") {
				store.dispatch(clearSelectedProductsPackaging())
				store.dispatch(showReceptionWarehouseInterface())

				callback()
				return
			}
			else {
				if (currentProductPackaging && currentProductPackaging.status !== "IN_PROGRESS") {
					const newProductPackaging = await updateProductPackagingStatus(currentProductPackaging.objectId, "IN_PROGRESS")
					await createOrLockProductPackagingLocker(newProductPackaging.objectId)

					store.dispatch({
						type: "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING",
						selectedProductsPackaging: [newProductPackaging]
					})

					callback()
					return
				}
				else {
					if (!currentProductPackaging){
						store.dispatch(loadReceptionWarehouseData([params.id]))
						await createOrLockProductPackagingLocker(String(params.id))
					}
					else {
						await createOrLockProductPackagingLocker(currentProductPackaging.objectId)

						store.dispatch({
							type: "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING",
							selectedProductsPackaging: [currentProductPackaging]
						})
					}

					callback()
					return
				}
			}
		}
	}
}

export function onEnterMultipleReceptionWarehouseDetail(store) {
	return async (nextState, replace, callback) => {
		const {params} = nextState

		if (params){
			const state = store.getState()

			store.dispatch(clearSelectedProductsPackaging())

			if (moment.utc(state.receptionWarehouse.date).startOf("day").valueOf() !== moment.utc(params.date).startOf("day").valueOf()){
				store.dispatch(updateReceptionWarehouseDate(moment.utc(params.date).startOf("day").valueOf()))
			}

			const productsPackaging = await getProductPackaging(moment.utc(params.date).startOf("day").valueOf(), params.packagingType.toUpperCase())
			const filterProductsPackaging = productsPackaging.filter(el => el.status === "TODO")
			const finalSelectedProductsPackaging = []

			await Promise.all(filterProductsPackaging.map(async productPackaging => {
				const productPackagingLocker = await getProductPackagingLocker(productPackaging.objectId)

				if (!productPackagingLocker && productPackaging.status !== "DONE") {
					if (productPackaging.status !== "IN_PROGRESS"){
						const newProductPackaging = await updateProductPackagingStatus(productPackaging.objectId, "IN_PROGRESS")

						finalSelectedProductsPackaging.push(newProductPackaging)
					}
					else {
						finalSelectedProductsPackaging.push(productPackaging)
					}

					await createOrLockProductPackagingLocker(productPackaging.objectId)
				}
			}))

			store.dispatch({
				type: "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING",
				selectedProductsPackaging: finalSelectedProductsPackaging
			})

			callback()
			return
		}
	}
}

export const showReceptionWarehouseFilter = () => {
	return push("/receptionEntrepotFilter")
}

export function showReceptionWarehouseInterface() {
	return push("/receptionEntrepot")
}

export function showSingleReceptionWarehouseDetail(idProductPackaging, date) {
	return push(`/receptionEntrepot/single/${date}/${idProductPackaging}`)
}

export function showMultiReceptionWarehouseDetail(packagingType, date) {
	return push(`/receptionEntrepot/multi/${date}/${packagingType.toLowerCase()}`)
}

