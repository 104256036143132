import React from "react"
import Dialog from "@mui/material/Dialog"

const ModalWrapper = (props) => {
	const { children, open, handleClose } = props
	
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-input-card-number"
        >
            {children}
        </Dialog>
    )
}

export default ModalWrapper