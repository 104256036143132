import React, { PureComponent } from "react"
import { Field } from "redux-form"
import PropTypes from "prop-types"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText"

const CustomSelect = (props) => {
    const {
        input: {name, value, onChange, ...restInput},
        meta,
        label,
        formControlProps,
        onChange: customOnChange,
        multiple= false,
        ...rest
    } = props
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

    const _onChange = (event, value) => {
        customOnChange && customOnChange(event.target.value)
        onChange(event, value)
    }

    return (
        <FormControl {...formControlProps} error={showError}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Select
                {...rest}
                variant="standard"
                name={name}
                onChange={_onChange}
                inputProps={restInput}
                value={value}
                multiple={multiple}
            />

            {showError &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    )
}

class ReduxSelectField extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        onChange: PropTypes.func,
        multiple: PropTypes.bool
    }

    render() {
        const {children, ...otherProps} = this.props
        return (
            <Field
                {...otherProps}
                component={CustomSelect}
                subscription={{
                    value: true,
                    active: true,
                    error: true,
                    touched: true
                }}
            >
                {children}
            </Field>
        )
    }
}

export default ReduxSelectField
