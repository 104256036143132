import React from "react"

export const TagIcon = ({selected}) => {
    return (
            <img
                src={selected?"/img/TagBlack.svg":"/img/Tag.svg"}
                alt="Tag"
            />
    )
}

export default TagIcon