import dayjs from "dayjs"

const defaultState = {
  secondaryDLCsBySections: new Map(),
  lastSelectedSecondaryDLC: null,
  sites: [],
  selectedSite: null,
  selectedKitchenArea: null,
  lots: [],
  supplierItems: [],
  groupIngredients: [],
  productTypesOptions: [],
  currentDate: null,
  warningDate: null,
  secondaryDLCSnackbar: {open: false, message: "", type: "error", duration: 0}
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case "SECONDARY_DLCS_BY_SECTIONS_LOADED":
      return {
        ...state,
        secondaryDLCsBySections: action.secondaryDLCsBySections,
        currentDate: action.currentDate || state.currentDate,
        warningDate: action.warningDate || state.warningDate
      }
    case "LAST_SELECTED_SECONDARY_DLC_LOADED":
      return {
        ...state,
        lastSelectedSecondaryDLC: action.lastSelectedSecondaryDLC
      }
    case "SECONDARY_DLC_SITES_LOADED":
      return {
        ...state,
        sites: action.sites,
        selectedSite: action.selectedSite,
        selectedKitchenArea: action.selectedKitchenArea
      }
    case "SECONDARY_DLC_SITE_LOADED":
      return {
        ...state,
        selectedSite: action.site
      }
    case "SECONDARY_DLC_KITCHEN_AREA_LOADED":
      return {
        ...state,
        selectedKitchenArea: action.kitchenArea
      }
    case "SECONDARY_DLC_LOTS_LOADED":
      return {
        ...state,
        lots: action.lots,
       supplierItems: action.supplierItems
      }
    case "SECONDARY_DLC_GROUP_INGREDIENTS_LOADED":
      return {
        ...state,
        groupIngredients: action.groupIngredients
      }
    case "SECONDARY_DLC_PRODUCT_TYPES_LOADED":
      return {
        ...state,
        productTypesOptions: action.productTypesOptions
      }
    case "TOGGLE_SECONDARY_DLC_SNACKBAR":
      return {
        ...state,
        secondaryDLCSnackbar: action.snackbar
      }
    default:
      return state
  }
}

export const getSecondaryDLCsBySectionsSelector = state => state.secondaryDLC.secondaryDLCsBySections || new Map()

export const getLastSelectedSecondaryDLCSelector = state => state.secondaryDLC.lastSelectedSecondaryDLC || null

export const getSecondaryDLCSitesSelector = state => state.secondaryDLC.sites || []

export const getSecondaryDLCSiteSelector = state => state.secondaryDLC.selectedSite || null

export const getSecondaryDLCKitchenAreaSelector = state => state.secondaryDLC.selectedKitchenArea || null

export const getSecondaryDLCGroupIngredientsSelector = state => state.secondaryDLC.groupIngredients || []

export const getSecondaryDLCProductTypesOptionsSelector = state => state.secondaryDLC.productTypesOptions || []

export const getSecondaryDLCLotsSelector = state => state.secondaryDLC.lots || []

export const getSecondaryDLCSupplierItemsSelector = state => state.secondaryDLC.supplierItems || []

export const getSecondaryDLCDateSelector = state => state.secondaryDLC.currentDate || dayjs.utc().startOf("day").valueOf()

export const getSecondaryDLCWarningDateSelector = state => state.secondaryDLC.warningDate || dayjs.utc().startOf("day").valueOf()

export const getSecondaryDLCSnackbarSelector = state => state.secondaryDLC.secondaryDLCSnackbar || {open: false, message: "", type: "error", duration: 0}