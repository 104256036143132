import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import IngredientPageHeader from "../IngredientPageHeader"
import Header from "../Internal/Header"
import Paper from "@mui/material/Paper"
import { Tab, Tabs } from "@mui/material"
import { useDispatch } from "react-redux"

import { updateIngredientDetails, updateIngredientAllergens, updateIngredientSourcing } from "../../../actions/Ingredients/Internal/CommercialsNames"
import IngredientDetails from "../IngredientDetails"
import { getIngredient } from "../../../reducers/Ingredients/IngredientsPage"
import { extractSubcontractorProductsWithCommercialNameId, showExternalCommercialNames } from "../../../actions/Ingredients/External/SubcontractorsCommercialNames"

const styles = {
    container: {
        padding: 16
    },
    subContainer: {
    },
    closeButtonContainer: {
        height: 64,
        textAlign: "right"
    },
    closeButton: {
    },
    redirectButtonContainer: {
        height: 64,
        textAlign: "right"
    },
    redirectButton: {
    },
    labelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    valueContainer: {
        margin: 16
    },
    flexContainer: {
        display: "flex",
        flexWrap: "wrap"
    }
}

export const SubcontractorCommercialName = (props) => {
    const { classes } = props
    const ingredientType = "externe"
    const ingredient = useSelector(getIngredient)

    const tabValue = 0
    const dispatch = useDispatch()

    const onUpdateSubcontractorCommercialName = useCallback(async (key, values) => {
        // ALL UPDATE FUNCTION ARE READY TO BE USED
        switch (key) {
            case "SOURCING":
                return await dispatch(updateIngredientSourcing(ingredient.objectId, values, ingredientType))
            case "ALLERGENS":
                return await dispatch(updateIngredientAllergens(ingredient.objectId, values, ingredientType))
            case "RESUME":
                return await dispatch(updateIngredientDetails(ingredient.objectId, values, ingredientType))
            default:
                return
        }
    }, [ingredient])

    const onBack = () => {
        dispatch(showExternalCommercialNames())
    }

    const onExportIngredientUse = () => dispatch(extractSubcontractorProductsWithCommercialNameId(ingredient.objectId))

    const headerMenus = [
        { onClick: onExportIngredientUse, label: "Exporter les utilisations de l'ingrédient" },
    ]

    return (
        <div className={classes.main}>
            <IngredientPageHeader className={classes.headerHeight} onBack={onBack} withBackButton ingredientType={ingredientType} />
            <Header commercialName={ingredient} ingredientType={ingredientType} menus={headerMenus} />
            <Paper className={classes.header}>
                <Tabs
                    className={classes.tabs}
                    value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Général" />
                </Tabs>
            </Paper>
            <IngredientDetails ingredient={ingredient} onSave={onUpdateSubcontractorCommercialName} ingredientType={ingredientType} />
        </div>
    )
}

export default withStyles(styles)(SubcontractorCommercialName)
