import React, { useCallback, useState, useRef } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
    countPackagingCardProdVolume
} from "../../../utils/planning"
import {
    packagingCardOrder,
    packagingNature
} from "../../../utils/packaging"
import {
    COLORS
} from "../../../utils"
import { LunchbagIcon, LunchbagColors } from "../../../icons/LunchbagIcon"
import _cloneDeep from "lodash/cloneDeep"
import PrintIcon from "@mui/icons-material/Print"
import LabelIcon from "@mui/icons-material/Label"
import SplitIcon from "@mui/icons-material/CallSplit"
import PackagingPlanningLine from "../../../components/Planning/PackagingPlanningLine"
import debounce from "lodash/debounce"
import _isEqual from "lodash/isEqual"
import { usePrevious, useUpdate } from "react-use"

const useStyles = makeStyles(() => ({
    planningCardTopContainer: {
        display: "flex",
        backgroundColor: COLORS.MEDIUM_GREY,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
    },
    planningCardImg: {
        width: "auto",
        borderTopLeftRadius: 3
    },
    planningCardTitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "120%",
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        marginLeft: 13,
        marginTop: 5,
        marginBottom: 5,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    defaultPlanningCardImage: {
        height: 44,
        width: 44
    },
    planningCardInfos: {
        fontWeight: "normal",
        lineHeight: "130%",
        fontSize: 12,
        marginTop: 5,
        padding: "0px 7px 0px 7px",
        color: COLORS.DARK_GREY
    },
    planningStarIcon: {
        color: COLORS.DEFAULT_GREY,
        fontSize: 16,
        marginLeft: -2
    },
    planningCardAction: {
        bottom: 10,
        position: "absolute",
        display: "flex"
    },
    planningRateBloc: {
        fontSize: 12,
        color: COLORS.DARK_GREY
    },
    planningCardBrandBloc: {
        display: "flex",
        marginLeft: "auto"
    },
    planningBrand: {
        height: 7,
        width: 7,
        borderRadius: "50%",
        marginLeft: 2
    },
    hover: {
        position: "absolute",
        width: "100%",
        top: 20,
        border: `1px solid ${COLORS.DEFAULT_GREY}`,
        zIndex: "400",
        backgroundColor: COLORS.WHITE,
        fontSize: 12,
        fontWeight: 500,
        padding: 4
    },
    divider1: {
        height: 1,
        width: "100%",
        backgroundColor: COLORS.MEDIUM_GREY,
        marginBottom: 5
    },
    saleDateRowContainer: {
        marginTop: 5
    },
    productionActions: {
        textAlign: "left"
    },
    actionsRow: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 3
    },
    actionsIcons: {
        color: COLORS.DARK_GREY,
        cursor: "pointer",
        fontSize: 18
    },
    redActionsIcons: {
        composes: "$actionsIcons",
        color: COLORS.RED,
    },
    greenActionsIcons: {
        composes: "$actionsIcons",
        color: COLORS.GREEN_400
    },
    foodCostContainer: {
        fontSize: 12,
        padding: "7px 0px 6px 7px",
        backgroundColor: COLORS.LIGHT_GREY_2,
        color: COLORS.DARK_GREY,
        fontStyle: "italic"
    },
    saleDateSecondRowInfos: {
        display: "flex"
    },
    lunchbag: {
        "& span": {
            lineHeight: 0
        },
        "& img": {
            maxWidth: 20
        }
    },
    sellDateLabel: {
        fontSize: 12,
        color: COLORS.LIGHT_BLACK
    }
}))

const PackagingPlanningCard = (props) => {
    const {
        canPrint,
        card,
        openProductInfo,
        setAnyFocused,
        saveProd,
        printPackagingSingleCard,
        openSplitModal,
        openRecipeLabelModal,
        canPrintLabel,
        canInputVolume
    } = props

    const classes = useStyles()
    const [showName, setShowName] = useState(false)
    const [savedSet, setSavedSet] = useState(new Set())

    const previousCard = usePrevious(card)
    const stateCardRef = useRef()
    const rerender = useUpdate()

    if (!_isEqual(card, previousCard)) {
        card.packagingType.sort((a, b) => {
            const aPos = packagingCardOrder.indexOf(a.value)
            const bPos = packagingCardOrder.indexOf(b.value)

            if (aPos < bPos) return -1
            if (aPos > bPos) return 1
            return 0
        })

        stateCardRef.current = _cloneDeep(card)
    }

    const stateCard = stateCardRef.current

    const findItemExpectedProduction = useCallback((id) => {
        const currentExpectedProductions = stateCard.expectedProduction.filter(el => el.packagingId === id).map(el => el.value)

        return currentExpectedProductions ? currentExpectedProductions.reduce((a, b) => a + b, 0) : ""
    }, [])

    const updateProd = useCallback((event, id) => {
        let currentPackagingType = stateCardRef.current.packagingType.find(packaging => packaging.id === id)

        if (currentPackagingType) {
            event.target.dataset.saved = "loading"
            currentPackagingType.packagingProduction = parseInt(event.target.value)
            rerender()
        }

        debouncedSaveProd(event.target, id)
    }, [])

    const _saveProd = useCallback((elm, id) => {
        if (elm.dataset && elm.dataset.saved === "loading") {
            const value = elm.value === "" ? 0 : parseInt(elm.value)

            saveProd(value, id, card)
            setAnyFocused(false)
            elm.dataset.saved = "saved"
            setSavedSet(savedSet.add(id))
        }
    }, [])

    const debouncedSaveProd = useCallback(
        debounce(async (elm, productPackagingId) => {
            _saveProd(elm, productPackagingId)
        }, 2000
        ), []
    )

    const removeFromSaved = useCallback((id) => {
        savedSet.delete(id)
        setSavedSet(savedSet)
    }, [])

    const getTotalProd = () => {
        return countPackagingCardProdVolume(stateCard)
    }

    const print = () => {
        printPackagingSingleCard(stateCard)
    }

    const printLabel = () => {
        const internalTags = Array.isArray(stateCard.originalCards) && stateCard.originalCards.length > 0
            ? (stateCard.originalCards[0].productionItems[0] ? stateCard.originalCards[0].productionItems[0].internalTag : [])
            : []

        openRecipeLabelModal(
            stateCard.packagingDate,
            stateCard.dlc,
            stateCard.name,
            stateCard.itemId,
            stateCard.itemType,
            internalTags
        )
    }

    const showProductInfo = () => {
        openProductInfo(card)
    }

    return (
        <>
            {
                stateCard
                    ?
                    (
                        <>
                            <div className={classes.planningCardTopContainer}>
                                <>{stateCard?.uniqueCode || ""}</>
                                <div
                                    className={classes.planningCardTitle}
                                    onClick={showProductInfo}
                                >
                                    {stateCard.name}
                                </div>
                            </div>
                            <div className={classes.foodCostContainer}>
                                Foodcost : {stateCard.foodcost} €
                            </div>
                            <div className={classes.divider1} />
                            <div className={classes.planningCardInfos}>
                                <>
                                    {
                                        stateCard.packagingType.map((item, i) => {
                                            const dlcExist = undefined !== stateCard.dlc.find(b => b.packagingId === item.id)
                                            const packagingType = item.value ? packagingNature.find(el => el.value === item.value).label : ""

                                            return (
                                                <div key={i} className={classes.saleDateRowContainer}>
                                                    <PackagingPlanningLine
                                                        saved={savedSet.has(item.id)}
                                                        stateCard={stateCard}
                                                        item={item}
                                                        dlcExist={dlcExist}
                                                        packagingType={packagingType}
                                                        canEdit={canInputVolume}
                                                        findItemExpectedProduction={findItemExpectedProduction}
                                                        updateProd={updateProd}
                                                        saveProd={_saveProd}
                                                        removeFromSaved={removeFromSaved}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                <div className={classes.actionsRow}>
                                    <div className={classes.productionActions}>
                                        {
                                            canPrint &&
                                            <PrintIcon
                                                className={stateCard.packagingHasBeenPrinted
                                                    ? stateCard.printPackaging
                                                        ? classes.greenActionsIcons
                                                        : classes.redActionsIcons
                                                    : classes.actionsIcons}
                                                onClick={() => {
                                                    print()
                                                }}
                                            />
                                        }
                                        {

                                            canPrintLabel &&
                                            <LabelIcon
                                                className={classes.actionsIcons}
                                                onClick={() => {
                                                    printLabel(stateCard)
                                                }}
                                            />
                                        }
                                        <SplitIcon
                                            className={classes.actionsIcons}
                                            onClick={() => {
                                                openSplitModal(stateCard)
                                            }}
                                        />
                                    </div>
                                    {
                                        stateCard.lunchbag &&
                                        <div className={classes.lunchbag}>
                                            <LunchbagIcon color={LunchbagColors.BLACK} />
                                        </div>
                                    }
                                    {getTotalProd()}
                                </div>
                            </div>
                        </>
                    )
                    :
                    null
            }
            {
                showName &&
                <div className={classes.hover}
                    onMouseEnter={() => { setShowName(true) }}
                    onMouseLeave={() => { setShowName(false) }}
                >
                    {stateCard.name}
                </div>
            }
        </>
    )
}

export default PackagingPlanningCard
