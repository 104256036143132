import React, { useState } from "react"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    bottom: 33,
    left: 22,
    zIndex: 1,
    width: "auto",
    display: "flex",
  },
  button: {
    fontWeight: "bold",
    fontSize: 24,
    width: 56,
    height: 56,
    borderRadius: "50%",
    backgroundColor: COLORS.PRIMARY_COLOR,
    boxShadow: `0 0 13px 1px ${COLORS.LIGHT_GREY}`,
    "&:hover": {
      backgroundColor: COLORS.GREY_BORDER,
    },
  },
  hoverText: {
    paddingLeft: 10,
    fontSize: 16,
    lineHeight: "56px",
    color: COLORS.GREY_SEARCH,
  },
}))

const RoundedAddButton = (props) => {
  const { onClick, hoverText, styles, className } = props

  const classes = useStyles()

  const [isHover, setIsHover] = useState(false)

  return (
    <div style={styles} className={clsx(classes.root, className)}>
      <IconButton
        aria-label="Add"
        variant="contained"
        className={classes.button}
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        size="large"
      >
        <AddIcon htmlColor={COLORS.WHITE} />
      </IconButton>
      {hoverText && isHover && (
        <Typography variant="span" className={classes.hoverText}>
          {hoverText}
        </Typography>
      )}
    </div>
  )
}

export default RoundedAddButton
