import React from "react"
import CommentIcon from "@mui/icons-material/Comment"
import { Badge, styled } from "@mui/material"

const CustomBadge = styled(Badge, {
	shouldForwardProp: false
})(({ badgeContent }) => ({
	marginTop: "12px",
	cursor: "pointer",
	marginRight: !!badgeContent && 18,
	"& .MuiBadge-badge": {
		right: -8,
		padding: "0px 0px 0px 4px",
		top: 13,
		fontSize: 14,
		fontWeight: 400,
		lineHeight: "22px"
	},
}))

const CommentsIconBadge = ({ count, onToggle }) => {

	return (
		<CustomBadge
			badgeContent={count}
			onClick={onToggle}
		>
			<CommentIcon />
		</CustomBadge>
	)

}

export default CommentsIconBadge