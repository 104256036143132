import React from "react"
import { Formik, Form } from "formik"
import {
	sourcingSchema,
} from "../../utils/yupValidators"
import {
	FormControl,
	InputLabel,
	TextField,
	Select,
	MenuItem,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ModalForm from "../form/ModalForm"
import ModalActions from "../ModalActions"
import { COLORS } from "../../utils"
import {
	conservationModes,
	origins,
	labels
} from "../../utils/commercialNamesUtils"

const useStyles = makeStyles({
	formControl: {
		marginLeft: "unset",
		width: "100%",
		marginTop: 20,
		"& *": {
			fontSize: 16,
			color: COLORS.BLACK,
		},
		"& .MuiFormLabel-root": {
			position: "relative",
			height: 20,
			display: "flex",
			alignItems: "center",
			transform: "none",
			marginTop: 4,
		},
	},
	selectFormControl: {
		composes: "$formControl",
		marginTop: 16,
	},
	formControlDisabled: {
		opacity: 0.4,
		pointerEvents: "none",
	},
	errorField: {
		color: COLORS.RED,
		marginTop: 7,
	},
	textField: {
		marginTop: 7,
	},
})

const IngredientSourcingForm = (props) => {
	const {
		onClose,
		open,
		ingredient,
		onSave
	} = props
	const classes = useStyles()

	const submitForm = async (values) => {
		const newValues = { ...values }
		onSave("SOURCING", newValues)
		onClose()
	}

	return (
		<ModalForm
			onClose={onClose}
			open={open}
			title="Modifier le sourcing de l’ingrédient"
			width={500}
		>
			<Formik
				initialValues={{
					label: ingredient.label,
					conservationMode: ingredient.conservationMode,
					firstOrigin: ingredient.firstOrigin,
					firstOriginAverage: ingredient.firstOriginAverage || 0,
					secondOrigin: ingredient.secondOrigin,
					secondOriginAverage: 100 - (ingredient.firstOriginAverage || 0),
				}}
				validationSchema={sourcingSchema}
				onSubmit={submitForm}
			>
				{({ values, errors, handleChange, handleBlur, handleSubmit, touched }) => (
					<Form>
						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Label</InputLabel>
							<Select
								name="label"
								value={values.label}
								onChange={handleChange}
							>
								{labels.map((label) => (
									<MenuItem
										key={label.value}
										value={label.value}
									>
										{label.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Mode de conservation</InputLabel>
							<Select
								name="conservationMode"
								value={values.conservationMode}
								onChange={handleChange}
							>
								{conservationModes.map((conservationMode) => (
									<MenuItem
										key={conservationMode.value}
										value={conservationMode.value}
									>
										{conservationMode.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Provenance 1</InputLabel>
							<Select
								name="firstOrigin"
								value={values.firstOrigin}
								onChange={handleChange}
							>
								{origins.map((origin) => (
									<MenuItem
										key={origin.value}
										value={origin.value}
									>
										{origin.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Moyenne provenance 1</InputLabel>
							<TextField
								name="firstOriginAverage"
								type="number"
								value={values.firstOriginAverage}
								onChange={(e) => {
									let newValue = parseInt(e.target.value, 10)
									newValue = Math.min(newValue, 100)
									newValue = Math.max(0, newValue)
									handleChange({
										target: {
											name: "firstOriginAverage",
											value: newValue,
										},
									})
									handleChange({
										target: {
											name: "secondOriginAverage",
											value: 100 - newValue,
										},
									})
								}}
								onBlur={handleBlur}
								variant="standard"
								error={Boolean(errors.firstOriginAverage && touched.firstOriginAverage)}
								helperText={touched.firstOriginAverage && errors.firstOriginAverage}
							/>
						</FormControl>

						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Provenance 2</InputLabel>
							<Select
								name="secondOrigin"
								value={values.secondOrigin}
								onChange={handleChange}
							>
								{origins.map((origin) => (
									<MenuItem
										key={origin.value}
										value={origin.value}
									>
										{origin.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={classes.formControl} variant="standard">
							<InputLabel>Moyenne provenance 2</InputLabel>
							<TextField
								name="secondOriginAverage"
								type="number"
								value={values.secondOriginAverage}
								onChange={handleChange}
								onBlur={handleBlur}
								variant="standard"
								inputProps={
									{ readOnly: true, }
								}
							/>
						</FormControl>
						<ModalActions
							onSave={handleSubmit}
							onCancel={onClose}
						/>
					</Form>
				)}
			</Formik>

		</ModalForm>
	)
}

export default IngredientSourcingForm