import React, { Fragment, useState } from "react"
import IconButton from "@mui/material/IconButton"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Stack, TextField, styled } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"

import { COLORS } from "../../utils"
import { updateSupplierItemImage } from "../../actions/Supplier/supplierItems"
import { addRecipeFileExtension, getRecipeFileExtension } from "../../utils/recipes"

const sx = {
  textField: {
    paddingBottom: "0px",
    "& .MuiInputBase-input": {
      color: COLORS.DOWNLOAD_DOCUMENT_BLUE,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    }
  }
}

const StyledButton = styled("button")({
  backgroundColor: "#fff",
  border: "none",
  color: COLORS.DOWNLOAD_DOCUMENT_BLUE,
  fontSize: 16,
  fontWeight: 400,
  cursor: "pointer"
})

const StyledInputContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "error"
})(({ error, theme }) => ({
  borderBottom: "1px solid " + (error ? theme.palette.error.main : "#CCCCCC")
}))

const FileListDialog = ({ open, onClose, images = [], onDownload, supplierItemId }) => {
  const dispatch = useDispatch()
  const [selectedFile, setSelectedFile] = useState(null)
  const [error, setError] = useState("")

  const handleCloseDialog = () => {
    setSelectedFile(null)
    setError("")
    onClose()
  }

  const handleEditClick = (image) => {
    setSelectedFile({ ...image, extension: getRecipeFileExtension(image) })
  }

  const handleChangeName = (event) => {
    if (!selectedFile) return
    const value = event.target.value
    setSelectedFile(prev => ({ ...prev, label: value }))
    if (!value) {
      setError("Nom de fichier obligatoire")
    }
  }

  const handleConfirmInput = async () => {
    if (!selectedFile) return
    if (error) return
    // add original extension if there is no one
    const newSelectedFile = addRecipeFileExtension(selectedFile)

    await dispatch(updateSupplierItemImage(supplierItemId, newSelectedFile))
    setError("")
    setSelectedFile(null)
  }

  const handleClearInput = () => {
    setSelectedFile(null)
    setError("")
  }

  return (
    <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={open}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
    >
      <div className="flexRow center spaceBetween">
        <DialogTitle id="alert-dialog-title" className="flexRow justifyCenter flex1">
            Consulter un document
        </DialogTitle>
      </div>

        <DialogContent>
          <Stack spacing={2}>
            {images.map((image, index) => (
              <div key={image.publicId + index} className="flexRow center spaceBetween">
                {selectedFile?.publicId === image.publicId
                  ? (
                    <Stack spacing={1} className="flex1">
                      <StyledInputContainer className="flexRow center spaceBetween" error={!!error}>
                        {/* input */}
                        <TextField
                          fullWidth
                          onChange={handleChangeName}
                          value={selectedFile.label}
                          sx={sx.textField}
                          // helperText={error}
                          error={!!error}
                        />
                        {/* icons */}
                        <Stack direction="row" alignItems="center">
                          {/* confirm */}
                          <IconButton onClick={handleConfirmInput}>
                            <CheckIcon />
                          </IconButton>
                          {/* cancel */}
                          <IconButton onClick={handleClearInput}>
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      </StyledInputContainer>
                      {/* error */}
                      {error && (
                        <FormHelperText error>
                          {error}
                        </FormHelperText>
                      )}
                    </Stack>


                  ) : (
                    <Fragment>
                      <StyledButton onClick={() => onDownload(image)}>
                        {image.label}
                      </StyledButton>
                      <IconButton onClick={() => handleEditClick(image)}>
                        <EditIcon />
                      </IconButton>
                    </Fragment>
                  )
                }
              </div>
            ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 3.5 }}>
        <Button variant="contained" onClick={handleCloseDialog}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

export default FileListDialog
