import Parse from "parse"
import { parseLimitRequest } from "../../utils"

export const ProductionNeeds = Parse.Object.extend("ProductionNeeds")

export const getProductionNeedsByProductionDate = async ({
	productionDate,
	toJSON = true,
}) => {
	try {
		const productionNeeds = await new Parse.Query(ProductionNeeds)
			.equalTo("stepProductionDate", productionDate)
			.include(["supplierItem"])
			.limit(parseLimitRequest)
			.find()

		return toJSON ? productionNeeds.map(productionNeed => productionNeed.toJSON()) : productionNeeds
	} catch (e) {
		return Promise.reject(e)
	}
}

export async function updateProductionNeedProvidedWeight(productionDate, supplierItemId, quantity = 0, unitWeight = 1) {
	const supplierItemPointer = new Parse.Object("SupplierItems")
	supplierItemPointer.id = supplierItemId

	const productionNeedQuery = new Parse.Query("ProductionNeeds")
	productionNeedQuery.equalTo("supplierItem", supplierItemPointer)
	productionNeedQuery.equalTo("stepProductionDate", productionDate)
	const productionNeed = await productionNeedQuery.first()

	if (productionNeed) {
		const currentWeight = productionNeed.get("providedWeight") || 0
		productionNeed.set("providedWeight", currentWeight + quantity * unitWeight)
		const updatedProductionNeed = await productionNeed.save()

		return updatedProductionNeed
	} 
	return null
}