import React, { useEffect, useLayoutEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {
    useDispatch,
    useSelector
} from "react-redux"
import { Modal } from "@mui/material"
import Parse from "parse"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Snackbar from "@mui/material/Snackbar"

import {
    changeHubTab,
    closeDispatchSnackBar,
    downloadBL,
    downloadFridgePlano
} from "../../../actions/Dispatch/dispatch"
import DispatchError from "../DispatchError"
import DispatchDone from "../DispatchDone"
import {
    COLORS,
} from "../../../utils"
import DispatchInProgress from "../DispatchInProgress"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import DispatchBLModal from "../../../components/Dispatch/DispatchBLModal"
import DispatchActions from "../../../components/Dispatch/DispatchActions"
import HelpButton from "../../../components/HelpButton"
import helpLinks from "../../../utils/HelpLinks"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import DispatchHeaderTitle from "../DispatchHeaderTitle"

const useClasses = makeStyles({
    tab: {
        composes: "flex1",
        outline: "none"
    },
    dispatchModal: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll"
    },
    tabContainer: {
        display: "flex"
    },
    tabActions: {
        position: "absolute",
        right: 0
    },
    username: {
        color: COLORS.DARK_GREY
    },
    rightInfosBloc: {
        justifyContent: "flex-end",
        marginLeft: "auto",
        marginRight: 0,
        marginTop: 5
    },
    dispatchModalContainer: {
        outline: "none"
    },
})

const HubDispatch = () => {
    const date = useSelector(state => parseFloat(state.dispatch.date))
    const brand = useSelector(state => state.dispatch.brand)
    const dispatchSnackBar = useSelector(state => state.dispatch.dispatchSnackBar)
    const tabValue = useSelector(state => state.dispatch.hubTab)
    const computedQuantityLength = useSelector(state => state.dispatch.computedQuantityLength)
    const dispatch = useDispatch()
    const classes = useClasses()
    const user = Parse.User.current()
    
    const [openDispatchBLModal, setOpenDispatchBLModal] = useState(false)

    useLayoutEffect(() => {
        document.title = "Dispatch - KFC"
    }, [])

    useEffect(() => {
        let isMounted = true
        async function checkTab() {
            if (undefined === tabValue) await changeHubTab(0)
        }

        if (isMounted) checkTab()

        return () => (isMounted = false)
    }, [])

    const _onTabChange = (event, tabValue) => {
        dispatch(changeHubTab(tabValue))
    }

    return <>
        <Modal key="dispatch-modal" BackdropProps={{invisible: true}} className={classes.dispatchModal} open={true}>
            <div className={classes.dispatchModalContainer}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton />}
                    title={<DispatchHeaderTitle />}
                    className="stretchSelf"
                    rightAction={<span className={classes.username}>Picker : {user.get("username")}</span>}
                />
                <div className={classes.tabContainer}>
                    <div className={classes.tab}>
                        <Tabs
                            value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                            onChange={_onTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label={`À DISPACHER (${computedQuantityLength && computedQuantityLength[0]})`}/>
                            <Tab label={`EN ATTENTE (${computedQuantityLength && computedQuantityLength[1]})`}/>
                            <Tab label={`À VÉRIFIER (${computedQuantityLength && computedQuantityLength[2]})`}/>
                            <Tab label={`DISPATCHÉ (${computedQuantityLength && computedQuantityLength[3]})`}/>
                        </Tabs>
                        <div className={classes.divider}/>
                        {tabValue === 0 && <DispatchInProgress hubs={true}/>}
                        {tabValue === 1 && <DispatchDone hubs={true} current={true}/>}
                        {tabValue === 2 && <DispatchError  hubs={true}/>}
                        {tabValue === 3 && <DispatchDone  hubs={true}/>}
                    </div>
                    <div className={classes.tabActions}>
                        <DispatchActions
                            brand={brand}
                            onClickBL={() => {setOpenDispatchBLModal(true)}}
                            onClickFridge={() => {dispatch(downloadFridgePlano(date))}}
                        />
                    </div>
                </div>
            </div>
        </Modal>
        <DispatchBLModal
            open={openDispatchBLModal}
            onConfirm={(values) => {
                dispatch(downloadBL(values, brand))
                setOpenDispatchBLModal(false)
            }}
            onClose={() => {setOpenDispatchBLModal(false)}}
            date={date}
        />
        <Snackbar
            anchorOrigin={{
                vertical: "bottom", horizontal: "left"
            }}
            open={dispatchSnackBar.open}
            autoHideDuration={dispatchSnackBar.duration}
            onClose={() => {dispatch(closeDispatchSnackBar(dispatchSnackBar.type))}}
        >
            <MySnackbarContentWrapper
                onClose={() => {dispatch(closeDispatchSnackBar(dispatchSnackBar.type))}}
                variant={dispatchSnackBar.type}
                message={dispatchSnackBar.message}
            />
        </Snackbar>
        <HelpButton link={helpLinks.warehouse}/>
    </>
}

export default HubDispatch
