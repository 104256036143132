import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterAttachRatesForm, { distributionCenterAttachRatesFormId } from "./DistributionCenterAttachRatesForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"

const DistributionCenterAttachRatesFormContainer = ({ open, onClose, distributionCenter }) => {

	const dispatch = useDispatch()

	const onSubmitAttachRatesForm = (values) => {
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, values, "attachRates"))
		onClose && onClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiPaper-root": {
					width: 500
				}
			}}
		>
			<DialogTitle>
				Éditer les taux de rattachement
			</DialogTitle>
			<DialogContent>
				<DistributionCenterAttachRatesForm
					distributionCenter={distributionCenter}
					handleSubmit={onSubmitAttachRatesForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-around" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterAttachRatesFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DistributionCenterAttachRatesFormContainer