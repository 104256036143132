
import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import MySnackbarContentWrapper from "./MySnackbarContentWrapper"
import { makeStyles } from "@mui/styles"

const styles = makeStyles(() => ({
    snackbar: {
        maxWidth: "550px",
        width: "550px"
    }
}))

const CustomSnackbar = ({ duration, enableAutohide = true, snackbarItem }) => {

    const classes = styles()

    const elapsedTime = useRef(0)

    useEffect(() => {
        let interval = null
        if (enableAutohide && duration) {
            interval = setInterval(() => {
                elapsedTime.current += 100
                if (elapsedTime.current >= duration) {
                    snackbarItem.closeAction && snackbarItem.closeAction()
                    clearInterval(interval)
                }
            }, 100)
        }
        return () => interval && clearInterval(interval)
    }, [])

    return <MySnackbarContentWrapper
            className={classes.snackbar}
            variant={snackbarItem.type}
            message={snackbarItem.message}
            onClose={snackbarItem.closeAction} />
}

export default CustomSnackbar

CustomSnackbar.propTypes = {
    duration: PropTypes.number,
    enableAutohide: PropTypes.bool,
    snackbarItem: PropTypes.object.isRequired,
}