import React from "react"
import { sx } from "./PackagingExecutionPresentation"
import { Card, CardContent, Typography, Stack } from "@mui/material"

const PackagingExecutionPackagingsNature = ({ packaging }) => {

	return (
		<Card sx={sx.card}>
			<CardContent>
				<Stack spacing={4}>
					<Stack spacing={2}>
						<Typography variant="body" sx={sx.label}>
							Packaging
						</Typography>
						<Typography variant="h6" sx={sx.item}>
							{packaging?.packagingName || ""}
						</Typography>
					</Stack>
					<Stack spacing={3}>
						<Typography variant="body" sx={sx.label}>
							Sous-packaging
						</Typography>
						<Stack spacing={1}>
							{(packaging?.subPackagings || []).map((subPackaging, index) => (
								<Typography
									variant="h6"
									sx={sx.item}
									key={index}>
									{subPackaging.subPackagingName}
								</Typography>
							))}
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	)
}

export default PackagingExecutionPackagingsNature