import React, {} from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Typography, Button } from "@mui/material"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
    main: {
        backgroundColor: COLORS.WHITE,
        width: 438,
        outline: "none",
        margin: "0 auto",
        top: "40%",
        position: "relative",
        borderRadius: 1,
        padding: "30px 30px 20px 30px"
    },
    actionContainer: {
        marginTop: 20,
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    },
    firstButton: {
        marginRight: 10,
        color: "#2196F3"
    },
})

const ProductionReceptionWarningModal = ({
    handleClose,
    handleValidate,
    errorMessage = ""
}) => {
    const classes = useStyles()

    return (
        <div className={classes.main}>
            <Typography sx={{ color: "#7C7C7C", fontSize: 16, lineHeight: "137.5%"}}>
                {errorMessage}
            </Typography>
            <div className={classes.actionContainer}>
                <Button
                    className={classes.firstButton}
                    color="primary"
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    className={classes.firstButton}
                    color="primary"
                    onClick={handleValidate}
                >
                    Valider
                </Button>
            </div>
        </div>
    )
}

export default ProductionReceptionWarningModal