import React from "react"
import ProductionSchemasReadOrEdit from "./ProductionSchemaReadOrEdit"
import { useSelector } from "react-redux"

const ProductionSchemasForm = () => {

  const menuOpened = useSelector(state => state.app.menuIsOpen)

  return <ProductionSchemasReadOrEdit isEdition={true} isMenuOpened={menuOpened} />
}

export default ProductionSchemasForm