import { getData } from "../index"

const defaultState = {
    reportRules: null,
    planningSettingsSnackBar: {open: false, type: "", message: "", duration: 1000},
    distributionCenters: [],
    brand: "FOODCHERI",
    selectedDistributionCenter: null,
    siteList: []
}

export default function (state = defaultState, action) {
    switch(action && action.type) {
        case "PLANNING_SETTINGS_REPORT_RULES_LOADED":
            return {
                ...state,
                reportRules: action.reportRules,
            }
        case "PLANNING_SETTINGS_REPORT_RULES_UPDATED":
            return {
                ...state,
                planningSettingsSnackBar: action.planningSettingsSnackBar,
                reportRules: action.reportRules,
            }
        case "PLANNING_SETTINGS_DISTRIBUTION_CENTERS_LIST_LOADED":
            return {
                ...state,
                distributionCenters: action.distributionCenters
            }
        case "SINGLE_DISTRIBUTION_CENTER_LOADED":
            return {
                ...state,
                selectedDistributionCenter: action.selectedDistributionCenter
            }
        case "PLANNING_SETTINGS_SINGLE_DISTRIBUTION_CENTER_LOADED":
            return {
                ...state,
                selectedDistributionCenter: action.selectedDistributionCenter,
                siteList: action.siteList
            }
        case "PLANNING_SETTINGS_SNACKBAR":
            return {
                ...state,
                planningSettingsSnackBar: action.planningSettingsSnackBar
            }
        case "PLANNING_SETTINGS_DISTRIBUTION_CENTERS_UPDATE_BRAND":
            return {
                ...state,
                brand: action.brand
            }
        default:
            return {
                ...state
            }
    }
}

export function getSelectedDistributionCenter(state, errorIfNotFound = false) {
    return getData(state, "planningSettings.selectedDistributionCenter", errorIfNotFound && "No DistributionCenter selected")
}