import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import ReceptionWarehouseTable from "./ReceptionWarehouseTable"

const useClasses = makeStyles(() => ({
    container: {
        width: "100%"
    }
}))

const ReceptionWarehouseToDo = (props) => {
    const {
        modalRef,
        receptionWarehouseDataToDo,
        productTypeOptions
    } = props
    
    const classes = useClasses()
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const searchFilter = (storage && storage.receptionWarehouseDataToDo)?storage.receptionWarehouseDataToDo:""

    return (
        <div className={classes.container}>
            <ReceptionWarehouseTable
                tableType="todo"
                receptionWarehouseData={receptionWarehouseDataToDo}
                searchFilter={searchFilter}
                storageIndex="receptionWarehouseToDo"
                modalRef={modalRef}
                productTypeOptions={productTypeOptions}
            />
        </div>
    )
}

export default connect((state) => {
    return {
        date: state.receptionWarehouse.date,
        productTypeOptions: state.receptionWarehouse.productTypeOptions
    }
}, {
})(ReceptionWarehouseToDo)
