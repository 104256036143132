import React, { useState } from "react"
import { withStyles } from "@mui/styles"
import {
    TreeDataState,
    CustomTreeData,
} from "@devexpress/dx-react-grid"
import {
    Grid,
    Table,
    TableHeaderRow,
    TableTreeColumn
} from "@devexpress/dx-react-grid-material-ui"
import {
    SortingState,
    IntegratedSorting,
} from "@devexpress/dx-react-grid"
import CheckBox from "@mui/material/Checkbox"
import { defaultRolesColumns } from "../../utils/rights"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"

const styles = {
    container: {
        padding: 16
    },
    leftText: {
        whiteSpace: "initial"
    }
}

const TableCell = ({onChange, classes}) => (tableProps) => {
    if (tableProps && tableProps.row && tableProps.row.parentId &&
        tableProps.column && tableProps.column.name &&
        typeof tableProps.row[tableProps.column.name] !== "string") {
        return (
            <Table.Cell {...tableProps}>
                <CheckBox
                    onChange={() => onChange(tableProps.row.id, tableProps.column.name)}
                    checked={tableProps.row[tableProps.column.name]}
                    color="secondary"
                />
            </Table.Cell>
        )
    } else {
        return <Table.Cell className={`${tableProps.className} ${classes.leftText}`} {...tableProps} />
    }
}

const TableHeaderCell = ({onClick}) => (tableProps) => {
    if (defaultRolesColumns.find(elem => elem.name === tableProps.tableColumn.column.name)) {
        return <TableHeaderRow.Cell {...tableProps} />
    } else {
        return (
            <TableHeaderRow.Cell {...tableProps}>
                <span>
                    {tableProps.tableColumn.column.title}
                </span>
                <IconButton
                    aria-label="Supprimer"
                    onClick={() => onClick(tableProps.tableColumn.column.name)}
                    size="large">
                    <DeleteIcon/>
                </IconButton>
            </TableHeaderRow.Cell>
        )
    }
}

const getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(r => r.parentId === (row ? row.id : null))
    return childRows.length ? childRows : null
}

export const RolesTable = ({tableColumns, tableRows, onChange, onClickDeleteIcon, classes}) => {
    const [expandedRowIds, setExpandedRowIds] = useState([])
    const tableColumnExtensions = tableColumns.length > 0
        ? [
            { columnName: tableColumns[0].name, width: 200 },
            { columnName: tableColumns[1].name, width: 300 }
            ]
        : []

    return (
        <div className={classes.container}>
            <Grid
                rows={tableRows}
                columns={tableColumns}
            >
                <TreeDataState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={setExpandedRowIds}
                />

                <CustomTreeData
                    getChildRows={getChildRows}
                />
                <SortingState />
                <IntegratedSorting />
                <Table
                    columnExtensions={tableColumnExtensions}
                    cellComponent={TableCell({onChange: onChange, classes})}
                />
                <TableHeaderRow
                    showSortingControls
                    cellComponent={TableHeaderCell({onClick: onClickDeleteIcon})}
                />
                <TableTreeColumn
                    for={tableColumnExtensions.length > 0 ? tableColumnExtensions[0].columnName : "Inconnu"}
                />
            </Grid>
        </div>
    )
}

const styledRolesTable = withStyles(styles)(RolesTable)

export default styledRolesTable
