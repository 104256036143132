import React from "react"
import { Box, Stack, Typography } from "@mui/material"
import { COLORS } from "../utils"
import MoreMenu from "./MoreMenu"
import GenericBannerTable from "./GenericBannerTable"

const sx = {
	genericBannerContainer: {
		backgroundColor: COLORS.BLUEGREY_700,
		width: "100%"
	},
	bannerImage: {
		width: "250px",
		height: "160px",
		objectFit: "cover"
	}
}

const GenericBanner = ({
	title,
	subtitle,
	imageSrc,
	alt,
	tableDataRow,
	tableColumns,
	moreMenus,
	addImageAction
}) => {

	const _renderImageContent = () => {
		if (addImageAction) {
			return <h1>todo</h1>
		}
		return (
			<img
				src={imageSrc}
				alt={alt || "parseObject-img"}
				style={sx.bannerImage}
			/>
		)
	}

	return (
		<Box
			component="section"
			sx={sx.genericBannerContainer}>
			<Stack
				direction="row"
				alignItems="stretch"
				justifyContent="start"
				gap="5%"
				flexWrap="wrap"
				sx={{ paddingRight: "4px" }}
			>
				<Stack>
					{_renderImageContent()}
				</Stack>
				<Stack
					flex={1}
					justifyContent={"center"}
					gap={"16px"}>
					<Stack
						direction="row"
						justifyContent="space-between"
					>
						<Stack gap={"2px"}>
							<Typography
								variant="h4"
								color={"#FFFFFF"}
							>{title}
							</Typography>

							<Typography
								variant="line"
								color={"#FFFFFF"}
							>
								{subtitle}
							</Typography>
						</Stack>
						<MoreMenu
							menus={moreMenus}
							color={"#FFFFFF"}
						/>
					</Stack>
					<GenericBannerTable
						dataRow={tableDataRow}
						columns={tableColumns}
					/>
				</Stack>
			</Stack>
		</Box>
	)
}

export default GenericBanner