import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import PrintIcon from "@mui/icons-material/Print"
import Modal from "@mui/material/Modal"
import ProductionDetailEnhancedTableToolbar from "../../components/table-utils/Production/ProductionDetailEnhancedTableToolbar"
import ProductionDetailEnhancedTableHead from "../../components/table-utils/Production/ProductionDetailEnhancedTableHead"
import TablePagination from "../../components/TablePagination"
import { getProduction } from "../../reducers/Recipe/productions"
import ProductionVolumeModal from "../../components/ProductionVolumeModal"
import {
    clearSelectedProduction,
    showProductionList,
    deleteProductionDetail,
    editProductionDetailVolume,
    printProductionDetail
} from "../../actions/Recipe/productions"
import {
    asyncPrintLabel
} from "../../actions/LabelProduct/LabelProduct"
import {roundNumber, COLORS, setSearchValue} from "../../utils"
import LabelProductModal from "../../components/LabelProductModal"
import { productType } from "../../utils/dispatchUtils"
import { stableSort, getSorting } from "../../components/table-utils/utils"

const styles = {
    root: {
        width: "100%"
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20
    },
    column: {
        cursor: "pointer"
    },
    datePicker: {
        marginLeft: 25,
        width: 200
    },
    searchFieldInput: {
        width: 300
    },
    clearBtn: {
        marginTop: 10,
        marginLeft: 10
    },
    brandLogo: {
        width: 25
    },
    brandLogoLeft: {
        composes: "$brandLogo",
        marginRight: 5
    },
    printKitchen: {
        color: COLORS.PRIMARY_COLOR
    },
    printPackaging: {
        color: COLORS.SECONDARY_COLOR
    },
    printLabel: {
    }
}

class ProductionDetail extends PureComponent {
    constructor(props) {
        super(props)

        const { production } = this.props
        const storage = JSON.parse(localStorage.getItem("searchValue"))
        const storageIndex = `production${production.id}`

        this.state = {
            order: "asc",
            orderBy: "name",
            selected: [],
            page: 0,
            dataPage: 0,
            rowsPerPage: 10,
            filterProductionData: [],
            volumeChanged: false,
            openVolumeModal: false,
            currentData: null,
            searchFilter: (storage && storage[storageIndex])?storage[storageIndex]:null,
            storageIndex,
            data: [],
            showLabelModal: false,
            labelProduct: null,
            productType: null
        }
    }

    componentDidMount() {
        const { clearSelectedProduction } = this.props
        clearSelectedProduction()
        this.createDataObject()
    }

    componentDidUpdate(prevProps, prevState) {
        const { productionDetailLength, productionVersion } = this.props
        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.productionVersion !== productionVersion
            || prevProps.productionDetailLength !== productionDetailLength){
            this.createDataObject()
        }
    }

    createDataObject = () => {
        const { production } = this.props
        const { searchFilter } = this.state
        let productionDataFilter = []
        const productionData = production.get("data")

        if (searchFilter !== null && searchFilter !== ""){
            const regex = new RegExp(searchFilter, "ig")
            productionDataFilter = productionData.filter(data => {
                return (data.recipe && data.recipe.get("name").match(regex) !== null) ||
                (data.subcontractorProduct && data.subcontractorProduct.get("name") &&
                    data.subcontractorProduct.get("name").get("name").match(regex) !== null)
                    ? data
                    : null
            })
        }
        else {
            productionDataFilter = productionData
        }

        this.setState({filterProductionData: productionDataFilter, data: productionDataFilter.map(this.createData)})
    }

    createData = (productionData) => {
        const id = productionData.recipe ? productionData.recipe.id : productionData.subcontractorProduct.id
        const name = productionData.recipe ? productionData.recipe.get("name") : productionData.subcontractorProduct.get("name").get("name")
        const brand = productionData.recipe ? productionData.recipe.get("brand") : productionData.subcontractorProduct.get("brand")
        const type = productType.find(t => t.value === (productionData.recipe ? productionData.recipe.get("type") : productionData.subcontractorProduct.get("type")))
        let foodcost = 0
        if (productionData.recipe){
            foodcost = roundNumber(productionData.recipe.get("foodcost"), 2)
        }
        else {
            foodcost = roundNumber(productionData.subcontractorProduct.get("name").get("pricePerPortion"), 2)
        }

        return {
            id: id,
            name: name,
            brand: brand,
            type: type.label,
            foodCost: foodcost,
            volume: productionData.volume,
            isRecipe: productionData.recipe ? true : false
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterProductionData } = this.state
            this.setState({
                selected: filterProductionData.map(productionDetail => {
                    if (productionDetail.recipe){
                        return productionDetail.recipe.id
                    }
                    else {
                        return productionDetail.subcontractorProduct.id
                    }
                })
            })
            return
        }
        this.setState({selected: []})
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        this.setState({ selected: newSelected })
    }

    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    updateSelectedItems = () => {
        this.setState({selected: []})
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    _handleVolumeModal = () => {
        this.setState({openVolumeModal: !this.state.openVolumeModal})
    }

    updateSearchValue = (event) => {
        this.setState({ searchFilter: event ? event.target.value : null })
        setSearchValue(event.target.value, this.state.storageIndex)
    }

    _showVolumeEdition = (event, dataId) => {
        const { production } = this.props

        const currentData = production.get("data").filter(data => {
            return (data.recipe && data.recipe.id === dataId) || (data.subcontractorProduct && data.subcontractorProduct.id === dataId)
                ? data
                : null
        })

        this.setState({
            openVolumeModal: true,
            currentData: currentData[0]
        })
    }

    _editProductionDetailVolume = (productionId, data, volume) => {
        const { editProductionDetailVolume } = this.props
        this._handleVolumeModal()
        editProductionDetailVolume(productionId, data, volume)
    }

    _printKitchenPdf = (dataId) => {
        const { production, printProductionDetail } = this.props
        const currentData = production.get("data").filter(data => {
            return (data.recipe && data.recipe.id === dataId) || (data.subcontractorProduct && data.subcontractorProduct.id === dataId)
                ? data
                : null
        })
        if (currentData.length === 1) {
            printProductionDetail(production.get("date"), currentData[0])
        }
    }

    _showLabelModal = (product) => {
        const type = product.isRecipe ? "RECIPE" : "SUBCONTRACTORS_PRODUCT"
        this.setState({showLabelModal: true, labelProduct: product, productType: type})
    }

    _closeLabelModal = () => {
        this.setState({showLabelModal: false, labelProduct: null, productType: ""})
    }

    render() {
        const {
            classes, production, deleteProductionDetail,
            showProductionList, printProductionDetail,
            asyncPrintLabel
        } = this.props
        const {
            order, orderBy, selected, rowsPerPage, page,
            data, dataPage, filterProductionData,
            openVolumeModal, currentData, labelProduct,
            showLabelModal, productType,
        } = this.state

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

        return (
            <Paper className={classes.root} elevation={0}>
                <ProductionDetailEnhancedTableToolbar
                    numSelected={selected.length}
                    productionData={production.get("data")}
                    productionId={production.id}
                    date={production.get("date")}
                    deleteProductionDetail={deleteProductionDetail}
                    printProductionDetail={printProductionDetail}
                    closeProductionDetail={showProductionList}
                    onDeleteItem={this.updateSelectedItems}
                    selected={selected}
                />
                <div className={classes.searchFieldDiv}>
                    <TextField
                        variant="standard"
                        defaultValue={this.state.searchFilter}
                        className={classes.searchFieldInput}
                        onChange={this.updateSearchValue}
                        label="Rechercher une fiche de production"
                    />
                </div>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <ProductionDetailEnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={filterProductionData.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy))
                                .slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => this.handleClick(event, n.id)}>
                                                <Checkbox checked={isSelected} color="secondary" />
                                            </TableCell>
                                            <TableCell className={classes.column} padding="none" onClick={event => this._showVolumeEdition(event, n.id)}>{n.name}</TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showVolumeEdition(event, n.id)}>
                                                {n.brand === "FOODCHERI" ?(
                                                    <img className={classes.brandLogo} alt="logofc" src="/img/logofc.png" />
                                                ): (n.brand === "SEAZON" ? (
                                                    <img className={classes.brandLogo} alt="logosz" src="/img/logoszn.png" />
                                                ): <div><img className={classes.brandLogoLeft} alt="logofc" src="/img/logofc.png" /><img className={classes.brandLogo} alt="logosz" src="/img/logoszn.png" /></div>)}
                                            </TableCell>
                                            <TableCell className={classes.column} onClick={event => this._showVolumeEdition(event, n.id)}>{n.type}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showVolumeEdition(event, n.id)}>{n.foodCost}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={event => this._showVolumeEdition(event, n.id)}>{n.volume}</TableCell>
                                            <TableCell className={classes.column} align="right" onClick={() => this._showLabelModal(n)}>
                                                <Tooltip title="Imprimer Etiquette" disableInteractive>
                                                    <IconButton aria-label="Imprimer Etiquette" size="large">
                                                        <PrintIcon className={classes.printLabel} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.column} align="right" onClick={() => this._printKitchenPdf(n.id)}>
                                                {n.isRecipe &&
                                                    <Tooltip title="Imprimer fiches" disableInteractive>
                                                        <IconButton aria-label="Imprimer fiches" size="large">
                                                            <PrintIcon className={classes.printKitchen} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPage={rowsPerPage}
                    dataPage={dataPage}
                    data={filterProductionData}
                    handleChangeRowPerPage={this.handleChangeRowsPerPage}
                    decrementPage={this.decrementPage}
                    incrementPage={this.incrementPage}
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={openVolumeModal}
                    onClose={this._handleVolumeModal}
                >
                    <ProductionVolumeModal
                        productionId={production.id}
                        data={currentData}
                        editProductionDetailVolume={this._editProductionDetailVolume}
                    />
                </Modal>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={showLabelModal}
                    onClose={this._closeLabelModal}
                >
                    <LabelProductModal
                        closeModal={this._closeLabelModal}
                        name={labelProduct ? labelProduct.name : ""}
                        type={productType}
                        productId={labelProduct ? labelProduct.id: ""}
                        onSubmitPrint={asyncPrintLabel}
                    />
                </Modal>
            </Paper>
        )
    }
}

ProductionDetail.propTypes = {
    classes: PropTypes.object.isRequired,
}

ProductionDetail = withStyles(styles)(ProductionDetail)

export default connect((state, props) => {
    const { params: {id} } = props
    const production = id ? getProduction(state, id) : null
    return {
        production: state.productions.selectedProduction || production,
        productionDetailLength: state.productions.productionDetailLength,
        productionVersion: production.get("updatedAt")
    }
}, {
    showProductionList, deleteProductionDetail,
    clearSelectedProduction, editProductionDetailVolume,
    printProductionDetail, asyncPrintLabel,
})(ProductionDetail)
