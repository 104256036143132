import {
    actionWithLoader,
    getReplacingPath,
    haveAccessRight,
    onEnter,
    push
} from "../Utils/utils"
import { freeLocker } from "../../parseManager/dispatch/parseLockerManager"
import moment from "moment"
import {
    sortProductPackagingData
} from "../../utils/lotGroupUtils"
import {
    createOrLockProductPackagingLocker,
    getProductPackagingLocker,
    freeProductPackagingLocker
} from "../../parseManager/lotGroup/parseLockerManager"
import {
    getProductPackaging,
    updateProductPackagingVolume
} from "../../parseManager/lotGroup/parseProductPackagingManager"
import {
    getLotGroupItem
} from "../../reducers/Reception/lotGroup"
import { getServerUrl } from "../../utils"
import Parse from "parse"
import { axiosCall } from "../../utils/axiosUtils"
import cloneDeep from "lodash/cloneDeep"
import { loadSites } from "../Orders/Orders"
import {getProductTypeOptions} from "../ProductTypes/productTypes"
import {WITH_MANAGEMENT_MODE_FILTER} from "../../utils/productTypesUtils"

export const loadLotGroupData = (id = null) => {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            const state = getState()
            const packaging = state.lotGroup.packaging
            const date = state.lotGroup.date || moment.utc().startOf("day").valueOf()
            let productsPackaging = await getProductPackaging(date, packaging)
            productsPackaging.map(productPackaging => {
                productPackaging.productDispatches = productPackaging.productionItems.map(productItem => productItem.productDispatch)
                return productPackaging
            })

            const productTypeOptions = await getProductTypeOptions(WITH_MANAGEMENT_MODE_FILTER)
            productsPackaging = sortProductPackagingData(productsPackaging, productTypeOptions)
            const selectedProductPackaging = productsPackaging.find(el => el.objectId === id)

            dispatch({
                type: "LOT_GROUP_DATA_LOADED",
                data: productsPackaging,
                packaging,
                date,
                selectedProductPackaging,
                productTypeOptions
            })
        }
        catch (e) {
            dispatch({
                type: "LOT_GROUP_OPEN_SNACKBAR",
                snackBar: { open: true, duration: 5000, type: "error", message: "Erreur en récupérant les données" }
            })
        }
    })
}

export function closeLotGroupDetail(currentProductPackaging, currentProductDispatch) {
    return actionWithLoader(async(dispatch) => {
        if (currentProductPackaging) {
            await freeProductPackagingLocker(currentProductPackaging.objectId)
            if (currentProductDispatch) await freeLocker(currentProductDispatch.objectId)
            
            dispatch(clearSelectedProductsPackaging())
        }
        
        dispatch(showLotGroupInterface())
    })
}

export function clearSelectedProductsPackaging() {
    return dispatch => {
        dispatch({
            type: "LOT_GROUP_SELECTED_PRODUCTS_PACKAGING",
            selectedProductPackaging: null,
            selectedProductDispatch: null
        })
    }
}

export function updateLotGroupPackaging(packaging) {
    return dispatch => {
        dispatch({
            type: "LOT_GROUP_PACKAGING_UPDATED",
            packaging
        })
        
        dispatch(loadLotGroupData())
    }
}

export function updateLotGroupDate(date) {
    return dispatch => {
        dispatch({
            type: "LOT_GROUP_DATE_UPDATED",
            date
        })
    }
}

export function closeLotGroupSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        return dispatch({
            type: "LOT_GROUP_CLOSE_SNACKBAR",
            snackBar: {open: false, type: currentType, message: "", duration: 1000}
        })
    })
}

export function computeDispatch(selectedProductDispatch, prodWarehouse) {
    return actionWithLoader(async (dispatch) => {
        try {
            const url = `${getServerUrl()}/dispatch/compute`
            const data = {
                prodWarehouse: prodWarehouse,
                idProductDispatch: selectedProductDispatch.objectId,
                productName: selectedProductDispatch.commercialTitle,
                user: Parse.User.current() ? Parse.User.current().get("username") : null
            }
            const result = await axiosCall("POST", url, data, {"Content-Type": "application/json"})

            if (result.status === 200){
                const copy = cloneDeep(result.data)
                
                return dispatch({
                    type: "LOT_GROUP_SELECTED_PRODUCT_DISPATCH_UPDATED",
                    selectedProductDispatch: copy
                })
            }
            else {
                dispatch({
                    type: "LOT_GROUP_OPEN_SNACKBAR",
                    snackBar: {
                        open: true,
                        type: "error",
                        message: "Une erreur est survenue lors du calcul du dispatch",
                        duration: 5000
                    }
                })
                return dispatch({
                    type: "LOT_GROUP_SELECTED_PRODUCT_DISPATCH_UPDATED",
                    selectedProductDispatch: selectedProductDispatch
                })
            }
        }
        catch (err) {
            dispatch({
                type: "LOT_GROUP_OPEN_SNACKBAR",
                snackBar: {
                    open: true,
                    type: "error",
                    message: "Une erreur est survenue lors du calcul du dispatch",
                    duration: 5000
                }
            })
            return dispatch({
                type: "LOT_GROUP_SELECTED_PRODUCT_DISPATCH_UPDATED",
                selectedProductDispatch: selectedProductDispatch
            })
        }
    })
}

export const filterLotGroup = (values) => {
	return async (dispatch) => {
		await dispatch({
			type: "LOT_GROUP_FILTERED_LOADED",
			date: values.packagingDate,
			packaging: values.packagingType,
			site: values.site,
		})
		dispatch(showLotGroupInterface())
	}
}

export function updateProductPackaging(productPackagingId, volume) {
    return actionWithLoader(async (dispatch, getState) => {
        try {
            const newProductPackaging = await updateProductPackagingVolume(productPackagingId, volume)
    
            const state = getState()
            newProductPackaging.productDispatches = state.lotGroup.selectedProductPackaging.productDispatches
            newProductPackaging.allDone = state.lotGroup.selectedProductPackaging.allDone
            
            await freeProductPackagingLocker(productPackagingId)
            
            return dispatch({
                type: "LOT_GROUP_SELECTED_PRODUCT_PACKAGING_UPDATED",
                selectedProductPackaging: newProductPackaging
            })
        }
        catch (e) {
            dispatch({
                type: "LOT_GROUP_OPEN_SNACKBAR",
                snackBar: {open: true, duration: 5000, type: "error", message: "Erreur en sauvegardant le volume"}
            })
        }
    })
}

export const loadLotGroupFilterData = () => {
	return actionWithLoader(async (dispatch) => {
		await dispatch(loadSites())
	})
}


export function onEnterLotGroupFilter(store) {
	return onEnter({
		store,
		actionThunk: loadLotGroupFilterData,
		getReplacingPath: getReplacingPath({needUser: true}),
		haveAccessRight: haveAccessRight({canDispatch: true})
	})
}

export function onEnterLotGroup(store) {
    return onEnter({
        store,
        actionThunk: loadLotGroupData,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight({canReadProductsPackaging: true})
    })
}

export function onEnterLotGroupDetail(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState
        
        const productPackagingLocker = await getProductPackagingLocker(String(params.id))
        
        if (productPackagingLocker) {
            store.dispatch(clearSelectedProductsPackaging())
            store.dispatch(showLotGroupInterface())
            
            callback()
            return
        }
        
        if (params) {
            const state = store.getState()
            
            store.dispatch(clearSelectedProductsPackaging())
            
            if (params.date) {
                if (moment.utc(state.lotGroup.date).startOf("day").valueOf() !== moment.utc(params.date).startOf("day").valueOf()){
                    store.dispatch(updateLotGroupDate(moment.utc(+params.date).startOf("day").valueOf()))
                }
            }
            
            const currentProductPackaging = getLotGroupItem(state, params.id) || null
            
            //all dispatched
            if (currentProductPackaging && currentProductPackaging.allDone) {
                store.dispatch(clearSelectedProductsPackaging())
                store.dispatch(showLotGroupInterface())
                
                callback()
                return
            }
            else {
                if (currentProductPackaging && currentProductPackaging.warehouseProduction !== null) {
                    await createOrLockProductPackagingLocker(currentProductPackaging.objectId)
                    
                    store.dispatch({
                        type: "LOT_GROUP_SELECTED_PRODUCTS_PACKAGING",
                        selectedProductPackaging: currentProductPackaging,
                        selectedProductDispatch: null
                    })
                    
                    callback()
                    return
                }
                else {
                    if (!currentProductPackaging) {
                        store.dispatch(loadLotGroupData(params.id))
                        await createOrLockProductPackagingLocker(String(params.id))
                    }
                    else {
                        await createOrLockProductPackagingLocker(currentProductPackaging.objectId)
                        
                        store.dispatch({
                            type: "LOT_GROUP_SELECTED_PRODUCTS_PACKAGING",
                            selectedProductPackaging: currentProductPackaging,
                            selectedProductDispatch: null
                        })
                    }
                    
                    callback()
                    return
                }
            }
        }
    }
}

export const showLotGroupFilter = () => {
	return push("/lotGroupFilter")
}

export const showLotGroupInterface = () => {
    return push("/lotGroup")
}

export const showLotGroupDetail = (idProductPackaging, date) => {
    return push(`/lotGroup/${date}/${idProductPackaging}`)
}
