import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import moment from "moment"

import { COLORS, getTimezoneDate } from "../../utils"
import { CustomKeyboardDatePicker } from "../CustomKeyboardDatePicker"
import { confirm } from "../react-await-dialog/react-await-dialog"

const styles = {
    prevCreationModalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 600,
        outline: "none",
        margin: "0 auto",
        top: "25%",
        position: "relative",
        borderRadius: 3,
        color: COLORS.BLACK,
        padding: 10
    },
    closeContainer: {
        textAlign: "right"
    },
    closeIcon: {
        cursor: "pointer"
    },
    title: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "Bold"
    },
    prevCreationModalInfos: {
        margin: "30px 20px 0px 20px",
        display: "flex"
    },
    actionContainer: {
        textAlign: "center"
    },
    cancelBtn: {
        marginRight: 20,
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.DARK_GREY,
        backgroundColor: COLORS.MEDIUM_GREY
    },
    confirmBtn: {
        marginBottom: 10,
        marginTop: 20,
        color: COLORS.WHITE
    },
    endDate: {
        marginLeft: "auto"
    }
}

export const PlanningPrevCreationModal = (props) => {

    const {
        classes,
        closeModal,
        brand,
        createPlanningPrevCard,
        saveStartDate
    } = props

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const minEndDate = moment().utc(startDate)

    const handleConfirm = async () => {
        if (await confirm({
            title: "Confirm",
            message: `Vous allez générer des cartes du ${getTimezoneDate(startDate, "moment").format("DD/MM/YYYY")} au ${getTimezoneDate(endDate, "moment").format("DD/MM/YYYY")}, si des cartes étaient déjà présentes, cela va les écraser, êtes vous sûrs ?`,
            confirmText: "Oui",
            cancelText: "Non"
        })) {
            createPlanningPrevCard(saveStartDate, startDate, endDate, brand)
            closeModal()
        }
    }

    const handleChangeDate = (value, type = "start") => {
        if (type === "start"){
            setStartDate(getTimezoneDate(value))
            return
        }
        setEndDate(getTimezoneDate(value))
    }

    return (
        <Modal open={true}>
            <div className={classes.prevCreationModalContainer}>
                <div className={classes.closeContainer}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={closeModal}
                    />
                </div>
                <div className={classes.title}>
                    Création des cartes de prévisions pour { brand.name }
                </div>
                <div className={classes.prevCreationModalInfos}>
                    <CustomKeyboardDatePicker
                        value={startDate}
                        onChange={(e) => handleChangeDate(e, "start")}
                        variant="inline"
                        label="Date de début"
                        placeholder="Date de début"
                        format="DD/MM/YYYY"
                        disabled={false}
                    />
                    <CustomKeyboardDatePicker
                        value={endDate}
                        onChange={(e) => handleChangeDate(e, "end")}
                        variant="inline"
                        label="Date de fin"
                        placeholder="Date de fin"
                        format="DD/MM/YYYY"
                        disabled={!startDate}
                        minDate={minEndDate}
                        className={classes.endDate}
                    />
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={closeModal}
                    >
                        ANNULER
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.confirmBtn}
                        onClick={handleConfirm}
                        disabled={startDate === null || endDate === null}
                    >
                        VALIDER
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default withStyles(styles)(PlanningPrevCreationModal)
