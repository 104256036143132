import React from "react"
import { Chip, Stack } from "@mui/material"

const IntervalCalendarOptions = ({ options = [], selectedOption = null, onSelect }) => {

  return (
    <Stack
      direction="column"
      spacing={2}
      alignSelf={"start"}
      alignItems="center">
      {options.map((option, index) => (
        <Chip
          key={index}
          label={option.label}
          onClick={() => onSelect && onSelect(option)}
          color={option === selectedOption ? "primary" : "default"}
          sx={{
              fontSize: 14,
              padding: "8px 16px"
          }}
        />
      ))}
    </Stack>
  )

}

export default IntervalCalendarOptions