import React from "react"
import Dialog from "@mui/material/Dialog"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import Button from "@mui/material/Button"
import CheckboxAction from "./CheckboxAction"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import moment from "moment"

const useStyles = makeStyles({
	dialog: {
		"& .MuiPaper-rounded": {
			borderRadius: 15
		}
	},
	tableWrapper: {
		overflowX: "auto",
		display: "flex",
		flexFlow: "column",
		alignItems: "center"
	},
	idColumn: {
		color: COLORS.BLACK,
		cursor: "pointer",
		fontSize: 24,
		lineHeight: "100%",
		paddingRight: 10,
		paddingLeft: 15,
		textAlign: "center"
	},
	nameColumn: {
		fontSize: 16,
		color: COLORS.DARK_GREY,
		letterSpacing: 0.25,
		lineHeight: "100%",
		display: "flex",
		marginTop: 15,
		paddingBottom: 28
	},
	dlcColumn: {
		fontSize: 16,
		color: COLORS.DARK_GREY,
		letterSpacing: 0.25,
		lineHeight: "100%"
	},
	centered: {
		textAlign: "center"
	},
	tableMenu: {
		paddingLeft: 20,
		backgroundColor: COLORS.PRIMARY_COLOR,
		color: COLORS.TEXT_COLOR_FOR_PRIMARY
	},
	button: {
		margin: "25px 25px"
	},
	isReusable: {
		background: "url(/img/recycle.svg) no-repeat center ",
		width: 10,
		height: 10,
		marginRight: 7,
		marginTop: 3
	}
})

const ListToShowTable = (props) => {
	const classes = useStyles()
	const {
		open,
		onClose,
		data,
		productSelected,
		onAddProduct,
		validateDisplay
	} = props

	const rows = [
		{ id: "uniqueCode", numeric: false, disablePadding: false, label: "CODE" },
		{ id: "name", numeric: false, disablePadding: true, label: "NOM" },
		{ id: "dlc", numeric: false, disablePadding: true, label: "DLC" },
		{ id: "checked", numeric: false, disablePadding: false, label: "" }
	]

	return (

		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="dialog-list-to-show"
			className={classes.dialog}
		>
			<div className={classes.tableWrapper}>
				<Table aria-labelledby="tableTitle">
					<TableHead>
						<TableRow>
							{
								rows.map(row =>
									<TableCell
										key={row.id}
										align={row.numeric ? "right" : "left"}
										sortDirection={false}
										className={classes.tableMenu}
									>
										{row.label}
									</TableCell>
								)
							}
						</TableRow>
					</TableHead>
					<TableBody>
						{
							data.map(productStock => {
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={false}
										tabIndex={-1}
										key={productStock.objectId}
									>
										<TableCell className={classes.idColumn} >{productStock.uniqueCode}</TableCell>
										<TableCell className={classes.nameColumn}>
											{ productStock.isReusable &&
												<div className={classes.isReusable}></div>
											}
											{productStock.commercialTitle}
										</TableCell>
										<TableCell className={classes.dlcColumn}>{moment.utc(productStock.dlc).format("DD/MM")}</TableCell>
										<TableCell className={classes.centered}>
											<CheckboxAction
												color="primary"
												checked={productSelected.includes(productStock.objectId)}
												canCheck={true}
												onCheck={onAddProduct}
												element={productStock}
											/>
										</TableCell>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>

				<Button
					variant="contained"
					color="primary"
					disableElevation
					className={classes.button}
					onClick={validateDisplay}
				>
					Ajouter
				</Button>
			</div>
		</Dialog>
	)
}

export default ListToShowTable