import moment from "moment"
import isEqual from "lodash/isEqual"
import {
	downloadFile,
	actionWithLoader,
	getReplacingPath,
	haveAccessRight,
	onEnter,
	push
} from "../Utils/utils"
import {
	rightReadPlanningDashboard
} from "../../utils/accessRights"
import {
	KFC_BRANDS,
	getServerUrl,
} from "../../utils"
import { axiosCall } from "../../utils/axiosUtils"
import {
	getProductionItems,
	getLockers,
	unlockLocker,
	getDistributionCentersByBrand
} from "../../parseManager/planning/parsePlanningDashboardManager"
import { getRecipeById } from "../../parseManager/recipe/parseRecipeManager"
import { getSubcontractorProductById } from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import { findProductionItem } from "../../parseManager/recipe/parseProductionItemManager"
import { getProductionItemByProductDispatch } from "../../parseManager/recipe/parseProductionItemManager"
import { getProductTypes } from "../../parseManager/products/productTypeManager"
import { MANAGEMENT_MODES_ENUM } from "../../utils/productTypesUtils"
import { loadProductTypeOptions } from "../ProductTypes/productTypes"

export function onEnterPlanningDashboard(store) {
	return onEnter({
		store,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight(rightReadPlanningDashboard)
	})
}
export function onEnterPlanningDashboardMonitoring(store) {
	return onEnter({
		store,
		actionThunk: loadPlanningDashboardMonitoring,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight(rightReadPlanningDashboard)

	})
}

export function loadPlanningDashboardMonitoring(startDay = {}) {
	return actionWithLoader(async (dispatch) => {
		const startDate = isEqual(startDay, {}) ? moment.utc().add(1, "days").startOf("day").valueOf() : startDay

		const productTypesByBrand = {}
		for (const brand of KFC_BRANDS) {
			const productTypesFilter = { brands: [brand.name], managementMode: MANAGEMENT_MODES_ENUM.manual }
			const productTypes = await getProductTypes(productTypesFilter)
			productTypesByBrand[brand.name] = productTypes
		}

		const monitoringData = await loadMonitoringData(startDate, productTypesByBrand)

		dispatch({
			type: "DASHBOARD_MONITORING_LOADED",
			salesDatesData: monitoringData.salesDatesData,
			targetsData: monitoringData.targetsData,
			foodCostData: monitoringData.foodCostData,
			date: startDate,
			productTypesByBrand
		})
	})
}

export function onEnterPlanningDashboardKo(store) {
	return async (nextState, replace, callback) => {
		const { params } = nextState
		const state = store.getState()

		if (params && state) {
			const date = state.planningDashboard.date || moment.utc().add(1, "days").startOf("day").valueOf()
			const brand = params.brand || KFC_BRANDS[0].name
			const filtersForProductTypes = { brands: [brand] }
			await Promise.all([
				store.dispatch(loadPlanningDashboardKo(date, brand)),
				store.dispatch(loadProductTypeOptions(filtersForProductTypes)),
			])
		}
		callback()
	}
}

export function loadPlanningDashboardKo(startDay = {}, brand = KFC_BRANDS[0].name) {
	return actionWithLoader(async (dispatch) => {
		const startDate = isEqual(startDay, {}) ? moment.utc().add(1, "days").startOf("day").valueOf() : startDay
		const hubs = await getDistributionCentersByBrand(brand)
		const filteredHubs = hubs.filter(hub => hub.isActive)
		const koRows = await loadKitchenOutputData(startDate, brand)

		dispatch({
			type: "DASHBOARD_KO_LOADED",
			date: startDate,
			brand: brand,
			koRows: koRows,
			hubs: filteredHubs
		})
	})
}

export async function loadMonitoringData(startDate, productTypesByBrand) {
	const endDate = moment.utc(startDate).add(7, "day").startOf("day").valueOf()

	const productionItems = await getProductionItems(startDate, endDate)
	const targetsData = await axiosCall(
		"GET",
		`${getServerUrl()}/planningPrev/targetSums?startDate=${startDate}&endDate=${endDate}`,
		{},
		{ "Content-Type": "application/json" }
	)

	return {
		salesDatesData: await formatFoodCostAndSalesDataFormProductionItem(productionItems, startDate, productTypesByBrand, "sales"),
		targetsData: formatTargetsData(targetsData.data, productTypesByBrand),
		foodCostData: await formatFoodCostAndSalesDataFormProductionItem(productionItems, startDate, productTypesByBrand, "foodcosts")
	}
}



export async function loadKitchenOutputData(date, brand) {
	try {
		const url = `${getServerUrl()}/dispatch?date=${date}&brand=${brand}`
		const result = await axiosCall("GET", url, {}, { "Content-Type": "application/json" })

		if (result.status === 200 && Array.isArray(result.data.data)) {
			const lockers = await getLockers(result.data.data.map(el => el.objectId))

			for (const productDispatch of result.data.data) {
				const productionItem = await getProductionItemByProductDispatch(productDispatch.objectId)
				productDispatch.productionItem = productionItem
			}
			return formatKoData(result.data.data, lockers)
		}
		else {
			//TODO OPEN SNACKBAR ERROR
		}
	} catch (e) {
		return Promise.reject(e)
	}
}

export function exportKitchenOutputData(date, brand) {
	return actionWithLoader(async () => {
		const url = `${getServerUrl()}/dispatch/extract?date=${date}&brand=${brand}`
		const result = await axiosCall("GET", url, {}, { "Content-Type": "application/json" })

		if (result.status === 200) {
			const fileName = `Extrait_KO_du_${moment.utc(date).format("DD-MM-YYYY")}.csv`
			const csvURL = window.URL.createObjectURL(new Blob([result.data]))
			downloadFile(csvURL, fileName)
		}
	})
}

export function createDayCard(date, brand, values) {
	return actionWithLoader(async () => {
		const url = `${getServerUrl()}/dispatch/createDayCard?date=${date}&brand=${brand}&idHub=${values.hub}&types=${values.types}`
		downloadFile(url, "carte.pdf")
	})
}

export function computeDispatchDay(date, brand) {
	return actionWithLoader(async (dispatch) => {

		const data = {
			date: date,
			brand: brand
		}
		const url = `${getServerUrl()}/dispatch/computeDispatchDay`

		const result = await axiosCall("POST", url, data, { "Content-Type": "application/json" })

		dispatch(loadPlanningDashboardKo(date, brand))
		if (result.status !== 200) {
			//TODO open failed snackbar
		}
	})
}

export function updateKitchenOutputCell(row, cell, value, date, brand) {
	return actionWithLoader(async (dispatch) => {

		const data = {
			idHub: cell,
			volume: value
		}
		const url = `${getServerUrl()}/dispatch/${row.idProductDispatch}/updateHubVolume`

		const result = await axiosCall("PATCH", url, data, { "Content-Type": "application/json" })

		dispatch(loadPlanningDashboardKo(date, brand))
		if (result.status !== 200) {
			//TODO open failed snackbar
		}
	})
}

export function resetKitchenOutputRow(row, date, brand) {
	return actionWithLoader(async (dispatch) => {
		await unlockLocker(row.lockerId)

		const url = `${getServerUrl()}/dispatch/${row.idProductDispatch}/reset`
		const result = await axiosCall("PATCH", url, {}, { "Content-Type": "application/json" })

		dispatch(loadPlanningDashboardKo(date, brand))
		if (result.status !== 200) {
			//TODO open failed snackbar
		}
	})
}

export function unlockDispatchLocker(lockerId, date) {
	return actionWithLoader(async (dispatch) => {
		await unlockLocker(lockerId)
		dispatch(loadPlanningDashboardKo(date))
	})
}

export function findProduct(rowInfo) {
	return actionWithLoader(async (dispatch) => {
		let rightProduct, card

		card = await findProductionItem(rowInfo.saleDate, rowInfo.productId, rowInfo.brand)

		if (card) {
			dispatch({
				type: "PLANNING_RIGHT_PRODUCT_LOADED_KO1",
				rightSelectedCard: card
			})

			if (card.itemType === "Recipe") rightProduct = await getRecipeById(card.itemId, ["internalTag", "ingredients", "ingredients.supplierItem", "ingredients.supplierItem.commercialName"])
			if (card.itemType === "SubcontractorProduct") rightProduct = await getSubcontractorProductById(card.itemId, ["internalTag", "subcontractorCommercialNames", "subcontractorCommercialNames.commercialName"])

			dispatch({
				type: "PLANNING_RIGHT_PRODUCT_LOADED_KO2",
				rightProduct
			})
		}

	})
}

export function removeRightProduct() {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "PLANNING_RIGHT_PRODUCT_REMOVED_KO"
		})
	})
}

function formatKoData(data, lockers) {
	const output = []

	for (const i in data) {
		const currentDate = new Date(moment().valueOf())
		const currentYear = currentDate.getFullYear()
		const current = data[i]
		const locker = lockers.find(locker => locker.idProductDispatch === current.objectId)
		const row = {
			index: parseInt(i),
			idProductDispatch: current.objectId,
			uniqueCode: current.uniqueCode ? current.uniqueCode : "",
			product: current.commercialTitle ? current.commercialTitle : "",
			status: current.kfcStatus ? current.kfcStatus : "",
			predictedQty: current.prod || 0,
			dispatchQty: current.prodWarehouse || 0,
			nationalSend: current.nationalSend ? "Oui" : "Non",
			isReusable: current.isReusable ? "Oui" : "Non",
			smallRetail: current.smallRetail ? "Oui" : "Non",
			productType: current.productType ? current.productType : "",
			productId: current.idProduct ? current.idProduct : "",
			saleDate: current.saleDate ? current.saleDate : "",
			brand: current.brand ? current.brand : "",
			lockerId: locker ? locker.objectId : null,
			dispatchDate: current.kfcStatus === "DONE" &&
				current.dispatchDate ?
				currentYear !== new Date(current.dispatchDate).getFullYear() ?
					moment(current.dispatchDate).format("DD/MM/YYYY - HH:mm") :
					moment(current.dispatchDate).format("DD/MM - HH:mm") :
				null,
			dlc: current.productionItem ? currentYear !== new Date(current.productionItem.dlc).getFullYear() ?
				moment(current.productionItem.dlc).format("DD/MM/YYYY") :
				moment(current.productionItem.dlc).format("DD/MM") :
				null,
		}
		let summaryCol = 0
		for (const j in current.hubs) {
			const currentHub = current.hubs[j]
			const hubValue = currentHub.volume || 0

			if (undefined !== hubValue) {
				row[currentHub.idHub] = {
					...currentHub,
					value: hubValue
				}
				summaryCol += hubValue
			}
		}

		row["total"] = summaryCol

		output.push(row)
	}
	return output
}

const getFoodCostsAndSalesData = async (date, productionItems, productTypesByBrand, type) => {
	const fields = {
		date: date,
		dateLabel: moment.utc(date).format("dddd DD MMMM")
	}

	for (const brand of KFC_BRANDS) {
		const productTypes = productTypesByBrand[brand.name]
		if (productTypes) {
			for (const productType of productTypes) {
				const key = `${brand.name}_${productType.kfcName}`
				fields[key] = type === "sales" ? 0 : [0, 0]
			}
		}
	}

	for (const productionItem of productionItems) {
		const name = `${productionItem.brand}_${productionItem.productType}`
		let toAdd = productionItem.expectedProduction
		if (productionItem.productDispatch) {
			if (productionItem.productDispatch.prodWarehouse !== null) {
				toAdd = productionItem.productDispatch.prodWarehouse
			} else if (productionItem.productDispatch.prod !== null) {
				toAdd = productionItem.productDispatch.prod
			}
		}

		if (fields.hasOwnProperty(name)) {
			if (type === "sales") {
				fields[name] += toAdd
			} else {
				fields[name][0] += productionItem.foodcost * toAdd
				fields[name][1] += toAdd
			}
		}
	}

	return fields
}

const formatFoodCostAndSalesDataFormProductionItem = async (productionItems, startDate, productTypesByBrand, type) => {
	const items = []
	let i = 0

	while (i < 7) {
		const date = moment.utc(startDate).add(i, "days").startOf("day").valueOf()
		const productionItemsDays = productionItems.filter(productionItem => productionItem.saleDate === date)
		const obj = await getFoodCostsAndSalesData(date, productionItemsDays, productTypesByBrand, type)
		items.push(obj)
		i++
	}

	return items
}

const formatTargetsData = (data, productTypesByBrand) => {
	const newData = []
	for (const current of data) {
		const result = {
			date: current.date,
			dateLabel: moment.utc(current.date).format("dddd DD MMMM")
		}

		for (const brand of KFC_BRANDS) {
			const matchingBrand = current.targets.find(t => t.brand === brand.name)

			const productTypes = productTypesByBrand[brand.name]

			if (productTypes) {
				for (const productType of productTypes) {
					let value = 0

					if (matchingBrand) {
						// adapt to the existing type after product type refactoring
						[productType].forEach(type => {
							const matchingType = matchingBrand.targets.find(t => t.key === type.kfcName)

							if (matchingType && matchingType.value) {
								value += matchingType.value
							}
						})
					}

					result[`${brand.name}_${productType.kfcName}`] = value
				}
			}
		}

		newData.push(result)
	}

	return newData
}

export function showMonitoring() {
	return push("/planning/dashboard/monitoring")
}

export function showKo(brand) {
	return push(`/planning/dashboard/ko/${brand}`)
}
