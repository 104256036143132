import React from "react"

import { Box, Stack } from "@mui/material"
import { StyledStepDescriptionText, StyledStepText } from "../StyledSectionComponents"

const StepNameDescription = ({ name, description, stepNumber }) => {
  return (
    <Stack spacing={1}>
      <Box sx={{ height: 30 }} className="flexRow center">
        <Stack direction="row" spacing={1}>
          <StyledStepText>{stepNumber}.</StyledStepText>
          <StyledStepText>{name || "-"}</StyledStepText>
        </Stack>
      </Box>
      <StyledStepDescriptionText>{description}</StyledStepDescriptionText>
    </Stack>
  )
}

export default StepNameDescription
