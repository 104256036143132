import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles({
    info: {
        color: "#55555",
        fontSize: 16,
        paddingLeft: 5
    }
})

const OrderReceptionHeaderInfo = (props) => {
    const {
        info
    } = props

    const classes = useStyles()
    
    return (
        <Typography className={classes.info}>
            {info}
        </Typography>
    )
}

export default OrderReceptionHeaderInfo