import Parse from "parse"
import {getServerUrl, parseLimitRequest} from "../../utils"
import {axiosCall} from "../../utils/axiosUtils"
const Roles = Parse.Role

export async function getRoles(includes = [], toJson = true) {
    try {
        const roles = await new Parse.Query(Roles)
            .include(includes)
            .limit(parseLimitRequest)
            .find()

        return toJson
            ? roles.map(elem => elem.toJSON())
            : roles
    } catch(err) {
        console.error("parseManager.rights.parseRoles.getRoles : ", err)
        return Promise.reject(err)
    }
}
export async function getRoleById(id, includes = [], toJson = true) {
    try {
        const role = await new Parse.Query(Roles)
            .equalTo("objectId", id)
            .include(includes)
            .first()

        return toJson
            ? role
                ? role.toJSON()
                : null
            : role
    } catch(err) {
        console.error("parseManager.rights.getRoleById : ", err)
        return Promise.reject(err)
    }
}
export async function getRights() {
    try {
        const res = await axiosCall("GET", `${getServerUrl()}/rights`, null, {"Content-Type": "application/json"})
        const rights = res.status === 200 && res.data && Array.isArray(res.data.rights)
            ? res.data.rights
            : []
        return Promise.resolve(rights)
    } catch(err) {
        console.error("parseManager.rights.parseRoles.getRights : ", err)
        return Promise.reject(err)
    }
}

export async function createARole(id, name) {
    try {
        let role
        if (id) {
            role = await new Parse.Query(Roles)
                .equalTo("objectId", id)
                .first()
            role.set("name", name)
        } else {
            const roleACL = new Parse.ACL()
            roleACL.setPublicReadAccess(true)
            roleACL.setPublicWriteAccess(true)
            role = await new Roles(name, roleACL)
        }
        if (!role) {
            const msg = id
                ? "La modification du rôle a échoué"
                : "La création d'un rôle a échoué"
            return Promise.reject(msg)
        }
        await role.save()
    } catch(err) {
        console.error("parseManager.rights.parseRoles.createARole : ", err)
        return Promise.reject(err)
    }

}

export async function deleteRoleWithId(id) {
    try {
        const role = await new Parse.Query(Roles)
            .equalTo("objectId", id)
            .first()

        if (role) {
            let toUpdate = false
            const subRoles = await role.getRoles().query().find()
            if (Array.isArray(subRoles) && subRoles.length > 0) {
                role.getRoles().remove(subRoles)
                toUpdate = true
            }
            const subUsers = await role.getUsers().query().find()
            if (Array.isArray(subUsers) && subUsers.length > 0) {
                role.getUsers().remove(subUsers)
                toUpdate = true
            }
            if (toUpdate) {
                // To delete join collections
                await role.save()
            }
            await role.destroy()
        }
    } catch(err) {
        console.error("parseManager.rights.parseRoles.deleteRoleWithId : ", err)
        return Promise.reject(err)
    }
}

export async function removeParseUserToAParseRole(role, user) {
    await role.getUsers().remove(user)
    await role.save()
}

export async function addParseUserToAParseRole(role, user) {
    await role.getUsers().add(user)
    await role.save()
}

export async function updateRightOfAParseRole(role, rights) {
    role.set("rights", rights)
    await role.save()
}
