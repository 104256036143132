import React from "react"
import { Form, Formik } from "formik"
import { Button, Card, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import dayjs from "dayjs"
import { DatePicker } from "@mui/x-date-pickers"

import { packagingNature } from "../../utils/packaging"

const LotGroupFilterForm = ({ onSubmit, sites }) => {
    return (
        <Card sx={{ px: 5, py: 5, minWidth: 400 }} className="flexCenter">
            <Stack spacing={2} alignItems="center">
                <Typography variant="h6">
                    Sélectionnez le site, le jour de barquettage et le type de packaging
                </Typography>
                <Formik
                    initialValues={{
                        site: sites[0],
                        packagingDate: dayjs.utc().startOf("day").valueOf(),
                        packagingType: packagingNature[2].value,
                    }}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isValid,
                        setFieldValue,
                    }) => {
                    return (
                        <Form>
                            <Stack spacing={4} alignItems="center">
                                <Stack direction="row" spacing={3} justifyContent="center">
                                    {/* site */}
                                    <Stack spacing={1} alignItems="center">
                                        <InputLabel>Site de réception</InputLabel>
                                        <Select
                                            name="site"
                                            value={values.site}
                                            onChange={handleChange}
                                            variant="standard"
                                            sx={{ minWidth: 100 }}
                                        >
                                            {sites.map((site, index) => (
                                                <MenuItem
                                                    value={site}
                                                    key={index}
                                                >
                                                    {site.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.site && (
                                            <FormHelperText error sx={{ my: 1 }}>
                                                {errors.site}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    {/* date */}
                                    <Stack spacing={1} alignItems="center">
                                        <InputLabel>Jour de barquettage</InputLabel>
                                        <DatePicker
                                            showToolbar={false}
                                            value={values.packagingDate}
                                            name="packagingDate"
                                            inputFormat="DD/MM/YY"
                                            renderInput={(params) => <TextField {...params} variant="standard" sx={{ minWidth: 180 }} />}
                                            onChange={(date) => {
                                                setFieldValue("packagingDate", dayjs.utc(date).startOf("day").valueOf())
                                            }}
                                        />
                                        {errors.packagingDate && (
                                            <FormHelperText error sx={{ my: 1 }}>
                                                {errors.packagingDate}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                    {/* packaging type */}
                                    <Stack spacing={1} alignItems="center">
                                        <InputLabel>Type de packaging</InputLabel>
                                        <Select
                                            name="packagingType"
                                            value={values.packagingType}
                                            onChange={handleChange}
                                            variant="standard"
                                            sx={{ minWidth: 100 }}
                                        >
                                            {packagingNature.map((option, index) =>
                                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                            )}
                                        </Select>
                                        {errors.packagingType && (
                                            <FormHelperText error sx={{ my: 1 }}>
                                                {errors.packagingType}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Stack>
                                <div className="flexRow justifyCenter stretchSelf">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                        disabled={!isValid}
                                    >
                                        Accéder à l'allotissement
                                    </Button>
                                </div>
                            </Stack>
                        </Form>
                    )}}
                </Formik>
            </Stack>
        </Card>
    )
}

export default LotGroupFilterForm
