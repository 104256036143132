import React from "react"
import { Formik, Form, FieldArray } from "formik"
import { Stack } from "@mui/material"
import { FormControl, TextField, Select, InputLabel } from "@mui/material"
import { MenuItem } from "@mui/material"
import HoursMaskedField from "../../../components/form/MaskedField"
import dayjs from "dayjs"
import { PSE_REGEX_HOURS } from "../../../utils/productionStepExecution"
import { isEmpty } from "lodash"

export const productionStepExecutionCoolingFormId = "productionStepExecutionCoolingForm"

const ProductionStepExecutionCoolingForm = ({
  machinesBatch = [],
  machinesOptions = [],
  onSubmit,
  onError
}) => {

  const handleValidate = (values) => {
    const errors = {}
    const machinesBatchErrors = []
    const { machinesBatch } = values
    for (const machineBatch of machinesBatch) {
      const index = machinesBatch.indexOf(machineBatch)
      const machineBatchError = {}
      const { machineId, machineName, coolingStartTime, startTemperature } = machineBatch
      if (!machineId || typeof machineId !== "string") {
        machineBatchError.machineId = "Champ obligatoire"
      }
      if (!machineName || typeof machineName !== "string") {
        machineBatchError.machineName = "Champ obligatoire"
      }
      if (!coolingStartTime || PSE_REGEX_HOURS.test(coolingStartTime) === false) {
        machineBatchError.coolingStartTime = "Veuillez entrer une heure valide"
      }
      if (!startTemperature || typeof startTemperature !== "number") {
        machineBatchError.startTemperature = "Veuillez saisir la température à laquelle votre préparation a commencé son refroidissement."
      }
      if (!isEmpty(machineBatchError)) {
        machinesBatchErrors[index] = machineBatchError
      }
    }
    if (machinesBatchErrors.length > 0) {
      errors.machinesBatch = machinesBatchErrors
    }
    if (errors.machinesBatch && errors.machinesBatch.some((error) => !!error.startTemperature)) {
      onError && onError("Veuillez saisir la température à laquelle votre préparation a commencé son refroidissement.")
    }
    return errors
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        machinesBatch: machinesBatch.map((machine) => ({
          machineId: machine.machine?.objectId,
          machineName: machine.machineName,
          coolingStartTime: dayjs().format("HH:mm"),
          startTemperature: null
        }))
      }}
      validate={handleValidate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form id={productionStepExecutionCoolingFormId}>
            <FieldArray name="machinesBatch">
              {() => (
                <Stack>
                  {values.machinesBatch?.map((_, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="end"
                      justifyContent="space-between"
                      gap={3}
                      sx={{ mb: 3 }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="test-select-label" variant="standard">Machine</InputLabel>
                        <Select
                          labelId='test-select-label'
                          variant="standard"
                          value={values.machinesBatch[index].machineId}
                          name={`machinesBatch.${index}.machineId`}
                          onChange={(e) => {
                            const selectedMachine = machinesOptions.find(machine => machine.objectId === e.target.value)
                            setFieldValue(`machinesBatch.${index}.machineName`, selectedMachine.name)
                            setFieldValue(`machinesBatch.${index}.machineId`, e.target.value)
                          }}
                        >
                          {machinesOptions.map((machine) => (
                            <MenuItem key={machine.objectId} value={machine.objectId}>
                              {machine.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ flex: 1 }}>
                        <HoursMaskedField
                          name={`machinesBatch.${index}.coolingStartTime`}
                          value={values.machinesBatch[index].coolingStartTime}
                          onChange={handleChange}
                          label="Heure de début de refroidissement"
                          width="100%"
                          hasError={!!errors?.machinesBatch?.[index]?.coolingStartTime}
                        />
                      </FormControl>
                      <FormControl sx={{ flex: 1 }}>
                        <TextField
                          name={`machinesBatch.${index}.startTemperature`}
                          value={values.machinesBatch[index].startTemperature}
                          label="Température (°C)"
                          onChange={handleChange}
                          variant="standard"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          sx={{
                            "& .MuiInput-underline:before, .MuiInput-underline:after": {
                              borderBottomColor: errors?.machinesBatch?.[index]?.startTemperature && "#F44259"
                            }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  ))}
                </Stack>
              )}
            </FieldArray>
          </Form>
        )
      }
      }
    </Formik>
  )

}

export default ProductionStepExecutionCoolingForm