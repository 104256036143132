import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"
import clsx from "clsx"

import { creditNotesType } from "../../../utils/creditNoteUtils"
import CreditNoteQuantity from "./CreditNoteQuantity"
import CreditNoteComment from "./CreditNoteComment"
import CreditNoteAmount from "./CreditNoteAmount"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import OrderReceptionHeaderTitle from "../../../containers/Order/Reception/OrderReceptionHeaderTitle"
import OrderReceptionHeaderInfo from "../../../containers/Order/Reception/OrderReceptionHeaderInfo"
import CreditNoteReasons from "./CreditNoteReasons"
import SingleReceptionHeaderTitle from "../Reception/SingleReceptionHeaderTitle"
import ActionFooter from "../../common/ActionFooter"
import { Button, Stack, Typography, Box } from "@mui/material"
import { PEPSEResumeContainer } from "../../../containers/ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import { StyledItemValue, StyledItemLabel } from "../../../containers/ProductionStepExecutions/styledComponents"
import CommentsIconBadge from "../../Comments/CommentsIcon"
import OrderTitle from "../OrderTitle"
import OrderCardTitle from "../OrderCardTitle"
import CreditNoteImagesAndCommentForm from "./CreditNoteImagesAndCommentForm"

const styles = makeStyles({
	content: {
		margin: "24px 24px 0px 24px"
	},
	lotContent: {
		marginTop: 63
	},
	contentBloc: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingBottom: 105,
		gap: "24px"
	},
	lotAppbar: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1000
	},
	subContentBloc: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "0px 24px"
	},
	title: {
		color: "#262626",
		fontSize: 20
	},
	subtitle: {
		color: "#7C7C7C"
	},
	commentsIconContainer: {
		position: "absolute",
		width: "fit-content",
		right: 12,
		top: 12,
		color: "rgba(85 85 85)"
	}
})

const CreditNote = (props) => {
	const {
		data,
		saveCreditNote,
		errors,
		commentsCount = 0,
		creditNotesAmount = 0,
		creditNoteReedition = false,
		disableFooter = false
	} = props

	const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false)

	const handleClickComments = () => {
		setCommentsDrawerOpen(!commentsDrawerOpen)
	}

	const classes = styles()
	const renderFirstBloc = (type) => {
		switch (type) {
			case creditNotesType.LOT:
				return (
					<GenericTopBarHeader
						leftAction={<GenericHeaderReturnButton handleClick={data.header.goBack} />}
						title={<OrderReceptionHeaderTitle sx={{ fontSize: 16 }} />}
						info={<OrderReceptionHeaderInfo info={`/ Commande n°${data.lot?.lotNumber} / ${data.lot?.orderSupplierItem?.name}`} />}
						className={classes.lotAppbar}
					/>
				)
			case creditNotesType.DELIVERY:
				return (
					<GenericTopBarHeader
						leftAction={<GenericHeaderReturnButton handleClick={data.header.goBack} />}
						title={<SingleReceptionHeaderTitle headings={data.header.headings} />}
						rightAction={data.header.actions}
					/>
				)
			default:
				return
		}

	}

	const renderSecondBloc = (type) => {
		switch (type) {
			case creditNotesType.LOT:
				return null
			case creditNotesType.DELIVERY:
				return (
					<OrderCardTitle
						title="Avoir pour refus de toute la livraison"
					/>
				)
			default:
				return
		}
	}

	const renderFourthBlock = (type) => {
		switch (type) {
			case creditNotesType.LOT:
			case creditNotesType.DELIVERY:
				return (
					<CreditNoteReasons
						items={data.items}
						callback={data.callback}
						errorReason={errors?.reason}
						defaultReason={data.initialValues?.reason}
					/>
				)
			default:
				return
		}
	}

	const renderThirdBlock = (type) => {

		switch (type) {
			case creditNotesType.LOT:
				return (
					<CreditNoteQuantity
						maxQuantity={data.lot.quantity}
						isLotCreditNote={true}
						lot={data.lot}
						unit={data.unit}
						callback={data.callback}
						quantityError={errors?.quantity}
						creditNotesAmount={creditNotesAmount}
						stockPrice={data.lot?.orderSupplierItem?.units?.stock?.price}
						defaultQuantity={data.initialValues?.quantity}
					/>
				)
			case creditNotesType.DELIVERY:
				return (
					<CreditNoteComment
						imageName={data.imageName}
						callback={data.callback}
					/>
				)
			default:
				return
		}
	}

	const renderFifthBloc = (type) => {
		switch (type) {
			case creditNotesType.LOT:
				return null
			case creditNotesType.DELIVERY:
				return (
					<CreditNoteAmount
						amount={data.amount}
					/>
				)
			default:
				return
		}
	}

	return (
		<div>
			{renderFirstBloc(data.type)}
			<Stack
				gap={3}
				className={clsx(classes.content, { [classes.lotContent]: creditNotesType.LOT === data.type })}
			>
				<PEPSEResumeContainer
					sx={{
						position: "relative !important"
					}}>
					<Stack spacing={4}>
						<Stack spacing={2.2} direction="row" alignItems="center">
							<Typography variant="h5" className={classes.title}>
								{data.lot?.orderSupplierItem?.name}
							</Typography>
						</Stack>
						<Stack spacing={4} direction="row">
							<Stack spacing={1}>
								<StyledItemLabel>
									Lot
								</StyledItemLabel>
								<StyledItemValue>
									{data.lot?.lotNumber}
								</StyledItemValue>
							</Stack>
							<Stack spacing={1}>
								<StyledItemLabel>
									DLC
								</StyledItemLabel>
								<StyledItemValue>
									{dayjs(data.lot?.dlc).format("DD/MM/YYYY")}
								</StyledItemValue>
							</Stack>
						</Stack>
					</Stack>
					<Box className={classes.commentsIconContainer}><CommentsIconBadge onToggle={handleClickComments} count={commentsCount} /></Box>
				</PEPSEResumeContainer>
				<OrderTitle
					title="Déclaration de casse"
					subtitle="Saisissez la quantité et indiquez le motif de la déclaration de casse."
				/>
			</Stack>
			<div className={classes.contentBloc}>
				<div className={classes.subContentBloc}>
					{renderSecondBloc(data.type)}

				</div>
				<div className={classes.subContentBloc}>
					{renderThirdBlock(data.type)}
				</div>
				<div className={classes.subContentBloc}>
					{renderFourthBlock(data.type)}
				</div>
				<div className={classes.subContentBloc}>
					{renderFifthBloc(data.type)}
				</div>
			</div>
			<CreditNoteImagesAndCommentForm data={data} open={commentsDrawerOpen} onClose={() => setCommentsDrawerOpen(false)} />
			
			{creditNoteReedition && (
					<ActionFooter
						leftAction={
							<Button
								onClick={data.header.goBack}
								variant="outlined">
								Annuler
							</Button>
						}
						rightAction={
							<Button
								onClick={saveCreditNote}
								variant="contained">
								Enregistrer
							</Button>
						}
					/>
			)}
			{(!creditNoteReedition && !disableFooter) && (
				<ActionFooter
					rightAction={
						<Button
							onClick={saveCreditNote}
							variant="contained">
							Valider la casse
						</Button>
					}
				/>
			)}
		</div>
	)
}

export default CreditNote