import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterAddressForm, { distributionCenterAddressFormId } from "./DistributionCenterAddressForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"

const DistributionCenterAddressFormContainer = ({ open, onClose, distributionCenter }) => {

	const dispatch = useDispatch()

	const onSubmitAddressForm = (values) => {
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, values, "address"))
		onClose && onClose()
	}

	return (
		<Dialog
			sx={{
				"& .MuiPaper-root": {
					width: 500
				}
			}}
			open={open}
			onClose={onClose}>
			<DialogTitle>
				Éditer l'adresse
			</DialogTitle>
			<DialogContent>
				<DistributionCenterAddressForm
					distributionCenter={distributionCenter}
					onClose={onClose}
					handleSubmit={onSubmitAddressForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterAddressFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DistributionCenterAddressFormContainer