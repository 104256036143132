import React, {
    useState,
    useEffect,
    useCallback
} from "react"
import { Box, Fab, styled } from "@mui/material"
import { Add } from "@mui/icons-material"
import { useSelector, useDispatch } from "react-redux"
import Snackbar from "@mui/material/Snackbar"
import moment from "moment"
import _isEqual from "lodash/isEqual"

import {
    clearSelectedSite,
    closeSiteSnackBar, 
    createNewSite,
    showSiteTab,
} from "../../../../actions/Site/sites"
import MySnackbarContentWrapper from "../../../../components/MySnackbarContentWrapper"
import SiteFormDialog from "../../../../components/Site/SiteFormDialog"
import SitesAndHubsTabs from "../SitesAndHubsTabs"
import {
    CAN_UPDATE_SITE_STOCK_ZONE,
} from "../../../../utils/accessRights"
import DataGrid from "../../../../components/DataGrid/DataGrid"

const StyledFabContainer = styled("div")({
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
})

const SitesTab = () => {
    const sites = useSelector(state => state.sites.sites, _isEqual)
    const siteSnackBar = useSelector(state => state.sites.siteSnackBar, _isEqual)
    const canAdd = useSelector(state => state.app.rights[CAN_UPDATE_SITE_STOCK_ZONE])

    const [data, setData] = useState([])
    const [openFormDialog, setOpenFormDialog] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearSelectedSite())
        createDataObject()
    }, [])

    useEffect(() => {
        createDataObject()
    }, [sites])

    const createDataObject = useCallback(() => {
        setData(sites.map(createData))
    }, [sites])

    const createData = (site) => {
        return {
            id: site.objectId,
            name: site.name,
            deliveryAddress: site.deliveryAddress ?
                `${site.deliveryAddress.line1}${site.deliveryAddress.line2 ? ", " + site.deliveryAddress.line2 : "" }` :
                "",
            updatedAt: moment(site.updatedAt).format("DD/MM/YYYY")
        }
    }

    const goToSite = (row) => {
        dispatch(showSiteTab("general", row.id))
    }

    const toggleCreate = () => {
        setOpenFormDialog(!openFormDialog)
    }
    
    const handleCreateNewSite = useCallback(values => dispatch(createNewSite(values)), [])

    const handleCloseSiteSnackBar = useCallback(() => dispatch(closeSiteSnackBar(siteSnackBar.type)), [siteSnackBar])
    
    const columns = [
        {
            key: "id",
            label: "ID",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            },
            width: 120
        },
        {
            key: "name",
            label: "Nom",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            },
        },
        {
            key: "deliveryAddress",
            label: "Adresse de livraison",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: false,
                type: "text"
            },
        },
        {
            key: "updatedAt",
            label: "Date de modification",
            dataType: "date",
            type: "date",
            sortable: true,
            filter: {
                filterable: false,
                type: "date"
            },
            width: 130
        }
    ]

    return (
        <SitesAndHubsTabs tabValue="sites">
          <Box className="flexColumn" sx={{ position: "relative", top: 70 }}>
                <DataGrid
                    title="Sites"
                    columns={columns}
                    data={data}
                    withFilters
                    rowLabel="sites"
                    onRowClick={goToSite}
                />
                {canAdd && (
                    <StyledFabContainer>
                        <Fab color="primary" onClick={toggleCreate}>
                            <Add />
                        </Fab>
                    </StyledFabContainer>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={siteSnackBar.open}
                autoHideDuration={siteSnackBar.duration}
                onClose={handleCloseSiteSnackBar}
            >
                <MySnackbarContentWrapper
                    onClose={handleCloseSiteSnackBar}
                    variant={siteSnackBar.type}
                    message={siteSnackBar.message}
                />
            </Snackbar>
            {canAdd &&
                <SiteFormDialog
                    onClose={toggleCreate}
                    open={openFormDialog}
                    onSave={handleCreateNewSite}
                />
            }
        </SitesAndHubsTabs>
    )
}

export default SitesTab
