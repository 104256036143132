import React from "react"
import { Formik, Form } from "formik"
import { TextField, FormControl } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  BLInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flexWrap: "wrap"
  },
  field: {
    width: "100%",
    marginBottom: 10
  }
}))

export const distributionCenterDeliveryNoteConfigFormId = "distributionCenterDeliveryNoteConfigFormId"

const DistributionCenterDeliveryNoteConfigForm = ({ distributionCenter = {}, handleSubmit }) => {

  const classes = useStyles()

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        counterMark: distributionCenter.counterMark,
        supplierCode: distributionCenter.supplierCode,
        internalMail: distributionCenter.internalMail,
        externalMail: distributionCenter.externalMail
      }}
    >
      {({values, handleChange, handleBlur}) => (
        <Form
          id={distributionCenterDeliveryNoteConfigFormId}>
          <div className={classes.BLInfoContainer}>
            <div>
              <FormControl variant="standard" className={classes.field}>
                <TextField
                  variant="standard"
                  name="counterMark"
                  label="Contremarque"
                  value={values.counterMark}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" className={classes.field}>
                <TextField
                  variant="standard"
                  name="supplierCode"
                  label="Code fournisseur"
                  value={values.supplierCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" className={classes.field}>
                <TextField
                  variant="standard"
                  name="internalMail"
                  label="Mail interne"
                  value={values.internalMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="standard" className={classes.field}>
                <TextField
                  variant="standard"
                  name="externalMail"
                  label="Mail externe"
                  value={values.externalMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DistributionCenterDeliveryNoteConfigForm