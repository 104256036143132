import React, { useState } from "react"
import { Stack } from "@mui/material"

import { StyledCard, StyledCardLabel, StyledCardTitle, StyledCardTitleContainer } from "../../../../components/styled/StyledPreview"
import PackagingAttachmentFormDialog from "./forms/PackagingAttachmentFormDialog"
import NewEditIcon from "../../../../components/NewEditIcon"

const PackagingAttachment = ({ supplierItem, onSubmit }) => {
  const [openModalForm, setOpenModalForm] = useState(false)

  const toggleEditTransformationMode = () => setOpenModalForm(!openModalForm)

  const handleSubmitResume = async (values) => {
    await onSubmit(values)
    toggleEditTransformationMode()
  }

  return (
    <StyledCard>
      <Stack spacing={3}>
        <StyledCardTitleContainer>
          <StyledCardTitle>Rattachement</StyledCardTitle>
          <NewEditIcon onClick={toggleEditTransformationMode} />
        </StyledCardTitleContainer>
        <Stack spacing={2}>
          <Stack direction="row" spacing={3}>
            <StyledCardLabel>Article fournisseur associé</StyledCardLabel>
            <span>{supplierItem?.name}</span>
          </Stack>
        </Stack>
      </Stack>

      <PackagingAttachmentFormDialog
        open={openModalForm}
        onClose={toggleEditTransformationMode}
        supplierItem={supplierItem}
        onSave={handleSubmitResume}
      />
    </StyledCard>
  )
}

export default PackagingAttachment
