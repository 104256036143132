import React, { useEffect } from "react"
import { FieldArray } from "formik"
import { Table, TableHead, TableBody, TableRow } from "@mui/material"
import { PETableDivider, PETableHeaderCell, PETotalTableCell } from "../StyledPackagingExecutionPreviewComponents"
import PackagingExecutionSection from "./PackagingExecutionSection"
import { convertKilosIntoGrams, convertGramsIntoKilos } from "../../../utils/ressources"
import { roundNumber } from "../../../utils"

const PackagingExecutionSections = ({ sections = [], packagingForecastNumber, setFieldValue, setFieldTouched, editable = true, touchedSections = [] }) => {

	const _renderKiloToGrams = (value) => {
		return roundNumber(convertKilosIntoGrams(value), 0)
	}

	/**
	 * calculate section realWeight (poids barquetté) = capacity (contenance) of each packaging
		*/
	const onChangeSectionRealWeight = (realWeight, index) => {
		if (!realWeight) {
			realWeight = 0
		}
		const realWeightKilos = convertGramsIntoKilos(realWeight)
		setFieldValue(`sections[${index}].realWeight`, realWeightKilos)

		const section = sections[index]
		const sectionProductionWeight = section.totalProductionWeight // already in kilos
		if (!sectionProductionWeight) return

		let newPackagingForecastNumber = Math.floor(sectionProductionWeight / realWeightKilos)
		if (!isFinite(newPackagingForecastNumber)) { // if realWeight is 0 the division will return Infinity
			newPackagingForecastNumber = 0
		}
		setFieldValue(`sections[${index}].packagingForecastNumber`, newPackagingForecastNumber)
	}

	/** update waste by section on section changes */
	const _updateSectionsWaste = (sections, packagingForecastNumber, touchedSections) => {
		for (const section of sections) {
			const index = sections.indexOf(section)
			const sectionProductionWeight = section.totalProductionWeight
			const sectionRealWeight = section.realWeight
			if (!touchedSections?.[index]?.realWeight) {
				continue
			}
			if (!isFinite(sectionProductionWeight) || !isFinite(sectionRealWeight) || !isFinite(packagingForecastNumber)) {
				continue
			}
			const sectionForecastWaste = sectionProductionWeight - (packagingForecastNumber * sectionRealWeight)
			setFieldValue(`sections[${index}].forecastWaste`, sectionForecastWaste)
		}
	}

	useEffect(() => {
		_updateSectionsWaste(sections, packagingForecastNumber, touchedSections)
	}, [packagingForecastNumber])


	// trigger all fields calculations from proposed weight if real weight has not yet been manually changed
	useEffect(() => {
		for (const section of sections) {
			const index = sections.indexOf(section)
			if (!section.realWeight && isFinite(section.proposedWeight)) {
				setFieldTouched(`sections[${index}].realWeight`, true)
				const proposedWeightToGrams = convertKilosIntoGrams(section.proposedWeight)
				onChangeSectionRealWeight(proposedWeightToGrams, index)
			}
		}
	}, [sections])

	// update forecast packaging number with the minimum value of all sections' forecast packaging number
	useEffect(() => {
		const sectionsWithPackagingForecastNumber = sections.filter(section => section.packagingForecastNumber && isFinite(section.packagingForecastNumber))
		const packagingForecastNumbers = sectionsWithPackagingForecastNumber.map(section => section.packagingForecastNumber)
		if (packagingForecastNumbers.length > 0) {
			const minimumPackagingForecastNumber = Math.min(...packagingForecastNumbers)
			setFieldValue("packagingForecastNumber", minimumPackagingForecastNumber)
		}
	}, [sections])

	return (<Table>
		<TableHead>
			<TableRow>
				<PETableHeaderCell align="start">Éléments à barquetter</PETableHeaderCell>
				<PETableHeaderCell>Poids fiche recette (g)</PETableHeaderCell>
				<PETableHeaderCell>Poids réel à barquetter (g)</PETableHeaderCell>
				<PETableDivider />
				<PETableHeaderCell>Waste (kg)</PETableHeaderCell>
			</TableRow>
		</TableHead>
		<TableBody>
			<FieldArray name="sections">
				{({ form }) => (
					<> {sections.map((section, index) => (
						<PackagingExecutionSection
							key={index}
							editable={editable}
							section={section}
							index={index}
							setFieldTouched={form.setFieldTouched}
							renderKiloToGrams={_renderKiloToGrams}
							onChangeSectionRealWeight={(value) => onChangeSectionRealWeight(value, index)}
						/>
					))
					}</>)}
			</FieldArray>
			<TableRow>
				<PETotalTableCell align="start">Poids total</PETotalTableCell>
				<PETotalTableCell>
					{_renderKiloToGrams(sections.reduce((acc, section) => acc + (section.recipeSectionWeight), 0))}
				</PETotalTableCell>
				<PETotalTableCell>
					{_renderKiloToGrams(sections.reduce((acc, section) => acc + (roundNumber(section.realWeight, 3) || 0), 0))}
				</PETotalTableCell>
				<PETableDivider />
				<PETotalTableCell
					disabled
					width={293}>
					{roundNumber(sections.reduce((acc, section) => acc + (section.forecastWaste || 0), 0), 1)}
				</PETotalTableCell>
			</TableRow>
		</TableBody>
	</Table>)

}

export default PackagingExecutionSections
