import React, { useState, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import clsx from "clsx"
import { COLORS } from "../../../utils"
import { generateOrderUnitLabel } from "../../../utils/ordersUtils"

const useStyles = makeStyles({
    containerGeneric: {
        borderRadius: 8,
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.15)",
        marginBottom: 10,
        marginRight: 7,
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        border: "2px solid transparent",

        width: 236,
        height: 158,
        padding: 16,
        gap: 16,
        flexShrink: 0,
    },
    nonSelectedContainer: {
        composes: "$containerGeneric",
        backgroundColor: COLORS.WHITE,
        color: COLORS.DRAFT_GREY
    },
    containerSelected: {
        composes: "$containerGeneric",
        border: `2px solid ${COLORS.PRIMARY_COLOR}`
    },
    container: {
        justifyContent: "space-between",
    },
    containerInCart: {
        composes: "$containerGeneric",
        backgroundColor: COLORS.PRIMARY_COLOR,
        color: COLORS.WHITE
    },
    titleContainer: {
        height: 44,
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 1.57,
        textTransform: "uppercase",
        alignSelf: "stretch",
    },
    nonSelectedTitle: {
        color: COLORS.PRODUCTION_STEPS_TEXT_GREY,
    },
    reference: {
        fontFamily: "Roboto",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    top: {
        gap: 4,
        alignSelf: "stretch",
        height: 62,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
    },
    infoRowGeneric: {
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "stretch",
    },
    firstInfoRow: {
        composes: "$infoRowGeneric",
        justifyContent: "space-between",
    },
    info: {
        textAlign: "right",
        fontFamily: "Roboto",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: 1.57,
    },
    label: {
        composes: "$info",
        fontWeight: 500,
    },
    tooltip: {
        top: 0,
        left: 0,
        padding: 8,
        borderRadius: 10,
        border: `1px solid ${COLORS.LIGHT_GREY}`,
        position: "absolute",
        backgroundColor: COLORS.WHITE,
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        zIndex: 2,
        boxShadow: "0px 1px 3px rgba(0,0,0,.8)",
    }
}) 

const OrderCard = (props) => {
    const {
        supplierItem,
        isInCart,
        isSelected,
        actions,
        isValidOrder,
        selectedOrder
    } = props
    
    const classes = useStyles()
    const [showFullName, setShowFullName] = useState(false)
    const [isTitleHover, setIsTitleHover] = useState(false)
    const ref = React.createRef()
    
    useLayoutEffect(() => {
        if (ref.current?.clientHeight < ref.current?.scrollHeight && isTitleHover) {
            setShowFullName(true)
        }
        else {
            setShowFullName(false)
        }
    }, [ref, isTitleHover])
    
    const onSelectCard = () => {
        actions({
            method: "SELECT_CARD",
            data: {
                card: supplierItem
            }
        })
    }

    const handleSelect = () => {
        if (selectedOrder?.orderStatus === "DONE") {
            if (!isInCart && isValidOrder) {
                onSelectCard()
            }
            return
        }

        if (!isInCart) {
            onSelectCard()
        }
    }

    return (
        <div
            className={clsx(classes.container, {
                [classes.containerInCart]: isInCart,
                [classes.containerSelected]: isSelected && !isInCart,
                [classes.nonSelectedContainer]: !isSelected && !isInCart
            })}
            onClick={handleSelect}
            role="button"
            tabIndex={0}
        >
            <div className={clsx(classes.content, classes.top)}>
                {supplierItem.supplierArticleId && (
                    <div>
                        <Typography className={classes.reference}>
                            {supplierItem.supplierArticleId}
                        </Typography>
                    </div>
                )}
                <div className={classes.titleContainer}>
                    <Typography
                        className={clsx(classes.title, !isInCart && classes.nonSelectedTitle)}
                        onMouseEnter={() => setIsTitleHover(true)}
                        ref={ref}
                    >
                        {supplierItem.name}
                    </Typography>
                    {
                        showFullName &&
                        <div
                            className={classes.tooltip}
                            onMouseLeave={() => setIsTitleHover(false)}
                        >
                            {supplierItem.name}
                        </div>
                    }
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.firstInfoRow}>
                    <Typography className={classes.label}>
                        {generateOrderUnitLabel(supplierItem, "order")}
                    </Typography>
                    <Typography className={classes.info}>
                        {supplierItem.units.order.weight} kg
                    </Typography>
                </div>
                <div className={classes.infoRowGeneric}>
                    <Typography className={classes.info}>
                        {supplierItem.units.order.price} €
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default OrderCard