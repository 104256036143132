import React, {Fragment, useState, useEffect} from "react"
import { COLORS } from "../../utils"
import PrevIcon from "@mui/icons-material/ChevronLeft"
import NextIcon from "@mui/icons-material/ChevronRight"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import {LockOpen, Lock} from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import DownloadIcon from "@mui/icons-material/GetApp"
import ShoppingCardIcon from "@mui/icons-material/ShoppingCart"
import moment from "moment"
import CalendarHeaderInfo from "../Calendar/CalendarHeaderInfo"
import { pxTOvw } from "../../utils/planning"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import { queryUserRights } from "../../actions/Utils/utils"

const useStyles = makeStyles({
    daysContainer: {
        display: "flex",
        position: "fixed",
        height: 38,
        zIndex: 200,
        top: 128,
        backgroundColor: COLORS.WHITE
    },
    dayContainer: {
        marginLeft: -1,
        paddingLeft: 10,
        paddingTop: 10,
        border: `1px solid ${COLORS.MEDIUM_GREY}`,
        fontSize: 14,
        height: 36
    },
    dayIcon: {
        width: 30,
        textAlign: "center",
        marginTop: 10,
        cursor: "pointer",
    },
    dayInfo5: {
        width: pxTOvw((window.innerWidth - 120.4) / 5),
    },
    dayInfo7: {
        width: pxTOvw((window.innerWidth - 120.4) / 7),
    },
    iconContainer: {
        color: COLORS.DARK_GREY,
        marginTop: -7,
    },
    icon: {
        cursor: "pointer",
        fontSize: 20,
        marginTop: 5,
        marginRight: 5
    },
    greenIcon: {
        composes: "$icon",
        color: COLORS.GREEN_400
    },
    redIcon: {
        composes: "$icon",
        color: COLORS.RED
    }
})

const PlanningDays = (props) => {
    const {
        days,
        planningType,
        productData = [],
        canEdit,
        canPrint,
        rootClassName,
        onProductionLockingUpdate
    } = props

    const classes = useStyles()

    const [ openInfo, setInfoOpen ] = useState(false)
    const [ anchorEl, setAnchorEl ] = useState(null)
    const [ shopAnchorEl, setShopAnchorEl ] = useState(null)
    const [ dayMenu, setDayMenu ] = useState(0)
    const [ rights, setRights ] = useState()
    const today = moment.utc().startOf("days").valueOf()


    useEffect(() => {
        getUserRights()
    }, [days])

    const getUserRights = async () => {
        const rights = await queryUserRights()
        setRights(rights)
    }
    
    const _onInfo = () => {
        setInfoOpen(!openInfo)
    }

    const _onEdit = (day) => {
        const { onEdit } = props

        onEdit(day)
    }

    const _onDownloadCards = (event) => {
        const { onDownloadCards } = props

        onDownloadCards(dayMenu, event)
        setAnchorEl(null)
        setDayMenu(null)
    }

    const _onDownloadPackagingSections = (event, format) => {
        const { onDownloadPackagingSections } = props

        onDownloadPackagingSections(dayMenu, format)
        setAnchorEl(null)
        setDayMenu(null)
    }

    const _onDownloadHeadPage = (event, format) => {
        const { onDownloadHeadPage } = props

        onDownloadHeadPage(dayMenu, event, format)
        setAnchorEl(null)
        setDayMenu(null)
    }

    // TODO: this is not used anymore, maybe it will be re-used later
    /* eslint-disable-next-line no-unused-vars */
    const _onDownloadReleaseVoucher = () => {
        const { onDownloadReleaseVoucher } = props

        onDownloadReleaseVoucher(dayMenu)
        setAnchorEl(null)
        setDayMenu(null)
    }

    const _onDownloadNameCard = () => {
        const { onDownloadNameCard } = props

        onDownloadNameCard(dayMenu)
        setAnchorEl(null)
        setDayMenu(null)
    }

    const _onDownloadTrackingVoucher = () => {
        const { onDownloadTrackingVoucher } = props

        onDownloadTrackingVoucher(dayMenu)
        setAnchorEl(null)
        setDayMenu(null)
    }

    const _onClickCart = (event) => {
        const { onClickCart } = props

        onClickCart(dayMenu, event)
        handleShopClose()
    }
    
    const _openCommandModal = () => {
        const { openCommandModal } = props
    
        openCommandModal(dayMenu)
        handleShopClose()
    }
    
    const handleShopingCardIcon = (event, day) => {
        setShopAnchorEl(event.currentTarget)
        setDayMenu({...day})
    }

    const _onClickPrev = () => {
        const { onClickPrev } = props

        if (planningType !== "PREVISIONS"){
            onClickPrev()
        }
    }

    const _onClickNext = () => {
        const { onClickNext } = props

        if (planningType !== "PREVISIONS"){
            onClickNext()
        }
    }

    const handleClickDownloadIcon = (event, day) => {
        setAnchorEl(event.currentTarget)
        setDayMenu({...day})
    }

    const handleClose = () => {
        setAnchorEl(null)
        setDayMenu(null)
    }
    
    const handleShopClose = () => {
        setShopAnchorEl(null)
        setDayMenu(null)
    }

    const id = anchorEl ? "download-icon-popover" : undefined
    const downloadItemsLabel = planningType === "PACKAGING"
        ? "Journée de barquettage"
        : "Journée de production"

    return (
        <Fragment>
            <div className={clsx(classes.daysContainer, rootClassName)}>
                <div
                    className={classes.dayIcon}
                    onClick={() => {_onClickPrev()}}
                >
                    {
                        planningType !== "PREVISIONS" &&
                        <PrevIcon/>
                    }
                </div>
                {
                    days.map((day, index) => {
                        const currentProductData = productData.find(el => el.date === day.timestamp)
                        const currentDate = day.timestamp
                        const showEdition = currentDate >= today
                        /*const showEdition = true*/
                        return (
                            <div key={index}>
                                <div className={clsx(classes.dayContainer, planningType !== "PREVISIONS" ? classes.dayInfo7 : classes.dayInfo5, "flexRow alignCenter spaceBetween")}>
                                    {moment(day.timestamp).format("ddd DD/MM")}
                                    {
                                        planningType !== "PREVISIONS" &&
                                            <div className={classes.iconContainer}>
                                                <InfoIcon
                                                    className={classes.icon}
                                                    onClick={() => {
                                                        _onInfo()
                                                    }}
                                                />
                                                {planningType === "PRODUCTION" && rights?.canLockProductionDay &&
                                                  (
                                                    day.isLocked ?
                                                      <Lock
                                                        className={classes.icon}
                                                        onClick={() => onProductionLockingUpdate(day)}
                                                      /> :
                                                      <LockOpen
                                                        className={classes.icon}
                                                        onClick={() => onProductionLockingUpdate(day)}
                                                      />
                                                  )
                                                }
                                                {
                                                    planningType === "SELL"
                                                        ?
                                                        canEdit && showEdition
                                                            ?
                                                            (
                                                                <EditIcon
                                                                    className={classes.icon}
                                                                    onClick={() => {
                                                                        _onEdit(day)
                                                                    }}
                                                                />
                                                            )
                                                            :
                                                            (<span></span>)
                                                        :
                                                        (
                                                            <Fragment>
                                                                {
                                                                    planningType === "PRODUCTION" && canPrint
                                                                        ?
                                                                        <>
                                                                            <ShoppingCardIcon
                                                                                className={day.reportHasAlreadyBeenPrinted
                                                                                    ? day.printReport
                                                                                        ? classes.greenIcon
                                                                                        : classes.redIcon
                                                                                    : classes.icon}
                                                                                onClick={(event) => {
                                                                                    handleShopingCardIcon(event, day)
                                                                                }}
                                                                            />
                                                                            <Menu
                                                                                id={id}
                                                                                open={Boolean(shopAnchorEl)}
                                                                                anchorEl={shopAnchorEl}
                                                                                onClose={handleShopClose}
                                                                                transformOrigin={{
                                                                                    vertical: "top",
                                                                                    horizontal: "center"
                                                                                }}
                                                                            >
                                                                                <MenuItem onClick={(event) => _onClickCart(event)}>
                                                                                    Rapport de commande
                                                                                </MenuItem>
                                                                                <MenuItem onClick={() => _openCommandModal()}>
                                                                                    Commande d'appoint
                                                                                </MenuItem>
                                                                            </Menu>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    canPrint ?
                                                                        (
                                                                            <>
                                                                                <DownloadIcon
                                                                                    aria-label="more"
                                                                                    aria-controls="long-menu"
                                                                                    aria-haspopup="true"
                                                                                    index={index}
                                                                                    onClick={(event) => handleClickDownloadIcon(event, day)}
                                                                                    className={day.hasAlreadyBeenPrinted
                                                                                        ? day.print
                                                                                            ? classes.greenIcon
                                                                                            : classes.redIcon
                                                                                        : classes.icon}
                                                                                />
                                                                                <Menu
                                                                                    id={id}
                                                                                    open={Boolean(anchorEl)}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    transformOrigin={{
                                                                                        vertical: "top",
                                                                                        horizontal: "center"
                                                                                    }}
                                                                                >
                                                                                    <MenuItem onClick={(event) => _onDownloadHeadPage(event, "pdf")}>
                                                                                        Page de garde { planningType === "PACKAGING" && "(.pdf)" }
                                                                                    </MenuItem>
                                                                                    {
                                                                                      planningType === "PACKAGING" &&
                                                                                          <MenuItem
                                                                                            onClick={(event) => _onDownloadHeadPage(event, "csv")}>
                                                                                              Page de garde (.csv)
                                                                                          </MenuItem>
                                                                                    }
                                                                                    <MenuItem onClick={(event) => _onDownloadCards(event)}>
                                                                                        {downloadItemsLabel}
                                                                                    </MenuItem>
                                                                                    {/* {
                                                                                      planningType === "PRODUCTION" &&
                                                                                      <MenuItem
                                                                                        onClick={() => _onDownloadReleaseVoucher()}>
                                                                                          Bon de sortie
                                                                                      </MenuItem>
                                                                                    } */}
                                                                                    {
                                                                                        planningType === "PACKAGING" &&
                                                                                            <MenuItem onClick={() => _onDownloadNameCard()}>
                                                                                                Noms des produits
                                                                                            </MenuItem>
                                                                                    }
                                                                                    {
                                                                                        planningType === "PRODUCTION" &&
                                                                                            <MenuItem onClick={() => _onDownloadTrackingVoucher()}>
                                                                                                Bon de traçabilité
                                                                                            </MenuItem>
                                                                                    } 
                                                                                    {
                                                                                        planningType === "PACKAGING" &&
                                                                                            <MenuItem onClick={() => _onDownloadPackagingSections(event, "csv")}>
                                                                                                Sections barquettées
                                                                                            </MenuItem>
                                                                                    }   
                                                                                </Menu>
                                                                            </>
                                                                        )
                                                                        :
                                                                        null
                                                                }
                                                            </Fragment>
                                                        )
                                                }
                                            </div>
                                    }
                                </div>
                                {
                                    openInfo && currentProductData &&
                                        <CalendarHeaderInfo
                                            internalProduct={currentProductData.totalIntern}
                                            externalProduct={currentProductData.totalExtern}
                                            expectedSales={currentProductData.productTypesData}
                                        />
                                }
                            </div>
                        )
                    })
                }
                <div
                    className={classes.dayIcon}
                    onClick={() => {_onClickNext()}}
                >
                    {
                        planningType !== "PREVISIONS" &&
                        <NextIcon />
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default PlanningDays
