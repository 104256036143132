import React from "react"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  styled,
} from "@mui/material"

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

export const KitchenAreaDeleteModal = ({ onClose, open }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
        Suppression de l’atelier impossible
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        Vous ne pouvez pas supprimer cet atelier car il comporte des machines. Si vous souhaitez supprimer l’atelier, il faut avant supprimer toutes ces machines
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onClose} color="primary" variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default KitchenAreaDeleteModal
