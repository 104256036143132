import React from "react"
import { makeStyles } from "@mui/styles"
import { Stack, Typography } from "@mui/material"

import { PEPSEResumeContainer } from "../../ProductionStepExecutions/preview/StyledExecutionPreviewComponents"
import { StyledItemLabel, StyledItemValue } from "../../ProductionStepExecutions/styledComponents"

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 20,
        display: "flex",
        flexDirection: "column",
        color: "#262626",
        alignItems: "center",
        fontWeight: 500
    },
}))

const SupplierItemLotsResume = ({ title, options = [] }) => {
    const classes = useStyles()
   
    return (
        <PEPSEResumeContainer sx={{ position: "relative", alignSelf: "stretch", height: 170, flex: 0 }}>
            <Stack spacing={4}>
                {/* head */}
                <Stack spacing={2.2} direction="row" alignItems="center">
                    <Typography variant="h1" className={classes.title}>
                        {title}
                    </Typography>
                </Stack>
                {/* resume */}
                <Stack spacing={4} direction="row">
                    {options.map((option, index) => (
                        <Stack spacing={1} key={index}>
                            <StyledItemLabel>
                                {option.label}
                            </StyledItemLabel>
                            <StyledItemValue>
                                {option.value}
                            </StyledItemValue>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </PEPSEResumeContainer>
                               
    )
}

export default SupplierItemLotsResume