export const TableDateFormat = "DD/MM/YYYY"

export function desc(a, b, orderBy) {
    if (typeof b[orderBy] === "string" && typeof a[orderBy] === "string") {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1
        }
        return 0
    } else {
        return b[orderBy] - a[orderBy]
    }
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

export function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}
