import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import moment from "moment"
import TextareaAutosize from "@mui/material/TextareaAutosize"

export const CheckCommentModal = (props) => {
    const {
        classes,
        onClose,
        isChecking,
        onValidate,
        selectedElement
    } = props

    const submitForm = (values) => {
        onValidate(selectedElement, values)
        onClose()
    }

    const initialValues = {
        comment: ""
    }

    return (
        <Formik
            key={moment.utc().valueOf()}
            initialValues={initialValues}
            onSubmit={submitForm}
        >
            {({
                  values,
                  handleChange,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isChecking}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Commentaire</span>
                            </DialogTitle>
                            <DialogContent>
                                <TextareaAutosize
                                    className={classes.textArea}
                                    minRows={4}
                                    maxRows={4}
                                    aria-label="maximum height"
                                    placeholder="Commentaire"
                                    defaultValue=""
                                    value={values.comment}
                                    onChange={handleChange}
                                    name="comment"
                                />
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    valider
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = {
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0
    },
    dialogTitleLabel: {
        fontWeight: 500,
        marginTop: 5,
        fontSize: 20,
        lineHeight: "24px",
        width: 596
    },
    textArea: {
        width: "100%"
    }
}

export default withStyles(styles)(CheckCommentModal)
