import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import { SupplierResumeEditSchema } from "../../utils/yupValidators"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import { getSupplierResumeInitialValues } from "../../utils/suppliers"
import Switch from "@mui/material/Switch"
import { DatePicker } from "@mui/x-date-pickers"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import moment from "moment"

import { red, green } from "@mui/material/colors"

const useStyles = makeStyles({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        width: 424,
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14
        },
        "& .MuiInputLabel-formControl": {
            fontSize: 11,
            top: -38,
            "&.MuiFormLabel-filled": {
                top: 0,
                fontSize: 14,
                "&.site-select-label": {
                    top: -14
                }
            },
            "&.single-label": {
                top: 0,
                marginBottom: 12,
                fontSize: 16,
                transform: "none",
                position: "relative",
                height: 30,
                display: "flex",
                alignItems: "center"
            }
        }
    },
    formControl50: {
        composes: "$formControl",
        width: "50%"
    },
    formControlDates: {
        composes: "$formControl50",
        marginTop: 0,
        "& .MuiFormControl-root": {
            marginBottom: 10
        }
    },
    formControlSite: {
        composes: "$formControl50",
        "& label + .MuiInput-formControl": {
            marginTop: 0
        },
        "& .MuiFormLabel-filled": {
            top: -38
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        margin: "5px 0px 0px 0px"
    },
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: green[400]
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: green[400],
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: 15,
        marginBottom: 15
    }
})

export const SupplierEdit = (props) => {
    const {
        onClose,
        isEditing,
        onSaveSupplier,
        jsonSupplier,
        sites
    } = props

    const classes = useStyles()

    const submitForm = async (values) => {
        await onSaveSupplier(values)
        onClose()
    }

    return (
        <Formik
            initialValues={getSupplierResumeInitialValues(jsonSupplier)}
            validationSchema={SupplierResumeEditSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
            }) => {
                if (values.referenceStartDate && !moment.isMoment(values.referenceStartDate)) values.referenceStartDate = moment.utc(values.referenceStartDate).startOf("day")
                if (values.referenceEndDate && !moment.isMoment(values.referenceEndDate)) values.referenceEndDate = moment.utc(values.referenceEndDate).startOf("day")

                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer le résumé fournisseur</span>
                                <Switch
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        track: values.isActive ? classes.switchTrackChecked : classes.switchTrack,
                                    }}
                                    color="default"
                                    id="status"
                                    name="isActive"
                                    placeholder="Status"
                                    checked={values.isActive}
                                    onChange={handleChange}
                                />
                            </DialogTitle>
                            <DialogContent dividers>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Export Etiquettable</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <Switch
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            track: values.canExportEtiquettable ? classes.switchTrackChecked : classes.switchTrack,
                                        }}
                                        color="default"
                                        id="etiquettable"
                                        name="canExportEtiquettable"
                                        checked={values.canExportEtiquettable}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Nom</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Nom</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.name?
                                            (
                                                <div className={classes.errorField}>{errors.name}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Raison Sociale</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Raison Sociale</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="corporateName"
                                        value={values.corporateName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.corporateName?
                                            (
                                                <div className={classes.errorField}>{errors.corporateName}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Code de pays</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Code de pays</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="countryCode"
                                        value={values.countryCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.countryCode?
                                            (
                                                <div className={classes.errorField}>{errors.countryCode}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Code de continent</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Code de continent</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="continentCode"
                                        value={values.continentCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.continentCode?
                                            (
                                                <div className={classes.errorField}>{errors.continentCode}</div>
                                            )
                                            : null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">N° d’agrément sanitaire</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>N° d’agrément sanitaire</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="sanitaryApprovalNumber"
                                        value={values.sanitaryApprovalNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.sanitaryApprovalNumber?
                                            (
                                                <div className={classes.errorField}>{errors.sanitaryApprovalNumber}</div>
                                            )
                                            : null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">SIRET</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>SIRET</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="siret"
                                        value={values.siret}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                    />
                                    {
                                        errors.siret?
                                            (
                                                <div className={classes.errorField}>{errors.siret}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">TVA intracommunautaire</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>TVA intracommunautaire</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="intraTVA"
                                        value={values.intraTVA}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                    />
                                    {
                                        errors.intraTVA?
                                            (
                                                <div className={classes.errorField}>{errors.intraTVA}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Période de référencement</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControlDates}>
                                    <DatePicker
                                        disableCloseOnSelect={false}
                                        showToolbar={false}
                                        variant="inline"
                                        value={values.referenceStartDate}
                                        onChange={value => setFieldValue("referenceStartDate", value)}
                                        inputFormat="dddd DD MMMM"
                                        name="referenceStartDate"
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Du" className={classes.datePicker}/>}
                                    />
                                    <DatePicker
                                        disableCloseOnSelect={false}
                                        showToolbar={false}
                                        variant="inline"
                                        value={values.referenceEndDate}
                                        onChange={value => setFieldValue("referenceEndDate", value)}
                                        inputFormat="dddd DD MMMM"
                                        name="referenceEndDate"
                                        renderInput={(params) => <TextField {...params} variant="standard" label="Au" className={classes.datePicker} />}
                                    />
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Référence client</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Réference client</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="clientReference"
                                        value={values.clientReference}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.clientReference?
                                            (
                                                <div className={classes.errorField}>{errors.clientReference}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Site de livraison par défaut</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControlSite}>
                                    <InputLabel className="site-select-label">Site de livraison</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="defaultDeliverySite"
                                        value={values.defaultDeliverySite}
                                        onChange={handleChange}
                                    >
                                        {
                                            sites && sites.map(site => (
                                                <MenuItem
                                                    key={site.objectId}
                                                    value={site.objectId}
                                                >
                                                    {site.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.defaultDeliverySite?
                                            (
                                                <div className={classes.errorField}>{errors.defaultDeliverySite}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    className={classes.button1}
                                    onClick={onClose}
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SupplierEdit
