import React, { useRef, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"
import ButtonFlaw from "../../components/Reception/ButtonFlaw"
import { TextField, Stack, Button } from "@mui/material"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import ActionHeader from "../../components/LotInventory/ActionHeader"

const useStyles = makeStyles(() => ({
	root: {
		paddingBottom: 105,
		textAlign: "center",
		alignSelf: "center",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		flexGrow: 1,
		height: "100%"
	},
	buttons: {
		display: "flex",
		flexDirection: "column"
	},
	button: {
		height: 44,
		fontSize: 15,
		margin: 8,
		"&.disabled": {
			opacity: 0.6
		}
	},
	buttonFlaw: {
		bottom: 33,
		marginBottom: 0
	},
	inputWrapper: {
		marginBottom: 4
	},
	input: {
		width: 125,
		fontSize: 40,
		textAlign: "center",
	},
	unit: {
		fontSize: 16,
		textTransform: "uppercase",
		color: COLORS.BLACK_500
	},
	title: {
		color: "#262626",
		textAlign: "center",
		fontSize: 24,
		fontWeight: 700,
		lineHeight: "32px"
	},
	bold: {
		fontWeight: 700
	},
	informations: {
		fontSize: 14
	}
}))

const SecondaryDLCCartInventory = ({ secondaryDLC, onEditFlaw, onEdit, onValidate, onBack }) => {

	const classes = useStyles()

	const formikRef = useRef()

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.setValues({ quantity: secondaryDLC.quantity || 0 })
		}
	}, [secondaryDLC])

	return (
		<div className={classes.root}>
			<ActionHeader
				handleClose={onBack}
				noBorder
			/>
			<Stack gap={3} useFlexGap>
				<div className={classes.title}>
					{secondaryDLC.orderSupplierItem.name}
				</div>
				<div className={classes.informations}>
					<div>
						LOT : <span className={classes.bold}>{secondaryDLC.lotNumber}</span>
					</div>
					<div>
						DLC : <span className={classes.bold}>{dayjs(secondaryDLC.dlc).format("DD/MM/YYYY")}</span>
					</div>
				</div>
				<div className={classes.informations}>
					<div>
						Date d'ouverture : <span className={classes.bold}>{dayjs(secondaryDLC.openingDate).format("DD/MM/YYYY")}</span>
					</div>
					<div>
						DLC secondaire : <span className={classes.bold}>{dayjs(secondaryDLC.secondaryDLC).format("DD/MM/YYYY")}</span>
					</div>
				</div>
			</Stack>
			<div>
				<Formik
					innerRef={formikRef}
					onSubmit={onValidate}
					initialValues={{ quantity: secondaryDLC.quantity || 0 }}
					validateOnChange
				>
					{({ values, handleChange }) => {
						return (
							<Form>
								<TextField
									name="quantity"
									variant="standard"
									placeholder={values.quantity}
									inputProps={{
										className: classes.input,
										type: "number",
										pattern: "[0-9]*",
									}}
									className={classes.inputWrapper}
									value={values.quantity}
									type="number"
									onChange={handleChange}
								/>
								<p className={classes.unit}>kg</p>
							</Form>)
					}}
				</Formik>
			</div>
			<div className={classes.buttons}>
				<Button
					className={classes.button}
					variant="contained"
					color="primary"
					disableElevation
					type="submit"
					onClick={() => formikRef?.current?.submitForm()}
				>
					CONFIRMER
				</Button>
				<Button
					className={classes.button}
					variant="outlined"
					color="primary"
					disableElevation
					type="submit"
					onClick={onEdit}
				>
					ÉDITER CE LOT
				</Button>
			</div>
			<ButtonFlaw rootClassName={classes.buttonFlaw} onSetFlaw={onEditFlaw} />
		</div>
	)
}

export default SecondaryDLCCartInventory