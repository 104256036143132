import React from "react"
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material"

const OrderValidityModal = ({onClose, open}) => {

	const closeModal = () => onClose &&  onClose()

	return (
		<Dialog
			onClose={closeModal}
			aria-labelledby="order-validity-check-dialog"
			open={open}
		>
			<DialogContent>
				<Typography variant={"span"}>
				La commande contient des articles avec une quantité égale à 0. Saisissez une quantité à commander ou supprimez ces articles de la commande.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					onClick={closeModal}
				>
					FERMER
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default OrderValidityModal