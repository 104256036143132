import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import moment from "moment"
import { capitalizeFirstLetter } from "../../utils"
import IngredientResumeForm from "./IngredientResumeForm"


const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: COLORS.WHITE,
		borderRadius: 5,
		paddingBottom: 40,
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
	},
	titleContainer: {
		display: "flex",
		flex: "1 1",
		justifyContent: "space-between",
		alignItems: "center",
	},
	title: {
		padding: "20px 32px 16px 32px",
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: 16,
	},
	singleValueContainer: {
		display: "flex",
		paddingLeft: 32,
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 16,
	},
	valueLabel: {
		color: COLORS.BLUEGREY_700,
		width: 200,
		minWidth: 200,
		display: "flex",
		alignItems: "center",
	},
	stateValue: {
		textTransform: "upperCase",
	}
}))
const IngredientResume = (props) => {
	const { ingredient, onSave, ingredientType } = props
	const classes = useStyles()

	const family = ingredient && ingredient.group && ingredient.group.family
		? ingredient.group.family.name
		: ""

	const group = ingredient && ingredient.group
		? ingredient.group.name
		: ""

	const season = ingredient && Array.isArray(ingredient.season)
		? ingredient.season
		: []

	const labelSort = season.sort((a, b) => {
		return Number(a) - Number(b)
	})
	const label = labelSort.map(elem =>
		capitalizeFirstLetter(moment().startOf("year").add(Number(elem) - 1, "months").format("MMMM")).substring(0, 3)
	).join(", ")


	const [openEditModal, setOpenEditModal] = useState(false)

	const handleCloseModal = () => {
		setOpenEditModal(false)
	}

	return (
		<Paper className={classes.container}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>{`Résumé de l'ingrédient ${ingredientType}`}</div>
				<NewEditIcon onClick={() => setOpenEditModal(true)} />
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Nom de l'ingrédient</div>
				<span>{ingredient.name}</span>
			</div>
			{ingredientType === "externe" &&
				<div className={classes.singleValueContainer}>
					<div className={classes.valueLabel}>Fournisseur</div>
					<span>{ingredient.supplier.name}</span>
				</div>
			}
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Famille d'ingrédients</div>
				<span>{family}</span>
			</div>
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Groupe d'ingrédients</div>
				<span>{group}</span>
			</div>
			{ingredientType === "interne" &&
				<div className={classes.singleValueContainer}>
					<div className={classes.valueLabel}>Complexité</div>
					<span>{ingredient.complexity}</span>
				</div>
			}
			<div className={classes.singleValueContainer}>
				<div className={classes.valueLabel}>Saisonnalité</div>
				<span>{label}</span>
			</div>

			<IngredientResumeForm
				open={openEditModal}
				onClose={handleCloseModal}
				ingredient={ingredient}
				onSave={onSave}
				ingredientType={ingredientType}
			/>
		</Paper>
	)
}

export default IngredientResume