import React, {useState} from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import InvoiceResumeEdit from "./InvoiceResumeEdit"
import RoundCheckBoxIcon from "../RoundCheckBoxIcon"

const useStyles = makeStyles({
    rootContainer: {
        display: "flex",
        background: "#E5E5E5",
        paddingTop: 20,
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 40,
        minHeight: "calc(100vh - 275px)"
    },
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 1140,
        marginTop: 20
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 128,
        display: "flex",
        alignItems: "center"
    },
    flex: {
        display: "flex"
    },
    listValue:{
        height:11,
        marginLeft:15,
        fontSize:16,
        fontWeight: 400,
        color: COLORS.SITE_INFORMATION_VALUE,
        display: "flex",
        justifyContent:"space-around"
    },
    label: {
        width: 323,
        marginLeft: 50
    },
    secondLabel: {
        width: "auto",
        marginRight: 20,
        marginLeft: 50
    }
})

export const InvoiceResume = (props) => {
    const {
        jsonSupplier,
        onSaveSupplier,
        canEdit
    } = props
    
    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)

    const edit = () => setIsEditing(true)

    const _toggleEdit = () => setIsEditing(!isEditing)

    return (
        <div className={classes.rootContainer}>
            <Paper className={classes.container}>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>
                        Facturation
                    </div>
                    {
                        canEdit &&
                        <IconButton
                            className={classes.iconContainer}
                            disableRipple={true}
                            onClick={edit}
                            size="large">
                            <EditIcon className={classes.icon}/>
                        </IconButton>
                    }
                </div>
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>
                        Facture
                    </div>
                    <div className={classes.listValue}>
                        <span className={classes.label}> N° Facture = N° BL : </span>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.billing && jsonSupplier.billing.invoiceEqualToBL}
                        />
                    </div>
                </div>
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>
                        Réclamation
                    </div>
                    <div className={classes.listValue}>
                        <span className={classes.label}>Créer automatiquement les réclamations : </span>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.billing && jsonSupplier.billing.autoComplain}
                        />
                        <span className={classes.secondLabel}>Envoi automatique par e-mail : </span>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.billing && jsonSupplier.billing.autoSendEmailComplain}
                        />
                    </div>
                </div>
                <div className={classes.valueContainer}>
                    <div className={classes.valueLabel}>
                        Avoirs
                    </div>
                    <div className={classes.listValue}>
                        <span className={classes.label}> Envoi automatique par e-mail : </span>
                        <RoundCheckBoxIcon
                            checked={jsonSupplier.billing && jsonSupplier.billing.autoSendEmailCredit}
                        />
                    </div>
                </div>
            </Paper>
            {
                canEdit &&
                <InvoiceResumeEdit
                    onClose={_toggleEdit}
                    isEditing={isEditing}
                    jsonSupplier={jsonSupplier}
                    onSaveSupplier={onSaveSupplier}
                />
            }
        </div>
    )
}

export default InvoiceResume
