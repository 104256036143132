import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { TRANSFORMATION_TYPES, getTransformationTypeLabel } from "../../../../utils/supplierItemUtils"
import FixedAddButton from "../../../../components/FixedAddButton"
import { addTransformationMode } from "../../../../actions/Supplier/transformationModes"
import DataGrid from "../../../../components/DataGrid/DataGrid"
import TransformationModesModal from "./TransformationModesFormModal"
import { getTransformationModesSelector } from "../../../../reducers/Suppliers/SupplierItems/supplierItems"

const columns = [
    {
        key: "id",
        label: "ID",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            strict: true
        },
        width: 120
    },
    {
        key: "name",
        label: "Nom",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        },
    },
    {
        key: "transformationType",
        label: "Type de transformation",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "select",
            options: TRANSFORMATION_TYPES.map((transformationType) => ({
                key: transformationType.value,
                value: transformationType.label,
                label: transformationType.label
            }))
        },
        width: 500
    },
]
const TransformationModes = () => {
    const dispatch = useDispatch()
    const [openCreationDialog, setOpenCreationDialog] = useState(false)
    const transformationModes = useSelector(getTransformationModesSelector)

    const [data, setData] = useState([])

    useEffect(() => {
        const newData = transformationModes.map((transformationMode) => {
            return {
                id: transformationMode.objectId,
                name: transformationMode.name,
                transformationType: getTransformationTypeLabel(transformationMode.transformationType)
            }
        })
        setData(newData)
    }, [transformationModes])

    const toggleDialog = () => setOpenCreationDialog(!openCreationDialog)

    const handleCreation = (values) => {
        dispatch(addTransformationMode(values))
    }

    return (
        <div>
           <div className="flexColumn">
                <DataGrid
                    title="Modes de transformation"
                    columns={columns}
                    data={data}
                    withFilters
                    rowLabel="modes de transformation"
                />
                <FixedAddButton onClick={toggleDialog} />
            </div> 
            <TransformationModesModal
                open={openCreationDialog}
                onClose={toggleDialog}
                onConfirm={handleCreation}
            />
        </div>
    )
}

export default TransformationModes
