import Parse from "parse"

export const ProductsDispatch = Parse.Object.extend("ProductsDispatch")
const ProductItem = Parse.Object.extend("ProductionItem")

export async function resetStatusToToDo(selectedProductDispatch) {
    try {
        const productDispatch = await new Parse.Query(ProductsDispatch)
                .equalTo("objectId", selectedProductDispatch.objectId)
                .first()

        productDispatch.set("kfcStatus", "TODO")
        return await productDispatch.save()
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function getProductItemByProductDispatch(ProductDispatchId, toJson = true) {
    try {
        const productItem = await new Parse.Query(ProductItem)
            .equalTo("productDispatch", ProductsDispatch.createWithoutData(ProductDispatchId))
            .first()
        
        return productItem ?
            (toJson ? productItem.toJSON() : productItem) :
            null
    }
    catch (e) {
        return Promise.reject(e)
    }
}
