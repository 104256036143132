import React, { PureComponent } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import Toolbar from "@mui/material/Toolbar"
import { lighten } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
})
export class ProductsTagsEnhancedTableToolbar extends PureComponent {
  _createProductTag = () => {
    const { createProductTag } = this.props

    createProductTag()
  }

  _onView = () => {
    const { selected, showProductTagForm, productsTags } = this.props
    const currentProductTag = productsTags.find(
      (productTag) => productTag.objectId === selected[0]
    )
    showProductTagForm(currentProductTag)
  }

  render() {
    const { numSelected, classes } = this.props
    const title = numSelected > 1 ? "Tags" : "Tag"

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {title}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {title}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        {numSelected > 0 ? (
          numSelected === 1 ? (
            <div className={classes.actions}>
              <Tooltip title="Editer" disableInteractive>
                <IconButton
                  aria-label="Editer"
                  onClick={this._onView}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : null
        ) : (
          <div className={classes.actions}>
            <Tooltip title="Créer" disableInteractive>
              <IconButton
                aria-label="Créer"
                onClick={this._createProductTag}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    )
  }
}

ProductsTagsEnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
}

ProductsTagsEnhancedTableToolbar = withStyles(toolbarStyles)(
  ProductsTagsEnhancedTableToolbar
)

export default ProductsTagsEnhancedTableToolbar
