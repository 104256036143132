import React, { useState, useEffect, useRef } from "react"
import { Formik, Form } from "formik"
import { Select, TextField, MenuItem, InputLabel, Stack, Checkbox } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { getTimezoneDate } from "../../utils"
import { loadPSEByProductionDateAndSupplierItem } from "../../parseManager/productionStepExecutions/parseProductionStepExecutionManager"
import { forwardRef } from "react"
import { uniq, uniqBy } from "lodash"
import * as Yup from "yup"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"

const useStyles = makeStyles({
	error: {
		color: "red",
		fontSize: 12,
		marginTop: 8
	}
})

const PSESubstitutionRecipesForm = ({
	handleSubmit,
	formId,
	formerValues
}) => {

	// reformat values so as to :
	// get all the production step executions that should be modified with the substitutes list
	// retrieve all necessary data to save inside ProductionSubstitution collection

	const _handleSubmit = (values) => {
		const clonedValues = { ...values }
		const { productionStepExecutionsFromThisDate, productionStepExecutionsIds } = clonedValues
		// retrieve all production step executions matching recipe id
		const selectedRecipeIds = productionStepExecutionsFromThisDate.filter((pse) => productionStepExecutionsIds.includes(pse.objectId)).map((pse) => pse.recipe.objectId)
		const selectedProductionStepExecutions = productionStepExecutionsFromThisDate.filter(pse => selectedRecipeIds.includes(pse.recipe.objectId))
		clonedValues.productionStepExecutions = selectedProductionStepExecutions.map((pse) => pse.objectId)
		delete clonedValues.productionStepExecutionsFromThisDate
		delete clonedValues.productionStepExecutionsIds
		clonedValues.recipes= uniq(selectedRecipeIds)
		clonedValues.startDate = dayjs.utc(clonedValues.productionDate).startOf("day").valueOf()
		clonedValues.endDate = dayjs.utc(clonedValues.productionDate).endOf("day").valueOf()
		handleSubmit && handleSubmit(clonedValues)
	}

	const formRef = useRef()

	const classes = useStyles()

	return (
		<Formik
			innerRef={formRef}
			onSubmit={_handleSubmit}
			initialValues={{
				...formerValues,
				productionStepExecutionsIds: [],
				productionStepExecutionsFromThisDate: [],
				productionDate: getTimezoneDate(new Date())
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={Yup.object().shape({
				productionDate: Yup.string().required("Veuillez sélectionner une date de production"),
				productionStepExecutionsIds: Yup.array().min(1, "Veuillez sélectionner au moins une recette")
			})}>
			{({ values, handleChange, setFieldValue, errors }) => {
				return (
					<Form id={formId}>
						<Stack gap={5}>
							<Stack>
								<InputLabel>Date de la production</InputLabel>
								<DatePicker
									showToolbar={false}
									value={values.productionDate}
									name="productionDate"
									inputFormat="DD/MM/YY"
									disablePast
									renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.productionDate} InputLabelProps={{ shrink: true }} />}
									onChange={(date) => {
										setFieldValue("productionDate", getTimezoneDate(date))
										setFieldValue("productionStepExecutionsIds", [])
									}}
								/>
							</Stack>
							<RecipesField
								values={values}
								handleChange={handleChange}
								errors={errors}
								classes={classes}
								productionDate={values.productionDate}
								supplierItemId={values.originalSupplierItem}
								setFieldValue={setFieldValue}
								ref={formRef}
							/>
						</Stack>
					</Form>
				)
			}}
		</Formik>
	)

}

export default PSESubstitutionRecipesForm

const RecipesField = forwardRef(function RecipesField(props, ref) {
	const {
		values,
		errors,
		classes,
		handleChange,
		productionDate,
		supplierItemId
	} = props


	const [productionStepExecutions, setProductionStepExecutions] = useState([])
	const [productionStepExecutionsOptions, setProductionStepExecutionsOptions] = useState([])

	const _loadProductionStepExecutions = async (productionDate, supplierItemId) => {
		const updatedProductionStepExecutions = await loadPSEByProductionDateAndSupplierItem({
			productionDate,
			supplierItemId
		})
		setProductionStepExecutions(updatedProductionStepExecutions)
		const deduplicatedProductionStepExecutions = uniqBy(updatedProductionStepExecutions, (pse) => pse.recipe.objectId)
		setProductionStepExecutionsOptions(deduplicatedProductionStepExecutions)
	}

	useEffect(() => {
		if (productionDate && supplierItemId) {
			_loadProductionStepExecutions(productionDate, supplierItemId)
		}
	}, [productionDate, supplierItemId])

	useEffect(() => {
		if (ref.current) {
			ref.current.setFieldValue("productionStepExecutionsFromThisDate", productionStepExecutions)
		}
	}, [productionStepExecutions])

	return (
		<Stack>
			<InputLabel>Recette</InputLabel>
			<Select
				multiple
				variant="standard"
				name="productionStepExecutionsIds"
				value={values.productionStepExecutionsIds}
				onChange={handleChange}
				renderValue={(value) => {
					const selectedRecipes = productionStepExecutions.filter((pse) => value.includes(pse.objectId))
					return selectedRecipes.map((pse) => pse.uniqueCode + " - " + pse.recipeName).join(", ")
				}}
			>
				{productionStepExecutionsOptions.map((pse) => (
					<MenuItem key={pse.objectId} value={pse.objectId}>
						<Checkbox
							checked={
								(values.productionStepExecutionsIds || []).includes(pse.objectId)
							}
							color="secondary"
						/>
						{pse.uniqueCode + " - " + pse.recipeName}
					</MenuItem>
				))}
			</Select>
			{errors.productionStepExecutionsIds && (
				<div className={classes.error}>{errors.productionStepExecutionsIds}</div>
			)}
		</Stack>
	)
})