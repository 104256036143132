import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { connect } from "react-redux"
import LotGroupTable from "./LotGroupTable"

const useClasses = makeStyles(() => ({
    container: {
        width: "100%"
    }
}))

const LotGroupDone = (props) => {
    const {
        modalRef,
        lotGroupDataDone,
    } = props
    
    const classes = useClasses()
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const searchFilter = (storage && storage.lotGroupDataDone)?storage.lotGroupDataDone:""

    return (
        <div className={classes.container}>
            <LotGroupTable
                canOpen={false}
                tableType="done"
                lotGroupData={lotGroupDataDone}
                searchFilter={searchFilter}
                storageIndex="lotGroupDone"
                modalRef={modalRef}
            />
        </div>
    )
}

export default connect((state) => {
    return {
        date: state.lotGroup.date
    }
}, {
})(LotGroupDone)
