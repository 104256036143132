import React, { useState, useCallback, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import { useSelector, useDispatch } from "react-redux"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"
import { Tab, Tabs } from "@mui/material"

import Header from "../../../components/SupplierItem/Header"
import SupplierItemGeneral from "../../../components/SupplierItem/SupplierItemGeneral"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import {
    CAN_UPDATE_SUPPLIER_ITEMS
} from "../../../utils/accessRights"
import SupplierItemPageHeader from "../../../components/SupplierItem/SupplierItemPageHeader"
import { getSupplierItemSnackbarSelector, getTransformationModesSelector } from "../../../reducers/Suppliers/SupplierItems/supplierItems"
import {
    updateSupplierItemNews,
    updateSupplierItemCookingModes,
    updateSupplierItemUnits,
    updateSupplierItemStockage,
    updateSupplierItemBillingUnits,
    updateSupplierItemResume
} from "../../../actions/Supplier/supplierItems"
import SupplierItemSetting from "../../../components/SupplierItem/SupplierItemSetting"
import { updateSupplierItemCommercialName, closeSupplierItemsSnackBar } from "../../../actions/Supplier/supplierItems"
import { CAN_DELETE_SUPPLIER_ITEMS } from "../../../utils/accessRights"
import { displaySidebar } from "../../../actions/Utils/app"
import SupplierItemTransformationModes from "./TransformationModes/SupplierItemTransformationModes"


const useStyles = makeStyles({
    main: {
        boxSizing: "inherit",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    header: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
    tabs: {
        width: "100%",
        "& button": {
            margin: "0 20px"
        }
    },
    labelContainer: {
        fontSize: "1rem",
        transform: "matrix(0.75, 0, 0, 0.75, 0, 1.5 )",
        opacity: "0.54",
        transformOrigin: "0px 0px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    redirectButtonContainer: {
        height: 64,
        textAlign: "right"
    },
    redirectButton: {
        margin: 16,
    },
    valueContainer: {
        margin: 16
    },
    tabsContainer: {
        flex: 1
    }
})

export const SupplierItem = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const selectedSupplierItem = useSelector(state => state.supplierItems.selectedSupplierItem)
    const supplierItemsRelatives = useSelector(state => state.supplierItems.supplierItemsRelatives)
    const sites = useSelector(state => state.supplierItems.sites)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_SUPPLIER_ITEMS])
    const transformationModes = useSelector(getTransformationModesSelector)
    const [editTransformationMode, setEditTransformationMode] = useState(false)
    const canDelete = useSelector(state => state.app.rights[CAN_DELETE_SUPPLIER_ITEMS])
    const supplierItemSnackBar = useSelector(getSupplierItemSnackbarSelector)

    const toggleEditTransformationMode = () => {
        dispatch(displaySidebar(editTransformationMode))
        setEditTransformationMode(!editTransformationMode)
    }

    const [tabValue, setTabValue] = useState(0)

    const onTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    const onUpdateSupplierItemCommercialName = useCallback(async (values) => {
        await dispatch(updateSupplierItemCommercialName(selectedSupplierItem.objectId, values))
    }, [selectedSupplierItem])

    useEffect(() => {
        onUpdateSupplierItem()
    }, [])

    const onUpdateSupplierItem = useCallback(async (key, values) => {
        switch (key) {
            case "NEWS":
                return await dispatch(updateSupplierItemNews(selectedSupplierItem.objectId, values))
            case "COOKING_MODES":
                return await dispatch(updateSupplierItemCookingModes(selectedSupplierItem.objectId, values))
            case "UNITS":
                return dispatch(updateSupplierItemUnits(selectedSupplierItem.objectId, values))
            case "STOCKAGE":
                return await dispatch(updateSupplierItemStockage(selectedSupplierItem.objectId, values))
            // TODO: to remove
            case "BILLING_UNITS":
                return await dispatch(updateSupplierItemBillingUnits(selectedSupplierItem.objectId, values))
            case "RESUME":
                return dispatch(updateSupplierItemResume(selectedSupplierItem.objectId, values))
            default:
                return
        }
    }, [selectedSupplierItem])

    if (!selectedSupplierItem) return null

    return (
        <div className={classes.main}>
            <SupplierItemPageHeader
                withBackButton
                title="Articles Fournisseurs"
                subtitle={selectedSupplierItem.name}
                fixed={false}
                icon={<PeopleOutlineIcon />}
                className="headerHeight"
            />
            <Header
                canDelete={canDelete}
                supplierItem={selectedSupplierItem}

            />
            <Paper className={classes.header}>
                <Tabs
                    className={classes.tabs}
                    value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                    onChange={onTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Général" />
                    {selectedSupplierItem.type === "RAW_MATERIAL" &&
                        <Tab label="Paramétrage Cuisine" />
                    }
                    {selectedSupplierItem.type === "RAW_MATERIAL" &&
                        <Tab label="Modes de Transformation" />
                    }
                </Tabs>
            </Paper>
            <div className={classes.tabsContainer}>
                {tabValue === 0 && (
                    <SupplierItemGeneral
                        onUpdateSupplierItem={onUpdateSupplierItem}
                        supplierItem={selectedSupplierItem}
                        sites={sites}
                        canEdit={canEdit}
                    />
                )}
                {tabValue === 1 && (
                    <SupplierItemSetting
                      canEdit={canEdit}
                      supplierItem={selectedSupplierItem}
                      supplierItemsRelatives={supplierItemsRelatives}
                      onUpdateSupplierItemCommercialName={onUpdateSupplierItemCommercialName}
                      onUpdateSupplierItem={onUpdateSupplierItem}
                    />


                )}
                {tabValue === 2 && (
                    <SupplierItemTransformationModes
                        supplierItemTransformationModes={selectedSupplierItem?.transformationModes}
                        onEdit={toggleEditTransformationMode} // TODO: use for edit mode switch
                        transformationModes={transformationModes}
                        id={selectedSupplierItem?.objectId}
                    />
                )}
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={supplierItemSnackBar.open}
                autoHideDuration={supplierItemSnackBar.duration}
                onClose={() => dispatch(closeSupplierItemsSnackBar(supplierItemSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    onClose={() => dispatch(closeSupplierItemsSnackBar(supplierItemSnackBar.type))}
                    variant={supplierItemSnackBar.type}
                    message={supplierItemSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default SupplierItem