import React, { useEffect, useRef, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../../utils"
import moment from "moment"
import TextField from "@mui/material/TextField"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  saleDateRowInfos: {
    display: "flex",
    "&.saved": {
      background: "none",
      animation: "$pulse 2s ease",
    },
  },
  sellDateLabel: {
    fontSize: 12,
    color: COLORS.LIGHT_BLACK,
  },
  iconPackaging: {
    background: COLORS.BLACK,
    width: 11,
    height: 11,
    marginRight: 5,
    marginTop: 2,
  },
  iconPackagingCAPPED: {
    composes: "$iconPackaging",
    mask: "url(/img/capped.svg) no-repeat center",
  },
  iconPackagingDISPOSABLE: {
    composes: "$iconPackaging",
    mask: "url(/img/disposable.svg) no-repeat center",
  },
  iconPackagingREUSABLE: {
    composes: "$iconPackaging",
    mask: "url(/img/recycle.svg) no-repeat center",
  },
  dlcLabel: {
    fontSize: 10,
    color: COLORS.DARK_GREY,
    marginLeft: "auto",
  },
  prodInputContainer: {
    marginLeft: 5,
  },
  prodInfoInput: {
    width: 30,
  },
  prodInputProps: {
    height: 5,
    fontSize: 12,
    textAlign: "right",
  },
  divider2: {
    height: 1,
    width: "100%",
    backgroundColor: COLORS.MEDIUM_GREY,
    marginTop: 5,
    marginBottom: 5,
  },
  expectedProduction: {
    fontSize: 10,
    color: COLORS.DARK_GREY,
    "& span": {
      fontSize: 9,
    },
  },
  "@keyframes pulse": {
    "0%, 100%": { background: "none" },
    "50%": { background: COLORS.GREEN_100 },
  },
}))

export const PackagingPlanningLine = (props) => {
  const {
    saved,
    stateCard,
    item,
    dlcExist,
    packagingType,
    canEdit,
    findItemExpectedProduction,
    updateProd,
    removeFromSaved,
    saveProd,
  } = props

  const classes = useStyles()
  const lineRef = useRef()
  const [pulseVisible, setPulseVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (saved) {
      setPulseVisible(true)

      setTimeout(() => {
        setPulseVisible(false)
        removeFromSaved(item.id)
      }, 2000)
    }
  }, [saved])

  return (
    <>
      <div className={classes.saleDateRowInfos}>
        <div className={classes[`iconPackaging${item.value}`]}></div>
        <div className={classes.sellDateLabel}>{packagingType}</div>
      </div>
      <div
        ref={lineRef}
        className={clsx(classes.saleDateRowInfos, pulseVisible ? "saved" : "")}
      >
        <div className={classes.expectedProduction}>
          P : <span>{findItemExpectedProduction(item.id)}</span>
        </div>
        {dlcExist && (
          <div className={classes.dlcLabel}>
            DLC{" "}
            {moment
              .utc(
                stateCard.dlc.filter((el) => el.packagingId === item.id)[0]
                  .value
              )
              .format("DD/MM")}
          </div>
        )}
        <div className={classes.prodInputContainer}>
          {canEdit ? (
            <TextField
              variant="standard"
              type="number"
              value={
                item.packagingProduction === 0 && isFocused
                  ? ""
                  : item.packagingProduction
              }
              className={classes.prodInfoInput}
              inputProps={{
                className: classes.prodInputProps,
                inputprops: { min: 0 },
              }}
              onChange={(event) => {
                updateProd(event, item.id)
              }}
              onFocus={() => {
                setIsFocused(true)
              }}
              onBlur={(event) => {
                saveProd(event.target, item.id)
                setIsFocused(false)
              }}
            />
          ) : (
            <div>{item.packagingProduction}</div>
          )}
        </div>
      </div>
      <div className={classes.divider2} />
    </>
  )
}

export default PackagingPlanningLine
