import { getData } from ".."

export default function app(state = {
	loading: false,
	error: null,
	message: null,
	user: null,
	title: "KFC",
	menuIsOpen: false,
	displaySidebar: true,
	rights: {},
	appSnackBar: { open: false, type: "", message: "", duration: 0 },
	screen: "desktop",
	tabs: {},
}, action) {
	switch (action.type) {
		case "LOADING_START":
			return {
				...state,
				loading: true
			}
		case "LOADING_END":
			return {
				...state,
				loading: false
			}

		case "USER_RIGHTS_LOADED":
			return {
				...state,
				rights: action.rights
			}

		case "LOGIN_SUCCESS":
			return {
				...state,
				user: action.user,
				currentProvider: action.currentProvider
			}
		case "LOGOUT_SUCCESS":
			return {
				...state,
				user: null,
				currentProvider: null
			}
		case "ERROR":
			return {
				...state,
				error: action.message
			}
		case "MESSAGE":
			return {
				...state,
				message: action.message
			}
		case "CLOSE_ERROR":
			return {
				...state,
				error: null
			}
		case "CLOSE_MESSAGE":
			return {
				...state,
				message: null
			}
		case "USER_UPDATED":
			return {
				...state,
				user: action.user,
				userLocalVersion: state.userLocalVersion + 1
			}
		case "SITE_PASSWORD":
			return {
				...state,
				sitePassword: action.sitePassword
			}
		case "SCREEN_UPDATED":
			return {
				...state,
				screen: action.screen
			}
		case "UPDATE_MENU":
			return {
				...state,
				menuIsOpen: action.menuIsOpen
			}
		case "TOGGLE_SIDEBAR":
			return {
				...state,
				displaySidebar: action.displaySidebar
			}
		case "APP_MESSAGE":
		case "SUCCESS_MESSAGE":
		case "ERROR_MESSAGE":
		case "CLOSE_APP_SNACKBAR":
			return {
				...state,
				appSnackBar: action.appSnackBar
			}
		case "UPDATE_TAB":
			if (!action.payload.component || !action.payload.tabIndex) {
				return {
					...state,
					tabs: {},
				}
			} else {
				return {
					...state,
					tabs: {
						...state.tabs,
						[action.payload.component]: action.payload.tabIndex,
					},
				}
			}
		default:
			return {
				...state
			}
	}
}

//------------------------------------------------- -----------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getUserRights(state, errorIfNotFound = false) {
	return getData(state, "app.rights", errorIfNotFound && "No rights found")
}
export function getLoading(state, errorIfNotFound = false) {
	return getData(state, "app.loading", errorIfNotFound && "No action with loader found")
}
export function getCurrentUser(state, errorIfNotFound = false) {
	return getData(state, "app.user", errorIfNotFound && "No current user in app")
}
export function getUserLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "app.userLocalVersion", errorIfNotFound && "No user local version in app")
}
export function getError(state, errorIfNotFound = false) {
	return getData(state, "app.error", errorIfNotFound && "No error in app")
}
export function getMessage(state, errorIfNotFound = false) {
	return getData(state, "app.message", errorIfNotFound && "No message in app")
}
export function getScreen(state, errorIfNotFound = false) {
	return getData(state, "app.screen", errorIfNotFound && "Unknwon screen device")
}
export function getCurrentProvider(state, errorIfNotFound = false) {
	return getData(state, "app.currentProvider", errorIfNotFound && "No provider found")
}

export function getIsMenuOpen(state, errorIfNotFound = false) {
	return getData(state, "app.menuIsOpen", errorIfNotFound && "No menu found")
}

export function getTab(state, component, defaultTab) {
	const tabValue = getData(state, `app.tabs.${component}`)
  return tabValue || defaultTab
}