import { TableCell, styled } from "@mui/material"
import { COLORS } from "../../utils"

export const PETableHeaderCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "align" 
})(({ align }) => ({
  fontSize: 20,
  padding: "16px 14px",
  textAlign: align || "center",
  backgroundColor: COLORS.HEADER_BLUE,
  color: COLORS.LABEL_GREY
}))

export const PETableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "fontWeight" && prop !== "width" && prop !== "align" && prop !== "color" && prop !== "backgroundColor"
})(({disabled, fontWeight, width, align, color, backgroundColor }) => ({
  padding: "14px 16px",
  textAlign: align || "center",
  fontSize: "20px",
  fontWeight: fontWeight || "unset",
  width: width || "auto",
  color: color ? color : disabled ? COLORS.PRODUCTION_STEPS_DISABLE_TEXT : "unset",
  backgroundColor: backgroundColor || "unset"
}))

export const PETotalTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "align"
})(({ align }) => ({
  padding: "14px 16px",
  fontSize: "20px",
  fontWeight: 700,
  textAlign: align || "center",
  backgroundColor: COLORS.BACKGROUND_GREY
}))

export const PETableDivider = styled(TableCell)({
  padding: "0px",
  borderColor: "transparent",
  width: "24px"
})

export const PECommentHeaderCell = styled(TableCell)({
  fontSize: 20,
  padding: "16px 14px",
  textAlign: "center",
  backgroundColor: COLORS.BACKGROUND_GREY,
  color: COLORS.LABEL_GREY
})
