import React from "react"
import TechnicalSheetTabs from "../TechnicalSheetTabs"
import ProductsTagsList from "./ProductsTags/ProductsTagsList"

const ProductsTagsResourcesTab = () => {
    return (
        <TechnicalSheetTabs tabValue="productsTags">
            <ProductsTagsList/>
        </TechnicalSheetTabs>
    )
}

export default ProductsTagsResourcesTab