import React from "react"
import { makeStyles } from "@mui/styles"
import { Box, Button } from "@mui/material"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: 24,
		gap: 16,
		borderTop: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "#fff"
	},
})

const SupplierItemLotFooter = ({
	cancelText,
	onCancel,
	confirmText,
	onConfirm,
	sx
}) => {
	const classes = useStyles()

	return (
			<Box className={classes.footer} sx={sx}>
				{cancelText && (
					<Button variant="outlined" color="primary" onClick={onCancel}>
						{cancelText}
					</Button>
				)}
				<Button variant="contained" color="primary" onClick={onConfirm}>
					{confirmText}
				</Button>
			</Box>
	)
}

export default SupplierItemLotFooter