import React from "react"
import { Formik, Form, FieldArray } from "formik"
import { Stack } from "@mui/material"
import { FormControl, TextField, Select, InputLabel } from "@mui/material"
import { MenuItem } from "@mui/material"
import HoursMaskedField from "../../../components/form/MaskedField"
import dayjs from "dayjs"
import { PSE_REGEX_HOURS } from "../../../utils/productionStepExecution"
import { isEmpty } from "lodash"

export const productionStepExecutionCookingEndFormId = "productionStepExecutionCookingForm"

const ProductionStepExecutionCookingEndForm = ({
	machinesBatch = [],
	machinesOptions = [],
	onSubmit,
	onError
}) => {

	const handleValidate = (values) => {
		const errors = {}
		const machinesBatchErrors = []
		const { machinesBatch } = values
		for (const machineBatch of machinesBatch) {
			const index = machinesBatch.indexOf(machineBatch)
			const machineBatchError = {}
			if (!machineBatch.machineId || typeof machineBatch.machineId !== "string") {
				machineBatchError.machineId = "Champ obligatoire"
			}
			if (!machineBatch.machineName || typeof machineBatch.machineName !== "string") {
				machineBatchError.machineName = "Champ obligatoire"
			}
			if (!machineBatch.cookingEndTime || PSE_REGEX_HOURS.test(machineBatch.cookingEndTime) === false) {
				machineBatchError.cookingEndTime = "Veuillez entrer une heure valide"
			}
			if (!machineBatch.endTemperature || typeof machineBatch.endTemperature !== "number") {
				machineBatchError.endTemperature = "Veuillez saisir la température à laquelle votre préparation a fini sa cuisson."
			}
			if (!isEmpty(machineBatchError)) {
				machinesBatchErrors[index] = machineBatchError
			}
		}
		if (machinesBatchErrors.length > 0) {
			errors.machinesBatch = machinesBatchErrors
		}
		if (errors.machinesBatch && errors.machinesBatch.some((error) => !!error.endTemperature)) {
			onError && onError("Veuillez saisir la température à laquelle votre préparation a fini sa cuisson.")
		}
		return errors
	}

	return (
		<Formik
			initialValues={{
				machinesBatch: machinesBatch.map((machine) => ({
					machineId: machine.machine?.objectId,
					machineName: machine.machineName,
					cookingEndTime: dayjs().format("HH:mm"),
					endTemperature: null
				}))
			}}
			validate={handleValidate}
			onSubmit={onSubmit}
			validateOnBlur={false}
			validateOnChange={false}
		>
			{({ values, errors, handleChange, setFieldValue }) => {
				return (
					<Form
						id={productionStepExecutionCookingEndFormId}
					>
						<FieldArray name="machinesBatch">
							{() => (
								<Stack>
									{values.machinesBatch?.map((_, index) => (
										<Stack
											key={index}
											direction="row"
											alignItems="end"
											justifyContent="space-between"
											gap={3}
											sx={{ mb: 3 }}
										>
											<FormControl sx={{ flex: 1 }}>
											<InputLabel id="test-select-label" variant="standard">Machine</InputLabel>
												<Select
													variant="standard"
													value={values.machinesBatch[index].machineId}
													name={`machinesBatch.${index}.machineId`}
													onChange={(e) => {
														const selectedMachine = machinesOptions.find(machine => machine.objectId === e.target.value)
														setFieldValue(`machinesBatch.${index}.machineName`, selectedMachine.name)
														setFieldValue(`machinesBatch.${index}.machineId`, e.target.value)
													}}
												>
													{machinesOptions.map((machine) => (
														<MenuItem key={machine.objectId} value={machine.objectId}>
															{machine.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
											<FormControl sx={{ flex: 1 }}>
												<HoursMaskedField
													name={`machinesBatch.${index}.cookingEndTime`}
													value={values.machinesBatch[index].cookingEndTime}
													onChange={handleChange}
													label="Heure de fin de cuisson"
													width="100%"
													hasError={!!errors?.machinesBatch?.[index]?.cookingEndTime}
												/>
											</FormControl>
											<FormControl sx={{ flex: 1 }}>
												<TextField
													name={`machinesBatch.${index}.endTemperature`}
													value={values.machinesBatch[index].endTemperature}
													label="Température (°C)"
													onChange={handleChange}
													variant="standard"
													type="number"
													onWheel={(e) => e.target.blur()}
													sx={{
														"& .MuiInput-underline:before, .MuiInput-underline:after": {
															borderBottomColor: errors?.machinesBatch?.[index]?.endTemperature && "#F44259"
														}
													}}
												/>
											</FormControl>
										</Stack>
									))}
								</Stack>
							)}
						</FieldArray>
					</Form>
				)
			}}
		</Formik>
	)

}

export default ProductionStepExecutionCookingEndForm