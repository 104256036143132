import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS
} from "../../../utils"
import ProductionPlanningCard from "../PlanningCard/ProductionPlanningCard"
import { pxTOvw } from "../../../utils/planning"
import DroppableWrapper from "../../../components/Planning/DroppableWrapper"
import DraggableWrapper from "../../../components/Planning/DraggableWrapper"

const styles = {
    planningColumnContainerDefault: {
        width: pxTOvw((window.innerWidth - 118.4) / 7),
        backgroundColor: COLORS.LIGHT_GREY,
        borderLeft: "1px dashed #E7E7E7",
        borderRight: "1px dashed #E7E7E7",
        paddingLeft: 5,
        paddingRight: 5
    },
    planningColumnContainerValid: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.ENABLED_DRAG
    },
    planningColumnContainerError: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.DISABLED_DRAG
    },
    cardContainer: {
        backgroundColor: COLORS.WHITE,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 3,
        position: "relative",
        boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)"
    }
}

const ProductionPlanningBody = (props) => {
    const {
        data,
        isIntern,
        section,
        classes,
        currentCard,
        saveExpectedProduction,
        saveSumExpectedProduction,
        removeProductionItemIdFromSaved,
        openSplitModal,
        openAddModal,
        openDeleteModal,
        printSingleKitchenCard,
        rules,
        canPrint,
        canDeleteProductionItem,
        openProductInfo,
        setAnyFocused,
        filteredBrands,
        filteredSaleDate,
        isDragAndDroppable,
        canAdd,
        canSplit,
        canInputVolume,
        deleteProductionItem,
        showDays
    } = props

    const getColumnClasses = (columnId) => {
        const column = data.find(el => el.id === columnId)
        const cardDayInfo = showDays && showDays.find(elem => elem.timestamp === column.date)
        if (currentCard) {
            const timeslots = currentCard.currentAvailableProductionDates
            if (timeslots.includes(column.date) && !cardDayInfo.isLocked) {
                return classes.planningColumnContainerValid
            }
            else {
                return classes.planningColumnContainerError
            }
        }

        return classes.planningColumnContainerDefault
    }

    return (
        data.map((item) => {
            const productType = section.key || section.type
            const cards = item.cards[productType] ? item.cards[productType].filter(el => el.itemType === isIntern) : []

            return (
                <DroppableWrapper
                    key={item.id}
                    droppableId={item.id}
                    isDroppable={isDragAndDroppable}
                    columnClasses={getColumnClasses(item.id)}
                >
                    {
                        cards.map((card, index) => {
                            const cardDayInfo = showDays && showDays.find(elem => elem.timestamp === card.productionDate)
                            return (
                                isDragAndDroppable ?
                                    (
                                        <DraggableWrapper
                                            key={card.id}
                                            draggableId={card.id}
                                            index={index}
                                            isDraggable={isDragAndDroppable && (!cardDayInfo || !cardDayInfo.isLocked)}
                                            draggableClass={classes.cardContainer}
                                        >
                                            <ProductionPlanningCard
                                                deleteProductionItem={deleteProductionItem}
                                                card={card}
                                                saveExpectedProduction={saveExpectedProduction}
                                                saveSumExpectedProduction={saveSumExpectedProduction}
                                                openSplitModal={openSplitModal}
                                                openAddModal={openAddModal}
                                                openDeleteModal={openDeleteModal}
                                                printSingleKitchenCard={printSingleKitchenCard}
                                                rules={rules}
                                                canPrint={canPrint}
                                                canDeleteProductionItem={canDeleteProductionItem}
                                                openProductInfo={openProductInfo}
                                                setAnyFocused={setAnyFocused}
                                                filteredBrands={filteredBrands}
                                                filteredSaleDate={filteredSaleDate}
                                                removeProductionItemIdFromSaved={removeProductionItemIdFromSaved}
                                                canAdd={canAdd}
                                                canSplit={canSplit}
                                                canInputVolume={canInputVolume}
                                                showDays={showDays}
                                            />
                                        </DraggableWrapper>
                                    )
                                    : (
                                        <div
                                            key={index}
                                            className={classes.cardContainer}
                                        >
                                            <ProductionPlanningCard
                                                deleteProductionItem={deleteProductionItem}
                                                card={card}
                                                saveExpectedProduction={saveExpectedProduction}
                                                saveSumExpectedProduction={saveSumExpectedProduction}
                                                openSplitModal={openSplitModal}
                                                openAddModal={openAddModal}
                                                openDeleteModal={openDeleteModal}
                                                printSingleKitchenCard={printSingleKitchenCard}
                                                rules={rules}
                                                canDeleteProductionItem={canDeleteProductionItem}
                                                openProductInfo={openProductInfo}
                                                setAnyFocused={setAnyFocused}
                                                filteredBrands={filteredBrands}
                                                filteredSaleDate={filteredSaleDate}
                                                removeProductionItemIdFromSaved={removeProductionItemIdFromSaved}
                                                canAdd={canAdd}
                                                canSplit={canSplit}
                                                canInputVolume={canInputVolume}
                                            />
                                        </div>
                                    )
                            )
                        })
                    }
                </DroppableWrapper>
            )
        })
    )
}

export default withStyles(styles)(ProductionPlanningBody)
