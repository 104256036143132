import React from "react"
import { useDispatch } from "react-redux"
import { Box, styled } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

import { COLORS } from "../../utils"
import {
    showPlanningProduction,
    showPlanningPackaging,

} from "../../actions/Planning/Planning"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import PlanningHeaderTitle from "../../components/Plannings/PlanningHeaderTitle"

const StyledTabsContainer = styled("div")({
    padding: "0 20px",
    borderBottom: `1px solid ${COLORS.DEFAULT_GREY}`,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    flexGrow: 1
})

const StyledTab = styled(Tab)({
    paddingLeft: 45,
    paddingRight: 45
})

const PlanningsTabs = (props) => {
    const {
        tabValue,
        children,
    } = props

    const dispatch = useDispatch()

    const _onTabChange = (event, tabValue) => {
        switch (tabValue) {
            case "packaging":
                dispatch(showPlanningPackaging())
                break
            default: // production
                dispatch(showPlanningProduction())
                break
        }
    }

    return (
        <Box display="flex" flexDirection="column" >
            <Box position="relative">
                <Box position="fixed" width="100%" zIndex={300} bgcolor="#fff">
                    <Box sx={{ height: 64 }}>
                        <GenericTopBarHeader
                            title={<PlanningHeaderTitle />}
                        />
                    </Box>
                    <StyledTabsContainer className="flexRow justifyCenter">
                        <Tabs
                            value={tabValue}
                            onChange={_onTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            centered
                        >
                            <StyledTab label="Production" value="production" />
                            <StyledTab label="Barquettage" value="packaging" />
                        </Tabs>
                    </StyledTabsContainer>                           
                </Box>
            </Box>
            {children}
        </Box>
    )
}

export default PlanningsTabs
