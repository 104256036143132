import Parse from "parse"
import { escapeRegExp } from "lodash"

export const ProductType = Parse.Object.extend("ProductTypes")

export const getFilteredProductTypes = async (filters, include = []) => {
	try {
		const query = await new Parse.Query(ProductType)
			.ascending("order")
			.include(include)

		if (filters?.search) {
			const regex = new RegExp(escapeRegExp(filters.search), "ig")
			query.matches("name", regex)
		}

		if (filters?.rowsPerPage) {
			query.limit(filters?.rowsPerPage)
		} else {
			query.limit(25)
		}

		if (filters?.page) {
			query.skip(filters.page)
		}

		const productTypes = await query.withCount().find()

		return productTypes
	}
	catch (e) {
		console.error("parseManager.products.productTypeManage.getFilteredProductTypes error : ", e)
		return Promise.reject(e)
	}
}

/**
 * 
 * @param {*} filters collection fields
 * @param {*} include 
 * @returns 
 */
export const getProductTypes = async (filters) => {
	try {
		const query = await new Parse.Query(ProductType)
			.notEqualTo("deleted", true)
			.ascending("order")

		if (filters) {
			Object.keys(filters).forEach(key => {
				const value = filters[key]
				if (value) {
					if (Array.isArray(value)) {
						query.containedIn(key, filters[key])
					} else {
						query.equalTo(key, filters[key])
					}
				}
			})
		}
		
		const productTypes = await query.find() || []

		return productTypes.map((productType) => productType.toJSON())
	}
	catch (e) {
		console.error("parseManager.products.productTypeManage.getProductTypes error : ", e)
		return Promise.reject(e)
	}
}

export const getProductTypeById = async (
  id,
  toJson = true
) => {
  try {
    const productType = await new Parse.Query(ProductType)
      .equalTo("objectId", id)
      .first()

    return toJson ? productType.toJSON() : productType
  } catch (e) {
    console.error(
      "parseManager.productType.productTypesManager.getMachinesById error : ",
      e
    )
    return Promise.reject(e)
  }
}