import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import { COLORS } from "../../utils"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import { Field, Form, Formik } from "formik"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

const tabs = [
    {key: "seasonalIngredientsRate", label: "Légumes et fruits de saison"},
    {key: "certifiedIngredientsRate", label: "Ingrédients labellisés"},
    {key: "frenchIngredientsRate", label: "Ingrédients français"},
    {key: "organicIngredientsRate", label: "Ingrédients bio"},
    {key: "localIngredientsRate", label: "Circuits courts"}
]

const NutritionalSourcing = (props) => {
    const {
        classes,
        nutritionInformation,
        onSaveNutritionsSourcing,
        checkProductInCard,
        showModalProductInCard
    } = props
    const stepValue = 50
    const [isEditing, setIsEditing] = useState(false)
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }
    
    const submitForm = (values) => {
        onSaveNutritionsSourcing(values, nutritionInformation)
        setIsEditing(false)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Valeurs sourcing
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>

            {
                tabs.map((tab, index) => {
                    return (
                        <div key={index} className={classes.valueContainer}>
                            <div className={classes.nutritionLabel}>
                                {tab.label}
                            </div>
                            <div className={nutritionInformation[tab.key] >= stepValue
                                ? classes.greenNutritionValue
                                : classes.redNutritionValue
                            }>
                                {nutritionInformation[tab.key]}&nbsp;%
                            </div>

                        </div>
                    )
                })
            }
            <div className={classes.valueContainer}>
                <div className={classes.nutritionLabel}>
                    Empreinte CO2
                </div>
                <div className={classes.nutritionValue}>
                    {nutritionInformation.carbonFootPrint}&nbsp;g
                </div>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.nutritionLabel}>
                    MSC/pêche durable
                </div>
                <div className={classes.nutritionValue}>
                    {(nutritionInformation.sustainableFishing)?"Oui":"Non"}
                </div>
            </div>

            <Formik
                initialValues={nutritionInformation}
                onSubmit={submitForm}
            >
                {({
                      values,
                      handleChange,
                      setFieldValue,
                      handleSubmit
                  }) => {
                    return (
                        <Form>
                            <Dialog onClose={() => setIsEditing(false)} aria-labelledby="customized-dialog-title"
                                    open={isEditing}>
                                <DialogTitle id="customized-dialog-title" onClose={() => setIsEditing(false)}>
                                    Editer les valeurs nutritionnelles
                                </DialogTitle>
                                <DialogContent dividers>
                                    {
                                        tabs.map((tab, index) =>
                                            <TextField
                                                variant="standard"
                                                multiline
                                                autoFocus
                                                fullWidth
                                                key={index}
                                                label={tab.label}
                                                name={tab.key}
                                                value={values[tab.key]}
                                                onChange={handleChange}
                                                className={classes.formControl}
                                                InputProps={{
                                                    disableUnderline: false,
                                                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                    type: "number"
                                                }}
                                            />
                                        )
                                    }
                                    <TextField
                                        multiline
                                        variant="standard"
                                        autoFocus
                                        fullWidth
                                        label={"Empreinte CO2"}
                                        name={"carbonFootPrint"}
                                        value={values.carbonFootPrint}
                                        onChange={handleChange}
                                        className={classes.formControl}
                                        InputProps={{
                                            disableUnderline: false,
                                            endAdornment: (<InputAdornment position="end">g</InputAdornment>),
                                            type: "number"
                                        }}
                                    />
                                    <FormControl margin="normal" className={classes.formControl}>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    name="sustainableFishing"
                                                    type="checkbox"
                                                    component={Checkbox}
                                                    checked={values.sustainableFishing}
                                                    onChange={(e) => setFieldValue("sustainableFishing", e.target.checked)}
                                                />
                                            }
                                            label="MSC / Pêche durable"
                                            labelPlacement="start"
                                            className={classes.checkBoxLabel}
                                        />
                                    </FormControl>
                                </DialogContent>
                                <DialogActions classes={{root: classes.rootDialogActions}}>
                                    <Button
                                        onClick={() => setIsEditing(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Sauvegarder
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </Paper>
    )
}
const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },
    titleContainer: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 16,
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    valueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 4px"
    },
    nutritionLabel: {
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "130%"

    },
    nutritionValue: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: 14,
        lineHeight: "130%",
        fontWeight: "bold"
    },
    redNutritionValue: {
        composes: "$nutritionValue",
        color: COLORS.RED_NUTRITION
    },
    greenNutritionValue: {
        composes: "$nutritionValue",
        color: COLORS.GREEN_NUTRITION
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    rootDialogActions: {
        justifyContent: "space-between"
    },
    formControl: {
        minWidth: 260,
        marginLeft: "unset",
        marginTop: 16,
        display: "block"
    }
}
export default withStyles(styles)(NutritionalSourcing)
