import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { loadProductTypes } from "../../../../actions/ProductTypes/productTypes"
import TablePagination from "../../../../components/TablePagination"
import { getProductTypesFiltersSelector, getProductTypesTotalSelector } from "../../../../reducers/ProductTypes/productTypes"
import { DEFAULT_ROW_PER_PAGE_OPTIONS } from "../../../../utils/constant"

const ProductTypesPagination = () => {
    const dispatch = useDispatch()
    const filters = useSelector(getProductTypesFiltersSelector)
    const total = useSelector(getProductTypesTotalSelector)

    const handleChangePage = (_, newPage) => {
        const filters = { page: newPage }
        dispatch(loadProductTypes(filters))
    }

    const handleChangeRowsPerPage = event => {
        const filters = { rowsPerPage: parseInt(event.target.value, 10), page: 0 }
        dispatch(loadProductTypes(filters))
    }

    return (
        <TablePagination
            component="div"
            rowsPerPageOptions={DEFAULT_ROW_PER_PAGE_OPTIONS}
            count={total}
            rowsPerPage={filters.rowsPerPage}
            page={filters.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Etape réutilisable par page"
        />
    )
}

export default ProductTypesPagination