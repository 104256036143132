import React from "react"
import ProductionStepExecutionCoolingForm from "./ProductionStepExecutionCoolingForm"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { Stack, Typography } from "@mui/material"
import { StyledContainer } from "../preview/StyledExecutionPreviewComponents"
import ProductionStepExecutionCoolingEndForm from "./ProductionStepExecutionCoolingEndForm"

const ProductionStepExecutionCoolingFormContainer = ({
	productionStepExecution,
	machinesOptions,
	handleSubmit,
	updateErrorMessage,
	isEnd = false,
	visible
}) => {

	if (!visible) {
		return null
	}

	return (
		<StyledContainer sx={{
			display: "flex",
			flexDirection: "column",
			gap: "24px"
		}}>
			<Stack direction="row" gap={1} alignItems="center">
				<AcUnitIcon sx={{
						width: 16,
						height: 16
					}} />
				<Typography sx={{
					color: "#262626",
					fontSize: 14,
					fontWeight: 500,
					lineHeight: "22px"
				}}>
					Refroidissement
				</Typography>
			</Stack>
			{!isEnd && <ProductionStepExecutionCoolingForm
				machinesBatch={productionStepExecution.machinesBatch}
				machinesOptions={machinesOptions}
				onSubmit={handleSubmit}
				onError={updateErrorMessage}
			/>}
			{isEnd && <ProductionStepExecutionCoolingEndForm
				machinesBatch={productionStepExecution.machinesBatch}
				machinesOptions={machinesOptions}
				onSubmit={handleSubmit}
				onError={updateErrorMessage}
			/>}
		</StyledContainer>
	)
}

export default ProductionStepExecutionCoolingFormContainer