import Parse from "parse"
import { parseLimitRequest } from "../../utils"
import { useProductionItemProductFoodCost } from "./parsePlanningManager"
const ProductionItem = Parse.Object.extend("ProductionItem")
const ProductionDay = Parse.Object.extend("ProductionDay")
const Locker = Parse.Object.extend("Locker")
const DistributionCenters = Parse.Object.extend("DistributionCenters")

export async function getProductionItems(startDate, endDate) {
    try {
        const productionItems = []

        await new Parse.Query(ProductionItem)
            .greaterThanOrEqualTo("saleDate", startDate)
            .lessThanOrEqualTo("saleDate", endDate)
            .include(["productDispatch", "recipe", "subcontractorProduct"])
            .each(async productionItem => {
                const productionItemJson = productionItem.toJSON()
                const newProductionItem = useProductionItemProductFoodCost(productionItemJson)

                productionItems.push(newProductionItem)
            })

        return productionItems
    }

    catch (e) {
        return Promise.reject(e)
    }
}

export async function getLockers(productDispatchIds) {
    const lockers = (await new Parse.Query(Locker)
        .equalTo("type", "dispatch")
        .equalTo("isOver", false)
        .containedIn("idProductDispatch", productDispatchIds)
        .limit(parseLimitRequest)
        .find()) || []

    return lockers.map(elem => elem.toJSON())
}

export async function getDistributionCentersByBrand(brand) {
    try {
        const distributionCenters = await new Parse.Query(DistributionCenters)
            .equalTo("brand", brand)
            .ascending("dispatch.priority")
            .limit(parseLimitRequest)
            .find()

        return distributionCenters ? distributionCenters.map(el => el.toJSON()) : []
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function unlockLocker(lockerId) {
    const locker = await new Parse.Query(Locker)
        .equalTo("objectId", lockerId)
        .first()

    if (locker) {
        locker.set("isOver", true)
        locker.set("closedFromViewKO", true)
        await locker.save()
    }
}

export async function getProductionDays(startDate, endDate) {
    try {
        const data = (await new Parse.Query(ProductionDay)
            .greaterThanOrEqualTo("date", startDate)
            .lessThanOrEqualTo("date", endDate)
            .limit(parseLimitRequest)
            .find()) || []

        return data.map(e => e.toJSON())
    } catch(e) {
        return Promise.reject(e)
    }
}

export async function getProductionDay(objectId) {
    try {
        const productionDay = await new Parse.Query(ProductionDay)
            .equalTo("objectId", objectId)
            .first()
        return productionDay
    } catch(err) {
        return Promise.reject(err)
    }
}
export async function createNewProductionDay(date) {
    const defaultCapacities = [
        {
            key: "STARTER",
            value: 0
        },
        {
            key: "MAIN_COURSE",
            value: 0
        },
        {
            key: "DESSERT",
            value: 0
        }, {
            key: "BREAD",
            value: 0
        },
        {
            key: "CHEESE",
            value: 0
        },
        {
            key: "DRINK",
            value:0
        },
        {
            key: "SNACK",
            value: 0
        },
        {
            key: "KIDS",
            value: 0
        },
        {
            key: "LUNCH_BAG",
            value: 0
        }
]
    const productionDay = new ProductionDay()
    productionDay.set("date", date)
    productionDay.set("capacities", defaultCapacities)
    return productionDay
}

export async function saveProductionDay(productionDay) {
    await productionDay.save()
}
