import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import ButtonFlawAction from "./ButtonFlawAction"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import SelectFlaw from "./SelectFlaw"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  withSelection: {
    fontSize: 30,
    fontWeight: "normal",
    lineHeight: "128.91%",
    marginTop: 57,
    "&.is-tablet": {
      marginTop: 52,
      fontSize: 37,
    },
    "& p": {
      margin: 0,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  input: {
    fontWeight: "normal",
    fontSize: 16,
    color: COLORS.BLACK_CARD,
    lineHeight: "24px",
    width: 406,
    marginTop: 57,
    textAlign: "center",
  },
}))

const InputFlawNumber = (props) => {
  const { handleValidate, isTablet, setValue, lostActionInfos } = props
  const classes = useStyles()
  const inputRef = useRef()

  const [disabled, setDisabled] = useState(true)

  useEffect(() => inputRef.current.focus(), [])

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onReasonChange = (flawStatus) => {
    lostActionInfos[1](flawStatus)

    if (flawStatus !== "") setDisabled(false)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection, { "is-tablet": isTablet })}>
        <p>
          <span>{lostActionInfos[0]}</span>
        </p>
        <p>
          <span>Produits attendus manquants</span>
        </p>
        <p>Entrez un motif</p>
      </div>

      {/* select flaw origin*/}
      <SelectFlaw
        preselected={""}
        isTablet={isTablet}
        onReasonChange={onReasonChange}
      />

      {/* text summary */}
      <TextField
        inputProps={{
          className: classes.input,
          placeholder: "commentaire (facultatif)",
          type: "text",
        }}
        variant="standard"
        onChange={onChange}
        inputRef={inputRef}
      />

      {/* button submit */}
      <ButtonFlawAction isTablet={isTablet} disabled={disabled}>
        Je confirme {lostActionInfos ? lostActionInfos[0] : ""} casses
      </ButtonFlawAction>
    </form>
  )
}

export default InputFlawNumber
