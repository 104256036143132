import React from "react"
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { COLORS } from "../../../utils"
import { Form, Formik, Field } from "formik"
import OrderReceptionSupplierItemAutoComplete from "../../autoComplete/OrderReceptionSupplierItemAutocomplete"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 500,
        borderRadius: 5,
        padding: 15
        
    },  
    title: {
        marginTop: 10,
        fontWeight: 500,
        fontSize: 15
    },
    formContainer: {
        marginTop: 15,
        display: "flex",
        flexDirection: "column"
    },
    buttonsContainer: {
        marginTop: 20,
        alignSelf: "flex-end"
    },
    returnButon: {
        marginRight: 15
    }
}))

const SupplierItemModal = (props) => {
    const {
        supplier,
        onClose,
        addSupplierItem
    } = props

    const classes = useStyles()
		
    const submitForm = (values) => {
        const supplierItemId = values.supplierItem.objectId
        if (supplierItemId) {
            addSupplierItem(supplierItemId)
        }
        onClose()
    }

    const onSupplierItemSelected = (value, setFieldValue) => {
        setFieldValue("supplierItem", value)
    }

    const defaultValues = {
        supplierItem: null
    }

    return (
        <Modal open={true} className={classes.container}>
            <div className={classes.modalContainer}>
                <Typography className={classes.title}>
                    Indiquez le nom du produit à ajouter.
                </Typography>
                <Formik
                    onSubmit={submitForm}
                    initialValues={defaultValues}
                >
                    {({
                        handleSubmit
                    }) => {
                        return (
                            <Form className={classes.formContainer}>
                                <Field
                                    name="supplierItem"
                                    component={OrderReceptionSupplierItemAutoComplete}
                                    supplier={supplier}
                                    onSupplierItemSelected={onSupplierItemSelected}
                                />
                                <div className={classes.buttonsContainer}>
                                    <Button
                                        className={classes.returnButon}
                                        variant="outlined"
                                        color="primary"
                                        onClick={onClose}
                                    >
                                        Retour
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Ajouter
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
                
            </div>
        </Modal>
    )
}

export default SupplierItemModal