import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import IconButton from "@mui/material/IconButton"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../utils"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Icon from "@mui/material/Icon"
import Dashboard from "@mui/icons-material/Dashboard"
import clsx from "clsx"
import { Box } from "@mui/material"

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarInfos: {
        display: "flex",
        alignItems: "center"
    },
    appBarTitle: {
        color: COLORS.GREY_700,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "uppercase"
    },
    titleIcon:  {
        color: COLORS.YELLOW_OUTPUT,
        marginLeft: 20,
        marginRight: 10,
        "&.is-inventory": {
            color: COLORS.SUPPLIER_DELIVERY_COlOR
        }
    },
    title: {
        paddingRight: 10,
        color: COLORS.YELLOW_OUTPUT,
        "&.is-inventory": {
            color: COLORS.SUPPLIER_DELIVERY_COlOR
        }
    }
}))

const Header = (props) => {
    const {
        onClickReturn,
        site,
        stockZone,
        date,
        isInventory,
        rightAction,
        fixed = true
    } = props

    const classes = useStyles()
    const stockZoneLabel = stockZone && stockZone.name ? stockZone.name : "TOUTES LES ZONES (CONSULTATION)"
    const content = (
        <>
            <IconButton onClick={onClickReturn} size="large">
                <ArrowBack />
            </IconButton>
            <Box className={classes.appBarInfos} sx={{ flex: 1 }}>
                <Icon
                    className={clsx(classes.titleIcon, isInventory ? "is-inventory" : "")}
                >
                    <Dashboard />
                </Icon>
                <Typography variant="h6" color="textPrimary" className={clsx(classes.title, isInventory ? "is-inventory" : "")}>
                    { isInventory ? "Inventaire " : "Sortie "}
                </Typography>
                <Typography className={classes.appBarTitle}>
                    {` - ${site && site.name}, ${stockZoneLabel} - ${date}`}
                </Typography>
            </Box>
            {rightAction}
        </>
    )

    return (
        fixed
            ? (
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton onClick={onClickReturn} size="large">
                            <ArrowBack />
                        </IconButton>
                        <Box className={classes.appBarInfos} sx={{ flex: 1 }}>
                            <Icon
                                className={clsx(classes.titleIcon, isInventory ? "is-inventory" : "")}
                            >
                                <Dashboard />
                            </Icon>
                            <Typography variant="h6" color="textPrimary" className={clsx(classes.title, isInventory ? "is-inventory" : "")}>
                                { isInventory ? "Inventaire " : "Sortie "}
                            </Typography>
                            <Typography className={classes.appBarTitle}>
                                {` - ${site && site.name}, ${stockZoneLabel} - ${date}`}
                            </Typography>
                        </Box>
                        {rightAction}
                    </Toolbar>
                </AppBar>
            ) : (
                <Box>
                    <Toolbar>
                        {content}
                    </Toolbar>
                </Box>
            )
    )
}

export default Header