import React from "react"

export const BigSearchIcon = ({selected}) => {
    return (
            <img
                src={selected?"/img/BigSearchBlack.svg":"/img/BigSearch.svg"}
                alt="Lost"
            />
    )
}

export default BigSearchIcon