import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import CheckboxAction from "./CheckboxAction"
import LightTooltip from "../LightTooltip"
import ReceptionCheckEnhancedTableHead from "../table-utils/Reception/ReceptionCheckEnhancedTableHead"
import {
	COLORS
} from "../../utils"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"

const useStyles = makeStyles({
	tableWrapper: {
		overflowX: "auto",
	},
	idColumn: {
		color: COLORS.BLACK,
		cursor: "pointer",
		fontSize: 24,
		lineHeight: "100%",
		paddingRight: 10,
		paddingLeft: 15,
		textAlign: "center"
	},
	nameColumn: {
		fontSize: 16,
		color: COLORS.DARK_GREY,
		letterSpacing: 0.25,
		lineHeight: "100%"
	},
	textColumn: {
		fontSize: 12,
		color: COLORS.BLACK,
		letterSpacing: 0.25,
		lineHeight: "100%",
		paddingRight: 10,
		paddingLeft: 15,
		textAlign: "center",
		"&.left": {
			textAlign: "left",
		}
	},
	gapColumn: {
		fontSize: 17,
		color: COLORS.BLACK,
		letterSpacing: 0.25,
		lineHeight: "100%",
		paddingRight: 10,
		paddingLeft: 15,
		textAlign: "center"
	},
	centered: {
		textAlign: "center"
	}

})

const ReceptionCheckTable = (props) => {
	const classes = useStyles()
	const { data, tableType, onCheckEvent } = props

	return (
			<div className={classes.tableWrapper}>
				<Table aria-labelledby="tableTitle">
					<ReceptionCheckEnhancedTableHead
						tableType={tableType}
					/>
					<TableBody>
						{
							data.map(event => {
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={event.pureFlaw}
										tabIndex={-1}
										key={event.idProductStock.concat(event.indexes.join("+"))}
										selected={event.pureFlaw}
									>
										<TableCell className={classes.idColumn}>{event.uniqueCode}</TableCell>
										<TableCell className={classes.nameColumn} >{event.commercialTitle}</TableCell>
										<TableCell className={classes.textColumn}>{event.hub}</TableCell>
										<TableCell className={classes.gapColumn} >{(event.gap > 0)?`+${event.gap}`:event.gap}</TableCell>
										<TableCell className={clsx(classes.textColumn, "left")} >{event.reason}</TableCell>
										<LightTooltip title={event.user}>
											<TableCell className={clsx(classes.textColumn, "left")} >
												{event.date}
											</TableCell>
										</LightTooltip>
										{tableType !== "TO_CHECK" &&
										<TableCell className={clsx(classes.textColumn, "left")} >{event.comment}</TableCell>
										}
										<LightTooltip title={event.checker}>
											<TableCell className={classes.centered}>
												<CheckboxAction
													color="primary"
													checked={tableType !== "TO_CHECK"}
													canCheck={tableType === "TO_CHECK"}
													onCheck={onCheckEvent}
													element={event}
												/>
											</TableCell>
										</LightTooltip>
									</TableRow>
								)
							})}
					</TableBody>
				</Table>
			</div>
	)
}

export default ReceptionCheckTable
