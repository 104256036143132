import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Select from "react-select"
import { emphasize } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import NoSsr from "@mui/material/NoSsr"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import MenuItem from "@mui/material/MenuItem"
import CancelIcon from "@mui/icons-material/Cancel"
import { COLORS } from "../../utils"

const styles = (theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
    marginTop: 12,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "100%",
    borderBottom: "none",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  errorEmpty: {
    margin: 0,
    fontSize: "0.75em",
    textAlign: "left",
    position: "absolute",
    marginTop: 8,
    minHeight: "1em",
    lineHeight: "1em",
    color: COLORS.RED,
  },
})

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control({ isDisabled, ...props }) {
  return (
    <TextField
      variant="standard"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
      disabled={isDisabled}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      key={props.data.id}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      key={props.selectProps.value[0].id}
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      elevation={0}
    >
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

class MultiSelectAutoComplete extends PureComponent {
  handleChange = (selectedValue) => {
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props
    setFieldValue(name, selectedValue)
  }

  render() {
    const {
      classes,
      theme,
      field: { name, value },
      placeholder,
      disabled,
      options,
    } = this.props
    const selectStyles = {
      height: "100%",
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit",
        },
      }),
    }

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            name={name}
            classes={classes}
            styles={selectStyles}
            components={components}
            options={options}
            textFieldProps={{
              label: placeholder,
              InputLabelProps: {
                shrink: true,
              },
            }}
            onChange={this.handleChange}
            value={value}
            placeholder={placeholder}
            isClearable={false}
            isMulti={true}
            isDisabled={disabled}
          />
        </NoSsr>
      </div>
    )
  }
}

MultiSelectAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(MultiSelectAutoComplete)
