import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    Grid,
    Table,
    TableHeaderRow,
    TableFixedColumns,
    TableSummaryRow,
    TableColumnResizing,
    VirtualTable
} from "@devexpress/dx-react-grid-material-ui"
import {
    SummaryState,
    IntegratedSummary,
    SortingState,
    IntegratedSorting
} from "@devexpress/dx-react-grid"
import {
    TableComponent,
    ItemComponent,
    LockedCellComponent,
    HeaderRowComponent,
    EditingCellComponent,
    HighlightedCellComponent,
    ChipCellComponent,
    SelectStatusCellComponent,
    LockedFixedCellComponent,
    ProductCellComponent
} from "./KitchenOutputTableComponents"
import { COLORS } from "../../../../utils"

const styles = {
    table: {
        position: "relative",
        width: "100%",
        marginTop: 25,
        height: "calc(100vh - 200px)",
        overflowY: "scroll",
        "& table:nth-child(2)": {
            marginBottom: "0 !important"
        },
        "& > div": {
            height: "calc(100vh - 200px)"
        }
    }
}

/**
 * Override the Cell component and implements a custom behaviour
 * to display either a "normal", an "edition" or a "locked" cell.
 * @param {*} myprops Custom props passed to the cell
 * @param {*} tableProps Props defined by the table
 */
export const Cell = ({
    onCellUnlock,
    onCommitChange,
    onStatusChange,
    editingCell,
    linkedCells,
    pastDate,
    onRowUnlock,
    canEdit,
    openProductInfo,
}) => (tableProps) => {
    const { row, column } = tableProps
    const data = row[column.name]

    const handleClick = (row) => {
        if (column.name === "product") {
            openProductInfo(row)
        }
    }

    // Process hub columns
    if (data && "object" === typeof data) { // hub columns

        if (null !== editingCell && row.index === editingCell.x && column.name === editingCell.y) {
            return <EditingCellComponent onCommitChange={onCommitChange} {...tableProps} />
        }
        else if (data.closed) {
            return <Table.Cell {...tableProps} value="" style={{backgroundColor: COLORS.RED_PLANNING}}/>
        }
        else if (data.locked) {
            return <LockedCellComponent onCellUnlock={() => canEdit && onCellUnlock(row.index, column.name)} cellData={data} {...tableProps} />
        }
    }

    // Process linked cells
    for (const i in linkedCells) {
        if (i === column.name) {
            return <HighlightedCellComponent statusOk={row[i] === row[linkedCells[i]]} {...tableProps} />
        }
    }

    // Process nationalSend, sendCapital column (display a chip, for better visibility)
    if ("nationalSend" === column.name || "smallRetail" === column.name) {
        return <ChipCellComponent {...tableProps} />
    }

    if ("product" === column.name && row.lockerId) {
        return <LockedFixedCellComponent onCellUnlock={() => canEdit && onRowUnlock(row.lockerId)} cellData={data} {...tableProps} />
    }
    
    if ("product" === column.name && !row.lockerId) {
        return <ProductCellComponent {...tableProps} onCellClick={(row) => {handleClick(row)}}/>
    }

    // Process status column (show chip if TODO or select otherwise)
    if ("status" === column.name) {
        return "TODO" === data || pastDate
            ? <ChipCellComponent {...tableProps} />
            : <SelectStatusCellComponent {...tableProps} onStatusChange={onStatusChange} rowIndex={row.index} canEdit={canEdit} />
    }
    
    return <Table.Cell {...tableProps} className={tableProps.className}/>
}

const KitchenOutputTable = (props) => {

    const {
        rows,
        columns,
        leftColumns,
        rightColumns,
        totalSummaryItems,
        defaultColumnWidths,
        onCellUnlock,
        onCommitChange,
        onStatusChange,
        editingCell,
        linkedCells,
        pastDate,
        classes,
        onRowUnlock,
        selectedHubs,
        canEdit,
        openProductInfo
    } = props

    return (
        <div className={classes.table}>
            <Grid
                rows={rows}
                columns={columns}
            >
                <SortingState
                    defaultSorting={[{ columnName: "uniqueCode", direction: "asc"}]}
                />
                <IntegratedSorting />
                <SummaryState totalItems={totalSummaryItems} />
                <IntegratedSummary />
                <VirtualTable
                    tableComponent={TableComponent}
                    cellComponent={Cell({
                        hubs: selectedHubs,
                        onCellUnlock: onCellUnlock,
                        onCommitChange: onCommitChange,
                        onStatusChange: onStatusChange,
                        editingCell: editingCell,
                        linkedCells: linkedCells,
                        pastDate: pastDate,
                        onRowUnlock: onRowUnlock,
                        canEdit: canEdit,
                        openProductInfo,
                        classes
                    })}
                    messages={{ noData: "Rien à afficher."}}
                    columnExtensions={defaultColumnWidths}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow rowComponent={HeaderRowComponent} showSortingControls/>
                <TableSummaryRow itemComponent={ItemComponent} />
                <TableFixedColumns
                    leftColumns={leftColumns}
                    rightColumns={rightColumns}
                />
            </Grid>
        </div>
    )
}

export default withStyles(styles)(KitchenOutputTable)
