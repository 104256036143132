import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loadFilteredCreditNotes, 
    selectSingleCreditNoteOrder,
    changeOrderIsPaidStatus,
    extractFilteredCreditNotesCSVFile,
} from "../../../actions/BillingCreditNotes/BillingCreditNotes"
import { getBillingCreditNotesSelector, getBillingSitesParseObjectsSelector, getBillingSitesSelector, getBillingSuppliersSelector } from "../../../reducers/BillingCreditNotes/billingCreditNotes"
import DataGrid from "../../../components/DataGrid/DataGrid"
import dayjs from "dayjs"
import { debounce } from "lodash"
import CreditNotePaidSwitch from "./CreditNotePaidSwitch"
import { prepareBillingCreditNotesFiltersForLoading } from "../../../utils/creditNoteUtils"
import {formatFiltersBeforeStorage, getDataGridFiltersStored} from "../../../utils/dataUtils"
import _cloneDeep from "lodash/cloneDeep"

const createData = (order) => {
    return {
        id: order.objectId,
        orderNumber: order.orderNumber,
        deliveryNoteNumber: order.deliveryNote ? order.deliveryNote.number : "",
        site: order.site.name,
        supplier: order.supplier.name,
        receptionDateUnique: order.receptionDate ? dayjs(order.receptionDate).format("DD/MM/YYYY") : "NO_DATE_FOUND",
        creditNotesAmount: order.creditNotesAmount ? parseFloat(order.creditNotesAmount.toFixed(3)) + " €" : "0 €",
        isCreditNotesPaid: !!order.isCreditNotesPaid
    }
}


const CreditNotesTab = () => {


    const dispatch = useDispatch()
    const orders = useSelector(getBillingCreditNotesSelector)
    const suppliers = useSelector(getBillingSuppliersSelector)
    const sites = useSelector(getBillingSitesSelector)
    const sitesParseObjects = useSelector(getBillingSitesParseObjectsSelector)

    const fixedAdditionalFilters = {
        receptionDate: {
            start: dayjs().subtract(3, "months").startOf("day").format(),
            end: dayjs().endOf("day").format(),
            isRange: true
        }
    }

    const defaultAdditionalFilters = _cloneDeep(fixedAdditionalFilters)

    const [data, setData] = useState([])
    const [loadingFilters, setLoadingFilters] = useState(defaultAdditionalFilters)


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            // Custom logic to handle the refresh
            localStorage.removeItem("useStoredDataGridFilters")
            localStorage.removeItem("dataGridFilters")
        }
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    const initDefaultFilters = () => {
        let defaultFilters = {}
        const useStoredDataGridFilters = localStorage.getItem("useStoredDataGridFilters")
        if (useStoredDataGridFilters && useStoredDataGridFilters === "1") {
            defaultFilters = getDataGridFiltersStored([], defaultAdditionalFilters, "creditNotes")
        }
        return defaultFilters || {}
    }

    const defaultFilters = initDefaultFilters()


    useEffect(() => {
     setData(orders.map(createData))
    }, [orders])

    useEffect(() => {
        if (!sitesParseObjects || !sitesParseObjects.length) {
            return
        }
       reloadData(loadingFilters, sitesParseObjects)
    }, [loadingFilters])

    const reloadData = debounce(async (loadingFilters, sitesParseObjects) => {
        await dispatch(loadFilteredCreditNotes(sitesParseObjects, loadingFilters))
      }, 300)

    const handleIsPaidValueChange = (newValue, orderId) => {
        dispatch(changeOrderIsPaidStatus(orderId, newValue))
      }

    const columnTableHeaders = [
        {
            key: "orderNumber",
            label: "Numéro de commande",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            }
        },
        {
            key: "deliveryNoteNumber",
            label: "N° de BL",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "site",
            label: "Site",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: sites.map(site => ({ key: site.objectId, value: site.name, label: site.name }))
            },
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: suppliers.map(supplier => ({ key: supplier.objectId, value: supplier.name, label: supplier.name }))
            },
        },
        {
            key: "receptionDateUnique",
            label: "Date de réception",
            dataType: "date",
            sortable: false,
            filter: {
                filterable: true,
                type: "date",
                strict: true
            }
        },
        {
            key: "creditNotesAmount",
            label: "Montant des avoirs",
            dataType: "number",
            type: "number",
            sortable: true,
            filter: {
                filterable: false,
                type: "number"
            },
            render: (value) => {
                const amount = value.split(" ")[0]
                const euroLabel = value.split(" ")[1]
                return (
                    <div><span style={{color: "#55555"}}>{amount}</span><span style={{ color: "#7C7C7C" }}>{" " + euroLabel}</span></div>
                )
            }
        },
        {
            key: "isCreditNotesPaid",
            label: "Statut des avoirs",
            dataType: "boolean",
            type: "boolean",
            sortable: true,
            filter: {
                filterable: true,
                type: "select",
                options: [
                    { key: "true", value: "Payé", label: "Payé" },
                    { key: "false", value: "Non payé", label: "Non payé" }
                ]
            },
            render: (value, orderId) => {
                return (
                    <CreditNotePaidSwitch
                        paid={value}
                        onChange={(newValue) => handleIsPaidValueChange(newValue, orderId)}
                    />
                )
            }
        }
    ]

    const additionalFilters = [
        {
            key: "receptionDate",
            label: "Date de réception",
            formLabel: "Indiquer la période de réception que vous souhaitez filtrer",
            dataType: "dateRange",
            sortable: false,
            undeletable: true,
            filter: {
                filterable: true,
                type: "dateRange"
            },
        }
    ]

    const prepareFiltersForLoading = (filters, sortBy = "asc", sortField) => {
        return prepareBillingCreditNotesFiltersForLoading({ filters, sortBy, sortField, sites, suppliers })
    }

    const handleFiltersChange = async (filters, sortBy = "asc", sortField) => {
        if (sortField === "") {
            sortField = "creditNotesAmount"
            sortBy = "desc"
        }
        const clonedFilters = prepareFiltersForLoading(filters, sortBy, sortField)
        if (Object.keys(filters).length > 0) {
            const formattedFilters = formatFiltersBeforeStorage(filters, "creditNotes")
            localStorage.setItem("dataGridFilters", JSON.stringify(formattedFilters))
        }

        setLoadingFilters(clonedFilters)
    }

    const exportOrdersLineToCSV = useCallback(async () => {
        dispatch(extractFilteredCreditNotesCSVFile(loadingFilters, sitesParseObjects))
    }, [loadingFilters, sitesParseObjects])


    const moreMenus = [
        {
            label: "Extraire les lignes de commande",
            onClick: exportOrdersLineToCSV
        }
    ]

    const handleRowClick = (order) => {
        localStorage.setItem("useStoredDataGridFilters", "1")
        const orderId = order.id
        const selectedOrder = orders.find((order) => order.objectId === orderId)
        dispatch(selectSingleCreditNoteOrder(selectedOrder))
    }      

    return (
        <DataGrid
            title="Avoirs"
            data={data}
            columns={columnTableHeaders}
            menus={moreMenus}
            withFilters
            additionalFilters={additionalFilters}
            defaultAdditionalFilters={defaultAdditionalFilters}
            asyncFilterFunction={handleFiltersChange}
            onRowClick={handleRowClick}
            defaultPostResetFilter={true}
            initialValues={defaultFilters}
            fixedAdditionalFilters={fixedAdditionalFilters}
         />
    )
}

export default CreditNotesTab