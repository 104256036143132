import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"

const useStyles = makeStyles(() => ({
    dialog: {
        textAlign: "justify",
        color: COLORS.GREY_SEARCH,
        fontSize: 16,
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.1)"
        },
        "& .MuiPaper-root": {
            minWidth: 500
        }
    },
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    title: {
        fontWeight: 500,
        fontSize: 17,
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0,
        "& span": {
            marginTop: 20
        }
    }
}))

const UpdateInfoRepartitionModal = (props) => {
    const {
        open,
        onClose,
        action
    } = props

    const classes = useStyles()
    
    const validate = () => {
        onClose()
        if (action !== null) action()
    }

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.dialog}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{root: classes.dialogTitle}}>
                <span className={classes.dialogTitleLabel}> ATTENTION : il faudra réimprimer les fiches de barquettage car la répartition peut avoir changé, souhaitez-vous continuer? </span>
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions classes={{root: classes.rootDialogActions}}>
                <Button
                    onClick={onClose}
                >
                    Non
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={validate}
                >
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateInfoRepartitionModal
