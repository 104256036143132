import React , { useState } from "react"
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { useSelector } from "react-redux"

import PackagingLifeCycleMaterialFormDialog from "./forms/PackagingLifeCycleMaterialFormDialog"
import { getPackagingMaterialsSelector } from "../../../../reducers/Settings/settings"
import { COLORS } from "../../../../utils"
import { getCountryLabelByCode } from "../../../../utils/countries"

const sx = {
  headerCell: {
    fontSize: "16px",
    lineHeight: 1,
    fontWeight: 500,
    color: COLORS.LABEL_GREY
  }
}
const PackagingLifeCycleMaterials = ({ materials, onAddMaterial, onEditMaterial, onDeleteMaterial }) => {
  const [openModalForm, setOpenModalForm] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState(null)

  const packagingMaterials = useSelector(getPackagingMaterialsSelector)

  const toggleDialog = () => setOpenModalForm(!openModalForm)
  const handleSelectRow = (row) => setSelectedMaterial(row)
  const handleCloseEditionDialog = () => setSelectedMaterial()

  const handleCreate = async (values) => {
    await onAddMaterial(values)
    toggleDialog()
  }

  const handleEdit = async (values) => {
    await onEditMaterial(selectedMaterial.index, values)
    handleCloseEditionDialog()
  }

  const handleDelete = async () => {
    await onDeleteMaterial(selectedMaterial.index)
    handleCloseEditionDialog()
  }

  
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: COLORS.HEADER_BLUE, height: 48 }}>
              <TableCell sx={sx.headerCell}>Matière</TableCell>
              <TableCell sx={sx.headerCell}>Provenance de la matière</TableCell>
              <TableCell sx={sx.headerCell}>Proportion dans le packaging (%)</TableCell>
              <TableCell sx={{ ...sx.headerCell, p: 0 }}>
                <IconButton onClick={toggleDialog} size="small">
                  <AddCircleIcon sx={{ color: COLORS.PRIMARY_COLOR }} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials?.map((material, index) => (
              <TableRow key={index} onClick={() => handleSelectRow(material)}>
                <TableCell sx={sx.headerCell}>
                  {material.packagingMaterial.name}
                </TableCell>
                <TableCell sx={sx.headerCell}>
                  {getCountryLabelByCode(material.countryOfOrigin)}
                </TableCell>
                <TableCell sx={sx.headerCell}>
                  {material.proportion}
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* creation dialog */}
      <PackagingLifeCycleMaterialFormDialog
        open={openModalForm}
        onClose={toggleDialog}
        onSave={handleCreate}
        packagingMaterials={packagingMaterials}
      />

      {/* edition dialog */}
      <PackagingLifeCycleMaterialFormDialog
        open={!!selectedMaterial}
        onClose={handleCloseEditionDialog}
        onSave={handleEdit}
        packagingMaterials={packagingMaterials}
        material={selectedMaterial}
        onDelete={handleDelete}
      />
    </>
  )
}

export default PackagingLifeCycleMaterials
