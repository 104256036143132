import Parse from "parse"
import moment from "moment"
import { getServerUrl } from "../utils"
import { axiosCall } from "../utils/axiosUtils"
import { updateExpectedProduction } from "../parseManager/planning/parsePlanningManager"
import { expectedProductionModifOrigin } from "../utils/production"

export const computePrevisionsReusableProdApi = async (changed) => {
  const origin = expectedProductionModifOrigin.REPORT
  const { productType, date, brand } = JSON.parse(changed)
  const url = `${getServerUrl()}/mealPlanner/_computeReusableProd`
  const body = {
      date: moment.utc(date).format("YYYY-MM-DD"),
      productType,
      brand,
      user: Parse.User.current() ? Parse.User.current().get("email") : null
  }
  
  const result = await axiosCall("POST", url, body, { "Content-Type": "application/json" })

  if (!result.data.data) return
  if (Object.keys(result.data.data).length > 0) {
      await Promise.all(Object.keys(result.data.data).map(async key => {
          await updateExpectedProduction(result.data.data[key], key, origin)
          return key
      }))
  }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const getReportQuantities = async (data) => {
  const url = `${getServerUrl()}/mealPlanner/_reportQuantities`
  const body = {
    productionItemId: data.productionItemId,
    newProd: data.newProd,
    user: Parse.User.current() ? Parse.User.current().get("email") : null    
  }
  const toUpdate = {
    [data.productionItemId]: data.newProd
  }
            
  const result = await axiosCall("POST", url, body, {"Content-Type": "application/json"})

  if (!result.data.data) return 
  result.data.data = { ...toUpdate, ...result.data.data }
  return result.data.data
}

export const getComputeReusableProdSum = async (data) => {
  const date = moment.utc(data.startDate).format("YYYY-MM-DD")
  const productionDate = moment.utc(data.productionDate).format("YYYY-MM-DD")
  const url = `${getServerUrl()}/mealPlanner/_computeReusableProd`
  const body = {
    date,
    productType: data.productType,
    productId: data.productId,
    brand: data.brand,
    prod: data.prod,
    productionDate,
    user: Parse.User.current() ? Parse.User.current().get("email") : null
  }
  const toUpdate = {}

  if (data.locked) {
      body.prodReu = data.prodReu
      toUpdate[data.prodReuId] = data.prodReu
  }
  const result = await axiosCall("POST", url, body, {"Content-Type": "application/json"})

  if (!result.data.data) return 
  result.data.data = { ...toUpdate, ...result.data.data }
  return result.data.data
}