import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { cyanA400 } from "../../styles"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { fullSeason } from "../../utils"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"

import { months } from "../../utils/recipes"

const styles = {
    container: {
      composes: "flexRow center",
    },
    buttonGroup: {
        flex: 1
    },
    button: {
        borderRight: "1px solid #c2c2c2"
    },
    selected: {
        backgroundColor: cyanA400
    },
    seasonLabel: {
        marginRight: 20,
        marginLeft: 10,
        marginTop: 15
    }
}

class SeasonChooser extends PureComponent {

    selectAll = event => {
        const {input: {onChange}} = this.props
        if (event.target.checked) {
            onChange(fullSeason)
        } else {
            onChange(null)
        }
    }

    _onChange = (event, value) => {
        const {input: {onChange}} = this.props
        onChange(value)
    }

    render() {
        const {input: {value}, meta, formControlProps, classes, withSelectAll = true} = this.props
        const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
        const allChecked = Array.isArray(value) && value.length === 12
        const partialChecked = Array.isArray(value) && value.length > 0 && value.length < 12

        const monthsButton = months.map((month, index) => {
            const value = month.format("M")
            return (
                <ToggleButton
                    value={value}
                    key={index}
                    classes={{root: classes.button, selected: classes.selected}}
                >
                    {month.format("MMM")}
                </ToggleButton>
            )
        })
        return (
            <div className={classes.container}>
                <FormControl {...formControlProps} error={showError}>
                    {withSelectAll &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allChecked}
                                    indeterminate={partialChecked}
                                    onChange={this.selectAll}
                                    color="secondary"
                                />
                            }
                            label="Tout sélectionner"
                        />
                    }
                    <ToggleButtonGroup selected value={value} onChange={this._onChange} classes={{root: classes.buttonGroup}}>
                        {monthsButton}
                    </ToggleButtonGroup>
                    {showError &&
                        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
                    }
                </FormControl>
            </div>
        )
    }
}

export default withStyles(styles)(SeasonChooser)
