import React, { useState } from "react"
import { Field, ErrorMessage } from "formik"
import withStyles from "@mui/styles/withStyles"
import { Grid } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"

import {
    getDefaultIngredients,
    parseSupplierItemToObject,
} from "../../utils/recipes"
import { roundNumber } from "../../utils"
import IngredientAutocompleteNew from "../autoComplete/IngredientAutocompleteNew"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import { RecipeStylesNew } from "../../styles"

import { COMPONENT_NAME as SECTIONS_COMPONENT_NAME } from "./Sections"
import { COMPONENT_NAME as STEPS_COMPONENT_NAME } from "./Steps"
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd"
import { COLORS } from "../../utils"

const COMPONENT_NAME = "INGREDIENTS"

const Ingredients = props => {
    const {
        editing,
        ingredients,
        stepIndex,
        sectionIndex,
        onFieldFocus,
        onFieldBlur,
        rowHover,
        onRowHover,
        onRowBlur,
        deleteHover,
        onDeleteHover,
        onDeleteBlur,
        isFocus,
        setFieldValue,
        computationHandler,
        onKeyUp,
        onKeyDown,
        setFieldTouched,
        errors,
        classes,
        isInherited,
        status,
        updateStepGrossWeight,
        updateSectionGrossWeight
    } = props

    const [currentDraggedIngredient, setCurrentDraggedIngredient] = useState(null)

    const _onDragStart = (event) => {
        let currentIngredient = null
        currentIngredient = ingredients.find(item => item.index === event.draggableId)

        setCurrentDraggedIngredient(currentIngredient)
    }

    const _onDragEnd = (event) => {
        const { destination, source } = event

        if (!destination) {
            setCurrentDraggedIngredient(null)

            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            setCurrentDraggedIngredient(null)

            return
        }

        const newIngredients = [...ingredients]
        newIngredients.splice(source.index, 1)
        newIngredients.splice(destination.index, 0, currentDraggedIngredient)

        setFieldValue(`sections[${sectionIndex}].steps[${stepIndex}].ingredients`, [...newIngredients])
    }

    const _stopPropagation = (event) => event && event.stopPropagation()

    const _isHover = (index) => rowHover
        && COMPONENT_NAME === rowHover.component
        && rowHover.index === index
        && rowHover.parentIndex === `${sectionIndex}-${stepIndex}`

    const _isDeleteHover = (index) => deleteHover &&
        ( // parenthesis not necessary thanks to operators priority but necesseray because of JS warning
            (
                COMPONENT_NAME === deleteHover.component
                && deleteHover.index === index
                && deleteHover.parentIndex === `${sectionIndex}-${stepIndex}`
            )
            || (
                SECTIONS_COMPONENT_NAME === deleteHover.component
                && deleteHover.index === sectionIndex
            )
            || (
                STEPS_COMPONENT_NAME === deleteHover.component
                && deleteHover.index === stepIndex
                && deleteHover.parentIndex === sectionIndex
            )
        )

    const _isError = (index) => errors.sections
        && errors.sections[sectionIndex]
        && errors.sections[sectionIndex].steps
        && errors.sections[sectionIndex].steps[stepIndex]
        && errors.sections[sectionIndex].steps[stepIndex].ingredients
        && errors.sections[sectionIndex].steps[stepIndex].ingredients[index]

    const _showDeleteIcon = () => !(ingredients && 1 === ingredients.length && null === ingredients[0].supplierItem)

    const _getErrorField = (index, fieldName) => {
        let result = false

        if (_isError(index)) {
            result = errors.sections[sectionIndex].steps[stepIndex].ingredients[index][fieldName]
        }

        return result
    }

    const _onCookingModeChange = (index, event) => {
        onKeyDown(event)

        if (!isInherited) {
            setFieldValue(`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].cookingMode`, event.target.value)
            onKeyUp(event)
        }
    }

    const _onSupplierItemChange = (index, value) => {
        onKeyDown({ target: { name: `sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].supplierItem` } })

        if (!isInherited) {
            if (value) {
                const newSupplierItem = parseSupplierItemToObject(value.supplierItem)
                ingredients[index].supplierItem = newSupplierItem
                ingredients[index].cookingMode = newSupplierItem.cookingModes[0].cookingMode.id
            } else {
                ingredients[index].grossWeight = 0
                ingredients[index].supplierItem = null
                ingredients[index].error = true
            }

            setFieldTouched(`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].supplierItem`)
            computationHandler(sectionIndex, stepIndex, index)
        }
    }

    const _onGrossWeightChange = (index, e) => {
        onKeyDown(e)

        if (!isInherited) {
            ingredients[index].grossWeight = e.target.value
            updateStepGrossWeight(stepIndex)
            updateSectionGrossWeight(sectionIndex)
            setFieldTouched(`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].grossWeight`)
            computationHandler(sectionIndex, stepIndex, index)
        }
    }

    const _addIngredient = (index, event = null) => {
        if (isInherited) {
            onKeyDown({ target: { name: `sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}]` } })
            _stopPropagation(event)
            return
        }

        const _ingredients = [...ingredients]
        _ingredients.splice(index + 1, 0, getDefaultIngredients())
        setFieldValue(`sections[${sectionIndex}].steps[${stepIndex}].ingredients`, _ingredients)
        _stopPropagation(event)
    }

    const _removeIngredient = (index, event = null) => {
        if (isInherited) {
            onKeyDown({ target: { name: `sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}]` } })
            _stopPropagation(event)
            return
        }

        const _ingredients = [...ingredients]
        _ingredients.splice(index, 1)

        if (!_ingredients.length) {
            _ingredients.splice(0, 0, getDefaultIngredients())
        }

        setFieldValue(`sections[${sectionIndex}].steps[${stepIndex}].ingredients`, _ingredients)
        _stopPropagation(event)
    }

    const getOrderedCookingModes = ingredient => {
        return (ingredient && ingredient.supplierItem && ingredient.supplierItem.cookingModes
            .filter(cookingMode => cookingMode.cookingMode && cookingMode.cookingMode.id)
            .sort((a, b) => {
                if (a.cookingMode.name < b.cookingMode.name) { return -1 }
                if (a.cookingMode.name > b.cookingMode.name) { return 1 }
                return 0
            })) || []
    }

    const _renderEditableIngredient = (ingredient, index, isHover, isDeleteHover, error = false) => (
        <Grid
            container
            item key={index}
            className={`
                ${classes.content}
                ${isHover ? classes.editHover : ""}
                ${error || isDeleteHover ? classes.ingredientLineError : ""}
                ${(isInherited) ? classes.ingredientInherited : ""}
            `}
            onMouseEnter={() => onRowHover(COMPONENT_NAME, index, `${sectionIndex}-${stepIndex}`)}
            onMouseLeave={onRowBlur}
        >
            <Grid container item xs={5} className={classes.natBorder} alignItems="center">
                <Grid item xs={1}>
                    {
                        isHover &&
                        <AddIcon
                            className={classes.addButtonStepAndIngredient}
                            onClick={(event) => _addIngredient(index, event)}
                        />
                    }
                </Grid>
                <Grid item xs={11}>
                    {
                        isHover
                            ?
                            (
                                <div className={classes.ingredientField}>
                                    <IngredientAutocompleteNew
                                        name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].supplierItem`}
                                        ingredientIndex={index}
                                        onChange={_onSupplierItemChange}
                                        value={ingredient.supplierItem}
                                        placeholder="Ingredient"
                                        onFocus={onFieldFocus}
                                        onBlur={onFieldBlur}
                                        showNoCommercialName={Number(status) < 3}
                                    />
                                    <ErrorMessage
                                        name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].supplierItem`}
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                </div>
                            )
                            : (
                                <div className={classes.ingredientName}>
                                    {
                                        _getErrorField(index, "supplierItem")
                                            ? (
                                                <ErrorMessage
                                                    name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].supplierItem`}
                                                    render={msg => <div className={classes.error}>{msg}</div>}
                                                />
                                            )
                                            : ingredient.supplierItem && ingredient.supplierItem.name
                                    }
                                </div>
                            )
                    }
                </Grid>
            </Grid>
            <Grid container item xs={3} className={classes.natBorder} alignItems="center">
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey800}`}>
                    {
                        isHover
                            ?
                            <>
                                <Field
                                    type="number"
                                    name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].grossWeight`}
                                    onClick={_stopPropagation}
                                    onChange={e => _onGrossWeightChange(index, e)}
                                    value={ingredient.grossWeight}
                                    className={classes.alignRight}
                                    onFocus={onFieldFocus}
                                    onBlur={onFieldBlur}
                                    onKeyUp={onKeyUp}
                                    onKeyDown={onKeyDown}
                                    onWheel={(e) => e.target.blur()}
                                />
                                <ErrorMessage
                                    name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].grossWeight`}
                                    render={msg => <div className={classes.error}>{msg}</div>}
                                />
                            </>
                            : _getErrorField(index, "grossWeight")
                                ? (
                                    <ErrorMessage
                                        name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].grossWeight`}
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                )
                                : ingredient.grossWeight
                    }
                </Grid>
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.supplierItem && ingredient.supplierItem.pricePerKg ? `${ingredient.supplierItem.pricePerKg}€` : "--"}
                </Grid>
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.blueGrey500}`}>
                    {ingredient.cost ? `${roundNumber(ingredient.cost, 3)}€` : "--"}
                </Grid>
            </Grid>
            <Grid container item xs={4} className={classes.natBorder} alignItems="center">
                <Grid item xs={4} className={`${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding}`}>
                    {
                        isHover
                            ? (
                                <>
                                    <FormControl variant="outlined" className={classes.selectFormControl}>
                                        <Select
                                            variant="outlined"
                                            value={ingredient.cookingMode}
                                            onChange={(e) => _onCookingModeChange(index, e)}
                                        >
                                            {
                                                getOrderedCookingModes(ingredient).map(cookingMode => (
                                                    <MenuItem
                                                        key={cookingMode.cookingMode.id}
                                                        value={cookingMode.cookingMode.id}
                                                    >
                                                        {cookingMode.cookingMode.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <ErrorMessage
                                        name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].cookingMode`}
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                </>
                            ) : _getErrorField(index, "cookingMode")
                                ? (
                                    <ErrorMessage
                                        name={`sections[${sectionIndex}].steps[${stepIndex}].ingredients[${index}].cookingMode`}
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                )
                                : ingredient.cookingModeLabel
                    }
                </Grid>
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.transformRate}%
                </Grid>
                <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.netWeight}
                </Grid>
                <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                    {
                        isHover && _showDeleteIcon() &&
                        <DeleteIcon
                            className={error || isDeleteHover ? classes.deleteIconRed : classes.deleteIcon}
                            onClick={(e) => _removeIngredient(index, e)}
                            onMouseEnter={() => onDeleteHover(COMPONENT_NAME, index, `${sectionIndex}-${stepIndex}`)}
                            onMouseLeave={onDeleteBlur}
                        />
                    }
                </Grid>
            </Grid>
        </Grid>
    )

    const _renderViewIngredient = (ingredient, index) => (
        <Grid
            container
            item
            key={index}
            className={`
                ${classes.content}
                ${(isInherited) ? classes.ingredientInherited : ""}
            `}
        >
            <Grid container item xs={5} className={`${classes.natBorder} show`} alignContent="center">
                <Grid item xs={1}></Grid>
                <Grid item xs={11} className={classes.ingredientName}>
                    {ingredient.supplierItem && ingredient.supplierItem.name}
                </Grid>
            </Grid>
            <Grid container item xs={3} className={`${classes.natBorder} show`} alignContent="center">
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey800}`}>
                    {ingredient.grossWeight}
                </Grid>
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.supplierItem && ingredient.supplierItem.pricePerKg ? `${ingredient.supplierItem.pricePerKg}€` : "--"}
                </Grid>
                <Grid item xs={4} className={
                    `${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.blueGrey500}`
                }>
                    {ingredient.cost ? `${roundNumber(ingredient.cost, 3)}€` : "--"}
                </Grid>
            </Grid>
            <Grid container item xs={4} className={`${classes.natBorder} show`} alignContent="center">
                <Grid item xs={4} className={
                    `${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding} ${classes.blueGrey800}`
                }>
                    {ingredient.cookingModeLabel}
                </Grid>
                <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.transformRate}%
                </Grid>
                <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>
                    {ingredient.netWeight}
                </Grid>
                <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                    {/* Space for delete action */}
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Grid container className={classes.ingredientLineContainer}>
            <DragDropContext
                isDragDroppable={true}
                onDragStart={_onDragStart}
                onDragEnd={_onDragEnd}
                style={{ width: "100%" }}
            >
                <Droppable
                    isDroppable={true}
                    droppableId={`ingredientDropable${stepIndex}`}
                >
                    {
                        (provided) => (
                            <div ref={provided.innerRef}>
                                {
                                    ingredients && ingredients.map((ingredient, index) => (
                                        <Draggable
                                            key={ingredient.index}
                                            index={index}
                                            isDragDisabled={!editing || isFocus}
                                            draggableId={ingredient.index}
                                        >
                                            {
                                                (provided, snapshot) => {

                                                    const style = {
                                                        outline: snapshot.isDragging ? `2px solid ${COLORS.PRIMARY_COLOR}` : "none",
                                                        boxShadow: snapshot.isDragging ? `inset 0 0 10px ${COLORS.PRIMARY_COLOR}, 0 0 10px ${COLORS.PRIMARY_COLOR}` : "none",
                                                        ...provided.draggableProps.style
                                                    }

                                                    return <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={style}
                                                    >
                                                        {
                                                            editing
                                                                ? _renderEditableIngredient(ingredient, index, _isHover(index), _isDeleteHover(index), _isError(index))
                                                                : _renderViewIngredient(ingredient, index)
                                                        }
                                                    </div>
                                                }
                                            }
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )
                    }
                </Droppable>
            </DragDropContext>
        </Grid>

    )
}

export default withStyles(RecipeStylesNew)(Ingredients)
