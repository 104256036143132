import React from "react"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import LocalShippingIcon from "@mui/icons-material/LocalShipping"

import { Stack } from "@mui/material"

import { COLORS } from "../../utils"

const DispatchHeaderTitle = () => {
    return (
        <Stack direction="row" alignItems="center" spacing={1.2}>
            <Icon sx={{ color: COLORS.VIOLET_DISPATCH }}>
                <LocalShippingIcon />
            </Icon>
            <Typography variant="h6" color="textPrimary" sx={{ color: COLORS.VIOLET_DISPATCH }}>
                Dispatch Entrepôt
            </Typography>
        </Stack>
    )
}

export default DispatchHeaderTitle
