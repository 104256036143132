import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import withStyles from "@mui/styles/withStyles"
import TableCell from "@mui/material/TableCell"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import Checkbox from "@mui/material/Checkbox"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import ExportProductsErrorsTableToolbar from "./table-utils/ExportProductsErrors/ExportProductsErrorsTableToolbar"
import ExportProductsErrorsTableHead from "./table-utils/ExportProductsErrors/ExportProductsErrorsTableHead"
import TablePagination from "../components/TablePagination"
import { isArray } from "util"
import { getBrandBy, KFC_BRANDS } from "../utils"
import { stableSort, getSorting } from "./table-utils/utils"

const styles = theme => ({
    modalTitle: {
        textAlign: "center",
        margin: "auto"
    },
    modalSubTitle: {
        textAlign: "center"
    },
    resultModal: {
        position: "absolute",
        top: "5%",
        left: "5%",
        width: "90%",
        height: "90%",
        outline: "none",
        overflow: "scroll",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    acceptButton: {
        marginLeft: 15,
        marginTop: 25,
        cursor: "pointer"
    },
    root: {
        width: "100%"
    },
    tableWrapper: {
        margin: 25
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20,
        float: "left"
    },
    searchFieldInput: {
        width: 300
    },
    brandLogo: {
        width: 25
    },
    brandLogoLeft: {
        composes: "$brandLogo",
        marginRight: 5
    },
    column: {
        cursor: "pointer"
    },
    headerModal: {
        display: "flex"
    },
    closeIcon: {
        float: "right"
    },
    tablePagination: {
        float: "right",
        display: "inline-block"
    },
    tableBody: {
        whiteSpace: "pre-wrap"
    },
    messageArea: {
        textAlign: "center",
        fontSize: "20px",
        paddingTop: "25%",
        position: "absolute",
        width: "100%"
    }
})

class ExportProductsResultModal extends PureComponent {

    state = {
        order: "asc",
        orderBy: "name",
        selected: [],
        page: 0,
        dataPage: 0,
        rowsPerPage: 25,
        searchFilter: null,
        filterErrors: [],
        data: []
    }

    componentDidMount() {
        this.createDataObject(0)
    }

    componentDidUpdate(prevProps, prevState) {
        const { errors } = this.props

        if (prevState.dataPage !== this.state.dataPage
            || prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.searchFilter !== this.state.searchFilter
            || prevProps.errors.length !== errors.length) {
            this.createDataObject()
        }
    }

    createDataObject = (dataPage) => {
        const { rowsPerPage, searchFilter } = this.state
        const { errors } = this.props
        dataPage = dataPage || this.state.dataPage
        let filterErrors = []
        if (searchFilter && searchFilter !== "") {
            const regex = new RegExp(searchFilter, "ig")
            filterErrors = errors.filter(error => error.name.match(regex) !== null)
        } else {
            filterErrors = errors
        }
        this.setState({filterErrors: filterErrors})
        filterErrors = filterErrors.slice(rowsPerPage * dataPage, rowsPerPage * dataPage + rowsPerPage)
        const finalData = filterErrors.map(error => {
            const message = error && error.result && error.result.errors
                ?
                    error.result.errors.length ?
                        error.result.errors.map((elem) => {
                            const title = elem.type && elem.name ? elem.type +  " " + elem.name : elem.trace
                            return title + " : " + elem.message
                        }).reduce((value, elem) => {
                            return value + ".\n" + elem
                        })
                    :
                        error.result.message
                : "Erreur interne au serveur"
            return {
                brand: error.brand,
                id: error.id,
                name: error.name,
                message: message
            }
        })
        this.setState({data: finalData})
    }

    handleCloseModal = () => {
        const { closeModal } = this.props
        closeModal()
    }


    decrementPage = () => {
        this.setState({dataPage: this.state.dataPage - 1})
    }

    incrementPage = () => {
        this.setState({dataPage: this.state.dataPage + 1})
    }

    handleChangeRowsPerPage = (value) => {
        this.setState({ rowsPerPage: value })
    }

    handleClick = (event, id) => {
        const { selected } = this.state
        const selectedIndex = selected.indexOf(id)
        let newSelected = selected
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(id)
        } else {
            newSelected = selected.filter((_, idx) => idx !== selectedIndex)
        }
        this.setState({ selected: newSelected })
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = "desc"
        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc"
        }
        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            const { filterErrors } = this.state
            this.setState({selected: filterErrors.map(error => error.id)})
            return
        }
        this.setState({selected: []})
    }

    updateSearchValue = (event) => {
        if (event) {
            this.setState({searchFilter: event.target.value})
        }
        else {
            this.setState({searchFilter: null})
        }
    }

    /*
    **  const toResolve take all the errors selected in the modal and remove duplicata to not open twice the
    **  same window
     */
    handleResolveErrors = () => {
        const { errors } = this.props
        const { selected } = this.state

        const toResolve = errors.filter(elem => selected.find(select => select === elem.id))
            .map(elem => elem.result.errors).reduce((cumulator, elem) => {
                return cumulator.concat(elem)
            }).filter((elem,  index, self) => {
                return (elem.id && elem.id !== "") && index === self.findIndex((el) => {
                    return el.id === elem.id
                })
            })
        const urls = toResolve.map(elem => {
            switch (elem.type) {
                case "Ingredient":
                    return "/ingredients/internals/ingredient/" + elem.id + "/edit"
                case "Recette":
                    return "products/recipe/" + elem.id
                case "Product Type":
                    return "products/recipe/" + elem.id
                case "Produit sous-traitant":
                    return "products/subcontractor-product/" + elem.id
                default:
                    return ""
            }
        }).filter(elem => elem !== "")
        for (const idx in urls) {
            window.open(urls[idx])
        }
    }

    renderBrandLogo = (brand) => {
        const { classes } = this.props

        if (isArray(brand)) {
            return KFC_BRANDS.map((b, index) => {
                return brand.includes(b.name)
                    ? (
                        <img
                            className={classes.brandLogo}
                            alt={`logo${b.shortname}`}
                            src={b.image}
                            key={index}
                        />
                    )
                    : null
            })
        } else {
            const completeBrand = getBrandBy("name", brand)

            return (
                <img
                    className={classes.brandLogo}
                    alt={`logo${completeBrand.shortname}`}
                    src={completeBrand.image}
                />
            )
        }
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    render() {
        const { classes, closeModal, title, subTitle, searchFieldsLabel, tableTitle, isFem, isRecipes, message } = this.props
        const { selected, orderBy, order, rowsPerPage, dataPage, data, filterErrors } = this.state

        return (
            <div className={classes.resultModal}>
                <div className={classes.headerModal}>
                    <Typography variant="h6" id="modal-title" className={classes.modalTitle}>
                        {title}
                    </Typography>
                    <IconButton
                        aria-label="Fermer"
                        className={classes.closeIcon}
                        onClick={closeModal}
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <Typography variant="subtitle1" id="simple-modal-description" className={classes.modalSubTitle}>
                    {subTitle} <br/>
                </Typography>
                {
                    message
                        ?
                            <span className={classes.messageArea}>
                                {message}
                            </span>
                        :
                            <Paper className={classes.root} elevation={0}>
                                <ExportProductsErrorsTableToolbar
                                    resolveErrors={this.handleResolveErrors}
                                    numSelected={selected.length}
                                    closeModal={this.handleCloseModal}
                                    tableTitle={tableTitle}
                                    isFem={isFem}
                                />
                                <div>
                                    <div className={classes.searchFieldDiv}>
                                        <TextField
                                            className={classes.searchFieldInput}
                                            onChange={this.updateSearchValue}
                                            label={searchFieldsLabel}
                                            variant="standard"
                                        />
                                    </div>
                                    <div className={classes.tablePagination}>
                                        <TablePagination
                                            inputStyle={{disabledUnderline: true}}
                                            rowsPerPage={rowsPerPage}
                                            dataPage={dataPage}
                                            isDisabled={true}
                                            data={filterErrors ? filterErrors : []}
                                            handleChangeRowPerPage={this.handleChangeRowsPerPage}
                                            decrementPage={this.decrementPage}
                                            incrementPage={this.incrementPage}
                                        />
                                    </div>
                                </div>
                                <div className={classes.tableWrapper}>
                                    <Table aria-labelledby="tableTitle">
                                        <ExportProductsErrorsTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={this.handleSelectAllClick}
                                            onRequestSort={this.handleRequestSort}
                                            rowCount={filterErrors.length}
                                            searchFieldsLabel={searchFieldsLabel}
                                            isRecipes={isRecipes}
                                        />
                                        <TableBody className={classes.tableBody}>
                                            {stableSort(data, getSorting(order, orderBy))
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id)
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        selected={isSelected}
                                                    >
                                                        <TableCell
                                                            padding="checkbox"
                                                            onClick={event => this.handleClick(event, n.id)}
                                                        >
                                                            <Checkbox checked={isSelected} color="secondary"/>
                                                        </TableCell>
                                                        <TableCell className={classes.column} align="right">
                                                            {
                                                                this.renderBrandLogo(n.brand || n.brands)
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.column}
                                                            padding="none"
                                                        >
                                                            {n.name}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.column}
                                                            padding="none"
                                                        >
                                                            {n.message}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    }
            </div>
        )
    }
}

ExportProductsResultModal.propTypes = {
    classes: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    errors: PropTypes.array,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    tableTitle: PropTypes.string.isRequired,
    searchFieldsLabel: PropTypes.string.isRequired,
    message: PropTypes.string
}
export default withStyles(styles, {withTheme: true})(ExportProductsResultModal)
