import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"

import { COLORS } from "../../utils"
import SiteFormDialog from "./SiteFormDialog"

const useStyles = makeStyles({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 800
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16,
        lineHeight: "11px"
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 232,
        display: "flex",
        alignItems: "center"
    }
})

const SiteResume = ({
    jsonSite,
    onSaveSite,
    canEdit
}) => {
    const classes = useStyles()
    const [openFormDialog, setOpenFormDialog] = useState(false)
    
    const toggleDialog = () => setOpenFormDialog(!openFormDialog)

    return <>
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Informations liées au site
                </div>
                {
                    canEdit &&
                    <IconButton
                        className={classes.iconContainer}
                        disableRipple={true}
                        onClick={toggleDialog}
                        size="large">
                        <EditIcon className={classes.icon}/>
                    </IconButton>
                }
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Pays
                </div>
                <span>
                    {
                        jsonSite && jsonSite.deliveryAddress
                            ? jsonSite.deliveryAddress.country
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Ville
                </div>
                <span>
                    {
                        jsonSite && jsonSite.deliveryAddress
                            ? jsonSite.deliveryAddress.city
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Code postal
                </div>
                <span>
                    {
                        jsonSite && jsonSite.deliveryAddress
                            ? jsonSite.deliveryAddress.zipCode
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Adresse de livraison
                </div>
                <span>
                    {
                        jsonSite && jsonSite.deliveryAddress
                            ? jsonSite.deliveryAddress.line1
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Complément d'adresse
                </div>
                <span>
                    {
                        jsonSite && jsonSite.deliveryAddress
                            ? jsonSite.deliveryAddress.line2
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Adresse de facturation
                </div>
                <span>
                    {
                        jsonSite && jsonSite.invoiceAddress
                            ? jsonSite.invoiceAddress.line1
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Complément d'adresse
                </div>
                <span>
                    {
                        jsonSite && jsonSite.invoiceAddress
                            ? jsonSite.invoiceAddress.line2
                            : ""
                    }
                </span>
            </div>
        </Paper>
        {
            canEdit &&
            <SiteFormDialog
                onClose={toggleDialog}
                open={openFormDialog}
                editing={true}
                site={jsonSite}
                onSave={onSaveSite}
            />
        }
    </>
}

export default SiteResume


