import React from "react"
import { styled } from "@mui/material"
import { Box, Stack } from "@mui/material"

const StyledValue = styled("div")({
	color: "#FFFFFF", fontSize: 16
})

const StyledLabel = styled("div")({
	color: "#FFFFFF", fontSize: 12
})

const GenericBannerTable = ({ dataRow = {}, columns = [] }) => {

	return (
		<Box>
			<Stack direction="row" flexWrap={"wrap"} gap={4}>
				{columns.map(({ label, id }, index) => (
					<Stack key={index} gap={2}>
						<StyledLabel>{label}</StyledLabel>
						<StyledValue>{dataRow[id]}</StyledValue>
					</Stack>
				))}
			</Stack>
		</Box>
	)
}

export default GenericBannerTable