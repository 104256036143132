import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import makeStyles from "@mui/styles/makeStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import { COLORS } from "../../utils"
import SupplierContactEdit from "./SupplierContactEdit"

const useStyles = makeStyles({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        paddingBottom: 16,
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        paddingLeft: 60,
        paddingTop: 40,
        paddingRight: 32,
        minWidth: 800,
        marginTop: 20
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 45
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "11px"
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
        padding: 0,
    },
    icon: {
        color: COLORS.PRIMARY_COLOR,
        "&:hover": {
            color: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    valueContainer: {
        display: "flex",
        paddingBottom: 30,
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 16,
        lineHeight: "11px"
    },
    valueLabel: {
        color: COLORS.BLUE_PAPER_SELECTED,
        width: 232,
        display: "flex",
        alignItems: "center"
    }
})

export const SupplierContact = (props) => {
    const {
        jsonSupplier,
        onSaveSupplier,
        canEdit
    } = props
    
    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)
    
    const edit = () => setIsEditing(true)

    const _toggleEdit = () => setIsEditing(!isEditing)

    return <>
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Coordonnées
                </div>
                {
                    canEdit &&
                    <IconButton
                        className={classes.iconContainer}
                        disableRipple={true}
                        onClick={edit}
                        size="large">
                        <EditIcon className={classes.icon}/>
                    </IconButton>
                }
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Pays
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.country
                            ? jsonSupplier.contact.country
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Ville
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.city
                            ? jsonSupplier.contact.city
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Code postal
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.zipCode
                            ? jsonSupplier.contact.zipCode
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Adresse
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.address
                            ? jsonSupplier.contact.address
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Nom du contact
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.contactName
                            ? jsonSupplier.contact.contactName
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Téléphone
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.phone
                            ? jsonSupplier.contact.phone
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    FAX
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.fax
                            ? jsonSupplier.contact.fax
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Emails commerciaux
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.emails && jsonSupplier.contact.emails.commercialEmails
                            ? jsonSupplier.contact.emails.commercialEmails.join(", ")
                            : ""
                    }
                </span>
            </div>
            <div className={classes.valueContainer}>
                <div className={classes.valueLabel}>
                    Emails de commande
                </div>
                <span>
                    {
                        jsonSupplier.contact && jsonSupplier.contact.emails && jsonSupplier.contact.emails.orderEmails
                            ? jsonSupplier.contact.emails.orderEmails.join(", ")
                            : ""
                    }
                </span>
            </div>
        </Paper>
        {
            canEdit &&
            <SupplierContactEdit
                onClose={_toggleEdit}
                isEditing={isEditing}
                editing={true}
                jsonSupplier={jsonSupplier}
                onSaveSupplier={onSaveSupplier}
            />
        }
    </>
}

export default SupplierContact


