import React, { useLayoutEffect, useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import moment from "moment"
import { DatePicker } from "@mui/x-date-pickers"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Button from "@mui/material/Button"
import { v4 as uuidv4 } from "uuid"
import InputLabel from "@mui/material/InputLabel"

import { COLORS } from "../../../utils"
import { generateStockUnitLabel } from "../../../utils/orderReception"

const useStyles = makeStyles({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    inputContainer: {
        width: "50%",
        display: "flex",
        justifyContent: "center"
    },
    input: {
        fontSize: 16,
        width: 324,
        textAlign: "left"
    },
    dateInput: {
        fontSize: 16,
        width: 144,
        textAlign: "left"
    },
    selectInput: {
        fontSize: 16,
        width: 324,
        textAlign: "left"
    },
    inputLabel: {
        textAlign: "center",
        fontSize: 20,
        marginBottom: 10,
        textTransform: "uppercase"
    },
    firstBloc: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "24px"
    },
    bloc: {
        marginTop: 50,
        display: "flex",
        flexDirection: "row",
        gap: "24px",
        justifyContent: "space-between"
    },
    btnBloc: {
        textAlign: "end",
        alignItems: "end",
        marginTop: 24,
        "@media (max-height: 760px)": {
            marginTop: 62
        }
    },
    firstBtn: {
        width: 219
    },
    error: {
        fontSize: 16,
        color: COLORS.RED_ERROR
    },
    selectInputLabel: {
        fontSize: "0.9em"
    },
    quantity: {
        color: "#555",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px"
    },
    quantityLabel: {
        color: "#9B9B9B"
    }
})

const AddLotBloc = (props) => {
    const {
        quantityLeft,
        orderSupplierItem,
        stockZones,
        onCreateLot,
        receptionDate
    } = props

    useLayoutEffect(() => {
    }, [orderSupplierItem])

    const classes = useStyles()

    const [lotNumber, setLotNumber] = useState("")
    const [dlc, setDlc] = useState(null)
    const [quantity, setQuantity] = useState(quantityLeft)
    const [selectedStockZone, setSelectedStockZone] = useState(orderSupplierItem && orderSupplierItem.defaultStockZone ? orderSupplierItem.defaultStockZone.objectId : stockZones[0].objectId)
    const [isDlcValid, setIsDlcValid] = useState(false)
    const [disableCta, setDisableCta] = useState(true)

    useLayoutEffect(() => {
        if ((dlc && !isDlcValid) || quantity > quantityLeft || selectedStockZone === "") {
            setDisableCta(true)
        } else {
            setDisableCta(false)
        }

    }, [quantity, isDlcValid, lotNumber, selectedStockZone, quantityLeft])

    useLayoutEffect(() => {
        if (stockZones.filter(zone => zone.objectId === selectedStockZone).length === 0) {
            setSelectedStockZone(stockZones[0].objectId)
        }
    }, [stockZones])

    useEffect(() => {
        setQuantity(quantityLeft)
    }, [quantityLeft])

    const onDlcChange = (date) => {
        setIsDlcValid(date ? date._isValid : false)

        // the input field and picker does not have the same hour
        // the date picker set the current date with current hour
        // the input field set the current date but with hour at 00:00:00
        // so we set the hour, min and sec manually for both
        const dateWithCurrentTime = date.set({
            h: moment().get("hour"),
            m: moment().get("minute"),
            s: moment().get("second"),
        })

        setDlc(moment(dateWithCurrentTime).valueOf())
    }

    const renderStockZones = () => {
        return stockZones.map((stockZone, i) =>
            <MenuItem key={i} value={stockZone.objectId}>{stockZone.name}</MenuItem>
        )
    }

    const confirm = () => {
        const autoDlc = orderSupplierItem.type === "PACKAGING_CONSUMABLE"
            ? moment.utc(moment(receptionDate).add(10, "years")).valueOf()
            : moment.utc(moment(receptionDate).add(10, "days")).valueOf()

        onCreateLot({
            method: "ADD_LOT",
            data: {
                id: uuidv4(),
                orderSupplierItem,
                lotNumber: lotNumber || `Reçu le ${moment(receptionDate).format("DD/MM/YYYY")}`,
                dlc: dlc || autoDlc,
                quantity,
                stockZone: stockZones.find(el => el.objectId === selectedStockZone),
                receptionDate: receptionDate
            }
        })
        setLotNumber("")
        setDlc(null)
    }

    return (
        <div className={classes.root}>
            <div className={classes.firstBloc}>
                <div>
                    <TextField
                        variant="standard"
                        label="Lot"
                        name="lotNumber"
                        onChange={(e) => { setLotNumber(e.target.value) }}
                        value={lotNumber}
                        placeholder="00"
                        inputProps={{
                            className: classes.input
                        }}
                    />
                </div>
                <div>
                    <DatePicker
                        disableCloseOnSelect={false}
                        value={dlc}
                        inputFormat="DD/MM/YYYY"
                        onChange={onDlcChange}
                        invalidDateMessage="Dlc invalide"
                        minDateMessage="Dlc invalide"
                        maxDateMessage="Dlc invalide"
                        className={classes.input}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="DLC"
                                placeholder="XX/XX/XXXX"
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classes.bloc}>
                <div>
                    <InputLabel className={classes.selectInputLabel}>
                        Zone de stockage
                    </InputLabel>
                    <Select
                        variant="standard"
                        name="stockZone"
                        onChange={(e) => setSelectedStockZone(e.target.value)}
                        value={selectedStockZone}
                        className={classes.selectInput}
                    >
                        {renderStockZones()}
                    </Select>
                    {
                        !selectedStockZone &&
                        <p className={classes.error}>
                            Zone de stockage requise
                        </p>
                    }
                </div>
                <div>
                    <TextField
                        variant="standard"
                        label="Quantité"
                        name="volume"
                        onChange={(e) => setQuantity(e.target.value !== "" && e.target.value ? +e.target.value : "")}
                        value={quantity}
                        placeholder="000"
                        error={quantity > quantityLeft}
                        inputProps={{
                            className: classes.input
                        }}
                        type="number"
                    />
                    {
                        quantity > quantityLeft &&
                        <p className={classes.error}>
                            Vous n'avez pas assez de quantité restante
                        </p>
                    }
                    <p className={classes.quantity}>
                        Quantité restante : {quantityLeft} <span className={classes.quantityLabel}>({generateStockUnitLabel(orderSupplierItem)})</span>
                    </p>
                </div>
            </div>
            <div className={classes.btnBloc}>
                <Button
                    color="primary"
                    className={classes.firstBtn}
                    onClick={confirm}
                    disabled={disableCta}
                >
                    Créer ce lot
                </Button>
            </div>
        </div>
    )
}

export default AddLotBloc
