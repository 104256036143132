import React from "react"
import { useDispatch } from "react-redux"
import { Button, Stack } from "@mui/material"
import { StyledMainContainer } from "./styledComponents"
import {
	PSE_STATUS,
	showProductionStepsSupervision,
	updateProductionStepExecutionsToInProgress
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import PSEStepComponents from "./preview/PSEStepComponents"
import PSEResume from "./preview/PSEResume"
import PSEProgressBar from "./preview/PSEProgressBar"
import PSEWeights from "./preview/PSEWeights"
import PSEPreparation from "./preview/PSEPreparation"
import { getPriorStepData } from "../../utils/productionStepExecution"
import ProductionStepExecutionFooter from "./ProductionStepExecutionFooter"
import ProductionStepsExecutionFixedHeader from "./ProductionSteoExecutionsFixedHeader"

const ProductionStepExecutionTodo = ({
	productionStepExecution,
	onClickCommentsIcon,
	commentsCount
}) => {

	const dispatch = useDispatch()

	const handleBack = () => dispatch(showProductionStepsSupervision())

	const updateStatusToProgress = () => dispatch(updateProductionStepExecutionsToInProgress())

	const initializePreparation = () => updateStatusToProgress()

	const renderRightActions = () => {
		if (!productionStepExecution) return

		switch (productionStepExecution.status) {
			case PSE_STATUS.locked:
				return (
					<Button variant="contained" disabled>
						Commencer
					</Button>
				)
			case PSE_STATUS.todo:
				return (
					<Button variant="contained" onClick={initializePreparation}>
						Commencer
					</Button>
				)
			default:
				return null
		}
	}

	return (
		<div>
			{/* header */}
			<ProductionStepsExecutionFixedHeader onBack={handleBack} />

			{/* body */}
			<StyledMainContainer> {/** for the fixed header */}
				<Stack>
					<Stack sx={{
						p: 3,
						borderBottom: "1px solid #E0E0E0"
					}}
						gap={3}>
						<PSEResume
							name={productionStepExecution.productionStepSnapshot.name}
							status={productionStepExecution.status}
							transformation={productionStepExecution.productionStepSnapshot.transformation}
							section={productionStepExecution.sectionName}
							recipe={productionStepExecution.recipeName}
							uniqueCode={productionStepExecution.uniqueCode}
							onClickComments={onClickCommentsIcon}
							commentsCount={commentsCount}
						/>
						<PSEProgressBar
							currentSubstatus={productionStepExecution.substatus}
							productionStepExecution={productionStepExecution}
							visible={[PSE_STATUS.inProgress, PSE_STATUS.todo].includes(productionStepExecution.status)}
							times={{
								startTime: productionStepExecution.startTime,
								endTime: productionStepExecution.endTime,
								theoreticalStartTime: productionStepExecution.theoreticalStartTime,
								theoreticalEndTime: productionStepExecution.theoreticalEndTime
							}}
						/>
					</Stack>
					<Stack
						sx={{ p: 3 }}
						gap={3}
					>
						<PSEWeights productionStepExecution={productionStepExecution} status={productionStepExecution.status} />
						<PSEPreparation productionStepExecution={productionStepExecution} />
						<PSEStepComponents
							stepComponents={productionStepExecution.productionStepSnapshot.stepComponents}
							substitutes={productionStepExecution.substitutes}
							expectedProduction={productionStepExecution.expectedProduction}
							coeff={productionStepExecution.coeff}
							priorStepData={getPriorStepData(productionStepExecution)}
						/>
					</Stack>
				</Stack>
			</StyledMainContainer >
			{/* footer */}
			< ProductionStepExecutionFooter rightAction={renderRightActions()} />
		</div >
	)
}

export default ProductionStepExecutionTodo