import React from "react"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"

const BooleanIcons = ({ checked = false }) => {
  if (checked) {
    return <CheckCircleIcon sx={{ color: "#4bb200" }} />
  }

  return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
}

export default BooleanIcons
