import React, { useState} from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import EditIcon from "@mui/icons-material/Edit"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"

export const News = ({
                         classes,
                         news,
                         onEdit,
                         checkProductInCard,
                         showModalProductInCard,
                         canEdit = false
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [value, setValue] = useState(news)

    const clickIconButton = () => {
        setIsEditing(!isEditing)
        if (isEditing) {
            onEdit(value)
        }
    }
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }

    return (
        <Paper className={news ? classes.container : classes.emptyContainer}>
            <div className={classes.titleContainer}>
                <span className={news ? classes.title : classes.emptyTitle}>
                    Remarques et actualité
                </span>
                {
                    canEdit
                        ?
                            <IconButton
                                className={classes.iconContainer}
                                disableRipple={true}
                                onClick={checkThenEdit}
                                size="large">
                                <EditIcon className={classes.icon} />
                            </IconButton>
                        : null

                }
            </div>
            <div className={classes.newsContainer}>
                {
                    news
                }
            </div>

            <Dialog onClose={() => setIsEditing(false)} aria-labelledby="customized-dialog-title" open={isEditing}>
                <DialogTitle id="customized-dialog-title" onClose={() => setIsEditing(false)}>
                    Editer les remarques et actualités
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        variant="standard"
                        multiline
                        autoFocus
                        fullWidth
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        InputProps={{disableUnderline: false}}
                    />
                </DialogContent>
                <DialogActions classes={{root: classes.rootDialogActions}}>
                    <Button
                        onClick={() => setIsEditing(false)}
                    >
                        Annuler
                    </Button>

                    <Button
                        onClick={clickIconButton}
                        color="primary"
                        variant="contained"
                    >
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

const styles = {
    container: {
        backgroundColor: COLORS.BLUEGREY_700,
        borderRadius: 5,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)"
    },
    emptyContainer: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        color: COLORS.WHITE
    },
    emptyTitle: {
        composes: "$title",
        color: COLORS.BLACK
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    newsContainer: {
        padding: 16,
        fontFamily: "Roboto",
        fontSize: 19,
        lineHeight: "130%",
        color: COLORS.WHITE,
        whiteSpace: "pre-wrap"
    },
    rootDialogActions: {
        justifyContent: "space-between"
    }
}

export default withStyles(styles)(News)
