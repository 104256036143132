import React from "react"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    cell: {
        textAlign: "center"
    }
}))

export const DistributionCenterDispatchSitesTableHead = () => {

    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                <TableCell key={0} className={classes.cell} />
                <TableCell
                    key={1}
                    className={classes.cell}
                >
                    Sites
                </TableCell>
                <TableCell
                    key={2}
                    className={classes.cell}
                >
                    Priorité
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default DistributionCenterDispatchSitesTableHead