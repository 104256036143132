import React from "react"
import { ErrorMessage } from "formik"
import { FormHelperText } from "@mui/material"

const FormikErrorMessage = ({ name, className, errorClassName }) => {
  return (
    <ErrorMessage
      name={name}
      className={className}
      render={(message) => {
        if (typeof message !== "string") return
        return <FormHelperText className={errorClassName} error>{message}</FormHelperText>
      }}
    />
  )
}

export default FormikErrorMessage
