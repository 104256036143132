import moment from "moment"
import {packagingNature} from "../../utils/packaging"

const defaultState = {
	date: moment.utc().startOf("day").valueOf(),
	packaging: packagingNature[2].value,
	data: [],
	site: null,
	selectedProductsPackaging: [],
	snackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state=defaultState, action) {
	switch (action && action.type) {
		case "RECEPTION_WAREHOUSE_DATA_LOADED":
			return {
				...state,
				data: action.data,
				date: action.date,
				packaging: action.packaging,
				selectedProductsPackaging: action.selectedProductsPackaging,
				productTypeOptions: action.productTypeOptions

			}
		case "RECEPTION_WAREHOUSE_FILTERED_LOADED":
			return {
				...state,
				date: action.date,
				site: action.site,
				packaging: action.packaging,
			}
		case "RECEPTION_WAREHOUSE_DATE_UPDATED":
			return {
				...state,
				date: action.date
			}
		case "RECEPTION_WAREHOUSE_PACKAGING_UPDATED":
			return {
				...state,
				packaging: action.packaging
			}
		case "RECEPTION_WAREHOUSE_SELECTED_PRODUCTS_PACKAGING":
			return {
				...state,
				selectedProductsPackaging: action.selectedProductsPackaging
			}
		case "RECEPTION_WAREHOUSE_PACKAGING_MODE":
			return {
				...state,
				mode: action.mode
			}
		case "RECEPTION_WAREHOUSE_OPEN_SNACKBAR":
		case "RECEPTION_WAREHOUSE_CLOSE_SNACKBAR":
			return {
				...state,
				snackBar: action.snackBar
			}
		default:
			return {
				...state
			}
	}
}

export function getReceptionWarehouseItem(state, idProductpackaging) {
	return state.receptionWarehouse.data.find(data => data.objectId === idProductpackaging)
}


export const getReceptionWarehouseDateSelector = (state) => {
	return state.receptionWarehouse.date
}

export const getReceptionWarehouseSiteSelector = (state) => {
	return state.receptionWarehouse.site
}