import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import { makeStyles } from "@mui/styles"
import {
	getProductionReceptionProductionDateSelector, getProductionReceptionProductionSuppliesToDoSelector,
	getProductionReceptionSiteSelector, getProductionReceptionProductionSuppliesDoneSelector,
	getProductionReceptionStockZoneSelector
} from "../../reducers/ProductionReception/ProductionReception"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ProductionReceptionHeaderTitle from "./ProductionReceptionHeaderTitle"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import dayjs from "dayjs"
import {Tab, Tabs} from "@mui/material"
import ProductionReceptionToDo from "./ProductionReceptionToDo"
import ProductionReceptionHeaderInfo from "./Header/ProductionReceptionHeaderInfo"
import ProductionReceptionDone from "./ProductionReceptionDone"
import {showProductionReceptionFilter} from "../../actions/ProductionReception/ProductionReception"

const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
	},
	tab: {
		paddingLeft: 45,
		paddingRight: 45,
	}
})

const ProductionReception = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const productionDate = useSelector(getProductionReceptionProductionDateSelector)
	const productionSuppliesToDo = useSelector(getProductionReceptionProductionSuppliesToDoSelector)
	const productionSuppliesDone = useSelector(getProductionReceptionProductionSuppliesDoneSelector)
	const selectedSite = useSelector(getProductionReceptionSiteSelector)
	const selectedStockZone = useSelector(getProductionReceptionStockZoneSelector)
	const [tabValue, setTabValue] = useState(0)

	const onTabChange = async (e, tabValue) => setTabValue(tabValue)

	return (
		<div className={classes.wrapper}>
			<GenericTopBarHeader
				leftAction={<GenericHeaderReturnButton handleClick={() => dispatch(showProductionReceptionFilter())}/>}
				title={<ProductionReceptionHeaderTitle sx={{ fontSize: 16 }}/>}
				info={selectedSite && selectedStockZone && <ProductionReceptionHeaderInfo info={` - ${selectedSite.name} - ${selectedStockZone.name} - ${dayjs.tz(productionDate).format("DD/MM/YYYY")}`}/>}
			/>
			<Tabs
				value={tabValue !== null && tabValue !== undefined ? tabValue : false}
				onChange={onTabChange}
				textColor="primary"
				indicatorColor="primary"
				sx={{
					borderBottom: "1px solid #E6E6E6",
					"& .MuiTab-root": {
						padding: "16px 24px"
					},
					marginTop: "8px"
				}}
			>
				<Tab label={`Produits à réceptionner (${productionSuppliesToDo && productionSuppliesToDo.length})`} classes={{ root: classes.tab }} />
				<Tab label={`Produits réceptionnés (${productionSuppliesDone && productionSuppliesDone.length})`} classes={{ root: classes.tab }} />
			</Tabs>
			{
				tabValue === 0 &&
				<ProductionReceptionToDo
					site={selectedSite}
					productionSupplies={productionSuppliesToDo}
				/>
			}

			{
				tabValue === 1 &&
				<ProductionReceptionDone
					site={selectedSite}
					productionSupplies={productionSuppliesDone}
				/>
			}
		</div>
	)
}
export default ProductionReception

