export const CAN_UPDATE_SELL_PLANNING = "canUpdateSellPlanning"

export const CAN_UPDATE_PREV_PLANNING = "canUpdatePrevPlanning"

export const CAN_UPDATE_PROD_PLANNING = "canUpdateProdPlanning"
export const CAN_PRINT_PROD_PLANNING = "canPrintProdPlanning"
export const CAN_DELETE_PRODUCTION_PLANNING_PRODUCTION = "canDeleteProductionItemProductionPlanning"

export const CAN_UPDATE_PACKAGING_PLANNING = "canUpdatePackagingPlanning"
export const CAN_PRINT_PACKAGING_PLANNING = "canPrintPackagingPlanning"

export const CAN_UPDATE_DASHBOARD = "canUpdateDashboardPlanning"

export const CAN_UPDATE_PRODUCT_STOCK = "writeProductsStock"
export const CAN_CHECK_EVENT_PRODUCT_STOCK_GAP = "checkEventProductStockGap"

export const CAN_PRINT_CARD_PROD_PLANNING = "canPrintCardPlanningProduction"
export const CAN_SPLIT_PROD_PLANNING = "canSplitPlanningProduction"
export const CAN_ADD_PROD_PLANNING = "canAddPlanningProduction"
export const CAN_DRAG_N_DROP_PROD_PLANNING = "canDragAndDropPlanningProduction"
export const CAN_INPUT_VOLUME_PROD_PLANNING = "canInputVolumePlanningProduction"
export const CAN_USE_GENERAL_ACTIONS_PROD_PLANNING = "canUseGeneralActionsPlanningProduction"

export const CAN_PRINT_CARD_PACKAGING_PLANNING = "canPrintCardPackagingPlanning"
export const CAN_PRINT_LABEL_PACKAGING_PLANNING = "canPrintLabelPackagingPlanning"
export const CAN_DRAG_N_DROP_PACKAGING_PLANNING = "canDragAndDropPackagingPlanning"
export const CAN_INPUT_VOLUME_PACKAGING_PLANNING = "canInputVolumePackagingPlanning"
export const CAN_USE_GENERAL_ACTIONS_PACKAGING_PLANNING = "canUseGeneralActionsPackagingPlanning"

export const CAN_UPDATE_SITE_STOCK_ZONE = "writeSiteStockZone"
export const CAN_DELETE_SITE_STOCK_ZONE = "deleteSiteStockZone"

export const CAN_READ_SUPPLIERS = "readSuppliers"
export const CAN_READ_SUPPLIER_ITEMS = "readSupplierItems"

export const CAN_UPDATE_SUPPLIER = "writeSuppliers"
export const CAN_UPDATE_SUPPLIER_ITEMS = "writeSupplierItems"
export const CAN_DELETE_SUPPLIER_ITEMS = "deleteSupplierItems"
export const CAN_DELETE_PRODUCTS = "deleteProducts"
export const CAN_UPDATE_PRODUCTS = "writeProducts"

export const CAN_ACCESS_SETTINGS_ADMINISTRATION = "accessSettingsAdministration"

export const rightReadPlanningSell = {
    canReadSellPlanning: true
}

export const rightReadPlanningPrev = {
    canReadPrevPlanning: true
}
export const rightReadPlanningProd = {
    canReadProdPlanning: true
}

export const rightReadPlanningPackaging = {
    canReadPackagingPlanning: true
}

export const rightReadPlanningDashboard = {
    canReadDashboardPlanning: true
}

export const rightReadOrders = {
    readToValidateOrders: true
}

export const rightReadOrderReceptions = {
    readOrderReceptions: true
}

export const rightReadInventory = {
    readInventory: true
}

export const rightReadInventoryOutput = {
    readInventoryOutput: true
}

export const rightReadSecondaryDLC = {
    readSecondaryDLC: true
}

export const rightDispatch = {
    canDispatch: true
}

export const rightReceptionWarehouse = {
    canReceiveDispatch: true
}

export const rightReadRights = {
    readRoles: true
}

export const rightReadProductStock = {
    canReadProductsStock: true
}

export const rightReadProductPackaging = {
    canReadProductsPackaging: true
}

export const rightReadEventProductStockGap = {
    canReadEventProductStockGap: true
}

export const rightReadSiteStockZone = {
    canReadSiteStockZone: true
}

export const rightReadOrderBillings = {
    readOrderBillings: true
}

export const canSeeMisc = {
    seeMisc: true
}

export const canReadProductionSteps = {
    readProductionSteps: true
}

export const canReadPackagingExecutions = {
    readPackagingExecutions: true
}

export const canAccessSettingAdministration = {
    [CAN_ACCESS_SETTINGS_ADMINISTRATION]: true
}

export const canReadProductionReception = {
    readProductionReception: true
}

export const canReadProductionSubstitutions = {
    readProductionSubstitutions: true
}
