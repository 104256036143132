import React from "react"
import { Box, Button, Typography, Paper } from "@mui/material"
import { useDispatch } from "react-redux"
import { showProductionSchemasFrom } from "../../../../actions/ProductionSchemas/ProductionSchemas"

import ProductionSchemaReadOrEdit from "./ProductionSchemaReadOrEdit"

const ProductionSchemas = () => {
	const dispatch = useDispatch()
	const goToForm = () => dispatch(showProductionSchemasFrom())

	return (
		<Paper elevation={0}>
			<Box className="flexColumn flex1 stretchSelf" bgcolor="#fff" sx={{ px: 3 }}>
				<Box className="flexRow stretchSelf spaceBetween" sx={{ pt: 3 }}>
					<Typography>Schémas de production</Typography>
					<Button onClick={goToForm} variant="contained">Editer</Button>
				</Box>
			</Box>
			{/* production schemas list (same design as form but no edition possible) */}
			<ProductionSchemaReadOrEdit isEdition={false}/>
		</Paper>
	)
}

export default ProductionSchemas
