import { cloneDeep } from "lodash"

import { roundNumber } from "../utils"

export const formatValuesWithWeight = (stepObj) => {
  const currentStep = cloneDeep(stepObj)

  let reusableStepTotalGrossWeight = 0
  let totalGrossWeight = 0
  let totalNetWeight = 0
  let totalFoodCost = 0

  // Iterate over the productionSteps array in the object
  currentStep.productionSteps?.forEach((step, index) => {
    let stepGrossWeight = 0
    let stepNetWeight = 0
    let stepCost = 0

    // Iterate over the stepComponents array in each production step
    step.stepComponents?.forEach((stepComponent) => {
      // Add the grossWeight and netWeight of each stepComponent to the step totals
      if (!stepComponent.priorSteps) {
        stepComponent.realCost = getRessourceFoodCost(stepComponent.grossWeight || 0, stepComponent.supplierItem?.pricePerKg || 0)
        stepCost += stepComponent.realCost
        stepNetWeight += stepComponent.netWeight || 0
        stepGrossWeight += stepComponent.grossWeight || 0
      }

      if (index === currentStep.productionSteps.length - 1) {
        totalNetWeight += stepComponent.netWeight || 0
      }

      if (stepComponent.supplierItem) {
        reusableStepTotalGrossWeight += stepComponent.grossWeight
      }

      totalGrossWeight += stepComponent.grossWeight || 0

    })

    // Assign the step totals back to the production step
    step.grossWeight = roundNumber(stepGrossWeight)
    step.netWeight = roundNumber(stepNetWeight)
    step.cost = stepCost
    step.realCost = stepCost // TODO: To be removed after data sanitization

    totalFoodCost += step.cost
  })

  // Assign the total grossWeight and netWeight to the object
  currentStep.grossWeight = roundNumber(totalGrossWeight)
  currentStep.netWeight = roundNumber(totalNetWeight)
  currentStep.cost = roundNumber(totalFoodCost)
  currentStep.realCost = roundNumber(totalFoodCost) // TODO: This is what's used to display, use cost instead, later
  currentStep.reusableStepTotalGrossWeight = reusableStepTotalGrossWeight

  return currentStep
}

export const convertKilosIntoGrams = (weightInKilos) => {
  return !isNaN(weightInKilos) ? weightInKilos * 1000 : null
}

export const convertGramsIntoKilos = (weightInGrams) => {
  return weightInGrams / 1000
}

export const getRessourceFoodCost = (grossWeightInKilo = 0, pricePerKilo = 0) => {
  return grossWeightInKilo * pricePerKilo
}