import React from "react"

import styled from "@emotion/styled"

import { Stack, Typography } from "@mui/material"

import { weekDays } from "./ProductionSchemaBatchesField"
import { renderWeekDaysLabels, renderSellDaysLabels } from "../../../../utils/productionSchemasUtils"

import { StyledProductionSchemaBodyCell } from "./ProductionSchemaStyledComponents"
import { COLORS } from "../../../../utils"

export const StyledText = styled(Typography)({
    fontWeight: 400,
    fontSize: 14,
    color: COLORS.LABEL_GREY
})


const ProductionSchemaBatchesPreview = ({ batches }) => {
    return (<div className="flexColumn stretchSelf">
        {batches.length && (
            <Stack spacing={2} className="stretchSelf">
                {batches.map((batch, index) => (
                    // row
                    <div key={index} className="flexRow center">
                        <Stack direction="row" spacing={2} className="flex1">
                            {/* production day & packaging day (2 first fields) */}
                            {weekDays.map((weekday, weekDayIndex) => (
                                <Stack
                                    spacing={0}
                                    key={weekday + weekDayIndex}
                                    className="stretchSelf flex1"
                                >
                                    <StyledProductionSchemaBodyCell isEdition={false}>
                                        <StyledText>{renderWeekDaysLabels([batch[weekday]])}</StyledText>
                                    </StyledProductionSchemaBodyCell>
                                </Stack>
                            ))}

                            {/* sell day by brand (3 last fields) */}
                            {batch.sellDays.map(({ days }, sellDayIndex) => (
                                <Stack
                                    spacing={0}
                                    key={sellDayIndex}
                                    className="stretchSelf flexColumn flex1"
                                >
                                    <StyledProductionSchemaBodyCell isEdition={false}>
                                        <StyledText>{renderSellDaysLabels(days)}</StyledText>
                                    </StyledProductionSchemaBodyCell>
                                </Stack>
                            ))}
                        </Stack >
                    </div>
                ))}
            </Stack>
        )}
    </div>)
}

export default ProductionSchemaBatchesPreview