import {
    actionWithLoader,
    onEnter,
    getReplacingPath,
    push
} from "../Utils/utils"
import {
    getChefs,
    createOrUpdateParseChef,
    getSingleChef,
    saveChef
} from "../../parseManager/products/resources/chef/parseChefsManager"
import { updateAllProductsCounter } from "../Products/Products"
import { showChefsList } from "../Products/Products"
import { setValueToParseObject } from "../../parseManager/planning/parsePlanningManager"

export function loadChefs() {
    return actionWithLoader(async (dispatch) => {
        const chefs = await getChefs()
        dispatch({
            type: "RESOURCES_CHEFS_LOADED",
            chefs
        })
    })
}

export function onEnterChefs(store) {
    return onEnter({
        store,
        actionThunk: loadChefs,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function createOrUpdateChef(values, id, location) {
    return actionWithLoader(async (dispatch) => {
        try {
            const newChef = await createOrUpdateParseChef(values, id)
            await dispatch(loadChefs())
            await dispatch(updateAllProductsCounter())
            if (location.state && location.state.returnPath === "edit" && newChef){
                await dispatch(showChefRead(newChef.objectId))
            }
            else {
                await dispatch(showChefsList())
            }
        } catch(err) {
            dispatch({
                type: "ERROR",
                message: "Erreur lors de la création/modification suppression du produit."
            })
        }
    })
}

export function addImageToChef(values, id) {
    return actionWithLoader(async (dispatch) => {
        try {
            await updateImageToAChef(values, id)
            await dispatch(loadChefs())
        } catch(err) {
            return Promise.reject(err)
        }
    })
}

export function removeImageToChef(id) {
    return actionWithLoader(async (dispatch) => {
        try {
            await updateImageToAChef(null, id)
            await dispatch(loadChefs())
        } catch(err) {
            return Promise.reject(err)
        }
    })
}
export function showChefRead(id) {
    if (id) {
        return push(`/settings/resources/chef/${id}`)
    }
    return push("/settings/resources/chef")
}

export function showChefEdit(id, location) {
    if (id) {
        return push(`/settings/resources/chef/${id}/edit`, location)
    }
    return push("/settings/resources/chef")
}

async function updateImageToAChef(image, id) {
    const chef = await getSingleChef(id, false)
    setValueToParseObject(chef, "image", image)
    await saveChef(chef)
}
