import Parse from "parse"
import { parseLimitRequest, roundNumber } from "../../../utils"
import { getSingleChef } from "../resources/chef/parseChefsManager"
import { getPackagingCost } from "../../../utils/recipes"
import { computeSubcontractorProductNetWeight } from "../../../utils/subcontractorProduct"

const SubcontractorsProducts = Parse.Object.extend("SubcontractorsProducts")
const SubcontractorCommercialName = Parse.Object.extend("SubcontractorsCommercialNames")
const SupplierItems = Parse.Object.extend("SupplierItems")
const Suppliers = Parse.Object.extend("Suppliers")
const Packaging = Parse.Object.extend("Packaging")
const ProductsTags = Parse.Object.extend("ProductsTags")

export async function getSubcontractorsProducts({ includes = [], selects = [], sortBy = "name", sortDirection = "asc", toJSON = false } = {}) {
    try {
        const query = new Parse.Query(SubcontractorsProducts)
            .notEqualTo("deleted", true)

        if (includes?.length) {
            query.include(includes)
        }

        if (selects?.length) {
            query.select(selects)
        }

        if (sortDirection === "desc") {
            query.descending(sortBy)
        } else {
            query.ascending(sortBy)
        }

        const subcontractorsProducts = await query.limit(3000).find()

        return toJSON ? (subcontractorsProducts ? subcontractorsProducts.map(el => el.toJSON()) : []) : subcontractorsProducts
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.getFilteredSubcontractorsProducts error : ", e)
        return []
    }
}

export async function getFilteredSubcontractorsProducts(filters) {
    try {
        let query = new Parse.Query(SubcontractorsProducts)
        query.notEqualTo("deleted", true)

        if (filters.search) {
            const regex = new RegExp(filters.search, "ig")

            const innerSupplierItemQuery = new Parse.Query(SupplierItems)
                .matches("name", regex)

            query = new Parse.Query.or(
                new Parse.Query(SubcontractorsProducts).matchesQuery("name.name", innerSupplierItemQuery),
                new Parse.Query(SubcontractorsProducts).matches("commercialName", regex),
                new Parse.Query(SubcontractorsProducts).matches("uniqueCode", regex)
            )
        }

        if (filters.status && !filters.status.includes("ALL")) {
            query.containedIn("status", filters.status)
        }
        if (filters.types && !filters.types.includes("ALL")) {
            query.containedIn("type", filters.types)
        }
        if (filters.brands && !filters.brands.includes("ALL")) {
            query.containedIn("brand", filters.brands)
        }
        if (filters.isActive && !filters.isActive.includes("ALL")) {
            query.containedIn("isActive", filters.isActive.map(el => JSON.parse(el)))
        }

        if (filters.suppliers && filters.suppliers.length > 0 && !filters.suppliers.includes("ALL")) {
            const innerSupplierQuery = new Parse.Query(Suppliers)
                .containedIn("objectId", filters.suppliers.map(el => el.objectId))

            const innerQuery = new Parse.Query(SupplierItems)
                .notEqualTo("deleted", true)
                .matchesQuery("supplier", innerSupplierQuery)

            query.matchesQuery("name", innerQuery)
        }

        query
            .notEqualTo("deleted", true)
            .include(["chef.image"])
            .include(["name.supplier"])
            .include(["subcontractorCommercialNames.commercialName"])
            .include(["subcontractorCommercialNames.commercialName.allergens"])
            .include(["internalTag"])

        const [subcontractorProducts, total] = await Promise.all([
            query
                .limit(filters.rowsPerPage)
                .skip(filters.page * filters.rowsPerPage)
                .find() || [],
            query.count()
        ])

        return {
            subcontractorProducts,
            total
        }
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.getFilteredSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function getSubcontractorsProductsRange(field, returnValue = false) {
    try {
        const subcontractorProductMin = await new Parse.Query(SubcontractorsProducts)
            .select(field)
            .notEqualTo(field, null)
            .notEqualTo("deleted", true)
            .ascending(field)
            .first()

        const subcontractorProductMax = await new Parse.Query(SubcontractorsProducts)
            .select(field)
            .notEqualTo("deleted", true)
            .notEqualTo(field, null)
            .descending(field)
            .first()

        return returnValue ?
            [subcontractorProductMin.get(returnValue), subcontractorProductMax.get(returnValue)] :
            [subcontractorProductMin.get(field), subcontractorProductMax.get(field)]
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.getSubcontractorsProductsRange error : ", e)
        return Promise.reject(e)
    }
}

export async function countSubcontractorsProducts() {
    try {
        return await new Parse.Query(SubcontractorsProducts).count()
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function getSubcontractorProductForPlanning(id, toJSON = true) {
    try {
        const subcontractorProduct = await new Parse.Query(SubcontractorsProducts)
            .select(["description", "nutritionInformation", "internalTag.type", "specialInstruction", "heatingInstructions", "preparation", "netWeight", "subcontractorCommercialNames.commercialName.name", "subcontractorCommercialNames.commercialName.allergens.name", "subcontractorCommercialNames.pourcentage", "internalTag", "subcontractorCommercialNames.commercialName.allergens"])
            .equalTo("objectId", id)
            .first()

        return subcontractorProduct ? toJSON ? subcontractorProduct.toJSON() : subcontractorProduct : null
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.getSubcontractorProductForPlanning error : ", e)
        return Promise.reject(e)
    }
}

export async function getSubcontractorProductById(id, includes = [], toJSON = true) {
    try {
        const subcontractorProduct = await new Parse.Query(SubcontractorsProducts)
            .equalTo("objectId", id)
            .include(includes)
            .first()

        return subcontractorProduct ? toJSON ? subcontractorProduct.toJSON() : subcontractorProduct : null
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.getSubcontractorProductById error : ", e)
        return Promise.reject(e)
    }
}

export async function updateSubcontractorProductTotalCost(idSupplierItem) {
    try {
        const supplierItem = await new Parse.Query(SupplierItems)
            .equalTo("objectId", idSupplierItem)
            .notEqualTo("deleted", true)
            .first()

        const subcontractorProducts = await new Parse.Query(SubcontractorsProducts)
            .notEqualTo("deleted", true)
            .equalTo("name", supplierItem)
            .find() || []

        subcontractorProducts.forEach(async subcontractorProduct => {
            const packagingId = subcontractorProduct.get("packaging") ? subcontractorProduct.get("packaging").id : null
            const packagingCost = await getPackagingCost(packagingId)
            const subcontractorIngredientCost = subcontractorProduct.get("name").get("units") && subcontractorProduct.get("name").get("units").stock
                ? roundNumber(subcontractorProduct.get("name").get("units").stock.price / subcontractorProduct.get("name").get("units").stock.unity.quantity)
                : null

            const units = supplierItem.get("units")
            const totalCost = packagingCost + subcontractorIngredientCost

            subcontractorProduct.set("totalCost", totalCost)
            subcontractorProduct.set("subcontractorIngredientCost", subcontractorIngredientCost)
            subcontractorProduct.set("netWeight", computeSubcontractorProductNetWeight(units))

            await subcontractorProduct.save()
        })
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.updateSubcontractorProductTotalCost error : ", e)
        return Promise.reject(e)
    }
}

export async function parseUpdateSubcontractorDetails(values, subcontractorProduct) {
    try {
        const keys = [
            "dlc",
            "price",
            "description",
            "appImage",
            "preparation",
            "specialInstruction",
            "heatingInstructions",
            "packagingCost",
            "totalCost"
        ]

        keys.forEach(key => {
            subcontractorProduct.set(key, values[key])
        })

        let packaging = null
        if (values.packaging) {
            packaging = await new Parse.Query(Packaging)
                .equalTo("objectId", values.packaging)
                .first()
        }

        subcontractorProduct.set("packaging", packaging)

        let subPackagings = []
        if (values.subPackaging && Array.isArray(values.subPackaging) && values.subPackaging.length) {
            subPackagings = (await new Parse.Query(Packaging)
                .containedIn("objectId", values.subPackaging.map(obj => obj.value))
                .limit(parseLimitRequest)
                .find()) || []
        }

        subcontractorProduct.set("subPackaging", subPackagings)

        let reusablePackaging = null
        if (values.reusablePackaging) {
            reusablePackaging = await new Parse.Query(Packaging)
            .equalTo("objectId", values.reusablePackaging)
            .first()
        }

        subcontractorProduct.set("reusablePackaging", reusablePackaging)

        let reusableSubPackagings = []
        if (values.reusableSubPackagings && Array.isArray(values.reusableSubPackagings) && values.reusableSubPackagings.length) {
            reusableSubPackagings = (await new Parse.Query(Packaging)
            .containedIn("objectId", values.reusableSubPackagings.map(obj => obj.value))
            .limit(parseLimitRequest)
            .find()) || []
        }

        subcontractorProduct.set("reusableSubPackagings", reusableSubPackagings)

        return await subcontractorProduct.save()
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export const getSupplierItemPointer = (supplierItemId) => {

    return SupplierItems.createWithoutData(supplierItemId)
}

export async function parseSaveProductResume(subcontractorProduct, values) {
    try {
        let ids = []
        Object.keys(values).filter(elem => elem.includes("internalTag")).forEach(key => {
            ids = ids.concat(values[key])
        })

        const keys = [
            "commercialName",
            "name",
            "ean",
            "tva",
            "spicy",
            "status",
            "type",
            "isActive",
            "brand",
            "heatingInstructions",
            "netWeight",
            "subcontractorIngredientCost",
            "packagingCost",
            "totalCost",
            "specialInstruction",
            "preparation",
            "pvc",
            "legalName"
        ]

        if (values.name && (subcontractorProduct.get("name").id !== values.name.id)) {
            values.name.set("subcontractorProductQuantity", (values.name.get("subcontractorProductQuantity") ? values.name.get("subcontractorProductQuantity") + 1 : 1))
            subcontractorProduct.get("name").set("subcontractorProductQuantity", (subcontractorProduct.get("name").get("subcontractorProductQuantity") ? subcontractorProduct.get("name").get("subcontractorProductQuantity") - 1 : 0))
            await values.name.save()
            await subcontractorProduct.get("name").save()
        }
        keys.forEach(key => {
            subcontractorProduct.set(key, values[key])
        })

        let packaging = null
        if (values.packaging) {
            packaging = await new Parse.Query(Packaging)
                .equalTo("objectId", values.packaging)
                .first()
        }
        subcontractorProduct.set("packaging", packaging)

        let subPackagings = []
        if (values.subPackaging && Array.isArray(values.subPackaging) && values.subPackaging.length) {
            subPackagings = (await new Parse.Query(Packaging)
                .containedIn("objectId", values.subPackaging.map(obj => obj.value))
                .limit(parseLimitRequest)
                .find()) || []
        }
        subcontractorProduct.set("subPackaging", subPackagings)

        const internalTags = (await new Parse.Query(ProductsTags)
            .containedIn("objectId", ids)
            .limit(parseLimitRequest)
            .find()) || []
        subcontractorProduct.set("internalTag", internalTags)

        const chef = values.chef
            ? await getSingleChef(values.chef, false)
            : null
        subcontractorProduct.set("chef", chef)

        return await subcontractorProduct.save()
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function parseSaveCommercialNamesAndSeason(subcontractorProduct, values) {
    try {
        if (values.subcontractorCommercialNames) {
            const currentSubcontractorCommercialNames = await Promise.all(values.subcontractorCommercialNames.map(async (subcontractorCommercialName) => {
                if (subcontractorCommercialName.id !== null) {
                    const currentCommercialName = await new Parse.Query(SubcontractorCommercialName)
                        .equalTo("objectId", subcontractorCommercialName.id)
                        .first()

                    if (currentCommercialName !== undefined) {
                        return {
                            pourcentage: subcontractorCommercialName.pourcentage,
                            commercialName: currentCommercialName,
                            cooked: subcontractorCommercialName.cooked
                        }
                    }

                    return null
                } else {
                    const currentSubcontractor = subcontractorProduct.get("subcontractor")

                    if (currentSubcontractor) {
                        const newCurrentCommercialName = new SubcontractorCommercialName()
                        newCurrentCommercialName.set("name", subcontractorCommercialName.label)
                        newCurrentCommercialName.set("subcontractor", currentSubcontractor)

                        return {
                            pourcentage: subcontractorCommercialName.pourcentage,
                            commercialName: newCurrentCommercialName,
                            cooked: subcontractorCommercialName.cooked
                        }
                    }

                    return null
                }
            }))

            subcontractorProduct.set("subcontractorCommercialNames", (
                currentSubcontractorCommercialNames && currentSubcontractorCommercialNames.length > 0
                    ? currentSubcontractorCommercialNames
                    : []
            )
            )
        }

        if (values.season) subcontractorProduct.set("season", values.season)

        return await subcontractorProduct.save()
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function parseSaveNutritionValues(subcontractorProduct, values, infoNutrition) {
    try {
        if (values.calories) infoNutrition.calories = Number(values.calories)
        if (values.fat) infoNutrition.fat = Number(values.fat)
        if (values.saturatedFattyAcids) infoNutrition.saturatedFattyAcids = Number(values.saturatedFattyAcids)
        if (values.carbohydrates) infoNutrition.carbohydrates = Number(values.carbohydrates)
        if (values.sugar) infoNutrition.sugar = Number(values.sugar)
        if (values.proteins) infoNutrition.proteins = Number(values.proteins)
        if (values.salt) infoNutrition.salt = Number(values.salt)
        if (values.fibers) infoNutrition.fibers = Number(values.fibers)

        if (infoNutrition) {
            subcontractorProduct.set("nutritionInformation", infoNutrition)

            await subcontractorProduct.save()
        }

        return subcontractorProduct
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function parseSaveNutritionSourcing(subcontractorProduct, values, infoNutrition) {
    try {
        if (values.frenchIngredientsRate) infoNutrition.frenchIngredientsRate = Number(values.frenchIngredientsRate)
        if (values.certifiedIngredientsRate) infoNutrition.certifiedIngredientsRate = Number(values.certifiedIngredientsRate)
        if (values.organicIngredientsRate) infoNutrition.organicIngredientsRate = Number(values.organicIngredientsRate)
        if (values.localIngredientsRate) infoNutrition.localIngredientsRate = Number(values.localIngredientsRate)
        if (values.seasonalIngredientsRate) infoNutrition.seasonalIngredientsRate = Number(values.seasonalIngredientsRate)
        if (values.carbonFootPrint) infoNutrition.carbonFootPrint = Number(values.carbonFootPrint)

        infoNutrition.sustainableFishing = (undefined !== values.sustainableFishing) ? values.sustainableFishing : false

        if (infoNutrition) {
            subcontractorProduct.set("nutritionInformation", infoNutrition)

            await subcontractorProduct.save()
        }

        return subcontractorProduct
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function parseSaveNutritionScore(subcontractorProduct, values, infoNutrition) {
    try {
        if (values.nutriscore) infoNutrition.nutriscore = values.nutriscore
        if (values.carboneScore) infoNutrition.carboneScore = values.carboneScore

        if (infoNutrition) {
            subcontractorProduct.set("nutritionInformation", infoNutrition)

            await subcontractorProduct.save()
        }

        return subcontractorProduct
    } catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.countSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}

export async function getSubcontractorsProductsByFilters(includes = [], filters, brand) {
    try {
        const query = await new Parse.Query(SubcontractorsProducts)
            .notEqualTo("deleted", true)

        if (brand) {
            query.equalTo("brand", brand)
        }

        if (filters) {
            if (filters.types) {
                query.containedIn("type", filters.types)
            }

            if (filters.status) {
                query.equalTo("status", filters.status)
            }
        }

        const subcontractorsProducts = await query
            .ascending("name")
            .include(includes)
            .limit(parseLimitRequest)
            .find()

        return subcontractorsProducts ? subcontractorsProducts.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.products.subcontractorProduct.parseSubcontractorProductManager.getSubcontractorsProducts error : ", e)
        return Promise.reject(e)
    }
}
