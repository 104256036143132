import { Tooltip } from "@mui/material"
import React from "react"
import { IntegratedSummary } from "@devexpress/dx-react-grid"
import { TableBandHeader, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui"
import { Table } from "@devexpress/dx-react-grid-material-ui"
import { productType } from "../../../../utils/dispatchUtils"
import {
    COLORS, forEachBrand, getBrandBy, KFC_BRANDS
} from "../../../../utils"

const PADDING_CELLS = 3

const _tableColumnExtensions = [{ name: "dateLabel", align: "left" }]
const _targetsColumns = [{ name: "dateLabel", title: "Jours de vente" }]
const _columnBands = []
const _totalSummaryItems = [{ columnName: "dateLabel", type: "label" }]
const _totalSummaryFoodCostItems = [{ columnName: "dateLabel", type: "label" }]
const _salesDaysColumns = [{ name: "dateLabel", title: "Jours de vente" }]
const _foodCostsColumns = [{ name: "dateLabel", title: "Jours de vente" }]

forEachBrand(brand => {
    const columnBand = { title: brand.label, children: [] }
    brand.monitoring.types.forEach(type => {
        const completeType = productType.find(t => t.type === type.split("+")[0])
        const tooltip = (type.split("+").length > 1) ?
            productType.filter(t => type.split("+").includes(t.type)).reduce((a, b) => `${a.label}s + ${b.label}${b.label !== "meal prep" ? "s" : ""}`)
            : ""
        const name = `${brand.name}_${type}`

        _targetsColumns.push({ name: name, title: completeType.label, tooltip })
        _salesDaysColumns.push({ name: name, title: completeType.label, tooltip })
        _foodCostsColumns.push({ name: name, title: completeType.label, tooltip })
        _tableColumnExtensions.push({ columnName: name, align: "center" })
        _totalSummaryItems.push({ columnName: name, type: "sum" })
        _totalSummaryFoodCostItems.push({ columnName: name, type: "avg_if" })
        columnBand.children.push({ columnName: name })
    })
    _columnBands.push(columnBand)
})

export const targetsColumns = _targetsColumns
export const salesDaysColumns = _salesDaysColumns
export const foodCostColumns = _foodCostsColumns
export const columnBands = _columnBands
export const tableColumnExtensions = _tableColumnExtensions
export const totalSummaryItems = _totalSummaryItems
export const totalSummaryFoodCostItems = _totalSummaryFoodCostItems


export const getTableInfos = (productTypesByBrand) => {
	const _tableColumnExtensions = [{ name: "dateLabel", align: "left" }]
	const _targetsColumns = [{ name: "dateLabel", title: "Jours de vente" }]
	const _columnBands = []
	const _totalSummaryItems = [{ columnName: "dateLabel", type: "label" }]
	const _totalSummaryFoodCostItems = [{ columnName: "dateLabel", type: "label" }]
	const _salesDaysColumns = [{ name: "dateLabel", title: "Jours de vente" }]
	const _foodCostsColumns = [{ name: "dateLabel", title: "Jours de vente" }]

	for (const brand of KFC_BRANDS) {
        const columnBand = { title: brand.label, children: [] }
        const productTypes = productTypesByBrand[brand.name]

        if (productTypes) {
            for (const productType of productTypes) {
                const productTypeName = productType.kfcName
                const tooltip = (productTypeName.split("+").length > 1) ?
                        productType.filter(t => productTypeName.split("+").includes(t.type)).reduce((a, b) => `${a.label}s + ${b.label}${b.label !== "meal prep" ? "s" : ""}`)
                        : ""
                const name = `${brand.name}_${productTypeName}`

                _targetsColumns.push({ name: name, title: productType.label, tooltip })
                _salesDaysColumns.push({ name: name, title: productType.label, tooltip })
                _foodCostsColumns.push({ name: name, title: productType.label, tooltip })
                _tableColumnExtensions.push({ columnName: name, align: "center" })
                _totalSummaryItems.push({ columnName: name, type: "sum" })
                _totalSummaryFoodCostItems.push({ columnName: name, type: "avg_if" })
                columnBand.children.push({ columnName: name })     
            }

            _columnBands.push(columnBand) 
        }
	}

	return {
		targetsColumns: _targetsColumns,
		salesDaysColumns: _salesDaysColumns,
		foodCostColumns: _foodCostsColumns,
		columnBands: _columnBands,
		tableColumnExtensions: _tableColumnExtensions,
		totalSummaryItems: _totalSummaryItems,
		totalSummaryFoodCostItems: _totalSummaryFoodCostItems,
	}
}

export const BandCell = () => ({ children, column, ...restProps }) => {
    const brand = getBrandBy("label", column.title)
    const backgroundColor = brand ? brand.color : COLORS.WHITE
    const color = brand ? COLORS.WHITE : COLORS.BLACK

    return (
        <TableBandHeader.Cell
            {...restProps}
            column={column}
            style={{ backgroundColor: backgroundColor, color: color, padding: PADDING_CELLS, textAlign: "center" }}
        >
            {children}
        </TableBandHeader.Cell>
    )
}

export const TableSummaryRowCell = ({ value }) => {
    return (
        <span style={{ padding: 4 }}>
            {value}
        </span>
    )
}

export const summaryCalculator = (type, rows, getValue) => {
    if (type === "avg_if") {
        if (!rows.length) {
            return null
        }
        const filteredRows = rows.filter(row => getValue(row) !== 0)
        const sum = filteredRows.reduce((a, b) => {
            return (getValue(a) ? getValue(a) : 0) + getValue(b)
        }, 0)

        return (sum !== 0) ? (sum / filteredRows.length).toFixed(2) : sum
    }

    return "label" === type
        ? "Total"
        : IntegratedSummary.defaultCalculator(type, rows, getValue)
}

export const SummaryCell = ({ color = COLORS.BLACK, targetsData, data }) => (props) => {
    let backgroundColor = COLORS.WHITE
    if (targetsData && data) {
        const field = props.tableColumn.column.name
        for (const brand of KFC_BRANDS) {
            if (field.includes(brand.name)) {
                const dataTotal = data.map(elem => elem[field]).reduce((sum, current) => sum + current, 0)
                const targetsTotal = targetsData.map(elem => elem[field]).reduce((sum, current) => sum + current, 0)
                backgroundColor = getBackgroundColor(targetsTotal, dataTotal)
            }
        }
    }
    return <Table.Cell {...props} style={
        {
            backgroundColor: backgroundColor,
            padding: PADDING_CELLS,
            fontWeight: "600",
            color: color,
            fontSize: "0.875rem"
        }
    } />
}

export const SummaryFoodCostCell = ({ color = COLORS.BLACK }) => (props) => {
    let backgroundColor = COLORS.WHITE
    return <Table.Cell {...props} style={
        {
            backgroundColor: backgroundColor,
            padding: PADDING_CELLS,
            fontWeight: "600",
            color: color,
            fontSize: "0.875rem"
        }
    } />
}

const setRef = (forwardedRef, node) => {
    if (typeof forwardedRef === "function") {
        forwardedRef(node)
    } else if (forwardedRef) {
        forwardedRef.current = node
    }
}

export const HeaderRowCell = (componentProps) => {
    const { forwardedRef } = componentProps
    const Cell = React.forwardRef((props, ref) => {
        const innerRef = (node) => {
            setRef(forwardedRef, node)
            setRef(ref, node)
        }

        return (
            <TableHeaderRow.Cell
                {...componentProps}
                {...props}
                forwardedRef={innerRef}
                style={{
                    color: componentProps.color || COLORS.BLACK,
                    padding: PADDING_CELLS,
                    borderRight: "0.5px solid #E0E0E0"

                }}
            />
        )
    })

    return (
        <Tooltip title={componentProps.column.tooltip || ""}>
            <Cell />
        </Tooltip>
    )
}

export const TableCell = ({ color = COLORS.BLACK }) => (props) => {
    return <Table.Cell {...props} style={{ color: color, padding: PADDING_CELLS }} />
}

export const ColoredCell = (myprops) => (tableProps) => {
    const rowDate = tableProps && tableProps.row && tableProps.row.dateLabel ? tableProps.row.dateLabel : null
    const columnName = tableProps && tableProps.column && tableProps.column.name ? tableProps.column.name : null
    let backgroundColor = COLORS.WHITE

    if (columnName !== "dateLabel") {
        const targetData = myprops && Array.isArray(myprops.targetsData) ? myprops.targetsData.find(elem => elem.dateLabel === rowDate) : null
        const targetValue = targetData ? targetData[columnName] : 0
        const value = tableProps.value
        backgroundColor = getBackgroundColor(targetValue, value)
    }

    return <Table.Cell {...tableProps} style={{ backgroundColor: backgroundColor, color: myprops.color, padding: PADDING_CELLS }} />
}

export const ColoredFoodCostCell = (myprops) => (tableProps) => {
    let backgroundColor = COLORS.WHITE
    return <Table.Cell {...tableProps} style={{ backgroundColor: backgroundColor, color: myprops.color, padding: PADDING_CELLS }} />
}

function getBackgroundColor(targetValue, value) {
    const pct = (targetValue - value) / value * 100
    if (pct < 1 && pct > -1) {
        return COLORS.GREEN_PLANNING
    } else if (pct < 3 && pct > -3) {
        return COLORS.ORANGE_PLANNING
    } else if (!isNaN(pct) || (targetValue !== 0 && value !== 0)) {
        return COLORS.RED_PLANNING
    }
}
