import React, { useState } from "react"
import { Stack, Button, DialogContentText, DialogContent, Dialog, DialogActions } from "@mui/material"
import { makeStyles } from "@mui/styles"
import RefuseOrderReception from "./RefuseOrderReception"
import MoreMenu from "../../../components/MoreMenu"

const useStyles = makeStyles({
  btnContainer: {
      display: "flex",
      alignItems: "center"
  },
  firstBtn: {
      marginRight: 20
  },
})

const SingleOrderReceptionFooter = ({
  orderReception,
  canEdit,
  articlesTodo,
  moreMenus,
  startTreatReception,
  handleDeliveryNote,
  handleDeliveryNoteControl,
  onDeliveryNoteControl,
  onCloseReception
}) => {
  const [openCloseDeliveryNoteDialog, setOpenCloseDeliveryNoteDialog] = useState(false)

  const classes = useStyles()

  const toggleCloseDeliveryNoteDialog = () => setOpenCloseDeliveryNoteDialog(!openCloseDeliveryNoteDialog)

  const handleCloseReception = async () => {
    await onCloseReception()
    setOpenCloseDeliveryNoteDialog(false)
  }

  if (orderReception?.receptionStatus === "TODO") {
    return (
        <Stack direction="row" spacing={2}>
            <RefuseOrderReception orderReception={orderReception} />
            <Button
                variant="contained"
                color="primary"
                onClick={startTreatReception}
            >
                Commencer la réception
            </Button>
            <MoreMenu menus={moreMenus} />
        </Stack>
    )
  }
  
  if (articlesTodo.length === 0 && !orderReception?.deliveryNote) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={onDeliveryNoteControl}
      >
        Contrôler le bon de livraison
      </Button>
    )
  }

  return (
    <div className={classes.btnContainer}>
      <div>
        {!canEdit && orderReception && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.firstBtn}
            onClick={handleDeliveryNote}
          >
            {orderReception.deliveryNote
              ? "Modifier le bon de livraison"
              : "Ajouter le numéro de BL + Photos"}
          </Button>
        )}
      </div>
      <div>
        {!canEdit && orderReception?.receptionStatus === "IN_PROGRESS" && (
          orderReception.deliveryNote
            ? (
              <Button
                variant="contained"
                color="primary"
                disabled={articlesTodo.length !== 0}
                onClick={toggleCloseDeliveryNoteDialog}
              >
                Clôturer la réception
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={articlesTodo.length !== 0}
                onClick={handleDeliveryNoteControl}
              >
                Vérifier le bon de Livraison
              </Button>
            )
        )}
      </div>
      <MoreMenu menus={moreMenus} />

      <Dialog
        open={openCloseDeliveryNoteDialog}
        onClose={toggleCloseDeliveryNoteDialog}
      >
        <DialogContent>
          <DialogContentText>
            Souhaitez-vous clôturer la réception ? Cette action enverra la demande d’avoir au fournisseur et empêchera toute nouvelle modification.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleCloseDeliveryNoteDialog} variant="text">
            Annuler
          </Button>
          <Button onClick={handleCloseReception} color="primary" variant="text">
            Clôturer
          </Button>
        </DialogActions>
        </Dialog>
    </div>
  )
}

export default SingleOrderReceptionFooter