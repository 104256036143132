import React from "react"
import {
    Grid,
    Table,
    TableBandHeader,
    TableHeaderRow,
    TableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui"
import {
    IntegratedSummary,
    SummaryState
} from "@devexpress/dx-react-grid"
import {
    TableSummaryRowCell,
    BandCell,
    HeaderRowCell,
    ColoredFoodCostCell,
    SummaryFoodCostCell
} from "./Monitoring"

export const FoodCostsTable = (props) => {
    const { data, targetsData, salesDatesData, tableData } = props

    const foodcostCalculator = (type, rows, getValue) => {
        let sum = 0
        let count = 0
        if (type === "avg_if") {
            for (let i = 0; i < rows.length; i++) {
                //---- we retrieve all the data ----//
                const row = rows[i]
                const salesRow = salesDatesData[i] // from the other table
                const cost = getValue(row)
                const volume = getValue(salesRow) // we're lucky it works

                sum += volume * cost
                count += volume
            }
            //---- now we can add the weighted value ----//
            return (sum !== 0) ? (sum / count).toFixed(2) : sum
        }
        return "label" === type
            ? "Total"
            : IntegratedSummary.defaultCalculator(type, rows, getValue)
    }

    return (
        <Grid
            rows={data}
            columns={tableData?.foodCostColumns}
        >
            <SummaryState
                totalItems={tableData?.totalSummaryFoodCostItems}
            />
            <IntegratedSummary
                calculator={foodcostCalculator}
            />

            <Table
                columnExtensions={tableData?.tableColumnExtensions}
                cellComponent={ColoredFoodCostCell({ targetsData: targetsData })}
            />
            <TableHeaderRow
                cellComponent={HeaderRowCell}
            />
            <TableBandHeader
                columnBands={tableData?.columnBands}
                cellComponent={BandCell()}
            />
            <TableSummaryRow
                itemComponent={TableSummaryRowCell}
                totalCellComponent={SummaryFoodCostCell({ targetsData: targetsData, data: data })}
            />
        </Grid>
    )
}

export default FoodCostsTable
