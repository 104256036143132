import React, { useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Modal from "@mui/material/Modal"

import FullScreenWrapper from "../../../components/FullScreenWrapper"
import {
    showOrderFilter,
    showSingleOrder,
    createOrderFromReception,
} from "../../../actions/OrderReception/OrderReception"
import {
    generateReceptionLabel
} from "../../../utils/orderReception"
import OrderReceptionToDo from "./OrderReceptionToDo"
import { COLORS } from "../../../utils"
import OrderReceptionReceived from "./OrderReceptionReceived"
import CreateOrderReceptionModal from "../../../components/Order/Reception/CreateOrderReceptionModal"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import OrderReceptionHeaderTitle from "../../../components/Order/Reception/OrderReceptionHeaderTitle"
import GenericHeaderInfo from "../../../components/GenericHeaderInfo"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import { getOrders } from "../../../parseManager/orders/parseOrdersManager"
import { getSiteById } from "../../../parseManager/site/parseSiteManager"


const useStyles = makeStyles({
    container: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    headerBloc: {
        flex: "0 0 66px",
        height: 66 // (including 2px for box-shadow)
    },
    tabContainer: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    tab: {
        fontSize: 24
    },
    addBtn: {
        color: COLORS.WHITE,
        borderRadius: "50%",
        fontSize: 24,
        height: 56,
        width: 56
    }
})

const OrderReceptionTabs = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const site = useSelector(state => state.orderReception.site)
    const date = useSelector(state => state.orderReception.date)
    let ordersTodo = useSelector(state => state.orderReception.orderReceptionsToDo)
    let ordersInProgress = useSelector(state => state.orderReception.orderReceptionsInProgress)
    let ordersDone = useSelector(state => state.orderReception.orderReceptionsDone)
    const suppliers = useSelector(state => state.suppliers.suppliers)

    const [tabValue, setTabValue] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const supplierOptions = suppliers.sort((a, b) => a.name.localeCompare(b.name)).map((el) => ({
        key: el.objectId,
        value: el.name,
        label: el.name
    }))

    const onTabChange = (_, tabValue) => {
        setTabValue(tabValue)
    }

    const onClose = () => {
        dispatch(showOrderFilter())
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const onCreateOrder = useCallback(async (supplierId) => {
        dispatch(createOrderFromReception(site, date, supplierId))

        //after creating new order from reception we update orders list
        const objectSite = await getSiteById(site.objectId, ["*"], false)
        const orders = await getOrders(objectSite, date)
        ordersTodo = orders.filter(order => order.receptionStatus === "TODO")
        ordersInProgress= orders.filter(order => order.receptionStatus === "IN_PROGRESS")
        ordersDone = orders.filter(order => order.receptionStatus === "DONE")
    }, [])

    const handleOrderClick = useCallback((id) => {
        dispatch(showSingleOrder(site.objectId, date, id))
    }, [])

    const renderHeaderInfo = () => {
        return <GenericHeaderInfo info={generateReceptionLabel(site && site.name ? site.name : "", date)} />
    }


    const typeOptions = Object.keys(supplierItemTypes)
        .map(key => ({
            key: supplierItemTypes[key].value,
            value: supplierItemTypes[key].label,
            label: supplierItemTypes[key].label
        }))

    const deliveryDelayOptions = [
        {
            key: "inAdvance",
            label: "En avance",
            value: "En avance"
        },
        {
            key: "onTime",
            label: "À jour",
            value: "À jour"
        },
        {
            key: "late",
            value: "En retard",
            label: "En retard"
        }
    ]

    return (
        <FullScreenWrapper>
            <div className={classes.container}>
                <div className={classes.headerBloc}>
                    <GenericTopBarHeader
                        leftAction={<GenericHeaderReturnButton handleClick={onClose} />}
                        title={<OrderReceptionHeaderTitle />}
                        info={renderHeaderInfo()}
                    />
                </div>
                <div>
                    <Tabs
                        value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                        onChange={onTabChange}
                        className={classes.tabContainer}
                        indicatorColor="primary"
                        centered
                    >
                        <Tab label={`Commandes à réceptionner (${ordersTodo.length})`} />
                        <Tab label={`Réceptions en cours (${ordersInProgress.length})`} />
                        <Tab label={`Réceptions clôturées (${ordersDone.length})`} />
                    </Tabs>
                    <div/>
                    {
                        tabValue === 0 &&
                        <OrderReceptionToDo
                            orders={ordersTodo}
                            onOrderClick={id => handleOrderClick(id)}
                            supplierOptions={supplierOptions}
                            typeOptions={typeOptions}
                            deliveryDelayOptions={deliveryDelayOptions}
                            onAdd={() => setOpenModal(true)}
                        />
                    }
                    {
                        tabValue === 1 &&
                        <OrderReceptionReceived
                            orders={ordersInProgress}
                            onOrderClick={(id) => handleOrderClick(id)}
                            supplierOptions={supplierOptions}
                            typeOptions={typeOptions}
                            deliveryDelayOptions={deliveryDelayOptions}
                        />
                    }
                    {
                        tabValue === 2 &&
                        <OrderReceptionReceived
                            orders={ordersDone}
                            onOrderClick={(id) => handleOrderClick(id)}
                            supplierOptions={supplierOptions}
                            typeOptions={typeOptions}
                            deliveryDelayOptions={deliveryDelayOptions}
                        />
                    }
                </div>
            </div>
            {
                openModal
                    ? <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="form-dialog-title"
                        className={classes.modal}
                    >
                        <CreateOrderReceptionModal
                            suppliers={suppliers}
                            onCreateOrder={onCreateOrder}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                    : <div></div>
            }
        </FullScreenWrapper>
    )
}

export default OrderReceptionTabs