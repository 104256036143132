import React from "react"
import {
	useSelector,
	useDispatch
} from "react-redux"
import Divider from "@mui/material/Divider"
import List from "@mui/material/List"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import TSIcon from "@mui/icons-material/Assignment"
import UserIcon from "@mui/icons-material/AccountCircle"
import LogoutIcon from "@mui/icons-material/PowerSettingsNew"
import IngredientIcon from "@mui/icons-material/Restaurant"
import DispatchIcon from "@mui/icons-material/LocalShipping"
import SettingsIcon from "@mui/icons-material/Settings"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import EuroIcon from "@mui/icons-material/Euro"
import LockIcon from "@mui/icons-material/Lock"
import ListItemIcon from "@mui/icons-material/List"
import DashboardIcon from "@mui/icons-material/Dashboard"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import WidgetsIcon from "@mui/icons-material/Widgets"
import MenuIconAndText from "../components/MenuIconAndText"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import ViewModuleICon from "@mui/icons-material/ViewModule"
import BuildIcon from "@mui/icons-material/Build"
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory"
import SupplierIcon from "@mui/icons-material/PeopleOutline"
import RepeatIcon from "@mui/icons-material/Repeat"
import DateRangeIcon from "@mui/icons-material/DateRange"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import { ListSubheader, styled } from "@mui/material"

import { logout } from "../actions/Utils/app"
import { showDispatchInterface } from "../actions/Dispatch/dispatch"
import { showReceptionWarehouseFilter } from "../actions/ReceptionWarehouse/receptionWarehouse"
import {
	showReceptionInterface,
	showInventoryInterface
} from "../actions/Reception/reception"
import { showLotGroupFilter } from "../actions/LotGroup/lotGroup"
import { showReceptionGapInterface } from "../actions/Reception/receptionGap"
import {
	showPlanningSell,
	showPlanningPrev,
	showPlanningProduction,
} from "../actions/Planning/Planning"
import { showRecipeList } from "../actions/Recipe/recipes"
import { showRightsRoles } from "../actions/Rights/Rights"
import { showInternalCommercialNames } from "../actions/Ingredients/Internal/CommercialsNames"
import { showProductionStepsLogin } from "../actions/ProductionSteps/ProductionStepExecutions"
import {
	rightDispatch,
	rightReceptionWarehouse,
	rightReadPlanningPrev,
	rightReadPlanningSell,
	rightReadProductStock,
	rightReadProductPackaging,
	rightReadEventProductStockGap,
	rightReadRights,
	CAN_READ_SUPPLIERS,
	CAN_READ_SUPPLIER_ITEMS,
	rightReadOrders,
	rightReadOrderReceptions,
	rightReadInventory,
	rightReadInventoryOutput,
	rightReadSecondaryDLC,
	rightReadPlanningProd,
	rightReadPlanningDashboard,
	rightReadOrderBillings,
	canSeeMisc,
	CAN_ACCESS_SETTINGS_ADMINISTRATION,
	canReadProductionSteps,
	canReadPackagingExecutions, canReadProductionReception,
	canReadProductionSubstitutions
} from "../utils/accessRights"
import { checkIfHaveRight } from "../actions/Utils/utils"
import { COLORS, KFC_BRANDS } from "../utils"
import { showSuppliersList } from "../actions/Supplier/suppliers"
import { showOrderFilter } from "../actions/OrderReception/OrderReception"
import { showLotFilter } from "../actions/LotInventory/LotInventory"
import { showOrdersSiteSelector } from "../actions/Orders/Orders"
import { LOT_INVENTORY_MODE, LOT_OUTPUT_MODE } from "../utils/lotInventoryUtils"
import { showBillingAndCreditNotes } from "../actions/BillingCreditNotes/BillingCreditNotes"
import { showKo, showMonitoring } from "../actions/Planning/PlanningDashboard"
import { showMisc } from "../actions/Misc/Misc"
import { showFamiliesClassificationsIngredients } from "../actions/Ingredients/Classifications/Families/Families"
import { showAdministrationProductTypes } from "../actions/ProductTypes/productTypes"
import { packagingExecutionBaseUrl, showPackagingExecutionFilter } from "../actions/PackagingExecution/packagingExecution"
import { showSecondaryDLCFilter } from "../actions/SecondaryDLC/secondaryDLC"
import { showProductionReceptionFilter } from "../actions/ProductionReception/ProductionReception"
import { showProductionSubstitutions } from "../actions/ProductionSubstitutions/ProductionSubstitutions"
import { updateTab } from "../actions/Utils/app"

const StyledListTitle = styled(ListSubheader)({
	margin: "0 15px 5px 0",
	fontSize: 15,
	color: COLORS.DARK_GREY
})

const Sidebar = (props) => {
	const {
		isVisible,
		open
	} = props

	const dispatch = useDispatch()

	const user = useSelector(state => state.app.user)
	const rights = useSelector(state => state.app.rights)
	const pathname = useSelector(state => state.routing.locationBeforeTransitions.pathname)
	const canReadSuppliers = useSelector(state => state.app.rights[CAN_READ_SUPPLIERS])
	const canReadSupplierItems = useSelector(state => state.app.rights[CAN_READ_SUPPLIER_ITEMS])
	const canAccessAdministrationTabs = useSelector(state => state.app.rights[CAN_ACCESS_SETTINGS_ADMINISTRATION])

	const showUrl = (domain) => {
		return (
			process.env.REACT_APP_ENV === domain ||
				process.env.REACT_APP_ENV === "DEV" ||
				process.env.REACT_APP_ENV === "PREPROD"
				? false
				: true
		)
	}

	const getUsernameLabel = (username) => {
		const at = username.indexOf("@")
		return username.slice(0, at)
	}
	const menus = [
		{
			title: "Catalogue",
			items: [
				{
					label: "Produits",
					url: showUrl("PROD_PRODUCT") ? "https://catalogue.foodcheri.com/products/recipes" : null,
					icon: TSIcon,
					onClick: () => dispatch(showRecipeList()),
					path: "/products"
				},
				{
					label: "Ingrédients",
					url: showUrl("PROD_PRODUCT") ? "https://catalogue.foodcheri.com/ingredients/internals" : null,
					icon: IngredientIcon,
					onClick: () => dispatch(showInternalCommercialNames()),
					path: "/ingredients"
				},
				...((canReadSuppliers || canReadSupplierItems) ? [
					{
						label: "Fournisseurs & Articles",
						url: showUrl("PROD_PRODUCT") ? "https://catalogue.foodcheri.com/suppliers/list" : null,
						icon: SupplierIcon,
						onClick: () => dispatch(showSuppliersList()),
						path: "/suppliers"
					}] : [])
			]
		},
		{
			title: "Planification",
			items: [
				{
					icon: ShoppingBasketIcon,
					label: "Carte",
					onClick: () => dispatch(showPlanningSell()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/planning/sell" : null,
					rightsAccess: rightReadPlanningSell,
					path: "/planning/sell"
				},
				{
					icon: ListItemIcon,
					label: "Prévisions",
					onClick: () => dispatch(showPlanningPrev()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/planning/prev" : null,
					rightsAccess: rightReadPlanningPrev,
					path: "/planning/prev"
				},
				{
					icon: CalendarMonthIcon,
					label: "Plannings",
					onClick: () => dispatch(showPlanningProduction()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/planning/production" : null,
					rightsAccess: rightReadPlanningProd,
					path: "/planning/production"
				},
				{
					icon: HowToRegIcon,
					label: "Supervision",
					onClick: () => dispatch(showMonitoring()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/planning/dashboard/monitoring" : null,
					rightsAccess: rightReadPlanningDashboard,
					path: "/planning/dashboard/monitoring"
				},
			]
		},
		{
			title: "Approvisionnements",
			items: [
				{
					icon: StoreMallDirectoryIcon,
					label: "Commandes",
					onClick: () => dispatch(showOrdersSiteSelector()),
					rightsAccess: rightReadOrders,
					path: "/orders"
				},
				{
					icon: EuroIcon,
					label: "Facturation & Avoirs",
					onClick: () => dispatch(showBillingAndCreditNotes()),
					rightsAccess: rightReadOrderBillings,
					path: "/billingCreditNotes"
				},
				{
					icon: MeetingRoomIcon,
					label: "Réception",
					onClick: () => dispatch(showOrderFilter()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/orderReceptions" : null,
					rightsAccess: rightReadOrderReceptions,
					path: "/orderReceptions"
				},
			]
		},
		{
			title: "Stockage",
			items: [
				{
					icon: WidgetsIcon,
					label: "Inventaire",
					onClick: () => dispatch(showLotFilter(LOT_INVENTORY_MODE)),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/lotInventory" : null,
					rightsAccess: rightReadInventory,
					path: "/lotInventory"
				},
				{
					icon: DashboardIcon,
					label: "Sorties",
					onClick: () => dispatch(showLotFilter(LOT_OUTPUT_MODE)),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/lotOutput" : null,
					rightsAccess: rightReadInventoryOutput,
					path: "/lotOutput"
				},
				{
					icon: DateRangeIcon,
					label: "DLC secondaires",
					onClick: () => dispatch(showSecondaryDLCFilter()),
					url: showUrl("PROD_KITCHEN") ? "https://cuisine.foodcheri.com/SecondaryDLC" : null,
					rightsAccess: rightReadSecondaryDLC,
					path: "/SecondaryDLC"
				}
			]
		},
		{
			title: "Production",
			items: [
				{
					icon: AutorenewIcon,
					label: "Remplacement d'articles",
					onClick: () =>  dispatch(showProductionSubstitutions()),
					rightsAccess: canReadProductionSubstitutions,
					path: "/productionSubstitutions"
				},
				{
					icon: MeetingRoomIcon,
					label: "Réception",
					onClick: () => dispatch(showProductionReceptionFilter()),
					rightsAccess: canReadProductionReception,
					path: "/productionReception"
				},
				{
					icon: RepeatIcon,
					label: "Étapes de production",
					onClick: () => dispatch(showProductionStepsLogin()),
					rightsAccess: canReadProductionSteps,
					path: "/productionSteps"
				},
				{
					icon: ShoppingBasketIcon,
					label: "Barquettage",
					onClick: () => dispatch(showPackagingExecutionFilter()),
					rightsAccess: canReadPackagingExecutions,
					path: packagingExecutionBaseUrl
				}
			]
		},
		{
			title: "Entrepôt",
			items: [
				{
					icon: MeetingRoomIcon,
					label: "Réception",
					onClick: () => dispatch(showReceptionWarehouseFilter()),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/receptionEntrepotFilter" : null,
					rightsAccess: rightReceptionWarehouse,
					path: "/receptionEntrepotFilter"
				},
				{
					icon: ViewModuleICon,
					label: "Allotissement",
					onClick: () => dispatch(showLotGroupFilter()),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/lotGroupFilter" : null,
					rightsAccess: rightReadProductPackaging,
					path: "/lotGroupFilter"
				},
				{
					icon: DispatchIcon,
					label: "Dispatch",
					onClick: () => { dispatch(showDispatchInterface()) },
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/dispatch" : null,
					rightsAccess: rightDispatch,
					path: "/dispatch"
				},
				{
					icon: HowToRegIcon,
					label: "Supervision",
					onClick: () => dispatch(showKo(KFC_BRANDS[0].name)),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/planning/dashboard/ko/" + KFC_BRANDS[0].name : null,
					rightsAccess: rightReadEventProductStockGap,
					path: "/planning/dashboard/ko"
				}
			]
		},
		{
			title: "Hubs",
			items: [
				{
					icon: MeetingRoomIcon,
					label: "Réception",
					onClick: () => dispatch(showReceptionInterface()),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/receptionFilter" : null,
					rightsAccess: rightReadProductStock,
					path: "/receptionFilter"
				},
				{
					icon: WidgetsIcon,
					label: "Inventaire",
					onClick: () => dispatch(showInventoryInterface()),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/inventoryFilter" : null,
					rightsAccess: rightReadProductStock,
					path: "/inventoryFilter"
				},
				{
					icon: HowToRegIcon,
					label: "Supervision",
					onClick: () => dispatch(showReceptionGapInterface()),
					url: showUrl("PROD_LOGISTIC") ? "https://logistique.foodcheri.com/receptionGap" : null,
					rightsAccess: rightReadEventProductStockGap,
					path: "/receptionGap"
				}
			]
		},
		{
			title: "Paramètres",
			items: [
				{
					icon: SettingsIcon,
					label: "Réglages ",
					onClick: () => canAccessAdministrationTabs ? dispatch(showAdministrationProductTypes()) : dispatch(showFamiliesClassificationsIngredients()),
					url: null,
					// rightsAccess: rightReadRights,
					path: "/settings"
				},
				{
					icon: LockIcon,
					label: "Droits",
					onClick: () => dispatch(showRightsRoles()),
					url: null,
					rightsAccess: rightReadRights,
					path: "/rights"
				},
				{
					icon: BuildIcon,
					label: "Divers",
					onClick: () => dispatch(showMisc()),
					url: null,
					rightsAccess: canSeeMisc,
					path: "/misc"
				},
				{
					label: (user && getUsernameLabel(user.get("username"))) || "No User",
					icon: UserIcon,
				},
				{
					label: "Déconnexion",
					upper: true,
					icon: LogoutIcon,
					onClick: () => dispatch(logout())
				}
			]
		}
	]

	if (!isVisible || !user) return

	return (
		<div style={{ paddingBottom: open && 50 }}>
			{menus.map((menu, index) => menu.items.some((menuItem) => checkIfHaveRight(menuItem.rightsAccess, rights))
				? (
					<List
						key={index}
						component="nav"
						aria-labelledby="nested-list-subheader"
						subheader={menu.title && (
							<StyledListTitle component="div" id="nested-list-subheader">
								{!open ? menu.title.substring(0, 3) + "..." : menu.title}
							</StyledListTitle>
						)}
					>
						{menu.items.map((menuItem, subIndex) => checkIfHaveRight(menuItem.rightsAccess, rights)
							? (
								<MenuIconAndText
									key={index + "-" + subIndex}
									label={menuItem.label}
									url={menuItem.url}
									icon={menuItem.icon}
									onClick={() => {
										menuItem.onClick()
										dispatch(updateTab())
								}}
									upper={menuItem.upper}
									selected={pathname.startsWith(menuItem.path)}
								/>
							) : null)}
						<Divider sx={{ mt: 1 }} />
					</List>
				) : [])}
		</div>
	)
}

export default Sidebar
