import React from "react"

import PriorSteps from "./PriorSteps"

const LoopPriorSteps = ({
  stepComponent,
  isEdition,
  onRowBlur,
  onRowHover,
  index,
  sectionIndex,
  indexComponent,
  hasError,
  step,
  transformationModes,
  isStepComponentHover,
  setFieldValue,
  steps,
  section,
  recipe,
  fromRecipe,
  stepComponentsIndexesList,
  isReusable
}) => {
  return (
      stepComponent.priorSteps.stepComponents && stepComponent.priorSteps.stepComponents.map((subStepComponent, indexSubComponent) => (
        !subStepComponent.supplierItem && subStepComponent.priorSteps?.stepComponents.length > 0
        ? (
            <LoopPriorSteps
              stepComponent={subStepComponent}
              isEdition={isEdition}
              onRowBlur={onRowBlur}
              onRowHover={onRowHover}
              index={index}
              sectionIndex={sectionIndex}
              indexComponent={indexSubComponent}
              hasError={hasError}
              step={step}
              transformationModes={transformationModes}
              isStepComponentHover={isStepComponentHover}
              setFieldValue={setFieldValue}
              key={indexSubComponent+"priorSteps"}
              steps={steps}
              section={section}
              recipe={recipe}
              fromRecipe={fromRecipe}
              stepComponentsIndexesList={stepComponentsIndexesList.concat(indexSubComponent)}
              isReusable={isReusable}
            />
            )
         : (
            <PriorSteps
              isEdition={isEdition}
              onRowBlur={onRowBlur}
              onRowHover={onRowHover}
              index={index}
              sectionIndex={sectionIndex}
              indexComponent={indexComponent}
              hasError={hasError}
              step={step}
              transformationModes={transformationModes}
              isStepComponentHover={isStepComponentHover}
              setFieldValue={setFieldValue}
              indexSubComponent={indexSubComponent}
              subStepComponent={subStepComponent}
              key={indexSubComponent+"priorSteps"}
              steps={steps}
              section={section}
              recipe={recipe}
              fromRecipe={fromRecipe}
              stepComponentsIndexesList={stepComponentsIndexesList.concat(indexSubComponent)}
              isReusable={isReusable}
            />
         )
    ))
  )
}

export default LoopPriorSteps
