import Parse from "parse"
import {findProductStock} from "../parseManager/reception/parseProductStockManager"

const Hubs = Parse.Object.extend("DistributionCenters")

export const checkProductStock = async (selectedProduct, hubId, dlc) => {
    const hubPointer = Hubs.createWithoutData(hubId)

    return await findProductStock(hubPointer, dlc, selectedProduct.data.objectId)
}

export const updateProductsStockReceptionStatus = async (productsStock, newStatus) => {
    for (const productStock of productsStock) {
        productStock.set("receptionStatus", newStatus)
        await productStock.save()
    }
}