import React, { memo } from "react"
import { isEqual } from "lodash"

import { CalendarHeaderInfo } from "./CalendarHeaderInfo"

let CalendarSectionHeader = ({ sectionData, section }) => {
    if (sectionData) {
        return (
            <CalendarHeaderInfo
                internalProduct={sectionData.intern}
                externalProduct={sectionData.extern}
                tags={sectionData.tags}
                expectedSales={[sectionData]}
                sectionKey={section.key}
            />
        )
    }


    return (
        <CalendarHeaderInfo
            internalProduct={0}
            externalProduct={0}
        />
    )

}

CalendarSectionHeader = memo(CalendarSectionHeader, isEqual)

export default CalendarSectionHeader
