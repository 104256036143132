import React from "react"
import { connect } from "react-redux"
import { FieldArray, reduxForm, reset } from "redux-form"
import Button from "@mui/material/Button"
import withStyles from "@mui/styles/withStyles"
import _isEqual from "lodash/isEqual"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import clsx from "clsx"
import DistributionCenterPriorityCard from "../../../OldSettings/Tabs/PlanningSettingsDispatchPriority/DistributionCenterPriorityCard"
import DistributionCenterPriorityListTitle from "../../../OldSettings/Tabs/PlanningSettingsDispatchPriority/DistributionCenterPriorityListTitle"
import PlanningBrandTabs from "../../../../components/Planning/PlanningBrandTabs"
import { COLORS } from "../../../../utils"
import { distributionCenterPriorityFormValidator } from "../../../../utils/validators"
import { distributionCentersPriorityInitialValues } from "../../../../actions/Utils/utils"
import {
    planningSettingsDispatchPriorityChangeBrand,
    updateDistributionCentersDispatchPriority
} from "../../../../actions/Planning/Settings/PlanningSettingsDispatchPriority"
import SitesAndHubsTabs from "../SitesAndHubsTabs"

const styles = {
    container: {
        width: "100%",
        position: "relative",
        top: 180
    },
    buttonContainer: {
        margin: 10,
        textAlign: "right"
    },
    button: {
        backgroundColor: COLORS.PRIMARY_COLOR
    }
}

export class SettingsDispatchPriorityTab extends React.Component {

    shouldComponentUpdate(nextProps) {
        const { menuIsOpen, distributionCenters, brand} = this.props

        if (menuIsOpen !== nextProps.menuIsOpen ||
            brand !== nextProps.brand ||
            !_isEqual(distributionCenters, nextProps.distributionCenters)) {
            return true
        }
        else {
            return false
        }
    }

    onBrandChange = (event, value) => {
        const { planningSettingsDispatchPriorityChangeBrand } = this.props

        planningSettingsDispatchPriorityChangeBrand(value)
        reset("dispatchPriority")
    }

    _onDragEnd = (event, fields) => {
        if (event && event.source && event.destination &&
            event.destination.hasOwnProperty("index") &&
            event.source.hasOwnProperty("index")) {
            const source = event.source
            const destination = event.destination
            fields.move(source.index, destination.index)
        }
    }

    renderDistributionCenters = (props) => {
        const fields = props.fields

        return (
            <DragDropContext onDragEnd={(event) => this._onDragEnd(event, fields)}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{height: 72 * fields.length}}
                        >
                            {
                                fields.map((distributionCenter, index) => (
                                        <Draggable
                                            key={index}
                                            draggableId={fields.get(index).objectId}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <DistributionCenterPriorityCard
                                                    name={fields.get(index).name}
                                                    index={index}
                                                    provided={provided}
                                                />
                                            )}
                                        </Draggable>
                                    )
                                )
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    render() {
        const {
            classes, menuIsOpen, handleSubmit, brand,
            updateDistributionCentersDispatchPriority,
        } = this.props

        return (
            <SitesAndHubsTabs tabValue="dispatchPriority">
                <PlanningBrandTabs
                    tabValue={brand}
                    onTabChange={this.onBrandChange}
                    isSub={false}
                />
                <form onSubmit={handleSubmit(updateDistributionCentersDispatchPriority)}>
                    <div className={clsx(classes.container, "flexColumn flexEnd")}>
                        <DistributionCenterPriorityListTitle menuIsOpen={menuIsOpen} />
                            <FieldArray
                                name="distributionCenters"
                                component={(props) => this.renderDistributionCenters(props)}
                            />
                        <div className={classes.buttonContainer}>
                            <Button type="submit" color="primary" variant="contained">
                                Enregistrer
                            </Button>
                        </div>
                    </div>
                </form>
            </SitesAndHubsTabs>
        )
    }
}

const DispatchPriorityForm = reduxForm({
    form: "dispatchPriority",
    enableReinitialize: true,
    shouldValidate: () => {return true},
    validate: distributionCenterPriorityFormValidator
})(SettingsDispatchPriorityTab)

const SettingsDispatchPriorityTabWithStyles = withStyles(styles)(DispatchPriorityForm)

export default connect((state) => {
    const dispatchPriorityFormValues = state.form.dispatchPriority ? state.form.dispatchPriority.values : undefined
    const brand = state.planningSettings.brand

    return {
        distributionCenters: state.planningSettings.distributionCenters,
        initialValues: distributionCentersPriorityInitialValues(state.planningSettings.distributionCenters, brand),
        dispatchPriorityFormValues: dispatchPriorityFormValues,
        dispatchPriority: state.planningSettings.distributionCenters,
        menuIsOpen: state.app.menuIsOpen,
        brand: brand
    }
}, {
    planningSettingsDispatchPriorityChangeBrand,
    updateDistributionCentersDispatchPriority
})(SettingsDispatchPriorityTabWithStyles)