import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SecondaryDLCMenuFilters from "./SecondaryDLCMenuFilters"
import GroupIngredientsSection from "../../components/common/GroupIngredientsSection"
import { DLC_FILTER_TYPES, filterSecondaryDLCsByDlc, getLotsGroupName } from "../../utils/lotInventoryUtils"
import { getSecondaryDLCDateSelector, getSecondaryDLCGroupIngredientsSelector, getSecondaryDLCProductTypesOptionsSelector, getSecondaryDLCWarningDateSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { filterLotsBySearchValue } from "../../utils/lotInventoryUtils"
import { cloneDeep } from "lodash"

const SecondaryDLCMenu = ({ secondaryDLCsBySections, selectedSecondaryDLC, onSelect }) => {

  const currentDate = useSelector(getSecondaryDLCDateSelector)
  const warningDate = useSelector(getSecondaryDLCWarningDateSelector)
  const productTypesOptions = useSelector(getSecondaryDLCProductTypesOptionsSelector)
  const groupIngredients = useSelector(getSecondaryDLCGroupIngredientsSelector)
  const [secondaryDLCsByGroupIngredients, setSecondaryDLCByGroupIngredients] = useState(secondaryDLCsBySections)

  const [searchValue, setSearchValue] = useState("")
  const [selectedDlcTypes, setSelectedDlcTypes] = useState(["ALL", ...DLC_FILTER_TYPES.map(el => el.key)])

  useEffect(() => {
    const clonedSecondaryDLCsByGroupIngredients = cloneDeep(secondaryDLCsBySections)
    const filteredSecondaryDLCsByGroupIngredients = filterLotsBySearchValue(clonedSecondaryDLCsByGroupIngredients, searchValue)
    setSecondaryDLCByGroupIngredients(filteredSecondaryDLCsByGroupIngredients)
  }, [searchValue, secondaryDLCsBySections])


  useEffect(() => {
    const clonedSecondaryDLCsByGroupIngredients = cloneDeep(secondaryDLCsBySections)
    const filteredSecondaryDLCsByGroupIngredients = filterSecondaryDLCsByDlc(clonedSecondaryDLCsByGroupIngredients, selectedDlcTypes, currentDate, warningDate)
    setSecondaryDLCByGroupIngredients(filteredSecondaryDLCsByGroupIngredients)
  }, [selectedDlcTypes, secondaryDLCsBySections])

  const handleDlcTypeChange = (value) => {
    if (selectedDlcTypes.includes("ALL") && value.includes("ALL") && selectedDlcTypes.length !== value.length) {
        value.shift()
    }
    else if (selectedDlcTypes.includes("ALL") && !value.includes("ALL")) {
        value = []
    }
    else if (
        (!value.includes("ALL") && value.length === DLC_FILTER_TYPES.length) ||
        (!selectedDlcTypes.includes("ALL") && value.includes("ALL"))
    ) {
        value = ["ALL", ...DLC_FILTER_TYPES.map(el => el.key)]
    }
    setSelectedDlcTypes(value)
  }

  return (
    <div className="width100">
      <SecondaryDLCMenuFilters
        date={currentDate}
        searchValue={searchValue}
        onChangeSearchValue={(value) => setSearchValue(value)}
        onChangeDlcTypes={handleDlcTypeChange}
        selectedDlcTypes={selectedDlcTypes}
         />
      <div>
        {
          Array.from(secondaryDLCsByGroupIngredients).map(([key, characters]) =>
            <GroupIngredientsSection
              collection="SecondaryDLC"
              mode="secondaryDLC"
              key={key}
              group={getLotsGroupName(key, groupIngredients, productTypesOptions)}
              characters={characters}
              onSelect={onSelect}
              selectedItem={selectedSecondaryDLC}
              currentDate={currentDate}
              dlcWarningDate={warningDate}
            />
          )
        }
      </div>
    </div>
  )

}

export default SecondaryDLCMenu