import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import { MeetingRoom } from "@mui/icons-material"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: COLORS.RED_RASPBERRY
    },
    icon: {
        marginRight: 10,
        color: COLORS.RED_RASPBERRY,
        height: 24
    }
})

const OrderReceptionHeaderTitle = () => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Icon className={classes.icon}>
                <MeetingRoom />
            </Icon>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                Réceptions
            </Typography>
        </div>
    )
}

export default OrderReceptionHeaderTitle