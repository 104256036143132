import React from "react"
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        }
    }
}

export const ChefSelect = (props) => {
    const {value, chefs, classes, onChange, ...otherProps } = props

    return (
        <FormControl className={classes.formControl} variant="standard">
            <InputLabel>Chef</InputLabel>
            <Select
                name="chef"
                id="chef"
                fullWidth
                value={value}
                MenuProps={MenuProps}
                onChange={onChange}
                {...otherProps}
            >
                <MenuItem value="">
                    Aucun Chef
                </MenuItem>

                {
                    chefs.map((chef, index) =>
                        <MenuItem value={chef.objectId} key={index}>
                            {chef.firstName} {chef.lastName}
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}
