import React, {useState, useRef, useLayoutEffect} from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Parse from "parse"
import clsx from "clsx"
import { Modal } from "@mui/material"

import ReceptionWarehouseToDo from "./ReceptionWarehouseToDo"
import ReceptionWarehouseDone from "./ReceptionWarehouseDone"
import { COLORS } from "../../utils"
import ReceptionWarehouseInProgress from "./ReceptionWarehouseInProgress"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ReceptionWarehouseHeaderTitle from "./ReceptionWarehouseHeaderTitle"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import { showReceptionWarehouseFilter } from "../../actions/ReceptionWarehouse/receptionWarehouse"

const useClasses = makeStyles({
    tab: {
        composes: "flex1",
        outline: "none"
    },
    receptionWarehouseModal: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll"
    },
    username: {
        color: COLORS.DARK_GREY
    },
    rightInfosBloc: {
        justifyContent: "flex-end",
        marginLeft: "auto",
        marginRight: 0,
        marginTop: 5
    },
    receptionWarehouseModalContainer: {
        outline: "none"
    },
})

const ReceptionWarehouse = ()  => {
    const receptionWarehouseDataToDo = useSelector(state => state.receptionWarehouse.data.filter(product => product.status === "TODO" && product.packagingType === state.receptionWarehouse.packaging) || [])
    const receptionWarehouseDataInProgress = useSelector(state => state.receptionWarehouse.data.filter(product => product.status === "IN_PROGRESS" && product.packagingType === state.receptionWarehouse.packaging) || [])
    const receptionWarehouseDataDone = useSelector(state => state.receptionWarehouse.data.filter(product => product.status === "DONE" && product.packagingType === state.receptionWarehouse.packaging) || []) 

    const classes = useClasses()
    const [tabValue, setTabValue] = useState(0)
    const user =  Parse.User.current()
    const modalRef = useRef()
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        document.title = "KFC - Réception"

        return () => (document.title = "KFC")
    }, [])

    const onBack = () => dispatch(showReceptionWarehouseFilter())

    const _onTabChange = (event, tabValue) => {
        setTabValue(tabValue)
    }

    return (
        <Modal
            key="reception-warehouse-modal"
            BackdropProps={{ invisible: true }}
            className={classes.receptionWarehouseModal}
            open
            ref={modalRef}
        >
            <div className={classes.receptionWarehouseModalContainer}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                    title={<ReceptionWarehouseHeaderTitle />}
                    className="stretchSelf"
                    rightAction={<span className={classes.username}>Picker : {user.get("username")}</span>}
                />
                <div className={clsx(classes.tab, "flexCenter")}>
                    <Tabs
                        value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                        onChange={_onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label={`À FAIRE (${receptionWarehouseDataToDo.length})`}/>
                        <Tab label={`EN COURS (${receptionWarehouseDataInProgress.length})`}/>
                        <Tab label={`FAIT (${receptionWarehouseDataDone.length})`}/>
                    </Tabs>
                    <div className={classes.divider}/>
                    {
                        tabValue === 0 &&
                        <ReceptionWarehouseToDo
                            modalRef={modalRef}
                            receptionWarehouseDataToDo={receptionWarehouseDataToDo}
                        />
                    }
                    {
                        tabValue === 1 &&
                        <ReceptionWarehouseInProgress
                            modalRef={modalRef}
                            receptionWarehouseDataInProgress={receptionWarehouseDataInProgress}
                        />
                    }
                    {
                        tabValue === 2 &&
                        <ReceptionWarehouseDone
                            modalRef={modalRef}
                            receptionWarehouseDataDone={receptionWarehouseDataDone}
                        />
                    }
                </div>
            <HelpButton link={helpLinks.warehouse} />
            </div>
        </Modal>
    )
}

export default ReceptionWarehouse
