import React from "react"
import withStyles from "@mui/styles/withStyles"
import { Field } from "redux-form"
import { fieldLabels } from "../../../../utils/dispatchPriority"
import ReduxTextField from "../../../../components/form/ReduxTextField"

const styles = {
    container: {
        width: "100%",
        border: "1px solid rgba(0,0,0, 0.3)",
        display: "inline-flex",
        padding: "5px 10px",
        height: 72,
        alignItems: "center"
    },
    nameContainer: {
        fontWeight: 500,
        fontSize: 20,
        width: "16%",
        textAlign: "center"
    },
    formContainer: {
        display: "inline-flex",
        flexWrap: "wrap"
    },
    fieldContainer: {
        width: "12%",
    },
    inputContainer: {
        display: "flex",
        marginLeft: "20%",
        marginRight: "20%"
    },
    priorityContainer: {
        width: "4%",
        textAlign: "center"
    },
    input: {
        textAlign: "right"
    }
}

export const DistributionCenterPriorityCard = ({classes, provided, index, name}) => {
    return (
        <div
            className={classes.container}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <div className={classes.priorityContainer}>
                {index + 1}
            </div>

            <div className={classes.nameContainer}>
                {name}
            </div>
            {
                fieldLabels.filter(elem => elem.type === "number").map((elem, elemIdx) => {
                    return (
                        <div className={classes.fieldContainer} key={elemIdx}>
                            <Field
                                type="number"
                                name={`distributionCenters[${index}].dispatch[${elem.field}]`}
                                className={classes.inputContainer}
                                component={ReduxTextField}
                                InputProps={{
                                    inputProps: {
                                        className: classes.input
                                    }
                                }}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

const DistributionCenterPriorityCardWithStyles = withStyles(styles)(DistributionCenterPriorityCard)

export default DistributionCenterPriorityCardWithStyles
