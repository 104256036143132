import { LOT_INVENTORY_MODE, UNKNOWN_LOT_GROUP_ID } from "../../utils/lotInventoryUtils"
import _cloneDeep from "lodash/cloneDeep"
import { supplierItemTypes } from "../../utils/supplierItemUtils"
import { getData } from "../index"

const defaultState = {
	sites: [],
	mode: LOT_INVENTORY_MODE,
	site: null,
	date: null,
	stockZone: null,
	groupIngredients: [],
	lots: null,
	lotsEvents: [],
	filters: null,
	unsortedLots: [],
	suppliers: [],
	supplierItems: [],
	inventoryOutputSnackBar: { open: false, type: "", message: "", duration: 0 },
	lotDamageReportSnackBar: { open: false, type: "", message: "", duration: 0 },
	substituteIds: new Set(),
	selectedLot: null,
	tabValue: 0,
	isSwitchOutputProduction: false,
}

export default function (state = defaultState, action) {
	switch (action.type) {
		case "SELECTED_LOT_LOADED": {
			return {
				...state,
				selectedLot: action.selectedLot
			}
		}
		case "LOT_FILTER_LOADED": {
			return {
				...state,
				sites: action.sites,
				mode: action.mode,
				outputDateSelected: action.outputDateSelected,
				outputTypeSelected: action.outputTypeSelected,
				siteSelected: action.siteSelected,
				stockZoneSelected: action.stockZoneSelected,
				tabValue: action.tabValue
			}
		}
		case "DISPATCH_LOT_TAB_CHANGED":
			return {
				...state,
				tabValue: action.tabValue
			}
		case "LOT_MAIN_LOADED": {
			return {
				...state,
				site: action.site,
				date: action.date,
				mode: action.mode,
				currentDate: action.currentDate,
				warningDate: action.warningDate,
				stockZone: action.stockZone,
				groupIngredients: action.groupIngredients,
				lots: action.lots,
				unsortedLots: action.unsortedLots,
				outputInventoryData: action.outputInventoryData,
				substituteIds: action.substituteIds,
				substituteLots: action.substituteLots,
				supplierItemsHypotheticalTreated: action.supplierItemsHypotheticalTreated,
				isSwitchOutputProduction: action.isSwitchOutputProduction,
			}
		}
		case "LOT_DAMAGE_REPORT_LOADED": {
			return {
				...state,
				selectedLot: action.selectedLot,
				stockZone: action.stockZone,
				site: action.site
			}
		}
		case "LOT_INVENTORY_ADD_LOT": {
			return {
				...state,
				lots: action.lots
			}
		}
		case "LOT_SITE_AND_DATE_LOADED": {
			return {
				...state,
				site: action.site,
				date: action.date,
				stockZone: action.stockZone,
			}
		}
		case "LOTS_OUTPUT_EVENTS_LOADED": {
			return {
				...state,
				lotsEvents: action.lotsEvents,
				lots: action.lots,
			}
		}
		case "LOTS_OUTPUT_FILTERS":
			return {
				...state,
				filters: {
					...state.filters,
					...action.filters
				}
			}
		case "LOT_INVENTORY_SELECTION_UPDATED": {
			// --------------------------------------------------------------- //
			// ----------------- RAW MATERIAL SUPPLIER ITEMS ----------------- //
			// --------------------------------------------------------------- //
			if (action.selectedLot.orderSupplierItem.type && action.selectedLot.orderSupplierItem.type === supplierItemTypes.RAW_MATERIAL.key) {
				// with group
				if (action.selectedLot.orderSupplierItem && action.selectedLot.orderSupplierItem.commercialName && action.selectedLot.orderSupplierItem.commercialName.group) {
					const lotGroup = state.lots.get(action.selectedLot.orderSupplierItem.commercialName.group.objectId)
					const lotNameGroup = lotGroup.get(action.selectedLot.orderSupplierItem.name.charAt(0).toUpperCase())

					const selectedPosition = lotNameGroup.findIndex(lot => lot.objectId === action.selectedLot.objectId)
					const newLots = state.lots

					lotNameGroup[selectedPosition] = action.selectedLot
					lotGroup.set(action.selectedLot.orderSupplierItem.name.charAt(0).toUpperCase(), lotNameGroup)
					newLots.set(action.selectedLot.orderSupplierItem.commercialName.group.objectId, lotGroup)

					newLots[selectedPosition] = action.selectedLot

					return {
						...state,
						lots: _cloneDeep(newLots),
						inventoryOutputSnackBar: action.inventoryOutputSnackBar
					}
				}
				// without group
				if (action.selectedLot.orderSupplierItem && !action.selectedLot.orderSupplierItem.commercialName) {
					const lotGroup = state.lots.get(UNKNOWN_LOT_GROUP_ID)

					const lotNameGroup = lotGroup.get(action.selectedLot.orderSupplierItem.name.charAt(0).toUpperCase())

					const selectedPosition = lotNameGroup.findIndex(lot => lot.objectId === action.selectedLot.objectId)
					const newLots = state.lots

					lotNameGroup[selectedPosition] = action.selectedLot
					lotGroup.set(action.selectedLot.orderSupplierItem.name.charAt(0).toUpperCase(), lotNameGroup)
					newLots.set(UNKNOWN_LOT_GROUP_ID, lotGroup)

					newLots[selectedPosition] = action.selectedLot

					return {
						...state,
						lots: _cloneDeep(newLots),
						inventoryOutputSnackBar: action.inventoryOutputSnackBar
					}
				}
			}

			// --------------------------------------------------------------- //
			// --------------- SALABLE PRODUCT SUPPLIER ITEMS ---------------- //
			// --------------------------------------------------------------- //
			if (action.selectedLot.orderSupplierItem.type && action.selectedLot.orderSupplierItem.type === supplierItemTypes.SALABLE_PRODUCT.key) {
				const groupId = supplierItemTypes.SALABLE_PRODUCT.key + "-" + action.selectedLot.orderSupplierItem.productType
				const lotGroup = state.lots.get(groupId)

				const lotNameGroup = lotGroup.get(action.selectedLot.orderSupplierItem.name.trim().charAt(0).toUpperCase())

				const selectedPosition = lotNameGroup.findIndex(lot => lot.objectId === action.selectedLot.objectId)
				const newLots = state.lots

				lotNameGroup[selectedPosition] = action.selectedLot
				lotGroup.set(action.selectedLot.orderSupplierItem.name.trim().charAt(0).toUpperCase(), lotNameGroup)
				newLots.set(groupId, lotGroup)

				newLots[selectedPosition] = action.selectedLot

				return {
					...state,
					lots: _cloneDeep(newLots),
					inventoryOutputSnackBar: action.inventoryOutputSnackBar
				}
			}

			// --------------------------------------------------------------- //
			// --------------- SALABLE PRODUCT SUPPLIER ITEMS ---------------- //
			// --------------------------------------------------------------- //
			if (action.selectedLot.orderSupplierItem.type && action.selectedLot.orderSupplierItem.type === supplierItemTypes.PACKAGING_CONSUMABLE.key) {
				const groupId = supplierItemTypes.PACKAGING_CONSUMABLE.key + "-" + action.selectedLot.orderSupplierItem.productType
				const lotGroup = state.lots.get(groupId)

				const lotNameGroup = lotGroup.get(action.selectedLot.orderSupplierItem.name.trim().charAt(0).toUpperCase())

				const selectedPosition = lotNameGroup.findIndex(lot => lot.objectId === action.selectedLot.objectId)
				const newLots = state.lots

				lotNameGroup[selectedPosition] = action.selectedLot
				lotGroup.set(action.selectedLot.orderSupplierItem.name.trim().charAt(0).toUpperCase(), lotNameGroup)
				newLots.set(groupId, lotGroup)

				newLots[selectedPosition] = action.selectedLot

				return {
					...state,
					lots: _cloneDeep(newLots),
					inventoryOutputSnackBar: action.inventoryOutputSnackBar
				}
			}

			return {
				...state
			}
		}
		case "LOT_INVENTORY_DISPLAY_FILTER":
			return {
				...state,
				suppliers: action.suppliers,
				supplierItems: action.supplierItems,
			}
		case "LOT_INVENTORY_OPEN_SNACKBAR":
			return {
				...state,
				inventoryOutputSnackBar: action.inventoryOutputSnackBar,
			}
		case "LOT_INVENTORY_OPEN_DETAIL_SNACKBAR":
		case "LOT_INVENTORY_CLOSE_DETAIL_SNACKBAR":
			return {
				...state,
				inventoryOutputSnackBar: action.inventoryOutputSnackBar,
				noDisplay: (action.noDisplay !== undefined) ? action.noDisplay : state.noDisplay
			}
    case "LOT_DAMAGE_REPORT_TOGGLE_SNACKBAR": {
			return {
				...state,
				lotDamageReportSnackBar: action.lotDamageReportSnackBar,
			}
		}
		case "CLEAR_LOT_MAIN": {
			return {
				...state,
				groupIngredients: [],
				lots: null,
				unsortedLots: [],
				stockZone: null,
			}
		}
		default: {
			return {
				...state
			}
		}
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//

export function getDisplayLotsBySuppliers(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.suppliers", errorIfNotFound && "No Supplier selected")
}

export function getDisplayLotsBySupplierItems(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.supplierItems", errorIfNotFound && "No Supplier selected")
}

export function getUnsortedLotsSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.unsortedLots", errorIfNotFound && "no unsorted lots found")
}

export function getLotsEventsSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.lotsEvents", errorIfNotFound && "No lots events found")
}

export function getLotsSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.lots", errorIfNotFound && "No lots found")
}

export function getLotSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.selectedLot", errorIfNotFound && "No selectedLot found")
}

export function getLotsFiltersSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.filters", errorIfNotFound && "No filters found")
}

export function getLotsStockZoneSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.stockZone", errorIfNotFound && "No stockZone found")
}

export function getLotsSiteSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.site", errorIfNotFound && "No site found")
}

export function getLotInventoryOuputSnackbarSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.inventoryOutputSnackBar", errorIfNotFound && "No inventoryOuputSnackbar found")
}

export function getLotInventoryDamageReportSnackbarSelector(state, errorIfNotFound = false) {
	return getData(state, "lotInventory.lotDamageReportSnackBar", errorIfNotFound && "No lotDamageReportSnackbar found")
}
