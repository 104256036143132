import React from "react"
import Checkbox from "@mui/material/Checkbox"

const CheckboxAction = (props) => {
	const {checked, onCheck, canCheck, element} =  props

	const onClick = () => {
		if (canCheck) {
			onCheck(element)
		}
	}

	return (
		<Checkbox
			color="primary"
			checked={checked}
			onClick={onClick}
		/>
	)

}

export default CheckboxAction