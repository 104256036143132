import React from "react"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import { COLORS, roundNumber } from "../../../utils"
import Typography from "@mui/material/Typography"
import {
    generateStockUnitLabel, getSupplierItemQuantity
} from "../../../utils/orderReception"
import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import { deliveryNoteArticleSchema } from "../../../utils/yupValidators"

const useStyles = makeStyles({
    flex: {
        display: "flex"
    },
    flexColumn: {
        composes: "flex",
        flexDirection: "column"
    },
    modal: {
        position: "absolute",
        width: 744,
        minHeight: 230,
        background: COLORS.WHITE,
        textAlign: "justify",
        fontSize: 16,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "32px 23px 26px 24px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: 15,
        marginBottom: 10
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "24px",
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 32
    },
    backButton: {
        marginRight: 10,
        border: "none"
    },
    informationRow: {
        composes: "$flex"
    },
    informationColumn: {
        composes: "$flexColumn",
        minWidth: 200,
        marginRight: 40
    },
    unitPriceInformationColumn: {
        composes: "$informationColumn",
        minWidth: 251,
        marginRight: 119
    },
    informationTitle: {
        fontSize: 12,
        opacity: 0.87,
        color: COLORS.INFORMATION_TITLE,
        marginBottom: 16
    },
    informationTitleInput: {
        composes: "$informationTitle",
        fontSize: 16,
        marginBottom: 0
    },
    informationValue: {
        fontSize: 14
    },
    textField: {
        width: "100%",
        "& input": {
            fontSize: 14
        },
        "& label + .MuiInput-formControl": {
            marginTop: 30
        }
    },
    unit: {
        color: COLORS.BLACK_CARD,
        fontSize: 14
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
})

const EditArticleModal = (props) => {
    const {
        article,
        onBack,
        onSubmit,
        open
    } = props

    const classes = useStyles()
    const label = generateStockUnitLabel(article.supplierItem)

    const submitForm =  (values) => {
        onSubmit(values)
        onBack()
    }

    return (
        <Modal
            open={open}
            onClose={onBack}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Formik
                enableReinitialize
                initialValues={{
                    quantity: getSupplierItemQuantity(article),
                    deliveryNoteUnitPrice: article.deliveryNoteUnitPrice || 0
                }}
                onSubmit={submitForm}
                validationSchema={deliveryNoteArticleSchema}
            >
                {({
                    values,
                    errors,
                    handleChange
                }) => {
                    const quantity = Number(values.quantity)
                    const deliveryNoteUnitPrice = Number(values.deliveryNoteUnitPrice)

                    return (
                        <Form>
                            <div className={classes.modal}>
                                <div className={classes.title}>
                                    {article.supplierItem.name} - ID article {article.supplierItem.objectId}
                                </div>
                                <div className={classes.informationRow}>
                                    <div className={classes.informationColumn}>
                                        <FormControl className={classes.textField} variant="standard">
                                            <InputLabel
                                                className={classes.informationTitleInput}
                                                shrink={true}
                                            >
                                                Quantité Bon de Livraison
                                            </InputLabel>
                                            <Input
                                                name="quantity"
                                                id="standard-adornment-quantity"
                                                value={values.quantity}
                                                onChange={handleChange}
                                                aria-describedby="standard-quantity-helper-text"
                                                inputProps={{
                                                    "aria-label": "quantity",
                                                    "type": "number",
                                                    "step": "any"
                                                }}
                                            />
                                            {
                                                errors.quantity ?
                                                    (
                                                        <div className={classes.errorField}>{errors.quantity}</div>
                                                    )
                                                    : null
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={classes.unitPriceInformationColumn}>
                                        <FormControl className={classes.textField} variant="standard">
                                            <InputLabel
                                                className={classes.informationTitleInput}
                                                shrink={true}
                                            >
                                                Prix unitaire (€)
                                            </InputLabel>
                                            <Input
                                                name="deliveryNoteUnitPrice"
                                                id="standard-adornment-deliveryNoteUnitPrice"
                                                value={values.deliveryNoteUnitPrice}
                                                onChange={handleChange}
                                                endAdornment={<InputAdornment className={classes.unit} position="end">/ {label}</InputAdornment>}
                                                aria-describedby="standard-deliveryNoteUnitPrice-helper-text"
                                                inputProps={{
                                                    "aria-label": "deliveryNoteUnitPrice",
                                                    "type": "number",
                                                    "step": "any"
                                                }}
                                                type="number"
                                            />
                                            {
                                                errors.deliveryNoteUnitPrice ?
                                                    (
                                                        <div className={classes.errorField}>{errors.deliveryNoteUnitPrice}</div>
                                                    )
                                                    : null
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={classes.informationColumn}>
                                        <Typography className={classes.informationTitle}>
                                            Montant global
                                        </Typography>
                                        <Typography className={classes.informationValue}>
                                            {roundNumber(quantity * deliveryNoteUnitPrice, 2)} €
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        color="primary"
                                        onClick={onBack}
                                        variant="outlined"
                                        className={classes.backButton}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Enregister
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default EditArticleModal