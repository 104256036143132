import styled from "@emotion/styled"
import { Box } from "@mui/material"


export const cellStyle = {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "14px",
    paddingBottom: "14px"
}

export const StyledProductionSchemaBodyCell = styled(Box, {
    shouldForwardProp: (prop) => !["width", "isEdition", "align", "maxWidth"].includes(prop)
})(({ isEdition = true, width, align = "start", maxWidth }) => {
    let defaultStyles = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: align,
        alignSelf: "stretch",
        flex: 1,
        margin: 0,
        paddingLeft: 16,
        paddingRight: 16,
    }

    if (width) defaultStyles.width = width

    if (maxWidth) defaultStyles.maxWidth = maxWidth

    if (isEdition) {
        defaultStyles.paddingBottom = 8
        defaultStyles.paddingTop = 8
    }
    else {
        defaultStyles.paddingBottom = 14
        defaultStyles.paddingTop = 14
    }

    return defaultStyles
})