import React from "react"
import { makeStyles } from "@mui/styles"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import ReactSelect from "react-select"
import { COLORS } from "../../../utils"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { orderMode } from "../../../utils/ordersUtils"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        paddingTop: 14
    },
    filter: {
        marginRight: 17
    },
    fieldInput: {
        minWidth: 175
    },
    autoComplete: {
        minWidth: 350,
        marginRight: 17
    },
    typeSelector: {
        minWidth: 280,
        display: "flex",
        alignItem: "flex-end",
        marginLeft: 10
    },
    select: {
        width: "100%"
    }
})

const selectStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "transparent",
        border: "none",
        borderBottom: `1px solid ${COLORS.GREY_SEARCH}`,
        borderRadius: "none",
        maxHeight: 60,
        overflowY: "scroll"
    })
}

const OrderFilterBloc = (props) => {
    const {
        mode,
        filter,
        handleFilterChange,
        suppliers,
        handleSelectSuppliers,
        selectedSuppliers,
        selectedOrder,
        selectedProductTypes,
        handleSelectProductTypes
    } = props

    const classes = useStyles()

    const options = suppliers.sort((a, b) => a.name.localeCompare(b.name)).map((el) => ({
        value: el.objectId,
        label: el.name
    }))

    const renderProductTypes = () => {
        return Object.keys(supplierItemTypes).map((el, i) =>
            <MenuItem value={supplierItemTypes[el].value} key={i}>
                {supplierItemTypes[el].label}
            </MenuItem>
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.filter}>
                <TextField
                    variant="standard"
                    label="Rechercher produit"
                    value={filter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                    inputProps={{
                        className: classes.fieldInput
                    }}
                />
            </div>
            <div className={mode === orderMode.CREATE ? classes.autoComplete : classes.fieldInput}>
                {
                    mode === orderMode.CREATE
                        ?
                            <ReactSelect
                                options={options}
                                value={selectedSuppliers}
                                onChange={(values) => handleSelectSuppliers(values)}
                                isMulti
                                closeMenuOnSelect={false}
                                styles={selectStyles}
                                placeholder="Tous"
                                disable={mode === orderMode.UPDATE}
                            />
                        :
                            <TextField
                                variant="standard"
                                label="Fournisseurs"
                                value={selectedOrder ? selectedOrder.supplier.name : ""}
                                disabled
                            />
                }
                
            </div>
            <div className={classes.typeSelector}>
                <FormControl className={classes.select} variant="standard">
                    <InputLabel id="selectLabel">Type de produit</InputLabel>
                    <Select
                        variant="standard"
                        labelId="selectLabel"
                        multiple
                        className={classes.select}
                        value={selectedProductTypes}
                        label="Type de produit"
                        onChange={(e) => handleSelectProductTypes(e.target.value)}
                    >
                        { renderProductTypes() }
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export default OrderFilterBloc