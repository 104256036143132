import Parse from "parse"
import {
    actionWithLoader,
} from "../Utils/utils"
import {getServerUrl, parseLimitRequest} from "../../utils"
import moment from "moment"
import {axiosCall} from "../../utils/axiosUtils"

const ProductionItem = Parse.Object.extend("ProductionItem")

export function startProductionItemsPubSub() {
    return actionWithLoader(async () => {
        try {
            const date = moment().startOf("day").valueOf()
            const productionItems = await new Parse.Query(ProductionItem)
              .select("objectId")
              .notEqualTo("deleted", true)
              .greaterThanOrEqualTo("dlc", date)
              .ascending("dlc")
              .limit(parseLimitRequest)
              .find()

            const url = `${getServerUrl()}/migrations/sendProductItemsToPubSub`
            const data = { ids: productionItems.map(item => item.id) }
            const response = await axiosCall("POST", url, data, {"Content-Type": "application/json"})
            console.log("response", response)
        } catch (e) {
            console.error("Misc productionItems.startProductionItemsPubSub : ", e)
            return Promise.reject(e)
        }
    })
}
