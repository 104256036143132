import { React, useRef } from "react"
import { makeStyles } from "@mui/styles"
import dayjs from "dayjs"
import { Formik } from "formik"
import * as Yup from "yup"
import { Form } from "formik"
import { FormControl, Select, InputLabel, MenuItem, Typography, Button, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"

const useStyles = makeStyles({
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "24px",
		padding: [24, 32],
		border: "1px solid #E6E6E6"
	},
	filtersForm: {
		display: "flex"
	},
	input: {
		width: 220,
		marginRight: 16
	},
	dateInput: {
		fontSize: 16,
		width: 180,
		textAlign: "left",
	},
	error: {
		margin: "16px 0px"
	},
	option: {
		fontSize: 14
	},
	submitButton: {
		padding: [11, 16],
		width: "fit-content"
	},
})


const PackagingExecutionFilterForm = ({ selectedSite, selectedDate, sites, handleSubmit }) => {

	const formRef = useRef(null)

	const submitForm = (values) => {
		handleSubmit && handleSubmit(values)
	}

	const classes = useStyles()

	const _renderOptions = (items) => {
		return items.map((item, i) => {
			return <MenuItem value={item.objectId} key={i}>
				<div className={classes.option}>
					{item.name}
				</div>
			</MenuItem>
		})
	}

	return (
		<Formik
			innerRef={formRef}
			validationSchema={Yup.object().shape({
				site: Yup.string().required("Veuillez sélectionner un site"),
				packagingDate: Yup.string().required("Veuillez sélectionner un jour de production")
			})
			}
			initialValues={{
				site: selectedSite?.objectId || "",
				packagingDate: dayjs.utc(selectedDate).valueOf()
			}}
			onSubmit={submitForm}
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleSubmit,
			}) => {
				return (<Form className={classes.form}>
					<Typography variant={"h7"} sx={{ fontWeight: 500 }}>Sélectionnez le site et le jour de barquettage</Typography>
					<div className={classes.filtersForm}>
						<FormControl>
							<InputLabel variant="standard" htmlFor="site">Site</InputLabel>
							<Select
								variant="standard"
								value={values.site}
								name="site"
								className={classes.input}
								onChange={handleChange}
							>
								{_renderOptions(sites)}
							</Select>
						</FormControl>
						<FormControl>
						</FormControl>
						<FormControl>
							<DatePicker
								disableCloseOnSelect={false}
								showToolbar={false}
								value={values.packagingDate}
								name="packagingDate"
								variant="inline"
								inputFormat="DD/MM/YYYY"
								required
								onChange={val => {
									setFieldValue("packagingDate", dayjs.utc(val).valueOf())
								}}
								renderInput={(params) => <TextField {...params} variant="standard" label={"Jour de barquettage"} />}
								inputProps={{
									className: classes.dateInput
								}}
							/>
						</FormControl>
					</div>
					<Button
						className={classes.submitButton}
						color="primary"
						type="submit"
						variant="contained"
						onClick={handleSubmit}
					>
						Voir les barquettages
					</Button>
				</Form>)
			}}
		</Formik>
	)
}



export default PackagingExecutionFilterForm