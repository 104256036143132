import React from "react"

import { useDispatch } from "react-redux"
import { Box, Button, Stack } from "@mui/material"

import GenericTopBarHeader from "../GenericTopBarHeader"
import PageHeaderTitle from "../PageHeaderTitle"
import { COLORS } from "../../utils"
import GenericHeaderReturnButton from "../GenericHeaderReturnButton"

import { showSupplierItems } from "../../actions/Supplier/supplierItems"

const sx = {
    genericHeaderContainer: {
        height: 64,
        width: "100%",
        bgcolor: "#fff",
        zIndex: 1000
    },
}

const SupplierItemPageHeader = ({
    withBackButton,
    subtitle,
    title,
    icon,
    className,
    rightAction,
    onCancel,
    onConfirm,
    color = COLORS.HEAD_SEAZON,
    fixed = true
}) => {
    const dispatch = useDispatch()

    const onBack = () => dispatch(showSupplierItems())

    const renderRightAction = () => {
        if (onConfirm) {
            return (
                <Stack direction="row" spacing={5}>
                    <Button onClick={onCancel}>
                        Annuler
                    </Button>
                    <Button onClick={onConfirm} variant="contained">
                        Enregistrer
                    </Button>
                </Stack>   
            )
        }

        if (rightAction) {
            return rightAction
        }

        return null
    }

    return (
        <Box sx={sx.genericHeaderContainer} position={fixed ? "fixed" : "relative"}>
            <GenericTopBarHeader
                className={className}
                title={(
                    <PageHeaderTitle
                        title={title}
                        icon={icon}
                        color={color}
                        subtitle={subtitle}
                    />
                )}
                leftAction={withBackButton ? <GenericHeaderReturnButton handleClick={onBack}/> : null}
                rightAction={renderRightAction()}
            />
        </Box>
    )
}

export default SupplierItemPageHeader