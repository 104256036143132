import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"

export const CustomKeyboardDatePicker = (props) => {
    const {
        value,
        onChange,
        variant,
        showToolbar = false,
        placeholder,
        format,
        label,
        minDate,
        disabled,
        className
    } = props

    return (
        <DatePicker
            showToolbar={showToolbar}
            disableCloseOnSelect={false}
            variant={variant}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            inputFormat={format}
            minDate={minDate}
            renderInput={(params) => <TextField {...params} variant="standard" label={label} className={className} />}
        />
    )
}
