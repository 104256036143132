import React from "react"

import StepComponentPreview from "../StepComponentPreview"
import EditablePriorStepComponent from "../EditablePriorStepComponent"
import { PRODUCTION_STEPS_PRIOR_COMPONENT_NAME, StyledAccordionSummary } from "../Steps"

const PriorSteps = ({
  isEdition,
  onRowBlur,
  onRowHover,
  index,
  sectionIndex,
  indexComponent,
  hasError,
  step,
  transformationModes,
  isStepComponentHover,
  setFieldValue,
  indexSubComponent,
  subStepComponent,
  steps,
  section,
  recipe,
  fromRecipe,
  stepComponentsIndexesList,
  isReusable
}) => {
  const handleRowHover = () => {
    if (!onRowHover) return
    onRowHover(PRODUCTION_STEPS_PRIOR_COMPONENT_NAME, index, sectionIndex, indexComponent, indexSubComponent, stepComponentsIndexesList)
  }

  return (
    <StyledAccordionSummary
      onMouseEnter={handleRowHover}
      onMouseLeave={onRowBlur}
      componentName={PRODUCTION_STEPS_PRIOR_COMPONENT_NAME}
      isReusable={isReusable}
      usage={"stepComponents"}
    >
      {!subStepComponent.supplierItem && subStepComponent.priorSteps?.stepComponents.length > 0 ?
        (
          subStepComponent.priorSteps.stepComponents.map((subSubStepComponent, indexSubSubComponent) => (
            isEdition
              ? (
                  <EditablePriorStepComponent
                    sectionIndex={sectionIndex}
                    hasError={hasError}
                    stepComponent={subSubStepComponent}
                    indexComponent={indexSubComponent}
                    indexStep={index}
                    parentStep={step}
                    indexSubComponent={indexSubSubComponent}
                    transformationModes={transformationModes}
                    isHover={isStepComponentHover(index, indexSubComponent, stepComponentsIndexesList.concat(indexSubSubComponent))}
                    setFieldValue={setFieldValue}
                    steps={steps}
                    section={section}
                    recipe={recipe}
                    fromRecipe={fromRecipe}
                    key={indexSubComponent + "-" + indexSubSubComponent}
                    stepComponentsIndexesList={stepComponentsIndexesList.concat(indexSubSubComponent)}
                  />
              ) : (
                <StepComponentPreview
                  key={indexSubComponent + "-" + indexSubSubComponent}
                  stepComponent={subSubStepComponent}
                  supplierItem={subSubStepComponent.supplierItem}
                  subComponent={true}
                />)
              ))
        ) :
        (
          isEdition
          ? (
            <EditablePriorStepComponent
              sectionIndex={sectionIndex}
              hasError={hasError}
              stepComponent={subStepComponent}
              indexComponent={indexComponent}
              indexStep={index}
              parentStep={step}
              key={indexComponent + "-" + indexSubComponent}
              indexSubComponent={indexSubComponent}
              transformationModes={transformationModes}
              isHover={isStepComponentHover(index, indexComponent, stepComponentsIndexesList)}
              setFieldValue={setFieldValue}
              steps={steps}
              section={section}
              recipe={recipe}
              fromRecipe={fromRecipe}
              stepComponentsIndexesList={stepComponentsIndexesList}
            />
          ) : (
            <StepComponentPreview
              key={indexComponent + "-" + indexSubComponent}
              stepComponent={subStepComponent}
              supplierItem={subStepComponent.supplierItem}
              subComponent
            />)
        )
      }
    </StyledAccordionSummary>
  )
}

export default PriorSteps
