import React, {useMemo} from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { ACTIVE_KFC_BRANDS } from "../../../../../utils"
import { createNewDistributionCenter } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"
import { getProductTypeOptionsSelector } from "../../../../../reducers/ProductTypes/productTypes"
import { distributionCenterFormDefaultValues } from "../../../../../utils/distributionCenter"
import { SUCY_ID } from "../../../../../parseManager/site/parseSiteManager"
import { DistributionCenterSchema } from "../../../../../utils/yupValidators"

const useStyles = makeStyles(({
	field: {
		width: "100%",
		marginBottom: 10
	}
}))


export const createDistributionCenterFormId = "createDistributionCenterFormId"

const DistributionCenterCreateForm = ({ onClose }) => {

	const dispatchSites = [{ name: "Sucy", priority: 1, objectId: SUCY_ID }]
	const productTypeOptions = useSelector(getProductTypeOptionsSelector)

	const initialValues = useMemo(() => {
    return distributionCenterFormDefaultValues(dispatchSites, productTypeOptions)
  }, [productTypeOptions, dispatchSites])

	const dispatch = useDispatch()

	const classes = useStyles()

	const onCreateNewDistributionCenter = (values) => {
		dispatch(createNewDistributionCenter(values))
		onClose && onClose()
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onCreateNewDistributionCenter}
			validationSchema={DistributionCenterSchema}>
			{({ values, handleChange, errors, touched, handleBlur }) => (
				<Form
					id={createDistributionCenterFormId}
				>
					<FormControl variant="standard" className={classes.field}>
						<TextField
								variant="standard"
								name="name"
								label="Nom"
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
						/>
							{
									touched.name && errors.name
										?
										(
											<div className={classes.errorField}>{errors.name}</div>
										)
										: null
								}
					</FormControl>
					<FormControl variant="standard" className={classes.field}>
						<InputLabel>Marque</InputLabel>
						<Select
							variant="standard"
							name="brand"
							value={values.brand}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							{ACTIVE_KFC_BRANDS.map((brand, i) => (
								<MenuItem key={i} value={brand.name}>{brand.label}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Form>
			)}
		</Formik>
	)

}

export default DistributionCenterCreateForm