import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import PrintIcon from "@mui/icons-material/Print"
import SyncIcon from "@mui/icons-material/Sync"
import { COLORS } from "../../utils"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import LabelProductModal from "../../components/LabelProductModal"
import ModalDownloadProduction from "./ModalDownloadProduction"

export const QuickActions = (props) => {
    const { classes, jsonRecipe, asyncPrintLabel,
        onDownloadProd, onClickSynchronizeEtiquettable
    } = props

    const [printLabelModalIsOpen, setPrintLabelModalIsOpen] = useState(false)
    const [downloadProductionModalIsOpen, setDownloadProductionModalIsOpen ] = useState(false)
    const internalTags = jsonRecipe && Array.isArray(jsonRecipe.internalTag)
        ? jsonRecipe.internalTag.map(elem => {
            return {
                ...elem,
                name: elem.label
            }
        })
        : []

    const downloadProd = (values) => {
        onDownloadProd(values)
        setDownloadProductionModalIsOpen(false)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.title}>
                Actions rapides
            </div>
            <IconButton
                className={classes.actionContainer}
                classes={{
                    root: classes.rootButton
                }}
                onClick={() => setPrintLabelModalIsOpen(true)}
                size="large">
                <PrintIcon
                    classes={{
                    root: classes.rootIcon
                    }}
                />
                <span className={classes.spanButton}>
                    Imprimer une etiquette
                </span>
            </IconButton>
            <IconButton
                className={classes.actionContainer}
                classes={{
                    root: classes.rootButton
                }}
                onClick={() => setDownloadProductionModalIsOpen(true)}
                size="large">
                <PrintIcon classes={{
                    root: classes.rootIcon
                }}
                />
                <span className={classes.spanButton}>
                    Imprimer une fiche de production
                </span>
            </IconButton>
            <IconButton
                className={classes.actionContainer}
                classes={{
                    root: classes.rootButton
                }}
                onClick={onClickSynchronizeEtiquettable}
                size="large">
                <SyncIcon
                    classes={{
                    root: classes.rootIcon
                }}
                />


                <span className={classes.spanButton}>
                    Mettre à jour etiquettable
                </span>
            </IconButton>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={printLabelModalIsOpen}
                onClose={() => setPrintLabelModalIsOpen(false)}
            >
                <LabelProductModal
                    closeModal={() => setPrintLabelModalIsOpen(false)}
                    name={jsonRecipe ? jsonRecipe.name : ""}
                    type="RECIPE"
                    productId={jsonRecipe ? jsonRecipe.objectId : ""}
                    onSubmitPrint={asyncPrintLabel}
                    internalTags={internalTags}
                />
            </Modal>
            <ModalDownloadProduction
                jsonRecipe={jsonRecipe}
                isOpen={downloadProductionModalIsOpen}
                handleClose={() => setDownloadProductionModalIsOpen(false)}
                clickDownload={downloadProd}
            />
        </Paper>
    )
}

const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        display: "block",
        padding: 16,
        marginTop: 16,
        borderRadius: 4
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500
    },
    actionContainer: {
        display: "flex",
        width: "100%",
        color: COLORS.PRIMARY_COLOR,
        fontSize: 16,
        justifyContent: "flex-start"
    },
    spanButton: {
        marginLeft: 4
    },
    rootButton: {
        borderRadius: 0,
        textAlign: "start",
        padding: 0,
        paddingTop: 12,
        paddingBottom: 12,
    },
    rootIcon: {
        marginRight: 12
    }
}

export default withStyles(styles)(QuickActions)
