import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  withSelection: {
    fontSize: 27,
    fontWeight: "normal",
    lineHeight: "32px",
    marginTop: 93,
    "&.is-tablet": {
      marginTop: 65,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  validateButton: {
    marginTop: 39,
    "&.is-tablet": {
      marginTop: 47,
    },
    "&:hover, &:disabled, &:disabled:hover": {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY_COLOR,
    },
  },
  input: {
    fontWeight: "normal",
    fontSize: 60,
    color: COLORS.BLACK_CARD,
    textAlign: "right",
    lineHeight: "70px",
    width: 102,
    marginTop: 37,
    padding: "0 12px",
    "&.is-tablet": {
      marginTop: 29,
    },
  },
}))

const InputCardNumber = (props) => {
  const { selectedCard, handleValidate, isTablet, setValue, isInventory } =
    props
  const classes = useStyles()
  const inputRef = useRef()

  const [disabled, setDisabled] = useState(true)

  useEffect(() => inputRef.current.focus(), [selectedCard])

  const enableInput = (e) => {
    setDisabled(e.target.value === "")
    setValue(e.target.value)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection, { "is-tablet": isTablet })}>
        {!isInventory && (
          <>
            {" "}
            Entrez le total de <span>{selectedCard.uniqueCode}</span> conformes
            reçues{" "}
          </>
        )}
        {isInventory && (
          <>
            {" "}
            Entrez la quantité de <span>{selectedCard.uniqueCode}</span> dans le
            frigo{" "}
          </>
        )}
      </div>

      {/* text summary */}
      <TextField
        variant="standard"
        inputProps={{
          className: clsx(classes.input, { "is-tablet": isTablet }),
          placeholder: "000",
          type: "number",
          pattern: "[0-9]*",
        }}
        onChange={enableInput}
        inputRef={inputRef}
      />

      {/* button submit */}
      <Button
        className={clsx(classes.validateButton, "action-button", {
          "is-tablet": isTablet,
        })}
        variant="contained"
        color="primary"
        disableElevation
        disabled={disabled}
        type="submit"
      >
        confirmer
      </Button>
    </form>
  )
}

export default InputCardNumber
