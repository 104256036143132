import React, {
    Fragment,
    useState,
    useCallback,
    useRef,
    useMemo,
    // useEffect
} from "react"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS, KFC_BRANDS, intersectionSet, roundCurrency } from "../../../utils"
import { LunchbagIcon, LunchbagColors } from "../../../icons/LunchbagIcon"
import _cloneDeep from "lodash/cloneDeep"
import TextField from "@mui/material/TextField"
import PrintIcon from "@mui/icons-material/Print"
import SplitIcon from "@mui/icons-material/CallSplit"
import _isEqual from "lodash/isEqual"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import ProductionPlanningLine from "../../../components/Planning/ProductionPlanningLine"
import debounce from "lodash/debounce"
import { useSelector } from "react-redux"
import moment from "moment"
import { usePrevious, useUpdate } from "react-use"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
    planningCardTopContainer: {
        display: "flex",
        backgroundColor: COLORS.MEDIUM_GREY,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
    },
    planningCardImg: {
        width: "auto",
        borderTopLeftRadius: 3
    },
    planningCardTitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "120%",
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        marginLeft: 13,
        marginTop: 5,
        marginBottom: 5,
        "&:hover": {
            textDecoration: "underline"
        }
    },
    defaultPlanningCardImage: {
        height: 44,
        width: 44,
        borderTopLeftRadius: 3
    },
    planningCardInfos: {
        fontWeight: "normal",
        lineHeight: "130%",
        fontSize: 12,
        marginTop: 5,
        padding: "0px 7px 0px 7px",
        color: COLORS.DARK_GREY,
    },
    planningCardLocked: {
        backgroundColor: "#EBF7EE"
    },
    planningCardBrandBloc: {
        display: "flex",
        marginLeft: "auto"
    },
    hover: {
        position: "absolute",
        width: "100%",
        top: 20,
        border: `1px solid ${COLORS.DEFAULT_GREY}`,
        zIndex: "400",
        backgroundColor: COLORS.WHITE,
        fontSize: 12,
        fontWeight: 500,
        padding: 4
    },
    expectedProductionInfoInput: {
        width: 30
    },
    expectedProductionInputProps: {
        height: 5,
        fontSize: 12,
        textAlign: "right"
    },
    divider1: {
        height: 1,
        width: "100%",
        backgroundColor: COLORS.MEDIUM_GREY,
        marginBottom: 5
    },
    divider2: {
        height: 1,
        width: "100%",
        backgroundColor: COLORS.MEDIUM_GREY,
        marginTop: 5,
        marginBottom: 5
    },
    saleDateRowContainer: {
        marginTop: 5
    },
    productionActions: {
        textAlign: "left"
    },
    actionsRow: {
        display: "flex",
        marginTop: 3,
        justifyContent: "space-between"
    },
    actionsIcons: {
        color: COLORS.DARK_GREY,
        cursor: "pointer",
        fontSize: 18
    },
    greenActionsIcons: {
        composes: "$actionsIcons",
        color: COLORS.GREEN_400,
    },
    redActionsIcons: {
        composes: "$actionsIcons",
        color: COLORS.RED,
    },
    lunchbag: {
        "& span": {
            lineHeight: 0
        },
        "& img": {
            maxWidth: 20
        }
    },
    foodCostContainer: {
        fontSize: 12,
        padding: "7px 0px 6px 7px",
        backgroundColor: COLORS.LIGHT_GREY_2,
        color: COLORS.DARK_GREY,
        fontStyle: "italic"
    },
    totalSameSaleDate: {
        textAlign: "end",
        marginTop: 7
    },
    hidden: {
        display: "none"
    }
}))

/**
 * the performance of this component is very important, so we bypass many React mechanisms
 * and trigger renders by ourselves (using react-use's useUpdate)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProductionPlanningCard = (props) => {
    const {
        card,
        saveExpectedProduction,
        saveSumExpectedProduction,
        removeProductionItemIdFromSaved,
        printSingleKitchenCard,
        openSplitModal,
        canPrint,
        setAnyFocused,
        openProductInfo,
        openDeleteModal,
        canDeleteProductionItem,
        openAddModal,
        filteredBrands,
        filteredSaleDate,
        canAdd,
        canSplit,
        canInputVolume,
        deleteProductionItem,
        showDays,
        rules
    } = props

    const classes = useStyles()
    const rerender = useUpdate()
    const changed = useSelector(state => state.planning.changed || [])
    const loadingProductionItems = useRef(new Set())
    const cardDayInfo = showDays && showDays.find(elem => elem.timestamp === card.productionDate)
    card.isLocked = !!(cardDayInfo && cardDayInfo.isLocked)
    //-------------------------------------------------------------------------//
    //--------------------- is card changed ? ---------------------------------//
    //-------------------------------------------------------------------------//
    // we use usePrevious because useMemo doesn't seem to be able to take a equality function as 3rd parameter
    const previousCard = usePrevious(card)
    const cardChanged = !_isEqual(card, previousCard)

    const stateCardRef = useRef()
    const productionItemIdsRef = useRef()

    const getTotalByIds = (ids) => {
        let total = 0
        stateCard.expectedProduction
            .filter(el => ids.includes(el.id))
            .forEach(elm => {
                total += Number(elm.value)
            })

        return total
    }

    if (cardChanged) {
        // card sorting
        // sorting by saleDate first
        // and if saleDate is the same, sorting by brand (following the brand order in the brand filter input)
        card.saleDate.sort(function (a, b) {
            if (a.value < b.value) return -1
            else if (a.value > b.value) return 1
            else { // SORT BRAND
                const brandAIndex = KFC_BRANDS.findIndex(el => el.name === a.brand) || 0
                const brandBIndex = KFC_BRANDS.findIndex(el => el.name === b.brand) || 0
                return brandAIndex - brandBIndex
            }
        })

        /*
        * from the card, creates a new structure with all saleDates (the date objects) merged by sale date
        */
        const newStateCard = _cloneDeep(card)
        const finalSaleDate = []

        for (const saleDate of newStateCard.saleDate) { // Note : saleDate isn't a simple date, it's the data of a date
            const existingObject = finalSaleDate.find(el => el.saleDate === saleDate.value && el.brand === saleDate.brand)

            if (existingObject) {
                existingObject.tab.push(saleDate)
            }
            else {
                finalSaleDate.push({
                    saleDate: saleDate.value, // this saleDate is a real date (unlike card.saleDate)
                    brand: saleDate.brand,
                    tab: [saleDate]
                })
            }
        }
        newStateCard.saleDate = finalSaleDate
        stateCardRef.current = newStateCard

        const cardDayInfo = showDays && showDays.find(elem => elem.timestamp === card.productionDate)
        card.isLocked = cardDayInfo && cardDayInfo.isLocked
    }

    const stateCard = stateCardRef.current


    //-------------------------------------------------------------------------//
    //--------------------- is "changed" changed ? ----------------------------//
    //-------------------------------------------------------------------------//
    const previousChanged = usePrevious(changed)
    if (changed !== previousChanged) { // the return from the data server caused a new array
        // changed is an array containing the returned changed data from the Data Server
        //---- newProductionItemIds ----//
        const newProductionItemIds = new Set()
        stateCard.expectedProduction.forEach(expProd => newProductionItemIds.add(expProd.id))
        productionItemIdsRef.current = newProductionItemIds

        //----------- are we concerned ? ----------//
        const changedIds = Object.keys(changed)
        if (changedIds.some(prodId => newProductionItemIds.has(prodId))) {
            // some products are impacted
            stateCard.expectedProduction.map(expProd => {
                if (changedIds.includes(expProd.id)) {
                    expProd.value = changed[expProd.id]
                }
                return expProd
            })
        }
    }
    //-------------------------------------------------------------------------//

    const productionItemIds = productionItemIdsRef.current // it's a Set
    const loadingProductionItemIds = useSelector(state => intersectionSet(state.planning.loading, productionItemIds), _isEqual)
    const savedProductionItemIds = useSelector(state => intersectionSet(state.planning.saved, productionItemIds), _isEqual)

    const [showName, setShowName] = useState(false)
    const [linesEditable, setLinesEditable] = useState([])

    const totalValues = useMemo(() => {
        let totals = []
        stateCardRef.current.saleDate.forEach((elem, j) => {
            const ids = elem.tab.map(el => el.id)
            if (ids.length > 1) {
                totals[j] = getTotalByIds(ids)
            }
        })
        return totals
    }, [stateCardRef.current, changed])


    const findItemExpectedProduction = useCallback(id => {
        const currentExpectedProduction = stateCard.expectedProduction.find(el => el.id === id)

        return currentExpectedProduction ? currentExpectedProduction.value : ""
    }, [stateCardRef.current])

    /**
     * only FOODCHERI can have 2 lines
     * @param date
     * @param brand
     * @returns {number}
     */
    const calculateBrandSubTotal = (date, brand) => {
        let count = 0

        const currentSaleDate = stateCard.saleDate.find(el => el.saleDate === date && el.brand === brand)

        if (currentSaleDate) {
            for (const saleDate of currentSaleDate.tab) {
                const currentExpectedProduction = stateCard.expectedProduction.find(el => el.id === saleDate.id)

                count += currentExpectedProduction ? Number(currentExpectedProduction.value) : 0
            }
        }

        return count
    }

    /**
     * called when a line has been manually changed
     * @param value
     * @param productionItemId
     * @param debounce
     * @param single
     */
    const updateExpectedProduction = (value, productionItemId, debounce = true, single = null) => {
        loadingProductionItems.current.add(productionItemId)
        const newValue = value === "" ? 0 : parseInt(value)
        if (single && single.brandSubTotal && single.brandSubTotal < newValue) return
        const currentExpectedProduction = stateCard.expectedProduction.find(el => el.id === productionItemId)

        if (!currentExpectedProduction) return
        currentExpectedProduction.value = newValue

        if (single && single.brandSubTotal) {
            const ids = single.elem.tab.map(el => el.id)
            const otherId = ids.find(elm => elm !== productionItemId)
            const otherExpectedProduction = stateCard.expectedProduction.find(el => el.id === otherId)
            otherExpectedProduction.value = single.brandSubTotal - newValue
            single.reusableId = (single.isReusable) ? productionItemId : otherId
        }

        rerender()

        if (!single) {
            if (debounce) debouncedSaveExpectedProduction(value, productionItemId)
            if (!debounce) _saveExpectedProduction(value, productionItemId)
        }

        if (single) debouncedSaveTotal(value, single.j, single.elem, {
            id: single.id, total: single.brandSubTotal, isReusable: single.isReusable, reusableId: single.reusableId
        })
    }

    const debouncedSaveExpectedProduction = useCallback(
        debounce(async (value, productionItemId) => {
            _saveExpectedProduction(value, productionItemId)
        }, 2000
        ), []
    )

    const _saveExpectedProduction = async (value, productionItemId) => {
        if (loadingProductionItems.current.has(productionItemId)) {
            const parsedValue = value === "" ? 0 : parseInt(value)
            const oldCurrentExpectedProduction = card.expectedProduction.find(el => el.id === productionItemId)
            const currentExpectedProduction = stateCard.expectedProduction.find(el => el.id === productionItemId)

            if (oldCurrentExpectedProduction && currentExpectedProduction && oldCurrentExpectedProduction.value === currentExpectedProduction.value) return
            await saveExpectedProduction(parsedValue, productionItemId, card)
            loadingProductionItems.current.delete(productionItemId)
            setAnyFocused(false)
        }
    }

    const getTotalExpectProduction = () => {
        let total = 0

        for (const i in stateCard.expectedProduction) {
            const current = stateCard.expectedProduction[i]

            total += current.value !== "" ? parseInt(current.value) : 0
        }

        return total
    }

    const _isReusable = (id) => {
        const currentOriginalCard = stateCard.originalCards.find(el => el.objectId === id)

        return currentOriginalCard ? currentOriginalCard.isReusable : false
    }

    const print = () => {
        printSingleKitchenCard(stateCard)
    }

    const showProductInfo = () => {
        openProductInfo(card)
    }

    const updateTotal = (value, index, elementData) => {
        loadingProductionItems.current.add("total")
        totalValues[index] = value
        if (value !== "") {
            setLinesEditable(linesEditable.filter(line => line !== index))
            debouncedSaveTotal(value, index, elementData)
        }
    }

    const debouncedSaveTotal = useCallback(
        debounce(async (value, index, elementData, single) => {
            _saveTotal(value, index, elementData, single)
        }, 2000)
        , []
    )

    /**
     *
     * @param value
     * @param index
     * @param elementData
     * @param single if null, it means we're saving the total value
     * @returns {Promise<void>}
     * @private
     */
    const _saveTotal = async (value, index, elementData, single = null) => {
        const id = single ? single.id : "total"
        if (loadingProductionItems.current.has(id)) {
            loadingProductionItems.current.delete(id)

            if (value !== "") {
                await emptyLine(value, index, elementData, single)
            }
        }
    }

    const emptyLine = async (value, index, elementData, single = null) => {
        // locked means that there are several lines (we have to wait for the data api to give us the values)
        const locked = (single && single.brandSubTotal)
        const data = single && !single.brandSubTotal ?
            {
                productionItemId: single.id,
                newProd: value,
            } :
            {
                startDate: elementData.saleDate,
                productionDate: card.productionDate,
                productType: card.originalCards[0].productType,
                brand: elementData.brand,
                productId: card.originalCards[0].itemId,
                prod: locked ? single.brandSubTotal : value,
                locked,
                prodReu: locked ?
                    (single.isReusable) ? value : single.brandSubTotal - value :
                    null,
                prodReuId: locked ?
                    (single.isReusable) ? single.id : single.reusableId :
                    null,
                productionItemId: locked ?
                    single.id :
                    null
            }

        const ids = elementData.tab.map(el => el.id)
        await saveSumExpectedProduction(data, ids, card)
        if (!single) setLinesEditable(Array.from(new Set([...linesEditable, index])))
    }

    const _removeFromSaved = (id) => {
        removeProductionItemIdFromSaved(id)
    }

    const isHidden = !stateCard.saleDate.some(el => (filteredSaleDate ? el.saleDate === filteredSaleDate : true) && filteredBrands.includes(el.brand))

    return (
        <Fragment>
            {
                stateCard
                    ?
                    (
                        <div className={isHidden ? classes.hidden : ""}>
                            <div className={classes.planningCardTopContainer}>
                                <>{stateCard?.uniqueCode || ""}</>
                                <div
                                    className={classes.planningCardTitle}
                                    onClick={showProductInfo}
                                >
                                    {stateCard.name}
                                </div>
                            </div>
                            <div className={classes.foodCostContainer}>
                                Foodcost : {roundCurrency(stateCard.foodcost, 2)} €
                            </div>
                            <div className={classes.divider1} />
                            <div className={clsx(classes.planningCardInfos, {
                                [classes.planningCardLocked]: card.isLocked,
                            })}>

                                <>
                                    {
                                        stateCard.saleDate.map((elem, index) => {
                                            const length = elem.tab.length
                                            // only FOODCHERI can have 2 lines
                                            const brandSubTotal = calculateBrandSubTotal(elem.saleDate, elem.brand)
                                            return (
                                                <div key={index}>
                                                    {
                                                        elem.tab.map((productionItemData, brandIndex) => {
                                                            const dlcExist = undefined !== stateCard.brand.find(b => b.value === productionItemData.brand)
                                                            // Is this packaging reusable ?
                                                            // FOODCHERI is the only brand that has 2 types of packaging
                                                            const isReusable = _isReusable(productionItemData.id)
                                                            return (
                                                                <div key={brandIndex} className={classes.saleDateRowContainer}>
                                                                    <ProductionPlanningLine
                                                                        deleteProductionItem={deleteProductionItem}
                                                                        stateCard={stateCard}
                                                                        // numberOfLineForEachBrand={brandsLines}
                                                                        canDeleteProductionItem={canDeleteProductionItem}
                                                                        isReusable={isReusable}
                                                                        productionItemData={productionItemData}
                                                                        dlcExist={dlcExist}
                                                                        dlc={dlcExist && moment.utc(stateCard.dlc.filter(el => el.brand === productionItemData.brand)[0].value).format("DD/MM")}
                                                                        loading={loadingProductionItemIds.has(productionItemData.id)}
                                                                        saved={savedProductionItemIds.has(productionItemData.id)}
                                                                        elem={elem}
                                                                        number={index}
                                                                        length={length}
                                                                        rules={rules}
                                                                        brandSubTotal={brandSubTotal}
                                                                        canEdit={canInputVolume && length === 1 && !card.isLocked}
                                                                        findItemExpectedProduction={findItemExpectedProduction}
                                                                        updateExpectedProduction={updateExpectedProduction}
                                                                        saveTotal={_saveTotal}
                                                                        saveExpectedProduction={_saveExpectedProduction}
                                                                        removeFromSaved={_removeFromSaved}
                                                                    />
                                                                    {
                                                                        brandIndex === length - 1 &&
                                                                        <>
                                                                            {
                                                                                length > 1 &&
                                                                                <div className={classes.totalSameSaleDate}>
                                                                                    {
                                                                                        canInputVolume && !card.isLocked
                                                                                            ?
                                                                                            (
                                                                                                <TextField
                                                                                                    variant="standard"
                                                                                                    // prevent scroll value change
                                                                                                    onWheel={(e) => e.target.blur()}
                                                                                                    type="number"
                                                                                                    value={
                                                                                                        totalValues[index]
                                                                                                    }
                                                                                                    defaultValue={(brandSubTotal !== 0) ? brandSubTotal : ""}
                                                                                                    placeholder={`${brandSubTotal}`}
                                                                                                    className={classes.expectedProductionInfoInput}
                                                                                                    inputProps={{
                                                                                                        className: classes.expectedProductionInputProps,
                                                                                                        min: 0,
                                                                                                        step: 1
                                                                                                    }}
                                                                                                    onChange={(event) => {
                                                                                                        updateTotal(event.target.value, index, elem)
                                                                                                    }}
                                                                                                    onBlur={(event) => {
                                                                                                        _saveTotal(event.target.value, index, elem)
                                                                                                    }}
                                                                                                />
                                                                                            )
                                                                                            : brandSubTotal
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className={classes.divider2} />
                                                                        </>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                <div className={classes.actionsRow}>
                                    <div className={classes.productionActions}>
                                        {
                                            stateCard.itemType !== "SubcontractorProduct" && canPrint &&
                                            <PrintIcon
                                                className={stateCard.kitchenHasBeenPrinted
                                                    ? stateCard.printKitchen
                                                        ? classes.greenActionsIcons
                                                        : classes.redActionsIcons
                                                    : classes.actionsIcons}
                                                onClick={() => {
                                                    print()
                                                }}
                                            />
                                        }
                                        {
                                            canSplit &&
                                            <SplitIcon
                                                className={classes.actionsIcons}
                                                onClick={() => {
                                                    openSplitModal(stateCard)
                                                }}
                                            />
                                        }
                                        {
                                            canAdd &&
                                            <AddIcon
                                                className={classes.actionsIcons}
                                                onClick={() => {
                                                    openAddModal(stateCard)
                                                }}
                                            />
                                        }
                                        {
                                            canDeleteProductionItem &&
                                            <DeleteIcon
                                                className={classes.actionsIcons}
                                                onClick={() => {
                                                    openDeleteModal(stateCard)
                                                }}
                                            />
                                        }
                                    </div>
                                    {
                                        stateCard.lunchbag &&
                                        <div className={classes.lunchbag}>
                                            <LunchbagIcon color={LunchbagColors.BLACK} />
                                        </div>
                                    }
                                    {getTotalExpectProduction()}
                                </div>
                            </div>
                        </div>
                    )
                    :
                    null
            }
            {
                showName &&
                <div
                    className={classes.hover}
                    onMouseEnter={() => { setShowName(true) }}
                    onMouseLeave={() => { setShowName(false) }}
                >
                    {stateCard.name}
                </div>
            }
        </Fragment>
    )
}

export default ProductionPlanningCard
