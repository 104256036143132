import React from "react"
import { Table, TableBody, TableHead, TableRow } from "@mui/material"
import { PETableHeaderCell, PETotalTableCell, PETableCell } from "../StyledPackagingExecutionPreviewComponents"
import { convertKilosIntoGrams } from "../../../utils/ressources"
import {roundNumber} from "../../../utils"

const PackagingExecutionSectionsAndWeights = ({ sections = [] }) => {

	return (
		<Table sx={{
			height: "100%"
		}}>
			<TableHead>
				<TableRow>
					<PETableHeaderCell align="start">Éléments à barquetter</PETableHeaderCell>
					<PETableHeaderCell>Poids réel à barquetter (g)</PETableHeaderCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sections.map((section, index) => (
					<TableRow key={index}>
						<PETableCell align="start">{section.sectionName || ""}</PETableCell>
						<PETableCell>{(convertKilosIntoGrams(section.realWeight || 0))}</PETableCell>
					</TableRow>
				))}
				<TableRow>
					<PETotalTableCell align="start">Poids total</PETotalTableCell>
					<PETotalTableCell>{roundNumber(convertKilosIntoGrams(sections.reduce((acc, section) => acc + (section.realWeight || 0), 0)), 1)}</PETotalTableCell>
				</TableRow>
			</TableBody>
		</Table>
	)

}

export default PackagingExecutionSectionsAndWeights