import React from "react"
import PlanningSettingReportForm from "../../../OldSettings/Tabs/PlanningSettingReport/PlanningSettingReportForm"
import ProductionTab from "../ProductionTabs"

export const ReportsTab = () => {
    return (
        <ProductionTab tabValue="reports">
            <PlanningSettingReportForm/>
        </ProductionTab>
    )
}

export default ReportsTab