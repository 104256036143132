import React from "react"
import { makeStyles } from "@mui/styles"
import { Typography } from "@mui/material"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	container: {
		padding: 0,
		display: "flex",
		flexDirection: "column",
		gap: 8,
		alignSelf: "stretch",
	},
	title: {
		color: COLORS.BLACK800,
		fontSize: 20,
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: 1.4,
	},
	description: {
		color: COLORS.BLACK_500,
		fontSize: 16,
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: 1.5,
	}
})

const SupplierItemLotTitle = ({ title, description }) => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<Typography className={classes.title} variant="h4">
				{title}
			</Typography>
			{description && (
				<Typography className={classes.description}>
					{title}
				</Typography>
			)}
		</div>
	)
}

export default SupplierItemLotTitle