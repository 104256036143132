import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { lighten } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import Parse from "parse"
import { confirm } from "../../react-await-dialog/react-await-dialog"
import { parseLimitRequest } from "../../../utils"
const Recipe = Parse.Object.extend("Recipe")
const Section = Parse.Object.extend("Section")

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  sectionNameToDeleteDiv: {
    display: "list-item",
    marginLeft: 25,
    marginTop: 5,
  },
  deleteMessageTitle: {
    fontSize: 20,
    marginBottom: 15,
  },
  deleteMessageContent: {
    marginBottom: 15,
  },
})

class SectionEnhancedTableToolbar extends PureComponent {
  generateDeleteMessage = (recipes) => {
    const { classes } = this.props

    const names = recipes.map((elem) => elem.attributes.name)
    return (
      <div>
        <div className={names.length > 1 ? classes.deleteMessageTitle : ""}>
          Voulez-vous supprimer cette section ?<br />
        </div>
        <div className={classes.deleteMessageContent}>
          {names.length === 0
            ? ""
            : names.length > 1
            ? "Cette section est présente dans les produits internes suivants : "
            : "Cette section est présente dans la produit interne suivant"}
        </div>
        {names.map((elem, key) => {
          return (
            <div key={key} className={classes.sectionNameToDeleteDiv}>
              {elem}
              <br />
            </div>
          )
        })}
      </div>
    )
  }

  _deleteSection = async () => {
    // Query
    const { selected, sections } = this.props
    const sectionsToDelete = sections.filter((section) => {
      if (selected.includes(section.id)) {
        return section.id
      } else {
        return false
      }
    })
    const innerQuery = await new Parse.Query(Section).equalTo(
      "objectId",
      sectionsToDelete[0].id
    )
    const recipes = await new Parse.Query(Recipe)
      .matchesQuery("sections", innerQuery)
      .limit(parseLimitRequest)
      .find()
    const message = this.generateDeleteMessage(recipes)
    if (
      await confirm({
        title: "Confirm",
        message: message,
        confirmText: "SUPPRIMER",
      })
    ) {
      const { selected, deleteSectionGeneric, onDeleteItem, sections } =
        this.props
      const sectionsToDelete = sections.filter((section) => {
        if (selected.includes(section.id)) {
          return section.id
        } else {
          return false
        }
      })
      for (const i in sectionsToDelete) {
        onDeleteItem()
        await deleteSectionGeneric(sectionsToDelete[i])
      }
    }
  }

  _createSection = () => {
    const { showSection } = this.props
    showSection()
  }

  render() {
    const { numSelected, classes } = this.props
    const text =
      numSelected > 1 ? "Sections Réutilisable" : "Section Réutilisable"

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {text}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Sections Réutilisable
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        {numSelected > 0 ? (
          numSelected === 1 ? (
            <div className={classes.actions}>
              <Tooltip title="Supprimer" disableInteractive>
                <IconButton
                  aria-label="Supprimer"
                  onClick={this._deleteSection}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : null
        ) : (
          <div className={classes.actions}>
            <Tooltip title="Créer" disableInteractive>
              <IconButton
                aria-label="Créer"
                onClick={this._createSection}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    )
  }
}

SectionEnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

SectionEnhancedTableToolbar = withStyles(toolbarStyles)(
  SectionEnhancedTableToolbar
)

export default SectionEnhancedTableToolbar
