import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import clsx from "clsx"

const StatusSelect = (props) => {
    const {classes, isCreation, ...otherProps} = props

    return (
        <FormControl className={clsx(classes.statusFormControl, {"noAction": isCreation})} variant="standard">
            <InputLabel>Status</InputLabel>
            <Select
				variant="standard"
                name="statut"
                label="Status"
                id="status"
                fullWidth
                {...otherProps}
            >
                <MenuItem value="1">En conception</MenuItem>
                <MenuItem value="2">Conception abandonnée</MenuItem>
                <MenuItem value="3">Contrôle achats à réaliser</MenuItem>
                <MenuItem value="4">Contrôle achat NOK</MenuItem>
                <MenuItem value="5">Informations à renseigner</MenuItem>
                <MenuItem value="6">Produit prêt à la vente</MenuItem>
            </Select>
        </FormControl>
    )
}

export default StatusSelect
