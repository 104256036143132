import {
	actionWithLoader,
	getReplacingPath,
	haveAccessRight,
	onEnter,
	push
} from "../Utils/utils"
import moment from "moment"
import {
	getProductStockWithEventsByDate,
	setEventChecked
} from "../../parseManager/reception/parseProductStockManager"
import { KFC_BRANDS } from "../../utils"
import { getDistributionCentersByBrand } from "../../parseManager/planning/parsePlanningDashboardManager"

export function onEnterReceptionGap(store) {
	return onEnter({
		store,
		actionThunk: loadReceptionGap,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight({ canReadEventProductStockGap: true })
	})
}

export function loadReceptionGap() {
	return actionWithLoader(async (dispatch) => {
		const startDate = moment.utc().startOf("day").valueOf()
		const endDate = moment.utc().endOf("day").valueOf()

		await loadData(dispatch, startDate, endDate)
	})
}

export function changeDate(startDate, endDate) {
	return actionWithLoader(async (dispatch) => {
		if (startDate <= endDate) {
			await loadData(dispatch, startDate, endDate)
		}
	})
}

export async function loadData(dispatch, startDate, endDate) {
	const brand = KFC_BRANDS[0].name

	const [hubs, originalReceptionGapData] = await Promise.all([
		getDistributionCentersByBrand(brand),
		getProductStockWithEventsByDate(startDate, endDate, brand)
	])

	dispatch({
		type: "RECEPTION_GAP_LOADED",
		startDate,
		endDate,
		originalReceptionGapData,
		hubs
	})
}

export function checkEvent(event, values) {
	return actionWithLoader(async (dispatch) => {
		try {
			const checked = await setEventChecked(event, values.comment)

			dispatch({
				type: "RECEPTION_GAP_EVENT_CHECKED",
				selectedEvent: event,
				checked
			})
		} catch (e) {
			dispatch({
				type: "RECEPTION_GAP_OPEN_SNACKBAR",
				receptionGapSnackBar: { open: true, duration: 5000, type: "success", message: "Erreur lors de la mise à jour" }
			})
		}
	})
}

export function brandChange(brand, startDate, endDate) {
	return actionWithLoader(async (dispatch) => {
		const hubs = await getDistributionCentersByBrand(brand)
		const originalReceptionGapData = await getProductStockWithEventsByDate(startDate, endDate, brand)

		dispatch({
			type: "FILTER_BRAND_UPDATED",
			brand,
			hubs,
			originalReceptionGapData
		})
	})
}

export function filterByNameOrCode(name) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_GAP_FILTER_NAME_OR_CODE_SET",
			searchFilter: name
		})
	})
}


export function closeReceptionGapSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_GAP_CLOSE_SNACKBAR",
			receptionGapSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

export function showReceptionGapInterface() {
	return push("/receptionGap")
}
