import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import SupplierItemSettingSubstitution from "./SupplierItemSettingSubstitution"
import SupplierItemSettingSourcingValue from "./SupplierItemSettingSourcingValue"
import SupplierItemSettingCookingModes from "./SupplierItemSettingCookingModes"
import {
    getRawMaterialSupplierItems
} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import SupplierItemSettingRecipeUses from "./SupplierItemSettingRecipeUses"
import { supplierItemTypes } from "../../utils/supplierItemUtils"
import SupplierItemSettingRelatives from "./SupplierItemSettingRelatives"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        padding: "40px 0",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        justifyContent: "center",
        height: "100%"
    },
    firstColumnContainer: {
        width: "75%",
        margin: "0 16px",
        minWidth: 500,
        maxWidth: 1000,
    },
    secondColumnContainer: {
        width: "25%",
        margin: "0 16px",
        minWidth: 275,
        maxWidth: 300
    },
    blocContainer: {
        marginTop: 20
    },
    supplierItemSettingRecipeUses: {
        marginBottom: 20
    }
}))

const SupplierItemSetting = (props) => {
    const {
        supplierItem,
        canEdit,
        onUpdateSupplierItem,
        onUpdateSupplierItemCommercialName,
        supplierItemsRelatives
    } = props

    const classes = useStyles()

    const supplierItemsRawMaterial = async () => {
        return await getRawMaterialSupplierItems({ includes: [], select: [] })
    }

    return (
        <div className={classes.container}>
            <div className={classes.firstColumnContainer}>
                <div className={classes.supplierItemSettingRecipeUses}>
                    <SupplierItemSettingRecipeUses
                        supplierItem={supplierItem}
                        canEdit={canEdit}
                        onUpdateSupplierItem={onUpdateSupplierItemCommercialName}
                    />
                </div>
                <div className={classes.supplierItemSettingRecipeUses}>
                    <SupplierItemSettingSubstitution
                        canEdit={canEdit}
                        supplierItem={supplierItem}
                        getSupplierItemList={supplierItemsRawMaterial}
                    />
                </div>
                {supplierItem.type === supplierItemTypes.RAW_MATERIAL.value && supplierItemsRelatives.length > 0 &&
                    <div>
                        <SupplierItemSettingRelatives
                            supplierItemsRelatives={supplierItemsRelatives}
                        />
                    </div>
                }
            </div>
            <div className={classes.secondColumnContainer}>
                <SupplierItemSettingSourcingValue
                    supplierItem={supplierItem}
                    onUpdateSupplierItem={onUpdateSupplierItem}
                />
                <SupplierItemSettingCookingModes
                    supplierItem={supplierItem}
                    onUpdateSupplierItem={onUpdateSupplierItem}
                />
            </div>
        </div>
    )
}

export default SupplierItemSetting