export const usersColumns = [
    { name: "objectId", title: "ID" },
    { name: "username", title: "Email" },
    { name: "roles", title: "Rôles"}
]

export const defaultRolesColumns = [
    {name: "rubric", title: "Rubrique"},
    {name: "label", title: "Action"}
]
