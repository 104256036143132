import React from "react"
import { DragDropContext } from "react-beautiful-dnd"

const DragDropContextWrapper = (props) => {
    const {
        children,
        isDragDroppable,
        ...restProps
    } = props

    return (
        <>
            {
                isDragDroppable &&
                <DragDropContext
                    {...restProps}
                >
                    {children}
                </DragDropContext>
            }
            {
                !isDragDroppable &&
                <div
                    {...restProps}
                >
                    {children}
                </div>
            }
        </>
    )
}

export default DragDropContextWrapper