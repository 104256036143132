import React from "react"
import { TableRow, TextField } from "@mui/material"
import { PETableCell, PETableDivider } from "../StyledPackagingExecutionPreviewComponents"
import FormikErrorMessage from "../../../components/FormikErrorMessage" 
import { convertKilosIntoGrams } from "../../../utils/ressources"
import { roundNumber } from "../../../utils"

const PackagingExecutionSection = ({ section, index, editable, setFieldTouched, onChangeSectionRealWeight }) => {

	const _renderKiloToGrams = (value) => {
		return roundNumber(convertKilosIntoGrams(value), 0)
	}

	return (
		<TableRow>
			<PETableCell align="start">{section.sectionName || ""}</PETableCell>
			<PETableCell>{_renderKiloToGrams(section.recipeSectionWeight || 0)}</PETableCell>
			<PETableCell>
				{editable ? <TextField
					name={`sections[${index}].realWeight`}
					value={_renderKiloToGrams(section.realWeight || 0)}
					variant="standard"
					onChange={(e) => {
						setFieldTouched(`sections[${index}].realWeight`, true, false)
						onChangeSectionRealWeight(e.target.value)
					}}
					type={"number"}
					inputProps={{
						style: {
							fontSize: 20,
							textAlign: "center"
						}
					}}
					onWheel={(e) => e.target.blur()}
				/> :
					<span>{_renderKiloToGrams(section.realWeight || 0)}</span>
				}
				<FormikErrorMessage name={`sections[${index}].realWeight`} />
			</PETableCell>
			<PETableDivider />
			<PETableCell disabled>{roundNumber(section.forecastWaste || 0, 1)}</PETableCell>
		</TableRow>
	)
}

export default PackagingExecutionSection