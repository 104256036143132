import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import { MeetingRoom } from "@mui/icons-material"
import Toolbar from "@mui/material/Toolbar"
import AppBar from "@mui/material/AppBar"
import { ArrowBack } from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import moment from "moment"


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
        zIndex: theme.zIndex.drawer + 1
    },
    container: {
        display: "flex",
        alignItems: "center",
        height: 63
    },
    title: {
        color: COLORS.RED_RASPBERRY
    },
    icon: {
        marginRight: 10,
        color: COLORS.RED_RASPBERRY,
        height: 24
    },
    headerInfos: {
        color: COLORS.DARK_GREY,
    }
}))

const ReceptionHeader = (props) => {
    const {
        date,
        hub,
        onClose
    } = props
    const classes = useStyles()

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton onClick={onClose} size="large">
                    <ArrowBack />
                </IconButton>
                <Icon className={classes.icon}>
                    <MeetingRoom />
                </Icon>
                <Typography variant="h6" color="textPrimary" className={classes.title}>
                    Réceptions Hubs <span className={classes.headerInfos}>- {hub}, {moment(date).format("DD/MM/YYYY")} </span>
                </Typography>

            </Toolbar>
        </AppBar>
    )
}

export default ReceptionHeader