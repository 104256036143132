import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import {
    ingredientResumeSchema,
} from "../../utils/yupValidators"
import {
    FormControl,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import ModalForm from "../form/ModalForm"
import ModalActions from "../ModalActions"
import { COLORS } from "../../utils"
import { useSelector } from "react-redux"
import { getFamiliesIngredients, getGroupsIngredients } from "../../reducers/Ingredients/Internal/CommercialName"
import { getSuppliers } from "../../reducers/Ingredients/SubcontractorsCommercialNames/SubcontractorsCommercialNames"

const useStyles = makeStyles({
    formControl: {
        marginLeft: "unset",
        width: "100%",
        marginTop: 20,
        "& *": {
            fontSize: 16,
            color: COLORS.BLACK,
        },
        "& .MuiFormLabel-root": {
            position: "relative",
            height: 20,
            display: "flex",
            alignItems: "center",
            transform: "none",
            marginTop: 4,
        },
    },
    selectFormControl: {
        composes: "$formControl",
        marginTop: 16,
    },
    formControlDisabled: {
        opacity: 0.4,
        pointerEvents: "none",
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7,
    },
    textField: {
        marginTop: 7,
    },
})

const IngredientResumeForm = (props) => {
    const {
        onClose,
        open,
        ingredient,
        onSave,
        ingredientType
    } = props

    const classes = useStyles()

    const [selectedFamily, setSelectedFamily] = useState(ingredient.group.family)
    const familiesIngredients = useSelector(getFamiliesIngredients)
    const groupIngredients = useSelector(getGroupsIngredients)
    const suppliers = useSelector(getSuppliers)
    const [isInitialSubmit, setIsInitialSubmit] = useState(false)

    const renderComplexity = () => {
        const complexities = [1, 2, 3, 5, 8, 13]

        return complexities.map(complexity =>
            <MenuItem key={complexity} value={complexity}>{complexity}</MenuItem>
        )
    }
    const months = [
        { value: "1", label: "Janvier" },
        { value: "2", label: "Février" },
        { value: "3", label: "Mars" },
        { value: "4", label: "Avril" },
        { value: "5", label: "Mai" },
        { value: "6", label: "Juin" },
        { value: "7", label: "Juillet" },
        { value: "8", label: "Août" },
        { value: "9", label: "Septembre" },
        { value: "10", label: "Octobre" },
        { value: "11", label: "Novembre" },
        { value: "12", label: "Décembre" },
    ]

    const initialValues = {
        name: ingredient.name,
        supplier: ingredient.supplier?.objectId,
        family: ingredient.group.family.objectId,
        group: ingredient.group.objectId,
        complexity: ingredient.complexity,
        season: (ingredient.season || []).map(month => month + ""), // some month are numbers (former error) => convert to string
    }

    const submitForm = async (values) => {
        const newValues = { ...values }
        setIsInitialSubmit(false)
        if (!newValues.season.length) {
            delete newValues.season
        }
        onSave("RESUME", newValues)
        onClose()
    }

    return (
        <ModalForm
            onClose={onClose}
            open={open}
            title="Modifier le résumé de l’ingrédient"
            width={500}
        >

            <Formik
                initialValues={{ ...initialValues }}
                validationSchema={ingredientResumeSchema}
                onSubmit={submitForm}
            >
                {({ values, errors, handleChange, handleSubmit, setFieldValue }) => {

                    const handleFamilyChange = (event) => {
                        const newFamilyId = event.target.value
                        const newFamily = familiesIngredients.find(family => family.objectId === newFamilyId)
                        setIsInitialSubmit(true)
                        setSelectedFamily(newFamily)
                        setFieldValue("family", newFamilyId) // Update the formik field value for "family"
                    }

                    useEffect(() => {
                        if (isInitialSubmit) {
                            values.group = "" // Clear group value when family changes
                            setFieldValue("group", "")
                        }
                    }, [isInitialSubmit])

                    return (
                        <Form>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Nom de l'ingredient</InputLabel>
                                <TextField
                                    variant="standard"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                {errors.name && (
                                    <div className={classes.errorField}>{errors.name}</div>
                                )}
                            </FormControl>
                            {ingredientType === "externe" &&
                                <FormControl className={classes.formControl} variant="standard">
                                    <InputLabel>Fournisseur</InputLabel>
                                    <Select
                                        name="supplier"
                                        value={values.supplier}
                                        onChange={handleChange}
                                    >
                                        {suppliers.map((supplier) => (
                                            <MenuItem
                                                key={supplier.objectId}
                                                value={supplier.objectId}
                                            >
                                                {supplier.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.supplier && (
                                        <div className={classes.errorField}>{errors.supplier}</div>
                                    )}
                                </FormControl>
                            }
                            <FormControl className={classes.formControl} variant="standard">
                                <InputLabel>Famille d'ingrédients</InputLabel>
                                <Select
                                    name="family"
                                    value={values.family}
                                    onChange={handleFamilyChange}
                                >
                                    {familiesIngredients.map((familyIngredient) => (
                                        <MenuItem
                                            key={familyIngredient.objectId}
                                            value={familyIngredient.objectId}
                                        >
                                            {familyIngredient.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.family && (
                                    <div className={classes.errorField}>{errors.family}</div>
                                )}
                            </FormControl>
                            <FormControl className={classes.formControl} variant="standard">
                                <InputLabel>Groupe d'ingrédients</InputLabel>
                                <Select
                                    name="group"
                                    value={values.group}
                                    onChange={handleChange}
                                >
                                    {groupIngredients
                                        .filter(groupIngredient => groupIngredient.family.name === selectedFamily.name)
                                        .map(groupIngredient => (
                                            <MenuItem
                                                key={groupIngredient.objectId}
                                                value={groupIngredient.objectId}
                                            >
                                                {groupIngredient.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {errors.group && (
                                    <div className={classes.errorField}>{errors.group}</div>
                                )}
                            </FormControl>
                            {ingredientType === "interne" &&
                                <FormControl className={classes.formControl} variant="standard">
                                    <InputLabel>Complexité</InputLabel>
                                    <Select
                                        name="complexity"
                                        value={values.complexity}
                                        onChange={handleChange}
                                    >
                                        {renderComplexity()}
                                    </Select>
                                    {errors.complexity && (
                                        <div className={classes.errorField}>{errors.complexity}</div>
                                    )}
                                </FormControl>
                            }
                            <FormControl className={classes.formControl} variant="standard">
                                <InputLabel>Saisonnalité</InputLabel>
                                <Select
                                    variant="standard"
                                    name="season"
                                    id="season"
                                    multiple
                                    value={values.season}
                                    fullWidth
                                    onChange={(event) => {
                                        const selectedValues = event.target.value
                                        setFieldValue("season", selectedValues) // Update the values.season array
                                    }}
                                    renderValue={(selected) => (
                                        <div>
                                            {months
                                                .filter((month) => selected.includes(month.value))
                                                .map((month) => month.label.substring(0, 3))
                                                .join(", ")}
                                        </div>
                                    )}
                                >
                                    {months.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            <Checkbox
                                                checked={values.season.includes(item.value)}
                                                color="secondary"
                                            />
                                            <ListItemText primary={item.label}></ListItemText>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <ModalActions onCancel={onClose} onSubmit={handleSubmit} />
                        </Form>
                    )
                }}
            </Formik>
        </ModalForm >
    )
}

export default IngredientResumeForm