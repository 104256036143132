const defaultState = {
    roles: [],
    users: [],
    rights: [],
    tableRolesColumns: [],
    tableRolesRows: [],
    snackBar: { open: false, type: "", message: "", duration: 0 },
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "LOAD_ROLES":
            return {
                ...state,
                roles: action.roles,
                rights: action.rights,
                tableRolesColumns: action.tableRolesColumns,
                tableRolesRows: action.tableRolesRows
            }
        case "LOAD_USERS":
            return {
                ...state,
                users: action.users,
                roles: action.roles
            }
        case "OPEN_RIGHTS_SNACKBAR":
            return {
                ...state,
                snackBar: action.snackBar
            }
        case "CLOSE_RIGHTS_SNACKBAR":
            return {
                ...state,
                snackBar: action.snackBar
            }
        default:
            return {
                ...state
            }
    }
}
