import React from "react"
import { useSelector } from "react-redux"
import MainFilter from "../../components/LotInventory/MainFilter"
import { DLC_FILTER_TYPES } from "../../utils/lotInventoryUtils"
import { getSecondaryDLCGroupIngredientsSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"

const useStyles = makeStyles({
  secondaryDLCMenuFilters: {
    padding: "14px 18px 14px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "24px",
    backgroundColor: COLORS.LIGHT_GREY,
  }
})

const SecondaryDLCMenuFilters = ({
  date,
  searchValue,
  onChangeSearchValue,
  onChangeDlcTypes,
  selectedDlcTypes
}) => {

  const classes = useStyles()

  const groupIngredients = useSelector(getSecondaryDLCGroupIngredientsSelector)

  return (
    <MainFilter
      isSecondaryDlc
      date={date}
      searchValue={searchValue}
      onSearchChange={onChangeSearchValue}
      onDlcTypeChange={onChangeDlcTypes}
      dlcFilterTypes={DLC_FILTER_TYPES}
      groupIngredients={groupIngredients}
      selectedDlcTypes={selectedDlcTypes}
      focusSearch={false}
      className={classes.secondaryDLCMenuFilters}
      
    />
  )
}

export default SecondaryDLCMenuFilters