import React, { useState } from "react"
import {useDispatch} from "react-redux"
import { makeStyles } from "@mui/styles"
import { DatePicker } from "@mui/x-date-pickers"
import { Button, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import {COLORS} from "../../utils"
import dayjs from "dayjs"
import {showProductionReception} from "../../actions/ProductionReception/ProductionReception"

const useStyles = makeStyles({
	container: {
		color: COLORS.BLACK,
		boxShadow: "0 0 3px rgba(0,0,0,.2)",
		backgroundColor: COLORS.WHITE,
		padding: "15px 30px 25px 30px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	title: {
		fontSize: 20,
		fontFamily: "Roboto",
		fontWeight: 500
	},
	filterContainer: {
		marginTop: 10,
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	selectContainer: {
		margin: "5px 25px 0 25px"
	},
	siteSelector: {
		width: 130
	},
	stockZoneSelector: {
		width: 220
	},
	separator: {
		marginTop: 20,
		width: "100%",
		height: 1,
		backgroundColor: COLORS.GREY_FLAW_ICON
	},
	submitButton: {
		marginTop: 21,
		width: 328,
		height: 38,
		borderRadius: 0
	},
	dateInput: {
		fontSize: 16,
		width: 180,
		textAlign: "left",
		marginTop: 1,
	},

	/** PENCIL **/
	iconContainer: {
		width: 38,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 3,
		padding: 0,
	},
	selectZoneContainer: {
		composes: "flexRow"
	},

	zoneItem: {
		composes: "flexRow"
	}
})

const ProductionReceptionFilterForm = ({
	sites,
    siteSelectedToUse,
    stockZoneSelected
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [productionDate, setProductionDate] = useState(dayjs.utc().startOf("day").add(1, "days").valueOf())

	const findStockZones = (siteSelectedToUse) => {
		return siteSelectedToUse.stockZones
	}

	const findSiteByValue = (sites, siteSelectedToUse) => {
		return sites.find((item) => (
			item.objectId === siteSelectedToUse.objectId
		))
	}

	const [selectedSite, setSelectedSite] = useState(findSiteByValue(sites, siteSelectedToUse))
	const [stockZones, setStockZones] = useState(findStockZones(selectedSite))
	const stockZoneSelectedToUse = stockZoneSelected ?
		selectedSite.stockZones.find(stockZone => stockZone.objectId === stockZoneSelected.objectId)
		: sites[0].stockZones[0]
	const [selectedStockZone, setSelectedStockZone] = useState(stockZoneSelectedToUse)

	const renderElements = (items) => {
		return items.map((item, i) => {
			return <MenuItem value={item} key={i} className={classes.selectZoneContainer}>
				<div className={classes.zoneItem}>
					{item.name}
					{item.objectId === 0 && (
						<IconButton
							className={classes.iconContainer}
							size="large">
							<img src="/img/edit_disabled.png" />
						</IconButton>
					)}
				</div>
			</MenuItem>
		})
	}

	const handleSiteChange = (e) => {
		const selectedSite = e.target.value
		const selectedStockZone = selectedSite.stockZones[0] || null

		setSelectedSite(selectedSite)
		setSelectedStockZone(selectedStockZone)
		setStockZones(findStockZones(selectedSite))
	}

	const onDateChange = (value) => {
		// the input field and picker does not have the same hour
		// the date picker set the current date with current hour
		// the input field set the current date but with hour at 00:00:00
		// so we set the hour, min and sec manually for both
		if (value) {
			const dateWithCurrentTime = value.set({
				h: dayjs.utc().get("hour"),
				m: dayjs.utc().get("minute"),
				s: dayjs.utc().get("second"),
			})

			setProductionDate(dateWithCurrentTime.utc().startOf("day").valueOf())
		}
	}

	const onSubmit = () => {
		/** TODO SUBMIT **/
		dispatch(showProductionReception(selectedSite.objectId, selectedStockZone.objectId, productionDate))
	}

	return (
		<div className={classes.container}>
			<h3 className={classes.title}>
				Sélectionnez le site, la zone de stockage et le jour de production
			</h3>
			<div className={classes.filterContainer}>
				<FormControl className={classes.selectContainer} variant="standard">
					<InputLabel variant="standard" htmlFor="uncontrolled-native">
						Site
					</InputLabel>
					<Select
						variant="standard"
						value={selectedSite}
						className={classes.siteSelector}
						onChange={handleSiteChange}
					>
						{renderElements(sites)}
					</Select>
				</FormControl>
				<FormControl className={classes.selectContainer} variant="standard">
					<InputLabel variant="standard" htmlFor="uncontrolled-native">
						Zone de stockage
					</InputLabel>
					<Select
						variant="standard"
						value={selectedStockZone}
						className={classes.stockZoneSelector}
						onChange={(e) => {
							setSelectedStockZone(e.target.value)
						}}
					>
						{renderElements(stockZones)}
					</Select>
				</FormControl>
				<FormControl className={classes.selectContainer} variant="standard">
					<DatePicker
						disableCloseOnSelect={false}
						showToolbar={false}
						value={productionDate}
						name="date"
						variant="inline"
						inputFormat="DD/MM/YYYY"
						required
						onChange={onDateChange}
						renderInput={(params) => <TextField {...params} variant="standard" label={"Jour de production"} />}
						inputProps={{
							className: classes.dateInput
						}}
					/>
				</FormControl>
			</div>
			<span className={classes.separator} />
			<Button
				className={classes.submitButton}
				color="primary"
				variant="contained"
				type="submit"
				onClick={onSubmit}
			>
				Voir les produits sortis
			</Button>
		</div>
	)
}


export default ProductionReceptionFilterForm