import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"

const ProductsTags = Parse.Object.extend("ProductsTags")

export async function getProductsTags({ includes = [], select = [] } = {}) {
    try {
        const query = new Parse.Query(ProductsTags)
            .notEqualTo("deleted", true)

        if (includes?.length > 0) {
            query.include(includes)
        }

        if (select?.length > 0) {
            query.select(select)
        }

        const productsTags = await query
            .limit(parseLimitRequest)
            .find()

        return productsTags ? productsTags.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.products.resources.productTag.parseProductTagManager.getProductsTags error : ", e)
        return Promise.reject(e)
    }
}

export async function createProductTag(productTag, values) {
    try {
        let parseProductTag

        if (!productTag) {
            parseProductTag = new ProductsTags()
        }
        else {
            parseProductTag = await new Parse.Query(ProductsTags)
                .equalTo("objectId", productTag.objectId)
                .first()
        }

        if (parseProductTag) {
            Object.keys(values).forEach(function (key) {
                const val = values[key]
                if (val !== null) {
                    parseProductTag.set(key, val)
                }
            })

            await parseProductTag.save()

            return parseProductTag.toJSON()
        }
    }
    catch (e) {
        console.error("parseManager.products.resources.productTag.parseProductTagManager.createProductTag error : ", e)
        return Promise.reject(e)
    }
}

export async function countProductsTags() {
    try {
        return await new Parse.Query(ProductsTags).count()
    }
    catch (e) {
        console.error("parseManager.products.resources.productTag.parseProductTagManager.countProductsTags error : ", e)
        return Promise.reject(e)
    }
}
