import { Add } from "@mui/icons-material"
import { Box, Fab, Table, TableBody, TableHead, TableRow, TextField } from "@mui/material"
import { debounce } from "lodash"

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { addMachineTypes, loadMachineTypes, showMachineType } from "../../../../../actions/Machines/Machines"
import { getMachineTypesSelector } from "../../../../../reducers/Machines/machines"
import { COLORS } from "../../../../../utils"
import { StyledTableCell } from "../../../../../components/styled/StyledTableCell"
import AddMachineTypeModal from "./AddMachineTypeModal"

const sx = {
    firstColumn: {
        width: 450,
        pl: 5,
    },
    row: {
        cursor: "pointer",
    }
}

const MachineTypes = () => {
    const dispatch = useDispatch()
    const machineTypes = useSelector(getMachineTypesSelector)
    const [openCreationDialog, setOpenCreationDialog] = useState(false)

    const toggleDialog = () => setOpenCreationDialog(!openCreationDialog)

    const handleRowClick = (id) => {
        dispatch(showMachineType(id))
    }

    const onSubmit = (values) => {
        dispatch(addMachineTypes(values))
    }

    // search 500ms after the user typed
    const onSearch = debounce((e) => {
        dispatch(loadMachineTypes({ search: e.target.value }))
    }, 500)

    return (
        <Box bgcolor={COLORS.GREY_IMAGE_LIST}>
            <Box>
                <Box sx={{ pl: 6, pb: 3, pt: 1 }}>
                    <TextField
                        variant="standard"
                        onChange={onSearch}
                        label="Type de machines"
                    />
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={sx.firstColumn} align="left">Type de machines</StyledTableCell>
                            <StyledTableCell align="left">Nombre de modèles différents</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {machineTypes.map((machineType) => (
                            <TableRow key={machineType.objectId} onClick={() => handleRowClick(machineType.objectId)} bgcolor="#fff" sx={sx.row}>
                                <StyledTableCell align="left" sx={sx.firstColumn}>{machineType.name}</StyledTableCell>
                                <StyledTableCell align="left">{machineType.machineModels?.length || 0}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{ position: "absolute", left: 30, bottom: 40 }}>
                <Fab color="primary" onClick={toggleDialog}>
                    <Add />
                </Fab>
            </Box>
            <AddMachineTypeModal open={openCreationDialog} onClose={toggleDialog} onConfirm={onSubmit} />
        </Box>
    )
}

export default MachineTypes