import React from "react"
import { TableCell, TableHead, TableRow, styled } from "@mui/material"

import { COLORS } from "../../../../utils"

const StyledTableCell = styled(TableCell)({
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.WHITE,
    maxWidth: 110,
})

const headers = [
    { id: "label", label: "Label" },
    { id: "order", label: "Classement" },
    { id: "pronoun", label: "Pronom" },
    { id: "kfcName", label: "Nom KFC" },
    { id: "fcSzName", label: "Nom FC/SZN" },
    { id: "unqiueCode", label: "Séquence code unique" },
    { id: "managementMode", label: "Mode de gestion" },
    { id: "brands", label: "Marques concernées" }
]

const ProductTypesTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                {
                    headers.map((header, index) =>
                        <StyledTableCell
                            key={header.id + index}
                            align={header.align || "left"}
                        >
                            {header.label}
                        </StyledTableCell>
                    )
                }
            </TableRow>
        </TableHead>
    )
}

export default ProductTypesTableHead