import moment from "moment"
import cloneDeep from "lodash/cloneDeep"

import { KFC_BRANDS } from "../utils"

export const productTypeAllOption = {
    value: "ALL",
    type: "ALL",
    label: "Tous",
    adj: ""
}

// TODO: remove this after the static to database product types refactoring is done
// and not used anymore
export const productType = [
    productTypeAllOption,
    {
        value: "STARTER",
        type: "STARTER",
        label: "Entrée",
        adj: "Cette"
    },
    {
        value: "MAIN_COURSE",
        type: "MAIN_COURSE",
        label: "Plat",
        adj: "Ce"
    },
    {
        value: "MEAL_PREP",
        type: "MEAL_PREP",
        label: "Meal Prep",
        adj: "Ce"
    },
    {
        value: "YAOURT",
        type: "YAOURT",
        label: "Yaourt",
        adj: "Ce"
    },
    {
        value: "DESSERT",
        type: "DESSERT",
        label: "Dessert",
        adj: "Ce"
    },
    {
        value: "BREAD",
        type: "BREAD",
        label: "Pain",
        adj: "Ce"
    },
    {
        value: "CHEESE",
        type: "CHEESE",
        label: "Fromage",
        adj: "Ce"
    },
    {
        value: "BREAKFAST",
        type: "BREAKFAST",
        label: "Petit déjeuner",
        adj: "Ce"
    },
    {
        value: "DRINK",
        type: "DRINK",
        label: "Boisson",
        adj: "Cette"
    },
    {
        value: "SNACK",
        type: "SNACK",
        label: "Snack",
        adj: "Ce"
    },
    {
        value: "CUTLERY",
        type: "CUTLERY",
        label: "Couverts",
        adj: "Ce"
    },
    {
        value: "GOODIES",
        type: "GOODIES",
        label: "Goodies",
        adj: "Ce"
    }
]

export const ambientProducts = ["DRINK", "YAOURT", "SNACK"]
export const newAmbientProducts = [...ambientProducts, "GOODIES", "CUTLERY"]
export const mainAmbientProducts = productType.filter((product) => ambientProducts.includes(product.value))
export const isAmbientProduct = (product) => ambientProducts.includes(product.productType)

export const getProductTypeLabel = (value) => {
    const currentProductType = productType.find(product => product.value === value)
    if (!currentProductType) {
        return ""
    }
    return currentProductType.label
}


export const getSelectedAmbientsLabel = (items, productTypeOptions) => {
    return productTypeOptions
        .filter((option) => items.includes(option.value))
        .map((option) => option.label)
        .join(", ")
}

export function computeDispatchIndexPosition(selectedProductDispatch) {
    let index = 0

    if (isAllSkipped(selectedProductDispatch)){
        resetSkippedHubs(selectedProductDispatch)
    }

    for (const i in selectedProductDispatch.hubs){
        const current = selectedProductDispatch.hubs[i]

        if (current.realQuantity === null && !current.skipped && !current.locked && !current.closed){
            return index
        }

        index += 1
    }

    return index
}

export function resetSkippedHubs (selectedProductDispatch) {
    for (const i in selectedProductDispatch.hubs){
        const current = selectedProductDispatch.hubs[i]

        current.skipped = false
    }
}

export function isAllSkipped (productDispatch) {
    for (const i in productDispatch.hubs){
        const current = productDispatch.hubs[i]
        if (current.realQuantity === null && !current.skipped && !current.locked && !current.closed){
            return false
        }
    }

    return true
}

export function isDispatchComplete (selectedItem) {
    for (const i in selectedItem.hubs){
        const current = selectedItem.hubs[i]
        if (current.realQuantity === null && !current.locked && !current.closed){
            return false
        }
    }

    return true
}

export function computeDispatchQuantity (data) {
    let quantity = 0

    for (const i in data.hubs){
        const current = data.hubs[i]

        quantity += current.volume
    }

    return quantity
}

export function computeHubDispatchQuantity(data) {
    let quantity = {}
    for (const i in data) {
        data[i].hubs.forEach(hub => {
            if (undefined === quantity[hub.idHub]) quantity[hub.idHub] = {
                ref: 0,
                totalRef: 0,
                total: 0,
                dispatched: 0,
                refDispatched: 0,
                remained: 0,
                elements: [],
                hasError: false,
                hasToDo: false,
                refToDo: 0
            }

            if (["TODO", "IN_PROGRESS"].includes(data[i].kfcStatus) && data[i].prodWarehouse === null) {
                quantity[hub.idHub].hasToDo = true
                quantity[hub.idHub].refToDo += 1
                quantity[hub.idHub].totalRef += 1
                return
            }

            if (hub.locked || data[i].kfcStatus === "DONE") {
                quantity[hub.idHub].dispatched += hub.volume
                quantity[hub.idHub].refDispatched += 1
            } else {
                quantity[hub.idHub].elements.push(data[i].objectId)
                quantity[hub.idHub].remained += hub.volume
                quantity[hub.idHub].ref += 1
            }

            if (true === hub.hasError) {
                quantity[hub.idHub].hasError = true

                if (hub.diff) quantity[hub.idHub].total += hub.diff
            }

            quantity[hub.idHub].total += hub.volume
        })
    }

    return quantity
}

export function getTabLengthByComputed(computedQuantity, hubs) {
    let length = [0, 0, 0, 0]

    for (const i in hubs) {
        const currentData = hubs[i]
        const currentComputed = computedQuantity[currentData.objectId]

        if (currentComputed) {
            if (currentComputed.ref !== 0) length[0] += 1
    
            if (currentComputed.hasToDo)  length[1] += 1
    
            if (currentComputed.ref === 0) {
                if (!currentComputed.hasToDo && currentComputed.hasError) length[2] += 1
                if (!currentComputed.hasToDo && !currentComputed.hasError) length[3] += 1
            }
        }
    }

    return length
}

export function getProductDispatchDlc(productDispatch) {
    return moment.utc(productDispatch.productionDate).add(productDispatch.lifeTime, "days").valueOf()
}

/**
 * for ambiants product, display total stock per unit (ex: piece)
 * @param {*} volume 
 * @param {*} subcontractorProduct 
 * @returns 
 */
export function getProductDispatchVolumePerStockUnit(volume, productDispatch, subcontractorProduct, brand) {
    if (!volume) return 0

     // Due to KFC-1165, we have to set ambient quantity dispatch to 0 but only for manuel adding
    if (isAmbientProduct(productDispatch) && !productDispatch.algorithmicAmbientDispatch) {
        return 0
    }

    if (isAmbientProduct(productDispatch) && subcontractorProduct && brand === "FOODCHERI") {
        return volume / subcontractorProduct.name.units.stock.unity.quantity
    }

    return volume
}

export function getProductDispatchProd(productDispatch, subcontractorProduct, brand) {
    if (!productDispatch.prod) return 0

    if (isAmbientProduct(productDispatch) && subcontractorProduct && brand === "FOODCHERI") {
        return productDispatch.prod / subcontractorProduct.name.units.stock.unity.quantity
    }

    return productDispatch.prod
}

export function productDispatchFormatHubs(productDispatch) {
    if (productDispatch.brand === KFC_BRANDS[0].name){
        if (["YAOURT", "BREAD", "DRINK", "SNACK"].includes(productDispatch.productType)) {
            productDispatch.hubs = productDispatch.hubs.filter(el => el.packagingTypes &&  (el.packagingTypes.includes("DISPOSABLE") || (el.packagingTypes.length === 1 && el.packagingTypes.includes("REUSABLE"))))
            productDispatch.hubs = productDispatch.hubs.map(el => ({...el, realQuantity: el.locked || el.closed ? el.volume : null, skipped: false}))
        } else {
            if (productDispatch.isReusable){
                productDispatch.hubs = productDispatch.hubs.filter(el => el.packagingTypes && el.packagingTypes.includes("REUSABLE"))
                productDispatch.hubs = productDispatch.hubs.map(el => ({...el, realQuantity: el.locked || el.closed ? el.volume : null, skipped: false}))
            }
            else {
                productDispatch.hubs = productDispatch.hubs.filter(el => el.packagingTypes && el.packagingTypes.includes("DISPOSABLE"))
                productDispatch.hubs = productDispatch.hubs.map(el => ({...el, realQuantity: el.locked || el.closed ? el.volume : null, skipped: false}))
            }
        }
    }
    else {
        productDispatch.hubs = productDispatch.hubs.map(el => ({...el, realQuantity: el.locked || el.closed ? el.volume : null, skipped: false}))
    }

    productDispatch.hubs = getDispatchHubsFiltered(productDispatch.hubs, productDispatch.site.objectId)
}

export function getProductDispatchProductTypeLabel(productDispatch) {
    const currentProductType = productType.find(el => el.type === productDispatch.productType) || null
    return currentProductType ? currentProductType.label : ""
}

export function sortProductDispatchData(data, productTypeOptions) {
    let finalData = []
    const sortProductType = cloneDeep(productTypeOptions)
    sortProductType.sort(function(a, b){
        if (a.label > b.label) return -1
        if (a.label < b.label) return 1
        return 0
    })

    for (const i in sortProductType){
        const current = sortProductType[i]

        const tab = data.filter(el => el.productType === current.value).sort(function(a, b){
            return a.commercialTitle - b.commercialTitle
        })

        finalData = [].concat(...finalData, tab)
    }

    return finalData
}

/**
 * format product data to send to the api
 */
export const formatSubcontractorProduct = (product, dlc, quantity, isDlcAddingForAmbient = false) => {
    const productImages = product.appImage
    const type = "SubcontractorProduct"
    const name = product.name && product.name.name ? product.name.name : ""
  
    return {
        itemId: product.objectId,
        itemType: type,
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
        dlc,
        name,
        commercialName: product.commercialName,
        brand: product.brand,
        uniqueCode: product.uniqueCode,
        itemBrand: product.brands || [product.brand],
        productType: product.type,
        internalTag: product.internalTag,
        image: Array.isArray(productImages) && productImages.length > 0 ? productImages[0] : productImages,
        rating: product.rating,
        nutriscore: (product.nutritionInformation && product.nutritionInformation.nutriscore) || null,
        subcontractor: product.subcontractor,
        price: product.price,
        foodcost: product.foodcost || product.totalCost,
        season: product.season,
        expectedProduction: isDlcAddingForAmbient ? 0 : quantity,
        nationalSend: true,
        sendCapital: true,
        smallRetail: true,
        lunchbag: false
    }
}
 

export const hasHubLocked = (productDispatch) => productDispatch && productDispatch.hubs.every(hub => !hub.locked)
export const isFoodCheriAmbient = (subcontractorProduct, brand) => subcontractorProduct && isAmbientProduct(subcontractorProduct) && brand === "FOODCHERI"
export const isProdWarehouseNull = (productDispatch) => productDispatch && productDispatch.prodWarehouse === null

export const getParamsFromPath = (pathname) => {
    const paths = pathname.split("/")
    if (
        (pathname.startsWith("/dispatch/")) && 
        ((paths.length === 3) || (paths.length === 4))
    ) {
        return {
            siteId: paths[paths.length - 1]
        }
    }

    return null
}

// remove all hubs not in the dispatchSite chosen
export const getDispatchHubsFiltered = (hubs, siteId) => {
    let hubsFiltered = []
    for (const hub of hubs) {
        if (hub.dispatchSites) {
            for (const dispatchSite of hub.dispatchSites) {
                if (dispatchSite.site.objectId === siteId) {
                    hubsFiltered.push(hub)

                    break
                }
            }
        }
    }

    return hubsFiltered
}
