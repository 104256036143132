import React, { PureComponent, useEffect, useLayoutEffect, useRef } from "react"
import { Formik, Form } from "formik"
import cloneDeep from "lodash/cloneDeep"
import withStyles from "@mui/styles/withStyles"
import { Grid } from "@mui/material"
import Icon from "@mui/material/Icon"
import Button from "@mui/material/Button"

import Sections from "../../components/Recipe/Sections"
import { roundNumber } from "../../utils"
import { computeRecipeDataOnFieldChange } from "../../utils/recipes"
import { recipeSectionsFormInitialValues } from "../../actions/Utils/utils"
import { RecipeStylesNew } from "../../styles"
import { RecipeSchema } from "../../utils/yupValidators"

const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })

    return ref.current
}

class Recipe extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            rowHover: null,
            deleteHover: null,
            fieldFocused: false,
            initialValues: null,
            paddingHeader: 0
        }

        if (props.parseRecipe) {
            this.state.initialValues = recipeSectionsFormInitialValues(props.parseRecipe)
        }

        // No need to put this in the state, for performance reasons
        this.nextRowHover = null
        this.sectionRef = React.createRef()
    }

    componentDidMount() {
        if (this.sectionRef.current) {
            const paddingHeader = this.sectionRef.current.offsetWidth - this.sectionRef.current.clientWidth
            this.setState({paddingHeader})
        }
    }

    componentDidUpdate = (prevProps) => {
        const { parseRecipe, editing } = this.props

        if (parseRecipe !== prevProps.parseRecipe || editing !== prevProps.editing) {
            this.setState({
                initialValues: recipeSectionsFormInitialValues(this.props.parseRecipe)
            })
        }
    }

    _onFieldFocus = () => this.setState({ fieldFocused: true })

    _onClearFocus = () => this.setState({ fieldFocused: false })

    _onFieldBlur = (event, setFieldTouched) => {
        this.setState({ fieldFocused: false })

        if (this.nextRowHover) {
            this.setState({ rowHover: this.nextRowHover })
            this.nextRowHover = null
        }

        setFieldTouched(event.target.name)
    }

    _onRowHover = (component, index, parentIndex = null) => {
        if (!this.state.fieldFocused) {
            this.setState({ rowHover: { component, index, parentIndex } })
        } else {
            this.nextRowHover = { component, index, parentIndex }
        }
    }

    _onRowBlur = () => {
        if (!this.state.fieldFocused) {
            this.setState({ rowHover: null })
        } else {
            this.nextRowHover = null
        }
    }

    _onDeleteHover = (component, index, parentIndex = null) => this.setState({ deleteHover: { component, index, parentIndex } })

    _onDeleteBlur = () => this.setState({ deleteHover: null })

    _onKeyUp = (event, setFieldTouched) => setFieldTouched(event.target.name)

    /** Calculation stuff will go here */
    _handleComputation = (values, setFieldValue, sectionIndex = null, stepIndex = null, ingredientIndex = null) => {
        computeRecipeDataOnFieldChange(values, sectionIndex, stepIndex, ingredientIndex)
        for (const key of ["sections", "cost", "netWeight", "asp"]) {
            setFieldValue(key, values[key])
        }
    }

    _checkChanges = (current, previousProps, setFieldValue, validateForm) => {
        if (! previousProps || ! previousProps.values) {
            return
        }

        const previous = previousProps.values

        if (! current || ! previous || ! current.sections || ! previous.sections) {
            return
        }

        // Check for sections length diff
        if (current.sections.length !== previous.sections.length) {
            this._handleComputation(current, setFieldValue)
            validateForm()
            return
        }

        // Check for steps length diff
        let found = false
        for (let i = 0, l = current.sections.length ; i < l && !found ; i++) {
            if (current.sections[i].steps.length !== previous.sections[i].steps.length) {
                this._handleComputation(current, setFieldValue, i)
                validateForm()
                found = true
            }
        }

        if (found) {
            return
        }

        // Check for ingredients length and data diff
        for (let i = 0, l = current.sections.length ; i < l && !found ; i++) {
            for (let j = 0, ll = current.sections[i].steps.length ; j < ll && !found ; j++) {
                const currentIngredients = current.sections[i].steps[j].ingredients
                const previousIngredients = previous.sections[i].steps[j].ingredients

                if (currentIngredients.length !== previousIngredients.length) {
                    this._handleComputation(current, setFieldValue, i, j)
                    validateForm()
                    found = true
                } else {
                    for (let k = 0, lll = currentIngredients.length ; k < lll && !found ; k++) {
                        if (
                            currentIngredients[k].grossWeight !== previousIngredients[k].grossWeight ||
                            currentIngredients[k].cookingMode !== previousIngredients[k].cookingMode
                        ) {
                            this._handleComputation(current, setFieldValue, i, j, k)
                            validateForm()
                            found = true
                        }
                    }
                }
            }
        }
    }

    _onSubmit = (values) => {
        const { parseRecipe, onStopEdit, updateRecipeSections } = this.props
        
        updateRecipeSections(cloneDeep(values), parseRecipe).then(onStopEdit)
    }

    render () {
        const { rowHover, deleteHover, initialValues } = this.state
        const {
            classes, genericSections, bindSubmitAndResetForm,
            createReusableSection, editing, onEdit, parseRecipe
        } = this.props

        return initialValues && (
            <Formik
                initialValues={initialValues}
                validationSchema={RecipeSchema}
                onSubmit={this._onSubmit}
                validateOnChange={false}
                enableReinitialize
            >
                {({ values, errors, setFieldValue, setFieldError, setFieldTouched, submitForm, validateForm }) => {
                    bindSubmitAndResetForm(submitForm)
                    const previousProps = usePrevious({ values })

                    useLayoutEffect(() => this._checkChanges(values, previousProps, setFieldValue, validateForm))

                    return (
                        <>
                            {
                                !editing && (
                                    <div className={classes.header}>
                                        <p>{/* Here will be the last edition */}</p>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.editButton}
                                            endIcon={<Icon>edit</Icon>}
                                            onClick={onEdit}
                                        >
                                            Editer la recette
                                        </Button>
                                    </div>
                                )
                            }
                            <Form>
                                <Grid container className={`${classes.container} ${editing ? classes.marginTop : ""}`}>
                                    <Grid container item xs={12} className={classes.natHeader} style={{paddingRight: `${this.state.paddingHeader}px` }}>
                                        <Grid container item xs={5} className={classes.natBorder} alignItems="center">
                                            <Grid item xs={12} className={`${classes.natCell} ${classes.natHeaderInfos}`}>
                                                {
                                                    values.fcpct &&
                                                        <span>Foodcost: <span className={values.fcpct > 24 ? classes.redPCT : classes.greenPCT}>{values.fcpct}%</span>&nbsp;</span>
                                                }
                                                | Prix conseillé : <span className={classes.bold}>{values.asp}€</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={3} className={classes.natBorder} alignItems="center">
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>P. BRUT (KG)</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>PRIX / KG</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.pricePadding}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>PRIX</Grid>
                                                <Grid item xs={12} className={classes.bold}>{roundNumber(values.cost,2)}</Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={4} className={classes.natBorder} alignItems="center">
                                            <Grid container item xs={4} className={`${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding}`}>
                                                <Grid item xs={12} className={classes.natColumnTitle}>CUISSON</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid container item xs={4} className={`${classes.alignRight} ${classes.natCell}`} alignItems="flex-end">
                                                <Grid item xs={12} className={classes.natColumnTitle}>TRANSFO</Grid>
                                                <Grid item xs={12} className={classes.bold}>--</Grid>
                                            </Grid>
                                            <Grid container item xs={3} className={`${classes.alignRight} ${classes.natCell}`} alignItems="flex-end">
                                                <Grid item xs={12} className={classes.natColumnTitle}>P. NET (KG)</Grid>
                                                <Grid item xs={12} className={classes.bold}>{values.netWeight}</Grid>
                                            </Grid>
                                            <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}></Grid>
                                        </Grid>
                                    </Grid>
                                    <div className={editing ? classes.scrollContainerEditing : classes.scrollContainer} ref={this.sectionRef}>
                                        <Sections
                                            sections={values.sections}
                                            genericSections={genericSections}
                                            sectionUpdated={() => this._computeData(values)}
                                            editing={editing}
                                            onFieldFocus={this._onFieldFocus}
                                            onClearFocus={this._onClearFocus}
                                            onFieldBlur={(e) => this._onFieldBlur(e, setFieldTouched)}
                                            rowHover={rowHover}
                                            onRowHover={this._onRowHover}
                                            onRowBlur={this._onRowBlur}
                                            deleteHover={deleteHover}
                                            onDeleteHover={this._onDeleteHover}
                                            onDeleteBlur={this._onDeleteBlur}
                                            onKeyUp={(e) => this._onKeyUp(e, setFieldTouched)}
                                            setFieldValue={setFieldValue}
                                            setFieldError={setFieldError}
                                            errors={errors}
                                            setFieldTouched={setFieldTouched}
                                            isFocus={this.state.fieldFocused}
                                            createReusableSection={createReusableSection}
                                            computationHandler={
                                                (sectionIndex, stepIndex, ingredientIndex) =>
                                                    this._handleComputation(values, setFieldValue, sectionIndex, stepIndex, ingredientIndex)
                                            }
                                            status={parseRecipe.get("status")}
                                        />
                                    </div>
                                </Grid>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        )
    }
}

export default withStyles(RecipeStylesNew)(Recipe)
