import React, { useCallback, useEffect, useState } from "react"

import { ListItemText } from "@mui/material"
import { cloneDeep, debounce } from "lodash"

import { StyledAutocomplete, StyledAutocompleteTextField } from "../StyledSectionComponents"
import { computeRecipeData, computeSectionData, getDefaultReusableSteps, parseReusableProductionStepToObject } from "../../../../utils/recipes"
import { addDataToIndex, searchIndex, suggestIndex } from "./reusableStepsIndex"

const ReusableStepsAutocomplete = ({
  steps,
  index,
  sectionIndex,
  onFieldFocus,
  onFieldBlur,
  onKeyUp,
  setFieldValue,
  isReusable,
  allReusableSteps,
  onClearFocus,
  formValues,
  setValues,
  fromRecipe,
}) => {
  const [inputValue, setInputValue] = useState("")
  const [suggestions, setSuggestions] = useState([])

  // Populate the FlexSearch index on component mount
  useEffect(() => {
    addDataToIndex(allReusableSteps)
  }, [])

  const _stopPropagation = (event) => event && event.stopPropagation()

  const getFieldName = useCallback(
    (fieldName) => {
      if (isReusable) {
        return `productionSteps[${index}].${fieldName}`
      }
      return `sections[${sectionIndex}].productionSteps[${index}].step.${fieldName}`
    },
    [index, sectionIndex, isReusable]
  )

  const getReusableStepOptionLabel = (option) => {
    if (typeof option === "string") {
      return option
    }

    if (option.get) {
      return option.get("name") || option.get("description")
    }

    return option.name || option.description
  }

  // reusable step autocomplete change
  const handleReusableStepsSelection = (
    event,
    formValue,
    sectionIndex,
    stepIndex,
  ) => {
    if (!event) return
    let value = formValue
      if (value.get) {
        value = value.get("name") || value.get("description")
      } else {
        value = value.name || value.description
      }

    const newSteps = [...steps]

    newSteps[stepIndex].isEmpty = false

    // select existing reusable step
    const selectedReusableStep = allReusableSteps.find(
      (step) => (step.get ? step.get("name") : step.name) === value
    )
    if (selectedReusableStep) {
      newSteps[stepIndex].isReusable = true
      const newReusableStep = parseReusableProductionStepToObject(selectedReusableStep) || getDefaultReusableSteps()

      newSteps[stepIndex] = {
        step: newReusableStep,
        reusable: true,
        coeff: newReusableStep.netWeight,
        grossWeight: newReusableStep.grossWeight,
        netWeight: newReusableStep.netWeight,
        cost: newReusableStep.cost,
        realCost: newReusableStep.realCost,
        error: false,
      }

      // update current section
      const newFormValues = cloneDeep(formValues)
      const section = { ...newFormValues.sections[sectionIndex], productionSteps: newSteps }
      computeSectionData(section, "productionSteps", "stepComponents", true)
      newFormValues.sections[sectionIndex] = section
      if (fromRecipe) {
        computeRecipeData(newFormValues)
      }

      setValues(newFormValues)

      onClearFocus()
    }
  }
  
  
  // Debounced search handler
  const handleDebouncedSearch = useCallback(
    debounce(async (value) => {
      let searchResults = await searchIndex(value)
      if (searchResults.length <= 0) {
        // if no normal search results, search the auto corrected results
        searchResults = suggestIndex(value, allReusableSteps || [])
      } else {
        searchResults = searchResults.map((result) => result.doc)
      }

      // update suggestions list
      setSuggestions(searchResults)
    }, 500),
    []
  )

  // Handle search input changes
  const handleSearch = async (
    event,
    search,
    sectionIndex,
    stepIndex,
  ) => {
    // update local field value
    setInputValue(search)
    // update form level field value
    setFieldValue(`sections[${sectionIndex}].productionSteps[${stepIndex}].step.name`, search)
    // update suggestions
    await handleDebouncedSearch(search, stepIndex)
    _stopPropagation(event)
  }

  // change the reusable step name by the last step child name
  const handleNameBlur = (event) => {
    if (isReusable) {
      const lastStep = steps[steps.length - 1]
      const currentStep = steps[index]

      if (currentStep.name && lastStep?.index === currentStep.index) {
        const name = steps[index].name
        setFieldValue("name", name)
      }
    }

    if (!event) return
    onFieldBlur(event)
  }

  return (
    <StyledAutocomplete
      freeSolo
      disableClearable
      selectOnFocus
      handleHomeEndKeys
      // important to update the suggestions list for smart search
      filterOptions={(x) => x} 
      inputValue={inputValue}
      getOptionLabel={getReusableStepOptionLabel}
      options={suggestions}
      onChange={(event, newInputValue) => {
        handleReusableStepsSelection(
          event,
          newInputValue,
          sectionIndex,
          index,
        )
      }}
      onInputChange={(event, newInputValue) => {
        handleSearch(
          event,
          newInputValue,
          sectionIndex,
          index
        )
      }}
      renderInput={(params) => (
        <StyledAutocompleteTextField
          {...params}
          name={getFieldName("name")}
          onClick={_stopPropagation}
          onFocus={onFieldFocus}
          onBlur={handleNameBlur}
          onKeyUp={onKeyUp}
          variant="standard"
          fullWidth
        />
      )}
      renderOption={(props, item) => (
        <li {...props} key={item.objectId}>
          <ListItemText>{getReusableStepOptionLabel(item)}</ListItemText>
        </li>
      )}
    />
  )
}

export default ReusableStepsAutocomplete
