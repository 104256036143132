import React from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { Formik, Field } from "formik"
import moment from "moment"
import {COLORS, KFC_BRANDS} from "../../utils"
import { RecipeDownloadProductionSchema } from "../../utils/yupValidators"
import DatePickerField from "./DatePickerField"
import BrandSelect from "./BrandSelect"

export const ModalDownloadProduction = (props) => {
    const { classes, jsonRecipe, isOpen, handleClose, clickDownload } = props
    const name = jsonRecipe && jsonRecipe.name ? jsonRecipe.name : ""
    const productBrands = KFC_BRANDS.filter(kfc_brand => jsonRecipe.brands.includes(kfc_brand.name))

    return (
        <Dialog
            open={isOpen }
            onClose={handleClose}
            aria-labelledby="Modal-dialog-title"
        >
            <DialogTitle id="modal-download-production" onClose={handleClose}>
                {name}
            </DialogTitle>
            <Formik
                initialValues={{
                    volume: "",
                    productionDate: moment().utc().startOf("day"),
                    packagingDate: moment().utc().startOf("day"),
                    brand: productBrands.length > 0 ? productBrands[0].name : KFC_BRANDS[0].name
                }}
                validationSchema={RecipeDownloadProductionSchema}
                onSubmit={clickDownload}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent dividers>
                            <div className={classes.inputContainer}>
                                <TextField
                                    variant="standard"
                                    type="number"
                                    name="volume"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.volume}
                                    required
                                    label="Quantité"
                                />
                            </div>
                            {touched.volume && errors.volume ? (
                                <div className={classes.errorField}>{errors.volume}</div>
                            ) : null}

                            <div className={classes.inputContainer}>
                                <BrandSelect
                                    id="brand"
                                    name="brand"
                                    value={values.brand}
                                    onChange={handleChange}
                                    productBrands={productBrands}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <Field
                                    name="productionDate"
                                    component={DatePickerField}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <Field
                                    name="packagingDate"
                                    component={DatePickerField}
                                />
                            </div>
                        </DialogContent>

                        <DialogActions classes={{root: classes.rootDialogActions}}>
                            <Button
                                onClick={handleClose}
                            >
                                Annuler
                            </Button>

                            <Button
                                onClick={handleSubmit}
                                color="primary"
                                variant="contained"
                            >
                                Télécharger
                            </Button>
                        </DialogActions>
                    </form>
                    )}
            </Formik>

        </Dialog>
    )
}

const styles = {
    rootDialogActions: {
        justifyContent: "space-between"
    },
    inputContainer: {
        margin: 8
    },
    errorField: {
        color: COLORS.RED
    }
}

export default withStyles(styles)(ModalDownloadProduction)
