import {
    actionWithLoader,
    push,
    onEnter,
    getReplacingPath
} from "../../Utils/utils"
import { formatDispatchSitesForm, formatDistributionCenterWithDispatchSites } from "../../../utils/distributionCenter"
import {
    getPlanningsSettingsDistributionCentersList,
    getPlanningsSettingsDistributionCenterById,
    getOrCreatePlanningsSettingsDistributionCenterById,
    updateParseDistributionCenterFromJsonValue,
    saveDistributionCenter,
    setValueToParseObject
} from "../../../parseManager/planning/parsePlanningManager"
import { getSites, getSitesByIds } from "../../../parseManager/site/parseSiteManager"
import { loadProductTypeOptions } from "../../ProductTypes/productTypes"
import { WITH_MANAGEMENT_MODE_FILTER } from "../../../utils/productTypesUtils"

export const createNewDistributionCenter = (values) => {
    return actionWithLoader(async (dispatch) => {
        try {

            const parseDistributionCenter = await getOrCreatePlanningsSettingsDistributionCenterById()

            await updateParseDistributionCenterFromJsonValue(parseDistributionCenter, values)

            await parseDistributionCenter.save()

            await dispatch(showPlanningSettingsDistributionCenterView(parseDistributionCenter.id))
        }
        catch (e) {
            dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    message: "Une erreur est survenue lors de la sauvegarde du centre de distribution",
                    duration: 5000,
                    type: "error"
                }
            })
        }
    })
}

export const editDistributionCenterSettings = (distributionCenterId, values, editionType) => {
    return actionWithLoader(async (dispatch) => {
        try {
            let parseDistributionCenter = await getPlanningsSettingsDistributionCenterById(distributionCenterId, false)

            let needRefresh = false, needRefreshProductTypeOptions = false

            if (editionType === "resume") {
                parseDistributionCenter.set("name", values.name)
                parseDistributionCenter.set("externalId", values.externalId)
                parseDistributionCenter.set("brand", values.brand)
                parseDistributionCenter.set("sector", values.sector)
                parseDistributionCenter.set("type", values.type)
                parseDistributionCenter.set("packagingTypes", values.packagingTypes)
                parseDistributionCenter.set("isActive", !!values.isActive)
                parseDistributionCenter.set("national", !!values.national)
                parseDistributionCenter.set("onlyPreorders", !!values.onlyPreorders)
                needRefreshProductTypeOptions = true
            }

            if (editionType === "address") {
                const modifiedValues = values.address
                const addressFields = ["address", "zipCode", "city", "country", "complement", "latitude", "longitude"]
                for (const addressField of addressFields) {
                    const value = modifiedValues[addressField]
                    if (value !== null && value !== undefined) {
                        parseDistributionCenter.set(`address.${addressField}`, value)
                    }
                }
            }

            if (editionType === "openDate") {
                parseDistributionCenter.set("openDate", values.openDate)
            }

            if (editionType === "dispatchSites") {
                const dispatchSitesValues = values.dispatchSites || []
                const sitesIds = dispatchSitesValues.map(site => site.objectId)
                const sites = await getSitesByIds(sitesIds, [], false)
                const dispatchSites = dispatchSitesValues.map((site) => {
                    const siteObject = sites.find(s => s.id === site.objectId)
                    return {
                        priority: site.priority,
                        site: siteObject.toPointer()
                    }
                })
                parseDistributionCenter.set("dispatchSites", dispatchSites)
                needRefresh = true // to make the include work
            }

            if (editionType === "attachRates") {
                parseDistributionCenter.set("attachRates", values.attachRates)
            }

            if (editionType === "deliveryNote") {
                parseDistributionCenter.set("counterMark", values.counterMark)
                parseDistributionCenter.set("externalMail", values.externalMail)
                parseDistributionCenter.set("internalMail", values.internalMail)
                parseDistributionCenter.set("supplierCode", values.supplierCode)
            }

            await parseDistributionCenter.save()

            if (needRefresh) { // to make the include work
                parseDistributionCenter = await getPlanningsSettingsDistributionCenterById(distributionCenterId, false)
            }

            if (needRefreshProductTypeOptions) {
                const filters = { ...WITH_MANAGEMENT_MODE_FILTER, brands: [parseDistributionCenter?.get("brand")] }
                dispatch(loadProductTypeOptions(filters))
            }

            await dispatch({
                type: "SINGLE_DISTRIBUTION_CENTER_LOADED",
                selectedDistributionCenter: formatDistributionCenterWithDispatchSites(parseDistributionCenter.toJSON())
            })

            await dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    message: `Les informations de type ${editionType} ont été modifiées`,
                    duration: 5000,
                    type: "success"
                }
            })
        }
        catch (e) {
            dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    message: "Une erreur est survenue lors de la sauvegarde du centre de distribution",
                    duration: 5000,
                    type: "error"
                }
            })
        }
    })
}

export function addPhotoToDistributionCenter(id, image) {
    return actionWithLoader(async (dispatch) => {
        const parseDistributionCenter = await getPlanningsSettingsDistributionCenterById(id, false)
        if (!parseDistributionCenter) {
            await dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    message: "Le centre de distribution n'a pas pu être trouvé",
                    duration: 5000,
                    type: "error"
                }
            })
            return
        }
        setValueToParseObject(parseDistributionCenter, "image", image)
        await saveDistributionCenter(parseDistributionCenter)
        await dispatch(loadPlanningSettingsSingleDistributionCenter)
    })
}

export function removePhotoToDistributionCenter(id) {
    return actionWithLoader(async (dispatch) => {
        const parseDistributionCenter = await getPlanningsSettingsDistributionCenterById(id, false)
        if (!parseDistributionCenter) {
            await dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    message: "Le centre de distribution n'a pas pu être trouvé",
                    duration: 5000,
                    type: "error"
                }
            })
            return
        }
        setValueToParseObject(parseDistributionCenter, "image", null)
        await saveDistributionCenter(parseDistributionCenter)
        await dispatch(loadPlanningSettingsSingleDistributionCenter)
    })
}
export function loadPlanningSettingsSingleDistributionCenter(params) {
    return actionWithLoader(async (dispatch) => {
        const id = params ? params.id : ""
        const distributionCenter = await getPlanningsSettingsDistributionCenterById(id)
        const siteList = await getSites()

        if (distributionCenter) {
            if (distributionCenter.dispatchSites) {
                distributionCenter.dispatchSites.sort(function (a, b) {
                    return a.priority < b.priority ? -1 : 1
                })

                distributionCenter.dispatchSites = formatDispatchSitesForm(distributionCenter.dispatchSites)
            }
        }

        const filters = { ...WITH_MANAGEMENT_MODE_FILTER, brands: [distributionCenter?.brand] }
        dispatch(loadProductTypeOptions(filters))

        dispatch({
            type: "PLANNING_SETTINGS_SINGLE_DISTRIBUTION_CENTER_LOADED",
            selectedDistributionCenter: distributionCenter,
            siteList: siteList ? siteList.sites : []
        })
    })
}

export function loadPlanningSettingsDistributionCentersList() {
    return actionWithLoader(async (dispatch) => {
        const distributionCenters = await getPlanningsSettingsDistributionCentersList()
        dispatch({
            type: "PLANNING_SETTINGS_DISTRIBUTION_CENTERS_LIST_LOADED",
            distributionCenters: distributionCenters
        })
    })
}

export function onEnterPlanningSettingsDistributionCentersList(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningSettingsDistributionCentersList,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterPlanningSettingSingleDistributionCenter(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningSettingsSingleDistributionCenter,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function showPlanningSettingsDistributionCentersList() {
    return push("/settings/sites/distributionCenters/list")
}

export function showPlanningSettingsDistributionCenterView(id) {
    return push(`/settings/sites/distributionCenters/view/${id}`)
}
