import React from "react"
import DistributionCentersAttachRates from "./DistributionCentersAttachRates"
import DistributionCenterDispatchSites from "./DistributionCenterDispatchSites"
import DistributionCenterDeliveryNoteConfig from "./DistributionCenterDeliveryNoteConfig"
import { Grid, Box } from "@mui/material"

const DistributionCenterLogistics = ({ distributionCenter = {}, onEditAttachRates, onEditDispatchSites, onEditDeliveryNoteConfig }) => {

  return (
    <Box m={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DistributionCentersAttachRates
            attachRates={distributionCenter.attachRates}
            onEdit={onEditAttachRates}
          />
        </Grid>
        <Grid item xs={12}>
          <DistributionCenterDispatchSites
            dispatchSites={distributionCenter.dispatchSites}
            onEdit={onEditDispatchSites}
          />
        </Grid>
        <Grid item xs={12}>
          <DistributionCenterDeliveryNoteConfig
            distributionCenter={distributionCenter}
            onEdit={onEditDeliveryNoteConfig}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default DistributionCenterLogistics