import { orderMode } from "../../utils/ordersUtils"

const defaultState = {
    site: null,
    mode: orderMode.CREATE,
    selectedOrder: null,
    orders: [],
    data: null,
    suppliers: [],
    ordersSnackBar: { open: false, type: "message", message: "", duration: 1000 }
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "ORDERS_LOADED":
            return {
                ...state,
                site: action.site,
                suppliers: action.suppliers,
                orders: action.orders,
                supplierItems: action.supplierItems
            }
        case "ORDER_LOADED":
            return {
                ...state,
                site: action.site,
                data: action.data,
                selectedOrder: action.selectedOrder,
                suppliers: action.suppliers,
                mode: orderMode.UPDATE
            }
        case "NEW_ORDER_LOADED": {
            return {
                ...state,
                site: action.site,
                data: action.data,
                suppliers: action.suppliers,
                mode: orderMode.CREATE
            }
        }
        case "CLEAR_SELECTED_ORDER": {
            return {
                ...state,
                selectedOrder: action.selectedOrder
            }
        }
        case "ORDERS_OPEN_SNACKBAR": {
            return {
                ...state,
                ordersSnackBar: action.ordersSnackBar
            }
        }
        case "ORDERS_CLOSE_SNACKBAR": {
            return {
                ...state,
                ordersSnackBar: action.ordersSnackBar
            }
        }
        default:
            return {
                ...state
            }
    }
}

