import React from "react"
import { StyledContainer } from "../preview/StyledExecutionPreviewComponents"
import { Stack, Typography } from "@mui/material"
import ProductionStepExecutionCookingEndForm from "./ProductionStepExecutionCookingEndForm"

const ProductionStepExecutionCookingFormContainer = ({
	productionStepExecution,
	visible = true,
	machinesOptions,
	handleSubmit,
	updateErrorMessage
}) => {

	if (!visible) {
		return null
	}

	return (
		<StyledContainer sx={{
			display: "flex",
			flexDirection: "column",
			gap: "24px"
		}}>
			<Stack direction="row" gap={1}>
				<img src="/img/recipes/cookingPan.svg" alt="cookingPan" />
				<Typography sx={{
					color: "#262626",
					fontSize: 14,
					fontWeight: 500,
					lineHeight: "22px"
				}}>
					Cuisson
				</Typography>
			</Stack>
			<ProductionStepExecutionCookingEndForm
					machinesBatch={productionStepExecution.machinesBatch}
					machinesOptions={machinesOptions}
					onSubmit={handleSubmit}
					onError={updateErrorMessage}
				/>
		</StyledContainer>)
}

export default ProductionStepExecutionCookingFormContainer