import React from "react"
import Paper from "@mui/material/Paper"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import {
    origins,
    conservationModes, labels
} from "../../utils/commercialNamesUtils"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        paddingBottom: 40,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: "20px 18px 16px 28px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    singleValueContainer: {
        display: "flex",
        paddingLeft: 28,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 18,
        justifyContent: "space-between"
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: "auto",
        maxWidth: 137,
        display: "flex",
        alignItems: "center"
    },
    value: {
        width: "auto",
        wordWrap: "break-word",
        textAlign: "right"
    }
}))

const SupplierItemSettingSourcingValue = (props) => {
    const {
        supplierItem
    } = props
    const classes = useStyles()

    const commercialName = supplierItem.commercialName

    const conservationModeLabel = commercialName
        ? (conservationModes.find(elem => elem.value === commercialName.conservationMode))
        : ""

    const firstOriginLabel = commercialName
        ? (origins.find(elem => elem.value === commercialName.firstOrigin) || "")
        : ""
    const secondOriginLabel = commercialName
        ? (origins.find(elem => elem.value === commercialName.secondOrigin) || "")
        : ""
    const firstOriginAverage = commercialName ? `${commercialName.firstOriginAverage}%` : ""
    const secondOriginAverage = commercialName ? `${commercialName.secondOriginAverage}%` : ""
    const labelObject = commercialName ? labels.find(label => label.value === commercialName.label) : null

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Valeurs de sourcing
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Mode de conservation
                </div>
                <span className={classes.value}>
                    {
                        conservationModeLabel?.label
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Provenance 1
                </div>
                <span className={classes.value}>
                    {
                        firstOriginLabel?.label
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Moyenne de Provenance 1
                </div>
                <span className={classes.value}>
                    {
                        firstOriginAverage
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Provenance 2
                </div>
                <span className={classes.value}>
                    {
                        secondOriginLabel?.label
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Moyenne de Provenance 2
                </div>
                <span className={classes.value}>
                    {
                        secondOriginAverage
                    }
                </span>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Label
                </div>
                <span className={classes.value}>
                    {labelObject && labelObject.label}
                </span>
            </div>
        </Paper>
    )
}

export default SupplierItemSettingSourcingValue