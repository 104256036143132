const defaultState = {
    allergensIngredients: [],
    commercialsNames: [],
    subcontractorsCommercialsNames: []
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "CLASSIFICATIONS_ALLERGENS_INGREDIENTS_LOADED":
            return {
                ...state,
                allergensIngredients: action.allergensIngredients,
                commercialsNames: action.commercialsNames,
                subcontractorsCommercialsNames: action.subcontractorsCommercialsNames
            }
        case "CLASSIFICATIONS_ALLERGEN_INGREDIENT_SAVED":
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getAllergenIngredient(state, id) {
    return state.allergensIngredients.allergensIngredients.find(allergenIngredient => allergenIngredient.objectId === id)
}
