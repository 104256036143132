import { getServerUrl } from "../utils"
import { downloadFile } from "../actions/Utils/utils"
import moment from "moment"

export async function downloadZipPdf(date, type, sourceType) {
    const fileName = getFileName(date, "", type, true)
    const url = `${getServerUrl()}/kitchen/downloadZipPdf?date=${date}&type=${type}&sourceType=${sourceType}`
    downloadFile(url, fileName)
}

export async function downloadSinglePdf(date, type, sourceType, idProduct, itemSource) {
    const url = `${getServerUrl()}/kitchen/downloadSinglePdf?date=${date}&type=${type}&sourceType=${sourceType}&idProduct=${idProduct}&itemSource=${itemSource}`
    downloadFile(url, "")
}

export async function downloadHeadPage(date, type, format = "pdf") {
    const fileName = type === "KITCHEN"
        ? `(cuisine) Page de garde ${moment(date).format("DD-MM-YYYY")}.${format}`
        :`(barquettage) Page de garde ${moment(date).format("DD-MM-YYYY")}.${format}`
    const url = `${getServerUrl()}/kitchen/downloadHeadPage?date=${date}&type=${type}&format=${format}`

    downloadFile(url, fileName)
}
function getFileName(date, name, type, isZip) {
    const extension = isZip
        ? ".zip"
        : ".pdf"
    let str =  type === "KITCHEN"
        ? `(cuisine) Fiche_de_prod_${moment(date).format("DD-MM-YYYY")}`
        : `(barquettage) Fiche_de_barquettage_${moment(date).format("DD-MM-YYYY")}`

    if (name) {
        str += `-${name}`
    }
    if (isZip) {
        str = str.replace("Fiche", "Fiches")
    }
    return str + extension
}
