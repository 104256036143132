import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { FieldArray, Formik, Form } from "formik"
import * as Yup from "yup"
import { isEqual } from "lodash"
import { TableHead, TableBody, TableCell, Table, TableRow, Select, MenuItem, Stack, Checkbox } from "@mui/material"

import FormikErrorMessage from "../../components/FormikErrorMessage"
import ProductionStepExecutionStatus from "./ProductionStepExecutionsStatus"
import { formatProductionStepsHoursEditInitialValues, PRODUCTION_STEP_EXECUTION_STATUSES } from "../../utils/productionStepExecution"
import { getProductionStepsExecutionsSelector } from "../../reducers/Production/ProductionSteps"
import { saveProductionStepsExecutionsEditedHours, showProductionStepsSupervision } from "../../actions/ProductionSteps/ProductionStepExecutions"
import HoursMaskedField from "../../components/form/MaskedField"

const formId = "productionStepsHoursEdit"

const tableHeaders = [
	{
		key: "theoreticalTime",
		label: "Heures théoriques",
	},
	{
		key: "name",
		label: "Nom de l'étape"
	},
	{
		key: "transformationType",
		label: "Type d'étape"
	},
	{
		key: "machine",
		label: "Machine"
	},
	{
		key: "section",
		label: "Section"
	},
	{
		key: "recipe",
		label: "Recette concernée"
	},
	{
		key: "status",
		label: "État de l'étape"
	}
]

// schema
const productionStepExecutionsHoursValidationSchema = Yup.object().shape({
	productionStepsExecutions: Yup.array().of(
		Yup.object().shape({
			theoreticalStartTime: Yup.string().validPEPSETime("L'heure doit être au format hh:mm"),
			theoreticalEndTime: Yup.string().validPEPSETime("L'heure doit être au format hh:mm"),
			machine: Yup.string(),
		})
	)
})

const ProductionStepsHoursEdit = () => {
	const dispatch = useDispatch()

	const productionStepExecutions = useSelector(getProductionStepsExecutionsSelector)
	const [productionStepExecutionsInitialValues, setProductionStepExecutionsInitialValues] = useState([])

	useEffect(() => {
		const initialValues = formatProductionStepsHoursEditInitialValues(productionStepExecutions)
		setProductionStepExecutionsInitialValues(initialValues)
	}, [productionStepExecutions])

	const _renderStatus = (productionStep) => {
		const status = productionStep.status
		const statusName = PRODUCTION_STEP_EXECUTION_STATUSES.find(({ value }) => value === status)?.name
		return <ProductionStepExecutionStatus statusValue={status} statusName={statusName} />
	}

	const _isProductionStepExecutionDirty = (initialValues, productionStepExecution) => {
		const productionStepExecutionFromInitialValues = initialValues.productionStepsExecutions.find(({ objectId }) => objectId === productionStepExecution.objectId)
		return !isEqual(productionStepExecution, productionStepExecutionFromInitialValues)
	}

	const handleSubmit = async (values) => {
		const formattedValues = values.productionStepsExecutions
			.filter(productionStepExecution => _isProductionStepExecutionDirty(productionStepExecutionsInitialValues, productionStepExecution))
			.map((productionStepExecution) => {
				return {
					objectId: productionStepExecution.objectId,
					theoreticalStartTime: productionStepExecution.theoreticalStartTime,
					theoreticalEndTime: productionStepExecution.theoreticalEndTime,
					machines: productionStepExecution.machines
				}
			})

		if (formattedValues.length) {
			await dispatch(saveProductionStepsExecutionsEditedHours(formattedValues))
		}
		dispatch(showProductionStepsSupervision())
	}

	return (
		<Formik
			initialValues={productionStepExecutionsInitialValues}
			validationSchema={productionStepExecutionsHoursValidationSchema}
			initialTouched={{}}
			enableReinitialize
			validateOnBlur={false}
			onSubmit={handleSubmit}
		>
			{({ values, touched, handleChange }) => {
				// the submit button is in the header component where we use the "formId" attribute to trace which form is being submitted
				return (
					<Form id={formId}>
						<Table>
							<TableHead>
								{tableHeaders.map(({ key, label }) => (
									<TableCell key={key}>{label}</TableCell>
								))}
							</TableHead>
							<TableBody>
								<FieldArray name="productionStepsExecutions">
									{() => (
										<>
											{values.productionStepsExecutions?.map((_, index) => (
												<TableRow key={index}>
													<TableCell>
														<Stack direction="row" spacing={1} alignItems="center">
															<Stack>
																<HoursMaskedField
																	name={`productionStepsExecutions.${index}.theoreticalStartTime`}
																	value={values.productionStepsExecutions[index].theoreticalStartTime}
																	onChange={handleChange}
																/>
																{touched?.productionStepsExecutions?.[index]?.theoreticalStartTime && <FormikErrorMessage name={`productionStepsExecutions.${index}.theoreticalStartTime`} />}
															</Stack>
															<div>-</div>
															<Stack>
																<HoursMaskedField
																	name={`productionStepsExecutions.${index}.theoreticalEndTime`}
																	value={values.productionStepsExecutions[index].theoreticalEndTime}
																	onChange={handleChange}
																/>
																{touched?.productionStepsExecutions?.[index]?.theoreticalEndTime && <FormikErrorMessage name={`productionStepsExecutions.${index}.theoreticalEndTime`} />}
															</Stack>
														</Stack>
													</TableCell>
													<TableCell>
														{values.productionStepsExecutions[index].name}  {/** not editable */}
													</TableCell>
													<TableCell>
														{values.productionStepsExecutions[index].transformationType}   {/** not editable */}
													</TableCell>

													<TableCell>
														{productionStepExecutions[index].machinesOptions?.length > 0 && (
															<Select
																multiple
																name={`productionStepsExecutions.${index}.machines`}
																value={values.productionStepsExecutions[index].machines}
																onChange={handleChange}
																variant="standard"
																sx={{ minWidth: 200 }}
																renderValue={(selected) => selected.map((selectedValue) => productionStepExecutions[index].machinesOptions.find(machine => machine.objectId === selectedValue).name).join(", ")}
															>
																{productionStepExecutions[index].machinesOptions.map(machine => (
																	<MenuItem key={machine.objectId} value={machine.objectId}>
																		<Checkbox checked={values.productionStepsExecutions[index].machines.indexOf(machine.objectId) > -1} />
																		{machine.name}
																	</MenuItem>
																))}
															</Select>
														)}
													</TableCell>

													<TableCell>
														{values.productionStepsExecutions[index].section}   {/** not editable */}
													</TableCell>
													<TableCell>
														{values.productionStepsExecutions[index].recipe}    {/** not editable */}
													</TableCell>
													<TableCell>
														{_renderStatus(values.productionStepsExecutions[index])}    {/** not editable */}
													</TableCell>
												</TableRow>
											))}
										</>
									)}
								</FieldArray>
							</TableBody>
						</Table>
					</Form>)
			}}
		</Formik>
	)
}

export default ProductionStepsHoursEdit