import React from "react"
import TableHead from "@mui/material/TableHead"
import Tooltip from "@mui/material/Tooltip"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableCell from "@mui/material/TableCell"
import Checkbox from "@mui/material/Checkbox"
import TableRow from "@mui/material/TableRow"
import PropTypes from "prop-types"

const rows = [
    { id: "id", numeric: false, disablePadding: true, label: "ID" },
    { id: "name", numeric: false, disablePadding: true, label: "Nom" },
    { id: "family", numeric: false, disablePadding: false, label: "Famille d'ingrédient" },
    { id: "createdAt", numeric: true, disablePadding: false, label: "Date de création" },
    { id: "updatedAt", numeric: true, disablePadding: false, label: "Date de modification" },
]

const GroupIngredientEnhancedTableHead = (props) =>  {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort
    } = props

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        color="secondary"
                    />
                </TableCell>
                {rows.map(row => {
                    if (row.id !== "id"){
                        return (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? "right" : "left"}
                                padding={row.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Filtrer"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                    disableInteractive
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={(event) => onRequestSort(event, row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        )
                    }
                    return null
                    },
                    this,
                )}
            </TableRow>
        </TableHead>
    )
}

GroupIngredientEnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

export default GroupIngredientEnhancedTableHead
