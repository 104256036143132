import React from "react"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { COLORS } from "../../utils"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import ReceptionWarehouseHeaderTitle from "./ReceptionWarehouseHeaderTitle"
import { getSitesSelector } from "../../reducers/Site/sites"
import ReceptionWarehouseFilterForm from "./ReceptionWarehouseFilterForm"
import { filterReceptionWarehouse } from "../../actions/ReceptionWarehouse/receptionWarehouse"

const ReceptionWarehouseFilter = () => {
    const dispatch = useDispatch()
    const sites = useSelector(getSitesSelector)

    const onConfirm = (values) => {
        dispatch(filterReceptionWarehouse(values))
    }

    return (
        <div className="flexColumn stretchSelf flex1">
            <GenericTopBarHeader
                title={<ReceptionWarehouseHeaderTitle />}
                className="stretchSelf"
            />
            
            <Box className="flexCenter stretchSelf flex1" sx={{ color: COLORS.GREY_SUPPLIER_BACKGROUND, flexGrow: 1  }}>
                <ReceptionWarehouseFilterForm
                    sites={sites}
                    onSubmit={onConfirm}
                />
            </Box>
        </div>
    )
}

export default ReceptionWarehouseFilter