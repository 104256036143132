import Parse from "parse"

export const ReusableProductionStep = Parse.Object.extend("ReusableProductionStep")

export const getReusableProductionSteps = async (include = [
	"productionSteps.kitchenArea",
	"productionSteps.machineType",
	"productionSteps.stepComponents.supplierItem",
	"productionSteps.stepComponents.transformationMode",
	"productionSteps.stepComponents.transformRate"
]) => {
	try {
		const reusableProductionsSteps = await new Parse.Query(ReusableProductionStep)
		.notEqualTo("deleted", true)
		.descending("updatedAt")
		.include(include).find() || []

		return reusableProductionsSteps.map((step) => step.toJSON())
	}
	catch (e) {
		console.error("parseManager.recipe.parseReusableProductionStepsManager.getReusableProductionSteps error : ", e)
		return Promise.reject(e)
	}
}

export const getReusableProductionStep = async (id, toJson = true) => {
	try {
		const reusableProductionStep = await new Parse.Query(ReusableProductionStep)
			.equalTo("objectId", id)
			.first()

		if (!reusableProductionStep) return null

		const reusableProductionStepJson = reusableProductionStep.toJSON()
		const productionSteps = []
		// for some unknown and strange cache (?) issue, we should query separately the production step
		for (const productionStep of reusableProductionStep.get("productionSteps")) {
			const parseProductionStep = await new Parse.Query("ProductionStep")
				.equalTo("objectId", productionStep.id)
				.include([
					"kitchenArea",
					"machineType",
					"stepComponents.supplierItem",
					"stepComponents.transformationMode",
					"stepComponents.priorSteps.stepComponents.transformationMode",
					"stepComponents.priorSteps.stepComponents.supplierItem",
				])
				.first()

			productionSteps.push(parseProductionStep.toJSON())
		}

		return toJson
			? { ...reusableProductionStepJson, productionSteps: productionSteps }
			: reusableProductionStep
	}
	catch (e) {
		console.error("parseManager.recipe.parseReusableProductionStepsManager.getReusableStep error : ", e)
		return Promise.reject(e)
	}
}

export const getReusableProductionStepByIdProductionStepList = async (idProductionStepList) => {
	try {
		const reusableProductionSteps = await new Parse.Query(ReusableProductionStep)
			.containedIn("productionSteps.objectId", idProductionStepList)
			.find() || []


		return reusableProductionSteps.map((step) => step.toJSON())
	}
	catch (e) {
		console.error("parseManager.recipe.parseReusableProductionStepsManager.getReusableProductionStepByIdProductionStepList error : ", e)
		return Promise.reject(e)
	}
}
