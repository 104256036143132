import React, { Fragment } from "react"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import { Link, ListItemButton } from "@mui/material"
import { COLORS } from "../utils"

const sx = {
    link: {
        color: COLORS.BLACK,
        display: "flex",
        width: "100%"
    }
}

const MenuIconAndText = (props) => {
    const {
        label,
        icon: MenuIcon,
        isVisible = true,
        button = true,
        upper = false,
        url,
        onClick,
        selected
    } = props

    const _onClick = () => {
        onClick && onClick()
    }

    if (!isVisible) return null

    const menuContent = (
        <Fragment>
            <ListItemIcon>
                <MenuIcon />
            </ListItemIcon>
            <ListItemText primary={upper ? label.toUpperCase() : label} />
        </Fragment>
    )

    return (
        <>
            {
                url
                    ? (
                        <ListItem button={button}>
                            <Link sx={sx.link} href={url} underline="hover">
                                {menuContent}
                            </Link>
                        </ListItem>
                    ) : (
                        <ListItemButton onClick={_onClick} selected={selected}>
                            {menuContent}
                        </ListItemButton>
                    )
            }
        </>
    )
}

export default MenuIconAndText