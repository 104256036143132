import React from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { Formik, Form } from "formik"
import { TextField, FormControl, InputAdornment } from "@mui/material"
import { getDistributionCenterProductTypes, getInitialAttachRates } from "../../../../../utils/distributionCenter"
import { getProductTypeOptionsSelector } from "../../../../../reducers/ProductTypes/productTypes"

export const distributionCenterAttachRatesFormId = "distributionCenterAttachRatesFormId"

const DistributionCenterAttachRatesForm = ({ distributionCenter, handleSubmit }) => {

  const productTypeOptions = useSelector(getProductTypeOptionsSelector)

  const formatAttachRates = (value) => {
    if (!value.match(new RegExp(/^\d*(\.\d{0,2})?$/))) value = (value && value.slice(0, -1)) || 0
    return value
  }


  const attachRatesChange = (event, setFieldValue, type) => {
    const newValue = formatAttachRates(event.target.value)
    setFieldValue(`attachRates.${type.value}`, newValue)
  }


  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        attachRates: distributionCenter.attachRates || getInitialAttachRates(productTypeOptions)
      }}>
      {({ values, handleBlur, setFieldValue }) => (
        <Form
          id={distributionCenterAttachRatesFormId}
        >
          {
            getDistributionCenterProductTypes(productTypeOptions).map((type, j) =>
              <div key={j}>
                <FormControl variant="standard" sx={{width: "100%"}}>
                  <TextField
                    variant="standard"
                    name={`attachRates.${type.value}`}
                    value={values.attachRates && values.attachRates[type.value]}
                    label={`${type.label}`}
                    type="number"
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                    }}
                    onChange={(e) => { attachRatesChange(e, setFieldValue, type) }}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </div>
            )
          }
        </Form>
      )}
    </Formik>
  )
}

export default DistributionCenterAttachRatesForm