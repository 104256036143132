import { createGlobalStyle } from "styled-components"
import {COLORS} from "../../utils"

// another adjustment from orginal theme
export default createGlobalStyle`
  body {
	  header.MuiAppBar-root {
	    font-family: Roboto;
	    font-style: normal;
	    font-weight: normal;
	    font-size: 16px;
	    line-height: 24px;
	    background-color: ${COLORS.WHITE};
	    color: ${COLORS.DARK_GREY};
	    
	    /* border */
	    border: 1px solid ${COLORS.MEDIUM_GREY};
	    box-sizing: border-box;
	    box-shadow: none;
	    
	    button span {
	        text-transform: none;
	        font-size: 16px;
	        font-weight: normal;
	    }
	  }
	  
      .action-button {
        max-width: 448px;
        width: 86%;
        height: 72px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        border-radius: 0;
      }
      
      // disabled button
      .MuiButtonBase-root:disabled {
         cursor: not-allowed;
         pointer-events: none;
         opacity: 0.5;
      }
      
      /* .MuiIconButton-root:hover {
        background-color: transparent;
      } */
  }
`