import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"

const AllergensIngredients = Parse.Object.extend("AllergensIngredients")

export async function getAllergensIngredients (includes = []) {
    try {
        const allergensIngredients = await new Parse.Query(AllergensIngredients)
            .ascending("name")
            .include(includes)
            .limit(parseLimitRequest)
            .find()

        return allergensIngredients ? allergensIngredients.map(el => el.toJSON()) : []
    }
    catch (e) {
        console.error("parseManager.ingredients.classifications.allergen.parseAllergenManager.getAllergensIngredients error : ", e)
        return Promise.reject(e)
    }
}

export async function getAllergensWithIds(ids, includes = [], toJson = true) {
    try {
        const allergens = (await new Parse.Query(AllergensIngredients)
            .ascending("name")
            .containedIn("objectId", ids)
            .include(includes)
            .limit(parseLimitRequest)
            .find()) || []
        return toJson ? allergens.map(elem => elem.toJSON()) : allergens
    } catch(err) {
        console.error("parseManager.ingredients.classifications.allergen.parseAllergenManager.getAllergensWithIds : ", err)
        return Promise.reject(err)
    }
}

export async function createAllergenIngredient(allergenIngredient, values) {
    try {
        let parseAllergenIngredient

        if (!allergenIngredient) {
            parseAllergenIngredient = new AllergensIngredients()
        }
        else {
            parseAllergenIngredient = await new Parse.Query(AllergensIngredients)
                .equalTo("objectId", allergenIngredient.objectId)
                .first()
        }

        if (parseAllergenIngredient){
            Object.keys(values).forEach( function(key) {
                const val = values[key]
                if (val !== null){
                    parseAllergenIngredient.set(key, val)
                }
            })

            await parseAllergenIngredient.save()

            return parseAllergenIngredient.toJSON()
        }
    }
    catch (e) {
        console.error("parseManager.ingredient.classifications.allergen.parseAllergenManager.createAllergenIngredient error : ", e)
        return Promise.reject(e)
    }
}

export async function getAllergensSearch(name, ids, includes = [], toJson = true) {
    try {
        const allergens = (await new Parse.Query(AllergensIngredients)
            .contains("name", name)
            .notContainedIn("objectId", ids)
            .include(includes)
            .limit(parseLimitRequest)
            .find()) || []

        return toJson
            ? allergens.map(elem => elem.toJSON())
            : allergens

    } catch(err) {
        console.error("parseManager.ingredient.classifications.allergens.parseAllergenManager.getAllergensSearch : ", err)
        return Promise.reject(err)
    }

}
