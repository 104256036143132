import React from "react"
import createStyles from "@mui/styles/createStyles"
import makeStyles from "@mui/styles/makeStyles"
import Typography from "@mui/material/Typography"
import moment from "moment"

import { COLORS } from "../../utils"

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            display: "flex",
            color: COLORS.BLACK_CARD,
            fontWeight: "bold",
            fontSize: 32,
        },
        subTitle: {
            marginLeft: 22,
            fontWeight: 500,
            fontSize: 16
        },
        typeAndDlc: {
            fontWeight: "normal",
            fontSize: 12,
            "& span": {
                paddingRight: 14,
                textTransform: "uppercase"
            }
        }
    }),
)

const ActionHeaderText = (props) => {
    const { selectedCard, productTypeOptions } = props
    const classes = useStyles()
    const dlc = moment(selectedCard.dlc).isSame(new Date(), "year") ? moment.utc(selectedCard.dlc).format("DD/MM") : moment.utc(selectedCard.dlc).format("DD/MM/YY")
    const productType = productTypeOptions.find(option => option.type === selectedCard.productType)?.label

    return (
        <>
            <Typography className={classes.title}>
                { selectedCard.uniqueCode }
            </Typography>
            <div className={classes.subTitle}>
                <div>{selectedCard.commercialTitle}</div>
                <div className={classes.typeAndDlc}>
                    <span>{productType}</span>DLC: {dlc}
                </div>
            </div>
        </>
    )
}

export default ActionHeaderText