import React, { useState } from "react"
import {
    Select,
    Checkbox,
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import Button from "@mui/material/Button"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import pull from "lodash/pull"

const useStyles = makeStyles(() => ({
    container: {
        color: COLORS.BLACK,
        boxShadow: "0 0 3px rgba(0,0,0,.2)",
        backgroundColor: "white",
        padding: "15px 30px 25px 30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: 237,
        width: 474
    },
    title: {
        fontSize: 20,
        fontFamily: "Roboto",
        fontWeight: 500
    },
    filterContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    siteSelector: {
        marginTop: 5,
        width: 130
    },
    buttonBloc: {
        marginTop: 21,
    },
    submitButton: {

        width: 328,
        height: 38,
        borderRadius: 0
    },
    errorLabel: {
        textAlign: "center",
        marginTop: 15,
        color: COLORS.RED
    },
}))

const BillingCreditNotesSiteSelector = (props) => {
    const {
        sites = [],
        onSubmit
    } = props

    const classes = useStyles()
    const [selectedSites, setSelectedSites] = useState(sites.map(site => site.objectId))
    const isAllSitesSelected = selectedSites.length === sites.length
    const [showError, setShowError] = useState(false)

    const onChange = e => {
        let values = e.target.value
        //---- ALL ----//
        if (values.includes("ALL") && !isAllSitesSelected) {
            values = sites.map(site => site.objectId)
        } else if (isAllSitesSelected && !values.includes("ALL")) {
            values = []
        }
        pull(values, "ALL")
        setSelectedSites(values)
    }

    const handleConfirm = () => {
        if (!selectedSites || selectedSites.length === 0) {
            setShowError(true)
        } else {
            onSubmit(selectedSites)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                Sélectionnez le(s) site(s)
            </div>
            <div className={classes.filterContainer}>
                <InputLabel>Site(s) de réception</InputLabel>
                <Select
                    variant="standard"
                    multiple
                    className={classes.siteSelector}
                    labelId="select-status"
                    id="select-status"
                    value={isAllSitesSelected ? ["ALL", ...selectedSites] : selectedSites}
                    onChange={onChange}
                    autoWidth
                    displayEmpty
                    renderValue={(filteredSites) => {
                        if (filteredSites.includes("ALL")) {
                            return "Tous"
                        } else if (!filteredSites.length) {
                            return "Aucun"
                        }
                        return filteredSites
                            .map((filteredSite) =>
                                sites.find((site) => filteredSite === site.objectId).name
                            )
                            .join(", ")
                    }}
                >
                    <MenuItem value={"ALL"}>
                        <Checkbox
                            checked={isAllSitesSelected}
                            color="secondary"
                        />
                        Tous
                    </MenuItem>
                    {sites.map((site, index) => (
                        <MenuItem key={index} value={site.objectId}>
                            <Checkbox
                                checked={
                                    selectedSites.includes(site.objectId)
                                }
                                color="secondary"
                            />
                            {site.name}
                        </MenuItem>
                    ))}
                </Select>
                {
                    showError &&
                    <div className={classes.errorLabel}>
                        Vous devez sélectionner au moins un site
                    </div>
                }
            </div>
            <div className={classes.buttonBloc}>
                <Button
                    className={classes.submitButton}
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleConfirm}
                >
                    Accéder au contrôle facturation
                </Button>
            </div>

        </div>
    )
}

export default BillingCreditNotesSiteSelector