import { actionWithLoader, onEnter, push } from "../Utils/utils"
import { createProductionSubstitution, loadProductionSubstitutions } from "../../parseManager/productionSubstitutions/parseProductionSubstitutionsManager"
import dayjs from "dayjs"
import { getRawMaterialSupplierItems } from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

export const showProductionSubstitutions = () => {
	return push("/productionSubstitutions")
}


export const loadProductionSubstitutionsData = () => {
	return async dispatch => {
		const [productionSubstitutions, supplierItems] = await Promise.all([
			loadProductionSubstitutions({
				filters: {},
				includes: ["originalSupplierItem", "replacementSupplierItem", "recipes"],
				selects: [],
				toJSON: true
			}),
			getRawMaterialSupplierItems({ select: ["name"] })
		])
		const supplierItemsOptions = supplierItems.map(supplierItem => ({
			key: supplierItem.objectId,
			value: supplierItem.name,
			label: supplierItem.name
		}))
		dispatch({
			type: "PRODUCTION_SUBSTITUTIONS_LOADED",
			productionSubstitutions
		})
		dispatch({
			type: "PRODUCTION_SUBSTITUTIONS_SUPPLIER_ITEMS_OPTIONS_LOADED",
			supplierItemsOptions
		})
	}
}

export const saveProductionSubstitution = (values, showSnackbar = true) => {
	return actionWithLoader(async (dispatch) => {
		try {
			await createProductionSubstitution({
				originalSupplierItemId: values.originalSupplierItem,
				replacementSupplierItemId: values.replacementSupplierItem,
				productionStepExecutionsIds: values.productionStepExecutions,
				recipesIds: values.recipes,
				startDate: values.startDate,
				endDate: values.endDate,
				usernameId: values.currentUserId
			})
			if (showSnackbar) {
				dispatch({
					type: "PRODUCTION_SUBSTITUTION_SNACKBAR_LOADED",
					snackbar: { open: true, type: "success", message: `Le remplacement d’article a bien été programmé pour les productions du ${dayjs.utc(values.startDate).format("DD/MM/YYYY")} au ${dayjs.utc(values.endDate).format("DD/MM/YYYY")}.`, duration: 5000 }
				})
			}
		}
		catch (e) {
			dispatch({
				type: "PRODUCTION_SUBSTITUTION_SNACKBAR_LOADED",
				snackbar: { open: true, type: "error", message: "Une erreur est survenue lors de la création de la substitution", duration: 5000 }
			})
		}
	})
}

export const closeProductionSubstitutionSnackbar = (currentType) => {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "PRODUCTION_SUBSTITUTION_SNACKBAR_LOADED",
			snackbar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}


export const onEnterProductionSubstitutions = (store) => {
	return onEnter({
		store,
		actionThunk: loadProductionSubstitutionsData
	})
}


