import React, { useEffect, useRef } from "react"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"
import BrandsSelect from "../../Recipe/BrandsSelect"
import { ACTIVE_KFC_BRANDS } from "../../../utils"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/DeleteSweep"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import moment from "moment"
import { planningBrands } from "../../../utils/planning"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: 10,
        zIndex: 1000,
        marginLeft: 30,
        "& > *": {
            marginLeft: 10
        }
    },
    searchTextField: {
        minWidth: 190
    },
    datePicker: {
        minWidth: 140,
        maxWidth: 170
    },
    selectTypes: {
        minWidth: 120
    },
    logo: {
        width: 16,
        marginBottom: -4
    },
    resetButton: {
        paddingBottom: 5
    },
    chip: {
        height: "auto",
    },
    inputLabel: {
        fontSize: "1em"
    }
})

const PlanningProductionPackagingFilters = (props) => {
    const {
        onSearchValueChange,
        types,
        filteredType,
        onTypesChange,
        date,
        onDateChange,
        searchFilter,
        brands,
        onBrandsChange,
        saleDate,
        onSaleDateChange
    } = props

    const classes = useStyles()

    const searchRef = useRef()

    useEffect(() => {
        searchRef.current.value = searchFilter || ""
    }, [])

    const showResetButton = date.valueOf() !== moment.utc().startOf("day").valueOf() ||
        filteredType !== "MAIN_COURSE" ||
        searchRef.current.value !== "" ||
        (onBrandsChange && brands !== planningBrands) ||
        (onSaleDateChange && saleDate)

    const resetFilter = () => {
        // We do not reset type and date filters
        if (searchRef.current.value !== "") {
            searchRef.current.value = ""
            onSearchValueChange({ target: { value: "" } })
        }
        if (onBrandsChange && brands !== planningBrands) onBrandsChange({ target: { value: planningBrands } })
        if (onSaleDateChange && saleDate) onSaleDateChange(null)
    }

    return (
        <span>
            <div className={classes.container}>
                <DatePicker
                    disableCloseOnSelect={false}
                    showToolbar={false}
                    variant="inline"
                    value={date}
                    onChange={onDateChange}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} variant="standard" className={classes.datePicker} />}
                />
                <TextField
                    variant="standard"
                    inputRef={searchRef}
                    onChange={onSearchValueChange}
                    label="Rechercher par nom ou code"
                    className={classes.searchTextField}
                    InputLabelProps={{
                        classes: { root: classes.inputLabel }
                    }}
                />
                <FormControl className={classes.formControl} variant="standard">
                    <InputLabel>Types</InputLabel>
                    <Select
                        variant="standard"
                        className={classes.selectTypes}
                        name="type"
                        placeholder="Type de produit"
                        value={filteredType}
                        multiple={false}
                        onChange={onTypesChange}
                        displayEmpty
                        renderValue={selected => selected
                            ? selected.map(elem => elem.label).join(", ")
                            : types.find(section => section.key === "MAIN_COURSE")?.label
                        }
                    >
                        {
                            types.filter(section => section.type !== "ALL").map(elem => {
                                return (
                                    <MenuItem key={elem.label} value={elem}>
                                        <ListItemText primary={elem.label} />
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                {
                    brands &&
                    <BrandsSelect
                        id="brands"
                        name="brands"
                        value={brands}
                        onChange={onBrandsChange}
                        productBrands={[{ label: "Toutes", name: "ALL" }, ...ACTIVE_KFC_BRANDS]}
                        classes={classes}
                        noLabel={true}
                    />
                }
                {
                    onSaleDateChange &&
                    <DatePicker
                        disableCloseOnSelect={false}
                        showToolbar={false}
                        variant="inline"
                        value={saleDate}
                        onChange={onSaleDateChange}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => <TextField {...params} variant="standard" label="Jour de vente" className={classes.datePicker} />}
                    />
                }
                {
                    showResetButton &&
                    <IconButton className={classes.resetButton} onClick={resetFilter} size="large">
                        <DeleteIcon />
                    </IconButton>
                }
            </div>
        </span>
    )
}

export default PlanningProductionPackagingFilters
