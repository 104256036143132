import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import CheckBox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import Chip from "@mui/material/Chip"
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const InternalTagMultiSelect = (props) => {
    const { productsTags, classes, value,
        name= "tags", id= "tags", label = "Tags",
        ...otherProps } = props

    const withoutRulesTags = productsTags.filter(elem => !elem.get("rules") || elem.get("rules").length === 0)

    return (
        <FormControl className={classes.formControl} variant="standard">
            <InputLabel>{label}</InputLabel>
            <Select
                variant="standard"
                name={name}
                id={id}
                multiple
                fullWidth
                renderValue={selected => (
                    <div className={classes.chips}>
                        {selected.map(value => {
                            const tag = productsTags.find(elem => elem.id === value)

                            return <Chip
                                key={value}
                                label={tag.get("name")}
                                className={classes.chip}
                            />
                        })}
                    </div>
                )}
                MenuProps={MenuProps}
                value={value}
                {...otherProps}
            >
                {
                    withoutRulesTags.map((elem, index) =>
                        <MenuItem key={index} value={elem.id}>
                            <CheckBox color="secondary" checked={value.indexOf(elem.id) > -1}/>
                            <ListItemText primary={elem.get("name")} />
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default InternalTagMultiSelect
