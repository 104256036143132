import React from "react"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../utils"
import makeStyles from "@mui/styles/makeStyles"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  root: {
    color: COLORS.DARK_GREY,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    width: 106,
    height: 96,
    border: `1px solid ${COLORS.GREY_BORDER}`,
    boxSizing: "border-box",
    borderRadius: 10,
    marginRight: 15,
    justifyContent: "flex-end",
    "&.is-tablet": {
      height: 134,
    },
    "&.last": {
      marginRight: 0,
    },
    "&.selected": {
      border: `2px solid ${COLORS.BLACK_CARD}`,
    },
  },
  text: {
    fontWeight: "normal",
    fontSize: 16,
    color: COLORS.BLACK_CARD,
    marginBottom: 9,
    "&.is-tablet": {
      marginBottom: 16,
    },
  },
}))

const CardFlaw = (props) => {
  const { text, icon, selected, onCauseSelected, isTablet } = props
  const classes = useStyles()

  const onClick = () => {
    onCauseSelected(text)
  }

  return (
    <Paper
      className={clsx(
        classes.root,
        { "is-tablet": isTablet },
        { selected: selected }
      )}
      onClick={onClick}
      elevation={0}
    >
      {icon}
      <div className={clsx(classes.text, { "is-tablet": isTablet })}>
        {text}
      </div>
    </Paper>
  )
}

export default CardFlaw
