import React, { useState } from "react"
import NewsForm from "./NewsForm"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.BLUEGREY_700,
        borderRadius: 5,
        paddingBottom: 40,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
    },
    emptyContainer: {
        composes: "$container",
        backgroundColor: COLORS.WHITE
    },
    filledContainer: {
        composes: "$container",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: "20px 32px 16px 32px",
        fontWeight: 500,
        fontSize: 16,
        color: COLORS.WHITE
    },
    emptyTitle: {
        composes: "$title",
        color: COLORS.BLACK
    },
    news: {
        paddingLeft: 32,
        color: COLORS.WHITE
    }
}))

const News = (props) => {
    const {
        supplierItem,
        onUpdateSupplierItem,
        canEdit
    } = props

    const classes = useStyles()

    const [openEditModal, setOpenEditModal] = useState(false)

    const handleCloseModal = () => {
        setOpenEditModal(false)
    }

    return (
        <Paper className={ supplierItem.news ? classes.filledContainer : classes.emptyContainer }>
            <div className={classes.titleContainer}>
                <div className={ supplierItem.news ? classes.title : classes.emptyTitle }>
                    Remarques et actualité
                </div>
                {
                    canEdit &&
                        <NewEditIcon
                            onClick={setOpenEditModal}
                        />
                }
            </div>
            <p className={classes.news}>
                {supplierItem.news}
            </p>
            {
                openEditModal
                ? <Dialog
                    open={openEditModal}
                    onClose={handleCloseModal}
                    aria-labelledby="form-dialog-title"
                >
                    <NewsForm
                        supplierItem={supplierItem}
                        onClose={handleCloseModal}
                        onUpdateSupplierItem={onUpdateSupplierItem}
                    />
                </Dialog>
                : null
            }
        </Paper>
    )
}

export default News