import React from "react"
import RoundCheckBoxIcon from "../RoundCheckBoxIcon"

const SendingOrderCheck = props => {
    const {
        label,
        checked,
        classes
    } =  props

    return (
        <>
            <div className={classes.checkBoxLabel}>{label}</div>
            <RoundCheckBoxIcon
                checked={checked}
                withMargin
            />
        </>
    )
}

export default SendingOrderCheck