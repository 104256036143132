import { makeStyles } from "@mui/styles"
import React from "react"
import {
	Formik,
	Form,
	Field
} from "formik"
import FormikCommercialNameAutocomplete from "../autoComplete/FormikCommercialNameAutoComplete"
import { TextField } from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import Button from "@mui/material/Button"
import { getIngredientWithId } from "../../parseManager/ingredients/internal/parseCommercialNameManager"
import { SupplierItemHeaderSchema } from "../../utils/yupValidators"
import { COLORS } from "../../utils"
import { headerInitialValues } from "../../utils/supplierItemUtils"
import Checkbox from "@mui/material/Checkbox"

const useStyles = makeStyles(() => ({
	container: {
		fontSize: 20,
		width: "auto",
		minWidth: 423,
		height: 428,
		padding: 20
	},
	title: {
		margin: "10px 0",
		fontSize: 20
	},
	formControl: {
		width: 200
	},
	form: {
		height: "93%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between"
	},
	inputsContainer: {
		display: "flex",
		flexDirection: "column"
	},
	family: {
		marginTop: 20
	},
	group: {
		marginTop: 20
	},
	allergens: {
		marginTop: 20
	},
	allergen: {
		marginRight: 10
	},
	buttonsContainer: {
		padding: "32px 12px",
		width: "100%",
		display: "flex",
		justifyContent: "space-between"
	},
	errorField: {
		color: COLORS.RED,
		fontSize: 14
	},
	checkboxContainer: {
		marginTop: 20,
		display: "flex",
		alignItems: "center"
	},
	checkboxTitle: {
		fontWeight: 400,
		fontSize: 16
	}
}))

const SupplierItemSubstitutionForm = (props) => {
	const {
		supplierItem,
		onUpdateSupplierItem,
		onClose
	} = props

	const classes = useStyles()

	const initialValues = headerInitialValues(supplierItem)

	const submitForm = async (values) => {
		onUpdateSupplierItem(values)
		onClose()
	}

	const _onCommercialNameChange = async (value, setFieldValue) => {
		if (value && value.objectId && value.label) {
			const newCommercialName = await getIngredientWithId(value.objectId, ["group.family", "allergens"], true)
			setFieldValue("commercialName", newCommercialName)

			if (newCommercialName.group) {
				setFieldValue("family", newCommercialName.group.family.name)
				setFieldValue("group", newCommercialName.group.name)
			}
		}
		else {
			setFieldValue("group", "")
			setFieldValue("family", "")
			setFieldValue("type", "")
		}
	}

	return (
		<div className={classes.container}>
			<p className={classes.title}>Utilisation sur les fiches recettes</p>
			<Formik
				initialValues={initialValues}
				onSubmit={submitForm}
				validationSchema={SupplierItemHeaderSchema}
			>
				{({
					values,
					handleSubmit,
					handleChange
				}) => {
					return (
						<Form className={classes.form}>
							<div className={classes.inputsContainer}>
								<Field
									name="commercialName"
									component={FormikCommercialNameAutocomplete}
									onCommercialNameChange={_onCommercialNameChange}
								/>
								<TextField
									variant="standard"
									name="ingredientFamily"
									value={values.family}
									label="Famille d'ingrédients"
									className={classes.family}
									disabled
								/>
								<TextField
									variant="standard"
									name="ingredientGroup"
									value={values.group}
									label="Groupe d'ingrédients"
									className={classes.group}
									disabled
								/>
								<div className={classes.checkboxContainer}>
									<FormControlLabel
										control={<Checkbox checked={values.useOnRecipe} />}
										label="Ajout possible sur une fiche recette"
										name="useOnRecipe"
										value={values.useOnRecipe}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className={classes.buttonsContainer}>
								<Button
									onClick={onClose}
								>
									Annuler
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									onClick={handleSubmit}
								>
									Valider
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}

export default SupplierItemSubstitutionForm