import React from "react"
import { withStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"

const styles = {
    addButtonContainer: {
        width: "100%",
        height: 48
    },
    addButton: {
        float: "right",
        marginRight: 16
    }
}

export const AddButton = ({onClick, classes}) => {
    return (
        <div className={classes.addButtonContainer}>
            <IconButton
                aria-label="Créer un groupe"
                className={classes.addButton}
                onClick={onClick}
                size="large">
                <AddIcon/>
            </IconButton>
        </div>
    )
}

export default withStyles(styles)(AddButton)
