import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { red } from "@mui/material/colors"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"
import Dialog from "@mui/material/Dialog"
import SupplierItemSubstitutionForm from "./SupplierItemSubstitutionForm"
import {getSupplierItemWithId} from "../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import {showSupplierItem} from "../../actions/Supplier/supplierItems"
import {useDispatch} from "react-redux"
import {getLabelIndex} from "../../utils/supplierItemUtils"

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    paddingBottom: 40,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: "20px 32px 16px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
  },
  useTransformTrue: {
    fontWeight: "bold",
    color: COLORS.GREEN_400,
  },
  useTransformFalse: {
    fontWeight: "bold",
    color: COLORS.RED,
  },
  supplierItemContainer: {
    paddingBottom: 30
  },
  singleValueContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
  },
  substitutionItemLabel: {
    fontWeight: "bold",
    cursor: "pointer",
    color: COLORS.LIGHT_BLUE_SLIDER
  },
  valueLabel: {
    color: COLORS.BLUEGREY_700,
    width: 300,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
  },
  stateValue: {
    textTransform: "upperCase",
  },
  activeStateValue: {
    color: COLORS.GREEN_LOT_INVENTORY_TEXT,
  },
  inactiveStateValue: {
    color: red[400],
  },
}))

const SupplierItemSettingSubstitution = (props) => {
  const {
    supplierItem,
    getSupplierItemList,
    canEdit
  } = props

  const dispatch = useDispatch()
  const classes = useStyles()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [supplierItemList, setSupplierItemList] = useState([])

  const handleCloseModal = async () => {
    setOpenEditModal(false)
  }

  const onUpdateSubstitutionList = async (supplierItemUpdated) => {
    const parseSupplierItem = await getSupplierItemWithId(supplierItemUpdated.objectId, [], false)
    const substitutionItemList = []
    for (const item of supplierItemUpdated.substitutionList) {
      const substitutionItem = await getSupplierItemWithId(item.objectId, [], false)
      substitutionItemList.push({
        "supplierItem": substitutionItem,
        "useTransform": !!item.useTransform,
        "label": item.label,
        "objectId": item.objectId
      })
    }

    parseSupplierItem.set("substitutionList", substitutionItemList)
    await parseSupplierItem.save()
  }

  const handleOpeningModal = async () => {
    const rawMaterialSupplierItemList = await getSupplierItemList()
    setSupplierItemList(rawMaterialSupplierItemList)
    setOpenEditModal(!openEditModal)
  }

  const navigateToSupplierItem = async (supplierItem) => {
    await dispatch(showSupplierItem(supplierItem.objectId))
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Articles de substitution</div>
        {canEdit && <NewEditIcon onClick={handleOpeningModal} />}
      </div>
      {
        supplierItem.substitutionList && supplierItem.substitutionList.map((substitutionItem, i) => {
          const useTransformClass = substitutionItem.useTransform ? "useTransformTrue" : "useTransformFalse"
          const labelIndex = getLabelIndex(i)
          return (
            <div key={i} className={classes.supplierItemContainer}>
              <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>{labelIndex} article de substitution</div>
                <span className={classes.substitutionItemLabel} onClick={() => navigateToSupplierItem(substitutionItem)}>{substitutionItem.label}</span>
              </div>
              <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>Utilisation des modes de transformation de l'article principal</div>
                <span className={classes[useTransformClass]}>{substitutionItem.useTransform ? "Oui" : "Non"}</span>
              </div>
            </div>
          )
        })
      }
      {
        openEditModal
          ? <Dialog
            open={openEditModal}
            onClose={handleCloseModal}
            aria-labelledby="form-dialog-title"
          >
            <SupplierItemSubstitutionForm
              supplierItem={supplierItem}
              supplierItemList={supplierItemList}
              onUpdateSubstitutionList={onUpdateSubstitutionList}
              onClose={handleCloseModal}
            />
          </Dialog>
          : null
      }
    </Paper>
  )
}

export default SupplierItemSettingSubstitution
