import React from "react"
import { styled } from "@mui/material"
import { COLORS } from "../../utils"
import clsx from "clsx"

const StyledToolbar = styled("div")({
    position: "fixed",
    height: 64,
    zIndex: 300,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: COLORS.WHITE,
    "& > span:first-child": {
        overflowX: "scroll",
        overflowY: "hidden",
        flex: 2
    },
    "&.flex3 > span:first-child": {
        flex: 3
    },
    "& > span:nth-child(2)": {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 30
    }
})

const StyledToolbarMenu = styled(StyledToolbar, {
    shouldForwardProp: (prop) => prop !== "open"
  })((props) => ({
    width: props.open ? "calc(100% - 239px)" : "calc(100% - 54.4px)",
    top: 64
  }))

const PlanningToolbar = (props) => {
    const {
        children,
        menuIsOpen,
        isFlex3 = false,
        className
    } = props

    return (
        <StyledToolbarMenu
            open={menuIsOpen}
            className={clsx(isFlex3 ? "flex3" : "", className)}
            display="flex"
        >
            {children}
        </StyledToolbarMenu>
    )
}

export default PlanningToolbar