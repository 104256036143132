import PropTypes from "prop-types"

import React from "react"

import { FormLabel } from "@mui/material"

import FormikTextArea from "../Recipe/FormikTextArea"
import FormikCheckboxSelectField from "./FormikCheckboxSelectField"
import FormikSelectField from "./FormikSelectField"
import FormikTextField from "./FormikTextField"
import FormikErrorMessage from "../FormikErrorMessage"

const CustomFormikField = ({ form, field, ...rest }) => {

  const { type, options = [], selectType = "none" } = rest || {}
  const {
    showError = false,
    showLabel = false,
    label = null,
    labelClassName = "",
    errorClassName = ""
  } = rest || {} // style and layout

  const dynamicStyles = rest.dynamicStyles

  let usedComponent = null

  switch (type) {
    case "select":
      if (!options || !options.length) {
        console.error("CustomFormikField: if type === select, you want to pass options as an array")
      }
      if (selectType === "checkbox") {
        usedComponent = <FormikCheckboxSelectField
          {...{
            dynamicStyles,
            form,
            field
          }}
          {...rest}
        />
      }
      else {
        usedComponent = <FormikSelectField
          {...{
            dynamicStyles,
            form,
            field
          }}
          {...rest}
        />
      }
      break
    case "textarea":
      usedComponent = <FormikTextArea
        {...{
          dynamicStyles,
          form,
          field
        }}
        {...rest}
      />
      break
    // @todo add autocomplete, dateRange, singleDate, simple checkbox, switch, setc when the opportunity arises
    default:
      usedComponent = <FormikTextField
        {...{
          dynamicStyles,
          form,
          field
        }}
        {...rest}
      />

      break
  }

  return (
    <>
      {label && showLabel &&
        <FormLabel className={labelClassName}>
          {label}
        </FormLabel>
      }
      {usedComponent}
      {showError && <FormikErrorMessage name={field.name} errorClassName={errorClassName} />}
    </>
  )
}

CustomFormikField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  selectType: PropTypes.oneOf(["checkbox", "radio"]),
  options: PropTypes.array,
  containerClassName: PropTypes.object,
  labelClassName: PropTypes.object,
  errorClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  dynamicStyles: PropTypes.object
}

export default CustomFormikField