import React, {useState} from "react"
import { withStyles} from "@mui/styles"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import CommercialNamesSeasonEdit from "./CommercialNamesSeasonEdit"

import { COLORS, roundNumber } from "../../utils"
import { mapSeasonsToPeriodsLabels } from "../../utils/recipes"

export const SubcontractorProductResume = (props) => {
    const {
        classes,
        jsonSubcontractorProduct,
        parseSubcontractorProduct,
        onSaveCommercialNames,
        checkProductInCard,
        showModalProductInCard
    } = props

    const [isEditing, setIsEditing] = useState(false)

    const seasonsPeriodsLabels = jsonSubcontractorProduct && Array.isArray(jsonSubcontractorProduct.season)
        ? mapSeasonsToPeriodsLabels(jsonSubcontractorProduct.season)
        : []

    const commercialNames = jsonSubcontractorProduct && Array.isArray(jsonSubcontractorProduct.subcontractorCommercialNames)
        ? jsonSubcontractorProduct.subcontractorCommercialNames.map(elem => elem.commercialName).filter(Boolean)
        : []

    const commercialNamesValues = jsonSubcontractorProduct && Array.isArray(jsonSubcontractorProduct.subcontractorCommercialNames)
        ? jsonSubcontractorProduct.subcontractorCommercialNames.sort((a, b) => b.pourcentage - a.pourcentage)
            .map(elem => {
                const name = elem.commercialName && elem.commercialName.name
                    ? elem.commercialName.name
                    : ""
                return name + " (" + elem.pourcentage + "%)"
            }).join(", ")
        : ""
    const commercialNamesLabel = commercialNamesValues.includes(", ") ? "Ingrédients" : "Ingrédient"
    const allergens = commercialNames.map(elem => elem.allergens).flat().filter(Boolean)
    const allergensNames = Array.from(new Set(allergens.map(allergen => allergen.name).filter(Boolean)))
    const allergensValues = allergensNames.join(", ")
    const allergensLabel = "Allergène".concat(allergensNames.length > 1 ? "s" : "")
    const netWeight = jsonSubcontractorProduct && jsonSubcontractorProduct.name && jsonSubcontractorProduct.name.units && jsonSubcontractorProduct.name.units.conversions && jsonSubcontractorProduct.name.units.conversions[0]
        ? roundNumber(jsonSubcontractorProduct.name.units.conversions[0].value * 1000, 0)
        : null

    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }
    
    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Résumé de la recette
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    Poids net
                </div>
                <div className={classes.valueContainer}>
                    {netWeight}&nbsp;g
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                        Saison
                </div>
                <div className={classes.valueContainer}>
                       {
                           seasonsPeriodsLabels.map((label, index) =>
                               <div key={index}>{label}</div>
                           )
                       }
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    {commercialNamesLabel}
                </div>
                <div className={classes.valueContainer}>
                    {commercialNamesValues}
                </div>
            </div>
            <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>
                    {allergensLabel}
                </div>
                <div className={allergensValues.length ? classes.valueContainer : classes.greyValueContainer}>
                    {
                        allergensValues.length
                            ? allergensValues
                            : "Pas d'allergène"
                    }
                </div>
            </div>
            <CommercialNamesSeasonEdit
                jsonSubcontractorProduct={jsonSubcontractorProduct}
                parseSubcontractorProduct={parseSubcontractorProduct}
                onClose={() => setIsEditing(false)}
                isEditing={isEditing}
                onSaveCommercialNames={onSaveCommercialNames}
            />
        </Paper>
    )
}

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 5,
        marginTop: 16,
        paddingBottom: 16,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)"
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: 16,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    singleValueContainer: {
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 16,
        display: "flex"
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: 128,
        minWidth: 128,
        display: "flex",
        alignItems: "center"
    },
    valueContainer: {
    },
    greyValueContainer: {
        composes: "$valueContainer",
        color: COLORS.DISABLED
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    }
}

export default withStyles(styles)(SubcontractorProductResume)
