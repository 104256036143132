import Parse from "parse"

const Suppliers = Parse.Object.extend("Suppliers")

export async function getSuppliers({ includes = [], selects = [], sortBy = "name", sortDirection = "asc", toJSON = false } = {}) {
    try {
        const query = new Parse.Query(Suppliers)

        if (includes?.length) {
            query.include(includes)
        }

        if (selects?.length) {
            query.select(selects)
        }

        if (sortDirection === "desc") {
            query.descending(sortBy)
        } else {
            query.ascending(sortBy)
        }

        const suppliers = await query.limit(3000).find()

        return toJSON ? (suppliers ? suppliers.map(el => el.toJSON()) : []) : suppliers
    } catch (e) {
        console.error("parseManager.products.resources.supplier.parseSupplierManager.getSuppliers error : ", e)
        return []
    }
}

export async function getSupplierWithId(id, includes = [], toJson = true) {
    try {
        const supplier = new Parse.Query(Suppliers)
            .include(includes)
            .equalTo("objectId", id)
            .first()

        return toJson
            ? supplier && supplier.toJSON()
            : supplier
    } catch (err) {
        console.error("parseManager.products.resources.supplier.parseSupplierManager.getASupplier : ", err)
        return Promise.reject
    }
}
