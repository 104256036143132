import Parse from "parse"
import moment from "moment"

const Locker = Parse.Object.extend("Locker")
const ProductStock = Parse.Object.extend("ProductsStock")

export async function getDispatchLocker(idProduct, type="dispatch") {
		try {
			if (type === "dispatch") {
				return await new Parse.Query("Locker")
					.equalTo("idProductDispatch", idProduct)
					.equalTo("isOver", false)
					.notEqualTo("userId", String(Parse.User.current().id))
					.first()
			}
			
			if (type === "productStock") {
				return await new Parse.Query("LockerStock")
					.equalTo("productStock", ProductStock.createWithoutData(idProduct))
					.equalTo("isOver", false)
					.notEqualTo("user", Parse.User.current())
					.first()
			}
		}
		catch (e) {
				return Promise.reject(e)
		}
}

export async function createDispatchLocker(idProductDispatch) {
		try {
				console.log("[Dispatch Picking] : create locker for idProductDispatch = " + idProductDispatch + " with user = " + Parse.User.current().id + " at " + moment().valueOf())

				const locker = new Locker()

				locker.set("startedAt", moment().valueOf())
				locker.set("isOver", false)
				locker.set("type", "dispatch")
				locker.set("idProductDispatch", idProductDispatch)
				locker.set("userId", String(Parse.User.current().id))

				await locker.save()
		}
		catch (e) {
				return Promise.reject(e)
		}
}

export async function freeLocker(idProduct, type="dispatch") {
		try {
			let locker
			if (type === "dispatch") {
				locker = await new Parse.Query("Locker")
					.equalTo("idProductDispatch", idProduct)
					.equalTo("userId", String(Parse.User.current().id))
					.equalTo("isOver", false)
					.first()
			}
			
			if (type === "productStock") {
				locker = await new Parse.Query("LockerStock")
					.equalTo("productStock", ProductStock.createWithoutData(idProduct))
					.equalTo("user", Parse.User.current())
					.equalTo("isOver", false)
					.descending("createdAt")
					.first()
			}
			
				if (locker) {
						locker.set("isOver", true)
						await locker.save()
				}
		}
		catch (e) {
				return Promise.reject(e)
		}
}
