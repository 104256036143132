import React, { useEffect, useRef, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import clsx from "clsx"

const styleButton = {
  color: COLORS.WHITE,
  backgroundColor: COLORS.BROWN,
}

const useStyles = makeStyles({
  withSelection: {
    fontSize: 27,
    fontWeight: "normal",
    lineHeight: "32px",
    marginTop: 93,
    "&.is-tablet": {
      marginTop: 47,
    },
    "& p": {
      margin: 0,
    },
    "& span": {
      fontWeight: "bold",
    },
    "& .hand": {
      fontSize: 20,
      width: 20,
      paddingLeft: 7,
    },
    "& .is-inventory": {
      padding: 25,
      maxWidth: 500,
    },
  },
  validateButton: {
    ...styleButton,
    marginTop: 40,
    "&:hover, &:disabled, &:disabled:hover": {
      ...styleButton,
    },
  },
  input: {
    fontWeight: "normal",
    fontSize: 60,
    textAlign: "right",
    color: COLORS.BLACK_CARD,
    lineHeight: "70px",
    width: 102,
    marginTop: 37,
    padding: "0 12px",
  },
})

const ConfirmCardNumber = (props) => {
  const { selectedCard, handleValidate, isTablet, setValue, isInventory } =
    props
  const classes = useStyles()
  const inputRef = useRef()

  const [disabled, setDisabled] = useState(true)

  useEffect(() => inputRef.current.focus(), [])

  const enableInput = (e) => {
    setDisabled(e.target.value === "")
    setValue(e.target.value)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection, { "is-tablet": isTablet })}>
        <p>
          <span>Vérification</span>
        </p>
        {!isInventory && (
          <p>
            Recomptez la quantité de <span>{selectedCard.uniqueCode}</span>{" "}
            reçues
            <span role="img" aria-label="please" className="hand">
              🙏
            </span>
          </p>
        )}
        {isInventory && (
          <p className={"is-inventory"}>
            Vous allez inventorier plus de produits{" "}
            <span>{selectedCard.uniqueCode}</span> que ceux que vous avez
            réceptionnés. Etes-vous sûr ?
          </p>
        )}
      </div>

      {/* text summary */}
      <TextField
        variant="standard"
        inputProps={{
          className: classes.input,
          placeholder: "000",
          type: "number",
          pattern: "[0-9]*",
        }}
        onChange={enableInput}
        inputRef={inputRef}
      />

      {/* button submit */}
      <Button
        className={clsx("action-button", classes.validateButton)}
        variant="contained"
        disableElevation
        disabled={disabled}
        type="submit"
      >
        confirmer
      </Button>
    </form>
  )
}

export default ConfirmCardNumber
