import { DEFAULT_FILTERS } from "../../utils/constant"

const defaultState = {
    productType: null,
    productTypes: [],
    productTypeOptions: [], // for static data to database refactoring
    filters: DEFAULT_FILTERS,
    total: 0,
    ordersSnackBar: { open: false, type: "message", message: "", duration: 1000 },
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // load data list
        case "PRODUCT_TYPES_LOADED":
            return {
                ...state,
                productTypes: action.productTypes,
            }
        // load single data
        case "PRODUCT_TYPE_LOADED":
            return {
                ...state,
                productType: action.productType,
            }
        // load data list
        case "PRODUCT_TYPE_OPTIONS_LOADED":
            return {
                ...state,
                productTypeOptions: action.productTypeOptions,
            }
        // clear states
        case "CLEAR_PRODUCT_TYPES": {
            return {
                ...state,
                productTypes: []
            }
        }
        case "CLEAR_PRODUCT_TYPE_OPTIONS": {
            return {
                ...state,
                productTypeOptions: []
            }
        }
        case "CLEAR_PRODUCT_TYPE": {
            return {
                ...state,
                productType: null
            }
        }
        case "PRODUCT_TYPES_FILTERS_LOADED":
            return {
                ...state,
                filters: action.filters,
            }
        case "PRODUCT_TYPES_TOTAL_LOADED":
            return {
                ...state,
                total: action.total,
            }
        default:
            return {
                ...state
            }
    }
}

// ---------------------------------------- //
// --------------- SELECTOR --------------- //
// ---------------------------------------- //
export const getProductTypesSelector = (state) => {
    return state.productTypes.productTypes
}

export const getProductTypeOptionsSelector = (state) => {
    return state.productTypes.productTypeOptions
}

export const getProductTypeSelector = (state) => {
    return state.productTypes.productType
}

export const getProductTypesFiltersSelector = (state) => {
    return state.steps.filters
}

export const getProductTypesTotalSelector = (state) => {
    return state.steps.total
}
