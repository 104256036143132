import { Add } from "@mui/icons-material"
import { Box, Fab } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import PackagingLineFormDialog from "./PackagingLineFormDialog"
import { createPackagingLine } from "../../../actions/Site/sites"
import DataGrid from "../../DataGrid/DataGrid"

const columns = [
  {
    key: "id",
    label: "ID",
    dataType: "text",
    sortable: false,
    filter: {
        filterable: true,
        type: "text",
        strict: true
    },
    width: 120
  },
  {
    key: "name",
    label: "Nom",
    dataType: "text",
    sortable: false,
    filter: {
        filterable: true,
        type: "text",
        fullText: true
    },
  },
]

const sx = {
  addButton: {
    position: "absolute",
    left: 30,
    bottom: 30
  }
}

const PackagingLine = ({ siteId, packagingLines }) => {
  const [openModalForm, setOpenModalForm] = useState(false)
  const [data, setData] = useState([])

  const dispatch = useDispatch()

  const toggleEditTransformationMode = () => setOpenModalForm(!openModalForm)

  useEffect(() => {
    setData(packagingLines.map((packagingLine) => ({
      id: packagingLine.objectId,
      name: packagingLine.name,
    })))
  }, [packagingLines])

  const handleSubmitResume = async (values) => {
    await dispatch(createPackagingLine(values, siteId))
    toggleEditTransformationMode()
  }

  return (
    <Box sx={{ px: 2, py: 5 }}>
      <DataGrid
        title="Lignes de barquettage"
        columns={columns}
        data={data}
        withFilters
        rowLabel="lignes de barquettage"
      />
      {/* add button */}
      <Box sx={sx.addButton}>
          <Fab color="primary" onClick={toggleEditTransformationMode}>
              <Add />
          </Fab>
      </Box>

      {/* dialog form */}
      <PackagingLineFormDialog
        open={openModalForm}
        onClose={toggleEditTransformationMode}
        onSave={handleSubmitResume}
      />
    </Box>
  )
}

export default PackagingLine