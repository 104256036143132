import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Box, Modal, styled } from "@mui/material"
import CloudinaryImage from "../Image/CloudinaryImage"
import { getScreen } from "../../reducers/Utils/app"


const CenteredModal = styled(Modal)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
})


const CommentsImagePreview = ({ imageId, open, onClose }) => {

	const screen = useSelector(getScreen)

	const windowWidth = window.innerWidth
	const windowHeight = window.innerHeight

	const imageWidth = useMemo(() => {
		return windowWidth - (56 * 2)
	}, [screen, windowHeight, windowWidth])

	const imageHeight = useMemo(() => {
		if (screen === "tablet") {
			return 460
		}
		return windowHeight - (56 * 2)
	}, [screen, windowHeight, windowWidth])

	if (!imageId) {
		return null
	}

	return (
		<CenteredModal
			onClose={onClose}
			open={open}
		>
			<Box
				sx={{
					width: "fit-content",
					height: "fit-content",
					backgroundColor: "white",
					lineHeight: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<CloudinaryImage
					imageId={imageId}
					width={imageWidth}
					height={imageHeight}
					customQuality="auto"
					resize="fit"
				/>
			</Box>
		</CenteredModal>
	)

}

export default CommentsImagePreview