import React from "react"
import { makeStyles } from "@mui/styles"
import OrderSupplierHeader from "./OrderSupplierHeader"
import OrderCard from "./OrderCard"

const useStyles = makeStyles({
	cardBloc: {
		display: "flex",
		flexWrap: "wrap",
		paddingTop: 16,
		paddingBottom: 14
	}
})

const OrderSupplierBloc = (props) => {
	const {
		supplier,
		supplierItems,
		cart,
		actions,
		selectedCard,
		isValidOrder,
		selectedOrder
	} = props
	
	const classes = useStyles()
	
	return (
		<div>
			<div>
				<OrderSupplierHeader
					supplierName={supplier.name}
				/>
			</div>
			<div className={classes.cardBloc}>
				{
					supplierItems.map((supplierItem, i) => {
						const cartSupplierValues = cart.get(supplier.objectId)
						const isInCart = cartSupplierValues ? cartSupplierValues.find(el => el.card.objectId === supplierItem.objectId) : false
						const isSelected = selectedCard && selectedCard.objectId === supplierItem.objectId
						
						return (
							<OrderCard
								selectedOrder={selectedOrder}
								isValidOrder={isValidOrder}
								key={i}
								supplierItem={supplierItem}
								isInCart={isInCart ? true : false}
								isSelected={isSelected}
								actions={actions}
							/>
						)
					})
				}
			</div>
		</div>
	)
}

export default OrderSupplierBloc