import React  from "react"
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper"
import { COLORS } from "../../utils"
import {showSupplierItem} from "../../actions/Supplier/supplierItems"
import {useDispatch} from "react-redux"

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 5,
    paddingBottom: 40,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  titleContainer: {
    display: "flex",
    flex: "1 1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    padding: "20px 32px 16px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
  },
  navigate: {
    fontWeight: "bold",
    color: COLORS.GREEN_400,
  },
  singleValueContainer: {
    display: "flex",
    paddingLeft: 32,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
  },
  substitutionItemLabel: {
    fontWeight: "bold",
    cursor: "pointer",
    color: COLORS.LIGHT_BLUE_SLIDER
  },
  valueLabel: {
    color: COLORS.BLUEGREY_700,
    width: 300,
    minWidth: 200,
    display: "flex",
    alignItems: "center",
  }
}))

const SupplierItemSettingSubstitution = (props) => {
  const {
    supplierItemsRelatives
  } = props

  const classes = useStyles()
  const dispatch = useDispatch()

  const navigateToSupplierItem = async (supplierItemId) => {
    await dispatch(showSupplierItem(supplierItemId))
  }

  return (
    <Paper className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>Articles principaux associés</div>
      </div>
      {
        supplierItemsRelatives && supplierItemsRelatives.map((supplierItem, i) => {
          return (
            <div key={i}>
              <div className={classes.singleValueContainer}>
                <div className={classes.valueLabel}>Rattaché à l'article principal</div>
                <span className={classes.substitutionItemLabel} onClick={() => navigateToSupplierItem(supplierItem.objectId)}>{supplierItem.name}</span>
              </div>
            </div>
          )
        })
      }
    </Paper>
  )
}

export default SupplierItemSettingSubstitution
