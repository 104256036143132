const defaultState = {
    productionSchemas: [],
    productionSchemasOptions: [],
    productionSchemaSnackbar: { open: false, type: "", message: "", duration: 0 },
    cellWidth: 260,
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "PRODUCTION_SCHEMAS_LOADED":
            return {
                ...state,
                productionSchemas: action.productionSchemas
            }
        case "PRODUCTION_SCHEMAS_CLEARED":
            return {
                ...state,
                productionSchemas: []
            }
        case "PRODUCTION_SCHEMAS_OPTIONS_LOADED":
            return {
                ...state,
                productionSchemasOptions: action.productionSchemasOptions
            }
        case "SHOW_PRODUCTION_SCHEMA_SNACKBAR":
            return {
                ...state,
                productionSchemaSnackbar: action.productionSchemaSnackbar
            }
        case "HIDE_PRODUCTION_SCHEMA_SNACKBAR":
            return {
                ...state,
                productionSchemaSnackbar: action.productionSchemaSnackbar
            }
        case "PRODUCTION_SCHEMA_CELL_WIDTH_UPDATED": {
            return {
                ...state,
                cellWidth: action.cellWidth
            }
        }
        default:
            return state
    }
}

export const getProductionSchemasSelector = (state) => {
    return state.productionSchemas.productionSchemas
}

export const getProductionSchemasOptionsSelector = (state) => {
    return state.productionSchemas.productionSchemasOptions
}

export const getProductionSchemaSnackbarSelector = (state) => {
    return state.productionSchemas.productionSchemaSnackbar
}

export const getProductionSchemaCellWidth = (state) => {
    return state.productionSchemas.cellWidth
}