import Parse from "parse"
import { parseLimitRequest } from "../../../../utils"

const Chefs = Parse.Object.extend("Chefs")

export async function countChefs() {
    try {
        return await new Parse.Query(Chefs).count()
    }
    catch (e) {
        console.error("parseManager.products.resources.chefs.parseChefsManager.countChefs error : ", e)
        return Promise.reject(e)
    }
}

export async function getChefs(toJson = true) {
    try {
        const chefs = (await new Parse.Query(Chefs)
            .include(["image"])
            .limit(parseLimitRequest)
            .find()) || []

        return toJson
            ? chefs.map(chef => chef.toJSON())
            : chefs
    } catch(e) {
        console.error("parseManager.products.resources.chefs.parseChefsManager.getChefs error : ", e)
        return Promise.reject(e)
    }
}

export async function getSingleChef(id, toJson = true) {
    try {
        const chef = await new Parse.Query(Chefs)
            .equalTo("objectId", id)
            .include(["image"])
            .first()

        return toJson
            ? chef
                ? chef.toJSON()
                : null
            : chef
    } catch(err) {
        console.error("parseManager.products.resources.chefs.parseChefsManager.getSingleChef : " , err)
        return Promise.reject(err)
    }
}

export async function saveChef(chef) {
    try {
        await chef.save()
        return chef
    } catch(err) {
        console.error("parseManager.products.resources.chefs.parseChefsManager.saveChef : " , err)
        return Promise.reject(err)
    }
}

export async function createOrUpdateParseChef(values, id) {
    try {
        const chef = id
            ? await new Parse.Query(Chefs)
                .include(["image"])
                .equalTo("objectId", id)
                .first()
            : new Chefs()
        if (!chef) {
            return null
        }
        chef.set(values)
        await chef.save()
        return chef.toJSON()
    } catch(err) {
        console.error("parseManager.products.resoucres.chefs.parseChefsManager.createOrUpdateChef : ", err)
        return Promise.reject(err)
    }
}
