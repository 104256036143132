import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    container: {
        height: 72,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    title: {
        color: COLORS.GREY_700,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "uppercase"
    },
    subTitle: {
        marginTop: 10,
        color: COLORS.DARK_GREY,
        fontSize: 12,
        textTransform: "uppercase"
    },
    infoBloc: {
        display: "flex",
        flexDirection: "row",
    },
    reference: {
        color: COLORS.BLACK,
        fontSize: 16,
        textTransform: "uppercase",
    }
})

const SingleReceptionHeaderTitle = (props) => {
    const {
        headings,
        infos
    } = props
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.topLine}>
                <Typography className={classes.title}>
                    {(headings && headings.title) || ""} {infos.reference && infos.reference !== "null" &&` - ${(infos && infos.reference) || ""}`}
                </Typography>
            </div>
            <div>
                <div className={classes.infoBloc}>
                    <Typography className={classes.info}>
                        {(infos && infos.stockUnitWeight) || ""}
                    </Typography>
                    <span> &nbsp; &nbsp; &nbsp;</span>
                    <Typography className={classes.info}>
                        {(infos && infos.pricePerStockUnit) || ""}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default SingleReceptionHeaderTitle