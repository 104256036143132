import React, { useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"

import PackagingExecutionFilterForm from "./PackagingExecutionFilterForm"
import { updatePackagingExecutionFilters, showPackagingExecutionRead, generatePackagingExecutionsVolumes } from "../../actions/PackagingExecution/packagingExecution"
import MoreMenu from "../../components/MoreMenu"
import DateFormDialog from "../../components/DateFormDialog"
import { COLORS, timestampToDate } from "../../utils"
import PackagingExecutionHeader from "./PackagingExecutionHeader"
import { getPackagingExecutionFilterDateSelector, getPackagingExecutionFilterSiteSelector, getPackagingExecutionSitesSelector } from "../../reducers/Packaging/packagingExecution"

const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	headerBlock: {
		flex: "0 0 64px"
	},
	header: {
		width: "100%"
	},
	headerTitle: {
		display: "flex",
		gap: "8px",
		justifyContent: "center",
		alignItems: "center",
		color: COLORS.PACKAGING_EXECUTION_YELLOW
	},
	headerSubtitle: {
		paddingLeft: 8,
		fontSize: 16,
		color: COLORS.DRAFT_GREY
	}
})

const PackagingExecutionFilter = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const sites = useSelector(getPackagingExecutionSitesSelector)
	const selectedSite = useSelector(getPackagingExecutionFilterSiteSelector)
	const selectedDate = useSelector(getPackagingExecutionFilterDateSelector)

	const [openDialog, setOpenDialog] = useState(false)

	const submitFilterForm = async (values) => {
		const { site, packagingDate } = values
		const formattedDate = timestampToDate(packagingDate, "YYYY-MM-DD")
		dispatch(updatePackagingExecutionFilters(site))
		dispatch(showPackagingExecutionRead(site, formattedDate))
	}

	const toggleDialog = () => setOpenDialog(!openDialog)

	const handleGeneratePE =  async (values) => {
		await dispatch(generatePackagingExecutionsVolumes(values.date))
		toggleDialog()
	}

	const moreMenus = useMemo(() => {
		return [
			{
				onClick: toggleDialog,
				label: "Générer les volumes de barquettage"
			}
		]}, [])

	return (
		<Box className={classes.wrapper}>
			<PackagingExecutionHeader rightAction={<MoreMenu menus={moreMenus} />} />
			<Box className={classes.container}>
				<PackagingExecutionFilterForm
					sites={sites}
					selectedSite={selectedSite}
					selectedDate={selectedDate}
					handleSubmit={submitFilterForm}
				/>
			</Box>

			<DateFormDialog
				title="Veuillez sélectionner le jour pour lequel vous souhaitez générer les volumes de barquettage."
				onClose={toggleDialog}
				open={openDialog}
				onConfirm={handleGeneratePE}
				buttonText="Générer les volumes"
				inputLabel="Jour de barquettage"
			/>
		</Box>
	)
}

export default PackagingExecutionFilter