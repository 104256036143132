import React from "react"
import { IconButton } from "@mui/material"
import Edit from "@mui/icons-material/Edit"
import { COLORS } from "../utils"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
  iconContainer: {
    margin: 16,
    width: 38,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.PRIMARY_COLOR,
    borderRadius: 3,
    padding: 0,
    "&:hover": {
      backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
    }
  },
  icon: {
    color: COLORS.WHITE,
  }
}))


const GenericEditIcon = ({ onEdit }) => {

  const classes = useStyles()

  return (
    <IconButton
      className={classes.iconContainer}
      disableRipple={true}
      onClick={onEdit}
      size="large">
      <Edit
        className={classes.icon} />
    </IconButton>
  )
}

export default GenericEditIcon