import { getData } from ".."

const defaultState = {
	packagingExecutions: [],
	packagingExecution: null,
	packagingExecutionProposedWeights: null,
	filterDate: new Date(),
	filterSite: null,
	filterSites: [],
	selectedSite: null,
	selectedDate: null,
	snackbar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultState, action) {
	switch (action.type) {
		case "PACKAGING_EXECUTIONS_FILTERS_LOADED":
			return {
				...state,
				filterDate: action.date,
				filterSite: action.site,
				filterSites: action.sites
			}
		case "PACKAGING_EXECUTION_FILTERS_SELECTED":
			return {
				...state,
				selectedDate: action.selectedDate,
				selectedSite: action.selectedSite
			}
		case "PACKAGING_EXECUTIONS_LOADED":
			return {
				...state,
				packagingExecutions: action.packagingExecutions,
			}
		case "PACKAGING_EXECUTIONS_UDPATED":
			return {
				...state,
				packagingExecutions: state.packagingExecutions.map(packagingExecution => {
					if (packagingExecution.objectId === action.packagingExecution.objectId) {
						return action.packagingExecution
					}
					return packagingExecution
				}),
			}
		case "PACKAGING_EXECUTION_LOADED":
			return {
				...state,
				packagingExecution: action.packagingExecution,
				packagingExecutionProposedWeights: action.packagingExecutionProposedWeights
			}
		case "TOGGLE_PACKAGING_EXECUTION_SNACKBAR":
			return {
				...state,
				snackbar: action.snackbar
			}
		default:
			return {
				...state
			}
	}
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export const getPackagingExecutionSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.packagingExecution", errorIfNotFound && "No packaging execution selected")
}

export const getPackagingExecutionsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.packagingExecutions", errorIfNotFound && "No packaging executions found")
}

export const getPackagingExecutionFilterDateSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.filterDate", errorIfNotFound && "No date found")
}

export const getPackagingExecutionFilterSiteSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.filterSite", errorIfNotFound && "No site found")
}

export const getPackagingExecutionSitesSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.filterSites", errorIfNotFound && "No sites found")
}

export const getPackagingExecutionSnackbarSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.snackbar", errorIfNotFound && "No snackbar found")
}

export const getPackagingExecutionSiteSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.selectedSite", errorIfNotFound && "No selected site found")
}

export const getPackagingExecutionDateSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.selectedDate", errorIfNotFound && "No selected date found")
}

export const getPackagingExecutionProposedWeightsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "packagingExecution.packagingExecutionProposedWeights", errorIfNotFound && "No proposed weight found")
}
