import {
    actionWithLoader,
    getReplacingPath,
    haveAccessRight,
    onEnter,
    push,
    reportRulesJsonToReportRulesParseFormat
} from "../../Utils/utils"
import {
    getPlanningReportRulesJson, getPlanningReportRulesParse,
    savePlanningReportRules, getValuesFromParseObject,
    setValueToParseObject
} from "../../../parseManager/planning/parsePlanningManager"
import { forEachBrand } from "../../../utils"

export function updateReportRules(values) {
    return actionWithLoader(async (dispatch) => {
        try {
            const rules = await getPlanningReportRulesParse()
            if (!rules) {
                dispatch({
                    type: "PLANNING_SETTINGS_SNACKBAR",
                    planningSettingsSnackBar: {
                        open: true,
                        type: "error",
                        message: "Les régles de report n'ont pas pu être sauvegardés",
                        duration: 5000
                    }
                })
            } else {
                const parseValues = reportRulesJsonToReportRulesParseFormat(values)

                const brandValues = {}
                forEachBrand(brand => {
                    const newConf = getValuesFromParseObject(rules, brand.name)
                    newConf.RECIPE.productionDate = parseValues[brand.name].RECIPE.productionDate
                    newConf.RECIPE.packagingDate = parseValues[brand.name].RECIPE.packagingDate
                    newConf.SUBCONTRACTORPRODUCT.productionDate = parseValues[brand.name].SUBCONTRACTORPRODUCT.productionDate
                    newConf.SUBCONTRACTORPRODUCT.packagingDate = parseValues[brand.name].SUBCONTRACTORPRODUCT.packagingDate
                    newConf.autoSaveableBrands = parseValues[brand.name].autoSaveableBrands
                    newConf.isReporting = parseValues[brand.name].isReporting
                    newConf.volumeReporting = parseValues[brand.name].volumeReporting
                    brandValues[brand.name] = newConf
                })

                for (const i in brandValues) {
                    setValueToParseObject(rules, i, brandValues[i])
                }

                await savePlanningReportRules(rules)
                const reportRules = getPlanningReportRulesJson(rules)
                dispatch({
                    type: "PLANNING_SETTINGS_REPORT_RULES_UPDATED",
                    planningSettingsSnackBar: {
                        open: true,
                        type: "success",
                        message: "Les régles de report ont été sauvegardées",
                        duration: 5000
                    },
                    reportRules: reportRules
                })
            }
        } catch(err) {
            dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    type: "error",
                    message: "Les régles de report n'ont pas pu être sauvegardés",
                    duration: 5000
                }
            })
        }
    })
}

export function loadPlanningSettingReport() {
    return actionWithLoader(async (dispatch) => {
        const parseRules = await getPlanningReportRulesParse()
        const rules = await getPlanningReportRulesJson(parseRules)
        dispatch({
            type: "PLANNING_SETTINGS_REPORT_RULES_LOADED",
            reportRules: rules,
        })
    })
}

export function onEnterPlanningSettingReport(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningSettingReport,
        getReplacingPath: getReplacingPath({needUser: true}),
        haveAccessRight: haveAccessRight({readPlanningRules: true})
    })
}

export function showPlanningSettingReport() {
    return push("/settings/production/reports")
}
