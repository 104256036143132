import React from "react"
import { makeStyles } from "@mui/styles"
import OrderReceptionFilter from "../../../components/Order/Reception/OrderReceptionFilter"
import { COLORS } from "../../../utils"
import { useSelector, useDispatch } from "react-redux"
import { showOrderList } from "../../../actions/OrderReception/OrderReception"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import OrderReceptionHeaderTitle from "../../../components/Order/Reception/OrderReceptionHeaderTitle"

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    container: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
    },
    headerBloc: {
        flex: "0 0 64px"
    }
}))


const OrderReception = () => {
    const classes = useStyles()
    
    const dispatch = useDispatch()
    const sites = useSelector(state => state.sites.sites)

    const onFilterSelect = (site, date) => {
        dispatch(showOrderList(site.objectId, date))
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerBloc}>
                <GenericTopBarHeader
                    title={<OrderReceptionHeaderTitle />}
                />
            </div>
            <div className={classes.container}>
                <OrderReceptionFilter sites={sites} onSubmit={onFilterSelect} />
            </div>
        </div>
    )
}

export default OrderReception