import {
    onEnter,
    getReplacingPath,
    actionWithLoader,
    push
} from "../Utils/utils"
import {
    countProductsTags
} from "../../parseManager/products/resources/productTag/parseProductTagManager"
import {
    countPackagings
} from "../../parseManager/products/resources/packaging/parsePackagingManager"
import {
    countGenericsSections
} from "../../parseManager/products/resources/section/parseSectionManager"
import {
    countSubcontractorsProducts
} from "../../parseManager/products/subcontractorProduct/parseSubcontractorProductManager"
import {
    countRecipes
} from "../../parseManager/products/recipe/parseRecipeManager"
import {
    countChefs
} from "../../parseManager/products/resources/chef/parseChefsManager"

export function updateProductsCounter() {
    return actionWithLoader(async (dispatch) => {
        const nbRecipes = await countRecipes()
        const nbSubcontractorsProducts = await countSubcontractorsProducts()

        dispatch({
            type: "COUNT_PRODUCTS_QUANTITY",
            nbRecipes,
            nbSubcontractorsProducts,
        })
    })
}

export function updateResourcesCounter() {
    return actionWithLoader(async (dispatch) => {
        const nbPackaging = await countPackagings()
        const nbSections = await countGenericsSections()
        const nbProductsTags = await countProductsTags()
        const nbChefs = await countChefs()
        dispatch({
            type: "COUNT_PRODUCTS_RESOURCES",
            nbPackaging,
            nbSections,
            nbProductsTags,
            nbChefs
        })
    })
}

export function updateAllProductsCounter() {
    return actionWithLoader(async (dispatch) => {
        dispatch(updateProductsCounter())
        dispatch(updateResourcesCounter())
    })
}

export function onEnterProducts(store) {
    return onEnter({
        store,
        actionThunk: updateProductsCounter,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function onEnterProductsResources(store) {
    return onEnter({
        store,
        actionThunk: updateResourcesCounter,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function showPackagingResources() {
    return push("/settings/resources/packagings")
}

export function showSectionsGenericsResources() {
    return push("/settings/resources/sections")
}

export function showChefsList() {
    return push("/settings/resources/chefs")
}

export function showReusableSteps() {
    return push("/settings/resources/reusableSteps")
}
