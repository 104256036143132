import React from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import SearchInput from "./SearchInput"
import { COLORS } from "../../utils"
import makeStyles from "@mui/styles/makeStyles"
import IconButton from "@mui/material/IconButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import clsx from "clsx"
import ProductPlacementIcon from "@mui/icons-material/Kitchen"

const useStyles = makeStyles(() => ({
  searchFieldDiv: {
    display: "flex",
    alignItems: "center",
    padding: "14px 18px 14px 10px",
    width: "100%",
    backgroundColor: COLORS.LIGHT_GREY,
  },
  selectType: {
    width: 158,
    color: COLORS.DARK_GREY,
    marginLeft: 395,
    "&.is-tablet": {
      marginLeft: 522,
    },
  },
  actionContainer: {
    marginLeft: "auto",
  },
  icon: {
    "& svg": {
      fontSize: 30,
    },
  },
}))

const Filter = (props) => {
  const {
    isTablet,
    status,
    onStatusChange,
    onNameChange,
    isInventory,
    refresh,
    focusSearch,
    searchValue,
    sendProductPlacement,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.searchFieldDiv}>
      <SearchInput
        defaultValue={searchValue}
        focused={focusSearch}
        isTablet={isTablet}
        onNameChange={onNameChange}
        isInventory={isInventory}
      />

      {!isInventory && (
        <Select
          variant="standard"
          className={clsx(classes.selectType, { "is-tablet": isTablet })}
          labelId="select-status"
          id="select-status"
          value={status}
          onChange={(e) => onStatusChange(e.target.value)}
          autoWidth={true}
        >
          <MenuItem value={"TODO"}>A réceptionner</MenuItem>
          <MenuItem value={"IN_PROGRESS"}>En cours</MenuItem>
          <MenuItem value={"DONE"}>Réceptionnés</MenuItem>
          <MenuItem value={"ALL"}>Tous les produits</MenuItem>
        </Select>
      )}

      <div className={classes.actionContainer}>
        {/* button to send on email product placement */}
        <IconButton
          className={classes.icon}
          onClick={sendProductPlacement}
          size="large"
        >
          <ProductPlacementIcon />
        </IconButton>
        {/* button to refresh */}
        <IconButton className={classes.icon} onClick={refresh} size="large">
          <RefreshIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default Filter
