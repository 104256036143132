import {productType} from "./dispatchUtils"
import moment from "moment"

export function sortProductPackagingData(data, productTypeOptions) {
	let finalData = []
	
	const sortProductType = productTypeOptions.sort(function(a, b){
		if (a.label > b.label) return -1
		if (a.label < b.label) return 1
		return 0
	})
	
	for (const i in sortProductType){
		const current = sortProductType[i]

		const tab = data.filter(el => el.productionItems.length > 0 && el.productionItems[0].productType === current.value).sort(function(a, b){
			if (a.productionItems[0].commercialName < b.productionItems[0].commercialName)
				return -1
			if ( a.productionItems[0].commercialName > b.productionItems[0].commercialName)
				return 1
			return 0
		})
		
		finalData = [].concat(...finalData, tab)
	}
	
	return finalData
}

export function getProductPackagingProductTypeLabel(type) {
	const currentProductType = productType.find(el => el.type === type) || null
	return currentProductType ? currentProductType.label : ""
}

export function getProductPackagingDlc(dlc, lifetime) {
	return moment.utc(dlc).add(lifetime, "days").valueOf()
}
