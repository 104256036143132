import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material"

import { TRANSFORMATION_TYPES } from "../../../../utils/supplierItemUtils"
import { transformationModesSchema } from "../../../../utils/yupValidators"

const initialValues = {
  name: "",
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

export const TransformationModesFormModal = (props) => {
  const { onClose, open, onConfirm } = props

  const formikRef = useRef(null)

  const handleConfirm = () => {
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
        Ajouter un mode de transformation
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={transformationModesSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors }) => {
            return (
              <Form>
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.name}>
                  <TextField
                    label="Mode de transformation"
                    variant="standard"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name}
                    fullWidth
                  />
                  {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.transformationType}>
                  <InputLabel id="transformationType-label">Type de transformation</InputLabel>
                  <Select
                    variant="standard"
                    name="transformationType"
                    value={values.transformationType}
                    onChange={handleChange}
                    fullWidth
                  >
                    {TRANSFORMATION_TYPES.map((transformationType, index) => (
                      <MenuItem key={index} value={transformationType.value}>
                        {transformationType.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.transformationType && <FormHelperText>{errors.transformationType}</FormHelperText>}
                </FormControl>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", mt: 3.5 }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default TransformationModesFormModal
