import { supplierItemTypes } from "./supplierItemUtils"
import { UNKNOWN_LOT_GROUP_ID, removeLotsNotNeeded } from "./lotInventoryUtils"
import { cloneDeep } from "lodash"

export const refreshSecondaryDLCsBySections = (secondaryDLCsBySections, updatedSecondaryDLC) => {

	let groupId = ""
	const type = updatedSecondaryDLC.orderSupplierItem.type
	if (type === supplierItemTypes.RAW_MATERIAL.key) {
		groupId = updatedSecondaryDLC.orderSupplierItem?.commercialName?.group?.objectId || UNKNOWN_LOT_GROUP_ID
	}
	if (type === supplierItemTypes.SALABLE_PRODUCT.key) {
		groupId = supplierItemTypes.SALABLE_PRODUCT.key + "-" + updatedSecondaryDLC.orderSupplierItem.productType
	}
	if (type === supplierItemTypes.PACKAGING_CONSUMABLE.key) {
		groupId = supplierItemTypes.PACKAGING_CONSUMABLE.key + "-" + updatedSecondaryDLC.orderSupplierItem.productType
	}

	const secondaryDLCsGroup = secondaryDLCsBySections.get(groupId)
	const secondaryDLCsNameGroup = secondaryDLCsGroup.get(updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase())

	const selectedPosition = secondaryDLCsNameGroup.findIndex(secondaryDLC => secondaryDLC.objectId === updatedSecondaryDLC.objectId)

	secondaryDLCsNameGroup[selectedPosition] = updatedSecondaryDLC

	secondaryDLCsGroup.set(updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase(), secondaryDLCsNameGroup)

	return cloneDeep(removeLotsNotNeeded(secondaryDLCsBySections))

}

export const insertIntoSecondaryDLCsBySections = (secondaryDLCsBySections, updatedSecondaryDLC) => {

	let groupId = ""
	const type = updatedSecondaryDLC.orderSupplierItem.type
	if (type === supplierItemTypes.RAW_MATERIAL.key) {
		groupId = updatedSecondaryDLC.orderSupplierItem?.commercialName?.group?.objectId || UNKNOWN_LOT_GROUP_ID
	}
	if (type === supplierItemTypes.SALABLE_PRODUCT.key) {
		groupId = supplierItemTypes.SALABLE_PRODUCT.key + "-" + updatedSecondaryDLC.orderSupplierItem.productType
	}
	if (type === supplierItemTypes.PACKAGING_CONSUMABLE.key) {
		groupId = supplierItemTypes.PACKAGING_CONSUMABLE.key + "-" + updatedSecondaryDLC.orderSupplierItem.productType
	}

	const secondaryDLCsGroup = secondaryDLCsBySections.get(groupId)
	if (secondaryDLCsGroup) {
		const secondaryDLCsNameGroup = secondaryDLCsGroup.get(updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase())
		if (secondaryDLCsNameGroup && secondaryDLCsNameGroup.length > 0) {
			secondaryDLCsNameGroup.push(updatedSecondaryDLC)
			secondaryDLCsGroup.set(updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase(), secondaryDLCsNameGroup)
		}
		else if (!secondaryDLCsNameGroup || secondaryDLCsNameGroup.length === 0) {
			secondaryDLCsGroup.set(updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase(), [updatedSecondaryDLC])
		}
	}
	else if (!secondaryDLCsGroup) {
		secondaryDLCsBySections.set(groupId, new Map())
		const nameGroup = updatedSecondaryDLC.orderSupplierItem.name.charAt(0).toUpperCase()
		secondaryDLCsBySections.get(groupId).set(nameGroup, [updatedSecondaryDLC])
	}

	return cloneDeep(removeLotsNotNeeded(secondaryDLCsBySections))
}

// for pse
export const groupSecondaryDLCsBySupplierItems = (secondaryDLCs, supplierItemsIds) => {
	const secondaryDLCsBySupplierItems = new Map()
	supplierItemsIds.forEach(id => secondaryDLCsBySupplierItems.set(id, []))
	secondaryDLCs.forEach(secondaryDLC => {
		const originalSupplierItemsIds = (secondaryDLC.originalSupplierItems || []).map(originalSupplierItem => originalSupplierItem.objectId)
		const allValidSupplierItemsIds = [secondaryDLC.supplierItem.objectId, ...originalSupplierItemsIds]
		allValidSupplierItemsIds.forEach(id => {
			if (secondaryDLCsBySupplierItems.has(id)) {
				secondaryDLCsBySupplierItems.get(id).push(secondaryDLC)
			}
		})
	})
	return secondaryDLCsBySupplierItems
}