import React from "react"
import clsx from "clsx"
import Toolbar from "@mui/material/Toolbar"
import { lighten } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
})

export const ChefsEnhancedTableToolbar = (props) => {
  const { createChef, numSelected, classes } = props

  const text = numSelected > 1 ? "Chefs" : "Chef"

  const _createChef = () => {
    createChef()
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {text}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            chefs
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      {numSelected > 0 ? null : (
        <div className={classes.actions}>
          <Tooltip title="Créer" disableInteractive>
            <IconButton aria-label="Créer" onClick={_createChef} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Toolbar>
  )
}

const StyledChefEnhancedTableToolbar = withStyles(toolbarStyles)(
  ChefsEnhancedTableToolbar
)

export default StyledChefEnhancedTableToolbar
