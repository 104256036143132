import React from "react"
import GenericBanner from "../../../../components/GenericBanner"

const DistributionCenterHeader = ({ distributionCenter = {} }) => {
	const tableColumns = [
		{
			id: "status",
			label: "État",
			width: 200
		},
		{
			id: "sector",
			label: "Secteur"
		},
		{
			id: "brand",
			label: "Marque"
		},
		{
			id: "priority",
			label: "Priorité"
		}
	]
	const tableData = {
		status: distributionCenter.isActive ? "Actif" : "Inactif",
		sector: distributionCenter.sector,
		brand: distributionCenter.brand,
		priority: distributionCenter.dispatch?.priority
	}

	return (
		<GenericBanner
			imageSrc={distributionCenter.image?.storageData?.secure_url || "/img/defaultImageDistributionCenter.png"}
			title={distributionCenter.name}
			tableColumns={tableColumns}
			tableDataRow={tableData}
		/>
	)
}

export default DistributionCenterHeader