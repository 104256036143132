import React from "react"
import ProductsPage from "./ProductsPage"
import SearchAdvanced from "./Search/SearchAdvanced"

const AdvancedSearchContainer = (props) => {
    const { rootRef } = props
	return (
		<ProductsPage tabValue="recipes">
			<SearchAdvanced rootRef={rootRef} />
		</ProductsPage>
	)
}

export default AdvancedSearchContainer