import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import { nutrionalValues } from "../../utils/nutritionalValues"
import { COLORS } from "../../utils"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import {Form, Formik} from "formik"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

const NutritionalValues = (props) => {
    const {
        classes,
        nutritionInformation,
        onSaveNutritionsValues,
        checkProductInCard,
        showModalProductInCard
    } = props
    const [isEditing, setIsEditing] = useState(false)

    const nutrionalValuesList = ["calories", "fat", "saturatedFattyAcids", "carbohydrates", "sugar", "proteins", "salt", "fibers"]
    const newNutrionalValues = nutrionalValues.filter(elm => nutrionalValuesList.includes(elm.nutritionValueKey))
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }
    
    const submitForm = (values) => {
        onSaveNutritionsValues(values, nutritionInformation)
        setIsEditing(false)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Valeurs nutritionnelles
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>
            {
                newNutrionalValues.map((elem, index) =>
                    <div key={index} className={elem.space ? classes.spaceValueContainer : classes.valueContainer}>
                        {
                            elem.indent &&
                            <div className={classes.nutritionLabel}>
                                &nbsp;&nbsp;{elem.nutritionLabel}
                            </div>
                        }
                        {
                            !elem.indent &&
                                <div className={classes.nutritionLabel}>
                                    {elem.nutritionLabel}
                                </div>
                        }
                        {
                            elem.nutritionValueKey &&
                                <div className={classes.nutritionValue}>
                                    {
                                        !elem.nutritionSecondValueKey
                                        ?
                                            nutritionInformation[elem.nutritionValueKey]
                                                ? <span>{nutritionInformation[elem.nutritionValueKey]}{elem.unity}</span>
                                                : <span>-</span>
                                        :
                                            nutritionInformation[elem.nutritionValueKey] && nutritionInformation[elem.nutritionValueKey][elem.nutritionSecondValueKey]
                                                ? <span>{nutritionInformation[elem.nutritionValueKey][elem.nutritionSecondValueKey]}{elem.unity}</span>
                                                : <span>-</span>
                                    }
                                </div>
                        }
                    </div>
                )
            }

            <Formik
                initialValues={nutritionInformation}
                onSubmit={submitForm}
            >
                {({
                      values,
                      handleChange,
                      handleSubmit
                  }) => {
                    return (
                        <Form>
                            <Dialog onClose={() => setIsEditing(false)} aria-labelledby="customized-dialog-title"
                                    open={isEditing}>
                                <DialogTitle id="customized-dialog-title" onClose={() => setIsEditing(false)}>
                                    Editer les valeurs nutritionnelles
                                </DialogTitle>
                                <DialogContent dividers>
                                    {
                                        newNutrionalValues.map((elem, index) =>
                                            <TextField
                                                variant="standard"
                                                multiline
                                                autoFocus
                                                fullWidth
                                                key={index}
                                                label={elem.nutritionLabel}
                                                name={elem.nutritionValueKey}
                                                value={values[elem.nutritionValueKey]}
                                                onChange={handleChange}
                                                className={classes.formControl}
                                                InputProps={{
                                                    disableUnderline: false,
                                                    endAdornment: (<InputAdornment position="end">{elem.unity}</InputAdornment>),
                                                    type: "number"
                                                }}
                                            />
                                        )
                                    }
                                </DialogContent>
                                <DialogActions classes={{root: classes.rootDialogActions}}>
                                    <Button
                                        onClick={() => setIsEditing(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Sauvegarder
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </Paper>
    )
}

const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },
    titleContainer: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 16,
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    valueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 4px"
    },
    spaceValueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 15px"
    },
    nutritionLabel: {
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "130%"

    },
    nutritionValue: {
        fontSize: 14,
        lineHeight: "130%",
        fontWeight: "bold",
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    rootDialogActions: {
        justifyContent: "space-between"
    },
    formControl: {
        width: 160,
        marginLeft: "unset",
        marginTop: 16,
        display: "block"
    }
}

export default withStyles(styles)(NutritionalValues)
