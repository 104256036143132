import React, { useMemo, useEffect, useState } from "react"
import { useSelector } from "react-redux/es/hooks/useSelector"
import { styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Stack } from "@mui/material"
import AdditionalFiltersForm, { additionalFiltersFormId } from "./AdditionalFiltersForm"
import { getLoading } from "../../reducers/Utils/app"
import { CircularProgress } from "@mui/material"
import AdditionalFiltersOptions from "./AdditionalFiltersOptions"

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "loading"
})(({ loading }) => ({
  "& .MuiPaper-root": {
    width: 348,
    margin: 0,
    padding: 32,
    display: "flex",
    justifyContent: loading ? "center" : "unset",
    alignItems: loading ? "center" : "unset",
  }
}))

const AdditionalFiltersModal = ({
  open,
  onClose,
  filters = [],
  selectedFilters = [],
  updateSelectedFilters,
  activeFilters,
  applyFilters
}) => {

  const [filtersToDisplay, setFiltersToDisplay] = useState([])

  const isUniqueSelection = useMemo(() => {
    return filters.length === 1
  }, [filters])

  useEffect(() => {
    if (isUniqueSelection) { // we don't need to pass via selection of tags (not even visible)
      setFiltersToDisplay(filters)
      return
    }
    setFiltersToDisplay(selectedFilters)
  }, [selectedFilters, isUniqueSelection])

  const isLoading = useSelector(getLoading)

  const title = useMemo(() => {
    if (isUniqueSelection && filters[0]?.formLabel) {
      return filters[0].formLabel
    }
    return "Choisissez les filtres que vous souhaitez appliquer."

  }, [filters, isUniqueSelection])

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      loading={isLoading}
      aria-labelledby="form-dialog-title">
      {isLoading && <CircularProgress />}
      {!isLoading && <><DialogTitle>
        <Typography>
          {title}
        </Typography>
      </DialogTitle>
        <DialogContent>
          <Stack spacing={3} useFlexGap>
            <AdditionalFiltersOptions
              filters={filters}
              selectedFilters={selectedFilters}
              onUpdateSelectedFilters={updateSelectedFilters}
              visible={!isUniqueSelection}
            />
            <AdditionalFiltersForm
              initialFilters={filtersToDisplay}
              initialValues={activeFilters}
              handleConfirm={applyFilters}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", paddingTop: "24px", width: "100%" }}>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button type="submit"
            form={additionalFiltersFormId}
            color="primary"
            variant="contained"
            disabled={!selectedFilters.length > 0 && !isUniqueSelection}
          >
            Appliquer
          </Button>
        </DialogActions>
      </>}
    </StyledDialog>
  )
}

export default AdditionalFiltersModal