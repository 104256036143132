import React from "react"
import Icon from "@mui/material/Icon"
import { COLORS } from "../utils"

export const EiffelTowerColors = { WHITE: COLORS.WHITE, BLACK: COLORS.BLACK}

export const EiffelTowerIcon = (props) => {
    const { color = EiffelTowerColors.WHITE } = props

    return (
        <Icon>
            <img
                src={color === EiffelTowerColors.WHITE ? "/img/EiffelTowerWhite.svg" : "/img/EiffelTowerBlack.svg"}
                alt="Eiffel tower"
            />
        </Icon>
    )
}

export default EiffelTowerIcon