import React, { forwardRef } from "react"
import { InputMask } from "@react-input/mask"
import { TextField, styled } from "@mui/material"

export const StyledRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "isValid"
})(({
  width = "initial",
}) => {
  return {
    "& input": {
      paddingBottom: 4,
      fontSize: 14,
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      maxWidth: width,
      "&:focus, &:active, &:focus:active": {
        outline: "none",
      },
      "&:hover": {
        outline: "none",
      }
    }
  }
})

// Component with InputMask
const ForwardedInputMask = forwardRef((props, forwardedRef) => {
  return (
    <InputMask
    ref={forwardedRef}
    showMask
    mask="__:__"
    replacement={{ _: /\d/ }}
    {...props}
    />
  )
})

const HoursMaskedField = ({
  onChange,
  value,
  name,
  autoFocus = false,
  width = 42,
  label = "",
  hasError = false
}) => {
  return (
    <StyledRoot width={width}>
        <TextField
          error={hasError}
          name={name}
          value={value}
          onChange={onChange}
          InputProps={{
            inputComponent: ForwardedInputMask,
          }}
          variant="standard"
          sx={{ border: "none", width: width }}
          autoFocus={autoFocus}
          label={label}
        />
    </StyledRoot>
  )
}

export default HoursMaskedField 