import React from "react"
import { Alert } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

const CommentsAlert = ({ children }) => {

	return (
		<Alert
			iconMapping={{
				info: <InfoOutlinedIcon sx={{
					width: 22,
					height: 22
				}} />,
			}}
			severity="info"
			sx={{
				padding: "8px 16px",
				backgroundColor: "#E3F2FD",
				maxWidth: "fit-content",
				".MuiAlert-icon": {
					color: "#0288D1",
				},
				".MuiAlert-message": {
					color: "#262626",
					fontSize: 14,
					lineHeight: "22px"
				}
			}}>{children}
		</Alert>
	)
}

export default CommentsAlert