import moment from "moment"
import { roundNumber } from "../utils"
import { generateStockUnitLabel, getOptionLabel } from "./orderReception"
import { unitSelectValues, getSupplierItemTypeLabel } from "./supplierItemUtils"
import { openDayslist, getSupplierDeliveryDays } from "./suppliers"

import cloneDeep from "lodash/cloneDeep"
import {
    getOrderSupplierItemsBySupplierItem
} from "../parseManager/orders/parseOrderSupplierItemManager"
import {getLotsByOrderSupplierItem} from "../parseManager/lot/parseLotManager"

export const orderStatus = {
    TO_VERIFY: "TO_VERIFY",
    DONE: "DONE"
}

export const orderMode = {
    CREATE: "CREATE",
    UPDATE: "UPDATE"
}

export const orderUpdateStatus = {
    TO_CANCEL: "TO_CANCEL",
    UPDATE: "UPDATE",
    CANCELLED: "CANCELLED",
}

export const generateOrderUnitLabel = (item, type = "stock") => {
    let label = ""

    if (item.units && item.units[type] && item.units[type].unity && item.units[type].unity) {
        let showQuantity = true

        if ((item.units[type].unity.name === "" || item.units[type].unity.name === null) && item.units[type].unity.quantity === 1) {
            showQuantity = false
        }

        label = `${item.units[type].unity.name} ${showQuantity ? item.units[type].unity.quantity : ""} ${unitSelectValues.find(el => el.value === item.units[type].unity.unity).label}${item.units[type].unity.quantity > 1 ? "s" : ""}`
    }

    return label
}

export const generateActiveStatus = (isActive) => {
    return isActive ? "Actif" : "Inactif"
}

export const getStockUnitWeightLabel = (supplierItem) => {
    if (!supplierItem.units?.order) {
        return generateOrderUnitLabel(supplierItem, "order")
    }

    return `${supplierItem.units.order.weight}kg / ${generateOrderUnitLabel(supplierItem, "order")}`
}

export const getStockUnitWeight = (supplierItem) => {
    if (!supplierItem.units?.stock) {
        return generateOrderUnitLabel(supplierItem, "stock")
    }

    return `${supplierItem.units.stock.weight}kg / ${generateOrderUnitLabel(supplierItem, "stock")}`
}

export const computeOrderSupplierItemWeight = (item, quantity = 0, type = "stock") => {
    let value = ""
    if (item.units && item.units[type] && item.units[type].weight) {
        value = `${roundNumber(item.units[type].weight * quantity, 2)}`
    }
    return value
}

export const computeOrderTotalWeightPrice = (values) => {
    const data = {
        totalAmount: 0,
        totalWeight: 0
    }

    for (const value of values) {
        if (value.card && value.card.units && value.card.units.order) {
            data.totalAmount = +data.totalAmount + (value.card.units.order.price * +value.quantity)
            data.totalWeight = +data.totalWeight + (value.card.units.order.weight * +value.quantity)
        }
    }

    return {
        totalAmount: roundNumber(data.totalAmount, 2),
        totalWeight: roundNumber(data.totalWeight, 2)
    }
}

export const getSelectedCardQuantity = (selectedCard, cart) => {
    let quantity = null
    const cartSupplierValues = cart.get(selectedCard.supplier.objectId)

    if (cartSupplierValues) {
        const cardInCart = cartSupplierValues.find(el => el.card.objectId === selectedCard.objectId)

        if (cardInCart) {
            quantity = cardInCart.quantity
        }
    }

    return quantity || ""
}

export const selectedCardIsInCart = (selectedCard, cart) => {
    const cartSupplierValues = cart.get(selectedCard.supplier.objectId)

    if (cartSupplierValues) {
        const cardInCart = cartSupplierValues.find(el => el.card.objectId === selectedCard.objectId)

        if (cardInCart) {
            return true
        }
    }

    return false
}
export const computeMinDateDelivery = (order) => {
    const currentDate = moment()
    const firstDateDeliveryAvailable = cloneDeep(currentDate)
    const allowEarlyDelivery = order && order.allowEarlyDelivery
    let dayOfFirstDate = dayNumber(firstDateDeliveryAvailable)

    if (!allowEarlyDelivery && order) {
        const time = order.orderHourLimit.split(":")
        const orderHourLimitDate = cloneDeep(currentDate)
        const supplierOpenDays = openDayslist.find(elem => elem.value === order.openDays)
        let isFound = false

        orderHourLimitDate.set({ "hour": time[0], "minute": time[1] })

        if (currentDate.isSameOrAfter(orderHourLimitDate)) {
            firstDateDeliveryAvailable.add(1, "day")
            dayOfFirstDate = dayNumber(firstDateDeliveryAvailable)
        }

        for (let i = order.deliveryLeadTime; i > 0;) {
            if (supplierOpenDays.days.includes(dayOfFirstDate)) {
                i--
            }
            firstDateDeliveryAvailable.add(1, "day")
            dayOfFirstDate = dayNumber(firstDateDeliveryAvailable)
        }

        while (!isFound) {
            if (order.deliveryDays.includes(dayOfFirstDate)) {
                isFound = true
            } else {
                firstDateDeliveryAvailable.add(1, "day")
                dayOfFirstDate = dayNumber(firstDateDeliveryAvailable)
            }
        }
    }

    return firstDateDeliveryAvailable
}
export const computeCartData = (cart) => {
    const result = {
        price: 0,
        weight: 0
    }

    if (cart) {
        cart.forEach((values) => {
            for (const value of values) {
                if (value.card && value.card.units && value.card.units.order) {
                    result.price = +result.price + (value.card.units.order.price * +value.quantity)
                    result.weight = +result.weight + (value.card.units.order.weight * +value.quantity)
                }
            }
        })
    }

    return {
        price: roundNumber(result.price, 2),
        weight: roundNumber(result.weight, 2)
    }
}

export const createOrderCart = (order) => {
    const cart = new Map()

    if (order) {
        cart.set(order.supplier.objectId, order.supplierItems.map(el => ({ card: el.supplierItem, quantity: +el.quantities.order })))
    }

    return cart
}

export const filterOrdersBySupplier = (orders, suppliers) => {
    const filteredOrders = []

    suppliers.forEach(supplier => {
        let items = orders.filter(order => order.supplier.objectId === supplier)
        if (items.length > 0) {
            filteredOrders.push(...items)
        }
    })

    return filteredOrders
}

export const filterOrdersBySupplierAndType = (orders, suppliers, types) => {
    let filteredOrders = orders
    //-----------------------------------------------------//
    //-------------------- Suppliers ----------------------//
    //-----------------------------------------------------//
    if (suppliers?.length) {
        const newOrders = []
        suppliers.forEach(supplier => {
            let items = filteredOrders.filter(order => order.supplier.objectId === supplier)
            if (items.length > 0) {
                newOrders.push(...items)
            }
        })
        filteredOrders = newOrders
    }

    //-----------------------------------------------------//
    //----------------------- Types -----------------------//
    //-----------------------------------------------------//
    if (types?.length) {
        const newOrders = []
        for (const order of filteredOrders) {
            const supplierItem = order.supplierItems.find(supplierItem => {
                return types.includes(supplierItem.supplierItem.type)
            })
            if (supplierItem) {
                newOrders.push(order)
            }
        }
        filteredOrders = newOrders
    }
    return filteredOrders
}

export const filterOrders = (orders, filter) => {
    const exp = new RegExp(filter, "i")
    const filteredOrders = orders.filter(order => exp.test(order.orderNumber))

    return filteredOrders
}

export const getParamsFromPath = (pathname) => {
    const elems = pathname.split("/")
    if (
        (pathname.startsWith("/orders/") || pathname.startsWith("/order/")) &&
        ((elems.length === 3) || (elems.length === 4))
    ) {
        return {
            siteId: elems[2],
            orderId: elems[3]
        }
    }

    return null
}

export const isValidOrder = (order, cart) => {
    if (!order || !order.expectedDeliveryDate) {
        return false
    }

    const minDateDelivery = computeMinDateDelivery(order)
    const hasDateDeliveryError = getDateDeliveryErrorMessage(moment(order.expectedDeliveryDate), minDateDelivery, order)

    if (hasDateDeliveryError !== "") {
        return false
    }

    if (order.forbidMinimumCommand && cart &&
        (
            ((order.minimumCommandUnit === "kg" || order.minimumCommandUnit === "Kg") && cart.weight < order.minimumCommand)
            || (order.minimumCommandUnit === "€" && cart.price < order.minimumCommand)
        )
    ) {
        return false
    }

    return true
}

export const ordersLineCsvHeader = {
    orderNumber: "Numéro de commande",
    deliveryNoteNumber: "Numéro de BL",
    supplierName: "Fournisseur",
    site: "Site de réception",
    receptionStatus: "Statut de la réception",
    expectedDeliveryDate: "Date de livraison attendue",
    receptionDate: "Date de réception",
    id: "ID de l'article",
    reference: "Référence de l'article",

    // orderSupplierItem
    name: "Nom de l'article",
    type: "Type d'article",
    stockUnit: "Unité de stock",
    unitStockWeight: "Poids d'une unité de stock (kg)",
    quantityOrders: "Quantité commandée",
    orderedWeight: "Poids commandé (kg)",
    receivedQuantity: "Quantité réceptionnée",
    receivedWeight: "Poids réceptionné (kg)",
    unitsStockPrice: "Prix unitaire attendu",
    totalPrice: "Prix total attendu",
    deliveryNoteQuantity: "Quantité BL",
    deliveryNoteWeight: "Poids BL (kg)",
    deliveryNoteUnitPrice: "Prix unitaire BL",
    totalPriceBL: "Prix total BL",
    invoicedQuantity: "Quantité facturée",
    invoicedWeight: "Poids facturé (kg)",
    invoicedUnitsStock: "Prix unitaire facturé",
    invoicedTotalPrice: "Prix total facturé"
}

const formatOrdersLineForCSV = (order, supplierItem) => {
    const deliveryNoteQuantity = order.receptionStatus === "DONE"
        ? supplierItem.quantities.deliveryNote || supplierItem.quantities.reception
        : ""

    return {
        orderNumber: order.orderNumber,
        deliveryNoteNumber: order.deliveryNote ? order.deliveryNote.number : "",
        supplierName: order.supplier.name,
        site: order.site.name,
        receptionStatus: order.receptionStatus ? getOptionLabel(order.receptionStatus) : "",
        expectedDeliveryDate: order.expectedDeliveryDate ? moment(order.expectedDeliveryDate).format("DD/MM/YYYY") : "",
        receptionDate: order.receptionDate ? moment(order.receptionDate).format("DD/MM/YYYY") : "",

        // orderSupplierItem
        name: supplierItem.supplierItem.name,
        type: getSupplierItemTypeLabel(supplierItem.supplierItem.type),
        id: supplierItem.supplierItem.supplierItemId,
        reference: supplierItem.supplierItem.reference || "",
        stockUnit: generateStockUnitLabel(supplierItem.supplierItem),
        unitStockWeight: supplierItem.supplierItem.units.stock.weight,
        quantityOrders: supplierItem.quantities.order || "0",
        orderedWeight: parseFloat((supplierItem.supplierItem.units.stock.weight * supplierItem.quantities.order).toFixed(3)) || "0",
        receivedQuantity: supplierItem.quantities.reception || "",
        receivedWeight: parseFloat((supplierItem.supplierItem.units.stock.weight * supplierItem.quantities.reception).toFixed(3)) || "0",
        unitsStockPrice: parseFloat((supplierItem.supplierItem.units.stock.price).toFixed(3)),
        totalPrice: parseFloat((supplierItem.supplierItem.units.stock.price * supplierItem.quantities.reception).toFixed(3)),
        deliveryNoteQuantity: deliveryNoteQuantity ? parseFloat(deliveryNoteQuantity.toFixed(3)) : "",
        deliveryNoteWeight: deliveryNoteQuantity ? parseFloat((deliveryNoteQuantity * supplierItem.supplierItem.units.stock.weight).toFixed(3)) : "",
        deliveryNoteUnitPrice: supplierItem.deliveryNoteUnitPrice ? parseFloat((supplierItem.deliveryNoteUnitPrice).toFixed(3)) : "",
        totalPriceBL: deliveryNoteQuantity && supplierItem.deliveryNoteUnitPrice ? parseFloat((supplierItem.deliveryNoteUnitPrice * deliveryNoteQuantity).toFixed(3)) : "",
        invoicedQuantity: supplierItem.quantities.bill || "",
        invoicedWeight: supplierItem.quantities.bill ? supplierItem.supplierItem.units.stock.weight * supplierItem.quantities.bill : "",
        invoicedUnitsStock: supplierItem.billUnitPrice || "",
        invoicedTotalPrice: supplierItem.quantities.bill && supplierItem.billUnitPrice ? supplierItem.quantities.bill * supplierItem.billUnitPrice : ""
    }
}

export const computeOrdersLineSupplierItemsForCSV = (orders) => {
    const data = []

    for (const order of orders) {
        for (const supplierItem of order.supplierItems) {
            if (supplierItem.supplierItem && supplierItem.supplierItem.units && supplierItem.supplierItem.units.stock) {
                data.push(formatOrdersLineForCSV(order, supplierItem))
            }
        }
    }
    return data
}

export const getOrderUpdateStatus = (order) => {
    let status
    if (order && order.orderStatus === "DONE") {
        status = orderUpdateStatus.TO_CANCEL
    } else {
        status = orderUpdateStatus.UPDATE
    }

    return status
}

export const getDateDeliveryErrorMessage = (expectedDateDelivery, minDateDelivery, order) => {
    const indexOfSelectedDay = dayNumber(expectedDateDelivery)

    if (minDateDelivery) {
        if (expectedDateDelivery.isBefore(order.minDateDelivery, "day")) {
            return `La date de livraison choisie ne respecte pas le délai de commande. La première date de livraison possible est le ${(order.minDateDelivery.format("DD/MM/YYYY"))}`
        } else if (!order.deliveryDays.includes(indexOfSelectedDay)) {
            return `Le fournisseur ne peut pas livrer à la date de livraison choisie. Les jours de livraison sont ${(getSupplierDeliveryDays(order.deliveryDays))}`
        }
    }

    return ""
}

//because moment sets sunday day as 0
export const dayNumber = (dayDate) => {
    return (dayDate.day() === 0) ? 7 : dayDate.day()
}


export const getStockQuantity = async (card) => {
    let stockQuantity = 0
    const orderSupplierItems = await getOrderSupplierItemsBySupplierItem(card.objectId, false)
    if (orderSupplierItems.length > 0) {
        const lots = await getLotsByOrderSupplierItem(orderSupplierItems)
        lots.forEach(lot => {
            const quantity = lot.quantity * (lot.orderSupplierItem.units ? lot.orderSupplierItem.units.stock.weight : 1)
            stockQuantity += quantity
        })
    }

    return stockQuantity
}