import React from "react"
import { Stack, Typography } from "@mui/material"
import { StyledPaper, StyledPaperTitle, StyledPaperLabel } from "../../../../components/StyledSingleParseObjectComponents"
import GenericEditIcon from "../../../../components/GenericEditIcon"

const DistributionCenterDates = ({ openDate, onEdit = null }) => {

	return (
		<StyledPaper>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center">
				<StyledPaperTitle>
					Jour d'inauguration
				</StyledPaperTitle>
				<GenericEditIcon onEdit={onEdit} />
			</Stack>
			<Stack gap="16px">
				<Stack direction="row">
					<StyledPaperLabel>Date de l'inauguration</StyledPaperLabel>
					<Typography>{openDate}</Typography>
				</Stack>
			</Stack>
		</StyledPaper>
	)
}

export default DistributionCenterDates