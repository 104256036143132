import { escapeRegExp } from "lodash"
import Parse from "parse"

export const MachineTypes = Parse.Object.extend("MachineTypes")

export const getMachineTypesById = async (id, includes=["machineModels"], toJson = true) => {
  try {
      const machineType = await new Parse.Query(MachineTypes)
          .equalTo("objectId", id)
          .include(includes)
          .first()

      return toJson ? machineType.toJSON() : machineType
  }
  catch (e) {
      console.error("parseManager.machine.machineTypesManager.getMachineTypesById error : ", e)
      return Promise.reject(e) 
  }
}

export const createMachineType = async (values, toJSON = true) => {
    try {
        const machineType = new MachineTypes()

        machineType.set("name", values.name)

        const newMachineTypes = await machineType.save()
        return toJSON ? newMachineTypes.toJSON() : newMachineTypes
    }
    catch (e) {
      console.error("parseManager.machine.machineTypesManager.createMachineType error : ", e)
      return Promise.reject(e)
    }
}

export const getMachineTypes = async (filters, includes = ["machineModels"], toJson = true) => {
    try {
        const query = await new Parse.Query(MachineTypes)
            .include(includes)

            if (filters?.search) {
                const regex = new RegExp(escapeRegExp(filters.search), "ig")
                query.matches("name", regex)
            }

        const machineTypes = await query.find()

        return toJson ? machineTypes.map(machineType => machineType.toJSON())  : machineTypes
    }
    catch (e) {
        console.error("parseManager.machine.machineTypesManager.getMachineTypes error : ", e)
        return Promise.reject(e) 
    }
}

