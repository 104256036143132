import React, { PureComponent } from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import FormControl from "@mui/material/FormControl"
import {
    TableHead,
    TableBody,
    TableRow,
    TableContainer,
    TableCell,
    Table
} from "@mui/material"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"

import { reportRulesInitialValues } from "../../../../actions/Utils/utils"
import { KFC_BRANDS, COLORS, ACTIVE_KFC_BRANDS } from "../../../../utils"
import Checkbox from "../../../../components/CheckBoxField"
import Radio from "../../../../components/RadioField"
import ReduxTextField from "../../../../components/form/ReduxTextField"
import { updateReportRules } from "../../../../actions/Planning/Settings/PlanningReport"
import { reportSettingValidator } from "../../../../utils/validators"

const styles = {
    root: {
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex"
    },
    formContainer: {
        width: "100%",
    },
    formFieldsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: 10,
        margin: 25
    },
    buttonContainer: {
        composes: "flexRow",
        justifyContent: "flex-end"
    },
    subContainer: {
        display: "inline-flex",
        width: "100%",
    },
    subContainerTitle: {
        margin: 50,
        width: 150,
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    brandTitle: {
        lineHeight: "32px",
        color: COLORS.WHITE,
        paddingLeft: 16,
        borderRadius: "4px 4px 0px 0px"
    },
    brandFormContainer: {
        border: `1px solid ${COLORS.MEDIUM_GREY}`,
        width: "100%",
        borderRadius: 4
    },
    subPartContainer: {
        margin: "10px 32px",
        display: "flex",
        flexDirection: "column"
    },
    subPartTitle: {
        textDecoration: "underline"
    },
    field: {
        marginTop: 0,
        marginBottom: 0,
    },
    subFormContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    subFormLabel: {
        justifyContent: "center",
        verticalAlign: "middle",
        marginTop: 16
    },
    actionButton: {
        margin: 25
    },
    otherBrandField: {
        marginTop: 0,
        marginBottom: 0,
    },
    otherBrandSubFormLabel: {
        composes: "$subFormContainer",
        width: 120
    },
    otherBrandSubFormContainer: {
        composes: "$subFormContainer",
        justifyContent: "flex-end"
    },
    otherBrandsContainer: {
        width: 120
    },
    otherBrandsSubFormLabel: {
        alignSelf: "baseline",
        marginTop: 16
    }
}

export class PlanningSettingReportForm extends PureComponent {

    searchInMyMap = (src, dest, values) => {
        const array = values.filter(elem => elem.src === dest)
        if (values.length === KFC_BRANDS.length - 1) {
            return true
        }
        for (const idx in array) {
            const elem = array[idx]
            if (elem.src === dest || elem.dest === src) {
                return true
            }
        }
        const secArray = values.filter(elem => elem.dest === src)
        for (const idx in secArray) {
            const elem = secArray[idx]
            if (elem.dest === src && elem.src === dest) {
                return true
            }
        }
        return false
    }

    reportBrandCheckBoxShouldBeDisabled = (currentBrand, other) => {
        const { stateForm : form } = this.props
        
        if (form && form.reportRules) {
            if (form.reportRules.values[currentBrand.name][`isReportingTo${other.name}`]) {
                return false
            }

            if (form.reportRules.values[other.name][`isReportingTo${currentBrand.name}`]) {
                return true
            }

            const brands = KFC_BRANDS.map(brand => brand.name)
            const values = []

            Object.keys(form.reportRules.values).forEach(key => {
                brands.forEach(brand => {
                    const str = key + brand
                    if (form.reportRules.values[key][str]) {
                        values.push({src: key, dest: brand})
                    }
                })
            })

            return this.searchInMyMap(currentBrand.name, other.name, values)
        }

        return false
    }

    reportVolumeRadioShouldBeDisabled = (currentBrand, otherBrand) => {
        return this.getField(otherBrand.name, "volumeReporting") === currentBrand.name
    }

    getField = (brandName, fieldName) => {
        const { stateForm : form } = this.props
    
        if (
            form && form.reportRules &&
            form.reportRules.values && form.reportRules.values[brandName]
        ) {
            return form.reportRules.values[brandName][fieldName]
        }

        return null
    }

    onChangeIsReporting = (event, brand, otherBrands) => {
        if (event && event.target && !event.target.checked) {
            for (const idx in otherBrands) {
                const other = otherBrands[idx]
                const str = "isReportingTo" + other.name
                this.props.change(`${brand.name}.${str}`, false)
                this.props.change(`${brand.name}.autoSaveableBrands.${other.name}`, null)
            }
        }
    }
    
    onChangeSelectedBrand = (event, brand, selectedBrand) => {
        if (event && event.target && !event.target.checked) {
            this.props.change(`${brand.name}.autoSaveableBrands.${selectedBrand.name}`, null)
        }
    }

    onChangeIsReportingVolume = (event, brand) => {
        if (event && event.target && !event.target.checked) {
            this.props.change(`${brand.name}.volumeReporting`, null)
        }
    }

    render() {
        const {
            classes,
            handleSubmit,
            updateReportRules
        } = this.props

        return (
            <Paper className={classes.root} elevation={0}>
                <form onSubmit={handleSubmit(updateReportRules)} className={classes.formContainer}>
                    <div className={classes.formFieldsContainer}>
                    {
                        ACTIVE_KFC_BRANDS.map((brand, index) => {
                            const otherBrands = ACTIVE_KFC_BRANDS.filter(other => other.name !== brand.name)
                            return (
                                <div className={classes.brandFormContainer} key={index}>
                                    <div className={classes.brandTitle} style={{backgroundColor: brand.color}}>
                                        {brand.label}
                                    </div>
                                    <div className={classes.subPartContainer}>
                                        <TableContainer>
                                            <Table className={classes.table} aria-label="report rules table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">Décalage en jours antérieurs</TableCell>
                                                        <TableCell align="right">Planning Vente</TableCell>
                                                        <TableCell align="right">Volume</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Se reporte</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">
                                                            <FormControl
                                                                margin="normal"
                                                                className={classes.field}
                                                            >
                                                                <Field
                                                                    name={`${brand.name}.isReporting`}
                                                                    type="checkbox"
                                                                    component={Checkbox}
                                                                    onChange={(event) => this.onChangeIsReporting(event, brand, otherBrands)}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <FormControl
                                                                margin="normal"
                                                                className={classes.field}
                                                            >
                                                                <Field
                                                                    name={`${brand.name}.isReportingVolume`}
                                                                    type="checkbox"
                                                                    component={Checkbox}
                                                                    onChange={(event) => this.onChangeIsReportingVolume(event, brand)}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        otherBrands.map((other, index) => {
                                                            const shouldReportBeDisabled = ! this.getField(brand.name, "isReporting") || this.reportBrandCheckBoxShouldBeDisabled(brand, other)
                                                            const selectedVolumeReport   = this.getField(brand.name, "volumeReporting")
                                                            const shouldVolumeBeDisabled = (! selectedVolumeReport && ! this.getField(brand.name, "isReportingVolume")) || this.reportVolumeRadioShouldBeDisabled(brand, other)
                                                            const disabledReport = !this.getField(brand.name, `isReportingTo${other.name}`)

                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell>{other.label}</TableCell>
                                                                    <TableCell align="right">
                                                                        <FormControl
                                                                            margin="normal"
                                                                            className={classes.field}
                                                                            required={!disabledReport}
                                                                        >
                                                                            <Field
                                                                                name={`${brand.name}.autoSaveableBrands.${other.name}.saleDate`}
                                                                                type="number"
                                                                                component={ReduxTextField}
                                                                                disabled={disabledReport}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <FormControl
                                                                            margin="normal"
                                                                            className={classes.otherBrandField}
                                                                        >
                                                                            <Field
                                                                                name={`${brand.name}.isReportingTo${other.name}`}
                                                                                type="checkbox"
                                                                                component={Checkbox}
                                                                                disabled={shouldReportBeDisabled}
                                                                                onChange={(e) => this.onChangeSelectedBrand(e, brand, other)}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <FormControl
                                                                            margin="normal"
                                                                            className={classes.otherBrandField}
                                                                        >
                                                                            <Field
                                                                                name={`${brand.name}.volumeReporting`}
                                                                                type="radio"
                                                                                props={{value: `${other.name}`, selectedValue: selectedVolumeReport}}
                                                                                component={Radio}
                                                                                disabled={shouldVolumeBeDisabled}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <Divider/>
                                    <div className={classes.subPartContainer}>
                                        <span className={classes.subPartTitle}>
                                            Interne
                                        </span>
                                        <div className={classes.subFormContainer}>
                                            <span className={classes.subFormLabel}>
                                                Production: Jours avant la vente:
                                            </span>
                                            <FormControl
                                                margin="normal"
                                                className={classes.field}
                                                required>
                                                <Field
                                                    name={`${brand.name}.RECIPE.productionDate`}
                                                    type="number"
                                                    component={ReduxTextField}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={classes.subFormContainer}>
                                            <span className={classes.subFormLabel}>
                                                Barquettage: Jours après la prod:
                                            </span>
                                            <FormControl
                                                margin="normal"
                                                className={classes.field}
                                                required>
                                                <Field
                                                    name={`${brand.name}.RECIPE.packagingDate`}
                                                    type="number"
                                                    component={ReduxTextField}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <div className={classes.subPartContainer}>
                                        <span className={classes.subPartTitle}>
                                            Externe
                                        </span>
                                        <div className={classes.subFormContainer}>
                                            <span className={classes.subFormLabel}>
                                                Production: Jours avant la vente:
                                            </span>
                                            <FormControl
                                                margin="normal"
                                                className={classes.field}
                                                required>
                                                <Field
                                                    name={`${brand.name}.SUBCONTRACTORPRODUCT.productionDate`}
                                                    type="number"
                                                    component={ReduxTextField}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={classes.subFormContainer}>
                                            <span className={classes.subFormLabel}>
                                                Barquettage: Jours après la prod:
                                            </span>
                                            <FormControl
                                                margin="normal"
                                                className={classes.field}
                                                required>
                                                <Field
                                                    name={`${brand.name}.SUBCONTRACTORPRODUCT.packagingDate`}
                                                    type="number"
                                                    component={ReduxTextField}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            className={classes.actionButton}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </Paper>
        )
    }
}

const ReportForm = reduxForm({
    form: "reportRules",
    enableReinitialize: false,
    validate: reportSettingValidator
})(PlanningSettingReportForm)

const PlanningSettingReportFormWithStyles = withStyles(styles)(ReportForm)

export default connect((state) => {
    return {
        stateForm: state.form,
        initialValues: reportRulesInitialValues(state.planningSettings.reportRules),
        reportRules: state.planningSettings.reportRules,
    }
}, {
    updateReportRules
})(PlanningSettingReportFormWithStyles)
