import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import ButtonFlawAction from "./ButtonFlawAction"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import SelectFlaw from "./SelectFlaw"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  withSelection: {
    fontSize: 30,
    fontWeight: "normal",
    lineHeight: "128.91%",
    marginTop: 57,
    "&.is-tablet": {
      marginTop: 52,
      fontSize: 37,
    },
    "& p": {
      margin: 0,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  reasons: {
    paddingTop: 28,
    "&.is-tablet": {
      paddingTop: 35,
    },
  },
  input: {
    fontWeight: "normal",
    fontSize: 16,
    color: COLORS.BLACK_CARD,
    lineHeight: "24px",
    width: 406,
    marginTop: 57,
    textAlign: "center",
  },
  input1: {
    fontWeight: "normal",
    fontSize: 60,
    color: COLORS.BLACK_CARD,
    textAlign: "center",
    lineHeight: "70px",
    width: 126,
    marginTop: 12,
    padding: "0 12px",
  },
}))

const InputFlawNumberAndReason = (props) => {
  const { handleValidate, isTablet, setValue, lostActionInfos, flawProduct } =
    props
  const classes = useStyles()
  const inputRef = useRef()

  const [disabled, setDisabled] = useState(true)
  const [flawStatus, setFlawStatus] = useState("")
  const [volume, setVolume] = useState("")

  useEffect(() => inputRef.current.focus(), [])

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onVolumeChange = (e) => {
    lostActionInfos[2](e.target.value)
    setVolume(e.target.value)

    if (flawStatus !== "") setDisabled(e.target.value === "")
  }

  const onReasonChange = (status) => {
    lostActionInfos[1](status)
    setFlawStatus(status)

    if (volume !== "" && status !== "") setDisabled(false)
  }

  return (
    <form onSubmit={handleValidate}>
      {/* text summary */}
      <div className={clsx(classes.withSelection, { "is-tablet": isTablet })}>
        Combien de <span>{flawProduct}</span> cassés ?
      </div>

      {/* select flaw origin*/}
      <SelectFlaw
        className={classes.reasons}
        preselected={flawStatus}
        isTablet={isTablet}
        onReasonChange={onReasonChange}
      />

      {/* text summary */}
      <TextField
        inputProps={{
          className: clsx(classes.input1, { "is-tablet": isTablet }),
          placeholder: "000",
          type: "number",
          pattern: "[0-9]*",
        }}
        variant="standard"
        onChange={onVolumeChange}
        inputRef={inputRef}
      />

      {/* text summary */}
      <TextField
        variant="standard"
        inputProps={{
          className: classes.input,
          placeholder: "commentaire (facultatif)",
          type: "text",
        }}
        onChange={onChange}
      />

      {/* button submit */}
      <ButtonFlawAction isTablet={isTablet} disabled={disabled} isFlaw={true}>
        Je confirme {lostActionInfos ? lostActionInfos[0] : ""} casses
      </ButtonFlawAction>
    </form>
  )
}

export default InputFlawNumberAndReason
