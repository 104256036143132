import React from "react"
import { Select, MenuItem, Chip, styled } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles(() => ({
    select: {
        boxShadow: "none",
        "& .MuiInputBase-input, .MuiSelect-select, MuiInput-input:focus": {
            backgroundColor: "transparent",
            paddingRight: "0px"
        }
    },
    selected: {
        fontWeight: "bold"
    },
    softGrey: {
        backgroundColor: "#9B9B9B"
    },
    darkGrey: {
        backgroundColor: "#7C7C7C"
    },
    icon: {
        right: 28,
        color: "white"
    },
    menuItem: {
        fontSize: "14px",
        fontWeight: 500,
        "& .MuiButtonBase-root, .MuiMenuItem-root, .Mui-selected": {
            backgroundColor: "white"
        }
    }
}))

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "bgColor"
})(({ bgColor = "#9B9B9B" }) => ({
    backgroundColor: bgColor,
    borderRadius: "43px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "4px",
    padding: "0px 4px",
    width: "110px",
    maxWidth: "110px",
    fontWeight: 500,
    fontSize: "14px",
    color: "white"
}))


const options = [
    { key: "paid", value: "paid", name: "Payé" },
    { key: "unpaid", value: "unpaid", name: "Non payé" }
]
const CreditNotePaidSwitch = ({ paid, onChange }) => {

    const classes = useStyles()

    const handleValueChange = (value) => {
        const newInputValue = value === "paid" ? true : false
        onChange && onChange(newInputValue)
    }

    return (
        <Select
            variant="standard"
            disableUnderline
            className={classes.select}
            value={paid ? "paid" : "unpaid"}
            onChange={(e) => handleValueChange(e.target.value)}
            classes={{
                icon: classes.icon
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        width: "103px",
                        minWidth: "103px",
                        "& .MuiList-root, .MuiMenu-list": {
                            padding: "4px"
                        },
                        "& .MuiMenuItem-root": {
                            padding: "8px"
                        },
                    }
                }
            }}
            renderValue={(value) => {
                return (
                    <StyledChip
                        label={value === "paid" ? "Payé" : "Non payé"}
                        bgColor={value === "paid" ? "#51C473" : "#9B9B9B"}
                    >
                    </StyledChip>
                )
            }}
            onClick={e => e.stopPropagation()}>
            {options.map(option => (
                <MenuItem className={classes.menuItem} key={option.key} value={option.value}>
                    <span>{option.name}</span>
                </MenuItem>
            ))}
        </Select>

    )
}

export default CreditNotePaidSwitch