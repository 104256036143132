import Parse from "parse"
import { createSecondaryDLC, loadSecondaryDLCs, updateSecondaryDLCDate, updateSecondaryDLCFromInventory } from "../../parseManager/secondaryDLC/parseSecondaryDLCManager"
import { getSiteById, getSitesWithKitchenAreas } from "../../parseManager/site/parseSiteManager"
import { getGroupsIngredients } from "../../parseManager/ingredients/classifications/group/parseGroupManager"
import {
  actionWithLoader,
  onEnter,
  push,
  getReplacingPath,
  haveAccessRight
} from "../Utils/utils"
import dayjs from "dayjs"
import { getProductTypeOptions } from "../ProductTypes/productTypes"
import { removeLotsNotNeeded, sortLots } from "../../utils/lotInventoryUtils"
import { getSecondaryDLCsBySectionsSelector } from "../../reducers/SecondaryDLC/SecondaryDLC"
import { refreshSecondaryDLCsBySections } from "../../utils/secondaryDLCsUtils"

export const dlcSecondaryBaseUrl = "/secondaryDLC"


// -------------- ACTIONS ------------- //
export const loadSecondaryDLCFilterData = () => {
  return actionWithLoader(async dispatch => {
    const selectedSite = localStorage.getItem("selectedSecondaryDLCSite")
    const selectedKitchenArea = localStorage.getItem("selectedSecondaryDLCKitchenArea")
    const sites = await getSitesWithKitchenAreas()
    dispatch({
      type: "SECONDARY_DLC_SITES_LOADED",
      sites,
      selectedSite: selectedSite ? JSON.parse(selectedSite) : null,
      selectedKitchenArea: selectedKitchenArea ? JSON.parse(selectedKitchenArea) : null
    })
  })
}

export const updateSecondaryDLCFilters = (site, kitchenArea) => {
  const selectedSite = JSON.stringify(site)
  localStorage.setItem("selectedSecondaryDLCSite", selectedSite)
  if (kitchenArea) {
    const selectedKitchenArea = JSON.stringify(kitchenArea)
    localStorage.setItem("selectedSecondaryDLCKitchenArea", selectedKitchenArea)
  }
}

export const loadSecondaryDLCData = (params) => {
  return actionWithLoader(async dispatch => {

    const { siteId, kitchenAreaId } = params

    const [secondaryDLCs, site, groupIngredients, productTypesOptions] = await Promise.all([
      loadSecondaryDLCs({ siteId, kitchenAreaId, include: ["orderSupplierItem.commercialName", "lot"] }),
      getSiteById(siteId, "kitchenAreas.kitchenArea"),
      getGroupsIngredients(),
      getProductTypeOptions()
    ])

    const kitchenArea = site.kitchenAreas.find(kitchenArea => kitchenArea.kitchenArea.objectId === kitchenAreaId)

    const secondaryDLCsBySections = sortLots(secondaryDLCs, groupIngredients, null, productTypesOptions)

    const currentDate = dayjs.utc().startOf("day").valueOf()
    // warn the lot DLC 5 days before its dlc
    const warningDate = dayjs.utc().add(1, "day").startOf("day").valueOf()


    dispatch({
      type: "SECONDARY_DLCS_BY_SECTIONS_LOADED",
      secondaryDLCsBySections: removeLotsNotNeeded(secondaryDLCsBySections),
      currentDate,
      warningDate
    })
    dispatch({
      type: "SECONDARY_DLC_SITE_LOADED",
      site
    })
    dispatch({
      type: "SECONDARY_DLC_KITCHEN_AREA_LOADED",
      kitchenArea: kitchenArea?.kitchenArea
    })
    dispatch({
      type: "SECONDARY_DLC_GROUP_INGREDIENTS_LOADED",
      groupIngredients
    })
    dispatch({
      type: "SECONDARY_DLC_PRODUCT_TYPES_LOADED",
      productTypesOptions
    })
  })
}

export const updateSecondaryDLCQuantity = (secondaryDLCId, quantity) => {
  return actionWithLoader(async (dispatch, getState) => {
    try {
      const date = dayjs.utc().valueOf()
      const newEvent = {
        mode: "INVENTORY",
        user: Parse.User.current().toJSON(),
        date,
        quantity: quantity
      }
      const updatedSecondaryDLC = await updateSecondaryDLCFromInventory(secondaryDLCId, quantity, newEvent)

      // refresh secondaryDLCsBySections with updated document
      const secondaryDLCsBySections = getSecondaryDLCsBySectionsSelector(getState())

      const updatedSecondaryDLCBySections = refreshSecondaryDLCsBySections(secondaryDLCsBySections, updatedSecondaryDLC)

      dispatch({
        type: "SECONDARY_DLCS_BY_SECTIONS_LOADED",
        secondaryDLCsBySections: updatedSecondaryDLCBySections
      })
      dispatch({
        type: "LAST_SELECTED_SECONDARY_DLC_LOADED",
        lastSelectedSecondaryDLC: updatedSecondaryDLC
      })
      openSecondaryDLCSuccessSnackbar()(dispatch)
    } catch (e) {
      openSecondaryDLCErrorSnackbar()(dispatch)
    }
  })
}

export const createSecondaryDLCAction = (values) => {
  return actionWithLoader(async (dispatch) => {
    await createSecondaryDLC(values)

   await loadSecondaryDLCData({ siteId: values.site, kitchenAreaId: values.kitchenArea })(dispatch)

  })
}

export const updateSecondaryDLCSecondaryDLC = (secondaryDLCId, secondaryDLCDate) => {
  return actionWithLoader(async (dispatch, getState) => {
    try {
      const date = dayjs.utc().valueOf()
      const newEvent = {
        mode: "SECONDARY_DLC",
        user: Parse.User.current().toJSON(),
        date
      }
      const updatedSecondaryDLC = await updateSecondaryDLCDate(secondaryDLCId, secondaryDLCDate, newEvent)

      // refresh secondaryDLCsBySections with updated document
      const secondaryDLCsBySections = getSecondaryDLCsBySectionsSelector(getState())

      const updatedSecondaryDLCBySections = refreshSecondaryDLCsBySections(secondaryDLCsBySections, updatedSecondaryDLC)

      dispatch({
        type: "SECONDARY_DLCS_BY_SECTIONS_LOADED",
        secondaryDLCsBySections: updatedSecondaryDLCBySections
      })
      dispatch({
        type: "LAST_SELECTED_SECONDARY_DLC_LOADED",
        lastSelectedSecondaryDLC: updatedSecondaryDLC
      })
      openSecondaryDLCSuccessSnackbar()(dispatch)
    } catch (e) {
      openSecondaryDLCErrorSnackbar()(dispatch)
    }
  })
}

export const openSecondaryDLCSuccessSnackbar = (message = "Mise à jour effectuée") => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_SECONDARY_DLC_SNACKBAR",
      snackbar: { open: true, duration: 5000, type: "success", message }
    })
  }
}

export const openSecondaryDLCErrorSnackbar = (message = "Erreur lors de la mise à jour") => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_SECONDARY_DLC_SNACKBAR",
      snackbar: { open: true, duration: 5000, type: "error", message }
    })
  }
}

export const closeSecondaryDLCSnackbar = () => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_SECONDARY_DLC_SNACKBAR",
      snackbar: { open: false, message: "", type: "", duration: 0 }
    })
  }
}

// -------------- ROUTING ------------- //
export const showSecondaryDLCFilter = () => {
  return push(dlcSecondaryBaseUrl)
}

export const showSecondaryDLC = (siteId, kitchenAreaId) => {
  return push(`${dlcSecondaryBaseUrl}/${siteId}/${kitchenAreaId}`)
}

export const onEnterSecondaryDLCFilter = (store) => {
  return onEnter({
    store,
    actionThunk: loadSecondaryDLCFilterData,
    getReplacingPath: getReplacingPath({ needUser: true }),
    haveAccessRight: haveAccessRight({ readSecondaryDLC: true })
  })
}

export const onEnterSecondaryDLC = (store) => {
  return onEnter({
    store,
    actionThunk: loadSecondaryDLCData,
    getReplacingPath: getReplacingPath({ needUser: true }),
    haveAccessRight: haveAccessRight({ readSecondaryDLC: true })
  })
}

