import Parse from "parse"
import { parseLimitRequest } from "../../utils"
import { getMachineTypesById } from "./machineTypesManager"

export const MachineModels = Parse.Object.extend("MachineModels")

export const getMachineModelsById = async (id, includes=[], toJson = true) => {
  try {
      const machineModel = await new Parse.Query(MachineModels)
          .equalTo("objectId", id)
          .include(includes)
          .first()

      return toJson ? machineModel.toJSON() : machineModel
  }
  catch (e) {
      console.error("parseManager.machine.machineModelsManager.getMachineModelsById error : ", e)
      return Promise.reject(e) 
  }
}

export async function getMachineModels (include = [], toJson = true) {
	try {
		const machineModels = await new Parse.Query(MachineModels)
			.include(include)
			.limit(parseLimitRequest)
			.find() || []
		
		return toJson ? machineModels.map(lot => lot.toJSON()) : machineModels
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export const createMachineModel = async (values, machineTypeId, toJSON = true) => {
    try {
        // ------------ MachineModels ------------ //
        const machineModel = new MachineModels()

        machineModel.set("name", values.name)
        machineModel.set("capacity", values.capacity)
        machineModel.set("cleanTime", values.cleanTime)
        machineModel.set("availabilityRate", values.availabilityRate)

        const newMachineModel = await machineModel.save()

        // ------------ MachineType ------------ //
        // add the newly created machine model to machine type via an array relation
        const machineType = await getMachineTypesById(machineTypeId, [], false)
        let machineModels = []

        // the new machine model should be the first
        if (machineType.has("machineModels")) {
            machineModels = [newMachineModel, ...machineType.get("machineModels")]
        } else {
            machineModels = [newMachineModel]
        }
        machineType.set("machineModels", machineModels)
        await machineType.save()
 
        return toJSON ? newMachineModel.toJSON() : newMachineModel
    }
    catch (e) {
      console.error("parseManager.machine.machineModelsManager.createMachineModel error : ", e)
      return Promise.reject(e)
    }
}

export const updateMachineModel = async (id, values, toJSON = true) => {
    try {
        // ------------ MachineModels ------------ //
        const machineModel = await getMachineModelsById(id, [], false)

        machineModel.set("name", values.name)
        machineModel.set("capacity", values.capacity)
        machineModel.set("cleanTime", values.cleanTime)
        machineModel.set("availabilityRate", values.availabilityRate)

        const newMachineModel = await machineModel.save()
 
        return toJSON ? newMachineModel.toJSON() : newMachineModel
    }
    catch (e) {
      console.error("parseManager.machine.machineModelsManager.createMachineModel error : ", e)
      return Promise.reject(e)
    }
}