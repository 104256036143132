import React, { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Box } from "@mui/material"
import showBillingCreditNotesControl from "../../../actions/OrderReception/OrderReception"
import { extractFilteredBillingsCSVFile, loadFilteredBillings } from "../../../actions/BillingCreditNotes/BillingCreditNotes"
import { getBillingBillingsSelector, getBillingSitesParseObjectsSelector, getBillingSitesSelector, getBillingSuppliersSelector } from "../../../reducers/BillingCreditNotes/billingCreditNotes"
import { roundNumber } from "../../../utils"
import DataGrid from "../../../components/DataGrid/DataGrid"
import dayjs from "dayjs"
import { debounce } from "lodash"
import { prepareBillingCreditNotesFiltersForLoading } from "../../../utils/creditNoteUtils"
import _cloneDeep from "lodash/cloneDeep"
import { formatFiltersBeforeStorage, getDataGridFiltersStored } from "../../../utils/dataUtils"


const createData = (order) => {
    return {
        id: order.objectId,
        orderNumber: order.orderNumber,
        deliveryNoteNumber: order.deliveryNote ? order.deliveryNote.number : "",
        site: order.site.name,
        supplier: order.supplier.name,
        receptionDateUnique: order.receptionDate ? dayjs(order.receptionDate).format("DD/MM/YYYY") : "NO_DATE_FOUND",
        totalAmount: roundNumber(order.totalAmount, 2) + " €"
    }
}


const getSelectedOrder = (orders, order) => orders.find((o) => o.objectId === order.id)

const BillingTab = () => {

    const fixedAdditionalFilters = {
        receptionDate: {
            start: dayjs().startOf("month").startOf("day").toDate(),
            end: dayjs().endOf("day").toDate(),
            isRange: true
        }
    }

    const defaultAdditionalFilters = _cloneDeep(fixedAdditionalFilters)

    const dispatch = useDispatch()
    const orders = useSelector(getBillingBillingsSelector)
    const sites = useSelector(getBillingSitesSelector)
    const sitesParseObjects = useSelector(getBillingSitesParseObjectsSelector)
    const suppliers = useSelector(getBillingSuppliersSelector)

    const [ordersData, setOrdersData] = useState([])
    const [loadingFilters, setLoadingFilters] = useState(defaultAdditionalFilters)


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault()
            // Custom logic to handle the refresh
            localStorage.removeItem("useStoredDataGridFilters")
            localStorage.removeItem("dataGridFilters")
        }
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        }
    }, [])

    useEffect(() => {
        const ordersData = orders.map(createData)
        setOrdersData(ordersData)
    }, [orders])

    useEffect(() => {
        if (!sitesParseObjects || !sitesParseObjects.length) {
            return
        }
        reloadData(loadingFilters, sitesParseObjects)
    }, [loadingFilters, sitesParseObjects])

    const reloadData = debounce(async (loadingFilters, sitesParseObjects) => {
        await dispatch(loadFilteredBillings(sitesParseObjects, loadingFilters))
    }, 300)

    const handleBillingCreditNotesControl = useCallback((order) => {
        localStorage.setItem("useStoredDataGridFilters", "1")
        const selectedOrder = getSelectedOrder(orders, order)
        dispatch(showBillingCreditNotesControl(selectedOrder.site.objectId, selectedOrder.receptionDate, selectedOrder.objectId))
    }, [orders])


    const initDefaultFilters = () => {
        let defaultFilters = {}
        const useStoredDataGridFilters = localStorage.getItem("useStoredDataGridFilters")
        if (useStoredDataGridFilters && useStoredDataGridFilters === "1") {
            defaultFilters = getDataGridFiltersStored([], defaultAdditionalFilters, "billing")
        }

        return defaultFilters || {}
    }

    const defaultFilters = initDefaultFilters()

    const columnTableHeaders = [
        {
            key: "orderNumber",
            label: "Numéro de commande",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                strict: true
            }
        },
        {
            key: "deliveryNoteNumber",
            label: "N° de BL",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "text",
                fullText: true
            }
        },
        {
            key: "site",
            label: "Site",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                options: sites.map(site => ({ key: site.objectId, value: site.name, label: site.name }))
            },
        },
        {
            key: "supplier",
            label: "Fournisseur",
            dataType: "text",
            sortable: false,
            filter: {
                filterable: true,
                type: "select",
                withSearch: true,
                options: suppliers.map(supplier => ({ key: supplier.objectId, value: supplier.name, label: supplier.name }))
            },
        },
        {
            key: "receptionDateUnique",
            label: "Date de réception",
            dataType: "date",
            sortable: false,
            filter: {
                filterable: true,
                type: "date",
                strict: true
            }
        },
        {
            key: "totalAmount",
            label: "Montant des avoirs",
            dataType: "number",
            type: "number",
            sortable: true,
            filter: {
                filterable: false,
                type: "number"
            },
            render: (value) => {
                const amount = value.split(" ")[0]
                const euroLabel = value.split(" ")[1]
                return (
                    <div><span>{amount}</span><span style={{ color: "#7C7C7C" }}>{" " + euroLabel}</span></div>
                )
            }
        }
    ]

    const additionalFilters = [
        {
            key: "receptionDate",
            label: "Date de réception",
            formLabel: "Indiquer la période de réception que vous souhaitez filtrer",
            dataType: "dateRange",
            sortable: false,
            undeletable: true,
            filter: {
                filterable: true,
                type: "dateRange"
            },
        }
    ]

    const exportOrdersLineToCSV = useCallback(async () => {
        dispatch(extractFilteredBillingsCSVFile(loadingFilters, sitesParseObjects))
    }, [loadingFilters, sitesParseObjects])


    const moreMenus = [
        {
            label: "Extraire les lignes de commande",
            onClick: exportOrdersLineToCSV
        }
    ]

    const renderRecapRow = (data) => {
        const totalAmount = data.filter(elem => elem.totalAmount !== "").reduce((acc, row) => {
            const rowAmount = row.totalAmount.substring(0, row.totalAmount.length - 2)
            return acc + Number(rowAmount)
        }, 0)
        return (
            <Box sx={{
                display: "flex",
                padding: "16px",
                backgroundColor: "#EBF7EE",
                color: "#262626",
                alignItems: "center",
                gap: "8px",
                alignSelf: "stretch",
                fontSize: "16px",
                lineHeight: "22px"
            }}>
                Montant total des réceptions filtrées :
                <strong>
                    {roundNumber(totalAmount, 2)} €
                </strong>
            </Box>)
    }

    const prepareFiltersForLoading = (filters, sortBy = "asc", sortField) => {
        return prepareBillingCreditNotesFiltersForLoading({ filters, sortBy, sortField, sites, suppliers })
    }

    const handleFiltersChange = async (filters, sortBy = "asc", sortField) => {
        if (sortField === "") {
            sortField = "receptionDate"
        }

        const clonedFilters = prepareFiltersForLoading(filters, sortBy, sortField)
        if (Object.keys(filters).length > 0) {
            const formattedFilters = formatFiltersBeforeStorage(filters, "billing")
            localStorage.setItem("dataGridFilters", JSON.stringify(formattedFilters))
        }
        setLoadingFilters(clonedFilters)
    }

    return (
        <DataGrid
            columns={columnTableHeaders}
            data={ordersData}
            additionalFilters={additionalFilters}
            withFilters={true}
            defaultAdditionalFilters={defaultAdditionalFilters}
            withAdditionalFiltersOptions={false}
            title={"Contrôle facturation"}
            onRowClick={handleBillingCreditNotesControl}
            menus={moreMenus}
            renderRecapRow={renderRecapRow}
            asyncFilterFunction={handleFiltersChange}
            initialValues={defaultFilters}
            fixedAdditionalFilters={fixedAdditionalFilters}
            defaultPostResetFilter={true}
        />
    )
}

export default BillingTab
