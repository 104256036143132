import moment from "moment"
import dayjs from "dayjs"
import { getServerUrl, roundNumber } from "../../utils"
import { actionWithLoader, recipeSectionsFormInitialValues } from "../Utils/utils"
import { axiosCall } from "../../utils/axiosUtils"
import {
    loadProductionPlanning
} from "./Planning"
import {
    downloadFile
} from "../Utils/utils"
import {
    dataMapping, packagingSectionsLineCsvHeader
} from "../../utils/planning"
import {
    downloadZipPdf,
    downloadSinglePdf,
    downloadHeadPage
} from "../../utils/downloadPdf"
import { getRecipeById } from "../../parseManager/recipe/parseRecipeManager"
import { getProductTypeLabel } from "../../utils/dispatchUtils"
import { exportCSVFile } from "../Products/SearchAdvanced"
import { cloneDeep } from "lodash"
import { downloadSupplierItemsByReportCsv } from "../Supplier/supplierItems"
import { getRecipesForProductionReport } from "../../parseManager/planning/parsePlanningManager"

export const formatDataForProductionReport = async (recipesData) => {
    const clonedData = cloneDeep(recipesData)
    const recipesIds = recipesData.map((recipe) => recipe.data.id)
    const recipesJson = await getRecipesForProductionReport(recipesIds, true)
    clonedData.forEach((recipeData) => {
        delete recipeData.data.productionItems
        const recipeRef = recipesJson.find((recipe) => recipe.objectId === recipeData.data.id)
        recipeData.commercialName = recipeRef.commercialName
        recipeData.uniqueCode = recipeRef.uniqueCode
        recipeData.type = "recipe"
    })
    return clonedData
}

export function downloadProductionReportCsv(selectedDay, reloadDate, data) {
    return actionWithLoader(async (dispatch) => {
        if ((selectedDay && selectedDay[0]) || data) {
            if (!data) {
                data = dataMapping(selectedDay)
            }
            if (data.length) {
                try {
                    const formattedData = await formatDataForProductionReport(data)
                    const csvFileName = `Commande d'appoint - Extrait le ${dayjs().format("DD-MM-YY HH[h]mm")}`
                    await dispatch(downloadSupplierItemsByReportCsv({
                        data: formattedData,
                        csvFileName,
                        isRandD: true
                    }))
                    await dispatch(loadProductionPlanning(reloadDate))
                }
                catch (e) {
                    console.error(e.message)
                    dispatch({
                        type: "PLANNING_OPEN_SNACKBAR",
                        planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la fiche." }
                    })
                }
            }
        }
    })
}

export function printMultipleCards(date, type) {
    return async (dispatch) => {
        try {
            await downloadZipPdf(date, type, "PLANNING")
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement des fiches." }
            })
        }
    }
}

export function printHeadPage(date, type, format = "pdf") {
    return async (dispatch) => {
        try {
            await downloadHeadPage(date, type, format)
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la page de garde" }
            })
        }
    }
}

export function printPackagingSections(dayData, format = "csv") {
    return async (dispatch) => {
        try {
            const recipeSections = []

            for (const sectionsType of Object.values(dayData[0].cards)) {
                for (const sectionType of sectionsType) {
                    if (sectionType.itemType === "Recipe") {
                        const recipe = await getRecipeById(sectionType.itemId,
                            [
                                "sections",
                                "sections.steps.ingredients.cookingMode",
                                "sections.steps.ingredients.supplierItem.cookingModes.cookingMode",
                                "sections.steps.ingredients.supplierItem.commercialName",
                                "sections.steps.ingredients.supplierItem.commercialName.allergens"
                            ],
                            false)
                        const computedSection = recipeSectionsFormInitialValues(recipe)
                        for (const section of recipe.get("sections")) {
                            if (section.get("print") !== false) {
                                const currentComputedSection = computedSection.sections.find(computedSection => computedSection.id === section.id)
                                const row = {
                                    type: getProductTypeLabel(recipe.get("type")),
                                    uniqueCode: recipe.get("uniqueCode"),
                                    name: recipe.get("name"),
                                    sectionName: section.get("name"),
                                    sectionNetWeight: currentComputedSection.netWeight,
                                    sectionCost: roundNumber(currentComputedSection.cost, 3),
                                    sectionPricePerWeight: roundNumber(currentComputedSection.cost / currentComputedSection.netWeight, 3)
                                }
                                recipeSections.push(row)
                            }
                        }
                    }
                }
            }

            // may be need other format later
            if (format === "csv") {
                dispatch(exportCSVFile(packagingSectionsLineCsvHeader, recipeSections, "packagingSections"))
            }
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la page de garde" }
            })
        }
    }
}

export function printSingleCard(card, date, type) {
    return async (dispatch) => {
        try {
            await downloadSinglePdf(date, type, "PLANNING", card.itemId, card.itemType)
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement des fiches." }
            })
        }
    }
}

// TODO: this is not used anymore, maybe it will be re-used later
export const downloadReleaseVoucher = (date) => {
    return async (dispatch) => {
        try {
            const url = `${getServerUrl()}/productions/downloadReleaseVoucher?date=${date}`
            const result = await axiosCall("POST", url, {}, { "Content-Type": "application/json" }, "blob")

            if (result.status === 200) {
                const pdfUrl = window.URL.createObjectURL(new Blob([result.data]))
                const fileName = `Bon_de_sortie_${moment(date).format("DD-MM-YYYY")}.pdf`
                downloadFile(pdfUrl, fileName)

                return
            }

            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement du bon de sortie." }
            })
        } catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement du bon de sortie." }
            })
        }
    }
}

export function downloadTrackingVoucher(date) {
    return async (dispatch) => {
        try {
            const url = `${getServerUrl()}/productions/downloadTrackingVoucher?date=${date}`
            const result = await axiosCall("POST", url, {}, { "Content-type": "application/json" }, "blob")

            if (result.status === 200) {
                const pdfUrl = window.URL.createObjectURL(new Blob([result.data]))
                const fileName = `Bon_de_traçabilité_${moment(date).format("DD-MM-YYYY")}.pdf`
                downloadFile(pdfUrl, fileName)
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement du bon de traçabilité." }

                })
            }
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement du bon de traçabilité." }

            })
        }
    }
}

export function downloadNameCard(date) {
    return async (dispatch) => {
        try {
            const url = `${getServerUrl()}/productions/downloadNameCard?date=${date}`
            const result = await axiosCall("POST", url, {}, { "Content-type": "application/json" }, "blob")

            if (result.status === 200) {
                const pdfUrl = window.URL.createObjectURL(new Blob([result.data]))
                const fileName = `Fiche_noms_produits${moment(date).format("DD-MM-YYYY")}.pdf`
                downloadFile(pdfUrl, fileName)
            }
            else {
                dispatch({
                    type: "PLANNING_OPEN_SNACKBAR",
                    planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la fiche des noms produits." }
                })
            }
        }
        catch (e) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la fiche des noms produits." }
            })
        }
    }
}
