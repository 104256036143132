import React from "react"
import { useSelector, useDispatch } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import MealPlannerComponent from "../../components/MealPlanner/MealPlanner"
import {
    saveSelectedItems,
} from "../../api/mealPlanner"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

const styles = {
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    }
}

const MealPlanner = props => {
    const {
        params: { date, brand },
        router
    } = props

    const dispatch = useDispatch()

    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    return (
        <MealPlannerComponent
            date={date}
            onSubmit={selection => dispatch(saveSelectedItems(brand, date, selection))}
            onCancel={router && router.goBack}
            targetBrand={brand}
            productTypeOptions={productTypeOptions}
        />
    )
}

export default withStyles(styles)(MealPlanner)
