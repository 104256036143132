import React from "react"
import {
	useSelector,
	useDispatch
} from "react-redux"
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"

import { showSuppliersList } from "../../actions/Supplier/suppliers"
import { showSupplierItems } from "../../actions/Supplier/supplierItems"
import {
	CAN_READ_SUPPLIERS,
	CAN_READ_SUPPLIER_ITEMS
} from "../../utils/accessRights"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import PageTabs from "../../components/PageTabs"

// list of tab
const tabs = [
	{
			value: "suppliers",
			label: "Fournisseurs"
	},
	{
			value: "supplierItems",
			label: "Articles Fournisseurs"
	}
]

const SuppliersPages = (props) => {
	const {
		tabValue,
		children
	} = props
	
	const dispatch = useDispatch()
	
	const canReadSuppliers = useSelector(state => state.app.rights[CAN_READ_SUPPLIERS])
	const canReadSupplierItems = useSelector(state => state.app.rights[CAN_READ_SUPPLIER_ITEMS])

	const onTabChange = (event, tabValue) => {
		// supplierItems
    if (tabValue === tabs[1].value) {
			dispatch(showSupplierItems())
			return
		}

		// suppliers
		dispatch(showSuppliersList())
	}
	
	return (
		<PageTabs
			title={<PageHeaderTitle title="Fournisseurs & Articles" icon={<PeopleOutlineIcon />} color="#019b04" />}
			tabs={tabs}
			tabValue={tabValue}
			onTabChange={onTabChange}
		>
			{tabValue === "suppliers" && canReadSuppliers && children}
			{tabValue === "supplierItems" && canReadSupplierItems && children}
		</PageTabs>
	)
}

export default SuppliersPages
