import Parse from "parse"

const ProductPackaging = Parse.Object.extend("ProductsPackaging")

export async function getProductPackaging(date, packaging, toJSON = true) {
    try {
        const productsPackaging = (await new Parse.Query(ProductPackaging)
            .include(["productionItems", "productionItems.productDispatch"])
            .equalTo("packagingDate", date)
            .equalTo("packagingType", packaging)
            .find()) || []
        
        return toJSON ? productsPackaging.map(el => el.toJSON()) : productsPackaging
    }
    catch (e) {
        return Promise.reject(e)
    }
}


export async function updateProductPackagingVolume(productPackagingId, volume) {
    try {
        const productPackaging = await new Parse.Query(ProductPackaging)
            .equalTo("objectId", productPackagingId)
            .first()
        
        if (productPackaging){
            productPackaging.set("warehouseProduction", volume)
            productPackaging.set("status", "DONE")
            await productPackaging.save()
            
            return productPackaging.toJSON()
        }
    }
    catch (e) {
        return Promise.reject(e)
    }
}
