export const PRODUCTION_STEPS_COL_WIDTHS = [
  640,
  100,
  116,
  116,
  129,
  124,
  100,
  120,
  130,
  170,
  160,
  177,
  177,
  150,
  40 // delete button
]

// export const PRODUCTION_STEPS_FIST_COL_PL = 80
const SECTION_FIRST_COL_PL = 80
const STEP_FIRST_COL_PL_DIFF = 32
const STEP_COMPONENT_FIRST_COL_PL_DIFF = 60

export const PRODUCTION_STEPS_SPACINGS = {
  ACCORDION_EXPANDED_ICON_LEFT: 48,
  SECTION_FIRST_COL_PL,
  STEP_FIRST_COL_PL_DIFF,
  STEP_FIRST_COL_PL: SECTION_FIRST_COL_PL + STEP_FIRST_COL_PL_DIFF, // 112
  STEP_COMPONENT_FIRST_COL_PL_DIFF
}

export const COLS_OFFSETS = { // offsets from const PRODUCTION_STEPS_SPACINGS.STEP_FIRST_COL_PL
  stepComponents: 20,
  priorStepComponents: 38
}

export const ACCORDION_ICONS_OFFSETS = {
  section: 48,
  productionSteps: 80,
  stepComponents: 100
}

export const DEFAULT_ROW_PER_PAGE_OPTIONS = [5, 10, 25]
export const DEFAULT_FILTERS = { page: 0, rowsPerPage: 25 }

export const EDITION_PAGE_INITIAL_ACTIONS = { hasDeleted: false, hasEdited: false, hasAdded: false }

export const ALPHABETS = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
  "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
]

export const STOPWORDS = [
  // Prépositions
  "à",
  "de",
  "du",
  "en",
  "au",
  "aux",
  "pour",
  "par",
  "avec",
  "sans",
  "chez",
  "sous",
  "sur",
  "vers",
  "dans",
  "entre",
  "pendant",
  "jusque",
  "jusqu",
  "au-dessus de",
  "au-dessous de",
  "près de",
  "loin de",
  "autour de",
  "au milieu de",

  // Articles définis
  "le",
  "la",
  "les",

  // Articles indéfinis
  "un",
  "une",
  "des",

  // Pronoms personnels sujets
  "je",
  "tu",
  "il",
  "elle",
  "nous",
  "vous",
  "ils",
  "elles",

  // Pronoms personnels compléments
  "me",
  "te",
  "le",
  "la",
  "lui",
  "nous",
  "vous",
  "les",
  "leur",

  // Pronoms possessifs
  "mon",
  "ma",
  "mes",
  "ton",
  "ta",
  "tes",
  "son",
  "sa",
  "ses",
  "notre",
  "nos",
  "votre",
  "vos",
  "leur",
  "leurs",

  // Pronoms démonstratifs
  "ce",
  "cette",
  "ces",

  // Pronoms interrogatifs
  "quel",
  "quelle",
  "quels",
  "quelles",

  // Pronoms relatifs
  "qui",
  "que",
  "quoi",
  "dont",
  "où",

  // Pronoms indéfinis
  "rien",
  "chacun",
  "tout",
  "tous",
  "toutes",

  // contractés
  "l",
  "d",
  "s",
  "c",
  "j",

  // conjonctions
  "&",
  "et"
]