import React, { useState, useEffect, useLayoutEffect } from "react"
import { Modal } from "@mui/material"
import { useSelector, connect } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"

import InventoryHeader from "../../components/Reception/Inventory/InventoryHeader"
import GlobalStyles from "../../components/Reception/GlobalStyle"
import LeftSideWrapper from "../../components/Reception/LeftSideWrapper"
import RightSideWrapper from "../../components/Reception/RightSideWrapper"
import FullScreenWrapper from "../../components/FullScreenWrapper"
import Filter from "../../components/Reception/Filter"
import GroupItems from "../../components/Reception/GroupItems"
import ButtonFlaw from "../../components/Reception/ButtonFlaw"
import ActionContent from "../../components/Reception/ActionContent"
import ModalWrapper from "../../components/Reception/ModalWrapper"
import ActionHeaderText from "../../components/Reception/ActionHeaderText"
import ReceptionSnackBar from "../../components/Reception/ReceptionSnackBar"
import { checkTablet } from "../../utils"
import {
	closeInventoryDetailSnackBar,
	showInventoryInterface,
	updateProductStock,
	updateStatus,
	filterByStatus,
	filterByNameOrCode,
	resetStatus,
	showErrors,
	loadInventory,
	sendProductPlacement
} from "../../actions/Reception/reception"
import { CAN_UPDATE_PRODUCT_STOCK } from "../../utils/accessRights"
import { findProductStock, getProductStock } from "../../utils/receptionUtils"
import ActionFlawContent from "../../components/Reception/ActionFlawContent"
import { setSearchValue } from "../../utils"
import RoundedAddButton from "../../components/RoundedAddButton"
import InventoryAddModal from "./InventoryAddModal"

import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

const useStyles = makeStyles({
	filter: {
		position: "relative",
		marginTop: 64,
		width: "57%",
		flexWrap: "wrap"
	}
})

const Inventory = (props) => {
	const {
		selectedHub,
		formattedInventoryData,
		update,
		showInventoryInterface,
		resetStatus,
		filterByStatus,
		filterByNameOrCode,
		updateStatus,
		updateProductStock,
		inventorySnackBar,
		closeInventoryDetailSnackBar,
		originalInventoryData,
		showErrors,
		canEdit,
		noDisplay,
		loadInventory,
		sendProductPlacement
	} = props

	const classes = useStyles()

	// constants
	const hub = (selectedHub) ? selectedHub[0].name : ""

	const subcontractorsProducts = useSelector(state => state.subcontractorsProducts.subcontractorsProductsList)
	const productTypeOptions = useSelector(getProductTypeOptionsSelector)

	// selected card state
	const [selectedCard, setSelectedCard] = useState([])
	const [open, setOpen] = useState(false)
	const [step, setStep] = useState(0)
	const [stepFlaw, setStepFlaw] = useState(0)
	const [status, setStatus] = useState("ALL")
	const [firstInput, setFirstInput] = useState()
	const [confirmInput, setConfirmInput] = useState()
	const [inventoryData, setInventoryData] = useState(formattedInventoryData)
	const [lostInput, setLostInput] = useState("")
	const [lostReason, setLostReason] = useState()
	const [lostVolume, setLostVolume] = useState()
	const [flawProduct, setFlawProduct] = useState()
	const [flawDlc, setFlawDlc] = useState("")
	const [selectedFlaw, setSelectedFlaw] = useState([])
	const [focusSearch, setFocusSearch] = useState(true)
	const [searchValue, updateSearchValue] = useState("")
	const [openModal, setOpenModal] = useState(false)

	// for device connected
	const [isTablet, setIsTablet] = useState(checkTablet)

	// effect on inventoryData change
	// type array of objects
	useEffect(() => {
		setInventoryData(formattedInventoryData)
	}, [update])

	// back to filter if no hub selected
	useEffect(() => {
		if (!selectedHub) {
			showInventoryInterface()
		}

		const storage = JSON.parse(localStorage.getItem("searchValue"))
		if (storage && storage.inventory) {
			updateSearchValue(storage.inventory)
			filterByNameOrCode(storage.inventory, true)
		}
	}, [])

	// focus search input
	useEffect(() => {
		if (step === 0) setFocusSearch(true)
		if (step !== 0) setFocusSearch(false)
	}, [step])

	// set isTablet state
	useLayoutEffect(() => {
		const checkisTablet = () => {
			setIsTablet(checkTablet())
		}

		document.title = "Inventaire - KFC"

		// check size
		window.addEventListener("resize", checkisTablet)

		// remove check
		return () => {
			window.removeEventListener("resize", checkisTablet)
			document.title = "KFC"
		}
	}, [])

	// open modal
	const handleClickOpenModal = () => {
		setOpen(true)
	}

	// close modal
	const handleCloseModal = async () => {
		if (stepFlaw > 0) {
			setStepFlaw(0)
			resetLost()

			if (open) setOpen(false)
			return
		}

		setStep((step === 0) ? 0 : step - 1)

		// close modal then undo selection
		if (step === 1) {
			await resetStatus(selectedCard, true)
			resetSelection()
		}
	}

	const resetSelection = () => {
		setSelectedCard([])

		if (open) {
			setOpen(false)
		}
	}

	// on filter by status
	const onStatusChange = (selectedStatus) => {
		setStatus(selectedStatus)
		filterByStatus(selectedStatus, true)
	}

	// on filter by status
	const onNameChange = (nameOrCode) => {
		filterByNameOrCode(nameOrCode, true)
		setSearchValue(nameOrCode, "inventory")
	}

	// on set flaw
	const onSetFlaw = () => {
		if (canEdit) {
			setStepFlaw(1)

			// open action in modal
			if (isTablet) {
				handleClickOpenModal()
			}
		}
	}

	// action on page quit
	const onClose = () => {
		filterByNameOrCode("", true)
		setSearchValue("", "inventory")
		showInventoryInterface()
	}

	const handleCardClick = async (element) => {
		if (selectedCard.length !== 0) await resetStatus(selectedCard, true)
		await updateStatus(element, true)

		setSelectedCard(element)
		setStep(1)

		// open action in modal
		if (isTablet) {
			handleClickOpenModal()
		}
	}

	const directValidation = async (input) => {
		await updateProductStock(selectedCard, input, null, "INVENTORY")
		resetSelection()
		setStep(0)
	}

	const handleValidate = async (e) => {
		e.preventDefault()

		if (step === 1) {
			if (selectedCard.events.length > 0) {
				const receptionElement = selectedCard.events.filter(elm => elm.type === "RECEPTION")
				if (receptionElement[0] && receptionElement[0].data.volume < parseInt(firstInput)) {
					setStep(2)
					return
				}
			}

			directValidation(firstInput)
			return
		}

		if (step === 2) {
			directValidation(confirmInput)
			return
		}
	}

	const handleUpdatePreexistingInventoryItem = async (code, dlc) => {
		const elem = getProductStock(originalInventoryData, code, dlc)
		if (!elem.volume) {
			await updateProductStock(elem, 0, 0, "INVENTORY")
		}
	}

	const handleFlawValidate = async (e) => {
		e.preventDefault()

		let products

		switch (stepFlaw) {
			case 1:
				products = findProductStock(originalInventoryData, flawProduct)
				setSelectedFlaw(products)

				if (products.length === 0) showErrors("Ce produit n'est pas sensé être en vente", true)
				if (products.length === 1) {
					if (products[0].receptionStatus !== "DONE") {
						showErrors("Ce produit n'est pas encore réceptionné", true)
						return
					}
					setStepFlaw(3)
				}
				if (products.length > 1) setStepFlaw(2)

				return
			case 2:
				products = findProductStock(originalInventoryData, flawProduct, flawDlc)
				setSelectedFlaw(products)

				if (products.length === 0) showErrors("Aucun Produit trouvé", true)
				if (products.length > 0) {
					if (products[0].receptionStatus !== "DONE") {
						showErrors("Ce produit n'est pas encore réceptionné", true)
						return
					}

					setStepFlaw(3)
				}

				return
			case 3:
				if (lostReason === "") {
					showErrors("Veuillez sélectionner un motif")
					return
				}

				const lost = {
					"volume": lostVolume,
					"reason": lostReason,
					"explanation": lostInput,
					"isInventory": true
				}
				await updateProductStock(selectedFlaw[0], lostVolume, lost, "LOSS")
				setStepFlaw(0)
				resetLost()

				return
			default:
				setStepFlaw(stepFlaw + 1)
		}
	}

	const inputActionChange = (value) => {
		if (step === 1) setFirstInput(value)
		if (step === 2) setConfirmInput(value)
	}

	const inputFlawActionChange = (value) => {
		if (stepFlaw === 1) setFlawProduct(value)
		if (stepFlaw === 2) setFlawDlc(value)
		if (stepFlaw === 3) setLostInput(value)
	}

	const resetLost = () => {
		setLostVolume("")
		setLostInput("")
		setLostReason("")
		setFlawDlc("")
		setFlawProduct("")
	}

	const refresh = () => {
		const storage = JSON.parse(localStorage.getItem("inventoryFilter"))
		if (storage) loadInventory(storage.brand, storage.hub)
	}

	const getAction = () => {
		if (stepFlaw > 0) {
			return (
				<ActionFlawContent
					stepFlaw={stepFlaw}
					handleClose={handleCloseModal}
					handleValidate={handleFlawValidate}
					setValue={inputFlawActionChange}
					isTablet={isTablet}
					flawProduct={flawProduct}
					flawDlc={flawDlc}
					lostActionInfos={[lostVolume, setLostReason, setLostVolume]}
				/>
			)
		}

		return (
			<ActionContent
				step={step}
				lostActionInfos={[]}
				textHeader={(
					<ActionHeaderText
						selectedCard={selectedCard}
						productTypeOptions={productTypeOptions}
					/>
				)}
				selectedCard={selectedCard}
				openModal={() => { }}
				handleClose={handleCloseModal}
				handleValidate={handleValidate}
				setValue={inputActionChange}
				isTablet={isTablet}
				isInventory={true}
				noDisplay={noDisplay}
			/>
		)
	}

	const listKeys = Object.keys(inventoryData)

	const onSendProductPlacement = () => {
		sendProductPlacement(selectedHub)
	}

	return (
		<FullScreenWrapper>
			{/* page header */}
			<InventoryHeader hub={hub} onClose={onClose} />
			<div className={classes.filter}>
				{/* filter upon device */}
				<Filter
					onStatusChange={onStatusChange}
					onNameChange={onNameChange}
					onSetFlaw={onSetFlaw}
					status={status}
					isTablet={isTablet}
					isInventory={true}
					refresh={refresh}
					focusSearch={focusSearch}
					searchValue={searchValue}
					sendProductPlacement={onSendProductPlacement}
				/>
			</div>

			<div>
				{
					stepFlaw === 0 &&
					<>
						{/* left side section */}
						<LeftSideWrapper fullWidth={isTablet}>
							<>
								{listKeys && listKeys.map((elm, i) => {
									return (
										<GroupItems
											key={elm}
											canEdit={canEdit}
											name={elm}
											list={inventoryData[elm]}
											last={(i === listKeys.length - 1)}
											isTablet={isTablet}
											onCardClick={handleCardClick}
											selectedCard={selectedCard}
											isInventory={true}
										/>
									)
								})
								}

								{/* Button for flaw (break) */}
								<ButtonFlaw isTablet={true} onSetFlaw={onSetFlaw} />
							</>
						</LeftSideWrapper>

						{/* Right side section */}
						{!isTablet &&
							<RightSideWrapper>
								{getAction()}
							</RightSideWrapper>
						}
					</>
				}

				{
					stepFlaw !== 0 && !isTablet && getAction()
				}

				{/* modal for input */}
				{isTablet &&
					<ModalWrapper
						open={open}
						handleClose={handleCloseModal}
					>
						{getAction()}
					</ModalWrapper>
				}
				<RoundedAddButton onClick={() => setOpenModal(true)} />
			</div>

			{
				openModal &&
				<Modal
					open={openModal}
					onClose={() => setOpenModal(false)}
					aria-labelledby="form-dialog-title"
				>
					<InventoryAddModal
						subcontractorsProducts={subcontractorsProducts}
						handleClose={() => setOpenModal(false)}
						handleUpdateItem={handleUpdatePreexistingInventoryItem}
						hub={selectedHub[0].objectId}
						refresh={refresh}
					/>
				</Modal>
			}

			{/* Error snackbar */}
			<ReceptionSnackBar data={inventorySnackBar} onClose={closeInventoryDetailSnackBar} />

			{/* apply styles adjustments */}
			<GlobalStyles />

		</FullScreenWrapper>
	)
}

export default connect((state) => {
	return {
		selectedHub: state.inventory.hub,
		formattedInventoryData: state.inventory.inventoryData,
		inventorySnackBar: state.inventory.inventoryDetailSnackBar,
		update: state.inventory.update,
		originalInventoryData: state.inventory.originalInventoryData,
		canEdit: state.app.rights[CAN_UPDATE_PRODUCT_STOCK],
		noDisplay: state.inventory.noDisplay
	}
}, {
	closeInventoryDetailSnackBar,
	showInventoryInterface,
	updateProductStock,
	updateStatus,
	resetStatus,
	filterByStatus,
	filterByNameOrCode,
	showErrors,
	loadInventory,
	sendProductPlacement
})(Inventory)