import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Checkbox, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, debounce } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { makeStyles } from "@mui/styles"
import UnhencedTextField from "./UnhencedTextField"

const useStyles = makeStyles(() => ({
  actionContainer: {
    padding: [[6, 9]],
    display: "flex",
    justifyContent: "space-between"
  },
  actionButton: {
    padding: 0,
    height: 20,
    minWidth: null,
    background: "none", // Remove background color
    boxShadow: "none", // Remove box shadow
    textTransform: "none", // Prevent text from transforming to uppercase
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline", // Underline text on hover
    },
  },
  listHead: {
    lineHeight: null
  }
}))

const UnhencedSelect = ({ onChange, options = [], multiple = false, value, withSearch = false, reset, label, debounceDelay = 1000, render, ...rest }) => {
  const [searchText, setSearchText] = useState("")
  const [inputValue, setInputValue] = useState(value)

  const classes = useStyles()

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const _debounceOnChange = useCallback(
    debounce((event) => {
      onChange && onChange(event)
    }, debounceDelay),
    []
  )

  const handleChange = (e) => {
    setInputValue(e.target.value)
    if (multiple) {
      _debounceOnChange(e)
    } else {
      onChange && onChange(e)
    }
  }

  const displayedOptions = useMemo(
    () => options.filter(option => String(option.label).toLowerCase().indexOf(searchText.toLowerCase()) > -1),
    [searchText, options]
  )

  const extraProps = {}

  if (withSearch) {
    extraProps.MenuProps = { autoFocus: false }
    extraProps.onClose = () => setSearchText("")
  }

  const _selectAll = () => {
    setInputValue(displayedOptions.map(option => option.value))
    _debounceOnChange({ target: { value: displayedOptions.map(option => option.value) } })
  }

  if (multiple && !Array.isArray(inputValue)) {
    return null
  }

  return (
    <SelectWrapper label={label} classes={classes}>
      <Select
        {...rest}
        {...extraProps}
        onChange={handleChange}
        value={inputValue}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map(item => render(item)).join(", ") : render(selected)}
      >
        <ListSubheader>
          {
            options.length > 20 &&
            <div className={classes.actionContainer}>
              <Button className={classes.actionButton} onClick={reset}>Effacer</Button>
              {
                multiple ?
                  <Button className={classes.actionButton} onClick={_selectAll}>Tout sélectionner</Button>
                  : null
              }
            </div>
          }
          {
            withSearch ?
              <UnhencedTextField
                variant="outlined"
                size="small"
                autoFocus
                placeholder="Rechercher"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
              />
              : null
          }
        </ListSubheader >
        {
          displayedOptions.map(option => multiple ? (
            <MenuItem key={option.key} value={option.value}>
              <Checkbox
                checked={Array.isArray(inputValue) ? inputValue.findIndex(selectedOption => option.value === selectedOption) > -1 : false}
                disableRipple
                style={{ padding: 4, marginRight: 6 }}
              />
              {option.label}
            </MenuItem>
          )
            : (
              <MenuItem key={option.key} value={option.value}>
                {option.label}
              </MenuItem>
            ))
        }
        {
          (options.length < 20) &&
          <div className={classes.actionContainer}>
            <Button className={classes.actionButton} onClick={reset}>Effacer</Button>
            {
              multiple ?
                <Button className={classes.actionButton} onClick={_selectAll}>Tout sélectionner</Button>
                : null
            }
          </div>
        }
      </Select>
    </SelectWrapper>
  )
}

export default UnhencedSelect

const SelectWrapper = ({ label, children }) => {
  return label
    ? (
      <FormControl fullWidth>
        <InputLabel htmlFor={label} variant="standard">
          {label}
        </InputLabel>
        {children}
      </FormControl>
    )
    : <>{children}</>
}