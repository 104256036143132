import React, {
  useState,
  useEffect,
  useCallback
} from "react"
import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import DataGrid from "../../../components/DataGrid/DataGrid"
import { showSubcontractorCommercialName, showSubcontractorCommercialNameForm } from "../../../actions/Ingredients/External/SubcontractorsCommercialNames"

const useStyles = makeStyles({
  fab: {
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
  },
})
const SubcontractorIngredients = () => {
  const subcontractorsIngredients = useSelector(state => state.subcontractorsCommercialNames.subcontractorsCommercialNamesList)
  const suppliers = useSelector(state => state.subcontractorsCommercialNames.suppliers)
  const families = useSelector(state => state.subcontractorsCommercialNames.familiesIngredients)
  const groups = useSelector(state => state.subcontractorsCommercialNames.groupsIngredients)

  const [data, setData] = useState([])

  const columns = [
    {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      },
      width: 130
    },
    {
      key: "name",
      label: "Nom",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "supplier",
      label: "Fournisseur",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: Array.isArray(suppliers) ? suppliers.map((supplier) => ({
          key: supplier.objectId,
          value: supplier.name,
          label: supplier.name
        })) : []
      },
      width: 130
    },
    {
      key: "family",
      label: "Famille d'ingrédient",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: Array.isArray(families) ? families.map((family) => ({
          key: family.objectId,
          value: family.name,
          label: family.name
        })) : []
      },
      width: 130
    },
    {
      key: "group",
      label: "Groupe d'ingrédient",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: Array.isArray(groups) ? groups.map((group) => ({
          key: group.objectId,
          value: group.name,
          label: group.name
        })) : []
      },
      width: 130
    },
    {
      key: "updatedAt",
      label: "Date de modification",
      dataType: "date",
      type: "date",
      sortable: true,
      filter: {
        filterable: false,
        type: "date"
      },
      width: 130
    }
  ]

  const classes = useStyles()
  const dispatch = useDispatch()

  const createData = (subcontractorIngredient) => {
    const serializedSubcontractorProduct = {
      id: subcontractorIngredient.id,
      name: subcontractorIngredient.get("name") || "",
      supplier: subcontractorIngredient.get("supplier")?.get("name") || "",
      family: subcontractorIngredient.get("group")?.get("family")?.get("name") || "",
      group: subcontractorIngredient.get("group")?.get("name") || "",
      updatedAt: subcontractorIngredient.updatedAt.toLocaleDateString("fr-FR", { year: "numeric", month: "numeric", day: "numeric" }),
    }

    return serializedSubcontractorProduct
  }

  const createDataObject = useCallback(() => {
    setData(subcontractorsIngredients.map(createData))
  }, [subcontractorsIngredients])

  useEffect(() => {
    createDataObject()
  }, [subcontractorsIngredients])

  const createExternalIngredient = () => {
    dispatch(showSubcontractorCommercialNameForm())
  }

  const _showSubcontractorIngredient = (subcontractorIngredient) => {
    dispatch(showSubcontractorCommercialName(subcontractorIngredient.id))
  }

  return (
    <>
      <DataGrid
        title="Ingrédients externes"
        columns={columns}
        data={data}
        withFilters
        rowLabel="ingrédients externes"
        onRowClick={_showSubcontractorIngredient}
      />
      <div className={classes.fab}>
        <Fab color="primary" onClick={createExternalIngredient}>
          <Add />
        </Fab>
      </div>
    </>
  )
}

export default SubcontractorIngredients