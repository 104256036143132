import React from "react"
import styled from "@emotion/styled"
import { red } from "@mui/material/colors"
import { Autocomplete, Box, InputBase, Select, TextField, TextareaAutosize, Typography } from "@mui/material"

import { PRODUCTION_STEPS_COL_WIDTHS, PRODUCTION_STEPS_SPACINGS } from "../../../utils/constant"
import { COLORS, getCellAlignment } from "../../../utils"

const widths = PRODUCTION_STEPS_COL_WIDTHS

export const lastColStickyStyle = {
  position: "sticky",
  right: 0,
}

export const stickyStyle = {
  position: "sticky",
  left: 0,
  borderRight: "1px solid #cccccc",
  zIndex: 3 // must be below StyledLeftBar
}

export const StyledErrorMessage = styled("div")({
  marginTop: 2,
  color: red[500],
  fontSize: 12
})

export const StyledStepBodyCell = styled(Box, {
  shouldForwardProp: (prop) => prop !== "align" && prop !== "width" && prop !== "px"
})(({ width, align, px = 16 }) => {
  let defaultStyles = {
    display: "flex",
    alignItems: "center",
    width,
    alignSelf: "stretch",
    margin: 0,
    paddingLeft: px,
    paddingRight: px,
  }

  if (align) {
    defaultStyles.justifyContent = getCellAlignment(align)
  }

  return defaultStyles
})

export const StyledProductionStepTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isHovered"
})(({ isHovered = false }) => ({
  height: 30,
  background: "#fff",
  borderRadius: 4,
  "& .MuiInputBase-root, .MuiAutocomplete-inputRoot.MuiInputBase-root": {
    height: "100%",
    "&:before, :after": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
    "& .MuiInputBase-input": {
      paddingLeft: 7
    }
  },
  "& .MuiInput-input, .MuiAutocomplete-input": isHovered
    ? {
      fontWeight: 600,
      fontSize: 14,
      color: "#414141"
    } : {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5,
      color: COLORS.PRODUCTION_STEPS_TEXT_GREY,
      textTransform: "lowercase !important"
    }
}))

export const StyledProductionStepsTextarea = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== "error"
})(({ theme, error = false }) => {
  const styles = {
    height: 30,
    background: "#fff",
    borderRadius: 4,
    fontWeight: 400,
    fontSize: 14,
    color: "#414141",
    width: "100%",
    fontFamily: "Roboto, sans-serif",
    lineHeight: 1.28,
  }

  if (error) {
    styles.borderColor = theme.palette.error.main
  }

  return styles
})

export const StyledProductionStepInputBase = styled(InputBase)({
  "& .MuiInputBase-input": {
    background: "#fff",
    borderRadius: 4,
    paddingLeft: 7,
    fontWeight: 600,
    fontSize: 14,
    color: "#414141",
    lineHeight: "1em",
    display: "flex",
    alignItems: "center",
    "&:before, :after": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
  },
})

// --------------------------------------- //
// -------------- Sections --------------- //
// --------------------------------------- //
export const StyledSectionFirstBodyColumn = styled((props) => (
  <Box {...props} sx={{ ...stickyStyle }} />
))({
  paddingLeft: PRODUCTION_STEPS_SPACINGS.SECTION_FIRST_COL_PL,
  paddingRight: 8,
  backgroundColor: COLORS.PRODUCTION_STEPS_BLUE,
  width: widths[0]
})

export const StyledStickyLastBodyColumn = styled(
  (props) => <Box {...props} sx={{ ...lastColStickyStyle }} />,
  {
    shouldForwardProp: (prop) => prop !== "type" && prop !== "addBackground"
  }
)(({ type = "section", addBackground = false }) => {
  const defaultStyle = {
    width: widths[widths.length - 1],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  if (addBackground) {
    if (type === "section") {
      defaultStyle.backgroundColor = COLORS.PRODUCTION_STEPS_BLUE
    }
    if (type === "step") {
      defaultStyle.backgroundColor = COLORS.PRODUCTION_STEPS_GREY
    }
    if (type === "reusableStepParent") {
      defaultStyle.backgroundColor = COLORS.YELLOW_REUSABLE_STEP_PARENT
    }
  }

  return defaultStyle
})

// --------------------------------------- //
// ---------------- Steps ---------------- //
// --------------------------------------- //
export const StyledStepFirstBodyColumn = styled(
  (props) => <Box {...props} sx={{ ...stickyStyle }} />,
  {
    shouldForwardProp: (prop) => prop !== "leftStep" & prop !== "bgcolor" && prop !== "isReusable"
  }
)(({ leftStep = 0, bgcolor = COLORS.PRODUCTION_STEPS_GREY, isReusable = false }) => ({
  paddingLeft: PRODUCTION_STEPS_SPACINGS.STEP_FIRST_COL_PL + leftStep,
  paddingRight: 8,
  backgroundColor: bgcolor,
  left: isReusable ? 8 : 0,
  width: isReusable ? widths[0] - 8 : widths[0], // 8 width of yellow styledLeftBar indicating a reusable step
  paddingTop: 16,
  paddingBottom: 15
}))

export const StyledStepText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "weight" && prop !== "fontSize" && prop !== "firstCol"
})(({ disabled = false, weight = 600, fontSize = 14, firstCol = false }) => {
  let defaultStyles = {
    fontWeight: weight,
    fontSize: fontSize,
    lineHeight: 1.5
  }

  if (disabled) {
    defaultStyles.color = COLORS.PRODUCTION_STEPS_DISABLE_TEXT
    if (firstCol) {
      defaultStyles.fontStyle = "italic"
    }
  } else {
    defaultStyles.color = COLORS.PRODUCTION_STEPS_TEXT_GREY
  }

  return defaultStyles
})

export const StyledStepDescriptionText = styled(StyledStepText)({
  fontWeight: 400
})

export const StyledProductionStepsSelect = styled((props) => (
  <Select {...props} />
), {
  shouldForwardProp: (prop) => prop !== "width"
})((props) => ({
  width: props.width
}))

export const StyledAutocompleteTextField = styled(TextField)({
  "& .MuiAutocomplete-inputRoot.MuiInputBase-root": {
    "&:before": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
    "& .MuiAutocomplete-input": {
      padding: 4
    }
  },
  "& .MuiInput-input": {
    fontWeight: 600,
    fontSize: 14,
    color: "#414141"
  }
})

export const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    width: 512,
    height: 30,
    background: "#fff",
    borderRadius: 4
  }
})