export const helpLinks = {
    login: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/M4dXiaZSr",
    site: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/Bd4wjWHGn",
    product: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/CLozXRYHAc",
    ingredient: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/qHfaGnkzyb",
    planning: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/ZtBNGpIFHE",
    warehouse: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/kO5kedX59I",
    hub: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/pvFVA7nZT4",
    rights: "https://foodcheri.slite.com/app/channels/7FWXx~qSfN/notes/P4uXslb3e"
}

export default helpLinks