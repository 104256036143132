import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { openUploadWidget } from "../../utils/cloudinary"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import {COLORS} from "../../utils"
import Typography from "@mui/material/Typography"

const useStyles =  makeStyles(() => ({
    container: {
        background: COLORS.SITE_BORDER,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 214,
        cursor: "pointer"
    },
    text: {
        paddingTop: 9,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "11px",
        color: COLORS.DARK_GREY
    },
    button: {
        fontWeight: "bold",
        fontSize: 24,
        width: 50,
        height: 50,
        borderRadius: "50%",
        backgroundColor: COLORS.BLUE_PAPER_SELECTED,
        "&:hover": {
            backgroundColor: COLORS.BLUE_PAPER_SELECTED
        }
    }
}))

const ImageAdd = props => {
    const {
        onUploadError,
        uploadOptions,
        onImageUploaded,
        width="100%",
        height="100%"
    } = props

    const classes =  useStyles()

    const uploadImageWithCloudinary = () => {
        const options = uploadOptions || {}

        openUploadWidget(options, (error, result) => {
            if (error) {
                return onUploadError && onUploadError(error)
            }
			if (result.event !== "success") {
				return
			}

            const publicId = result.info.public_id

            onImageUploaded && onImageUploaded(publicId)
        })
    }

    return (
        <div
            className={classes.container}
            onClick={uploadImageWithCloudinary}
            style={{
                width,
                height
            }}
        >
            <IconButton
                aria-label="Add"
                variant="contained"
                className={classes.button}
                size="large">
                <AddIcon htmlColor={COLORS.SITE_BORDER}/>
            </IconButton>
            <Typography variant="span" className={classes.text}>
                Ajouter une image
            </Typography>
        </div>
    )
}

export default ImageAdd
