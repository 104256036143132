import _capitalize from "lodash/capitalize"
import moment from "moment"
import { months } from "./recipes"

export const SORT_CRITERION = {
    price_asc: {
        key: "price",
        label: "prix de vente croissant",
    },
    price_desc: {
        key: "price",
        label: "prix de vente décroissant",
        desc: true
    },
    foodcost_asc: {
        key: "foodcost",
        label: "foodcost croissant",
    },
    foodcost_desc: {
        key: "foodcost",
        label: "foodcost décroissant",
        desc: true
    },
    _created_at: {
        key: "createdAt",
        label: "date de création",
        desc: true
    },
    _updated_at: {
        key: "updatedAt",
        label: "date de modification",
        desc: true
    },
    // rating_asc: {
    //     key: "rating",
    //     label: "note croissante *",
    // },
    // rating_desc: {
    //     key: "rating",
    //     label: "note décroissante *",
    //     desc: true
    // },
    nutriscore: {
        key: "nutriscore",
        label: "Nutriscore",
    },
    // isBestSeller: {
    //     key: "isBestSeller",
    //     label: "meilleure vente *",
    // },
    dlc: {
        key: "dlc",
        label: "DLC",
    },
}

export const AVAILABLE_FILTERS = {
    internalTag: {
        multiple: true,
        label: "Tag interne",
        options: []
    },
    season: {
        multiple: true,
        label: "Saison",
        options: [
            {
                value: "ALL",
                label: "Tous"
            },
            ...months.map(month => ({
                value: moment(month).format("M"),
                label: _capitalize(moment(month).format("MMMM"))
            }))
        ]
    },
    brand: {
        label: "Marque",
        multiple: false,
        options: [
            {
                value: "ALL",
                label: "Tous"
            }
        ]
    }
}
