
const defaultState = {
    sites: [],
    sitesParseObjects: [],
    suppliers: [],
    creditNotes: [],
    billings: [],
    filters: { billings: null, creditNotes: null },
    ordersSnackBar: { open: false, type: "message", message: "", duration: 1000 },
    total: { billings: 0, creditNotes: 0 },
    orderType: "" // billing or creditNote tab
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "BILLING_CREDIT_NOTES_ORDERS_LOADED":
            return {
                ...state,
                orders: action.orders,
                total: action.total
            }
        case "BILLING_CREDIT_NOTES_SUPPLIERS_LOADED":
            return {
                ...state,
                suppliers: action.suppliers,
                sites: action.sites,
                sitesParseObjects: action.sitesParseObjects || state.sitesParseObjects
            }
        case "CREDIT_NOTES_ORDERS_LOADED":
            return {
                ...state,
                creditNotes: action.creditNotes,
                total: {
                    ...state.total,
                    creditNotes: action.total
                }
            }
        case "BILLINGS_ORDERS_LOADED":
            return {
                ...state,
                billings: action.billings,
                total: {
                    ...state.total,
                    billings: action.total
                }
            }
        case "FILTERS_CREDIT_NOTES":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    creditNotes: action.creditNotesFilter
                }
            }
        case "FILTERS_BILLINGS":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    billings: action.billingsFilter
                }
            }
        case "BILLING_CREDIT_NOTES_SITES_LOADED":
            return {
                ...state,
                sites: action.sites,
            }
        case "BILLING_CREDIT_NOTES_OPEN_SNACKBAR": {
            return {
                ...state,
                ordersSnackBar: action.ordersSnackBar
            }
        }
        case "BILLING_CREDIT_NOTES_CLOSE_SNACKBAR": {
            return {
                ...state,
                ordersSnackBar: action.ordersSnackBar
            }
        }
        case "FILTERS_BILLING_CREDIT_NOTES":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters
                }
            }
        case "SELECT_BILLING_CREDIT_NOTES_ORDER_TYPE": {
            return {
                ...state,
                orderType: action.orderType
            }
        }
        case "CLEAR_BILLING_CREDIT_NOTES_ORDER_TYPE": {
            return {
                ...state,
                orderType: ""
            }
        }
        case "CLEAR_CREDIT_NOTES_FILTERS":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    creditNotes: null
                }
            }
        case "CLEAR_BILLING_CREDIT_NOTES_FILTERS":
            return {
                ...state,
                filters: {
                    billing: null,
                    creditNotes: null
                }
            }
        default:
            return {
                ...state
            }
    }
}


export function getBillingFilters(state) {
    return state.billingCreditNotes.filters.billings
}

export const getBillingCreditNotesSelector = state => state.billingCreditNotes.creditNotes || []

export const getBillingBillingsSelector = state => state.billingCreditNotes.billings || []

export const getBillingSitesSelector = state => state.billingCreditNotes.sites || []

export const getBillingSitesParseObjectsSelector = state => state.billingCreditNotes.sitesParseObjects || []    

export const getBillingSuppliersSelector = state => state.billingCreditNotes.suppliers || []

