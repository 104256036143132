import React, { useState } from "react"
import { Form, Formik } from "formik"
import { Stack } from "@mui/material"

import ModalForm from "../../../../../components/form/ModalForm"
import { getPackagingAttachmentFormInitialValues } from "../../../../../utils/packaging"
import ModalActions from "../../../../../components/ModalActions"
import { searchPackagingSupplierItems } from "../../../../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { formatSupplierItemsAutocompleteOptions } from "../../../../../utils/supplierItemUtils"
import SearchableAutocomplete from "../../../../../components/form/SearchableAutocomplete"

const PackagingAttachmentFormDialog = ({
  onClose,
  open,
  onSave,
  supplierItem
}) => {
  const [searchedSupplierItems, setSearchedSupplierItems] = useState([])

  const handleClearSupplierItemSearch = () => {
    setSearchedSupplierItems([])
  }

  const handleSearchSupplierItem = async (search) => {
    const supplierItems = await searchPackagingSupplierItems(search)
    setSearchedSupplierItems(supplierItems)
  }

  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Modifier le rattachement"
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={getPackagingAttachmentFormInitialValues(supplierItem)}
        onSubmit={onSave}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <SearchableAutocomplete
                  name="supplierItem"
                  label="Article fournisseur associé"
                  value={values.supplierItem}
                  setFieldValue={setFieldValue}
                  options={formatSupplierItemsAutocompleteOptions(searchedSupplierItems)}
                  onSearch={handleSearchSupplierItem}
                  onBlur={handleClearSupplierItemSearch}
                  error={!!errors.supplierItem}
                  helperText={errors.supplierItem}
                  textFieldSx={{ minWidth: 250 }}
                />
              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingAttachmentFormDialog
