import React, { useState, useRef } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import clsx from "clsx"
import Parse from "parse"
import { Modal } from "@mui/material"

import LotGroupToDo from "./LotGroupToDo"
import LotGroupDone from "./LotGroupDone"
import { COLORS } from "../../utils"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import LotGroupHeaderTitle from "./LotGroupHeaderTitle"
import { showLotGroupFilter } from "../../actions/LotGroup/lotGroup"

const useClasses = makeStyles({
    tab: {
        composes: "flex1",
        outline: "none"
    },
    lotGroupModal: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll"
    },
    username: {
        color: COLORS.DARK_GREY
    },
    rightInfosBloc: {
        justifyContent: "flex-end",
        marginLeft: "auto",
        marginRight: 0,
        marginTop: 5
    },
    lotGroupModalContainer: {
        outline: "none"
    },
})

const LotGroup = ()  => {
    const lotGroupDataToDo = useSelector(state => state.lotGroup.data.filter(product => !product.allDone && product.packagingType === state.lotGroup.packaging) || [])
    const lotGroupDataDone = useSelector(state => state.lotGroup.data.filter(product => product.allDone && product.packagingType === state.lotGroup.packaging) || []) 
    const classes = useClasses()
    const [tabValue, setTabValue] = useState(0)
    const user =  Parse.User.current()
    const modalRef = useRef()
    const dispatch = useDispatch()

    const onBack = () => dispatch(showLotGroupFilter())

    const _onTabChange = (event, tabValue) => {
        setTabValue(tabValue)
    }
    
    return (
        <Modal
            key="reception-warehouse-modal"
            BackdropProps={{invisible: true}}
            className={classes.lotGroupModal}
            open={true}
            ref={modalRef}
        >
            <div className={classes.lotGroupModalContainer}>
                <GenericTopBarHeader
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                    title={<LotGroupHeaderTitle />}
                    className="stretchSelf"
                    rightAction={<span className={classes.username}>Picker : {user.get("username")}</span>}
                />
                <div className={clsx(classes.tab, "flexCenter")}>
                    <Tabs
                        value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                        onChange={_onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label={`À FAIRE (${lotGroupDataToDo.length})`}/>
                        <Tab label={`FAIT (${lotGroupDataDone.length})`}/>
                    </Tabs>
                    <div className={classes.divider}/>
                    {
                        tabValue === 0 &&
                        <LotGroupToDo
                            modalRef={modalRef}
                            lotGroupDataToDo={lotGroupDataToDo}
                        />
                    }
                    {
                        tabValue === 1 &&
                        <LotGroupDone
                            modalRef={modalRef}
                            lotGroupDataDone={lotGroupDataDone}
                        />
                    }
                </div>
                <HelpButton link={helpLinks.warehouse}/>
            </div>
        </Modal>
    )
}

export default LotGroup
