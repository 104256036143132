import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"
import { detailsField } from "../../utils/recipes"

const useStyles = makeStyles({
    dialog: {
        textAlign: "justify",
        color: COLORS.GREY_SEARCH,
        fontSize: 16,
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.1)"
        },
        "& .MuiPaper-root": {
            minWidth: 500
        }
    },
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    title: {
        fontWeight: 500,
        fontSize: 17,
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0,
        "& span": {
            marginTop: 20
        }
    }
})

const InvalidStatusModal = (props) => {
    const {
        open,
        errors,
        foodcostError,
        onClose
    } = props

    const classes = useStyles()

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.dialog}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{root: classes.dialogTitle}}>
                <span className={classes.dialogTitleLabel}> Pour pouvoir changer le statut de la fiche technique en "Produit prêt à la vente", il faut bien renseigner les onglets Recette et Détails. </span>
            </DialogTitle>
            <DialogContent>
                {
                    foodcostError &&
                    <p>- Sections / Étapes / Ingrédients de la recette  </p>
                }
                {
                    errors && [...new Set(errors.inner.map(el => el.path.includes("[") ? el.path.split("[")[0] : el.path))]
                        .map((el, i) => {
                            const isDefault = el.includes("defaultValues.")
                            const field = el.replace("defaultValues.", "")
                            const details = detailsField[field]

                            return details ?
                                <p key={i}>- { details } { isDefault ? "par défaut": ""}</p> :
                                false
                        })
                }
            </DialogContent>
            <DialogActions classes={{root: classes.rootDialogActions}}>
                <Button
                    color="primary"
                    type="submit"
                    onClick={onClose}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InvalidStatusModal
