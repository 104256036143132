import React, { useState, useCallback } from "react"
import { Box } from "@mui/material"

import CalendarSection from "../../components/Calendar/CalendarSection"

import {
    COLORS,
} from "../../utils"
import ProductionPlanningSectionInfos from "../../components/Planning/ProductionPlanningSectionInfos"
import PlanningBody from "../Planning/PlanningBody/PlanningBody"
import CalendarSectionHeader from "../../components/Calendar/CalendarSectionHeader"


const PlanningsProductionCalendarSection = (props) => {
    const {
        rules,
        canEdit,
        canDeleteProductionItem,
        setAnyFocused,
        removeProductionItemIdFromSaved,
        canPrintCard,
        canAdd,
        canSplit,
        canDragAndDrop,
        canInputVolume,

        filteredBrands,
        filteredSaleDate,
        productData,
        inputValue,
        showDaysCards,
        showDays,
        section,

        updateExpectedProduction,
        updateSumExpectedProduction,
        updateProductionCard,
        openSplitModal,
        openAddModal,
        openDeleteModal,
        printSingleKitchenCard,
        openProductInfo,
        updateSectionCapacity,
        deleteProductionItem,
        updateInternCapacity
    } = props

    const [cardDragging, isCardDragging] = useState(false)

    const _updateInternCapacity = useCallback((value, item, productTypeData) => {
        updateInternCapacity(showDaysCards[0].date, value, item, productTypeData)
    }, [showDaysCards])

    const renderCalendarSectionHeaderInfo = (section) => productData.map((product, index) => {
        const sectionData = product.productTypesData.find(productType => productType.key === section.key)
        return <CalendarSectionHeader sectionData={sectionData} section={section} key={index} />
    })

    return (
        <CalendarSection
            title={section.label}
            section={section}
            color={COLORS.PRIMARY_COLOR}
            day={showDays}
            showInfoBtn={false}
            headerInfos={() => renderCalendarSectionHeaderInfo(section)}
            searching={"" !== inputValue}
            cardDragging={cardDragging}

        >
            <Box display="flex">
                {productData.map((product, index) => (
                    <ProductionPlanningSectionInfos
                        key={index}
                        sectionData={product.productTypesData.find(
                            (productType) => productType.key === section.type
                        )}
                        updateInternCapacity={_updateInternCapacity}
                        updateSectionCapacity={updateSectionCapacity}
                        product={product}
                        canEdit={canEdit}
                    />
                ))}
            </Box>
            <CalendarSection
                isSub={true}
                color={COLORS.LIGHT_GREY}
                title="Interne"
                showInfoBtn={false}
                searching={"" !== inputValue}
            >
                <PlanningBody
                    deleteProductionItem={deleteProductionItem}
                    data={showDaysCards}
                    isCardDragging={isCardDragging}
                    brand={null}
                    section={section}
                    isIntern="Recipe"
                    planningType="PRODUCTION"
                    showInfoBtn={false}
                    saveExpectedProduction={updateExpectedProduction}
                    saveSumExpectedProduction={updateSumExpectedProduction}
                    updateProductionCard={updateProductionCard}
                    openSplitModal={openSplitModal}
                    openAddModal={openAddModal}
                    openDeleteModal={openDeleteModal}
                    printSingleKitchenCard={printSingleKitchenCard}
                    rules={rules}
                    canPrint={canPrintCard}
                    canDeleteProductionItem={canDeleteProductionItem}
                    openProductInfo={openProductInfo}
                    setAnyFocused={setAnyFocused}
                    filteredBrands={filteredBrands}
                    filteredSaleDate={filteredSaleDate}
                    removeProductionItemIdFromSaved={removeProductionItemIdFromSaved}
                    isDragAndDroppable={canDragAndDrop}
                    canAdd={canAdd}
                    canSplit={canSplit}
                    canInputVolume={canInputVolume}
                    showDays={showDays}
                />
            </CalendarSection>
            <CalendarSection
                isSub={true}
                color={COLORS.LIGHT_GREY}
                title="Externe"
                showInfoBtn={false}
                searching={"" !== inputValue}
            >
                <PlanningBody
                    deleteProductionItem={deleteProductionItem}
                    data={showDaysCards}
                    isCardDragging={isCardDragging}
                    brand={null}
                    section={section}
                    isIntern="SubcontractorProduct"
                    planningType="PRODUCTION"
                    saveExpectedProduction={updateExpectedProduction}
                    saveSumExpectedProduction={updateSumExpectedProduction}
                    updateProductionCard={updateProductionCard}
                    openSplitModal={openSplitModal}
                    openAddModal={openAddModal}
                    openDeleteModal={openDeleteModal}
                    canPrint={canPrintCard}
                    canDeleteProductionItem={canDeleteProductionItem}
                    openProductInfo={openProductInfo}
                    setAnyFocused={setAnyFocused}
                    filteredBrands={filteredBrands}
                    filteredSaleDate={filteredSaleDate}
                    removeProductionItemIdFromSaved={removeProductionItemIdFromSaved}
                    isDragAndDroppable={canDragAndDrop}
                    canAdd={canAdd}
                    canSplit={canSplit}
                    canInputVolume={canInputVolume}
                />
            </CalendarSection>
        </CalendarSection>
    )
}

export default PlanningsProductionCalendarSection
