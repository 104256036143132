import _cloneDeep from "lodash/cloneDeep"
import { batch } from "react-redux"
import Parse from "parse"
import { getSites, getSiteById } from "../../parseManager/site/parseSiteManager"
import {
    getParamsFromPath,
    getAllOrderCreditNotes,
    generateQuantitiesCreditNotes
} from "../../utils/orderReception"
import { actionWithLoader, onEnter, push, sendCreditNoteMail } from "../Utils/utils"
import { getOrderReception, getOrderReceptionById, getSelectedOrderSupplierItemSelector } from "../../reducers/OrderReception/orderReception"
import {
    getOrders,
    createOrderReception,
    getOrder,
    addOrderSupplierItem,
    updateOrderSupplierItem,
    updateOrderShippingFees,
    saveOrderDeliveryNote,
    createOrderLots,
    closeOrderReception,
    startOrderReception,
    initializeDeliveryNoteUnitPrices,
    updateArticleDeliveryNoteQuantityAndPrice,
    updateArticleBillQuantityAndPrice,
    addOrderCreditNote,
    updateOrderCreditNotesAmount,
    updateOrderReceptionStatus,
    updateOrderCreditNotesByTotalAmount,
    addLotCreditNote,
} from "../../parseManager/orders/parseOrdersManager"
import { loadSuppliersList } from "../Supplier/suppliers"
import { getParseSupplier } from "../../parseManager/suppliers/suppliers/parseSupplierManager"
import { creditNotesReasons, formatCreditNote } from "../../utils/creditNoteUtils"
import { getLot, getLotsByOrderSupplierItem} from "../../parseManager/lot/parseLotManager"
import { CreditNote } from "../../parseManager/orders/parseCreditNotesManager"

export function loadSites() {
    return actionWithLoader(async (dispatch) => {
        const sites = await getSites()

        dispatch({
            type: "SITES_LOADED",
            sites
        })
    })
}

export function loadOrders() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const data = getParamsFromPath(path)

        if (data && data.site && data.date) {
            const site = await getSiteById(data.site, ["*"], false)
            const orders = await getOrders(site, data.date)

            if (site) {
                batch(() => {
                    dispatch({
                        type: "ORDER_RECEPTION_LOADED",
                        date: data.date,
                        site: site.toJSON(),
                        data: orders,
                        dataTodo: orders.filter(order => order.receptionStatus === "TODO"),
                        dataInProgress: orders.filter(order => order.receptionStatus === "IN_PROGRESS"),
                        dataDone: orders.filter(order => order.receptionStatus === "DONE"),
                    })
                    dispatch(loadSuppliersList())
                })
            }
            else {
                dispatch(showOrderFilter())
            }
            return
        }

        dispatch(showOrderFilter())
    })
}

export function createOrderFromReception(site, date, supplierId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const parseSite = await getSiteById(site.objectId, ["*"], false)
        const supplier = state.suppliers.suppliers.find(el => el.objectId === supplierId)

        await createOrderReception(parseSite, date, supplier)

        dispatch(loadOrders())
    })
}

export const onCloseOrderReception = ({ orderReceptionId, totalAmount, isRefusedReception = false }) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const date = state.orderReception.date
        const site = state.orderReception.site
        // 1. add deliveryNoteUnitPrice for each supplierItem
        const updatedOrderReception = await initializeDeliveryNoteUnitPrices(orderReceptionId, isRefusedReception)
        const supplierItems = updatedOrderReception.supplierItems
        // 2. generate creditNotes (received or ordered quantity) for each supplierItem
        const creditNotes = isRefusedReception ? [] : generateQuantitiesCreditNotes(supplierItems)
        // 3. add the creditNotes to the orderReception (for each supplierItem)
        // and calculate the totalAmount and supplier item prices
        await closeOrderReception(orderReceptionId, creditNotes, totalAmount)
        // if the reception is refused
        if (isRefusedReception) {
            // the order creditNotesAmount should be equal to the order  totalAmount
            await updateOrderCreditNotesAmount(orderReceptionId, totalAmount)
        } else {
            // the order creditNotesAmount is sum of all creditNotes amount and lot creditNotes amount
            await updateOrderCreditNotesByTotalAmount(orderReceptionId)
        }

        const orderReception = state.orderReception.selectedOrderReception

        const orderCreditNotes = []

        if (creditNotes) {
            Array.from(creditNotes).forEach(([, values]) => {
                orderCreditNotes.push(...values)
            })
        }

        if (orderReception.creditNotes && Array.isArray(orderReception.creditNotes)) {
            orderCreditNotes.push(...orderReception.creditNotes)
        }

        supplierItems.forEach(item => {
            if (Array.isArray(item.supplierItem.creditNotes)) {
                orderCreditNotes.push(...item.supplierItem.creditNotes)
            }
            if (Array.isArray(item.lots)) {
                item.lots.forEach(lot => {
                    if (Array.isArray(lot.creditNotes)) {
                        orderCreditNotes.push(...lot.creditNotes)
                    }
                })
            }
        })

        const hasCreditNodeToPaid = !!orderCreditNotes.find(d => d.amount > 0)
        if (orderCreditNotes.length > 0 && hasCreditNodeToPaid) {
            setTimeout(() => dispatch(sendCreditNoteMail(orderReceptionId)), 15000)
        }

        dispatch({
            type: "ORDER_RECEPTION_CLOSED",
            selectedOrderReception: null
        })
        dispatch(loadOrders())
        dispatch(showOrderList(site.objectId, date))
    })
}

export function clearSelectedOrderReception() {
    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: null
        })
    }
}

export function clearOrderCreditNotes() {
    return dispatch => {
        dispatch({
            type: "CLEAR_ORDER_RECEPTION_CREDIT_NOTE",
        })
    }
}

export function clearSelectedOrderSupplierItem() {
    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_SUPPLIER_ITEM",
            selectedOrderSupplierItem: null
        })
    }
}

export function updateOrderSupplierItemQuantity(orderReception, supplierItemId, values) {
    return async dispatch => {

        const updatedOrderReception = await updateOrderSupplierItem(orderReception.objectId, supplierItemId, "IN_PROGRESS", values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    }
}

export function updateShippingFees(orderReception, shippingFees) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateOrderShippingFees(orderReception.objectId, shippingFees)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export const updateArticleChanges = (orderReceptionId, article, values) => {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateArticleDeliveryNoteQuantityAndPrice(orderReceptionId, article.supplierItem.objectId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function updateArticleThunk(orderReception, article, values) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateArticleBillQuantityAndPrice(orderReception.objectId, article.supplierItem.objectId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function resetOrderSupplierItem(orderReception, supplierItemId) {
    return async dispatch => {

        const updatedOrderReception = await updateOrderSupplierItem(orderReception.objectId, supplierItemId, "TODO", null)

        if (updatedOrderReception) {
            batch(() => {
                dispatch({
                    type: "UPDATE_SELECTED_ORDER_RECEPTION",
                    selectedOrderReception: updatedOrderReception
                })
                dispatch(clearSelectedOrderSupplierItem())
                dispatch(showSingleOrder(orderReception.site.objectId, orderReception.receptionDate, orderReception.objectId))
            })
        }
    }
}

export function updateOrderReceptionSupplierItem(orderReception, supplierItem) {
    const updatedOrderReception = _cloneDeep(orderReception)
    const index = orderReception.supplierItems.indexOf(orderReception.supplierItems.find(item => item.supplierItem.reference === supplierItem.reference))
    updatedOrderReception.supplierItems[index] = {
        supplierItem,
        receptionStatus: "DONE"
    }

    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    }
}

export function addSupplierItemToOrderReception(orderId, supplierItemId) {
    return actionWithLoader(async (dispatch) => {
        const order = await addOrderSupplierItem(orderId, supplierItemId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function validateReceptionDate(orderId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const order = getOrderReceptionById(state, orderId)

        if (order.expectedDeliveryDate < order.receptionDate) {
            const creditNote = formatCreditNote({
                reason: creditNotesReasons.LATE_DELIVERY.key,
                amount: 0
            })

            const updatedOrderReception = await addOrderCreditNote(orderId, [creditNote], true)
            const creditNotes = getAllOrderCreditNotes(updatedOrderReception)

            dispatch({
                type: "UPDATE_ORDER_RECEPTION_CREDIT_NOTES",
                selectedOrderReception: updatedOrderReception,
                creditNotes
            })
        }
    })
}

export function startReceptionTreament(orderId) {
    return actionWithLoader(async (dispatch) => {
        const order = await startOrderReception(orderId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function saveDeliveryNote(orderId, values) {
    return actionWithLoader(async (dispatch) => {
        const order = await saveOrderDeliveryNote(orderId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function validateLotReception(orderReception, orderSupplierItem, lots, creditNotes) {
    return actionWithLoader(async (dispatch) => {
        const parseUpdatedOrderReception = await createOrderLots(orderReception.objectId, orderSupplierItem, lots, creditNotes)
        const jsonUpdatedOrderReception = parseUpdatedOrderReception.toJSON()

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: jsonUpdatedOrderReception
        })
        dispatch(clearSelectedOrderSupplierItem())
        dispatch(showSingleOrder(jsonUpdatedOrderReception.site.objectId, jsonUpdatedOrderReception.receptionDate, jsonUpdatedOrderReception.objectId))
    })
}

export function addCreditNoteToLot(orderId, lotId, creditNote) {
    return actionWithLoader(async (dispatch) => {

        await addLotCreditNote(lotId, creditNote, true)
        const jsonUpdatedOrderReception = await getOrder(orderId, true)

        batch(() => {
            dispatch({
                type: "UPDATE_SELECTED_ORDER_RECEPTION",
                selectedOrderReception: jsonUpdatedOrderReception
            })
            dispatch(clearSelectedOrderSupplierItem())
            dispatch(showSingleOrder(jsonUpdatedOrderReception.site.objectId, jsonUpdatedOrderReception.receptionDate, jsonUpdatedOrderReception.objectId))
        })
    }
)}

export const updateLotAndCreditNotes = (data) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const orderSupplierItem = getSelectedOrderSupplierItemSelector(state)

        const lots = orderSupplierItem.lots || []
        const { quantity, reason, amount } = data.values

        await new Parse.Query(CreditNote)
            .containedIn("objectId", data.lot.creditNotes?.map(creditNote => creditNote.objectId) || [])
            .each(async (creditNote) => {
                creditNote.set("quantity", quantity)
                creditNote.set("reason", reason)
                creditNote.set("amount", amount)
                await creditNote.save()
            })

        const newEvents = data.lot.events.map((event) => {
            if (event.mode === "BROKEN") {
                return { ...event, quantity, reason }
            }
            return event
        })

        const oldQuantity = data.lot.creditNotes[0]?.quantity || 0
        const globalQuantity = data.lot.quantity + (oldQuantity - quantity)
        const lot = await getLot({ id: data.lot.objectId, toJson: false })
        lot.set("quantity", globalQuantity)
        lot.set("events", newEvents)
        const updatedLot = await lot.save()
        const updatedLots = lots.map(lot => {
            if (lot.objectId === updatedLot.id) {
                return updatedLot.toJSON()
            }
            return lot
        })
        await dispatch({
            type: "ORDER_SUPPLIER_ITEM_LOTS_UPDATED",
            lots: updatedLots
        })
    }
)}

export const cancelLot = (lot) => {
    return actionWithLoader(async (dispatch, getState) => {
        const orderSupplierItem = getSelectedOrderSupplierItemSelector(getState())
        const lots = orderSupplierItem.lots || []
        const lotParseObj = await getLot({ id: lot.objectId, toJson: false })
        const updatedEvents = lotParseObj.get("events")?.filter(event => event.mode !== "BROKEN") || []
        const oldCreditNotesIds = (lotParseObj.get("creditNotes") || []).map(creditNote => creditNote.id)

        lotParseObj.set("quantity", lot.initialQuantity)
        lotParseObj.set("events", updatedEvents)
        
        await new Parse.Query(CreditNote)
            .containedIn("objectId", oldCreditNotesIds)
            .each(async (creditNote) => {
                await creditNote.destroy()
            })

        lotParseObj.set("creditNotes", [])
        const updatedLot = await lotParseObj.save()

        const updatedLots = lots.map(lot => {
            if (lot.objectId === updatedLot.id) {
                return updatedLot.toJSON()
            }
            return lot
        })

        dispatch({
            type: "ORDER_SUPPLIER_ITEM_LOTS_UPDATED",
            lots: updatedLots
        })
    }
)}

export function saveCreditNote(orderReceptionId, values) {
    return actionWithLoader(async (dispatch) => {
        const order = await addOrderCreditNote(orderReceptionId, values, true)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function initializeOrderReception(orderId, orderSupplierItemId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()

        const updatedSelectedOrder = await updateOrderReceptionStatus(orderId, orderSupplierItemId)
        const creditNotes = getAllOrderCreditNotes(updatedSelectedOrder)

        dispatch({
            type: "ORDER_RECEPTION_SELECTED",
            ...state.orderReception,
            selectedOrderReception: updatedSelectedOrder,
            creditNotes
        })
    })
}

export function completeOrderDeliveryNoteUnitPrices(orderReception) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await initializeDeliveryNoteUnitPrices(orderReception.objectId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function loadOrderReceptionSite(site) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "ORDER_RECEPTION_SITE_LOADED",
            site
        })
    })
}

export function onEnterOrderReception(store) {
    return onEnter({
        store,
        actionThunk: loadSites
    })
}

export function onEnterOrderReceptionList(store) {
    return onEnter({
        store,
        actionThunk: loadOrders
    })
}

export function onEnterOrderDeliveryNote(store) {
    return async (nextState) => {
        const { params } = nextState
        store.dispatch(clearSelectedOrderReception())
        if (params) {
            const state = store.getState()

            const date = state.orderReception.date || params.date
            const site = state.orderReception.site || (await getSiteById(params.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            store.dispatch({
                type: "ORDER_DELIVERY_NOTE_LOADED",
                selectedOrderReception: selectedOrder,
                date,
                site
            })
        }
    }
}

export function onEnterSingleOrderReception(store) {
    return async (nextState) => {
        const { params } = nextState


        store.dispatch(clearSelectedOrderReception())
        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))
            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            const orderSupplierItemIdList = selectedOrder.supplierItems.map(orderSupplierItem => orderSupplierItem?.supplierItem.objectId)
            const lots = await getLotsByOrderSupplierItem(orderSupplierItemIdList, ["events", "dlc", "lotNumber", "quantity", "orderSupplierItem", "receptionDate", "supplierItem"])
            const parseSupplier = await getParseSupplier(selectedOrder.supplier.objectId)
            const creditNotes = getAllOrderCreditNotes(selectedOrder)

            store.dispatch({
                type: "ORDER_RECEPTION_SELECTED",
                selectedOrderReception: selectedOrder,
                date,
                site,
                parseSupplier,
                creditNotes,
                lots
            })
        }
    }
}

export function onEnterOrderReceptionQuantities(store) {
    return async (nextState) => {
        const { params } = nextState

        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            let selectedOrderSupplierItem = selectedOrder.supplierItems.find(el => el.supplierItem.objectId === params.orderSupplierItemId)

            batch(() => {
                store.dispatch({
                    type: "ORDER_RECEPTION_QUANTITY",
                    selectedOrderReception: selectedOrder,
                    selectedOrderSupplierItem,
                    date,
                    site
                })
            })
        }
    }
}

export function onEnterOrderSupplierItemLots(store) {
    return async (nextState) => {
        const { params } = nextState

        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            let selectedOrderSupplierItem = selectedOrder.supplierItems.find(el => el.supplierItem.objectId === params.orderSupplierItemId)

            store.dispatch({
                type: "ORDER_SUPPLIER_ITEM_LOTS_LOADED",
                site,
                date,
                selectedOrderReception: selectedOrder,
                selectedOrderSupplierItem: selectedOrderSupplierItem
            })
        }
    }
}

/** Routers **/

export function showOrderFilter() {
    return push("/orderReceptions")
}

export function showOrderList(site, date) {
    if (site && date) {
        return push(`/orderReceptions/${site}/${date}`)
    }
}

export function showSingleOrder(site, date, id) {
    if (site && date && id) {
        const localStorage = window.localStorage
        const pathname = window.location.pathname
        localStorage.setItem("singleOrderReceptionNavigationHistory", JSON.stringify({ lastPath: pathname.substring(1) }))
        return push(`/orderReceptions/${site}/${date}/${id}`)
    }
}

export function showOrderSupplierItemLots(site, date, id, supplierItemId) {
    if (site && date && id && supplierItemId) {
        return push(`/orderReceptions/${site}/${date}/${id}/${supplierItemId}`)
    }
}

export function showOrderSupplierItemQuantities(site, date, id, supplierItemId) {
    if (site && date && id && supplierItemId) {
        return push(`/orderReceptions/${site}/${date}/${id}/${supplierItemId}/quantities`)
    }
}

export function showOrderDeliveryNote(site, date, id) {
    if (site && date && id) {
        return push(`/orderReceptions/${site}/${date}/${id}/deliveryNote`)
    }
}

export function showOrderDeliveryNoteControl(site, date, id) {
    if (site && date && id) {
        return push(`/orderReceptions/${site}/${date}/${id}/deliveryNoteControl`)
    }
}

export default function showBillingCreditNotesControl(site, date, id) {
    if (site && date && id) {
        return push(`/billingCreditNotes/${site}/${date}/${id}/control`)
    }
}
