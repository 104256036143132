import React from "react"

import { Select, styled, FormControl, MenuItem, FormLabel } from "@mui/material"

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline"
})

const StyledSelect = styled(Select)({
  flexBasis: "30%",
  marginTop: "0px !important",
  fontSize: "14px !important"
})

const WeeklyMealPlannerProductionSchemaFilter = ({ selectProductionSchema = () => {}, productionSchemasOptions, selectedProductionSchema }) => {

  return (<StyledFormControl>
    <FormLabel
      sx={{ fontSize: 14, lineHeight: "22px"}}
      focused={false}>
      Sélectionner le schéma de production à suivre par les produits que vous ajoutez :&nbsp;
    </FormLabel>

    <StyledSelect
     variant="standard"
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Age"
      value={selectedProductionSchema || ""}
      onChange={selectProductionSchema}
    >
      {productionSchemasOptions.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  </StyledFormControl>)

}


export default WeeklyMealPlannerProductionSchemaFilter