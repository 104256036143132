import React from "react"
import { useDispatch } from "react-redux"
import SettingsIcon from "@mui/icons-material/Settings"

import PageHeaderTitle from "../../components/PageHeaderTitle"
import PageTabs from "../../components/PageTabs"
import { showRightsRoles, showRightsUsers } from "../../actions/Rights/Rights"

// list of tab
const tabs = [
    {
        value: "roles",
        label: "Droits"
    },
    {
        value: "users",
        label: "Utilisateurs"
    }
]

const RightsPage = ({ children, tabValue }) => {
    const dispatch = useDispatch()

    const onTabChange = (event, tabValue) => {
        // subcontractorsProducts
        if (tabValue === tabs[1].value) {
            dispatch(showRightsUsers())
            return
        }
        // recipes
        dispatch(showRightsRoles())
    }

    return (
        <PageTabs
            title={<PageHeaderTitle title="Droits" icon={<SettingsIcon />} color="#52ba43" />}
            tabs={tabs}
            tabValue={tabValue}
            onTabChange={onTabChange}
        >
            {children}
        </PageTabs>
    )
}

export default RightsPage