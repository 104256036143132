import React, { useLayoutEffect, useState, useEffect } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { getBrandBy, COLORS } from "../../utils"
import { loadReception, closeReceptionSnackBar, brandChange } from "../../actions/Reception/reception"
import ReceptionOutputFilter from "../../components/Reception/ReceptionOutputFilter"
import ReceptionSnackBar from "../../components/Reception/ReceptionSnackBar"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import { HubReceptionHeader } from "../../components/Reception/HubReceptionHeader"
import { makeStyles } from "@mui/styles"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"

const useStyles = makeStyles(() => ({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	headerBloc: {
		flex: "0 0 64px"
	}
}))

const ReceptionFilter = (props) => {
	const classes = useStyles()
	const { brand, hubs, date, receptionSnackBar, closeReceptionSnackBar, loadReception, brandChange } = props
	const [selectedDate, setSelectedDate] = useState(date)
	const [selectedHubs, setHubs] = useState(hubs.map(el => ({
		value: el.objectId,
		label: el.name,
	})))
	const [selectedHub, setSelectedHub] = useState("")
	const [selectedBrand, setSelectedBrand] = useState(brand)

	useLayoutEffect(() => {
		document.title = "Réception - KFC"

		return () => (document.title = "KFC")
	}, [])

	useEffect(() => {

		setSelectedBrand(brand)

		const activeHubs = hubs.filter((el) => el.isActive === true)

		setHubs(activeHubs.map(el => ({
			value: el.objectId,
			label: el.name,
		})))
	}, [brand])

	const onBrandClick = async (brandSelected) => {
		await brandChange(brandSelected.name)
	}

	const onHubChange = (eventHub) => {
		setSelectedHub(eventHub)
	}

	const onDateChange = (event) => {
		setSelectedDate(moment.utc(event).startOf("day").valueOf())
	}

	// sort hubs alphabetically
	const sortByLabel = (arr) => {
		return arr.sort((a, b) => a.label.localeCompare(b.label))
	}

	// validate and go next
	const onValidate = (e) => {
		e.preventDefault()

		if (selectedDate && selectedBrand && selectedHub !== "") {
			loadReception(selectedDate, selectedBrand, selectedHub)
			localStorage.setItem("receptionFilter", JSON.stringify({ date: selectedDate, brand: selectedBrand, hub: selectedHub }))
		}
	}

	const completeBrand = getBrandBy("name", selectedBrand)

	return (
		<>
			<div className={classes.headerBloc}>
				<GenericTopBarHeader
					title={<HubReceptionHeader />}
					rightAction={
						<span>
							<IconButton
								aria-label="more"
								aria-controls="long-menu"
								aria-haspopup="true"
								size="large">
								<MoreVertIcon />
							</IconButton>
						</span>
					}
				/>
			</div>
			<div className={classes.container}>
				{/* Filter*/}
				<ReceptionOutputFilter
					date={selectedDate}
					hubs={sortByLabel(selectedHubs)}
					brand={completeBrand}
					selectedHub={selectedHub}
					onBrandClick={onBrandClick}
					onDateChange={onDateChange}
					onHubChange={onHubChange}
					onValidate={onValidate}
				/>

				{/* Error snackbar */}
				<ReceptionSnackBar data={receptionSnackBar} onClose={closeReceptionSnackBar} />
				<HelpButton link={helpLinks.hub} />
			</div>
		</>
	)
}

export default connect((state) => {
	return {
		date: state.reception.date,
		hubs: state.reception.hubs,
		brand: state.reception.brand,
		receptionSnackBar: state.reception.receptionSnackBar
	}
}, {
	loadReception,
	closeReceptionSnackBar,
	brandChange
})(ReceptionFilter)
