import React from "react"
import {
    useSelector
} from "react-redux"
import { COLORS } from "../../../utils"
import { Box, styled } from "@mui/material"

const StyledTabsContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "open" || prop !== "top"
  })((props) => {
    const style = {
        position: "fixed",
        backgroundColor: COLORS.WHITE,
        zIndex: 300,
        width: props.open ? "calc(100% - 240px)" : "calc(100% - 54.4px)",
    }

    if (props.top) {
        style.top = props.top
    }

    return style
  })

const TabsContainer = ({ children, top = 113.5 }) => {    
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)

    return (
        <StyledTabsContainer open={menuIsOpen} display="flex" flexDirection="column" top={top}>
            {children}
        </StyledTabsContainer>
    )
}

export default TabsContainer