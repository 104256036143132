import React from "react"

const DayCheck = props => {
    const {
        className,
        value
    } = props

    return (
        <span className={className}>{value}</span>
    )
}

export default DayCheck