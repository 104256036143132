import React, {PureComponent} from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import DoneIcon from "@mui/icons-material/Done"

const styles = theme => ({
    volumeModal: {
        position: "absolute",
        top: "35%",
        left: "37%",
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none",
    },
    addNewVolumeIcon: {
        marginLeft: 15,
        marginTop: 25,
        cursor: "pointer"
    },
    inputNewVolume: {
        width: "80%"
    }
})

class ProductionVolumeModal extends PureComponent {

    constructor(props){
        super(props)
        const { data } = this.props
        this.state = {
            volume: data.volume
        }
    }

    _updateProductionVolume = () => {
        const { productionId, data, editProductionDetailVolume } = this.props
        const { volume } = this.state
        if (parseFloat(volume) && data.volume !== parseFloat(volume)){
            editProductionDetailVolume(productionId, data.recipe ? data.recipe.id : data.subcontractorProduct.id , parseFloat(volume))
        }
    }

    _onKeyDown = (event) => {
        if (event.keyCode === 13){
            this._updateProductionVolume()
        }
    }

    _onVolumeChange = (event) => {
        this.setState({volume: event.target.value})
    }

    render() {

        const { classes, data } = this.props
        const { volume } = this.state

        return (
            <div className={classes.volumeModal}>
                <Typography variant="h6" id="modal-title">
                    {data.recipe ? data.recipe.get("name") : data.subcontractorProduct.get("name").get("name")}
                </Typography>
                <TextField
                    variant="standard"
                    className={classes.inputNewVolume}
                    placeholder="Volume"
                    label="Volume"
                    name="newVolume"
                    onKeyDown={this._onKeyDown}
                    onChange={this._onVolumeChange}
                    value={volume}
                />
                <DoneIcon className={classes.addNewVolumeIcon} onClick={() => this._updateProductionVolume()}/>
            </div>
        )
    }
}

ProductionVolumeModal.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ProductionVolumeModal)
