import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import SelectableAlphabetCard from "./SelectableAlphabetCard"
import { compareLots, compareSecondaryDLCs, isLotOrSecondaryDLCCardSelected } from "../../utils/lotInventoryUtils"

const useStyles = makeStyles({
	container: {
		width: "100%"
	},
	header: {
		margin: 0,
		padding: 6,
		backgroundColor: COLORS.PRIMARY_COLOR,
		color: COLORS.WHITE,
		fontWeight: 400,
		fontSize: 14,
		lineHeight: "14px",
		textTransform: "uppercase"
	},
	wrapper: {
		padding: "13px 15px"
	},
	rowContainer: {
		display: "flex",
		padding: "10px 0",

	},
	ornate: {
		color: COLORS.PRIMARY_COLOR,
		marginRight: 15,
		fontSize: 42,
		lineHeight: "42px",
		fontWeight: 700,
		textTransform: "uppercase"
	},
	cardsContainer: {
		display: "flex",
		flexWrap: "wrap",
		"& > div": {
			marginRight: 13,
			marginBottom: 10
		}
	}
})

const GroupIngredientsSection = ({
	collection,
	group,
	selectedItem,
	onSelect,
	characters,
	mode,
	substituteIds = new Map(),
	currentDate,
	dlcWarningDate
}) => {

	const isLotOrSecondaryDLC = collection === "Lot" || collection === "SecondaryDLC"

	const comparator = collection === "Lot" ? compareLots : collection === "SecondaryDLC" ? compareSecondaryDLCs : null

	if (comparator) {
		characters.forEach((character) =>
			character.sort(comparator)
		)
	}

	const classes = useStyles()

	const _isDocumentSelected = (selectedItem, document) => {
		if (isLotOrSecondaryDLC) {
			return isLotOrSecondaryDLCCardSelected(selectedItem, document)
		}
		return false
	}

	return <div className={classes.container}>
		<div className={classes.header}>
			{group}
		</div>
		<div className={classes.wrapper}>
			{
				Array.from(characters).map(([character, documents]) => (
					<div
						key={character}
						className={classes.rowContainer}
					>
						<div className={classes.ornate}>
							{character}
						</div>
						<div className={classes.cardsContainer}>
							{
								documents.map((document, index) => {
									const supplierItemId = document.orderSupplierItem?.supplierItemId || ""
									return (
										<SelectableAlphabetCard
											collection={collection}
											key={index}
											document={document}
											mode={mode}
											currentDate={currentDate}
											dlcWarningDate={dlcWarningDate}
											onSelect={onSelect}
											selected={_isDocumentSelected(selectedItem, document)}
											isSubstitute={substituteIds.has(supplierItemId)}
										/>
									)
								})
							}
						</div>
					</div>
				))
			}
		</div>
	</div>
}

export default GroupIngredientsSection
