import React from "react"
import IngredientsTabs from "../IngredientsTabs"
import GroupIngredientList from "./Groups/GroupIngredientList"

const GroupsIngredientsTab = () => {
    return (
        <IngredientsTabs tabValue="groups">
            <GroupIngredientList/>
        </IngredientsTabs>
    )
}

export default GroupsIngredientsTab