import React from "react"
import { Box, Stack } from "@mui/material"

import { COLORS } from "../../../utils"
import PackagingResume from "./general/PackagingResume"
import PackagingAttachment from "./general/PackagingAttachment"
import PackagingLogistics from "./general/PackagingLogistics"


const sx = {
    root: {
        padding: "40px 0",
        background: COLORS.GREY_RECIPE_BACKGROUND,
        height: "100%" 
    },
    firstColumnContainer: {
        width: 1000,
    },
}

const PackagingGeneralTab = ({ packaging, onSave }) => {
    return (
        <Box sx={sx.root} className="flexRow flex1 justifyCenter stretchSelf">
            <Stack sx={sx.firstColumnContainer} spacing={2.6}>
                <PackagingResume packaging={packaging} onSubmit={onSave} />
                <PackagingAttachment supplierItem={packaging.supplierItem} onSubmit={onSave} />
                <PackagingLogistics packaging={packaging} onSubmit={onSave} />
            </Stack>
        </Box>
    )
}

export default PackagingGeneralTab