import React from "react"
import { makeStyles } from "@mui/styles"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	tableMenu: {
		paddingLeft: 20,
		backgroundColor: COLORS.PRIMARY_COLOR,
		color: COLORS.TEXT_COLOR_FOR_PRIMARY
	},
	smallColumn: {
		composes: "$tableMenu",
		width: 30,
		paddingRight: 10,
		textAlign: "center"
	},
	sortColumn: {
		color: COLORS.TEXT_COLOR_FOR_PRIMARY
	}
})

const ReceptionCheckEnhancedTableHead = (props) => {
	const {tableType} = props
	const classes = useStyles()

	const getTableCellClasse = (row) => {
		if (row.id === "name" || row.id === "reason"|| row.id === "date"|| row.id === "comment") return classes.tableMenu

		return classes.smallColumn
	}

	let rows
	if (tableType === "TO_CHECK") rows = [
		{ id: "uniqueCode", numeric: false, disablePadding: true, label: "CODE" },
		{ id: "name", numeric: false, disablePadding: false, label: "NOM" },
		{ id: "hub", numeric: false, disablePadding: true, label: "HUB" },
		{ id: "gap",  numeric: false, disablePadding: false, label: "ECART" },
		{ id: "reason",  numeric: false, disablePadding: false, label: "MOTIF" },
		{ id: "date", numeric: false, disablePadding: false, label: "HEURE" },
		{ id: "checked", numeric: false, disablePadding: false, label: "TRAITÉ" }
	]

	if (tableType !== "TO_CHECK") rows = [
		{ id: "uniqueCode", numeric: false, disablePadding: true, label: "CODE" },
		{ id: "name", numeric: false, disablePadding: false, label: "NOM" },
		{ id: "hub", numeric: false, disablePadding: true, label: "HUB" },
		{ id: "gap",  numeric: false, disablePadding: false, label: "ECART" },
		{ id: "reason",  numeric: false, disablePadding: false, label: "MOTIF" },
		{ id: "date", numeric: false, disablePadding: false, label: "HEURE" },
		{ id: "comment", numeric: false, disablePadding: false, label: "COMMENTAIRE" },
		{ id: "checked", numeric: false, disablePadding: false, label: "TRAITÉ" }
	]

	return (
		<TableHead>
			<TableRow>
				{
					rows.map(row =>
						<TableCell
							key={row.id}
							align={row.numeric ? "right" : "left"}
							sortDirection={false}
							className={getTableCellClasse(row)}
						>
							{row.label}
						</TableCell>
					)
				}
			</TableRow>
		</TableHead>
	)
}

export default ReceptionCheckEnhancedTableHead
