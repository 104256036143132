import React, { useState } from "react"

import {
  Autocomplete,
  CircularProgress,
  styled,
  TextField
} from "@mui/material"
import { debounce } from "lodash"

const autocompleteSx = {
  textField: {
    "& .MuiInput-input": {
      cursor: "pointer"
    }
  }
}

export const StyledAutocompleteTextField = styled(TextField)({
  "& .MuiAutocomplete-inputRoot.MuiInputBase-root": {
    "&:before": {
      borderBottom: "none",
      "&:hover": {
        borderBottom: "none"
      }
    },
    "& .MuiAutocomplete-input": {
      padding: 4
    }
  },
  "& .MuiInput-input": {
    fontWeight: 600,
    fontSize: 14,
    color: "#414141"
  }
})

const SearchableAutocomplete = ({
  setFieldValue,
  name,
  value,
  label,
  options,
  getOptionLabel, // { value (ex: objectId), data (parse object json)}
  onSearch,
  onBlur,
  onFocus,
  helperText,
  textFieldSx,
  error = false,
  readOnly = false,
  ...autoCompleteProps
}) => {
  const [loading, setLoading] = useState(false)

  const handleChange = (_, newValue) => {
    setFieldValue(name, newValue)
  }

  const handleInputChange = debounce(
    async (event, newValue) => {
      if (newValue && event?.type === "change") {
        setLoading(true)
        await onSearch(newValue)
        setLoading(false)
      }
    },
    700
  )

  return (
    <Autocomplete
      {...autoCompleteProps}
      name={name}
      value={value}
      options={options}
      isOptionEqualToValue={(option, value) =>
        value && option.value === value.value
      }
      getOptionLabel={option => getOptionLabel ? getOptionLabel(option.data || option) : option.label}
      onChange={handleChange}
      onInputChange={handleInputChange}
      sx={{ flex: 1, pointer: "cursor" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label={label}
          sx={{ ...textFieldSx, ...(readOnly && autocompleteSx.textField) }}
          disableClearable
          onFocus={onFocus}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default SearchableAutocomplete
