import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material"
import { COLORS } from "../../../utils"
import SupplierItemLotTitle from "./SupplierItemLotTitle"
import { creditNotesReasons, creditNotesType } from "../../../utils/creditNoteUtils"
import { generateStockUnitLabel } from "../../../utils/orderReception"
import OrderCard from "../../../components/Order/OrderCard"
import OrderCardTitle from "../../../components/Order/OrderCardTitle"
import SupplierItemLotFooter from "./SupplierItemLotFooter"

const useStyles = makeStyles({
	supplierItemLot: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		flex: 1,
		justifyContent: "space-between",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		gap: 16,
	},
	quantity: {
		display: "flex",
		alignItems: "center",
	},
	cardContent: {
		display: "flex",
		gap: 32,
		alignSelf: "stretch",
	},
	column: {

	},
	label: {
		color: "#555",
		fontSize: 14,
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: 1.57,
	},
	value: {
		color: COLORS.BLACK800,
		fontSize: 16,
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: 1.57,
	}
})

const SupplierItemLot = ({
	actions,
	onCancel,
	selectedLot
}) => {
	const [openCancelModal, setOpenCancelModal] = useState(false)

	const toggleCancelModal = () => setOpenCancelModal(!openCancelModal)

	const classes = useStyles()

	const handleClickEditLot = () => {
		actions({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL_REEDITION",
			data: {
				type: creditNotesType.LOT
			}
		})
	}

	return (
		<div className={classes.supplierItemLot}>
			{/* top */}
			<div className={classes.content}>
				<SupplierItemLotTitle title="Casse" />
				<OrderCard>
					<OrderCardTitle title="Mise à la casse" icon={<HighlightOffIcon sx={{ width: 16, height: 16 }} />} />
					<div className={classes.cardContent}>
						{/* left */}
						<div className={classes.column}>
							<Typography variant="body" className={classes.label}>
								Quantité mise à la casse
							</Typography>
							<div className={classes.quantity}>
								<Typography>{selectedLot.creditNotes[0]?.quantity}&nbsp;</Typography>
								<Typography className={classes.value}>({generateStockUnitLabel(selectedLot.orderSupplierItem)})</Typography>
							</div>
						</div>
						{/* middle */}
						<div className={classes.column}>
							<Typography variant="body" className={classes.label}>
								Motif
							</Typography>
							<div>
								<Typography  className={classes.value}>
									{creditNotesReasons[selectedLot.creditNotes[0]?.reason]?.label}
								</Typography>
							</div>
						</div>
						{/* right */}
						<div className={classes.column}>
							<Typography variant="body" className={classes.label}>
								Montant de l’avoir
							</Typography>
							<div>
								<Typography className={classes.value}>{selectedLot.creditNotes[0]?.amount} € </Typography>
							</div>
						</div>
					</div>
				</OrderCard>
			</div>
			{/* bottom */}
			<SupplierItemLotFooter
				cancelText="ANNULER LA CASSE"
				onCancel={toggleCancelModal}
				confirmText="ÉDITER LA CASSE"
				onConfirm={handleClickEditLot}
			/>
			{/* cancel dialog */}
      <Dialog
        open={openCancelModal}
        onClose={toggleCancelModal}
				sx={{ "& .MuiPaper-root": { padding: "24px", borderRadius: 0 } }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
				<DialogContent>
          <DialogContentText id="alert-dialog-description">
						Êtes-vous sûr de vouloir annuler la déclaration de casse ?
          </DialogContentText>
        </DialogContent>
        <DialogActions spacing={8}>
          <Button onClick={toggleCancelModal} variant="text">Annuler</Button>
          <Button onClick={onCancel} variant="text">
						Confirmer
          </Button>
        </DialogActions>
			</Dialog>
		</div>
	)
}

export default SupplierItemLot