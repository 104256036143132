import React from "react"
import Typography from "@mui/material/Typography"
import styled from "@emotion/styled"
import { Box } from "@mui/material"

import {
  PRODUCTION_STEPS_COL_WIDTHS, PRODUCTION_STEPS_SPACINGS,
} from "../../../utils/constant"
import { getCellAlignment } from "../../../utils"
import { lastColStickyStyle } from "./StyledSectionComponents"

// ----------------------------------------------- //
// -------------------- styles ------------------- //
// ----------------------------------------------- //
const stickyStyle = {
  position: "sticky",
  left: 0,
  borderRight: "1px solid #cccccc"
}

// ----------------------------------------------- //
// -------------- styled components -------------- //
// ----------------------------------------------- //
const StyledHeadCell = styled(Box, {
  shouldForwardProp: (prop) => prop !== "addBackground" && prop !== "isFirstColumn" && prop !== "isLastColumn" && prop !== "align"

})((props) => {
  let defaultStyles = {
    height: "100%",
    color: "#fff",
    paddingRight: 16,
    paddingLeft: 16
  }

  if (props.isFirstColumn) {
    defaultStyles = {
      ...defaultStyles,
      ...stickyStyle,
      zIndex: 1000,
      paddingLeft: PRODUCTION_STEPS_SPACINGS.SECTION_FIRST_COL_PL
    }
  }

  if (props.isLastColumn) {
    defaultStyles = {
      ...defaultStyles,
      ...lastColStickyStyle,
      zIndex: 1000,
    }
  }

  if (props.addBackground) {
    defaultStyles.backgroundColor = "#2196f3"
  }

  if (props.align) {
    defaultStyles.justifyContent = getCellAlignment(props.align)
  }

  return defaultStyles
})

const StyledHeadRow = styled(Box)({
  backgroundColor: "#2196f3",
  height: 60
})

const isHeadersLastColumn = (headersLength, index) => headersLength - 1 === index

const ProductionStepsTableHead = ({ headers, hasAnyHoveredRow }) => {

  return (
    <StyledHeadRow className="flexRow center" sx={{ position: "sticky", top: 0, zIndex: 100 }}>
      {headers.map((header, index) => (
        // first head column
        <StyledHeadCell
          key={header.label + index}
          isFirstColumn={index === 0}
          isLastColumn={isHeadersLastColumn(headers.length, index)}
          addBackground={
            (hasAnyHoveredRow && isHeadersLastColumn(headers.length, index)) ||
            index === 0 // first col
          }
          sx={{
            width: PRODUCTION_STEPS_COL_WIDTHS[index],
            pl: 16
          }}
          align="left"
          className="flexRow center"
        >
          <Typography
            sx={{
              textAlign: "left",
              // textAlign: index === 0 ? "left" : "center",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px"
            }}
          >
            {header.label}
          </Typography>
        </StyledHeadCell>
      ))}
    </StyledHeadRow>
  )
}

export default ProductionStepsTableHead
