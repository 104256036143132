import React from "react"
import Button from "@mui/material/Button"
import { Box } from "@mui/material"

const ModalActions = ({ onSubmit, onCancel, sx }) => {
  return (
    <Box sx={{ mt: "90px", ...sx }} className="flexRow spaceBetween">
      <Button onClick={onCancel} color="primary">
        ANNULER
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit}
      >
        ENREGISTRER
      </Button>
    </Box>
  )
}

export default ModalActions
