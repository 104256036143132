import PropTypes from "prop-types"
import React from "react"
import {
    COLORS
} from "../../utils"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    calendarHeaderInfoContainer: {
        color: COLORS.DARK_GREY,
        fontSize: 12,
        lineHeight: "130%",
        padding: "12px 18px 12px 12px",
        backgroundColor: COLORS.WHITE,
        border: `1px solid ${ COLORS.MEDIUM_GREY }`,
        flex: 1
    },
    infoNumber: {
        marginLeft: "auto"
    }
})

export const CalendarHeaderInfo = ({
    tags,
    internalProduct,
    externalProduct,
    expectedSales = [],
    sectionKey
}) => {
    const classes = useStyles()

    return (
        <div className={classes.calendarHeaderInfoContainer}>
            <div className="flexRow">
                Produits internes <div className={classes.infoNumber}>{internalProduct}</div>
            </div>
            <div className="flexRow">
                Produits ST <div className={classes.infoNumber}>{externalProduct}</div>
            </div>
            {
                tags && tags.map((tag, i) => {
                    return tag.sections && tag.sections.indexOf(sectionKey) === -1
                        ? null
                        : (
                            <div className="flexRow" key={i}>
                                Produits {tag.name} <div className={classes.infoNumber}>{tag.count}</div>
                            </div>
                        )
                })
            }
            {
                expectedSales.length > 0 && expectedSales.map((prev, index) => {
                    return (
                        <div key={prev + index} className="flexRow">
                            Cibles {prev.label} <div className={classes.infoNumber}>{prev.expectedSale}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

CalendarHeaderInfo.propTypes = {
    internalProduct: PropTypes.number,
    externalProduct: PropTypes.number,
    expectedSales: PropTypes.array,
    tags: PropTypes.array,
}

export default CalendarHeaderInfo
