import React from "react"

import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { DatePicker } from "@mui/x-date-pickers"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import { Formik, Form } from "formik"
import moment from "moment"

import { FormHelperText, Stack, TextField } from "@mui/material"
import { forEachBrand } from "../../utils"

const typeSubmits = [
    { value: "products", label: "Produit par produit" },
    { value: "hubs", label: "Par centre de distribution" }
]

const DispatchForm = (props) => {
    const {
        onSubmit,
        date,
        brand,
        sites,
    } = props
    
    const renderBrand = () => {
        const options = []
        forEachBrand(brand => {
            options.push(<MenuItem key={brand.shortname} value={brand.name}>{brand.label}</MenuItem>)
        }, true)
        
        return options
    }
    
    const renderTypeSubmit = () => {
        return typeSubmits.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
    }

    const renderSites = () => {
        return sites.map(site => <MenuItem key={site.objectId} value={site}>{site.name}</MenuItem>)
    }
    
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                date,
                brand,
                site: sites[0],
                typeSubmit: "products"
            }}
        >
            {({
                errors,
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                isValid,
            }) => {
                return (
                    <Form>
                        <Stack spacing={4} alignItems="center">
                            <Stack direction="row" spacing={3.8} justifyContent="center">
                                {/* site */}
                                <Stack spacing={1} alignItems="center" justifyContent="center">
                                    <InputLabel>Site de dispatch</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="site"
                                        label="Site de dispatch"
                                        placeholder="Site de dispatch"
                                        value={values.site}
                                        onChange={handleChange}
                                        sx={{ minWidth: 120 }}
                                    >
                                        {renderSites()}
                                    </Select>
                                    {errors.site && (
                                        <FormHelperText error sx={{ my: 1 }}>
                                            {errors.site}
                                        </FormHelperText>
                                    )}
                                </Stack>
                                {/* date */}
                                <Stack spacing={1} alignItems="center" justifyContent="center">
                                    <InputLabel>Jour de vente</InputLabel>
                                    <DatePicker
                                        disableCloseOnSelect={false}
                                        showToolbar={false}
                                        variant="inline"
                                        name="date"
                                        value={values.date}
                                        inputFormat="DD/MM/YYYY"
                                        renderInput={(params) => <TextField {...params} variant="standard" sx={{ width: 140 }} />}
                                        onChange={val => {
                                            setFieldValue("date", moment.utc(val).valueOf())
                                        }}
                                    />
                                    {errors.date && (
                                        <FormHelperText error sx={{ my: 1 }}>
                                            {errors.date}
                                        </FormHelperText>
                                    )}
                                </Stack>
                                {/* brand */}
                                <Stack spacing={1} alignItems="center">
                                    <InputLabel>Marque</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="brand"
                                        label="Marques"
                                        placeholder="Marques"
                                        value={values.brand}
                                        onChange={handleChange}
                                        sx={{ minWidth: 110 }}
                                    >
                                        {renderBrand()}
                                    </Select>
                                    {errors.brand && (
                                        <FormHelperText error sx={{ my: 1 }}>
                                            {errors.brand}
                                        </FormHelperText>
                                    )}
                                </Stack>
                                {/* typeSubmit */}
                                <Stack spacing={1} alignItems="center">
                                    <InputLabel>Mode de dispatch</InputLabel>
                                    <Select
                                        variant="standard"
                                        name="typeSubmit"
                                        label="Mode de dispatch"
                                        placeholder="Mode de dispatch"
                                        value={values.typeSubmit}
                                        onChange={handleChange}
                                    >
                                        {renderTypeSubmit()}
                                    </Select>
                                    {errors.typeSubmit && (
                                        <FormHelperText error sx={{ my: 1 }}>
                                            {errors.typeSubmit}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Stack>

                            <div className="flexRow justifyCenter stretchSelf">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                >
                                    Accéder au dispatch
                                </Button>
                            </div>
                        </Stack>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default DispatchForm