import React, { useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const Actions = {
    EXTRACT: "EXTRACT",
    COMPUTE: "COMPUTE",
    UPDATE_STOCKS: "UPDATE_STOCKS",
    BREAD: "BREAD",
    ORDER_REPORT: "ORDER_REPORT"
}

const PlanningProductionActions = (props) => {

    const {
        onClickComputeDispatch,
        onClickExtract,
        onClickUpdateStocks,
        onClickBread,
        onClickOrderReport
    } = props

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = (_event, action) => {
        switch (action) {
            case Actions.EXTRACT:
                onClickExtract()
                break
            case Actions.COMPUTE:
                onClickComputeDispatch()
                break
            case Actions.UPDATE_STOCKS:
                onClickUpdateStocks()
                break
            case Actions.BREAD:
                onClickBread()
                break
            case Actions.ORDER_REPORT:
                onClickOrderReport()
                break
            default:
                // do nothing
        }

        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

    return (
        <span>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={id}
                open={Boolean(open)}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <MenuItem onClick={event => handleMenuItemClick(event, Actions.EXTRACT)}>Extraire</MenuItem>
                <MenuItem onClick={event => handleMenuItemClick(event, Actions.COMPUTE)}>Calcul dispatch</MenuItem>
                <MenuItem onClick={event => handleMenuItemClick(event, Actions.UPDATE_STOCKS)}>Mise à jour stocks FC</MenuItem>
                <MenuItem onClick={event => handleMenuItemClick(event, Actions.BREAD)}>Commande pain</MenuItem>
                <MenuItem onClick={event => handleMenuItemClick(event, Actions.ORDER_REPORT)}>Rapport de commande</MenuItem>
            </Menu>
        </span>
    )
}

export default PlanningProductionActions
