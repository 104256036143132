
const defaultState = {
    machineType: null,
    machineModel: null,
    machine: null,
    machineTypes: [],
    machineModels: [],
    machines: [],
    oldMachines: [],
    ordersSnackBar: { open: false, type: "message", message: "", duration: 1000 },
}

export default function (state = defaultState, action) {
    switch (action.type) {
        // load data list
        case "MACHINE_TYPES_LOADED":
            return {
                ...state,
                machineTypes: action.machineTypes,
            }
        case "MACHINE_MODELS_LOADED":
            return {
                ...state,
                machineModels: action.machineModels,
            }
        case "MACHINES_LOADED":
            return {
                ...state,
                machines: action.machines,
            }
        case "OLD_MACHINES_LOADED":
            return {
                ...state,
                oldMachines: action.oldMachines,
            }
        // load single data
        case "MACHINE_TYPE_LOADED":
            return {
                ...state,
                machineType: action.machineType,
            }
        case "MACHINE_MODEL_LOADED":
            return {
                ...state,
                machineModel: action.machineModel,
            }
        case "MACHINE_LOADED":
            return {
                ...state,
                machine: action.machine,
            }
        // clear states
        case "CLEAR_MACHINE_TYPES": {
            return {
                ...state,
                machineTypes: []
            }
        }
        case "CLEAR_MACHINE_MODELS": {
            return {
                ...state,
                machineModels: []
            }
        }
        case "CLEAR_OLD_MACHINES":
            return {
                ...state,
                oldMachines: [],
            }
        case "CLEAR_MACHINES": {
            return {
                ...state,
                machines: []
            }
        }
        case "CLEAR_MACHINE_TYPE": {
            return {
                ...state,
                machineType: null
            }
        }
        case "CLEAR_MACHINE_MODEL": {
            return {
                ...state,
                machineModel: null
            }
        }
        case "CLEAR_MACHINE": {
            return {
                ...state,
                machine: null
            }
        }
        default:
            return {
                ...state
            }
    }
}

// ---------------------------------------- //
// --------------- SELECTOR --------------- //
// ---------------------------------------- //
export function getMachineTypesSelector(state) {
    return state.machines.machineTypes
}

export function getMachinesSelector(state) {
    return state.machines.machines
}

export function getMachineModelsSelector(state) {
    return state.machines.machineModels
}

export function getMachineTypeSelector(state) {
    return state.machines.machineType
}

export function getMachineSelector(state) {
    return state.machines.machine
}

export function getMachineModelSelector(state) {
    return state.machines.machineModel
}
