import { Paper, styled, Typography } from "@mui/material"
import { COLORS } from "../utils"

export const StyledPaperTitle = styled(Typography)(() => ({
	fontSize: 16,
	fontWeight: "bold"
}))

export const StyledPaperLabel = styled(Typography)(() => ({
	color: COLORS.BLUEGREY_700,
	width: 200,
	minWidth: 200
}))

export const StyledPaper = styled(Paper)(() => ({
  backgroundColor: COLORS.WHITE,
  borderRadius: 5,
  padding: "20px 32px 40px 32px",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  width: "100%"
}))