import { getData } from ".."

const defaultSate = {
    sectionsGeneric : [],
    ingredients: [],
    cookingModes: [],
    selectedSectionGeneric: null,
    saveInterval: 30000,
    pristine: false,
    exitAfterSave: false,
    relatedSectionsCount: 0
}

export default function (state = defaultSate, action) {

    switch (action.type) {
        case "SECTIONS_GENERIC_LOADED":
            return {
                ...state,
                sectionsGeneric: action.sectionsGeneric,
                selectedSectionGeneric: action.selectedSectionGeneric
            }
        case "SECTION_GENERIC_SAVED":
            return {
                ...state,
                selectedSectionGeneric: action.selectedSectionGeneric
            }
        case "SECTION_GENERIC_SELECTED":
            return {
                ...state,
                selectedSectionGeneric: action.selectedSectionGeneric,
                relatedSectionsCount: (action.relatedSectionsCount) ? action.relatedSectionsCount : 0
            }
        case "INGREDIENTS_LOADED":
            return {
                ...state,
                ingredients: action.ingredients
            }
        case "COOKING_MODE_LOADED":
            return {
                ...state,
                cookingModes: action.cookingModes
            }

        case "EXIT_AFTER_SAVE":
            return {
                ...state,
                exitAfterSave: action.exit
            }
        case "SECTION_GENERIC_REMOVED": {
            return {
                ...state,
            }
        }
        default:
            return {
                ...state
            }
    }
}
//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getSection(state, id) {
    if (!id)
        return null
    return (state.sectionsGeneric.sectionsGeneric || []).find(section => section.id === id)
}

export function getSelectedSection(state, errorIfNotFound = false) {
    return getData(state, "sectionsGeneric.selectedSectionGeneric", errorIfNotFound && "No section selected")
}