import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import PropTypes from "prop-types"
import clsx from "clsx"
import dayjs from "dayjs"

const useStyles = makeStyles({
  infoRow: {
    width: "100%",
    marginBottom: 8,
    padding: "4px 0",
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
    fontSize: 10,
    lineHeight: "8px",
    fontWeight: 400,
    color: COLORS.SITE_INFORMATION_VALUE,
    "& > p": {
      margin: 0
    }
  },
  dlcClose: {
    width: 166,
    padding: "4px 8px",
    marginLeft: -9,
    borderLeft: `2px solid ${COLORS.ORANGE_INVENTORY_BORDER}`,
    backgroundColor: COLORS.ORANGE_INVENTORY_BACKGROUND
  },
  dlcPassed: {
    width: 166,
    padding: "4px 8px",
    marginLeft: -9,
    backgroundColor: COLORS.RED_INVENTORY_BACKGROUND
  }
})


const SelectableLotCardContent = ({ document, collection, selected = false, currentDate, dlcWarningDate }) => {

  const classes = useStyles()

  const getRowClassName = (dlc) => {
    if (selected) return ""

    if (collection === "SecondaryDLC") {
      if (dayjs(dlc).startOf("day").valueOf() === dayjs(currentDate).startOf("day").valueOf()) {
        return classes.dlcClose
      }
      if (dlc < currentDate) return classes.dlcPassed
      return ""
    }

    return dlc < currentDate
      ? classes.dlcPassed
      : dlc <= dlcWarningDate
        ? classes.dlcClose
        : ""
  }

  if (document.hypothetical) return null

  return (
    <>
      <div className={clsx(classes.infoRow, getRowClassName(document.dlcTimestamp))}>
        {collection === "SecondaryDLC" ? <p>DLC SECONDAIRE</p> : <p>DLC</p>}
        <p>{document.dlc}</p>
      </div>
      <div className={classes.infoRow}>
        <p>LOT</p>
        <p>{document.lotNumber}</p>
      </div>
    </>
  )

}

export default SelectableLotCardContent

SelectableLotCardContent.propTypes = {
  document: PropTypes.object.isRequired,
  collection: PropTypes.oneOf(["Lot", "SecondaryDLC"]).isRequired,
  mode: PropTypes.string, // for lot only
  selected: PropTypes.bool
}