
import React from "react"
import { Stack, Typography } from "@mui/material"
import { StyledPaper, StyledPaperTitle, StyledPaperLabel } from "../../../../components/StyledSingleParseObjectComponents"
import GenericEditIcon from "../../../../components/GenericEditIcon"

const DistributionCenterDeliveryNoteConfig = ({ distributionCenter = {}, onEdit = null }) => {

	return (
		<StyledPaper>
			<Stack gap="16px">
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<StyledPaperTitle>Informations pour BL</StyledPaperTitle>
					<GenericEditIcon onEdit={onEdit} />
				</Stack>
				<Stack gap="16px">
					<Stack direction="row">
						<StyledPaperLabel>Contremarque</StyledPaperLabel>
						<Typography>{distributionCenter.counterMark}</Typography>
					</Stack>
					<Stack direction="row">
						<StyledPaperLabel>Code fournisseur</StyledPaperLabel>
						<Typography>{distributionCenter.supplierCode}</Typography>
					</Stack>
					<Stack direction="row">
						<StyledPaperLabel>Mail interne</StyledPaperLabel>
						<Typography>{distributionCenter.internalMail}</Typography>
					</Stack>
					<Stack direction="row">
						<StyledPaperLabel>Mail externe</StyledPaperLabel>
						<Typography>{distributionCenter.externalMail}</Typography>
					</Stack>
				</Stack>
			</Stack>
		</StyledPaper>
	)

}

export default DistributionCenterDeliveryNoteConfig