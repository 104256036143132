import React from "react"

import { FormControlLabel, Stack, Switch, Typography } from "@mui/material"

const SwitchProductionSteps = ({ isEdition, onCheck, checked }) => {
  const handleCheck = (event) => {
    onCheck(event.target.checked)
  }

  const label = (
    <Typography variant="body2" sx={{ color: "grey.600" }}>
      {checked ? "Nouvelle fiche “Étapes de production”" : "Ancienne fiche “Recette”"}
    </Typography>
  )

  return (
    <Stack direction="row" alignItems="center" spacing={5}>
      <Typography variant="body2" sx={{ color: "grey.600" }}>
        Données utilisées pour cette fiche technique :
      </Typography>

      {/* switch input or value */}
      {isEdition
        ? (
          // input
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                onChange={handleCheck}
                color="secondary"
              />}
            label={label}
          />
        ) : label
      }
  </Stack>
  )
}

export default SwitchProductionSteps