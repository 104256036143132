import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Formik, Form } from "formik"
import { TextField, Table, TableBody, TableRow, TableCell, Select, MenuItem, Tooltip } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import IconButton from "@mui/material/IconButton"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import CloseIcon from "@mui/icons-material/Clear"
import DistributionCenterDispatchSitesTableHead from "../DistributionCenterDispatchSitesTableHead"
import { makeStyles } from "@mui/styles"
import _cloneDeep from "lodash/cloneDeep"
import { SUCY_ID } from "../../../../../parseManager/site/parseSiteManager"
import { COLORS } from "../../../../../utils"

const useStyles = makeStyles(() => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flexWrap: "wrap"
  },
  field: {
    width: "100%",
    marginBottom: 10
  },
  titleRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 5px 16px 5px",
    borderBottom: `1px solid ${COLORS.GREY_ORDER_BORDER}`
  },
  fridgeInfosCell: {
    textAlign: "center",
    padding: "0 10px",
    maxWidth: 100
  },
  fridgeInfosButton: {
    padding: 0
  },
  tooltip: {
    backgroundColor: COLORS.BLUE_CREDIT_TOOLBAR,
    fontSize: 14,
  },
  arrow: {
    color: COLORS.BLUE_CREDIT_TOOLBAR,
  },
  toolTipIcon: {
    color: COLORS.BLUE_CREDIT_TOOLBAR,
    cursor: "pointer",
    fontSize: 20
  },
  addDispatchSite: {
    width: 48,
    color: COLORS.BLUE_CREDIT_TOOLBAR
  },
  cell: {
    textAlign: "center"
  },
  dispatchSiteCell: {
    textAlign: "center",
    padding: "0 10px",
    maxWidth: 200
  },
}))

export const distributionCenterDispatchSitesFormId = "distributionCenterDispatchSitesFormId"

const DistributionCenterDispatchSitesForm = ({ distributionCenter, handleSubmit }) => {

  const siteList = useSelector(state => state.planningSettings.siteList)

  const [dispatchSites, setDispatchSites] = useState(distributionCenter && distributionCenter.dispatchSites ? distributionCenter.dispatchSites : [{ name: "Sucy", priority: 1, objectId: SUCY_ID }])

  const classes = useStyles()


  const addDispatchSite = (setFieldValue) => {
    const copy = _cloneDeep(dispatchSites)
    //Sucy is the dispatch site by default
    const sucySite = siteList.find(el => el.objectId === SUCY_ID)

    sucySite.priority = 1
    copy.push(sucySite)
    setFieldValue("dispatchSites", copy)
    setDispatchSites(copy)
  }

  const removeSiteInfo = (index, setFieldValue) => {
    const copy = _cloneDeep(dispatchSites)

    copy.splice(index, 1)

    setFieldValue("dispatchSites", copy)
    setDispatchSites(copy)
  }

  const updateSite = (e, index, type, setFieldValue) => {
    const copy = _cloneDeep(dispatchSites)
    if (type === "priority") {
      copy[index][type] = Number(e.target.value)
    } else {
      const siteChosen = siteList.find(el => el.objectId === e.target.value)
      siteChosen.priority = copy[index].priority
      copy[index] = siteChosen
    }

    setFieldValue("dispatchSites", copy)
    setDispatchSites(copy)
  }

  const renderSitesDispatch = (sites, setFieldValue, errors) => {
    return sites.map((currentSite, i) => {
      return (
        <TableRow key={i}>
          <TableCell classes={{ root: classes.fridgeInfosButton }}>
            <IconButton onClick={() => removeSiteInfo(i, setFieldValue)} size="large">
              <CloseIcon />
            </IconButton>
          </TableCell>
          <TableCell classes={{ root: classes.dispatchSiteCell }}>
            <Select
              variant="standard"
              value={currentSite.objectId}
              onChange={(e) => updateSite(e, i, "objectId", setFieldValue)}
            >
              {
                siteList.map((currentOfSiteList, index) => (
                  <MenuItem value={currentOfSiteList.objectId} key={index}>{currentOfSiteList.name}</MenuItem>
                ))
              }
            </Select>
          </TableCell>
          <TableCell classes={{ root: classes.fridgeInfosCell }}>
            <TextField
              variant="standard"
              value={currentSite.priority}
              type="number"
              onChange={(e) => updateSite(e, i, "priority", setFieldValue)}
              error={errors.dispatchSites && errors.dispatchSites[i] && errors.dispatchSites[i].priority}
              helperText={errors.dispatchSites && errors.dispatchSites[i] && errors.dispatchSites[i].priority ? errors.dispatchSites[i].priority : ""}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: 100
                }
              }}
            />
          </TableCell>
          <TableCell classes={{ root: classes.fridgeInfosButton }} />
        </TableRow>
      )
    })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        dispatchSites: distributionCenter.dispatchSites
      }}>
      {({ setFieldValue, errors = {} }) => (
        <Form
          id={distributionCenterDispatchSitesFormId}>
          <div className={classes.formAddressContainer}>
            <div className={classes.titleRow}>
              <h2>
                Sites de dispatch
              </h2>
              <Tooltip
                placement="top"
                title={"A classer par ordre de priorité (1 = le plus prioritaire). Les produits sont envoyés depuis le site le plus prioritaire si possible, depuis les autres sites sinon."}
                enterDelay={500}
                leaveDelay={200}
                arrow
                disableInteractive
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
              >
                <InfoIcon className={classes.toolTipIcon} />
              </Tooltip>
            </div>

            <div>
              <Table>
                <DistributionCenterDispatchSitesTableHead />
                <TableBody>
                  {renderSitesDispatch(dispatchSites, setFieldValue, errors)}
                </TableBody>
              </Table>
              {errors.dispatchSites && !Array.isArray(errors.dispatchSites) && (<div className={classes.errorField}>{errors.dispatchSites}</div>)}
              <IconButton
                className={classes.addDispatchSite}
                onClick={() => addDispatchSite(setFieldValue)}
                size="large">
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DistributionCenterDispatchSitesForm