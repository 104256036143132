import React from "react"
import { COLORS } from "../../utils"
import Typography from "@mui/material/Typography"
import { Box, Icon, styled } from "@mui/material"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

const StyledIcon = styled(Icon)({
    marginRight: 10,
    color: COLORS.ORANGE_CREDIT_NOTE,
    height: 24
})

const PlanningHeaderTitle = () => {
    return (
        <Box display="flex" alignItems="center">
            <StyledIcon>
                <CalendarMonthIcon />
            </StyledIcon>
            <Typography variant="h6" color="textPrimary" sx={{ color: COLORS.ORANGE_CREDIT_NOTE }}>
                Plannings
            </Typography>
        </Box>
    )
}

export default PlanningHeaderTitle
