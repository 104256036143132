import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import LotFilterHeaderTitle from "../../components/Order/Lot/LotFilterHeaderTitle"
import { Box, IconButton, Tab, Tabs } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem } from "@mui/material"
import LotFilter from "./LotFilter"
import LotReturn from "./LotReturn"
import ExtractLotModal from "./ExtractLotModal"
import moment from "moment"
import { loadReturnLotsData } from "../../actions/LotInventory/LotInventory"
import { changeLotTab } from "../../actions/Dispatch/dispatch"
import {
  LOT_OUTPUT_MODE,
  LOT_RETURN_MODE,
} from "../../utils/lotInventoryUtils"

const LotOutput = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [extractInventory, setExtractInventory] = useState(false)
  const tabValue = useSelector((state) => state.lotInventory.tabValue)
  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExtractInventory(false)
  }

  const openExtractModal = () => {
    setExtractInventory(true)
    setAnchorEl(null)
  }

  const _onTabChange = (_event, tabValue) => {
    const dateAtMidnight = moment.utc().startOf("day").valueOf()
    dispatch(changeLotTab(tabValue))
    dispatch(loadReturnLotsData(dateAtMidnight))
  }

  return (
    <>
      <Box>
        <GenericTopBarHeader
          title={<LotFilterHeaderTitle mode={"outputColor"} />}
          rightAction={
            <span>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={id}
                open={Boolean(open)}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {
                  <MenuItem onClick={() => openExtractModal()}>
                    Extraire les sorties
                  </MenuItem>
                }
              </Menu>
            </span>
          }
        />
      </Box>

      <Tabs
        value={
          tabValue !== null && tabValue !== undefined ? tabValue : "output"
        }
        onChange={_onTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
        centered
        sx={{
          borderBottom: "1px solid #E6E6E6",
        }}
      >
        <Tab label="SORTIES DE STOCKS" />
        <Tab label="RETOURS DE PROD" />
      </Tabs>
      {tabValue === 0 && <LotFilter />}
      {tabValue === 1 && <LotReturn mode={LOT_RETURN_MODE} />}
      <ExtractLotModal
        open={extractInventory}
        onClose={handleClose}
        mode={LOT_OUTPUT_MODE}
      />
    </>
  )
}

export default LotOutput
