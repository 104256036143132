import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withStyles } from "@mui/styles"

import { ACTIVE_KFC_BRANDS, COLORS, roundNumber } from "../../utils"
import { getRecipePrice } from "../../utils/recipes"
import ProductHeaderActions from "./ProductHeaderActions"
import { getRecipeById } from "../../parseManager/recipe/parseRecipeManager"
import { deleteRecipe, duplicateRecipe } from "../../actions/Recipe/recipes"
import { getProductTypeLabelByProduct } from "../../utils/productTypesUtils"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

import CloudinaryImage from "../Image/CloudinaryImage"
import CommentsIconBadge from "../Comments/CommentsIcon"

export const Header = (props) => {
    const { image, jsonRecipe, classes, onToggleDrawer } = props

    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const dispatch = useDispatch()
    const commercialName = jsonRecipe
        ? jsonRecipe.commercialName
        : ""

    const status = jsonRecipe && jsonRecipe.isActive
        ? "Actif"
        : "Inactif"

    const uniqueCode = jsonRecipe && jsonRecipe.uniqueCode
        ? jsonRecipe.uniqueCode
        : ""

    const brands = jsonRecipe && Array.isArray(jsonRecipe.brands) ? jsonRecipe.brands : []
    const recipeBrands = ACTIVE_KFC_BRANDS.filter(elem => brands.includes(elem.name))

    const onClickDelete = async () => {
        const recipe = await getRecipeById(jsonRecipe.objectId, [], false)
        dispatch(deleteRecipe(recipe, true))
    }

    const onClickDuplicate = (currentRecipeId, newRecipeName) => {
        dispatch(duplicateRecipe(currentRecipeId, newRecipeName, true))
    }

    const commentsCount = useMemo(() => {
        if (Array.isArray(jsonRecipe.comments)) {
            return jsonRecipe.comments.length
        }
        return 0
    }, [jsonRecipe])

    return (
        <div className={classes.header}>
            {
                jsonRecipe
                    ?
                    <>
                        {
                            image ?
                                <CloudinaryImage
                                    containerClassName={classes.recipeImage}
                                    imageId={image && image.publicId}
                                    width={240}
                                    height={160}
                                    resize="scale"
                                    customQuality="auto"
                                />
                                : <img
                                    src="/img/noImageAvailabled.png"
                                    alt="Indisponible"
                                    className={classes.recipeImage}
                                />
                        }
                        <div className={classes.infosContainer}>
                            <div className={classes.recipeCommercialNameContainer}>
                                {commercialName}
                            </div>
                            <div className={classes.subInfosContainer}>
                                <div className={classes.singleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Code
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {uniqueCode}
                                    </span>
                                </div>

                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Statut
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {status}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Type
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {getProductTypeLabelByProduct(jsonRecipe, productTypeOptions)}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Marque{recipeBrands.length > 1 ? "s" : ""}
                                    </span>
                                    <div className={classes.brandsValueContainer}>
                                        {
                                            recipeBrands.map((brand, index) => {
                                                const defaultPrice = getRecipePrice(jsonRecipe, {})
                                                let price = getRecipePrice(jsonRecipe, brand.name)

                                                price = (price) ? price : defaultPrice

                                                return (
                                                    <div key={index} className={classes.singleBrandContainer}>
                                                        <img className={classes.logo} src={brand.whiteImage} alt="logo" />
                                                        <span className={classes.brandName}>{brand.label}</span>
                                                        {
                                                            (price > 0) &&
                                                            <span className={classes.brandName}>{`(${(roundNumber(price, 2) + "").replace(".", ",")}€)`}</span>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            {onToggleDrawer && (
               <CommentsIconBadge count={commentsCount} onToggle={onToggleDrawer}/>
            )}
            <ProductHeaderActions
                onClickDelete={onClickDelete}
                onClickDuplicate={onClickDuplicate}
                jsonData={jsonRecipe}
                type={"RECIPE"}
            />
        </div>
    )
}

const styles = {
    header: {
        width: "100%",
        backgroundColor: COLORS.BLUEGREY_700,
        height: 160,
        display: "flex",
        color: COLORS.WHITE
    },

    recipeImage: {
        width: 245,
        height: "100%"
    },
    infosContainer: {
        width: "calc(100% - 245px)",
        paddingLeft: 48,
        paddingTop: 16,
        paddingRight: 16
    },
    recipeCommercialNameContainer: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 30,
    },
    subInfosContainer: {
        display: "flex",
        marginTop: 16
    },
    singleSubInfoContainer: {
        display: "flex",
        flexDirection: "column"
    },
    notFirstSingleSubInfoContainer: {
        composes: "$singleSubInfoContainer",
        marginLeft: 32
    },
    subInfoLabel: {
        fontSize: 12
    },
    subInfoValue: {
        fontSize: 16,
        marginTop: 8
    },
    brandsValueContainer: {
        display: "flex",
    },
    logo: {
        width: 16,
        height: 16
    },
    singleBrandContainer: {
        marginRight: 24,
        marginTop: 8
    },
    brandName: {
        fontSize: 16,
        marginLeft: 8
    },
    icon: {
        color: COLORS.WHITE,
        height: "fit-content",
        width: "fit-content",
        marginTop: "8px",
        padding: "4px"
    }
}

export default withStyles(styles)(Header)
