import React from "react"
import { makeStyles } from "@mui/styles"
import LotCard from "../../../components/Order/Lot/LotCard"
import { creditNotesType } from "../../../utils/creditNoteUtils"
import { Stack } from "@mui/material"
import LotDoneCard from "../../../components/Order/Lot/LotDoneCard"

const useStyles = makeStyles(() => ({
	cardContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		gap: "16px"
	},
	leftTitle: {
		fontSize: 20
	},
	leftSubtitle: {
		color: "#7C7C7C"
	},
	cards: {
		display: "flex",
		flexWrap: "wrap",
		gap: "24px"
	},
	buttonContainer: {
		position: "absolute",
		left: "50%",
		bottom: "5%"
	}
}))

const SupplierItemLotsTopBlock = (props) => {
	const {
		lots,
		onSelectLot,
		selectedLot,
		creditNotes,
		actions,
		supplierItemReceptionStatus,
		toggleLotPreview,
	} = props
	const classes = useStyles()

	const handleClickBroken = () => {
		if (creditNotes.size) {
			actions({
				method: "SHOW_UPDATE_CREDIT_NOTE_MODAL",
				data: {
					type: creditNotesType.LOT,
				}
			})
			return
		}
		actions({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL",
			data: {
				type: creditNotesType.LOT
			}
		})
	}

	const handleClickBrokenView = () => {
		actions({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL_REEDITION",
			data: {
				type: creditNotesType.LOT,
			}
		})
	}

	const deleteLot = (lot) => {
		actions({
			method: "DELETE_LOT",
			data: {
				id: lot.id,
				quantity: lot.quantity
			}
		})
	}

	const addCreditNote = () => {
		actions({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL",
			data: {
				type: creditNotesType.LOT
			}
		})
	}

	return (
		<div className={classes.cardContainer}>
			<Stack spacing={1}>
				<p className={classes.leftTitle}>
					Création de lots :
				</p>
				<p className={classes.leftSubtitle}>Créez un ou plusieurs lots et générez des avoirs</p>
			</Stack>
			{lots?.length !== 0 && <div className={classes.cards}>
				{
					lots.map((lot, i) => {
						return supplierItemReceptionStatus === "DONE" ? (
							<LotDoneCard
								key={i}
								lot={lot}
								selectedLot={selectedLot}
								onSelectLot={onSelectLot}
								creditNotes={lot.creditNotes || []}
								handleClickBroken={handleClickBroken}
								handleClickBrokenView={handleClickBrokenView}
								addCreditNote={addCreditNote}
								toggleLotPreview={toggleLotPreview}
							/>
						)
						: (<LotCard
							key={i}
							lot={lot}
							selectedLot={selectedLot}
							onSelectLot={onSelectLot}
							creditNotes={creditNotes.get(lot.id) || null}
							handleClickBroken={handleClickBroken}
							deleteLot={deleteLot}
							addCreditNote={addCreditNote}
						/>)
					}
					)
				}
			</div>}
		</div>
	)
}

export default SupplierItemLotsTopBlock