import Parse from "parse"
import { setValues } from "../parseUtils"

export const Image = Parse.Object.extend("Image")

export const imageEditableKeys = [
    "publicId",
    "type",
    "subtype",
    "brands",
    "storageData"
]

export const IMAGE_SAVED_ACTION = "IMAGE_SAVED"
export const IMAGE_DELETE_ACTION = "IMAGE_DELETE"

export async function getImageByObjectId (objectId) {
    if (!objectId) {
        return null
    }

    try {
        return await new Parse.Query(Image).get(objectId)
    } catch (e) {
        return null
    }
}

export async function saveImage (image={}) {
    const { objectId } = image

    let parseImage = objectId
        ? await getImageByObjectId(objectId)
        : null

    parseImage = parseImage || new Image()

    setValues(parseImage, image, imageEditableKeys)

    return await parseImage.save()
}

export async function deleteImage (image={}) {
    const { objectId } = image

    if (!objectId) {
        return
    }

    const parseImage = await getImageByObjectId(objectId)

    if (!parseImage) {
        return
    }

    return await parseImage.destroy()
}
