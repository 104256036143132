import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import moment from "moment"
import MonitoringToolbar from "../../components/Planning/Dashboard/Monitoring/MonitoringToolbar"
import SalesDatesTable from "../../components/Planning/Dashboard/Monitoring/SalesDatesTable"
import TargetsTable from "../../components/Planning/Dashboard/Monitoring/TargetsTable"
import {
    loadPlanningDashboardMonitoring
} from "../../actions/Planning/PlanningDashboard"
import FoodCostsTable from "../../components/Planning/Dashboard/Monitoring/FoodCostsTable"
import { COLORS } from "../../utils"
import { Box, styled, Typography } from "@mui/material"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import { getPlanningDashboardProductTypesByBrand } from "../../reducers/Planning/PlanningDashboard"
import { getTableInfos } from "../../components/Planning/Dashboard/Monitoring/Monitoring"

const StyledContainer = styled("div")({
    top: 25,
    position: "relative",
    paddingLeft: 32,
    display: "block",
    background: COLORS.WHITE
})

const sx = {
    toolbarContainer: {
        position: "relative",
        display: "flex",
        width: "100%"
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        mb: 3
    }
}

const Monitoring = () => {
    const targetsData = useSelector(state => state.planningDashboard.targetsData)
    const salesDatesData = useSelector(state => state.planningDashboard.salesDatesData)
    const foodCostData = useSelector(state => state.planningDashboard.foodCostData)
    const date = useSelector(state => state.planningDashboard.date)
    const productTypesByBrand = useSelector(getPlanningDashboardProductTypesByBrand)
    const [tableData, setTableData] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        document.title = "KFC - Monitoring"
        return () => document.title = "KFC"
    }, [])

    useEffect(() => {
        const data = getTableInfos(productTypesByBrand)
        setTableData(data)
    }, [productTypesByBrand])

    const onDateChange = (event) => {
        const date = moment.utc(event).startOf("day").valueOf()
        
        dispatch(loadPlanningDashboardMonitoring(date))
    }

    if (!tableData) return

    return (
        <Box display="flex" flexDirection="column" >
            <Box sx={{ height: 64 }}>
                <GenericTopBarHeader
                    title={<PageHeaderTitle title="Supervision" icon={<HowToRegIcon />} color={COLORS.RED_NUTRITION} />}
                />
            </Box>
            <StyledContainer>
                <Box sx={sx.toolbarContainer}>
                    <MonitoringToolbar date={date} onDateChange={onDateChange} />
                </Box>
                <div>
                    <Typography variant="h4" textAlign="center" sx={sx.title}>Volume de production</Typography>
                    <SalesDatesTable data={salesDatesData} tableData={tableData} targetsData={targetsData} />
                </div>
                <Box mt={5}>
                    <Typography variant="h4" textAlign="center" sx={sx.title}>Cibles</Typography>
                    <TargetsTable data={targetsData} tableData={tableData} />
                </Box>
                <Box mt={5}>
                    <Typography variant="h4" textAlign="center" sx={sx.title}>Foodcost</Typography>
                    <FoodCostsTable data={foodCostData} targetsData={targetsData} salesDatesData={salesDatesData} tableData={tableData} />
                </Box>
            </StyledContainer>
        </Box>
    )
}

export default Monitoring
