import { KFC_BRANDS } from "../../utils"
import {computeHubDispatchQuantity, getDispatchHubsFiltered, getTabLengthByComputed} from "../../utils/dispatchUtils"
import _cloneDeep from "lodash/cloneDeep"
import _isEqual from "lodash/isEqual"

const defaultSate = {
	data: [],
	dispatchDataToDo: [],
	dispatchDataInProgress: [],
	dispatchDataError: [],
	dispatchDataDone: [],
	computedQuantityLength: [],
	hubs: [],
	hubTab: 0,
	productTab: 0,
	computedQuantity: [],
	date: null,
	hubError: false,
	hub: false,
	brand: KFC_BRANDS[0].name,
	site: null,
	selectedTypeFilter: "ALL",
	selectedProductDispatch: null,
	subcontractorProduct: null,
	subcontractorProductLots: [],
	dispatchSnackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultSate, action) {
	switch (action.type) {
		case "DISPATCH_DATA_LOADED":
			const computedQuantity =  (action.isHub && (!_isEqual(state.data, action.data) || !_isEqual(state.hubs, action.hubs)))?
				computeHubDispatchQuantity(action.data):
				state.computedQuantity

			return {
				...state,
				data: _cloneDeep(action.data),
				date: action.date,
				brand: action.brand,
				site: action.site,
				hubs: _cloneDeep(action.hubs),
				selectedProductDispatch: action.selectedProductDispatch,
				isHub: action.isHub,
				computedQuantity,
				productTypeOptions: action.productTypeOptions,
				dispatchDataToDo: action.isHub?[]:(action.data && action.data.filter(el =>
					(el.kfcStatus === "TODO" || (el.kfcStatus === "IN_PROGRESS" && el.prodWarehouse === null))
				)) || [],
				dispatchDataInProgress: action.isHub?[]:(action.data && action.data.filter(el => el.kfcStatus === "IN_PROGRESS")) || [],
				dispatchDataError: action.isHub?[]:(action.data && action.data.filter(el => el.hasError && el.kfcStatus === "DONE")) || [],
				dispatchDataDone: action.isHub?[]:(action.data && action.data.filter(el => el.kfcStatus === "DONE" && el.hasError === false)) || [],
				computedQuantityLength: action.isHub?getTabLengthByComputed(computedQuantity, action.hubs):[]
			}
		case "DISPATCH_DATE_UPDATED":
			return {
				...state,
				date: action.date
			}
		case "DISPATCH_SELECTED_TYPE_UPDATED":
			return {
				...state,
				selectedTypeFilter: action.selectedTypeFilter
			}
		case "DISPATCH_BRAND_UPDATED":
			return {
				...state,
				brand: action.brand
			}
		case "DISPATCH_BRAND_DATE_AND_SITE_UPDATED":
			return {
				...state,
				brand: action.brand,
				date: action.date,
				site: action.site
			}
		case "DISPATCH_SELECTED_PRODUCT_DISPATCH_UPDATED":
			return {
				...state,
				selectedProductDispatch: action.selectedProductDispatch,
				subcontractorProduct: action.subcontractorProduct,
				site: action.site,
				subcontractorProductLots: action.subcontractorProductLots
			}
		case "DISPATCH_HUB_HAS_ERROR":
			return {
				...state,
				hubError: action.error
			}
		case "DISPATCH_HUB_TAB_CHANGED":
			return {
				...state,
				hubTab: action.hubTab
			}
		case "DISPATCH_PRODUCT_TAB_CHANGED":
			return {
				...state,
				productTab: action.productTab
			}
		case "DISPATCH_HUB_DISPATCH_UPDATED":
			return {
				...state,
				hub: action.hub,
				site: action.site
			}
		case "DISPATCH_OPEN_SNACKBAR":
		case "DISPATCH_CLOSE_SNACKBAR":
			return {
				...state,
				dispatchSnackBar: action.dispatchSnackBar,
				hubError: false
			}
		default:
			return {
				...state
			}
	}
}

export function getItem(state, idProductDispatch) {
	const productDispatch = state.dispatch.data.find(data => data.objectId === idProductDispatch)
	if (productDispatch) {
		productDispatch.hubs = getDispatchHubsFiltered(productDispatch.hubs, productDispatch.site.objectId)
	}

	return productDispatch
}

export const getDispatchBrandSelector = (state) => state.dispatch.brand
export const getDispatchDateSelector = (state) => parseFloat(state.dispatch.date)
