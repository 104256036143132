import React, { useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const Actions = {
    EXPAND: "EXPAND",
    CREATION: "CREATION",
    VALIDATE: "VALIDATE"
}

const PlanningPrevActions = (props) => {

    const {
        folded,
        onFoldExpand,
        onCreatePlanningPrevCards,
        onValidatePrevCards
    } = props

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = (_event, action) => {
        switch (action) {
            case Actions.EXPAND:
                onFoldExpand()
                break
            case Actions.CREATION:
                onCreatePlanningPrevCards()
                break
            case Actions.VALIDATE:
                onValidatePrevCards()
                break
            default:
                // do nothing
        }

        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

    return (
        <span>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={id}
                open={Boolean(open)}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <MenuItem
                    onClick={event => handleMenuItemClick(event, Actions.EXPAND)}
                >
                    { folded ? "Déplier les cartes" : "Replier toutes les cartes" }
                </MenuItem>
                <MenuItem
                    onClick={event => handleMenuItemClick(event, Actions.CREATION)}
                >
                    Générer prévisions
                </MenuItem>
                <MenuItem
                    onClick={event => handleMenuItemClick(event, Actions.VALIDATE)}
                >
                    Valider prévisions
                </MenuItem>
            </Menu>
        </span>
    )
}

export default PlanningPrevActions
