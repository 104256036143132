import moment from "moment"
import { packagingNature } from "../../utils/packaging"
import { checkAllDone } from "../../utils/lotGroupUtils"

const defaultState = {
    date: moment.utc().startOf("day").valueOf(),
    packaging: packagingNature[2].value,
    data: [],
    site: null,
    selectedProductPackaging: null,
    selectedProductDispatch: null,
    snackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultState, action) {
    let selectedProductDispatch, selectedProductPackaging
    
    switch (action && action.type) {
        case "LOT_GROUP_DATA_LOADED":
            return {
                ...state,
                data: action.data,
                date: action.date,
                packaging: action.packaging,
                selectedProductPackaging: action.selectedProductPackaging,
                productTypeOptions: action.productTypeOptions
            }
        case "LOT_GROUP_FILTERED_LOADED":
            return {
                ...state,
                date: action.date,
                site: action.site,
                packaging: action.packaging,
            }
        case "LOT_GROUP_DATE_UPDATED":
            return {
                ...state,
                date: action.date
            }
        case "LOT_GROUP_PACKAGING_UPDATED":
            return {
                ...state,
                packaging: action.packaging
            }
        case "LOT_GROUP_SELECTED_PRODUCTS_PACKAGING":
            return {
                ...state,
                selectedProductPackaging: action.selectedProductPackaging,
                selectedProductDispatch: action.selectedProductDispatch
            }
        case "LOT_GROUP_PACKAGING_MODE":
            return {
                ...state,
                mode: action.mode
            }
        case "LOT_GROUP_SELECTED_PRODUCT_PACKAGING_UPDATED":
            return {
                ...state,
                selectedProductPackaging: action.selectedProductPackaging,
                selectedProductDispatch: action.selectedProductPackaging.productDispatches[0]
            }
        case "LOT_GROUP_SELECTED_PRODUCT_DISPATCH_UPDATED":
            selectedProductPackaging = state.selectedProductPackaging
            const currentIndex = selectedProductPackaging.productDispatches.findIndex(el => el.objectId === action.selectedProductDispatch.objectId)
            const last = (currentIndex === (selectedProductPackaging.productDispatches.length - 1))
            let data = state.data
        
            selectedProductPackaging.productDispatches[currentIndex] = action.selectedProductDispatch
            const checkedPackaging = checkAllDone(selectedProductPackaging)
            data[data.findIndex(el => el.objectId === selectedProductPackaging.objectId)] = checkedPackaging
            selectedProductPackaging = !last ? checkedPackaging : null
            selectedProductDispatch =  !last ? selectedProductPackaging.productDispatches[currentIndex + 1] : null
            
            return {
                ...state,
                selectedProductPackaging,
                selectedProductDispatch,
                data
            }
        case "LOT_GROUP_OPEN_SNACKBAR":
        case "LOT_GROUP_CLOSE_SNACKBAR":
            return {
                ...state,
                snackBar: action.snackBar
            }
        default:
            return {
                ...state
            }
    }
}

export function getLotGroupItem(state, idProductpackaging) {
    return state.lotGroup.data.find(data => data.objectId === idProductpackaging)
}