import {
    actionWithLoader,
    push,
    onEnter,
    getReplacingPath
} from "../../Utils/utils"
import {
    getDistributionCentersOrderByPriority,
    getParseDistributionCentersFromIds, saveObjects,
    setValueToParseObject
} from "../../../parseManager/planning/parsePlanningManager"
import {
    fieldLabels,
    distributionCentersProductTypes
} from "../../../utils/dispatchPriority"

export function planningSettingsDispatchPriorityChangeBrand(brand) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "PLANNING_SETTINGS_DISTRIBUTION_CENTERS_UPDATE_BRAND",
            brand: brand
        })
    })
}

function parseDispatchObjectFromFormValue(dispatchFormValue, index) {
    const dispatchObj = {
        priority: Number(index) + 1,
    }

    for (const i in distributionCentersProductTypes) {
        const productType = distributionCentersProductTypes[i]

        const minField = fieldLabels.find(el => el.productType === productType && el.labelType === "min")
        const maxField = fieldLabels.find(el => el.productType === productType && el.labelType === "max")
        const reusableField = fieldLabels.find(el => el.productType === productType && el.labelType === "reusable")

        dispatchObj[productType] = {
            min: null,
            max: null,
            reusable: 0
        }
        if (dispatchFormValue.hasOwnProperty(minField.field) &&
            dispatchFormValue[minField.field] !== "" &&
            dispatchFormValue[minField.field] !== null &&
            dispatchFormValue[minField.field] !== undefined
        ) {
            dispatchObj[productType].min = Number(dispatchFormValue[minField.field])
        }
        else {
            dispatchObj[productType].min = null
        }
        if (dispatchFormValue.hasOwnProperty(maxField.field) &&
            dispatchFormValue[maxField.field] !== "" &&
            dispatchFormValue[minField.field] !== null &&
            dispatchFormValue[minField.field] !== undefined
        ) {
            dispatchObj[productType].max = Number(dispatchFormValue[maxField.field])
        }
        else {
            dispatchObj[productType].max = null
        }
        if (dispatchFormValue.hasOwnProperty(reusableField.field) &&
            dispatchFormValue[reusableField.field] !== "" &&
            dispatchFormValue[reusableField.field] !== null &&
            dispatchFormValue[reusableField.field] !== undefined
        ) {
            dispatchObj[productType].reusable = Number(dispatchFormValue[reusableField.field])
        }
        else {
            dispatchObj[productType].reusable = 0
        }
    }

    return dispatchObj
}

export const updateDistributionCentersDispatchPriority = (values) => {
    return actionWithLoader(async (dispatch) => {
        const distributionCentersFormValues = values.distributionCenters
        const ids = distributionCentersFormValues.map(distributionCenter => distributionCenter.objectId)
        const parseDistributionCenters = await getParseDistributionCentersFromIds(ids)

        let toUpdate = true
        for (const index in distributionCentersFormValues) {
            const distributionCenterFormValue = distributionCentersFormValues[index]
            const dispatchFormValue = distributionCenterFormValue.dispatch
            const parseDistributionCenter = parseDistributionCenters.find(parseDistributionCenter => parseDistributionCenter.id === distributionCenterFormValue.objectId)

            if (!parseDistributionCenter) {
                toUpdate = false
            }

            const dispatch = parseDispatchObjectFromFormValue(dispatchFormValue, index)
            setValueToParseObject(parseDistributionCenter, "dispatch", dispatch)
        }

        if (toUpdate) {
            await saveObjects(parseDistributionCenters)
            dispatch({
                type: "PLANNING_SETTINGS_SNACKBAR",
                planningSettingsSnackBar: {
                    open: true,
                    type: "success",
                    message: "La priorité des centres de distribution a été mis à jour.",
                    duration: 5000
                }
            })

            return
        }

        dispatch({
            type: "PLANNING_SETTINGS_SNACKBAR",
            planningSettingsSnackBar: {
                open: true,
                type: "error",
                message: "Une erreur est survenue lors de la mise à jours des priorités du dispatch.",
                duration: 5000
            }
        })
    })

}

export function loadPlanningSettingsDispatchPriority() {
    return actionWithLoader(async (dispatch) => {
        const distributionCenters = await getDistributionCentersOrderByPriority()
        dispatch({
            type: "PLANNING_SETTINGS_DISTRIBUTION_CENTERS_LIST_LOADED",
            distributionCenters: distributionCenters
        })
    })
}

export function onEnterPlanningSettingsDispatchPriority(store) {
    return onEnter({
        store,
        actionThunk: loadPlanningSettingsDispatchPriority,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function showPlanningSettingsDispatchPriority() {
    return push("/settings/sites/dispatchPriority")
}
