import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import withStyles from "@mui/styles/withStyles"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import DoneIcon from "@mui/icons-material/Done"

const styles = theme => ({
    copyModal: {
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none",
    },
    addNewRecipeIcon: {
        marginLeft: 15,
        marginTop: 25,
        cursor: "pointer"
    },
    inputNewRecipeName: {
        width: "80%"
    }
})

class CopyRecipeModal extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            newRecipeName: ""
        }
    }

    _createNewRecipe = () => {
        const { currentRecipeId, currentRecipeName, duplicateRecipe } = this.props
        const { newRecipeName } = this.state
        if (newRecipeName !== currentRecipeName && newRecipeName !== "") {
            duplicateRecipe(currentRecipeId, newRecipeName)
        }
    }

    _onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this._createNewRecipe()
        }
    }

    _onNewRecipeNameChange = (event) => {
        this.setState({ newRecipeName: event.target.value })
    }

    render() {

        const { classes, currentRecipeName } = this.props
        const { newRecipeName } = this.state

        return (
            <div className={classes.copyModal}>
                <Typography variant="h6" id="modal-title">
                    Dupliquer le produit interne
                </Typography>
                <Typography variant="subtitle1" id="simple-modal-description">
                    Vous allez dupliquer le produit interne : {currentRecipeName}<br /><br />
                    Veuillez renseigner le nom du nouveau produit interne
                </Typography>
                <TextField
                    variant="standard"
                    className={classes.inputNewRecipeName}
                    placeholder="Nom"
                    label="Nom"
                    name="newRecipeName"
                    onKeyDown={this._onKeyDown}
                    onChange={this._onNewRecipeNameChange}
                    value={newRecipeName} />
                <DoneIcon className={classes.addNewRecipeIcon} onClick={() => this._createNewRecipe()} />
            </div>
        )
    }
}

CopyRecipeModal.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(CopyRecipeModal)
