import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import { COLORS } from "../../utils"
import { SupplierContactEditSchema } from "../../utils/yupValidators"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import { getSupplierContactInitialValues } from "../../utils/suppliers"
import { red, green } from "@mui/material/colors"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        width: 424,
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14
        },
        "& .MuiInputLabel-formControl": {
            fontSize: 11,
            top: -38,
            "&.MuiFormLabel-filled": {
                top: 0,
                fontSize: 14,
                "&.site-select-label": {
                    top: -14
                }
            },
            "&.single-label": {
                top: 0,
                marginBottom: 12,
                fontSize: 16,
                transform: "none",
                position: "relative",
                height: 30,
                display: "flex",
                alignItems: "center"
            }
        }
    },
    formControl50: {
        composes: "$formControl",
        width: "50%"
    },
    formControlDates: {
        composes: "$formControl50",
        marginTop: 0,
        "& .MuiFormControl-root": {
            marginBottom: 10
        }
    },
    formControlSite: {
        composes: "$formControl50",
        "& label + .MuiInput-formControl": {
            marginTop: 0
        },
        "& .MuiFormLabel-filled": {
            top: -38
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        margin: "5px 0px 0px 0px"
    },
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: green[400]
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: green[400],
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: 15,
        marginBottom: 15
    }
}))

export const SupplierContactEdit = (props) => {
    const {
        onClose,
        isEditing,
        onSaveSupplier,
        jsonSupplier
    } = props

    const classes = useStyles()

    const submitForm = async (values) => {
        await onSaveSupplier(values)
        onClose()
    }

    return (
        <Formik
            initialValues={getSupplierContactInitialValues(jsonSupplier)}
            validationSchema={SupplierContactEditSchema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer les coordonnées fournisseur</span>
                            </DialogTitle>
                            <DialogContent dividers>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Pays</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Pays</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.country?
                                            (
                                                <div className={classes.errorField}>{errors.country}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Ville</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Ville</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.city ?
                                            (
                                                <div className={classes.errorField}>{errors.city}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Code postal</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Code postal</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="zipCode"
                                        value={values.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.zipCode ?
                                            (
                                                <div className={classes.errorField}>{errors.zipCode}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>


                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Adresse</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Adresse</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.address ?
                                            (
                                                <div className={classes.errorField}>{errors.address}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Nom du contact</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Nom du contact</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="contactName"
                                        value={values.contactName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.contactName ?
                                            (
                                                <div className={classes.errorField}>{errors.contactName}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Téléphone</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Téléphone</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.phone ?
                                            (
                                                <div className={classes.errorField}>{errors.phone}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>

                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">FAX</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>FAX</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="fax"
                                        value={values.fax}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.fax ?
                                            (
                                                <div className={classes.errorField}>{errors.fax}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Emails commerciaux</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Emails commerciaux</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="commercialEmails"
                                        value={values.commercialEmails}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.commercialEmails?
                                            (
                                                <div className={classes.errorField}>{errors.commercialEmails}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel className="single-label">Emails de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Emails de commande</InputLabel>
                                    <TextField
                                        variant="standard"
                                        name="orderEmails"
                                        value={values.orderEmails}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        errors.orderEmails?
                                            (
                                                <div className={classes.errorField}>{errors.orderEmails}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    className={classes.button1}
                                    onClick={onClose}
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SupplierContactEdit
