import React, { PureComponent } from "react"
import { withStyles } from "@mui/styles"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import { Field, reduxForm } from "redux-form"
import { roleValidator } from "../../utils/validators"
import ReduxTextField from "../../components/form/ReduxTextField"
import { COLORS } from "../../utils"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

const styles = {
    container: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll",
        zIndex: 1300,
        height: "100%"
    },
    titleContainer: {
        textAlign: "center",
        margin: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    fieldsContainer: {
        width: "100%"
    },
    formControl: {
        width: 200,
        margin: 16
    },
    buttonContainer: {
        width: "100%",
        textAlign: "center"
    },
    submitButton: {
        float: "center",
        marginTop: 16,
        marginBottom: 16
    }
}

export class RoleForm extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {}
    }

    static getDerivedStateFromProps(props, state) {
        const { change } = props

        const name = props && props.role && props.role.name ? props.role.name : ""
        if (name) {
            change("name", name)
        }
        return state
    }

    render() {
        const { classes, handleSubmit, saveRole, onClose } = this.props
        const title = "Créer un nouveau profil"

        return (
            <div className={classes.container}>
                <h2 className={classes.titleContainer}>
                    {title}
                    <IconButton aria-label="Fermer" onClick={onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </h2>
                <form onSubmit={handleSubmit(saveRole)} >
                    <div className={classes.fieldsContainer}>
                        <div>
                            <FormControl margin="normal" className={classes.formControl} required>
                                <Field name="name" label="Nom" component={ReduxTextField} />
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            className={classes.submitButton}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

RoleForm = reduxForm({
    form: "role",
    enableReinitialize: true,
    validate: roleValidator,
})(RoleForm)

const styledRoleForm = withStyles(styles)(RoleForm)

export default styledRoleForm
