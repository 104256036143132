import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { usePrevious } from "react-use"
import { makeStyles } from "@mui/styles"
import Fab from "@mui/material/Fab"
import ListItemIcon from "@mui/icons-material/List"
import SaveIcon from "@mui/icons-material/Save"
import {
    useDispatch,
    useSelector
} from "react-redux"
import moment from "moment"
import _isEqual from "lodash/isEqual"
import _cloneDeep from "lodash/cloneDeep"
import Snackbar from "@mui/material/Snackbar"
import { Box } from "@mui/material"
import { deepPurple } from "@mui/material/colors"

import PlanningPrevFilters from "../../components/Planning/PlanningFilters/PlanningPrevFilters"
import PlanningPrevActions from "../../components/Planning/PlanningActions/PlanningPrevActions"
import {
    updateBrand,
    loadPrevsPlanning,
    updatePrevisionsCards,
    savePrevisionsCards,
    updatePrevisionsCardsValidationState,
    createPlanningPrevCard,
    closePlanningSnackBar
} from "../../actions/Planning/Planning"
import {
    COLORS,
    setSearchValue
} from "../../utils"
import PlanningDays from "../../components/Planning/PlanningDays"

import PlanningToolbar from "../../components/Planning/PlanningToolbar"
import PlanningBody from "./PlanningBody/PlanningBody"
import PrevisionsHeader from "../../components/Planning/PrevisionsHeader"
import PlanningPrevEditModal from "../../components/Planning/PlanningPrevEditModal"
import PlanningPrevCreationModal from "../../components/Planning/PlanningPrevCreationModal"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { CAN_UPDATE_PREV_PLANNING } from "../../utils/accessRights"
import UpdateInfoRepartitionModal from "../../components/Planning/UpdateInfoRepartitionModal"
import { sectors } from "../../utils/planning"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import { StyledPlanningPageHeader } from "../../components/Planning/StyledPlanningPageHeader"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import { getProductTypeOptionsSelector } from "../../reducers/ProductTypes/productTypes"

const styles =  makeStyles(() => ({
    planningContainer: {
        top: 166,
        position: "relative",
        paddingBottom: 70
    },
    changeDay: {
        width: 20,
        justifyContent: "center"
    },
    topTextfield: {
        fontSize: "1rem"
    },
    planningDaysBloc: {
        overflow: "auto",
        width: "100%"
    },
    dragZone: {
        top: 166,
        position: "relative",
        width: 32,
        height: document.getElementsByClassName("sectionContainer").innerHeight,
        backgroundColor: COLORS.WHITE
    },
    bodyContainer: {
        display: "flex",
        position: "relative"
    },
    saveButton: {
        position: "fixed",
        bottom: 15,
        right: 120
    }
}))

const PlanningPrev = () => {
    const days = useSelector(state => state.planning.prevsDays)
    const brand = useSelector(state => state.planning.brand)
    const dataPlanning = useSelector(state => state.planning.prevsDataPlanning)
    const planningSnackBar = useSelector(state => state.planning.planningSnackBar)
    const prevChanged = useSelector(state => state.planning.prevChanged)
    const menuIsOpen = useSelector(state => state.app.menuIsOpen)
    const canEdit = useSelector(state => state.app.rights[CAN_UPDATE_PREV_PLANNING])
    const hubs = useSelector(state => state.planning.hubs)
    const productTypeOptions = useSelector(getProductTypeOptionsSelector)

    const dispatch = useDispatch()
    const classes = styles()
    
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    
    const newSectors = ["ALL", ...Array.from(new Set(hubs.map(el => el.sector)))]
    
    const selectedHubs = Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : []
    selectedHubs.push("ALL")
    const formatedHubs = [{
        value: "ALL",
        label: "Tous"
    }].concat(hubs.map(el => ({
        value: el.objectId,
        label: el.name
    })))
    
    const [showDays, setShowDays] = useState(days.filter((day, index) => index < 5))
    const [showDaysCards, setShowDaysCards] = useState(dataPlanning.filter((data, index) => index < 5))
    const [filteredCards, setFilteredCards] = useState(dataPlanning.filter((data, index) => index < 5))
    const [openEditModal, setOpenEditModal] = useState(false)
    const [currentCard, setCurrentCard] = useState(null)
    const [currentProductType, setCurrentProductType] = useState(null)
    const [search, setSearch] = useState(storage && storage.planningPrev ? storage.planningPrev : "")
    const [foldedCards, setFoldedCards] = useState(false)
    const [openCreationCardModal, setOpenCreationCardModal] = useState(false)
    const [openUpdateInfoRepartitionModal, setOpenUpdateInfoRepartitionModal] = useState(false)
    const [stateSectors, setStateSectors] = useState(sectors.filter(elm => newSectors.includes(elm.value)))
    const [stateSelectedSectors, setStateSelectedSectors] = useState(newSectors)
    const [stateHubs, setStateHubs] = useState(formatedHubs)
    const [stateSelectedHubs, setStateSelectedHubs] = useState(selectedHubs)
    const [selectedProductTypes, setSelectedProductTypes] = useState([])
    
    const [date, setDate] = useState(Array.isArray(showDays) && showDays.length > 0 ? moment(showDays[0].timestamp) : moment.utc().startOf("day").valueOf())
    const [actionModal, setActionModal] = useState({action: () => {}})
    
    const prevDataPlanning = usePrevious(dataPlanning)
    const prevBrand = usePrevious(brand)
    const prevHubs = usePrevious(hubs)

    useLayoutEffect(() => {
        filterCards()
    }, [search])

    useLayoutEffect(() => {
        setDate(Array.isArray(showDays) && showDays.length > 0 ? moment(showDays[0].timestamp) : moment.utc().startOf("day").valueOf())
    }, [showDays])
    
    useEffect(() => {
        if (!_isEqual(prevDataPlanning, dataPlanning) || prevBrand !== brand){
            setShowDays(days)
            setShowDaysCards(dataPlanning)
        }
    }, [dataPlanning, brand])
    
    useLayoutEffect(() => {
        filterCards()
    }, [showDaysCards])
    
    useLayoutEffect(() => {
        setSelectedProductTypes(productTypeOptions.map(productType => productType.value))
    }, [productTypeOptions])

    useEffect(() => {
        if (!_isEqual(prevHubs, hubs)){
            const selectedHubs = Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : []
            const newSectors = ["ALL", ...Array.from(new Set(hubs.map(el => el.sector)))]
    
            selectedHubs.push("ALL")
    
            const formatedHubs = [{
                value: "ALL",
                label: "Tous"
            }].concat(hubs.map(el => ({
                value: el.objectId,
                label: el.name
            })))
            
            setStateHubs(formatedHubs)
            setStateSelectedHubs(selectedHubs)
            setStateSectors(sectors.filter(elm => newSectors.includes(elm.value)))
            setStateSelectedSectors(newSectors)
        }
    }, [hubs])
    
    const filterCards = (noStorageAdd = false) => {
        let filtered = _cloneDeep(showDaysCards)
        const regex = new RegExp(search, "ig")

        if (search !== "") {
            filtered = filtered.map(sdc => {
                Object.keys(sdc.cards).forEach(brand => {
                    sdc.cards[brand] = sdc.cards[brand].filter(prev => prev.hubName.match(regex) !== null)
                })

                return sdc
            })
        }

        if (!noStorageAdd) setSearchValue(search, "planningPrev")
        setFilteredCards(filtered)
    }

    const onDateChange = useCallback(async (date) => {
        await dispatch(loadPrevsPlanning(moment.utc(date).startOf("day").valueOf()))
    }, [])

    const onBrandChange = useCallback(async (brand) => {
        await dispatch(updateBrand(brand))
    }, [brand])

    const onSearchChange = (event) => {
        setSearch(event.target.value)
    }

    const onFoldExpand = useCallback(() => {
        setFoldedCards(!foldedCards)
    }, [foldedCards])

    const onCreatePlanningPrevCards = useCallback(() => {
        setOpenCreationCardModal(!openCreationCardModal)
    }, [openCreationCardModal])
    
    const _createPlanningPrevCards = async (saveStartDate, startDate, endDate, brand) => {
        await dispatch(createPlanningPrevCard(saveStartDate, startDate, endDate, brand))
    }

    const onValidatePrevCards = useCallback(() => {
        const cards = []

        for (const current of showDaysCards) {
            if (current.cards && current.cards[brand.name]) {
                for (const currentBrandCard of current.cards[brand.name]){
                    cards.push(currentBrandCard)
                }
            }
        }

        dispatch(updatePrevisionsCardsValidationState(cards, "VALIDATED", showDays[0].timestamp))
    }, [showDaysCards, showDays])

    const isCardDragging = useCallback(() => {
    }, [])

    const updatePrevDay = useCallback((dayInfo, dayPosition) => {
        const copy = _cloneDeep(showDaysCards)
        copy.splice(dayPosition, 1, dayInfo)

        setShowDaysCards(copy)
    }, [showDaysCards])

    const save = () => {
        const cards = []

        for (const current of showDaysCards){
            if (current.cards && current.cards[brand.name]){
                for (const i in current.cards[brand.name]){
                    const currentCard = current.cards[brand.name][i]

                    cards.push(currentCard)
                }
            }
        }

        dispatch(savePrevisionsCards(cards, showDays[0].timestamp, brand, prevChanged))
    }

    const _openPrevEditModal = useCallback((currentCard, currentProductType) => {
        setOpenEditModal(true)
        setCurrentCard(currentCard)
        setCurrentProductType(currentProductType)
    }, [])

    const _closePrevEditModal = useCallback(() => {
        setOpenEditModal(false)
        setCurrentCard(null)
        setCurrentProductType(null)
    }, [])

    const _closePrevCreationModal = useCallback(() => {
        setOpenCreationCardModal(false)
    }, [])
    
    const checkThenUpdate = useCallback((currentCard, currentProductType, values) => {
        const dayDiff = moment.duration(moment.utc(currentCard.date).diff(moment.utc().startOf("day"))).asDays()
        if (dayDiff < 3) {
            setActionModal({action: () => _updateCard(currentCard, currentProductType, values)})
            setOpenUpdateInfoRepartitionModal(true)
        } else {
            _updateCard(currentCard, currentProductType, values)
        }
    }, [showDaysCards, showDays, brand])
    
    const checkInclude = (source, selected, value) => {
        let newSelected = value
        
        if (!selected.includes("ALL") && value.includes("ALL")) {
            newSelected = source.map(elem => elem.value)
        }
        else if (selected.includes("ALL") && !value.includes("ALL")) {
            newSelected = []
        }
        else if (!newSelected.includes("ALL") && newSelected.length === source.length -1) {
            newSelected.push("ALL")
        }
        else if (newSelected.includes("ALL") && newSelected.length < source.length) {
            newSelected = newSelected.filter(elem => elem !== "ALL")
        }
        
        return newSelected
    }
    
    const onSectorsChange = useCallback((eventSectors) => {
        const newSectors = checkInclude(stateSectors, stateSelectedSectors, eventSectors)
        
        const newHubs = [{
            value: "ALL",
            label: "Tous"
        }].concat(hubs.filter(el => newSectors.includes(el.sector)).map(el => ({
            value: el.objectId,
            label: el.name
        })))
        const selectedHubs = newHubs.map(elem => elem.value)
        
        setStateSelectedSectors(newSectors)
        setStateSelectedHubs(selectedHubs)
        setStateHubs(newHubs)
    }, [stateSelectedSectors, stateSelectedHubs, stateSectors])
    
    const onHubsChange = useCallback((eventHubs) => {
        const newHubs = checkInclude(stateHubs, stateSelectedHubs, eventHubs)
        
        setStateSelectedHubs(newHubs)
    }, [stateSelectedHubs])
    
    const onProductTypesChange = useCallback((eventProductTypes) => {
        const newProductTypes = checkInclude(productTypeOptions, selectedProductTypes, eventProductTypes)
        
        setSelectedProductTypes(newProductTypes)
    }, [selectedProductTypes])
    
    const _updateCard = (currentCard, currentProductType, values) => {
        const copy = _cloneDeep(showDaysCards)
        const currentDay = copy.find(el => el.date === currentCard.date)
        const oldCard = Array.isArray(currentDay.cards[brand.name]) ? currentDay.cards[brand.name].find(el => el.idHub === currentCard.idHub) : null
        
        if (oldCard) {
            const oldPrev = oldCard.prev.find(el => el.productType === currentProductType)

            if (oldPrev) {
                if (oldPrev.bonus !== values.bonus){
                    oldPrev.bonus = parseFloat(values.bonus)
                }

                if (oldPrev.min !== values.min){
                    oldPrev.min = values.min !== "" ? parseFloat(values.min) : null
                }

                if (oldPrev.max !== values.max){
                    oldPrev.max = values.max !== "" ? parseFloat(values.max) : null
                }

                if (oldPrev.reusable !== values.reusable){
                    oldPrev.reusable = values.reusable !== "" ? parseFloat(values.reusable) : null
                }

                const daysToUpdate = []
                const diffPercentage = (((parseFloat(values.prevSell) - oldPrev.prevSell) / (oldPrev.prevSell > 0 ? oldPrev.prevSell : 1)) * 100).toFixed(2)

                if (parseFloat(diffPercentage) !== 0){
                    const position = copy.indexOf(currentDay)

                    if (values.globalDays) {

                        for (const i in copy) {
                            const day = copy[i]

                            if (parseInt(i) >= position) {
                                daysToUpdate.push(day)
                            }
                        }
                    }
                    else {
                        daysToUpdate.push(currentDay)
                    }

                    dispatch(updatePrevisionsCards(showDaysCards, daysToUpdate, brand, diffPercentage, values, currentCard, currentProductType))
                }
            }
        }
        
        setOpenEditModal(false)
        setCurrentCard(null)
        setCurrentProductType(null)
        setShowDaysCards(copy)
    }
    
    return (
        <Box display="flex" flexDirection="column" >
            <Box position="relative" width="100%">
                <StyledPlanningPageHeader sx={{ height: 64 }} open={menuIsOpen}>
                    <GenericTopBarHeader
                        title={<PageHeaderTitle title="Prévisions" icon={<ListItemIcon />} color={deepPurple[500]} />}
                    />
                </StyledPlanningPageHeader>
            </Box>
            <PlanningToolbar
                menuIsOpen={menuIsOpen}
                isFlex3={true}
            >
                <PlanningPrevFilters
                    onDateChange={onDateChange}
                    date={date}
                    brand={brand}
                    onBrandChange={onBrandChange}
                    search={search}
                    onSearchChange={onSearchChange}
                    sectors={stateSectors}
                    hubs={stateHubs}
                    productTypeOptions={productTypeOptions}
                    selectedSectors={stateSelectedSectors}
                    selectedHubs={stateSelectedHubs}
                    selectedProductTypes={selectedProductTypes}
                    onSectorsChange={onSectorsChange}
                    onHubsChange={onHubsChange}
                    onProductTypesChange={onProductTypesChange}
                />
                <PlanningPrevActions
                    folded={foldedCards}
                    onFoldExpand={onFoldExpand}
                    onCreatePlanningPrevCards={onCreatePlanningPrevCards}
                    onValidatePrevCards={onValidatePrevCards}
                />
            </PlanningToolbar>
            <div className={classes.planningDaysBloc}>
                <PlanningDays
                    days={showDays}
                    planningType="PREVISIONS"
                />
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.dragZone}>
                </div>
                <div className={classes.planningContainer}>
                    <PrevisionsHeader
                        data={showDaysCards}
                        brand={brand}
                        selectedProductTypes={selectedProductTypes}
                        productTypeOptions={productTypeOptions}
                    />
                    <PlanningBody
                        data={filteredCards}
                        isCardDragging={isCardDragging}
                        brand={brand}
                        planningType="PREVISIONS"
                        openPrevEditModal={_openPrevEditModal}
                        updatePrevDay={updatePrevDay}
                        foldedCards={foldedCards}
                        canEdit={canEdit}
                        selectedProductTypes={selectedProductTypes}
                        selectedHubs={stateSelectedHubs}
                        productTypeOptions={productTypeOptions}
                    />
                </div>
                <div className={classes.dragZone}>
                </div>
            </div>
            {
                openEditModal &&
                <PlanningPrevEditModal
                    closeModal={_closePrevEditModal}
                    currentCard={currentCard}
                    currentProductType={currentProductType}
                    update={checkThenUpdate}
                    brand={brand}
                />
            }
            {
                openCreationCardModal &&
                <PlanningPrevCreationModal
                    closeModal={_closePrevCreationModal}
                    brand={brand}
                    createPlanningPrevCard={_createPlanningPrevCards}
                    saveStartDate={showDays[0].timestamp}
                />
            }
            {
                canEdit
                    ?
                        <Fab
                            variant="extended"
                            className={classes.saveButton}
                            size="medium"
                            color="primary"
                            onClick={save}
                        >
                            <SaveIcon />
                            Enregistrer
                        </Fab>
                    :
                        null
            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={planningSnackBar.open}
                autoHideDuration={planningSnackBar.duration}
                onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
            >
                <MySnackbarContentWrapper
                    variant={planningSnackBar.type}
                    message={planningSnackBar.message}
                    onClose={() => dispatch(closePlanningSnackBar(planningSnackBar.type))}
                />
            </Snackbar>
            <UpdateInfoRepartitionModal
                open={openUpdateInfoRepartitionModal}
                onClose={() => {setOpenUpdateInfoRepartitionModal(false)}}
                action={actionModal.action}
            />
            <HelpButton link={helpLinks.planning}/>
        </Box>
    )
}

export default PlanningPrev
