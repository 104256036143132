import React from "react"
import Drawer from "@mui/material/Drawer"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
    drawer: {
        width: "33%",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "33%",
        paddingTop: 64
    },
    drawerContainer: {
        overflow: "auto",
    }
})

const RightSideWrapper = (props) => {
	const { children } = props
    const classes = useStyles()

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
        >
            {children}
        </Drawer>
    )
}

export default RightSideWrapper