import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import IngredientResume from "../../components/Ingredients/IngredientResume"
import IngredientAllergens from "../../components/Ingredients/IngredientAllergens"
import IngredientSourcing from "../../components/Ingredients/IngredientSourcing"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "40px 0",
    background: COLORS.GREY_RECIPE_BACKGROUND,
    justifyContent: "center",
    height: "fit-content"
  },
  firstColumnContainer: {
    width: "75%",
    margin: "0 16px",
    minWidth: 500,
    maxWidth: 1000,
  },
  secondColumnContainer: {
    width: "25%",
    margin: "0 16px",
    minWidth: 275,
    maxWidth: 300
  },
  blocContainer: {
    marginTop: 20
  }
}))

const IngredientDetails = ({ ingredient, onSave, ingredientType }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.firstColumnContainer}>
        <div>
          <IngredientResume ingredient={ingredient} onSave={onSave} ingredientType={ingredientType} />
        </div>
        <div className={classes.blocContainer}>
          <IngredientAllergens ingredient={ingredient} onSave={onSave} />
        </div>
        <div className={classes.blocContainer}>
          <IngredientSourcing ingredient={ingredient} onSave={onSave} />
        </div>
      </div>
    </div>
  )
}

export default IngredientDetails