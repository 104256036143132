import React from "react"
import { Formik, Form } from "formik"
import { FormControl } from "@mui/material"
import { TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import * as Yup from "yup"

const useStyles = makeStyles(() => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    flexWrap: "wrap"
  },
  field: {
    width: "100%",
    marginBottom: 10
  }
}))

export const distributionCenterAddressFormId = "distributionCenterAddressFormId"

const DistributionCenterAddressForm = ({ distributionCenter, handleSubmit }) => {

  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        address: {
          address: distributionCenter.address.address,
          complement: distributionCenter.address.complement,
          city: distributionCenter.address.city,
          zipCode: distributionCenter.address.zipCode,
          country: distributionCenter.address.country,
          latitude: distributionCenter.address.latitude,
          longitude: distributionCenter.address.longitude
        }
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        address: Yup.object().shape({
          address: Yup.string(),
          zipCode: Yup.string(),
          city: Yup.string(),
          latitude: Yup.number(),
          longitude: Yup.number()
        })
      })}
    >
      {({ values, handleBlur, handleChange }) => (
        <Form
          id={distributionCenterAddressFormId}
        >
          <div className={classes.formContainer}>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.address"
                  label="Adresse"
                  value={values.address.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.complement"
                  label="Complément d'adresse"
                  value={values.address.complement}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.zipCode"
                  label="Code postal"
                  value={values.address.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.city"
                  label="Ville"
                  value={values.address.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.latitude"
                  label="Latitude"
                  value={values.address.latitude}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className={classes.field}
                  variant="standard"
                  name="address.longitude"
                  label="Longitude"
                  value={values.address.longitude}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                />
              </FormControl>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DistributionCenterAddressForm