import Parse from "parse"
import {
    parseHeatingInstructions,
} from "../../utils/recipes"
import { parseLimitRequest } from "../../utils"
import { recipeSectionsFormInitialValues } from "../../actions/Utils/utils"
import { escapeRegExp } from "lodash"
import {getRecipesByProductionStepExecution} from "../productionStepExecutions/parseProductionStepExecutionManager"

export const Recipe = Parse.Object.extend("Recipe")
const Packaging = Parse.Object.extend("Packaging")
export const SupplierItems = Parse.Object.extend("SupplierItems")

export async function getRecipes({ includes = [], ids, selects = [], sortBy = "name", sortDirection = "asc", toJSON = false } = {}) {
    try {

        const query = new Parse.Query(Recipe)
            .notEqualTo("deleted", true)

        if (ids && ids.length) {
            query.containedIn("objectId", ids)
        }

        if (includes?.length) {
            query.include(includes)
        }

        if (selects?.length) {
            query.select(selects)
        }

        if (sortDirection === "desc") {
            query.descending(sortBy)
        } else {
            query.ascending(sortBy)
        }

        const recipes = await query.limit(3000).find()

        return toJSON ? (recipes ? recipes.map(el => el.toJSON()) : []) : recipes
    } catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getRecipes error : ", e)
        return []
    }
}

export async function getFilteredRecipes(filters) {
    try {
        // filtering is done on server side now
        const results = await Parse.Cloud.run("getFilteredRecipes", { ...filters }) || []
        // the result format is
        /*
        [{
            paginatedResults: [...],
            totalCount: [{
                total: <number>
            }]
        }]
        */
        const total = results[0]?.totalCount[0]?.total
        const recipesObjs = results[0]?.paginatedResults || []
        const recipesId = recipesObjs.map(recipeObj => recipeObj._id)

        // we have to make a second request because
        // 1. we use the parse .get() method in all components related to the recipes
        // 2. we neet to include internalTag which is a pointer
        const recipes = await new Parse.Query("Recipe")
            .containedIn("objectId", recipesId)
            .include("internalTag")
            .find()
        // reminder: find without limit is limited to 100 results
        // that suits us because we set filters.rowsPerPage to a value in the range of 10 to 100

        return {
            recipes,
            total
        }
    }
    catch (e) {
        console.error("parseManager.products.recipe.parseRecipeManager.getFilteredRecipes error : ", e)
        return Promise.reject(e)
    }
}

export async function getRecipeRange(field, returnValue = false) {
    try {
        const recipeMin = await new Parse.Query(Recipe)
            .select(field)
            .notEqualTo(field, null)
            .ascending(field)
            .first()

        const recipeMax = await new Parse.Query(Recipe)
            .select(field)
            .notEqualTo(field, null)
            .descending(field)
            .first()

        return (returnValue) ? [recipeMin.get(returnValue), recipeMax.get(returnValue)] : [recipeMin.get(field), recipeMax.get(field)]
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getPriceRange error : ", e)
        return Promise.reject(e)
    }
}

export async function getRecipeById(recipeId, includes = [], toJSON = true) {
    try {
        const recipe = await new Parse.Query(Recipe)
            .equalTo("objectId", recipeId)
            .include(includes)
            .first()

        return recipe ? toJSON ? recipe.toJSON() : recipe : null
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getRecipe error : ", e)
        return Promise.reject(e)
    }
}

export async function getRecipeForPlanning(recipeId, toJSON = true) {
    try {
        const recipe = await new Parse.Query(Recipe)
            .select(["preparation", "netWeight", "specialInstruction", "heatingInstructions", "defaultValues.heatingInstructions", "brands", "heatingInstructions", "nutritionInformation", "name", "ingredients.supplierItem.commercialName.allergens.name", "ingredients.percentage", "ingredients.supplierItem.commercialName.name", "internalTag.type"])
            .equalTo("objectId", recipeId)
            .first()

        return recipe ? toJSON ? recipe.toJSON() : recipe : null
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getRecipeForPlanning error : ", e)
        return Promise.reject(e)
    }
}

export async function checkIfEanExists(ean, objectId) {
    try {
        const eanCheck = await new Parse.Query(Recipe)
            .select("ean")
            .equalTo("ean", ean)
            .notEqualTo("objectId", objectId)
            .first()

        return !!eanCheck
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function checkNoCommercialNameIngredients(ingredients) {
    try {
        return (await new Parse.Query(SupplierItems)
            .notEqualTo("deleted", true)
            .containedIn("objectId", ingredients.map(ingredient => ingredient.supplierItem.objectId))
            .equalTo("commercialName", null)
            .find()) || []
    }
    catch (e) {
        return Promise.reject(e)
    }
}

export async function updateParseRecipeDetails(values, recipe) {
    if (!recipe) {
        recipe = new Recipe()
        recipe.set("exportedTo", [])
    }

    const recipeDefaultValues = recipe.get("defaultValues") || {}

    if (values.defaultValues) {
        const defaultValues = values.defaultValues

        recipeDefaultValues.description = defaultValues.description || null
        recipeDefaultValues.commercialName = defaultValues.commercialName || null
        recipeDefaultValues.instructions = defaultValues.instructions || null
        recipeDefaultValues.price = defaultValues.price ? parseFloat(defaultValues.price) : null
        recipeDefaultValues.tva = defaultValues.tva ? parseFloat(defaultValues.tva) : null
        recipeDefaultValues.dlc = defaultValues.dlc ? parseFloat(defaultValues.dlc) : null
        recipeDefaultValues.appImage = defaultValues.appImage ? defaultValues.appImage : null

        recipe.set("defaultValues", recipeDefaultValues)
        recipe.set("commercialName", defaultValues.commercialName || "") //TODO need to be removed later
    }

    if (Array.isArray(values.packaging) && values.packaging.length) {
        const packaging = await Promise.all(values.packaging.map(async (p) => {
            let packaging = null

            if (p.value) {
                packaging = await new Parse.Query(Packaging)
                    .equalTo("objectId", p.value)
                    .first()
            }

            return { brand: p.brand, value: packaging }
        }))

        recipe.set("packaging", packaging || [])
    }

    if (Array.isArray(values.reusablePackaging) && values.reusablePackaging.length) {
        const reusablePackaging = await Promise.all(values.reusablePackaging.map(async (p) => {
            let reusablePackaging = null

            if (p.value) {
                reusablePackaging = await new Parse.Query(Packaging)
                    .equalTo("objectId", p.value)
                    .first()
            }

            return { brand: p.brand, value: reusablePackaging }
        }))

        recipe.set("reusablePackaging", reusablePackaging || [])
    }

    if (Array.isArray(values.subPackaging) && values.subPackaging.length) {
        const subPackaging = await Promise.all(values.subPackaging.map(async (sp) => {
            let subPackagings = []

            if (sp.value && Array.isArray(sp.value) && sp.value.length) {
                subPackagings = (await new Parse.Query(Packaging)
                    .containedIn("objectId", sp.value.map(obj => obj.value))
                    .limit(parseLimitRequest)
                    .find()) || []
            }

            return { brand: sp.brand, value: subPackagings }
        }))

        recipe.set("subPackaging", subPackaging)
    }

    if (Array.isArray(values.reusableSubPackaging) && values.reusableSubPackaging.length) {
        const reusableSubPackaging = await Promise.all(values.reusableSubPackaging.map(async (sp) => {
            let reusableSubPackaging = []

            if (sp.value && Array.isArray(sp.value) && sp.value.length) {
                reusableSubPackaging = (await new Parse.Query(Packaging)
                    .containedIn("objectId", sp.value.map(obj => obj.value))
                    .limit(parseLimitRequest)
                    .find()) || []
            }

            return { brand: sp.brand, value: reusableSubPackaging }
        }))

        recipe.set("reusableSubPackaging", reusableSubPackaging)
    }

    if ("1" === values.preparation) {
        recipe.set("heatingInstructions", [])
    }
    else {
        const finalHeatingInstructions = []

        for (const heatingInstruction of values.heatingInstructions) {
            finalHeatingInstructions.push({
                brand: heatingInstruction.brand,
                value: parseHeatingInstructions(heatingInstruction.value)
            })
        }

        recipe.set("heatingInstructions", finalHeatingInstructions)
    }

    if (values.price && values.price.length) {
        const finalPrice = []

        for (const item of values.price) {
            if (item.value !== null && item.value !== "" && parseFloat(item.value) !== values.defaultValues.price) {
                finalPrice.push({
                    brand: item.brand,
                    value: parseFloat(item.value)
                })
            }
        }

        recipe.set("price", finalPrice)
    }

    if (values.tva && values.tva.length) {
        const finalTva = []

        for (const item of values.tva) {
            if (item.value !== null && parseFloat(item.value) !== values.defaultValues.tva) {
                finalTva.push({
                    brand: item.brand,
                    value: parseFloat(item.value)
                })
            }
        }

        recipe.set("tva", finalTva)
    }

    if (values.dlc && values.dlc.length) {
        const finalDlc = []

        for (const item of values.dlc) {
            if (item.value && parseFloat(item.value) !== values.defaultValues.dlc) {
                finalDlc.push({
                    brand: item.brand,
                    value: parseFloat(item.value)
                })
            }
        }

        recipe.set("dlc", finalDlc)
    }

    if (values.description && values.description.length) {
        const finalDescription = []

        for (const item of values.description) {
            if (item.value !== null && item.value !== "" && item.value !== values.defaultValues.description) {
                finalDescription.push({
                    brand: item.brand,
                    value: item.value
                })
            }
        }

        recipe.set("description", finalDescription)
    }

    if (values.instructions && values.instructions.length) {
        const finalInstructions = []

        for (const item of values.instructions) {
            if (item.value !== null && item.value !== values.defaultValues.instructions) {
                finalInstructions.push({
                    brand: item.brand,
                    value: item.value
                })
            }
        }

        recipe.set("instructions", finalInstructions)
    }

    if (values.appImage) { // appìmage can be an empty array, it is initialized as such in db ( half of the recipes fall under this category)
        const finalAppImage = []

        for (const item of values.appImage) {
            if (item.value !== null && item.value !== values.defaultValues.appImage) {
                finalAppImage.push({
                    brand: item.brand,
                    value: item.value
                })
            }
        }

        recipe.set("appImage", finalAppImage)
    }

    if (values.kitchenImage && values.kitchenImage.length) {
        const finalIKitchenImage = []

        for (const item of values.kitchenImage) {
            if (item.value !== null && item.value !== values.defaultValues.kitchenImage) {
                finalIKitchenImage.push({
                    brand: item.brand,
                    value: item.value
                })
            }
        }

        recipe.set("kitchenImage", finalIKitchenImage)
    }


    if (values.commercialNames && values.commercialNames.length) {
        const finalCommercialNames = []

        for (const item of values.commercialNames) {
            if (item.value !== null && item.value !== "" && item.value !== values.defaultValues.commercialNames) {
                finalCommercialNames.push({
                    brand: item.brand,
                    value: item.value
                })
            }
        }

        recipe.set("commercialNames", finalCommercialNames)
    }

    recipe.set("portionPerPlate", values.portionPerPlate ? parseFloat(values.portionPerPlate) : null)
    recipe.set("gesters", values.gesters ? parseFloat(values.gesters) : null)
    recipe.set("difficulty", values.difficulty ? parseInt(values.difficulty) : null)
    recipe.set("preparation", values.preparation)
    recipe.set("specialInstruction", values.specialInstruction)

    await recipe.save()

    return recipe
}

export async function getRecipesBySupplierItem(supplierItemId, toJSON = true) {
    try {
        const recipes = await new Parse.Query(Recipe)
            .exists("ingredients")
            .include("ingredients")
            .include(["chef.image"])
            .include(["sections.steps.ingredients.cookingMode"])
            .include(["sections.steps.ingredients.supplierItem.cookingModes.cookingMode"])
            .include(["sections.steps.ingredients.supplierItem.commercialName"])
            .include(["sections.steps.ingredients.supplierItem.commercialName.allergens"])

            // reusable production steps
            .include(["sections.productionSteps.step.productionSteps"])
            .include(["sections.productionSteps.step.productionSteps.stepComponents.cookingMode"])
            .include(["sections.productionSteps.step.productionSteps.stepComponents.supplierItem.cookingModes.cookingMode"])
            .include(["sections.productionSteps.step.stepComponents.supplierItem.commercialName"])
            .include(["sections.productionSteps.step.stepComponents.supplierItem.commercialName.allergens"])

            .include(["internalTag"])
            .include(["ingredients.supplierItem.commercialName.allergens"])
            .containedIn("ingredients.supplierItem.objectId", [supplierItemId])
            .limit(parseLimitRequest)
            .find()

        return toJSON ? recipes.map((recipe) => recipe.toJSON()) : recipes
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.getRecipesBySupplierItem error : ", e)
        return Promise.reject(e)
    }
}

export async function updateAllRecipesFoodcost() {
    try {
        return await new Parse.Query(Recipe)
            .include(["sections.steps.ingredients.supplierItem.cookingModes.cookingMode"])
            .each(async recipe => {
                const recipeValues = recipeSectionsFormInitialValues(recipe)
                recipe.set("foodcost", recipeValues.cost)
                recipe.set("grossWeight", recipeValues.grossWeight)

                await recipe.save()
            })
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.updateAllRecipesFoodcost error : ", e)
        return Promise.reject(e)
    }
}

export const searchRecipeByName = async (search, recipeIds = []) => {
    try {
        let query = new Parse.Query(Recipe)

        const regex = new RegExp(escapeRegExp(search), "ig")

        query = new Parse.Query.or(
            new Parse.Query(Recipe).matches("name", regex),
            new Parse.Query(Recipe).matches("uniqueCode", regex),
        )

        query
            .notEqualTo("deleted", true)

        if (recipeIds.length > 0) {
            query.containedIn("objectId", recipeIds)
        }

        const recipes = await query.find() || []

        return recipes.map(recipe => recipe.toJSON())
    }
    catch (e) {
        console.error("parseManager.recipe.parseRecipeManager.searchRecipeByName : ", e)
        return Promise.reject(e)
    }
}

export const searchRecipes = async (search, isSearchForPSEGeneration = false, productionDate = null) => {
    let recipeIds = []
    let recipes = []
    if (isSearchForPSEGeneration === true) {
        recipeIds = await getRecipesByProductionStepExecution(productionDate)
    }

    if (recipeIds.length > 0 || isSearchForPSEGeneration === false) {
        recipes = await searchRecipeByName(search, recipeIds)
    }

    return recipes
}