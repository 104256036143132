import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import { ACTIVE_KFC_BRANDS, COLORS } from "../../utils"
import {
    getRecipeDetailsSchema,
    getRecipeResumeSchema
} from "../../utils/yupValidators"
import StatusSelect from "./StatusSelect"
import TypeSelect from "./TypeSelect"
import SpicySelect from "./SpicySelect"
import InternalTagMultiSelect from "./InternalTagMultiSelect"
import { productTagsTypes } from "../../utils/productTagsTypes"
import { ChefSelect } from "../ChefSelect"
import BrandsSelect from "./BrandsSelect"
import { checkIfEanExists, checkNoCommercialNameIngredients } from "../../parseManager/recipe/parseRecipeManager"
import { useAsync } from "react-use"
import { recipeDetailsFormInitialValues } from "../../actions/Utils/utils"
import InvalidStatusModal from "./InvalidStatusModal"
import Switch from "../form/Switch"

const useStyles = makeStyles({
    rootDialogActions: {
        justifyContent: "space-between"
    },
    inputContainer: {
        marginTop: 16
    },
    eanInputContainer: {
        composes: "$inputContainer",
        width: 160
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: "0px 4px 4px 4px"
    },
    input: {
        display: "flex",
        padding: 0,
        height: "100%"
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
    },
    switchFormControlLabel: {
        marginLeft: "unset",
    },
    statusFormControl: {
        width: 160,
        marginLeft: "unset",
        marginTop: 16
    },
    typeFormControl: {
        width: 160,
        marginLeft: "unset",
        marginTop: 16,
    },
    formControl: {
        marginLeft: "unset",
        width: "100%",
        marginTop: 16
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 400,
        margin: "5px 0px 0px 0px",
        fontSize: "1.3em"
    },
    selectsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    errorField: {
        color: COLORS.RED
    },
    tvaErrorField: {
        color: COLORS.RED,
        marginLeft: 18,
        marginTop: 5
    },
    logo: {
        width: 16,
        marginRight: 10
    }
})

export const ProductResumeEdit = (props) => {
    const {
        jsonRecipe,
        parseRecipe,
        onClose,
        isEditing,
        onSaveProductResume,
        productsTags,
        chefs
    } = props

    const classes = useStyles()
    const noCommercialNameIngredients = useAsync(async () => {
        const ingredients = await checkNoCommercialNameIngredients(jsonRecipe.ingredients)

        return ingredients
    }, [jsonRecipe.ingredients])

    const [schema, setSchema] = useState(getRecipeResumeSchema(jsonRecipe.status, noCommercialNameIngredients))
    const [isEanValid, setIsEanValid] = useState(true)
    const [tvaCheck, setTvaCheck] = useState(true)
    const [openInvalidStatusModal, setOpenInvalidStatusModal] =  useState(false)

    const detailsErrors = useAsync(async () => {
        let errors = false
        const detailsSchema = getRecipeDetailsSchema("6", parseRecipe.get("preparation"))
        const detailsValue = recipeDetailsFormInitialValues(parseRecipe)
        await detailsSchema.validate(detailsValue, { abortEarly: false }).catch(e => {
            errors = e
        })

        return errors
    }, [parseRecipe])
    const foodcostError = (
        !jsonRecipe.foodcost ||
        jsonRecipe.foodcost === 0 ||
        !(jsonRecipe.sections && jsonRecipe.sections.length > 0)
    ) ? true : false

    const submitForm = async (values) => {
        if (values.status === 6 && !!jsonRecipe.defaultValues.tva) {
            setTvaCheck(false)
            return
        } else {
            setTvaCheck(true)
        }

        if (await validateEan(values)) {
            onSaveProductResume(values)
            onClose()
        }
    }

    const recipeInternalTag = Array.isArray(jsonRecipe.internalTag)
        ? jsonRecipe.internalTag
        : []

    const productTagsTypesEnable = productTagsTypes.filter(productTagType => productTagType.enableValue)

    const initialValues = {
        brands: jsonRecipe.brands,
        spicy: jsonRecipe.spicy,
        name: jsonRecipe.name,
        ean: jsonRecipe.ean,
        status: jsonRecipe.status,
        type: jsonRecipe.type,
        isActive: jsonRecipe.isActive,
        chef: jsonRecipe.chef ? jsonRecipe.chef.objectId : "",
        maxProd: jsonRecipe.maxProd,
        commercialName: jsonRecipe.commercialName,
        legalName: jsonRecipe.legalName,
    }
    
    const _onStatusChange = (values, status, setFieldValue) => {
        if (status === "6" && (detailsErrors.value || foodcostError)) {
            setOpenInvalidStatusModal(true)
            return false
        }

        setSchema(getRecipeResumeSchema(status, noCommercialNameIngredients))
        setFieldValue("status", status)
    }

    productTagsTypesEnable.forEach(productTagType => {
        initialValues[`internalTag${productTagType.value}`] = recipeInternalTag.filter(elem => elem.type === productTagType.value)
            .map(elem => elem.objectId)
    })

    const validateEan = async (values) => {
        const ean = values.ean
        if (ean && ean.startsWith("370145")) {
            const eanExists = await checkIfEanExists(ean, jsonRecipe.objectId)
            if (eanExists) {
                setIsEanValid(false)
                return false
            } 
            else {
                setIsEanValid(true)
                return true
            } 
        }
        else {
            setIsEanValid(true)
            return true
        }
    }

    return <>
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={submitForm}
        >
            {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  handleSubmit
              }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer le résumé du produit</span>
                                <Switch
                                    name="isActive"
                                    checked={values.isActive}
                                    onChange={handleChange}
                                    color="secondary"
                                />
                            </DialogTitle>
                            <DialogContent dividers>
                                <TextField
                                    variant="standard"
                                    className={classes.inputContainer}
                                    label="Dénomination légale"
                                    name="legalName"
                                    value={values.legalName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                                <TextField
                                    variant="standard"
                                    className={classes.inputContainer}
                                    id="name"
                                    label="Nom technique"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                                <BrandsSelect
                                    id="brands"
                                    name="brands"
                                    value={values.brands}
                                    onChange={handleChange}
                                    productBrands={ACTIVE_KFC_BRANDS}
                                    classes={classes}
                                />
                                <div className={classes.selectsContainer}>
                                    <StatusSelect
                                        classes={classes}
                                        id="status"
                                        name="status"
                                        value={values.status}
                                        onChange={(e) => {
                                            _onStatusChange(values, e.target.value, setFieldValue)
                                        }}
                                    />
                                    {
                                        errors.status
                                            ?
                                            (
                                                <div className={classes.errorField}>{errors.status}</div>
                                            )
                                            : null
                                    }
                                    <TypeSelect
                                        classes={classes}
                                        name="type"
                                        id="type"
                                        value={values.type}
                                        onChange={handleChange}
                                    />
                                    <SpicySelect
                                        classes={classes}
                                        name="spicy"
                                        id="spicy"
                                        value={values.spicy}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        variant="standard"
                                        className={classes.eanInputContainer}
                                        id="maxProd"
                                        label="Production maximale"
                                        name="maxProd"
                                        type="number"
                                        value={values.maxProd}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        touched.maxProd && errors.maxProd
                                            ?
                                            (
                                                <div className={classes.errorField}>{errors.maxProd}</div>
                                            )
                                            : null
                                    }
                                </div>
                                <div>
                                    <TextField
                                        variant="standard"
                                        className={classes.eanInputContainer}
                                        id="ean"
                                        label="EAN *"
                                        name="ean"
                                        value={values.ean}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {
                                        touched.ean && errors.ean
                                            ?
                                            (
                                                <div className={classes.errorField}>{errors.ean}</div>
                                            )
                                            : null
                                    }
                                    {
                                        !isEanValid
                                            ?
                                            (
                                                <div className={classes.errorField}>Cet EAN existe déjà</div>
                                            )
                                            : null
                                    }
                                </div>
                                <div>
                                    <ChefSelect
                                        id="chef"
                                        label="chef"
                                        name="chef"
                                        value={values.chef}
                                        chefs={chefs}
                                        onChange={handleChange}
                                        classes={classes}
                                    />
                                </div>
                                {
                                    productTagsTypesEnable.map((productTagsType) =>
                                        <InternalTagMultiSelect
                                            label={`Tags ${productTagsType.label}`}
                                            name={`internalTag${productTagsType.value}`}
                                            id={`${productTagsType.value}`}
                                            key={productTagsType.value}
                                            productsTags={productsTags.filter(elem => elem.get("type") === productTagsType.value)}
                                            classes={classes}
                                            value={values[`internalTag${productTagsType.value}`]}
                                            onChange={handleChange}
                                        />
                                    )
                                }
                            </DialogContent>
                            {
                                !tvaCheck
                                    ? <div className={classes.tvaErrorField}>Veuillez mettre a jour la TVA dans l'onglet Détails</div>
                                    : null
                            }
                            {
                                errors.commercialName
                                    ? <div className={classes.tvaErrorField}>Veuillez verifier le Nom Commercial dans l'onglet Détails</div>
                                    : null
                            }
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    onClick={onClose}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Sauvegarder
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
        <InvalidStatusModal
            open={openInvalidStatusModal}
            errors={detailsErrors.value}
            foodcostError={foodcostError}
            onClose={() => setOpenInvalidStatusModal(false)}
        />
    </>
}

export default ProductResumeEdit
