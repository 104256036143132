import React from "react"
import { Formik, FieldArray, Form } from "formik"
import * as Yup from "yup"

import PackagingExecutionSectionsAndWasteRecap from "../packagingExecutionDone/PackagingExecutionSectionsAndWasteRecap"

export const packagingExecutionWasteFormId = "packagingExecutionWasteForm"

const PackagingExecutionSectionsAndWasteForm = ({ sections, handleSubmit }) => {

	return (
		<Formik
			initialValues={{ sections: sections }}
			onSubmit={handleSubmit}
			validationSchema={Yup.object().shape({
				sections: Yup.array().of(
					Yup.object().shape({
						realWaste: Yup.number().required("Champ obligatoire")
					}))
			})
			}
		>
			{({ values, handleChange, errors, touched }) => (
				<Form
					id={packagingExecutionWasteFormId}
				>
					<FieldArray name="sections">
						{() => (
							<PackagingExecutionSectionsAndWasteRecap
								sections={values.sections}
								isEdition
								handleChange={handleChange}
								errors={errors}
								touched={touched}
							/>
						)}
					</FieldArray>
				</Form>
			)}
		</Formik>
	)

}

export default PackagingExecutionSectionsAndWasteForm