import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import ImageAdd from "../Image/ImageAdd"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { confirm } from "../react-await-dialog/react-await-dialog"
import CloudinaryImage from "../Image/CloudinaryImage"

const useStyles = makeStyles(() => ({
    header: {
        width: "100%",
        backgroundColor: COLORS.BLUEGREY_700,
        height: 163,
        display: "flex",
        color: COLORS.WHITE,
        marginTop: 64
    },
    siteImage: {
        width: 216,
        height: "100%"
    },
    infosContainer: {
        width: "calc(100% - 245px)",
        paddingLeft: 48,
        paddingTop: 16,
        paddingRight: 16
    },
    siteNameContainer: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: 500,
        fontSize: 25,
        textTransform: "uppercase"
    },
    subInfosContainer: {
        display: "flex",
        marginTop: 16
    },
    singleSubInfoContainer: {
        display: "flex",
        flexDirection: "column"
    },
    notFirstSingleSubInfoContainer: {
        composes: "$singleSubInfoContainer",
        marginLeft: 32
    },
    subInfoLabel: {
        fontSize: 16,
        color: COLORS.SITE_SUBTITLE
    },
    subInfoValue: {
        fontSize: 16,
        marginTop: 8
    },
    brandsValueContainer: {
        display: "flex",
    },
    logo: {
        width: 16,
        height: 16
    },
    singleBrandContainer: {
        marginRight: 24,
        marginTop: 8
    },
    brandName: {
        fontSize: 16,
        marginLeft: 8
    },
    imageContainer: {
        position: "relative",
        width: 216,
        height: "100%",
        "&:hover $imageOverlay": {
            opacity: 1
        },
    },
    imageOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        transition: ".5s ease"
    },
    deleteImageIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 5,
        backgroundColor: COLORS.BLACK,
        color: "white",
        "& svg": {
            cursor: "pointer",
            fontSize: 15
        }
    }
}))

export const Header = (props) => {
    const {
        image,
        jsonSite,
        saveImage,
        canEdit
    } = props

    const classes = useStyles()
    const [imageEditing, setImageEditing] = useState(false)
    const [publicId, setPublicId] = useState(null)

    useEffect(() => {
        setPublicId(image && image.publicId)
    }, [image])

    const name = jsonSite ?
        jsonSite.name :
        ""

    const address = jsonSite && jsonSite.deliveryAddress ?
        `${jsonSite.deliveryAddress.line1}${jsonSite.deliveryAddress.line2 ? ", " + jsonSite.deliveryAddress.line2 : ""}`:
        ""

    const handleImageChange = (publicId) => {
            setPublicId(publicId)
            saveImage({
                publicId
            })
    }

    const onDelete = async () => {
        if (await confirm({
            title: "Confirm",
            message: "Voulez-vous supprimer l'image du site ?",
            confirmText: "Oui",
            cancelText: "Non"
        })) {
            setPublicId(null)
            saveImage(null)
        }
    }

    return (
        <div className={classes.header}>
            {
                jsonSite
                    ?
                    <>
                        {
                            publicId ?
                                <div
                                    className={classes.imageContainer}
                                    onMouseEnter={() => setImageEditing(true)}
                                    onMouseLeave={() => setImageEditing(false)}
                                >
                                    <CloudinaryImage
                                        containerClassName={classes.siteImage}
                                        imageId={publicId}
                                        width={216}
                                        height={163}
                                        customQuality="auto"
                                    />
                                    {
                                        imageEditing && canEdit &&
                                        <div className={classes.imageOverlay}>
                                            {
                                                onDelete &&
                                                <div
                                                    className={classes.deleteImageIcon}
                                                >
                                                    <DeleteIcon onClick={onDelete} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div> :
                                canEdit ?
                                <ImageAdd
                                    uploadOptions={{ tags: ["", ""] }}
                                    onImageUploaded={handleImageChange}
                                    width={216}
                                    height="100%"
                                /> :
                                <img
                                    src="/img/noImageAvailabled.png"
                                    alt="Indisponible"
                                    className={classes.siteImage}
                                />
                        }
                        <div className={classes.infosContainer}>
                            <div className={classes.siteNameContainer}>
                                {name}
                            </div>
                            <div className={classes.subInfosContainer}>
                                <div className={classes.singleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Adresse de livraison
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {address}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Ville
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {jsonSite.deliveryAddress.city}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Code Postale
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {jsonSite.deliveryAddress.zipCode}
                                    </span>
                                </div>
                                <div className={classes.notFirstSingleSubInfoContainer}>
                                    <span className={classes.subInfoLabel}>
                                        Nombre de zone de stockage
                                    </span>
                                    <span className={classes.subInfoValue}>
                                        {jsonSite.stockZones.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
        </div>
    )
}

export default Header
