import React  from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import { COLORS } from "../../utils"

const tabs = [
    {key: "seasonalIngredientsRate", label: "Légumes et fruits de saison"},
    {key: "certifiedIngredientsRate", label: "Ingrédients labellisés"},
    {key: "frenchIngredientsRate", label: "Ingrédients français"},
    {key: "organicIngredientsRate", label: "Ingrédients bio"},
    {key: "localIngredientsRate", label: "Circuits courts"}
]

const NutritionalSourcing = (props) => {
    const {classes, nutritionInformation} = props
    const stepValue = 50

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                Valeurs sourcing
            </div>

            {
                tabs.map((tab, index) => {
                    return (
                        <div key={index} className={classes.valueContainer}>
                            <div className={classes.nutritionLabel}>
                                {tab.label}
                            </div>
                            <div className={nutritionInformation[tab.key] >= stepValue
                                ? classes.greenNutritionValue
                                : classes.redNutritionValue
                            }>
                                {nutritionInformation[tab.key]}&nbsp;%
                            </div>

                        </div>
                    )
                })
            }
            <div className={classes.valueContainer}>
                <div className={classes.nutritionLabel}>
                    Empreinte CO2
                </div>
                <div className={classes.nutritionValue}>
                    {nutritionInformation.carbonFootPrint}&nbsp;g
                </div>
            </div>

        </Paper>
    )
}
const styles = {
    container: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },
    titleContainer: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 16
    },
    valueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 4px"
    },
    nutritionLabel: {
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "130%"

    },
    nutritionValue: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: 14,
        lineHeight: "130%",
        fontWeight: "bold"
    },
    redNutritionValue: {
        composes: "$nutritionValue",
        color: COLORS.RED_NUTRITION
    },
    greenNutritionValue: {
        composes: "$nutritionValue",
        color: COLORS.GREEN_NUTRITION
    }
}
export default withStyles(styles)(NutritionalSourcing)
