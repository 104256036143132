import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  styled,
} from "@mui/material"

import { machineModelSchema } from "../../../../../utils/yupValidators"

// form initial values
const getInitialValues = (data) => {
  // creation
  if (!data) {
    return {
      cleanTime: "",
    }
  }

  // edition
  return {
    cleanTime: data.cleanTime,
    capacity: data.capacity,
    name: data.name,
    availabilityRate: data.availabilityRate,
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})


export const MachineModelFormModal = (props) => {
  const { onClose, open, onConfirm, machineType, machineModel } = props

  const formikRef = useRef(null)

  const handleConfirm = () => {
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
         {machineModel ? "Éditer un modèle" : "Ajouter un modèle"}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Formik
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={machineModelSchema}
          initialValues={getInitialValues(machineModel)}
        >
          {({ values, handleChange, handleBlur, errors }) => {
            return (
              <Form>
                <TextField
                  variant="standard"
                  label="Modèle"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
                <TextField
                  variant="standard"
                  label="Type de machine"
                  value={machineType.name}
                  fullWidth
                  disabled
                  sx={{ mt: 2 }}
                />
                <TextField
                  variant="standard"
                  label="Capacité max (en kg)"
                  name="capacity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.capacity}
                  error={!!errors.capacity}
                  helperText={errors.capacity}
                  fullWidth
                  type="number"
                  sx={{ mt: 2 }}
                />
                <TextField
                  variant="standard"
                  label="Temps de nettoyage entre 2 utilisations (en min)"
                  name="cleanTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cleanTime}
                  error={!!errors.cleanTime}
                  helperText={errors.cleanTime}
                  fullWidth
                  type="number"
                  sx={{ mt: 2 }}
                />
                <TextField
                  variant="standard"
                  label="Taux de disponibilité (en %)"
                  name="availabilityRate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.availabilityRate}
                  error={!!errors.availabilityRate}
                  helperText={errors.availabilityRate}
                  fullWidth
                  type="number"
                  sx={{ mt: 2 }}
                />
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default MachineModelFormModal
