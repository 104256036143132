import React, { PureComponent, Fragment } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { withStyles } from "@mui/styles"
import _isEqual from "lodash/isEqual"
import KitchenOutputFilters from "../../../components/Planning/Dashboard/KitchenOutput/KitchenOutputFilters"
import KitchenOutputTable from "../../../components/Planning/Dashboard/KitchenOutput/KitchenOutputTable"
import { sectors } from "../../../utils/planning"
import {
    loadPlanningDashboardKo,
    unlockDispatchLocker,
    updateKitchenOutputCell,
    resetKitchenOutputRow,
    showKo,
    exportKitchenOutputData,
    computeDispatchDay,
    createDayCard,
    findProduct,
    removeRightProduct
} from "../../../actions/Planning/PlanningDashboard"
import { getBrandBy } from "../../../utils"
import PlanningKitchenOutputActions from "../../../components/Planning/PlanningActions/PlanningKitchenOutputActions"
import DayCardModal from "../../../components/Planning/PlanningActions/DayCardModal"
import { CAN_UPDATE_DASHBOARD } from "../../../utils/accessRights"
import PlanningProductModal from "../../../components/Planning/PlanningProductModal"

const styles = {
    kitchenOutputToolbar: {
        display: "flex",
        marginLeft: 10
    },
    kitchenOutputFilters: {
        overflowX: "scroll",
        overflowY: "hidden",
        flex: 2
    },
    kitchenOutputCompute: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 20
    }
}

class KitchenOutput extends PureComponent {
    constructor(props) {
        super(props)
        const { hubs, productTypesOptions } = this.props

        const selectedHubs = Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : []
        selectedHubs.push("ALL")

        const formatedHubs = [{
            value: "ALL",
            label: "Tous"
        }].concat(hubs.map(el => ({
            value: el.objectId,
            label: el.name
        })))

        this.state = {
            sectors: sectors,
            selectedSectors: sectors.map(elem => elem.value),
            hubs: formatedHubs,
            selectedHubs: selectedHubs,
            productTypes: productTypesOptions,
            selectedProductTypes: productTypesOptions.map(elem => elem.value),
            tableConfig: this.generateTableConfig(),
            editingCell: null,
            isCreatingDayCard: false,
            openProductInfo: false
        }
    }

    componentDidUpdate(prevProps) {
        const { hubs } = this.props

        if (!_isEqual(prevProps.hubs, hubs)) {
            const selectedHubs = Array.isArray(hubs) ? hubs.map(elem => elem.objectId) : []
            selectedHubs.push("ALL")

            const formatedHubs = [{
                value: "ALL",
                label: "Tous"
            }].concat(hubs.map(el => ({
                value: el.objectId,
                label: el.name
            })))

            this.setState({
                hubs: formatedHubs,
                selectedHubs: selectedHubs,
                tableConfig: this.generateTableConfig()
            })
        }
    }

    onClickExport = () => {
        const { date, brand, exportKitchenOutputData } = this.props

        exportKitchenOutputData(date, brand)
    }

    onClickCompute = () => {
        const { date, brand, computeDispatchDay } = this.props

        computeDispatchDay(date, brand)
    }

    onBrandClick = (brand) => {
        const {
            showKo
        } = this.props

        showKo(brand.name)
    }

    /**
     * Called when user click on lock icon
     * @param {int} x index
     * @param {string} y key
     */
    onCellUnlock = (x, y) => {
        const { date } = this.props

        if (moment().isSameOrBefore(date, "days")) {
            this.setState({ editingCell: { x: x, y: y } })
        }
    }

    /**
     * Called when blur event happen on text input
     * @param {int} value New value of the cell
     */
    onCommitChange = (value) => {
        const {
            koRows,
            updateKitchenOutputCell,
            brand,
            date
        } = this.props
        const {
            editingCell
        } = this.state

        const finalValue = value !== "" ? value : 0

        if (finalValue !== koRows[editingCell.x][editingCell.y].value) {
            updateKitchenOutputCell(koRows[editingCell.x], editingCell.y, finalValue, date, brand)
        }

        this.setState({ editingCell: null })
    }

    onStatusChange = (status, rowIndex) => {
        if ("TODO" === status) {
            const {
                koRows,
                resetKitchenOutputRow,
                date,
                brand
            } = this.props

            resetKitchenOutputRow(koRows[rowIndex], date, brand)
        }
    }

    checkInclude = (source, selected, value) => {
        let newSelected = value

        if (!selected.includes("ALL") && value.includes("ALL")) {
            newSelected = source.map(elem => elem.value)
        }
        else if (selected.includes("ALL") && !value.includes("ALL")) {
            newSelected = []
        }
        else if (!newSelected.includes("ALL") && newSelected.length === source.length - 1) {
            newSelected.push("ALL")
        }
        else if (newSelected.includes("ALL") && newSelected.length < source.length) {
            newSelected = newSelected.filter(elem => elem !== "ALL")
        }

        return newSelected
    }

    onSectorsChange = (eventSectors) => {
        const { hubs } = this.props
        const {
            sectors,
            selectedSectors
        } = this.state

        const newSectors = this.checkInclude(sectors, selectedSectors, eventSectors)

        const newHubs = [{
            value: "ALL",
            label: "Tous"
        }].concat(hubs.filter(el => newSectors.includes(el.sector)).map(el => ({
            value: el.objectId,
            label: el.name
        })))
        const selectedHubs = newHubs.map(elem => elem.value)

        this.setState({
            selectedSectors: newSectors,
            selectedHubs: selectedHubs,
            hubs: newHubs
        })
    }

    onHubsChange = (eventHubs) => {
        const {
            hubs,
            selectedHubs
        } = this.state

        const newHubs = this.checkInclude(hubs, selectedHubs, eventHubs)

        this.setState({
            selectedHubs: newHubs
        })
    }

    onProductTypesChange = (eventProductTypes) => {
        const {
            productTypes,
            selectedProductTypes
        } = this.state

        const newProductTypes = this.checkInclude(productTypes, selectedProductTypes, eventProductTypes)

        this.setState({
            selectedProductTypes: newProductTypes
        })
    }

    onRowUnlock = (lockerId) => {
        const { unlockDispatchLocker, date } = this.props

        unlockDispatchLocker(lockerId, date)
    }

    onDateChange = (event) => {
        const {
            loadPlanningDashboardKo,
            brand
        } = this.props
        const date = moment.utc(event).startOf("day").valueOf()

        loadPlanningDashboardKo(date, brand)
    }

    onCreateDayCard = (values) => {
        const { date, brand, createDayCard } = this.props

        createDayCard(date, brand, values)
    }

    toggleCreatingDayCard = () => {
        this.setState({ isCreatingDayCard: !this.state.isCreatingDayCard })
    }

    /**
     * Creates all the configuration for the table.
     * Here we have the columns, summary row, columns widths, and the fixed columns
     * @returns Columns, totalSummaryItems, leftColumns, rightColumns, defaultColumnWidths
     */
    generateTableConfig() {
        const { hubs } = this.props

        const columns = [
            { name: "uniqueCode", title: "Code" },
            { name: "product", title: "Produit" },
            { name: "dlc", title: "DLC" },
            { name: "status", title: "Statut" },
            { name: "dispatchDate", title: "Dispatché le" },
            { name: "predictedQty", title: "Prévu" },
            { name: "dispatchQty", title: "Entrepôt" },
            { name: "nationalSend", title: "Province" },
            { name: "smallRetail", title: "Petit linéaire" }
        ]

        const totalSummaryItems = [
            { columnName: "product", type: "count" },
            { columnName: "predictedQty", type: "sum" },
            { columnName: "dispatchQty", type: "sum" },
            { columnName: "total", type: "sum" }
        ]

        const defaultColumnWidths = [
            { columnName: "uniqueCode", width: 75 },
            { columnName: "product", width: 150 },
            { columnName: "dlc", width: 75 },
            { columnName: "status", width: 110 },
            { columnName: "dispatchDate", width: 125 },
            { columnName: "predictedQty", width: 75 },
            { columnName: "dispatchQty", width: 75 },
            { columnName: "nationalSend", width: 75 },
            { columnName: "smallRetail", width: 75 },
            { columnName: "total", width: 75 }
        ]

        for (const currentHub of hubs) {
            columns.push({
                name: currentHub.objectId,
                title: currentHub.name,
                getCellValue: row => {
                    return row[currentHub.objectId] ? row[currentHub.objectId].value : 0
                }
            })
            totalSummaryItems.push({
                columnName: currentHub.objectId,
                type: "sum"
            })
            defaultColumnWidths.push({
                columnName: currentHub.objectId,
                width: 75
            })
        }

        columns.push({
            name: "total",
            title: "Total"
        })

        const rightColumns = ["total"]

        const linkedCells = { // linked: for exemple productedQty in red if < predictedQty
            "dispatchQty": "productedQty",
            "total": "dispatchQty"
        }

        return {
            columns: columns,
            totalSummaryItems: totalSummaryItems,
            rightColumns: rightColumns,
            defaultColumnWidths: defaultColumnWidths,
            linkedCells: linkedCells
        }
    }

    /**
     * Filter table data with values provided in filters
     * @param {*[]} rows Rows to filter
     * @param {string[]} cols Columns to filter
     * @param {Object[]} hubsCpy A copy of the hubs (because we filter hubs if sector is changed)
     * @returns Rows columns, and hubs filtered
     */
    filter(rows, cols, hubsCpy) {
        const {
            hubs
        } = this.props
        const {
            selectedHubs,
            selectedSectors,
            selectedProductTypes
        } = this.state

        if (!selectedSectors.includes("ALL")) {
            cols = cols.filter(col => {
                const hub = hubs.find(hub => hub.value === col.name)

                return undefined === hub ? true : selectedSectors.includes(hub.sector)
            })
        }

        if (!selectedProductTypes.includes("ALL")) {
            rows = rows.filter(row => selectedProductTypes.includes(row.productType))
        }

        cols = cols.filter(col => (
            hubs.find(hub => hub.objectId === col.name) === undefined || selectedHubs.includes(col.name)
        ))

        return {
            newRows: rows,
            newCols: cols,
            newHubs: hubsCpy
        }
    }

    closeProductInfo = () => {
        this.props.removeRightProduct()
        this.setState({ openProductInfo: false })
    }

    openProductInfo = (row) => {
        if (row.saleDate && row.productId && row.brand) {
            this.props.findProduct(row)
            this.setState({ openProductInfo: true })
        }
    }

    goToProduct = () => {
        const { selectedCard } = this.props
        const isRecipe = (selectedCard.itemType === "Recipe")

        if (isRecipe) window.open(`/products/recipe/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
        if (!isRecipe) window.open(`/products/subcontractor-product/general/${selectedCard.itemId ? selectedCard.itemId : ""}`)
    }

    render() {
        const {
            date,
            koRows,
            classes,
            brand,
            canEdit,
            rightProduct,
            selectedCard
        } = this.props

        const {
            productTypes,
            selectedSectors,
            selectedHubs,
            selectedProductTypes,
            tableConfig,
            editingCell,
            hubs,
            isCreatingDayCard,
            openProductInfo
        } = this.state

        const {
            newRows,
            newCols,
            newHubs
        } = this.filter([...koRows], [...tableConfig.columns], [...hubs])

        const completeBrand = getBrandBy("name", brand)

        return (
            <Fragment>
                <div className={classes.kitchenOutputToolbar}>
                    <span className={classes.kitchenOutputFilters}>
                        <KitchenOutputFilters
                            date={date}
                            sectors={sectors}
                            hubs={newHubs}
                            productTypes={productTypes}
                            brand={completeBrand}
                            selectedSectors={selectedSectors}
                            selectedHubs={selectedHubs}
                            selectedProductTypes={selectedProductTypes}
                            onBrandClick={this.onBrandClick}
                            onDateChange={this.onDateChange}
                            onSectorsChange={this.onSectorsChange}
                            onHubsChange={this.onHubsChange}
                            onProductTypesChange={this.onProductTypesChange}
                        />
                    </span>
                    <span className={classes.kitchenOutputCompute}>
                        <PlanningKitchenOutputActions
                            onClickExtract={this.onClickExport}
                            onClickCompute={this.onClickCompute}
                            onClickCreateCard={this.toggleCreatingDayCard}
                        />
                    </span>
                </div>
                <KitchenOutputTable
                    key={newCols.length}
                    rows={newRows}
                    columns={newCols}
                    selectedHubs={hubs}
                    leftColumns={tableConfig.leftColumns}
                    rightColumns={tableConfig.rightColumns}
                    totalSummaryItems={tableConfig.totalSummaryItems}
                    onCellUnlock={this.onCellUnlock}
                    onCommitChange={this.onCommitChange}
                    onStatusChange={this.onStatusChange}
                    defaultColumnWidths={tableConfig.defaultColumnWidths}
                    editingCell={editingCell}
                    linkedCells={tableConfig.linkedCells}
                    pastDate={!moment().isSameOrBefore(date, "days")}
                    onRowUnlock={this.onRowUnlock}
                    canEdit={canEdit}
                    openProductInfo={this.openProductInfo}
                />
                <DayCardModal
                    onClose={this.toggleCreatingDayCard}
                    onCreateDayCard={this.onCreateDayCard}
                    isCreatingDayCard={isCreatingDayCard}
                    hubs={hubs}
                />
                <PlanningProductModal
                    open={openProductInfo}
                    handleClose={this.closeProductInfo}
                    selectedCard={selectedCard}
                    rightProduct={rightProduct}
                    goToProduct={this.goToProduct}
                />
            </Fragment>
        )
    }
}

const KitchenOutputWithStyles = withStyles(styles)(KitchenOutput)

export default connect((state) => {
    return {
        date: state.planningDashboard.date,
        koRows: state.planningDashboard.koRows,
        hubs: state.planningDashboard.hubs,
        brand: state.planningDashboard.brand,
        canEdit: state.app.rights[CAN_UPDATE_DASHBOARD],
        rightProduct: state.planningDashboard.rightProduct,
        selectedCard: state.planningDashboard.rightSelectedCard,
        productTypesOptions: state.productTypes.productTypeOptions
    }
}, {
    loadPlanningDashboardKo,
    showKo,
    computeDispatchDay,
    updateKitchenOutputCell,
    resetKitchenOutputRow,
    unlockDispatchLocker,
    exportKitchenOutputData,
    createDayCard,
    findProduct,
    removeRightProduct
})(KitchenOutputWithStyles)
