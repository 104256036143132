import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import {useDispatch, useSelector} from "react-redux"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import LoginForm from "./LoginForm"
import { createAccount } from "../../actions/Utils/app"
import Typography from "@mui/material/Typography"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import { createAccountSchema } from "../../utils/yupValidators"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { closeAppSnackBar } from "../../actions/Utils/app"
import _isEqual from "lodash/isEqual"

const useStyles = makeStyles({
    card: {
        background: COLORS.WHITE,
        border: `0.5px solid ${COLORS.SITE_BORDER}`,
        boxSizing: "border-box",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        borderRadius: 4,
        minHeight: 312,
        minWidth: 363,
        paddingLeft: 43,
        paddingTop: 40,
        paddingRight: 54,
        "& .MuiCardContent-root": {
            padding: 0,
            width: 266
        }
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 10
    },
    baseLink: {
        fontSize: 14,
        letterSpacing: 0.25,
        color: COLORS.BLUE_PAPER_SELECTED,
        "& > a": {
            color: COLORS.BLUE_PAPER_SELECTED,
            textDecoration: "underlined"
        }
    },
    pwdInfo: {
        composes: "$baseLink",
        marginTop: 57
    },
    cardAction: {
        justifyContent: "center",
        paddingTop: 40,
        paddingBottom: 18
    },
    list: {
        paddingLeft: 30
    }
})

const CreateAccount = (props) => {
    const {
        location
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const appSnackBar = useSelector(state => state.app.appSnackBar, _isEqual)

    const _createAccount = values => {
        dispatch(createAccount(values.username, values.password, location.state && location.state.returnPath))
    }

    const _closeSnackbar = () => {
        dispatch(closeAppSnackBar(appSnackBar.type))
    }

    return (
        <div className="flexCenter height100">
            <Formik
                enableReinitialize
                initialValues={{
                    username: "",
                    password: ""
                }}
                onSubmit={_createAccount}
                validationSchema={createAccountSchema}
            >
                {({
                    values,
                    errors,
                    handleChange
                }) => {
                    return (
                        <Form>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.title}>
                                        Créer mon compte
                                    </Typography>
                                    <LoginForm
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </CardContent>
                                <CardActions className={classes.cardAction}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        CRÉER MON COMPTE
                                    </Button>
                                </CardActions>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
            <div className={classes.pwdInfo}>
                Votre mot de passe doit comporter :
                <ul className={classes.list}>
                    <li>Au moins 10 caractères</li>
                    <li>Au moins un caractère spécial</li>
                    <li>Au moins une majuscule</li>
                    <li>Au moins un chiffre</li>
                </ul>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={appSnackBar.open}
                autoHideDuration={appSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={appSnackBar.type}
                    message={appSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default CreateAccount
