import React from "react"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

export const BrandSelect = (props) => {
    const { productBrands, ...otherProps } = props

    return (
        <FormControl variant="standard">
            <InputLabel>Marque</InputLabel>
            <Select
				variant="standard"
                name="brand"
                label="Marque"
                id="brand"
                {...otherProps}
            >
                {
                    productBrands.map((elem, index) =>
                        <MenuItem key={index} value={elem.name}>{elem.label} </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default BrandSelect
