import React, { useState } from "react"

import { makeStyles } from "@mui/styles"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import { Modal } from "@mui/material"
import { Button } from "@mui/material"
import Delete from "@mui/icons-material/Delete"
import { COLORS } from "../../../../utils"

const useStyles = makeStyles({
    container: {
        minHeight: 38,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: COLORS.BLUE_CREDIT_DELETE
    },
    header: {
        color: COLORS.BLUE_CREDIT_TOOLBAR
    },
    delete: {
        color: COLORS.BLUE_CREDIT_TOOLBAR,
        height: 38,
        width: 38
    },
    modalRoot: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modalContainer: {
        backgroundColor: COLORS.WHITE,
        borderRadius: 4,
        padding: 25,
        width: 600
    },
    modalTitle: {
        fontWeight: 500,
        fontSize: 20,
        marginBottom: 24
    },
    modalText: {
        color: COLORS.GREY_BACK_BUTTON,
        fontSize: 16,
        marginBottom: 24
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        "& > :first-child": {
            marginRight: 20
        }
    }
})

export const CreditNotesEnhancedTableToolbar = (props) => {
    const {
        numSelected,
        onDelete
    } = props

    const classes = useStyles()

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const handleClick = () => {
        setShowDeleteModal(true)
    }

    const handleDelete = () => {
        onDelete()
        setShowDeleteModal(false)
    }

    return (
        <Toolbar
            className={classes.container}
        >
            <div>
                <Typography className={classes.header}>
                    {`${numSelected} ${numSelected > 1 ? "avoirs" : "avoir"} ${numSelected > 1 ? "sélectionnés" : "sélectionné"}`}
                </Typography>
            </div>
            <div>
                <Tooltip title="supprimer" disableInteractive>
                    <IconButton onClick={handleClick} className={classes.delete} size="large">
                        <Delete />
                    </IconButton>
                </Tooltip>
            </div>
            {
                    showDeleteModal &&
                        <Modal
                            open={showDeleteModal} 
                            onClose={() => setShowDeleteModal(false)}
                            className={classes.modalRoot}
                        >
                            <div className={classes.modalContainer}>
                                <Typography className={classes.modalTitle}>
                                    Supprimer les avoirs / réclamations
                                </Typography>
                                <Typography className={classes.modalText}>
                                    Êtes-vous certains de vouloir supprimer les avoirs / réclamations sélectionnés.
                                </Typography>
                                <div className={classes.modalActions}>
                                    <Button
                                        color="primary"
                                        onClick={() => setShowDeleteModal(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDelete}
                                    >
                                        supprimer
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                }
        </Toolbar>
    )
}

export default CreditNotesEnhancedTableToolbar