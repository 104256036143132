import React from "react"
import withStyles from "@mui/styles/withStyles"
import Slider from "@mui/material/Slider"
import { COLORS } from "../../../utils"

const boxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)"
const LeftSearchSlider = withStyles({
    root: {
        color: COLORS.LIGHT_BLUE_SLIDER,
        padding: "30px 0",
        "& .MuiSlider-valueLabel": {
            backgroundColor: "transparent",
            color: COLORS.BLACK
        }
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: COLORS.WHITE,
        boxShadow,
        "&:focus, &:hover, &$active": {
            boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        fontSize: 12,
        fontWeight: 400,
        top: -4,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
        opacity: 0.5,
        backgroundColor: COLORS.LIGHT_SLIDER,
    },
    mark: {
        backgroundColor: COLORS.LIGHT_SLIDER,
    },
    markActive: {
        opacity: 1,
        backgroundColor: "currentColor",
    }
})(Slider)

function valuetext(value) {
    return `${value}°C`
}

export default function RangeSlider(props) {
    const {
        label,
        marks,
        value,
        updateValue
    } = props

    const min = marks[0]
    const max = marks[marks.length - 1]

    const handleChange = (event, newValue) => {
        updateValue(newValue)
    }

    return (
        <div>
            <h2>{label}</h2>
            <LeftSearchSlider
                value={(value.length)?value:[marks[0], marks[marks.length - 1]]}
                onChange={handleChange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                color={"primary"}
                min={min}
                max={max}
            />
        </div>
    )
}
