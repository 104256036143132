import React from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

import { countries } from "../../utils/countries"

/**
 * display label
 * @param {*} option 
 * @returns 
 */
const getOptionLabel = (option) => {
  // get the label from the option list, mainly if there is a default value
  if (typeof option === "string") {
    const currentCountry = countries.find(country => country.code === option)

    return currentCountry?.label || ""
  }

  return option.label
}

const CountrySelect = ({ sx, onChange, name, value, ...inputProps }) => {
  // save only the country code instead of the option object
  const handleChange = (_, newValue) => {
    const value = newValue?.code 
    if (name) {
      onChange(name, value)
      return
    }
    onChange(value)
  }

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      id="country-select"
      sx={sx}
      options={countries}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        // flag
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          name={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

export default CountrySelect