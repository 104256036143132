import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ReceptionDispatchEnhancedTableHead from "../../components/table-utils/Dispatch/ReceptionDispatchEnhancedTableHead"
import { COLORS, setSearchValue } from "../../utils"
import { showLotGroupDetail } from "../../actions/LotGroup/lotGroup"
import { stableSort, getSorting } from "../../components/table-utils/utils"
import moment from "moment"
import SearchInput from "../../components/LotGroup/SearchInput"
import clsx from "clsx"

const useClasses = makeStyles(() => ({
  root: {
    width: "100%",
    "& .Mui-selected": {
      backgroundColor: COLORS.PACKAGING_ROW_SELECTED,
    },
  },
  tableWrapper: {
    overflowX: "auto",
    background: COLORS.LIGHT_GREY,
  },
  table: {
    marginBottom: 21,
    background: COLORS.WHITE,
    "&.last": {
      marginBottom: 0,
    },
  },
  idColumn: {
    color: COLORS.BLACK,
    cursor: "pointer",
    fontSize: 24,
    lineHeight: "100%",
    paddingRight: 10,
    paddingLeft: 15,
    textAlign: "center",
  },
  nameColumn: {
    fontSize: 16,
    color: COLORS.DARK_GREY,
    letterSpacing: 0.25,
    lineHeight: "100%",
  },
  typeColumn: {
    fontSize: 12,
    color: COLORS.BLACK,
    letterSpacing: 0.25,
    lineHeight: "100%",
    paddingRight: 10,
    paddingLeft: 15,
    textAlign: "center",
  },
  dlcColumn: {
    fontSize: 12,
    color: COLORS.BLACK,
    letterSpacing: 0.25,
    lineHeight: "100%",
    paddingRight: 10,
    paddingLeft: 15,
    textAlign: "center",
  },
  waitingColumn: {
    fontSize: 17,
    color: COLORS.BLACK,
    letterSpacing: 0.25,
    lineHeight: "100%",
    paddingRight: 10,
    paddingLeft: 15,
    textAlign: "center",
  },
  reusable: {
    mask: "url(/img/recycle.svg) no-repeat center",
    width: 11,
    height: 10,
    marginRight: 5,
    marginTop: 2,
    display: "inline-block",
  },
}))

const LotGroupTable = (props) => {
  const {
    storageIndex,
    tableType,
    lotGroupData,
    showLotGroupDetail,
    date,
    packaging,
    canOpen = true,
    productTypeOptions
  } = props

  const classes = useClasses()
  const order = "asc"
  const orderBy = "type"
  const selected = []
  const [searchFilter, setSearchFilter] = useState(props.searchFilter)
  const [filterLotGroupData, setfilterLotGroupData] = useState([])
  const [data, setData] = useState({})

  useEffect(() => {
    createDataObject()

    if ("" !== searchFilter) {
      updateSearchValue({
        target: { value: searchFilter, noStorageAdd: true },
      })
    }
  }, [])

  useEffect(() => {
    createDataObject()
  }, [lotGroupData, searchFilter])

  const createDataObject = () => {
    const finalData = {}
    let lotGroupDataFilter = []

    if (searchFilter !== null && searchFilter !== "") {
      const regex = new RegExp(searchFilter, "ig")
      lotGroupDataFilter = lotGroupData.filter(
        (el) =>
          el.productionItems[0] &&
          (el.productionItems[0].commercialTitle.match(regex) !== null ||
            el.productionItems[0].uniqueCode.match(regex) !== null)
      )
    } else {
      lotGroupDataFilter = lotGroupData
    }

    setfilterLotGroupData(lotGroupDataFilter)

    for (const i in lotGroupDataFilter) {
      const currentData = lotGroupDataFilter[i]

      if (currentData.productionItems.length > 0) {
        const currentProductType = productTypeOptions.find(
          (el) => el.type === currentData.productionItems[0].productType
        )

        if (!finalData[currentProductType.label])
          finalData[currentProductType.label] = []

        finalData[currentProductType.label].push({
          uniqueCode: currentData.productionItems[0].uniqueCode,
          dlc: moment(currentData.productionItems[0].dlc).format("DD/MM"),
          idProductPackaging: currentData.objectId,
          idProduct: currentData.productionItems[0].itemId,
          commercialName: currentData.productionItems[0].commercialName,
          productType: currentProductType ? currentProductType.label : "",
          kfcStatus: currentData.status,
          waitingProduction: currentData.packagingProduction
            ? currentData.packagingProduction
            : currentData.expectedProduction,
          warehouseProduction: currentData.warehouseProduction,
        })
      }
    }

    setData(finalData)
  }

  const updateSearchValue = (event) => {
    if (event) {
      setSearchFilter(event.target.value)
      if (!event.target.noStorageAdd)
        setSearchValue(event.target.value, storageIndex)
    } else {
      setSearchFilter(null)
    }
  }

  const _showLotGroupDetail = (event, idProductPackaging) => {
    if (canOpen && tableType === "todo") {
      showLotGroupDetail(
        idProductPackaging,
        moment(date).startOf("day").format("YYYY-MM-DD")
      )
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  return (
    <Paper elevation={0} className={classes.root}>
      <SearchInput
        searchFilter={searchFilter}
        label="Rechercher un ID, un produit"
        updateSearchValue={updateSearchValue}
      />

      <div className={classes.tableWrapper}>
        {Object.keys(data).map((index, loop) => {
          const last = loop + 1 === Object.keys(data).length
          return (
            <Table
              aria-labelledby="tableTitle"
              className={clsx(classes.table, {
                last: last || searchFilter,
              })}
              key={`${tableType}-${index}`}
            >
              <ReceptionDispatchEnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={null}
                productType={index}
                packaging={packaging}
                rowCount={filterLotGroupData && filterLotGroupData.length}
                collapse={searchFilter !== ""}
              />
              <TableBody>
                {stableSort(data[index], getSorting(order, orderBy)).map(
                  (n) => {
                    const isSelectedRow = isSelected()
                    const isDone = null !== n.warehouseProduction

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelectedRow}
                        tabIndex={-1}
                        key={n.idProductPackaging}
                        selected={isSelectedRow || !isDone}
                      >
                        <TableCell
                          className={classes.idColumn}
                          component="th"
                          scope="row"
                        >
                          {n.uniqueCode}
                        </TableCell>
                        <TableCell
                          className={classes.nameColumn}
                          onClick={(event) =>
                            isDone &&
                            _showLotGroupDetail(event, n.idProductPackaging)
                          }
                        >
                          {n.commercialName}
                        </TableCell>
                        <TableCell
                          className={classes.typeColumn}
                          onClick={(event) =>
                            isDone &&
                            _showLotGroupDetail(event, n.idProductPackaging)
                          }
                        >
                          {n.productType.toUpperCase()}
                        </TableCell>
                        <TableCell
                          className={classes.dlcColumn}
                          onClick={(event) =>
                            isDone &&
                            _showLotGroupDetail(event, n.idProductPackaging)
                          }
                        >
                          {n.dlc}
                        </TableCell>
                        <TableCell
                          className={classes.waitingColumn}
                          onClick={(event) =>
                            isDone &&
                            _showLotGroupDetail(event, n.idProductPackaging)
                          }
                        >
                          {n.waitingProduction}
                        </TableCell>
                        <TableCell
                          className={classes.waitingColumn}
                          onClick={(event) =>
                            isDone &&
                            _showLotGroupDetail(event, n.idProductPackaging)
                          }
                        >
                          {n.warehouseProduction}
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          )
        })}
      </div>
    </Paper>
  )
}

export default connect(
  (state) => {
    return {
      date: state.lotGroup.date,
      packaging: state.lotGroup.packaging,
      productTypeOptions: state.lotGroup.productTypeOptions
    }
  },
  {
    showLotGroupDetail,
  }
)(LotGroupTable)
