import React, { useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Table from "@mui/material/Table"
import TableContainer from "@mui/material/TableContainer"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import CheckBox from "@mui/material/Checkbox"
import TablePagination from "@mui/material/TablePagination"
import { connect } from "react-redux"
import { showChefEdit, showChefRead } from "../../../../../actions/Chefs/Chefs"
import { getSorting, stableSort } from "../../../../../components/table-utils/utils"
import ChefsEnhancedTableHead from "../../../../../components/table-utils/Chefs/ChefsEnhancedTableHead"
import ChefsEnhancedTableToolbar from "../../../../../components/table-utils/Chefs/ChefsEnhancedTableToolbar"
import { createChefDataTable } from "../../../../../utils/chefs"
import { setSearchValue, filterWithSearchValue } from "../../../../../utils"

export const ChefsList = ({classes, chefs, showChefRead, showChefEdit}) => {
    const storage = JSON.parse(localStorage.getItem("searchValue"))
    const [searchFilter, setSearchFilter] = useState((storage && storage.chefsList)?storage.chefsList:null)
    const [order, setOrder] = useState("asc")
    const [orderBy, setOrderBy] = useState("firstName")
    const [selected, setSelected] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filterChefs, setFilterChefs ] = useState(filterWithSearchValue(searchFilter, chefs, "firstName"))
    const [data, setData] = useState(filterChefs.map(createChefDataTable))

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const isItSelected = id => selected.indexOf(id) !== -1
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc"
        setOrder(isDesc ? "asc" : "desc")
        setOrderBy(property)
    }

    const handleSelectAllClick = event => {
        const newSelected = event && event.target && event.target.checked
            ? data.map(subcontractor => subcontractor.objectId)
            : []
        setSelected(newSelected)
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }
        setSelected(newSelected)
    }

    const updateSearchFilter = (event) => {
        const searchValue = event && event.target && event.target.value
            ? event.target.value
            : null

        setSearchFilter(searchValue)
        setSearchValue(searchValue, "chefsList")
        updateFilters(searchValue)
    }

    const updateFilters = (search) => {
        const newChefsFilter = filterWithSearchValue(search, chefs, "firstName")

        setFilterChefs(newChefsFilter)
        setData(newChefsFilter.map(createChefDataTable))
        setPage(0)
    }

    return (
        <Paper elevation={0} className={classes.root}>
            <ChefsEnhancedTableToolbar
                numSelected={selected.length}
                chefs={chefs}
                createChef={showChefEdit}
                selected={selected}
            />
            <div className={classes.searchFieldDiv} >
                <TextField
                    variant="standard"
                    defaultValue={searchFilter}
                    className={classes.searchFieldInput}
                    onChange={updateSearchFilter}
                    label="Rechercher un chef"
                />
            </div>
            <TableContainer className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <ChefsEnhancedTableHead
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        order={order}
                        orderBy={orderBy}
                        rowCount={filterChefs.length}
                    />
                    <TableBody>
                        {
                            stableSort(data, getSorting(order, orderBy)).map(elem => {
                                const isSelected = isItSelected(elem.objectId)
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={elem.objectId}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox" onClick={event => handleClick(event, elem.objectId)}>
                                            <CheckBox color="secondary" checked={isSelected} />
                                        </TableCell>
                                        <TableCell className={classes.column} onClick={() => showChefRead(elem.objectId)}>
                                            {elem.objectId}
                                        </TableCell>
                                        <TableCell className={classes.column} onClick={() => showChefRead(elem.objectId)}>
                                            {elem.firstName}
                                        </TableCell>
                                        <TableCell className={classes.column} onClick={() => showChefRead(elem.objectId)}>
                                            {elem.lastName}
                                        </TableCell>
                                        <TableCell className={classes.column} align="right" onClick={() => showChefRead(elem.objectId)}>
                                            {elem.createdAt}
                                        </TableCell>
                                        <TableCell className={classes.column} align="right" onClick={() => showChefRead(elem.objectId)}>
                                            {elem.updatedAt}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Chefs par page"
            />
        </Paper>
    )
}

const styles = {
    root: {
        width: "100%"
    },
    searchFieldDiv: {
        paddingLeft: 24,
        width: 300,
        marginBottom: 20,
        display: "flex"
    },
    searchFieldInput: {
        width: 300
    },
    tableWrapper: {
        overflowX: "auto",
    },
    selectType: {
        marginLeft: 20,
        width: 200
    }
}
const StyledChefsList = withStyles(styles)(ChefsList)

export default connect(state => {
    return {
        chefs: state.chefs.chefs
    }
}, {
    showChefRead,
    showChefEdit
})(StyledChefsList)
