import React from "react"
import PageHeaderTitle from "../../components/PageHeaderTitle"
import { Box } from "@mui/material"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import IngredientIcon from "@mui/icons-material/Restaurant"

const IngredientPageHeader = ({ subtitle, onBack, withBackButton = false, ingredientType }) => {
    const title = <PageHeaderTitle title={`Ingrédient ${ingredientType}`} subtitle={subtitle} icon={<IngredientIcon />} color="#6E3EFF" />

    if (withBackButton) {
        return (
            <Box sx={{ height: 64 }}>
                <GenericTopBarHeader
                    title={title}
                    leftAction={<GenericHeaderReturnButton handleClick={onBack} />}
                />
            </Box>
        )
    }
    return title
}

export default IngredientPageHeader