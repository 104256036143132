import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import {COLORS} from "../../utils"

const useStyles = makeStyles(() => ({
    dialog: {
        textAlign: "justify",
        color: COLORS.GREY_SEARCH,
        fontSize: 16,
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.1)"
        }
    },
    rootDialogActions: {
        justifyContent: "flex-end",
        padding: 15,
        marginBottom: 15
    },
    title: {
        fontWeight: 500,
        fontSize: 17,
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 0,
    },
    dialogTitleLabel: {
        fontWeight: 500,
        marginTop: 5,
        fontSize: 20,
        lineHeight: "24px",
        width: 596
    }
}))

const ValidateCreationModal = (props) => {
    const {
        open,
        title,
        onClose,
        action
    } = props

    const classes = useStyles()

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.dialog}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{root: classes.dialogTitle}}>
                <span className={classes.dialogTitleLabel}>{title}</span>
            </DialogTitle>
            <DialogContent>
                <p>
                    Cela vous permettra de l’importer dans une nouvelle recette en la recherchant sur l’interface d’édition de la recette. Vous pourrez la modifier sur cette page accessible depuis la liste des sections réutilisables de KFC.
                </p>
            </DialogContent>
            <DialogActions classes={{root: classes.rootDialogActions}}>
                <Button
                    onClick={onClose}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={action}
                >
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ValidateCreationModal
