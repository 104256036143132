import React from "react"
import CheckBox from "@mui/material/Checkbox"

export default ({ input: {checked, name, onChange: onInputChange }, onChange, ...rest }) => {
    const handleChange = (event, checked) => {
        onInputChange(event)
        onChange && onChange(checked)
    }

    return (
        <CheckBox{...rest} name={name} onChange={handleChange} checked={!!checked} color="secondary"/>
    )
}
