import React from "react"
import { Box, useTheme } from "@mui/material"

const TabPanel = ({ children, top = 14, sx }) => {
  const theme = useTheme()

  return (
    <Box
      sx={sx}
      mt={top}
      bgcolor="#F6F6F6"
      minHeight={`calc(100vh - ${theme.spacing(top)})`} // window height - margin top
    >
          {children}
      </Box>
    )
}

export default TabPanel