import React, { useRef } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  ListItemText,
  Checkbox,
  Box,
} from "@mui/material"

import { productTypesSchema } from "../../../../utils/yupValidators"
import { MANAGEMENT_MODES, PRONOUNS } from "../../../../utils/productTypesUtils"
import { ALPHABETS } from "../../../../utils/constant"
import { ACTIVE_KFC_BRANDS } from "../../../../utils"
import BrandsSelect from "../../../../components/Recipe/BrandsSelect"

const getInitialValues = (productType) => {
  if (productType) {
    return {
      label: productType.label,
      uniqueCode: productType.uniqueCode.split(""),
      brands: productType.brands,
      adj: productType.adj,
      fcSzName: productType.fcSzName,
      kfcName: productType.kfcName,
      managementMode: productType.managementMode,
    }
  }

  return {
    label: "",
    uniqueCode: [],
    brands: ["ALL"],
    adj: "",
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

export const ProductTypeFormModal = ({ onClose, open, onConfirm, productType }) => {
  const formikRef = useRef(null)

  const handleConfirm = () => {
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm({ values, inEditionMode: !!productType, order: productType?.order }) // if there is a productType, we are in edit mode, else in creation mode
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
        {productType ? "Modifier un type de produit" : "Ajouter un type de produit"}
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Formik
          innerRef={formikRef}
          initialValues={getInitialValues(productType)}
          validationSchema={productTypesSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors }) => {
            return (
              <Form>
                {/* label */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.name}>
                  <TextField
                    label="Label"
                    variant="standard"
                    name="label"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.label}
                    error={errors.label}
                    fullWidth
                    disabled={!!productType}
                  />
                  {errors.label && <FormHelperText>{errors.label}</FormHelperText>}
                </FormControl>
                {/* pronouns */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.adj}>
                  <InputLabel id="pronoun-label">Pronom</InputLabel>
                  <Select
                    variant="standard"
                    name="adj"
                    value={values.adj}
                    onChange={handleChange}
                    fullWidth
                  >
                    {PRONOUNS.map((pronoun, index) => (
                      <MenuItem key={index} value={pronoun.value}>
                        {pronoun.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.adj && <FormHelperText>{errors.adj}</FormHelperText>}
                </FormControl>
                {/* kfcName */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.kfcName}>
                  <TextField
                    label="Nom KFC"
                    variant="standard"
                    name="kfcName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.kfcName}
                    error={errors.kfcName}
                    fullWidth
                    disabled={!!productType}
                  />
                  {errors.kfcName && <FormHelperText>{errors.kfcName}</FormHelperText>}
                </FormControl>

                {/* fcSzName */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.fcSzName}>
                  <TextField
                    label="Nom FC/SZN"
                    variant="standard"
                    name="fcSzName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fcSzName}
                    error={errors.fcSzName}
                    fullWidth
                    disabled={!!productType}
                  />
                  {errors.fcSzName && <FormHelperText>{errors.fcSzName}</FormHelperText>}
                </FormControl>

                {/* uniqueCode */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.uniqueCode}>
                  <InputLabel id="uniqueCode-label">Séquence code unique</InputLabel>
                  <Select
                    variant="standard"
                    name="uniqueCode"
                    value={typeof values.uniqueCode === "string" ? values.uniqueCode.split("") : values.uniqueCode}
                    onChange={handleChange}
                    fullWidth
                    multiple
                    renderValue={(selected) => selected.join("")}
                  >
                    {ALPHABETS.map((alphabet, index) => (
                      <MenuItem key={index} value={alphabet}>
                        <Checkbox checked={values.uniqueCode.indexOf(alphabet) > -1} />
                        <ListItemText primary={alphabet} />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.uniqueCode && <FormHelperText>{errors.uniqueCode}</FormHelperText>}
                </FormControl>

                {/* managementMode */}
                <FormControl variant="standard" fullWidth sx={{ mt: 2.5 }} error={!!errors.managementMode}>
                  <InputLabel id="managementMode-label">Mode de gestion</InputLabel>
                  <Select
                    variant="standard"
                    name="managementMode"
                    value={values.managementMode}
                    onChange={handleChange}
                    fullWidth
                  >
                    {MANAGEMENT_MODES.map((managementMode, index) => (
                      <MenuItem key={index} value={managementMode.value}>
                        {managementMode.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.managementMode && <FormHelperText>{errors.managementMode}</FormHelperText>}
                </FormControl>

                {/* brands */}
                <Box sx={{ mt: 2.5 }}>
                  <BrandsSelect
                    label="Marques concernées"
                    error={!!errors.brands}
                    id="brands-label"
                    name="brands"
                    value={values.brands}
                    onChange={handleChange}
                    productBrands={[{ label: "Toutes", name: "ALL" }, ...ACTIVE_KFC_BRANDS]}
                    noLabel
                    fullWidth
                  />
                  {errors.brands && <FormHelperText>{errors.brands}</FormHelperText>}
                </Box>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", mt: 3.5 }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ProductTypeFormModal
