import Parse from "parse"

const DistributionCenters = Parse.Object.extend("DistributionCenters")

export const getDistributionCentersById = async (hubId, includes=["*"], toJson = true) => {
  try {
      const hub = await new Parse.Query(DistributionCenters)
          .equalTo("objectId", hubId)
          .include(includes)
          .first()

      return toJson ? hub.toJSON() : hub
  }
  catch (e) {
      console.error("parseManager.distributionCenters.site.parseSiteManager.getDistributionCentersById error : ", e)
      return Promise.reject(e) 
  }
}