import React from "react"

const InformationLine = props => {
    const {
        label,
        value,
        classes
    } =  props

    return (
        <div className={classes.valueContainer}>
            <div className={classes.valueLabel}>{label}</div>
            <div className={classes.price}>{value}</div>
        </div>
    )
}

export default InformationLine