import PropTypes from "prop-types"
import React, { Fragment } from "react"
import moment from "moment"
import withStyles from "@mui/styles/withStyles"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import StarRate from "@mui/icons-material/StarRate"
import LinesEllipsis from "react-lines-ellipsis"
import { EiffelTowerIcon, EiffelTowerColors } from "../../icons/EiffelTowerIcon"
import { LunchbagIcon, LunchbagColors } from "../../icons/LunchbagIcon"
import PanoramaVerticalIcon from "@mui/icons-material/PanoramaVertical"
import { COLORS, KFC_BRANDS, roundCurrency } from "../../utils"
import CapitalShippingIcon from "../../icons/CapitalShippingIcon"
import CloudinaryImage from "../Image/CloudinaryImage"

const styles = {
    item: {
        boxSizing: "border-box",
        boxShadow: `0px 1px 1px ${ COLORS.DEFAULT_GREY }`,
        alignItems: "center",
        minHeight: 86,
        padding: "14px 10px",
    },
    itemCheckbox: {
        width: 12,
        height: 12
    },
    itemName: {
        fontSize: 13,
        marginBottom: 8,
        fontWeight: 500,
        letterSpacing: .1,
        opacity: 0.87,
        marginLeft: "10px"
    },
    itemRating: {
        fontSize: 11,
        lineHeight: 1.3,
        letterSpacing: .15,
        color: COLORS.LIGHT_GREY,
        display: "inline-flex",
        alignItems: "center",
        fontWeight: 500,
        "& > *": {
            flex: "0 0 auto"
        }
    },
    itemRatingIcon: {
        width: 18,
        height: 18,
        marginRight: 4
    },
    itemBrand: {
        fontSize: 0,
        color: "transparent",
        borderRadius: 100,
        display: "inline-block",
        width: 7,
        height: 7,
        marginLeft: "10px"
    },
    infoBloc: {
        marginLeft: 10,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    }
}

export const MealPlannerCartItem = props => {
    const {
        item={},
        checked=false,
        onChange,
        classes={},
        isWeekly = false
    } = props

    const labelId = `checkbox-list-secondary-label-${ item.itemId }`

    return (
        <ListItem button className={ classes.item } onClick={ onChange }>
            <ListItemIcon>
                <Checkbox
                    className={ classes.itemCheckbox }
                    edge="end"
                    onChange={ onChange }
                    checked={ checked }
                    inputProps={{ "aria-labelledby" : labelId }}
                    color="secondary"
                />
            </ListItemIcon>
            {
                item.image && item.image.publicId
                    ?
                        <CloudinaryImage width={86} imageId={ item.image.publicId } />
                    :
                        <img
                            width="86"
                            src="/img/noImageAvailabled.png"
                            alt="Non disponible"
                        />
            }
            <ListItemText
                id={ labelId }
                primary= {
                    <Typography className={ classes.itemName }>
                        <LinesEllipsis component="span" text={ item.uniqueCode + " - " + item.name } maxLine={ 2 } ellipsis="…" />
                    </Typography>
                }
                secondary= {
                    <Fragment>
                        <Typography className={ classes.itemRating }>
                            { item.rating ? <StarRate className={ classes.itemRatingIcon } /> : null}
                            { item.rating || null }
                            { item.rating ? <span>&emsp;|&emsp;</span> : null }
                            {
                                Array.isArray(item.itemBrand) && KFC_BRANDS.map((brand, i) => {
                                    if (item.itemBrand.includes(brand.name)) {
                                        return (
                                            <Fragment key={i}>
                                                <span
                                                    className={ classes.itemBrand }
                                                    style={{ backgroundColor: brand.color }}
                                                    title={brand.label}
                                                >
                                                    {brand.label}
                                                </span>
                                                &nbsp;
                                            </Fragment>
                                        )
                                    }

                                    return null
                                })
                            }
                        </Typography>
                        <div className={classes.infoBloc}>
                            { 
                               !isWeekly &&  item.dlc &&
                                    <div>
                                        DLC: {moment(item.dlc).format("DD/MM")}
                                    </div>
                            }
                            {
                                item.foodcost &&
                                    <div>
                                        Foodcost = {roundCurrency(item.foodcost)}€
                                    </div>
                            }
                        </div>
                    </Fragment>
                }
            />
            {!isWeekly && item.sendCapital && <EiffelTowerIcon color={EiffelTowerColors.BLACK} /> }
            {!isWeekly &&  item.nationalSend && <CapitalShippingIcon color={COLORS.BLACK} /> }
            {!isWeekly &&  item.lunchbag && <LunchbagIcon color={LunchbagColors.BLACK} /> }
            {!isWeekly && !item.smallRetail && <PanoramaVerticalIcon /> }
        </ListItem>
    )
}

MealPlannerCartItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        image: PropTypes.shape({
            publicId: PropTypes.string
        }),
        name: PropTypes.string,
        itemBrand: PropTypes.array,
        rating: PropTypes.number
    }),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    classes: PropTypes.object,
    isWeekly: PropTypes.bool
}

export default withStyles(styles)(MealPlannerCartItem)
