import React from "react"
import { makeStyles } from "@mui/styles"
import ProductionStepsSupervisionHeader from "./ProductionStepsSupervisionHeader"

const useStyles = makeStyles({
  root: {
    display: "flex",
    zIndex: 1000,
    maxHeight: "fit-content",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
  }
})

const ProductionStepsExecutionFixedHeader = ({  onBack }) => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ProductionStepsSupervisionHeader onBack={onBack} />
    </div>
  )

}

export default ProductionStepsExecutionFixedHeader