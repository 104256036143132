import React, { useMemo } from "react"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import { formatLotOrSecondaryDLCCard } from "../../utils/lotInventoryUtils"
import SelectableAlphabetCardFooterContent from "./SelectableAlphabetCardFooterContent"
import SelectableAlphabetCardContent from "./SelectableAlphabetCardContent"
import SelectableAlphabetCardSubFooterContent from "./SelectableAlphabetCardSubFooterContent"
import dayjs from "dayjs"

const _formatDocument = (document, collection, mode, currentDate) => {
    if (["Lot", "SecondaryDLC"].includes(collection)) {
        return formatLotOrSecondaryDLCCard(document, collection, mode, currentDate)
    }
    return document
}

const useStyles = makeStyles({
    noDisplay: {
        display: "none !important"
    },
    container: {
        minWidth: 166,
        maxWidth: 166,
        padding: "8px 8px 6px 8px",
        borderRadius: 10,
        boxShadow: `0 1px 3px ${COLORS.INVENTORY_BOX_SHADOW}`,
        border: "1px solid transparent",
        display: "flex",
        flexDirection: "column"
    },
    substituteContainer: {
        minWidth: 166,
        maxWidth: 166,
        padding: "8px 8px 6px 8px",
        borderRadius: 10,
        boxShadow: `0 1px 3px ${COLORS.INVENTORY_BOX_SHADOW}`,
        border: "1.5px solid #7900D9",
        display: "flex",
        flexDirection: "column"
    },
    defaultContainerBackground: {
        backgroundColor: COLORS.WHITE,
    },
    hypotheticalContainerBackground: {
        backgroundColor: "#F0F0F0"
    },
    containerWarning: {
        border: `1px solid ${COLORS.RED_INVENTORY_WARNING}`
    },
    containerSelected: {
        background: COLORS.PRIMARY_COLOR,
        "& *, * > p": {
            color: COLORS.WHITE
        }
    },
    containerGrey: {
        background: COLORS.SITE_BORDER
    },
    containerGreen: {
        background: COLORS.GREEN_LOT_INVENTORY,
        "& *, * > p": {
            color: COLORS.GREEN_LOT_INVENTORY_TEXT
        }
    },
    containerUpdated: {
        background: COLORS.GREEN_LOT_INVENTORY,
        "& *, * > p": {
            color: COLORS.GREEN_LOT_INVENTORY_TEXT
        }
    },
    title: {
        textTransform: "uppercase",
        fontSize: 10,
        lineHeight: "13px",
        fontWeight: 700,
        marginTop: 0,
    },
    footerRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 6,
        "& > p": {
            margin: 0
        }
    },
    subTag: {
        color: "#7900D9"
    }
})


const SelectableAlphabetCard = ({
    document,
    collection = "Lot",
    currentDate,
    dlcWarningDate,
    mode = null,
    isSubstitute = false,
    selected,
    onSelect
}) => {

    const classes = useStyles()

    const formattedDocument = useMemo(() => {
        return _formatDocument(document, collection, mode, currentDate)
    }, [document])

    const getContainerClassName = (document) => {
        const dlc = document.dlcTimestamp
        if (!document.hasAlreadyQuantityOutput && document.quantity === 0 && !document.hypothetical) {
            return classes.noDisplay
        }

        if (document.hasAlreadyQuantityOutput && document.quantity === 0) {
            return classes.containerGreen
        }

        if (selected) {
            return classes.containerSelected
        }

        if (document.isGrey) {
            return classes.containerGrey
        }

        if (document.isUpdated && !document.hypothetical) {
            return classes.containerUpdated
        }

        if (dayjs(dlc).startOf("day").valueOf() < dayjs(currentDate).startOf("day").valueOf()) { 
            return classes.containerWarning
        }

        return classes.container
    }

    const getContainerBackgroundColor = (document) => {
        if (document.hypothetical) {
            return classes.hypotheticalContainerBackground
        }
        return classes.defaultContainerBackground
    }

    return (
        <div
            id={`${collection.toLowerCase()}-${document.objectId}`}
            // order is important for styles overriding (mainly background color)
            className={clsx(isSubstitute && mode === "OUTPUT" ? classes.substituteContainer : classes.container, getContainerBackgroundColor(formattedDocument), getContainerClassName(formattedDocument))}
            onClick={() => onSelect(document)}
        >
            <h3 className={classes.title}>
                {isSubstitute && mode === "OUTPUT" ? <span style={{ color: "#7900D9" }}>SUB -</span> : <span></span>}
                {formattedDocument.name}
            </h3>
            <SelectableAlphabetCardContent
                document={formattedDocument}
                collection={collection}
                mode={mode}
                selected={selected}
                currentDate={currentDate}
                dlcWarningDate={dlcWarningDate}
            />

            <SelectableAlphabetCardFooterContent
                document={formattedDocument}
                collection={collection}
                mode={mode}
                footerClassName={classes.footerRow}
                selected={selected}
                currentDate={currentDate}
            />
            <SelectableAlphabetCardSubFooterContent
                document={formattedDocument}
                selected={selected}
                collection={collection}
            />
        </div>
    )
}

export default SelectableAlphabetCard