import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import Modal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"
import { COLORS, forEachBrand } from "../../utils"
import _cloneDeep from "lodash/cloneDeep"
import { formatSplitModalData } from "../../utils/planning"
import TextField from "@mui/material/TextField"
import moment from "moment"
import Button from "@mui/material/Button"

const styles = {
    splitProductionModalContainer: {
        backgroundColor: COLORS.WHITE,
        width: 700,
        outline: "none",
        margin: "32px auto",
        top: "auto",
        position: "relative",
        borderRadius: 3,
        color: COLORS.BLACK,
        padding: 10,
        maxHeight: "93vh",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
    },
    closeContainer: {
        textAlign: "right"
    },
    closeIcon: {
        cursor: "pointer"
    },
    title: {
        fontSize: 20,
        textAlign: "center"
    },
    splitProductionModalInfos: {
        margin: "40px 160px 40px 160px"
    },
    saleDateRow: {
        display: "flex",
        marginTop: 25,
        marginBottom: 25
    },
    saleDate: {
        marginTop: 19,
        fontWeight: "bold"
    },
    inputsContainer: {
        marginLeft: "auto"
    },
    expectedProductionInput: {
        marginRight: 10,
        width: 70
    },
    expectedProductionLabel: {
        height: 10,
        width: 20,
        fontSize: 18
    },
    expectedProductionInputProps: {
        height: 10,
        fontSize: 18,
        width: 70
    },
    planningBrand: {
        height: 7,
        width: 7,
        borderRadius: "50%",
        marginLeft: 2
    },
    commercialName: {
        textAlign: "center",
        marginTop: 10
    },
    actionContainer: {
        textAlign: "center"
    },
    confirmBtn: {
        marginBottom: 10,
        color: COLORS.WHITE
    },
    reusableExpectedProduction: {
        background: "url(/img/recycle.svg) no-repeat center",
        width: 10,
        height: 10,
        marginRight: 7,
        marginLeft: 1,
        marginTop: 25,
      },
}

forEachBrand(brand => {
    styles[`expectedProduction${brand.name}Brand`] = {
        composes: "$planningBrand",
        backgroundColor: brand.color,
        marginRight: 10,
        marginTop: 25
    }
})

export class PlanningSplitProductionModal extends PureComponent {

    constructor(props){
        super(props)

        const { currentCard } = this.props

        const formatedCurrentCard = formatSplitModalData(_cloneDeep(currentCard))

        this.state = {
            data: formatedCurrentCard
        }
    }

    updateYesterdayExpectedProduction = (event, itemId) => {
        const { data } = this.state

        const copy = _cloneDeep(data)
        const current = copy.find(el => el.id === itemId)
        const newValue = event.target.value

        if (newValue === ""){
            current.todayExpectedProduction = current.initialExpectedProduction - current.tomorrowExpectedProduction
            current.yesterdayExpectedProduction = newValue
        }
        else {
            const floatNewValue = parseFloat(newValue)
            const newTodayExpectedProduction = current.initialExpectedProduction - floatNewValue - current.tomorrowExpectedProduction
            if ((floatNewValue + current.tomorrowExpectedProduction + newTodayExpectedProduction) === current.initialExpectedProduction && newTodayExpectedProduction > 0){
                current.yesterdayExpectedProduction = floatNewValue
                current.todayExpectedProduction = newTodayExpectedProduction
            }
        }

        this.setState({
            data: copy
        })
    }

    resetYesterdayExpectedProduction = (itemId) => {
        const { data } = this.state

        const copy = _cloneDeep(data)
        const current = copy.find(el => el.id === itemId)
        if (current.yesterdayExpectedProduction === ""){
            current.yesterdayExpectedProduction = 0
        }

        this.setState({
            data: copy
        })
    }

    updateTomorrowExpectedProduction = (event, itemId) => {
        const { data } = this.state

        const copy = _cloneDeep(data)
        const current = copy.find(el => el.id === itemId)
        const newValue = event.target.value

        if (newValue === ""){
            current.todayExpectedProduction = current.initialExpectedProduction - current.yesterdayExpectedProduction
            current.tomorrowExpectedProduction = newValue
        }
        else {
            const floatNewValue = parseFloat(newValue)
            const newTodayExpectedProduction = current.initialExpectedProduction - floatNewValue - current.yesterdayExpectedProduction
            if ((floatNewValue + current.yesterdayExpectedProduction + newTodayExpectedProduction) === current.initialExpectedProduction && newTodayExpectedProduction > 0){
                current.tomorrowExpectedProduction = floatNewValue
                current.todayExpectedProduction = newTodayExpectedProduction
            }
        }

        this.setState({
            data: copy
        })
    }

    resetTomorrowExpectedProduction = (itemId) => {
        const { data } = this.state

        const copy = _cloneDeep(data)
        const current = copy.find(el => el.id === itemId)
        if (current.tomorrowExpectedProduction === ""){
            current.tomorrowExpectedProduction = 0
        }

        this.setState({
            data: copy
        })
    }

    confirmSplit = () => {
        const { saveSplitProduction } = this.props
        const { data } = this.state

        saveSplitProduction(data)
    }

    render(){
        const {
            classes,
            closeModal,
            currentCard
        } = this.props

        const {
            data
        } = this.state

        return (
            <Modal open={true}>
                <div className={classes.splitProductionModalContainer}>
                    <div className={classes.closeContainer}>
                        <CloseIcon
                            className={classes.closeIcon}
                            onClick={() => {closeModal()}}
                        />
                    </div>
                    <div className={classes.title}>
                        Comment souhaitez-vous diviser cette production ?
                    </div>
                    <div className={classes.commercialName}>
                        { currentCard.commercialName }
                    </div>
                    <div className={classes.splitProductionModalInfos}>
                        {
                            data.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div className={classes.saleDateRow}>
                                            {
                                                item.isReusable
                                                    ? <div className={classes.reusableExpectedProduction}/>
                                                    : <div className={classes[`expectedProduction${item.brand}Brand`]} />
                                            }
                                            <div className={classes.saleDate}>
                                                { moment.utc(item.saleDate).format("DD/MM") }
                                            </div>
                                            <div className={classes.inputsContainer}>
                                                <TextField
                                                    variant="standard"
                                                    type="number"
                                                    value={item.yesterdayExpectedProduction}
                                                    className={classes.expectedProductionInput}
                                                    label={moment.utc(item.productionDate).add(-1, "days").startOf("day").format("DD/MM")}
                                                    disabled={item.disableYesterday}
                                                    inputProps={{
                                                        className: classes.expectedProductionInputProps,
                                                        inputprops: {min: 0}
                                                    }}
                                                    onChange={(event) => {
                                                        this.updateYesterdayExpectedProduction(event, item.id)
                                                    }}
                                                    onBlur={() => {
                                                        this.resetYesterdayExpectedProduction(item.id)
                                                    }}
                                                />
                                                <TextField
                                                    variant="standard"
                                                    type="number"
                                                    value={item.todayExpectedProduction}
                                                    className={classes.expectedProductionInput}
                                                    label={moment.utc(item.productionDate).startOf("day").format("DD/MM")}
                                                    disabled={true}
                                                    inputProps={{
                                                        className: classes.expectedProductionInputProps,
                                                        inputprops: {min: 0}
                                                    }}
                                                />
                                                <TextField
                                                    variant="standard"
                                                    type="number"
                                                    value={item.tomorrowExpectedProduction}
                                                    className={classes.expectedProductionInput}
                                                    label={moment.utc(item.productionDate).add(1, "days").startOf("day").format("DD/MM")}
                                                    disabled={item.disableTomorrow}
                                                    inputProps={{
                                                        className: classes.expectedProductionInputProps,
                                                        inputprops: {min: 0}
                                                    }}
                                                    onChange={(event) => {
                                                        this.updateTomorrowExpectedProduction(event, item.id)
                                                    }}
                                                    onBlur={() => {
                                                        this.resetTomorrowExpectedProduction(item.id)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={classes.actionContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmBtn}
                            onClick={() => {this.confirmSplit()}}
                        >
                            VALIDER
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withStyles(styles)(PlanningSplitProductionModal)
