import Parse from "parse"
import moment from "moment"
import {
	actionWithLoader,
	getReplacingPath,
	haveAccessRight,
	onEnter,
	push
} from "../Utils/utils"
import {
	getDataServerUrl,
	KFC_BRANDS
} from "../../utils"
import { createOrLockStockLocker } from "../../parseManager/reception/parseLockerManager"
import {
	getDispatchLocker,
	freeLocker
} from "../../parseManager/dispatch/parseLockerManager"
import { getDistributionCentersByBrand } from "../../parseManager/planning/parsePlanningDashboardManager"
import {
	getInventoryProductsStock,
	getProductStockById,
	getReceptionProductsStock,
	updateProductStockInventoryStatus,
	updateProductStockStatus,
	updateProductStockVolume,
	updateInitialVolumeProductStocks,
	downloadProductsStocksInventory
} from "../../parseManager/reception/parseProductStockManager"
import {
	updateProductStockFCVolume
} from "../../api/fcStock"
import { getLostData } from "../../utils/receptionUtils"
import { axiosCall } from "../../utils/axiosUtils"
import { loadSubcontractorsProductsByBrand } from "../Subcontractor/subcontractorsProducts"
import { exportCSVFile } from "../Products/SearchAdvanced"
import { MANAGEMENT_MODES_ENUM, WITH_MANAGEMENT_MODE_FILTER } from "../../utils/productTypesUtils"
import { getProductTypeOptions, loadProductTypeOptions } from "../ProductTypes/productTypes"

export function onEnterReceptionFilter(store) {
	return onEnter({
		store,
		actionThunk: loadReceptionFilter,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight({ canReadProductsStock: true })
	})
}

export function loadReceptionFilter() {
	return actionWithLoader(async (dispatch) => {
		const brand = KFC_BRANDS[0].name
		const date = moment.utc().startOf("day").valueOf()
		const hubs = await getDistributionCentersByBrand(brand)
		const filters = { ...WITH_MANAGEMENT_MODE_FILTER, brands: [brand] }
		dispatch(loadProductTypeOptions(filters))

		dispatch({
			type: "RECEPTION_FILTER_LOADED",
			date: date,
			brand: brand,
			hubs: hubs
		})
	})
}

export function loadReception(date, brand, hub) {
	return actionWithLoader(async (dispatch) => {
		try {
			const result = await getReceptionProductsStock(date, brand, hub)

			dispatch({
				type: "RECEPTION_LOADED",
				date,
				brand,
				hub,
				originalReceptionData: result
			})

			if (result.length > 0) {
				dispatch(showReception())
			} else {
				dispatch({
					type: "RECEPTION_OPEN_SNACKBAR",
					receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Pas de résultat" }
				})
			}
		}
		catch (e) {
			dispatch({
				type: "RECEPTION_OPEN_SNACKBAR",
				receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur en récupérant les données" }
			})
		}
	})
}

export const downloadProductStocksInventoryCsv = () => {
	return actionWithLoader(async (dispatch) => {
		const productStockEventsCsvHeader = {
			hub: "Hub",
			productType: "Type",
			uniqueCode: "Code",
			commercialTitle: "Nom du produit",
			isReusable: "Packaging réutilisable",
			foodCost: "FoodCost",
			dlc: "DLC",
			volume: "Quantité",
			lastInventoryEventDate: "Date du dernier inventaire"
		}

		const rows = await downloadProductsStocksInventory()
		dispatch(exportCSVFile(productStockEventsCsvHeader, rows, "productStocksInventory"))
	})
}


export function onEnterInventoryFilter(store) {
	return onEnter({
		store,
		actionThunk: loadInventoryFilter,
		getReplacingPath: getReplacingPath({ needUser: true }),
		haveAccessRight: haveAccessRight({ canReadProductsStock: true })
	})
}

export function loadInventoryFilter() {
	return actionWithLoader(async (dispatch) => {
		const brand = KFC_BRANDS[0].name
		const startDate = moment.utc().startOf("day").valueOf()
		const hubs = await getDistributionCentersByBrand(brand)
		const filters = { managementMode: [MANAGEMENT_MODES_ENUM.none, MANAGEMENT_MODES_ENUM.onSite], brands: [brand] }
		const productTypeOptions = await getProductTypeOptions(filters)
		const productTypeNames = productTypeOptions.map(productType => productType.value)

		dispatch({
			type: "INVENTORY_FILTER_LOADED",
			date: startDate,
			brand: brand,
			hubs: hubs
		})
		dispatch(loadSubcontractorsProductsByBrand("FOODCHERI", productTypeNames))
		dispatch({
			type: "PRODUCT_TYPE_OPTIONS_LOADED",
			productTypeOptions,
		})
	})
}

export function loadInventory(brand, hub) {
	return actionWithLoader(async (dispatch) => {
		try {
			const result = await getInventoryProductsStock(brand, hub)

			dispatch({
				type: "INVENTORY_LOADED",
				brand,
				hub,
				originalInventoryData: result
			})

			if (result.length > 0) {
				dispatch(showInventory())
			} else {
				dispatch({
					type: "INVENTORY_OPEN_SNACKBAR",
					inventorySnackBar: { open: true, duration: 5000, type: "success", message: "Pas de résultat" }
				})
			}
		} catch (e) {
			dispatch({
				type: "INVENTORY_OPEN_SNACKBAR",
				inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Erreur en récupérant les données" }
			})
		}
	})
}

export function updateProductStock(productStock, volume, lost = null, type) {
	return actionWithLoader(async (dispatch) => {
		try {
			let data, copy
			const date = moment.utc().valueOf()

			if (type === "RECEPTION") {
				data = [
					{
						type: "RECEPTION",
						user: Parse.User.current(),
						date: date,
						data: {
							volume: parseInt(volume),
						}
					}]
			}

			if (type === "INVENTORY") {
				data = [
					{
						type: "INVENTORY",
						user: Parse.User.current(),
						date: date,
						data: {
							oldVolume: parseInt(productStock.volume),
							newVolume: parseInt(volume)
						}
					}]
			}

			if (type === "LOSS") {
				const dataLost = getLostData(lost, date)
				data = [dataLost]
			}

			if (lost && type === "RECEPTION") {
				const dataLost = getLostData(lost, date)
				data = [...data, dataLost]
			}

			if (type === "LOSS") copy = await updateProductStockVolume(productStock.objectId, productStock.volume - volume, data, type)
			if (type !== "LOSS") copy = await updateProductStockVolume(productStock.objectId, volume, data, type)

			const result = await updateProductStockFCVolume(productStock.objectId, type, parseInt(volume))

			// necessary to define where the casse is from
			if (lost && lost.isInventory) type = "INVENTORY"

			if (!result) {
				if (type === "INVENTORY") {
					dispatch({
						type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
						inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour du volume sur FC" }
					})
				}
				else {
					dispatch({
						type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
						receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour du volume sur FC" }
					})
				}
			}

			await freeLocker(copy.objectId, "productStock")

			if (type === "INVENTORY") {
				return dispatch({
					type: "INVENTORY_SELECTED_PRODUCT_STOCK_UPDATED",
					selectedProduct: copy,
					inventorySnackBar: { open: true, duration: 5000, type: "success", message: "La mise à jour a été enregistrée" }
				})
			}

			return dispatch({
				type: "RECEPTION_SELECTED_PRODUCT_STOCK_UPDATED",
				selectedProduct: copy,
				receptionSnackBar: { open: true, duration: 5000, type: "success", message: "La mise à jour a été enregistrée" }
			})
		} catch (e) {
			// necessary to define where the casse is from
			if (lost && lost.isInventory) type = "INVENTORY"

			if (type === "INVENTORY") {
				dispatch({
					type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
					inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			} else {
				dispatch({
					type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
					receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			}
		}
	})
}

export function filterByStatus(status, isInventory = false) {
	if (isInventory) {
		return actionWithLoader(async (dispatch) => {
			dispatch({
				type: "INVENTORY_FILTER_STATUS_SET",
				inventoryStatus: status
			})
		})
	}

	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_FILTER_STATUS_SET",
			receptionStatus: status
		})
	})
}

export function filterByNameOrCode(name, isInventory = false) {
	if (isInventory) {
		return actionWithLoader(async (dispatch) => {
			dispatch({
				type: "INVENTORY_FILTER_NAME_OR_CODE_SET",
				searchFilter: name
			})
		})
	}

	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_FILTER_NAME_OR_CODE_SET",
			searchFilter: name
		})
	})
}

export function resetStatus(productStock, isInventory = false) {
	return actionWithLoader(async (dispatch) => {
		try {
			let copy = await getProductStockById(productStock.objectId)

			copy = (isInventory) ?
				await updateProductStockInventoryStatus(copy, true) :
				await updateProductStockStatus(copy, true)

			await freeLocker(productStock.objectId, "productStock")

			if (isInventory) {
				return dispatch({
					type: "INVENTORY_SELECTED_PRODUCT_STOCK_UPDATED_STATUS",
					selectedProduct: copy,
					noDisplay: false,
					reset: true
				})
			}

			return dispatch({
				type: "RECEPTION_SELECTED_PRODUCT_STOCK_UPDATED_STATUS",
				selectedProduct: copy,
				noDisplay: false,
				reset: true
			})
		}
		catch (e) {
			if (isInventory) {
				dispatch({
					type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
					noDisplay: false,
					inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			} else {
				dispatch({
					type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
					noDisplay: false,
					receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			}
		}
	})
}

export function updateStatus(productStock, isInventory = false) {
	return actionWithLoader(async (dispatch) => {
		try {
			let copy = await getProductStockById(productStock.objectId)

			// for inventory
			if (isInventory) {
				// set to in progress
				if (copy.get("inventoryStatus") === "TODO") {
					copy = await updateProductStockInventoryStatus(copy)
				} else {
					const stockLocker = await getDispatchLocker(productStock.objectId, "productStock")

					//Cancel action if locker exist
					if (stockLocker) {
						dispatch({
							type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
							noDisplay: true,
							inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Un inventaire est en train d'être fait sur ce produit" }
						})

						return
					}

					copy = copy.toJSON()
				}

				await createOrLockStockLocker(productStock.objectId)
				return dispatch({
					type: "INVENTORY_SELECTED_PRODUCT_STOCK_UPDATED_STATUS",
					selectedProduct: copy,
					noDisplay: false,
					reset: false
				})
			} else {
				// set to in progress
				if (copy.get("receptionStatus") === "TODO") {
					copy = await updateProductStockStatus(copy)
				} else {
					const stockLocker = await getDispatchLocker(productStock.objectId, "productStock")

					//Cancel action if locker exist
					if (stockLocker) {
						dispatch({
							type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
							noDisplay: true,
							receptionSnackBar: { open: true, duration: 5000, type: "error", message: "une réception est en train d’être faite sur ce produit" }
						})

						return
					}

					copy = copy.toJSON()
				}

				await createOrLockStockLocker(productStock.objectId)
				return dispatch({
					type: "RECEPTION_SELECTED_PRODUCT_STOCK_UPDATED_STATUS",
					selectedProduct: copy,
					noDisplay: false,
					reset: false
				})
			}
		} catch (e) {
			if (isInventory) {
				dispatch({
					type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
					noDisplay: true,
					inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			} else {
				dispatch({
					type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
					noDisplay: true,
					receptionSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors de la mise à jour" }
				})
			}
		}
	})
}

export function closeReceptionSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_CLOSE_SNACKBAR",
			receptionSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

export function closeReceptionDetailSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "RECEPTION_CLOSE_DETAIL_SNACKBAR",
			receptionSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

// share the same store
export function showReception() {
	return push("/reception")
}

export function showReceptionInterface() {
	return push("/receptionFilter")
}

export function closeInventorySnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "INVENTORY_CLOSE_SNACKBAR",
			inventorySnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

export function closeInventoryDetailSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "INVENTORY_CLOSE_DETAIL_SNACKBAR",
			inventorySnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

export function showErrors(error, isInventory) {
	return actionWithLoader(async (dispatch) => {
		if (isInventory) {
			dispatch({
				type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
				inventorySnackBar: { open: true, duration: 5000, type: "error", message: error }
			})
		} else {
			dispatch({
				type: "RECEPTION_OPEN_DETAIL_SNACKBAR",
				receptionSnackBar: { open: true, duration: 5000, type: "error", message: error }
			})
		}
	})
}

export function validateAddDisplay(productSelected) {
	return actionWithLoader(async (dispatch) => {
		const productStocks = await updateInitialVolumeProductStocks(productSelected)

		dispatch({
			type: "RECEPTION_ADD_PRODUCTS",
			receptionSnackBar: { open: true, duration: 5000, type: "success", message: "Produits ajoutés" },
			productStocks
		})
	})
}

export function brandChange(brand) {
	return actionWithLoader(async (dispatch) => {
		const hubs = await getDistributionCentersByBrand(brand)

		dispatch({
			type: "FILTER_BRAND_UPDATED",
			brand,
			hubs
		})
	})
}

export function sendProductPlacement(selectedHub) {
	return actionWithLoader(async (dispatch) => {
		const user = Parse.User.current() ? Parse.User.current().get("email") : null

		if (user) {
			const url = `${getDataServerUrl()}/planigramme?sdate=${moment().format("YYYY-MM-DD")}&hubs=[${selectedHub[0].objectId}]&dest=${user}`

			const result = await axiosCall("GET", url, null, { "Content-Type": "application/json" })

			if (result.status === 200) {
				dispatch({
					type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
					inventorySnackBar: { open: true, duration: 5000, type: "success", message: "Planigramme envoyé sur votre email" }
				})
			}
			else {
				dispatch({
					type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
					inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Echec de l'envoie du Planigramme" }
				})
			}
		}
		else {
			dispatch({
				type: "INVENTORY_OPEN_DETAIL_SNACKBAR",
				inventorySnackBar: { open: true, duration: 5000, type: "error", message: "Utilisateur non trouvé" }
			})
		}
	})
}

// share the same store
export function showInventory() {
	return push("/inventory")
}

export function showInventoryInterface() {
	return push("/inventoryFilter")
}
