import React from "react"
import withStyles from "@mui/styles/withStyles"
import {
    COLORS
} from "../../../utils"
import PackagingPlanningCard from "../PlanningCard/PackagingPlanningCard"
import { pxTOvw } from "../../../utils/planning"
import DroppableWrapper from "../../../components/Planning/DroppableWrapper"
import DraggableWrapper from "../../../components/Planning/DraggableWrapper"

const styles = {
    planningColumnContainerDefault: {
        width: pxTOvw((window.innerWidth - 118.4) / 7),
        backgroundColor: COLORS.LIGHT_GREY,
        borderLeft: "1px dashed #E7E7E7",
        borderRight: "1px dashed #E7E7E7",
        paddingLeft: 5,
        paddingRight: 5
    },
    planningColumnContainerValid: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.ENABLED_DRAG
    },
    planningColumnContainerError: {
        composes: "$planningColumnContainerDefault",
        backgroundColor: COLORS.DISABLED_DRAG
    },
    cardContainer: {
        backgroundColor: COLORS.WHITE,
        marginTop: 5,
        marginBottom: 5,
        borderRadius: 3,
        cursor: "pointer",
        position: "relative",
        boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.25)"
    }
}

export const PackagingPlanningBody = (props) => {
    const {
        data,
        isIntern,
        section,
        classes,
        currentCard,
        saveProd,
        printPackagingSingleCard,
        openSplitModal,
        openRecipeLabelModal,
        canPrint,
        openProductInfo,
        setAnyFocused,
        isDragAndDroppable,
        canPrintLabel,
        canInputVolume
    } = props

    const getColumnClasses = (columnId) => {
        if (currentCard){
            const timeslots = currentCard.currentAvailablePackagingDates
            const column = data.find(el => el.id === columnId)
            if (timeslots.includes(column.date)){
                return classes.planningColumnContainerValid
            }
            else {
                return classes.planningColumnContainerError
            }
        }

        return classes.planningColumnContainerDefault
    }

    return (
        data.map((item) => {
            const cards = item.cards[section.type] ? item.cards[section.type].filter(el => el.itemType === isIntern) : []

            return (
                <DroppableWrapper
                    key={item.id}
                    droppableId={item.id}
                    isDroppable={isDragAndDroppable}
                    columnClasses={getColumnClasses(item.id)}
                >
                    {
                        cards.map((card, index) => {
                            return (
                                isDragAndDroppable ?
                                    (
                                        <DraggableWrapper
                                            key={card.id}
                                            draggableId={card.id}
                                            index={index}
                                            isDraggable={isDragAndDroppable}
                                            draggableClass={classes.cardContainer}
                                        >
                                            <PackagingPlanningCard
                                                card={card}
                                                saveProd={saveProd}
                                                printPackagingSingleCard={printPackagingSingleCard}
                                                openRecipeLabelModal={openRecipeLabelModal}
                                                canPrint={canPrint}
                                                openSplitModal={openSplitModal}
                                                openProductInfo={openProductInfo}
                                                setAnyFocused={setAnyFocused}
                                                canPrintLabel={canPrintLabel}
                                                canInputVolume={canInputVolume}
                                            />
                                        </DraggableWrapper>
                                    )
                                    :
                                    (
                                        <div
                                            className={classes.cardContainer}
                                            key={index}
                                        >
                                            <PackagingPlanningCard
                                                card={card}
                                                saveProd={saveProd}
                                                printPackagingSingleCard={printPackagingSingleCard}
                                                openRecipeLabelModal={openRecipeLabelModal}
                                                canPrint={canPrint}
                                                openSplitModal={openSplitModal}
                                                openProductInfo={openProductInfo}
                                                setAnyFocused={setAnyFocused}
                                                canPrintLabel={canPrintLabel}
                                                canInputVolume={canInputVolume}
                                            />
                                        </div>
                                    )
                            )
                        })
                    }
                </DroppableWrapper>
            )
        })
    )
}

export default withStyles(styles)(PackagingPlanningBody)
