import React, { PureComponent } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { lighten } from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import PrintIcon from "@mui/icons-material/Print"
import OrderIcon from "@mui/icons-material/GetApp"
import AddIcon from "@mui/icons-material/Add"
import { confirm } from "../../react-await-dialog/react-await-dialog"
import { COLORS } from "../../../utils"

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
  printKitchen: {
    color: COLORS.PRIMARY_COLOR,
  },
  printPackaging: {
    color: COLORS.SECONDARY_COLOR,
  },
})

class ProductionEnhancedTableToolbar extends PureComponent {
  _deleteProduction = async () => {
    const { selected } = this.props
    const message =
      selected.length === 1
        ? "Voulez-vous supprimer cette journée de production ?"
        : "Voulez-vous supprimer ces journées de productions ?"
    if (
      await confirm({
        title: "Confirm",
        message: message,
        confirmText: "SUPPRIMER",
      })
    ) {
      const { selected, deleteProduction, onDeleteItem, productions } =
        this.props
      const productionsToDelete = productions.filter((production) => {
        if (selected.includes(production.id)) {
          return production.id
        }
        return null
      })
      for (const i in productionsToDelete) {
        onDeleteItem()
        await deleteProduction(productionsToDelete[i])
      }
    }
  }

  _printProduction = () => {
    const { selected, printProduction, productions } = this.props
    const productionsToPrint = productions.filter((production) => {
      if (selected.includes(production.id)) {
        return production.id
      }
      return null
    })
    for (const idx in productionsToPrint) {
      const production = productionsToPrint[idx]
      printProduction(production)
    }
  }

  _downloadProductionReport = () => {
    const { selected, downloadProductionReport, productions } = this.props
    const productionsToDownload = productions.filter((production) => {
      if (selected.includes(production.id)) {
        return production.id
      }
      return null
    })
    downloadProductionReport(productionsToDownload)
  }

  _createProduction = () => {
    const { showProduction } = this.props
    showProduction()
  }

  render() {
    const { numSelected, classes } = this.props
    const text =
      numSelected > 1
        ? "Fiches de production sélectionnées"
        : "Fiche de production sélectionnée"

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {text}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Fiche de productions
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        {numSelected > 0 ? (
          <div className="flexRow">
            <div className={classes.actions}>
              <Tooltip title="Télécharger rapport" disableInteractive>
                <IconButton
                  aria-label="Télécharger rapport"
                  onClick={this._downloadProductionReport}
                  size="large"
                >
                  <OrderIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.actions}>
              <Tooltip title="Imprimer fiches" disableInteractive>
                <IconButton
                  aria-label="Imprimer fiche barquettage"
                  onClick={this._printProduction}
                  size="large"
                >
                  <PrintIcon className={classes.printPackaging} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.actions}>
              <Tooltip title="Supprimer" disableInteractive>
                <IconButton
                  aria-label="Supprimer"
                  onClick={this._deleteProduction}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className={classes.actions}>
            <Tooltip title="Créer" disableInteractive>
              <IconButton
                aria-label="Créer"
                onClick={this._createProduction}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    )
  }
}

ProductionEnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
}

ProductionEnhancedTableToolbar = withStyles(toolbarStyles)(
  ProductionEnhancedTableToolbar
)

export default ProductionEnhancedTableToolbar
