import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { FormControl } from "@mui/material"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import {
    createResourcesProductTag,
    showProductTagRead,
    showProductsTagsList
} from "../../../../../actions/ProductsTags/ProductsTags"
import { productTagValidator } from "../../../../../utils/validators"
import ReduxSelectField from "../../../../../components/form/ReduxSelectField"
import ReduxTextField from "../../../../../components/form/ReduxTextField"
import { getProductTag } from "../../../../../reducers/ProductsTags/ProductsTags"
import { recipeFormstyles } from "../../../../../styles"

export class ProductTagForm extends PureComponent {

    _onClose = () => {
        const {
            showProductsTagsList,
            showProductTagRead,
            productTag,
            location
        } = this.props

        if (location.state && location.state.returnPath === "edit"){
            showProductTagRead(productTag.objectId)
        }
        else {
            showProductsTagsList()
        }
    }

    renderBase = (arr) => {
        return arr.map(elem =>
            <MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>
        )
    }

    renderTypes = () => {
        const { types } = this.props

        return this.renderBase(types)
    }

    render() {
        const {
            classes,
            handleSubmit,
            createResourcesProductTag,
            location
        } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.closeWindow}>
                    <IconButton aria-label="Fermer" onClick={this._onClose} size="large">
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={classes.formContainer}>
                    <form onSubmit={handleSubmit(createResourcesProductTag, location)}>
                        <div className="flexRow">
                            <FormControl margin="normal" classes={{root: classes.formControlMax400}} required>
                                <Field
                                    name="name"
                                    component={ReduxTextField}
                                    label="Nom"
                                />
                            </FormControl>
                            <FormControl margin="normal" classes={{root: classes.statusSelect}} required>
                                <ReduxSelectField
                                    name="type"
                                    label="Type"
                                    placeholder="Type"
                                >
                                    {this.renderTypes()}
                                </ReduxSelectField>
                            </FormControl>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                classes={{root: classes.actionButton}}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const ReduxProductTagForm = reduxForm({
    form: "productTag",
    enableReinitialize: false,
    validate: productTagValidator
})(ProductTagForm)

const StyledProductTagForm = withStyles(recipeFormstyles)(ReduxProductTagForm)

export default connect((state, props) => {
    const {params: {id}} = props
    const productTag = id ? getProductTag(state, id) : null
    const productTagFormValues = productTag !== null ? state.form.productTag ? state.form.productTag.values : undefined : undefined

    return {
        initialValues: {...productTag},
        productTagFormValues,
        productTag: productTag,
        types: state.productsTags.types
    }
}, {
    showProductsTagsList,
    showProductTagRead,
    createResourcesProductTag
})(StyledProductTagForm)
