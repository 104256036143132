import { getServerUrl } from "../../utils"

export function printLabel(data) {
    _printLabel(data)
}

export function asyncPrintLabel(data) {
    return async dispatch => {
        _printLabel(data)
        dispatch({
            type: "PRINT_LABEL"
        })
    }
}

function _printLabel(data) {
    if (data
        && data.color
        && data.template
        && data.dlc
        && data.lotNumber
        && data.type
        && data.brand
    ) {
        let url = getServerUrl() + "/labels/" +
            data.productId + "/_print?color=" + encodeURIComponent(data.color) +
            "&template=" + encodeURIComponent(data.template) +
            "&dlc=" + encodeURIComponent(data.dlc) +
            "&lotNumber=" + encodeURIComponent(data.lotNumber) +
            "&type=" + encodeURIComponent(data.type) +
            "&brand=" + encodeURIComponent(data.brand)

        if (data.hideDlc !== undefined) url += "&hideDlc=" + encodeURIComponent(data.hideDlc)
        if (data.fromPackagingPlanning !== undefined) url += "&fromPackagingPlanning=" + encodeURIComponent(data.fromPackagingPlanning)
        if (data.dlcTimestamp !== undefined) url += "&dlcTimestamp=" + encodeURIComponent(data.dlcTimestamp)

        window.open(url)
    }
}

