import React, { useEffect, useState } from "react"
import IconButton from "@mui/material/IconButton"
import CalendarToday from "@mui/icons-material/CalendarToday"
import BreakIcon from "../../../icons/BreakIcon"
import { Typography, Stack } from "@mui/material"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import TemperatureIcon from "@mui/icons-material/AcUnit"
import NotPresentedIcon from "@mui/icons-material/Clear"
import LateDeliveryIcon from "@mui/icons-material/Restore"
import MissingDocumentIcon from "@mui/icons-material/Description"
import TruckCleanliness from "@mui/icons-material/LocalShipping"
import { COLORS } from "../../../utils"
import { creditNotesReasons } from "../../../utils/creditNoteUtils"
import makeStyles from "@mui/styles/makeStyles"
import CreditNoteCardReason from "./CreditNoteCardReason"

const styles = makeStyles(() => ({
	container: {
		display: "flex",
		justifyContent: "center"
	},
	buttonContainer: {
		margin: "(0, 10px)"
	},
	iconButton: {
		color: COLORS.GREY_FLAW_ICON,
		padding: 0,
		flex: 1,
		width: 32,
		height: 32,
		"& svg": {
			width: 32,
			height: 32
		}
	},
	errorContainer: {
		marginTop: "16px"
	}
}))

const CreditNoteReasons = (props) => {
	const {
		items,
		callback,
		defaultReason,
		errorReason = null
	} = props

	const classes = styles()
	const [selected, setSelected] = useState(null)

	useEffect(() => {
		if (!defaultReason) return
		setSelected(defaultReason)
	}, [defaultReason])

	const _onSelected = (status) => {
		setSelected(status)
		callback({
			method: "UPDATE_REASON",
			data: {
				reason: status
			}
		})
	}

	const getIcon = (status) => {
		const statusSelected = (status === selected)

		switch (status) {
			case creditNotesReasons.BROKEN.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<BreakIcon selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.DLC.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<CalendarToday selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.TEMPERATURE.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<TemperatureIcon selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.NOT_PRESENTED.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<NotPresentedIcon selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.LATE_DELIVERY.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<LateDeliveryIcon selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.MISSING_DOCUMENT.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<MissingDocumentIcon selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.TRUCK_CLEANLINESS.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<TruckCleanliness selected={statusSelected} />
					</IconButton>
				)
			case creditNotesReasons.OTHER.key:
				return (
					<IconButton className={classes.iconButton} aria-label="search" size="large">
						<HelpOutlineOutlinedIcon htmlColor={statusSelected ? COLORS.BLACK_CARD : COLORS.GREY_FLAW_ICON} />
					</IconButton>
				)
			default:
				return
		}
	}

	return (
		<Stack direction="column" sx={{
			width: "100%"
		}}>
			<div className={classes.container}>
				{
					items.map((item, i) => {
						const currentIcon = creditNotesReasons[item]

						return (
							<CreditNoteCardReason
								key={currentIcon.key.toUpperCase()}
								text={currentIcon.label}
								value={currentIcon.key.toUpperCase()}
								icon={getIcon(currentIcon.key)}
								selected={(currentIcon.key === selected)}
								onSelected={_onSelected}
								last={i === items.length - 1}
								hasError={!!errorReason}
							/>
						)
					})
				}
			</div>
			{errorReason && <div className={classes.errorContainer}>
				<Typography variant={"error"}>{errorReason}</Typography>
			</div>}
		</Stack>
	)
}

export default CreditNoteReasons