import React, { useCallback } from "react"

import styled from "@emotion/styled"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material"

import { COLORS } from "../../../../utils"
import EditableSection from "./EditableSection"
import SectionPreview from "./SectionPreview"
import { PRODUCTION_STEPS_SPACINGS } from "../../../../utils/constant"
import Steps from "../steps/Steps"
import { computeProductionStepsRecipeOnFieldChange, computeRecipeData } from "../../../../utils/recipes"

export const COMPONENT_NAME = "SECTIONS"

// ----------------------------------------------- //
// -------------- styled components -------------- //
// ----------------------------------------------- //

// -------------- Accordion -------------- //
const StyledAccordion = styled((props) => (
  <Accordion {...props} />
))({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
})

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))({
  flexDirection: "row-reverse",
  position: "relative",
  // opened and closed expanded icon
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded,& .MuiAccordionSummary-expandIconWrapper": {
    position: "sticky",
    left: PRODUCTION_STEPS_SPACINGS.ACCORDION_EXPANDED_ICON_LEFT,
    zIndex: 30
  },
  // row
  "& .MuiAccordionSummary-content": {
    padding: 0,
    margin: 0,
    height: 56,
    borderBottom: "1px solid #cccccc",
    marginLeft: -28, // important! for the summary to not take account of the expand icon space
    backgroundColor: COLORS.PRODUCTION_STEPS_BLUE
  }
})

const Sections = ({
  recipe,
  sections,
  isEdition,
  onRowHover,
  onRowBlur,
  hoveredRow,
  genericSections,
  onClearFocus,
  onFieldFocus,
  onFieldBlur,
  onKeyUp,
  deleteHover,
  setFieldValue,
  onDeleteBlur,
  errors,
  formValues,
  setValues,
  machineTypes,
  kitchenAreas,
  supplierItems,
  transformationModes,
  handleChange,
  allReusableSteps,
  stepNumbers
}) => {
  // sections form values computation
  const computeSectionsFormValues = useCallback((sections, sectionIndex) => {
    const newFormValues = { ...formValues }
    newFormValues.sections = sections

    if (!sections[sectionIndex] || !sections[sectionIndex].productionSteps) return
    sections[sectionIndex].productionSteps.forEach((step, stepIndex) => {
      step.stepComponents && step.stepComponents.forEach((_, ingredientIndex) => {
        computeProductionStepsRecipeOnFieldChange({
          recipe: newFormValues,
          sectionIndex,
          stepIndex,
          stepComponentIndex: ingredientIndex
        })
      })
    })
    computeRecipeData(newFormValues)
    
    setValues(newFormValues)
  }, [formValues, setValues])

  // do not display sections row in preview if it's empty
  // display an empty row if sections is empty in edition mode
  // alway has a default section, see: getDefaultSection()
  if (!isEdition && !(sections.length && sections[0].id)) return

  const _isHover = (index) => {
    return (
      hoveredRow &&
      COMPONENT_NAME === hoveredRow.component &&
      hoveredRow.index === index
    )
  }

  const _isDeleteHover = (index) => {
    return (
      deleteHover &&
      COMPONENT_NAME === deleteHover.component &&
      deleteHover.index === index
    )
  }

  const _hasError = (index) => errors.sections && errors.sections[index] && (errors.sections[index].name || errors.sections[index].parentPercent)

  const _hasStepError = (index,field,sectionIndex) => {
    return errors.sections?.[sectionIndex]?.productionSteps?.[index]?.step?.[field]
  }

  return (
    <Box className="flexColumn">
      {sections.map((section, index) => (
        <StyledAccordion
          elevation={0}
          defaultExpanded
          square
          disableGutters
          key={index}
        >
          <StyledAccordionSummary
            expandIcon={<img alt="chevron" src="/icons/chevron-down.svg" />}
            onMouseEnter={() => onRowHover(COMPONENT_NAME, index)}
            onMouseLeave={onRowBlur}
          >
            {isEdition ? (
              <EditableSection
                sections={sections}
                section={section}
                index={index}
                isHover={_isHover(index)}
                isDeleteHover={_isDeleteHover(index)}
                genericSections={genericSections}
                setFieldValue={setFieldValue}
                onClearFocus={onClearFocus}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onKeyUp={onKeyUp}
                onDeleteBlur={onDeleteBlur}
                hasError={_hasError}
                computeSectionsFormValues={computeSectionsFormValues}
              />
            ) : (
              <SectionPreview section={section} />
            )}
          </StyledAccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <Steps
              recipe={recipe}
              section={section}
              steps={section.productionSteps}
              isEdition={isEdition}
              hoveredRow={hoveredRow}
              sectionIndex={index}
              onFieldFocus={onFieldFocus}
              onFieldBlur={onFieldBlur}
              onKeyUp={onKeyUp}
              onRowHover={onRowHover}
              onRowBlur={onRowBlur}
              errors={errors}
              machineTypes={machineTypes}
              kitchenAreas={kitchenAreas}
              supplierItems={supplierItems}
              transformationModes={transformationModes}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              hasError={_hasStepError}
              isReusable={false}
              onClearFocus={onClearFocus}
              formValues={formValues}
              setValues={setValues}
              allReusableSteps={allReusableSteps}
              stepNumbers={stepNumbers}
            />
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  )
}

export default Sections
