import React, {PureComponent} from "react"
import AsyncSelect from "react-select/async"
import Parse from "parse"
import { parseLimitRequest, KFC_BRANDS } from "../../utils"
import AutoCompleteBase from "./AutoCompleteBase"
import MenuItem from "@mui/material/MenuItem"
import PropTypes from "prop-types"
const Recipe = Parse.Object.extend("Recipe")

const loadOptions = async (inputValue, callback) => {
    callback(await filterRecipes(inputValue))
}

const filterRecipes = async (inputValue) => {
    if (inputValue) {
        const recipes = await new Parse.Query(Recipe)
            .matches("name", inputValue, "i")
            //.equalTo("status", "6")
            .limit(parseLimitRequest)
            .find()

        let result = []
        for (const i in recipes) {
            const obj = recipes[i]
            result.push({id: obj.id, label: obj.get("name"), brands: obj.get("brands")})
        }
        return result
    }

    return []
}

function getLogo(brands) {
    return brands
    ? (
        <div className="brandLogoContainer">
            {
                KFC_BRANDS.map((brand, index) => {
                    if (brands.includes(brand.name)) {
                        return <img key={index} src={brand.image} className="brandLogo" alt={brand.shortname} />
                    }

                    return null
                })
            }
        </div>
    )
    : null
}

function Option(hiddenOptions) {
    return function(props) {
        const hiddenIds = hiddenOptions.map(item => item.id)
        const {data} = props
        if (!hiddenIds.includes(data.id)) {
            const brands = data ? data.brands : null
            const logo = getLogo(brands)
                return (
                    <MenuItem
                        buttonRef={props.innerRef}
                        selected={props.isFocused}
                        component="div"
                        style={{
                            fontWeight: props.isSelected ? 500 : 400,
                            display: "flex",
                            flexDirection: "row"
                        }}
                        {...props.innerProps}
                    >
                        {logo} {props.children}
                    </MenuItem>
                )
        } else {
            return null
        }
    }
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function SingleValue(props) {
    const { getValue } = props
    const value = getValue()[0]
    const logo = getLogo(value.brand)
    return (
        <div
            {...props.innerProps}
            className="flexRow center"
        >
            {logo} {props.children}
        </div>
    )
}
class RecipeAutocomplete extends PureComponent {
    static defaultProps = {
        hiddenOptions: []
    }

    static propTypes = {
        hiddenOptions: PropTypes.array.isRequired
    }

    render() {
        const {onChange: OnInputChange, name, value: newValue, hiddenOptions} = this.props

        return (
            <AutoCompleteBase onInputChange={OnInputChange}>
                {({classes: baseClasses, styles, components, onChange}) => (
                    <AsyncSelect
                        name={name}
                        classes={baseClasses}
                        styles={styles}
                        components={{...components, Option: Option(hiddenOptions), ValueContainer, SingleValue}}
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={onChange}
                        placeholder="Produit interne"
                        value={newValue}
                        isClearable={true}
                    />
                )}
            </AutoCompleteBase>
        )
    }
}

export default RecipeAutocomplete
