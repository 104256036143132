import React, { PureComponent } from "react"
import { connect } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import {List, Typography} from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { DatePicker } from "@mui/x-date-pickers"
import {
    createProduction,
    showProductionList
} from "../../actions/Recipe/productions"
import TextField from "@mui/material/TextField"
import moment from "moment"
import RecipeAutocomplete from "../../components/autoComplete/RecipeAutocomplete"
import SubcontractorProductAutoComplete from "../../components/autoComplete/SubcontractorProductAutoComplete"
import ListCartItem from "../../components/ListCartItem"
import {canAddToCart} from "../../utils/production"
import { COLORS } from "../../utils"

const styles = theme => {
    return ({
        container: {
            composes: "flexRow",
        },
        info: {
            composes: "flexRow",
            marginBottom: 20
        },
        headInfos: {
            marginLeft: 30,
            marginBottom: 10
        },
        error: {
            color: COLORS.RED,
        },
        dateStyle: {
            marginLeft: 30,
            marginBottom: 30
        },
        addButton: {
            marginLeft: 40
        },
        deleteButton: {
            marginTop: 20,
            marginLeft: -100
        },
        cartListContainer: {
            backgroundColor: theme.palette.background.paper,
            composes: "flexColumn width100",
            minHeight: 260
        },
        cartInfo: {
            composes: "flexColumn",
            width: "35%",
            marginTop: 6,
            borderLeft: "2px solid #c2c2c2",
            minHeight: 400,
            paddingBottom: 50
        },

        cartInfoTitle: {
            textDecoration: "underline",
            alignSelf: "center"
        },
        details: {
            composes: "flexColumn",
            width: "65%",
            padding: 20,
            paddingTop: 40
        },
        sections: {
            composes: "flexColumn",
            marginBottom: 30
        },
        selectAutoContainer: {
            minWidth: 350,
            marginLeft: 30
        },
        selectAuto: {
            minWidth: 350,
            marginTop: 12
        },
        textControl: {
            marginLeft: 20,
            width: 150
        },
        textControl300: {
            marginLeft: 20,
            width: 150,
        },
        section: {
            textAlign: "center",
            fontSize: 22,
            fontweight: 700,
            color: `${theme.palette.primary["A700"]}`
        },
        noBorder: {
            border: "none"
        },
        hoHeight: {
            height: 0
        },
        step: {
            border: "none",
            height: 0
        },
        ingredientRow: {
            borderRight: "2px solid #c2c2c2",
            borderLeft: "2px solid #c2c2c2",
        },
        ingredientHeader: {
            composes: "$ingredientRow",
            borderTop: "2px solid #c2c2c2"
        },
        ingredientCell: {
            border: "1px solid #c2c2c2"
        },
        packagingContainer: {
            composes: "flexRow"
        },
        packagingColumn: {
            composes: "flexColumn",
            marginBottom: 10,
        },
        packaging: {
            composes: "flexRow center",
            marginBottom: 10
        },
        packagingItem: {
            height: 30,
            padding: 5,
            marginLeft: 10,
            marginRight: 10,
            border: "1px solid #c2c2c2",
            color: "purple"
        },
        packagingLabel: {
            marginRight: 5
        },
        subPackagingLabel: {
            marginLeft: 20,
            marginRight: 5
        },
        btnDownload: {
            marginTop: 20,
            alignSelf: "center"
        },
        closeWindow: {
            textAlign: "end"
        },
        multipleError: {
            composes: "$error",
            marginLeft: 30,
            marginTop: 10,
            marginBottom: 20
        }
    })
}

class ProductionCreation extends PureComponent {

    constructor(props){
        super(props)

        this.state = {
            cart: [],
            date: null,
            recipe: null,
            subcontractorProduct: null,
            recipeVolume: 0,
            subcontractorProductVolume: 0,
            dateErrorMessage: "",
            showDateError: false,
            showRecipeError: false,
            showSubcontractorProductError: false
        }
    }

    _onDeleteCartItem = (index) => {
        const { cart } = this.state
        const newCart = [].concat(cart)

        newCart.splice(index, 1)

        this.setState({
            cart: newCart
        })
    }

    _addRecipeToCart = async () => {
        const { cart, recipe, recipeVolume, date } = this.state
        const volumeNumber = parseFloat(recipeVolume)

        if (recipe &&
            date &&
            (volumeNumber % 1) === 0 &&
            volumeNumber !== 0)
        {
            if (await canAddToCart(date, recipe.id)){
                const newCart = [].concat(cart)

                newCart.push({
                    data: recipe,
                    type: "recipe",
                    volume: volumeNumber
                })

                this.setState({
                    cart: newCart,
                    recipe: null,
                    recipeVolume: 0,
                    showRecipeError: false
                })
            }
            else {
                this.setState({
                    showRecipeError: true
                })
            }
        }
    }

    _addSubcontractorProductToCart = async () => {
        const { cart, subcontractorProduct, subcontractorProductVolume, date } = this.state
        const volumeNumber = parseFloat(subcontractorProductVolume)

        if (subcontractorProduct &&
            date &&
            (volumeNumber % 1) === 0 &&
            volumeNumber !== 0)
        {
            if (await canAddToCart(date, subcontractorProduct.id)) {
                const newCart = [].concat(cart)

                newCart.push({
                    data: subcontractorProduct,
                    type: "subcontractorProduct",
                    volume: volumeNumber
                })

                this.setState({
                    cart: newCart,
                    subcontractorProduct: null,
                    subcontractorProductVolume: 0,
                    showSubcontractorProductError: false
                })
            }
            else {
                this.setState({
                    showSubcontractorProductError: true
                })
            }
        }
    }

    renderCart = () => {
        const { classes } = this.props
        const { cart } = this.state

        return (
            <List classes={{root: classes.cartListContainer}}>
                {(cart &&
                    cart.map((values, index) => {
                        return (
                            <ListCartItem
                                key={index}
                                values={values}
                                index={index}
                                onDelete={this._onDeleteCartItem}
                            />
                        )
                    })
                )}
            </List>
        )
    }

    checkDate = (date) => {
        const currentDate = moment.utc().startOf("day").valueOf()

        if (!date) {
            this.setState({
                showDateError: true,
                dateErrorMessage: "Vous devez renseigner une date de production"
            })
            return false
        }

        if (date < currentDate) {
            this.setState({
                showDateError: true,
                dateErrorMessage: "La date doit être supérieur ou égale à la date d'aujourd'hui"
            })
            return false
        }

        return true
    }

    _createProduction = () => {
        const { createProduction } = this.props
        const { cart, date } = this.state

        if (this.checkDate(date)){
            createProduction({
                date: date,
                cart: cart
            })
        }
    }

    _onSubcontractorProductChange = (value) => {
        this.setState({
            subcontractorProduct: value,
            showSubcontractorProductError: false
        })
    }

    _onSubcontractorProductVolumeChange = event => {
        this.setState({subcontractorProductVolume: event.target.value})
    }

    _onRecipeChange = (value) => {
        this.setState({
            recipe: value,
            showRecipeError: false
        })
    }

    _onRecipeVolumeChange = event => {
        this.setState({recipeVolume: event.target.value})
    }

    _datePickerChange = (date) => {
        this.setState({
            date: moment.utc(date).startOf("day").valueOf(),
            showDateError: false
        })
    }

    _onVolumeKeyPress = (event, type) => {
        if (event.keyCode === 13){
            if (type === "recipe"){
                this._addRecipeToCart()
            }
            else {
                this._addSubcontractorProductToCart()
            }
        }
    }

    _onClose = () => {
        const { showProductionList } = this.props
        showProductionList()
    }

    _getSelectedCart = (type) => {
        const { cart } = this.state

        if (type === "recipe") {
            return cart
                .filter(row => row.type === type)
                .reduce((obj, value) => {
                    obj.push(value.data)
                    return obj
                    }, [])
        }
        else {
            return cart
                .filter(row => row.type === type)
                .reduce((obj, value) => {
                    obj.push(value.data)
                    return obj
                    }, [])
        }
    }

    render(){
        const { classes } = this.props
        const {
            date, recipe, subcontractorProduct, subcontractorProductVolume,
            recipeVolume, showDateError, dateErrorMessage, cart,
            showRecipeError, showSubcontractorProductError
        } = this.state

        return (
            <div className={classes.container}>
                <div className={classes.details}>
                    <div className={classes.dateStyle}>
                        <DatePicker
                            disableCloseOnSelect={false}
                            showToolbar={false}
                            variant="inline"
                            value={date}
                            onChange={this._datePickerChange}
                            inputFormat="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} variant="standard" label="Date de production" className={classes.datePicker} />}
                        />
                        {showDateError &&
                            <FormHelperText className={classes.error}>{dateErrorMessage}</FormHelperText>
                        }
                    </div>
                    {
                        date &&
                            <div>
                                <div className={classes.headInfos}>
                                    <Typography variant="h6" style={{textDecoration: "underline"}}>
                                        Internes
                                    </Typography>
                                </div>
                                <div className={classes.info}>
                                    <div className={classes.selectAutoContainer}>
                                        <FormControl margin="normal" classes={{root: classes.selectAuto}}>
                                            <RecipeAutocomplete
                                                onChange={this._onRecipeChange}
                                                name="recipes"
                                                value={recipe}
                                                hiddenOptions={this._getSelectedCart("recipe")}
                                            />
                                        </FormControl>
                                    </div>
                                    <TextField
                                        variant="standard"
                                        id="volumeRecipe"
                                        className={classes.textControl}
                                        type="number"
                                        label="Volume"
                                        value={recipeVolume}
                                        onChange={this._onRecipeVolumeChange}
                                        inputProps={{min: 0, step: 1}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onKeyDown={(event) => {this._onVolumeKeyPress(event, "recipe")}}
                                    />
                                    <IconButton
                                        aria-label="Ajouter"
                                        className={classes.addButton}
                                        onClick={this._addRecipeToCart}
                                        size="large">
                                        <AddIcon fontSize="large"/>
                                    </IconButton>
                                </div>
                                {showRecipeError &&
                                    <FormHelperText className={classes.multipleError}>Cette recette a déjà été ajoutée pour ce jour de production</FormHelperText>
                                }
                                <div className={classes.headInfos}>
                                    <Typography variant="h6" style={{textDecoration: "underline"}}>
                                        Sous-traitants
                                    </Typography>
                                </div>
                                <div className={classes.info}>
                                    <div className={classes.selectAutoContainer}>
                                        <FormControl
                                            margin="normal"
                                            classes={{root: classes.selectAuto}}
                                        >
                                            <SubcontractorProductAutoComplete
                                                onChange={this._onSubcontractorProductChange}
                                                value={subcontractorProduct}
                                                name="SubconstractorProduct"
                                                hiddenOptions={this._getSelectedCart("subcontractorProduct")}
                                            />
                                        </FormControl>
                                    </div>
                                    <TextField
                                        variant="standard"
                                        id="volumeSub"
                                        className={classes.textControl}
                                        type="number"
                                        label="Volume"
                                        onChange={this._onSubcontractorProductVolumeChange}
                                        value={subcontractorProductVolume}
                                        inputProps={{min: 0, step: 1}}
                                        InputLabelProps={{shrink: true}}
                                        onKeyDown={(event) => {this._onVolumeKeyPress(event, "subcontractorProduct")}}
                                    />
                                    <IconButton
                                        aria-label="Ajouter"
                                        className={classes.addButton}
                                        onClick={this._addSubcontractorProductToCart}
                                        size="large">
                                        <AddIcon fontSize="large"/>
                                    </IconButton>
                                </div>
                                {showSubcontractorProductError &&
                                    <FormHelperText className={classes.error}>Cette recette a déjà été ajoutée pour ce jour de production</FormHelperText>
                                }
                            </div>
                    }
                </div>
                <div className={classes.cartInfo}>
                    <Typography variant="h6" classes={{root: classes.cartInfoTitle}}>
                        Récapitulatif {cart.length > 0 && (cart.length === 1 ? "- " + cart.length + " fiche de prod" : "- " + cart.length + " fiches de prods")}
                    </Typography>
                    {this.renderCart()}
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.btnDownload}
                        onClick={this._createProduction}
                    >
                        Générer les fiches
                    </Button>
                </div>
                <IconButton aria-label="Fermer la fiche" onClick={this._onClose} size="large">
                    <CloseIcon/>
                </IconButton>
            </div>
        )
    }
}

ProductionCreation = withStyles(styles)(ProductionCreation)

export default connect(() => {
    return {}
}, {
    createProduction, showProductionList
})(ProductionCreation)
