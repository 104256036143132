import React, { useEffect, useState} from "react"
import { useDispatch } from "react-redux"
import {
    computeOrderSupplierItemQuantity,
    generateStockUnitLabel
} from "../../../utils/orderReception"
import DataGrid from "../../../components/DataGrid/DataGrid"
import OrderSupplierItemDialogForm from "./OrderSupplierItemDialogForm"
import { updateArticleChanges } from "../../../actions/OrderReception/OrderReception"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    top: {
        height: 0,
        marginTop: 0
    },
    root: {
        marginTop: 8
    }
})

const columns = [
    {
        key: "name",
        label: "Nom de l'article",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        }
    },
    {
        key: "reference",
        label: "Référence",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        }
    },
    {
        key: "quantity",
        label: "Quantité",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: false,
        },
        render: (value) => {
            const receivedQuantity = value.split("-")[1]
            const unit = value.split("-")[2]
            return (
              <div><span>{receivedQuantity} </span><span style={{color: "#7C7C7C"}}>({unit})</span></div>
            )
        }
    },
    {
        key: "totalWeight",
        label: "Poids total",
        dataType: "text",
        sortable: false,
        filter: {
            filterable: true,
            type: "text",
            fullText: true
        }
    },
]

const OrderDeliveryNoteSupplierItems = ({ items, orderReceptionId }) => {
    const classes = useStyles()
    const [selectedSupplierItem, setSelectedSupplierItem] = useState(null)
    const [orderSupplierItems, setOrderSupplierItems] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        const newSupplierItems = items?.map((orderSupplierItem) => {
            return {
                id: orderSupplierItem.supplierItem?.objectId,
                name: orderSupplierItem.supplierItem?.name,
                reference: orderSupplierItem.supplierItem?.reference,
                quantity: computeOrderSupplierItemQuantity(orderSupplierItem)
                    + "-" + ((orderSupplierItem.quantities?.reception) || "0")
                    + "-" + generateStockUnitLabel(orderSupplierItem.supplierItem),
                totalWeight: orderSupplierItem.quantities?.reception * (orderSupplierItem.supplierItem?.units?.stock?.weight || 0)
            }
        }) || []

        setOrderSupplierItems(newSupplierItems)
    }, [items])

    const handleRowClick = (orderSupplierItemId) => {
        const item = items.find(orderSupplierItem => orderSupplierItem.supplierItem.objectId === orderSupplierItemId)
        if (!item) return
        setSelectedSupplierItem(item)
    }

    const handleClose = () => {
        setSelectedSupplierItem(null)
    }


    const handleConfirmQuantity = async (values) => {
        await dispatch(updateArticleChanges(orderReceptionId, selectedSupplierItem, values))
    }

    return (
        <div className={classes.root}>
            <DataGrid
              columns={columns}
              data={orderSupplierItems}
              withFilters={true}
              rowLabel={null}
              onRowClick={(orderSupplierItem) => handleRowClick(orderSupplierItem.id)}
              withAdditionalFiltersOptions={false}
              tableContainerStyle={{ boxShadow: "none" }}
              removeDeleteFilterIcon={true}
              topClassName={classes.top}
            />
            <OrderSupplierItemDialogForm
                open={!!selectedSupplierItem}
                onClose={handleClose}
                onConfirm={handleConfirmQuantity}
                orderSupplierItem={selectedSupplierItem}
            />
        </div>
    )
}

export default OrderDeliveryNoteSupplierItems