import React from "react"
import PlanningsProduction from "./PlanningsProduction"
import PlanningsTabs from "./PlanningsTabs"

const PlanningProductionTab = () => {
    return (
        <PlanningsTabs tabValue="production">
            <PlanningsProduction />
        </PlanningsTabs>
    )
}

export default PlanningProductionTab
