import React, { useState, useEffect } from "react"
import IconButton from "@mui/material/IconButton"
import BigSearchIcon from "../../icons/BigSearchIcon"
import CalendarToday from "@mui/icons-material/CalendarToday"
import BreakIcon from "../../icons/BreakIcon"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { COLORS } from "../../utils"
import makeStyles from "@mui/styles/makeStyles"
import TagIcon from "../../icons/TagIcon"
import ThumbDownIcon from "../../icons/ThumbDownIcon"
import CardFlaw from "./CardFlaw"
import clsx from "clsx"

const useClasses = makeStyles(() => ({
  container: {
    display: "flex",
    paddingTop: 22,
    "&.is-tablet": {
      paddingTop: 40,
    },
  },
  iconButton: {
    color: COLORS.GREY_FLAW_ICON,
    padding: 0,
    flex: 1,
    "& svg": {
      width: 32,
      height: 32,
    },
    "&.is-tablet": {
      "& svg": {
        width: 51,
        height: 51,
      },
    },
  },
  iconButton32: {
    "& img": {
      width: 37,
      height: 32,
    },
    "&.is-tablet": {
      "& img": {
        width: 57,
        height: 48,
      },
    },
  },
  iconButton39: {
    "& img": {
      width: 52,
      height: 39,
    },
    "&.is-tablet": {
      "& img": {
        width: 77,
        height: 57,
      },
    },
  },
  iconButton59: {
    "& img": {
      width: 59,
      height: 59,
    },
  },
  iconButton43: {
    "& img": {
      width: 43,
      height: 43,
    },
  },
  iconButton62: {
    "& img": {
      width: 62,
      height: 62,
    },
  },
}))

const SelectFlaw = (props) => {
  const { preselected, isTablet, onReasonChange } = props
  const classes = useClasses()

  const [selected, setSelected] = useState(preselected)

  const flawStatuses = [
    "Abimé",
    "DLC",
    "Perdu",
    "Etiquette",
    "Moche",
    "Autres",
  ]

  // set preselection on mount
  useEffect(() => {
    setSelected(preselected)
    onReasonChange(preselected)
  }, [preselected])

  const onClick = (status) => {
    setSelected(status)
    onReasonChange(status)
  }

  const getIcon = (status) => {
    const statusSelected = status === selected

    switch (status) {
      case "Abimé":
        return (
          <IconButton
            className={clsx(classes.iconButton, classes.iconButton39, {
              "is-tablet": isTablet,
            })}
            aria-label="search"
            size="large"
          >
            <BreakIcon selected={statusSelected} />
          </IconButton>
        )
      case "DLC":
        return (
          <IconButton
            className={clsx(classes.iconButton, classes.iconButton39, {
              "is-tablet": isTablet,
            })}
            aria-label="search"
            size="large"
          >
            <CalendarToday selected={statusSelected} />
          </IconButton>
        )
      case "Perdu":
        return (
          <IconButton
            className={clsx(
              classes.iconButton,
              isTablet ? classes.iconButton62 : classes.iconButton43
            )}
            aria-label="search"
            size="large"
          >
            <BigSearchIcon selected={statusSelected} />
          </IconButton>
        )
      case "Etiquette":
        return (
          <IconButton
            className={clsx(
              classes.iconButton,
              isTablet ? classes.iconButton59 : classes.iconButton43
            )}
            aria-label="search"
            size="large"
          >
            <TagIcon selected={statusSelected} />
          </IconButton>
        )
      case "Moche":
        return (
          <IconButton
            className={clsx(classes.iconButton, classes.iconButton32, {
              "is-tablet": isTablet,
            })}
            aria-label="search"
            size="large"
          >
            <ThumbDownIcon selected={statusSelected} />
          </IconButton>
        )
      case "Autres":
        return (
          <IconButton
            className={clsx(classes.iconButton, {
              "is-tablet": isTablet,
            })}
            aria-label="search"
            size="large"
          >
            <HelpOutlineOutlinedIcon
              htmlColor={
                statusSelected ? COLORS.BLACK_CARD : COLORS.GREY_FLAW_ICON
              }
            />
          </IconButton>
        )
      default:
        return
    }
  }

  return (
    <div className={clsx(classes.container, { "is-tablet": isTablet })}>
      {flawStatuses.map((status) => {
        return (
          <CardFlaw
            key={status.toUpperCase()}
            text={status}
            icon={getIcon(status)}
            selected={status === selected}
            onCauseSelected={onClick}
            isTablet={isTablet}
          />
        )
      })}
    </div>
  )
}

export default SelectFlaw
