import React from "react"

import SettingsIcon from "@mui/icons-material/Settings"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { Paper, Tab, Tabs } from "@mui/material"

import PackagingGeneralTab from "./preview/PackagingGeneralTab"
import { showPackagingResources } from "../../actions/Products/Products"
import PageHeader from "../../components/PageHeader"
import PackagingSummaryHeader from "./preview/PackagingSummaryHeader"
import { getPackagingJSONSelector } from "../../reducers/Packaging/packagingList"
import PackagingLifeCycleTab from "./preview/PackagingLifeCycleTab"
import { addOrEditPackagingMaterialsToPackaging, createOrUpdatePackaging, removePackagingMaterialsToPackaging } from "../../actions/Packaging/packaging"
import { confirm } from "../../components/react-await-dialog/react-await-dialog"

const sx = {
  paper: {
    borderRadius: 0,
    borderLeft: "none",
    borderRight: "none",
    marginBottom: "1px"
  }
}

const Packaging = () => {
  const [tab, setTab] = useState(0)

  const dispatch = useDispatch()

  const handleTabValue = (e, value) => {
    setTab(value)
  }

  const packaging = useSelector(getPackagingJSONSelector)

  const onBack = () => dispatch(showPackagingResources())

  if (!packaging) return null

  const handleCreateOrUpdatePackaging = async (values) => {
    await dispatch(createOrUpdatePackaging(values, packaging))
  }

  const handleAddMaterial = (values) => {
    dispatch(addOrEditPackagingMaterialsToPackaging(packaging.objectId, values))
  }

  const handleEditMaterial = (materialId, values) => {
    dispatch(addOrEditPackagingMaterialsToPackaging(packaging.objectId, values, materialId))
  }

  const handleDeleteMaterial = async (materialId) => {
    const message = "Voulez-vous supprimer cette matière de la composition du packaging ? "
    if (
      await confirm({
        title: "Confirm",
        message: message,
        confirmText: "SUPPRIMER",
      })
    ) {
    dispatch(removePackagingMaterialsToPackaging(packaging.objectId, materialId))
    }
  }


  return (
    <div className="flexColumn flex1">
      <PageHeader icon={<SettingsIcon />} title="Réglages" onGoBack={onBack} withBackButton />

      <PackagingSummaryHeader packaging={packaging} />

      <Paper className="flexRow stretchSelf" sx={sx.paper}>
        <Tabs
          value={tab}
          onChange={handleTabValue}
          indicatorColor="primary"
          textColor="primary"
          centered
          className="flex1"
        >
          <Tab label="Général" />
          <Tab label="Cycle de vie" />
        </Tabs>
      </Paper>
      <div className="flexColumn stretchSelf flex1">
        {tab === 0 && (
          <PackagingGeneralTab packaging={packaging} onSave={handleCreateOrUpdatePackaging} />
        )}
        {tab === 1 && (
          <PackagingLifeCycleTab
            packaging={packaging}
            onSave={handleCreateOrUpdatePackaging}
            onAddMaterial={handleAddMaterial}
            onEditMaterial={handleEditMaterial}
            onDeleteMaterial={handleDeleteMaterial}
          />
        )}
      </div>
    </div>
  )
}

export default Packaging