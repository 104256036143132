import Parse from "parse"

const Recipe = Parse.Object.extend("Recipe")

export async function countRecipes() {
    try {
        return await new Parse.Query(Recipe).count()
    }
    catch (e) {
        console.error("parseManager.products.recipe.parseRecipeManager.countRecipes error : ", e)
        return Promise.reject(e)
    }
}
