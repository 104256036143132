import dayjs from "dayjs"

export const getParamsFromPath = (pathname) => {
	const elems = pathname.split("/")
	if (pathname.startsWith("/productionReception/") && elems.length === 5) {
		return {
			siteId: elems[2],
			stockZoneId: elems[3],
			date: dayjs.utc(parseFloat(elems[4])).startOf("day").valueOf()
		}
	}

	return null
}
