import { getData } from "../.."

const defaultState = {
    subcontractorsCommercialNamesList: [],
    suppliers: [],
    familiesIngredients: [],
    groupsIngredients: [],
    subcontractorsCommercialNamesSnackBar: { open: false, type: "", message: "", duration: 0 },
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "SUPPLIERS_COMMERCIAL_NAME_LOADED":
            return {
                ...state,
                subcontractorsCommercialNamesList: action.subcontractorsCommercialNamesList,
                suppliers: action.suppliers,
                subcontractorsProductsList: action.subcontractorsProductsList
            }
        case "SUPPLIERS_LOADED":
            return {
                ...state,
                suppliers: action.suppliers
            }
        case "FAMILIES_INGREDIENTS_LOADED":
            return {
                ...state,
                familiesIngredients: action.familiesIngredients
            }
        case "GROUPS_INGREDIENTS_LOADED":
            return {
                ...state,
                groupsIngredients: action.groupsIngredients
            }
        case "EXTRACT_SUPPLIER_PRODUCTS_WITH_COMMERCIAL_NAME_ID":
            return {
                ...state,
                subcontractorsCommercialNamesSnackBar: action.subcontractorsCommercialNamesSnackBar
            }
        case "EXTRACT_SUPPLIER_PRODUCTS_WITH_COMMERCIAL_NAME_ID_ERROR":
            return {
                ...state,
                subcontractorsCommercialNamesSnackBar: action.subcontractorsCommercialNamesSnackBar
            }
        case "CLOSE_SUBCONTRACTORS_COMMERCIAL_NAMES_SNACKBAR":
            return {
                ...state,
                subcontractorsCommercialNamesSnackBar: action.subcontractorsCommercialNamesSnackBar
            }

        default:
            return {
                ...state
            }
    }
}


//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getSubcontractorCommercialName(state, id) {
    return state.subcontractorsCommercialNames.subcontractorsCommercialNamesList.find(subcontractorCommercialName => subcontractorCommercialName.objectId === id)
}

export function getSuppliers(state, errorIfNotFound = false) {
    return getData(state, "subcontractorsCommercialNames.suppliers", errorIfNotFound && "No suppliers loaded")
}