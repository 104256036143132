import React from "react"
import { Form, Formik } from "formik"
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material"

import ModalForm from "../../../../../components/form/ModalForm"
import { getPackagingLogisticsFormInitialValues } from "../../../../../utils/packaging"
import { packagingLogisticsSchema } from "../../../../../utils/yupValidators"
import ModalActions from "../../../../../components/ModalActions"

const sx = {
  formControl: {
    "& .MuiFormLabel-root": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      transform: "none",
      fontSize: 14,
    },
    "& .MuiInputBase-root": {
      marginTop: 0
    }
  }
}
const PackagingLogisticsFormDialog = ({
  onClose,
  open,
  onSave,
  packaging
}) => {
  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Modifier les informations logistiques"
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={getPackagingLogisticsFormInitialValues(packaging)}
        onSubmit={onSave}
        validationSchema={packagingLogisticsSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="weight"
                    label="Poids (kg)"
                    value={values.weight}
                    type="number"
                    error={!!errors.weight}
                    helperText={errors.weight}
                    onChange={handleChange}
                    disabled
                  />
                  {errors.weight && <FormHelperText error>{errors.weight}</FormHelperText>}
                </FormControl>
                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="height"
                    label="Hauteur&nbsp;(mm)"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={values.height}
                    type="number"
                    error={!!errors.height}
                    helperText={errors.height}
                    onChange={handleChange}
                  />
                  {errors.height && <FormHelperText error>{errors.height}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth error={!!errors.type} sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="deepth"
                    label="Profondeur&nbsp;(mm)"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={values.deepth}
                    type="number"
                    error={!!errors.deepth}
                    helperText={errors.deepth}
                    onChange={handleChange}
                  />
                  {errors.deepth && <FormHelperText error>{errors.deepth}</FormHelperText>}
                </FormControl>

                <FormControl margin="normal" fullWidth  error={!!errors.nature} sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="width"
                    label="Largeur&nbsp;(mm)"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={values.width}
                    type="number"
                    error={!!errors.width}
                    helperText={errors.width}
                    onChange={handleChange}
                  />
                  {errors.nature && <FormHelperText error>{errors.nature}</FormHelperText>}
                </FormControl>

              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingLogisticsFormDialog
