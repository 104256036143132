import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 20
    },
    title: {
        fontSize: 16,
        fontWeight: 500,
        color: COLORS.GREY_700
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 400,
        color: COLORS.DARK_GREY
    }
})

const DeliveryNoteHeaderTitle = (props) => {
    const {
        deliveryNoteNumber,
        supplierName,
        orderNumber
    } = props

    const classes = useStyles()
    
    return (
        <div className={classes.container}>
            <Typography variant="h6" color="textPrimary" className={classes.title}>
                CONTROLE DU BON DE LIVRAISON N°{deliveryNoteNumber}
            </Typography>
            <Typography variant="h6" color="textPrimary" className={classes.subtitle}>
                {supplierName} - N° DE COMMANDE : {orderNumber}
            </Typography>
        </div>
    )
}

export default DeliveryNoteHeaderTitle
