import React from "react"

import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"


const ReceptionSnackBar = (props) => {
	const { data, onClose } = props
	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={data.open}
			autoHideDuration={data.duration}
			onClose={() => onClose(data.type)}
		>
			<MySnackbarContentWrapper
				variant={data.type}
				message={data.message}
				onClose={() => onClose(data.type)}
			/>
		</Snackbar>
	)
}

export default ReceptionSnackBar
