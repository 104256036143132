import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterDeliveryNoteConfigForm, { distributionCenterDeliveryNoteConfigFormId } from "./DistributionCenterDeliveryNoteConfigForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"

const DistributionCenterDeliveryNoteConfigFormContainer = ({ open, onClose, distributionCenter }) => {

	const dispatch = useDispatch()

	const onSubmitAddressForm = (values) => {
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, values, "deliveryNote"))
		onClose && onClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}>
			<DialogTitle>
				Éditer les informations BL
			</DialogTitle>
			<DialogContent>
				<DistributionCenterDeliveryNoteConfigForm
					distributionCenter={distributionCenter}
					handleSubmit={onSubmitAddressForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterDeliveryNoteConfigFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DistributionCenterDeliveryNoteConfigFormContainer