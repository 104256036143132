import React from "react"
import SuppliersPage from "./SuppliersPage"
import SupplierItemList from "./SupplierItems/SupplierItemList"

const SupplierItemsTab = () =>  {
	return (
		<SuppliersPage tabValue="supplierItems">
			<SupplierItemList/>
		</SuppliersPage>
	)
}

export default SupplierItemsTab