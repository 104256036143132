import React from "react"
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material"
import { Button } from "@mui/material"
import DistributionCenterDispatchSitesForm, { distributionCenterDispatchSitesFormId } from "./DistributionCenterDispatchSitesForm"
import { editDistributionCenterSettings } from "../../../../../actions/Planning/Settings/PlanningSettingsDistributionCenters"

const DistributionCenterDispatchSitesFormContainer = ({ open, onClose, distributionCenter }) => {

	const dispatch = useDispatch()

	const onSubmitDispatchSitesForm = (values) => {
		dispatch(editDistributionCenterSettings(distributionCenter.objectId, values, "dispatchSites"))
		onClose && onClose()
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiPaper-root": {
					width: 500
				}
			}}
		>
			<DialogTitle>
				Éditer les sites de dispatch
			</DialogTitle>
			<DialogContent>
				<DistributionCenterDispatchSitesForm
					distributionCenter={distributionCenter}
					handleSubmit={onSubmitDispatchSitesForm}
				/>
			</DialogContent>
			<DialogActions sx={{ justifyContent: "space-between" }}>
				<Button
					onClick={onClose}
				>
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={distributionCenterDispatchSitesFormId}
				>
					Sauvegarder
				</Button>
			</DialogActions>
		</Dialog>)

}

export default DistributionCenterDispatchSitesFormContainer