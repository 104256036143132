import React from "react"

import IngredientsPage from "./IngredientsPage"
import SubcontractorIngredients from "./External/SubcontractorIngredients"

const SubcontractorIngredientsTab = () => {
    return (
        <IngredientsPage tabValue="externals">
            <SubcontractorIngredients />
        </IngredientsPage>
    )
}

export default SubcontractorIngredientsTab