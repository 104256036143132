import React from "react"

import { grey } from "@mui/material/colors"
import { Box } from "@mui/material"
import { sum } from "lodash"

import { PRODUCTION_STEPS_COL_WIDTHS } from "../../../utils/constant"

const ProductionStepsTable = ({ children }) => {
  return (
    <Box sx={{ overflowX: "scroll", width: "100%", height: "100%" }}>
      <div
        style={{
          maxWidth: "100vw",
          maxHeight: "100vh",
          border: "1px solid " + grey[300]
        }}
      >
        <Box sx={{ minWidth: sum(PRODUCTION_STEPS_COL_WIDTHS) }} aria-label="recipe table">
          {children}
        </Box>
      </div>
    </Box>
  )
}

export default ProductionStepsTable
