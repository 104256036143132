import React from "react"
import TableHead from "@mui/material/TableHead"
import Tooltip from "@mui/material/Tooltip"
import TableSortLabel from "@mui/material/TableSortLabel"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import PropTypes from "prop-types"

const rows = [].concat(
    { id: "uniqueCode", numeric: false, disablePadding: false, label: "Code" },
    { id: "name", numeric: false, disablePadding: false, label: "Nom" },
    { id: "commercialName", numeric: false, disablePadding: false, label: "Nom commercial" },
    { id: "type", numeric: false, disablePadding: false, label: "Type" },
    { id: "status", numeric: false, disablePadding: false, label: "Statut" },
    { id: "state", numeric: false, disablePadding: false, label: "Etat" },
)

const SearchAdvancedEnhancedTableHead = (props) => {
    const {
        order,
        orderBy,
        onRequestSort
    } = props

    return (
        <TableHead>
            <TableRow>
                {rows.map(
                    row => (
                        <TableCell
                            key={row.id}
                            align="left"
                            padding={row.disablePadding ? "none" : "default"}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <Tooltip
                                title="Filtrer"
                                placement={row.numeric ? "bottom-end" : "bottom-start"}
                                enterDelay={300}
                                disableInteractive
                            >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order}
                                    onClick={(event) => onRequestSort(event, row.id)}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ),
                    this,
                )}
            </TableRow>
        </TableHead>
    )
}

SearchAdvancedEnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired
}

export default SearchAdvancedEnhancedTableHead
