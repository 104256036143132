import React from "react"
import TextField from "@mui/material/TextField"

export const ReduxTextField = (
    {
        label,
        input,
        meta: { touched, invalid, error },
        ...custom
    }) => (
        <TextField
            variant="standard"
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            {...custom}
        />
)

export default ReduxTextField
