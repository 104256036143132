import * as Yup from "yup"

import { weekDaysOptions } from "./productionSchemasUtils"
import { getProductionSchemaNameCount } from "../actions/ProductionSchemas/ProductionSchemas"

Yup.addMethod(Yup.string, "uniqueName", function (errorMessage) {
  return this.test("test-unique-name", errorMessage, async function validateValue(value, { parent }) {
    const currentObjectId = parent.objectId || ""
    const existingCount = await getProductionSchemaNameCount(value, currentObjectId)
    return existingCount === 0
  })
})

const sellDaysSchema = Yup.array().of(
  Yup.object().shape({
    brand: Yup.string(),
    days: Yup.array().of(Yup.string())
  })
)

const batchSchema = Yup.object().shape({
  productionDay: Yup.string().required("Veuillez choisir une option"),
  packagingDay: Yup.string().required("Veuillez choisir une option").test("test-valid-packaging-day", "Doit être ultérieur au jour de production", (value, { parent }) => {
    const productionDay = parent.productionDay
    const productionDayIndex = weekDaysOptions.findIndex((option) => option.value === productionDay)
    const packagingDayIndex = weekDaysOptions.findIndex((option) => option.value === value)
    return packagingDayIndex >= productionDayIndex
  }),
  sellDays: sellDaysSchema
})

export const productionSchemasSchema = Yup.object().shape({
  productionSchemas: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Requis").uniqueName("Ce nom est déjà utilisé"),
        batches: Yup.array().of(batchSchema)
      })
    )
    .required("Empty production schemas") // these constraints are shown if and only if inner constraints are satisfied
})
