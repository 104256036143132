import React from "react"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import { SupplierItemCookingModesSchema } from "../../utils/yupValidators"
import { COLORS } from "../../utils"
import { cookingModesIntitialValues } from "../../utils/supplierItemUtils"

const useStyles = makeStyles(() => ({
    container: {
        fontSize: 20,
        width: "auto",
        minWidth: 500,
        height: "auto",
        padding: 20
    },
    title: {
        margin: "10px 0",
        fontSize: 20
    },
    form: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    inputsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    buttonsContainer: {
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        marginTop: 20
    },
    field: {
        width: "100%",
        marginTop: 30
    },
    errorField: {
        color: COLORS.RED
    }
}))

const CookingModesForm = (props) => {
    const {
        cookingModes,
        onUpdateSupplierItem,
        onClose
    } = props

    const classes = useStyles()

    const initialValues = cookingModesIntitialValues(cookingModes)

    cookingModes.forEach((mode) => {
        if (mode.cookingMode) {
            initialValues[mode.cookingMode.name] = mode.transformRate
        }
    })

    const submitForm = async (values) => {
        const formattedValues = [ ...cookingModes ]
        
        Object.keys(values).forEach((item, index) => {
            if (formattedValues[index]) {
                formattedValues[index].transformRate = values[item]
            }
        })
        
        onUpdateSupplierItem("COOKING_MODES", formattedValues)
        onClose()
    }

    return (
        <div className={classes.container}>
            <p className={classes.title}>Editer les modes de cuisson</p>
            <Formik
                initialValues={initialValues}
                onSubmit={submitForm}
                validationSchema={SupplierItemCookingModesSchema}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit
                }) => {
                    return (
                        <Form className={classes.form}>
                            <div className={classes.inputsContainer}>
                                <TextField
                                    variant="standard"
                                    name="raw"
                                    label="Cru/pas de cuisson"
                                    value={values.raw}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.raw
                                        ? <div className={classes.errorField}>{errors.raw}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="steam"
                                    label="Vapeur/blanchi"
                                    value={values.steam}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.steam
                                        ? <div className={classes.errorField}>{errors.steam}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="fried"
                                    label="Sauteuse"
                                    value={values.fried}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.fried
                                        ? <div className={classes.errorField}>{errors.fried}</div>
                                        : null
                                }
                                <TextField
                                    name="oven"
                                    variant="standard"
                                    label="Four"
                                    value={values.oven}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.oven
                                        ? <div className={classes.errorField}>{errors.oven}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="long"
                                    label="Longue"
                                    value={values.long}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.long
                                        ? <div className={classes.errorField}>{errors.long}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="special1"
                                    label="Spéciale 1"
                                    value={values.special1}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.special1
                                        ? <div className={classes.errorField}>{errors.special1}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="special2"
                                    label="Spéciale 2"
                                    value={values.special2}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.special2
                                        ? <div className={classes.errorField}>{errors.special2}</div>
                                        : null
                                }
                                <TextField
                                    variant="standard"
                                    name="special3"
                                    label="Spéciale 3"
                                    value={values.special3}
                                    className={classes.field}
                                    onChange={handleChange}
                                />
                                {
                                    errors.special3
                                        ? <div className={classes.errorField}>{errors.special3}</div>
                                        : null
                                }
                            </div>
                            <div className={classes.buttonsContainer}>
                                <Button
                                    onClick={onClose}
                                >
                                    Annuler
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Valider
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
        
    )
}

export default CookingModesForm