import React from "react"
import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material"

import ModalForm from "../../../../../components/form/ModalForm"
import { getPackagingResumeFormInitialValues, packagingNature } from "../../../../../utils/packaging"
import { packagingResumeSchema } from "../../../../../utils/yupValidators"
import { getPackagingCategoriesSelector, getPackagingTypesSelector } from "../../../../../reducers/Packaging/packagingList"
import { getBrandsOptions } from "../../../../../utils"
import ModalActions from "../../../../../components/ModalActions"

const sx = {
  formControl: {
    "& .MuiFormLabel-root": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      transform: "none",
      fontSize: 14,
    },
    "& .MuiInputBase-root": {
      marginTop: 0
    }
  }
}
const PackagingResumeFormDialog = ({
  onClose,
  open,
  onSave,
  packaging
}) => {
  const types = useSelector(getPackagingTypesSelector)
  const categories = useSelector(getPackagingCategoriesSelector)

  return (
    <ModalForm
      onClose={onClose}
      open={open}
      title="Modifier le résumé"
      sx={{ pt: 2 }}
    >
      <Formik
        initialValues={getPackagingResumeFormInitialValues(packaging)}
        onSubmit={onSave}
        validationSchema={packagingResumeSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form>
              <Stack spacing={3}>
                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="name"
                    label="Nom"
                    value={values.name}
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={handleChange}
                  />
                  {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
                </FormControl>

                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="reference"
                    label="Référence"
                    value={values.reference}
                    error={!!errors.reference}
                    helperText={errors.reference}
                    onChange={handleChange}
                  />
                  {errors.reference && <FormHelperText error>{errors.reference}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth error={!!errors.type} sx={sx.formControl}>
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    labelId="type"
                    variant="standard"
                    name="type"
                    label="Type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    {types.map(type => (
                      <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
                </FormControl>

                <FormControl margin="normal" fullWidth error={!!errors.nature} sx={sx.formControl}>
                  <InputLabel id="nature">Nature du packaging</InputLabel>
                  <Select
                    labelId="nature"
                    variant="standard"
                    name="nature"
                    label="Nature du packaging"
                    value={values.nature}
                    onChange={handleChange}
                  >
                    {packagingNature.map(type => (
                      <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.nature && <FormHelperText error>{errors.nature}</FormHelperText>}
                </FormControl>


                <FormControl margin="normal" fullWidth error={!!errors.categories} sx={sx.formControl}>
                  <InputLabel id="categories">Catégorie</InputLabel>
                  <Select
                    labelId="categories"
                    variant="standard"
                    name="categories"
                    label="Catégorie"
                    value={values.categories}
                    onChange={handleChange}
                  >
                    {categories.map(type => (
                      <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.categories && <FormHelperText error>{errors.categories}</FormHelperText>}
                </FormControl>


                <FormControl margin="normal" fullWidth error={!!errors.brands} sx={sx.formControl}>
                  <InputLabel id="brands">Marques</InputLabel>
                  <Select
                    labelId="brands"
                    variant="standard"
                    name="brands"
                    label="Marques"
                    placeholder="Marques"
                    value={values.brands}
                    onChange={handleChange}
                    multiple
                  >
                    {getBrandsOptions().map(brand => (
                      <MenuItem key={brand.value} value={brand.value}>{brand.label}</MenuItem>
                    ))}
                  </Select>
                  {errors.brands && <FormHelperText error>{errors.brands}</FormHelperText>}
                </FormControl>

                <FormControl margin="normal" fullWidth sx={sx.formControl}>
                  <TextField
                    variant="standard"
                    name="price"
                    label="Prix"
                    value={values.price}
                    type="number"
                    error={!!errors.price}
                    helperText={errors.price}
                    disabled
                    InputProps={{
                      type: "number",
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                      disableUnderline: false,
                    }}
                  />
                </FormControl>
              </Stack>
              <ModalActions onCancel={onClose} />
            </Form>
          )
        }}
      </Formik>
    </ModalForm>
  )
}

export default PackagingResumeFormDialog
