import React from "react"
import { DatePicker } from "@mui/x-date-pickers"
import PlanningBrandSelector from "../../PlanningBrandSelector"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import { FormControl, TextField } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"
import { withStyles } from "@mui/styles"

const styles = {
    toolbarContainer: {
        marginTop: 15,
        display: "flex",
        alignItems: "flex-end",
        "& > *": {
            marginLeft: 10
        }
    },
    date: {
        minWidth: 140,
        maxWidth: 170
    },
    formControl: {
        minWidth: 120
    },
    select: {
        width: 180
    }
}

export const KitchenOutputFilters = (props) => {
    const {
        date,
        sectors,
        hubs,
        productTypes,
        brand,
        selectedSectors,
        selectedHubs,
        selectedProductTypes,
        onBrandClick,
        onDateChange,
        onSectorsChange,
        onHubsChange,
        onProductTypesChange,
        classes
    } = props

    const renderSelectResult = (selected, resources) => {
        if (selected.includes("ALL")) {
            return resources[0].label
        }
        else {
            return resources.filter(elem => selected.includes(elem.value)).map(elem => elem.label).join(", ")
        }
    }

    const renderData = (objects, selectedObjects) => {
        return (
            objects.map((item, index) =>
                <MenuItem
                    value={item.value}
                    key={index}
                >
                    <Checkbox
                        checked={selectedObjects.includes(item.value)}
                        color="secondary"
                    />
                    <ListItemText primary={item.label} />
                </MenuItem>
            )
        )
    }

    return (
        <div className={classes.toolbarContainer}>
            <PlanningBrandSelector
                brand={brand}
                onBrandClick={onBrandClick}
                activeBrandsOnly
            />
            <DatePicker
                disableCloseOnSelect={false}
                showToolbar={false}
                variant="inline"
                value={date}
                inputFormat="DD/MM/YYYY"
                onChange={onDateChange}
                renderInput={(params) => <TextField {...params} variant="standard" className={classes.date} />}
            />
            <FormControl className={classes.formControl} variant="standard">
                <InputLabel htmlFor="sector">Secteur</InputLabel>
                <Select
                    variant="standard"
                    className={classes.select}
                    multiple={true}
                    value={selectedSectors}
                    onChange={(e) => onSectorsChange(e.target.value)}
                    inputProps={{
                        name: "sector",
                        id: "sector",
                    }}
                    renderValue={
                        selected => renderSelectResult(selected, sectors)
                    }
                >
                    { renderData(sectors, selectedSectors) }
                </Select>
            </FormControl>
            <FormControl className={classes.formControl} variant="standard">
                <InputLabel htmlFor="hub">Hub</InputLabel>
                <Select
                    variant="standard"
                    className={classes.select}
                    multiple={true}
                    value={selectedHubs}
                    onChange={(e) => onHubsChange(e.target.value)}
                    inputProps={{
                        name: "hub",
                        id: "hub",
                    }}
                    renderValue={
                        selected => renderSelectResult(selected, hubs)
                    }
                >
                    { renderData(hubs, selectedHubs) }
                </Select>
            </FormControl>
            <FormControl className={classes.formControl} variant="standard">
                <InputLabel>Type</InputLabel>
                <Select
                    variant="standard"
                    className={classes.select}
                    multiple={true}
                    value={selectedProductTypes}
                    onChange={(e) => onProductTypesChange(e.target.value)}
                    renderValue={
                        selected => renderSelectResult(selected, productTypes)
                    }
                    inputProps={{
                        name: "type",
                        id: "type",
                    }}
                >
                    { renderData(productTypes, selectedProductTypes) }
                </Select>
            </FormControl>
        </div>
    )
}

export default withStyles(styles)(KitchenOutputFilters)
