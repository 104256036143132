import React from "react"
import {
    Grid,
    Table,
    TableBandHeader,
    TableHeaderRow,
    TableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui"
import {
    IntegratedSummary,
    SummaryState
} from "@devexpress/dx-react-grid"
import {
    summaryCalculator,
    TableSummaryRowCell,
    BandCell,
    HeaderRowCell,
    ColoredCell, SummaryCell
} from "./Monitoring"

export const SalesDatesTable = (props) => {
    const { data, targetsData, tableData } = props

    return (
        <Grid
            rows={data}
            columns={tableData?.salesDaysColumns}
        >
            <SummaryState
                totalItems={tableData?.totalSummaryItems}
            />
            <IntegratedSummary
                calculator={summaryCalculator}
            />

            <Table
                columnExtensions={tableData?.tableColumnExtensions}
                cellComponent={ColoredCell({targetsData: targetsData})}
            />
            <TableHeaderRow
                cellComponent={HeaderRowCell}
            />
            <TableBandHeader
                columnBands={tableData?.columnBands}
                cellComponent={BandCell()}
            />
            <TableSummaryRow
                itemComponent={TableSummaryRowCell}
                totalCellComponent={SummaryCell({targetsData: targetsData, data: data})}
            />
        </Grid>
    )
}

export default SalesDatesTable
