import { styled, TableCell, tableCellClasses } from "@mui/material"
import { COLORS } from "../../../../../utils"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    height: 70,
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: theme.palette.common.white,
  },
}))
