import {
    KFC_BRANDS
} from "../../utils"
import { formatProduct, formatFoodCost } from "../../utils/planning"

const defaultState = {
    targetsData: [],
    salesDatesData: [],
    koRows: [],
    hubs: [],
    brand: KFC_BRANDS[0].name,
    rightProduct: null,
    rightSelectedCard: null,
    foodCostData: [],
    productTypesByBrand: null
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "DASHBOARD_MONITORING_LOADED":
            return {
                ...state,
                targetsData: action.targetsData,
                salesDatesData: action.salesDatesData,
                foodCostData: formatFoodCost(action.foodCostData),
                date: action.date,
                productTypesByBrand: action.productTypesByBrand,
            }
        case "DASHBOARD_KO_LOADED":
            return {
                ...state,
                koRows: action.koRows,
                date: action.date || state.date,
                brand: action.brand,
                hubs: action.hubs
            }
        case "UPDATE_TARGETS_DATA":
            return {
                ...state,
                targetsData: action.targetsData
            }
        case "PLANNING_RIGHT_PRODUCT_LOADED_KO1":
            return {
                ...state,
                rightSelectedCard: action.rightSelectedCard
            }
        case "PLANNING_RIGHT_PRODUCT_LOADED_KO2":
            return {
                ...state,
                rightProduct: formatProduct(action.rightProduct)
            }
        case "PLANNING_RIGHT_PRODUCT_REMOVED_KO":
            return {
                ...state,
                rightProduct: null,
                rightSelectedCard: null
            }
        default:
            return {
                ...state
            }
    }
}

// ---------------------------------------- //
// --------------- SELECTOR --------------- //
// ---------------------------------------- //
export const getPlanningDashboardProductTypesByBrand = (state) => {
    return state.planningDashboard.productTypesByBrand
}