import React, {useEffect, useRef, useState} from "react"
import {Formik, Form, ErrorMessage} from "formik"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  FormHelperText,
  styled, Checkbox
} from "@mui/material"
import {DateSchema} from "../../utils/yupValidators"
import {COLORS} from "../../utils"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

const sx = {
  title: {
    pb: 0,
    fontSize: 16,
    color: COLORS.BLACK_500,
    fontWeight: 400,
    lineHeight: 1.375
  },
}

const PSEFusionStep2Dialog = ({ onClose, open, onConfirm, buttonText, title, selectedDate, reusableStepList }) => {
  const [disabledButton, setDisabledButton] = useState((!selectedDate || !reusableStepList))
  const [selectedReusableStepList, setSelectedReusableStepList] = useState(reusableStepList.map(reusableStep => reusableStep.objectId))
  const formikRef = useRef(null)

  useEffect(() => {
    setSelectedReusableStepList(reusableStepList.map(reusableStep => reusableStep.objectId))
  }, [reusableStepList])

  const handleConfirm = () => {
    if (!formikRef.current) return
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
  }

  const handleCancel = () => {
    onClose()
  }

  const onSelectChange = (e, setFieldValue) => {
    const values = e.target.value
    setFieldValue("selectedReusableStepList", values)
    setSelectedReusableStepList(values)
    setDisabledButton(values.length < 2) // 2 PSE need to be selected to merge them
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={sx.title}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Formik
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={DateSchema}
          initialValues={{ selectedReusableStepList: reusableStepList.map(reusableStep => reusableStep.objectId), date: selectedDate }}
          validateOnChange
        >
          {({ values, setFieldValue }) => {
            useEffect(() => {
              if (reusableStepList.length > 0 && values.selectedReusableStepList.length === 0) {
                setFieldValue("selectedReusableStepList", reusableStepList.map(reusableStep => reusableStep.objectId))
              }
            }, [reusableStepList])

            return (
              <Form>
                <Stack spacing={1}>
                  <InputLabel>Étapes fusionnées</InputLabel>
                  <Select
                    name="reusableStepList"
                    id="reusableStepList"
                    value={selectedReusableStepList}
                    sx={{ flex: 1, pointer: "cursor" }}
                    variant="standard"
                    multiple
                    onChange={(e) => onSelectChange(e, setFieldValue)}
                    fullWidth
                    renderValue={(reusableSelected) => {
                      if (reusableSelected.length === reusableStepList.length) {
                        return "Toutes"
                      } else {
                        return reusableSelected
                          .map((currentReusableSelected) =>
                            reusableStepList.find((reusableStep) => currentReusableSelected === reusableStep.objectId)?.name
                          )
                          .join(", ")
                      }
                    }}
                  >
                    {
                      reusableStepList.map((reusableStep, index) =>
                        <MenuItem key={index} value={reusableStep.objectId}>
                          <Checkbox
                            checked={
                              selectedReusableStepList && selectedReusableStepList.includes(reusableStep.objectId)
                            }
                            color="secondary"
                          />
                          {reusableStep.name}
                        </MenuItem>
                      )
                    }
                  </Select>
                  <ErrorMessage
                    name="reusableStepList"
                    render={(message) => (
                      <FormHelperText error>
                        {message}
                      </FormHelperText>
                    )}
                  />
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={disabledButton}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default PSEFusionStep2Dialog
