import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    container: {
        height: 39,
        width: "100%",  
        backgroundColor: COLORS.PRIMARY_COLOR,
        padding: "13px 23px 13px 23px"
    },
    name: {
        color: COLORS.WHITE,
        fontSize: 14,
        lineHeight: "14px",
        fontWeight: 700,
        textTransform: "uppercase"
    }
})

const OrderSupplierHeader = (props) => {
    const {
        supplierName
    } = props
    
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.name}>
                {supplierName}
            </Typography>
        </div>
    )
}

export default OrderSupplierHeader