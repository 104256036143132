import React, { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import withStyles from "@mui/styles/withStyles"
import CustomThemeProvider from "../components/CustomThemeProvider"
import Root from "./Root"
import useMediaQuery from "@mui/material/useMediaQuery"
import { updateScreen } from "../actions/Utils/app"

const styles = {
    container: {
        display: "flex"
    }
}

const App = (props) => {
    const {
        children
    } = props

    const dispatch = useDispatch()

    const isMobile = useMediaQuery("(max-width:600px) and (max-height:1024px)")
    const isTablet = useMediaQuery("(min-width:601px) and (max-width:1024px) and (max-height:1280px)")

    const screen = useMemo(() => {
        if (isMobile) {
            return "mobile"
        }
        if (isTablet) {
            return "tablet"
        }
        return "desktop"
    }, [isMobile, isTablet])

    const _updateScreen = (screen) => {
        dispatch(updateScreen(screen))
    }

    useEffect(() => {
        _updateScreen(screen)
    }, [screen])

    return (
        <CustomThemeProvider>
            <Root>{children}</Root>
        </CustomThemeProvider>
    )
}

export default withStyles(styles)(App)