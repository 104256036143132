import React, {
  useState,
  useEffect,
  useCallback
} from "react"

import Snackbar from "@mui/material/Snackbar"
import _isEqual from "lodash/isEqual"
import Fab from "@mui/material/Fab"
import Add from "@mui/icons-material/Add"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"

import { useSelector, useDispatch } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import {
  showSubcontractorProductTab,
  closeSubcontractorProductSnackBar,
  createProductResume,
  showAdvancedSearch,
} from "../../../actions/Subcontractor/subcontractorsProducts"

import {
  getBrandBy,
 ACTIVE_KFC_BRANDS
} from "../../../utils"

import { getRecipeStatusToString, RecipeStatus } from "../../../utils/recipes"
import MySnackbarContentWrapper from "../../../components/MySnackbarContentWrapper"
import ProductResumeCreate from "../../../components/Recipe/ProductResumeCreate"
import { getProductTypeOptionsSelector } from "../../../reducers/ProductTypes/productTypes"
import { getProductTypeLabelByProduct } from "../../../utils/productTypesUtils"
import DataGrid from "../../../components/DataGrid/DataGrid"
import { getSubcontractorProducts, getSubcontractorProductSuppliers } from "../../../reducers/Subcontractor/subcontractorsProducts"

const useStyles = makeStyles({
  brandLogoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  brandLogoLeft: {
    width: 25,
    marginRight: 5
  },
  column: {
    cursor: "pointer"
  },
  fab: {
    position: "fixed",
    marginLeft: 20,
    bottom: 20,
    width: "fit-content"
  },
})

const SubcontractorProducts = () => {
  const subcontractorsProducts = useSelector(getSubcontractorProducts)
  const suppliers = useSelector(getSubcontractorProductSuppliers)
  const subcontractorProductSnackBar = useSelector(state => state.subcontractorsProducts.subcontractorProductSnackBar, _isEqual)
  const productTypeOptions = useSelector(getProductTypeOptionsSelector)

  const [data, setData] = useState([])
  const [isCreating, setIsCreating] = useState(false)

  const columns = [
    {
      key: "id",
      label: "ID",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        strict: true
      },
      width: 120
    },
    {
      key: "uniqueCode",
      label: "Code unique",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text"
      },
      width: 100
    },
    {
      key: "name",
      label: "Nom technique",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "commercialName",
      label: "Nom commercial",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "text",
        fullText: true
      }
    },
    {
      key: "supplier",
      label: "Fournisseur",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        withSearch: true,
        options: Array.isArray(suppliers) ? suppliers.map((supplier) => ({
          key: supplier.id,
          value: supplier.get("name"),
          label: supplier.get("name")
        })) : []
      },
      width: 100
    },
    {
      key: "brand",
      label: "Marque",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: ACTIVE_KFC_BRANDS.map((brand) => ({
          key: brand.name,
          value: brand.name,
          label: brand.label
        }))
      },
      render: (brand) => {
        const completeBrand = getBrandBy("name", brand)
        return (
          <div className={classes.brandLogoContainer}>
            <img
              key={brand}
              src={completeBrand?.image}
              alt={`logo${completeBrand?.shortname}`}
              className={classes.brandLogoLeft}
            />
          </div>
        )
      },
      width: 100
    },
    {
      key: "type",
      label: "Type",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: productTypeOptions?.map((productType) => ({
          key: productType.value,
          value: productType.label,
          label: productType.label
        }))
      },
      width: 100
    },
    {
      key: "status",
      label: "Statut",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: RecipeStatus.map((status) => ({
          key: status.value,
          value: status.label,
          label: status.label
        }))
      },
      width: 100
    },
    {
      key: "state",
      label: "Etat",
      dataType: "text",
      sortable: false,
      filter: {
        filterable: true,
        type: "select",
        options: [
          { key: "active", value: "active", label: "Actif" },
          { key: "inactive", value: "inactive", label: "Inactif" },
        ]
      },
      render: (state) => {
        if (state === "active") {
          return <CheckCircleIcon sx={{ color: "#4bb200" }} />
        } else {
          return <DoDisturbOnIcon sx={{ color: "#c4c2c6" }} />
        }
      },
      width: 100
    },
    {
      key: "updatedAt",
      label: "Date de modification",
      dataType: "date",
      type: "date",
      sortable: true,
      filter: {
        filterable: false,
        type: "date"
      },
      width: 130
    }
  ]

  const dispatch = useDispatch()
  const classes = useStyles()

  const createData = (subcontractorProduct) => {
    const serializedSubcontractorProduct = {
      id: subcontractorProduct.id,
      uniqueCode: subcontractorProduct.get("uniqueCode"),
      name: subcontractorProduct.get("name") ?
        subcontractorProduct.get("name").get("name").toLowerCase() :
        "",
      commercialName: subcontractorProduct.get("commercialName") || "",
      supplier: subcontractorProduct.get("name") && subcontractorProduct.get("name").get("supplier") ?
        subcontractorProduct.get("name").get("supplier").get("name") :
        "",
      brand: subcontractorProduct.get("brand"),
      type: getProductTypeLabelByProduct(subcontractorProduct, productTypeOptions),
      status: getRecipeStatusToString(parseInt(subcontractorProduct.get("status"))),
      state: subcontractorProduct.get("isActive") === true ? "active" : "inactive",
      updatedAt: subcontractorProduct.updatedAt.toLocaleDateString("fr-FR", { year: "numeric", month: "numeric", day: "numeric" }),
    }

    return serializedSubcontractorProduct
  }

  const createDataObject = useCallback(() => {
    setData(subcontractorsProducts.map(createData))
  }, [subcontractorsProducts])

  useEffect(() => {
    createDataObject()
  }, [subcontractorsProducts])

  const _showSubcontractorProduct = (subcontractorProduct) => {
    dispatch(showSubcontractorProductTab("general", subcontractorProduct.id))
  }


  const _toggleCreate = () => {
    setIsCreating(!isCreating)
  }

  const _closeSubcontractorProductSnackBar = useCallback(() => closeSubcontractorProductSnackBar(subcontractorProductSnackBar.type), [subcontractorProductSnackBar])
  const _createProductResume = useCallback(values => dispatch(createProductResume(values)), [])
  const _showAdvancedSearch = useCallback(() => dispatch(showAdvancedSearch()), [])

  const moreMenus = [
    {
      onClick: _showAdvancedSearch,
      label: "Accéder à la recherche avancée"
    },
  ]

  return (
    <>
      <DataGrid
        title="Produits sous-traitants"
        columns={columns}
        data={data}
        withFilters
        rowLabel="produits sous-traitants"
        menus={moreMenus}
        onRowClick={_showSubcontractorProduct}
      />
      <ProductResumeCreate
        onClose={_toggleCreate}
        isCreating={isCreating}
        isSubcontractorProduct={true}
        onSaveProductResume={_createProductResume}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={subcontractorProductSnackBar.open}
        autoHideDuration={subcontractorProductSnackBar.duration}
        onClose={_closeSubcontractorProductSnackBar}
      >
        <MySnackbarContentWrapper
          onClose={_closeSubcontractorProductSnackBar}
          variant={subcontractorProductSnackBar.type}
          message={subcontractorProductSnackBar.message}
        />
      </Snackbar>
      <div className={classes.fab}>
        <Fab color="primary" onClick={_toggleCreate}>
          <Add />
        </Fab>
      </div>
    </>
  )
}

export default SubcontractorProducts
