import React from "react"
import { Table, TableHead, TableRow, TableBody, Button, TextField, styled } from "@mui/material"
import { PECommentHeaderCell, PETableCell } from "../StyledPackagingExecutionPreviewComponents"
import { FieldArray, Formik, Form } from "formik"
import { AddCircle } from "@mui/icons-material"
import { Delete } from "@mui/icons-material"
import { dateToTimestamp, timestampToDate } from "../../../utils"
import Parse from "parse"

const CREATED_AT_FORMAT = "DD/MM/YYYY - HH:mm"

const StyledButton = styled(Button)(() => ({
	"&.MuiButtonBase-root": {
		position: "absolute",
		top: 16,
		right: 40,
		padding: 0,
	}
}))

const PackagingExecutionComments = ({ comments = [], hoveredRow = 0, changeHoveredRow, editable = true, visible = true }) => {

	const _getDefaultComment = () => {
		return {
			isNew: true,
			comment: "",
			createdAt: dateToTimestamp(new Date()),
			user: Parse.User.current()?.toJSON(),
		}
	}

	const onSubmitComments = (values) => {
		console.log(values)
	}

	if (!visible) return null // to be continued

	return (
		<Formik
			initialValues={{ comments: comments }}
			onSubmit={onSubmitComments}
		>
			{({ values }) => {
				return (
					<Form style={{ width: "100%" }}>
						<Table sx={{ position: "relative" }}>
							<TableHead>
								<TableRow>
									<PECommentHeaderCell>Date</PECommentHeaderCell>
									<PECommentHeaderCell>Commentaire</PECommentHeaderCell>
									<PECommentHeaderCell>Membre</PECommentHeaderCell>
								</TableRow>
							</TableHead>
							<FieldArray name="comments">
								{({ push, form, remove }) => {
									return (
										<>
											<TableBody>
												{(values.comments || []).map((comment, index) => (
													<TableRow
														key={index}
														sx={{ position: "relative" }}
														onTouchStart={() => changeHoveredRow(index)}
														onMouseEnter={() => changeHoveredRow(index)}
														onMouseLeave={() => changeHoveredRow(-1)}
														onTouchEnd={() => changeHoveredRow(-1)}
													>
														<PETableCell>{timestampToDate(comment.createdAt, CREATED_AT_FORMAT)}</PETableCell>
														<PETableCell>
															{editable ? <TextField
																inputProps={{
																	style: {
																		fontSize: 20
																	}
																}}
																variant="standard"
																name={`comments[${index}].comment`}
																value={comment.comment}
																onChange={form.handleChange}
															/> :
																<span>{comment.comment}</span>
															}
														</PETableCell>
														<PETableCell>{comment.user?.username}</PETableCell>
														{hoveredRow === index && (
															<StyledButton
																onClick={() => remove(index)}
																disabled={!editable}>
																<Delete />
															</StyledButton>

														)}
													</TableRow>
												))}
											</TableBody>
											<StyledButton
												onClick={() => push(_getDefaultComment())}
												disabled={!editable}
											>
												<AddCircle />
											</StyledButton>
										</>
									)
								}}
							</FieldArray>
						</Table>
					</Form>
				)
			}}
		</Formik>
	)
}

export default PackagingExecutionComments