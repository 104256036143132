import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import CookingModesForm from "./CookingModesForm"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../utils"
import NewEditIcon from "../NewEditIcon"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.WHITE,
        marginTop: 24,
        borderRadius: 5,
        paddingBottom: 40,
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    },
    titleContainer: {
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    title: {
        padding: "20px 32px 16px 32px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16
    },
    singleValueContainer: {
        display: "flex",
        paddingLeft: 32,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
    },
    valueLabel: {
        color: COLORS.BLUEGREY_700,
        width: 200,
        minWidth: 170,
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        fontSize: 14,
    },
    value: {
        fontWeight: "bold",
        textAlign: "right",
        width: "100%",
        paddingRight: 10,
        fontSize: 14,
    }
}))

const SupplierItemSettingCookingModes = (props) => {
    const {
        supplierItem,
        onUpdateSupplierItem
    } = props

    const classes = useStyles()

    const [openEditModal, setOpenEditModal] = useState(false)

    const cookingModes = supplierItem.cookingModes

    const handleCloseModal = () => {
        setOpenEditModal(false)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Modes de cuisson
                </div>
                <NewEditIcon onClick={setOpenEditModal} />
            </div>
            {
                Array.isArray(cookingModes) && cookingModes.map((mode, index) => {
                    return mode.cookingMode
                    ? <div className={classes.singleValueContainer} key={index}>
                        <div className={classes.valueLabel}>
                            {mode.cookingMode.name}
                        </div>
                        <span className={classes.value}>
                            {mode.transformRate} %
                        </span>
                    </div>
                    : null
                })
            }
            {
                openEditModal
                ? <Dialog
                    open={openEditModal}
                    onClose={handleCloseModal}
                    aria-labelledby="form-dialog-title"
                >
                    <CookingModesForm
                        cookingModes={cookingModes}
                        onClose={handleCloseModal}
                        onUpdateSupplierItem={onUpdateSupplierItem}
                    />
                </Dialog>
                : null
            }
        </Paper>
    )
}

export default SupplierItemSettingCookingModes