import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import OrdersSiteSelector from "../../../components/Order/Orders/OrdersSiteSelector"
import OrdersHeaderTitle from "../../../components/Order/Orders/OrdersHeaderTitle"
import { showOrdersList } from "../../../actions/Orders/Orders"

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
    },
    headerBloc: {
        height: 64
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
    }
}))

const Orders = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const sites = useSelector(state => state.sites.sites)

    const onSiteSelected = (site) => {
        dispatch(showOrdersList(site.objectId))
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.headerBloc}>
                <GenericTopBarHeader
                    title={<OrdersHeaderTitle />}
                />
            </div>
            
            <div className={classes.container}>
                <OrdersSiteSelector
                    sites={sites}
                    onSubmit={onSiteSelected}
                />
            </div>
        </div>
    )
}

export default Orders