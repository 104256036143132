import React, { useState } from "react"
import {
    TableContainer,
    TableCell,
    Table,
    TableBody,
    TableRow
} from "@mui/material"
import { withStyles } from "@mui/styles"
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Timer as TimerIcon,
    OfflineBolt as OfflineBoltIcon
} from "@mui/icons-material"
import Button from "@mui/material/Button"
import { Formik, Form, Field, ErrorMessage } from "formik"
import cloneDeep from "lodash/cloneDeep"
import _get from "lodash/get"
import { RecipeDetailsStyles } from "../../styles"
import { getDefaultHeatingInstruction } from "../../actions/Utils/utils"
import FormikTextArea from "../Recipe/FormikTextArea"
import { getPackagings, getSubPackagings, getReusablePackagings, getReusableSubPackagings } from "../../reducers/Subcontractor/subcontractorsProducts"
import MultiSelectAutoCompleteNew from "../autoComplete/MultiSelectAutoCompleteNew"
import { emptySchema, getSubcontractorProductDetailsSchema } from "../../utils/yupValidators"
import { openUploadWidget } from "../../utils/cloudinary"
import { productType } from "../../utils/dispatchUtils"
import {
    recipePreparations,
    recipePreparationsBasic
} from "../../utils/recipes"
import { roundNumber } from "../../utils"
import CloudinaryImage from "../Image/CloudinaryImage"

const FIELD_TYPES = {
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    MULTISELECTAUTOCOMPLETE: "multiselectautocomplete",
    ICON: "icon",
    NUMBER: "number"
}
const HEATING_FIELDS = [
    { key: "power", fieldType: FIELD_TYPES.ICON, icon: <OfflineBoltIcon /> },
    { key: "duration", fieldType: FIELD_TYPES.ICON, icon: <TimerIcon /> },
    { key: "instructions", fieldType: FIELD_TYPES.TEXTAREA, icon: null, props: { placeholder: "Instructions" } }
]
const PREPARATION_VALUES = [
    { value: "1", label: "Se mange froid" },
    { value: "2", label: "Se mange chaud" },
    { value: "3", label: "Se mange chaud ou froid" }
]

const Details = (props) => {
    const {
        classes,
        editing,
        onEdit,
        bindSubmitAndResetForm,
        parseSubcontractorProduct,
        jsonSubcontractorProduct,
        onStopEdit,
        updateSubcontractorDetails,
        packagings,
        initialValues
    } = props

    const [schema, setSchema] = useState(parseSubcontractorProduct ? getSubcontractorProductDetailsSchema(parseSubcontractorProduct.get("status"), parseSubcontractorProduct.get("preparation")) : emptySchema)

    const _buildPackagings = (packagings, subPackagings = false, allowNull = false, reusable = false) => {
        let filteredpackagings = []
        if (reusable) {
            filteredpackagings = subPackagings
                ? getReusableSubPackagings(packagings, initialValues.brand)
                : getReusablePackagings(packagings, initialValues.brand)
        }
        else {
            filteredpackagings = subPackagings
                ? getSubPackagings(packagings, initialValues.brand)
                : getPackagings(packagings, initialValues.brand)
        }

        const result = filteredpackagings && Array.isArray(filteredpackagings)
            ? filteredpackagings.map(p => ({ value: p.id, label: p.get("name") }))
            : []

        if (allowNull) {
            result.unshift({ value: "", label: "Aucun" })
        }

        return result
    }

    const _onSubmit = (values) => {
        updateSubcontractorDetails(cloneDeep(recalculatePackagingCost(values)), parseSubcontractorProduct).then(onStopEdit)
    }

    const _updatePreparation = (values, value, recipeType, setFieldValue) => {
        const type = productType.find(item => item.value === recipeType)
        let preparation
        if (type.value === "DRINK") {
            preparation = recipePreparationsBasic.find(item => item.value === value)
        }
        else {
            preparation = recipePreparations.find(item => item.value === value)
        }

        setFieldValue("preparation", value, false) // No validation here, because there's a second setFieldValue after
        setFieldValue(
            "specialInstruction",
            `${type.adj} ${type.label.toLowerCase()} ${type.adj === "Ce" ? preparation.label.toLowerCase() : preparation.labelFem.toLowerCase()}`,
            false
        ) // No validation here, because there's a second setFieldValue after

        if (value !== "1") {
            setFieldValue("heatingInstructions", getDefaultHeatingInstruction(values.brand))
        }
        else {
            setFieldValue("heatingInstructions", null)
        }

        setSchema(getSubcontractorProductDetailsSchema(parseSubcontractorProduct.get("status"), value))
    }

    const _uploadAppImage = (values, setFieldValue) => {
        openUploadWidget({ tags: ["app"] }, (error, result) => {

            if (error) {
                console.error(error)
                return
            }
            const appImage = result.event === "success" ? { publicId: result.info.public_id } : null

            if (appImage !== null) {
                setFieldValue("appImage", appImage)
            }
        })
    }

    const _renderField = (fieldType, { options, icon, secondType, ...props }) => {
        let result = null
        
        switch (fieldType) {
            case FIELD_TYPES.TEXT:
            case FIELD_TYPES.NUMBER:
                result = <Field type={fieldType} {...props} />
                break
            case FIELD_TYPES.TEXTAREA:
                result = <Field component={FormikTextArea} {...props} />
                break
            case FIELD_TYPES.SELECT:
                result = (
                    <Field component="select" {...props}>
                        {
                            options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))
                        }
                    </Field>
                )
                break
            case FIELD_TYPES.MULTISELECTAUTOCOMPLETE:
                result = <Field component={MultiSelectAutoCompleteNew} options={options} {...props} />
                break
            case FIELD_TYPES.ICON:
                result = (
                    <div className={classes.iconField}>
                        {icon}
                        {_renderField(secondType, props)}
                    </div>
                )
                break
            default:
            // do nothing
        }

        return result
    }

    const _renderGenericRow = (values, fieldName, fieldType, label, errors, enableDefault = true, props = {}) => {
        const { placeholder } = props

        return (
            <TableRow>
                <TableCell className={classes.leftHeader}>{label}</TableCell>
                <TableCell>
                    {
                        enableDefault && (
                            <>
                                {_renderField(fieldType, {
                                    name: fieldName,
                                    disabled: !editing,
                                    className: _get(errors, fieldName, false) ? classes.fieldError : null,
                                    ...props
                                })}
                                <ErrorMessage
                                    name={fieldName}
                                    render={msg => <div className={classes.error}>{msg}</div>}
                                />
                            </>
                        )
                    }
                    {
                        !enableDefault && (
                            <>
                                {_renderField(fieldType, {
                                    name: `${fieldName}`,
                                    disabled: !editing,
                                    placeholder: undefined === placeholder ? "Par défaut" : placeholder,
                                    className: _get(errors, `${fieldName}`, false) ? classes.fieldError : null,
                                    ...props
                                })}
                                <ErrorMessage
                                    name={`${fieldName}`}
                                    render={msg => <div className={classes.error}>{msg}</div>}
                                />
                            </>
                        )
                    }
                </TableCell>
            </TableRow>
        )
    }

    const _renderHeatingRow = (values, type, label, errors, props = {}) =>
        <TableRow>
            <TableCell className={classes.leftHeader}>{label}</TableCell>
            <TableCell>
                {
                    HEATING_FIELDS.map(({ key, fieldType, icon }, index) =>
                        <div key={index} className={classes.iconFieldWrapper}>
                            {_renderField(fieldType, {
                                name: `heatingInstructions["${type}"]["${key}"]`,
                                disabled: !editing,
                                icon: icon,
                                secondType: null !== icon ? FIELD_TYPES.NUMBER : null,
                                className: _get(errors, `heatingInstructions["${type}"]["${key}"]`, false) ? classes.fieldError : null,
                                ...props[key]
                            })}
                            <ErrorMessage
                                name={`heatingInstructions["${type}"]["${key}"]`}
                                render={msg => <div className={classes.error}>{msg}</div>}
                            />
                        </div>
                    )
                }
            </TableCell>
        </TableRow>

    const _renderAppImage = (publicId, onDelete) =>
        <div className={classes.imageContainer}>
            <CloudinaryImage
                imageId={publicId}
                containerClassName={`${classes.image} subcontractor`}
                width={200}
                height={134}
            />
            {
                editing &&
                <div className={classes.imageOverlay}>
                    {
                        onDelete &&
                        <div className={classes.deleteImageIcon}>
                            <DeleteIcon onClick={onDelete} />
                        </div>
                    }
                </div>
            }
        </div>

    const _renderAppImageRow = (values, setFieldValue, label, errors) => {
        const appImage = values.appImage

        return (
            <TableRow>
                <TableCell className={classes.leftHeader}>{label}</TableCell>
                <TableCell>
                    {
                        editing
                            ?
                            appImage && appImage.publicId
                                ?
                                _renderAppImage(
                                    appImage.publicId,
                                    () => setFieldValue("appImage", null)
                                )
                                :
                                <div className={_get(errors, "appImage", false) ? classes.fieldError : null}>
                                    <ErrorMessage
                                        name="appImage"
                                        render={msg => <div className={classes.error}>{msg}</div>}
                                    />
                                    <span
                                        className={classes.addPhoto}
                                        onClick={() => _uploadAppImage(values, setFieldValue, null)}
                                    >
                                        Ajouter une photo
                                    </span>
                                </div>
                            :
                            appImage && appImage.publicId
                                ?
                                _renderAppImage(appImage.publicId, null)
                                :
                                <span className={classes.default}>Aucune image</span>
                    }
                </TableCell>
            </TableRow>
        )
    }

    const recalculatePackagingCost = (values) => {
        let packagingCost = 0
        let subPackagingCost = 0

        const value = values.subPackaging
        const packagingFormValues = values.packaging
        if (packagingFormValues) {
            const currentPackaging = getPackagings(packagings, values.brand).filter(packaging => packaging.id === packagingFormValues ? packaging : null)[0]
            if (currentPackaging) {
                packagingCost += parseFloat(currentPackaging.get("price"))
            }
        }

        const currentSubPackagings = getSubPackagings(packagings, values.brand).filter(subPackaging => {
            for (const i in value) {
                if (subPackaging.id === value[i].value) {
                    return subPackaging
                }
            }

            return null
        })

        for (const i in currentSubPackagings) {
            subPackagingCost += parseFloat(currentSubPackagings[i].get("price"))
        }

        const finalCost = packagingCost + subPackagingCost

        values.packagingCost = roundNumber(finalCost, 2)
        values.totalCost = roundNumber(values.subcontractorIngredientCost + finalCost)

        return values
    }

    const _renderSectionSeparator = (title, colSpan) =>
        <TableRow className={classes.sectionSeparator}>
            <TableCell className={classes.sectionTitle} colSpan={colSpan}>{title}</TableCell>
        </TableRow>

    let brandPackagings = null
    let brandSubPackagings = null
    let brandReusablePackagings = null
    let brandReusableSubPackagings = null

    if (packagings) {
        brandPackagings = _buildPackagings(packagings, false, true)
        brandSubPackagings = _buildPackagings(packagings, true, true)
        brandReusablePackagings = _buildPackagings(packagings, false, true, true)
        brandReusableSubPackagings = _buildPackagings(packagings, true, true, true)
    }

    return initialValues && (
        <Formik
            initialValues={{ ...initialValues, appImage: jsonSubcontractorProduct.appImage }}
            validationSchema={schema}
            enableReinitialize={false}
            onSubmit={_onSubmit}
        >
            {({ values, errors, submitForm, resetForm, setFieldValue }) => {
                bindSubmitAndResetForm(submitForm, resetForm)

                return (
                    <div className={classes.rootContainer}>
                        {
                            !editing && (
                                <div className={classes.header}>
                                    <p>{/* Here will be the last edition */}</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.editButton}
                                        endIcon={<EditIcon />}
                                        onClick={onEdit}
                                    >
                                        Editer la recette
                                    </Button>
                                </div>
                            )
                        }
                        <Form
                            className={classes.form}
                        >
                            <TableContainer className={`${classes.container} ${editing ? classes.marginTop : ""}`}>
                                <Table>
                                    <TableBody>
                                        {/* MARKETING */}
                                        {_renderSectionSeparator("Marketing", 12)}
                                        {_renderAppImageRow(values, setFieldValue, "Photo vue client", true, "app", errors)}
                                        {_renderGenericRow(values, "price", FIELD_TYPES.NUMBER, "Prix de vente", errors, true, {
                                            placeholder: values.price
                                        })}
                                        {_renderGenericRow(values, "dlc", FIELD_TYPES.NUMBER, "Date limite de consommation (en jours)", errors, true, {
                                            placeholder: values.dlc
                                        })}
                                        {_renderGenericRow(values, "description", FIELD_TYPES.TEXTAREA, "Description", errors, true, {
                                            placeholder: values.description
                                        })}
                                        {/* PACKAGING */}
                                        {_renderSectionSeparator("Packaging", 12)}
                                        {_renderGenericRow(values, "packaging", FIELD_TYPES.SELECT, "Packaging", errors, false, {
                                            options: brandPackagings
                                        })}
                                        {_renderGenericRow(values, "subPackaging", FIELD_TYPES.MULTISELECTAUTOCOMPLETE, "Sous-Packaging", errors, false, {
                                            options: brandSubPackagings,
                                            placeholder: "" // We don't want to show "par défaut" here
                                        })}
                                        {_renderGenericRow(values, "reusablePackaging", FIELD_TYPES.SELECT, "Packaging réutilisable", errors, false, {
                                            options: brandReusablePackagings
                                        })}
                                        {_renderGenericRow(values, "reusableSubPackagings", FIELD_TYPES.MULTISELECTAUTOCOMPLETE, "Sous-Packaging réutilisable", errors, false, {
                                            options: brandReusableSubPackagings,
                                            placeholder: "" // We don't want to show "par défaut" here
                                        })}
                                        {/* HEATING */}
                                        {_renderSectionSeparator("Chauffage", 12)}
                                        {_renderGenericRow(values, "preparation", FIELD_TYPES.SELECT, "Préparation", errors, true, {
                                            options: PREPARATION_VALUES,
                                            onChange: (e) => _updatePreparation(values, e.target.value, values.type, setFieldValue)
                                        })}
                                        {_renderGenericRow(values, "specialInstruction", FIELD_TYPES.TEXT, "Instruction spécifique", errors)}
                                        {
                                            "1" !== values.preparation &&
                                            <>
                                                {_renderHeatingRow(values, "microwave", "Micro-ondes", errors, {
                                                    power: { placeholder: _get(values, "heatingInstructions.microwave.power") },
                                                    duration: { placeholder: _get(values, "heatingInstructions.microwave.duration") },
                                                    instructions: { placeholder: _get(values, "heatingInstructions.microwave.instructions") }
                                                })}
                                                {_renderHeatingRow(values, "oven", "Four", errors, {
                                                    power: { placeholder: _get(values, "heatingInstructions.oven.power") },
                                                    duration: { placeholder: _get(values, "heatingInstructions.oven.duration") },
                                                    instructions: { placeholder: _get(values, "heatingInstructions.oven.instructions") }
                                                })}
                                                {_renderHeatingRow(values, "pan", "Poele", errors, {
                                                    power: { placeholder: _get(values, "heatingInstructions.pan.power") },
                                                    duration: { placeholder: _get(values, "heatingInstructions.pan.duration") },
                                                    instructions: { placeholder: _get(values, "heatingInstructions.pan.instructions") }
                                                })}
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Form>
                    </div>
                )
            }}
        </Formik>
    )
}

export default withStyles(RecipeDetailsStyles)(Details)