import React from "react"
import PropTypes from "prop-types"
import { MenuItem, FormControlLabel, Checkbox } from "@mui/material"
import { StyledSelect } from "./FormikSelectField"

const styles = {
  fontSize: "14px !important",
  fontWeight: "400px !important"
}

const FormikCheckboxSelectField = ({ form, field, ...props }) => {
  const { options = [] } = props || {}

  return (
    <StyledSelect
      {...field}
      {...props} // multiple (or not) is passed as a prop
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.value)
      }}
      customStyles={styles}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <FormControlLabel
            control={<Checkbox
              checked={field.value.indexOf(option.value) > -1}
              sx={{
                "& .MuiSvgIcon-root": styles
              }}
            />}
            value={option.value}
            label={option.label}
            sx={{
              "& .MuiTypography-body1": styles
            }}
          />
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default FormikCheckboxSelectField

FormikCheckboxSelectField.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  dynamicStyles: PropTypes.object
}