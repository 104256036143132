import React, { useCallback, useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import moment from "moment"
import { COLORS, roundCurrency } from "../../../utils"
import CreditNotesEnhancedTableHead from "../../table-utils/Order/Reception/CreditNotesEnhancedTableHead"
import FullScreenWrapper from "../../../components/FullScreenWrapper"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import SingleReceptionHeader from "../../../components/Order/Reception/SingleReceptionHeader"
import { generateStockUnitLabel, generateStockUnitWeightLabel } from "../../../utils/orderReception"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import Checkbox from "@mui/material/Checkbox"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import CreditNotesEnhancedTableToolbar from "../../table-utils/Order/Reception/CreditNotesEnhancedTableToolbar"
import { creditNotesReasons } from "../../../utils/creditNoteUtils"

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: COLORS.BACKGROUND_GREY,
        height: "100%"
    },
    headerBloc: {
        backgroundColor: COLORS.WHITE
    },
    title: {
        color: COLORS.BLACK,
        fontWeight: 500,
        fontSize: 27,
        padding: "20px 60px"
    },
    tableRow: {
        height: 58,
        backgroundColor: COLORS.WHITE
    },
    tableCell: {
        textAlign: "center",
        padding: 8
    }
}))

const SupplierItemCreditNotes = (props) => {
    const {
        creditNotes,
        goBack,
        selectedOrderReception,
        selectedOrderSupplierItem,
        actions
    } = props

    const classes = useStyles()

    const [data, setData] = useState([])
    const [selected, setSelected] = useState([])

    useEffect(() => {
        setData(formatData(creditNotes))
    }, [creditNotes])

    const formatData = (mapItem) => {
        const data = []
        Array.from(mapItem).map(([key, values]) => (
            values.forEach((note) => {
                if (key === "global") {
                    data.push({
                        id: note.creditNote.id,
                        lot: key,
                        creditNoteType: "Avoir sur une référence",
                        lotNumber: "",
                        dlc: "",
                        stockZone: "",
                        supplierItemAmount: note.creditNote.quantity,
                        reason: creditNotesReasons[note.creditNote.reason].label
                    })
                }
                else {
                    data.push({
                        id: note.creditNote.id,
                        lot: key,
                        creditNoteType: "Avoir sur un Lot",
                        lotNumber: note.lot.lotNumber,
                        dlc: moment(note.lot.dlc).format("DD/MM/YYYY"),
                        stockZone: note.lot.stockZone ? note.lot.stockZone.name : "",
                        supplierItemAmount: note.creditNote.quantity,
                        reason: creditNotesReasons[note.creditNote.reason].label,
                    })
                }
            })
        ))

        return data
    }

    const onSelectAll = (e) => {
        const newSelected = e && e.target && e.target.checked
            ? data.map(el => ({id: el.id, lot: el.lot}))
            : []
        setSelected(newSelected)
    }

    const handleSelect = (e, key) => {
        const target = selected.find(el => el.id === key.id && el.lot === key.lot)
        const selectedIndex = selected.indexOf(target)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, key)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const handleDelete = () => {
        actions({
            method: "DELETE_CREDIT_NOTE",
            data: selected
        })
        setSelected([])
    }

    const generateHeadings = useCallback(() => {
        return {
            title: selectedOrderSupplierItem.supplierItem.name,
            subtitle: supplierItemTypes[selectedOrderSupplierItem.supplierItem.type].label.toUpperCase()
        }
    }, [selectedOrderSupplierItem])

    const generateHeaderInfos = useCallback(() => {
        const stockUnitWeight = generateStockUnitWeightLabel(selectedOrderSupplierItem.supplierItem)
        const pricePerStockUnit = (selectedOrderSupplierItem.supplierItem.units && selectedOrderSupplierItem.supplierItem.units.stock && selectedOrderSupplierItem.supplierItem.units.stock.price)  || ""
        const unitType = generateStockUnitLabel(selectedOrderSupplierItem.supplierItem)
    
        return {
            reference: `${selectedOrderSupplierItem.supplierItem.reference}`,
            stockUnitWeight: `${stockUnitWeight}`,
            pricePerStockUnit: `${roundCurrency(pricePerStockUnit)} € / ${unitType}`
        }

    }, [selectedOrderSupplierItem])

    return (
        <FullScreenWrapper>
            {
                selectedOrderReception && selectedOrderSupplierItem && 
                    <div className={classes.container}>
                        <div className={classes.headerBloc}>
                            <GenericTopBarHeader
                                leftAction={<GenericHeaderReturnButton handleClick={goBack}/>}
                                title={<SingleReceptionHeader headings={generateHeadings()} infos={generateHeaderInfos()}/>}
                            />
                        </div>
                        <div>
                            <div className={classes.titleBloc}>
                                <Typography className={classes.title}>
                                    Récapitulatif des avoirs et réclamations
                                </Typography>
                            </div>
                            <div>
                                {
                                    selected.length > 0 &&
                                        <CreditNotesEnhancedTableToolbar 
                                            numSelected={selected.length}
                                            selected={selected}
                                            onDelete={handleDelete}
                                        />

                                }
                                <Table className={classes.table}>
                                    <CreditNotesEnhancedTableHead
                                        onSelectAll={onSelectAll}
                                        numSelected={selected.length}
                                        rowCount={data.length}
                                    />
                                    <TableBody>
                                        {
                                            data.map(el => {

                                                const isElementSelected = selected.filter(item => item.id === el.id).length > 0 && selected.filter(item => item.lot === el.lot).length > 0
                                                return (
                                                    <TableRow
                                                        onClick={(e) => handleSelect(e, {id: el.id, lot: el.lot})}
                                                        role="checkbox"
                                                        key={el.id}
                                                        selected={isElementSelected}
                                                        className={classes.tableRow}
                                                    >
                                                        <TableCell className={classes.tableCell}>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isElementSelected}
                                                            />
                                                        </TableCell>

                                                        {
                                                            Object.entries(el).map(([key, value], i) => {
                                                                if (key !== "id" && key !== "lot" ) {
                                                                    return <TableCell 
                                                                        key={i} 
                                                                        className={classes.tableCell}
                                                                    >
                                                                        {value}
                                                                    </TableCell>
                                                                }
                                                                return null
                                                            })
                                                        }

                                                    </TableRow>

                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
            }
        </FullScreenWrapper>
    )
}

export default SupplierItemCreditNotes