import React, { useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import {useDispatch, useSelector} from "react-redux"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import { ChangePasswordSchema } from "../../utils/yupValidators"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import { changePassword, showLogin, closeAppSnackBar } from "../../actions/Utils/app"
import _isEqual from "lodash/isEqual"
import InputLabel from "@mui/material/InputLabel"
import Input from "@mui/material/Input"
import FormControl from "@mui/material/FormControl"

const useStyles = makeStyles({
    card: {
        background: COLORS.WHITE,
        border: `0.5px solid ${COLORS.SITE_BORDER}`,
        boxSizing: "border-box",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        borderRadius: 4,
        minHeight: 289,
        minWidth: 363,
        paddingLeft: 43,
        paddingTop: 40,
        paddingRight: 54,
        "& .MuiCardContent-root": {
            padding: 0,
            width: 266
        }
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 10
    },
    cardAction: {
        justifyContent: "center",
        paddingTop: 40,
        paddingBottom: 18
    },
    textField: {
        marginTop: 20,
        width: "100%",
        fontSize: 16,
        "& input": {
            color: COLORS.GREY_DISABLED,
        }
    },
    label: {
        color: COLORS.GREY_DISABLED,
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7,
        fontSize: 14
    },
    baseLink: {
        fontSize: 14,
        letterSpacing: 0.25,
        color: COLORS.BLUE_PAPER_SELECTED,
        "& > a": {
            color: COLORS.BLUE_PAPER_SELECTED,
            textDecoration: "underlined"
        }
    },
    pwdInfo: {
        composes: "$baseLink",
        marginTop: 57,
        marginBottom: 49,
    },
    list: {
        paddingLeft: 30
    }
})

const ChangeAccountPassword = (props) => {
    const {
        location
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const _showLogin = () => dispatch(showLogin())

    useEffect(() => {
        if (!location.query.app || !location.query.token || !location.query.username) {
            _showLogin()
        }
    }, [])

    const appSnackBar = useSelector(state => state.app.appSnackBar, _isEqual)

    const _changePassword = values => {
        dispatch(changePassword({
            token: location.query.token,
            newPassword: values.password,
            username: location.query.username
        }))
    }

    const _closeSnackbar = () => {
        dispatch(closeAppSnackBar(appSnackBar.type))
    }

    return (
        <div className="flexCenter height100">
            <div className={classes.pwdInfo}>
                Veuillez saisir un nouveau mot de passe pour le compte :
                <ul className={classes.list}>
                    <li>Au moins 10 caractères</li>
                    <li>Au moins un caractère spécial</li>
                    <li>Au moins une majuscule</li>
                    <li>Au moins un chiffre</li>
                </ul>
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    password: "",
                    confirmPassword: ""
                }}
                onSubmit={_changePassword}
                validationSchema={ChangePasswordSchema}
            >
                {({
                    values,
                    errors,
                    handleChange
                }) => {
                    return (
                        <Form>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.title}>
                                        Changement de mot de passe
                                    </Typography>
                                    <FormControl className={classes.textField} variant="standard">
                                        <InputLabel
                                            className={classes.label}
                                            shrink={true}
                                        >
                                            Nouveau mot de passe
                                        </InputLabel>
                                        <Input
                                            name="password"
                                            label="Mot de passe"
                                            type="password"
                                            value={values.password}
                                            onChange={handleChange}
                                        />
                                        {
                                            errors.password ?
                                                (
                                                    <div className={classes.errorField}>{errors.password}</div>
                                                )
                                                : null
                                        }
                                    </FormControl>
                                    <FormControl className={classes.textField} variant="standard">
                                        <InputLabel
                                            className={classes.label}
                                            shrink={true}
                                        >
                                            Confirmation
                                        </InputLabel>
                                        <Input
                                            name="confirmPassword"
                                            label="Confirmation Mot de passe"
                                            type="password"
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                        />
                                        {
                                            errors.confirmPassword ?
                                                (
                                                    <div className={classes.errorField}>{errors.confirmPassword}</div>
                                                )
                                                : null
                                        }
                                    </FormControl>
                                </CardContent>
                                <CardActions className={classes.cardAction}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        MISE À JOUR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={appSnackBar.open}
                autoHideDuration={appSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={appSnackBar.type}
                    message={appSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default ChangeAccountPassword
