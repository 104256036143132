import React from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button } from "@mui/material"

const ReplaceSupplierItemsErrorModal = ({ onClose, open = false }) => {

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="duplicate-supplierItem-dialog"
			open={open}
		>
			<DialogTitle>
				Paramétrage manquant pour réaliser la substitution
			</DialogTitle>
			<DialogContent>
				<Typography variant={"span"}>
					Certains modes de transformation qui sont sur l'article à remplacer ne sont pas paramétrés sur l'article de remplacement. Paramétrez ces modes pour pouvoir faire la substitution.
				</Typography>
			</DialogContent>
			<DialogActions
			>
				<Button
					color="primary"
					onClick={onClose}
				>
					FERMER
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ReplaceSupplierItemsErrorModal