import React , { useState } from "react"
import { Stack, Typography } from "@mui/material"

import { StyledCard, StyledCardLabel, StyledCardTitle, StyledCardTitleContainer } from "../../../../components/styled/StyledPreview"
import NewEditIcon from "../../../../components/NewEditIcon"
import PackagingLifeCycleFormDialog from "./forms/PackagingLifeCycleFormDialog"
import { getCountryLabelByCode } from "../../../../utils/countries"

const YesOrNo = ({ value }) => {
  if (typeof value !== "boolean") {
    return <Typography>-</Typography>
  }

  return (
    value
      ? <Typography color="success.light">Oui</Typography>
      : <Typography color="error.light">Non</Typography>
  )
}

const PackagingLifeCycleResume = ({ packaging, onSubmit }) => {
  const [openModalForm, setOpenModalForm] = useState(false)

  const toggleDialog = () => setOpenModalForm(!openModalForm)

  const handleSubmitResume = async (values) => {
    await onSubmit(values)
    toggleDialog()
  }

  return (
    <StyledCard>
      <Stack spacing={3}>
        <StyledCardTitleContainer>
          <StyledCardTitle>Résumé cycle de vie</StyledCardTitle>
          <NewEditIcon onClick={toggleDialog} />
        </StyledCardTitleContainer>
        <Stack spacing={2}>
          <div className="flexRow">
            <StyledCardLabel>Pays de fabrication</StyledCardLabel>
            <Typography>
              {packaging.manufacturingCountry ? getCountryLabelByCode(packaging.manufacturingCountry) : "-"}
            </Typography>
          </div>
          <div className="flexRow">
            <StyledCardLabel>Réemployable</StyledCardLabel>
            <YesOrNo value={packaging.isReusable} />
          </div>
          <div className="flexRow">
            <StyledCardLabel>Recyclable en France</StyledCardLabel>
            <YesOrNo value={packaging.isRecyclableInFrance} />
          </div>
        </Stack>
      </Stack>

      <PackagingLifeCycleFormDialog
        open={openModalForm}
        onClose={toggleDialog}
        packaging={packaging}
        onSave={handleSubmitResume}
      />
    </StyledCard>
  )
}

export default PackagingLifeCycleResume
