import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import withStyles from "@mui/styles/withStyles"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import { recipeNutriscores } from "../../utils/recipes"
import { Form, Formik } from "formik"
import { COLORS } from "../../utils"

export const NutritionalScore = (props) => {
    const {
        classes,
        nutritionInformation,
        onSaveNutriScore,
        checkProductInCard,
        showModalProductInCard
    } = props
    const [isEditing, setIsEditing] = useState(false)

    const nutriscore = nutritionInformation && nutritionInformation.nutriscore
        ? nutritionInformation.nutriscore.toUpperCase()
        : ""
    const srcNutriscore  = `/img/nutriscore/nutriscore_${nutriscore}.png`

    const ecoscore = nutritionInformation && nutritionInformation.carboneScore
        ? nutritionInformation.carboneScore.toUpperCase()
        : "unknown"
    const srcEcoscore = `/img/ecoscore/ecoscore_${ecoscore}.svg`

    const renderNutriscore = () => {
        return recipeNutriscores.map(elem =>
            <MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>
        )
    }
    
    const checkThenEdit = async () => {
        const check = await checkProductInCard()
        
        if (check) {
            showModalProductInCard(edit)
            return
        }
        
        edit()
    }
    
    const edit = () => {
        setIsEditing(true)
    }
    
    const submitForm = (values) => {
        onSaveNutriScore(values, nutritionInformation)
        setIsEditing(false)
    }

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    Scores
                </div>
                <IconButton
                    className={classes.iconContainer}
                    disableRipple={true}
                    onClick={checkThenEdit}
                    size="large">
                    <EditIcon className={classes.icon}/>
                </IconButton>
            </div>
            <div className={classes.valueContainer}>
                {
                    nutriscore
                        ? <img src={srcNutriscore} alt="Nutriscore" className={classes.nutritionInformation}/>
                        : <span className={classes.nutriscoreLabel}>Nutriscore Inconnu</span>
                }
                {
                    ecoscore
                        ? <img src={srcEcoscore} alt="Nutriscore" className={classes.ecoscoreImg}/>
                        : <span className={classes.ecoscoreLabel}>Ecoscore inconnu</span>

                }
            </div>
            <Formik
                initialValues={nutritionInformation}
                onSubmit={submitForm}
            >
                {({
                      values,
                      setFieldValue,
                      handleSubmit
                  }) => {
                    return (
                        <Form>
                            <Dialog onClose={() => setIsEditing(false)} aria-labelledby="customized-dialog-title"
                                    open={isEditing}>
                                <DialogTitle id="customized-dialog-title" onClose={() => setIsEditing(false)}>
                                    Editer nutriscore et environnement score
                                </DialogTitle>
                                <DialogContent dividers>
                                    <FormControl margin="normal" className={classes.formControl}>
                                        <InputLabel> Nutriscore </InputLabel>
                                        <Select
                                            variant="standard"
                                            className={classes.select}
                                            name="nutriscore"
                                            value={values.nutriscore}
                                            onChange={(e) => setFieldValue("nutriscore", e.target.value)}
                                        >
                                            {renderNutriscore()}
                                        </Select>
                                    </FormControl>
                                    <FormControl margin="normal" className={classes.formControl}>
                                        <InputLabel> Environnement score </InputLabel>
                                        <Select
                                            variant="standard"
                                            className={classes.select}
                                            name="carboneScore"
                                            value={values.carboneScore}
                                            onChange={(e) => setFieldValue("carboneScore", e.target.value)}
                                        >
                                            {renderNutriscore()}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions classes={{root: classes.rootDialogActions}}>
                                    <Button
                                        onClick={() => setIsEditing(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Sauvegarder
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
         </Paper>
    )
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        padding: 16,
        marginTop: 16,
        borderRadius: 5
    },
    titleContainer: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 16,
        display: "flex",
        flex: "1 1",
        justifyContent: "space-between",
        alignItems: "center"
    },
    valueContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "4px 0px 4px"
    },
    unknownLabel: {
        marginTop: "auto",
        marginBottom: "auto"
    },
    nutriscoreLabel: {
        composes: "$unknownLabel",
        marginRight: 10
    },
    ecoscoreLabel: {
        composes: "$unknownLabel",
        marginLeft: 10
    },
    nutriscoreImg: {
        marginRight: 10
    },
    ecoscoreImg: {
        marginLeft: 10
    },
    iconContainer: {
        margin: 16,
        width: 38,
        height: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.PRIMARY_COLOR,
        borderRadius: 3,
        padding: 0,
        "&:hover": {
            backgroundColor: COLORS.LIGHT_PRIMARY_COLOR
        }
    },
    icon: {
        color: COLORS.WHITE,
    },
    rootDialogActions: {
        justifyContent: "space-between"
    },
    formControl: {
        minWidth: 260,
        marginLeft: "unset",
        marginTop: 16,
        display: "block"
    },
    select: {
        minWidth: 260
    }
}

export default withStyles(styles)(NutritionalScore)
