import React from "react"
import { COLORS } from "../../utils"
import Typography from "@mui/material/Typography"
import Icon from "@mui/material/Icon"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import { Stack } from "@mui/material"

const ReceptionWarehouseHeaderTitle = () => {
    return (
        <Stack direction="row" alignItems="center" spacing={1.2}>
            <Icon sx={{ color: COLORS.ORANGE_CREDIT_NOTE }}>
                <MeetingRoomIcon />
            </Icon>
            <Typography variant="h6" color="textPrimary" sx={{ color: COLORS.ORANGE_CREDIT_NOTE }}>
                Réception Entrepôt
            </Typography>
        </Stack>
    )
}

export default ReceptionWarehouseHeaderTitle
