import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Grid from "@mui/material/Grid"
import Divider from "@mui/material/Divider"
import RangeSlider from "../../../components/SearchAdvanced/Left/Slider"
import Button from "@mui/material/Button"
import { COLORS, KFC_BRANDS } from "../../../utils"
import MultipleTypeSelect from "../../../components/form/MultipleTypeSelect"
import MultipleSelect from "../../../components/SearchAdvanced/Left/MultipleSelect"
import SearchInput from "../../../components/SearchAdvanced/Left/SearchInput"
import { topFields, searchFields, subSearchFields, getInitialValues } from "../../../utils/searchAdvancedUtils"
import BrandsSelect from "../../../components/Recipe/BrandsSelect"
import { connect } from "react-redux"
import { Form, Formik } from "formik"
import { filterData } from "../../../actions/Products/SearchAdvanced"
import { FormControl, FormControlLabel, Switch } from "@mui/material"

const useStyles = makeStyles(() => ({
    paperLeft: {
        minHeight: "100vh",
        height: "100%",
        backgroundColor: COLORS.LEFT_FILTER_SEARCH,
        alignItems: "center",
        flexFlow: "column nowrap",
        "& > *" : {
            margin: "10px 0",
            minWidth: "80%",
            width: "80%"
        },
        "& > *:not(button):last-child": {
            paddingBottom: 100
        },
        "& .MuiFormControl-root": {
            width: "100%"
        }
    },
    divider: {
        height: 3,
        background: COLORS.WHITE,
        margin: "27px 0 0",
        width: "100%"
    },
    logo: {
        width: 16,
        marginRight: 10
    },
    form: {
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        width: "100%",
        "& > *": {
            margin: "10px 0 0",
            "& h2": {
                textTransform: "lowercase"
            },
            "& h2::first-letter": {
                textTransform: "uppercase"
            },
            "& .MuiInputLabel-animated": {
                textTransform: "capitalize"
            }
        }
    },
    switch: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 0
    },
    filterContainer: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        "& > *:not(.MuiDivider-root)": {
            margin: "10px 0 0",
            width: "80%"
        }
    },
    stickyContainer: {
        composes: "$filterContainer",
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor:  COLORS.LEFT_FILTER_SEARCH
    },
    title: {
        textAlign: "center",
        fontWeight: "normal"
    }
}))

const LeftSection = (props) => {
    const {
        searchOn,
        switchSearchOn,
        leftFilterValues,
        hasFilter,
        filterData,
        resetSearch,
        setLoading,
        setFilterValues
    } = props
    const classes = useStyles()
    const [showMore, setShowMore] = useState(false)
    
    const renderField = (field, values, handleChange, setFieldValue, index, position) => {
        if (field.type === "search") {
            return (
                <div key={`${position}-${index}`}>
                    {
                        field.label &&
                        <h2>{field.label.toUpperCase()}</h2>
                    }
                    <SearchInput
                        placeholder={field.placeholder}
                        field={field.field}
                        value={values[field.field]}
                        onInputChange={(event) => {
                            setFieldValue(field.field, event.target.value)
                        }}
                    />
                </div>
            )
        }
    
        if (field.type === "switch") {
            return (
                <FormControl component="fieldset" key={`${position}-${index}`}>
                    <FormControlLabel
                        value="start"
                        control={
                            <Switch
                                color="primary"
                                checked={searchOn.includes(field.field)}
                                onChange={() => switchSearchOn(field.field)}
                            />
                        }
                        label={field.label}
                        labelPlacement="start"
                        className={classes.switch}
                    />
                </FormControl>
            )
        }
        
        if (field.type === "multiselect") {
            let list = leftFilterValues[field.field]
            
            switch (field.field) {
                case "types":
                    return (
                        <div key={`${position}-${index}`}>
                            <MultipleTypeSelect
                                classes={classes}
                                value={values.types}
                                onChange={handleChange}
                            />
                        </div>
                    )
                case "brands":
                    return (
                        <div key={`${position}-${index}`}>
                            <BrandsSelect
                                id="brands"
                                name="brands"
                                value={values.brands}
                                onChange={handleChange}
                                productBrands={[{label:"Tous", name:"ALL"}, ...KFC_BRANDS]}
                                classes={classes}
                            />
                        </div>
                    )
                default:
                    const allValue = (field.field === "season")?
                        {label:"Toute l'année", value:"ALL"}:
                        {label:"Tous", value:"ALL"}
                    
                    if (field.field === "provider") list = [{label:"Interne", value:"interne"}, ...list]
                    
                    return (
                        <div key={`${position}-${index}`}>
                            <MultipleSelect
                                classes={classes}
                                value={values[field.field]}
                                values={[allValue, ...list]}
                                field={field.field}
                                label={field.label.toLowerCase().replace(" :", "")}
                                onChange={handleChange}
                            />
                        </div>
                    )
            }
        }
        if (field.type === "numeric") {
            return (
                <RangeSlider
                    key={`${position}-${index}`}
                    label={`${field.label.toUpperCase()}${(field.unit)?` (${field.unit})`:""}`}
                    marks={leftFilterValues[field.field]}
                    value={values[field.field]}
                    updateValue={(value) => setFieldValue(field.field, value)}
                />
            )
        }
    }
    
    const resetForm = (setFieldValue) => {
        const intialValues = getInitialValues()
        
        Object.keys(intialValues).map(key => {
            setFieldValue(key, intialValues[key])
            return key
        })
        
        resetSearch()
    }
    
    const submitForm = async (values) => {
        setLoading(true)
        setFilterValues(values)
        const filtered = []
        
        Object.keys(values).map(key => {
            if (values[key].length && values[key][0] !== "ALL") filtered.push(key)
            return key
        })
        
        await filterData(filtered)
        setLoading(false)
    }
    
    return (
        <Grid item lg={3}>
            <Grid
                container
                className={classes.paperLeft}
                spacing={0}
            >
                {
                    leftFilterValues &&
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={submitForm}
                    >
                        {({
                              values,
                              handleChange,
                              setFieldValue
                          }) => {
                            return (
                                <Form className={classes.form}>
                                    <div className={classes.stickyContainer}>
                                        <h1 className={classes.title}>Filtrer les produits</h1>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.advancedSearchButton}
                                            type="submit"
                                        >
                                            Rechercher
                                        </Button>
                                        {
                                            hasFilter &&
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                className={classes.advancedSearchButton}
                                                onClick={() => resetForm(setFieldValue)}
                                            >
                                                Réinitialiser les filtres
                                            </Button>
                                        }
                                        {
                                            topFields.map((field, index) => renderField(field, values, handleChange, setFieldValue, index, "top-field"))
                                        }
                                        <Divider variant="fullWidth" className={classes.divider} />
                                    </div>
                                    <div className={classes.filterContainer}>
                                        {
                                            searchFields.map((field, index) => renderField(field, values, handleChange, setFieldValue, index, "main-field"))
                                        }
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.advancedSearchButton}
                                            onClick={() => setShowMore(!showMore)}
                                        >
                                            {(showMore)?"-":"+"} de filtres
                                        </Button>
                                        {
                                            showMore &&
                                            subSearchFields.map((field, index) => renderField(field, values, handleChange, setFieldValue, index, "more-field"))
                                        }
                                    </div>
                                </Form>
                            )}}
                    </Formik>
                }
            </Grid>
        </Grid>
    )
}

export default connect(state => {
    return {
        leftFilterValues: state.searchAdvanced.leftFilterValues
    }
}, {
    filterData
})(LeftSection)
