import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import Button from "@mui/material/Button"
import { Formik, Form } from "formik"
import { COLORS } from "../../utils"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import DialogActions from "@mui/material/DialogActions"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import Input from "@mui/material/Input"
import {
    deliveryDaysList,
    openDayslist,
    minimumCommandUnits,
    deliveryLeadTimeList,
    sendingOrderTypes,
    getSupplierCommandInitialValues
} from "../../utils/suppliers"
import MenuItem from "@mui/material/MenuItem"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import { supplierCommandSchema } from "../../utils/yupValidators"
import RoundFormControlLabel from "../RoundFormControlLabel"
import { red, green } from "@mui/material/colors"

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 1007
        }
    },
    formControl: {
        marginLeft: "unset",
        marginTop: 16,
        paddingRight: 20,
        "& *": {
            fontSize: 14
        },
        "& .MuiInputLabel-formControl": {
            fontWeight: 500,
            color: COLORS.BLACK,
            height: 20,
            transform: "none",
            position: "relative"
        }
    },
    formControl50: {
        composes: "$formControl",
        width: "50%"
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    dialogTitleLabel: {
        fontWeight: 500,
        fontSize: 20,
        margin: "5px 0px 0px 0px"
    },
    rootDialogActions: {
        justifyContent: "space-between",
        padding: 15,
        marginBottom: 15
    },
    checkBoxList: {
        composes: "$formControl50",
        paddingRight: 100,
        marginTop: 0,
        "& > label": {
            marginTop: 0
        }
    },
    divider: {
        marginTop: 25
    },
    minimumCommand: {
        display: "inline-flex",
        flexFlow: "row"
    },
    unitedInput: {
        width: "35%",
        paddingRight: 15,
        textAlign: "right"
    },
    singleCheck: {
        width: "16%"
    },
    minimumCommandUnit: {
        width: 50
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    unit: {
        paddingTop: 9
    },
    switchBase: {
        color: red[400],
    },
    switchChecked: {
        color: green[400]
    },
    switchTrack: {
        backgroundColor: red[400],
    },
    switchTrackChecked: {
        backgroundColor: green[400]
    }
}))

export const CommandResumeEdit = (props) => {
    const {
        onClose,
        isEditing,
        jsonSupplier,
        onSaveSupplier
    } = props

    const classes = useStyles()

    const submitForm = async (values) => {
        await onSaveSupplier(values)
        onClose()
    }

    return (
        <Formik
            initialValues={getSupplierCommandInitialValues(jsonSupplier)}
            onSubmit={submitForm}
            validationSchema={supplierCommandSchema}
        >
            {({
                  values,
                  errors,
                  setFieldValue,
                  handleChange,
                  handleSubmit
            }) => {
                return (
                    <Form>
                        <Dialog
                            onClose={onClose}
                            aria-labelledby="customized-dialog-title"
                            open={isEditing}
                            className={classes.dialog}
                        >
                            <DialogTitle
                                id="customized-dialog-title"
                                onClose={onClose}
                                classes={{root: classes.dialogTitle}}>
                                <span className={classes.dialogTitleLabel}>Editer les informations liées aux commandes</span>
                            </DialogTitle>
                            <DialogContent dividers>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Minimum de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <div className={classes.minimumCommand}>
                                        <TextField
                                            variant="standard"
                                            name="minimumCommand"
                                            value={values.minimumCommand}
                                            onChange={handleChange}
                                            className={classes.unitedInput}
                                            type="number"
                                        />
                                        <Select
                                            variant="standard"
                                            name="minimumCommandUnit"
                                            value={values.minimumCommandUnit}
                                            onChange={handleChange}
                                            className={classes.minimumCommandUnit}
                                        >
                                            {
                                                minimumCommandUnits.map((unit, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={unit}
                                                    >
                                                        {unit}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    {
                                        errors.minimumCommand ?
                                            (
                                                <div className={classes.errorField}>{errors.minimumCommand}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Interdire une commande inférieur au montant minimum de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <RoundFormControlLabel
                                        classes={classes}
                                        checked={values.forbidMinimumCommand}
                                        onChange={() => setFieldValue("forbidMinimumCommand", !values.forbidMinimumCommand)}
                                        label=""
                                        labelPlacement="end"
                                    />
                                </FormControl>
                                <Divider className={classes.divider} />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Frais de port</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <div className={classes.minimumCommand}>
                                        <TextField
                                            variant="standard"
                                            name="shippingFees"
                                            value={values.shippingFees}
                                            onChange={handleChange}
                                            className={classes.unitedInput}
                                            type="number"
                                        />
                                        <span className={classes.unit}>€</span>
                                    </div>
                                    {
                                        errors.shippingFees ?
                                            (
                                                <div className={classes.errorField}>{errors.shippingFees}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Annuler les frais de port si la commande est supérieure au minimum de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <RoundFormControlLabel
                                        classes={classes}
                                        checked={values.allowShippingFeesCancel}
                                        onChange={() => setFieldValue("allowShippingFeesCancel", !values.allowShippingFeesCancel)}
                                        label=""
                                        labelPlacement="end"
                                    />
                                    {
                                        errors.allowShippingFeesCancel ?
                                            (
                                                <div className={classes.errorField}>{errors.allowShippingFeesCancel}</div>
                                            ) :
                                            null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Délai de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <Select
                                        variant="standard"
                                        name="deliveryLeadTime"
                                        value={values.deliveryLeadTime}
                                        onChange={handleChange}
                                    >
                                        {
                                            deliveryLeadTimeList.map((delay, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={delay.value}
                                                    >
                                                    {delay.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {errors.deliveryLeadTime && (<div className={classes.errorField}>{errors.deliveryLeadTime}</div>)}
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Heure limite de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <TextField
                                        variant="standard"
                                        name="orderHourLimit"
                                        value={values.orderHourLimit}
                                        onChange={handleChange}
                                        type="time"
                                    />
                                    {
                                        errors.orderHourLimit ?
                                            (
                                                <div className={classes.errorField}>{errors.orderHourLimit}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Autoriser une date de livraison inférieure au délai minimum</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <RoundFormControlLabel
                                        classes={classes}
                                        checked={values.allowEarlyDelivery}
                                        onChange={() => setFieldValue("allowEarlyDelivery", !values.allowEarlyDelivery)}
                                        label=""
                                        labelPlacement="end"
                                    />
                                    {
                                        errors.allowEarlyDelivery ?
                                            (
                                                <div className={classes.errorField}>{errors.allowEarlyDelivery}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <Divider className={classes.divider} />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Jour de livraison</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <Select
                                        variant="standard"
                                        multiple
                                        name="deliveryDays"
                                        value={values.deliveryDays}
                                        onChange={handleChange}
                                        input={<Input />}
                                        renderValue={(selected) => selected.map(el => deliveryDaysList[el - 1]).join(", ")}
                                    >
                                        {
                                            deliveryDaysList.map((day, index) => (
                                                <MenuItem key={index} value={index+1}>
                                                    <Checkbox checked={values.deliveryDays && values.deliveryDays.indexOf(index+1) > -1} color="secondary" />
                                                    <ListItemText primary={day} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Jour d'ouverture</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <Select
                                        variant="standard"
                                        name="openDays"
                                        value={values.openDays}
                                        onChange={handleChange}
                                    >
                                        {
                                            openDayslist.map((openDay, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={openDay.value}
                                                >
                                                    {openDay.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    {
                                        errors.openDays ?
                                            (
                                                <div className={classes.errorField}>{errors.openDays}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Mode d'envoi</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.checkBoxList}>
                                    {
                                        sendingOrderTypes.map((el, index) => (
                                            <RoundFormControlLabel
                                                key={index}
                                                classes={classes}
                                                checked={values.sendOrderMode[el.name]}
                                                onChange={() => setFieldValue(`sendOrderMode[${el.name}]`, !values.sendOrderMode[el.name])}
                                                label={el.label}
                                                labelPlacement="start"
                                            />
                                        ))
                                    }
                                </FormControl>
                                <Divider className={classes.divider} />
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Afficher un lien cliquable de confirmation de lecture dans l’email de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <RoundFormControlLabel
                                        classes={classes}
                                        checked={values.showLinkInEmail}
                                        onChange={() => setFieldValue("showLinkInEmail", !values.showLinkInEmail)}
                                        label=""
                                        labelPlacement="end"
                                    />
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <InputLabel>Précisions à imprimer sur les bons de commande</InputLabel>
                                </FormControl>
                                <FormControl variant="standard" className={classes.formControl50}>
                                    <TextField
                                        variant="standard"
                                        name="orderPrecision"
                                        value={values.orderPrecision}
                                        onChange={handleChange}
                                    />
                                    {
                                        errors.orderPrecision ?
                                            (
                                                <div className={classes.errorField}>{errors.orderPrecision}</div>
                                            )
                                            : null
                                    }
                                </FormControl>
                            </DialogContent>
                            <DialogActions classes={{root: classes.rootDialogActions}}>
                                <Button
                                    className={classes.button1}
                                    onClick={onClose}
                                    color="primary"
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    className={classes.button2}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    ENREGISTRER
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CommandResumeEdit
