import Parse from "parse"

const ProductPackaging = Parse.Object.extend("ProductsPackaging")

export async function getProductPackaging (date, packaging, toJSON = true) {
	try {
		const productsPackaging = (await new Parse.Query(ProductPackaging)
			.equalTo("packagingDate", date)
			.equalTo("packagingType", packaging)
			.include(["productionItems"])
			.find()) || []

		return toJSON ? productsPackaging.map(el => el.toJSON()) : productsPackaging
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function updateProductPackagingVolume(productPackagingId, volume) {
	try {
		const productPackaging = await new Parse.Query(ProductPackaging)
			.equalTo("objectId", productPackagingId)
			.first()

		if (productPackaging){
			productPackaging.set("warehouseProduction", volume)
			productPackaging.set("status", "DONE")

			return await productPackaging.save()
		}
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function updateProductPackagingStatus(productPackagingId, status, toJSON = true) {
	try {
		const productPackaging = await new Parse.Query(ProductPackaging)
			.equalTo("objectId", productPackagingId)
			.first()

		if (productPackaging){
			productPackaging.set("status", status)

			await productPackaging.save()
		}

		return productPackaging ? toJSON ? productPackaging.toJSON() : productPackaging : null
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function resetProductPackagingStatus(productPackagingId) {
	try {
		const productPackaging = await new Parse.Query(ProductPackaging)
			.equalTo("objectId", productPackagingId)
			.first()

		if (productPackaging && productPackaging.get("status") === "IN_PROGRESS") {
			productPackaging.set("status", "TODO")

			await productPackaging.save()
		}
	}
	catch (e) {
		return Promise.reject(e)
	}
}
