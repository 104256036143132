import React from "react"
import clsx from "clsx"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { lighten } from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import AddButton from "../../Rights/AddButton"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
    display: "flex",
  },
  title: {
    flex: "0 0 auto",
  },
}))

const UserEnhancedTableToolbar = (props) => {
  const { numSelected, deleteUsers, addUser } = props

  const classes = useStyles()

  const text = numSelected > 1 ? "Utilisateurs" : "Utilisateur"

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <div className={classes.title}>
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {text}
          </Typography>
        </div>
      )}
      {numSelected === 0 && (
        <Typography variant="h6" id="tableTitle">
          Utilisateurs
        </Typography>
      )}
      <div className={classes.spacer} />
      <div className="flexRow">
        <div className={classes.actions}>
          {numSelected > 0 && (
            <Tooltip title="Supprimer" disableInteractive>
              <IconButton
                aria-label="Supprimer"
                onClick={deleteUsers}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Ajouter" disableInteractive>
            <AddButton onClick={addUser} />
          </Tooltip>
        </div>
      </div>
    </Toolbar>
  )
}

export default UserEnhancedTableToolbar
