import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector, useDispatch } from "react-redux"
import { Modal } from "@mui/material"
import { checkTablet, COLORS } from "../../utils"
import {
  closeMultiReceptionWarehouseDetail,
  closeReceptionWarehouseSnackBar,
  updateProductPackaging,
  removeFromInProgress,
} from "../../actions/ReceptionWarehouse/receptionWarehouse"
import Snackbar from "@mui/material/Snackbar"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"
import _isEqual from "lodash/isEqual"
import { confirm } from "../../components/react-await-dialog/react-await-dialog"
import ReceptionWarehouseHeader from "../../components/ReceptionWarehouse/ReceptionWarehouseHeader"
import InputBlock from "../../components/ReceptionWarehouse/InputBlock"
import clsx from "clsx"
import { packagingNature } from "../../utils/packaging"
import { useCookies } from "react-cookie"
import { usePrevious } from "react-use"

const useClasses = makeStyles(() => ({
  singleReceptionWarehouseModalDetail: {
    backgroundColor: COLORS.WHITE,
    opacity: 1,
    outline: "none",
    overflow: "scroll",
    zIndex: 1300,
    height: "100%",
  },
  divider: {
    border: "1px solid #e8e8e8",
    width: "100%",
  },
  noOutline: {
    outline: "none",
  },
  contentBloc: {
    textAlign: "center",
    marginTop: 65,
    color: COLORS.BLACK,
    fontSize: 27,
    lineHeight: "32px",
  },
  confirmButtonBasic: {
    composes: "flexRow",
    height: 72,
    width: 410,
    fontSize: 20,
    textAlign: "center",
    alignItems: "center",
    fontWeight: 500,
    cursor: "pointer",
    borderRadius: 5,
    marginLeft: 25,
    marginRight: 25,
  },
  confirmButtonEmpty: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.DARK_GREY,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  confirmButtonFill: {
    composes: "$confirmButtonBasic",
    backgroundColor: COLORS.PRIMARY_COLOR,
    color: COLORS.TEXT_COLOR_FOR_PRIMARY,
  },
  buttonText: {
    margin: "0 auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  uniqueCode: {
    fontSize: 34,
  },
  doneIcon: {
    color: COLORS.PRIMARY_COLOR,
    fontSize: 70,
  },
}))

const MultiReceptionWarehouseDetail = () => {
  const date = useSelector((state) => state.receptionWarehouse.date)
  const selectedProductsPackaging = useSelector(
    (state) => state.receptionWarehouse.selectedProductsPackaging,
    _isEqual
  )
  const snackBar = useSelector(
    (state) => state.receptionWarehouse.snackBar,
    _isEqual
  )

  const classes = useClasses()
  const inputRef = useRef(null)
  const [index, setIndex] = useState(0)
  const wantedInputValue =
    selectedProductsPackaging.length > 0 &&
    selectedProductsPackaging[index] &&
    (selectedProductsPackaging[index].packagingProduction ||
      selectedProductsPackaging[index].expectedProduction)
  const [wantedInput, setWantedInput] = useState(wantedInputValue)
  const [inputValue, setInputValue] = useState(wantedInput === 0 ? "0" : "")
  const [isTablet, setIsTablet] = useState(checkTablet)
  const [cookies, setCookie, removeCookie] = useCookies([`toClean${date}`])

  const dispatch = useDispatch()
  const prevSelectedProductsPackaging = usePrevious(selectedProductsPackaging)

  const _storeOnCookie = useCallback(() => {
    if (selectedProductsPackaging) {
      setCookie(
        `toClean${date}`,
        selectedProductsPackaging.map((el) => el.objectId),
        { path: "/" }
      )
    }
  }, [selectedProductsPackaging])

  const cleanInProgressPackagings = (selectedProductsPackagingIds) => {
    dispatch(removeFromInProgress(selectedProductsPackagingIds))
  }

  // set isTablet state
  useLayoutEffect(() => {
    const checkisTablet = () => {
      setIsTablet(checkTablet())
    }

    if (cookies[`toClean${date}`]) {
      cleanInProgressPackagings(cookies[`toClean${date}`])
      removeCookie(`toClean${date}`, { path: "/" })
    }

    // title
    document.title = "Réception - KFC"

    // check size
    window.addEventListener("resize", checkisTablet)

    // check unmount
    window.addEventListener("unload", _storeOnCookie, false)

    // remove check
    return () => {
      window.removeEventListener("resize", checkisTablet)
      window.removeEventListener("unload", _storeOnCookie)
      document.title = "KFC"
    }
  }, [])

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }

    const wantedInputValue =
      selectedProductsPackaging.length > 0 &&
      selectedProductsPackaging[index] &&
      (selectedProductsPackaging[index].packagingProduction ||
        selectedProductsPackaging[index].expectedProduction)
    setWantedInput(wantedInputValue)
    setInputValue(wantedInput === 0 ? "0" : "")

    if (!_isEqual(selectedProductsPackaging, prevSelectedProductsPackaging)) {
      if (selectedProductsPackaging.length === 0) {
        _onClose()
      } else if (index + 1 > selectedProductsPackaging.length) {
        setIndex(0)
      }
    }
  }, [selectedProductsPackaging, index])

  const _onClose = useCallback(() => {
    dispatch(closeMultiReceptionWarehouseDetail(selectedProductsPackaging))
  }, [selectedProductsPackaging])

  const _onInputValueChange = (event) => {
    setInputValue(event.target.value)
  }

  const _confirmStep = useCallback(async () => {
    if (
      inputValue !== "" &&
      !inputValue.includes(",") &&
      !inputValue.includes(".")
    ) {
      if (
        wantedInput === parseFloat(inputValue) ||
        (await confirm({
          title: "Confirm",
          message:
            "La valeur indiquée est différente de celle attendue. Êtes vous sûr?",
          confirmText: "Oui",
        }))
      ) {
        await dispatch(
          updateProductPackaging(
            selectedProductsPackaging[index].objectId,
            parseFloat(inputValue),
            false
          )
        )
      }
    }
  }, [selectedProductsPackaging, inputValue, wantedInput, index])

  const _onKeyDown = (event) => {
    if (event && event.keyCode === 13) {
      _confirmStep()
      event.preventDefault()
    }
  }

  const _skip = () => {
    if (index + 1 < selectedProductsPackaging.length) {
      setIndex(index + 1)
    } else {
      setIndex(0)
    }
  }

  return (
    <Modal
      key="multi-reception-warehouse-detail-modal"
      BackdropProps={{ invisible: true }}
      className={classes.singleReceptionWarehouseModalDetail}
      open={true}
    >
      <div className={classes.noOutline}>
        {selectedProductsPackaging[index] && (
          <div>
            <ReceptionWarehouseHeader
              onClose={_onClose}
              selectedProductPackaging={selectedProductsPackaging[index]}
              date={date}
            />
            <div className={classes.divider} />
            <div className={classes.contentBloc}>
              <span>
                Entrez le total des{" "}
                <strong>
                  {selectedProductsPackaging[index] &&
                    selectedProductsPackaging[index].productionItems.length >
                      0 &&
                    selectedProductsPackaging[index].productionItems[0]
                      .uniqueCode}
                </strong>{" "}
                en packaging{" "}
                <strong>
                  {
                    packagingNature.find(
                      (el) =>
                        el.value ===
                        selectedProductsPackaging[index].packagingType
                    ).label
                  }
                </strong>{" "}
                en entrepôt
              </span>
              <InputBlock
                inputRef={inputRef}
                onKeyDown={_onKeyDown}
                onInputValueChange={_onInputValueChange}
                inputValue={inputValue}
                wantedInput={wantedInput}
              />
              <div className={classes.buttonContainer}>
                <div
                  className={clsx(
                    inputValue !== null && inputValue !== "" && !snackBar.open
                      ? classes.confirmButtonFill
                      : classes.confirmButtonEmpty,
                    { "is-tablet": isTablet }
                  )}
                  onClick={() => {
                    if (!snackBar.open) _confirmStep()
                  }}
                >
                  <span className={classes.buttonText}>CONFIRMER</span>
                </div>
                <div
                  className={clsx(classes.confirmButtonEmpty, {
                    "is-tablet": isTablet,
                  })}
                  onClick={() => {
                    if (!snackBar.open) _skip()
                  }}
                >
                  <span className={classes.buttonText}>PASSER</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snackBar.open}
          autoHideDuration={snackBar.duration}
          onClose={() =>
            dispatch(closeReceptionWarehouseSnackBar(snackBar.type))
          }
        >
          <MySnackbarContentWrapper
            onClose={() =>
              dispatch(closeReceptionWarehouseSnackBar(snackBar.type))
            }
            variant={snackBar.type}
            message={snackBar.message}
          />
        </Snackbar>
      </div>
    </Modal>
  )
}

export default MultiReceptionWarehouseDetail
