import React, { useState, useCallback } from "react"
import Button from "@mui/material/Button"
import { useDispatch } from "react-redux"

import { onCloseOrderReception, saveCreditNote } from "../../../actions/OrderReception/OrderReception"
import CreditNoteDelivery from "../../../components/Order/CreditNote/CreditNoteDelivery"

const generateModalHeader = (order) => {
    return {
        title: `Commande ${order.supplier.name}`,
        subtitle: `N° de commande : ${order.orderNumber}`
    }
}

const RefuseOrderReception = ({ orderReception }) => {
    const dispatch = useDispatch()
    const [showAddCreditNoteModal, setShowAddCreditNoteModal] = useState(false)


    const refuseReception = () => {
        setShowAddCreditNoteModal(true)
    }

    const addCreditNote = useCallback(async (data) => {
        const values = [{
            reason: data.reason,
            lot: null,
            quantity: null,
            comment: data.comment,
            images: data.images,
            amount: data.amount
        }]

        await dispatch(saveCreditNote(orderReception.objectId, values))
        setShowAddCreditNoteModal(false)
        dispatch(onCloseOrderReception({
            orderReceptionId: orderReception.objectId,
            totalAmount: data?.amount,
            isRefusedReception: true
        }))
    }, [orderReception])

    const generateCreditNoteData = () => {
        return {
            headerData: {
                goBack: () => setShowAddCreditNoteModal(false),
                headings: generateModalHeader(orderReception),
                infos: {
                    reference: null,
                    stockUnitWeight: null,
                    pricePerStockUnit: null
                }
            },
            orderData: orderReception,
            onSaveCreditNote: addCreditNote,
            disableFooter: showAddCreditNoteModal
        }
    }

    return (
        <>
            <Button
                color="secondary"
                onClick={refuseReception}
                sx={{ color: "#EB173E" }}
            >
                Refuser la réception
            </Button>
            {
                showAddCreditNoteModal &&
                <CreditNoteDelivery
                    {...generateCreditNoteData()}
                />
            }
        </>
    )
}


export default RefuseOrderReception