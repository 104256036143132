import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"
import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  styled,
} from "@mui/material"

const initialValues = {
  name: "",
}

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

export const AddMachineTypeModal = (props) => {
  const [errors, setErrors] = useState({ name: "" })
  const { onClose, open, onConfirm } = props

  const formikRef = useRef(null)

  const handleConfirm = () => {
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    if (!values.name) {
      setErrors({ name: "Nom obligatoire" })
      return
    }
    onConfirm(values)
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={{ pb: 0 }}>
        Ajouter un type de machines
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur }) => {
            return (
              <Form>
                <TextField
                  variant="standard"
                  label="Nom du type de machines"
                  name="name"
                  onChange={(value) => {
                    handleChange(value)
                    setErrors({ name: "" })
                  }}
                  onBlur={handleBlur}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                />
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Enregistrer
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default AddMachineTypeModal
