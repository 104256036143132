import React from "react"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import { COLORS } from "../../utils"
import clsx from "clsx"

const styleButton = {
  color: COLORS.WHITE,
  backgroundColor: COLORS.RED_FLAW,
}

const useStyles = makeStyles(() => ({
  validateButton: {
    ...styleButton,
    borderRadius: 4,
    marginTop: 32,
    "&.action-button": {
      width: 406,
    },
    "&.is-tablet": {
      marginTop: 47,
    },
    "&.is-flaw": {
      marginTop: 59,
    },
    "&:hover, &:disabled, &:hover:disabled": {
      ...styleButton,
      opacity: 1,
    },
  },
}))

const ButtonFlawAction = (props) => {
  const { children, isTablet, disabled, isFlaw } = props
  const classes = useStyles()

  return (
    <Button
      className={clsx(
        classes.validateButton,
        "action-button",
        { "is-tablet": isTablet },
        { "is-flaw": isFlaw }
      )}
      variant="contained"
      disableElevation
      disabled={disabled}
      type="submit"
    >
      {children}
    </Button>
  )
}

export default ButtonFlawAction
