import { getData } from ".."

const defaultState = {
    ingredient: null,
    nbGroups: 0,
    nbFamilies: 0,
    nbAllergens: 0,
    associatedSupplierItems: []
}

export default function (state = defaultState, action) {
    switch (action && action.type) {
        case "INGREDIENTS_CLASSIFICATIONS_LOADED":
            return {
                ...state,
                nbGroups: action.nbGroups,
                nbFamilies: action.nbFamilies,
                nbAllergens: action.nbAllergens
            }
        case "INGREDIENT_LOADED":
            return {
                ...state,
                ingredient: action.ingredient
            }
        case "INGREDIENT_SUPPLIER_ITEMS_LOADED":
            return {
                ...state,
                associatedSupplierItems: action.supplierItems
            }
        default:
            return {
                ...state
            }
    }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getIngredient(state, errorIfNotFound = false) {
    return getData(state, "ingredientsPage.ingredient", errorIfNotFound && "No ingredient found")
}

export function getAssociatedSupplierItems(state, errorIfNotFound = false) {
    return getData(state, "ingredientsPage.associatedSupplierItems", errorIfNotFound && "No associated supplier items found")
}