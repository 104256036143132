import React, { useState } from "react"
import { Field, ErrorMessage } from "formik"
import withStyles from "@mui/styles/withStyles"

import { Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DeleteIcon from "@mui/icons-material/Delete"

import { getDefaultSteps, getStepGrossWeight } from "../../utils/recipes"
import { roundNumber } from "../../utils"
import Ingredients from "./Ingredients"
import FormikTextArea from "./FormikTextArea"
import { RecipeStylesNew } from "../../styles"

import { COMPONENT_NAME as SECTION_COMPONENT_NAME } from "./Sections"
import clsx from "clsx"
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd"
import { COLORS } from "../../utils"

export const COMPONENT_NAME = "STEPS"
const Steps = props => {
  const {
      steps,
      sectionIndex,
      editing,
      onFieldFocus,
      onFieldBlur,
      rowHover,
      onRowHover,
      onRowBlur,
      deleteHover,
      onDeleteHover,
      onDeleteBlur,
      isFocus,
      setFieldValue,
      computationHandler,
      onKeyUp,
      onKeyDown,
      errors,
      setFieldTouched,
      classes,
      isInherited,
      status,
      updateSectionGrossWeight,
      setOpenGrossWeightChangeModal,
      setCurrentPart,
      fromGenericSections
  } = props

  const [currentDraggedStep, setCurrentDraggedStep] = useState(null)

  const _onDragStart = (event) => {
      let currentStep = null
      currentStep = steps.find(item => item.index === event.draggableId)

      setCurrentDraggedStep(currentStep)
  }

  const _onDragEnd = (event) => {
      const { destination, source } = event

      if (!destination) {
          setCurrentDraggedStep(null)
          
          return
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
          setCurrentDraggedStep(null)

          return
      }

      const newSteps = [...steps]
      newSteps.splice(source.index, 1)
      newSteps.splice(destination.index, 0, currentDraggedStep)

      setFieldValue(`sections[${sectionIndex}].steps`, [...newSteps])
  }

  const _stopPropagation = (event) => event && event.stopPropagation()

  const _isHover = (index) => rowHover
      && COMPONENT_NAME === rowHover.component
      && rowHover.index === index
      && rowHover.parentIndex === sectionIndex

  const _isDeleteHover = (index) => deleteHover &&
      ( // parenthesis not necessary thanks to operators priority but necesseray because of JS warning
          (
              COMPONENT_NAME === deleteHover.component
              && deleteHover.index === index
              && deleteHover.parentIndex === sectionIndex
          )
          || (
              SECTION_COMPONENT_NAME === deleteHover.component
              && deleteHover.index === sectionIndex
          )
      )

  const _isError = (index) => errors.sections
      && errors.sections[sectionIndex]
      && errors.sections[sectionIndex].steps
      && errors.sections[sectionIndex].steps[index]
      && errors.sections[sectionIndex].steps[index].description

  const _addStep = (index, event = null) => {
      if (isInherited) {
          onKeyDown({target: {name: `sections[${sectionIndex}].steps[${index}]`}})
          _stopPropagation(event)
          return
      }

      const _steps = [...steps]
      _steps.splice(index + 1, 0, getDefaultSteps())
      setFieldValue(`sections[${sectionIndex}].steps`, _steps)
      _stopPropagation(event)
  }

  const _removeStep = (index, event = null) => {
      if (isInherited) {
          onKeyDown({target: {name: `sections[${sectionIndex}].steps[${index}]`}})
          _stopPropagation(event)
          return
      }

      const _steps = [...steps]
      _steps.splice(index, 1)
      if (! _steps.length){
          _steps.splice(0, 0, getDefaultSteps())
      }

      setFieldValue(`sections[${sectionIndex}].steps`, _steps)

      _stopPropagation(event)
  }

  const _onGrossWeightChange = (index, e) => {
      if (!isInherited) {
          const originalGrossWeight = (steps[index].grossWeight ? steps[index].grossWeight : getStepGrossWeight(steps[index])) || 1
          const value = Number(e.target.value)
          const proportion = value !== 0 ? (value / originalGrossWeight) : 1

          steps[index].grossWeight = value
          setFieldTouched(`sections[${sectionIndex}].steps[${index}].grossWeight`)

          steps[index].ingredients.forEach((ingredient, ingredientIndex) => {
              ingredient.grossWeight *= proportion
              setFieldTouched(`sections[${sectionIndex}].steps[${index}].ingredients[${ingredientIndex}].grossWeight`)
              computationHandler(sectionIndex, index, ingredientIndex)
          })

          updateSectionGrossWeight(sectionIndex)
      }
  }

  const _updateStepGrossWeight = index => {
      if (!isInherited) {
          steps[index].grossWeight = getStepGrossWeight(steps[index])
          setFieldTouched(`sections[${sectionIndex}].steps[${index}].grossWeight`)
      }
  }

  const _onGrossWeightKeyDown = (e, step) => {
      onKeyDown(e)

      if (!isInherited && step.preventGrossWeightChange) {
          setCurrentPart(step)
          setOpenGrossWeightChangeModal(true)
      }
  }

  const _renderEditableStep = (step, index, isHover, isDeleteHover, error = false) => (
      <Grid
          container
          className={`${isHover ? classes.editHover : ""} ${error || isDeleteHover ? classes.stepLineError : ""} ${(isInherited)?classes.stepInherited:""}`}
          onClick={_stopPropagation}
          onMouseEnter={() => onRowHover(COMPONENT_NAME, index, sectionIndex)}
          onMouseLeave={onRowBlur}
      >
          <Grid container item xs={5} className={classes.natBorder} alignItems="center">
              <Grid item xs={1}>
                  {
                      isHover &&
                          <AddIcon
                              className={classes.addButtonStepAndIngredient}
                              onClick={(e) => _addStep(index, e)}
                          />
                  }
              </Grid>
              <Grid item xs={11} className={classes.stepDescription}>
                  {
                      isHover
                          ? (
                              <>
                                  <Field
                                      component={FormikTextArea}
                                      name={`sections[${sectionIndex}].steps[${index}].description`}
                                      onClick={_stopPropagation}
                                      onFocus={onFieldFocus}
                                      onBlur={onFieldBlur}
                                      onKeyUp={onKeyUp}
                                      onKeyDown={onKeyDown}
                                  />
                                  <ErrorMessage
                                      name={`sections[${sectionIndex}].steps[${index}].description`}
                                      render={msg => <div className={classes.error}>{msg}</div>}
                                  />
                              </>
                          ) : (
                              error
                                  ? (
                                      <ErrorMessage
                                          name={`sections[${sectionIndex}].steps[${index}].description`}
                                          render={msg => <div className={classes.error}>{msg}</div>}
                                      />
                                  )
                                  : (
                                      <div>
                                          <span className={classes.stepIndex}>
                                              {index + 1}.
                                          </span>
                                          {step.description}
                                      </div>
                                  )
                          )
                  }
              </Grid>
          </Grid>
          <Grid container item xs={3} className={classes.natBorder} alignContent="center">
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey800}`}>
                  { fromGenericSections && "--" }
                  {
                      !fromGenericSections && (isHover
                          ? (
                              <>
                                  <Field
                                      type="number"
                                      name={`sections[${sectionIndex}].steps[${index}].grossWeight`}
                                      value={ step.grossWeight ? step.grossWeight : getStepGrossWeight(step) }
                                      onChange={ e => _onGrossWeightChange(index, e) }
                                      onClick={_stopPropagation}
                                      className={classes.alignRight}
                                      onFocus={onFieldFocus}
                                      onBlur={onFieldBlur}
                                      onKeyUp={onKeyUp}
                                      onKeyDown={e => _onGrossWeightKeyDown(e, step)}
                                      onWheel= {(e) => e.target.blur()}
                                  />
                                  <ErrorMessage
                                      name={`sections[${sectionIndex}].steps[${index}].grossWeight`}
                                      render={msg => <div className={classes.error}>{msg}</div>}
                                  />
                              </>
                          ) : (
                              error
                                  ? (
                                      <ErrorMessage
                                          name={`sections[${sectionIndex}].steps[${index}].grossWeight`}
                                          render={msg => <div className={classes.error}>{msg}</div>}
                                      />
                                  )
                                  : (
                                      <div>
                                          { step.grossWeight ? step.grossWeight : getStepGrossWeight(step) }
                                      </div>
                                  )
                          )
                      )
                  }
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>--</Grid>
              <Grid item xs={4} className={
                  `${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.blueGrey500}`
              }>
                  {step.cost && `${roundNumber(step.cost, 3)}€`}
              </Grid>
          </Grid>
          <Grid container item xs={4} className={classes.natBorder} alignContent="center">
              <Grid item xs={4} className={
                  `${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding} ${classes.blueGrey800}`
              }>--</Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>--</Grid>
              <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.bold} ${classes.blueGrey500}`}>
                  {step.netWeight}
              </Grid>
              <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                  {
                      isHover &&
                          <DeleteIcon
                              className={error || isDeleteHover ? classes.deleteIconRed : classes.deleteIcon}
                              onClick={(e) => _removeStep(index, e)}
                              onMouseEnter={() => onDeleteHover(COMPONENT_NAME, index, sectionIndex)}
                              onMouseLeave={onDeleteBlur}
                          />
                  }
              </Grid>
          </Grid>
      </Grid>
  )

  const _renderViewStep = (step, index) => (
      <Grid container onClick={_stopPropagation}>
          <Grid container item xs={5} className={`${classes.natBorder} show`} alignItems="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={11} className={classes.stepDescription}>
                  <div>
                      <span className={classes.stepIndex}>
                          {index + 1}.
                      </span>
                      {step.description}
                  </div>
              </Grid>
          </Grid>
          <Grid container item xs={3} className={`${classes.natBorder} show`} alignContent="center">
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey800}`}>
                  { fromGenericSections && "--" }
                  { !fromGenericSections && (step.grossWeight ? step.grossWeight : getStepGrossWeight(step)) }
              </Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>--</Grid>
              <Grid item xs={4} className={
                  `${classes.alignRight} ${classes.natCell} ${classes.pricePadding} ${classes.blueGrey500}`
              }>
                  {step.cost && `${roundNumber(step.cost, 3)}€`}
              </Grid>
          </Grid>
          <Grid container item xs={4} className={`${classes.natBorder} show`} alignContent="center">
              <Grid item xs={4} className={
                  `${classes.alignLeft} ${classes.natCell} ${classes.cookingModePadding} ${classes.blueGrey800}`
              }>--</Grid>
              <Grid item xs={4} className={`${classes.alignRight} ${classes.natCell} ${classes.blueGrey500}`}>--</Grid>
              <Grid item xs={3} className={`${classes.alignRight} ${classes.natCell} ${classes.bold} ${classes.blueGrey500}`}>
                  {step.netWeight}
              </Grid>
              <Grid item xs={1} className={`${classes.alignRight} ${classes.natCell}`}>
                  {/* Space for delete action */}
              </Grid>
          </Grid>
      </Grid>
  )

  return (
      <DragDropContext
          isDragDroppable={true}
          onDragStart={_onDragStart}
          onDragEnd={_onDragEnd}
      >
          <Droppable
              isDroppable={true}
              droppableId={`stepDroppable${sectionIndex}`}
          >
              {
                  (provided) => (
                      <div ref={provided.innerRef}>
                          {
                              steps.map((step, index) => (
                                  <Draggable
                                      key={step.index}
                                      index={index}
                                      isDragDisabled={!editing || isFocus}
                                      draggableId={step.index}
                                  >
                                      {
                                          (provided, snapshot) => {

                                              const style = {
                                                  outline: snapshot.isDragging ? `2px solid ${COLORS.PRIMARY_COLOR}` : "none",
                                                  boxShadow: snapshot.isDragging ? `inset 0 0 10px ${COLORS.PRIMARY_COLOR}, 0 0 10px ${COLORS.PRIMARY_COLOR}` : "none",
                                                  ...provided.draggableProps.style
                                              }

                                              return <div 
                                                      {...provided.draggableProps}
                                                      style={style}
                                                      ref={provided.innerRef}
                                                  >
                                                      <Accordion
                                                          square={true}
                                                          elevation={0}
                                                          className={classes.stepHeader}
                                                          key={index}
                                                          classes={{ root: classes.expansionRoot, expanded: classes.expanded }}
                                                          defaultExpanded={true}
                                                      >
                                                          <AccordionSummary
                                                              className={clsx(classes.expansionPannelSummary, (isInherited)?classes.stepInherited:"")}
                                                              classes={{
                                                                  root: classes.summaryRootAndContentStep,
                                                                  content: classes.summaryRootAndContentStep,
                                                                  expanded: classes.expanded,
                                                                  focused: classes.focused,
                                                                  expandIcon: classes.expandIconStep
                                                              }}
                                                              {...provided.dragHandleProps}
                                                              expandIcon={<ExpandMoreIcon />}
                                                          >
                                                              {
                                                                  editing
                                                                      ? _renderEditableStep(step, index, _isHover(index), _isDeleteHover(index), _isError(index))
                                                                      : _renderViewStep(step, index)
                                                              }
                                                          </AccordionSummary>
                                                          <AccordionDetails classes={{ root: classes.detailsRoot }}>
                                                              <Ingredients
                                                                  editing={editing}
                                                                  ingredients={step.ingredients}
                                                                  stepIndex={index}
                                                                  sectionIndex={sectionIndex}
                                                                  onFieldFocus={onFieldFocus}
                                                                  onFieldBlur={onFieldBlur}
                                                                  rowHover={rowHover}
                                                                  onRowHover={onRowHover}
                                                                  onRowBlur={onRowBlur}
                                                                  deleteHover={deleteHover}
                                                                  isFocus={isFocus}
                                                                  onDeleteHover={onDeleteHover}
                                                                  onDeleteBlur={onDeleteBlur}
                                                                  setFieldValue={setFieldValue}
                                                                  computationHandler={computationHandler}
                                                                  onKeyUp={onKeyUp}
                                                                  onKeyDown={onKeyDown}
                                                                  setFieldTouched={setFieldTouched}
                                                                  errors={errors}
                                                                  isInherited={isInherited}
                                                                  status={status}
                                                                  updateStepGrossWeight={_updateStepGrossWeight}
                                                                  updateSectionGrossWeight={updateSectionGrossWeight}
                                                              />
                                                          </AccordionDetails>
                                                      </Accordion>
                                                  </div>
                                          }
                                      }
                                  </Draggable>
                              ))
                          }
                          {provided.placeholder}
                      </div>
                  )}
          </Droppable>
      </DragDropContext>
  )
}
export default withStyles(RecipeStylesNew)(Steps)
