import React from "react"

import { Box, Typography, styled } from "@mui/material"

import MealPlannerCartSection from "../MealPlanner/MealPlannerCartSection"
import { COLORS } from "../../utils"

const EmptyCartText = styled(Typography)({
    color: COLORS.BLACK_500,
    fontSize: 18,
    fontWeight: 700,
    textAlign: "center"
})

const CartContainer = styled(Box)({
    backgroundColor: "white",
    height: "100%",
    overflowY: "auto"
})

const WeeklyMealPlannerCart = ({ selection = [], productionSchemas = [], removeProduct = () => { } }) => {

    const emptyCart = !selection || !selection.length || !Array.isArray(selection)

    const _handleDelete = (mealIds) => {
        let foundMeals = []
        selection.forEach(({ products }) => {
            products.forEach(product => {
                if (mealIds.includes(product.itemId)) {
                    foundMeals.push(product)
                }
            })
        })
        if (foundMeals.length) {
            foundMeals.forEach(meal => {
                removeProduct(meal)
            })
        }
    }

    return (
        <CartContainer>
            {emptyCart && <Box sx={{ p: "24px" }}>
                <EmptyCartText>Vous n’avez pas de produits dans votre sélection</EmptyCartText>
            </Box>}
            {!emptyCart && selection.map(({ productionSchemaId, products }, index) => {
                const foundProductionSchema = productionSchemas.find(schema => schema.objectId === productionSchemaId)
                return (
                    <MealPlannerCartSection
                        items={selection}
                        sectionItems={products}
                        title={foundProductionSchema.name}
                        key={index}
                        handleDelete={_handleDelete}
                        isWeekly
                    />)
            })}
        </CartContainer>
    )
}

export default WeeklyMealPlannerCart