import { actionWithLoader, getReplacingPath, onEnter, push } from "../../Utils/utils"
import { getFamiliesIngredients } from "../../../parseManager/ingredients/classifications/family/parseFamilyManager"
import {
    getGroupIngredientWithId,
    getGroupsIngredients,
} from "../../../parseManager/ingredients/classifications/group/parseGroupManager"
import {
    createACommercialName,
    getCommercialNames,
    deleteCommercialNameWithId,
    getIngredientWithId,
    ingredientKeysToIgnore
} from "../../../parseManager/ingredients/internal/parseCommercialNameManager"
import { getServerUrl, replaceCSVSeparatorInText } from "../../../utils"
import { axiosCall } from "../../../utils/axiosUtils"
import { commercialNamesBaseCsvHeader, conservationModes, getIngredientLabel, labels, origins } from "../../../utils/commercialNamesUtils"
import { exportCSVFile } from "../../Products/SearchAdvanced"
import { mapSeasonsToPeriodsLabels } from "../../../utils/recipes"
import { getAllergensWithIds } from "../../../parseManager/ingredients/classifications/allergen/parseAllergenManager"
import { isEmpty } from "lodash"
import { getSupplierWithId } from "../../../parseManager/products/resources/supplier/parseSupplierManager"
import { getSubcontractorIngredientWithId } from "../../../parseManager/ingredients/external/parseSubcontractorCommercialNameManager"
import { getSupplierItemsAssociatedToCommercialName } from "../../../parseManager/suppliers/supplierItems/parseSupplierItemManager"

export function loadIngredient(ingredientId) {
    return async dispatch => {
        if (ingredientId) {
            const ingredient = await getIngredientWithId(ingredientId, ["group.family", "allergens"], true)
            dispatch({
                type: "INGREDIENT_LOADED",
                ingredient
            })
        }
    }
}

export function loadCommercialNames() {
    return actionWithLoader(async (dispatch) => {
        const commercialNamesList = await getCommercialNames({ includes: ["group.family", "allergens"], sortBy: "updatedAt", sortDirection: "desc" })

        dispatch({
            type: "COMMERCIAL_NAME_LOADED",
            commercialNamesList,
        })
    })
}

export function loadCommercialNamesOptions() {
    return actionWithLoader(async (dispatch) => {
        const commercialNamesList = await getCommercialNames({ sortBy: "name", sortDirection: "asc" })
        const commercialNamesOptions = commercialNamesList.map(commercialName => ({
            key: commercialName.objectId,
            label: commercialName.name,
        }))
        dispatch({
            type: "COMMERCIAL_NAMES_OPTIONS_LOADED",
            commercialNamesOptions,
        })
    })
}

export function loadFamiliesIngredients() {
    return actionWithLoader(async (dispatch) => {
        const familiesIngredients = await getFamiliesIngredients()

        dispatch({
            type: "FAMILIES_INGREDIENTS_LOADED",
            familiesIngredients
        })
    })
}

export function loadSupplierItemsAssociatedToCommercialName(commercialNameId) {
    return actionWithLoader(async (dispatch) => {
        const supplierItems = await getSupplierItemsAssociatedToCommercialName(commercialNameId, ["name"])
        dispatch({
            type: "INGREDIENT_SUPPLIER_ITEMS_LOADED",
            supplierItems
        })
    })
}

export function loadGroupsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const groupsIngredients = await getGroupsIngredients([])

        dispatch({
            type: "GROUPS_INGREDIENTS_LOADED",
            groupsIngredients
        })
    })
}

export function deleteCommercialName(id) {
    return actionWithLoader(async (dispatch) => {
        await deleteCommercialNameWithId(id)

        dispatch(loadCommercialNames())
    })
}

export function createCommercialName(values, dispatch, { commercialName }) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const newCommercialName = await createACommercialName(values, commercialName)

        if (state.routing.locationBeforeTransitions.state && state.routing.locationBeforeTransitions.state.returnPath === "edit") {
            dispatch(showCommercialName(newCommercialName.objectId))
        }
        else {
            dispatch(loadCommercialNames())
            dispatch(showInternalCommercialNames())
        }
    })
}

export function updateIngredientDetails(ingredientId, values, ingredientType) {
    return actionWithLoader(async (dispatch) => {
        const ingredient = ingredientType === "interne" ? await getIngredientWithId(ingredientId, [], false) : await getSubcontractorIngredientWithId(ingredientId, [], false)
        if (ingredient) {
            /* Supplier - subcontractor ingredients only */
            if (values.supplier) {
                const supplier = await getSupplierWithId(values.supplier, [], false)
                ingredient.set("supplier", supplier)
            }

            /* Group */
            if (values.group) {
                const group = await getGroupIngredientWithId(values.group) // an id
                ingredient.set("group", group || null)
            }

            Object.keys(values).forEach(function (key) {
                if (!ingredientKeysToIgnore.includes(key)) {
                    const val = values[key]
                    ingredient.set(key, val)
                }
            })

            await ingredient.save()
            dispatch({
                type: "INGREDIENT_LOADED",
                ingredient: ingredient.toJSON()
            })
        }
    })
}

export function updateIngredientAllergens(ingredientId, values, ingredientType) {
    return actionWithLoader(async (dispatch) => {
        const ingredient = ingredientType === "interne" ? await getIngredientWithId(ingredientId, [], false) : await getSubcontractorIngredientWithId(ingredientId, [], false)
        if (ingredient) {
            /* Group */
            if (values.allergens && !isEmpty(values.allergens)) {
                const allergensIds = values.allergens.map(allergen => allergen.objectId)
                const allergens = await getAllergensWithIds(allergensIds, [], false)
                ingredient.set("allergens", allergens ? allergens : null)
            } else {
                ingredient.set("allergens", [])
            }

            await ingredient.save()
            dispatch({
                type: "INGREDIENT_LOADED",
                ingredient: ingredient.toJSON()
            })
        }
    })
}

export function updateIngredientSourcing(ingredientId, values, ingredientType) {
    return actionWithLoader(async (dispatch) => {
        const ingredient = ingredientType === "interne" ? await getIngredientWithId(ingredientId, [], false) : await getSubcontractorIngredientWithId(ingredientId, [], false)
        if (ingredient) {
            if (values.firstOrigin !== null) {
                ingredient.set("firstOrigin", values.firstOrigin)
                if (values.secondOrigin !== null) {
                    ingredient.set("secondOrigin", values.secondOrigin)
                }
            } else {
                ingredient.unset("firstOrigin")
                ingredient.unset("secondOrigin")
            }

            if (values.firstOrigin !== null && values.firstOriginAverage) {
                ingredient.set("firstOriginAverage", parseFloat(values.firstOriginAverage))
                if (values.firstOriginAverage !== 100) {
                    ingredient.set("secondOriginAverage", parseFloat(values.secondOriginAverage))
                } else {
                    ingredient.set("secondOriginAverage", 0)
                }
            } else {
                ingredient.unset("firstOriginAverage")
                ingredient.unset("secondOriginAverage")
            }

            if (values.conservationMode !== null) {
                ingredient.set("conservationMode", values.conservationMode)
            } else {
                ingredient.unset("conservationMode")
            }

            if (values.label !== null) {
                ingredient.set("label", values.label)
            } else {
                ingredient.unset("label")
            }

            await ingredient.save()
            dispatch({
                type: "INGREDIENT_LOADED",
                ingredient: ingredient.toJSON()
            })
        }
    })
}

export function loadCommercialNamesData() {
    return actionWithLoader(async (dispatch) => {
        await Promise.all([
            dispatch(loadCommercialNames()),
            dispatch(loadFamiliesIngredients()),
            dispatch(loadGroupsIngredients())
        ])
    })
}

export function onEnterCommercialNames(store) {
    return onEnter({
        store,
        actionThunk: loadCommercialNamesData,
        getReplacingPath: getReplacingPath({ needUser: true })
    })
}

export function onEnterSingleCommercialName(store) {
    return async (nextState, replace, callback) => {
        const commercialNameId = nextState.params?.id
        await Promise.all([
            store.dispatch(loadIngredient(commercialNameId)),
            store.dispatch(loadFamiliesIngredients()),
            store.dispatch(loadGroupsIngredients()),
            store.dispatch(loadSupplierItemsAssociatedToCommercialName(commercialNameId))
        ])

        callback()
    }
}

export function showInternalCommercialNames() {
    return push("/ingredients/internals")
}

export function showCommercialName(commercialNameId, location) {
    if (commercialNameId) {
        return push(`/ingredients/internal/${commercialNameId}`, location)
    }
}

export function showCommercialNameForm(commercialNameId, location) {
    if (commercialNameId) {
        return push(`/ingredients/internal/${commercialNameId}/edit`, location)
    }
    else {
        return push("/ingredients/internal/", "/ingredients/internals/")
    }
}

export function synchroEtiquettableIngredient() {
    return actionWithLoader(async (dispatch) => {
        
        const url = `${getServerUrl()}/ingredients/synchroRecipeIngredientsEtiquettable`

        const result = await axiosCall("POST", url, {}, { "Content-Type": "application/json" })

        if (result.status === 200) {
            dispatch({
                type: "SYNCHRO_COMMERCIAL_NAME_ETIQUETTABLE",
                commercialNameSnackbar: { open: true, type: "success", message: "La synchro des ingrédients a réussi", duration: 5000 }
            })
        }
        else {
            dispatch({
                type: "SYNCHRO_COMMERCIAL_NAME_ETIQUETTABLE",
                commercialNameSnackbar: { open: true, type: "error", message: "La synchro des ingrédients a échouée", duration: 5000 }
            })
        }
    })
}

export function closeCommercialNameSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "CLOSE_COMMERCIAL_NAME_SNACKBAR",
            CommercialNameSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
        })
        dispatch(loadCommercialNames())
    })
}

export const extractIngredientsBaseCsv = () => {
    return actionWithLoader(async (dispatch) => {
        try {
            const commercialNames = await getCommercialNames({ includes: ["group.family", "allergens"] })

            const commercialNamesRow = commercialNames.map((commercialName) => ({
                id: commercialName.objectId,
                name: replaceCSVSeparatorInText(commercialName.name),
                family: replaceCSVSeparatorInText(commercialName.group?.family?.name),
                group: replaceCSVSeparatorInText(commercialName.group?.name),
                complexity: commercialName.complexity || "",
                season: commercialName.season ? mapSeasonsToPeriodsLabels(commercialName.season).join(", ") : "",
                allergens: commercialName.allergens ? commercialName.allergens.map(allergen => allergen.name).join(", ") : "",
                label: getIngredientLabel(labels, commercialName.label),
                conservationMode: getIngredientLabel(conservationModes, commercialName.conservationMode),
                firstOrigin: getIngredientLabel(origins, commercialName.firstOrigin),
                firstOriginAverage: commercialName.firstOriginAverage || "",
                secondOrigin: getIngredientLabel(origins, commercialName.secondOrigin),
                secondOriginAverage: commercialName.secondOriginAverage || "",
            }))

            dispatch(exportCSVFile(commercialNamesBaseCsvHeader, commercialNamesRow, "ingredients"))
        } catch (err) {
            dispatch({
                type: "PLANNING_OPEN_SNACKBAR",
                planningSnackBar: { open: true, duration: 5000, type: "error", message: "Erreur lors du téléchargement de la base ingredients" }
            })
        }
    })
}