import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import Typography from "@mui/material/Typography"
import clsx from "clsx"
import { Formik, Form } from "formik"
import _isEqual from "lodash/isEqual"
import {useDispatch, useSelector} from "react-redux"

import LoginForm from "./LoginForm"
import {
    closeAppSnackBar,
    login,
    showCreateAccount,
    showRequestPasswordChange
} from "../../actions/Utils/app"
import HelpButton from "../../components/HelpButton"
import helpLinks from "../../utils/HelpLinks"
import { COLORS } from "../../utils"
import { loginSchema } from "../../utils/yupValidators"
import MySnackbarContentWrapper from "../../components/MySnackbarContentWrapper"


const useStyles = makeStyles({
    card: {
        background: COLORS.WHITE,
        border: `0.5px solid ${COLORS.SITE_BORDER}`,
        boxSizing: "border-box",
        boxShadow: `0px 4px 13px ${COLORS.SITE_BOX_SHADOW}`,
        borderRadius: 4,
        minHeight: 312,
        minWidth: 363,
        paddingLeft: 43,
        paddingTop: 40,
        paddingRight: 54,
        "& .MuiCardContent-root": {
            padding: 0,
            width: 266
        }
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        color: COLORS.SITE_INFORMATION_VALUE,
        marginBottom: 10
    },
    baseLink: {
        fontSize: 14,
        letterSpacing: 0.25,
        color: COLORS.BLUE_PAPER_SELECTED,
        "& > span": {
            color: COLORS.BLUE_PAPER_SELECTED,
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    forgottenPwd: {
        composes: "$baseLink",
        textAlign: "right",
        marginTop: 8
    },
    createAccount: {
        composes: "$baseLink",
        marginTop: 57
    },
    cardAction: {
        justifyContent: "center",
        paddingTop: 40,
        paddingBottom: 18
    }
})

const Login = (props) => {
    const {
        location
    } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const appSnackBar = useSelector(state => state.app.appSnackBar, _isEqual)

    const _login = values => {
        dispatch(login(values.username, values.password, location.state && location.state.returnPath))
    }

    const createAccount = () => dispatch(showCreateAccount())

    const changePassword = () => dispatch(showRequestPasswordChange())

    const _closeSnackbar = () => {
        dispatch(closeAppSnackBar(appSnackBar.type))
    }

    return (
        <div className="flexCenter height100">
            <Formik
                enableReinitialize
                initialValues={{
                    username: "",
                    password: ""
                }}
                onSubmit={_login}
                validationSchema={loginSchema}
            >
                {({
                    values,
                    errors,
                    handleChange
                }) => {
                    return (
                        <Form>
                            <Card className={clsx(classes.card, "flexCenter")}>
                                <CardContent>
                                    <Typography className={classes.title}>
                                        Se connecter
                                    </Typography>
                                    <LoginForm
                                        values={values}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                    <Typography className={classes.forgottenPwd}>
                                        <span onClick={changePassword}>Mot de passe perdu ?</span>
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.cardAction}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        ME CONNECTER
                                    </Button>
                                </CardActions>
                            </Card>
                        </Form>
                    )
                }}
            </Formik>
            <div className={classes.createAccount}>
                Pas encore inscrit(e) ? <span onClick={createAccount}>Créer mon compte</span>
            </div>
            <HelpButton link={helpLinks.login} />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={appSnackBar.open}
                autoHideDuration={appSnackBar.duration}
                onClose={_closeSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={_closeSnackbar}
                    variant={appSnackBar.type}
                    message={appSnackBar.message}
                />
            </Snackbar>
        </div>
    )
}

export default Login
