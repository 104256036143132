
import Parse from "parse"
import { maxBy, orderBy } from "lodash"

import { actionWithLoader, getReplacingPath, onEnter, push } from "../Utils/utils"
import { 
    getFilteredSites,
    getParseSite,
    getParseStockZone,
    deleteParseSites
} from "../../parseManager/site/parseSiteManager"
import {
    getReduxSite,
    getSelectedSite
} from "../../reducers/Site/sites"
import { generateRandomToken } from "../../utils"
import { countMachinesByKitchenArea } from "../../parseManager/machines/machinesManager"
import { getKitchenAreaBySiteId } from "../../parseManager/kitchenAreas/kitchenAreasManager"
import { setValues } from "../../parseUtils"

const Site = Parse.Object.extend("Site")
const StockZone = Parse.Object.extend("StockZone")
const KitchenArea = Parse.Object.extend("KitchenArea")
export const PackagingLine = Parse.Object.extend("PackagingLine")

const PACKAGING_LINE_PROPERTIES = new Set(["name"])

export function loadSites () {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const sites = await getFilteredSites(state.sites.siteFilters)

        const oldSelectedSite = getSelectedSite(state)
        const newSelectedSite = oldSelectedSite ? getReduxSite(state, oldSelectedSite.id) : null

        dispatch({
            type: "SITES_LOADED",
            sites,
            selectedSites: newSelectedSite,
        })
    })
}

export function onEnterSites(store) {
    return onEnter({
        store,
        actionThunk: loadSites,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}

export function onEnterSingleSite(store) {
    return async (nextState, replace, callback) => {
        const { params } = nextState

        if (params.id) {
            store.dispatch(loadSingleSite(params.id))
        }

        callback()
    }
}

export function closeSiteSnackBar(currentType) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "CLOSE_SITE_SNACKBAR",
            siteSnackBar: {open: false, type: currentType, message: "", duration: 1000}
        })
        dispatch(loadSites())
    })
}

export function clearSelectedSite() {
    return dispatch => {
        dispatch({
            type: "SITE_SELECTED",
            selectedSite: null
        })
    }
}

export function updateSiteFilters(change) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "SITE_UPDATE_LIST_FILTERS",
            change
        })
        dispatch(loadSites())
    })
}

export function deleteSites(sites) {
    return actionWithLoader(async (dispatch) => {
        await deleteParseSites(sites)
        await dispatch(loadSites())
    })
}

export const loadKitchenAreaBySiteId = () => {
    return actionWithLoader(async (dispatch) => {
        const kitchenAreas = await getKitchenAreaBySiteId()
        dispatch({
            type: "KITCHEN_AREAS_LOADED",
            kitchenAreas
        })
    })
}


export function createNewSite(values) {
    return actionWithLoader(async (dispatch) => {
        const site = new Site()

        Object.keys(values).forEach(key => {
            site.set(key, values[key])
        })

        site.set("type", "")
        site.set("image", null)
        site.set("stockZones", [])
        await site.save()

        await dispatch(loadSites())
        await dispatch(showSiteNewDetails(site.id))
    })
}

export function createStockZone(values, siteId) {
    return actionWithLoader(async (dispatch) => {
        const stockZone = new StockZone()
        const site = await getParseSite(siteId)
        const stockZones = site.get("stockZones")

        Object.keys(values).forEach(key => {
            stockZone.set(key, values[key])
        })

        stockZone.set("comments", "-")
        await stockZone.save()

        stockZones.push(stockZone)
        site.set("stockZones", stockZones)

        await site.save()
        dispatch(loadSingleSite(siteId))
    })
}

export const createPackagingLine = (values, siteId) => {
    return actionWithLoader(async (dispatch) => {
        const packagingLine = new PackagingLine()
        setValues(packagingLine, values, PACKAGING_LINE_PROPERTIES)
        const newPackagingLine = await packagingLine.save()
        if (!newPackagingLine) return

        // add the newly packaging line to the site
        const site = await getParseSite(siteId)
        const packagingLines = site.get("packagingLines") || []
        site.set("packagingLines", [newPackagingLine, ...packagingLines])
        await site.save()

        dispatch(loadSingleSite(siteId))
    })
}

export const loadSingleSite = (siteId) => {
    return actionWithLoader(async (dispatch) => {
        const site = await getParseSite(siteId, ["stockZones", "kitchenAreas.kitchenArea", "packagingLines"])
        const siteJson = site.toJSON()
        const kitchenAreas = siteJson.kitchenAreas || []

        const newKitchenAreas = []

        for (const kitchenArea of kitchenAreas) {
            const parseKitchenArea = new KitchenArea()
            parseKitchenArea.id = kitchenArea.kitchenArea.objectId
            const machinesCount = await countMachinesByKitchenArea(parseKitchenArea)
            newKitchenAreas.push({ ...kitchenArea, machinesCount })
        }

        const newSite = { ...siteJson, kitchenAreas: newKitchenAreas }

        dispatch({
            type: "SELECTED_SITE_UPDATED",
            selectedSite: newSite
        })

        // for edition
        dispatch({
            type: "KITCHEN_AREAS_LOADED",
            kitchenAreas: newKitchenAreas
        })
    })
}

/**
 * add a new kitchen area
 * note that the modification is not saved to database yet
 * so we just need to add it to the store (redux)
 * @param {*} values 
 * @returns 
 */
export function addKitchenArea(values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const kitchenAreas = state.sites.kitchenAreas || []

        const kitchenAreaWithMaxOrder = maxBy(kitchenAreas, kitchenArea => kitchenArea.order)
        // increment the order
        const order = kitchenAreaWithMaxOrder ? kitchenAreaWithMaxOrder.order + 1 : 1
        const newKitchenArea = {
            kitchenArea: {
                ...values,
                objectId: generateRandomToken(), // temporary objectId for the new added kitchen area
            },
            order,
            machinesCount: 0,
        }

        const newKitchenAreas = [...kitchenAreas, newKitchenArea]

        dispatch({
            type: "KITCHEN_AREAS_LOADED",
            kitchenAreas: newKitchenAreas
        })
    })
}

/**
 * edit single kitchen area
 * note that the modification is not saved to database yet
 * so we just need to update the store (redux)
 * @param {*} id 
 * @param {*} values 
 * @returns 
 */
export function editKitchenArea(id, values) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const kitchenAreas = state.sites.kitchenAreas || []

        const newKitchenAreas = []

        for (const kitchenArea of kitchenAreas) {
            if (kitchenArea.kitchenArea.objectId === id) {
                const updatedKitchenArea = {
                    ...kitchenArea, // other field
                    kitchenArea: { // updated field
                        ...kitchenArea.kitchenArea, // other old kitchenArea field
                        ...values // new / updated fields
                    },
                    status: "edited" // need this temporary field to know which item has been updated
                }
                newKitchenAreas.push(updatedKitchenArea)
            } else {
                newKitchenAreas.push(kitchenArea)
            }
        }

        dispatch({
            type: "KITCHEN_AREAS_LOADED",
            kitchenAreas: newKitchenAreas
        })
    })
}

export const deleteKitchenArea = (id) => {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const kitchenAreas = state.sites.kitchenAreas || []

        let newKitchenAreas = kitchenAreas.filter(kitchenArea => kitchenArea.kitchenArea.objectId !== id)

        // update the orders
        newKitchenAreas = newKitchenAreas.map((kitchenArea, index) => ({
            ...kitchenArea,
            order: index + 1
        }))

        dispatch({
            type: "KITCHEN_AREAS_LOADED",
            kitchenAreas: newKitchenAreas
        })
    })
}

/**
 * save and update the database for the edited, added, and deleted kitchen areas
 * @param {*} siteId 
 * @returns 
 */
export function saveSiteKitchenAreas(siteId, actions) {
    return actionWithLoader(async (dispatch, getState) => {
        const site = await getParseSite(siteId, ["stockZones", "kitchenAreas.kitchenArea"])
        let oldSiteKitchenAreas = site.get("kitchenAreas")
        const state = getState()
        const kitchenAreas = state.sites.kitchenAreas

        // ------------------------------------------------- //
        // --------------- Delete KitchenAreas ------------- //
        // ------------------------------------------------- //
        if (actions.hasDeleted) {
            const filteredOldSiteKitchenAreas = []
            for (const oldKitchenArea of oldSiteKitchenAreas) {
                const kitchenAreaToDelete = kitchenAreas.find(kitchenArea => kitchenArea.kitchenArea.objectId === oldKitchenArea.kitchenArea.id)

                if (!kitchenAreaToDelete) {
                    const kitchenArea = await new Parse.Query("KitchenArea")
                        .equalTo("objectId", oldKitchenArea.kitchenArea.id)
                        .first()
                    await kitchenArea.destroy()
                } else {
                    // remaining kitchen areas
                    filteredOldSiteKitchenAreas.push(oldKitchenArea)
                }
            }

            oldSiteKitchenAreas = filteredOldSiteKitchenAreas.map((kitchenArea, index) => ({
                ...kitchenArea,
                order: index + 1 // update the order
            }))
        }

        // ------------------------------------------------- //
        // ----------------- Change order ------------------ //
        // ------------------------------------------------- //
        if (actions.hasOrderChanged) {
            const orderedKitchenAreas = []
            for (const oldKitchenArea of oldSiteKitchenAreas) {
              const currentKitchenArea = kitchenAreas.find(kitchenArea => kitchenArea.kitchenArea.objectId === oldKitchenArea.kitchenArea.id)
        
              if (currentKitchenArea) {
                orderedKitchenAreas.push({
                  ...oldKitchenArea,
                  order: currentKitchenArea.order,
                })
              }
            }

            oldSiteKitchenAreas = orderedKitchenAreas
        }

        // ------------------------------------------------- //
        // ------------ Create new KitchenAreas ------------ //
        // ------------------------------------------------- //
        if (actions.hasAdded) {
            // get only the new added from form
            const newKitchenAreas = kitchenAreas.filter(kitchenArea => !kitchenArea.kitchenArea.createdAt)
            const savedNewKitchenAreas = []

            for (const newKitchenArea of newKitchenAreas) {
                const kitchenArea = new KitchenArea()
                kitchenArea.set("name", newKitchenArea.kitchenArea.name)
                const savedKitchenArea = await kitchenArea.save()

                // add the newly created kitchen Area to the site
                savedNewKitchenAreas.push({
                    kitchenArea: savedKitchenArea,
                    order: newKitchenArea.order
                })
            }

            oldSiteKitchenAreas = [...oldSiteKitchenAreas, ...savedNewKitchenAreas]
        }
     
        // ------------------------------------------------- //
        // ----------- Edit existing KitchenAreas ---------- //
        // ------------------------------------------------- //
        if (actions.hasEdited) {
            // update only those temporary marked as "edited" and if it exist in the database
            const editedOldKitchenAreas = kitchenAreas.filter(kitchenArea => kitchenArea.status === "edited" && kitchenArea.kitchenArea.createdAt)

            // we just need to update the reference (KitchenArea), no need to update the pointer (in Site)
            for (const editedOldKitchenArea of editedOldKitchenAreas) {
                const kitchenArea = await new Parse.Query("KitchenArea")
                    .equalTo("objectId", editedOldKitchenArea.kitchenArea.objectId)
                    .first()

                kitchenArea.set("name", editedOldKitchenArea.kitchenArea.name)
                await kitchenArea.save()
            }
        }

        // ------------------------------------------------- //
        // ---- Update Site with the saved KitchenAreas ---- //
        // ------------------------------------------------- //
        const orderedKitchenAreas = orderBy(oldSiteKitchenAreas, "order")
        site.set("kitchenAreas", orderedKitchenAreas)

        await site.save()

        // reload the site query
        dispatch(loadSingleSite(siteId))

        dispatch({
            type: "KITCHEN_AREAS_CLEARED",
        })
    })
}

export function editNews(siteId, news) {
    return async (dispatch) => {
        const site = await getParseSite(siteId)

        if (!site) {
            return
        }

        site.set("news", news)
        await site.save()

        dispatch(loadSingleSite(siteId))
    }
}

export function saveSite(id, values) {
    return async (dispatch) => {
        const site = await getParseSite(id)

        if (!site) {
            return
        }

        Object.keys(values).forEach(key => {
            site.set(key, values[key])
        })

        await site.save()
        await dispatch(loadSingleSite(id))
    }
}

export function saveSiteImage(image, siteId) {
    return async (dispatch) => {
        const site = await getParseSite(siteId)

        if (!site) {
            return
        }

        site.set("image", image)

        await site.save()
        await dispatch(loadSingleSite(siteId))
    }
}

export function saveStockZone(stockZoneId, values, siteId) {
    return async (dispatch) => {
        const stockZone = await getParseStockZone(stockZoneId)

        if (!stockZone) {
            return
        }

        Object.keys(values).forEach(key => {
            stockZone.set(key, values[key])
        })

        await stockZone.save()
        await dispatch(loadSingleSite(siteId))
    }
}

export function deleteStockZone(stockZoneId, siteId) {
    return async (dispatch) => {
        const stockZone = await getParseStockZone(stockZoneId)
        const site = await getParseSite(siteId)

        const newStockZones = site.get("stockZones").filter(stockZone => stockZone.id !== stockZoneId)
        site.set("stockZones", newStockZones)

        await site.save()
        await stockZone.destroy()

        await dispatch(loadSingleSite(siteId))
    }
}

export function showSitesList() {
    return push("/settings/sites/sites")
}

export function showSiteNewDetails(id) {
    return push(`/settings/sites/site/general/${id}`)
}

export function showSiteTab(tab, siteId) {
    return push(`/settings/sites/site/${tab}/${siteId ? siteId : ""}`)
}
