import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import { TextField, Typography } from "@mui/material"
import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"

import {
    showSingleOrder,
    saveDeliveryNote
} from "../../../actions/OrderReception/OrderReception"

import OrderReceptionHeader from "../Reception/OrderReceptionHeader"
import OrderReceptionResume from "../Reception/OrderReceptionResume"
import OrderTitle from "../../../components/Order/OrderTitle"
import OrderCard from "../../../components/Order/OrderCard"
import OrderCardTitle from "../../../components/Order/OrderCardTitle"
import SupplierItemLotFooter from "../Lot/SupplierItemLotFooter"
import OrderDeliveryNoteSupplierItems from "./OrderDeliveryNoteSupplierItems"
import { getOrderSupplierItemReceptionSelector } from "../../../reducers/OrderReception/orderReception"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    content: {
        padding: "24px 24px 100px 24px",
        display: "flex",
        flexDirection: "column",
        gap: 24,
        marginTop: 64,
        alignSelf:"stretch",
    }
})

const OrderDeliveryNote = () => {
    const [number, setNumber] = useState("")
    const [error, setError] = useState("")

    const dispatch = useDispatch()
    const orderReception = useSelector(getOrderSupplierItemReceptionSelector)

    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)

    const classes = useStyles()

    // only for initial value
    useEffect(() => {
        if (!orderReception) return
        setNumber(orderReception.deliveryNote?.number || "")
    }, [])

    const onClickReturn = useCallback(() => {
        dispatch(showSingleOrder(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    if (!orderReception) return

    const handleNumberChange = (e) => {
        setNumber(e.target.value)
    }

    const handleSubmit = async () => {
        if (!number) {
            setError("Veuillez indiquer le numéro du bon de livraison.")
            return
        }

        const values = { number }
        await dispatch(saveDeliveryNote(orderReception.objectId, values))
        dispatch(showSingleOrder(site.objectId, date, orderReception.objectId))
    }

    return (
        <div className="flexColumn positionRelative flex1">
            <OrderReceptionHeader onBack={onClickReturn} />
            <div className={classes.content}>
                <OrderReceptionResume />
                <OrderTitle
                    title="Contrôle du bon de livraison"
                    subtitle="Ajoutez le numéro du BL et faites correspondre les quantités avec le BL papier."
                />
                <OrderCard>
					<OrderCardTitle title="Bon de livraison" icon={<LocalShippingOutlinedIcon />} />
                    <TextField
                        variant="standard"
                        name="number"
                        value={number}
                        onChange={handleNumberChange}
                        label="Numéro du bon de livraison"
                        className={classes.numberInput}
                        fullWidth
                        error={!!error}
                        helperText={error}
                    />
                    {orderReception.deliveryNote?.inputDate && (
                        <Typography variant="body2" sx={{ color: COLORS.BLACK_500}}>
                            Saisi le {dayjs(orderReception.deliveryNote?.inputDate).format("DD/MM/YYYY à HH:mm")}
                        </Typography>
                    )}
                </OrderCard>
                {/* supplier items table */}
                <OrderDeliveryNoteSupplierItems
                    items={orderReception.supplierItems}
                    orderReceptionId={orderReception.objectId}
                />
            </div>
            <SupplierItemLotFooter
                confirmText="Valider le Bon de Livraison"
                onConfirm={handleSubmit}
                sx={{ position: "fixed !important" }}
            />
        </div>
    )
}

export default OrderDeliveryNote