
import React from "react"
import PropTypes from "prop-types"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { quality, format } from "@cloudinary/url-gen/actions/delivery"
import { auto, autoEco, autoLow, autoBest } from "@cloudinary/url-gen/qualifiers/quality"
import { gif, auto as autoFormat } from "@cloudinary/url-gen/qualifiers/format"
import { thumbnail, fill, crop, fit, scale } from "@cloudinary/url-gen/actions/resize"
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
})

const CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME

const CloudinaryImage = ({
	containerClassName,
	imageId,
	width,
	height,
	resize = "fill",
	round = null,
	qualityNumber = null,
	customQuality = "eco",
	imageFormat = "auto",
	cloudName = CLOUD_NAME
}) => {

	const classes = useStyles()

	if (!imageId || typeof imageId !== "string" || !imageId.length) {
		return null
	}
	if (!cloudName || typeof cloudName !== "string" || !cloudName.length) {
		return null
	}

	// Create a Cloudinary instance and set your cloud name.
	const cld = new Cloudinary({
		cloud: {
			cloudName: cloudName
		}
	})

	// Instantiate a CloudinaryImage object for the image with the public ID.
	const myImage = cld.image(imageId)

	const styles = {}

	// -------------------------------------------------------- //
	// handle quality optimization (default eco for low bandwidth)
	// -------------------------------------------------------- //

	if (!isNaN(qualityNumber) && qualityNumber >= 1 && qualityNumber <= 100) {
		myImage.delivery(quality(qualityNumber))
	}
	else {
		if (customQuality === "auto") {
			myImage.delivery(quality(auto()))
		}
		if (customQuality === "eco") {
			myImage.delivery(quality(autoEco()))
		}
		if (customQuality === "low") {
			myImage.delivery(quality(autoLow()))
		}
		if (customQuality === "best") {
			myImage.delivery(quality(autoBest()))
		}
	}

	// --------------------------------------------------------------------------------------------------- //
	// handle image format optimization (it will set it to a webp an avi depending on the browser and context)
	// ---------------------------------------------------------------------------------------------------- //
	if (imageFormat === "auto") {
		myImage.delivery(format(autoFormat()))
	}
	if (imageFormat === "gif") {
		myImage.delivery(format(gif()).lossy())
	}

	// -------------------------------------------------------- //
	// ----------------------- SIZE --------------------------- //
	// -------------------------------------------------------- //

	if (typeof width === "number" && typeof height === "number") {
		if (resize === "fill") {
			myImage.resize(fill().width(width).height(height))
		}
		if (resize === "fit") {
			myImage.resize(fit().width(width).height(height))
		}
		if (resize === "thumbnail") {
			myImage.resize(thumbnail().width(width).height(height))
		}
		if (resize === "crop") {
			myImage.resize(crop().width(width).height(height))
		}
		if (resize === "scale") { // doesn't preserve aspect ratio
			myImage.resize(scale().width(width).height(height))
		}
	}
	else { // if height OR width not provided use styles object to set it to size of container
		styles.height = typeof height === "number" ? `${height}px` : "100%"
		styles.width = typeof width === "number" ? `${width}px` : "100%"
		if (resize === "fill") {
			styles.objectFit = "cover"
		}
		if (resize === "fit") {
			styles.objectFit = "contain"
		}
	}

	// --------------------RADIUS----------------------------------- //
	if (typeof round === "number" && round > 0) {
		myImage.roundCorners(byRadius(round))
	}


	return (
		<div className={containerClassName || classes.container}>
			<AdvancedImage cldImg={myImage} style={styles} />
		</div>
	)
}

export default CloudinaryImage

CloudinaryImage.propTypes = {
	imageId: PropTypes.string.isRequired,
	width: PropTypes.any,
	height: PropTypes.any,
	resize: PropTypes.oneOf(["fill", "fit", "thumbnail", "crop", "scale"]),
	round: PropTypes.number,
	qualityNumber: PropTypes.number,
	customQuality: PropTypes.oneOf(["good", "best", "eco", "low"]),
	imageFormat: PropTypes.string,
	extension: PropTypes.string
}