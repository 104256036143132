import React from "react"
import {getSpicyImg, RecipeSpicy} from "../../utils/recipes"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

const SpicySelect = (props) => {
	const {classes, ...otherProps} = props
	
	return (
		<FormControl className={classes.typeFormControl} variant="standard">
			<InputLabel>Epice</InputLabel>
			<Select
				variant="standard"
				name="spicy" label="Épicé" id="spicy" fullWidth {...otherProps}>
				{
					RecipeSpicy.map((spicy, index) => {
						if (spicy.value === "0"){
							return (
								<MenuItem key={index} value={spicy.value}>{spicy.label}</MenuItem>
							)
						}
						else {
							return (
								<MenuItem key={index} value={spicy.value}><div>{getSpicyImg(parseFloat(spicy.value))}</div></MenuItem>
							)
						}
					})
				}
			</Select>
		</FormControl>
	)
}

export default SpicySelect
