import { getLots } from "../parseManager/lot/parseLotManager"
import { getStockZone } from "../parseManager/site/parseSiteManager"
import cloneDeep from "lodash/cloneDeep"
import moment from "moment/moment"
import { roundNumber } from "../utils"

export const getLotsBySite = async (site) => {
  let lots = []

  for (const stockZone of site.stockZones) {
    const parseStockZone = await getStockZone({ stockZoneId: stockZone.objectId, toJson: false })
    lots = await getLots(parseStockZone)
  }
  return lots
}

export const getSortedLotsBySiteAndProduct = async (site, subcontractorProduct) => {
  const lots = await getLotsBySite(site)
  const lotsByProduct = lots.filter((lot) => (
    lot.orderSupplierItem.supplierItemId === subcontractorProduct.name.objectId
    // && lot.dlc === subcontractorProduct.dlc
  ))
  const sortedLotsByDateCreation = lotsByProduct.sort((a, b) => (new Date(a.createdAt).getTime()) - (new Date(b.createdAt).getTime()))
  return sortedLotsByDateCreation
}

export const filterLotsBySupplierItem = (lots, supplierItems) => {
  const filteredLots = []

  supplierItems.forEach(supplierItem => {
    let items = lots.filter(lot => lot.orderSupplierItem && lot.orderSupplierItem.supplierItemId === supplierItem)
    if (items.length > 0) {
      filteredLots.push(...items)
    }
  })

  return filteredLots
}

export const getSiteStoredForLot = () => {
  return JSON.parse(localStorage.getItem("siteSelected"))
}

export const initializeStockZones = (stockZoneSelected, siteSelected) => {
  const cloneStockZone = stockZoneSelected && stockZoneSelected.objectId === 0 ? stockZoneSelected : (
    siteSelected.stockZones ? cloneDeep(siteSelected.stockZones[0]) : {}
  )

  // OBJECT ID 0 is used for "all stock zones" option
  if (!stockZoneSelected || stockZoneSelected.objectId !== 0) {
    cloneStockZone.name = "Toutes les zones"
    cloneStockZone.objectId = 0
  }

  if (!siteSelected.stockZones.find(stockZone => stockZone.objectId === 0)) {
    return [...siteSelected.stockZones, ...[cloneStockZone]]
  } else {
    return siteSelected.stockZones
  }
}

export const getAvailableLotsForSubstitutes = (rawLots, originalSupplierItem, originalLot, date, substituteSupplierItemIds) => {
  const currentDate = moment.utc().startOf("day").valueOf()
  /**
   * We get only substitute lots with DLC in the futur
   * We add isSubstitute field to mark these lots
   */
  const filteredLots = rawLots.filter(rawLot => rawLot.dlc >= currentDate).map(obj => ({ ...obj, isSubstitute: true, originalSupplierItem, originalLot, quantityOutput: 0 }))
  /** Sort by substitute order in database AND by DLC after **/
  const sortedLots = filteredLots.sort((l1, l2) => substituteSupplierItemIds.indexOf(l1.orderSupplierItem.supplierItemId) - substituteSupplierItemIds.indexOf(l2.orderSupplierItem.supplierItemId) || l1.dlc - l2.dlc)

  /** Calculate output already done **/
  sortedLots.forEach(lot => {
    let outputDone = 0
    const weight = lot.orderSupplierItem && lot.orderSupplierItem.units && lot.orderSupplierItem.units.stock.weight ? lot.orderSupplierItem.units.stock.weight : 1
    lot.events.forEach(event => {
      if (event.mode === "OUTPUT" && event.productionDate === date) {
        outputDone += event.quantity * weight
      }
    })

    lot.outputAlreadyDone = roundNumber(outputDone, 2)
  })

  return sortedLots.filter(lot => lot.quantity > 0 || (lot.quantity === 0 && lot.outputAlreadyDone > 0))
}

export const getHypotheticalLotsFromLots = (lots) => {
  const hypotheticalLots = []
  for (const [, value] of lots) {
    for (const [, values] of value) {
      values.forEach((lot) => {
        if (lot.hypothetical) {
          hypotheticalLots.push(lot)
        }
      })
    }
  }

  return hypotheticalLots
}

export const updateHypotheticalLotOnSubstituteChange = (substituteLotChanged, lots, quantityValue) => {
  const hypotheticalLots = getHypotheticalLotsFromLots(lots)
  const hypotheticalLotReplaced = hypotheticalLots.find(hypotheticalLot => hypotheticalLot.orderSupplierItem.supplierItemId === substituteLotChanged.originalSupplierItem.objectId)
  hypotheticalLotReplaced.quantityOutput = hypotheticalLotReplaced.quantityOutput - (parseInt(quantityValue) * substituteLotChanged.orderSupplierItem.units.stock.weight)
}


// for pse
// for lot with orignalSupplierItem (=== traiter le manquant), we only use them for replacement
export const groupLotsBySupplierItems = (lots, supplierItemsIds, productionDate) => {
  const lotsBySupplierItems = new Map()
  supplierItemsIds.forEach(id => lotsBySupplierItems.set(id, []))
  lots.forEach(lot => {
    const event = lot.events.find(event => event.productionDate === productionDate)
    let supplierItemId = lot.supplierItem.objectId
    if (event?.originalSupplierItem) {
      supplierItemId = event.originalSupplierItem.objectId
    }
    if (lotsBySupplierItems.has(supplierItemId)) {
      lotsBySupplierItems.get(supplierItemId).push(lot)
    }
  })
  return lotsBySupplierItems
}