import React, { useRef, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import createStyles from "@mui/styles/createStyles"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { COLORS } from "../../utils"
import clsx from "clsx"

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      width: 373,
      height: 45,
      border: "none",
      boxSizing: "border-box",
      borderRadius: 5,
      boxShadow: "none",
      backgroundColor: COLORS.LIGHT_GREY,
    },
    searchFieldDiv: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 8,
      width: "100%",
      height: 67,
      backgroundColor: COLORS.LIGHT_GREY,
      justifyContent: "space-between",
    },
    input: {
      color: COLORS.DARK_GREY,
      marginLeft: theme.spacing(0),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    onSearch: {
      backgroundColor: COLORS.PRIMARY_COLOR,
      color: COLORS.WHITE,
    },
  })
)

export default function SearchInput(props) {
  const { label, searchFilter, updateSearchValue, focused } = props
  const classes = useStyles()
  const inputRef = useRef()
  const onSearch = "" !== searchFilter
  const reset = () => {
    inputRef.current.value = ""
    updateSearchValue({ target: { value: "" } })
  }

  useEffect(() => {
    if (focused) inputRef.current.focus()
  }, [focused])

  return (
    <div
      className={clsx(classes.searchFieldDiv, onSearch ? classes.onSearch : "")}
    >
      <Paper
        component="form"
        className={clsx(classes.root, onSearch ? classes.onSearch : "")}
      >
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          size="large"
        >
          <SearchIcon
            htmlColor={onSearch ? COLORS.WHITE : COLORS.GREY_SEARCH}
          />
        </IconButton>
        <InputBase
          className={clsx(classes.input, onSearch ? classes.onSearch : "")}
          placeholder={label}
          inputProps={{ "aria-label": label }}
          inputRef={inputRef}
          defaultValue={searchFilter}
          onChange={updateSearchValue}
        />
      </Paper>

      {/* close button */}
      {onSearch && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={reset}
          size="large"
        >
          <CloseIcon htmlColor={COLORS.WHITE} />
        </IconButton>
      )}
    </div>
  )
}
