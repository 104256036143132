import Parse from "parse"
import {actionWithLoader, getReplacingPath, onEnter, push} from "../../Utils/utils"

const GroupsIngredients = Parse.Object.extend("GroupsIngredients")
const FamiliesIngredients = Parse.Object.extend("FamiliesIngredients")
const Allergens = Parse.Object.extend("AllergensIngredients")

export function loadClassificationsIngredients() {
    return actionWithLoader(async (dispatch) => {
        const nbGroups = await new Parse.Query(GroupsIngredients)
            .count()
        const nbFamilies = await new Parse.Query(FamiliesIngredients)
            .count()
        const nbAllergens = await new Parse.Query(Allergens)
            .count()

        dispatch({
            type: "INGREDIENTS_CLASSIFICATIONS_LOADED",
            nbGroups: nbGroups,
            nbFamilies: nbFamilies,
            nbAllergens: nbAllergens
        })
    })
}
export function onEnterClassificationsIngredients(store) {
    return onEnter({
        store,
        actionThunk: loadClassificationsIngredients,
        getReplacingPath: getReplacingPath({needUser: true})
    })
}
export function showClassificationsIngredients() {
    return push("/settings/ingredients/classifications")
}
