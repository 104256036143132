import {
		KFC_BRANDS
} from "../../utils"

import { formatInventoryData, handleLastInventory } from "../../utils/inventoryUtils"

const defaultState = {
		hubs: [],
		inventoryData: [],
	originalInventoryData: [],
		brand: KFC_BRANDS[0].name,
	noDisplay: false,
	selectedStatus: "ALL",
	nameOrCode: "",
	inventorySnackBar: { open: false, type: "", message: "", duration: 0 },
	inventoryDetailSnackBar: { open: false, type: "", message: "", duration: 0 }
}

export default function (state = defaultState, action) {
	let selectedPosition, newOriginalReceptionData, newInventoryData

		switch (action && action.type) {
				case "INVENTORY_FILTER_LOADED":
						return {
								...state,
								hubs: action.hubs,
								date: action.date
						}
			case "FILTER_BRAND_UPDATED":
				return {
					...state,
					brand: action.brand,
					hubs: action.hubs
				}
				case "INVENTORY_LOADED":
					newOriginalReceptionData = action.originalInventoryData.map(elm => handleLastInventory(elm))
					newInventoryData = formatInventoryData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

					return {
								...state,
								hub: state.hubs.filter(elm => elm.objectId === action.hub),
								date: action.date,
								brand: action.brand,
						selectedStatus: state.selectedStatus,
						nameOrCode: state.nameOrCode,
						update: JSON.stringify(newInventoryData),
								inventoryData: newInventoryData,
							originalInventoryData: newOriginalReceptionData
						}

			case "INVENTORY_SELECTED_PRODUCT_STOCK_UPDATED_STATUS":
				selectedPosition = state.originalInventoryData.findIndex(elm => elm.objectId === action.selectedProduct.objectId)
				newOriginalReceptionData = state.originalInventoryData

				if (action.selectedProduct.inventoryStatus !== "IN_PROGRESS") newOriginalReceptionData[selectedPosition] = handleLastInventory(action.selectedProduct)
				newInventoryData = formatInventoryData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

				return {
					...state,
					originalInventoryData: newOriginalReceptionData,
					inventoryData: newInventoryData,
					update: JSON.stringify(newInventoryData),
					noDisplay: (action.noDisplay !== undefined)?action.noDisplay:state.noDisplay
				}
			case "INVENTORY_SELECTED_PRODUCT_STOCK_UPDATED":
				selectedPosition = state.originalInventoryData.findIndex(elm => elm.objectId === action.selectedProduct.objectId)
				newOriginalReceptionData = state.originalInventoryData

				newOriginalReceptionData[selectedPosition] = handleLastInventory(action.selectedProduct)
				newInventoryData = formatInventoryData(filter(newOriginalReceptionData, state.selectedStatus, state.nameOrCode))

				return {
					...state,
					originalInventoryData: newOriginalReceptionData,
					inventoryData: newInventoryData,
					update: JSON.stringify(newInventoryData),
					inventoryDetailSnackBar: action.inventorySnackBar
				}
				
			case "INVENTORY_FILTER_STATUS_SET":
				newInventoryData = formatInventoryData(filter(state.originalInventoryData, action.inventoryStatus, state.nameOrCode))

				return {
					...state,
					inventoryData: newInventoryData,
					selectedStatus: action.inventoryStatus,
					update: JSON.stringify(newInventoryData)
				}

			case "INVENTORY_FILTER_NAME_OR_CODE_SET":
				newInventoryData = formatInventoryData(filter(state.originalInventoryData, state.selectedStatus, action.searchFilter))

				return {
					...state,
					inventoryData: newInventoryData,
					nameOrCode: action.searchFilter,
					update: JSON.stringify(newInventoryData)
				}

			case "INVENTORY_OPEN_DETAIL_SNACKBAR":
			case "INVENTORY_CLOSE_DETAIL_SNACKBAR":
				return {
					...state,
					inventoryDetailSnackBar: action.inventorySnackBar,
					noDisplay: (action.noDisplay !== undefined)?action.noDisplay:state.noDisplay
				}

				case "INVENTORY_OPEN_SNACKBAR":
			case "INVENTORY_CLOSE_SNACKBAR":
				return {
					...state,
					inventorySnackBar: action.inventorySnackBar
				}
				default:
						return {
								...state
						}
		}
}

function filter(result, status, nameOrCode) {
	let newResult = result.filter(elm => elm.display)

	// filter on status
	if (status !== "ALL") {
		newResult = newResult.filter(elm => elm.inventoryStatus === status)
	}

	// filter on name or code
	const regex = new RegExp(nameOrCode, "ig")
	newResult = newResult.filter(elm =>  (elm.commercialTitle.match(regex) !== null || elm.uniqueCode.match(regex) !== null))

	return newResult
}
