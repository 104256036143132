import React from "react"
import { StyledPaper, StyledPaperTitle, StyledPaperLabel } from "../../../../components/StyledSingleParseObjectComponents"
import { Stack, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { getProductTypeOptionsSelector } from "../../../../reducers/ProductTypes/productTypes"
import GenericEditIcon from "../../../../components/GenericEditIcon"
import { getDistributionCenterProductTypes } from "../../../../utils/distributionCenter"

const DistributionCentersAttachRates = ({ attachRates = [], onEdit = null }) => {

  const productTypesOptions = useSelector(getProductTypeOptionsSelector)

  return (
    <StyledPaper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <StyledPaperTitle>Taux de rattachement</StyledPaperTitle>
        <GenericEditIcon onEdit={onEdit} />
      </Stack>
      {getDistributionCenterProductTypes(productTypesOptions).map(({ label, value }, index) => {
        return (
          <Stack direction="row" key={index}>
            <StyledPaperLabel>{label}</StyledPaperLabel>
            <Typography>{attachRates[value] || 0} %</Typography>
          </Stack>
        )
      })}
    </StyledPaper>
  )
}

export default DistributionCentersAttachRates