import React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const Actions = {
    EXTRACT: "EXTRACT",
    COMPUTE: "COMPUTE",
    DAYCARD: "DAYCARD"
}

const PlanningKitchenOutputActions = (props) => {
    const {
        onClickExtract,
        onClickCompute,
        onClickCreateCard
    } = props

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = (event, action) => {
        switch (action) {
            case Actions.EXTRACT:
                onClickExtract()
                break
            case Actions.COMPUTE:
                onClickCompute()
                break
            case Actions.DAYCARD:
                onClickCreateCard()
                break
            default:
                // do nothing
        }

        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "menu-popover" : undefined

    return <>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size="large">
            <MoreVertIcon />
        </IconButton>
        <Menu
            id={id}
            open={Boolean(open)}
            anchorEl={anchorEl}
            onClose={handleClose}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <MenuItem onClick={event => handleMenuItemClick(event, Actions.EXTRACT)}>Extraire</MenuItem>
            <MenuItem onClick={event => handleMenuItemClick(event, Actions.COMPUTE)}>Recalculer</MenuItem>
            <MenuItem onClick={event => handleMenuItemClick(event, Actions.DAYCARD)}>Carte du jour</MenuItem>
        </Menu>
    </>
}

export default PlanningKitchenOutputActions