import Parse from "parse"
import { push, onEnter, getReplacingPath, haveAccessRight, actionWithLoader } from "../Utils/utils"
import { getProductionSchemas, getProductionSchemaById } from "../../parseManager/productionSchema/parseProductionSchemasManager"
import { isParseObjDirty, setValues } from "../../parseUtils"
import { ACTIVE_KFC_BRANDS, PRODUCTION_SCHEMA_FIELDS } from "../../utils"

const ProductionSchema = Parse.Object.extend("ProductionSchema")


// ----------- ROUTING -------- //
export const showProductionSchemas = () => {
    return push("/settings/production/productionSchemas")
}

export const showProductionSchemasFrom = () => {
    return push("/settings/production/productionSchemas/form")
}

// ----------- LOADING -------- //

export const loadProductionSchemas = (toJson = true) => {
    return actionWithLoader(async dispatch => {
        const productionSchemas = await getProductionSchemas(toJson)
        dispatch({
            type: "PRODUCTION_SCHEMAS_LOADED",
            productionSchemas
        })
    })
}

export const loadProductionSchemasOptions = (toJson = true) => {
    return actionWithLoader(async dispatch => {
        const productionSchemas = await getProductionSchemas(toJson)
        const productionSchemasOptions = productionSchemas.map(schema => ({ key: schema.objectId, label: schema.name }))
        dispatch({
            type: "PRODUCTION_SCHEMAS_OPTIONS_LOADED",
            productionSchemasOptions
        })
    })
}

export const loadProductionSchemasData = () => {
    return actionWithLoader(async (dispatch) => {
        await dispatch(loadProductionSchemas())
    })
}


export const onEnterProductionSchemas = (store) => {
    return onEnter({
        store,
        actionThunk: loadProductionSchemasData,
        getReplacingPath: getReplacingPath({ needUser: true }),
        haveAccessRight: haveAccessRight({ readPlanningRules: true })
    })
}

// ----------- CREATE / SAVE -------- //

export const createOrUpdateSchema = async (values) => {
    const { isNew, objectId } = values
    const productionSchema = isNew ? new ProductionSchema() : await getProductionSchemaById(objectId, false)
    if (isParseObjDirty(productionSchema, values, PRODUCTION_SCHEMA_FIELDS)) {
        setValues(productionSchema, values, PRODUCTION_SCHEMA_FIELDS)
        return productionSchema
    }
    return null
}

export const saveProductionSchemas = (productionSchemas) => {
    return async dispatch => {
        try {
            // create or update schemas
            let schemasToUpdate = await Promise.all(productionSchemas.map(createOrUpdateSchema))
            schemasToUpdate = schemasToUpdate.filter(schema => !!schema)

            await Parse.Object.saveAll(schemasToUpdate)
            // refresh store
            await dispatch(loadProductionSchemas())
            // redirect
            dispatch(showProductionSchemas())
        }
        catch (e) {
            console.error(e)
            return dispatch({
                type: "SHOW_PRODUCTION_SCHEMA_SNACKBAR",
                productionSchemaSnackbar: { open: true, type: "error", message: "Erreur durant la sauvegarde des schémas de production", duration: 5000 }
            })
        }
    }
}
// ----------- OTHER-------- //
/**
 * used for yup validation : ensures that chosen name is unique
 * @param {String} name 
 * @param {String} objectId 
 * @returns 
 */
export const getProductionSchemaNameCount = async (name, objectId) => {
    const query = new Parse.Query(ProductionSchema)
    query.equalTo("name", name)
    if (objectId) {
        query.notEqualTo("objectId", objectId)
    }
    return await query.count()
}


export const closeProductionSchemaSnackbar = (currentType) => {
    return dispatch => {
        return dispatch({
            type: "HIDE_PRODUCTION_SCHEMA_SNACKBAR",
            productionSchemaSnackbar: { open: false, type: currentType, message: "", duration: 1000 }
        })
    }
}

export const updateCellWidth = (isEdition = true) => {
    return (dispatch, getState) => {
        const state = getState()
        const isAppMenuOpened = state.app.menuIsOpen
        let containerWidth = window.innerWidth - 48 // margins of the form
        if (isAppMenuOpened) {
            containerWidth -= 240 // size of menu
        }
        else {
            containerWidth -= 64 // size of menu
        }
        const addDeleteCellWidth = 46
        const fixedCellSpace = addDeleteCellWidth * 2
        const remainingDynamicCellSpace = isEdition ? containerWidth - fixedCellSpace : containerWidth
        const brandsColsNumber = ACTIVE_KFC_BRANDS.length
        const colsNumber = 2 + brandsColsNumber
        const cellWidth = remainingDynamicCellSpace / colsNumber
        dispatch({
            type: "PRODUCTION_SCHEMA_CELL_WIDTH_UPDATED",
            cellWidth
        })
    }
}