import React from "react"
import { makeStyles } from "@mui/styles"

import { Stack, Typography } from "@mui/material"

const styles = makeStyles({
	title: {
		color: "#262626",
		fontSize: 20
	},
	subtitle: {
		color: "#7C7C7C"
	},
})

const OrderTitle = ({ title, subtitle }) => {
	const classes = styles()
	return (
		<Stack gap={1} className="stretchSelf">
			<Typography variant="h6" className={classes.title}>{title}</Typography>
			{subtitle && (
					<Typography variant="span" className={classes.subtitle}>
						{subtitle}
					</Typography>
			)}
		</Stack>
	)
}

export default OrderTitle