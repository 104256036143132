import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import CloseIcon from "@mui/icons-material/Close"

const useStyles = makeStyles(() => ({
    insideicon:{
        borderRadius: "50%",
        width: 20,
        height: 20,
    },
    withMargin: {
        composes: "$insideicon",
        marginTop: -3
    }
}))

function IconClose({color, bgColor, withMargin}) {
    const classes = useStyles()
    return (
        <div className={withMargin ? classes.withMargin : ""}>
            <CloseIcon className={classes.insideicon} style={{ backgroundColor:bgColor, color:color}}/>
        </div>
    )
}

export default IconClose
